import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useInfiniteLoader from '../../../hooks/useInfiniteLoader'
import Datepicker from '../../../shared/Inputs/Datepicker/Datepicker'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import { selectAccessRights } from '../../App/app.selectors'
import css from './archive.module.css'
import { ArchiveEntry } from './archive.types'
import ArchiveDeleteForm from './ArchiveDeleteForm'
import ArchiveEntryForm from './ArchiveEntryForm'
import ArchiveMergeForm from './ArchiveMergeForm'
import ArchiveTable from './ArchiveTable'

/**
 * Модальное окно с поиском и отображением аварийного архива
 */
const ArchiveModal = (props: Props) => {
  const intl = useIntl()

  const tableWrapper = React.useRef<HTMLDivElement>()
  const scroll = React.useRef(0)

  const rights = useSelector(selectAccessRights)
  const allowCreating = rights['incidents_archive:create']
  const allowDeleting = rights['incidents_archive:delete']

  // поиск по дате и названию
  const [filter, setFilter] = React.useState<FilterState>(() => {
    const date = new Date()
    const t1 = date.valueOf() * 1000
    const t0 = date.setMonth(date.getMonth() - 1) * 1000
    return { t0, t1, name: '' }
  })

  const [mode, changeMode] = React.useState<FormMode>('view')
  const [selection, setSelection] = React.useState(new Set<number>())
  const { results, isLoading, isMore, loadMore, setResults } = useInfiniteLoader<ArchiveEntry>({
    url: `/nsi/v1/incidents_archive`,
    query: filter as any,
  })

  const reloadData = () => setFilter({ ...filter })

  // перед сменой формы запоминаем позицию прокрутки в таблице результатов, чтобы позже ее восстановить
  const setMode = (mode: FormMode) => {
    if (tableWrapper.current) {
      scroll.current = tableWrapper.current.scrollTop
    }
    changeMode(mode)
  }

  // восстанавливаем позицию прокрутки (после возвращения из формы удаления, редактирования и т.д.)
  React.useLayoutEffect(() => {
    if (mode === 'view' && tableWrapper.current) {
      tableWrapper.current.scrollTop = scroll.current
    }
  }, [mode])

  // обработка нажатия на строку - добавляем в выбранные строки, либо снимаем
  // выделение при повторном клике
  const handleSelect = React.useCallback((id: number) => {
    setSelection((prev) => {
      const selection = new Set(prev)

      if (selection.has(id)) selection.delete(id)
      else selection.add(id)

      return selection
    })
  }, [])

  // переход в плеере ко времени выбранной записи
  const gotoEntry = () => {
    const entry = results.find((e) => selection.has(e.id))
    props.onSelect(entry)
  }

  const resetMode = () => setMode('view')

  // создание нового импульс-архива
  if (mode === 'create') {
    return <ArchiveEntryForm mode="create" onClose={resetMode} onCreate={reloadData} />
  }

  // редактирование участка
  if (mode === 'edit') {
    const handleEdit = (result: ArchiveEntry) => {
      setResults((prev) => prev.map((r) => (r.id === result.id ? { ...r, ...result } : r)))
    }

    const entry = results.find((e) => selection.has(e.id))
    return <ArchiveEntryForm mode="edit" entry={entry} onClose={resetMode} onCreate={handleEdit} />
  }

  // удаление выбранных участков
  if (mode === 'delete') {
    const handleDelete = (ids: Set<number>) => {
      const nextSelection = new Set(selection)
      for (const id of ids) nextSelection.delete(id)

      setResults((prev) => prev.filter((r) => !ids.has(r.id)))
      setSelection(nextSelection)
    }

    return <ArchiveDeleteForm selection={selection} onClose={resetMode} onDelete={handleDelete} />
  }

  // объединение выбранных участков
  if (mode === 'merge') {
    const handleMerge = (result: ArchiveEntry, ids: Set<number>) => {
      setSelection(new Set([result.id]))
      setResults((prev) => {
        const results = prev.filter((r) => !ids.has(r.id))
        results.push(result)
        return results.sort((a, b) => b.ts_end - a.ts_end)
      })
    }

    return <ArchiveMergeForm selection={selection} entries={results} onClose={resetMode} onMerge={handleMerge} />
  }

  const selectLabel =
    props.mode === 'select' ? 'ChartPlayer.archive.impulse_select' : 'ChartPlayer.archive.impulse_goto'

  return (
    <Modal size="lg" isTall={true} onClose={props.onClose} closeOnClickOutside={false}>
      <div>
        <h2>{intl.formatMessage({ id: 'ChartPlayer.archive.impulse_title' })}</h2>

        <div className={css.formHeader}>
          <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.t0' })}>
            <Datepicker
              time={filter.t0 / 1000}
              onTimeChange={(t0) => setFilter({ ...filter, t0: t0 * 1000 })}
              inputClass="nsi-input"
            />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.t1' })}>
            <Datepicker
              time={filter.t1 / 1000}
              onTimeChange={(t1) => setFilter({ ...filter, t1: t1 * 1000 })}
              inputClass="nsi-input"
            />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.name_filter' })}>
            <TextInput
              name="name"
              value={filter.name}
              onChange={(name) => setFilter({ ...filter, name })}
              required={false}
            />
          </InputRow>
        </div>

        <div ref={tableWrapper} className={css.tableWrapper}>
          <InfiniteScroll parentSelector={'.' + css.tableWrapper} isMore={isMore} loadMore={loadMore}>
            <ArchiveTable entries={results} selection={selection} intl={intl} onSelect={handleSelect} />
          </InfiniteScroll>
        </div>

        <div className="text--gray text--center">
          {!isLoading && results.length === 0 && intl.formatMessage({ id: 'common.no_results' })}
        </div>

        <div className="app-modal__footer">
          <button className="nsi-button inverted" onClick={props.onClose}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </button>

          {allowCreating && selection.size === 0 && (
            <button className="nsi-button default" onClick={() => setMode('create')}>
              {intl.formatMessage({ id: 'ChartPlayer.archive.impulse_create' })}
            </button>
          )}

          {allowDeleting && selection.size > 0 && (
            <button className="nsi-button danger" onClick={() => setMode('delete')}>
              {intl.formatMessage({ id: 'ChartPlayer.archive.impulse_delete' })}
            </button>
          )}

          {allowCreating && selection.size === 1 && (
            <button className="nsi-button danger" onClick={() => setMode('edit')}>
              {intl.formatMessage({ id: 'ChartPlayer.archive.impulse_edit' })}
            </button>
          )}

          {allowCreating && selection.size > 1 && (
            <button className="nsi-button danger" onClick={() => setMode('merge')}>
              {intl.formatMessage({ id: 'ChartPlayer.archive.impulse_merge' })}
            </button>
          )}

          {selection.size === 1 && (
            <button className="nsi-button default" onClick={gotoEntry}>
              {intl.formatMessage({ id: selectLabel })}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

interface FilterState {
  t0: number
  t1: number
  name: string
}

type FormMode = 'view' | 'create' | 'edit' | 'delete' | 'merge'

interface Props {
  mode: 'view' | 'select'
  onClose: () => void
  onSelect: (entry: ArchiveEntry) => void
}

export default ArchiveModal
