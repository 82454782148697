import React from 'react'
import useResize from '../../../hooks/useResize'
import GanttContext from '../gantt.context'
import GanttRow from './GanttRow'

const GanttBody = (props) => {
  const ref = React.useRef<HTMLDivElement>()
  const context = React.useContext(GanttContext)

  // при изменении размеров окна необходимо перерассчитать размеры тела диаграммы ганта
  // т.к. они нужны для выставления высоты курсора равной высоте самой диаграммы
  useResize(ref, () => {
    if (ref.current) {
      const bodyRect = ref.current.getBoundingClientRect()
      context.setBodyRect(bodyRect)
    }
  })

  return (
    <div ref={ref} className="gantt__body">
      {props.children}
      <GanttRow selectable={false}>
        <div />
        <div />
      </GanttRow>
    </div>
  )
}

export default GanttBody
