import React from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import Override from './Override'
import { DeviceParamsMapping, ParamMapping } from './parameters.utils'
import { Phasor } from 'au-nsi/equipment'
import { Select } from '@alterouniversal/au-react-components'

class Phasors extends React.Component<Props> {
  private handleChange = (systemParameter: string, deviceParameter: string) => {
    this.props.onChange(deviceParameter, { value: systemParameter })
  }

  private handleFactorChange = (factor: number, deviceParameter: string) => {
    this.props.onChange(deviceParameter, { factor })
  }

  private handleOffsetChange = (offset: number, deviceParameter: string) => {
    this.props.onChange(deviceParameter, { offset })
  }

  render() {
    const { phasors, deviceParamsMapping, parametersUsage, options, editing, intl } = this.props
    const placeholder = { label: '', value: null, factor: null, offset: null }

    if (phasors.length === 0) {
      return (
        <div className="nsi-placeholder">
          <FormattedMessage id="nsi.no_results" />
        </div>
      )
    }

    const params = []

    for (const phasor of phasors) {
      params.push({ name: phasor.magnitudeName, factor: phasor.magnitudeFactor })
      params.push({ name: phasor.angleName, offset: phasor.angleOffset })
    }

    const rows = params.map((param, i) => {
      const { name } = param
      const option = deviceParamsMapping[name] || placeholder

      const isDuplicate = parametersUsage.get(option.value) > 1
      const error = isDuplicate ? intl.formatMessage({ id: 'nsi.parameters.duplicate_parameter' }) : ''

      const factor = option.factor || param.factor
      const offset = option.offset || param.offset

      const nameInput = (
        <Select
          name={name}
          value={option.value}
          onChange={this.handleChange}
          onClear={this.props.onRemove}
          options={options}
          error={error}
        />
      )

      const factorInput =
        i % 2 === 0 ? (
          <NumberInput fullWidth={true} name={name} value={factor} onChange={this.handleFactorChange} />
        ) : (
          <NumberInput fullWidth={true} name={name} value={offset} onChange={this.handleOffsetChange} />
        )

      const factorText =
        i % 2 === 0 ? (
          <Override initialValue={param.factor} currentValue={option.factor} />
        ) : (
          <Override initialValue={param.offset} currentValue={option.offset} />
        )

      return (
        <tr key={i}>
          <td>{name}</td>
          <td style={{ width: '25%' }}>{editing ? factorInput : factorText}</td>
          <td style={{ width: '35%' }}>{editing ? nameInput : <span>{option.label}</span>}</td>
        </tr>
      )
    })

    return (
      <table className="nsi-table single-color two-level">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="nsi.parameters.device" />
            </th>
            <th>
              <FormattedMessage id="nsi.parameters.factor" /> / <FormattedMessage id="nsi.parameters.offset" />
            </th>
            <th>
              <FormattedMessage id="nsi.parameters.system" />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }
}

interface Props {
  intl: IntlShape
  options: { value: string; label: string }[]
  phasors: Phasor[]
  editing: boolean
  deviceParamsMapping: DeviceParamsMapping
  parametersUsage: Map<string, number>
  onChange: (deviceParameter: string, updates: Partial<ParamMapping>) => void
  onRemove: (deviceParameter: string) => void
}

export default injectIntl(Phasors)
