import { atom } from 'recoil'
import { Equipment } from 'au-nsi/equipment'

interface IConnectDevice {
  id: Equipment['id']
  address: Equipment['address']
  name: string
  notes: string
}

// Стейт с массивом провернных устройств на подключение
const stateConnectDevice = atom<IConnectDevice[]>({
  key: 'maps:connectingDevices',
  default: [],
})

export default stateConnectDevice
