import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import { AnyCatalogWithInstances } from '../../../../../Catalogs/catalogs.types'
import { useEffect } from 'react'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import LogbookEntryForm from '../../../../../MapTopology/DeviceScreen/DeviceLogbook/DeviceLogbookModal/LogbookEntryForm'
import { actions as documentsActions } from '../../../../../Libraries/Documents/docs.reducers'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import useInstanceFormCallback from '../../../../../Catalogs/components/InstanceForm/useInstanceFormCallback'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import { ILogbookEntry } from 'au-nsi/logbook'
import { EquipmentLogbookRequest } from 'au-nsi/moderation'

export interface ILogbookRequestFormProps {
  editing: boolean
  logbookEntry: ILogbookEntry
}

const LogbookRequestForm = ({ editing, logbookEntry }: ILogbookRequestFormProps) => {
  const [request, setRequest] = useRecoilState<EquipmentLogbookRequest>(selectedDraftRequestState as any)

  const isApproved = request.moderated_at && !request.rejected
  const currentId = request.request_body?.id ?? extractIntIdFromUrl(request.url)
  const dispatch = useDispatch()

  const logbook = useSelector(
    (state: ReduxState) =>
      state.catalogs.catalogs.find((c) => c.id === request.request_body?.schema_id) as AnyCatalogWithInstances
  )

  const handlePropertiesChange = useInstanceFormCallback({
    setter: setRequest,
    propertiesKey: 'request_body.values',
    deps: [],
  })

  useEffect(() => {
    if (request.request_body?.files?.length > 0 && !isApproved) {
      dispatch(documentsActions.addDocuments(request.request_body.files))
    }

    return () => {
      dispatch(documentsActions.cleanDocuments())
    }
  }, [request, currentId])

  const handleChange = (value: any, name: string) => {
    setRequest((prev) => ({ ...prev, request_body: { ...prev.request_body, [name]: value } }))
  }

  const empty = <span>-</span>

  const renderPrev = () => {
    return (
      <LogbookEntryForm
        onPropertiesChange={null}
        formMode={'view'}
        logbook={logbook}
        logbookEntry={logbookEntry}
        onChange={undefined}
        renderButtons={false}
      />
    )
  }

  const renderUpdated = () => {
    return (
      <LogbookEntryForm
        loadDocuments={false}
        onPropertiesChange={handlePropertiesChange}
        formMode={editing ? 'edit' : 'view'}
        logbook={logbook}
        logbookEntry={request.request_body}
        onChange={handleChange}
        loading={false}
        renderButtons={false}
      />
    )
  }

  const before = { id: 'user.request.before', render: logbookEntry ? renderPrev : () => empty }
  const after = { id: 'user.request.after', render: request.request_body ? renderUpdated : () => empty }

  if (!logbook) return <span>404</span>
  if (request.method === 'POST') return renderUpdated()

  return <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />
}

export default LogbookRequestForm
