import styles from '../../maptopology.module.css'
import panelStyles from '../../MapLeftPanel/mapLeftPanel.module.css'
import { memo } from 'react'
import { getDeviceStateDescription } from '../../MapLeftPanel/mapPanel.utils'
import useGroupEquipment from '../hooks/useGroupEquipment'
import { IGroup } from 'au-nsi-elevator/groups'

interface IProps {
  group: IGroup
  onCLick: (groupId: IGroup['id']) => void
}

const GroupTile = (props: IProps) => {
  const groupEquipment = useGroupEquipment(props.group.id)

  const renderEquipmentState = () => {
    const descriptions = groupEquipment.map(getDeviceStateDescription)

    return descriptions.map((d, index) => (
      <span
        key={d.title}
        className={panelStyles.equipmentStateIcon}
        title={groupEquipment[index].name}
        style={{ background: d.color }}
      />
    ))
  }

  return (
    <div className={`${styles.deviceTile} ${panelStyles.hover}`} onClick={() => props.onCLick(props.group.id)}>
      <div className={styles.deviceTileContent}>
        <div className={styles.deviceTileUserInfo}>
          <h2 className={styles.deviceTileName + ' line_clamp'} title={props.group.name}>
            {props.group.name}
          </h2>
          <span className={styles.deviceTileDescription}>{props.group.description}</span>
        </div>
        <div className={styles.deviceTileSystemInfo} style={{ overflow: 'hidden' }}>
          <h2 className={`${styles.deviceTileName} ${panelStyles.equipmentState}`}>{renderEquipmentState()}</h2>
        </div>
      </div>
    </div>
  )
}

export default memo(GroupTile)
