import useDeviceWidth from '../../hooks/useDeviceWidth'
import { useEffect } from 'react'
import Links from '../TopMenu/Nav/Links'
import AppRoutes from '../TopMenu/Nav/Routes'
import { catalogsRoutes } from './catalogs.routes'
import { useDispatch, useSelector } from 'react-redux'
import rolesActions from '../Collections/Roles/roles.actions'
import { organizationsActions } from './Organizations/organizations.actions'
import { ReduxState } from '../../redux/store.types'

const CatalogsPage = () => {
  const dispatch = useDispatch()
  const { isDesktop } = useDeviceWidth()
  const user = useSelector((state: ReduxState) => state.user)

  const className = isDesktop ? 'nsi' : 'nsi nsi-journal'

  useEffect(() => {
    if (user.access_rights['catalogs:get']) {
      dispatch(organizationsActions.loadOrganizations())
    }
    dispatch(rolesActions.loadItems())
  }, [user])

  return (
    <>
      {isDesktop && (
        <div className="top-menu is-submenu">
          <Links routes={catalogsRoutes} />
        </div>
      )}

      <div className={className}>
        <AppRoutes routes={catalogsRoutes} fallback="" />
      </div>
    </>
  )
}

export default CatalogsPage
