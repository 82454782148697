import { ITag } from 'au-nsi/shared'
import { ReactComponent as EditIcon } from 'icons/pen.svg'
import { ReactComponent as RemoveIcon } from 'icons/trash.svg'
import { useState } from 'react'
import TagEditRow from './TagEditRow'
import css from './tags.module.css'
import * as api from './tags.api'
import { handleHttpError } from '../../../utils/http'
import { useDispatch } from 'react-redux'
import { tagsActions } from './tags.state'
import TagRemoveRow from './TagRemoveRow'

interface Props {
  tag: ITag
  onClick: (id: number) => void
}

/**
 * Строка отображающая название тэга с возможностью его выбрать,
 * отредактировать или удалить
 */
const TagRow = ({ tag, onClick }: Props) => {
  const dispatch = useDispatch()
  const [mode, setMode] = useState<'view' | 'edit' | 'remove'>('view')

  const handleCancel = () => setMode('view')

  if (mode === 'edit') {
    const handleSave = (updates: ITag) => {
      if (updates.name === tag.name) return handleCancel()

      api.updateTag(tag.id, updates).catch(handleHttpError)
      dispatch(tagsActions.tagUpdated(updates))
      handleCancel()
    }

    return <TagEditRow tag={tag} onCancel={handleCancel} onSave={handleSave} />
  }

  if (mode === 'remove') {
    const handleConfirm = () => {
      api.deleteTag(tag.id).catch(handleHttpError)
      dispatch(tagsActions.tagDeleted(tag.id))
      handleCancel()
    }

    return <TagRemoveRow name={tag.name} onCancel={handleCancel} onRemove={handleConfirm} />
  }

  const handleEdit = (e) => {
    e.stopPropagation()
    setMode('edit')
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    setMode('remove')
  }

  return (
    <div className={css.tagRow} onClick={() => onClick(tag.id)}>
      <span>{tag.name}</span>
      <EditIcon width={18} className="svg-icon" style={{ marginLeft: 'auto' }} onClick={handleEdit} />
      <RemoveIcon width={14} className="svg-icon" style={{ marginLeft: '0.5em' }} onClick={handleRemove} />
    </div>
  )
}

export default TagRow
