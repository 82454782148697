import { isJsonEqual } from '../../../../utils/misc'
import { IDocument } from 'au-nsi/documents'

export const findDocumentsDiff = (
  before: IDocument[],
  after: IDocument[]
): { removedIds: IDocument['id'][]; createdFiles: IDocument[]; updatedFiles: IDocument[] } => {
  const afterMap = after.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const prev_ids = new Set(before.map((f) => f.id))
  const updated_ids = new Set(after.map((f) => f.id))

  const removedIds = before.filter((f) => !updated_ids.has(f.id)).map((f) => f.id)
  const createdFiles = after.filter((f) => !prev_ids.has(f.id))

  const updatedFiles = []
  for (const doc of before) {
    if (afterMap[doc.id] && !isJsonEqual(doc.access, afterMap[doc.id].access)) updatedFiles.push(afterMap[doc.id])
  }

  return { removedIds, createdFiles, updatedFiles }
}

export const getNextGroupPath = (path: string) => {
  const split = path.split('.')
  split[split.length - 1] = (parseInt(split[split.length - 1]) + 1).toString()
  return split.join('.')
}
