import { useEffect, useRef, useState } from 'react'
import DevicesList from '../../components/DevicesList'
import SearchBlock from './SearchBlock'
import HideableLeftMenu from '../../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import { Equipment } from 'au-nsi/equipment'
import { IPlace } from 'au-nsi/shared'

interface IProps {
  devices: Equipment[]
  selectedId: string
  onDeviceSelect: (id: Equipment['id']) => void
  onPlaceSelect: (place: IPlace) => void
  selectedPlace: IPlace
  statusFilter: Record<Equipment['state'], boolean>
  onStatusFilterChange: (settings: Record<Equipment['state'], boolean>) => void
  filterByViewport: boolean
  onFilterByViewportChange: (filter: boolean) => void
  searchPattern?: string
}

/**
  LeftCol карты устройств
  Рендерит блок поиска, список устройств и фильтры отображения устройств
*/
const DevicesPanel = ({
  selectedPlace,
  onDeviceSelect,
  onPlaceSelect,
  onStatusFilterChange,
  statusFilter,
  devices,
  selectedId,
  searchPattern,
  filterByViewport,
  onFilterByViewportChange,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>()

  const [listHeight, setListHeight] = useState(0)

  // Считаем высоту списка устройств в зависимости от размеров родительского блока
  useEffect(() => {
    if (!containerRef.current) return

    const callback = () => {
      if (!containerRef.current) return

      const cs = getComputedStyle(containerRef.current)
      // calc(var(--input-height) + 2em)
      const searchHt = (parseInt(cs.getPropertyValue('--input-height')) || 40) + 2 * parseInt(cs['font-size'])
      const ht = containerRef?.current?.getBoundingClientRect()?.height - searchHt
      setListHeight(ht || listHeight)
    }

    const observer = new ResizeObserver(callback)
    observer.observe(containerRef.current)

    callback()
    return () => observer.disconnect()
  }, [containerRef.current])

  return (
    <HideableLeftMenu ref={containerRef}>
      <SearchBlock
        selectedPlace={selectedPlace}
        onPlaceSelect={onPlaceSelect}
        filterByViewPort={filterByViewport}
        onFilterByViewPortChange={onFilterByViewportChange}
        onStatusFilterChange={onStatusFilterChange}
        statusFilters={statusFilter}
      />
      <DevicesList
        devices={devices}
        selectedId={selectedId}
        searchPattern={searchPattern}
        onDeviceSelect={onDeviceSelect}
        listHeight={listHeight}
      />
    </HideableLeftMenu>
  )
}

export default DevicesPanel
