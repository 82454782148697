import { Select } from '@alterouniversal/au-react-components'
import { Lang } from 'au-nsi/shared'
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { languages } from '../../../shared/constants'
import { selectLanguage } from '../../App/app.selectors'
import { setLanguage } from '../../App/user.actions'

const LANGS = [
  { label: 'English', value: 'en' },
  { label: 'Русский', value: 'ru' },
]

const options = LANGS.filter((o) => languages.includes(o.value))

const LanguageForm = () => {
  const locale = useSelector(selectLanguage)
  const dispatch = useDispatch()

  const handleSelect = useCallback((lang: Lang) => dispatch(setLanguage(lang)), [])

  return (
    <>
      <h2 className="system__title" style={{ marginTop: '45px', marginBottom: '20px' }}>
        <FormattedMessage id="nav.system.language" />
      </h2>
      <div style={{ maxWidth: '270px' }}>
        <Select name="lang" options={options} value={locale} onChange={handleSelect} />
      </div>
    </>
  )
}

export default LanguageForm
