import { IChartSettings, IChartSettings2 } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../pages/App/app.selectors'
import { selectDataIntervalsMap } from '../../pages/Nsi/nsi.selectors'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import * as selectors from '../../pages/Parameters/params.selectors'
import Clock from '../../services/clock/clock.service'
import DataService from '../../services/data/data.service'
import { IDataIntervals, IDataSelector } from '../../services/data/data.types'
import Chart from './Chart'
import { ChartControls } from './chart.interfaces'
import { ChartScale } from './utils/scale.utils'
import { convertTo2 } from './utils/version.utils'

/**
 * Компонент настраивающий DataService и передающий его в Chart компонент
 */
const ChartWrapper = (props: Props) => {
  const settings = convertTo2(props.settings)

  // отдельный сервис на каждую ось графика
  const services = React.useMemo(() => {
    return settings.axes.map((_, i) => {
      // для каждого сервиса нужен уникальный id (uint32), обычно он берется равным id графика
      // (serial генерируемый базой), но в данном случае для одного графика нужно несколько сервисов,
      // поэтому для дополнительных осей берем значения ближе к верхней границе uint32, которых
      // автоинкрементируемый id скорее всего никогда не достигнет
      const id = i === 0 ? props.id : Math.pow(2, 31) - 10 * props.id - i
      return new DataService(id, { clock: props.clock })
    })
  }, [settings.axes.length])

  const dataIntervals = useSelector(selectDataIntervalsMap)
  const integerParameters = useSelector(selectors.selectIntegerParameters)
  const irregularParameters = useSelector(selectors.selectIrregularParameters)
  const lang = useSelector(selectLanguage)

  // освобождение ресурсов неиспользуемых сервисов
  React.useEffect(() => {
    return () => services.forEach((s) => s.destroy())
  }, [services])

  // передача информации о data_rate в сервисы
  React.useEffect(() => {
    services.forEach((s) => s.setDataIntervals(props.dataIntervals || dataIntervals))
  }, [dataIntervals, services])

  // передача информации о целочисленных параметрах
  React.useEffect(() => {
    services.forEach((s) => {
      s.setIrregularParameters(irregularParameters)
      s.integerParameters = integerParameters
    })
  }, [integerParameters, irregularParameters, services])

  // выставление настроек отображения
  React.useEffect(() => {
    const predictionTime = settings.prediction ? settings.predictionTime : 0

    for (let i = 0; i < services.length; i++) {
      const service = services[i]
      const axis = settings.axes[i]

      service.setStacked(axis.stack)
      service.setAggregationWindow(axis.aggregationWindow)
      service.setPredictionTime(predictionTime)
    }
  }, [settings])

  // фильтрация параметров скрытых пользователем через легенду графика
  const axes = React.useMemo(() => {
    let index = -1

    return settings.axes.map((axis) => {
      const lines = axis.lines.filter(() => {
        index += 1
        return !props.linesHidden.has(index)
      })

      return { ...axis, lines }
    })
  }, [settings.axes, props.linesHidden])

  // задание устройств и параметров, данные по которым необходимы на графике
  React.useEffect(() => {
    for (let i = 0; i < services.length; i++) {
      const service = services[i]
      const axis = settings.axes[i]

      const selectors: IDataSelector[] = []

      for (const line of axis.lines) {
        const selector = selectors.find((s) => s.device_id === line.device_id)

        if (selector) {
          selector.parameters.push(line.parameter_id)
        } else {
          selectors.push({ device_id: line.device_id, parameters: [line.parameter_id] })
        }
      }

      service.setDataSelectors(selectors)
    }
  }, [settings, axes, props.linesHidden])

  // В качестве ключа используется services.length для того чтобы при изменении количества осей был создан
  // новый инстанс компонента. В этом случае сам компонент Chart может считать что количество осей всегда
  // постоянно и равно количеству передаваемых сервисов (services) и множителей (scales)
  return <Chart key={services.length} {...props} settings={{ ...settings, axes }} services={services} lang={lang} />
}

interface Props {
  clock?: Clock
  dataIntervals?: Map<string, IDataIntervals>
  controls: ChartControls
  id: number
  lastResize: number
  legendHeight: number
  lineMode: number
  linesHidden: Set<number>
  onAutoScale: (scale: ChartScale, index: number) => void
  parameters: Map<string, ParameterDn>
  scales: ChartScale[]
  settings: IChartSettings | IChartSettings2
}

export default ChartWrapper
