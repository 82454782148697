/**
 * Показать пользователю уведомление с ошибкой
 */
export const showError = (id: string, content?: string) => {
  const event = new CustomEvent('app-notification', { detail: { type: 'error', id, content } })
  window.document.dispatchEvent(event)
}

/**
 * Показать пользователю уведомление с предупреждением
 */
export const showWarning = (id: string, content?: string) => {
  const event = new CustomEvent('app-notification', { detail: { type: 'warn', id, content } })
  window.document.dispatchEvent(event)
}

/**
 * Показать пользователю уведомление с информацией
 */
export const showInfo = (id: string, content?: string) => {
  const event = new CustomEvent('app-notification', { detail: { type: 'info', id, content } })
  window.document.dispatchEvent(event)
}

/**
 * Очистить список уведомлений
 */
export const clearNotifications = () => {
  const event = new CustomEvent('app-notification-clear')
  window.document.dispatchEvent(event)
}
