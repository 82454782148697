import { AnyCatalogWithInstances } from '../../catalogs.types'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import TextInput from '../../../../shared/Inputs/TextInput'
import Datepicker from '../../../../shared/Inputs/Datepicker/Datepicker'
import { getBooleanOptions, validateInstanceValue } from '../../catalogs.utils'
import { useIntl } from 'react-intl'
import { memo, useEffect, useState } from 'react'
import TextareaInput from '../../../../shared/Inputs/TextareaInput'
import styles from '../../catalogs.module.css'
import { useDispatch } from 'react-redux'
import userTasksActions from '../../../UserTasks/userTasks.actions'
import { generateCreateCatalogItemTask, generateCreateOrganizationTask } from '../../../UserTasks/userTasks.creators'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { useAppSelector } from '../../../../redux/store'
import { selectAccessRights } from '../../../App/app.selectors'
import userTaskEvents from '../../../UserTasks/userTasks.events'
import { Select, SelectOption } from '@alterouniversal/au-react-components'

interface IProps {
  value: any
  rowSchema: IPropertyDescription
  editing: boolean
  onChange: (schemaId: string, updatedValue: any) => void
  catalogs: AnyCatalogWithInstances[]
  /**
   В случае, если tableMode === true, компонент отрендерит строчку в таблице.
   Иначе - вернёт только значение (span с текстом для editing = false и соответствующий инпут для editing = true)
   */
  tableMode?: boolean
  required?: boolean
  // событие нажатия на кнопку "Нет в списке"
  onNotListed?: (catalogId: number) => void
}

const InstanceRow = ({
  value: userValue,
  rowSchema,
  editing,
  onChange,
  onNotListed,
  catalogs,
  tableMode = true,
  required,
}: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const organizationTypes = useAppSelector((state) => state.organizations.organizations)
  const rights = useAppSelector(selectAccessRights)

  const [userTaskID, setUserTaskID] = useState<string>()

  // при клике на "Нет в списке" заполнение формы производится в полностью отдельном окне,
  // которое может быть свернуто, развернуто и сохранено в любой момент времени, но
  // после сохранения необходимо сделать выбранным созданный элемент (AE-924)
  useEffect(() => {
    if (!userTaskID) return

    return userTaskEvents.subscribe(({ type, id, result }) => {
      if (id !== userTaskID) return

      setUserTaskID(null)
      if (type === 'complete') onChange(rowSchema.id, result.id)
    })
  }, [userTaskID])

  const value = validateInstanceValue(userValue, rowSchema)
  const booleanOptions = getBooleanOptions(intl)

  const handleChange = (val: any) => onChange(rowSchema.id, val)

  // Клик на кнопку "Нет в списке"
  const handleNotListed = () => {
    if (onNotListed) onNotListed(rowSchema.ref_catalog_id)

    // повторный клик, открываем уже существующую форму
    if (userTaskID) {
      return dispatch(userTasksActions.activateTask(userTaskID))
    }

    // добавление нового экземпляра справочника
    if (rowSchema.type === 'ref' || rowSchema.type === 'object') {
      const task = generateCreateCatalogItemTask(rowSchema.ref_catalog_id)
      dispatch(userTasksActions.createUserTask(task))
      setUserTaskID(task.id)
    }

    // добавление новой организации
    if (rowSchema.type === 'organization_ref') {
      const task = generateCreateOrganizationTask(rowSchema.ref_catalog_id)
      dispatch(userTasksActions.createUserTask(task))
      setUserTaskID(task.id)
    }
  }

  const ValueEditingCol = () => {
    let options: SelectOption[]

    if (rowSchema.type === 'ref' || rowSchema.type === 'object') {
      options = catalogs
        .find((c) => c.id === rowSchema.ref_catalog_id)
        ?.instances?.map((instance) => ({ label: instance.name, value: instance.id }))
    } else if (rowSchema.type === 'organization_ref') {
      options = organizationTypes
        .find((org) => org.id === rowSchema.ref_catalog_id)
        ?.instances?.map((instance) => ({ label: instance.name, value: instance.id }))
    } else if (rowSchema.type === 'boolean') {
      options = booleanOptions
    }

    options = options ?? []
    const props = { name: rowSchema.id, onChange: handleChange, required: required ?? rowSchema.required }

    switch (rowSchema.type) {
      case 'number':
        return <NumberInput {...props} value={value} fullWidth={true} allowUndefined={true} />
      case 'string':
        return <TextInput {...props} value={value} />
      case 'ref':
      case 'object':
      case 'organization_ref':
        return (
          <div className="inscription-input__block">
            <Select {...props} value={value} options={options} />
            {rights['catalogs:update_items'] && (
              <span onClick={handleNotListed}>{intl.formatMessage({ id: 'catalogs.not_listed' })}</span>
            )}
          </div>
        )
      case 'date':
        return <Datepicker renderTime={false} date={value} onDateChange={handleChange} name={'value'} />
      case 'boolean':
        return <Select {...props} value={value} options={options} />
      case 'text':
        return <TextareaInput {...props} value={value} useWrapper={false} />
    }
  }

  const renderStatic = () => {
    let currentValue: any = value

    if (rowSchema.type === 'ref' || rowSchema.type === 'object') {
      currentValue = catalogs
        .find((c) => c.id === rowSchema.ref_catalog_id)
        ?.instances?.find((inst) => inst.id === value)?.name
    } else if (rowSchema.type === 'boolean') {
      currentValue = booleanOptions.find((o) => o.value === value)?.label ?? '-'
    } else if (rowSchema.type === 'text') {
      currentValue = <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
    } else if (rowSchema.type === 'organization_ref') {
      currentValue = organizationTypes
        .find((org) => org.id === rowSchema.ref_catalog_id)
        ?.instances?.find((instance) => instance.id === value)?.name
    }

    if (!tableMode) return <span>{currentValue ?? '-'}</span>
    return (
      <tr>
        <td className={styles.catalogsTableTd}>{rowSchema.name}</td>
        <td className={styles.catalogsTableTd}>{currentValue ?? '-'}</td>
      </tr>
    )
  }

  const renderEditing = () => {
    if (!tableMode) return ValueEditingCol()

    return (
      <tr>
        <td className={styles.catalogsTableTd}>{rowSchema.name}</td>
        <td className={styles.catalogsTableTd}>{ValueEditingCol()}</td>
      </tr>
    )
  }

  return editing ? renderEditing() : renderStatic()
}

export default memo(InstanceRow)
