import { useIntl } from 'react-intl'

interface Props {
  name: string
  onCancel: () => void
  onRemove: () => void
}

/**
 * Строка подтверждения удаления тэга
 */
const TagRemoveRow = ({ name, onCancel, onRemove }: Props) => {
  const intl = useIntl()

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', height: '36px', padding: '0 8px' }}>
      <span>{name}</span>
      <span onClick={onRemove} style={{ ...style, color: 'var(--danger-text)', marginLeft: 'auto' }}>
        {intl.formatMessage({ id: 'common.delete' })}
      </span>
      <span onClick={onCancel} style={{ ...style, color: 'var(--gray-30)' }}>
        {intl.formatMessage({ id: 'common.cancel' })}
      </span>
    </div>
  )
}

const style = { cursor: 'pointer', whiteSpace: 'nowrap' } as const

export default TagRemoveRow
