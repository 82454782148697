import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReduxState } from '../../../redux/store.types'
import clock from '../../../services/clock/clock.factory'
import { selectEnabledModules, selectLanguage } from '../../App/app.selectors'
import { getIncidentDescription, getIncidentRule } from '../../Incidents/incidents.utils'
import { generateCharts } from '../../Incidents/preview.utils'
import useIncidentSelector from '../../Incidents/useIncidentSelector'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import { rulesMapSelector } from '../../SignalEvents/se.selectors'
import { Dashboard } from './Dashboard'

/**
 * Автоматически сгенерированный экран с графиками по сигналым ситуациям
 */
const DashboardPreview = () => {
  const params = useParams()
  const [components, setComponents] = React.useState<IDashboardComponent[]>([])
  const [dashboard, setDashboard] = React.useState(emptyDashboard)

  const isInitialized = React.useRef(false)

  const controls = useSelector((state: ReduxState) => state.dashboards.controls)
  const modules = useSelector(selectEnabledModules)
  const equipment = useSelector(selectEquipmentMap)
  const seRules = useSelector(rulesMapSelector)
  const lang = useSelector(selectLanguage)

  const id = +params.id
  const incident = useIncidentSelector(id)

  // выставляем время плеера на выбранный инцидент и генерируем линейные графики
  // по параметрам указанным в деталях инцидента
  React.useEffect(() => {
    if (!incident || isInitialized.current === true) return

    const start = incident.ts_start / 1000
    const end = incident.ts_end / 1000 || Date.now()
    const frame = end - start

    clock.setFrame(frame)

    if (incident.ts_end) {
      clock.setReplay()
      clock.setPlayerTime(end)
    }

    const { title } = getIncidentDescription(incident, seRules, lang)
    const formula = getIncidentRule(incident, seRules)

    setDashboard((prev) => ({ ...prev, name: title }))
    setComponents(generateCharts({ incident, equipment, seRules }))

    // выставляем флаг, когда экран полностью сформирован, чтобы больше его не менять
    isInitialized.current = formula !== null && equipment.size > 0
  }, [incident, equipment, seRules])

  // обработчики добавление и изменения компонентов - т.к. экран не настоящий,
  // а сгенерированный, то все изменения будут сохранены только локально и удалятся
  // когда пользователь покинет экран
  const handleCreate = React.useCallback((c: IDashboardComponent) => {
    setComponents((prev) => {
      const id = Math.max(...prev.map((p) => p.id)) + 1
      return [...prev, { ...c, id }]
    })
  }, [])

  const handleDelete = React.useCallback((c: IDashboardComponent) => {
    setComponents((prev) => prev.filter((item) => item.id !== c.id))
  }, [])

  const handleUpdate = React.useCallback((c: IDashboardComponent) => {
    setComponents((prev) => prev.map((item) => (item.id === c.id ? { ...item, ...c } : item)))
  }, [])

  return (
    <Dashboard
      allowEditing={true}
      components={components}
      controls={controls}
      dashboard={dashboard}
      dashboards={[]}
      id="0"
      modules={modules}
      onCreate={handleCreate}
      onDelete={handleDelete}
      onMount={handleMount}
      onPositionUpdate={handleUpdate}
      onSettingsUpdate={handleUpdate}
    />
  )
}

const emptyDashboard: IDashboard = {
  id: '0',
  name: '',
  user_ordering_index: 0,
  access: { restricted: false, users: [], roles: [], orgs: [] },
  folder_id: null,
  folder_name: '',
}

const handleMount = () => {}

export default DashboardPreview
