import { IChartSettings, IChartSettings2 } from 'au-nsi/dashboards'
import { SiPrefix } from 'au-nsi/parameters'
import classnames from 'classnames'
import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { ReactComponent as AreaIcon } from '../../icons/chart-area.svg'
import { ReactComponent as HandIcon } from '../../icons/hand.svg'
import { ReactComponent as ResizeIcon } from '../../icons/resize.svg'
import { ReactComponent as ZoomIcon } from '../../icons/zoom.svg'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import { selectDenormalizedParametersMap } from '../../pages/Parameters/params.selectors'
import { ReduxState } from '../../redux/store.types'
import idb from '../../utils/idb'
import { ChartControls } from './chart.interfaces'
import './chart.styles.css'
import ChartWrapper from './ChartWrapper'
import AggregationModeSelector from './components/AggregationModeSelector'
import ChartLegend from './components/ChartLegend'
import ChartLegendIcon from './components/ChartLegendIcon'
import ModeSelector from './components/ModeSelector'
import ScaleSelector from './components/ScaleSelector'
import { countAxes } from './utils/chart.utils'
import { ChartScale } from './utils/scale.utils'
import { convertTo2 } from './utils/version.utils'

class ChartPanel extends React.Component<Props, State> {
  state: State = {
    controls: { autoscale: true, zoom: false, pan: false, fillArea: false },
    lineMode: 1,
    aggregationMode: null,
    linesHidden: new Set(),
    legendHeight: 0,
    showLegend: false,
    autoScales: [1],
    userScales: [null],
  }

  private defaultControlsState = { autoscale: false, zoom: false, pan: false }

  componentDidMount() {
    this.updateScales()

    idb.get('line_chart:' + this.props.id).then((cache) => {
      if (cache != null) this.setState(cache)
    })
  }

  componentDidUpdate(prevProps: Props) {
    const settingsChanged = prevProps.settings !== this.props.settings
    const parametersChanged = prevProps.parameters !== this.props.parameters

    if (settingsChanged || parametersChanged) this.updateScales()

    // т.к. у линий нет уникального идентификатора и скрытые линии определяются по их индексу,
    // то при смене настроек обнуляем скрытие, потому что индексы могли измениться
    if (settingsChanged) this.setState({ linesHidden: new Set() })
  }

  // сохранение локальных настроек
  private persistState() {
    const { lineMode, showLegend, userScales } = this.state
    idb.set('line_chart:' + this.props.id, { lineMode, showLegend, userScales })
  }

  // выставление количества множителей равным количеству осей графика
  private updateScales() {
    const axes = convertTo2(this.props.settings).axes
    const prevLength = this.state.autoScales.length

    const userScales: ChartScale[] = axes.map(() => null)
    const autoScales: ChartScale[] = axes.map(() => 1)

    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]
      const parameter_id = axis.lines[0]?.parameter_id
      const parameter = this.props.parameters.get(parameter_id)

      if (parameter && parameter.unit && !parameter.unit.prefixable) {
        userScales[i] = 1
        continue
      }

      if (i < prevLength) {
        userScales[i] = this.state.userScales[i]
        autoScales[i] = this.state.autoScales[i]
      }
    }

    this.setState({ userScales, autoScales })
  }

  // учет того, что на график могут выводиться параметры, приходящие не в базовых единицах
  // (кГц вместо Гц например), из-за чего необходимо применять поправку на множитель
  private getAxisNormalizationFactor(index: number) {
    const axes = convertTo2(this.props.settings).axes
    const parameter_id = axes[index].lines[0].parameter_id
    const parameter = this.props.parameters.get(parameter_id)

    return parameter && parameter.prefix ? 10 ** parameter.prefix.exponent : 1
  }

  private toggleAutoscale = () => this.toggleControl('autoscale')
  private toggleZoom = () => this.toggleControl('zoom')
  private togglePan = () => this.toggleControl('pan')

  private toggleControl(name: string) {
    this.setState((prev) => {
      return { controls: { ...prev.controls, ...this.defaultControlsState, [name]: !prev.controls[name] } }
    })
  }

  private toggleFillArea = () => {
    this.setState((prev) => {
      return { controls: { ...prev.controls, fillArea: !prev.controls.fillArea } }
    })
  }

  private toggleLegend = () => {
    this.setState({ showLegend: !this.state.showLegend }, () => this.persistState())
  }

  private setAutoScale = (scale: ChartScale, index: number) => {
    const { autoScales, userScales } = this.state

    if (scale !== autoScales[index] && userScales[index] == null) {
      const updates = [...autoScales]
      updates[index] = scale
      this.setState({ autoScales: updates })
    }
  }

  private setUserScale = (scale: ChartScale, index: number) => {
    const userScales = [...this.state.userScales]
    const factor = this.getAxisNormalizationFactor(index)

    userScales[index] = (scale / factor) as ChartScale
    this.setState({ userScales }, () => this.persistState())
  }

  private setLineMode = (lineMode) => {
    this.setState({ lineMode }, () => this.persistState())
  }

  private setLinesHidden = (linesHidden: Set<number>) => this.setState({ linesHidden })
  private setLegendHeight = (legendHeight: number) => this.setState({ legendHeight })

  private setAggregationMode = (aggregationMode: number) => this.setState({ aggregationMode })

  private renderControls() {
    const { intl } = this.props
    const { autoscale, zoom, pan, fillArea } = this.state.controls

    const autoscaleClass = classnames('line-chart__icon', { active: autoscale })
    const zoomClass = classnames('line-chart__icon', { active: zoom })
    const panClass = classnames('line-chart__icon', { active: pan })
    const areaClass = classnames('line-chart__icon', { active: fillArea })
    const legendClass = classnames('line-chart__icon', { active: this.state.showLegend })

    const autoscaleTitle = intl.formatMessage({ id: 'dashboards.linear_chart.autoscale' })
    const zoomTitle = intl.formatMessage({ id: 'dashboards.linear_chart.zoom' })
    const panTitle = intl.formatMessage({ id: 'dashboards.linear_chart.pan' })
    const areaTitle = intl.formatMessage({ id: 'dashboards.linear_chart.area' })
    const modeTitle = intl.formatMessage({ id: 'dashboards.linear_chart.lineMode' })
    const legendTitle = intl.formatMessage({ id: 'dashboards.linear_chart.legend' })

    return (
      <React.Fragment>
        <ResizeIcon
          className={autoscaleClass}
          style={{ marginLeft: 'auto' }}
          onClick={this.toggleAutoscale}
          title={autoscaleTitle}
        />
        <ZoomIcon className={zoomClass} onClick={this.toggleZoom} title={zoomTitle} />
        <HandIcon className={panClass} onClick={this.togglePan} title={panTitle} />
        <ChartLegendIcon className={legendClass} title={legendTitle} onClick={this.toggleLegend} />
        <ModeSelector lineMode={this.state.lineMode} onChange={this.setLineMode} title={modeTitle} />
        <AggregationModeSelector
          intl={this.props.intl}
          onChange={this.setAggregationMode}
          value={this.state.aggregationMode ?? this.props.settings.aggregationMode}
        />
        <AreaIcon className={areaClass} onClick={this.toggleFillArea} title={areaTitle} />
      </React.Fragment>
    )
  }

  // компонент для отображения единиц измерений и выбора множителя для каждой из осей
  private renderScaleSelectors() {
    const axes = convertTo2(this.props.settings).axes
    const components: JSX.Element[] = []
    const counts = { left: 0, right: 0 }

    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]
      if (!axis.lines.length) continue

      const parameter_id = axis.lines[0].parameter_id
      const parameter = this.props.parameters.get(parameter_id)

      const factor = this.getAxisNormalizationFactor(i)
      const autoScale = this.state.autoScales[i] * factor
      const userScale = this.state.userScales[i] * factor

      counts[axis.position] += 1
      const left = axis.position === 'left' ? 50 * counts.left : undefined
      const right = axis.position === 'right' ? 50 * counts.right : undefined

      components.push(
        <ScaleSelector
          autoScale={autoScale as ChartScale}
          index={i}
          intl={this.props.intl}
          key={i}
          left={left}
          onChange={this.setUserScale}
          prefixes={this.props.prefixes}
          right={right}
          unit={parameter?.unit}
          userScale={userScale as ChartScale}
        />
      )
    }

    return components
  }

  render() {
    let settings = convertTo2(this.props.settings)
    const { title, mirrorTitle } = settings

    // возможность изменить отображение в режиме аггрегации - по умолчанию используется значение указанное
    // в форме настроек, но пользователь может изменить его через панель иконок (изменения в этом случае
    // сохранятся только локально, а не в НСИ)
    const { aggregationMode } = this.state
    settings = aggregationMode != null ? { ...settings, aggregationMode } : settings

    const axes = countAxes(settings)
    const left = 25 + 50 * axes.left
    const right = 25 + 50 * axes.right

    const scales = this.state.userScales.map((scale, i) => scale || this.state.autoScales[i])

    return (
      <div className="line-charts__panel">
        <div className="line-chart__header" style={{ left, right }}>
          <span title={title} className="line-charts__panel-title line_clamp">
            {title}
          </span>

          {this.renderControls()}
        </div>

        <ChartWrapper
          controls={this.state.controls}
          id={this.props.id}
          lastResize={this.props.lastResize}
          legendHeight={this.state.legendHeight}
          lineMode={this.state.lineMode}
          linesHidden={this.state.linesHidden}
          onAutoScale={this.setAutoScale}
          parameters={this.props.parameters}
          scales={scales}
          settings={settings}
        />

        {settings.mirrorY && mirrorTitle && (
          <div className="line-chart__mirror-header" style={{ bottom: 35 + this.state.legendHeight, left }}>
            <span className="line-charts__panel-title">{mirrorTitle}</span>
          </div>
        )}

        {this.renderScaleSelectors()}

        {this.state.showLegend && (
          <ChartLegend
            lastResize={this.props.lastResize}
            settings={this.props.settings}
            linesHidden={this.state.linesHidden}
            onHeightChange={this.setLegendHeight}
            onToggle={this.setLinesHidden}
          />
        )}
      </div>
    )
  }
}

interface Props {
  id: number
  intl: IntlShape
  lastResize: number
  parameters: Map<string, ParameterDn>
  prefixes: { [id: string]: SiPrefix }
  settings: IChartSettings | IChartSettings2
}

interface State {
  controls: ChartControls
  lineMode: number
  aggregationMode: number
  linesHidden: Set<number>
  legendHeight: number
  showLegend: boolean
  autoScales: ChartScale[]
  userScales: ChartScale[]
}

const mapStateToProps = (state: ReduxState) => {
  const parameters = selectDenormalizedParametersMap(state)
  const prefixes = state.parameters.prefixes
  return { parameters, prefixes }
}

export default connect(mapStateToProps)(injectIntl(ChartPanel))
