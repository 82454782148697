import React from 'react'
import { useIntl, IntlShape } from 'react-intl'
import FormPanel from '../../../shared/FormPanel/FormPanel'
import RelativeTime from '../../../shared/Utils/RelativeTime'
import TableHeader from '../../../shared/Utils/TableHeader'
import http, { handleHttpError } from '../../../utils/http'
import { formatDate } from '../../../utils/lang'
import TableLoader from '../About/TableLoader'

const RebootJournal = () => {
  const [panels, setPanels] = React.useState({ uptime: true, events: true })
  const [state, setState] = React.useState<State>({ isLoading: true, uptime: [], events: [] })
  const intl = useIntl()

  React.useEffect(() => {
    http
      .get('/nsi/v1/system/reboot')
      .then((r) => setState({ ...r.data, isLoading: false }))
      .catch((err) => {
        handleHttpError(err)
        setState({ ...state, isLoading: false })
      })
  }, [])

  const handleToggle = (id: string) => {
    setPanels({ ...panels, [id]: !panels[id] })
  }

  const uptime = state.uptime.map((row) => {
    return (
      <tr key={row.host}>
        <td>{row.host}</td>
        <td>
          <RelativeTime ts={Date.now() - row.uptime * 1000} />
        </td>
      </tr>
    )
  })

  const events = state.events.map((row) => {
    return (
      <tr key={row.id}>
        <td>{formatDate(row.ts, 'd LLL HH:mm:ss')}</td>
        <td>{row.host}</td>
        <td>{row.message}</td>
      </tr>
    )
  })

  const timeTrans = intl.formatMessage({ id: 'system.logs.time' })
  const messageTrans = intl.formatMessage({ id: 'system.logs.message' })

  return (
    <div className="nsi-main__container">
      <FormPanel id="uptime" title="system.reboot.uptime" open={panels.uptime} onToggle={handleToggle}>
        <table className="nsi-table system-table">
          <thead>
            <TableHeader titles={['Hostname', timeTrans]} />
          </thead>
          <tbody>
            {uptime}
            {state.isLoading && <TableLoader columns={2} />}
            {!state.isLoading && state.uptime.length === 0 && renderEmptyMessage(2, intl)}
          </tbody>
        </table>
      </FormPanel>

      <FormPanel id="events" title="system.reboot.events" open={panels.events} onToggle={handleToggle}>
        <table className="nsi-table system-table">
          <thead>
            <TableHeader titles={[timeTrans, 'Hostname', messageTrans]} />
          </thead>
          <tbody>
            {events}
            {state.isLoading && <TableLoader columns={3} />}
            {!state.isLoading && state.events.length === 0 && renderEmptyMessage(3, intl)}
          </tbody>
        </table>
      </FormPanel>
    </div>
  )
}

const renderEmptyMessage = (columns: number, intl: IntlShape) => {
  return (
    <tr>
      <td colSpan={columns} style={{ textAlign: 'center', color: 'var(--gray-30)', fontStyle: 'italic' }}>
        {intl.formatMessage({ id: 'common.no_results' })}
      </td>
    </tr>
  )
}

interface State {
  isLoading: boolean
  uptime: Uptime[]
  events: RebootEvent[]
}

interface Uptime {
  host: string
  uptime: number
}

interface RebootEvent {
  id: string
  ts: number
  host: string
  message: string
}

export default RebootJournal
