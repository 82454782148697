import { Toggle as ToggleInput } from '@alterouniversal/au-react-components'
import React from 'react'

type ToggleProps = React.ComponentProps<typeof ToggleInput>

const Toggle = (props: ToggleProps) => {
  const style = props.style || {}
  return <ToggleInput {...props} style={{ margin: '0 16px', ...style }} />
}

export default Toggle
