import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Equipment } from 'au-nsi/equipment'

export type ChartMode = 'pq' | 'trends'

export interface PQState {
  search: string
  chartMode: ChartMode
  showAsGrid: boolean
  trends: {
    frequency: boolean
    activePower: boolean
    reactivePower: boolean
    voltageMagnitude: boolean
    voltageAngle: boolean
    currentMagnitude: boolean
    dfrequency: boolean
    currentAngle: boolean
  }
  hiddenDevices: { [id: string]: boolean }
}

const initialState: PQState = {
  search: '',
  chartMode: 'pq',
  showAsGrid: true,
  trends: {
    frequency: true,
    dfrequency: true,
    activePower: true,
    reactivePower: true,
    voltageMagnitude: true,
    voltageAngle: true,
    currentMagnitude: true,
    currentAngle: true,
  },
  hiddenDevices: {}, // устройства, которые не будут отображаться на PQ диаграмме и графиках
}

const slice = createSlice({
  name: 'app/pq',
  initialState,
  reducers: {
    SET_STATE: (state, action: PayloadAction<PQState>) => {
      Object.assign(state, action.payload)
      state.search = ''
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setChartMode: (state, action: PayloadAction<ChartMode>) => {
      state.chartMode = action.payload
    },
    setTrends: (state, action) => {
      state.trends = action.payload
    },
    toggleTrend: (state, action: PayloadAction<string>) => {
      state.trends[action.payload] = !state.trends[action.payload]
    },
    toggleGrid: (state) => {
      state.showAsGrid = !state.showAsGrid
    },
    toggleVisibility: (state, action: PayloadAction<string>) => {
      const status = state.hiddenDevices[action.payload] ? undefined : true
      state.hiddenDevices[action.payload] = status
    },
    resetVisibility: (state, action: PayloadAction<Equipment[]>) => {
      const hiddenCount = Object.keys(state.hiddenDevices).length

      if (hiddenCount > 0) {
        // сделать все устройства видимыми, если хотя бы одно скрыто
        state.hiddenDevices = {}
      } else {
        // если все видимы, то скрыть все
        for (const e of action.payload) {
          state.hiddenDevices[e.id] = true
        }
      }
    },
  },
})

export default slice.reducer
export const actions = slice.actions
