import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import InlineSelect from '../../../shared/Inputs/InlineSelect'
import { selectTheme } from '../../App/app.selectors'
import { updatePreferences } from '../../App/user.actions'

const options = [
  { value: 'white', label: 'white' },
  { value: 'dark-blue', label: 'dark-blue' },
  { value: 'system', label: 'system' },
]

export type TTheme = 'white' | 'dark-blue' | 'system'

const ColorTheme = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedTheme = useAppSelector(selectTheme)

  const locOptions = useMemo(
    () => options.map((o) => ({ ...o, label: intl.formatMessage({ id: 'nav.system.theme.' + o.value }) })),
    [intl.locale]
  )

  const handleChange = (theme: TTheme) => {
    dispatch(updatePreferences({ theme }))
  }

  return (
    <>
      <h2 className="system__title">
        <FormattedMessage id="nav.system.theme" />
      </h2>
      <InlineSelect value={selectedTheme || 'dark-blue'} options={locOptions} onChange={handleChange} />
    </>
  )
}

export default ColorTheme
