import { DragEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useContextMenu from '../../../../hooks/useContextMenu'
import { ReactComponent as SearchIcon } from '../../../../icons/search.svg'
import { ReduxState } from '../../../../redux/store.types'
import http from '../../../../utils/http'
import CreateFolderModal from './CreateFolderModal'
import DeleteModal from './DeleteModal'
import DocsNavigation from './DocsNavigation'
import Document from './Document'
import UploadFilesModal from './FileModals/UploadFilesModal'
import css from '../docs.module.css'
import { actions } from '../docs.reducers'
import '../intl/docs.intl'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Loader from '../../../../shared/Utils/Loader'
import { fetchDocuments } from '../documents.api'
import { selectAccessRights } from '../../../App/app.selectors'
import ContextMenu from '../../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../../shared/Forms/ContextMenu/ContextMenuOption'
import { ReactComponent as UploadIcon } from 'icons/upload.svg'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import { IDocument } from 'au-nsi/documents'

/**
 * Библиотека документов
 */
const DocumentsGrid = ({ selection, ...props }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()

  const rights = useSelector(selectAccessRights)
  const state = useSelector((state: ReduxState) => state.documents)
  const folder = state.path[state.path.length - 1] // id текущей папки

  const resource = props.resource === 'library' ? 'documents' : props.resource
  const isModerator = rights[resource + ':moderate']

  // загрузка содержимого папки, только если пользователь в нее перешел
  useEffect(() => {
    if (!state.loadedFolders.includes(folder) && !loading) {
      const request = fetchDocuments({
        resource: props.resource,
        resource_item_id: props.resource_item_id,
        parent_id: folder,
      })

      wait(request, (r) => {
        if (!r || r.length === 0) return

        dispatch(actions.setDocuments({ folder, documents: r }))
      })
    }
  }, [folder, state.loadedFolders])

  // из общего списка документов извлекаем содержимое текущей папки
  const documents = useMemo(() => {
    return state.items.filter((d) => d.parent_id === folder && !state.cuttedItems.includes(d.id))
  }, [folder, state.items, state.cuttedItems])

  // сброс выбранных элементов если они перестали быть видимыми (при переходе из другой папки например)
  useEffect(() => {
    if (!documents.length) return

    const shouldResetSelection = selection.some((id) => documents.find((d) => d.id === id) == null)
    if (shouldResetSelection) props.onSelect([])
  }, [state.path])

  const containerRef = useRef<HTMLDivElement>()
  const menuRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()
  const menuInfo = useContextMenu(containerRef, menuRef)

  const [modalState, setModalState] = useState<'create_folder' | 'upload_files' | 'delete'>(null)
  const [files, setFiles] = useState<FileList>() // загружаемые файлы

  const resetSelection = () => selection.length > 0 && props.onSelect([])

  // выбор элемента по клику, нескольких элементов при зажатом CTRL
  const handleSelect = useCallback(
    (id: string, isCtrl: boolean) => {
      if (!isCtrl) return props.onSelect([id])

      const isAlreadySelected = selection.includes(id)
      props.onSelect(isAlreadySelected ? selection.filter((e) => e !== id) : [...selection, id])
    },
    [selection]
  )

  const closeModal = () => setModalState(null)

  const handleCreateFolder = () => {
    setModalState('create_folder')
    menuInfo.setOpen(false)
  }

  const handleUploadClick = () => {
    menuInfo.setOpen(false)
    inputRef.current.click()
  }

  const preventDefault = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  // перетаскивание файлов из файлового менеджера в браузер
  const handleFilesDrop = (e: DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.files.length) {
      preventDefault(e)
      setFiles(e.dataTransfer.files)
    }
  }

  const handleDelete = useCallback(() => setModalState('delete'), [])

  // вырезать выбранные элементы для переноса в другую папку
  const handleCut = () => {
    resetSelection()
    dispatch(actions.setCuttedItems(selection))
  }

  // вставка вырезанных элементов в текущую папку
  const handlePaste = () => {
    menuInfo.setOpen(false)
    props.onSelect(state.cuttedItems)
    dispatch(actions.setCuttedItems([]))

    let url = `/nsi/v1/documents/move`
    if (props.resource) url += '?resource=' + props.resource
    if (props.resource_item_id) url += '&resource_item_id=' + props.resource_item_id
    http.post(url, { destination: folder, ids: state.cuttedItems })
  }

  // перетаскивание элементов в папку
  const handleDrop = useCallback(
    (id: string) => {
      let url = `/nsi/v1/documents/move`
      if (props.resource) url += '?resource=' + props.resource
      if (props.resource_item_id) url += '&resource_item_id=' + props.resource_item_id

      http.post(url, { destination: id, ids: selection })
      props.onSelect([])
    },
    [selection]
  )

  const menu = (
    <ContextMenu open={menuInfo.open && props.allowEditing} ref={menuRef}>
      {isModerator && state.cuttedItems.length > 0 && <ContextMenuOption type={'paste'} onClick={handlePaste} />}
      {isModerator && (
        <div className="nsi-dropdown__item" onClick={handleCreateFolder}>
          <PlusIcon />
          {intl.formatMessage({ id: 'documents.create_folder' })}
        </div>
      )}
      <div className="nsi-dropdown__item" onClick={handleUploadClick}>
        <UploadIcon />
        {intl.formatMessage({ id: 'documents.upload_files' })}
      </div>
    </ContextMenu>
  )

  const renderItems = () =>
    documents.map((doc) => {
      return (
        <Document
          key={doc.id}
          allowEditing={props.allowEditing}
          cuttedItems={state.cuttedItems}
          document={doc}
          onCut={handleCut}
          onDelete={handleDelete}
          onDrop={handleDrop}
          onSelect={handleSelect}
          selection={selection}
          isModerator={isModerator}
        />
      )
    })

  return (
    <div
      ref={containerRef}
      onDrop={handleFilesDrop}
      onDragEnter={preventDefault}
      onDragOver={preventDefault}
      onDragLeave={preventDefault}
      style={{ height: '100%' }}
    >
      <div className={css.header}>
        <DocsNavigation documents={state.items} path={state.path} />

        <div className={css.searchIcon} onClick={props.onSearch} style={{ backgroundColor: 'var(--primary-80)' }}>
          <SearchIcon width={20} height={20} />
        </div>
      </div>

      {loading && (
        <div className="center">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className={css.documentsGrid} style={{ maxHeight: props.maxHeight, overflowY: 'auto' }}>
          {renderItems()}
        </div>
      )}

      <input ref={inputRef} type="file" multiple={true} hidden={true} onChange={(e) => setFiles(e.target.files)} />
      {menu}
      {modalState === 'create_folder' && (
        <CreateFolderModal
          onClose={closeModal}
          parent_id={folder || undefined}
          resource={props.resource}
          resource_item_id={props.resource_item_id}
        />
      )}
      {modalState === 'delete' && (
        <DeleteModal
          onClose={closeModal}
          selection={selection}
          resource={props.resource}
          resource_item_id={props.resource_item_id}
        />
      )}
      {files != null && (
        <UploadFilesModal
          files={files}
          parent_id={folder || undefined}
          onClose={() => setFiles(null)}
          resource={props.resource}
          resource_item_id={props.resource_item_id}
        />
      )}
    </div>
  )
}

interface Props {
  allowEditing: boolean
  selection: string[]
  onSelect: (selection: string[]) => void
  onSearch: () => void
  maxHeight?: string
  resource?: IDocument['resource']
  resource_item_id?: string
}

export default DocumentsGrid
