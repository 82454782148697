import { AnyCatalogWithInstances } from '../../../../../Catalogs/catalogs.types'
import { filterSchemaTree, getSchemaMap } from '../CatalogRequestForm/catalogRequest.diff'
import { AnyObject } from 'common'
import { ICatalogItem, IPropertyDescription } from 'au-nsi/catalogs'

/** Объект, у которого сущесвует ключ/множество ключей K, по которому(ым) лежит объект типа ICatalogItem['properties'] */
type ObjWithProperties<O extends Object, K extends keyof O> = {
  [key in K]: O[key] extends ICatalogItem['properties'] ? ICatalogItem['properties'] : never
} & AnyObject

/**
 * Получить items и catalog, содержащие только поля it1 и it2 с различными значениями
 * */
function findCatalogItemsDiff<
  T extends AnyCatalogWithInstances,
  Item extends AnyObject,
  K extends keyof Item,
  ItemWithProperties extends ObjWithProperties<Item, K>
>(
  it1: ItemWithProperties,
  it2: ItemWithProperties,
  catalog: T,
  propertiesKey: K
): [ItemWithProperties, ItemWithProperties, T] {
  if (!it1 || !it2 || !catalog) return [it1, it2, catalog]

  const catalogSchemaMap = getSchemaMap(catalog.schema)
  const updatedCatalogSchema: IPropertyDescription[] = []

  const updatedProperties1 = {}
  const updatedProperties2 = {}
  const keys = new Set([...Object.keys(it1[propertiesKey]), ...Object.keys(it2[propertiesKey])])

  for (const key of keys) {
    if (it1[propertiesKey][key] !== it2[propertiesKey][key]) {
      updatedProperties1[key] = it1[propertiesKey][key]
      updatedProperties2[key] = it2[propertiesKey][key]

      if (catalogSchemaMap.has(key)) updatedCatalogSchema.push(catalogSchemaMap.get(key))
    }
  }

  const schemaIds = new Set(updatedCatalogSchema.map((p) => p.id))
  return [
    { ...it1, [propertiesKey]: updatedProperties1 },
    { ...it2, [propertiesKey]: updatedProperties2 },
    { ...catalog, schema: updatedCatalogSchema, schema_tree: filterSchemaTree(catalog.schema_tree, schemaIds) },
  ]
}

export default findCatalogItemsDiff
