import { Equipment } from 'au-nsi/equipment'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { ReactComponent as LogbookIcon } from '../../../icons/catalog.svg'
import { ReactComponent as GroupIcon } from '../../../icons/network.svg'
import { ReduxState } from '../../../redux/store.types'
import { useGoogleMapsLoader } from '../../../shared/GoogleMap/useGoogleMapsLoader'
import HideableLeftMenu from '../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import Loader from '../../../shared/Utils/Loader'
import PrevButton from '../../../shared/Utils/PrevButton'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import { loadOrganizations } from '../../Catalogs/Organizations/organizations.actions'
import rolesActions from '../../Collections/Roles/roles.actions'
import usersActions from '../../Collections/Users/users.actions'
import AEPSStorageWidget from '../../Dashboards/AEPSStorageWidget'
import AEPSCommercialWidget from '../../Dashboards/AEPSStorageWidget/AEPSCommercialWidget'
import Dashboard from '../../Dashboards/components/Dashboard'
import DeviceState from '../components/DeviceState'
import GroupsLoader from '../Groups/components/GroupsLoader'
import useDeviceGroup from '../Groups/hooks/useDeviceGroup'
import { recoilPlacePanelState } from '../MapLeftPanel/PlacePanel/PlacePanel'
import { recoilSelectedPlaceState } from '../MapTopology'
import styles from '../maptopology.module.css'
import DeviceLogbook from './DeviceLogbook/DeviceLogbook'
import { checkRouteAccess, deviceScreenRoutes } from './screen.routes'

/**
  Компонент карточки оборудования карты устройств
  Попадают сюда при переходе по ссылкам вида
  /map/device/:id

  Получает id устройства из урла, находит его в стейте и рендерит навигацию. Если такового нет - рендерит 404
  Требует загруженных карт. На время загрузки, если таковое нужно, рендерит лоадер
*/
const DeviceScreen = () => {
  const params = useParams()
  const id = params.deviceId

  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mapsLoaded = useGoogleMapsLoader()

  const rights = useSelector(selectAccessRights)
  const modules = useSelector(selectEnabledModules)
  const logbooks = useSelector((state: ReduxState) => state.catalogs.catalogs.filter((c) => c.type === 'logbook'))
  const device = useSelector((state: ReduxState) => state.nsi.equipment.find((e) => e.id === id))
  const group = useDeviceGroup(device.id)

  const routes = useMemo(() => deviceScreenRoutes(device), [device])

  const [logbooksOpen, setLogbooksOpen] = useState(true)
  const setSelectedGroup = useSetRecoilState(recoilPlacePanelState)
  const setSelectedPlace = useSetRecoilState(recoilSelectedPlaceState)

  useEffect(() => {
    dispatch(loadOrganizations())
    dispatch(rolesActions.loadItems())
    dispatch(usersActions.loadItems())
  }, [])

  if (!device) {
    return (
      <div className={styles.mapTopologyLoading}>
        <h2 style={{ color: 'var(--danger-50)' }}>404 Not Found</h2>
      </div>
    )
  }

  if (!mapsLoaded) {
    return (
      <div className={styles.mapTopologyLoading}>
        <Loader />
      </div>
    )
  }

  const handleGroupClick = () => {
    if (group) {
      setSelectedPlace(group.address)
      setSelectedGroup({ panelMode: 'groups', selectedGroupId: group.id })
      navigate('/map')
    }
  }

  const className = 'system__nav-item'
  const activeClassName = styles.deviceScreenActiveNavLink + ' ' + className

  const renderLinks = () => {
    return routes
      .filter((route) => checkRouteAccess(route, rights, modules))
      .map((route) => (
        <div key={route.id + ':link'}>
          <NavLink
            to={route.path}
            className={({ isActive }) => (isActive ? activeClassName : className)}
            style={{ padding: 0 }}
          >
            <div className={styles.deviceScreenLink}>
              {route.icon}
              <span>{intl.formatMessage({ id: `nav.map.device.${route.id}` })}</span>
            </div>
          </NavLink>
        </div>
      ))
  }

  const renderLogbooksLinks = () => {
    const showLogbooks = modules.has('catalogs') && modules.has('equipment_logbook') && rights['equipment_logbook:get']
    if (!showLogbooks) return null

    const links = logbooks.map((logbook) => (
      <div key={logbook.id}>
        <NavLink
          to={`/map/device/${device?.id}/logbook/${logbook.id}`}
          className={({ isActive }) => (isActive ? activeClassName : className)}
          style={{ padding: 0 }}
        >
          <div className={styles.deviceScreenLink}>
            <LogbookIcon />
            <span>{logbook.name}</span>
          </div>
        </NavLink>
      </div>
    ))

    const iconClass = logbooksOpen ? 'tpl-icon open-icon' : 'tpl-icon closed-icon'
    return (
      <div className={`tpl-branch`}>
        <span className={`tpl-branch__name ${styles.deviceScreenLink}`}>
          <LogbookIcon />
          {intl.formatMessage({ id: 'nav.logbooks' })}
          <span className={iconClass} onClick={() => setLogbooksOpen(!logbooksOpen)} style={{ marginLeft: 'auto' }} />
        </span>
        {logbooksOpen && <div className="tpl-branch">{links}</div>}
      </div>
    )
  }

  const renderRoutes = () => {
    return routes
      .filter((route) => checkRouteAccess(route, rights, modules))
      .map((route) => <Route key={route.id + ':route'} element={route.render()} path={route.path} />)
  }

  return (
    <div className={styles.mapTopology}>
      <HideableLeftMenu>
        <div className={styles.devicePanel} style={{ overflowY: 'auto' }}>
          <div className={styles.addressHeader}>
            <NavLink to={'/map'} style={{ textDecoration: 'none', color: 'currentColor' }}>
              <PrevButton onCLick={() => {}} />
            </NavLink>
            <div className={styles.deviceScreenStatus}>
              <h2>{device.name}</h2>
              <GroupsLoader showLoader={false}>
                {group && (
                  <GroupIcon
                    onClick={handleGroupClick}
                    title={group?.name}
                    className={styles.deviceScreenGroupStatus}
                  />
                )}
              </GroupsLoader>
              <div>
                <DeviceState device={device} />
              </div>
            </div>
            {renderTimezoneInfo(device)}
          </div>
          <div className={styles.deviceScreenLinkList}>
            {renderLinks()}
            {renderLogbooksLinks()}
          </div>
        </div>
      </HideableLeftMenu>
      <div className="nsi-main__wrapper">
        <Routes>
          <Route path="dashboards/aepso" element={renderAepso()} />
          <Route path="dashboards/aepsc" element={renderAepsc()} />
          <Route path="dashboards/:id" element={renderDashboard()} />
          <Route path="logbook/:logbookId" element={<DeviceLogbook device={device} />} />
          {renderRoutes()}
        </Routes>
      </div>
    </div>
  )
}

const renderDashboard = () => (
  <div style={{ margin: '0 -30px' }}>
    <Dashboard />
  </div>
)

const renderAepso = () => (
  <div style={{ margin: '0 -30px' }}>
    <AEPSStorageWidget />
  </div>
)

const renderAepsc = () => (
  <div style={{ margin: '0 -30px' }}>
    <AEPSCommercialWidget />
  </div>
)

// информация о часовом поясе, в котором находится устройство
const renderTimezoneInfo = (device: Equipment) => {
  const { timezone } = device
  if (!timezone || !timezone.id) return null

  const offset = timezone.offset / 60
  const sign = offset < 0 ? '-' : '+'
  const info = `${timezone.id} (GMT${sign}${offset})`

  return <div className="text--gray font--x-small">{info}</div>
}

export default DeviceScreen
