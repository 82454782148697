import { Incident } from 'au-nsi/signal-events'
import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { selectLanguage } from '../App/app.selectors'
import { selectDataSources } from '../Nsi/nsi.selectors'
import { ParameterDn } from '../Parameters/params.interfaces'
import { selectDenormalizedParameters } from '../Parameters/params.selectors'
import { SignalEvent } from '../SignalEvents/se.interfaces'
import { rulesMapSelector } from '../SignalEvents/se.selectors'
import { DenormalizedIncident } from './incident.interfaces'
import * as utils from './incidents.utils'

const usersSelector = (state: ReduxState) => state.users.items
const incidentsSelector = (state: ReduxState) => state.incidents.incidents
const incidentsArchiveSelector = (state: ReduxState) => state.incidents.archive

const mapIdsToNames = (items) => {
  const result = {}

  for (const item of items) {
    result[item.id] = item.name
  }

  return result
}

export const devicesNameSelector = createSelector(selectDataSources, mapIdsToNames)

export const parametersMapSelector = createSelector(selectDenormalizedParameters, (parameters: ParameterDn[]) => {
  const result = new Map<string, ParameterDn>()

  for (const p of parameters) {
    result.set(p.id, p)
  }

  return result
})

export const usersNameSelector = createSelector(usersSelector, mapIdsToNames)

/**
 * Выбор только последних инцидентов по каждой сигнальной ситуации
 */
export const uniqueIncidentsSelector = createSelector(incidentsSelector, rulesMapSelector, (incidents, rules) => {
  const items = new Map()

  for (const item of incidents) {
    // id сигнальной ситуации если инцидент по формуле, либо incident.event_id
    const key = item.type === 'formula' && rules.has(item.formula_id) ? rules.get(item.formula_id).id : item.event_id
    const prev = items.get(key)

    if (prev == null || prev.ts_start < item.ts_start) {
      items.set(key, item)
    }
  }

  const result = [...items.values()]
  result.sort((a, b) => (a.event_id > b.event_id ? 1 : -1))

  return result
})

/*
 * Селекторы активных инцидентов которые будут отображаться в списке уведомлений
 */

const isActive = (incident: Incident) => incident.ts_end === 0 || incident.ack_user == null

export const summarizeActiveIncidents = (incidents: Incident[], rules: Map<number, SignalEvent>) => {
  let count = 0
  let isDanger = false
  let isWarning = false

  for (const incident of incidents) {
    if (isActive(incident)) {
      count += 1

      if (!isDanger) {
        const lvl = utils.getIncidentLevel(incident, rules)
        if (lvl === 'is-danger') isDanger = true
        if (lvl === 'is-warning') isWarning = true
      }
    }
  }

  const level = isDanger ? 'is-danger' : isWarning ? 'is-warning' : ''
  return { count, level }
}

export const activeIncidentsSelector = createSelector(incidentsSelector, (incidents) => incidents.filter(isActive))

export const activeIncidentsSummarySelector = createSelector(
  [incidentsSelector, rulesMapSelector],
  summarizeActiveIncidents
)

/*
 * Селекторы денормализованных инцидентов готовых к отображению в интерфейсе
 */

const denormalize = (
  incidents: Incident[],
  devices: { [id: string]: string },
  users: { [id: string]: string },
  parameters: Map<string, ParameterDn>,
  rules: Map<number, SignalEvent>,
  lang: string
): DenormalizedIncident[] => {
  return incidents.map((incident) => {
    const { title, text } = utils.getIncidentDescription(incident, rules, lang)

    return {
      id: incident.id,
      type: incident.type,
      ts_start: incident.ts_start,
      ts_end: incident.ts_end,
      ack_ts: incident.ack_ts,
      ack_user: users[incident.ack_user],
      details: utils.getIncidentDetails(incident, { devices, parameters, lang }),
      ack_state: utils.getAckState(incident, rules),
      level: utils.getIncidentLevel(incident, rules),
      title,
      text,
    }
  })
}

export const denormalizedIncidentsSelector = createSelector(
  [incidentsSelector, devicesNameSelector, usersNameSelector, parametersMapSelector, rulesMapSelector, selectLanguage],
  denormalize
)

export const activeDenormalizedIncidentsSelector = createSelector(
  [
    activeIncidentsSelector,
    devicesNameSelector,
    usersNameSelector,
    parametersMapSelector,
    rulesMapSelector,
    selectLanguage,
  ],
  denormalize
)

export const archiveDenormalizedIncidentsSelector = createSelector(
  [
    incidentsArchiveSelector,
    devicesNameSelector,
    usersNameSelector,
    parametersMapSelector,
    rulesMapSelector,
    selectLanguage,
  ],
  denormalize
)
