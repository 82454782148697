import { IUser } from 'au-nsi/user'
import useHttpLoader from 'hooks/useHttpLoader'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'
import confirmService from 'shared/Modal/confirm.service'
import Loader from 'shared/Utils/Loader'
import http, { handleHttpError } from 'utils/http'
import usersActions from './users.actions'
import css from './users.module.css'

interface Props {
  user: IUser
}

/**
 * Информация о настройках безопасности пользовательского аккаунта (включено или нет 2FA)
 */
const UserSecurityStatus = ({ user }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const currentUser = useAppSelector((state) => state.user)
  const { loading, wait } = useHttpLoader()

  if (!user.security_settings?.['2fa_method']) return null

  // отключение 2FA у пользователя, доступно только супер-админам
  const handleDisable = () => {
    const title = 'nsi.user.disable_2fa.confirm_warning'
    const options = [{ title: 'nsi.user.disable_2fa', value: 'confirm' }]
    confirmService.requestConfirmation(title, options).then((res) => {
      if (res !== 'confirm') return

      const request = http
        .post(`/nsi/users/${user.id}/reset2FA`)
        .then((r) => dispatch(usersActions.itemUpdated(user.id, r.data)))
        .catch(handleHttpError)

      wait(request)
    })
  }

  return (
    <div className="nsi-cn__status-row">
      <span style={{ color: 'var(--text-success)' }}>{intl.formatMessage({ id: 'nsi.user.2fa_enabled' })}</span>

      {currentUser.is_admin && (
        <span className={css.disable2FA} onClick={handleDisable}>
          {loading ? <Loader /> : intl.formatMessage({ id: 'nsi.user.disable_2fa' })}
        </span>
      )}
    </div>
  )
}

export default UserSecurityStatus
