import { useIntl } from 'react-intl'
import { Equipment } from 'au-nsi/equipment'
import styles from '../maptopology.module.css'
import { getDeviceStateDescription } from '../MapLeftPanel/mapPanel.utils'
import { memo } from 'react'

const DeviceState = ({ device }: Props) => {
  const intl = useIntl()
  const { color, label, title } = getDeviceStateDescription(device)

  return (
    <div className={styles.deviceTileState} style={{ border: `1px solid ${color}` }}>
      <span className="line_clamp" title={title} style={{ color }}>
        {intl.formatMessage({ id: 'nsi.device_state.' + label })}
      </span>
    </div>
  )
}

interface Props {
  device: Equipment
}

export default memo(DeviceState)
