import { TNode } from './tree.types'
import { AnyObject } from 'common'
import { KeyboardEvent, MutableRefObject, useRef, useEffect, FocusEvent } from 'react'
import styles from './tree.module.css'
import nodeIdService from './nodeId.service'

interface IProps<T extends AnyObject> {
  node: TNode<T>
  onKeyDown: (e: KeyboardEvent<HTMLLIElement | HTMLSpanElement>) => void
  onSelect: (e: FocusEvent<HTMLElement>) => void
  onToggle: (id: T['id']) => void
  nodeContentRenderer: (node: { node: T }) => JSX.Element | JSX.Element[] | string | null
  tabIndex: MutableRefObject<number>
  tabIndexMap: Map<number, T['id']>
  openedNodes: Set<TNode<T>['id']>
  selectedNodeId: T['id']

  leafClassName?: string
  leafActiveClassName?: string
  branchClassName?: string
  branchNameClassName?: string
  branchNameActiveClassName?: string
}

const TreeNode = <T extends TNode<AnyObject>>(props: IProps<T>) => {
  const ref = useRef<HTMLSpanElement | HTMLLIElement>(null)

  useEffect(() => {
    if (!ref.current) return

    if (props.selectedNodeId === props.node.id) {
      const bbox = ref.current.getBoundingClientRect()
      if (bbox.bottom > window.innerHeight || bbox.top < 0) {
        ref.current.scrollIntoView({ block: 'center' })
      }

      ref.current.focus()
    }
  }, [props.selectedNodeId])

  const Content = props.nodeContentRenderer({ node: props.node })
  if (!Content) return null

  props.tabIndexMap.set(props.tabIndex.current, props.node.id as T['id'])

  const isSelected = props.selectedNodeId === props.node.id
  const isOpen = props.openedNodes.has(props.node.id)
  if (!props.node.children) {
    const cn = props.leafClassName ?? 'tpl-leaf tpl-leaf__name is-selectable'
    const activeCn = props.leafActiveClassName ?? 'is-selected'

    return (
      <li
        ref={ref as any}
        role="treeitem"
        className={`${cn} ${isSelected ? activeCn : ''}`}
        style={{ outline: 'none' }}
        tabIndex={props.tabIndex.current++}
        key={props.node.id}
        onFocus={props.onSelect}
        onKeyDown={isSelected ? props.onKeyDown : null}
        data-id={nodeIdService.encodeId(props.node.id)}
      >
        {Content}
      </li>
    )
  }

  const iconCn = isOpen ? 'tpl-icon open-icon' : 'tpl-icon closed-icon'
  const branchCn = props.branchClassName ?? 'tpl-branch'
  const branchNameCn = props.branchNameClassName ?? 'tpl-branch__name is-selectable'
  const branchNameActiveCn = props.branchNameActiveClassName ?? 'is-selected'

  const expandIcon = props.node.displayNesting !== false && (
    <span
      className={iconCn}
      onClick={(e) => {
        e.stopPropagation()
        props.onToggle(props.node.id as T['id'])
      }}
    />
  )

  return (
    <li role="treeitem" aria-expanded={isOpen} key={props.node.id} className={branchCn}>
      <span
        style={{ outline: 'none' }}
        ref={ref}
        tabIndex={props.tabIndex.current++}
        className={`${styles.node} ${branchNameCn} ${isSelected && branchNameActiveCn}`}
        onFocus={props.onSelect}
        onKeyDown={props.onKeyDown}
        data-id={nodeIdService.encodeId(props.node.id)}
      >
        {expandIcon}
        {!expandIcon && <span style={{ width: '20px' }} />}
        {Content}
      </span>
      <ul style={{ listStyleType: 'none' }}>
        {isOpen && props.node.children.map((node) => <TreeNode {...props} node={node} key={node.id} />)}
      </ul>
    </li>
  )
}

export default TreeNode
