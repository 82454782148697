import { Equipment } from 'au-nsi/equipment'
import { selectEnabledModules } from 'pages/App/app.selectors'
import { Dashboard } from 'pages/Dashboards/components/Dashboard'
import dashboardActions from 'pages/Dashboards/dashboard.actions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'
import globalClock from '../../../../services/clock/clock.factory'
import { useDeviceDashboards } from '../DeviceDashboards/DeviceDashboards'

interface Props {
  device: Equipment
  dashboardId: string
}

/**
 * Шаблон экрана, выбранный пользователем в качестве главной страницы карточки устройства
 */
const DeviceMainDashboard = ({ dashboardId, device }: Props) => {
  const dispatch = useDispatch()
  const modules = useAppSelector(selectEnabledModules)
  const components = useAppSelector((state) => state.dashboards.components[dashboardId])
  const dashboards = useDeviceDashboards(device.id)

  useEffect(() => {
    dispatch(dashboardActions.loadDashboardComponents(dashboardId))

    // на экране с embeddedMode=true нет плеера, поэтому вручную выставляем кадр по умолчанию
    globalClock.setOnline()
    globalClock.setFrame(300_000)
  }, [dashboardId])

  const dashboard = dashboards.find((d) => d.id === dashboardId)
  if (!dashboard || !components) return null

  return (
    <Dashboard
      allowEditing={false}
      components={components}
      controls={controls}
      id={dashboardId}
      dashboard={dashboard}
      dashboards={dashboards}
      modules={modules}
      embeddedMode={true}
      templateDevice={device}
      onCreate={noop}
      onDelete={noop}
      onMount={noop}
      onPositionUpdate={noop}
      onSettingsUpdate={noop}
    />
  )
}

const noop = () => null
const controls = { autoalign: 'none', allow_editing: false, grid_step: 0 } as const

export default DeviceMainDashboard
