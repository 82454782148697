/**
 * Масштабировать svg элемент находящийся внутри контейнера так, чтобы его размер находился в указанных границах
 */
export const fitSVG = (svg: SVGSVGElement, maxWidth: number, maxHeight: number) => {
  const w = svg.width.baseVal.value || svg.viewBox.baseVal.width
  const h = svg.height.baseVal.value || svg.viewBox.baseVal.height
  const ratio = w / h

  if (!svg.hasAttribute('viewBox')) {
    svg.setAttribute('viewBox', `0 0 ${w} ${h}`)
  }

  let width = maxWidth
  let height = width / ratio

  if (height > maxHeight) {
    height = maxHeight
    width = height * ratio
  }

  svg.setAttribute('width', width.toString())
  svg.setAttribute('height', height.toString())
}

/**
 * Найти все ноды у которых указан id
 */
export const findNodes = (svg: SVGSVGElement) => {
  const text: string[] = [] // только текстовые ноды
  const all: string[] = [] // все ноды с указанным id
  let lastIncorrectId = null

  const nodes = Array.from(svg.childNodes)

  while (nodes.length > 0) {
    const node = nodes.pop()

    if (node instanceof Element && node.hasAttribute('id')) {
      const id = node.getAttribute('id')
      if (!isIdValidSelector(id)) {
        lastIncorrectId = id
        continue
      }
      if (!svg.querySelector('#' + id)) continue

      all.push(id)

      if (node.nodeName === 'text' || node.nodeName === 'tspan') text.push(id)
    }

    if (node instanceof Element) {
      Array.prototype.push.apply(nodes, node.childNodes)
    }
  }

  return { all, text, lastIncorrectId }
}

/**
 * Группировка списка условий по узлу SVG к которому они применимы
 */
export const groupConditions = <T extends { node_id: string }>(conditions: T[]) => {
  const result = new Map<string, T[]>()

  for (const c of conditions) {
    const group = result.get(c.node_id) || []

    group.push(c)
    result.set(c.node_id, group)
  }

  return result
}

/**
 * Перекрасить элемент в указанный цвет
 */
export const applyColor = (e: SVGElement | HTMLElement, color: string) => {
  if (!e) return

  if (color != null) {
    // сохранение первоначального стиля
    const style = window.getComputedStyle(e)

    if (!e.dataset.color) e.dataset.color = style.color
    if (!e.dataset.fill) e.dataset.fill = style.fill
    if (!e.dataset.stroke) e.dataset.stroke = style.stroke

    // меняем цвет на указанный (но только если элемент уже был окрашен, чтобы например
    // не применить заливку к прозрачному элементу)
    if (style.fill && style.fill !== 'none') e.style.fill = color
    if (style.color && style.color !== 'none') e.style.color = color
    if (style.stroke && style.stroke !== 'none') e.style.stroke = color
  } else {
    // восстановление первоначального стиля
    e.style.color = e.dataset.color
    e.style.fill = e.dataset.fill
    e.style.stroke = e.dataset.stroke
  }

  // также перекрашиваем все дочерние элементы
  for (const child of Array.from(e.childNodes)) {
    if (child instanceof SVGElement) {
      applyColor(child, color)
    }
  }
}

const isIdValidSelector = (id: string): boolean => {
  try {
    document.createDocumentFragment().querySelector('#' + id)
    return true
  } catch {
    return false
  }
}
