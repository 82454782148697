import { convertTo2 } from '../../shared/LineCharts/utils/version.utils'
import { isJsonEqual } from '../../utils/misc'
import { highlightSearch } from '../../utils/search'
import { Equipment } from 'au-nsi/equipment'
import { IDashboardSearchResult } from './dashboard.types'
import { IChartSettings2, IDashboard, IDashboardComponent } from 'au-nsi/dashboards'

export const modalTitles = {
  copy: 'dashboards.copy',
  create: 'dashboards.add',
  edit: 'dashboards.edit',
}

export const createEmptyDashboard = (): IDashboard => {
  return {
    id: '',
    name: '',
    user_ordering_index: 0,
    access: { restricted: false, roles: [], users: [], orgs: [] },
    folder_id: null,
    folder_name: '',
  }
}

export const searchDashboards = (dashboards: IDashboard[], pattern: string): IDashboardSearchResult[] => {
  const parts = pattern.split(/\s+/g)
  const results: IDashboardSearchResult[] = []

  for (const d of dashboards) {
    const { isMatch, result } = highlightSearch(d.name, parts)

    if (isMatch) {
      results.push({ ...d, folder_name: '', folder_id: undefined, searchResult: result })
    }
  }

  return results
}

export const getUpdates = (source: IDashboard[], current: IDashboard[]) => {
  const dashboards: Record<IDashboard['id'], IDashboard> = current.reduce(
    (acc, d) => Object.assign(acc, { [d.id]: d }),
    {}
  )
  const existsDashboards: Record<IDashboard['id'], IDashboard> = source.reduce(
    (acc, d) => Object.assign(acc, { [d.id]: d }),
    {}
  )

  const removed = []
  const updated = []
  for (const d_id of Object.keys(existsDashboards)) {
    if (dashboards[d_id] === undefined) removed.push(existsDashboards[d_id].id)
    else if (!isJsonEqual(dashboards[d_id], existsDashboards[d_id])) updated.push(dashboards[d_id])
  }

  return {
    updated,
    removed,
  }
}

/**
 * Заголовок экрана с добавлением названия родительской папки или родительского устройства
 */
export const constructTitle = (d: IDashboard, dashboards: IDashboard[], device: Equipment): string => {
  if (!d) return ''
  if (device) return device.name + ' / ' + d.name
  if (!d.folder_id) return d.name

  const folder = dashboards.find((e) => e.id === d.folder_id)
  if (!folder || !folder.folder_name) return d.name

  return folder.folder_name + ' / ' + d.name
}

/**
 * Заменить id устройств в настройках компонента
 */
export const replaceDevices = (c: IDashboardComponent, map: Map<string, string>) => {
  const get = (id: string) => map.get(id) || id

  switch (c.type) {
    case 'linear_chart':
      c.settings = convertTo2(c.settings)

      for (const axis of (c.settings as IChartSettings2).axes) {
        for (const line of axis.lines) {
          line.device_id = get(line.device_id)
        }
      }
      break
    case 'button':
      for (const command of c.settings.commands) {
        command.target = get(command.target)
      }
      break
    case 'gauge':
    case 'gauge_linear':
    case 'indicator':
      c.settings.device_id = get(c.settings.device_id)
      break
    case 'vector_chart':
      for (const vector of c.settings.vectors) {
        vector.device_id = get(vector.device_id)
      }
      break
    case 'gantt':
    case 'phase_portrait':
    case 'table':
      c.settings.equipment = c.settings.equipment.map((id) => get(id))
      break
    case 'map':
      for (const row of c.settings.equipment) {
        row.id = get(row.id)
      }
      break
    case 'text':
      for (const v of c.settings.variables) {
        v.device_id = get(v.device_id)
      }
      for (const condition of c.settings.conditions || []) {
        condition.condition.device_id = get(condition.condition.device_id)

        for (const v of condition.variables) {
          v.device_id = get(v.device_id)
        }
      }
      break
    case 'svg_diagram':
      for (const row of c.settings.text || []) row.device_id = get(row.device_id)
      for (const row of c.settings.color || []) row.device_id = get(row.device_id)
      for (const row of c.settings.commands || []) row.target = get(row.target)
      for (const row of c.settings.visibility || []) row.device_id = get(row.device_id)
      for (const row of c.settings.movements || []) row.device_id = get(row.device_id)
      for (const row of c.settings.rotations || []) row.device_id = get(row.device_id)
      break
    case 'image':
      for (const condition of c.settings.conditions) {
        condition.device_id = get(condition.device_id)
      }
      break
    case 'windrose':
    case 'histogram':
    case 'bar_chart':
      for (const p of c.settings.parameters) {
        p.device_id = get(p.device_id)
      }
      break
    case 'template_variables':
      break
    default:
      unreachable(c)
  }
}

/**
 * Заменить id параметров в настройках компонента
 */
export const replaceParameters = (c: IDashboardComponent, map: Map<string, string>) => {
  const get = (id: string) => map.get(id) || id

  switch (c.type) {
    case 'linear_chart':
      c.settings = convertTo2(c.settings)

      for (const axis of (c.settings as IChartSettings2).axes) {
        for (const line of axis.lines) {
          line.parameter_id = get(line.parameter_id)
        }
      }
      break
    case 'gauge':
    case 'gauge_linear':
    case 'indicator':
      c.settings.parameter_id = get(c.settings.parameter_id)
      break
    case 'vector_chart':
      for (const vector of c.settings.vectors) {
        vector.angle_parameter_id = get(vector.angle_parameter_id)
        vector.magnitude_parameter_id = get(vector.magnitude_parameter_id)
      }
      break
    case 'phase_portrait':
      c.settings.x_parameter_id = get(c.settings.x_parameter_id)
      c.settings.y_parameter_id = get(c.settings.y_parameter_id)
      break
    case 'table':
      for (const column of c.settings.columns) {
        column.parameter_id = get(column.parameter_id)
      }
      break
    case 'map':
      c.settings.parameter_id = get(c.settings.parameter_id)
      break
    case 'text':
      for (const v of c.settings.variables) {
        v.parameter_id = get(v.parameter_id)
      }
      for (const condition of c.settings.conditions || []) {
        condition.condition.parameter_id = get(condition.condition.parameter_id)

        for (const v of condition.variables) {
          v.parameter_id = get(v.parameter_id)
        }
      }
      break
    case 'svg_diagram':
      for (const row of c.settings.text || []) row.parameter_id = get(row.parameter_id)
      for (const row of c.settings.color || []) row.parameter_id = get(row.parameter_id)
      for (const row of c.settings.visibility || []) row.parameter_id = get(row.parameter_id)
      for (const row of c.settings.movements || []) row.parameter_id = get(row.parameter_id)
      for (const row of c.settings.rotations || []) row.parameter_id = get(row.parameter_id)
      break
    case 'image':
      for (const condition of c.settings.conditions) {
        condition.parameter_id = get(condition.parameter_id)
      }
      break
    case 'windrose':
      for (const p of c.settings.parameters) {
        p.direction_parameter_id = get(p.direction_parameter_id)
        p.speed_parameter_id = get(p.speed_parameter_id)
      }
      break
    case 'histogram':
    case 'bar_chart':
      for (const p of c.settings.parameters) {
        p.parameter_id = get(p.parameter_id)
      }
      break
    case 'button':
    case 'gantt':
    case 'template_variables':
      break
    default:
      unreachable(c)
  }
}

/**
 * Извлечь id всех использующихся устройств из настроек компонентов
 */
export const extractDevices = (c: IDashboardComponent, set: Set<string>): Set<string> => {
  switch (c.type) {
    case 'linear_chart':
      return convertTo2(c.settings).axes.reduce(
        (acc, axis) => axis.lines.reduce((acc2, line) => acc2.add(line.device_id), acc),
        set
      )
    case 'button':
      for (const command of c.settings.commands) {
        // command.target может ссылаться на сервис, а не на устройство
        if (isUUID(command.target)) set.add(command.target)
      }
      return set
    case 'gauge':
    case 'gauge_linear':
    case 'indicator':
      return set.add(c.settings.device_id)
    case 'vector_chart':
      return c.settings.vectors.reduce((acc, vector) => acc.add(vector.device_id), set)
    case 'gantt':
    case 'phase_portrait':
    case 'table':
      return (c.settings.equipment || []).reduce((acc, id) => acc.add(id), set)
    case 'map':
      return c.settings.equipment.reduce((acc, row) => acc.add(row.id), set)
    case 'text':
      for (const v of c.settings.variables) set.add(v.device_id)
      for (const condition of c.settings.conditions || []) {
        set.add(condition.condition.device_id)
        for (const v of condition.variables) set.add(v.device_id)
      }
      return set
    case 'svg_diagram':
      for (const row of c.settings.text || []) set.add(row.device_id)
      for (const row of c.settings.color || []) set.add(row.device_id)
      for (const row of c.settings.visibility || []) set.add(row.device_id)
      for (const row of c.settings.movements || []) set.add(row.device_id)
      for (const row of c.settings.rotations || []) set.add(row.device_id)
      for (const row of c.settings.commands || []) {
        // аналогично настройкам button, target может быть названием сервиса
        if (isUUID(row.target)) set.add(row.target)
      }
      return set
    case 'image':
      return c.settings.conditions.reduce((acc, c) => acc.add(c.device_id), set)
    case 'windrose':
    case 'histogram':
    case 'bar_chart':
      const parameters = c.settings.parameters as Array<{ device_id: string }>
      return parameters.reduce((acc, p) => acc.add(p.device_id), set)
    case 'template_variables':
      return set
    default:
      unreachable(c)
  }
}

/**
 * Извлечь id всех использующихся параметров из настроек компонентов
 */
export const extractParameters = (c: IDashboardComponent, set: Set<string>): Set<string> => {
  switch (c.type) {
    case 'linear_chart':
      return convertTo2(c.settings).axes.reduce(
        (acc, axis) => axis.lines.reduce((acc2, line) => acc2.add(line.parameter_id), acc),
        set
      )
    case 'gauge':
    case 'gauge_linear':
    case 'indicator':
      return set.add(c.settings.parameter_id)
    case 'vector_chart':
      return c.settings.vectors.reduce(
        (acc, vector) => acc.add(vector.angle_parameter_id).add(vector.magnitude_parameter_id),
        set
      )
    case 'phase_portrait':
      return set.add(c.settings.x_parameter_id).add(c.settings.y_parameter_id)
    case 'table':
      return c.settings.columns.reduce((acc, row) => acc.add(row.parameter_id), set)
    case 'map':
      return set.add(c.settings.parameter_id)
    case 'text':
      for (const v of c.settings.variables) set.add(v.parameter_id)
      for (const condition of c.settings.conditions || []) {
        set.add(condition.condition.parameter_id)
        for (const v of condition.variables) set.add(v.parameter_id)
      }
      return set
    case 'svg_diagram':
      for (const row of c.settings.text || []) set.add(row.parameter_id)
      for (const row of c.settings.color || []) set.add(row.parameter_id)
      for (const row of c.settings.visibility || []) set.add(row.parameter_id)
      for (const row of c.settings.movements || []) set.add(row.parameter_id)
      for (const row of c.settings.rotations || []) set.add(row.parameter_id)
      return set
    case 'image':
      return c.settings.conditions.reduce((acc, c) => acc.add(c.parameter_id), set)
    case 'windrose':
    case 'histogram':
    case 'bar_chart':
      const parameters = c.settings.parameters as Array<{ device_id: string }>
      return parameters.reduce((acc, p) => acc.add(p.device_id), set)
    case 'button':
    case 'gantt':
    case 'template_variables':
      return set
    default:
      unreachable(c)
  }
}

const isUUID = (str: string) => UUID_PATTERN.test(str)
const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

function unreachable(_: never): never {
  throw new Error(`Unexpected component type`)
}
