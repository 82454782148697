import React from 'react'

const mobileBreakpoint = 760
const tabletBreakpoint = 1480

/**
 * Хук для определения ширины экрана устройства
 */
const useDeviceWidth = () => {
  const [width, setWidth] = React.useState(window.innerWidth)

  const m1 = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`)
  const m2 = window.matchMedia(`(max-width: ${tabletBreakpoint}px)`)

  const onChange = React.useCallback(() => setWidth(window.innerWidth), [])

  React.useEffect(() => {
    m1.addEventListener('change', onChange)
    m2.addEventListener('change', onChange)

    return () => {
      m1.removeEventListener('change', onChange)
      m2.removeEventListener('change', onChange)
    }
  }, [])

  const isMobile = m1.matches
  const isTablet = m2.matches && !isMobile
  const isDesktop = !isMobile && !isTablet

  return { width, isMobile, isTablet, isDesktop }
}

export default useDeviceWidth
