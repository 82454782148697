import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import { organizationsActions } from '../../organizations.actions'
import { useEffect, useState } from 'react'
import { FormMode } from '../../../../../shared/interfaces'
import OrganizationTypeForm from './OrganizationTypeForm/OrganizationTypeForm'
import useOrganizationTypeCallbacks from './useOrganizationTypeCallbacks'
import FormButtons from '../../../../../shared/Forms/FormButtons'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import Form from '../../../../../shared/Forms/Form'
import { useParams } from 'react-router-dom'

const OrganizationTypeFormRoute = () => {
  const params = useParams()
  const id = parseInt(params.id)
  const dispatch = useDispatch()

  const organizationType = useSelector((state: ReduxState) =>
    state.organizations.organizations.find((o) => o.id === id)
  )

  const [mode, setMode] = useState<FormMode>('view')
  const { wait, loading } = useHttpLoader()
  const [draftOrganization, setDraftOrganization] = useState(organizationType)

  const organizationTypeCallbacks = useOrganizationTypeCallbacks({ deps: [id], setter: setDraftOrganization })

  useEffect(() => {
    if (mode !== 'edit') setDraftOrganization(organizationType)
  }, [organizationType, mode])

  const handleDelete = async () => {
    wait(dispatch(organizationsActions.deleteOrganizationType(id)) as any)
  }

  const handleEditSave = async () => {
    wait(dispatch(organizationsActions.updateOrganizationType(draftOrganization)) as any, () => setMode('view'))
  }

  if (!draftOrganization) return <></>

  return (
    <Form editing={mode === 'edit'} onSubmit={handleEditSave}>
      <OrganizationTypeForm
        editing={mode === 'edit'}
        organizationType={draftOrganization}
        onMetaChange={organizationTypeCallbacks.handleMetaChange}
        onChange={organizationTypeCallbacks.handleChange}
        onMove={organizationTypeCallbacks.handleMove}
        onRemove={organizationTypeCallbacks.handleRemoveProperty}
        onCreateProperty={organizationTypeCallbacks.handleCreateProperty}
      />
      <FormButtons
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onDelete={() => setMode('delete')}
        onDeleteConfirm={handleDelete}
        allowEditing={true}
        allowDeleting={true}
        isLoading={loading}
      />
    </Form>
  )
}

export default OrganizationTypeFormRoute
