import { createSelector } from 'reselect'
import { ReduxState } from '../../../redux/store.types'
import { generateImageSrc } from './image.utils'
import { Image } from 'au-nsi/images'

const imageSelector = (state: ReduxState) => state.images.items

export const selectImages = createSelector(imageSelector, (images) => {
  return images.map((image) => {
    const fullname = image.name + '.' + image.format
    return { ...image, fullname, src_url: generateImageSrc(image) } as Image
  })
})

export const selectImagesMap = createSelector(selectImages, (images) => {
  const result = new Map<string, Image>()

  for (const image of images) {
    result.set(image.id, image)
  }

  return result
})
