import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import actions from './audio.actions'
import AudioUpload from './AudioUpload'
import { IAudio } from 'au-nsi/audio'

/**
 * Модальное окно с формой для редактирования аудио
 */
const AudioModal = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [audio, setAudio] = React.useState({ ...props.audio })

  const handleSrcChange = (changes: IAudio) => {
    const { src, name, format } = changes
    setAudio({ ...audio, src, name, format })
  }

  const handleSave = () => {
    dispatch(actions.updateAudio(audio))
    props.onClose()
  }

  return (
    <Modal onClose={props.onClose}>
      <div>
        <h2>{intl.formatMessage({ id: 'system.audio.edit' })}</h2>

        <InputRow label={intl.formatMessage({ id: 'system.audio.name' })}>
          <TextInput name="name" value={audio.name} onChange={(name) => setAudio({ ...audio, name })} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.audio.src' })}>
          <AudioUpload multiple={false} onChange={handleSrcChange} />
        </InputRow>

        <ModalFooter onCancel={props.onClose} onSave={handleSave} />
      </div>
    </Modal>
  )
}

interface Props {
  audio: IAudio
  onClose: () => void
}

export default AudioModal
