import { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import SmoothPanel from '../../Utils/SmoothPanel/SmoothPanel'

interface IProps {
  open: boolean
  animationOrigin?: string

  style?: CSSProperties
  onClick?: () => void
}

const ContextMenu = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>((props, ref) => {
  const renderMenu = () => {
    return (
      <div className="nsi-dropdown contextmenu" ref={ref} style={props.style} onClick={props.onClick}>
        {props.children}
      </div>
    )
  }

  return (
    <SmoothPanel
      open={props.open}
      animationTime={200}
      icon={null}
      panelContent={renderMenu()}
      transformOrigin={props.animationOrigin ?? 'top left'}
      style={{ position: 'initial' }}
    />
  )
})

export default ContextMenu
