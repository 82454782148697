import { TTheme } from '../../User/Profile/ColorTheme'

export const colorCssVariables = [
  '--bg-input',
  '--bg-default-light',
  '--bg-default',
  '--bg-550',
  '--bg-600',
  '--gray-30',
  '--gray-60',
  '--gray-80',
  '--gray-100',
  '--selection-70',
  '--selection-80',
  '--primary-50',
  '--primary-80',
  '--primary-90',
  '--text-success',
  '--success-70',
  '--danger-40',
  '--danger-text',
  '--danger-80',
  '--bg-danger',
  '--color',
  '--warning',
  '--bg-warning',
  '--scrollbar-color',
] as const

export type TColor = typeof colorCssVariables[number]
type TThemeColorMap = Record<TColor, string>

const darkBlue: TThemeColorMap = {
  '--bg-input': '#213146',
  '--bg-default-light': '#1a2637',
  '--bg-default': '#151f2d',
  '--bg-550': '#101722',
  '--bg-600': '#040e1a',

  '--gray-30': '#b4b4bb',
  '--gray-60': '#5a5b6a',
  '--gray-80': '#454651',
  '--gray-100': '#292a31',

  '--selection-70': '#1b4ea360',
  '--selection-80': '#1b4ea350',

  '--primary-50': '#193D67',
  '--primary-80': '#0462AC',
  '--primary-90': '#379FEF',

  '--text-success': '#65b168',
  '--success-70': '#057b7c',

  '--danger-40': '#6f1f19',
  '--danger-text': '#f44336',
  '--danger-80': '#9e0630',
  '--bg-danger': '#401E27',

  '--warning': '#ff9857',
  '--bg-warning': '#594336',
  '--color': '#fff',

  '--scrollbar-color': '#b4b4bb',
}

const white: TThemeColorMap = {
  '--bg-input': '#E0E1E9',
  '--bg-default-light': '#CED0DC',
  '--bg-default': '#D6D7E1',
  '--bg-550': '#E7E8F1',
  '--bg-600': '#FFFFFF',

  '--gray-30': '#454651',
  '--gray-60': '#5a5b6a',
  '--gray-80': '#b4b4bb',
  '--gray-100': '#b4b4bb',

  '--selection-70': '#379FEF40',
  '--selection-80': '#379FEF40',

  '--primary-50': '#379FEF',
  '--primary-80': '#659bd9',
  '--primary-90': '#0462AC',

  '--text-success': '#65b168',
  '--success-70': '#057b7c',

  '--danger-40': '#D63447',
  '--danger-text': '#f44336',
  '--danger-80': '#D6345E',
  '--bg-danger': '#F8DBE6',

  '--bg-warning': '#F5E4DD',
  '--warning': '#E59E72',
  '--color': '#333',

  '--scrollbar-color': '#b4b4bb',
}

const themes: { [key in TTheme]: TThemeColorMap } = { 'dark-blue': darkBlue, white, system: null }
export default themes
