import { useIntl } from 'react-intl'
import styles from './keyValueTable.module.css'
import { CSSProperties } from 'react'

interface IProps {
  pairs: [string, any, string?][]
  keyRowPercentWidth?: number
  style?: CSSProperties
}

const KeyValueTable = (props: IProps) => {
  const intl = useIntl()

  const renderThead = () => {
    if (!props.keyRowPercentWidth) return null

    return (
      <thead>
        <tr>
          <th style={{ width: props.keyRowPercentWidth + '%' }} />
        </tr>
      </thead>
    )
  }

  const rows = props.pairs.map(
    (pair) =>
      pair[0] && (
        <tr key={pair[2] ?? pair[0]}>
          <td className={styles.key}>
            <span>{intl.formatMessage({ id: pair[0] })}</span>
          </td>
          <td className={styles.value}>{pair[1]}</td>
        </tr>
      )
  )

  return (
    <table className={styles.table} style={props.style}>
      {renderThead()}
      <tbody>{rows}</tbody>
    </table>
  )
}

export default KeyValueTable
