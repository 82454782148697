import usePersistentState from './usePersistentState'
import { useEffect, useLayoutEffect } from 'react'

/**
 * Хук, запоминающий scrollTop элемента {scrollElementSelector} и выстовляющий его заново на mount
 * */
const usePersistenceScrollPosition = (scrollElementSelector: string, key: string) => {
  const [scrollY, setScrollY] = usePersistentState<number>(0, key)

  useEffect(() => {
    const parent = document.querySelector(scrollElementSelector)
    parent.scrollTo({ top: scrollY })
  }, [])

  useLayoutEffect(() => {
    return () => {
      const parent = document.querySelector(scrollElementSelector)
      setScrollY(parent.scrollTop)
    }
  }, [])
}

export default usePersistenceScrollPosition
