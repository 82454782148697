import i18n from '../../../translations/i18n'
import bar_chart_en from '../BarChart/bar_chart_en.json'
import bar_chart_ru from '../BarChart/bar_chart_ru.json'
import button_en from '../Button/button_en.json'
import button_ru from '../Button/button_ru.json'
import histogram_en from '../Histogram/histogram_en.json'
import histogram_ru from '../Histogram/histogram_ru.json'
import image_en from '../Image/image_en.json'
import image_ru from '../Image/image_ru.json'
import indicator_en from '../Indicator/indicator_en.json'
import indicator_ru from '../Indicator/indicator_ru.json'
import map_en from '../Map/map_en.json'
import map_ru from '../Map/map_ru.json'
import phase_portrait_en from '../PhasePortrait/phase_portrait_en.json'
import phase_portrait_ru from '../PhasePortrait/phase_portrait_ru.json'
import svg_en from '../SVG/svg_en.json'
import svg_ru from '../SVG/svg_ru.json'
import table_en from '../Table/table_en.json'
import table_ru from '../Table/table_ru.json'
import template_en from '../Template/template_en.json'
import template_ru from '../Template/template_ru.json'
import text_en from '../Text/text_en.json'
import text_ru from '../Text/text_ru.json'
import windrose_en from '../Windrose/windrose_en.json'
import windrose_ru from '../Windrose/windrose_ru.json'
import chart_en from './chart_en.json'
import chart_ru from './chart_ru.json'
import dashboards_en from './dashboards_en.json'
import dashboards_ru from './dashboards_ru.json'
import gauge_en from './gauge_en.json'
import gauge_ru from './gauge_ru.json'
import vector_en from './vector_en.json'
import vector_ru from './vector_ru.json'

i18n.append([
  { prefix: 'dashboards.', en: dashboards_en, ru: dashboards_ru },
  { prefix: 'dashboards.bar_chart.', en: bar_chart_en, ru: bar_chart_ru },
  { prefix: 'dashboards.button.', en: button_en, ru: button_ru },
  { prefix: 'dashboards.gauge.', en: gauge_en, ru: gauge_ru },
  { prefix: 'dashboards.gauge_linear.', en: gauge_en, ru: gauge_ru },
  { prefix: 'dashboards.histogram.', en: histogram_en, ru: histogram_ru },
  { prefix: 'dashboards.image.', en: image_en, ru: image_ru },
  { prefix: 'dashboards.indicator.', en: indicator_en, ru: indicator_ru },
  { prefix: 'dashboards.linear_chart.', en: chart_en, ru: chart_ru },
  { prefix: 'dashboards.map.', en: map_en, ru: map_ru },
  { prefix: 'dashboards.phase_portrait.', en: phase_portrait_en, ru: phase_portrait_ru },
  { prefix: 'dashboards.svg_diagram.', en: svg_en, ru: svg_ru },
  { prefix: 'dashboards.table.', en: table_en, ru: table_ru },
  { prefix: 'dashboards.template.', en: template_en, ru: template_ru },
  { prefix: 'dashboards.text.', en: text_en, ru: text_ru },
  { prefix: 'dashboards.vector_chart.', en: vector_en, ru: vector_ru },
  { prefix: 'dashboards.windrose.', en: windrose_en, ru: windrose_ru },
])
