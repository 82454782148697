import { Equipment, EquipmentType, Region } from 'au-nsi/equipment'
import { IAccessSettings } from 'au-nsi/shared'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import { useAppSelector } from '../../../redux/store'
import AccessModal from '../../../shared/Access/AccessModal'
import HideableLeftMenu from '../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import Toggle from '../../../shared/Inputs/Toggle'
import confirmService from '../../../shared/Modal/confirm.service'
import Tree from '../../../shared/Tree/Tree'
import useTree from '../../../shared/Tree/useTree'
import CollapseButton from '../../../shared/Utils/CollapseButton'
import { selectAccessRights } from '../../App/app.selectors'
import GroupSelectorModal from '../Modals/GroupSelectorModal'
import ReplicationModal from '../Modals/ReplicationModal'
import SearchModal from '../Modals/SearchModal/SearchModal'
import * as actions from '../nsi.actions'
import { selectRegionsState, selectTargetRegions } from '../nsi.selectors'
import DeviceRow from './DeviceRow'
import { handleDropAction } from './dnd.utils'
import RegionControls from './RegionControls'
import RegionRow from './RegionRow'
import treeBuilder from './tree.builder'

/**
 * Дерево топологии (регионы и оборудование)
 */
const NsiTree = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [state, setState] = useState<State>({
    showAccessModal: false,
    showMoveModal: false,
    showReplicationModal: false,
    showSearchModal: false,
  })

  const rights = useAppSelector(selectAccessRights)
  const regionsState = useAppSelector(selectRegionsState)
  const targetRegions = useAppSelector(selectTargetRegions)
  const regions = useAppSelector((state) => state.nsi.regions)
  const showInactive = useAppSelector((state) => state.nsi.showInactive)
  const selectedDeviceId = useAppSelector((state) => state.nsi.selectedDeviceId)
  const selectedRegionId = useAppSelector((state) => state.nsi.selectedRegionId)
  const showInactiveToggle = useAppSelector((state) => state.nsi.equipmentAll.length > state.nsi.equipment.length)
  const equipment = useAppSelector((state) => (state.nsi.showInactive ? state.nsi.equipmentAll : state.nsi.equipment))

  const nsiTree = useMemo(() => treeBuilder.build(regions, equipment), [regions, equipment])
  const tree = useTree({ persistenceStrategy: 'localstorage', key: 'topologyTree' })

  useEffect(() => {
    if (selectedDeviceId && selectedDeviceId !== tree.selectedNodeId) tree.handleSelect(selectedDeviceId)
    if (selectedRegionId && selectedRegionId !== tree.selectedNodeId) tree.handleSelect(selectedRegionId)
  }, [selectedDeviceId, selectedRegionId])

  useEffect(() => {
    if (rights['equipment:update']) {
      window.addEventListener('keyup', handleKeyUp)
      return () => window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  // обработать нажатие ALT + ArrowUp или ALT + ArrowDown для перемещения элементов по дереву
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.altKey && e.key === 'ArrowUp') {
      e.preventDefault()
      dispatch(actions.reorderSelectedItem(-1))
    }

    if (e.altKey && e.key === 'ArrowDown') {
      e.preventDefault()
      dispatch(actions.reorderSelectedItem(1))
    }
  }

  // удалить выбранное устройство
  const handleDeleteDevice = () => {
    const title = 'nsi.confirm_device_deletion'
    const options = [{ title: 'common.delete', value: 'delete' }]

    confirmService.requestConfirmation(title, options).then((result) => {
      if (result === 'cancel') return

      const action = actions.deleteEquipment(selectedDeviceId)
      dispatch(action)
    })
  }

  // архивировать / восстановить устройство
  const handleArchiveDevice = () => {
    const device = equipment.find((e) => e.id === selectedDeviceId)
    const action = actions.patchEquipment(selectedDeviceId, { inactive: !device.inactive })
    dispatch(action)
  }

  // запустить все устройства в группе
  const handleStart = () => dispatch(actions.setDevicesState('RUNNING'))

  // остановить все устройства в группе
  const handleStop = () => dispatch(actions.setDevicesState('STOPPED'))

  // удалить выбранный регион
  const handleDeleteRegion = () => {
    const title = 'common.confirm_deletion'
    const options = [{ title: 'common.delete', value: 'del' }]

    confirmService.requestConfirmation(title, options).then((result) => {
      if (result === 'cancel') return

      const action = actions.deleteRegion(selectedRegionId)
      dispatch(action)
    })
  }

  const toggleInactiveFilter = () => dispatch(actions.toggleInactiveFilter())

  const toggleAccessModal = () => {
    setState((prev) => ({ ...prev, showAccessModal: !prev.showAccessModal }))
  }

  const toggleSearchModal = () => {
    setState((prev) => ({ ...prev, showSearchModal: !prev.showSearchModal }))
  }

  const handleAccessChange = (access: IAccessSettings) => {
    dispatch(actions.patchEquipment(selectedDeviceId, { access }))
    setState((prev) => ({ ...prev, showAccessModal: false }))
  }

  // переместить выбранный объект в выбранный регион
  const handleTargetRegionSelect = (targetId: number | string) => {
    const cb = selectedDeviceId != null ? moveDevice : moveRegion
    cb.call(this, targetId)
    closeModal()
  }

  const handleDrop = (source: string, target: string) => dispatch(handleDropAction(source, target))

  // переместить регион в другой родительский регион
  const moveRegion = (targetId: number) => {
    const sourceRegion = regions.find((r) => r.id === selectedRegionId)
    const destinationRegion = regions.find((r) => r.id === targetId)

    const action = actions.moveRegion({
      source_path: sourceRegion.path,
      destination_path: destinationRegion.path,
    })

    dispatch(action)
  }

  // переместить устройство в другой регион
  const moveDevice = (targetId: number) => {
    const action = actions.patchEquipment(selectedDeviceId, { region_id: targetId })
    dispatch(action)
  }

  const showReplicationModal = () => setState((prev) => ({ ...prev, showReplicationModal: true }))

  // показать/скрыть модальное окно для выбора региона для перемещения выбранного объекта
  const openModal = () => setState((prev) => ({ ...prev, showMoveModal: true }))
  const closeModal = () => setState((prev) => ({ ...prev, showMoveModal: false }))

  const handleSelect = (id: Region['id'] | Equipment['id']) => {
    tree.handleSelect(id)

    if (typeof id === 'string') dispatch(actions.setSelectedDeviceId(id))
    else dispatch(actions.setSelectedRegionId(id))
  }

  // добавить новое устройство
  const createDevice = (protocol: any, type: EquipmentType) => {
    const region_id = selectedRegionId || 1
    const name = intl.formatMessage({ id: 'nsi.device' })
    const action = actions.createEquipment({ name, protocol, type, region_id })
    dispatch(action)
  }

  // добавить новый регион
  const createRegion = () => {
    dispatch(actions.setFormMode('create'))
  }

  const handleCollapseToggle = (isCollapsed: boolean) => {
    if (isCollapsed) tree.setOpenedNodes(new Set())
    else tree.setOpenedNodes(new Set(regions.map((r) => r.id)))
  }

  // отрендерить строку с оборудованием
  const renderEquipment = (e: Equipment) => {
    const isVirtual = !!e.path // AU-3197: запрет перемещения виртуальных устройств

    return (
      <DeviceRow
        allowChangingAccess={rights['equipment:update']}
        allowCreating={rights['equipment:create']}
        allowDeleting={rights['equipment:delete']}
        allowMoving={rights['equipment:update'] && !isVirtual}
        allowReordering={rights['equipment:update']}
        device={e}
        onAccess={toggleAccessModal}
        onArchive={handleArchiveDevice}
        onDelete={handleDeleteDevice}
        onDrop={handleDrop}
        onMove={openModal}
        onReplicate={showReplicationModal}
        selectedId={selectedDeviceId}
      />
    )
  }

  // отрендерить регион и его дочерние элементы
  const renderRegion = (r: Region) => {
    const regionState = regionsState.get(r.id) || 0

    return (
      <RegionRow
        allowChangingState={rights['equipment:update']}
        allowCreating={rights['equipment:create']}
        allowDeleting={rights['equipment:delete']}
        allowEditing={rights['equipment:update']}
        onCreateDevice={createDevice}
        onCreateRegion={createRegion}
        onDelete={handleDeleteRegion}
        onDrop={handleDrop}
        onMove={openModal}
        onStart={handleStart}
        onStop={handleStop}
        region={r}
        regionState={regionState}
        selectedId={selectedRegionId}
      />
    )
  }

  const renderNode = ({ node }) => {
    if ('region_id' in node) return renderEquipment(node)
    return renderRegion(node)
  }

  const renderAccessModal = () => {
    const device = equipment.find((e) => e.id === selectedDeviceId)

    return <AccessModal access={device.access} onSave={handleAccessChange} onCancel={toggleAccessModal} />
  }

  // модальное окно для перемещения объектов топологии
  const renderMoveModal = () => {
    return (
      state.showMoveModal && (
        <GroupSelectorModal
          groups={targetRegions}
          onClose={closeModal}
          onSelect={handleTargetRegionSelect}
          textSelect="common.move"
          textTitle="common.movein"
        />
      )
    )
  }

  const renderReplicationModal = () => {
    const device = equipment.find((e) => e.id === selectedDeviceId)
    const onClose = () => setState((prev) => ({ ...prev, showReplicationModal: false }))

    return <ReplicationModal device={device} onClose={onClose} />
  }

  // переключатель между показом всех устройств или только активных (не архивированных)
  const renderInactiveToggle = () => {
    return (
      <div className="nsi-tree__header-row" style={{ marginLeft: 4 }}>
        <Toggle name="" checked={showInactive} onChange={toggleInactiveFilter} size="10px" />
        <FormattedMessage id="nsi.show_inactive" />
      </div>
    )
  }

  return (
    <HideableLeftMenu>
      <div className="nsi-tree_title" style={{ display: 'flex' }}>
        <div>
          <FormattedMessage id="nsi.topology" />
        </div>
        <div className="nsi__title-controls">
          <CollapseButton
            isCollapsed={tree.openedNodes.size !== regions.length}
            onChange={handleCollapseToggle}
            className="nsi__title-icon"
            style={{ right: 112 }}
          />

          {equipment.length > 4 && (
            <div className="nsi__title-icon is-active" style={{ right: 72 }} onClick={toggleSearchModal}>
              <SearchIcon width="18px" height="18px" />
            </div>
          )}

          <RegionControls
            allowCreating={rights['equipment:create']}
            onCreateEquipment={createDevice}
            onCreateRegion={createRegion}
            useButtonIcon={true}
          />
        </div>
      </div>
      {showInactiveToggle && renderInactiveToggle()}

      <div className="nsi-tree_content">
        <Tree nodes={nsiTree} {...tree} nodeContentRenderer={renderNode} handleSelect={handleSelect} />
      </div>

      {state.showMoveModal && renderMoveModal()}
      {state.showAccessModal && renderAccessModal()}
      {state.showReplicationModal && renderReplicationModal()}
      {state.showSearchModal && <SearchModal onClose={toggleSearchModal} onSelect={handleSelect} />}
    </HideableLeftMenu>
  )
}

interface State {
  showAccessModal: boolean
  showMoveModal: boolean
  showReplicationModal: boolean
  showSearchModal: boolean
}

export default NsiTree
