import { ModbusParametersMapping } from 'au-nsi/equipment'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import Checkbox from 'shared/Inputs/Checkbox/Checkbox'
import { useAppSelector } from '../../../../redux/store'
import { InputOptions, renderInput } from '../../../../shared/Forms/forms.utils'
import RemoveButton from '../../../../shared/Forms/RemoveButton'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import { selectParameterOptions } from '../../../Parameters/params.selectors'
import { modbusTableOptions, modbusTypeOptions } from '../../nsi.utils'

interface Props {
  row: ModbusParametersMapping
  editing: boolean
  index: number
  onRemove: (index: number) => void
  onChange: (row: ModbusParametersMapping, index: number) => void
  invalidRegister: boolean
  duplicateParameter: boolean
  columnSizes: string
  defaultByteOrder: 'le' | 'be'
  defaultRegisterOrder: 'le' | 'be'
}

/**
 * Строка таблицы настроек маппинга параметров для Modbus
 */
const ModbusMappingRow = (props: Props) => {
  const intl = useIntl()

  const { row, editing, index, onChange, onRemove, invalidRegister, columnSizes } = props
  const { defaultByteOrder, defaultRegisterOrder } = props
  const parameters = useAppSelector(selectParameterOptions)

  const handleChange = (value: any, key: string) => {
    onChange({ ...row, [key]: value }, index)
  }

  const handleMaskChange = (position: number) => {
    handleChange(bitPositionToMask(position), 'bit_mask')
  }

  // изменение порядка байт или регистров
  const handleOrderChange = (inverted: boolean, name: string) => {
    const defaultOrder = name === 'byte_order' ? defaultByteOrder : defaultRegisterOrder
    const order = inverted ? invertByteOrder(defaultOrder) : defaultOrder
    handleChange(order, name)
  }

  // для дискретных таблиц доступен только булевый тип регистра
  const typeOptions =
    row.table === 0 || row.table === 1 ? modbusTypeOptions.filter((o) => o.value === 'bool') : modbusTypeOptions

  const error = props.duplicateParameter ? intl.formatMessage({ id: 'nsi.parameters.duplicate_parameter' }) : ''

  const inputs: InputOptions[] = [
    { type: 'select', key: 'parameter_id', options: parameters, required: true, error },
    { type: 'select', key: 'table', options: modbusTableOptions },
    { type: 'number', key: 'register', integer: true, fullWidth: true, min: 0, max: MAX_REGISTER },
    { type: 'select', key: 'type', options: typeOptions },
    { type: 'number', key: 'factor', fullWidth: true },
  ]

  // для булевых типов в колонке вместо сдвига задается битовая маска
  if (row.type !== 'bool') {
    inputs.push({ type: 'number', key: 'offset', fullWidth: true })
  }

  const rows = inputs.map((input) => {
    if (input.type === 'number') {
      input.style = input.key === 'register' && invalidRegister ? { border: '1px solid var(--danger-80)' } : undefined
    }

    return (
      <div className={editing ? '' : 'line_clamp'} key={input.key}>
        {renderInput({ data: row, input, editing, onChange: handleChange })}
      </div>
    )
  })

  const className = `row ${index % 2 ? 'odd' : 'even'}`

  return (
    <div className={className} style={{ gridTemplateColumns: columnSizes }}>
      <div style={{ color: 'var(--gray-30)', whiteSpace: 'nowrap' }}>{index + 1}</div>
      {rows}

      {row.type === 'bool' && editing && (
        <div>
          <NumberInput
            name="bit_mask"
            type="number"
            value={maskToBitPosition(row.bit_mask)}
            onChange={handleMaskChange}
            fullWidth={true}
            integer={true}
            min={0}
            max={15}
          />
        </div>
      )}

      {row.type === 'bool' && !editing && <div>{maskToBitPosition(row.bit_mask)}</div>}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          name="byte_order"
          checked={row.byte_order && row.byte_order !== defaultByteOrder}
          disabled={!editing}
          onChange={handleOrderChange}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          name="register_order"
          checked={row.register_order && row.register_order !== defaultRegisterOrder}
          disabled={!editing}
          onChange={handleOrderChange}
        />
      </div>

      <div style={{ padding: 0 }}>{editing && <RemoveButton onRemove={() => onRemove(index)} />}</div>
    </div>
  )
}

const MAX_REGISTER = 2 ** 16 - 1

// утилиты для преобразования битовой маски - хранится маска как integer, а пользователь
// задает позицию бита, выставленного в 1
const bitPositionToMask = (position: number) => 1 << (position || 0)

const maskToBitPosition = (mask: number) => {
  if (!mask) return 0

  let position = 0
  let factor = 1

  while ((mask & factor) !== factor) {
    position += 1
    factor *= 2
  }

  return position
}

const invertByteOrder = (order: 'be' | 'le') => (order === 'be' ? 'le' : 'be')

export default memo(ModbusMappingRow)
