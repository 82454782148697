import classnames from 'classnames'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import Checkbox from '../../shared/Inputs/Checkbox/Checkbox'
import MultiDropdown from '../../shared/Inputs/MultiDropdown'
import { actions, ChartMode } from './vector.reducers'

const VectorChartsHeader = (props: Props) => {
  const intl = useIntl()
  const { charts } = props
  const isCharts = props.mode === 'charts'

  const vectorIconClass = classnames('pq-trends-header__icon icon-vectors is-left', { 'is-active': !isCharts })
  const chartsIconClass = classnames('pq-trends-header__icon icon-trends is-right', { 'is-active': isCharts })
  const gridIconClass = classnames('pq-trends-header__icon icon-grid', { 'is-active': props.showAsGrid })

  const handleModeClick = (e) => {
    const { mode } = e.target.dataset
    props.dispatch(actions.setChartMode(mode))
  }

  const handleFixUa = (fixed: boolean) => props.dispatch(actions.setFixUa(fixed))
  const handleToggleCurrent = () => props.dispatch(actions.toggleShowCurrent())
  const handleToggleVoltage = () => props.dispatch(actions.toggleShowVoltage())
  const handleGridClick = () => props.dispatch(actions.toggleGrid())

  const chartsTitle = intl.formatMessage({ id: 'lfo.charts.title' })
  const chartsValues = Object.keys(charts).filter((key) => charts[key])

  const chartsOptions = Object.keys(charts).map((key) => {
    return { title: intl.formatMessage({ id: 'vector.charts.' + key }), value: key }
  })

  const handleChartsChange = (selected: string[]) => {
    const updated = { ...charts }

    for (const key of Object.keys(updated)) updated[key] = false
    for (const key of selected) updated[key] = true

    props.dispatch(actions.setCharts(updated))
  }

  return (
    <div className="pq-trends-header vector-charts-header">
      <div className={vectorIconClass} data-mode="vector" onClick={handleModeClick} />
      <div className={chartsIconClass} data-mode="charts" onClick={handleModeClick} style={{ marginRight: '1em' }} />

      {!isCharts && (
        <React.Fragment>
          <Checkbox checked={props.fixUa} onChange={handleFixUa} />
          <div className="pq-trends-header__label">
            <FormattedMessage id="vector.controls.fix_ua" values={intlValues} />
          </div>
          <Checkbox checked={props.showCurrent} onChange={handleToggleCurrent} />
          <div className="pq-trends-header__label">
            <FormattedMessage id="vector.controls.show_current" />
          </div>
          <Checkbox checked={props.showVoltage} onChange={handleToggleVoltage} />
          <div className="pq-trends-header__label">
            <FormattedMessage id="vector.controls.show_voltage" />
          </div>
        </React.Fragment>
      )}

      {isCharts && <div className={gridIconClass} onClick={handleGridClick} />}

      {isCharts && (
        <MultiDropdown
          title={chartsTitle}
          options={chartsOptions}
          values={chartsValues}
          onChange={handleChartsChange}
        />
      )}
    </div>
  )
}

const intlValues = {
  label: (
    <span>
      U<sub>a</sub>
    </span>
  ),
}

interface Props {
  mode: ChartMode
  showAsGrid: boolean
  fixUa: boolean
  showCurrent: boolean
  showVoltage: boolean
  charts: { [id: string]: boolean }
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    mode: state.vector.chartMode,
    charts: state.vector.charts,
    showAsGrid: state.vector.showAsGrid,
    fixUa: state.vector.vectorDiagram.fixUa,
    showCurrent: state.vector.vectorDiagram.showCurrent,
    showVoltage: state.vector.vectorDiagram.showVoltage,
  }
}

export default connect(mapStateToProps)(VectorChartsHeader)
