import { Incident } from 'au-nsi/signal-events'

export interface IncidentsState {
  incidents: Incident[] // current incidents
  incidents_start: number
  archive: Incident[] // used to display history incidents
  archive_start: number
  archive_end: number
  isLoaded: boolean
  // incident that was clicked from the alerts list (used to scroll to this incident)
  selectedIncidentId: number
  audioId: string // id воспроизводимого в текущий момент аудио
}

export interface DenormalizedIncident {
  id: number
  type: string
  ts_start: number
  ts_end: number
  ack_state: IncidentAckState
  ack_ts?: number
  ack_user?: string
  title: string
  text: string
  level: string
  details: IIncidentDetails[]
}

export interface IIncidentDetails {
  device_id: string
  device_name: string
  parameters: Array<{
    parameter_id: string
    parameter_name: string
    parameter_value: string
    stats: Array<{ name: string; value: string }>
  }>
}

export enum IncidentAckState {
  Acked,
  WaitingAck,
  None,
}
