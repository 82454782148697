import React, { CSSProperties } from 'react'

/**
 * Компонент для загрузки текстовых файлов
 * Рендерит скрытый инпут и кнопку для выбора файла
 */
class FileInput extends React.Component<Props> {
  private ref = React.createRef<HTMLInputElement>()

  componentDidMount() {
    if (this.props.autofocus) this.handleClick()
  }

  private handleClick = () => {
    !this.props.disabled && this.ref.current.click()
  }

  private handleChange = (e) => {
    const reader = new FileReader()
    reader.readAsText(e.target.files[0])

    reader.onload = () => {
      this.props.onChange(reader.result as string)
    }
  }

  render() {
    const className = 'nsi-button ' + (this.props.buttonClass || 'default')

    return (
      <React.Fragment>
        <button style={this.props.style} className={className} onClick={this.handleClick}>
          {this.props.children}
        </button>
        <input
          ref={this.ref}
          className="nsi-input"
          type="file"
          accept={this.props.accept}
          onChange={this.handleChange}
        />
      </React.Fragment>
    )
  }
}

interface Props {
  accept: string // какие расширения файлов принимать
  onChange: (content: string) => void
  children: React.ReactNode | React.ReactNodeArray
  buttonClass?: 'default' | 'inverted' | 'secondary'
  style?: CSSProperties
  autofocus?: boolean
  disabled?: boolean
}

export default FileInput
