import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import PlusButton from '../../../shared/Forms/PlusButton'
import SettingsTextRow from './SettingsTextRow'
import css from './svg.module.css'

/**
 * Таблица с настройками параметров выводимых в узлы SVG схемы
 */
const SettingsText = (props: Props) => {
  const intl = useIntl()

  const handleRemove = React.useCallback((index: number) => props.onRemove('text', index), [props.onRemove])

  const handleChange = React.useCallback(
    (key: string, value: any, index: number) => props.onChange('text', index, key, value),
    [props.onChange]
  )

  const rows = props.settings.text.map((row, index) => {
    return (
      <SettingsTextRow
        key={index}
        index={index}
        row={row}
        idOptions={props.textOptions}
        deviceOptions={props.deviceOptions}
        parameterOptions={props.parameterOptions}
        onChange={handleChange}
        onRemove={handleRemove}
        onNodeHover={props.onHover}
        usedNodes={props.usedNodes}
      />
    )
  })

  return (
    <div className={css.settingsTableWrapper}>
      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.text.text_node_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.text.device_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.text.parameter_id' })}</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <PlusButton textId="common.add" onClick={() => props.onAdd('text')} style={{ marginLeft: 0 }} />
    </div>
  )
}

interface Props {
  settings: ISVGSettings
  textOptions: SelectOption[]
  deviceOptions: SelectOption[]
  parameterOptions: SelectOption[]
  onAdd: (key: string) => void
  onRemove: (key: string, index: number) => void
  onChange: (key: string, index: number, prop: string, value: any) => void
  onHover: (node: string) => void
  usedNodes: Set<string>
}

export default SettingsText
