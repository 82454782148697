import { Incident } from 'au-nsi/signal-events'
import { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { GanttTimes } from '../../../services/gantt/gantt.interfaces'
import GanttService from '../../../services/gantt/gantt.service'
import { actions as pqActions } from '../../PqDiagram/pq.reducers'
import * as actions from '../incident.actions'
import { DenormalizedIncident } from '../incident.interfaces'
import * as selectors from '../incidents.selectors'
import { getIncidentDevices, searchIncidents } from '../incidents.utils'
import IncidentsListItem from './IncidentsListItem'

const IncidentsListBody = (props: Props) => {
  const stateRef = useRef(null)
  const dispatch = useDispatch()

  // используется только как триггер для рендеринга поэтому само значение не нужно
  const [, setTime] = useState(null)

  // получить список инцидентов из редакс стора
  const data = useSelector((state: ReduxState) => {
    const gantt = props.service.getGanttTime()
    const frameStart = gantt.ganttTime - gantt.frame

    const isArchive = frameStart < state.incidents.incidents_start

    const selector = isArchive
      ? selectors.archiveDenormalizedIncidentsSelector
      : selectors.denormalizedIncidentsSelector

    return {
      incidents: selector(state),
      incidentsActive: state.incidents.incidents,
      selectedIncidentId: state.incidents.selectedIncidentId,
      hiddenDevices: state.pq.hiddenDevices,
    }
  }, shallowEqual)

  // подписаться на изменение времени и кадра на диаграмме Ганта
  useEffect(() => {
    return props.service.time$.subscribe((gantt) => {
      // рассчитать новый массив инцидентов попадающих в кадр и перерендерить компонент только
      // если он отличается от предыдущего
      const state = stateRef.current
      if (!state) return setTime(gantt)

      const next = filterIncidents({ ...state.data, ...calculateFrame(gantt), search: state.search })

      if (!isEqual(state.filtered, next)) {
        setTime(gantt)
      }
    })
  }, [])

  // убрать выделение со всех сигнальных ситуаций
  const removeSelection = useCallback(() => {
    dispatch(actions.setSelectedIncident(null))
  }, [])

  // прокрутить список до выбранной сигнальной ситуации
  const scrollToSelection = useCallback((e) => {
    const container = document.getElementById('alerts-list__container')
    const containerRect = container.getBoundingClientRect()
    const targetRect = e.target.getBoundingClientRect()

    const delta = containerRect.top - targetRect.top
    container.scrollTo(0, container.scrollTop - delta)
  }, [])

  const { incidents, hiddenDevices } = data
  const times = props.service.getGanttTime()

  const filtered = filterIncidents({ incidents, hiddenDevices, search: props.search, ...calculateFrame(times) })

  // при переходе к списку сигнальных ситуаций по клику на уведомление необходимо
  // прокрутить лист к выбранной сигнальной ситуации и выделить ее
  useEffect(() => {
    const id = data.selectedIncidentId
    if (!id) return

    // выбранная СС попадает в кадр
    const isPresent = filtered.find((e) => e.id === id) != null
    if (isPresent) return

    const incident: Incident = data.incidentsActive.find((e) => e.id === id)

    // проверить что устройства по котрым произошла СС не скрыты из списка
    const devices = getIncidentDevices(incident)
    const isHiddenDevice = devices.length > 0 && devices.every((d) => data.hiddenDevices[d] === true)
    if (isHiddenDevice) {
      dispatch(pqActions.toggleVisibility(devices[0]))
    }

    const time = incident.ts_end / 1000

    // если у СС нет конечного времени значит она еще не завершилась
    if (!time) return props.service.setOnline()

    // подвинуть кадр чтобы в него попала выбранная СС
    props.service.setGanttTime(time, true)
  }, [data.selectedIncidentId])

  // сохранить текущее состояние в ref, чтобы к нему был доступ из мемоизированной функции в useEffect
  stateRef.current = { data, filtered, search: props.search }

  // отрендерить список инцидентов
  const rows = filtered.map((incident) => (
    <IncidentsListItem
      key={incident.id}
      incident={incident}
      isSelected={incident.id === data.selectedIncidentId}
      onFlashStart={scrollToSelection}
      onFlashEnd={removeSelection}
      onClick={props.onOpen}
    />
  ))

  return <div>{rows}</div>
}

// отфильтровать инциденты попадающие в кадр диаграммы Ганта по типу и устройству
const filterIncidents = (options: FilterOptions): DenormalizedIncident[] => {
  const words = options.search.toLowerCase().split(' ')

  const result = options.incidents.filter((item: DenormalizedIncident) => {
    const startsBefore = item.ts_start <= options.t1
    const endsAfter = item.ts_end >= options.t0 || item.ts_end === 0

    return startsBefore && endsAfter && getIncidentDevices(item).some((id) => options.hiddenDevices[id] !== true)
  })

  return searchIncidents(result, words)
}

// сравнение массивов инцидентов на равенство - т.к. оба массива отсортированы в одинаковом порядке, то
// достаточно сравнить их длину и крайние элементы
const isEqual = (prev: DenormalizedIncident[], next: DenormalizedIncident[]): boolean => {
  if (prev.length !== next.length) return false
  if (prev.length === 0) return true

  return prev[0].id === next[0].id && prev[prev.length - 1].id === next[next.length - 1].id
}

// рассчитать границы фрейма на диаграмме Ганта
const calculateFrame = (times: GanttTimes) => {
  const t1 = times.ganttTime * 1000
  const t0 = (times.ganttTime - times.frame) * 1000

  return { t0, t1 }
}

interface FilterOptions {
  incidents: DenormalizedIncident[]
  search: string
  t0: number
  t1: number
  hiddenDevices: { [id: string]: boolean }
}

interface Props {
  search: string
  service: GanttService
  onOpen: (id: number) => void
}

export default IncidentsListBody
