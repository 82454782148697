import { useSelector } from 'react-redux'
import CatalogTree from '../components/CatalogTree/CatalogTree'
import { Route, Routes } from 'react-router'
import SchemaFormCreate from '../components/SchemaForm/SchemaFormCreate'
import SchemaFormRoute from '../components/SchemaForm/SchemaFormRoute'
import InstanceFormCreate from '../components/InstanceForm/InstanceFormCreate'
import InstanceFormRoute from '../components/InstanceForm/InstanceFormRoute'
import { selectCatalogsAccessRights, selectLogbooks } from '../catalogs.selectors'

const LogbooksSchemas = () => {
  const logbooks = useSelector(selectLogbooks)
  const rights = useSelector(selectCatalogsAccessRights)

  return (
    <>
      <CatalogTree
        title={'nav.logbooks_schemas'}
        items={logbooks}
        instanceCreatePathGenerator={(id) => `/catalogs/logbooks/${id}/create`}
        itemCreatePath={'/catalogs/logbooks/create'}
        routePrefix={'/catalogs/logbooks/'}
        allowInstancesEditing={false}
        allowSchemasEditing={rights.allowCatalogsEdit}
        displayNesting={false}
      />

      <div className={'nsi-main__wrapper'}>
        <div className={'nsi-main__container'}>
          <Routes>
            <Route path={'create'} element={<SchemaFormCreate />} />
            <Route path={':id'} element={<SchemaFormRoute />} />
            <Route path={':id/create'} element={<InstanceFormCreate />} />
            <Route path={':id/instance/:instanceId'} element={<InstanceFormRoute />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default LogbooksSchemas
