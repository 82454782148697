import { IIndicatorSettings } from 'au-nsi/dashboards'

export const checkIfConditionsValid = (conditions: Array<IndicatorConditions>) => {
  for (let i = 0; i < conditions.length; i++) {
    for (let j = 0; j < conditions.length; j++) {
      if (i === j) continue

      const c1 = conditions[i],
        c2 = conditions[j]

      if (c1.operator === '>' && c2.operator === '>') {
        return { i, j }
      }

      if (c1.operator === '<' && c2.operator === '<') {
        return { i, j }
      }

      if (c1.operator === '>' && c2.operator === 'out') {
        return { i, j }
      }

      if (c1.operator === '<' && c2.operator === 'out') {
        return { i, j }
      }

      if (c1.operator === 'out' && c2.operator === 'out') {
        return { i, j }
      }

      if (c1.operator === '>' && c2.operator === 'in') {
        if (c2.range && c1.value < c2.range[1]) return { i, j }
      }

      if (c1.operator === '<' && c2.operator === 'in') {
        if (c2.range && c1.value > c2.range[0]) return { i, j }
      }

      if (c1.operator === 'in' && c2.operator === 'in') {
        // в настройках начальной версии индикатора не было интервалов
        if (!c1.range || !c2.range) continue

        // начало одного интервала сразу после конца другого
        if (c1.range[1] === c2.range[0] || c1.range[0] === c2.range[1]) continue

        const isOverlap =
          (c1.range[0] <= c2.range[0] && c1.range[1] >= c2.range[0]) ||
          (c2.range[0] <= c1.range[0] && c2.range[1] >= c1.range[0])

        if (isOverlap) return { i, j }
      }
    }
  }

  return null
}

export const matchersFor2: Record<string, Matcher> = {
  in: (p, v1, v2) => p > v1 && p < v2,
  out: (p, v1, v2) => p < v1 || p > v2,
}

type Matcher = (parameterValue: number, rangeFrom: number, rangeTo: number) => boolean

type IndicatorConditions = IIndicatorSettings['conditions'][0]
