import { IVectorChartSettings } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import React from 'react'
import useResize from '../../hooks/useResize'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import ThreePhaseCircuit from '../../shared/ThreePhaseCircuit/ThreePhaseCircuit'
import VectorGraphWrapper from '../../shared/VectorGraph/VectorGraphWrapper'
import { VectorDiagramState } from './vector.reducers'

/**
 * Компонент отображающий трехфазный контур и векторную диаграмму
 */
const VectorCircuitAndGraph = (props: Props) => {
  const device = props.registrator
  const { fixUa, showCurrent, showVoltage } = props.vectorDiagram

  const settings = React.useMemo(() => {
    const vectors: IVectorChartSettings['vectors'] = []

    if (showCurrent) {
      vectors.push(
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'CurrentMagnitudePhaseA',
          angle_parameter_id: 'CurrentAnglePhaseA',
          color: '#ff0',
          name: 'Ia',
        },
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'CurrentMagnitudePhaseC',
          angle_parameter_id: 'CurrentAnglePhaseC',
          color: '#f00',
          name: 'Ic',
        },
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'CurrentMagnitudePhaseB',
          angle_parameter_id: 'CurrentAnglePhaseB',
          color: '#0f0',
          name: 'Ib',
        }
      )
    }

    if (showVoltage) {
      vectors.push(
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'VoltageMagnitudePhaseC',
          angle_parameter_id: 'VoltageAnglePhaseC',
          color: '#f00',
          name: 'Uc',
        },
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'VoltageMagnitudePhaseA',
          angle_parameter_id: 'VoltageAnglePhaseA',
          color: '#ff0',
          name: 'Ua',
        },
        {
          device_id: props.registrator.id,
          magnitude_parameter_id: 'VoltageMagnitudePhaseB',
          angle_parameter_id: 'VoltageAnglePhaseB',
          color: '#0f0',
          name: 'Ub',
        }
      )
    }

    return {
      title: '',
      current_nominal: device.rated_values.stator_current || 8625,
      voltage_nominal: device.rated_values.stator_voltage || 9093,
      vector_style: '1' as const,
      grid_parameter: 'current' as const,
      fixed_vector_name: props.vectorDiagram.fixUa ? 'Ua' : '',
      vectors,
    }
  }, [device, fixUa, showCurrent, showVoltage])

  const ref = React.useRef<HTMLDivElement>()

  const [lastResize, setLastResize] = React.useState(0)

  useResize(ref, () => setLastResize((prev) => prev + 1))

  return (
    <div className="pq-chart-container-vector-graph-wrapper">
      <div className="pq-chart-container-half">
        <ThreePhaseCircuit deviceId={device.id} viewBox={`0 0 540 300`} />
      </div>
      <div className="pq-chart-container-half" ref={ref}>
        <div id="vector-graph">
          <VectorGraphWrapper id={RESERVED_CHART_IDS.UNBALANCE_VECTOR} settings={settings} lastResize={lastResize} />
        </div>
      </div>
    </div>
  )
}

interface Props {
  registrator: Equipment
  vectorDiagram: VectorDiagramState
}

export default VectorCircuitAndGraph
