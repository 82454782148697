import React from 'react'
import useDeviceWidth from '../../hooks/useDeviceWidth'
import archiveRoutes from '../TopMenu/Nav/archive.routes'
import Links from '../TopMenu/Nav/Links'
import AppRoutes from '../TopMenu/Nav/Routes'
import './intl/archive.intl'

const ArchivePage = () => {
  const { isDesktop } = useDeviceWidth()
  const wrapper = isDesktop ? 'nsi' : 'nsi nsi-journal'

  return (
    <React.Fragment>
      {isDesktop && (
        <div className="top-menu is-submenu">
          <Links routes={archiveRoutes} />
        </div>
      )}

      <div className={wrapper}>
        <AppRoutes routes={archiveRoutes} fallback="" />
      </div>
    </React.Fragment>
  )
}

export default ArchivePage
