import classnames from 'classnames'
import { useIntl } from 'react-intl'

type Mode = 'groups' | 'place'

interface IProps {
  mode: Mode
  onChange: (mode: Mode) => void
  groupsCount: number
  equipmentCount: number
}

const ModeToggle = ({ mode, onChange, groupsCount, equipmentCount }: IProps) => {
  const intl = useIntl()
  const placeClass = classnames('history-toggle__btn', { 'is-selected': mode === 'place' })
  const groupsClass = classnames('history-toggle__btn', { 'is-selected': mode === 'groups' })

  const handleChange = (clickedMode: Mode) => {
    if (clickedMode !== mode) onChange(clickedMode)
  }

  const modeStyle = { width: 'fit-content', padding: '0 10px' }
  return (
    <div className="history-toggle" style={{ width: 'fit-content' }}>
      <div className={placeClass} onClick={() => handleChange('place')} style={modeStyle}>
        {intl.formatMessage({ id: 'map.ae.groups.equipment' })} ({equipmentCount})
      </div>
      <div className={groupsClass} onClick={() => handleChange('groups')} style={modeStyle}>
        {intl.formatMessage({ id: 'map.ae.groups' })} ({groupsCount})
      </div>
    </div>
  )
}

export default ModeToggle
