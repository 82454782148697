import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import { Equipment } from 'au-nsi/equipment'
import { ChartMode, VectorDiagramState } from './vector.reducers'
import VectorCharts from './VectorCharts'
import VectorChartsHeader from './VectorChartsHeader'
import VectorCircuitAndGraph from './VectorCircuit'

/**
 * Раздел с графиками на странице несимметрии. Включает хэдер с элеменами переключения
 * между линейными графиками и вектроной диаграммой и сами эти компоненты
 */
const VectorDiagrams = (props: Props) => {
  const device = props.selectedRegistrators[0]

  if (!device) {
    return <div className="pq-chart-container" />
  }

  const charts =
    props.chartMode === 'vector' ? (
      <VectorCircuitAndGraph registrator={device} vectorDiagram={props.vectorDiagram} />
    ) : (
      <VectorCharts device={device} />
    )

  return (
    <div className="pq-chart-container">
      <VectorChartsHeader />
      {charts}
    </div>
  )
}

interface Props {
  registrators: Equipment[]
  selectedRegistrators: Equipment[]
  chartMode: ChartMode
  vectorDiagram: VectorDiagramState
}

const mapStateToProps = (state: ReduxState) => {
  return {
    registrators: state.nsi.equipment,
    chartMode: state.vector.chartMode,
    vectorDiagram: state.vector.vectorDiagram,
  }
}

export default connect(mapStateToProps)(VectorDiagrams)
