import { useEffect, useMemo, useState } from 'react'
import http from 'utils/http'

/**
 * Загрузчик бесконечно прокручиваемого списка
 */
const useInfiniteLoader = <T extends Item>({ url, query, size = 20, autoreload = true }: LoaderOptions) => {
  const [state, setState] = useState<LoaderState<T>>({ results: [], nextPage: null, isLoading: true })
  const queue = useMemo(() => new LoaderQueue(), [])

  const setResults = (setter: (prev: T[]) => T[]) => {
    setState((prev) => ({ ...prev, results: setter(prev.results) }))
  }

  const reload = () => {
    setState((prev) => ({ ...prev, isLoading: true }))

    queue.add(() =>
      http.get(formatURL(null)).then((r) => {
        const { results, nextPage } = r.data
        setState({ results, nextPage, isLoading: false })
      })
    )
  }

  // загрузка списка с нуля при изменении параметров запроса
  useEffect(() => {
    if (autoreload) reload()
  }, [url, query])

  const formatURL = (nextPage: string) => {
    let result = url + '?size=' + size
    if (nextPage) result += '&page=' + nextPage

    for (const [key, value] of Object.entries(query)) {
      if (value) result += `&${key}=${encodeURIComponent(value)}`
    }

    return result
  }

  // загрузка следующего куска списка
  const loadMore = () => {
    if (!state.nextPage) return

    queue.add(() =>
      http.get(formatURL(state.nextPage)).then((r) => {
        const { results, nextPage } = r.data
        setState((prev) => ({ ...prev, results: [...prev.results, ...results], nextPage }))
      })
    )
  }

  const { results, isLoading } = state
  return { results, isLoading, isMore: state.nextPage != null, loadMore, reload, setResults }
}

// очередь запросов необходима в случае если пользователь начнет быстро менять фильтры списка,
// чтобы не спамить сервер параллельными запросами, а отправить только один с последними настройками
// после завершения предыдущего
class LoaderQueue {
  private nextFn: () => Promise<void> = null
  private loading = false

  add(fn: () => Promise<void>) {
    this.nextFn = fn
    this.next()
  }

  private next() {
    if (!this.nextFn || this.loading) return

    const fn = this.nextFn
    this.loading = true
    this.nextFn = null

    fn().finally(() => {
      this.loading = false
      this.next()
    })
  }
}

interface LoaderOptions {
  url: string
  query: Record<string, string | number | boolean>
  size?: number
  autoreload?: boolean // перезагружать список при изменении query
}

interface LoaderState<T> {
  results: T[]
  nextPage: string
  isLoading: boolean
}

interface Item {
  id: string | number
}

export default useInfiniteLoader
