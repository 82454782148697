import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as Icon } from '../../icons/wireless-error.svg'
import ws from '../../services/ws/ws'
import HelpTooltip from '../../shared/HelpTooltip/HelpTooltip'

/**
 * Индикатор для отображения проблем с установлением ws соединения
 */
const ConnectionIndicator = () => {
  const intl = useIntl()
  const [connected, setConnected] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => ws.connected$.subscribe(setConnected, true), [])

  // показываем ошибку через 3 секунды после обрыва соединения (для установки начального соединения
  // или на случай если соединение тут же восстановилось)
  useEffect(() => {
    if (connected) return setShowError(false)

    const timer = setTimeout(() => setShowError(true), 3000)
    return () => clearTimeout(timer)
  }, [connected])

  if (connected || !showError) return null

  const icon = <Icon width="20" height="20" style={{ color: 'var(--danger-text)', marginRight: 12 }} />

  return (
    <HelpTooltip position="bottom" content={icon}>
      {intl.formatMessage({ id: 'errors.network.ws' })}
    </HelpTooltip>
  )
}

export default ConnectionIndicator
