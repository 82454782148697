import useCache from '../../../hooks/useCache'
import { useAppSelector } from '../../../redux/store'
import http from '../../../utils/http'
import { showError } from '../../../utils/notifications'
import { selectParameterOptions } from '../../Parameters/params.selectors'
import { loadCalendars } from '../../Scheduler/Calendar/calendar.api'

/**
 * Хук для загрузки и форматирования опций, доступных для заполнения формы АЭПС
 */
export const useAEPSOptions = (open: boolean) => {
  const cacheOptions = { defaultValue: [], delayLoading: !open }

  const parameters = useAppSelector(selectParameterOptions)
  const calendarsState = useCache('scheduler.calendars', loadCalendars, cacheOptions)
  const zonesState = useCache('aeps.price_zones', loadPriceZones, cacheOptions)
  const subjectsState = useCache('aeps.federal_subjects', loadFederalSubjects, cacheOptions)

  const calendars = calendarsState.data.map((c) => ({ value: c.id, title: c.name }))
  const zones = zonesState.data.map((value) => ({ value, title: value }))
  const subjects = subjectsState.data.map((value) => ({ value, title: value }))

  return { parameters, calendars, zones, subjects }
}

const loadPriceZones = () => {
  return http
    .get<string[]>('/nsi/v1/aeps/soph/price_zones')
    .then((r) => r.data)
    .catch(() => {
      showError('nsi.aeps_panel.zones_loading_failed')
      return []
    })
}

const loadFederalSubjects = () => {
  return http
    .get<string[]>('/nsi/v1/aeps/coph/subjects')
    .then((r) => r.data)
    .catch(() => {
      showError('nsi.aeps_panel.subjects_loading_failed')
      return []
    })
}
