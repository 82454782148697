import { IDashboard } from 'au-nsi/dashboards'
import { Image } from 'au-nsi/images'
import { Ref, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import EditableText from '../../../../shared/Inputs/EditableText/EditableText'
import { selectAccessRights } from '../../../App/app.selectors'
import DashboardsTile from '../DashboardsTile'
import { useDashboardsMove } from '../useDashboardsMove'
import styles from './dashboardsfolder.module.css'

interface IProps {
  dashboards: IDashboard[]
  folder: IDashboard
  folderRef: Ref<any>
  images: Map<string, Image>

  onClose: () => void
  onClick: (d: IDashboard) => void
  onCopy: (d: IDashboard) => void
  onDelete: (d: IDashboard) => void
  onEdit: (d: IDashboard) => void
  onNameChange: (name) => void
  onDashboardRemove: (dashboardId: string) => void
}

export const DashboardsFolderModal = ({
  dashboards,
  folder,
  folderRef,
  images,
  onClose,
  onEdit,
  onDelete,
  onCopy,
  onClick,
  onNameChange,
  onDashboardRemove,
}: IProps) => {
  const modalRef = useRef(null)
  const [shouldUpdatePosition, setShouldUpdatePosition] = useState({ update: false })

  const { allowEditing, draggingElement } = useSelector((state: ReduxState) => {
    return {
      draggingElement: state.dashboards.draggingId,
      allowEditing: selectAccessRights(state)['dashboards:update'],
    }
  })

  const { onDragStart } = useDashboardsMove({
    items: [...dashboards],
    filter: false,
    onmouseup: (e, dashboardId) => {
      const box = modalRef.current.getBoundingClientRect()
      dashboardId &&
        (box.left > e.clientX || box.top > e.clientY || box.right < e.clientX || box.bottom < e.clientY) &&
        onDashboardRemove(dashboardId)
    },
  })

  useEffect(() => {
    /* Handle click outside and esc key */

    const onclick = (e) => e.target.id === 'dashboards-shadow' && onClose()
    const onkeyup = (e) => e.key === 'Escape' && onClose()

    document.addEventListener('click', onclick)
    document.addEventListener('keyup', onkeyup)

    return () => {
      document.removeEventListener('click', onclick)
      document.removeEventListener('keypress', onkeyup)
    }
  }, [])

  useLayoutEffect(() => {
    /* Animate modal open */
    const modal = modalRef && modalRef.current
    if (!modal) return

    modal.setAttribute('style', getStyles(folderRef))

    modal.setAttribute(
      'class',
      `${styles.dashboardsFolderModal} ${styles.dashboardsFolderModal_active} app-modal is-lg`
    )
    setTimeout(() => modal.setAttribute('style', 'position: static; padding: 0;'), 450)
    /* Updating tiles positions after animation end. For useFLip hook */
    setTimeout(() => setShouldUpdatePosition({ update: true }), 1000)
  }, [modalRef])

  return ReactDOM.createPortal(
    <div id={'dashboards-shadow'} className={'app-modal__shadow'}>
      <div ref={modalRef} className={styles.dashboardsFolderModal}>
        <div className={styles.folderModalHeader}>
          <div className={styles.folderModalName}>
            <EditableText
              style={{ background: 'transparent' }}
              allowEditing={allowEditing}
              onChange={onNameChange}
              value={folder.folder_name}
            />
          </div>
        </div>
        <div className={styles.folderModalContent}>
          {dashboards.map((d) => (
            <DashboardsTile
              key={d.name + d.id}
              dashboard={d}
              dashboardsLength={dashboards.length}
              allowEditing={allowEditing}
              image={images.get(d.image_id)}
              title={d.name}
              onClick={onClick}
              onCopy={onCopy}
              onDelete={onDelete}
              onEdit={onEdit}
              onDragStart={(e) => onDragStart(e as any, d.id)}
              isDrag={draggingElement === d.id}
              isDrop={false}
              isDropFolder={false}
              shouldPositionUpdate={shouldUpdatePosition}
            />
          ))}
        </div>
      </div>
    </div>,
    document.getElementById('app')
  )
}

const getStyles = (folderRef) => {
  const box = folderRef && folderRef.current && folderRef.current.getBoundingClientRect()
  return box
    ? `
          width: ${box.width}px;
          height: ${box.height}px;
          left: calc(${box.left + box.width / 2}px);
          top: calc(${box.top + window.scrollY - window.pageYOffset - box.height / 2}px);
        `
    : ``
}
