import { Chart } from '../Chart'

/**
 * Плагин для добавления возможности панорамирования
 */
class PanPlugin {
  private moving = false
  private startX = 0
  private startY = 0

  constructor(private chart: Chart) {
    chart.mouseDownHandlers.push(this.onMouseDown)
    chart.mouseMoveHandlers.push(this.onMouseMove)
    chart.mouseUpHandlers.push(this.onMouseUp)
    chart.mouseLeaveHandlers.push(this.onMouseLeave)
  }

  private onMouseDown = (e: React.MouseEvent) => {
    if (this.chart.props.controls.pan) {
      this.moving = true
      this.startX = e.clientX
      this.startY = e.clientY
    }
  }

  private onMouseMove = (e: React.MouseEvent) => {
    if (!this.moving) return

    const dx = e.clientX - this.startX
    const dy = e.clientY - this.startY

    // право/лево - сдвиг времени
    if (Math.abs(dx) > 2) {
      this.startX = e.clientX
      const dt = -this.px2time(dx)

      this.chart.props.services[0].clock.movePlayerTime(dt)
    }

    // верх/низ - сдвиг значений
    if (Math.abs(dy) > 2) {
      this.startY = e.clientY
      // по Y у каждой оси свои границы, поэтому вычисляем новые значения для каждой отдельно
      const { axes } = this.chart.props.settings
      const minY = [...this.chart.state.minY]
      const maxY = [...this.chart.state.maxY]

      for (let i = 0; i < minY.length; i++) {
        const axis = axes[i]
        const dv = (dy * (maxY[i] - minY[i])) / this.chart.innerHeight

        const nextMinY = minY[i] + dv
        const nextMaxY = maxY[i] + dv

        // проверка выхода за указанные пользователем границы
        const isAllowed = (axis.minY == null || axis.minY <= nextMinY) && (axis.maxY == null || axis.maxY >= nextMaxY)

        if (isAllowed) {
          minY[i] = nextMinY
          maxY[i] = nextMaxY
        }
      }

      this.chart.setState({ minY, maxY })
    }
  }

  private onMouseUp = () => {
    if (this.moving) this.moving = false
  }

  private onMouseLeave = () => {
    if (this.moving) this.moving = false
  }

  private px2time(dx: number): number {
    const { t0, t1 } = this.chart
    return (dx * (t1 - t0)) / this.chart.innerWidth
  }
}

export default PanPlugin
