import React, { useState } from 'react'
import FormPanel from './FormPanel'

const PanelList = (props: Props) => {
  const [openPanels, setOpenPanels] = useState<{ [id: string]: boolean }>({})

  const getOpenState = (id: string) => {
    let open = openPanels[id]
    if (open == null && props.defaultOpen) {
      open = true
    }

    return open
  }

  const handleToggle = (id: string) => {
    const open = getOpenState(id)
    setOpenPanels({ ...openPanels, [id]: !open })
  }

  const panels = props.items.map((item) => {
    const id = item.title
    const open = getOpenState(id)

    return (
      <FormPanel key={id} id={id} title={id} open={open} onToggle={handleToggle}>
        {item.children}
      </FormPanel>
    )
  })

  return <React.Fragment>{panels}</React.Fragment>
}

interface PanelItem {
  title: string
  children: any
}

interface Props {
  items: PanelItem[]
  defaultOpen: boolean
}

export default PanelList
