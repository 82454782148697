import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**
 * Настройки микросервисов (таких как DQ, LFO, etc.)
 */
export interface ServiceSettingsState {
  [name: string]: any
}

interface ServiceSettings {
  name: string
  settings: any
}

const slice = createSlice({
  name: 'service_settings',
  initialState: {} as ServiceSettingsState,
  reducers: {
    set(state, action: PayloadAction<ServiceSettings>) {
      const { name, settings } = action.payload
      state[name] = settings
    },
  },
})

export const actions = slice.actions
export default slice.reducer
