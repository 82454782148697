import React from 'react'
import { useIntl } from 'react-intl'
import Dropdown from '../../Inputs/Dropdown'
import NumberInput from '../../Inputs/NumberInput'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'

/**
 * Инпут для ввода числа которое может быть undefined. Для того чтобы пользователь выбрал
 * вводить число или оставить его undefined добавлен дополнительный дропдаун.
 */
const OptionalNumberInput = (props: Props) => {
  const intl = useIntl()

  const options = [
    { value: true, title: intl.formatMessage({ id: props.labelForValue }) },
    { value: false, title: intl.formatMessage({ id: props.labelForUndefined }) },
  ]

  const handleOption = (option: boolean) => {
    const value = option ? 0 : undefined
    props.onChange(value, props.name)
  }

  const { value } = props

  const input = (
    <NumberInput
      name={props.name}
      type="parameter"
      value={value}
      onChange={props.onChange}
      baseUnit={props.baseUnit}
      displayPrefix={props.displayPrefix}
      displayUnit={props.displayUnit}
      units={props.units}
      prefixes={props.prefixes}
    />
  )

  return (
    <div>
      <div className="system__label">{intl.formatMessage({ id: props.label })}</div>
      <div className="system__input-wrapper chart__settings-row-block">
        <Dropdown name="fill" value={value != null} options={options} onChange={handleOption} />

        {value != null && input}
      </div>
    </div>
  )
}

interface Props {
  baseUnit: SiUnit
  displayPrefix: SiPrefix
  displayUnit: SiUnit
  units: { [id: string]: SiUnit }
  prefixes: { [id: string]: SiPrefix }
  label: string
  labelForUndefined: string
  labelForValue: string
  name: string
  onChange: (value: number, name: string) => void
  value: number
}

export default React.memo(OptionalNumberInput)
