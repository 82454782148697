import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import actions from './image.actions'
import styles from './image.module.css'
import { generateDataURL } from './image.utils'
import { CreateImageDTO } from './images.types'

/**
 * Форма загрузки изображений: выводит предпросмотр изображений выбранных пользователем в UploadInput
 * с возможностью изменить название файла. При сохранении отправляет изображения на сервер.
 */
const UploadForm = (props: Props) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  // локальная копия для редактировании имен
  const [images, setImages] = React.useState<CreateImageDTO[]>([])

  // кэшируем url т.к. их генерация может использовать преобразование изображения в base64
  const urls = React.useMemo(() => images.map((image) => generateDataURL(image)), [images.length])

  React.useEffect(() => {
    setImages(props.images)
  }, [props.images])

  const handleSave = () => {
    dispatch(actions.createImages(images))
    props.onClose()
  }

  const handleChange = (e) => {
    const i = +e.target.dataset.index
    const { value } = e.target
    const image = { ...images[i], name: value }
    setImages([...images.slice(0, i), image, ...images.slice(i + 1)])
  }

  const rows = images.map((image, i) => {
    return (
      <div className={styles.formRow} key={i}>
        <div>
          <input data-index={i} className="nsi-input" value={image.name} onChange={handleChange} />
        </div>
        <img alt="" src={urls[i]} />
      </div>
    )
  })

  const className = rows.length > 1 ? 'system__grid' : ''

  return (
    <Modal onClose={props.onClose} closeOnClickOutside={false}>
      <div>
        <h2>{intl.formatMessage({ id: 'system.images.upload_title' })}</h2>
        <div className={className}>{rows}</div>
        <ModalFooter onCancel={props.onClose} onSave={handleSave} />
      </div>
    </Modal>
  )
}

interface Props {
  images: CreateImageDTO[]
  onClose: () => void
}

export default UploadForm
