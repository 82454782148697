import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import ToggleWithLabel from '../../../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../../../shared/Modal/Modal'
import ModalFooter from '../../../../../shared/Modal/ModalFooter'
import { formatMemory } from '../../../../../utils/misc'
import { CurrentUserState } from '../../../../App/user.reducers'
import { fileToBase64, parseFilename } from '../../docs.utils'
import { IDocument } from 'au-nsi/documents'
import { IAccessSettings } from 'au-nsi/shared'

interface IProps {
  files: FileList
  parent_id?: string
  resource: IDocument['resource']
  resource_item_id: IDocument['resource_item_id']
  onClose: () => void
  onSubmit: (documents: Partial<IDocument>[]) => void
  requestAccess: boolean
}

/**
 * Модальное окно для загрузки документов.
 *
 * Прокидывает в onSubmit подготовленные к загрузке документы, после подтверждения пользователем.
 */
const UploadFilesModal = (props: IProps) => {
  const files = Array.from(props.files)

  const intl = useIntl()
  const user = useSelector((state: ReduxState) => state.user)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [unrestricted, setUnrestricted] = useState(true)

  const handleUpload = async () => {
    setLoading(true)

    try {
      const uploadFiles: Partial<IDocument>[] = []

      for (const file of files) {
        const { name, extension } = parseFilename(file.name)
        const access = generateAccessSettings(unrestricted, user)
        const base64content = await fileToBase64(file)

        uploadFiles.push({
          name,
          extension,
          access,
          size_bytes: file.size,
          resource: props.resource,
          resource_item_id: props.resource_item_id || undefined,
          content: base64content,
          parent_id: props.parent_id,
          type: file.type,
        })
      }

      return props.onSubmit(uploadFiles)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  let total = 0

  // таблица с названиями выбранных файлов, их размерами и индикатором прогресса загрузки
  const rows = files.map((file, i) => {
    total += file.size

    return (
      <tr key={i}>
        <td>
          <span>{file.name}</span>
        </td>
        <td align="right" className="text--nowrap">
          {formatMemory(file.size / 1e3)}
        </td>
      </tr>
    )
  })

  // общий размер всех загружаемых файлов
  if (rows.length > 1) {
    rows.push(
      <tr key={files.length}>
        <td style={{ paddingTop: 14 }}>{intl.formatMessage({ id: 'documents.total_upload_size' })}</td>
        <td style={{ paddingTop: 14 }} align="right">
          {formatMemory(total / 1e3)}
        </td>
      </tr>
    )
  }

  const accessLabel = user.organization_id
    ? intl.formatMessage({ id: 'documents.upload_files.share_organization' })
    : intl.formatMessage({ id: 'documents.upload_files.share_everyone' })

  return (
    <Modal onClose={() => props.onClose()} closeOnClickOutside={false}>
      <h2>{intl.formatMessage({ id: 'documents.upload_files' })}</h2>

      <table className="nsi-settings-table" style={{ width: '100%' }}>
        <tbody>{rows}</tbody>
      </table>

      {props.requestAccess && (
        <div style={{ marginTop: '1rem' }}>
          <ToggleWithLabel name="" label={accessLabel} value={unrestricted} onChange={setUnrestricted} />
        </div>
      )}

      <ModalFooter
        loading={loading}
        error={error}
        onCancel={props.onClose}
        onSave={handleUpload}
        saveMessage={intl.formatMessage({ id: 'documents.confirm_upload' })}
      />
    </Modal>
  )
}

const generateAccessSettings = (unrestricted: boolean, user: CurrentUserState): IAccessSettings => {
  const isAdmin = user.id === 1 || user.role_id === 1
  const isOrgMember = user.organization_id != null

  switch (true) {
    // виден всем пользователям системы
    case unrestricted && !isOrgMember:
      return { restricted: false, users: [], roles: [], orgs: [] }
    // виден всем пользователям из текущей организации
    case unrestricted && isOrgMember:
      return { restricted: true, users: [], roles: [], orgs: [user.organization_id] }
    // виден только админам
    case !unrestricted && isAdmin:
      return { restricted: true, users: [], roles: [], orgs: [] }
    // виден только админам и текущему пользователю
    default:
      return { restricted: true, users: [user.id], roles: [], orgs: [] }
  }
}

export default UploadFilesModal
