import { INumberFormatSettings } from 'au-nsi/dashboards'

export const defaultFormat: INumberFormatSettings = {
  useGrouping: false,
  minimumIntegerDigits: 1,
  maximumFractionDigits: 4,
  minimumFractionDigits: 0,
}

export const createFormatter = (format: INumberFormatSettings, locale: string): Formatter => {
  const base = format?.base

  if (base === 16 || base === 2) {
    const prefix = format.prefix || ''

    return {
      format: (v: number) => {
        let str = v.toString(base).padStart(format.minimumIntegerDigits, '0')
        if (!format.lowerCase) str = str.toUpperCase()

        return prefix + str
      },
    }
  }

  return new Intl.NumberFormat(locale, format || defaultFormat)
}

interface Formatter {
  format(v: number): string
}
