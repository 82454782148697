import React from 'react'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import EditableText from '../../../shared/Inputs/EditableText/EditableText'

const TitleRow = (props: Props) => {
  const updateColor = (color: string) => {
    props.onColorChange(color)
  }

  return (
    <div className="nsi-row">
      <ColorPicker color={props.color} onChange={updateColor} />
      <div style={{ flexGrow: 1, fontSize: '24px', marginLeft: '8px' }}>
        <EditableText
          value={props.name}
          validator={props.validator}
          onChange={props.onNameChange}
          allowEditing={props.allowEditing}
        />
      </div>
    </div>
  )
}

interface Props {
  allowEditing: boolean
  color: string
  name: string
  onColorChange: (color: string) => void
  onNameChange: (name: string) => void
  validator: (name: string) => string
}

export default React.memo(TitleRow)
