import { API_URL, defaultLogo, defaultTitle } from '../shared/constants'

/**
 * Выставление заголовка и логотипа в табе браузера
 */
export const applyTitleAndLogo = (title: string, logo: string) => {
  document.title = title || defaultTitle

  const link = document.querySelector("link[rel='icon']") as HTMLLinkElement
  link.href = logo ? API_URL + `/nsi/v1/images/${logo}/raw` : defaultLogo
}
