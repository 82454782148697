import { ICommand, ICommandType } from 'au-nsi/commands'
import { AxiosError } from 'axios'
import http from '../../utils/http'
import { showError, showInfo } from '../../utils/notifications'

export const commandKeyService = {
  encryptCommandID: (id: ICommand['id']) => 'c:' + id,
  encryptCommandTypeID: (id: ICommandType['id']) => 't:' + id,
  decryptKey: (key: string) => {
    const [prefix, id] = key.split(':')
    return { id: +id, is_command: prefix === 'c', is_command_type: prefix === 't' }
  },
}

export const newCommand = (type: ICommandType): ICommand => {
  const args = {}
  const access = { restricted: false, users: [], roles: [], orgs: [] }

  for (const arg of type.args_schema) {
    args[arg.id] = convertValueToType(undefined, arg.type)
  }

  return { id: 0, type: type.id, name: '', args, access }
}

export const newCommandType = (): ICommandType => {
  return {
    id: 0,
    cmd: '',
    name: '',
    description: '',
    target_type: 'device',
    allowed_targets: [],
    args_schema: [],
  }
}

export const convertValueToType = (value: any, type: string) => {
  switch (type) {
    case 'number':
      return typeof value === 'number' ? value : undefined
    case 'string':
      return typeof value === 'string' ? value : ''
    case 'boolean':
      return typeof value === 'boolean' ? value : false
    default:
      return value
  }
}

export const validateArgsSchema = (commandType: ICommandType): string => {
  const ids = new Set<string>()

  for (const arg of commandType.args_schema) {
    if (ids.has(arg.id)) return 'commands.errors.duplicate_arg_id'

    ids.add(arg.id)
  }

  return null
}

export const sendCommand = ({ command_id, target, args }: CommandOptions) => {
  return http
    .post(`/nsi/v1/commands/${command_id}/send`, { target, args })
    .then(() => showInfo('commands.send_success'))
    .catch((err: AxiosError) => {
      console.error(err)

      const response = err.response?.data?.toString()
      if (response) showError(response, response)
      else showError('commands.send_error')
    })
}

export const sendCommands = async (commands: CommandOptions[]) => {
  for (const command of commands) {
    await sendCommand(command)
  }
}

/**
 * Готова ли команда для отправки или необходимо вывести
 * модальное окно для ввода незаполненных аргументов
 */
export const isCompleteCommand = (
  options: CommandOptions,
  commands: Map<number, ICommand>,
  commandTypes: Map<number, ICommandType>
) => {
  const command = commands.get(options.command_id)
  if (!command) return true

  const commandType = commandTypes.get(command.type)
  if (!commandType) return true

  for (const arg of commandType.args_schema) {
    if (!arg.is_constant && options.args[arg.id] == null) return false
  }

  return true
}

export const validateCommand = (
  { command_id, target, args }: CommandOptions,
  commands: Map<number, ICommand>,
  commandTypes: Map<number, ICommandType>
) => {
  if (!command_id) return 'commands.errors.missing_command_id'
  if (!target) return 'commands.errors.missing_target'

  const command = commands.get(command_id)
  const commandType = command && commandTypes.get(command.type)
  if (!commandType) return 'commands.errors.missing_command_id'

  for (const arg of commandType.args_schema) {
    if (!arg.is_constant && args[arg.id] == null) return 'commands.errors.missing_arg'
  }

  return null
}

/**
 * Проверка изменилась ли схема константных аргументов команды
 */
export const checkArgsSchemaChange = (prev: ICommandType, draft: ICommandType) => {
  const prevArgs = prev.args_schema.filter((a) => a.is_constant)
  const draftArgs = draft.args_schema.filter((a) => a.is_constant)

  if (prevArgs.length !== draftArgs.length) return true

  for (const arg of prevArgs) {
    const draftArg = draftArgs.find((a) => a.id === arg.id)
    if (!draftArg || draftArg.type !== arg.type) return true
  }

  return false
}

/**
 * Информация о том, какую команду, кому и с какими аргументами отправлять
 */
export interface CommandOptions {
  command_id: number
  target: string
  args: Record<string, any>
}
