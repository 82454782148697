import { Select } from '@alterouniversal/au-react-components'
import React from 'react'
import { API_URL } from '../../../../shared/constants'
import InputRow from '../../../../shared/Inputs/InputRow'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import http, { handleHttpError } from '../../../../utils/http'
import { showError } from '../../../../utils/notifications'
import { DataRatesTable } from './DataRatesTable/DataRatesTable'
import { DataRates, url } from './dataRateUtils'

// Модальное окно для редактирования настроек скорости приема данных ресивером
class DataRateModal extends React.Component<Props, State> {
  private sourceOptions =
    this.props.protocol === 'IEC104'
      ? [{ value: 'user', label: this.props.translations['equipment.data_rate_source.user'] }]
      : [
          { value: 'device', label: this.props.translations['equipment.data_rate_source.device'] },
          { value: 'user', label: this.props.translations['equipment.data_rate_source.user'] },
        ]

  state: State = {
    loading: false,
    draft: this.props.draft,
    data_rate: this.props.data_rate,
    data_rate_source: this.props.data_rate_source,
  }

  private handleChange = (value, key) => {
    this.setState({ [key]: value } as any)
  }

  private handleSave = () => {
    this.setState({ ...this.state, loading: true })

    for (const dr of this.state.draft.data_rates) {
      if (!dr.device_id || !dr.data_rate) {
        showError(this.props.intl.formatMessage({ id: 'router.errors.all_fields_must_be_filled' }))
        this.setState({ ...this.state, loading: false })
        return false
      }
    }

    http
      .post(API_URL + url, { ...this.state.draft, device_id: this.props.device_id })
      .then(() => {
        this.setState({ ...this.state, loading: false })
        this.props.onChange(this.state.data_rate, this.state.data_rate_source)
        this.props.onClose()
      })
      .catch((err) => {
        this.setState({ ...this.state, loading: false })
        handleHttpError(err)
      })
  }

  private updateDraft = (draft: DataRates) => {
    this.setState({ ...this.state, draft: draft })
  }

  // если выбран источник "Устройство" то вместо инпута выводим нередактируемое значение
  private renderRateInput = () => {
    return (
      <NumberInput
        fullWidth={true}
        disabled={this.state.data_rate_source !== 'user'}
        name="data_rate"
        value={this.state.data_rate}
        onChange={this.handleChange}
        min={1e-6}
      />
    )
  }

  render() {
    const { translations } = this.props

    return (
      <Modal size={'lg'} onClose={this.props.onClose} closeOnClickOutside={false} closeOnEscape={true}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '6px', marginRight: '24px' }}>
          <InputRow label={translations['equipment.data_rate_source']}>
            <Select
              name="data_rate_source"
              options={this.sourceOptions}
              value={this.state.data_rate_source}
              onChange={this.handleChange}
            />
          </InputRow>

          <InputRow label={translations['equipment.data_rate']}>{this.renderRateInput()}</InputRow>
        </div>
        <DataRatesTable
          editable={this.state.data_rate_source === 'user'}
          updateDraft={this.updateDraft}
          draft={this.state.draft}
          device_id={this.props.device_id}
        />
        <ModalFooter loading={this.state.loading} onCancel={this.props.onClose} onSave={this.handleSave} />
      </Modal>
    )
  }
}

interface Props {
  intl: any
  draft: DataRates
  device_id: string
  data_rate: number
  data_rate_source: string
  translations: { [x: string]: string }
  protocol: string
  onChange: (value, source) => void
  onClose: () => void
}

interface State {
  loading: boolean
  draft: DataRates
  data_rate: number
  data_rate_source: string
}

export default DataRateModal
