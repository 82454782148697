import { formatDistanceStrict } from '../../utils/lang'

const units = [
  { unit: 'second', value: 1 },
  { unit: 'minute', value: 60 },
  { unit: 'hour', value: 3600 },
  { unit: 'day', value: 86400 },
  { unit: 'month', value: 2592000 },
  { unit: 'inf', value: Number.POSITIVE_INFINITY },
]

const RelativeTime = (props: Props) => {
  if (!props.ts) return null

  const diff = (props.ts - Date.now()) / 1000
  const absDiff = Math.abs(diff)

  for (let i = 1; i < units.length; i++) {
    if (absDiff < units[i].value) {
      const value = (diff / units[i - 1].value) | 0
      const unit = units[i - 1].unit

      return <span>{formatDistanceStrict(value, unit as Intl.RelativeTimeFormatUnit)}</span>
    }
  }
}

interface Props {
  ts: number
}

export default RelativeTime
