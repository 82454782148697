import InputRow from '../../../../../../shared/Inputs/InputRow'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { IntlShape } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import { useEffect, useMemo } from 'react'
import { OrganizationType } from 'au-nsi/organizations'
import { Select } from '@alterouniversal/au-react-components'

interface IProps {
  organizationType: OrganizationType
  onChange: (value: any, name: string) => void
  editing: boolean
  intl: IntlShape
  shouldRenderName: boolean
}

export const NameAndRoleControls = ({ organizationType, onChange, editing, intl, shouldRenderName }: IProps) => {
  const roles = useSelector((state: ReduxState) => state.roles.items)

  const rolesOptions = useMemo(() => roles.map((role) => ({ label: role.name, value: role.id })), [roles])
  const selectedRole = rolesOptions.find((r) => r.value === organizationType.role_id)

  // AE-298: при создании организации если доступна только одна роль, то автоматически выставляем ее
  useEffect(() => {
    if (editing && !organizationType.role_id && rolesOptions.length === 1) {
      onChange(rolesOptions[0].value, 'role_id')
    }
  }, [])

  const renderEditing = () => (
    <div className={'system__grid'}>
      {shouldRenderName && (
        <InputRow label={intl.formatMessage({ id: 'catalogs.name' })}>
          <TextInput value={organizationType.name} onChange={onChange} name={'name'} required={true} />
        </InputRow>
      )}
      <InputRow label={intl.formatMessage({ id: 'catalogs.organization.role' })}>
        <Select
          value={organizationType.role_id}
          name={'role_id'}
          onChange={onChange}
          options={rolesOptions}
          required={true}
          disabled={organizationType.role_id && rolesOptions.length === 1}
        />
      </InputRow>
    </div>
  )

  const renderStatic = () => (
    <>
      {shouldRenderName && <h2>{organizationType.name}</h2>}
      <h3>{intl.formatMessage({ id: 'catalogs.organization.role' }) + ': ' + (selectedRole?.label || '-')}</h3>
    </>
  )

  return editing ? renderEditing() : renderStatic()
}
