import { IDashboardComponent } from 'au-nsi/dashboards'

/**
 * Генерирование позиции для очередного виджета на экране.
 * Свободная позиция находится автоматически.
 * Используется динамический алгоритм нахождения максимальной нулевой подматрицы.
 * См. https://e-maxx.ru/algo/maximum_zero_submatrix
 * */
export const generatePosition = (
  components: IDashboardComponent[],
  id: string,
  x: number,
  y: number,
  w = 0.3,
  h = 0.2
) => {
  let rectangle: IRectangle
  if (x !== null && x !== undefined && y !== null && y !== undefined) {
    rectangle = { x, y, w, h }
  } else {
    const matrix = buildMatrixByComponents(components)
    rectangle = maxRectangle(matrix)

    rectangle.w = Math.max(Math.min(rectangle.w, w), w * 0.75)
    rectangle.h = Math.max(Math.min(rectangle.h, h), h * 0.75)
    if (rectangle.x + rectangle.w > 1) rectangle.x -= rectangle.x + rectangle.w - 1
  }

  const z = getNextLayer(components)
  const position = { id: 0, dashboard_id: id, ...rectangle, z }

  if (position.x + position.w > 1) {
    position.x = 1 - position.w
  }

  return position
}

const matrixStep = 0.01
interface IRectangle {
  x: number
  w: number
  y: number
  h: number
}

const maxRectangle = (matrix: number[][]): IRectangle => {
  let maxArea = 0
  let ans: IRectangle = { x: 0, w: 0, h: 0, y: 0 }

  const d = Array(matrix[0].length).fill(-1)
  const d1 = Array(matrix[0].length).fill(-1)
  const d2 = Array(matrix[0].length).fill(-1)
  let st = []

  for (let i = 0; i < matrix.length; ++i) {
    for (let j = 0; j < matrix[i].length; ++j) if (matrix[i][j]) d[j] = i
    st = []

    for (let j = 0; j < matrix[i].length; ++j) {
      while (Boolean(st.length) && d[st[st.length - 1]] <= d[j]) st.pop()
      d1[j] = st.length === 0 ? -1 : st[st.length - 1]
      st.push(j)
    }
    st = []

    for (let j = matrix[i].length - 1; j >= 0; --j) {
      while (Boolean(st.length) && d[st[st.length - 1]] <= d[j]) st.pop()
      d2[j] = st.length === 0 ? matrix[i].length : st[st.length - 1]
      st.push(j)
    }

    for (let j = 0; j < matrix[i].length; ++j) {
      const area = (i - d[j]) * (d2[j] - d1[j] - 1)
      if (area > maxArea) {
        maxArea = area

        const heightK = window.innerWidth / window.innerHeight
        const leftTop = { x: d1[j] + 1, y: d[j + 1] }
        const rightDown = { x: d2[j] - 1, y: i }
        // В виду использования matrixStep и скейла height относитльно width, имеет смысл, для безопасности вычислений
        // проверять, что ничего не вышло за ноль.
        ans = {
          x: Math.max(0, leftTop.x * matrixStep),
          w: Math.max(0, (rightDown.x - leftTop.x) * matrixStep),
          y: Math.max((leftTop.y * matrixStep) / heightK, 0),
          h: Math.max(((rightDown.y - leftTop.y) * matrixStep) / heightK, 0),
        }
      }
    }
  }

  return ans
}

const buildMatrixByComponents = (components: IDashboardComponent[]): number[][] => {
  const heightK = window.innerWidth / window.innerHeight

  const n = Math.floor(1 / matrixStep)
  const matrix = Array(n)
    .fill(0)
    .map(() => Array(n).fill(0))

  for (const component of components) {
    for (let i = component.x; i <= component.x + component.w; i += matrixStep) {
      for (let j = component.y; j <= component.y + component.h; j += matrixStep) {
        const [matrixI, matrixJ] = [i / matrixStep, (j / matrixStep) * heightK].map(Math.floor)

        // В случае, если h компонента больше единцы (не рекомендуется, но допустимо), соответствующим образом
        // увеоичиваем матрицу
        while (matrixJ > matrix.length - 1) matrix.push(Array(n).fill(0))

        matrix[matrixJ][matrixI] = 1
      }
    }
  }

  return matrix
}

const getNextLayer = (components: IDashboardComponent[]): number => {
  let result = 1

  for (const c of components) {
    if (c.z >= result) result = c.z + 1
  }

  return result
}
