import { IGaugeRange } from 'au-nsi/dashboards'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import { useIntl } from 'react-intl'
import RemoveButton from '../Forms/RemoveButton'
import ColorPicker from '../Inputs/Colorpicker/ColorPicker'
import Dropdown from '../Inputs/Dropdown'
import NumberInput from '../Inputs/NumberInput'
import * as utils from './gauge.utils'

/**
 * Настройки выделенных участков на датчике (области предупреждения или тревоги)
 */
const GaugeRangeSettings = (props: Props) => {
  const intl = useIntl()
  const { range, index } = props

  const handleChange = (value: any, key: string) => {
    props.onChange({ ...range, [key]: value }, index)
  }

  const handleColorChange = (value: string) => {
    props.onChange({ ...range, color: value }, index)
  }

  const typeOptions = [
    { value: 'warning', title: intl.formatMessage({ id: 'dashboards.gauge.settings.warning' }) },
    { value: 'danger', title: intl.formatMessage({ id: 'dashboards.gauge.settings.danger' }) },
    { value: 'custom', title: intl.formatMessage({ id: 'dashboards.gauge.settings.custom' }) },
  ]

  const numberProps = { ...props.unitsInfo, fullWidth: true }

  const isFixedColor = range.type in utils.colors
  const color = isFixedColor ? utils.colors[range.type] : range.color

  return (
    <tr>
      <td>
        <Dropdown name="type" value={range.type} options={typeOptions} onChange={handleChange} />
      </td>
      <td>
        <NumberInput type="parameter" name="lower" value={range.lower} onChange={handleChange} {...numberProps} />
      </td>
      <td>
        <NumberInput type="parameter" name="upper" value={range.upper} onChange={handleChange} {...numberProps} />
      </td>
      <td>
        <ColorPicker color={color} onChange={handleColorChange} position="left" disabled={isFixedColor} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={() => props.onRemove(index)} />
      </td>
    </tr>
  )
}

interface Props {
  index: number
  range: IGaugeRange
  onChange: (range: IGaugeRange, index: number) => void
  onRemove: (index: number) => void
  unitsInfo: {
    units: Record<string, SiUnit>
    prefixes: Record<string, SiPrefix>
    baseUnit: SiUnit
    basePrefix: SiPrefix
    displayUnit: SiUnit
    displayPrefix: SiPrefix
  }
}

export default GaugeRangeSettings
