import { AnyCatalogWithInstances } from './catalogs.types'
import http, { handleHttpError, handleHttpResponse } from '../../utils/http'
import { ICatalogItem } from 'au-nsi/catalogs'

const API_PREFIX = '/nsi/v1/'

export const postCreateCatalog = async (catalog: AnyCatalogWithInstances): Promise<AnyCatalogWithInstances> => {
  const created: AnyCatalogWithInstances = await http
    .post(API_PREFIX + 'catalogs', {
      name: catalog.name,
      schema: catalog.schema,
      schema_tree: catalog.schema_tree,
      type: catalog.type,
    })
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return created
}

export const patchUpdateCatalog = async (catalog: AnyCatalogWithInstances): Promise<AnyCatalogWithInstances> => {
  const updated: AnyCatalogWithInstances = await http
    .patch(API_PREFIX + `catalogs/${catalog.id}`, {
      id: catalog.id,
      schema: catalog.schema,
      schema_tree: catalog.schema_tree,
      name: catalog.name,
      type: catalog.type,
    })
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return updated
}

export const requestCatalogDelete = async (catalog: AnyCatalogWithInstances): Promise<AnyCatalogWithInstances> => {
  const deleted: AnyCatalogWithInstances = await http
    .delete(API_PREFIX + `catalogs/${catalog.id}`)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return deleted
}

export const postCreateInstance = async (instance: ICatalogItem): Promise<ICatalogItem> => {
  const created: ICatalogItem = await http
    .post(API_PREFIX + 'catalog_items', instance)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return created
}

export const patchInstanceUpdate = async (instance: ICatalogItem): Promise<ICatalogItem> => {
  const updated: ICatalogItem = await http
    .patch(API_PREFIX + `catalog_items/${instance.id}`, instance)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return updated
}

export const requestInstanceDelete = async (instance: ICatalogItem): Promise<ICatalogItem> => {
  const deleted: ICatalogItem = await http
    .delete(API_PREFIX + `catalog_items/${instance.id}`)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return deleted
}
