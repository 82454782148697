import React from 'react'
import GanttService from '../../../services/gantt/gantt.service'
import { Boundaries } from '../../../shared/interfaces'
import rAF from '../../../utils/rAF'

class TimelineSlider extends React.Component<SliderProps> {
  private xMiddle = 0
  private xLeft = 0
  private xRight = 0

  private isOutside(x: number) {
    const { left, right } = this.props.boundaries
    return x < left || x > right
  }

  /* dragging */
  private startDragging = (e) => {
    this.xMiddle = e.clientX
    document.addEventListener('mousemove', this.moveSlider)
    document.addEventListener('mouseup', this.stopDragging)
    document.body.style.cursor = 'grabbing'
  }

  private stopDragging = () => {
    document.removeEventListener('mousemove', this.moveSlider)
    document.removeEventListener('mouseup', this.stopDragging)
    document.body.style.cursor = 'default'
  }

  private moveSlider = rAF<MouseEvent>((e) => {
    const x = e.clientX
    if (this.isOutside(x)) return this.stopDragging()

    const dx = x - this.xMiddle
    this.xMiddle = x
    const dt = this.pixels2time(dx)
    this.props.ganttService.setGanttTime(this.props.ganttTime + dt)
  })

  /* left resize */
  private startLeftResize = (e) => {
    this.xLeft = e.clientX
    document.addEventListener('mousemove', this.resizeLeft)
    document.addEventListener('mouseup', this.stopLeftResize)
    document.body.style.cursor = 'ew-resize'
  }

  private stopLeftResize = () => {
    document.removeEventListener('mousemove', this.resizeLeft)
    document.removeEventListener('mouseup', this.stopLeftResize)
    document.body.style.cursor = 'default'
  }

  private resizeLeft = rAF<MouseEvent>((e) => {
    const x = e.clientX
    if (this.isOutside(x)) return this.stopLeftResize()

    const dx = x - this.xLeft
    this.xLeft = x
    const dt = this.pixels2time(dx)
    this.props.ganttService.setFrame(this.props.ganttFrame - dt)
  })

  /* right resize */
  private startRightResize = (e) => {
    this.xRight = e.clientX
    document.addEventListener('mousemove', this.resizeRight)
    document.addEventListener('mouseup', this.stopRightResize)
    document.body.style.cursor = 'ew-resize'
  }

  private stopRightResize = () => {
    document.removeEventListener('mousemove', this.resizeRight)
    document.removeEventListener('mouseup', this.stopRightResize)
    document.body.style.cursor = 'default'
  }

  private resizeRight = rAF<MouseEvent>((e) => {
    const x = e.clientX
    if (this.isOutside(x)) return this.stopRightResize()

    const dx = x - this.xRight
    this.xRight = x
    const dt = this.pixels2time(dx)

    this.props.ganttService.setGanttTime(this.props.ganttTime + dt)
    this.props.ganttService.setFrame(this.props.ganttFrame + dt)
  })

  private pixels2time(dx: number) {
    return (dx * (this.props.t1 - this.props.t0)) / this.props.boundaries.width
  }

  private calcStyles() {
    const { boundaries, ganttTime, ganttFrame, t0, t1 } = this.props
    const { width } = boundaries
    const shiftFrame = t1 - t0
    const sliderWidth = Math.round((width * ganttFrame) / shiftFrame)
    const translateX = Math.round((width * (ganttTime - ganttFrame - t0)) / shiftFrame)

    return {
      width: sliderWidth + 'px',
      transform: `translateX(${translateX}px)`,
    }
  }

  render() {
    const style = this.calcStyles()

    return (
      <div className="gantt__slider" style={style}>
        <div className="gantt__slider-body" onMouseDown={this.startDragging} />
        <div className="gantt__slider-edge gantt__slider-left" onMouseDown={this.startLeftResize} />
        <div className="gantt__slider-edge gantt__slider-right" onMouseDown={this.startRightResize} />
      </div>
    )
  }
}

interface SliderProps {
  boundaries: Boundaries
  ganttService: GanttService
  ganttTime: number
  ganttFrame: number
  t0: number
  t1: number
}

export default TimelineSlider
