import { IButtonSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import PlusButton from '../../../shared/Forms/PlusButton'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import CommandSelectForm from '../../Commands/components/CommandSelectForm'

interface Props {
  commands: IButtonSettings['commands']
  onChange: (commands: IButtonSettings['commands'], name: 'commands') => void
}

/**
 * Настройки команд отправляемых по нажатию кнопки
 */
const ButtonSettingsCommands = ({ commands, onChange }: Props) => {
  const handleAdd = () => {
    onChange([...commands, { target: null, command_id: null, args: {} }], 'commands')
  }

  const handleRemove = (e) => {
    const index = +e.currentTarget.dataset.id
    const updates = [...commands.slice(0, index), ...commands.slice(index + 1)]
    onChange(updates, 'commands')
  }

  const handleChange = (value: any, key: string, index: number) => {
    const updates = produce(commands, (draft) => {
      draft[index][key] = value
    })
    onChange(updates, 'commands')
  }

  const rows = commands.map((command, i) => {
    return (
      <div key={i} style={{ display: 'flex', alignItems: 'center', columnGap: '1em', marginBottom: '1em' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', flexGrow: 1, columnGap: '1em' }}>
          <CommandSelectForm
            {...command}
            editing={true}
            required={false}
            onChange={(value, key) => handleChange(value, key, i)}
          />
        </div>

        <div style={{ transform: 'translateY(10px)' }}>
          <RemoveButton id={i} onRemove={handleRemove} />
        </div>
      </div>
    )
  })

  return (
    <>
      {rows}
      <PlusButton style={{ margin: 0 }} textId="dashboards.button.settings.add_command" onClick={handleAdd} />
    </>
  )
}

export default ButtonSettingsCommands
