import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import useDeviceWidth from '../../../hooks/useDeviceWidth'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import { filter, IRoute } from './nav.utils'

const AppRoutes = (props: Props) => {
  const { isDesktop } = useDeviceWidth()
  const modules = useSelector(selectEnabledModules)
  const rights = useSelector(selectAccessRights)

  const routes = filter(props.routes, rights, modules)
  const fallback = <div className={props.fallback} />

  const items = routes.map((route) => {
    const Page = route.component
    return <Route key={route.id} path={route.path} element={<Page />} />
  })

  return (
    <React.Suspense fallback={fallback}>
      <Routes>
        {props.children}
        {items}
        {isDesktop && <Route path="*" element={<Navigate to={routes[0].path} />} />}
      </Routes>
    </React.Suspense>
  )
}

interface Props {
  routes: IRoute[]
  fallback: string
  children?: React.ReactNode
}

export default AppRoutes
