import { IChartSettings2 } from 'au-nsi/dashboards'
import { Boundaries, Margins } from '../../interfaces'
import { Point } from '../chart.interfaces'

// верхняя граница графика должна быть выше максимального значения на 15-40 %
export const updateTopBoundary = (previous: number, max: number, span: number): number => {
  if (max > previous - span * 0.15 || max < previous - span * 0.4) {
    return max + span * 0.25
  }

  return previous
}

// поместить нижнюю границу ниже минимума на 5-20%
export const updateBottomBoundary = (previous: number, min: number, span: number): number => {
  if (min < previous + span * 0.05 || min > previous + span * 0.2) {
    return min - span * 0.1
  }

  return previous
}

// определить точка внутри или снаружи указанного прямоугольника
// координаты точки должны быть заданы относительно этого прямоугольника
// tolerance - допустимый выход за границу
export const isOutside = (point: Point, rect: Boundaries, tolerance: number): boolean => {
  const xOutside = point.x < -tolerance || point.x > rect.width + tolerance
  const yOutside = point.y < -tolerance || point.y > rect.height + tolerance
  return xOutside || yOutside
}

// минимальный размер зума в пикселях
export const zoomResolution = 20

// рассчитать координаты прямоугольника для отображения области зума
export const calculateZoom = (zoomStart: Point, mouse: Point, boundaries: Boundaries, margins: Margins) => {
  // границы видимой области графика
  const xMin = margins.left
  const xMax = boundaries.width - margins.right
  const yMin = margins.top
  const yMax = boundaries.height - margins.bottom

  // границы зума
  let x0 = Math.max(xMin, Math.min(zoomStart.x, mouse.x))
  let x1 = Math.min(xMax, Math.max(zoomStart.x, mouse.x))
  let y0 = Math.max(yMin, Math.min(zoomStart.y, mouse.y))
  let y1 = Math.min(yMax, Math.max(zoomStart.y, mouse.y))

  // при вертикальном движении растягивать зум на всю ширину по горизонтали
  if (x1 - x0 < zoomResolution && y1 - y0 > zoomResolution) {
    x0 = xMin
    x1 = xMax
  }

  // при горизонтальном движении растягивать на всю ширину по вертикали
  if (x1 - x0 > zoomResolution && y1 - y0 < zoomResolution) {
    y0 = yMin
    y1 = yMax
  }

  return { x: x0, y: y0, width: x1 - x0, height: y1 - y0 }
}

/**
 * Рассчитать стили для отображения тултипа
 * @param x X coordinate of the cursor relative to chart boundaries
 * @param height tooltip height
 * @param values number of values that will be displayed on the tooltip
 */
export const tooltipStyles = (x: number, height: number, values: number) => {
  // number of rows that can fit inside tooltip (16px is line height)
  const rows = Math.floor(height / 16)

  // number of columns necessary to fit all values
  const columns = Math.ceil(values / rows)

  // 80px is column width + 4px padding on both sides
  const minWidth = columns * 80 + 8

  // place tooltip on the left side of the cursor, unless it touches left chart boundary
  let translate = x - minWidth - 16

  if (translate < 0) {
    translate = x + 16
  }

  return { transform: `translateX(${translate}px)`, minWidth: minWidth + 'px' }
}

/**
 * Подсчет количества осей с каждой стороны графика
 */
export const countAxes = (settings: IChartSettings2) => {
  const result = { left: 0, right: 0 }

  for (const axis of settings.axes) {
    result[axis.position] += 1
  }

  return result
}
