import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { selectAccessRights } from '../../App/app.selectors'
import { selectParametersNames } from '../../Parameters/params.selectors'
import { useSettingsAPI } from './settings.hooks'
import renderFooter from '../system.footer'
import { LfoSettings } from 'au-nsi/settings'

const fields = [
  'frequency_range1',
  'frequency_range2',
  'step_length1',
  'step_length2',
  'window_in_seconds1',
  'window_in_seconds2',
]

const windowOptions = [
  { value: 'Blackman', title: 'Blackman' },
  { value: 'Hamming', title: 'Hamming' },
  { value: 'Hann', title: 'Hann' },
  { value: 'Rectangular', title: 'Rectangular' },
]

/**
 * Форма настроек микросервиса НЧК
 */
const LfoSettingsPage = () => {
  const allowEditing = useSelector(selectAccessRights)['settings:update']
  const translations = useSelector((s: ReduxState) => s.language.translations)
  const parameters = useSelector(selectParametersNames)
  const equipment = useSelector((s: ReduxState) => s.nsi.equipment)

  const state = useSettingsAPI<LfoSettings>('ms-lfo')
  const { draft } = state

  const handleInput = (value, name) => {
    state.updateDraft({ [name]: value })
  }

  if (state.settings == null || draft == null) {
    return null
  }

  const parameterOptions = ['Frequency', 'PowerActiveTriPhase'].map((id) => ({ id, name: parameters.get(id) || id }))

  const rows = fields.map((id) => {
    return (
      <InputRow key={id} label={translations['settings_lfo.' + id]}>
        <NumberInput
          name={id}
          type="number"
          value={draft[id]}
          onChange={handleInput}
          disabled={!state.editing}
          disabledStyle="text"
          fullWidth={true}
        />
      </InputRow>
    )
  })

  return (
    <div className="nsi-main__container">
      <h2 className="system__title">
        <FormattedMessage id="nav.system.lfo" />
      </h2>

      <Form editing={state.editing} onCancel={state.cancelDraft} onSubmit={state.updateSettings}>
        <div className="system__grid">
          {rows}

          <InputRow label={translations['settings_lfo.window_type']}>
            <Dropdown
              name="window_type"
              options={windowOptions}
              value={draft.window_type}
              onChange={handleInput}
              disabled={!state.editing}
            />
          </InputRow>

          <InputRow label={translations['settings_lfo.parameters']}>
            <MultiSelect
              name="parameters"
              options={parameterOptions}
              selection={draft.parameters}
              onChange={handleInput}
              disabled={!state.editing}
            />
          </InputRow>
        </div>

        <InputRow label={translations['settings_smsr.equipment']}>
          <MultiSelect
            name="equipment"
            options={equipment}
            selection={draft.equipment || []}
            onChange={handleInput}
            disabled={!state.editing}
          />
        </InputRow>

        {allowEditing && renderFooter(state)}
      </Form>
    </div>
  )
}

export default LfoSettingsPage
