import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import TableHeader from '../../../shared/Utils/TableHeader'
import { IChangelogItem } from './actions.types'
import ActionsRow from './ActionsRow'

const ActionsTable = (props: Props) => {
  const intl = useIntl()
  const translations = useSelector((s: ReduxState) => s.language.translations)

  const rows = props.items.map((item) => {
    return (
      <ActionsRow
        key={item.id}
        item={item}
        showDetails={props.showDetails}
        compactHeader={props.compactHeader}
        onSelect={props.onSelect}
        intl={intl}
        translations={translations}
      />
    )
  })

  const timeTrans = intl.formatMessage({ id: 'system.actions.time' })
  const actionTrans = intl.formatMessage({ id: 'system.actions.action' })
  const userTrans = intl.formatMessage({ id: 'system.actions.user' })
  const resourceTrans = intl.formatMessage({ id: 'system.actions.resource' })
  const nameTrans = intl.formatMessage({ id: 'system.actions.name' })

  return (
    <table className="nsi-table logs-table s-changelog__table">
      {!props.compactHeader && (
        <thead>
          <TableHeader titles={[timeTrans, actionTrans, userTrans, resourceTrans, nameTrans]} />
        </thead>
      )}
      <tbody>{rows}</tbody>
    </table>
  )
}

interface Props {
  items: IChangelogItem[]
  showDetails: boolean
  compactHeader?: boolean
  onSelect: (item) => void
}

export default React.memo(ActionsTable)
