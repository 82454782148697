import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import { ValidationResult } from '../../../shared/interfaces'
import confirmService from '../../../shared/Modal/confirm.service'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import HistoryButton from '../ActionsJournal/HistoryButton'
import { useSettingsAPI } from '../Settings/settings.hooks'
import renderFooter from '../system.footer'
import DurationSelector from './DurationSelector'
import SizeSelector from './SizeSelector'
import StorageExceptions from './StorageExceptions'
import { IStorageSettings } from 'au-nsi/settings'

const StorageSettings = () => {
  const intl = useIntl()
  const rights = useSelector(selectAccessRights)
  const translations = useSelector((s: ReduxState) => s.language.translations)
  const modules = useSelector(selectEnabledModules)

  const allowEditing = rights['settings:update']
  const showHistory = rights['journal:get_changelog']

  const booleanOptions = [
    { value: true, title: intl.formatMessage({ id: 'common.yes' }) },
    { value: false, title: intl.formatMessage({ id: 'common.no' }) },
  ]

  const state = useSettingsAPI<IStorageSettings>('storage', validate)

  const handleDataChange = (data_retention: string, data_retention_days: number) => {
    state.updateDraft({ data_retention, data_retention_days })
  }

  const handleIncidentsChange = (incidents_archive_retention: string, incidents_archive_retention_days: number) => {
    state.updateDraft({ incidents_archive_retention, incidents_archive_retention_days })
  }

  const handleChange = (value: number, key: string) => {
    state.updateDraft({ [key]: value })
  }

  const handleLoginsChange = (logins_retention: string, logins_retention_days: number) => {
    state.updateDraft({ logins_retention, logins_retention_days })
  }

  const handleChangelogChange = (changelog_retention: string, changelog_retention_days: number) => {
    state.updateDraft({ changelog_retention, changelog_retention_days })
  }

  const handleLogsChange = (logs_retention: string, logs_retention_days: number) => {
    state.updateDraft({ logs_retention, logs_retention_days })
  }

  const handleExceptionsChange = (data_retention_exceptions) => {
    state.updateDraft({ data_retention_exceptions })
  }

  const handleRequestsChange = (retention: string, days: number) => {
    state.updateDraft({ moderation_requests_retention_days: days, moderation_requests_retention: retention })
  }

  const handleNotificationsChange = (retention: string, days: number) => {
    state.updateDraft({ user_notifications_retention_days: days, user_notifications_retention: retention })
  }

  const handleSchedulerChange = (retention: string, days: number) => {
    state.updateDraft({ scheduler_history_retention_days: days, scheduler_history_retention: retention })
  }

  const updateSettings = () => {
    confirmService
      .requestConfirmation('system.storage.confirm_save', [{ title: 'common.continue', value: 'continue' }])
      .then((res) => {
        if (res === 'continue') state.updateSettings()
      })
  }

  if (state.settings == null || state.draft == null) {
    return null
  }

  const { draft, editing } = state

  return (
    <div className="nsi-main__container">
      <div className="nsi-main__header">
        <h2>{intl.formatMessage({ id: 'nav.system.storage' })}</h2>
        {showHistory && <HistoryButton resource="settings_storage" resourceId="settings_storage" />}
      </div>

      <Form editing={editing} onCancel={state.cancelDraft} onSubmit={updateSettings}>
        <div className="system__grid">
          <InputRow label={translations['settings_storage.data_retention']}>
            <DurationSelector duration={draft.data_retention} onChange={handleDataChange} editing={editing} />
          </InputRow>

          <InputRow label={translations['settings_storage.incidents_archive_unremovable']}>
            <Dropdown
              name="incidents_archive_unremovable"
              options={booleanOptions}
              value={draft.incidents_archive_unremovable || false}
              onChange={handleChange}
              disabled={!editing}
            />
          </InputRow>

          {!draft.incidents_archive_unremovable && (
            <InputRow label={translations['settings_storage.incidents_archive_retention']}>
              <DurationSelector
                duration={draft.incidents_archive_retention}
                onChange={handleIncidentsChange}
                editing={editing}
              />
            </InputRow>
          )}

          <SizeSelector
            name="incidents_archive_bytes"
            editing={editing}
            label={translations['settings_storage.incidents_archive_bytes']}
            onChange={handleChange}
            value={draft.incidents_archive_bytes}
          />

          <InputRow label={translations['settings_storage.logs_retention']}>
            <DurationSelector duration={draft.logs_retention} onChange={handleLogsChange} editing={editing} />
          </InputRow>

          <SizeSelector
            name="logs_max_bytes"
            editing={editing}
            label={translations['settings_storage.logs_max_bytes']}
            onChange={handleChange}
            value={draft.logs_max_bytes}
          />

          <InputRow label={translations['settings_storage.changelog_retention']}>
            <DurationSelector duration={draft.changelog_retention} onChange={handleChangelogChange} editing={editing} />
          </InputRow>

          <InputRow label={translations['settings_storage.logins_retention']}>
            <DurationSelector duration={draft.logins_retention} onChange={handleLoginsChange} editing={editing} />
          </InputRow>

          <InputRow label={translations['settings_storage.user_notifications_retention']}>
            <DurationSelector
              duration={draft.user_notifications_retention}
              onChange={handleNotificationsChange}
              editing={editing}
            />
          </InputRow>

          {modules.has('moderation') && (
            <InputRow label={translations['settings_storage.moderation_requests_retention']}>
              <DurationSelector
                duration={draft.moderation_requests_retention}
                onChange={handleRequestsChange}
                editing={editing}
              />
            </InputRow>
          )}

          {modules.has('au-scheduler') && (
            <InputRow label={translations['settings_storage.scheduler_history_retention']}>
              <DurationSelector
                duration={draft.scheduler_history_retention}
                onChange={handleSchedulerChange}
                editing={editing}
              />
            </InputRow>
          )}
        </div>

        <StorageExceptions
          editing={editing}
          exceptions={draft.data_retention_exceptions}
          onChange={handleExceptionsChange}
          translations={translations}
        />

        {allowEditing && renderFooter({ ...state, updateSettings })}
      </Form>
    </div>
  )
}

const validate = (settings: IStorageSettings): ValidationResult => {
  const exceptions = settings.data_retention_exceptions || []

  for (let i = 0; i < exceptions.length; i++) {
    const row = exceptions[i]
    const index = i + 1

    if (!row.device_id) {
      return { id: 'system.storage.errors.empty_device', values: { index } }
    }

    if (!row.apply_to_all_parameters && row.parameters.length === 0) {
      return { id: 'system.storage.errors.empty_parameters', values: { index } }
    }

    if (row.apply_to_all_parameters) {
      const duplicate = exceptions.findIndex(
        (e, j) => i !== j && e.apply_to_all_parameters && e.device_id === row.device_id
      )

      if (duplicate !== -1) {
        return { id: 'system.storage.errors.duplicate_default', values: { index1: index, index2: duplicate + 1 } }
      }
    }
  }

  return null
}

export default StorageSettings
