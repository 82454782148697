import { IDashboard } from 'au-nsi/dashboards'
import { Image } from 'au-nsi/images'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelect } from '../../hooks/useSelect'
import { ReactComponent as ExportIcon } from '../../icons/download.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'
import { ReactComponent as ImportIcon } from '../../icons/upload.svg'
import { ReduxState } from '../../redux/store.types'
import FileInput from '../../shared/Inputs/FileInput'
import SearchInput from '../../shared/Inputs/Search/SearchInput'
import { selectAccessRights, selectEnabledModules } from '../App/app.selectors'
import { selectImagesMap } from '../Libraries/Images/image.selectors'
import CopyModal from './components/CopyModal'
import { DashboardsFolder } from './components/DashboardsFolder/DashboardsFolder'
import DashboardsModal from './components/DashboardsModal'
import DashboardsTile from './components/DashboardsTile'
import ExportModal from './components/ExportModal'
import ImportModal from './components/ImportModal'
import { useDashboardsMove } from './components/useDashboardsMove'
import actions from './dashboard.actions'
import './dashboard.styles.css'
import { IDashboardSearchResult } from './dashboard.types'
import { createEmptyDashboard, modalTitles, searchDashboards } from './dashboard.utils'
import './intl/dashboards.intl'

export const DashboardsPage = (props: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')
  const [importedFile, setImportedFile] = useState('')
  const [state, setState] = useState<{ mode: PageMode; dashboard: IDashboard }>({ mode: 'view', dashboard: null })

  const { dashboardsDraft, editing } = useSelector((state: ReduxState) => state.dashboards)

  useEffect(() => {
    !editing && dispatch(actions.setDashboardsDraft({ draft: props.dashboards }))
  }, [props.dashboards, editing])

  const filteredDashboards = search ? searchDashboards(dashboardsDraft, search.toLowerCase()) : dashboardsDraft

  const { setSelected, selectedIndexes, setSelectAll } = useSelect({ len: filteredDashboards.length })

  const handleImport = (content: string) => {
    setImportedFile(content)
    setState({ mode: 'import', dashboard: null })
  }

  const handleCreateFinal = (d) => {
    props.onCreate(d)
    handleCancel()
  }

  const handleCancel = () => {
    dispatch(actions.cancelDraft())
    setSelectAll(false)
    setImportedFile('')
    setState({ mode: 'view', dashboard: null })
  }

  const handleEditFinal = (d) => {
    dispatch(actions.updateDraftDashboard(d))
    setState({ mode: 'view', dashboard: null })
  }

  const handleGroupIntoFolder = () => {
    dispatch(actions.groupIntoDraftFolder(selectedIndexes as Set<string>))
    setSelectAll(false)
  }

  const handleRemoveList = () => {
    dispatch(actions.removeDraftList(selectedIndexes as Set<string>))
    setSelectAll(false)
  }

  const handleSave = async () => {
    await dispatch(actions.saveDraft())
    handleCancel()
  }

  const { orderedItems, onDragStart, dropElement, dropFolder } = useDashboardsMove({
    items: filteredDashboards,
  })

  const modalActions = useMemo(() => {
    return {
      create: handleCreateFinal,
      edit: handleEditFinal,
    }
  }, [])

  const modalTitle = modalTitles[state.mode]
  const modalAction = modalActions[state.mode]

  const tilesProps = {
    allowEditing: props.allowEditing,
    onClick: useCallback((d: IDashboard) => navigate('/dashboards/' + d.id), []),
    onEdit: useCallback((d: IDashboard) => setState({ mode: 'edit', dashboard: d }), []),
    onDelete: useCallback((d: IDashboard) => dispatch(actions.removeDraftList(new Set([d.id]))), []),
    onCopy: useCallback((d: IDashboard) => setState({ mode: 'copy', dashboard: d }), []),
    onDragStart,
  }

  const renderDashboardTile = (d: IDashboard | IDashboardSearchResult) => (
    <DashboardsTile
      {...tilesProps}
      dashboard={d as IDashboard}
      dashboardsLength={orderedItems.length}
      image={props.images.get((d as IDashboard).image_id)}
      title={'searchResult' in d ? d.searchResult : d.name}
      key={d.id + search[0]}
      isDrag={props.draggingId === d.id}
      isDrop={dropElement === d.id}
      isDropFolder={dropFolder === d.id}
      isFilter={!!search}
      selected={selectedIndexes.has(d.id)}
      onSelect={setSelected}
      editing={editing}
    />
  )

  const renderFolderTile = (d: IDashboard) => {
    const folderDashboards = [
      d,
      ...filteredDashboards
        .filter((dh) => dh.folder_id === d.id && dh.id !== d.id)
        .sort((a, b) => a.user_ordering_index - b.user_ordering_index),
    ]

    const key = filteredDashboards
      .filter((dh) => dh.folder_id === d.id)
      .map((dh) => dh.id)
      .sort()
      .toString()

    return (
      <DashboardsFolder
        {...tilesProps}
        dashboards={folderDashboards}
        key={key}
        folder={d}
        isDrag={props.draggingId === d.id}
        dashboardsLength={orderedItems.length}
      />
    )
  }

  const renderHeader = () => {
    const createButton = props.allowEditing && (
      <button
        style={{ opacity: editing ? '0.6' : '1' }}
        className="nsi-button default dashboards__header-create__button"
        data-test-id="create-dashboard"
        onClick={() => !editing && setState({ mode: 'create', dashboard: createEmptyDashboard() })}
      >
        <PlusIcon width="20" height="20" />
        <span className="mobile_none">{intl.formatMessage({ id: 'dashboards.add' })}</span>
      </button>
    )

    const searchInput = <SearchInput onChange={setSearch} />

    const exportBtn = props.allowImport && (
      <button
        style={{ marginLeft: '0', opacity: editing ? '0.6' : '1' }}
        className="nsi-button inverted mobile_none"
        onClick={() => !editing && setState({ mode: 'export', dashboard: null })}
      >
        <ExportIcon width="14" height="14" style={{ marginRight: '8px', marginTop: '-4px' }} />
        <span> {intl.formatMessage({ id: 'dashboards.export' })}</span>
      </button>
    )

    const importBtn = props.allowImport && (
      <div className="mobile_none">
        <FileInput
          disabled={editing}
          style={{ margin: '0', opacity: editing ? '0.6' : '1' }}
          accept=".json"
          buttonClass="inverted"
          onChange={handleImport}
        >
          <ImportIcon width="14" height="14" style={{ marginRight: '8px', marginTop: '-4px' }} />
          <span>{intl.formatMessage({ id: 'dashboards.import' })}</span>
        </FileInput>
      </div>
    )

    return (
      <div className="dashboards__header">
        {createButton}
        {searchInput}
        {exportBtn}
        {importBtn}
      </div>
    )
  }

  return (
    <>
      {renderHeader()}

      <div className={`dashboards`} id={'dashboards'}>
        {orderedItems.map((d) => (d.folder_id !== d.id ? renderDashboardTile(d) : renderFolderTile(d)))}

        {(editing || selectedIndexes.size > 0) && (
          <div className={'dashboards__footer'}>
            {(editing || selectedIndexes.size > 0) && (
              <button onClick={handleCancel} className="nsi-button inverted">
                {intl.formatMessage({ id: 'common.cancel' })}
              </button>
            )}

            {editing && selectedIndexes.size === 0 && (
              <button onClick={handleSave} className="nsi-button default">
                {intl.formatMessage({ id: 'common.save' })}
              </button>
            )}

            {selectedIndexes.size > 1 && (
              <button onClick={handleGroupIntoFolder} className="nsi-button default">
                {intl.formatMessage({ id: 'dashboards.group_into_folder' })}
              </button>
            )}
            {selectedIndexes.size > 0 && (
              <button onClick={handleRemoveList} className="nsi-button danger">
                {intl.formatMessage({ id: 'common.delete' })}
              </button>
            )}
          </div>
        )}
      </div>

      {(state.mode === 'create' || state.mode === 'edit') && (
        <DashboardsModal dashboard={state.dashboard} title={modalTitle} onCancel={handleCancel} onSave={modalAction} />
      )}

      {state.mode === 'copy' && <CopyModal dashboard={state.dashboard} onClose={handleCancel} />}
      {state.mode === 'export' && <ExportModal onClose={handleCancel} />}
      {state.mode === 'import' && <ImportModal onClose={handleCancel} content={importedFile} />}
    </>
  )
}

type PageMode = 'view' | 'edit' | 'create' | 'delete' | 'copy' | 'export' | 'import'

interface Props {
  allowEditing: boolean
  allowImport: boolean
  dashboards: IDashboard[]
  images: Map<string, Image>
  modules: Set<string>
  onCreate: (d: IDashboard) => void
  draggingId: string
}

const mapStateToProps = (state: ReduxState) => {
  const rights = selectAccessRights(state)

  return {
    allowEditing: rights['dashboards:update'],
    allowImport: rights['dashboards:import'],
    dashboards: state.dashboards.dashboards,
    images: selectImagesMap(state),
    modules: selectEnabledModules(state),
    draggingId: state.dashboards.draggingId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreate: (d) => dispatch(actions.createDashboard(d)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardsPage)
