import { ITextSettings, ITextSettingsCommon } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { ReduxState } from '../../../redux/store.types'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { convert } from '../../Parameters/params.utils'
import { createFormatter } from '../common/format.utils'
import './text.styles.css'
import * as utils from './text.utils'

/**
 * Компонент отображающий заданный пользователем текст с возможностью вставки текущих значений параметров
 */
const Text = (props: Props) => {
  const intl = useIntl()
  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  const [state, setState] = React.useState<State>({ settings: props.settings, data: {} })

  const store = useSelector((s: ReduxState) => {
    return {
      parameters: selectDenormalizedParametersMap(s),
      prefixes: s.parameters.prefixes,
      units: s.parameters.units,
    }
  }, shallowEqual)

  const formatters = React.useMemo(() => {
    const result = {}

    for (const c of state.settings.variables) {
      result[c.name] = createFormatter(c.number_format, intl.locale)
    }

    return result
  }, [state.settings.variables])

  React.useEffect(() => {
    const selectors: Array<{ device_id: string; parameters: string[] }> = []

    for (const { device_id, parameter_id } of utils.eachParameter(props.settings)) {
      const selector = selectors.find((s) => s.device_id === device_id)

      if (!selector) {
        selectors.push({ device_id: device_id, parameters: [parameter_id] })
      } else if (!selector.parameters.includes(parameter_id)) {
        selector.parameters.push(parameter_id)
      }
    }

    service.setDataSelectors(selectors)

    const updateState = () => {
      // в настройках могут быть заданы несколько вариантов текста в зависимости от значений
      // параметров, поэтому ищем подходящее условие, а если не нашли то берем основной текст
      const settings = utils.matchCondition(service, props.settings.conditions || []) || props.settings
      const data = {}

      for (const v of settings.variables) {
        const point = service.selectCurrentPoint(v.device_id)
        data[v.name] = point ? point[v.parameter_id] : null
      }

      setState({ settings, data })
    }

    service.onTick = () => {
      if (service.shouldRender()) {
        updateState()
      }
    }

    updateState()
  }, [props.settings])

  const lines = React.useMemo(() => {
    return state.settings.text.split('\n').map((line) => utils.tokenizeText(line))
  }, [state.settings])

  // стиль обычного текста
  const textStyle: React.CSSProperties = {
    fontSize: state.settings.text_size,
    color: state.settings.text_color,
    ...utils.getFontStyle(state.settings.text_style),
  }

  // стиль значений параметров
  const varsStyle: React.CSSProperties = {
    fontSize: state.settings.vars_size,
    color: state.settings.vars_color,
    ...utils.getFontStyle(state.settings.vars_style),
  }

  // перевести значение переменной в выбранную единицу измерения
  const formatValue = (name: string) => {
    let value = state.data[name]
    if (value == null) return ''

    const setting = state.settings.variables.find((v) => v.name === name)
    const parameter = store.parameters.get(setting.parameter_id)
    if (!parameter) return ''

    value = convert(value, {
      fromUnit: parameter.unit,
      fromPrefix: parameter.prefix,
      toUnit: store.units[setting.display_unit],
      toPrefix: store.prefixes[setting.display_prefix],
    })

    const formatter = formatters[name]
    if (formatter) return formatter.format(value)

    return ''
  }

  // текст разбиваем на строки и в каждой строке заменяем переменную на текущее значение параметра
  const content = lines.map((line, i) => {
    const parts = line.map((part, j) => {
      const isVariable = part.type === 'variable'
      const value = isVariable ? formatValue(part.value) : part.value
      const style = isVariable ? varsStyle : textStyle

      return (
        <span key={j} style={style}>
          {value}
        </span>
      )
    })

    return <div key={i}>{parts}</div>
  })

  const alignment = state.settings.align || 'center space-around'
  const [alignItems, justifyContent] = alignment.split(' ')

  return (
    <div style={{ alignItems, justifyContent, textAlign: alignItems as any }} className="dashboard-text__wrapper">
      {content}
    </div>
  )
}

interface State {
  // отображаемый текст (либо основной, либо один из альтернативных если выполнилось его условие)
  settings: ITextSettingsCommon
  // 'название переменной' -> значение привязанного к ней параметра
  data: Record<string, number>
}

interface Props {
  id: number
  settings: ITextSettings
}

export default Text
