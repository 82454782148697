import { IBarChartSettings } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import { Parameter } from 'au-nsi/parameters'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useCanvasScale from '../../../hooks/useCanvasScale'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { COLORS } from '../../../shared/constants'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { formatUnit, formatValue } from '../../Parameters/params.utils'
import { calcYGrid } from '../charts.utils'
import css from './barchart.module.css'
import { adjustMinMax } from './barchart.utils'

const paddings = { top: 24, right: 0, bottom: 24, left: 50 }

interface Props {
  id: number
  lastResize: number
  settings: IBarChartSettings
}

const BarChart = ({ id, lastResize, settings }: Props) => {
  const service = useDataService(id, { singleValueMode: true })
  useDataRate(service)

  const parameters = useSelector(selectDenormalizedParametersMap)

  const container = useRef<HTMLDivElement>()
  const canvasRef = useRef<HTMLCanvasElement>()

  // данные не хранятся в стейте, для того чтобы не вызывать ререндер компонента при каждом изменении
  const dataRef = useRef<BarChartData>({})
  const chartOptions = useRef({ minY: settings.min ?? 0, maxY: settings.max ?? 1, hoveredIndex: -1 })
  const [size, setSize] = useState<DOMRect>()
  const innerWidth = size ? size.width - paddings.left - paddings.right : 0
  const innerHeight = size ? size.height - paddings.top - paddings.bottom : 0
  const barWidth = innerWidth / settings.parameters.length

  useCanvasScale(canvasRef, size?.width, size?.height)

  const yScale = (value: number) => {
    return (
      paddings.top +
      (innerHeight * (chartOptions.current.maxY - value)) / (chartOptions.current.maxY - chartOptions.current.minY)
    )
  }
  // выставление размеров canvas равными размерам контейнера
  useEffect(() => {
    const rect = container.current.getBoundingClientRect()
    rect.width = Math.floor(rect.width)
    rect.height = Math.floor(rect.height)
    setSize(rect)
  }, [lastResize])

  // запрос всех указанных в настройках параметров и устройств
  useEffect(() => {
    const selectorsMap = new Map<Equipment['id'], Set<Parameter['id']>>()

    for (const { device_id, parameter_id } of settings.parameters) {
      if (selectorsMap.has(device_id)) selectorsMap.get(device_id).add(parameter_id)
      else selectorsMap.set(device_id, new Set<Parameter['id']>([parameter_id]))
    }

    const selectors = Array.from(selectorsMap.entries()).map((pair) => ({
      device_id: pair[0],
      parameters: Array.from(pair[1]),
    }))
    service.setDataSelectors(selectors)
  }, [settings.parameters])

  // get Data
  useEffect(() => {
    chartOptions.current.maxY = settings.max ?? chartOptions.current.maxY
    chartOptions.current.minY = settings.min ?? chartOptions.current.minY

    if (settings.parameters.length === 0) {
      return (service.onTick = () => null)
    }

    const onTick = () => {
      let [min, max] = [0, 0]
      const data: BarChartData = {}

      // получаем значения всех нужных парметров и одновременно находим минимум и максимум
      for (const { device_id, parameter_id, mirror } of settings.parameters) {
        if (!data[device_id]) data[device_id] = service.selectCurrentPoint(device_id) || {}
        if (data[device_id][parameter_id] == null) continue

        const k = mirror ? -1 : 1
        min = Math.min(min, data[device_id][parameter_id] * k)
        max = Math.max(max ?? 0, data[device_id][parameter_id] * k)
      }

      // если границы изменились и если они не фиксированы в настройках, то выставляем новые границы
      if (max != null && max > min) {
        const { minY, maxY } = chartOptions.current
        const result = adjustMinMax(min, max, minY, maxY)
        if (result.min !== minY && settings.min == null) chartOptions.current.minY = result.min
        if (result.max !== maxY && settings.max == null) chartOptions.current.maxY = result.max
      }

      dataRef.current = data
      drawChart()
    }

    service.onTick = onTick
    onTick()
  }, [settings, size])

  // Рендер диаграммы
  function drawChart() {
    if (!size || !canvasRef.current) return

    const yGrid = calcYGrid(
      chartOptions.current.minY,
      chartOptions.current.maxY,
      (innerHeight > 0 ? innerHeight : 300) / 60
    )

    const ctx = canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, size.width, size.height)
    ctx.font = '12px Roboto'

    const y1 = yScale(0)

    // отрисовка линии нуля
    ctx.fillStyle = COLORS.gray
    ctx.fillRect(paddings.left, y1 - 1, innerWidth, 2)

    // отрисовка линий сетки по оси Ox
    const x0 = paddings.left
    const x1 = innerWidth + paddings.left - paddings.right

    ctx.beginPath()
    ctx.textAlign = 'right'
    ctx.textBaseline = 'middle'

    const parameter = parameters.get(settings.parameters[0].parameter_id)

    for (const value of yGrid.values) {
      const y = Math.round(yScale(value)) + 0.5
      ctx.moveTo(x0, y)
      ctx.lineTo(x1, y)
      const x = paddings.left - 6

      const renderValue = settings.mirror_axis ? Math.abs(value) : value
      const text = formatValue(renderValue, parameter, false)
      ctx.fillText(text, x, y, paddings.left - 6)
    }
    // отрисовка единицы измерения в левом верхнем углу
    const parameterUnit = formatUnit(parameter, 'space')
    ctx.fillText(parameterUnit, paddings.left - 6, 10)

    ctx.stroke()
    ctx.strokeStyle = COLORS.darkGray
    ctx.textAlign = 'center'
    ctx.textBaseline = 'alphabetic'

    // границы по оси X последих подписей столбцов (название и значение)
    let prevNameEnd = 0
    let prevValueEnd = 0

    const barSidePadding = settings.bar_width ? (barWidth * (1 - settings.bar_width / 100)) / 2 : 100
    const text1: Text[] = [] // обычные подписи
    const text2: Text[] = [] // подписи с повышенным приоритетом (должны быть на переднем плане)

    // отрисовка столбцов
    for (let i = 0; i < settings.parameters.length; i++) {
      const { device_id, parameter_id, color, name, mirror } = settings.parameters[i]
      const value = dataRef.current[device_id][parameter_id]
      if (value == null) continue

      const y0 = yScale(mirror ? value * -1 : value)
      const x0 = barWidth * i + barSidePadding + paddings.left
      const w = barWidth - 2 * barSidePadding
      const center = barWidth * (i + 0.5) + paddings.left

      ctx.fillStyle = color
      ctx.fillRect(x0, Math.min(y0, y1), w, Math.abs(y1 - y0))
      ctx.fillStyle = COLORS.gray

      const isHover = i === chartOptions.current.hoveredIndex

      // название столбца
      if (name && (settings.show_names || isHover)) {
        const { width } = ctx.measureText(name)
        const y = size.height - 6
        const x = Math.max(center, width / 2)
        const text = { text: name, x, y, width }

        const leftBorder = Math.floor(center - width / 2 - 3)
        const rightBorder = Math.ceil(center + width / 2 + 3)
        const isEnoughSpace = leftBorder > prevNameEnd
        if (isEnoughSpace) prevNameEnd = rightBorder

        // если все названия не входят, то некоторые пропускаем, так чтобы
        // не было перекрытий с предыдущими
        if (isHover) text2.push(text)
        else if (isEnoughSpace) text1.push(text)
      }

      // подпись со значением столбца
      if (settings.show_values || isHover) {
        const barDirection = value * (mirror ? -1 : 1) < 0 ? 'bottom' : 'top'
        const y = barDirection === 'bottom' ? y0 + 16 : y0 - 8

        const parameter = parameters.get(parameter_id)
        const renderValue = formatValue(value, parameter)

        const { width } = ctx.measureText(renderValue)
        const text = { text: renderValue, x: center, y, width }

        const leftBorder = Math.floor(center - width / 2 - 3)
        const rightBorder = Math.ceil(center + width / 2 + 3)
        const isEnoughSpace = leftBorder > prevValueEnd
        if (isEnoughSpace) prevValueEnd = rightBorder

        // аналогично названию проверяем не накладывается ли подпись на предыдущую
        if (isHover) text2.push(text)
        else if (isEnoughSpace) text1.push(text)
      }
    }

    // добавляем подписи после отрисовки всех столбцов, чтобы подпись всегда была
    // на переднем плане, т.к. она может быть шире столбца и заходить на соседний
    for (const { text, x, y, width } of text1.concat(text2)) {
      ctx.clearRect(x - width / 2 - 2, y - 14, width + 4, 16)
      ctx.fillText(text, x, y)
    }

    // отрисовка линий пороговых значений
    for (let i = 0; i < settings.thresholds.length; i++) {
      ctx.save()
      const { line, color, value } = settings.thresholds[i]
      const y = yScale(value)

      ctx.lineWidth = 2
      ctx.strokeStyle = color
      ctx.setLineDash(line === 'solid' ? [] : [6, 8])
      ctx.beginPath()
      ctx.moveTo(paddings.left, y)
      ctx.lineTo(paddings.left + innerWidth, y)
      ctx.stroke()
      ctx.restore()
    }
  }

  // определение индекса столбца, на который наведен курсор
  const handleMouseMove = (e: MouseEvent) => {
    const prevIndex = chartOptions.current.hoveredIndex
    chartOptions.current.hoveredIndex = Math.floor((e.pageX - size.left - paddings.left) / barWidth)
    if (prevIndex !== chartOptions.current.hoveredIndex) drawChart()
  }

  return (
    <div ref={container} className={css.container}>
      <h2 className={'line_clamp'} title={settings.title}>
        {settings.title}
      </h2>
      <canvas
        ref={canvasRef}
        width={size ? size.width : 0}
        height={size ? size.height : 0}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          chartOptions.current.hoveredIndex = -1
          drawChart()
        }}
      />
    </div>
  )
}

// device_id -> (parameter_id, value)
type BarChartData = Record<Equipment['id'], Record<Parameter['id'], number>>

interface Text {
  text: string
  x: number
  y: number
  width: number
}

export default BarChart
