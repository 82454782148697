const milliseconds = 1
const seconds = milliseconds * 1000
const minutes = seconds * 60
const hours = minutes * 60
const days = hours * 24

export const units = {
  milliseconds,
  seconds,
  minutes,
  hours,
  days,
}

export const frame2ms = (frame: FrameHuman): number => {
  const factor = units[frame.unit] || 1
  return frame.value * factor
}

export const ms2frame = (ms: number): FrameHuman => {
  const unitNames = Object.keys(units).reverse()

  for (const unit of unitNames) {
    if (ms >= units[unit]) {
      const value = Math.round((ms / units[unit]) * 100) / 100
      return { unit, value }
    }
  }
}

export interface FrameHuman {
  value: number
  unit: string
}
