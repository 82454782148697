import axios from 'axios'
import { FormEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { API_URL } from '../../shared/constants'
import InputRow from '../../shared/Inputs/InputRow'
import TextInput from '../../shared/Inputs/TextInput'
import Loader from '../../shared/Utils/Loader'
import { getResponseErrorMessage } from './auth.utils'
import { AuthWrapper } from './AuthWrapper'

/**
 * Форма восстановления пароля 1: ввод имени и почты, на которую будет отправлена ссылка
 * для задания нового пароля
 */
const PasswordReset1 = () => {
  const intl = useIntl()
  const [data, setData] = useState({ name: '', username: '' })
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState('')

  const handleChange = (value, key: string) => setData({ ...data, [key]: value })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(API_URL + '/nsi/auth/reset', data)
      .then(() => setCompleted(true))
      .catch((err) =>
        setError(
          err.response && err.response.status < 429
            ? intl.formatMessage({ id: 'auth.reset.error' })
            : getResponseErrorMessage(err, intl)
        )
      )
      .finally(() => setLoading(false))
  }

  return (
    <AuthWrapper showSignin={true} title={intl.formatMessage({ id: 'auth.reset.title' })}>
      <div className="login-form">
        {!completed && (
          <form onSubmit={handleSubmit}>
            <InputRow label={intl.formatMessage({ id: 'auth.reset.name' })}>
              <TextInput value={data.name} name="name" onChange={handleChange} />
            </InputRow>
            <InputRow label={intl.formatMessage({ id: 'auth.reset.username' })}>
              <TextInput value={data.username} name="username" onChange={handleChange} type="email" />
            </InputRow>

            <div className="login-form__error">{error}</div>

            <button
              type="submit"
              className="nsi-button default"
              disabled={loading || !data.name || !data.username}
              style={{ display: 'block', marginLeft: 'auto' }}
            >
              {loading ? <Loader /> : intl.formatMessage({ id: 'auth.reset.submit' })}
            </button>
          </form>
        )}

        {completed && (
          <div className="text--success" style={{ textAlign: 'center', margin: '20px' }}>
            {intl.formatMessage({ id: 'auth.reset.success' })}
          </div>
        )}
      </div>
    </AuthWrapper>
  )
}

export default PasswordReset1
