import { shallowEqual, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PanelList from '../../../shared/FormPanel/PanelList'
import { selectAccessRights } from '../../App/app.selectors'
import Logins from './Logins'
import Sessions from './Sessions'

const AuthJournal = () => {
  const rights = useSelector((state: ReduxState) => {
    const access = selectAccessRights(state)
    return {
      showSessions: access['journal:get_sessions'],
      showLogins: access['journal:get_logins'],
      allowDeletingSessions: access['journal:delete_sessions'],
    }
  }, shallowEqual)

  const panels = []

  if (rights.showSessions) {
    panels.push({
      title: 'system.auth.sessions',
      children: <Sessions allowDeleting={rights.allowDeletingSessions} />,
    })
  }

  if (rights.showLogins) {
    panels.push({
      title: 'system.auth.logins',
      children: <Logins />,
    })
  }

  return (
    <div style={{ marginBottom: '4rem' }} className="nsi-main__container">
      <PanelList items={panels} defaultOpen={true} />
    </div>
  )
}

export default AuthJournal
