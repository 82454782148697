import { Equipment } from 'au-nsi/equipment'
import { Incident } from 'au-nsi/signal-events'
import { IUser } from 'au-nsi/user'
import { useEffect, useState } from 'react'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { formatDate } from '../../../utils/lang'
import { SignalEvent } from '../../SignalEvents/se.interfaces'
import SeFormulaPreview from '../../SignalEvents/SeFormulaPreview'
import { getIncidentDescription } from '../incidents.utils'

interface Props {
  incident: Incident
  devices: Map<string, Equipment>
  users: Map<number, IUser>
  rules: Map<number, SignalEvent>
  showDetails: boolean
  onSelect: (seID: number) => void
}

const timeFormat = 'dd MMM yyyy HH:mm:ss'

const IncidentsRow = (props: Props) => {
  const intl = useIntl()

  const [showDetails, setShowDetails] = useState(props.showDetails)
  useEffect(() => setShowDetails(props.showDetails), [props.showDetails])

  const { incident, devices, users, rules } = props
  const { title, text } = getIncidentDescription(incident, rules, intl.locale)

  const startDate = new Date(incident.ts_start / 1000)
  const endDate = new Date(incident.ts_end / 1000)
  const start = formatDate(startDate, timeFormat)

  // если инцидент закончился в тот же день, то в окончании опускаем дату
  const endFormat = startDate.getDate() === endDate.getDate() ? 'HH:mm:ss' : timeFormat
  const end = formatDate(endDate, endFormat)

  const objects = Object.keys(incident.details)
    .map((id) => devices.get(id)?.name)
    .join(', ')

  const user = incident.ack_user ? users.get(incident.ack_user)?.name : ''
  const ack = incident.ack_ts ? formatDate(incident.ack_ts, timeFormat) + ' ' + user : ''

  // при клике по названию отображаем только инциденты по этой сигнальной ситуации
  const handleTitleClick = (e) => {
    if (incident.type !== 'formula') return

    const se = rules.get(incident.formula_id)
    if (se) {
      e.stopPropagation()
      props.onSelect(se.id)
    }
  }

  // сообщение, указанное в настройках сигнальной ситуации
  const renderDescription = () => {
    const description = intl.formatMessage({ id: 'alerts.description' }) + ': ' + text
    return <div>{description}</div>
  }

  // условие, по которому сработала сигнальная ситуация
  const renderCondition = () => {
    if (incident.type !== 'formula') return null

    const se = rules.get(incident.formula_id)
    if (!se) return null

    const rule = se.rules.find((r) => r.id === incident.formula_id)
    if (!rule) return null

    const condition = rule.type === 'formula' ? <SeFormulaPreview formula={rule.formula} /> : rule.src

    return (
      <div>
        <div>{intl.formatMessage({ id: 'alerts.formula' })}:</div>
        {condition}
      </div>
    )
  }

  return (
    <>
      <tr className="cursor--pointer" onClick={() => setShowDetails(!showDetails)}>
        <td>{start}</td>
        <td>{incident.ts_end ? end : '-'}</td>
        <td onClick={handleTitleClick}>{title}</td>
        <td>{objects}</td>
        <td>{ack}</td>
      </tr>
      {showDetails && (
        <tr>
          <td colSpan={5} style={{ padding: '8px 25px' }}>
            {renderDescription()}
            {renderCondition()}
          </td>
        </tr>
      )}
    </>
  )
}

export default memo(IncidentsRow)
