import http, { handleHttpError, handleHttpResponse } from '../../../utils/http'
import { OrganizationTypeWithInstances } from './organizations.types'
import { Organization } from 'au-nsi/organizations'

const API_PREFIX = '/nsi/v1'

export const selectOrganizations = async (): Promise<OrganizationTypeWithInstances[]> => {
  const organizations: { organizations_types: OrganizationTypeWithInstances[]; organizations: Organization[] } =
    await http
      .get(API_PREFIX + '/organizations_types')
      .then((r) => r.data)
      .catch(handleHttpError)

  if (!organizations) return []

  return organizations.organizations_types.map((ot) => ({
    ...ot,
    instances: organizations.organizations.filter((o) => o.organization_type_id === ot.id),
  }))
}

export const postCreateOrganizationType = async (
  organizationType: OrganizationTypeWithInstances
): Promise<OrganizationTypeWithInstances> => {
  const created = await http
    .post(API_PREFIX + '/organizations_types', organizationType)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return created
}

export const patchUpdateOrganizationType = async (
  organizationType: OrganizationTypeWithInstances
): Promise<OrganizationTypeWithInstances> => {
  const updated = await http
    .patch(API_PREFIX + '/organizations_types/' + organizationType.id, organizationType)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return updated
}

export const requestDeleteOrganizationType = async (
  id: OrganizationTypeWithInstances['id']
): Promise<OrganizationTypeWithInstances> => {
  const deleted = await http
    .delete(API_PREFIX + '/organizations_types/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return deleted
}

export const postCreateOrganization = async (organization: Organization): Promise<Organization> => {
  const created = await http
    .post(API_PREFIX + '/organizations', organization)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return created
}

export const patchUpdateOrganization = async (organization: Organization): Promise<Organization> => {
  const updated = await http
    .patch(API_PREFIX + '/organizations/' + organization.id, organization)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return updated
}

export const requestDeleteOrganization = async (organizationId: Organization['id']): Promise<Organization> => {
  const deleted = await http
    .delete(API_PREFIX + '/organizations/' + organizationId)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return deleted
}
