import React from 'react'
import { useIntl } from 'react-intl'
import Links from '../TopMenu/Nav/Links'
import AppRoutes from '../TopMenu/Nav/Routes'
import systemRoutes from '../TopMenu/Nav/system.routes'
import './intl/system.intl'
import HideableLeftMenu from '../../shared/HideableLeftMenu/HIdeableLeftMenu'

const SystemPage = () => {
  const intl = useIntl()

  const icon = <span className="system__nav-icon" />

  const navMenu = (
    <HideableLeftMenu>
      <div className="nsi-tree_title">{intl.formatMessage({ id: 'system.title' })}</div>

      <div className="system__nav">
        <Links routes={systemRoutes} className="system__nav-item" icon={icon} />
      </div>
    </HideableLeftMenu>
  )

  return (
    <React.Fragment>
      {navMenu}

      <div className="nsi-main__wrapper">
        <AppRoutes routes={systemRoutes} fallback="" />
      </div>
    </React.Fragment>
  )
}

export default SystemPage
