import classnames from 'classnames'
import React from 'react'
import { IntlShape } from 'react-intl'
import { ReactComponent as LineIcon } from '../../../icons/chart-line.svg'

/**
 * Иконка для быстрого переключения отображения в режиме аггрегации (выбор min/max/avg)
 */
const AggregationModeSelector = (props: Props) => {
  const { intl } = props
  const value = props.value ?? 2

  const handleClick = (e) => {
    const id = +e.target.dataset.id
    const aggregationMode = value ^ id
    props.onChange(aggregationMode)
  }

  const options = [
    { id: 1, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.min' }) },
    { id: 2, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.avg' }) },
    { id: 4, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.max' }) },
  ]

  const menuItems = options.map((o) => {
    const className = classnames('chart__icon-dropdown-menu__item', { 'is-selected': (value & o.id) === o.id })

    return (
      <span key={o.id} data-id={o.id} className={className} onClick={handleClick}>
        {o.name}
      </span>
    )
  })

  return (
    <span className="chart__icon-dropdown">
      <LineIcon className="line-chart__icon" />
      <span className="chart__icon-dropdown-menu">{menuItems}</span>
    </span>
  )
}

interface Props {
  intl: IntlShape
  value: number
  onChange: (value: number) => void
}

export default React.memo(AggregationModeSelector)
