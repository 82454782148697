import classnames from 'classnames'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/store'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { selectCommandsMap } from '../commands.selectors'
import { CommandOptions } from '../commands.utils'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import css from './commands.module.css'

interface Props {
  commands: CommandOptions[]
  onClose: () => void
  onSelect: (selection: CommandOptions[]) => void
}

/**
 * Модальное окно для выбора команд из предоставленного списка
 */
const CommandSelectModal = ({ commands, onClose, onSelect }: Props) => {
  const intl = useIntl()
  const [selection, setSelection] = useState(new Set<number>())

  const commandsMap = useAppSelector(selectCommandsMap)
  const devicesMap = useAppSelector(selectEquipmentMap)

  const handleSelect = (e) => {
    const index = +e.currentTarget.dataset.index
    const copy = new Set(selection)

    if (!selection.has(index)) copy.add(index)
    else copy.delete(index)

    setSelection(copy)
  }

  const handleSave = () => {
    const selectedCommands = commands.filter((_, i) => selection.has(i))
    if (selectedCommands.length) onSelect(selectedCommands)
  }

  const rows = commands.map((c, i) => {
    const className = classnames(css.selectionItem, { [css.selectedItem]: selection.has(i) })
    const command = commandsMap.get(c.command_id)
    const device = devicesMap.get(c.target)
    const name = command ? command.name : '-'
    const target = device ? device.name : c.target

    return (
      <div key={i} data-index={i} onClick={handleSelect} className={className}>
        {name} ({target})
      </div>
    )
  })

  const title = intl.formatMessage({ id: 'dashboards.button.commands.select_command' })
  const saveMessage = intl.formatMessage({ id: 'dashboards.button.commands.select' })

  return (
    <Modal onClose={onClose}>
      <div>
        <h2>{title}</h2>
        <div>{rows}</div>

        <ModalFooter onCancel={onClose} onSave={handleSave} saveMessage={saveMessage} />
      </div>
    </Modal>
  )
}

export default CommandSelectModal
