import React from 'react'
import usePopup from './usePopup'

type Ref = React.RefObject<HTMLElement>

/**
 * Хук для обработки клика правой кнопки мыши по элементу и выведения всплывающего меню
 * @param containerRef родительский элемент при клике на который должно открываться меню
 * @param menuRef само всплывающее меню
 * @param isActive
 * @param positionInside Всегда располагать меню внутри родительского контейнера
 */
const useContextMenu = (containerRef: Ref, menuRef: Ref, isActive?: boolean, positionInside?: boolean) => {
  const active = isActive ?? true
  const { open, setOpen } = usePopup(menuRef, { active })

  // при клике правой клавишей мыши открыть меню
  React.useEffect(() => {
    if (!active) return setOpen(false)

    const container = containerRef.current
    if (!container) return

    const openMenu = (e) => {
      if (!active) return

      e.preventDefault()
      e.stopPropagation()

      container.dataset.contextx = e.pageX
      container.dataset.contexty = e.pageY
      container.dataset.zindex = container.style.zIndex
      container.style.zIndex = '990'
      setOpen(true)
    }

    container.addEventListener('contextmenu', openMenu)

    return () => container.removeEventListener('contextmenu', openMenu)
  }, [containerRef.current, active])

  // при открытии меню, до его отрисовки поместить его рядом с местом клика
  // при закрытии вернуть исходное значение z-index
  React.useLayoutEffect(() => {
    const container = containerRef.current
    const menu = menuRef.current

    if (!open) {
      const shouldReset = container && container.dataset.zindex != null && container.style.zIndex === '990'
      if (shouldReset) container.style.zIndex = container.dataset.zindex

      return
    }

    if (!container || !menu || !active) return
    const containerRect = container.getBoundingClientRect()

    const pageX = +container.dataset.contextx
    const pageY = +container.dataset.contexty

    let top = pageY - window.scrollY - containerRect.top + container.scrollTop
    let left = pageX - window.scrollX - containerRect.left

    // если меню выйдет за пределы экрана, то сдвигаем его
    if (pageY - window.scrollY + menu.clientHeight > document.documentElement.clientHeight) {
      top -= menu.clientHeight
    }

    if (pageX - window.scrollX + menu.clientWidth > document.documentElement.clientWidth) {
      left -= menu.clientWidth
    }

    // если меню не должно выходить за границы контейнера, то также сдвигаем его
    if (positionInside && left + menu.clientWidth > containerRect.right) {
      left = containerRect.right - menu.clientWidth - 8
    }

    menu.style.top = top + 'px'
    menu.style.left = left + 'px'
  }, [open, menuRef.current, active])

  return { open, setOpen }
}

export default useContextMenu
