import { Equipment } from 'au-nsi/equipment'
import { PlatformModuleID } from 'au-nsi/shared'
import { ReactComponent as SettingsIcon } from 'icons/cog.svg'
import { ReactComponent as DashboardIcon } from 'icons/dashboard.svg'
import { ReactComponent as PassportIcon } from 'icons/docs.svg'
import { ReactComponent as FilesIcon } from 'icons/folder.svg'
import { ReactComponent as ChangelogIcon } from 'icons/history.svg'
import { ReactComponent as HomeIcon } from 'icons/home.svg'
import { ReactComponentElement, ReactElement } from 'react'
import { IRoute } from '../../TopMenu/Nav/nav.utils'
import DeviceAEPSSettings from './DeviceAEPSSettings'
import DeviceChangelog from './DeviceChangelog/DeviceChangelog'
import DeviceDashboards from './DeviceDashboards/DeviceDashboards'
import DeviceFiles from './DeviceFiles/DeviceFiles'
import LP001SettingsControl from './DeviceLP001Settings'
import DeviceMain from './DeviceMain/DeviceMain'
import DeviceScreenPassport from './DevicePassport/DeviceScreenPassport'

type IMapRoute = Omit<IRoute, 'component'> & {
  icon: ReactElement
  render: () => ReactComponentElement<any>
}

export const deviceScreenRoutes = (device: Equipment): IMapRoute[] => {
  const routes: IMapRoute[] = [
    {
      id: 'main',
      path: `main`,
      render: () => <DeviceMain device={device} />,
      modules: ['map_topology'],
      icon: <HomeIcon />,
    },
    {
      id: 'dashboards',
      path: `dashboards`,
      render: () => <DeviceDashboards device={device} />,
      modules: ['map_topology'],
      icon: <DashboardIcon />,
    },
    {
      id: 'files',
      path: `files`,
      render: () => <DeviceFiles device={device} />,
      modules: ['map_topology'],
      icon: <FilesIcon />,
    },
    {
      id: 'passport',
      path: `passport`,
      render: () => <DeviceScreenPassport device={device} />,
      modules: ['map_topology', 'catalogs'],
      rights: ['equipment:get_passport'],
      icon: <PassportIcon />,
    },
    {
      id: 'changelog',
      path: `changelog`,
      render: () => <DeviceChangelog device={device} />,
      modules: ['map_topology'],
      rights: ['equipment:get_changelog'],
      icon: <ChangelogIcon width="18px" height="18px" />,
    },
  ]

  if (device.protocol === 'LP001') {
    routes.push({
      id: 'lpControl',
      modules: ['map_topology', 'LP001-Server'],
      path: `lpcontrol`,
      render: () => <LP001SettingsControl device={device} />,
      icon: <SettingsIcon width="19px" height="19px" />,
    })
  }

  if (device.protocol === 'modbustcp-client') {
    routes.push({
      id: 'aepsWorktime',
      modules: ['aeps-worktime-parser'],
      rights: ['aeps_worktime:get', 'aeps_worktime:update'] as any,
      path: `plan`,
      render: () => <DeviceAEPSSettings device={device} />,
      icon: <SettingsIcon width="19px" height="19px" />,
    })
  }

  return routes
}

export const checkRouteAccess = (
  route: IMapRoute,
  accessRight: Record<string, boolean>,
  modules: Set<PlatformModuleID>
) => {
  const hasRights = route?.rights ? route.rights.some((r) => accessRight[r]) : true
  const modulesEnabled = route?.modules ? route.modules.every((module) => modules.has(module)) : true
  return hasRights && modulesEnabled
}
