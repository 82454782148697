import axios from 'axios'
import { API_URL } from '../../shared/constants'
import { handleHttpError } from '../../utils/http'
import { AnyCatalogWithInstances } from '../Catalogs/catalogs.types'
import { OrganizationTypeWithInstances } from '../Catalogs/Organizations/organizations.types'
import { UserData } from './Register/register.utils'
import { Organization } from 'au-nsi/organizations'

const API_PREFIX = API_URL + '/nsi/auth'

export const selectRegisterOrganizations = async () => {
  const organizations: { organizations: Organization[]; organizations_types: OrganizationTypeWithInstances[] } =
    await axios
      .get(API_PREFIX + '/register_schemas')
      .then((r) => r.data)
      .catch(handleHttpError)

  return (
    organizations?.organizations_types?.map((type) => ({
      ...type,
      instances: organizations.organizations.filter((o) => o.organization_type_id === type.id),
    })) || []
  )
}

export const selectRegisterCatalogsDeps = async (
  organizationTypeId: OrganizationTypeWithInstances['id']
): Promise<AnyCatalogWithInstances[]> => {
  const catalogs = await axios
    .get(API_PREFIX + '/register_fields_deps/' + organizationTypeId)
    .then((r) => r.data)
    .catch(handleHttpError)

  return (
    catalogs?.catalogs?.map((catalog) => ({
      ...catalog,
      instances: catalogs.catalog_items.filter((instance) => instance.catalog_id === catalog.id),
    })) || []
  )
}

export const requestRegister = async (userData: UserData) => {
  return axios.post(API_PREFIX + '/register', userData)
}
