import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { useAppSelector } from '../../../redux/store'
import DeviceParametersSelector from '../../../shared/DeviceSelector/DeviceParametersSelector'
import Form from '../../../shared/Forms/Form'
import Datepicker from '../../../shared/Inputs/Datepicker/Datepicker'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import http from '../../../utils/http'
import { showError } from '../../../utils/notifications'
import { loadDeviceParameters } from '../../Nsi/nsi.actions'
import { selectParametersNames } from '../../Parameters/params.selectors'
import { ArchiveEntry } from './archive.types'

interface Props {
  mode: 'edit' | 'create'
  entry?: ArchiveEntry
  onClose: () => void
  onCreate: (entry: ArchiveEntry) => void
}

/**
 * Форма создания и редактирования импульс-архива
 */
const ArchiveEntryForm = ({ mode, entry, onClose, onCreate }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()

  const parameters = useAppSelector(selectParametersNames)
  const regions = useAppSelector((state) => state.nsi.regions)
  const equipment = useAppSelector((state) => state.nsi.equipment)
  const deviceParams = useAppSelector((state) => state.nsi.processedDeviceParameters)

  useEffect(() => {
    dispatch(loadDeviceParameters())
  }, [])

  // выбранные устройства и параметры для режима 'selected_parameters'
  const [selectedParams, setSelectedParams] = useState(
    mode === 'edit' ? convertToSelectedParameters(entry.parameters, deviceParams) : {}
  )

  const [draft, setDraft] = useState<ArchiveEntry>(() => {
    const now = Date.now()

    return mode === 'edit'
      ? { ...entry, ts_start: entry.ts_start / 1000, ts_end: entry.ts_end / 1000 }
      : { id: 0, name: '', ts_end: now, ts_start: now - 60_000, parameters: [] }
  })

  const handleChange = useCallback((value: any, key: string) => {
    setDraft((prev) => ({ ...prev, [key]: value }))
  }, [])

  // отправка запроса в НСИ на сохранение импульс-архива
  const handleSave = () => {
    const payload = { ...draft }
    payload.ts_start *= 1000 // перевод в микросекунды
    payload.ts_end *= 1000
    payload.parameters = convertFromSelectedParameters(selectedParams, deviceParams)

    const error = validateForm(payload)
    if (error) return showError(error)

    const request =
      mode === 'edit'
        ? http.patch('/nsi/v1/incidents_archive/' + payload.id, payload)
        : http.post('/nsi/v1/incidents_archive', payload)

    wait(request, (r) => {
      onCreate(r.data)
      onClose()
    })
  }

  return (
    <Modal size="lg" isTall={true} onClose={onClose} closeOnClickOutside={false}>
      <h2>{intl.formatMessage({ id: 'ChartPlayer.archive.impulse_' + mode })}</h2>

      <Form editing={true} onCancel={onClose} onSubmit={handleSave}>
        <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.form.name' })}>
          <TextInput name="name" value={draft.name} onChange={handleChange} />
        </InputRow>

        <div className="system__grid" style={{ marginBottom: 0 }}>
          <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.form.ts_start' })}>
            <Datepicker name="ts_start" time={draft.ts_start} onTimeChange={handleChange} inputClass="nsi-input" />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.form.ts_end' })}>
            <Datepicker name="ts_end" time={draft.ts_end} onTimeChange={handleChange} inputClass="nsi-input" />
          </InputRow>
        </div>

        <div className="section-title">{intl.formatMessage({ id: 'ChartPlayer.archive.form.saved_parameters' })}</div>

        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <DeviceParametersSelector
            regions={regions}
            equipment={equipment}
            selectedParams={selectedParams}
            setSelectedParams={setSelectedParams}
            deviceParams={deviceParams}
            parameters={parameters}
          />
        </div>

        <ModalFooter loading={loading} onCancel={onClose} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

// преобразовать объект с выбранными пользователем устройствами и параметрами в массив формата импульс-архива
const convertFromSelectedParameters = (selection: ParametersSelection, deviceParameters: Record<string, string[]>) => {
  const result: ArchiveEntry['parameters'] = []

  for (const [id, parameters] of Object.entries(selection)) {
    const row = { device_id: id, all_parameters: false, parameters: [] }

    for (const [parameter, isSelected] of Object.entries(parameters)) {
      if (isSelected) row.parameters.push(parameter)
    }

    if (row.parameters.length === deviceParameters[id]?.length) {
      row.all_parameters = true
      row.parameters = []
    }

    if (row.all_parameters || row.parameters.length > 0) {
      result.push(row)
    }
  }

  return result
}

const convertToSelectedParameters = (
  settings: ArchiveEntry['parameters'],
  deviceParameters: Record<string, string[]>
) => {
  const result: ParametersSelection = {}

  for (const row of settings) {
    const id = row.device_id
    result[id] = {}

    if (row.all_parameters && deviceParameters[id]) {
      deviceParameters[id].forEach((param) => (result[id][param] = true))
    } else {
      row.parameters.forEach((param) => (result[id][param] = true))
    }
  }

  return result
}

const validateForm = (entry: ArchiveEntry): string => {
  if (!entry.name) return 'ChartPlayer.archive.errors.empty_name'
  if (!entry.parameters.length) return 'ChartPlayer.archive.errors.empty_parameters'
  return null
}

type ParametersSelection = Record<string, Record<string, boolean>>

export default ArchiveEntryForm
