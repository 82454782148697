import { IAepsSettings } from 'au-nsi/settings'

export const normalizeSettings = (settings: Partial<IAepsSettings>): IAepsSettings => {
  const normalized = settings ? { ...settings } : {}

  const stringKeys: (keyof IAepsSettings)[] = [
    'email_host',
    'email_password',
    'email_password',
    'files_error_dir',
    'xml_success_dir',
    'files_success_dir',
    'files_target_pattern',
  ]
  for (const key of stringKeys) {
    if (!normalized[key]) normalized[key as any] = ''
  }

  const arrayKeys: (keyof IAepsSettings)[] = ['files_mapping', 'xml_mapping']
  for (const key of arrayKeys) {
    if (!normalized[key]) normalized[key as any] = []
  }

  return normalized as IAepsSettings
}
