import classnames from 'classnames'
import React from 'react'

const GanttRow = (props: Props) => {
  const handleClick = (e) => props.onClick && props.onClick(e)

  const children = React.Children.toArray(props.children)

  const className = classnames('gantt__row', {
    'gantt__row--selected': props.selected,
    'gantt__row--selectable': props.selectable ?? true,
    'gantt__row--clickable': props.onClick,
    'gantt__row--nested': props.nested,
    'gantt__row--warning': props.warning,
    'gantt__row--danger': props.danger,
  })

  return (
    <div className={className} onClick={handleClick} data-id={props.id}>
      <div className="gantt__col-1">{children[0]}</div>
      <div className="gantt__col-2">{children.slice(1)}</div>
    </div>
  )
}

interface Props {
  selected?: boolean
  selectable?: boolean
  children: any
  onClick?: (e: any) => void
  id?: string | number
  nested?: boolean
  warning?: boolean
  danger?: boolean
}

export default GanttRow
