import { useAppSelector } from '../../../../redux/store'
import { useMemo } from 'react'
import { Equipment } from 'au-nsi/equipment'
import { IGroup } from 'au-nsi-elevator/groups'

/**
 * Вернуть список оборудования, принадлежащего группе
 * */
const useGroupEquipment = (groupId: IGroup['id']): Equipment[] => {
  const equipment = useAppSelector((state) => state.nsi.equipment)
  const groupEquipment = useMemo(
    () => equipment.filter((e) => e.protocol === 'LP001' && e.attributes.group_id === groupId),
    [equipment, groupId]
  )

  return groupEquipment
}

export default useGroupEquipment
