import { OrganizationTypeWithInstances } from './organizations.types'
import { IntlShape } from 'react-intl'
import { Organization } from 'au-nsi/organizations'

export const createOrganizationType = (): OrganizationTypeWithInstances => ({
  id: -1,
  name: '',
  role_id: null,
  organization_schema: [],
  registration_schema: [],
  register_request_organization: true,
  accept_registration: true,
  instances: [],
})

export const createOrganization = (
  organizationType: OrganizationTypeWithInstances,
  values?: Record<string, any>
): Organization => ({
  id: -1,
  name: '',
  shortname: undefined,
  organization_type_id: organizationType.id,
  organization_properties:
    values ||
    organizationType.organization_schema.reduce((acc, schema) => ({ ...acc, [schema.id]: schema.default_value }), {}),
})

export const boolToText = (val: boolean, intl: IntlShape) => {
  return val ? (
    <span style={{ color: 'var(--text-success)' }}>{intl.formatMessage({ id: 'catalogs.true' })}</span>
  ) : (
    <span style={{ color: 'var(--danger-text)' }}>{intl.formatMessage({ id: 'catalogs.false' })}</span>
  )
}

export const validateOrganizationType = (organizationType: OrganizationTypeWithInstances) => {
  return organizationType.name.length && organizationType.role_id !== -1
}

export const validateOrganization = (organization: Organization) => {
  return organization.name.length && (organization.shortname == null || organization.shortname.length > 1)
}
