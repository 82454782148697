import { useSelector } from 'react-redux'
import { selectPassports } from '../../../../../Catalogs/catalogs.selectors'
import { Equipment } from 'au-nsi/equipment'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import EquipmentPassportForm from '../../../../../Nsi/EquipmentForm/EquipmentPassport/EquipmentPassportForm'
import findCatalogItemsDiff from '../CatalogItemRequestForm/catalogItemRequests.diff'

interface IProps {
  prevDevice: Equipment
  device: Equipment
  onChange: (schemaId: string, value: any) => void
  editing: boolean
}

/**
 Форма паспорта в заявке на модерацию

 Позволяет менять как схему, так и значения.
*/
const EquipmentRequestFormPassport = (props: IProps) => {
  const intl = useIntl()
  const passports = useSelector(selectPassports)

  const prevPassport = useMemo(
    () => passports.find((p) => p.id === props.prevDevice?.passport_catalog_id),
    [props.prevDevice?.passport_catalog_id, passports]
  )

  const selectedPassport = useMemo(
    () => passports.find((p) => p.id === props.device.passport_catalog_id),
    [props.device.passport_catalog_id, passports]
  )

  const [, updatedDevice, catalog] =
    prevPassport && prevPassport.id === selectedPassport.id
      ? findCatalogItemsDiff(props.prevDevice, props.device, prevPassport, 'passport_values')
      : [null, props.device, selectedPassport]

  return (
    <>
      <div className="nsi-row" style={{ marginBottom: '20px' }}>
        <div className="nsi-label">{intl.formatMessage({ id: 'catalogs.passport_schema' })}</div>
        {selectedPassport?.name || '-'}
      </div>
      {selectedPassport && <h2 style={{ marginBottom: '20px' }}>{intl.formatMessage({ id: 'passport' })}</h2>}
      {selectedPassport && (
        <EquipmentPassportForm
          passport_schema={catalog.schema_tree}
          passport_values={updatedDevice.passport_values}
          onChange={props.onChange}
          editing={props.editing}
        />
      )}
    </>
  )
}

export default EquipmentRequestFormPassport
