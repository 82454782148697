import { createContext, PropsWithChildren, useEffect } from 'react'

interface Props {
  editing: boolean
  onSubmit: () => void
  onCancel?: () => void
}

/**
 * HTML form элемент для валидации формы, сохранению по нажатию Enter и отмены по Escape
 */
const Form = ({ editing, children, onCancel, onSubmit }: PropsWithChildren<Props>) => {
  // Listen for Escape key to cancel form
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !e.defaultPrevented) onCancel()
    }

    if (editing && onCancel) {
      window.addEventListener('keydown', onKeyDown)
      return () => window.removeEventListener('keydown', onKeyDown)
    }
  }, [editing])

  // validate form on submit
  const handleSubmit = (e) => {
    e.preventDefault()
    if (editing) onSubmit()
  }

  return (
    <FormContext.Provider value={{ form: true }}>
      <form onSubmit={handleSubmit}>{children}</form>
    </FormContext.Provider>
  )
}

// флаг для того чтобы дочерние элементы могли понять, когда они обернуты в форму, а когда нет
interface IFormContext {
  form: boolean
}

export const FormContext = createContext<IFormContext>({ form: false })

export default Form
