import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import Form from '../../../../../shared/Forms/Form'
import InputRow from '../../../../../shared/Inputs/InputRow'
import TextInput from '../../../../../shared/Inputs/TextInput'
import Loader from '../../../../../shared/Utils/Loader'
import { catalogsActions } from '../../../../Catalogs/catalogs.actions'
import * as api from '../../../../Catalogs/catalogs.api'
import { selectAllCatalogs } from '../../../../Catalogs/catalogs.selectors'
import { createCatalogItem } from '../../../../Catalogs/catalogs.utils'
import InstanceFormWrapper from '../../../../Catalogs/components/InstanceForm/InstanceFormWrapper'
import InstanceRow from '../../../../Catalogs/components/InstanceForm/InstanceRow'
import userTasksActions from '../../../userTasks.actions'
import userTaskEvents from '../../../userTasks.events'
import { ICatalogItemCreateTask } from '../../../userTasks.types'
import useUnmount from '../useUnmount'

interface IProps {
  task: ICatalogItemCreateTask
}

const CreateCatalogItemTaskForm = ({ task }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const catalogs = useSelector(selectAllCatalogs)
  const catalog = useMemo(() => catalogs.find((c) => c.id === task.catalog_id), [task.catalog_id, catalogs])

  const { loading, wait } = useHttpLoader()
  const [draftPayload, setDraftPayload] = useState(task.state ?? createCatalogItem(catalog))

  const handleChange = useCallback(
    (schemaId: string, value: any) => {
      setDraftPayload((prev) => ({ ...prev, properties: { ...prev.properties, [schemaId]: value } }))
    },
    [catalog]
  )

  const handleUnmount = () => {
    dispatch(userTasksActions.updateUserTask({ ...task, state: draftPayload }))
  }
  useUnmount(handleUnmount)

  if (!catalog) return <span className="text--danger">Not found</span>

  const handleSave = async () => {
    const request = api.postCreateInstance(draftPayload)

    wait(request, (result) => {
      dispatch(userTasksActions.deleteUserTask(task))
      if (!result) return

      dispatch(catalogsActions.instanceCreated(result))
      userTaskEvents.next({ type: 'complete', id: task.id, result: result })
    })
  }

  const rows = catalog.schema.map((schemaRow) => (
    <InstanceRow
      key={schemaRow.id}
      value={draftPayload.properties[schemaRow.id]}
      rowSchema={schemaRow}
      catalogs={catalogs}
      editing={true}
      onChange={handleChange}
    />
  ))

  return (
    <Form editing={true} onSubmit={handleSave}>
      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'common.name' })}>
          <TextInput
            value={draftPayload.name}
            name={'name'}
            onChange={(name) => setDraftPayload((prev) => ({ ...prev, name }))}
            required={true}
          />
        </InputRow>
      </div>
      <InstanceFormWrapper editing={true}>{rows}</InstanceFormWrapper>
      <div className="system__content-footer justify_flex_end" style={{ marginBottom: 0 }}>
        {!loading && (
          <button className="nsi-button default" type="submit">
            {intl.formatMessage({ id: 'common.save' })}
          </button>
        )}
        {loading && <Loader />}
      </div>
    </Form>
  )
}

export default CreateCatalogItemTaskForm
