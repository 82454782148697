import React, { CSSProperties } from 'react'

/**
 * Component that displays either plain text (if input not in editin mode),
 * or arbitrary input (if editing mode is on)
 */
const InputWrapper = (props: Props) => {
  return (
    <div className="system__input-wrapper" style={props.style}>
      {props.disabled ? <span className="system__input-disabled">{props.value}</span> : props.children}
    </div>
  )
}

interface Props {
  disabled: boolean
  value: string | number
  children: React.ReactNode
  style?: CSSProperties
}

export default InputWrapper
