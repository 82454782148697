import http, { handleHttpError, handleHttpResponse } from '../../../../utils/http'
import { ILogbookEntry } from 'au-nsi/logbook'

const API_PREFIX = '/nsi/v1/equipment_logbook'

export const createLogbookEntry = async (logbookEntry: ILogbookEntry) => {
  const created = await http.post(API_PREFIX, logbookEntry).then(handleHttpResponse).catch(handleHttpError)

  return created
}

export const getLogbookEntry = async (id: ILogbookEntry['id']): Promise<ILogbookEntry> => {
  const entry = await http
    .get(API_PREFIX + '/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return entry
}

export const updateLogbookEntry = async (updates: ILogbookEntry) => {
  const updated = await http
    .patch(API_PREFIX + '/' + updates.id, updates)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return updated ?? updates
}

export const removeLogbookEntry = async (toRemove: ILogbookEntry) => {
  const removed = await http
    .delete(API_PREFIX + '/' + toRemove.id)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  return removed
}

export const approveLogbookEntry = async (toApprove: ILogbookEntry) => {
  const approved = await http
    .post(API_PREFIX + '/' + toApprove.id + '/approve')
    .then(handleHttpResponse)
    .catch(handleHttpError)

  if (!approved) return toApprove
  return { ...toApprove, ...approved }
}
