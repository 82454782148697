import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ISVGTextSettings } from 'au-nsi/dashboards'
import React from 'react'
import RemoveButton from '../../../shared/Forms/RemoveButton'

/**
 * Строка таблицы настроек выводимых значений параметров в узлы SVG схемы
 */
const SettingsTextRow = (props: Props) => {
  const { row, index } = props

  const idOptions = props.idOptions.filter(({ value }) => value === row.node_id || !props.usedNodes.has(value))

  const handleChange = (value: string, key: string) => {
    props.onChange(key, value, index)
  }

  return (
    <tr>
      <td>
        <Select
          name="node_id"
          options={idOptions}
          value={row.node_id}
          onChange={handleChange}
          onHover={props.onNodeHover}
          required={true}
        />
      </td>
      <td>
        <Select
          name="device_id"
          options={props.deviceOptions}
          value={row.device_id}
          onChange={handleChange}
          required={true}
        />
      </td>
      <td>
        <Select
          name="parameter_id"
          options={props.parameterOptions}
          value={row.parameter_id}
          onChange={handleChange}
          required={true}
        />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={() => props.onRemove(index)} />
      </td>
    </tr>
  )
}

interface Props {
  deviceOptions: SelectOption[]
  idOptions: SelectOption[]
  index: number
  onChange: (key: string, value: string, index: number) => void
  onNodeHover: (id: string) => void
  onRemove: (index: number) => void
  parameterOptions: SelectOption[]
  row: ISVGTextSettings
  usedNodes: Set<string>
}

export default React.memo(SettingsTextRow)
