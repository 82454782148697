import { ITag } from 'au-nsi/shared'
import { ReactComponent as SaveIcon } from 'icons/check.svg'
import { KeyboardEventHandler, useState } from 'react'
import { useAppSelector } from '../../../redux/store'
import RemoveButton from '../../../shared/Forms/RemoveButton'

interface Props {
  tag: ITag
  onCancel: () => void
  onSave: (tag: ITag) => void
}

/**
 * Строка редактирования тэга
 */
const TagEditRow = ({ tag, onCancel, onSave }: Props) => {
  const tags = useAppSelector((state) => state.tags)
  const [name, setName] = useState(tag.name)

  const isDuplicate = tags.find((t) => t.name === name && t.id !== tag.id)

  const handleSave = () => {
    if (!isDuplicate) onSave({ ...tag, name })
  }

  const handleKeyUp: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') return handleSave()
    else if (e.key === 'Escape') return onCancel()
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
      <input
        className="nsi-input"
        style={{ height: '36px', border: isDuplicate ? '1px solid var(--danger-80)' : undefined }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyUp={handleKeyUp}
        autoFocus={true}
      />
      <SaveIcon width={14} className="svg-icon" onClick={() => handleSave()} />
      <RemoveButton onRemove={onCancel} />
    </div>
  )
}

export default TagEditRow
