import React from 'react'

class Override extends React.Component<Props> {
  render() {
    const { initialValue, currentValue } = this.props
    const shouldOverride = currentValue != null && currentValue !== initialValue

    if (!shouldOverride) {
      return <span>{initialValue}</span>
    }

    return (
      <span>
        {currentValue}
        <span className="nsi-override">{initialValue}</span>
      </span>
    )
  }
}

interface Props {
  initialValue: number
  currentValue: number
}

export default Override
