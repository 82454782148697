import { useIntl } from 'react-intl'

interface Props {
  speed: number
}

const SpeedControls = ({ speed }: Props) => {
  const intl = useIntl()
  let displayValue = speed.toString()

  if (speed < 1) {
    const factor = 1 / speed
    displayValue = '1/' + factor
  }

  return (
    <div className="chart-player__speed">
      {intl.formatMessage({ id: 'ChartPlayer.speed' }) + ':'}
      <span className="chart-player__speed-value">
        {displayValue}
        <span className="speed-value__icon">&times;</span>
      </span>
    </div>
  )
}

export default SpeedControls
