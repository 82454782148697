import { DataRate, DataRates, tableHeader } from '../dataRateUtils'
import { DataRateTableRow } from './DataRateTableRow'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import PlusButton from '../../../../../shared/Forms/PlusButton'
import { useIntl } from 'react-intl'

interface IProps {
  draft: DataRates
  updateDraft: (draft: DataRates) => void
  editable: boolean
  device_id: string
}

export const DataRatesTable = ({ device_id, editable, updateDraft, draft }: IProps) => {
  const intl = useIntl()

  const parameters = useSelector((state: ReduxState) => state.parameters.items)

  const unacceptedParams = parameters
    .filter((parameter) => draft.data_rates.some((dr) => dr.parameter_id === parameter.id))
    .reduce((obj, param) => Object.assign(obj, { [param.id]: { label: param.name, value: param.id } }), {})

  const acceptedParameters: { label: string; value: string }[] = parameters
    .filter((parameter) => !draft.data_rates.some((dr) => dr.parameter_id === parameter.id))
    .map((parameter) => {
      return { value: parameter.id, label: parameter.name }
    })

  const handleTableChange = (row: DataRate, index: number) => {
    updateDraft({
      data_rates: [...draft.data_rates.slice(0, index), row, ...draft.data_rates.slice(index + 1)],
    })
  }

  const handleInTableRemove = (index: number) => {
    updateDraft({
      data_rates: [...draft.data_rates.slice(0, index), ...draft.data_rates.slice(index + 1)],
    })
  }

  const handleRowAdd = () => {
    updateDraft({
      data_rates: [...draft.data_rates, { device_id: device_id, parameter_id: '', data_rate: 0 }],
    })
  }

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {tableHeader.map((col) => (
            <th key={col}>{intl.formatMessage({ id: col })}</th>
          ))}
          <th style={{ width: '3ch' }} />
        </tr>
      </thead>
    )
  }

  return (
    <>
      <h2 className={'system__title'}>{intl.formatMessage({ id: 'nsi.paramsFrequency' })}</h2>
      <table className={'nsi-settings-table '} style={{ tableLayout: 'fixed' }}>
        {renderTableHeader()}
        <tbody>
          {draft.data_rates.map((dataRate, index) => (
            <DataRateTableRow
              key={dataRate.parameter_id + dataRate.device_id + dataRate.data_rate + index}
              editable={editable}
              onRemove={handleInTableRemove}
              onChange={handleTableChange}
              index={index}
              row={dataRate}
              parameters={
                unacceptedParams[dataRate.parameter_id]
                  ? [...acceptedParameters, unacceptedParams[dataRate.parameter_id]]
                  : acceptedParameters
              }
            />
          ))}
        </tbody>
      </table>
      {editable && (
        <PlusButton
          onClick={handleRowAdd}
          style={{ marginLeft: '0' }}
          textId={intl.formatMessage({ id: 'nsi.addParameter' })}
        />
      )}
    </>
  )
}
