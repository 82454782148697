/**
 * Перерассчитать нижнюю и верхнюю границы. По возможности сохраняются текущие границы,
 * и изменяются только в случае если данные выходят за их пределы или отходят слишком далеко,
 * чтобы график не дергался при каждом изменении данных
 * @param realMin минимальное значение данных
 * @param realMax максимальное значение данных
 * @param currentMin текущая нижняя граница
 * @param currentMax текущая верхняя граница
 */
export const adjustMinMax = (realMin: number, realMax: number, currentMin: number, currentMax: number) => {
  const result = { min: currentMin, max: currentMax }
  const delta = realMax - realMin || Math.abs(realMax)

  if (realMax > result.max * 0.9) result.max = realMax + delta * 0.2
  if (realMax < result.max * 0.7) result.max = realMax + delta * 0.2

  if (realMin < result.min) result.min = realMin - delta * 0.2
  if (realMin === 0 && result.min < 0) result.min = 0

  return result
}
