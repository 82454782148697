import http from '../../utils/http'

/**
 * Класс для загрузки последних полученных значений. Работает по аналогии с DataLoader - собирает
 * запросы со всех компонентов, объединяет вместе и делает запрос на сервер
 */
class LastValuesLoader {
  private requests: Array<{ options: IRequestOption[]; cb: (result: ILastValues) => void }> = []

  public load(options: IRequestOption[], cb: (result: ILastValues) => void) {
    const shouldStartTimer = this.requests.length === 0
    this.requests.push({ options, cb })

    // ожидание запросов от других компонентов, чтобы отправить все вместе
    if (shouldStartTimer) setTimeout(this.sendRequests, 100)
  }

  private sendRequests = async () => {
    const requests = this.requests
    this.requests = []

    const combinedOptions = combineOptions(requests.flatMap((r) => r.options))
    const results: ILastValues = new Map()

    // по каждому устройству данные необходимо загружать отдельно
    const operations = combinedOptions.map((options) => {
      return http
        .post('/back/v1/tsdb/query', { query: 'last', ...options, ts: options.ts ? options.ts + '000000' : undefined })
        .then((r) => results.set(getOptionKey(options), r.data))
        .catch((err) => console.error(err))
    })

    await Promise.all(operations)

    // для каждого компонента находим какие устройства и параметры ему нужны
    // среди всех загруженных данных и возвращаем
    for (const { options, cb } of requests) {
      const result: ILastValues = new Map()

      for (const { id, ts, parameters, type } of options) {
        const key = getOptionKey({ id, ts, parameters, type })
        const deviceData = results.get(key) || {}
        const filteredData = {}

        for (const p of parameters) {
          if (deviceData[p]) filteredData[p] = deviceData[p]
        }

        const prev = result.get(id) || {}
        result.set(id, { ...prev, ...filteredData })
      }

      cb(result)
    }
  }
}

/**
 * Объединение запрашиваемых параметров по устройству. Чтобы загрузить данные одним запросом
 * если двум разным виджетам нужны данные по одному устройству, но по разным параметрам.
 */
const combineOptions = (options: IRequestOption[]) => {
  // device -> parameters set
  const requestedParameters = new Map<string, Set<string>>()

  for (const { id, ts, parameters, type } of options) {
    const key = getOptionKey({ id, ts, parameters, type })
    let params = requestedParameters.get(key)

    if (!params) {
      params = new Set<string>()
      requestedParameters.set(key, params)
    }

    for (const p of parameters) {
      params.add(p)
    }
  }

  const combined: IRequestOption[] = []

  for (const [key, parameters] of requestedParameters.entries()) {
    const [type, id, ts] = key.split(':')
    combined.push({ id, ts: +ts, parameters: [...parameters], type })
  }

  return combined
}

const getOptionKey = ({ id, ts, type }: IRequestOption): string => {
  const key = type + ':' + id
  return ts ? key + ':' + ts : key
}

interface IRequestOption {
  id: string
  ts?: number
  parameters: string[]
  type: string // processed or irregular
}

export type ILastValues = Map<string, Record<string, { ts: number; value: number }>>

export default new LastValuesLoader()
