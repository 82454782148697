import { SelectOption } from '@alterouniversal/au-react-components'
import { Primitive } from '../../interfaces'
import styles from './tagsInput.module.css'

interface IProps<T extends Primitive> {
  tags: SelectOption[]
  selectedTags: T[] | Set<T>
  onToggle: (toggled: T) => void
}

const TagsInput = <T extends Primitive>(props: IProps<T>) => {
  const renderTags = () => {
    return props.tags.map((tag) => (
      <div
        className={styles.tag}
        data-active={isContain(props.selectedTags, tag.value)}
        key={tag.label}
        onClick={() => props.onToggle(tag.value)}
      >
        {tag.label}
      </div>
    ))
  }

  return <div className={styles.container}>{renderTags()}</div>
}

const isContain = <T extends Primitive>(container: Set<T> | T[], value: T) => {
  if ('has' in container) return container.has(value)
  return container.includes(value)
}

export default TagsInput
