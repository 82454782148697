import { C37Equipment, Equipment } from 'au-nsi/equipment'
import { IPlace, PlatformModuleID } from 'au-nsi/shared'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxState } from '../../../redux/store.types'
import { IAttribute } from '../../../shared/Attributes/attributes.types'
import Panel from '../../../shared/FormPanel/FormPanel'
import KeyValueTable from '../../../shared/Forms/KeyValueTable/KeyValueTable'
import EditableText from '../../../shared/Inputs/EditableText/EditableText'
import { PLacePicker } from '../../../shared/Inputs/PlacePicker/PlacePicker'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import HistoryButton from '../../System/ActionsJournal/HistoryButton'
import AEPSPanel from '../Attributes/AEPSPanel'
import AttributesPanel from '../Attributes/AttributesPanel'
import RatedValuesPanel from '../Attributes/RatedValues'
import ComputableParameters from '../ComputableParameters/ComputableParameters'
import ConnectionPanel from '../ConnectionPanel/ConnectionPanel'
import DataVerificationPanel from '../DataVerification/DataVerification'
import * as actions from '../nsi.actions'
import { NsiPanel } from '../nsi.interfaces'
import { selectCompositeDevices, selectDevice, selectEquipmentNames } from '../nsi.selectors'
import { isDataReceiver } from '../nsi.utils'
import ParametersMappingPanel from '../ParametersMapping/ParametersMapping'
import DeviceTags from '../Tags/DeviceTags'
import AEEntranceInput from './AEEntranceInput'
import DataRate from './DataRate'
import DeviceControls from './DeviceControls'
import DeviceLogicalPath from './DeviceLogicalPath'
import DeviceNotes from './DeviceNotes'
import DevicePath from './DevicePath'
import DeviceStatus from './DeviceStatus'
import EquipmentPassportPanel from './EquipmentPassport/EquipmentPassportPanel'
import TitleRow from './TitleRow'

class EquipmentForm extends React.Component<Props> {
  private updateName = (name: string) => {
    const action = actions.patchEquipment(this.props.device.id, { name })
    this.props.dispatch(action)
  }

  private updateShortname = (shortname: string) => {
    const action = actions.patchEquipment(this.props.device.id, { shortname })
    this.props.dispatch(action)
  }

  private updateAddress = (place: IPlace) => {
    const action = actions.updateEquipmentAddress(this.props.device.id, { address: place })
    this.props.dispatch(action)
  }

  private validateName = (name: string) => {
    return this.props.names.has(name) ? 'nsi.device_name_duplication' : null
  }

  private validateShortname = (shortname: string) => {
    return this.props.shortnames.has(shortname) ? 'nsi.device_name_duplication' : null
  }

  private updateColor = (color: string) => {
    const action = actions.patchEquipment(this.props.device.id, { color })
    this.props.dispatch(action)
  }

  private togglePanel = (name: NsiPanel) => {
    this.props.dispatch(actions.togglePanel(name))
  }

  private handleConnectionConfigChange = (config: any) => {
    const action = actions.updateEquipmentConfig(this.props.device.id, config)
    this.props.dispatch(action)
  }

  // навигация к родительскому устройству
  private handleNavigate = (id: string) => {
    this.props.dispatch(actions.setSelectedDeviceId(id))
  }

  private updateNotes = (notes: Equipment['notes']) => {
    const action = actions.patchEquipment(this.props.device.id, { notes })
    this.props.dispatch(action)
  }

  private renderMeta() {
    const { device, allowEditing, modules } = this.props
    const isVirtual = !!device.path

    const pairs = []

    // id
    pairs.push([
      'ID',
      <div className="editable-text" style={{ cursor: 'auto' }}>
        {device.id}
      </div>,
    ])

    // shortname
    pairs.push([
      'nsi.shortname',
      <EditableText
        value={device.shortname}
        validator={this.validateShortname}
        onChange={this.updateShortname}
        allowEditing={allowEditing}
      />,
    ])

    // data rate
    pairs.push([
      this.props.translations['equipment.data_rate'],
      <DataRate
        device={this.props.device}
        translations={this.props.translations}
        allowEditing={this.props.allowEditing}
      />,
    ])

    // device address
    if (modules.has('maps')) {
      pairs.push([
        'nsi.address',
        <PLacePicker
          key={device.id}
          allowEditing={allowEditing}
          selectedPlace={device.address}
          onSave={this.updateAddress}
        />,
      ])
    }

    //location
    pairs.push(['nsi.location', <DevicePath allowEditing={allowEditing && !isVirtual} deviceId={device.id} />])

    // entrance for lp001 protocol only
    if (device.protocol === 'LP001') {
      pairs.push(['nsi.lp001.entrance', <AEEntranceInput device={device} />])
    }

    // logical path
    if (device.path !== '') {
      pairs.push(['nsi.parent_device', <DeviceLogicalPath device={device} onNavigate={this.handleNavigate} />])
    }

    pairs.push(
      ['nsi.device.notes', <DeviceNotes notes={device.notes} onSave={this.updateNotes} />],
      ['nsi.device.tags', <DeviceTags id={device.id} tags={device.tags} />],
      ['nsi.device_state', <DeviceStatus state={device.state} error={device.error} />]
    )

    return <KeyValueTable pairs={pairs} keyRowPercentWidth={35} style={{ marginBottom: '1em' }} />
  }

  private renderConnectionPanel() {
    const isComposite = this.props.compositeDevices.has(this.props.device.id)

    return (
      <ConnectionPanel
        device={this.props.device}
        allowConfiguring={this.props.allowConfiguring}
        translations={this.props.translations}
        isOpen={this.props.openPanels.connection_config}
        isComposite={isComposite}
        onToggle={this.togglePanel}
        onChange={this.handleConnectionConfigChange}
      />
    )
  }

  private renderParametersPanel() {
    return (
      <ParametersMappingPanel
        device={this.props.device}
        isOpen={this.props.openPanels.protocol_config}
        onToggle={this.togglePanel}
        allowEditing={this.props.allowConfiguring}
      />
    )
  }

  private renderDataVerification() {
    return (
      <Panel
        id="data_verification"
        title="nsi.data_verification"
        open={this.props.openPanels.data_verification}
        onToggle={this.togglePanel}
      >
        <DataVerificationPanel />
      </Panel>
    )
  }

  private renderRatedValues() {
    return (
      <Panel
        id="rated_values"
        title="nsi.rated_values"
        open={this.props.openPanels.rated_values}
        onToggle={this.togglePanel}
      >
        <RatedValuesPanel device={this.props.device} />
      </Panel>
    )
  }

  private renderComputableParameters() {
    return (
      <Panel
        id="computable_parameters"
        title="nsi.computable_parameters"
        open={this.props.openPanels.computable_parameters}
        onToggle={this.togglePanel}
      >
        <ComputableParameters device={this.props.device as C37Equipment} allowEditing={this.props.allowConfiguring} />
      </Panel>
    )
  }

  private renderAttributes() {
    const { id, attributes, protocol } = this.props.device

    // Для LP001 и LP001-Server аттрибуты используются bff`ом в других целях
    if (protocol === 'LP001' || protocol === 'LP001-Server') return null

    return (
      <Panel id="attributes" title="attributes" open={this.props.openPanels.attributes} onToggle={this.togglePanel}>
        <AttributesPanel id={id} attributes={attributes as IAttribute[]} />
      </Panel>
    )
  }

  private renderPassportPanel() {
    return (
      <Panel id={'passport'} open={this.props.openPanels.passport} title={'passport'} onToggle={this.togglePanel}>
        <div style={{ padding: '0 25px' }}>
          <EquipmentPassportPanel
            device={this.props.device}
            allowTemplateImport={false}
            formButtonsStyle={{ background: 'var(--bg-default-light)' }}
          />
        </div>
      </Panel>
    )
  }

  render() {
    const { device, allowEditing, modules } = this.props

    if (!device) {
      return <div className="nsi-main__wrapper" />
    }

    const { protocol } = device

    const isComposite = this.props.compositeDevices.has(device.id)
    const isVirtual = !!device.path
    const isLP001 = device.protocol.startsWith('LP001')

    // Если устройство не является приемником данных то показывать конфигурацию подключения не нужно
    const showStateControls = isDataReceiver(device)
    const showConnectionPanel =
      showStateControls || protocol === 'OPCDA' || protocol === 'modbustcp-client' || protocol === 'MQTT-Ob'

    // в случае C37 родительский ресивер только передает данные от других устройств и не имеет своих настроек (AU-3828)
    const showRatedValues = modules.has('au-data-quality') && !isComposite && protocol === 'C.37.118'
    const showDataVerification = modules.has('au-data-quality') && !isComposite && protocol === 'C.37.118'
    const showComputableParameters = modules.has('au-data-quality') && !isComposite && protocol === 'C.37.118'
    const showAEPSSettings = modules.has('aeps-worktime-parser') && protocol === 'modbustcp-client'

    // для LP001 - всегда скрываем
    // для modbus - всегда показываем
    // для остальных протоколов - скрываем только у составных устройств
    const showParametersConfig = isLP001 ? false : protocol === 'modbustcp-client' ? true : !isComposite

    const title = isComposite
      ? 'nsi.equipment.composite_device'
      : isVirtual
      ? 'nsi.equipment.virtual_device'
      : 'nsi.equipment.receiver'

    const toMapTopologyLink = modules.has('map_topology') && (
      <Link className="gray_link" to={`/map/device/${device.id}/main`}>
        <FormattedMessage id="map.equipment_move_to_map" />
      </Link>
    )

    return (
      <div className="nsi-main__wrapper">
        <div className="nsi-main__container" style={{ marginBottom: '240px' }}>
          <div className="nsi-main__header">
            <h2>
              <FormattedMessage id={title} />
            </h2>

            <div className="inline-flex ">
              {toMapTopologyLink}
              {this.props.showHistory && <HistoryButton resource="equipment" resourceId={device.id} />}
            </div>
          </div>

          <TitleRow
            name={device.name}
            color={device.color}
            validator={this.validateName}
            onNameChange={this.updateName}
            onColorChange={this.updateColor}
            allowEditing={allowEditing}
          />

          {this.renderMeta()}
          {showStateControls && <DeviceControls id={device.id} state={device.state} protocol={device.protocol} />}
          {showConnectionPanel && this.renderConnectionPanel()}
          {showParametersConfig && this.renderParametersPanel()}
          {showRatedValues && this.renderRatedValues()}
          {showDataVerification && this.renderDataVerification()}
          {showComputableParameters && this.renderComputableParameters()}
          {showAEPSSettings && <AEPSPanel id={device.id} attributes={device.attributes} allowEditing={allowEditing} />}
          {this.renderAttributes()}
          {this.props.showPassport && this.renderPassportPanel()}
        </div>
      </div>
    )
  }
}

interface Props {
  allowConfiguring: boolean
  allowEditing: boolean
  showPassport: boolean
  showHistory: boolean
  compositeDevices: Set<string>
  device: Equipment
  dispatch: (action: any) => void
  modules: Set<PlatformModuleID>
  names: Set<string>
  openPanels: { [name in NsiPanel]: boolean }
  shortnames: Set<string>
  translations: { [key: string]: string }
}

const mapStateToProps = (state: ReduxState) => {
  const rights = selectAccessRights(state)
  const modules = selectEnabledModules(state)

  return {
    modules,
    allowConfiguring: rights['equipment:configure'],
    allowEditing: rights['equipment:update'],
    showHistory: rights['journal:get_changelog'],
    showPassport: rights['equipment:get_passport'] && modules.has('catalogs'),
    compositeDevices: selectCompositeDevices(state),
    device: selectDevice(state),
    openPanels: state.nsi.openPanels,
    translations: state.language.translations,
    ...selectEquipmentNames(state),
  }
}

export default connect(mapStateToProps)(EquipmentForm)
