import { Unsubscribe } from '../../utils/events'
import globalClock from '../clock/clock.factory'
import Clock, { ClockTime } from '../clock/clock.service'
import ws from '../ws/ws'
import * as utils from './buffer.utils'
import { DataMap, IDataArray, IDataChunk, IDataIntervals, IDataSelector, ITickInfo, Timespan } from './data.types'
import loader, { DataLoaderResponse } from './DataLoader'
import { ILastValues } from './LastValuesLoader'
import lastValuesLoader from './LastValuesLoader'
import { getDataKey, groupSelectors, isEqualArrays } from './misc.utils'
import * as select from './select.utils'
import { computeStack } from './stack.utils'

export default class DataService {
  private onlineData: DataMap = new Map()
  private onlineBuffer: DataMap = new Map()
  private archiveData: DataMap = new Map()
  private predictedData: DataMap = new Map()

  private onlineTimespan: Timespan = { t0: 0, t1: 0 }
  private archiveTimespan: Timespan = { t0: 0, t1: 0 }

  private loaderId = 0
  private selectors: Required<IDataSelector>[] = []

  // если необходимы будут только данные за один момент времени (как в таблице), а не интервал (как на графике)
  private singleValueMode = false

  private msPerFrame = 0
  private pointsPerFrame = 0
  private autoAggregationWindow = 0
  private userAggregationWindow = null // если пользователь вручную зафиксировал шаг аггрегации
  private shouldComputeStack = false // рассчитывать кумулятивную сумму по всем устройствам

  // настройки загрузки прогнозируемых данных
  private predictionTime = 0
  private predictionLoaderId = 0
  private predictionTimer = null

  // интервал между измерениями в миллисекундах для каждого устройства
  private dataIntervals = new Map<string, IDataIntervals>()
  private maxDataInterval = 0 // наибольший интервал среди использующихся устройств

  // id целочисленных параметров (т.к. они требуют отдельной обработки)
  public integerParameters = new Set<string>()
  // id параметров не имеющих определенной частоты приема данных (также требуют отдельной обработки)
  private irregularParameters = new Set<string>()

  private unsubscribe: Unsubscribe[] = []

  private dataUpdated = false
  private dataUpdatedReason = ''

  private isStarted = false
  private isWsConnected = false
  private playerTime = 0

  public isLoading = false
  public clock: Clock
  public onTick: (tick: ITickInfo) => void = () => null

  // получение ширина окна аггрегации - по умолчанию определяется автоматически по
  // ширине графика в пикселях, но при необходимости пользователь может вручную установить
  // нужное значение (будет проигнорировано, если оно меньше автоматического, чтобы случайно
  // не уронить фронт и базу выгружая слишком много данных)
  private get aggregationWindow(): number {
    return this.userAggregationWindow
      ? Math.max(this.userAggregationWindow, this.autoAggregationWindow)
      : this.autoAggregationWindow
  }

  constructor(private id: number, options: Partial<IDataServiceOptions> = {}) {
    this.singleValueMode = options.singleValueMode || false
    this.clock = options.clock || globalClock

    this.loaderId = loader.register(this.handleArchiveData)
  }

  // задать какие параметры с каких устройств необходимо загружать
  setDataSelectors(s: IDataSelector[]) {
    const selectors = groupSelectors({
      dataIntervals: this.dataIntervals,
      irregularParameters: this.irregularParameters,
      selectors: s,
    })

    if (this.isEqualSelectors(selectors)) return

    // перерассчитать максимальный интервал между измерениями по запрашиваемым устройствам
    this.maxDataInterval = 0
    for (const s of selectors) {
      const interval = this.getDataInterval(s.device_id, s.parameters[0])
      this.maxDataInterval = Math.max(this.maxDataInterval, interval)
    }

    this.selectors = selectors
    this.preformatEverything()
    this.isWsConnected = false

    if (selectors.length === 0) {
      return this.isStarted && this.stop()
    }

    if (!this.isStarted) {
      return this.start()
    }

    ws.updateSubscription({ id: this.id, query: this.selectors, onData: this.handleSocketData })
    loader.cancel(this.loaderId)
    this.loadData()
    this.reloadPredictions()
  }

  destroy() {
    this.stop()
    loader.unregister(this.loaderId)
    loader.unregister(this.predictionLoaderId)
    clearInterval(this.predictionTimer)
  }

  setMsPerFrame(ms: number) {
    const prevWindow = this.aggregationWindow

    this.msPerFrame = ms
    this.adjustAggregationWindow(prevWindow)
    this.markForUpdate('frame')

    if (!this.isStarted) this.start()
    else this.loadData()
  }

  setPointsPerFrame(points: number) {
    const prevWindow = this.aggregationWindow

    this.pointsPerFrame = points
    this.adjustAggregationWindow(prevWindow)
    this.markForUpdate('frame')
    if (!this.isStarted) this.start()
  }

  // возможность пользователю вручную указать шаг аггрегирования
  setAggregationWindow(value: number) {
    const prevWindow = this.aggregationWindow

    if (value) {
      this.userAggregationWindow = value
      this.loadData()
    } else {
      this.userAggregationWindow = null
      this.adjustAggregationWindow(prevWindow)
    }
  }

  setStacked(value: boolean) {
    if (this.shouldComputeStack !== value) {
      this.shouldComputeStack = value
      this.preformatEverything()
    }
  }

  // выставление интервала времени в будущем за который будет загружаться прогноз
  setPredictionTime(time: number) {
    if (!time && !this.predictionTime) return
    if (time === this.predictionTime) return

    const shouldStart = !this.predictionTime && time
    const shouldStop = this.predictionTime && !time
    this.predictionTime = time

    // запускаем периодическую загрузку прогнозируемых данных
    if (shouldStart) {
      this.preformat(this.predictedData)
      this.predictionLoaderId = loader.register(this.handlePredictedData)
      this.reloadPredictions()
    }

    // останавливаем загрузку прогнозов и очищаем ресурсы
    if (shouldStop) {
      this.predictedData = new Map()
      loader.unregister(this.predictionLoaderId)
      clearInterval(this.predictionTimer)
    }
  }

  setDataIntervals(intervals: Map<string, IDataIntervals>) {
    this.dataIntervals = intervals
    this.setDataSelectors(this.selectors)
  }

  setIrregularParameters(value: Set<string>) {
    this.irregularParameters = value
    this.setDataSelectors(this.selectors)
  }

  markForUpdate(reason = '') {
    this.dataUpdated = true
    this.dataUpdatedReason = reason
  }

  getUpdateReason() {
    return this.dataUpdatedReason
  }

  /**
   * Проверка необходимости обновления данных отображаемых компонентом (при обновлении раз в секунду)
   */
  shouldRender() {
    return this.clock.shouldRender(this.id)
  }

  selectPoint(options: ISelectPointOptions) {
    const id = options.deviceId
    const ts = options.ts
    const useStackedValues = options.useStackedValues && this.shouldComputeStack
    const targetKey = options.parameterId ? this.getDataKey(id, options.parameterId) : null

    const placeholder: IDataArray = { ts: [] }
    const result: { [key: string]: number } = {}

    // параметры от одного устройства могут храниться с разными ключами из-за разного
    // data rate, поэтому проходим по всем относящимся к устройству данными и объединяем
    for (const selector of this.selectors) {
      if (selector.device_id !== id) continue
      if (targetKey && targetKey !== selector.key) continue

      const timeStep = this.getTimeStep(id, selector.parameters[0])

      let { key } = selector
      if (useStackedValues) key += ':stack'

      // в онлайн данных допустима задержка некоторых данных на 1 секунду
      const interval = this.clock.isOnline() ? Math.max(timeStep, 1000) : timeStep

      const onlineData = this.onlineData.get(key) || placeholder
      const archiveData = this.archiveData.get(key) || placeholder
      const predictedData = this.predictedData.get(key) || placeholder

      // search in online data first
      const onlineIndex = select.findIndex(onlineData.ts, ts, 2 * interval)
      const onlineDelta = onlineIndex !== -1 ? Math.abs(onlineData.ts[onlineIndex] - ts) : Number.MAX_SAFE_INTEGER
      let isFound = onlineIndex !== -1 && onlineDelta < timeStep && !selector.irregular

      // if not found in online or parameter is irregular, search in archive
      // for irregular data always check archive, because we can't know if found online point is close enough
      const archiveIndex = !isFound ? select.findIndex(archiveData.ts, ts, 2 * interval) : -1
      const archiveDelta = archiveIndex !== -1 ? Math.abs(archiveData.ts[archiveIndex] - ts) : Number.MAX_SAFE_INTEGER
      isFound = archiveIndex !== -1 && archiveDelta < timeStep && !selector.irregular

      // if not found in archive and if prediction is enabled, search in predicted data
      const predictedIndex = !isFound && this.predictionTime ? select.findIndex(predictedData.ts, ts, 2 * interval) : -1
      const predictedDelta =
        predictedIndex !== -1 ? Math.abs(predictedData.ts[predictedIndex] - ts) : Number.MAX_SAFE_INTEGER

      let data = onlineData
      let index = onlineIndex
      let delta = onlineDelta

      // на границе онлайн и архивных данных близкие точки могут найтись в обоих участках
      // поэтому выбираем наиболее близкую к запрашиваемому времени
      if (archiveDelta < delta) {
        data = archiveData
        index = archiveIndex
        delta = archiveDelta
      }

      if (predictedDelta < delta) {
        data = predictedData
        index = predictedIndex
        delta = predictedDelta
      }

      if (index !== -1) {
        for (const [key, values] of Object.entries(data)) {
          result[key] = values[index]
        }
      }
    }

    return result
  }

  selectCurrentPoint(id: string) {
    return this.selectPoint({ deviceId: id, parameterId: null, ts: this.playerTime, useStackedValues: false })
  }

  selectRange(options: ISelectRangeOptions) {
    const { t0, t1, deviceId, parameterId } = options
    const allowOverlap = options.allowOverlap ?? true
    const includePrediction = options.includePrediction ?? false
    const useStackedValues = options.useStackedValues && this.shouldComputeStack

    let key = this.getDataKey(deviceId, parameterId)
    if (useStackedValues) key += ':stack'

    const onlineData = this.onlineData.get(key) || { ts: [] }
    const archiveData = this.archiveData.get(key) || { ts: [] }

    const archiveBoundaries = select.findBoundaries(archiveData.ts, t0, t1)

    const threshold = archiveBoundaries.i1 !== -1 ? archiveData.ts[archiveBoundaries.i1] : t0
    const onlineBoundaries = select.findBoundaries(onlineData.ts, threshold, t1)

    const result: IDataChunk[] = []

    // последняя точка архивных данных может совпадать с первой точкой онлайн, при
    // необходимости убираем это пересечение
    if (!allowOverlap && onlineBoundaries.i0 !== -1 && archiveBoundaries.i1 !== -1) {
      if (onlineData.ts[onlineBoundaries.i0] === archiveData.ts[archiveBoundaries.i1]) {
        onlineBoundaries.i0 += 1
      }
    }

    if (archiveBoundaries.i0 !== -1 && archiveBoundaries.i1 !== -1) {
      result.push({ ...archiveBoundaries, data: archiveData })
    }

    if (onlineBoundaries.i0 !== -1 && onlineBoundaries.i1 !== -1) {
      result.push({ ...onlineBoundaries, data: onlineData })
    }

    // если включен прогноз, то ищем данные и в нем
    if (includePrediction) {
      const predictedData = this.predictedData.get(key) || { ts: [] }
      const predictedBoundaries = select.findBoundaries(predictedData.ts, this.clock.getServerTime(), t1)

      if (predictedBoundaries.i0 !== -1 && predictedBoundaries.i1 !== -1) {
        result.push({ ...predictedBoundaries, data: predictedData })
      }
    }

    return result
  }

  // интервал времени (в миллисекундах) между последовательными пакетами данных от устройства с id
  getTimeStep(deviceId: string, parameterId: string) {
    const interval = this.getDataInterval(deviceId, parameterId)
    return this.singleValueMode ? interval : Math.max(interval, this.aggregationWindow)
  }

  // аггрегируются (разрежаются) данные от устройства с id или хранятся в исходном виде
  isAggregated(deviceId: string, parameterId: string) {
    const interval = this.getDataInterval(deviceId, parameterId)
    return !this.singleValueMode && interval && this.aggregationWindow >= 2 * interval
  }

  private getDataInterval(deviceId: string, parameterId: string) {
    const irregular = this.irregularParameters.has(parameterId)
    if (irregular) return Number.POSITIVE_INFINITY

    const interval = this.dataIntervals.get(deviceId)
    if (!interval) return Number.POSITIVE_INFINITY

    return interval.parameters.get(parameterId) || interval.default
  }

  private start() {
    if (this.isStarted) return
    if (this.selectors.length === 0 || this.dataIntervals.size === 0) return
    if (!this.singleValueMode && (this.pointsPerFrame === 0 || this.msPerFrame === 0)) return

    this.isStarted = true
    this.autoAggregationWindow = Math.round(this.msPerFrame / this.pointsPerFrame)

    this.clock.registerComponent()
    this.unsubscribe.push(this.clock.time$.subscribe(this.handleClockTick))
    this.unsubscribe.push(this.clock.online$.subscribe(this.handleOnlineChange))

    ws.subscribe({ id: this.id, query: this.selectors, onData: this.handleSocketData })
    this.loadData()
    this.reloadPredictions()
  }

  private stop() {
    if (this.isStarted) {
      this.isStarted = false
      this.clock.unregisterComponent()
      this.unsubscribe.forEach((fn) => fn())
      ws.unsubscribe(this.id)
    }
  }

  private adjustAggregationWindow(prevWindow: number) {
    this.autoAggregationWindow = Math.round(this.msPerFrame / this.pointsPerFrame)

    if (prevWindow !== this.aggregationWindow) {
      this.loadData()
    }
  }

  // обработка новых данных пришедших по сокетам
  private handleSocketData = (data: DataMap, query: IDataSelector[]) => {
    // при слишком большом кадре и широком окне аггрегации перестаем обрабатывать онлайн данные, т.к.
    // их хранение во временном буфере перед аггрегацией займет слишком много памяти, в таком режиме
    // проще периодически делать запрос в базу за уже разреженными данными
    if (this.maxDataInterval && this.aggregationWindow > 1000 * this.maxDataInterval) {
      return
    }

    // при получении первого пакета данных, уведомляем об этом clock для того чтобы
    // отобразить их сразу же на следующем фрейме
    if (!this.isWsConnected) {
      this.isWsConnected = true
      this.clock.invalidateRender(this.id)
    }

    const playerTime = this.clock.getPlayerTime()
    const serverTime = this.clock.getServerTime()
    let updateReason = 'ws'

    utils.pushToBuffer(this.onlineBuffer, data, query)

    for (const [key, points] of this.onlineBuffer.entries()) {
      const selector = this.selectors.find((s) => s.key === key)
      const step = this.getTimeStep(selector.device_id, selector.parameters[0])
      const shouldAggregate = this.isAggregated(selector.device_id, selector.parameters[0])

      // некоторые компоненты используют оптимизации в расчете на то, что онлайн данные
      // приходят с текущим временем и почти без задержек, если это условие не выполнено
      // то им необходимо это знать, иначе пользователь может увидеть не совсем верные данные
      if (points.ts.length > 0 && points.ts[0] < playerTime - 2 * step) {
        updateReason = 'archive'
      }

      if (shouldAggregate) {
        const { parameters } = this.selectors.find((s) => s.key === key)

        utils.processBuffer(this.onlineBuffer, this.onlineData, {
          id: key,
          window: this.aggregationWindow,
          parameters,
          residueSize: 15,
          integerParameters: this.integerParameters,
        })
      } else {
        utils.copyFromBuffer(key, this.onlineBuffer, this.onlineData)
      }
    }

    // после получения новых данных удаляем более ненужные измерения
    const truncateThreshold = this.singleValueMode
      ? Math.max(this.playerTime, serverTime - 10_000)
      : serverTime - 1.2 * this.msPerFrame

    utils.truncateData(this.onlineData, truncateThreshold)
    this.onlineTimespan = select.timespan(this.onlineData)

    if (this.shouldComputeStack) this.computeStack(this.onlineData)
    this.markForUpdate(updateReason)
  }

  private handleArchiveData = (err: Error, data: DataLoaderResponse[]) => {
    if (err != null) {
      this.archiveTimespan.t0 = 0
      this.archiveTimespan.t1 = 0
      return
    }

    for (const row of data) {
      const key = this.getDataKey(row.id, row.parameters[0])
      this.archiveData.set(key, row.points)
    }

    if (this.shouldComputeStack) this.computeStack(this.archiveData)

    // из-за аггрегации и расчете среднего целочисленные параметры могут принимать нецелые значения
    // поэтому перед отображением их надо округлить
    utils.roundIntegers(this.archiveData, this.integerParameters)

    const timespan = select.timespan(this.archiveData)
    utils.truncateData(this.onlineData, timespan.t1, 0)
    this.onlineTimespan = select.timespan(this.onlineData)

    this.isLoading = loader.isLoading(this.loaderId)
    this.clock.invalidateRender(this.id)
    this.markForUpdate('archive')
  }

  private handleLastValues = (data: ILastValues) => {
    for (const { device_id, parameters, irregular } of this.selectors) {
      if (!irregular) continue

      const archiveData = { ts: [] }
      const values = data.get(device_id) || {}

      for (const p of parameters) {
        const value = values[p]
        if (!value) continue

        archiveData.ts = [value.ts / 1000]
        archiveData[p] = [value.value]
      }

      const key = this.getDataKey(device_id, parameters[0])
      this.archiveData.set(key, archiveData)
    }

    this.clock.invalidateRender(this.id)
    this.markForUpdate('archive')
  }

  private handlePredictedData = (err: Error, data: DataLoaderResponse[]) => {
    if (err != null) return

    this.markForUpdate('archive')
    this.predictedData = new Map()

    for (const row of data) {
      const key = this.getDataKey(row.id, row.parameters[0])
      this.predictedData.set(key, row.points)
    }

    if (this.shouldComputeStack) this.computeStack(this.predictedData)
  }

  private handleClockTick = (time: ClockTime) => {
    if (!this.isStarted) return false
    if (!this.dataUpdated && this.playerTime === time.playerTime && !this.isLoading) return false

    // difference should be normally ~16ms, if it is larger, it means time shift occured
    // and we need to perform full rerender
    if (Math.abs(this.playerTime - time.playerTime) > 400) {
      this.markForUpdate('timesync')
    }

    this.playerTime = time.playerTime

    this.onTick({
      playerTime: time.playerTime - this.aggregationWindow,
      serverTime: time.serverTime,
      isOnline: this.clock.isOnline(),
      dataUpdated: this.dataUpdated,
    })

    this.dataUpdated = false

    const shouldLoad = this.shouldLoadData(time.playerTime)
    if (shouldLoad) this.loadData()
  }

  // при отключении онлайн режима плеера загружаем архивные данные
  private handleOnlineChange = (online: boolean) => {
    if (!online && this.singleValueMode) {
      this.loadData()
    }
  }

  // проверка необходимости дозагрузки данных
  private shouldLoadData(playerTime: number) {
    const isOnline = this.clock.isOnline()
    const interval = this.maxDataInterval !== Number.POSITIVE_INFINITY ? this.maxDataInterval : 0

    switch (true) {
      case this.singleValueMode && isOnline:
        if (this.maxDataInterval < 2_000) return false
        if (playerTime - this.onlineTimespan.t1 < 60_000) return false
        if (playerTime - this.archiveTimespan.t1 < 60_000) return false
        return true

      case this.singleValueMode && !isOnline:
        return playerTime - interval < this.archiveTimespan.t0 || playerTime + 2_000 > this.archiveTimespan.t1

      case !this.singleValueMode && isOnline:
        let delta = this.maxDataInterval === Number.POSITIVE_INFINITY ? 60_000 : 5_000
        delta = Math.max(delta, 2 * this.aggregationWindow)

        const t0 = playerTime - this.msPerFrame
        const t1 = Math.max(playerTime - delta, t0)
        return !this.isRangeLoaded(t0, t1)

      case !this.singleValueMode && !isOnline:
        const ts1 = Math.min(playerTime + 5_000, playerTime + 1.5 * this.msPerFrame)
        return !this.isRangeLoaded(playerTime - this.msPerFrame, ts1)
    }
  }

  // Рассчитать интервал времени который нужно загрузить из базы
  private getLoadingRange(playerTime: number) {
    const isOnline = this.clock.isOnline()
    const interval = this.maxDataInterval !== Number.POSITIVE_INFINITY ? this.maxDataInterval : 0

    switch (true) {
      case this.singleValueMode && isOnline:
        return { t0: playerTime - 2 * interval, t1: this.clock.getServerTime() }
      case this.singleValueMode && !isOnline:
        return { t0: playerTime - 2 * interval, t1: playerTime + 10_000 }
      case !this.singleValueMode && isOnline:
        return { t0: playerTime - this.msPerFrame, t1: this.clock.getServerTime() }
      case !this.singleValueMode && !isOnline:
        const t1 = Math.min(playerTime + 20_000, playerTime + 2 * this.msPerFrame)
        return { t0: playerTime - this.msPerFrame, t1 }
    }
  }

  private isRangeLoaded(t0: number, t1: number) {
    const inOnline = t0 >= this.onlineTimespan.t0 && t1 <= this.onlineTimespan.t1
    if (inOnline) return true

    const inArchive = t0 >= this.archiveTimespan.t0 && t1 <= this.archiveTimespan.t1
    if (inArchive) return true

    // in overlap
    let d = Math.max(this.aggregationWindow, this.maxDataInterval)
    if (d === Number.POSITIVE_INFINITY) d = 0

    return (
      this.archiveTimespan.t1 + d >= this.onlineTimespan.t0 &&
      t0 >= this.archiveTimespan.t0 &&
      t1 <= this.onlineTimespan.t1
    )
  }

  private reloadPredictions = () => {
    if (!this.predictionTime || !this.predictionLoaderId) return

    clearTimeout(this.predictionTimer)
    loader.cancel(this.predictionLoaderId)

    const tsFrom = Date.now()
    const tsTo = tsFrom + this.predictionTime + 3000
    const type = 'predicted' as const

    const options = this.selectors.map((selector) => {
      const aggregationWindow = this.getAggregationWindow(selector.device_id, selector.parameters[0])
      return { ...selector, tsFrom, tsTo, aggregationWindow, interval: selector.interval, type }
    })

    loader.load(this.predictionLoaderId, options)
    this.predictionTimer = setTimeout(this.reloadPredictions, 2000)
  }

  private loadData() {
    if (this.isStarted && this.playerTime) {
      const range = this.getLoadingRange(this.playerTime)
      const t0 = Math.floor(range.t0)
      const t1 = Math.ceil(range.t1)

      this.archiveTimespan.t0 = t0
      this.archiveTimespan.t1 = t1

      const rangeOptions = []
      const pointOptions = []

      for (const selector of this.selectors) {
        const type = selector.irregular ? ('irregular' as const) : ('processed' as const)

        if (selector.irregular && this.singleValueMode) {
          const ts = this.clock.isOnline() ? undefined : Math.floor(this.playerTime)
          pointOptions.push({ id: selector.device_id, ts, parameters: selector.parameters, type })
          continue
        }

        const aggregationWindow = this.getAggregationWindow(selector.device_id, selector.parameters[0])
        rangeOptions.push({ ...selector, tsFrom: t0, tsTo: t1, aggregationWindow, type })
      }

      if (rangeOptions.length > 0) loader.load(this.loaderId, rangeOptions)
      if (pointOptions.length > 0) lastValuesLoader.load(pointOptions, this.handleLastValues)
      this.isLoading = true
    }
  }

  private getDataKey(deviceId: string, parameterId: string) {
    return getDataKey({
      deviceId,
      parameterId,
      dataIntervals: this.dataIntervals,
      irregularParameters: this.irregularParameters,
    })
  }

  // сравнить равны ли новые параметры запрашиваемых данных и предыдущие
  private isEqualSelectors(selectors: IDataSelector[]): boolean {
    if (selectors.length !== this.selectors.length) {
      return false
    }

    for (let i = 0; i < selectors.length; i++) {
      const prev = this.selectors[i]
      const next = selectors[i]

      const notEqual = prev.key !== next.key || !isEqualArrays(prev.parameters, next.parameters)
      if (notEqual) return false
    }

    return true
  }

  private getAggregationWindow(deviceId: string, parameterId: string) {
    const delta = this.getDataInterval(deviceId, parameterId) || Number.POSITIVE_INFINITY
    const w = this.aggregationWindow || 0

    // запрашиваем аггрегированные данные только если окно аггрегации больше 2х интервалов
    // между измерениями, и округляем окно до целого значения таких интервалов
    return w < 2 * delta ? 0 : w - (w % delta)
  }

  private preformatEverything() {
    this.preformat(this.onlineBuffer)
    this.preformat(this.onlineData)
    this.preformat(this.archiveData)
    if (this.predictionTime) this.preformat(this.predictedData)
  }

  // удалить данные по устройствам и параметрам которые больше не используются
  // и инициализировать все новые необходимые поля
  private preformat(data: DataMap) {
    // удаляем лишние поля
    for (const [key, values] of data.entries()) {
      const selector = this.selectors.find((s) => s.key === key)

      if (!selector) {
        data.delete(key)
        continue
      }

      for (const parameter of Object.keys(values)) {
        if (parameter !== 'ts' && !selector.parameters.includes(parameter)) {
          delete values[parameter]
        }
      }
    }

    // инициализируем новые
    for (const { key, parameters } of this.selectors) {
      if (!data.has(key)) {
        data.set(key, { ts: [] })
      }

      const values = data.get(key)
      const ts = values.ts

      for (const parameter of parameters) {
        if (!values[parameter]) {
          const placeholder = ts.map(() => null)
          values[parameter] = placeholder
        }
      }
    }
  }

  private computeStack(data: DataMap) {
    const steps = new Map(this.selectors.map((s) => [s.key, this.getTimeStep(s.device_id, s.parameters[0])]))
    computeStack(data, this.selectors, steps)
  }
}

export interface IDataServiceOptions {
  singleValueMode: boolean
  clock: Clock
}

interface ISelectRangeOptions {
  deviceId: string
  parameterId: string
  t0: number
  t1: number
  allowOverlap: boolean
  includePrediction: boolean
  useStackedValues?: boolean
}

interface ISelectPointOptions {
  deviceId: string
  ts: number
  parameterId?: string
  useStackedValues?: boolean
}
