import http, { handleHttpError } from '../../utils/http'
import { NotificationSettings } from 'au-nsi/notifications'

const url = '/nsi/v1/notifications/settings/'

export const getUserSettings = (id: number) => loadSettings(url + 'users/' + id)
export const getRoleSettings = (id: number) => loadSettings(url + 'roles/' + id)

const loadSettings = (path: string) => {
  return http
    .get(path)
    .then((r) => r.data || defaultNotificationSettings)
    .catch((err) => {
      if (err.response && err.response.status === 404) return defaultNotificationSettings
      else throw err
    })
}

export const updateUserSettings = (id: number, updates: Partial<NotificationSettings>) => {
  return http.patch(url + 'users/' + id, updates).catch(handleHttpError)
}

export const updateRoleSettings = (id: number, updates: Partial<NotificationSettings>) => {
  return http.patch(url + 'roles/' + id, updates).catch(handleHttpError)
}

export const updateUserSettingsSource = (id: number, use_role_ntf_settings: boolean) => {
  return http.post(`${url}users/${id}/source`, { use_role_ntf_settings })
}

export const getNotificationOptions = () => {
  return http
    .get<{ user_actions: UserActionOptions[] }>('/nsi/v1/notifications/options')
    .then((r) => r.data.user_actions)
}

export const defaultNotificationSettings: NotificationSettings = {
  id: undefined,
  system_messages: { email: { levels: [] } },
  moderation: { email: { resources: [], events: [] } },
  user_actions: { email: {}, web: {} },
  equipment: [],
  formula: [],
}

// ресурс и действия с ним, о которых можно включить уведомления
export interface UserActionOptions {
  resource: string
  actions: string[]
  right_id: string
  module_id: string
}
