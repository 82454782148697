import { useIntl } from 'react-intl'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { ReactComponent as CatalogIcon } from '../../../../icons/catalog.svg'
import { ReactComponent as CreateBtnSvg } from '../../../../images/icons/nsi/add-button.svg'
import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg'
import { ReactComponent as ObjectIcon } from '../../../../icons/object.svg'
import { ICatalog, ICatalogItem } from 'au-nsi/catalogs'
import { Organization, OrganizationType } from 'au-nsi/organizations'
import { OrganizationTypeWithInstances } from '../../Organizations/organizations.types'
import { AnyCatalogWithInstances } from '../../catalogs.types'
import HideableLeftMenu from '../../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import useTree from '../../../../shared/Tree/useTree'
import Tree from '../../../../shared/Tree/Tree'
import SpanLink from '../../../../shared/Utils/SpanLink'
import styles from '../../catalogs.module.css'
import catalogTreeIdCodec from './catalogTreeIdCodec'
import { parseCatalogsUrl } from '../../catalogs.utils'

type ITreeItem = OrganizationTypeWithInstances | AnyCatalogWithInstances
type ITreeNode = ((ICatalog | OrganizationType) & { children: ITreeNode }) | (ICatalogItem | Organization)
interface IProps {
  allowSchemasEditing: boolean
  allowInstancesEditing: boolean
  items: ITreeItem[]
  itemCreatePath: string
  instanceCreatePathGenerator: (id: number) => string
  title: string
  routePrefix?: string
  displayNesting?: boolean
}

const CatalogTree = ({
  allowInstancesEditing,
  allowSchemasEditing,
  items,
  title,
  routePrefix,
  itemCreatePath,
  instanceCreatePathGenerator,
  displayNesting,
}: IProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const nodes = useMemo(() => buildTree(items, displayNesting), [items])
  const tree = useTree<ITreeNode>({ key: routePrefix, persistenceStrategy: 'inmemory' })

  // В случае смены uri необходимо выбрать соответсвующий элемент в дереве
  useEffect(() => {
    const parsed = parseCatalogsUrl(location.pathname)
    if (!parsed) return

    let encodedId
    if (parsed.type === 'catalog') {
      encodedId = catalogTreeIdCodec.encodeCatalog({ id: parsed.selectedCatalogId })
    } else {
      encodedId = catalogTreeIdCodec.encodeInstance({ id: parsed.selectedItemId }, parsed.selectedCatalogId)
    }

    tree.handleSelect(encodedId)
  }, [location.pathname])

  useEffect(() => {
    const nodeId = tree.selectedNodeId as any
    if (!nodeId) return

    const isCatalogSelected = catalogTreeIdCodec.isIdBelongsToCatalog(nodeId)

    if (isCatalogSelected) {
      const id = catalogTreeIdCodec.decodeCatalog(nodeId)
      navigate(routePrefix + id)
    } else {
      const [id, parentId] = catalogTreeIdCodec.decodeInstance(nodeId)
      navigate(routePrefix + parentId + '/instance/' + id)
    }
  }, [tree.selectedNodeId])

  const handleSelect = (nodeId: string) => tree.handleSelect(nodeId as any)

  const renderInstance = (item: Organization | ICatalogItem) => {
    return (
      <span className="link_normalize tpl-node__padding">
        <CatalogIcon style={{ margin: '0 10px', width: 20, height: 20, flexShrink: 0 }} />
        <span>{item.name}</span>
      </span>
    )
  }

  const renderNode = ({ node }: { node: ITreeNode }) => {
    if (!('children' in node)) return renderInstance(node)

    const id = catalogTreeIdCodec.decodeCatalog(node.id as unknown as string)
    const plusIcon = (
      <PlusIcon className={styles.catalogs__icon} style={{ width: 20, height: 20, color: 'var(--success-70)' }} />
    )
    return (
      <span className="link_normalize tpl-node__padding tpl-node-inline-flex" style={{ gap: '0' }}>
        <ObjectIcon className={styles.objectIcon} />
        <span>{node.name}</span>
        <div className={'nsi__row-controls'}>
          <div className={'nsi__row-control'}>
            {allowInstancesEditing && (
              <SpanLink
                title={plusIcon}
                to={instanceCreatePathGenerator ? instanceCreatePathGenerator(id) : routePrefix}
              />
            )}
          </div>
        </div>
      </span>
    )
  }

  return (
    <HideableLeftMenu>
      <div className={'nsi-tree_title'}>
        <div>{intl.formatMessage({ id: title })}</div>
        <div className={'nsi__title-controls'}>
          {allowSchemasEditing && (
            <NavLink to={itemCreatePath || routePrefix + 'create'}>
              <CreateBtnSvg className="nsi-tree-add-btn" />
            </NavLink>
          )}
        </div>
      </div>
      <Tree {...tree} nodes={nodes as any} nodeContentRenderer={renderNode} handleSelect={handleSelect as any} />
    </HideableLeftMenu>
  )
}

const buildTree = (items: ITreeItem[], displayNesting: boolean) => {
  return items.map((it) => ({
    ...it,
    id: catalogTreeIdCodec.encodeCatalog(it),
    children: it.instances.map((i) => ({
      ...i,
      id: catalogTreeIdCodec.encodeInstance(i, it.id),
    })),
    displayNesting,
  })) as unknown as ITreeNode
}

export default CatalogTree
