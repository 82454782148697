import { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'

const PlusButton = (props: Props) => {
  return (
    <button type="button" className="nsi__plus-button" style={props.style} onClick={props.onClick}>
      <PlusIcon style={{ width: 24, height: 24 }} />
      <span style={{ marginLeft: '6px' }}>
        <FormattedMessage id={props.textId} />
        {props.textSuffix}
      </span>
    </button>
  )
}

interface Props {
  style?: CSSProperties
  onClick: () => void
  textId: string
  textSuffix?: string
}

export default PlusButton
