import classnames from 'classnames'
import { MouseEventHandler } from 'react'
import { highlightSearch } from '../../utils/search'
import css from './access.module.css'

const AccessUserRow = ({ user, onClick, isSelected, searchTerms, paddingLeft }: Props) => {
  const className = classnames(css.item, { [css.selectedItem]: isSelected })

  const onDragStart = (e) => {
    e.dataTransfer.setData('text/plain', 'users:' + user.id)
    e.dataTransfer.dropEffect = 'move'
  }

  return (
    <div
      className={className}
      style={{ paddingLeft: paddingLeft || 46 }}
      draggable={true}
      data-type="users"
      data-id={user.id}
      onClick={onClick}
      onDragStart={onDragStart}
    >
      {highlightSearch(user.name, searchTerms).result}
    </div>
  )
}

interface Props {
  user: { id: number; name: string }
  onClick: MouseEventHandler
  isSelected: boolean
  searchTerms?: string[]
  paddingLeft?: number
}

export default AccessUserRow
