import { Region } from 'au-nsi/equipment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import Form from '../../../shared/Forms/Form'
import FormButtons from '../../../shared/Forms/FormButtons'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextInput from '../../../shared/Inputs/TextInput'
import { selectAccessRights } from '../../App/app.selectors'
import HistoryButton from '../../System/ActionsJournal/HistoryButton'
import { addRegion, deleteRegion, setFormMode, updateRegion } from '../nsi.actions'
import { selectRegion } from '../nsi.selectors'

const RegionForm = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const mode = useAppSelector((state) => state.nsi.mode)
  const rights = useAppSelector(selectAccessRights)
  const region = useAppSelector(selectRegion)

  const allowEditing = rights['equipment:update']
  const allowDeleting = rights['equipment:delete']
  const showHistory = rights['journal:get_changelog']

  const newRegion: Region = { id: null, path: '', name: '', data_rate: 50, attributes: [], user_ordering_index: 0 }
  const [draft, setDraft] = useState<Region>(region || newRegion)

  useEffect(() => {
    if (region && mode === 'view') setDraft(region)
  }, [region, mode])

  useEffect(() => {
    if (mode === 'create') setDraft(newRegion)
  }, [mode])

  const handleEdit = () => dispatch(setFormMode('edit'))
  const handleCancel = () => dispatch(setFormMode('view'))
  const handleDelete = () => dispatch(setFormMode('delete'))

  const handleCreateSave = () => {
    const parent = region
    const path = parent ? parent.path : '1'
    dispatch(addRegion({ ...draft, path }))
  }

  const handleEditSave = () => dispatch(updateRegion(region.id, draft))

  const handleDeleteConfirm = () => dispatch(deleteRegion(region.id))

  const handleSubmit = () => {
    if (mode === 'create') handleCreateSave()
    else if (mode === 'edit') handleEditSave()
  }

  const handleChange = (value: any, key: string) => {
    setDraft((prev) => ({ ...prev, [key]: value }))
  }

  const editing = mode === 'create' || mode === 'edit'

  return (
    <div className="nsi-main__wrapper">
      <div className="nsi-main__container">
        <div className="nsi-main__header">
          <h2>{intl.formatMessage({ id: 'nsi.region' })}</h2>
          {showHistory && <HistoryButton resource="regions" resourceId={draft.id} />}
        </div>

        <Form editing={editing} onSubmit={handleSubmit} onCancel={handleCancel}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'nsi.region_name' })}>
              <TextInput
                name="name"
                value={draft.name}
                onChange={handleChange}
                required={true}
                disabled={!editing}
                disabledClassName="system__input-disabled"
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'nsi.region_id' })}>
              <div className="system__input-disabled">{draft.id}</div>
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'nsi.region_data_rate' })}>
              <NumberInput
                name="data_rate"
                value={draft.data_rate}
                onChange={handleChange}
                min={1e-6}
                fullWidth={true}
                disabled={!editing}
                disabledStyle="text"
              />
            </InputRow>
          </div>

          <FormButtons
            mode={mode}
            onCancel={handleCancel}
            onCreateSave={() => null}
            onDelete={handleDelete}
            onDeleteConfirm={handleDeleteConfirm}
            onEdit={handleEdit}
            onEditSave={() => null}
            allowEditing={allowEditing}
            allowDeleting={allowDeleting}
          />
        </Form>
      </div>
    </div>
  )
}

export default RegionForm
