import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITag } from 'au-nsi/shared'
import { orderedInsert } from 'redux/reducer.utils'

const slice = createSlice({
  name: 'app/tags',
  initialState: [] as ITag[],
  reducers: {
    setTags: (_, action: PayloadAction<ITag[]>) => {
      return action.payload
    },
    tagCreated: (state, action: PayloadAction<ITag>) => {
      orderedInsert(state, action.payload)
    },
    tagUpdated: (state, action: PayloadAction<ITag>) => {
      const i = state.findIndex((e) => e.id === action.payload.id)

      if (i !== -1) {
        const element = state[i]
        state.splice(i, 1)
        orderedInsert(state, { ...element, ...action.payload })
      }
    },
    tagDeleted: (state, action: PayloadAction<number>) => {
      return state.filter((e) => e.id !== action.payload)
    },
  },
})

const tagsReducer = slice.reducer
export default tagsReducer

export const tagsActions = slice.actions
