import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectEquipmentMap } from '../Nsi/nsi.selectors'
import { selectDenormalizedParametersMap } from '../Parameters/params.selectors'
import { formatValue } from '../Parameters/params.utils'
import { SignalEventRule } from './se.interfaces'

interface Props {
  formula: SignalEventRule['formula']
}

/**
 * Превью формулы для определения сигнальной ситуации (только отображение,
 * в отличие от компонента SeCondition, который для редактирования)
 */
const SeFormulaPreview = ({ formula }: Props) => {
  const intl = useIntl()

  const parameters = useSelector(selectDenormalizedParametersMap)
  const devicesMap = useSelector(selectEquipmentMap)

  const rows = formula.conditions.map((c, index) => {
    const operator = index === 0 ? 'if' : c.operator
    const parameter = parameters.get(c.condition.parameter)

    const value =
      c.condition.operator === 'in' || c.condition.operator === 'out'
        ? formatValue(c.condition.value[0], parameter) + ' — ' + formatValue(c.condition.value[1], parameter)
        : formatValue(c.condition.value as number, parameter)

    const devices = c.equipment.value.map((id) => {
      const device = devicesMap.get(id)
      return device ? device.name : id
    })

    return (
      <div key={index} className="sef__preview">
        <span className="sef__preview-item sef__preview-operator">
          {intl.formatMessage({ id: 'nsi.se.ops.logical.' + operator })}
        </span>
        <span className="sef__preview-item">{parameter ? parameter.name : c.condition.parameter}</span>
        <span className="sef__preview-item sef__preview-operator">
          {intl.formatMessage({ id: 'nsi.se.ops.' + c.condition.operator })}
        </span>
        <span className="sef__preview-item">{value}</span>
        <span className="sef__preview-item sef__preview-operator">
          {intl.formatMessage({ id: 'nsi.se.ops.' + c.equipment.operator })}:
        </span>
        <span className="sef__preview-item">{devices.join(', ')}</span>
      </div>
    )
  })

  return <div>{rows}</div>
}

export default SeFormulaPreview
