import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import { ReduxState } from '../../../redux/store.types'
import http, { handleHttpError } from '../../../utils/http'
import { selectAccessRights } from '../../App/app.selectors'
import DocumentsSelectModal from './components/DocumentsSelectModal'
import UploadFilesModal from './components/FileModals/UploadFilesModal'
import css from './docs.module.css'
import { actions } from './docs.reducers'
import { openFile } from './docs.utils'
import { IDocument } from 'au-nsi/documents'

/**
 * Поле для выбора списка документов
 */
const DocumentsInput = (props: Props) => {
  const dispatch = useDispatch()

  const documents = useSelector(selectDocumentsMap)
  const token = useSelector((state: ReduxState) => state.auth.accessToken)

  const rights = useSelector(selectAccessRights)
  const allowUpload = rights['documents:update']
  const allowSearch = rights['documents:get'] || allowUpload

  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState<FileList>()

  // загрузка документов из НСИ, которых нет локально (для отображения названий) и
  // удаление папок (если пользователь из библиотеки вместе с файлами выбрал еще и папки)
  useEffect(() => {
    const missing: string[] = []
    const folders: string[] = []

    for (const id of props.value) {
      if (!id) continue

      const doc = documents.get(id)
      if (!doc) missing.push(id)
      if (doc && doc.is_folder) folders.push(id)
    }

    if (missing.length > 0) {
      const url = '/nsi/v1/documents?' + missing.map((id) => `ids=` + id).join('&')

      http
        .get(url)
        .then((r) => dispatch(actions.addDocuments(r.data)))
        .catch(handleHttpError)
    }

    if (folders.length > 0) {
      const value = props.value.filter((id) => !folders.includes(id))
      props.onChange(value, props.name)
    }
  }, [props.value])

  const values = props.value.filter((id) => documents.has(id))

  // режим отображения
  if (props.disabled) {
    const renderRow = (id) => {
      const doc = documents.get(id)
      const name = doc.name + '.' + doc.extension

      return (
        <div key={id} className={css.inputItemDisabled} onClick={() => openFile(doc, token)}>
          {name}
        </div>
      )
    }

    return <div>{values.map(renderRow)}</div>
  }

  const handleSelect = (selection: string[]) => {
    const value = [...props.value]

    for (const id of selection) {
      if (!value.includes(id)) value.push(id)
    }

    props.onChange(value, props.name)
  }

  const handleRemove = (e) => {
    const id = e.currentTarget.dataset.id
    const value = props.value.filter((e) => e !== id)
    props.onChange(value, props.name)
  }

  // загрузка новых файлов в библиотеку напрямую через инпут
  const handleUploadStart = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.addEventListener('change', () => setFiles(input.files), { once: true })
    input.click()
  }

  const handleUploadFinish = (docs: IDocument[]) => {
    if (docs && docs.length) handleSelect(docs.map((d) => d.id))
    setFiles(null)
  }

  // режим редактирования
  const items = values.map((id) => {
    const doc = documents.get(id)
    const name = doc.name + '.' + doc.extension

    return (
      <div key={id} className={css.inputItem} style={values.length === 1 ? { maxWidth: 'calc(100% - 80px)' } : {}}>
        <span className={css.inputItemTitle} title={name}>
          {name}
        </span>
        <span className={css.inputRemoveIcon} data-id={id} onClick={handleRemove}>
          ×
        </span>
      </div>
    )
  })

  return (
    <div className={css.input}>
      {items}

      <div style={{ marginLeft: 'auto', height: 40, display: 'flex', alignItems: 'center' }}>
        {allowUpload && <UploadIcon width="16" height="16" className={css.inputIcon} onClick={handleUploadStart} />}
        {allowSearch && (
          <SearchIcon
            width="20"
            height="20"
            className={css.inputIcon}
            style={{ margin: '0 8px' }}
            onClick={() => setOpen(true)}
          />
        )}
      </div>

      {open && <DocumentsSelectModal onClose={() => setOpen(false)} onSelect={handleSelect} />}
      {files && files.length && (
        <UploadFilesModal
          files={files}
          onClose={handleUploadFinish}
          resource={props.resource}
          resource_item_id={props.resource_item_id}
        />
      )}
    </div>
  )
}

const selectDocumentsMap = createSelector(
  (state: ReduxState) => state.documents.items,
  (documents) => new Map(documents.map((d) => [d.id, d]))
)

interface Props {
  name: string
  value: string[]
  onChange: (value: string[], name: string) => void
  disabled?: boolean
  resource?: IDocument['resource']
  resource_item_id?: string
}

export default memo(DocumentsInput)
