import { Select } from '@alterouniversal/au-react-components'
import { UserRole } from 'au-nsi/user'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import usePasswordRules from '../../../hooks/usePasswordRules'
import { ReduxState } from '../../../redux/store.types'
import InputRow from '../../../shared/Inputs/InputRow'
import PasswordInput from '../../../shared/Inputs/PasswordInput'
import TextInput from '../../../shared/Inputs/TextInput'
import InstanceRow from '../../Catalogs/components/InstanceForm/InstanceRow'
import { User } from '../collection.interfaces'
import css from './users.module.css'

const UserForm = (props: Props) => {
  const intl = useIntl()
  const organizations = useSelector((state: ReduxState) => state.organizations.organizations)
  const catalogs = useSelector((state: ReduxState) => state.catalogs.catalogs)

  const { user, editing, allowChangingCredentials } = props
  const { validator, help } = usePasswordRules(editing && allowChangingCredentials)

  // данные об организации пользователя
  const orgs = useMemo(() => {
    const typeOptions = organizations.map((o) => ({ value: o.id, label: o.name }))
    const defaults = {
      options: [],
      typeOptions,
      selectedOption: null,
      selectedName: '',
      schema: [],
    }

    if (!user || !user.organization_type_id) return defaults

    const type = organizations.find((o) => o.id === user.organization_type_id)
    if (!type) return defaults

    const options = type.instances.map((e) => ({ label: e.name, value: e.id }))
    const selectedOption = options.find((o) => o.value === user.organization_id)
    const selectedName = selectedOption ? `${type.name}. ${selectedOption.label}` : type.name
    const schema = type.registration_schema

    return { options, typeOptions, selectedOption, selectedName, schema }
  }, [organizations, user?.organization_type_id, user?.organization_id])

  const role = props.roles.find((r) => r.id === user.role_id)

  const renderInput = (key: string) => {
    return <TextInput name={key} value={user[key] || ''} onChange={props.onChange} required={false} />
  }

  const renderRoleInput = () => {
    const options = props.roles.map((r) => ({ label: r.name, value: r.id }))
    return <Select name="role_id" options={options} value={user.role_id} onChange={props.onChange} />
  }

  const renderOrgTypeInput = () => {
    return (
      <Select
        name="organization_type_id"
        options={orgs.typeOptions}
        value={user.organization_type_id}
        onChange={props.onChange}
      />
    )
  }

  const renderOrgInput = () => {
    return (
      <Select name="organization_id" options={orgs.options} value={user.organization_id} onChange={props.onChange} />
    )
  }

  const handleDataChange = (rowId: string, value: any) => {
    const user_data = { ...user.user_data, [rowId]: value }
    props.onChange(user_data, 'user_data')
  }

  // дополнительные поля зависящие от организации введенные при регистрации
  const userData = orgs.schema.map((row, i) => {
    return (
      <InstanceRow
        key={i}
        value={user.user_data?.[row.id]}
        rowSchema={row}
        editing={editing}
        catalogs={catalogs}
        onChange={handleDataChange}
      />
    )
  })

  const passwordValid = !user.password || validator(user.password)
  const passwordStyle = !passwordValid ? { border: '1px solid var(--danger-80)' } : undefined

  return (
    <>
      <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.name' })}>
        {editing ? renderInput('name') : user.name}
      </InputRow>

      <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.role' })}>
        {editing ? renderRoleInput() : role && role.name}
      </InputRow>

      {editing && props.allowChangingOrgType && (
        <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.organization_type' })}>
          {renderOrgTypeInput()}
        </InputRow>
      )}

      {editing && user.organization_type_id && (
        <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.organization' })}>
          {renderOrgInput()}
        </InputRow>
      )}

      {!editing && user.organization_id && (
        <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.organization' })}>
          {orgs.selectedName}
        </InputRow>
      )}

      <InputRow tableMode={true} label="Email">
        {editing && allowChangingCredentials ? renderInput('email') : user.email && user.email}
      </InputRow>

      <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.username' })}>
        {editing && allowChangingCredentials ? renderInput('username') : user.username}
      </InputRow>

      {editing && allowChangingCredentials && (
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()} style={{ marginTop: 10 }}>
          <InputRow
            tableMode={true}
            help={<span className="text--prewrap">{help}</span>}
            label={intl.formatMessage({ id: 'nsi.user.password' })}
          >
            <PasswordInput
              name="password"
              style={passwordStyle}
              value={user.password || ''}
              onChange={props.onChange}
            />
          </InputRow>
          <InputRow tableMode={true} label={intl.formatMessage({ id: 'nsi.user.password_confirm' })}>
            <PasswordInput name="password_confirm" value={user.password_confirm || ''} onChange={props.onChange} />
          </InputRow>
        </form>
      )}

      <table className={css.userDataTable}>
        <tbody>{userData}</tbody>
      </table>
    </>
  )
}

interface Props {
  user: User
  roles: UserRole[]
  editing: boolean
  onChange: (value: any, key: string) => void
  allowChangingCredentials: boolean
  allowChangingOrgType: boolean
}

export default UserForm
