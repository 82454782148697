import React from 'react'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import styles from './image.module.css'
import UploadForm from './UploadForm'
import * as utils from './image.utils'
import { Image } from 'au-nsi/images'

/**
 * Поле для загрузки новых изображений: при клике откроется стандартое окно для выбора файлов,
 * либо можно сразу перетаскивать в эту область файлы из файлового менеджера.
 */
const UploadInput = () => {
  const [files, setFiles] = React.useState<Pick<Image, 'src' | 'name' | 'format'>[]>([])

  const inputRef = React.useRef<HTMLInputElement>()
  const areaRef = React.useRef<HTMLDivElement>()

  const loadFiles = (list: FileList) => {
    utils.loadFiles(list, (image) => setFiles((prev) => [...prev, image]))
  }

  const handleChange = (e) => loadFiles(e.target.files)

  const handleReset = () => {
    inputRef.current.value = null
    setFiles([])
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    areaRef.current.style.opacity = '1'
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    areaRef.current.style.opacity = null
  }

  const handleDrop = (e) => {
    handleDragLeave(e)
    loadFiles(e.dataTransfer.files)
  }

  const className = styles.tile + ' ' + styles.createButton

  return (
    <React.Fragment>
      <div
        ref={areaRef}
        className={className}
        onClick={() => inputRef.current.click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <UploadIcon style={{ width: 24, height: 24 }} />
        <input
          ref={inputRef}
          className="nsi-input"
          type="file"
          accept={utils.formats}
          onChange={handleChange}
          multiple={true}
        />
      </div>

      {files.length > 0 && <UploadForm images={files} onClose={handleReset} />}
    </React.Fragment>
  )
}

export default React.memo(UploadInput)
