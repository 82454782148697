import { createStore, get, set, clear } from 'idb-keyval'

const store = process.env.NODE_ENV !== 'test' ? createStore('au', 'cache') : undefined

type Key = string | number

const onError = (err) => {
  console.error(err)
  return undefined
}

const idb = {
  get<T = any>(key: Key) {
    return get(key, store).catch(onError) as Promise<T>
  },

  set(key: Key, value: any) {
    return set(key, value, store).catch(console.error)
  },

  clear() {
    return clear(store)
  },
}

export default idb
