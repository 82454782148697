import React, { CSSProperties } from 'react'
import HelpTooltip from '../HelpTooltip/HelpTooltip'

const InputRow = (props: Props) => {
  const rowClass = props.tableMode ? 'nsi-cn__form-row' : undefined
  const labelClass = props.tableMode ? 'nsi-cn__form-label' : 'system__label'
  let inputClass = props.tableMode ? 'nsi-cn__form-input' : 'system__input-wrapper'

  if (props.compact) inputClass += ' is-compact'

  return (
    <div className={rowClass} style={props.style}>
      <div className={labelClass}>
        <span style={{ marginRight: 6 }}>{props.label}</span>
        {props.help && <HelpTooltip position="top" children={props.help} size={14} />}
      </div>
      <div className={inputClass}>{props.children}</div>
    </div>
  )
}

interface Props {
  label: string
  help?: React.ReactNode
  children: React.ReactNode
  compact?: boolean
  style?: CSSProperties
  /**
   * По умолчанию label располагается сверху над полем.
   * tableMode нужен для отображения label слева от поля, как в таблице (например на странице пользователей)
   */
  tableMode?: boolean
}

export default InputRow
