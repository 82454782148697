import { IPhasePortraitSettings } from 'au-nsi/dashboards'
import React from 'react'
import { Boundaries, Margins } from '../../../shared/interfaces'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { formatValue } from '../../Parameters/params.utils'

/**
 * Сетка и подписи к осям для фазового портрета
 */
const PortraitGrid = (props: Props) => {
  const { boundaries, margins, settings } = props
  const width = boundaries.width - margins.left - margins.right
  const height = boundaries.height - margins.top - margins.bottom

  const xCoords = calcGridLines(settings.x_min, settings.x_max, Math.round(width / 80))
  const yCoords = calcGridLines(settings.y_min, settings.y_max, Math.round(height / 80))

  // линии сетки и отдельно линии проходящие через ноль, т.к. нулевые оси должны рисоваться поверх
  // всех остальных линий
  const lines = []
  const zeros = []

  // линии и подписи оси X
  for (let i = 0; i < xCoords.length; i++) {
    const value = xCoords[i]
    const left = centerLine((width * (value - settings.x_min)) / (settings.x_max - settings.x_min))
    const className = value === 0 ? 'line zero' : 'line'
    const group = value === 0 ? zeros : lines

    group.push(
      <g key={'x:' + i}>
        <line className={className} x1={left} x2={left} y1={0} y2={height} />
        <text x={left} y={height + 18} textAnchor="middle">
          {formatValue(value, props.xParameter, false)}
        </text>
      </g>
    )
  }

  // линии и подписи оси Y
  for (let i = 0; i < yCoords.length; i++) {
    const value = yCoords[i]
    const top = centerLine((height * (settings.y_max - value)) / (settings.y_max - settings.y_min))
    const className = value === 0 ? 'line zero' : 'line'
    const group = value === 0 ? zeros : lines

    group.push(
      <g key={'y:' + i}>
        <line key={i} className={className} style={{ top, width }} x1={0} x2={width} y1={top} y2={top} />
        <text x={width + 6} y={top + 5} textAnchor="start">
          {formatValue(value, props.yParameter, false)}
        </text>
      </g>
    )
  }

  const style = { top: 0, left: 0, width: boundaries.width, height: boundaries.height }

  return (
    <svg className="phase-portrait__grid" style={style} viewBox={`0 0 ${style.width} ${style.height}`}>
      <g transform={`translate(${margins.left} ${margins.top})`}>
        {lines}
        {zeros}
      </g>

      <text className="axis-sub" x={margins.left + width / 2} y={margins.top - 6} textAnchor="middle">
        {generateSub(props.xParameter)}
      </text>
      <text
        className="axis-sub"
        x={0}
        y={0}
        textAnchor="middle"
        transform={`rotate(270) translate(-${margins.top + height / 2} 16)`}
      >
        {generateSub(props.yParameter)}
      </text>
    </svg>
  )
}

// для четкого отображения линий шириной в 1px в svg они должны быть сдвинуты на пол пикселя
const centerLine = (px: number) => {
  return px < 1 ? 0.5 : Math.ceil(px) - 0.5
}

/**
 * Рассчет значений для линий сетки
 * @param min нижняя граница
 * @param max верхняя граница
 * @param count максимально допустимое количество линий сетки
 */
const calcGridLines = (min: number, max: number, count: number) => {
  const lines = []

  const includeZero = max > 0 && min < 0 // попадает ли ноль в границы графика
  const span = max - min

  const power = Math.ceil(Math.log10(max - min))
  const magnitude = Math.pow(10, power - 2)

  let n = count
  let step = span

  // подбираем количество линий таким образом чтобы они шли через ровные промежутки
  for (n = count; n > 0; n -= 1) {
    step = span / n

    let isFound = Number.isInteger(step / magnitude)
    if (includeZero) isFound = isFound && Number.isInteger(max / step)

    if (isFound) break
  }

  for (let i = 0; i <= n; i++) {
    lines.push(min + i * step)
  }

  return lines
}

// сгенерировать подпись к оси - название параметра и единицы измерения
const generateSub = (parameter: ParameterDn) => {
  if (!parameter) return ''

  const unit = parameter.display_unit?.symbol || ''
  const prefix = parameter.display_prefix?.symbol || ''

  return unit ? parameter.name + ', ' + prefix + unit : parameter.name
}

interface Props {
  boundaries: Boundaries
  margins: Margins
  settings: IPhasePortraitSettings
  xParameter: ParameterDn
  yParameter: ParameterDn
}

export default React.memo(PortraitGrid)
