import { CSSProperties } from 'react'

const RemoveButton = (props: Props) => (
  <button
    type="button"
    onClick={props.onRemove}
    data-id={props.id}
    className={`nsi-icon__remove ${props.className || ''}`}
    style={props.style}
  >
    &times;
  </button>
)

interface Props {
  onRemove: (e: any) => void
  id?: string | number
  style?: CSSProperties
  className?: string
}

export default RemoveButton
