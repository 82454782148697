import { ReactComponent as MapIcon } from 'icons/map.svg'
import { useIntl } from 'react-intl'
import styles from '../../maptopology.module.css'
import { useEffect, useState } from 'react'
import TextInput from '../../../../shared/Inputs/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { updateEquipmentAddress } from '../../../Nsi/nsi.actions'
import { selectAccessRights } from '../../../App/app.selectors'
import FormButtons from '../../../../shared/Forms/FormButtons'
import DeviceAccessButtons from '../DeviceAccessButtons'
import TextareaInput from '../../../../shared/Inputs/TextareaInput'
import KeyValueTable from '../../../../shared/Forms/KeyValueTable/KeyValueTable'
import AEConnectControllerId from './AE_ConnectControllerId/AEConnectControllerId'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import { prepareEquipmentUpdate } from '../../../Nsi/nsi.utils'
import { aeValidateEntrance } from '../../../Nsi/EquipmentForm/AEEntranceInput'
import GroupSelect from '../../Groups/components/GroupSelect'
import { isAddressValid } from '../../mapTopology.utils'
import useGroup from '../../Groups/hooks/useGroup'
import GroupsLoader from '../../Groups/components/GroupsLoader'
import confirmService from '../../../../shared/Modal/confirm.service'
import PlaceSelectModal from '../../../../shared/Inputs/PlacePicker/PlaceSelectModal'
import useDeviceWidth from '../../../../hooks/useDeviceWidth'
import { Equipment, LP001Equipment } from 'au-nsi/equipment'
import GoogleMap from '../../../../shared/GoogleMap'
import { showError } from '../../../../utils/notifications'

/**
  Компонент главного экрана в карточке устройтва на карте устройств.

  Умеет показывать и редактировать местоположение устройства и его короткое имя.
  Для протокола LPOO1 также показывает ID контроллера с возможностью редактирования.
*/
interface IProps<T extends Equipment> {
  device: T
}

const DeviceMainContent = <T extends Equipment>({ device }: IProps<T>) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { isMobile } = useDeviceWidth()

  const translations = useSelector((state: ReduxState) => state.language.translations)
  const allowEditing = useSelector(selectAccessRights)['equipment:update']
  const user = useSelector((state: ReduxState) => state.user)
  const { wait, loading } = useHttpLoader()

  const [editing, setEditing] = useState(false)
  const [draftDevice, setDraftDevice] = useState<T>(device)
  const [placeModalOpen, setPlaceModalOpen] = useState(false)
  const group = useGroup((draftDevice as LP001Equipment)?.attributes?.group_id)

  // если пользователю лично выдан доступ к устройству, то он может отказаться от него
  // и отключить устройство от своего личного кабинета
  const canDetach = device.access?.restricted && device.access.users.includes(user.id)

  useEffect(() => {
    if (!editing) setDraftDevice(device)
  }, [editing, device])

  const handleSave = async () => {
    const updates = {
      address: draftDevice.address,
      shortname: draftDevice.shortname,
      notes: draftDevice.notes,
      attributes: draftDevice.attributes,
    } as Partial<Equipment>
    prepareEquipmentUpdate(updates, device)
    if (Object.keys(updates).length === 0) return showError('errors.no_changes')

    if ('attributes' in updates && 'entrance' in updates.attributes) {
      if (!aeValidateEntrance(updates.attributes.entrance)) {
        return showError('common.validation_error')
      }
    }

    if (updates.address && device.protocol === 'LP001' && group) {
      const title = 'map.ae.groups.change_address_confirm'
      const options = [{ title: 'common.yes', value: 'yes' }]
      const resp = await confirmService.requestConfirmation(title, options)
      if (resp !== 'yes') return
    }

    const updater = dispatch(updateEquipmentAddress(device.id, updates))
    wait(updater as any, () => setEditing(false))
  }

  const renderForm = () => {
    const pairs = []

    pairs.push([
      'nsi.shortname',
      <TextInput
        value={draftDevice.shortname}
        name={''}
        onChange={(v) => setDraftDevice({ ...device, shortname: v })}
        disabled={!editing}
      />,
    ])

    // Для устройств лифтового протокола необходимо выводить также номер подъезда, id контроллера и группу
    if (draftDevice.protocol === 'LP001') {
      if (!editing) {
        pairs.push([
          translations['equipment.configuration.controller_id'],
          draftDevice.configuration.controller_id || '-',
        ])
      }

      const attributes = draftDevice.attributes ?? {}
      const entrance = draftDevice.attributes?.entrance
      pairs.push([
        intl.formatMessage({ id: 'nsi.lp001.entrance' }),
        <>
          {!editing && <div>{entrance ?? '-'}</div>}
          {editing && (
            <NumberInput
              required={false}
              fullWidth={true}
              name={''}
              onChange={(val) => setDraftDevice({ ...draftDevice, attributes: { ...attributes, entrance: val } })}
              value={entrance}
            />
          )}
        </>,
      ])

      if (isAddressValid(draftDevice.address)) {
        const groupId = group?.id
        pairs.push([
          intl.formatMessage({ id: 'map.ae.group' }),
          <GroupsLoader>
            {!editing && (group?.name || '-')}
            {editing && (
              <GroupSelect
                groupId={groupId}
                name={''}
                onChange={(group_id) =>
                  setDraftDevice({ ...draftDevice, attributes: { ...draftDevice.attributes, group_id } })
                }
                address={draftDevice.address}
              />
            )}
          </GroupsLoader>,
        ])
      }
    }

    pairs.push([
      'nsi.device.notes',
      <>
        {!editing && <div className={styles.textarea}>{draftDevice.notes || '-'}</div>}
        {editing && (
          <TextareaInput
            useWrapper={false}
            value={draftDevice.notes}
            onChange={(notes) => setDraftDevice({ ...draftDevice, notes })}
            style={{ width: '100%', margin: 0 }}
          />
        )}
      </>,
    ])

    return <KeyValueTable pairs={pairs} keyRowPercentWidth={isMobile ? 60 : 40} style={{ marginBottom: '1em' }} />
  }

  const renderMap = () => {
    if (editing) {
      return (
        <div>
          <span style={{ display: 'inline-flex', gap: '1em', alignItems: 'center' }}>
            {draftDevice.address.name}
            <MapIcon style={{ height: '15px', cursor: 'pointer' }} onClick={() => setPlaceModalOpen(true)} />
          </span>
          {placeModalOpen && (
            <PlaceSelectModal
              place={draftDevice.address}
              onChange={(address) => setDraftDevice({ ...draftDevice, address })}
              onClose={() => setPlaceModalOpen(false)}
            />
          )}
        </div>
      )
    }

    return (
      <GoogleMap
        place={draftDevice.address}
        onChange={() => {}}
        width={'100%'}
        height={'35vh'}
        config={{ zoom: 17 }}
        rememberPlace={false}
        displayGeoControls={false}
      />
    )
  }

  return (
    <>
      {renderForm()}
      <div className={styles.deviceScreenAddressBlock}>
        <h2>{intl.formatMessage({ id: 'nsi.address' })}</h2>
        {!editing && <span>{draftDevice.address.name}</span>}
        {renderMap()}
      </div>
      <FormButtons
        mode={editing ? 'edit' : 'view'}
        onEdit={() => setEditing(true)}
        onEditSave={handleSave}
        onCancel={() => setEditing(false)}
        allowEditing={allowEditing}
        allowDeleting={false}
        isLoading={loading}
        style={{ gap: '12px', flexWrap: 'wrap' }}
      >
        {device.protocol === 'LP001' && (
          <AEConnectControllerId controllerId={device.configuration.controller_id} deviceId={device.id} />
        )}
        {canDetach && <DeviceAccessButtons user_id={user.id} device={device} />}
      </FormButtons>
    </>
  )
}

export default DeviceMainContent
