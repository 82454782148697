import { IGanttSettings } from 'au-nsi/dashboards'
import React from 'react'
import GanttService from '../../services/gantt/gantt.service'
import { Boundaries } from '../../shared/interfaces'
import memoize from '../../utils/memoize'
import GanttBlock from './components/GanttBlock'
import GanttBody from './components/GanttBody'
import GanttChart from './components/GanttChart'
import GanttContainer from './components/GanttContainer'
import GanttHeader from './components/GanttHeader'
import GanttRow from './components/GanttRow'
import GanttTimeline from './components/GanttTimeline'
import GanttTitle from './components/GanttTitle'
import GanttContext, { IGanttContext } from './gantt.context'
import './gantt.styles.css'

/**
 * Родительский контейнер для всех компонентов диаграммы Ганта. Создает контекст с данными
 * необходимыми для работы дочерних элементов.
 */
class Gantt extends React.Component<Props, State> {
  static Block = GanttBlock
  static Body = GanttBody
  static Chart = GanttChart
  static Header = GanttHeader
  static Row = GanttRow
  static Timeline = GanttTimeline
  static Title = GanttTitle

  state: State = {
    bodyRect: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      height: 0,
      width: 0,
    },
  }

  componentDidMount() {
    const { settings, service } = this.props

    if (settings != null) {
      service.setSettings(settings)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { settings, service } = this.props

    if (prevProps.settings !== settings && settings) {
      service.setSettings(settings)
    }
  }

  private setBodyRect = (bodyRect: Boundaries) => {
    this.setState({ bodyRect })
  }

  // общий контекст для всех элементов данного экземпляра диаграммы
  private makeContext = memoize(
    (bodyRect: Boundaries, settings: IGanttSettings, service: GanttService): IGanttContext => {
      return { bodyRect, service, settings, setBodyRect: this.setBodyRect }
    }
  )

  render() {
    const context = this.makeContext(this.state.bodyRect, this.props.settings, this.props.service)

    // если диаграмма используется отдельно от конструктора, то ей необходимо выставить другой css класс
    // для немного другого визуального представления и обернуть в контейнер, который будет обрабатывать
    // клик правой мыши и вызывать меню отличающееся от меню конструктора
    const content =
      'embedded' in this.props ? (
        <GanttContainer id={this.props.id} page={this.props.page}>
          {this.props.children}
        </GanttContainer>
      ) : (
        <div className="gantt__component">{this.props.children}</div>
      )

    return <GanttContext.Provider value={context}>{content}</GanttContext.Provider>
  }
}

interface State {
  bodyRect: Boundaries
}

// пропсы для диаграммы используемой как виджет конструктора графиков
interface DashboardProps {
  service: GanttService
  settings: IGanttSettings
  children: React.ReactNode
}

// пропсы для диаграммы используемой отдельно от конструктора (формы PQ, LFO и т.д.)
interface EmbeddedProps {
  embedded: true
  id: number
  page: string
  service: GanttService
  settings: IGanttSettings
  children: React.ReactNode
}

type Props = DashboardProps | EmbeddedProps

export default Gantt
