import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGSettings } from 'au-nsi/dashboards'
import React from 'react'
import PlusButton from '../../../shared/Forms/PlusButton'
import SettingsCommandsRow from './SettingsCommandsRow'
import css from './svg.module.css'

/**
 * Таблица с настройками команд отправляемых по нажатию на элементы SVG
 */
const SettingsCommands = (props: Props) => {
  const handleRemove = React.useCallback((index: number) => props.onRemove('commands', index), [props.onRemove])

  const handleChange = React.useCallback(
    (key: string, value: any, index: number) => props.onChange('commands', index, key, value),
    [props.onChange]
  )

  const rows = (props.settings.commands || []).map((row, index) => {
    return (
      <SettingsCommandsRow
        key={index}
        index={index}
        row={row}
        nodeOptions={props.nodeOptions}
        onChange={handleChange}
        onNodeHover={props.onHover}
        onRemove={handleRemove}
      />
    )
  })

  return (
    <div className={css.settingsTableWrapper}>
      {rows}
      <PlusButton textId="common.add" onClick={() => props.onAdd('commands')} style={{ marginLeft: 0 }} />
    </div>
  )
}

interface Props {
  settings: ISVGSettings
  nodeOptions: SelectOption[]
  onAdd: (key: string) => void
  onRemove: (key: string, index: number) => void
  onChange: (key: string, index: number, prop: string, value: any) => void
  onHover: (node: string) => void
}

export default SettingsCommands
