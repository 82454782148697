import { VerificatonOption } from 'au-nsi/settings'

const INF = Math.pow(2, 32)
const eps = Math.pow(10, -5)

const optionToInterval = (option: VerificatonOption): VerificatonOption => {
  if (typeof option[0] === 'number') return option

  // Переводим структуру вида ['>=', 2] в числовой интервал. Т.е. ['>=', 2] -> [2, INF]
  const sign = option[0]
  const val = option[1] as number
  switch (sign) {
    case '>=':
      return [val, INF]
    case '>':
      return [val + eps, INF]
    case '<=':
      return [-INF, val]
    case '<':
      return [-INF, val - eps]
    case '=':
      return [val, val]
  }
}

export const validateOptions = (options: VerificatonOption[]) => {
  // Сортируем интервальные значения
  const intervals = []
    .concat(
      ...options.map((o, index) =>
        optionToInterval(o).map((op) => {
          return { value: op, index }
        })
      )
    )
    .sort((a, b) => a.value - b.value)

  // Бежим по интервальным значениям. Если в отсортированном порядке индексы одной из последовательных двоек не равны,
  // значит интервалы пересекаются. В этом случае возвращаем пересечение
  for (let i = 0; i < intervals.length; i += 2) {
    if (intervals[i].index !== intervals[i + 1].index) {
      return {
        i: Math.min(intervals[i].index + 1, intervals[i + 1].index + 1),
        j: Math.max(intervals[i].index + 1, intervals[i + 1].index + 1),
      }
    }
  }

  // Пересечений нет
  return false
}
