import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import ModerationRequestsFilters from './ModerationRequests/ModerationRequestsFilters'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import moderationActions from './moderation.actions'
import RejectModal from './ModerationRequests/RejectModal'
import ModerationRequestsList from './ModerationRequests/ModerationRequestsList'
import { useEffect } from 'react'
import { wsEvents } from '../../../services/ws/ws.events'
import { ModerationState } from './moderation.reducer'
import { useAppSelector } from '../../../redux/store'
import styles from '../user.module.css'
import useHttpLoader from '../../../hooks/useHttpLoader'
import usePersistenceScrollPosition from '../../../hooks/usePersistenceScrollPosition'

/**
 Компонент страницы "Заявки" в личном кабинете пользователя.

 Подгружает заявки по мере скрола, складывает их в редакс. Стейт чистится после ухода с личного кабинета (UserPage.tsx)
*/
const ModerationPage = () => {
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()
  usePersistenceScrollPosition('.nsi-main__wrapper', 'moderation:list')
  const filters = useAppSelector((state) => state.moderation_requests.filters)

  const state = useSelector((state: ReduxState) => state.moderation_requests)
  const allLoaded = state.loaded && state.nextPage == null

  // В случае обрыва ws соединения, после его восстановления, стоит загрузить заявки, которые, возможно, были созданы
  useEffect(() => {
    return wsEvents.subscribe(({ resource, payload }) => {
      if (resource === 'ws-connection' && payload === 'reconnected') dispatch(moderationActions.loadLast())
    })
  }, [])

  const handleFiltersChange = (updated: ModerationState['filters']) => {
    wait(dispatch(moderationActions.loadRequests({ filters: updated, cleanBeforePush: true })) as any)
  }

  const loadMore = () => dispatch(moderationActions.loadRequests())

  return (
    <div className="nsi-main__container" style={{ maxWidth: '100%' }}>
      <div className={styles.requestListPage}>
        <RejectModal />
        <InfiniteScroll isMore={!allLoaded} loadMore={loadMore} parentSelector={'.nsi-main__wrapper'}>
          <ModerationRequestsList requests={state.requests} allLoaded={allLoaded} />
        </InfiniteScroll>
        <ModerationRequestsFilters filters={filters} onChange={handleFiltersChange} loading={loading} />
      </div>
    </div>
  )
}

export default ModerationPage
