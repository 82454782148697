import React from 'react'
import { useSelector } from 'react-redux'
import ChartsList from '../../shared/LineCharts/ChartsList'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import { Equipment } from 'au-nsi/equipment'
import { selectParametersNames } from '../Parameters/params.selectors'

const DigitalsCharts = (props: Props) => {
  const parameters = useSelector(selectParametersNames)

  const digitals = React.useMemo(() => {
    const device = props.device
    if (device.protocol !== 'C.37.118') return []

    const deviceParameters = device.protocol_configuration.digitals || []
    const result = []

    for (const digital of deviceParameters) {
      const systemParameter = device.parameters_mapping.find((m) => m.name === digital.name)

      if (systemParameter) {
        result.push(systemParameter.parameter_id)
      }
    }

    return result
  }, [props.device])

  const charts = React.useMemo(() => {
    return digitals.map((digital, i) => {
      const title = parameters.get(digital) || digital
      const lines = [{ device_id: props.device.id, parameter_id: digital, color: props.device.color }]

      return { id: RESERVED_CHART_IDS.DIGITALS + i, settings: { title, lines, minY: -0.5, maxY: 1.5 } }
    })
  }, [digitals])

  return (
    <div style={{ width: '50%' }}>
      <ChartsList showAsGrid={false} charts={charts} />
    </div>
  )
}

interface Props {
  device: Equipment
}

export default DigitalsCharts
