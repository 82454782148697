import memoize from '../../utils/memoize'
import { isJsonEqual } from '../../utils/misc'
import { C37Equipment, Equipment, Region } from 'au-nsi/equipment'

/**
 * Генерация случайного цвета в формате #RRGGBB
 */
export const randomColor = () => {
  const r = randomHex()
  const g = randomHex()
  const b = randomHex()
  return '#' + r + g + b
}

const randomHex = () => {
  const hex = Math.round(255 * Math.random()).toString(16)
  return normalize(hex).toUpperCase()
}

const normalize = (d: string) => {
  return d.length === 1 ? '0' + d : d
}

/**
 * Получить список названий параметров которые принимает ресивер
 */
export const getConfigurationParameters = memoize((device: C37Equipment) => {
  const result: string[] = []

  const phasors = device.protocol_configuration.phasors || []
  const analogs = device.protocol_configuration.analogs || []
  const digitals = device.protocol_configuration.digitals || []

  for (const phasor of phasors) {
    result.push(phasor.angleName, phasor.magnitudeName)
  }

  for (const analog of analogs) {
    result.push(analog.name)
  }

  for (const digital of digitals) {
    result.push(digital.name)
  }

  return result
})

/**
 * Отсортировать устройства и регионы в порядке заданным пользователем (user_ordering_index)
 */
export const orderTopology = (equipment: Equipment[], regions: Region[]) => {
  const parents = new Map(regions.map((r) => [r.id, r.path.split('.').map((id) => +id)]))
  const regionsOrder = new Map(regions.map((r) => [r.id, r.user_ordering_index]))

  const orderRegions = (r1: number, r2: number) => {
    const parents1 = parents.get(r1) || [r1]
    const parents2 = parents.get(r2) || [r2]

    // находим индекс ближайшего общего родителя для обоих регионов
    let i = 0

    while (i < parents1.length && i < parents2.length && parents1[i] === parents2[i]) {
      i += 1
    }

    const p1 = parents1[i]
    const p2 = parents2[i]

    return regionsOrder.get(p1) - regionsOrder.get(p2)
  }

  regions.sort((r1, r2) => orderRegions(r1.id, r2.id))

  equipment.sort((e1, e2) => {
    const order = orderRegions(e1.region_id, e2.region_id)
    if (order) return order

    return e1.user_ordering_index - e2.user_ordering_index
  })
}

export const generateUniqueName = (suggestedName: string, suggestedShortname: string, equipment: Equipment[]) => {
  const usedNames = new Set<string>()
  const usedShortnames = new Set<string>()

  for (const { name, shortname } of equipment) {
    if (name.startsWith(suggestedName)) usedNames.add(name)
    if (shortname.startsWith(suggestedShortname)) usedShortnames.add(shortname)
  }

  let index = 0

  while (true) {
    index += 1
    const name = suggestedName + '-' + index
    const shortname = suggestedShortname + index

    if (!usedNames.has(name) && !usedShortnames.has(shortname)) {
      return { name, shortname }
    }
  }
}

// является ли устройство дата ресивером
export const isDataReceiver = (device: Equipment) => {
  return (
    (device.type === 'receiver' && device.path === '') || device.type === 'aggregator' || device.protocol === 'LP001'
  )
}

// Подготовка объекта оборудования к отправке. Удаляет одинаковые с оригиналом поля
export const prepareEquipmentUpdate = (updated: Partial<Equipment>, source: Equipment) => {
  for (const key in updated) if (isJsonEqual(updated[key], source[key])) delete updated[key]
}

export const modbusTypeOptions = [
  { value: 'bool', label: 'bool' },
  { value: 'byte', label: 'byte' },
  { value: 'sbyte', label: 'sbyte' },
  { value: 'short', label: 'short' },
  { value: 'ushort', label: 'ushort' },
  { value: 'int', label: 'int' },
  { value: 'uint', label: 'uint' },
  { value: 'float', label: 'float' },
  { value: 'long', label: 'long' },
  { value: 'ulong', label: 'ulong' },
  { value: 'double', label: 'double' },
]

export const modbusTableOptions = [
  { value: 0, label: '(0) Output Coils' },
  { value: 1, label: '(1) Discrete Input' },
  { value: 3, label: '(3) Input Registers' },
  { value: 4, label: '(4) Output Registers' },
]
