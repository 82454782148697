interface Points {
  ts: number[]
  values: number[]
}

/**
 * Нахождение минимумов и максимумов в массиве данных за O(N).
 * Включено устранение ложных экстремумов.
 * Например, ложным минимумом считается точка которая ближе к предыдущим максимумам
 * чем к текущему минимуму.
 */
export const findExtremums = (ts: number[], arr: number[]): { mins: Points; maxs: Points } => {
  if (ts.length < 3) {
    return { mins: { ts: [], values: [] }, maxs: { ts: [], values: [] } }
  }

  const mins: Points = { ts: [ts[0]], values: [arr[0]] }
  const maxs: Points = { ts: [ts[0]], values: [arr[0]] }

  let isMinimum = false
  let isMaximum = false

  let current = 0
  let prev = 0
  let next = 0
  let prevMin = 0
  let prevMax = 0
  let shouldReplace = false
  let delta = 0
  let delta1 = 0
  let delta2 = 0

  for (let i = 1; i < arr.length - 1; i++) {
    current = arr[i]
    prev = arr[i - 1]
    next = arr[i + 1]

    isMinimum = current < prev && current < next
    isMaximum = current > prev && current > next

    if (isMinimum) {
      shouldReplace = false
      const len = mins.ts.length

      if (len > 2) {
        delta1 = prevMin - current
        delta2 = prevMin - mins.values[len - 2]
        delta = prevMax - prevMin
        shouldReplace = delta < delta1 && delta < delta2
      }

      if (shouldReplace) {
        mins.values[len - 1] = arr[i]
        mins.ts[len - 1] = ts[i]
      } else {
        mins.values.push(arr[i])
        mins.ts.push(ts[i])
      }

      prevMin = current
    }

    if (isMaximum) {
      shouldReplace = false
      const len = maxs.ts.length

      if (len > 2) {
        delta1 = current - prevMax
        delta2 = maxs.values[len - 2] - prevMax
        delta = prevMax - prevMin
        shouldReplace = delta < delta1 && delta < delta2
      }

      if (shouldReplace) {
        maxs.values[len - 1] = arr[i]
        maxs.ts[len - 1] = ts[i]
      } else {
        maxs.values.push(arr[i])
        maxs.ts.push(ts[i])
      }

      prevMax = current
    }
  }

  const lastValue = arr[arr.length - 1]
  const lastTs = ts[ts.length - 1]
  mins.values.push(lastValue)
  maxs.values.push(lastValue)
  mins.ts.push(lastTs)
  maxs.ts.push(lastTs)

  return { mins, maxs }
}

// Отрисовка контура по минимумам и максимумам и заливка области между ними
export const drawExtremums = (ctx: CanvasRenderingContext2D, mins: Points, maxs: Points, xScale, yScale) => {
  if (maxs.ts.length === 0) return

  ctx.beginPath()
  ctx.moveTo(xScale(maxs.ts[0]), yScale(maxs.values[0]))

  for (let i = 1; i < maxs.ts.length; i++) {
    ctx.lineTo(xScale(maxs.ts[i]), yScale(maxs.values[i]))
  }

  for (let i = mins.ts.length - 1; i >= 0; i--) {
    ctx.lineTo(xScale(mins.ts[i]), yScale(mins.values[i]))
  }

  ctx.closePath()
  ctx.fill()
  ctx.stroke()
}
