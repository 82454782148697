import PlusButton from '../../../shared/Forms/PlusButton'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import TextInput from '../../../shared/Inputs/TextInput'

interface Tag {
  tag: string
}

interface Props {
  tags: Tag[]
  editing: boolean
  onChange: (tags: Tag[], key: 'tags') => void
}

const OPCDATags = ({ tags, editing, onChange }: Props) => {
  const handleCreate = () => {
    onChange([...tags, { tag: '' }], 'tags')
  }

  const handleChange = (tag: string, key: string) => {
    const index = +key
    const updates = tags.map((v, i) => (i === index ? { ...v, tag } : v))
    onChange(updates, 'tags')
  }

  const handleRemove = (e) => {
    const index = +e.target.dataset.id
    const updates = tags.filter((_, i) => i !== index)
    onChange(updates, 'tags')
  }

  const rows = tags.map(({ tag }, i) => {
    return (
      <div key={i} style={{ display: 'flex', alignItems: 'center', margin: '1px 0' }}>
        <TextInput name={i + ''} value={tag} onChange={handleChange} disabled={!editing} />
        {editing && <RemoveButton id={i} onRemove={handleRemove} style={{ fontSize: 20, marginLeft: 5 }} />}
      </div>
    )
  })

  const styles = { lineHeight: '30px', '--input-height': '30px' }

  return (
    <div style={styles}>
      {rows}
      {editing && <PlusButton textId="common.add" onClick={handleCreate} style={{ margin: 0 }} />}
    </div>
  )
}

export default OPCDATags
