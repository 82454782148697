import { useMemo } from 'react'
import styles from '../../user.module.css'
import InputRow from '../../../../shared/Inputs/InputRow'
import { useIntl } from 'react-intl'
import Toggle from '../../../../shared/Inputs/Toggle'
import { moderatelyResources } from '../moderation.utils'
import { ModerationState } from '../moderation.reducer'
import TagsInput from '../../../../shared/Inputs/TagsInput/TagsInput'
import produce from 'immer'
import useDebounceState from '../../../../hooks/useDebounceState'
import Loader from '../../../../shared/Utils/Loader'
import { IModerationRequest } from 'au-nsi/moderation'
import { SelectOption } from '@alterouniversal/au-react-components'

interface IProps {
  filters: ModerationState['filters']
  onChange: (controls: ModerationState['filters']) => void
  loading: boolean
}

const ModerationRequestsFilters = ({ filters, onChange, loading }: IProps) => {
  const intl = useIntl()
  const [currentFilters, setCurrentFilters] = useDebounceState({ onChange, initialValue: filters })

  const locModeratelyResources: SelectOption[] = useMemo(
    () => moderatelyResources.map((r) => ({ ...r, label: intl.formatMessage({ id: r.label }) })),
    [intl.locale]
  )

  const handleToggleActive = (showOnlyActive: boolean) => setCurrentFilters({ ...currentFilters, showOnlyActive })

  const handleToggle = (toggled: IModerationRequest['resource']) => {
    setCurrentFilters({ ...currentFilters, resources: toggle(currentFilters.resources, toggled) })
  }

  return (
    <div className={styles.requestsControls}>
      <InputRow label={intl.formatMessage({ id: 'user.requests.only_active' })}>
        <div className={styles.requestsControlsToggleContainer}>
          <Toggle
            checked={currentFilters.showOnlyActive}
            name={'onlyActive'}
            onChange={handleToggleActive}
            style={{ marginLeft: 0 }}
          />
          {loading && <Loader />}
        </div>
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'user.request.resource' })}>
        <TagsInput tags={locModeratelyResources} selectedTags={currentFilters.resources} onToggle={handleToggle} />
      </InputRow>
    </div>
  )
}

const toggle = <T extends unknown>(set: Set<T>, value: T) => {
  return produce(set, (draft: Set<T>) => {
    if (draft.has(value)) draft.delete(value)
    else draft.add(value)
  })
}

export default ModerationRequestsFilters
