import { useEffect, useMemo, useState } from 'react'
import { validateSettings, verificationConfig } from '../verification.utils'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '../../../../App/app.selectors'
import { useSettingsAPI } from '../../settings.hooks'
import { ReduxState } from '../../../../../redux/store.types'
import { VerificationTable } from '../VerificationTable/VerificationTable'
import { VerificationOptions } from '../VerificationOptions/VerificationOptions'
import renderFooter from '../../../system.footer'
import { SotiAssoSettings } from 'au-nsi/settings'

const type = 'au-soti-asso'

export const SotiAssoVerification = () => {
  const utils = useMemo(() => verificationConfig[type], [type])

  const intl = useIntl()
  const allowEditing = useSelector(selectAccessRights)['settings:update']
  const settingsApi = useSettingsAPI<SotiAssoSettings>(type, validateSettings)

  const devices: { value: string; label: string }[] = useSelector((state: ReduxState) => state.nsi.equipment)
    .filter(utils.deviceFilter)
    .map((d) => {
      return { value: d.id, label: d.name }
    })

  const acceptedReferences: { value: string; label: string }[] = useSelector((state: ReduxState) => state.nsi.equipment)
    .filter(utils.referenceFilter)
    .map((d) => {
      return { value: d.id, label: d.name }
    })

  const [acceptedDevices, setAcceptedDevices] = useState<{ value: string; label: string }[]>([])

  useEffect(() => {
    settingsApi.draft &&
      settingsApi.draft.links &&
      setAcceptedDevices(
        devices.filter((device) => {
          return !settingsApi.draft.links.some((draftLink) => {
            return draftLink.device_id === device.value
          })
        })
      )
  }, [settingsApi.draft])

  const handleLinkAdd = () => {
    settingsApi.updateDraft({
      links: [...settingsApi.draft.links, utils.newLink()],
    } as SotiAssoSettings)
  }

  const handleTableChange = (settings: any, name: string) => {
    settingsApi.updateDraft({ [name]: settings })
  }

  const handleOptionsChange = (settings: any, name: string) => {
    settingsApi.updateDraft({
      options: { ...settingsApi.draft.options, ...{ [name]: settings } } as SotiAssoSettings['options'],
    })
  }

  return (
    <div className={'nsi-main__container'}>
      <h2 className={'system__title'}>{intl.formatMessage({ id: utils.header })}</h2>
      {settingsApi.draft && (
        <VerificationTable
          name={'links'}
          onChange={handleTableChange}
          onAddLink={handleLinkAdd}
          acceptedReferenceIds={acceptedReferences}
          acceptedDeviceIds={acceptedDevices}
          data={settingsApi.draft.links}
          acceptChange={settingsApi.editing}
          type={type}
        />
      )}
      <div className={'system__content-footer'} />
      {settingsApi.draft && (
        <VerificationOptions
          name={'current'}
          service={type}
          acceptChange={settingsApi.editing}
          settings={settingsApi.draft.options.current}
          onChange={handleOptionsChange}
          headSuffix={'-current'}
        />
      )}
      <div className={'system__content-footer'} />
      {settingsApi.draft && (
        <VerificationOptions
          name={'power'}
          service={type}
          acceptChange={settingsApi.editing}
          settings={settingsApi.draft.options.power}
          onChange={handleOptionsChange}
          headSuffix={'-power'}
        />
      )}
      {allowEditing && renderFooter(settingsApi)}
    </div>
  )
}
