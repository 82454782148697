import { Equipment } from 'au-nsi/equipment'
import { VerificationLink, VerificationService } from './verification.types'
import { SotiAssoLink } from 'au-nsi/settings'

export const verificationConfig = {
  'au-soti-asso': {
    deviceFilter: (d: Equipment) => d,
    referenceFilter: (d: Equipment) => d.protocol === 'IEC104',
    periodOptions: [
      { title: '30', value: 30, dimension: 'ChartControls.seconds' },
      { title: '3', value: 180, dimension: 'ChartControls.minutes' },
      { title: '30', value: 1800, dimension: 'ChartControls.minutes' },
    ],
    typeOptions: [
      { title: 'system.verification.average', value: 'average' },
      { title: 'system.verification.instant', value: 'instant' },
      { title: 'system.verification.integral', value: 'integral' },
    ],
    tableCols: ['system.sotiAsso.reference', 'system.verification.device_smpr', 'system.verification.type'],
    header: 'nav.system.au-soti-asso.full',
    newLink: () => {
      return { device_id: '', reference_id: '', compare_type: '', period: 30 }
    },
  },
  'au-aepf-ms': {
    deviceFilter: (d: Equipment) => d.protocol === 'C.37.118',
    referenceFilter: (d: Equipment) => d.protocol === 'db-client',
    periodOptions: [
      { title: '30', value: 1800, dimension: 'ChartControls.minutes' },
      { title: '3', value: 180, dimension: 'ChartControls.minutes' },
    ],
    header: 'nav.system.au-aepf-ms.full',
    tableCols: ['system.aepfMs.reference', 'system.verification.device_smpr', 'system.verification.period'],
    newLink: () => {
      return { device_id: '', reference_id: '', period: 180 }
    },
  },
}

export const formatPeriod = (link: VerificationLink, intl, type: VerificationService) => {
  for (const l of verificationConfig[type].periodOptions) {
    if (link.period === l.value) {
      return l.title + ' ' + intl.formatMessage({ id: l.dimension })
    }
  }
}

export const validateSettings = (settings) => {
  for (const link of settings.links) {
    // Check is all fields filled
    if (
      !Object.keys(link).reduce((acc, key) => acc && !(link[key] === ''), true) ||
      ((link as SotiAssoLink).compare_type === 'average' && !link.period)
    ) {
      return { id: 'router.errors.all_fields_must_be_filled' }
    }
  }
}
