import React from 'react'
import RadioButton from '../RadioButton/RadioButton'
import RegistratorCell from './RegistratorCell'

export default class Cell extends React.PureComponent<CellProps> {
  render() {
    const { registrator, danger, warning, checked } = this.props

    return (
      <RegistratorCell registrator={registrator} danger={danger} warning={warning}>
        <RadioButton id={registrator.id} checked={checked} onChange={this.props.onChange} />
      </RegistratorCell>
    )
  }
}

interface CellProps {
  registrator: any
  checked: boolean
  danger?: boolean
  warning?: boolean
  onChange: (e: any) => void
}
