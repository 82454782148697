import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationTypeWithInstances } from './organizations.types'
import { FormMode } from '../../../shared/interfaces'
import { Organization } from 'au-nsi/organizations'
import { orderedInsert } from 'redux/reducer.utils'

export interface OrganizationsState {
  isLoaded: boolean
  organizations: OrganizationTypeWithInstances[]
  selectedOrganizationTypeId: OrganizationTypeWithInstances['id']
  selectedOrganizationId: Organization['id']
  formMode: FormMode
}

const initialState: OrganizationsState = {
  isLoaded: false,
  organizations: [],
  selectedOrganizationTypeId: undefined,
  selectedOrganizationId: undefined,
  formMode: 'view',
}

export const slice = createSlice({
  name: 'app/organizations',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<OrganizationsState>>) => {
      Object.keys(action.payload).forEach((key) => (state[key] = action.payload[key]))
    },
    organizationsLoaded: (state, action: PayloadAction<OrganizationTypeWithInstances[]>) => {
      state.isLoaded = true
      state.organizations = action.payload
    },
    selectOrganizationType: (state, action: PayloadAction<OrganizationTypeWithInstances['id']>) => {
      state.selectedOrganizationTypeId = action.payload
      state.selectedOrganizationId = undefined
    },
    organizationTypeCreated: (state, action: PayloadAction<OrganizationTypeWithInstances>) => {
      const created = action.payload

      if (created && !state.organizations.some((organization) => organization.id === created.id)) {
        orderedInsert(state.organizations, { ...created, instances: [] })
      }
    },
    organizationTypeUpdated: (state, action: PayloadAction<OrganizationTypeWithInstances>) => {
      const organizationTypeIndex = state.organizations.findIndex(
        (organizationType) => organizationType.id === action.payload.id
      )

      if (organizationTypeIndex !== -1) {
        state.organizations[organizationTypeIndex] = {
          ...action.payload,
          instances: state.organizations[organizationTypeIndex].instances,
        }
      }
    },
    organizationTypeDeleted: (state, action: PayloadAction<OrganizationTypeWithInstances>) => {
      state.organizations = state.organizations.filter((org) => org.id !== action.payload.id)
    },
    selectOrganization: (
      state,
      action: PayloadAction<{ id: Organization['id']; typeId: OrganizationTypeWithInstances['id'] }>
    ) => {
      state.selectedOrganizationId = action.payload.id
      state.selectedOrganizationTypeId = action.payload.typeId
    },
    organizationCreated: (state, action: PayloadAction<Organization>) => {
      if (!action.payload) return

      const organizationType = state.organizations.find((org) => org.id === action.payload.organization_type_id)
      if (!organizationType) return

      if (!organizationType.instances.some((instance) => instance.id === action.payload.id)) {
        orderedInsert(organizationType.instances, action.payload)
      }
    },
    organizationUpdated: (state, action: PayloadAction<Organization>) => {
      const organization = state.organizations.find((org) => org.id === action.payload.organization_type_id)
      const instanceIndex = (organization?.instances || []).findIndex((instance) => instance.id === action.payload.id)
      if (instanceIndex !== -1) organization.instances[instanceIndex] = action.payload
    },
    organizationDeleted: (state, action: PayloadAction<Organization>) => {
      const organization = state.organizations.find((catalog) => catalog.id === action.payload.organization_type_id)
      const instanceIndex = (organization?.instances || []).findIndex((instance) => instance.id === action.payload.id)
      if (instanceIndex !== -1) organization.instances.splice(instanceIndex, 1)
    },
  },
})

export const actions = slice.actions
export const organizationsReducer = slice.reducer
