import http, { handleHttpError, handleHttpResponse } from '../../../utils/http'
import { Equipment, LP001Equipment } from 'au-nsi/equipment'
import { IGroup } from 'au-nsi-elevator/groups'

const groupsApiPrefix = '/nsi/v1/groups'

const getGroups = (): Promise<IGroup[]> => {
  return http
    .get(groupsApiPrefix)
    .then((r) => r.data)
    .catch(handleHttpError)
}

const postGroup = (group: Omit<IGroup, 'id'>): Promise<IGroup> => {
  return http.post(groupsApiPrefix, group).then(handleHttpResponse).catch(handleHttpError)
}

export type PatchGroupObject = Partial<IGroup> & { id: IGroup['id'] }
const patchGroup = (group: PatchGroupObject): Promise<IGroup> => {
  return http
    .patch(groupsApiPrefix + `/${group.id}`, group)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const deleteGroup = (id: IGroup['id']): Promise<Pick<IGroup, 'id'> & Pick<IGroup, 'name'>> => {
  return http
    .delete(groupsApiPrefix + `/${id}`)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const patchEquipmentGroup = (
  equipmentId: string,
  prevAttributes: LP001Equipment['attributes'],
  groupId: IGroup['id'] | null
): Promise<{ id: Equipment['id']; attributes: LP001Equipment['attributes'] }> => {
  return http
    .patch('/nsi/v1/equipment/' + equipmentId, {
      id: equipmentId,
      attributes: { ...prevAttributes, group_id: groupId },
    } as Partial<LP001Equipment>)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const groupsApi = {
  getGroups,
  postGroup,
  patchGroup,
  deleteGroup,
  patchEquipmentGroup,
}

export default groupsApi
