import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataRate, DataRates } from './Modals/DataRateModal/dataRateUtils'

const slice = createSlice({
  name: 'data_rates',
  initialState: { data_rates: [] } as DataRates,
  reducers: {
    set(state, action: PayloadAction<{ data_rates: DataRate[]; device_id?: string }>) {
      state.data_rates = [
        ...action.payload.data_rates,
        ...state.data_rates.filter((dr) => dr.device_id !== action.payload.device_id),
      ]
    },
  },
})

export const dataRatesActions = slice.actions
export default slice.reducer
