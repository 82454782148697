import React from 'react'
import { GanttData } from '../../../services/gantt/gantt.interfaces'
import EventEmitter from '../../../utils/events'
import Gantt from '../../GanttTable/Gantt'
import { BaseEquipment } from 'au-nsi/equipment'

const GanttRow = (props: Props) => {
  const [data, setData] = React.useState<GanttData[]>([])

  React.useEffect(() => {
    return props.data$.subscribe((d) => setData(d))
  }, [])

  const rows = data.map((row, i) => {
    const nested = i > 0
    const warning = row.warning.length > 0
    const danger = row.danger.length > 0

    const head = nested ? subrow(row.name) : <span>{props.device.name}</span>

    return (
      <Gantt.Row key={row.id + props.device.id} id={props.device.id} nested={nested} warning={warning} danger={danger}>
        {head}
        <Gantt.Chart {...row} />
      </Gantt.Row>
    )
  })

  return <React.Fragment>{rows}</React.Fragment>
}

const subrow = (name: string) => {
  return (
    <span>
      <span className="pq__icon-nested" />
      {name}
    </span>
  )
}

interface Props {
  data$: EventEmitter<GanttData[]>
  device: BaseEquipment
}

export default GanttRow
