import { memo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { useAppSelector } from '../../../redux/store'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Loader from '../../../shared/Utils/Loader'
import http, { handleHttpError } from '../../../utils/http'
import { isJsonEqual } from '../../../utils/misc'
import { showError } from '../../../utils/notifications'
import { selectCurrentUser } from '../../App/app.selectors'
import usersActions from '../../Collections/Users/users.actions'
import SecuritySettingsModal from './SecuritySettingsModal'

/**
 * Форма настроек безопасности аккаунта
 */
const SecuritySettings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()
  const user = useAppSelector(selectCurrentUser)

  const [draft, setDraft] = useState(user.security_settings)
  const [requireCode, setRequireCode] = useState(false)

  useEffect(() => {
    setDraft(user.security_settings)
  }, [user.security_settings])

  // разрешаем редактировать сразу и показываем кнопку "Сохранить" если были внесены изменения
  const isChanged = !isJsonEqual(draft, user.security_settings)

  const handleCancel = () => {
    setRequireCode(false)
    setDraft(user.security_settings)
  }

  // сохранение настроек - могут сохраниться сразу, либо НСИ может запросить код
  // подтверждения при включении 2FA
  const handleSave = (data) => {
    const request = http
      .post('/nsi/user/account/security', data)
      .then(({ data }) => {
        if (data.status === 'success') {
          dispatch(usersActions.itemUpdated(user.id, { security_settings: draft }))
          setRequireCode(false)
        }

        if (data.status === 'confirmation_required') {
          setRequireCode(true)
        }
      })
      .catch(handleHttpError)

    wait(request)
  }

  const handleChange = (value: any, key: string) => {
    // у пользователя должна быть указана почта для отправки на нее уведомлений и кодов
    const requireEmail = (key === 'login_notification' && value === true) || (key === '2fa_method' && value === 'email')

    if (requireEmail && !user.email) {
      return showError('user.profile.security.missing_email')
    }

    setDraft((prev) => ({ ...prev, [key]: value }))
  }

  const handleMethodChange = (value: boolean) => {
    const method = value ? 'email' : ''
    handleChange(method, '2fa_method')
  }

  return (
    <>
      <h2 className="system__title" style={{ margin: '45px 0 20px 0' }}>
        {intl.formatMessage({ id: 'nav.system.security' })}
      </h2>

      <ToggleWithLabel
        name="login_notification"
        value={draft.login_notification === true}
        onChange={handleChange}
        label={intl.formatMessage({ id: 'user.profile.security.login_notification' })}
        style={{ marginBottom: '12px' }}
      />

      <ToggleWithLabel
        name="2fa_email"
        value={draft['2fa_method'] === 'email'}
        onChange={handleMethodChange}
        label={intl.formatMessage({ id: 'user.profile.security.2fa_email' })}
      />

      {isChanged && (
        <div style={{ marginTop: '20px' }}>
          <button type="button" className="nsi-button default" onClick={() => handleSave(draft)}>
            {loading ? <Loader /> : intl.formatMessage({ id: 'common.save' })}
          </button>
        </div>
      )}

      {requireCode && (
        <SecuritySettingsModal
          loading={loading}
          onClose={handleCancel}
          onConfirm={(code) => handleSave({ ...draft, '2fa_code': code })}
        />
      )}
    </>
  )
}

export default memo(SecuritySettings)
