import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ITextSettingsCondition } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import { useIntl } from 'react-intl'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { operatorOptions } from '../condition.utils'
import TextSettingsForm from './TextSettingsForm'

/**
 * Форма настроек альтернативных вариантов текста в зависимости от значений параметров.
 * Помимо основной формы с настройками текста включает заголовок в котором задается
 * само условие на параметры, при выполнении которого будет показан именно этот текст.
 */
const TextConditionForm = (props: Props) => {
  const intl = useIntl()
  const cond = props.settings.condition

  const parameter = props.parameters.get(cond.parameter_id) || null
  const baseUnit = parameter ? props.units[parameter.unit] : null
  const basePrefix = parameter ? props.prefixes[parameter.prefix] : null
  const displayUnit = parameter ? props.units[parameter.display_unit] : null
  const displayPrefix = parameter ? props.prefixes[parameter.display_prefix] : null

  const handleChange = (settings: Partial<ITextSettingsCondition>) => {
    props.onChange(settings, props.index)
  }

  const handleConditionChange = (value: any, key: string) => {
    const condition = { ...cond, [key]: value }
    props.onChange({ condition }, props.index)
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <InputRow label={intl.formatMessage({ id: 'dashboards.text.settings.condition' })}>
        <div className="dashboard-text__settings-condition">
          <Select
            name="device_id"
            options={props.equipmentOptions}
            value={cond.device_id}
            onChange={handleConditionChange}
          />
          <Select
            name="parameter_id"
            options={props.parameterOptions}
            value={cond.parameter_id}
            onChange={handleConditionChange}
          />
          <Dropdown
            name="condition"
            value={cond.condition}
            options={operatorOptions}
            onChange={handleConditionChange}
          />
          <NumberInput
            type="parameter"
            name="value"
            value={cond.value}
            onChange={handleConditionChange}
            baseUnit={baseUnit}
            basePrefix={basePrefix}
            displayUnit={displayUnit}
            displayPrefix={displayPrefix}
            units={props.units}
            prefixes={props.prefixes}
          />
        </div>
      </InputRow>

      <TextSettingsForm {...props} onChange={handleChange} />
    </div>
  )
}

interface Props {
  index: number
  settings: ITextSettingsCondition
  equipmentOptions: SelectOption[]
  parameterOptions: SelectOption[]
  parameters: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  units: { [id: string]: SiUnit }
  onChange: (settings: Partial<ITextSettingsCondition>, index: number) => void
}

export default TextConditionForm
