import { Equipment } from 'au-nsi/equipment'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../../App/app.selectors'
import { useEffect, useLayoutEffect, useState } from 'react'
import { elevatorControlModuleId } from './useLPSettingsLoad'
import http from '../../../../utils/http'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import useParameterValue from '../../../../hooks/useParameterValue'

interface IProps {
  device: Equipment
}

const screenNodeId = elevatorControlModuleId + '_screen'

const Lp001SettingsForm = ({ device }: IProps) => {
  const intl = useIntl()
  const lang = useSelector(selectLanguage)
  const [screenType, setScreenType] = useState('general')
  const workModeValue = useParameterValue(device.id, 'workMode')

  useEffect(() => {
    window[elevatorControlModuleId].renderScreen({
      type: screenType,
      DOMNodeId: screenNodeId,
      equipmentId: device.id,
      parentId: device.path,
      workMode: workModeValue,
      lang,
      http,
    })
  }, [screenType, workModeValue])

  useLayoutEffect(() => {
    return () => window[elevatorControlModuleId].clearScreen(screenNodeId)
  }, [])

  const renderTabs = () => {
    const tabs = window[elevatorControlModuleId].screenTypes.map((type) => {
      const className = classNames({ 'nsi-tab': true, 'is-active': type === screenType })

      return (
        <span className={className} key={type} onClick={() => setScreenType(type)}>
          {intl.formatMessage({ id: 'map.ae.settings.' + type })}
        </span>
      )
    })

    return (
      <div className="nsi-tabs" style={{ marginBottom: '1em' }}>
        {tabs}
      </div>
    )
  }

  return (
    <div>
      {renderTabs()}
      <div id={screenNodeId} />
    </div>
  )
}

export default Lp001SettingsForm
