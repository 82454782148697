import React from 'react'
import { FormattedMessage } from 'react-intl'
import useDataService from '../../hooks/useDataService'
import useDataRate from '../../hooks/useDataRate'
import SearchInput from '../../shared/Inputs/Search/SearchInput'
import CellWithRadio from '../../shared/RegistratorCell/CellWithRadio'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import Gantt from '../GanttTable/Gantt'
import { Equipment } from 'au-nsi/equipment'

const measurements1: Array<[string, number]> = [
  ['VoltageMagnitudePhaseA', 1e3],
  ['CurrentMagnitudePhaseA', 1],
  ['PowerActivePhaseA', 1e6],
  ['PowerReactivePhaseA', 1e6],
  ['PowerApparentPhaseA', 1e6],
  ['PowerFactorPhaseA', 1],
]

const measurements2: Array<[string, number]> = [
  ['VoltageMagnitudePhaseB', 1e3],
  ['CurrentMagnitudePhaseB', 1],
  ['PowerActivePhaseB', 1e6],
  ['PowerReactivePhaseB', 1e6],
  ['PowerApparentPhaseB', 1e6],
  ['PowerFactorPhaseB', 1],
]

const measurements3: Array<[string, number]> = [
  ['VoltageMagnitudePhaseC', 1e3],
  ['CurrentMagnitudePhaseC', 1],
  ['PowerActivePhaseC', 1e6],
  ['PowerReactivePhaseC', 1e6],
  ['PowerApparentPhaseC', 1e6],
  ['PowerFactorPhaseC', 1],
]

const parameters = [
  ...measurements1.map((row) => row[0]),
  ...measurements2.map((row) => row[0]),
  ...measurements3.map((row) => row[0]),
]

const OplimTable = (props: Props) => {
  const [state, setState] = React.useState<State>({})

  const service = useDataService(RESERVED_CHART_IDS.OPLIM_TABLE, { singleValueMode: true })
  useDataRate(service)

  React.useEffect(() => {
    const selectors = props.equipment.map((e) => ({ device_id: e.id, parameters }))
    service.setDataSelectors(selectors)

    service.onTick = () => {
      if (!service.shouldRender()) return

      const data: State = {}

      for (const { id } of props.equipment) {
        data[id] = service.selectCurrentPoint(id)
      }

      setState(data)
    }
  }, [props.equipment])

  const table = props.equipmentMatch.map((device) => {
    const { id } = device
    const firstRow = renderRow(id, measurements1, state)
    const secondRow = renderRow(id, measurements2, state)
    const thirdRow = renderRow(id, measurements3, state)

    return (
      <Gantt.Block key={id}>
        <Gantt.Row selectable={false}>
          <CellWithRadio registrator={device} checked={id === props.selectedDeviceId} onChange={props.onSelect} />
          <div className="data-table__row">
            <div className="oplim__cell data-table__cell" />
            <div className="oplim__cell data-table__cell" />
            <div className="oplim__cell data-table__cell" />
            <div className="oplim__cell data-table__cell" />
            <div className="oplim__cell data-table__cell" />
            <div className="oplim__cell data-table__cell" />
          </div>
        </Gantt.Row>
        <Gantt.Row nested={true} selectable={false}>
          <div className="oplim__subrow">A</div>
          <div className="data-table__row">{firstRow}</div>
        </Gantt.Row>
        <Gantt.Row nested={true} selectable={false}>
          <div className="oplim__subrow">B</div>
          <div className="data-table__row">{secondRow}</div>
        </Gantt.Row>
        <Gantt.Row nested={true} selectable={false}>
          <div className="oplim__subrow">C</div>
          <div className="data-table__row">{thirdRow}</div>
        </Gantt.Row>
      </Gantt.Block>
    )
  })

  return (
    <div className="gantt-table">
      <Header onSearch={props.onSearch} />
      <Gantt.Body>{table}</Gantt.Body>
    </div>
  )
}

const renderRow = (id: string, names: Array<[string, number]>, state: State) => {
  return names.map(([name, factor]) => {
    const value = state[id]?.[name]

    return (
      <div key={name} className="oplim__cell data-table__cell">
        {renderValue(value, factor)}
      </div>
    )
  })
}

const renderValue = (value: number, factor: number) => {
  return value != null ? (value / factor).toFixed(2) : ''
}

const Header = React.memo((props: { onSearch: (s: string) => void }) => {
  return (
    <Gantt.Header>
      <Gantt.Title>
        <SearchInput onChange={props.onSearch} />
      </Gantt.Title>
      <div className="data-table__row data-table__header">
        <div className="oplim__cell data-table__cell">U, {<FormattedMessage id="vector.kilovolt" />}</div>
        <div className="oplim__cell data-table__cell">I, A</div>
        <div className="oplim__cell data-table__cell">
          P, {<FormattedMessage id="units.mega" />}
          {<FormattedMessage id="units.watt" />}
        </div>
        <div className="oplim__cell data-table__cell">
          Q, {<FormattedMessage id="units.mega" />}
          {<FormattedMessage id="units.var" />}
        </div>
        <div className="oplim__cell data-table__cell">
          S, {<FormattedMessage id="units.mega" />}
          {<FormattedMessage id="units.watt" />}
        </div>
        <div className="oplim__cell data-table__cell">cos&phi;</div>
      </div>
    </Gantt.Header>
  )
})

interface Props {
  equipment: Equipment[]
  equipmentMatch: Equipment[]
  selectedDeviceId: string
  search: string
  onSelect: (e: any) => any
  onSearch: (s: string) => void
}

interface State {
  [deviceId: string]: { [parameter: string]: number }
}

export default OplimTable
