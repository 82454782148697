// call wrapped function only once per animation frame
const rAF = <T>(cb: (e: T) => void) => {
  let animationId = null

  return (e: T) => {
    cancelAnimationFrame(animationId)
    animationId = requestAnimationFrame(() => cb(e))
  }
}

export default rAF
