import React from 'react'
import { FormattedMessage } from 'react-intl'
import useDataService from '../../hooks/useDataService'
import useDataRate from '../../hooks/useDataRate'
import SearchInput from '../../shared/Inputs/Search/SearchInput'
import CellWithRadio from '../../shared/RegistratorCell/CellWithRadio'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import Gantt from '../GanttTable/Gantt'
import { Equipment } from 'au-nsi/equipment'

const measurements: Array<[string, number]> = [
  ['FrequencyLfoMagnitude', 1],
  ['FrequencyLfoDampingFactor', 1],
  ['FrequencyLfoFrequency', 1],
  ['PowerActiveTriPhaseLfoMagnitude', 1e3],
  ['PowerActiveTriPhaseLfoDampingFactor', 1],
  ['PowerActiveTriPhaseLfoFrequency', 1],
]

const parameters = [
  'FrequencyLfoFrequency',
  'FrequencyLfoMagnitude',
  'FrequencyLfoDampingFactor',
  'PowerActiveTriPhaseLfoFrequency',
  'PowerActiveTriPhaseLfoMagnitude',
  'PowerActiveTriPhaseLfoDampingFactor',
]

const LfoTable = (props: Props) => {
  const [state, setState] = React.useState<State>({})

  const service = useDataService(RESERVED_CHART_IDS.LFO_TABLE, { singleValueMode: true })
  useDataRate(service)

  React.useEffect(() => {
    const selectors = props.equipment.map((e) => ({ device_id: e.id, parameters }))
    service.setDataSelectors(selectors)

    service.onTick = () => {
      if (!service.shouldRender()) return

      const data: State = {}

      for (const { id } of props.equipment) {
        data[id] = service.selectCurrentPoint(id)
      }

      setState(data)
    }
  }, [props.equipment])

  const table = props.equipmentMatch.map((device) => {
    return (
      <Gantt.Block key={device.id}>
        <Gantt.Row>
          <CellWithRadio
            registrator={device}
            checked={device.id === props.selectedDeviceId}
            onChange={props.onSelect}
          />
          <div className="data-table__row">{renderRow(device.id, state)}</div>
        </Gantt.Row>
      </Gantt.Block>
    )
  })

  return (
    <div className="gantt-table">
      <GanttHeader search={props.search} onSearch={props.onSearch} />
      <Gantt.Body>{table}</Gantt.Body>
    </div>
  )
}

const renderRow = (id: string, state: State) => {
  return measurements.map(([name, factor]) => {
    const value = state[id]?.[name]

    return (
      <div key={name} className="lfo__cell data-table__cell">
        {renderValue(value, factor)}
      </div>
    )
  })
}

const renderValue = (value: number, factor: number) => {
  return value != null ? (value / factor).toFixed(2) : '−'
}

const GanttHeader = React.memo((props: HeaderProps) => {
  return (
    <Gantt.Header>
      <Gantt.Title>
        <SearchInput onChange={props.onSearch} />
      </Gantt.Title>
      <div className="data-table__row data-table__header">
        <div className="lfo__cell data-table__cell">
          A (F), <FormattedMessage id="units.hertz" />
        </div>
        <div className="lfo__cell data-table__cell">
          K<sub>d</sub> (F), %
        </div>
        <div className="lfo__cell data-table__cell">
          F (F), <FormattedMessage id="units.hertz" />
        </div>
        <div className="lfo__cell data-table__cell">
          A (P), <FormattedMessage id="units.kilo" />
          <FormattedMessage id="units.watt" />
        </div>
        <div className="lfo__cell data-table__cell">
          K<sub>d</sub> (P), %
        </div>
        <div className="lfo__cell data-table__cell">
          F (P), <FormattedMessage id="units.hertz" />
        </div>
      </div>
    </Gantt.Header>
  )
})

interface Props {
  equipment: Equipment[]
  equipmentMatch: Equipment[]
  onSearch: (s: string) => void
  onSelect: (e: any) => void
  search: string
  selectedDeviceId: string
}

interface State {
  [deviceId: string]: { [parameter: string]: number }
}

interface HeaderProps {
  search: string
  onSearch: (s: string) => void
}

export default LfoTable
