import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import i18n from '../../translations/i18n'
import { setLocale } from '../../utils/lang'
import { LoginStatus } from '../Auth/auth.reducers'
import groupsActions from '../Parameters/groups.actions'
import paramsActions from '../Parameters/params.actions'
import { modulesActions } from '../System/Modules/modules.state'
import { selectLanguage } from './app.selectors'
import { loadTranslations } from './lp.actions'

export class LanguageProvider extends React.PureComponent<Props, State> {
  state = { messages: i18n.getMessages() }

  componentDidMount() {
    setLocale(this.props.locale)
    i18n.subscribe((messages) => this.setState({ messages }))
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.locale !== this.props.locale) {
      setLocale(this.props.locale)

      // параметры, группы и единицы измерения содержат названия формируемые на сервере,
      // поэтому при смене языка их необходимо перезагрузить
      if (this.props.loginStatus === 'logged_in') {
        this.reloadParameters()
        this.reloadTranslations()
      }
    }

    if (this.props.loginStatus === 'logged_in' && !this.props.translationsLoaded) {
      this.reloadTranslations()
    }
  }

  private reloadParameters() {
    this.props.dispatch(paramsActions.loadItems(true))
    this.props.dispatch(groupsActions.loadItems(true))
    this.props.dispatch(modulesActions.loadModules())
  }

  private reloadTranslations() {
    this.props.dispatch(loadTranslations(true))
  }

  render() {
    return React.createElement(
      // fix for types mismatch between react@18.0.0 and react-intl@5.24.8
      IntlProvider as any,
      {
        locale: this.props.locale,
        messages: this.state.messages[this.props.locale],
        textContent: React.Fragment,
        onError: noop,
      },
      React.Children.only(this.props.children)
    )
  }
}

const noop = () => null

interface Props {
  loginStatus: LoginStatus
  translationsLoaded: boolean
  locale: string
  dispatch: (action: any) => void
  children: React.ReactNode
}

interface State {
  messages: {
    en: Record<string, string>
    ru: Record<string, string>
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    loginStatus: state.auth.status,
    locale: selectLanguage(state),
    translationsLoaded: state.language.translationsLoaded,
  }
}

export default connect(mapStateToProps)(LanguageProvider)
