import http from 'utils/http'
import { SavedTemplateType } from './templates.types'

const url = '/nsi/v1/saved_templates/'

type STT = SavedTemplateType

export const listSavedTemplates = <T = any>(type: STT) => {
  return http.get(url + type).then((r) => r.data as T[])
}

export const createSavedTemplate = <T = any>(type: STT, body: T) => {
  return http.post(url + type, body).then((r) => r.data as T)
}

export const updateSavedTemplate = <T = any>(type: STT, id: number, updates: T) => {
  return http.patch(url + type + '/' + id, updates).then((r) => r.data as T)
}

export const deleteSavedTemplate = (type: STT, id: number) => {
  return http.delete(url + type + '/' + id)
}
