import { Equipment } from 'au-nsi/equipment'
import { ReactComponent as SettingsIcon } from 'icons/cog.svg'
import { selectPreferences } from 'pages/App/app.selectors'
import { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import usePopup from '../../../../hooks/usePopup'
import { useAppSelector } from '../../../../redux/store'
import SmoothPanel from '../../../../shared/Utils/SmoothPanel/SmoothPanel'
import styles from '../../maptopology.module.css'
import DeviceMainContent from './DeviceMainContent'
import DeviceMainDashboard from './DeviceMainDashboard'
import HomeDashboardsModal from './HomeDashboardsModal'

interface Props {
  device: Equipment
}

/**
 * Главная страница устройства, на которую по умолчанию попадает пользователь с карты устройств
 */
const DeviceMain = ({ device }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const intl = useIntl()

  const { open, setOpen } = usePopup(ref)
  const [showModal, setShowModal] = useState(false)
  const preferences = useAppSelector(selectPreferences)

  // из настроек определяем какой дашборд показывать на главной странице для текущего устройства
  const mainDashboard = useMemo(() => {
    if (!preferences || !preferences.home_dashboards) return null

    const conditions = preferences.home_dashboards

    for (const c of conditions) {
      const isMatch =
        c.match === 'all' ||
        (c.match === 'devices' && c.devices.includes(device.id)) ||
        (c.match === 'tags' && c.tags.some((tag) => device.tags.includes(tag)))

      if (isMatch) return c.dashboard_id
    }

    return null
  }, [preferences, device])

  // открыть модальное окно для выбора дашборда
  const openCustomizationModal = () => {
    setShowModal(true)
    setOpen(false)
  }

  const settingsMenu = () => (
    <div className="nsi-dropdown">
      <div className="nsi-dropdown__item" onClick={openCustomizationModal}>
        {intl.formatMessage({ id: 'map.device.home_dashboards_settings' })}
      </div>
    </div>
  )

  const settingsIcon = <SettingsIcon className="svg-icon" width="18px" onClick={() => setOpen(!open)} />
  const style = mainDashboard ? { maxWidth: '100%', marginBottom: 0 } : {}

  return (
    <div className="nsi-main__container" style={style}>
      <div className={styles.deviceScreenPageHeader}>
        <h2>{intl.formatMessage({ id: 'nav.map.device.main' })}</h2>

        <SmoothPanel
          ref={ref}
          open={open}
          icon={settingsIcon}
          panelContent={settingsMenu()}
          transformOrigin="top right"
        />
      </div>

      {!mainDashboard && <DeviceMainContent device={device} />}
      {mainDashboard && <DeviceMainDashboard device={device} dashboardId={mainDashboard} />}

      {showModal && <HomeDashboardsModal onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default DeviceMain
