import axios, { AxiosError, AxiosResponse } from 'axios'
import { actions, AuthState } from '../pages/Auth/auth.reducers'
import { store } from '../redux/store'
import { API_URL } from '../shared/constants'
import { showError, showInfo } from './notifications'

const http = axios.create({
  baseURL: API_URL,
  headers: {},
  adapter: require('axios/lib/adapters/xhr'),
})

let state: AuthState = null
let refreshRequest = Promise.resolve('')
let isRefreshing = false

/**
 * Обновить заголовок с авторизацией если обновился токен
 */
store.subscribe(() => {
  const auth = store.getState().auth
  if (auth === state) return

  state = auth
  http.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`
})

if (process.env.NODE_ENV !== 'test') {
  /**
   * Перед каждым запросом проверить действителен ли еще токен доступа,
   * и если нет то попытаться его обновить
   */
  http.interceptors.request.use((config) => {
    return ensureAuthorization().then((token) => {
      config.headers.Authorization = `Bearer ${token}`
      return config
    })
  })

  /**
   * Проверить ответ сервера и при ошибках связанных с авторизацией
   * перенаправить пользователя на страницу входа
   */
  http.interceptors.response.use(
    (response) => response,
    (err) => {
      const shouldLogout = err.response && err.response.status === 401

      if (shouldLogout) {
        store.dispatch(actions.logout())
      }

      throw err
    }
  )
}

/**
 * Проверить что токен еще действителен и получить новый если уже нет
 */
export const ensureAuthorization = () => {
  // до окончания действия токена осталось меньше 10 мин
  const shouldRefresh = !state || Date.now() + 600000 > state.accessExpires
  return shouldRefresh ? refreshToken() : Promise.resolve(state.accessToken)
}

/**
 * Получить новый access token
 */
export const refreshToken = () => {
  // один запрос на обновление уже в процессе, не отправлять еще один

  if (isRefreshing) {
    return refreshRequest
  }

  // т.к. сессионная cookie имеет свойство SameSite=Strict, то запрос на обновление токена с другого
  // домена завершится с ошибкой, поэтому в разработке сохраняем токен в локальном хранилище
  if (process.env.NODE_ENV !== 'production') {
    const token = localStorage.getItem('accessToken')
    store.dispatch(actions.setToken(token, Date.now() + 1e6))

    return Promise.resolve(token)
  }

  isRefreshing = true

  refreshRequest = axios
    .get(API_URL + '/nsi/auth/accessToken', { withCredentials: true })
    .then((response) => {
      isRefreshing = false
      const { accessToken, expires } = response.data
      const action = actions.setToken(accessToken, expires)

      store.dispatch(action)
      return accessToken
    })
    .catch((err) => {
      isRefreshing = false
      if (!err.response || (err.response.status !== 401 && err.response.status !== 403)) {
        store.dispatch(actions.serverError())
      } else {
        store.dispatch(actions.logout())
        return ''
      }
    })

  return refreshRequest
}

export const handleHttpResponse = (r: AxiosResponse): AxiosResponse['data'] => {
  if (r.status === 202) {
    r.data.message && showInfo(r.data.message)
    return null
  }

  return r.data
}

export const handleHttpError = (err: AxiosError) => {
  const r = err.response
  const data = r.data ? r.data.toString() : ''

  if (!state || state.status !== 'logged_in') {
    return
  } else if (r && r.status === 401) {
    return
  } else if (r && r.status === 403) {
    return data ? showError(data, data) : showError('errors.network.forbidden')
  } else if (r && r.status === 413) {
    showError('errors.file_size_limit')
  } else if (r && r.config.method === 'get') {
    showError('errors.network.loading_failed')
  } else if (r) {
    showError(data, data)
  } else {
    showError('errors.network.general')
  }
}

export default http
