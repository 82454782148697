import http from '../../../utils/http'
import { CalendarHolidays, CalendarProfile, YearHolidays } from './calendar.state'
import { parseHolidaysFile } from './calendar.utils'

const CALENDARS_PATH = '/nsi/v1/scheduler/calendar/profiles/'
const HOLIDAYS_PATH = '/nsi/v1/scheduler/calendar/holidays/'

/* calendars */

export const loadCalendars = () => {
  return http.get<CalendarProfile[]>(CALENDARS_PATH).then((r) => r.data)
}

export const createCalendar = (body: Omit<CalendarProfile, 'id'>) => {
  return http.post<CalendarProfile>(CALENDARS_PATH, body).then((r) => r.data)
}

export const updateCalendar = (calendar: CalendarProfile) => {
  return http.patch<CalendarProfile>(CALENDARS_PATH + calendar.id, calendar).then((r) => r.data)
}

export const deleteCalendar = (id: number) => {
  return http.delete(CALENDARS_PATH + id)
}

/* holidays */

export const loadHolidays = () => {
  return http.get<ServerFormat[]>(HOLIDAYS_PATH).then((r) => fromServerFormat(r.data))
}

export const saveHolidays = (calendar_id: number, year: number, holidays: YearHolidays) => {
  const data = toServerFormat(calendar_id, year, holidays)
  return http.post(HOLIDAYS_PATH, data).then(() => data)
}

export const uploadFile = (content: string, calendar_id: number) => {
  const data = parseHolidaysFile(content)
  data.calendar_id = calendar_id

  return http.post(HOLIDAYS_PATH, data).then(() => data)
}

/* holidays utils */

const fromServerFormat = (data: ServerFormat[]) => {
  const result: Record<number, CalendarHolidays> = {}

  for (const { calendar_id, year, months } of data) {
    const calendarHolidays = result[calendar_id] || {}
    const yearHolidays: Record<number, number[]> = {}

    for (const { month, days } of months) {
      yearHolidays[month - 1] = days
    }

    calendarHolidays[year] = yearHolidays
    result[calendar_id] = calendarHolidays
  }

  return result
}

const toServerFormat = (calendar_id: number, year: number, holidays: YearHolidays) => {
  const result: ServerFormat = { calendar_id, year, months: [] }

  for (const [month, days] of Object.entries(holidays)) {
    result.months.push({ month: parseInt(month) + 1, days })
  }

  return result
}

export interface ServerFormat {
  calendar_id: number
  year: number
  months: Array<{ month: number; days: number[] }>
}
