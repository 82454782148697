import { IGaugeRange } from 'au-nsi/dashboards'
import systemColorsService from '../../pages/App/Theme/systemColorsService'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import { convert } from '../../pages/Parameters/params.utils'
import { colors, findRange, getLabelPrecision } from './gauge.utils'

type ScaleType = 'default' | 'tubular' | 'compact'

interface DrawAxisOptions {
  ctx: CanvasRenderingContext2D
  ranges: IGaugeRange[]
  type: ScaleType
  value2pixel: (value: number) => number
  width: number
}

/**
 * Отрисовка основной линии обозначающей ось шкалы
 */
export const drawAxis = (options: DrawAxisOptions) => {
  const { ctx, ranges, type, value2pixel, width } = options

  const heights: { [key in ScaleType]: number } = {
    default: 2,
    tubular: 16,
    compact: 1,
  }

  const shifts: { [key in ScaleType]: number } = {
    default: 4,
    tubular: 0,
    compact: 2,
  }

  const minX = 0
  const maxX = width
  const y = shifts[type]
  const h = heights[type]

  ctx.fillStyle = colors.info
  ctx.fillRect(minX, y, maxX - minX, h)

  for (const range of ranges) {
    const x1 = Math.max(minX, value2pixel(range.lower))
    const x2 = Math.min(maxX, value2pixel(range.upper))

    ctx.fillStyle = colors[range.type] || range.color
    ctx.fillRect(x1, y, x2 - x1, h)
  }

  if (type === 'tubular') {
    ctx.clearRect(minX + 1, y + 1, maxX - minX - 2, h - 2)
  }
}

interface DrawLabelsOptions {
  ctx: CanvasRenderingContext2D
  height: number
  start: number
  stop: number
  steps: number
  value2pixel: (value: number) => number
  parameter: ParameterDn
  isVertical: boolean
}

/**
 * Отрисовка подписей к оси
 */
export const drawLabels = (options: DrawLabelsOptions) => {
  const systemColors = systemColorsService.getColors()
  const { ctx, height, start, stop, steps, parameter: p, value2pixel, isVertical } = options

  const units = p && { fromUnit: p.unit, fromPrefix: p.prefix, toUnit: p.display_unit, toPrefix: p.display_prefix }
  const range = p ? convert(stop, units) - convert(start, units) : stop - start
  const precision = getLabelPrecision(range)
  const shift = isVertical ? 32 : 18

  ctx.font = '12px source-code-pro, Menlo, Monaco, Consolas, monospace'
  ctx.fillStyle = systemColors['--gray-30']

  for (let i = 0; i <= steps; i++) {
    if (!isVertical) ctx.textBaseline = 'top'
    else if (i === 0) ctx.textBaseline = 'bottom'
    else if (i === steps) ctx.textBaseline = 'top'
    else ctx.textBaseline = 'middle'

    if (isVertical) ctx.textAlign = 'start'
    else if (i === 0) ctx.textAlign = 'start'
    else if (i === steps) ctx.textAlign = 'end'
    else ctx.textAlign = 'center'

    const value = start + ((stop - start) * i) / steps
    const v = p ? convert(value, units) : value

    const x = value2pixel(value)
    const y = height - shift

    ctx.save()

    // в вертикальном режиме весь канвас повернут на 90 градусов, поэтому для
    // нормальной отрисовки текста его надо развернуть в обратную сторону
    if (isVertical) {
      ctx.translate(x, y)
      ctx.rotate(Math.PI / 2)
      ctx.translate(-x, -y)
    }

    ctx.fillText(v.toFixed(precision), x, y, 32)
    ctx.restore()
  }
}

interface DrawTicksOptions {
  ctx: CanvasRenderingContext2D
  start: number
  stop: number
  steps: number
  value2pixel: (value: number) => number
  ranges: IGaugeRange[]
  type: ScaleType
}

/**
 * Отрисовка меток на оси
 */
export const drawTicks = (options: DrawTicksOptions) => {
  const { ctx, ranges, start, stop, steps, type, value2pixel } = options
  ctx.fillStyle = '#161C27'

  const heights: { [key in ScaleType]: number } = {
    default: 4,
    tubular: 8,
    compact: 3,
  }

  const shifts: { [key in ScaleType]: number } = {
    default: 12,
    tubular: 4,
    compact: 5,
  }

  const h = heights[type]
  const y = shifts[type]

  const isTubular = type === 'tubular'
  const i0 = isTubular ? 1 : 0
  const i1 = isTubular ? steps - 1 : steps

  for (let i = i0; i <= i1; i++) {
    const value = start + ((stop - start) * i) / steps
    const x = Math.ceil(value2pixel(value)) - 1

    if (!isTubular) {
      const range = findRange(ranges, value)
      ctx.fillStyle = range ? colors[range.type] || range.color : colors.info
    }

    ctx.fillRect(Math.max(x, 1), y, 1, h)
  }
}

/**
 * Рассчитать шаг с котрым отображать подписи к осям
 * @param valuesRange Интервал значений
 * @param width Ширина компонента
 */
export const getLabelSteps = (valuesRange: number, width: number) => {
  const precision = Math.floor(Math.log10(valuesRange)) - 1

  // перебор количества меток
  for (let n = 10; n > 1; n -= 1) {
    const dx = width / n
    // между подписями должно быть не меньше 60px
    if (dx < 60) continue

    const dv = valuesRange / n

    // если метки попадают на целые значения, то останавливаем поиск
    const isRound = Number.isInteger(dv / Math.pow(10, precision))
    if (isRound) return n
  }

  return 1
}
