import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ISVGCommandsSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import InputRow from '../../../shared/Inputs/InputRow'
import CommandSelectForm from '../../Commands/components/CommandSelectForm'

const SettingsCommandsRow = (props: Props) => {
  const intl = useIntl()
  const { row, index } = props

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', columnGap: '1em', marginBottom: '1em' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', flexGrow: 1, columnGap: '1em' }}>
        <InputRow compact={true} label={intl.formatMessage({ id: 'dashboards.svg_diagram.settings.commands.node_id' })}>
          <Select
            name="node_id"
            options={props.nodeOptions}
            value={row.node_id}
            onChange={handleChange}
            onHover={props.onNodeHover}
            required={true}
          />
        </InputRow>

        <CommandSelectForm {...row} editing={true} required={false} onChange={handleChange} />
      </div>

      <div style={{ transform: 'translateY(10px)' }}>
        <RemoveButton id="" onRemove={() => props.onRemove(index)} />
      </div>
    </div>
  )
}

interface Props {
  index: number
  nodeOptions: SelectOption[]
  onChange: (key: string, value: string, index: number) => void
  onNodeHover: (id: string) => void
  onRemove: (index: number) => void
  row: ISVGCommandsSettings
}

export default React.memo(SettingsCommandsRow)
