import { AxiosError } from 'axios'
import { IntlShape } from 'react-intl'

export const getResponseErrorMessage = (err: AxiosError, intl: IntlShape): string => {
  switch (true) {
    case err.response && err.response.status === 429:
      return intl.formatMessage({ id: 'errors.network.too_many_requests' })
    case err.response && err.response.status >= 500:
      return intl.formatMessage({ id: 'errors.network.general' })
    case err.response && err.response.status != null:
      return err.response.data + ''
    default:
      return intl.formatMessage({ id: 'errors.network.general' })
  }
}
