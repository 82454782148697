import classnames from 'classnames'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import MultiDropdown from '../../shared/Inputs/MultiDropdown'
import { actions } from './lfo.reducers'

const LfoChartsHeader = (props: Props) => {
  const { charts } = props

  const gridIconClass = classnames('pq-trends-header__icon icon-grid', { 'is-active': props.showAsGrid })
  const title = props.intl.formatMessage({ id: 'lfo.charts.title' })

  const options = Object.keys(charts).map((key) => {
    return { title: props.intl.formatMessage({ id: 'lfo.charts.' + key }), value: key }
  })

  const values = Object.keys(charts).filter((key) => charts[key])

  const handleChange = (selected: string[]) => {
    const updated = { ...charts }

    for (const key of Object.keys(updated)) updated[key] = false
    for (const key of selected) updated[key] = true

    props.dispatch(actions.setVisibleCharts(updated))
  }

  const handleGridClick = () => props.dispatch(actions.toggleGrid())

  return (
    <div className="pq-trends-header">
      <div className={gridIconClass} onClick={handleGridClick} />
      <MultiDropdown title={title} options={options} values={values} onChange={handleChange} />
    </div>
  )
}

interface Props {
  charts: { [id: string]: boolean }
  showAsGrid: boolean
  intl: IntlShape
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    charts: state.lfo.visibleCharts,
    showAsGrid: state.lfo.showAsGrid,
  }
}

export default injectIntl(connect(mapStateToProps)(LfoChartsHeader))
