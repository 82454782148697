import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { selectReceivers } from '../Nsi/nsi.selectors'

const getHiddenDevices = (state: ReduxState) => state.pq.hiddenDevices

/**
 * Выбрать все устройства из НСИ и применить к ним настройки видимости,
 * которые хранятся отдельно в local storage
 */
export const selectEquipmentWithVisibility = createSelector(
  [selectReceivers, getHiddenDevices],
  (registrators, hidden) => {
    return registrators.map((r) => {
      const visible = !hidden[r.id]
      return { ...r, visible }
    })
  }
)
