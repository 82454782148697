import { moderationSliceActions, ModerationState } from './moderation.reducer'
import { getRequest, getRequests } from './moderation.api'
import { ReduxState } from '../../../redux/store.types'

const LOAD_LIMIT = 10

const loadRequest = (id: number) => async (dispatch, getState) => {
  const request = await getRequest(id)
  if (!request) return

  const nextPage = (getState() as ReduxState).moderation_requests.nextPage
  dispatch(moderationSliceActions.append({ requests: [request], nextPage, cleanBeforePush: false }))
}

const loadLast = () => async (dispatch, getState) => {
  const filters = (getState() as ReduxState).moderation_requests.filters
  const { results, nextPage } = await getRequests(LOAD_LIMIT, null, filters)
  dispatch(moderationSliceActions.append({ requests: results, nextPage, cleanBeforePush: true }))
}

interface ILoadRequestsOptions {
  cleanBeforePush?: boolean
  filters?: ModerationState['filters']
}

const loadRequests = (options?: ILoadRequestsOptions) => async (dispatch, getState) => {
  const filters = options?.filters ?? (getState() as ReduxState).moderation_requests.filters

  const page = options?.cleanBeforePush ? null : (getState() as ReduxState).moderation_requests.nextPage
  const { results, nextPage } = await getRequests(LOAD_LIMIT, page, filters)

  dispatch(
    moderationSliceActions.append({
      requests: results,
      nextPage,
      cleanBeforePush: options?.cleanBeforePush,
      filters: options?.filters,
    })
  )
}

const moderationActions = { ...moderationSliceActions, loadRequests, loadRequest, loadLast }

export default moderationActions
