import { TNode } from '../tree.types'
import { AnyObject } from 'common'
import { useCallback, useState } from 'react'
import storeTreeStrategy from './storeTreeStrategies'

export type TTreePersistenceStrategy = 'localstorage' | 'inmemory' | 'none'

export interface ITreeState<T extends AnyObject> {
  selectedNodeId: TNode<T>['id']
  openedNodes: Set<TNode<T>['id']>

  setSelectedNodeId: (node: TNode<T>['id']) => void
  setOpenedNodes: (nodes: Set<TNode<T>['id']>) => void
}

/**
 * Хук, возвращающий состояние дерева и реплицирующий его в соответсвии с указанной стратегией
 * */
const useTreeState = <T extends AnyObject>(strategy: TTreePersistenceStrategy, key: string): ITreeState<T> => {
  const [selectedNodeId, setSelectedNodeId] = useState<ITreeState<T>['setSelectedNodeId']>(
    () => storeTreeStrategy[strategy]?.get(`${key}:selectedNodeId`) as ITreeState<T>['selectedNodeId']
  )
  const [openedNodes, setOpenedNodes] = useState(() => getNodesState(strategy, key) as ITreeState<T>['openedNodes'])

  const handleNodesChange = useCallback(
    (nodes: ITreeState<T>['openedNodes']) => {
      setOpenedNodes(nodes)
      if (strategy === 'none') return

      const k = `${key}:openedNodes`
      switch (strategy) {
        case 'localstorage': // Set не сереализуется в JSON, поэтому необходимо использовать отедльный кодек
          storeTreeStrategy['setLocalstorage'].set(k, nodes)
          break
        default:
          storeTreeStrategy[strategy].set(k, nodes)
      }
    },
    [strategy, key]
  )

  const handleSelectNodeId = useCallback(
    (id: ITreeState<T>['setSelectedNodeId']) => {
      setSelectedNodeId(id)
      if (strategy === 'none') return

      storeTreeStrategy[strategy].set(`${key}:selectedNodeId`, id)
    },
    [strategy, key]
  )

  return {
    selectedNodeId,
    openedNodes,
    setOpenedNodes: handleNodesChange,
    setSelectedNodeId: handleSelectNodeId,
  } as ITreeState<T>
}

const getNodesState = (strategy: TTreePersistenceStrategy, key: string) => {
  if (strategy === 'none') return new Set()
  if (strategy === 'localstorage') return storeTreeStrategy.setLocalstorage.get(`${key}:openedNodes`) ?? new Set()
  return storeTreeStrategy[strategy].get(`${key}:openedNodes`) ?? new Set()
}

export default useTreeState
