import { IAttribute } from 'au-nsi/equipment'

/**
 * Получить атрибут с указанным ключом
 */
export const getAttribute = (attributes: IAttribute[], key: string) => {
  if (!attributes || !Array.isArray(attributes)) return null

  return attributes.find((a) => a.key === key)
}

/**
 * Получить значение атрибута с указанным ключом
 */
export const getAttributeValue = (attributes: IAttribute[], key: string) => {
  const attr = getAttribute(attributes, key)
  return attr ? attr.value : null
}

/**
 * Выставить значение атрибута. Чистая функция. Если атрибут уже есть то его значение будет заменено,
 * если нет - то добавлен новый атрибут
 */
export const setAttribute = (attributes: IAttribute[], attribute: IAttribute): IAttribute[] => {
  if (!attributes || !Array.isArray(attributes)) return [attribute]

  const index = attributes.findIndex((a) => a.key === attribute.key)
  if (index === -1) return [...attributes, attribute]

  return [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]
}

/**
 * Копировать атрибуты с ключами keys из source в target. Чистая функция.
 */
export const copyAttributes = (source: IAttribute[], target: IAttribute[], keys: string[]) => {
  let result = target

  for (const key of keys) {
    const attr = getAttribute(source, key)
    if (attr) result = setAttribute(result, { ...attr })
  }

  return result
}
