import { HTMLProps } from 'react'
import { useNavigate } from 'react-router-dom'
import { MouseEvent } from 'react'

interface IProps extends HTMLProps<HTMLSpanElement> {
  to: string
  title: any
}

/**
 Компонент для замены <Link /> в react-router-dom
 Нужен в случае появления вложенных друг в друга ссылок. Мини-хак для соблюдения семантики.
*/
const SpanLink = ({ to, title, ...props }: IProps) => {
  const navigate = useNavigate()

  const route = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    e.preventDefault()

    navigate(to)
  }

  return (
    <span {...props} onClick={route}>
      {title}
    </span>
  )
}

export default SpanLink
