import React from 'react'
import IncidentsJournal from '../../Incidents/journal/IncidentsJournal'
import AuthJournal from '../../System/AuthJournal/AuthJournal'
import RebootJournal from '../../System/RebootJournal/RebootJournal'
import SystemMessagesPage from '../Messages/MessagesPage'
import { IRoute } from './nav.utils'

const ActionsJournal = React.lazy(() => import('../../System/ActionsJournal/ActionsJournal'))
const LogsPage = React.lazy(() => import('../../System/Logs/LogsPage'))

const journalRoutes: IRoute[] = [
  {
    id: 'journal.auth',
    path: 'auth',
    component: AuthJournal,
    rights: ['journal:get_sessions', 'journal:get_logins'],
  },
  { id: 'journal.actions', path: 'actions', component: ActionsJournal, rights: ['journal:get_changelog'] },
  { id: 'journal.system', path: 'system', component: SystemMessagesPage, rights: ['settings:get_messages'] },
  { id: 'journal.logs', path: 'logs', component: LogsPage, rights: ['journal:get_logs'] },
  {
    id: 'journal.incidents',
    path: 'incidents',
    component: IncidentsJournal,
    rights: ['incidents:get'],
    modules: ['au-signal-events'],
  },
  { id: 'journal.reboot', path: 'reboot', component: RebootJournal, rights: ['journal:get_logs'] },
]

export default journalRoutes
