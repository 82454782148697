import { Equipment } from 'au-nsi/equipment'
import { useAppSelector } from '../../../../redux/store'
import { useMemo } from 'react'
import { IGroup } from 'au-nsi-elevator/groups'

/**
 * Вернуть группу устройства с id={equipmentId}
 * */
const useDeviceGroup = (equipmentId: Equipment['id']): IGroup => {
  const groups = useAppSelector((state) => state.groups.groups)
  const equipment = useAppSelector((state) => state.nsi.equipment)

  const device = useMemo(() => equipment.find((e) => e.id === equipmentId), [equipment])
  const group = useMemo(
    () => device.protocol === 'LP001' && groups.find((g) => g.id === device.attributes.group_id),
    [device, groups]
  )

  return group
}

export default useDeviceGroup
