import { Equipment } from 'au-nsi/equipment'
import { useIntl } from 'react-intl'
import Loader from '../../../shared/Utils/Loader'

interface Props {
  state: Equipment['state']
  error: Equipment['error']
}

const DeviceStatus = ({ state, error }: Props) => {
  const intl = useIntl()

  const showLoader = state === 'STARTING' || state === 'READING_CONF'
  const showError = state === 'ERROR' || state === 'WARNING' || state === 'READING_CONF_ERROR'

  let color = 'var(--color)'
  if (state === 'ERROR' || state === 'READING_CONF_ERROR') color = 'var(--danger-text)'
  if (state === 'WARNING') color = 'var(--warning)'

  let text = intl.formatMessage({ id: 'nsi.device_state.' + state })
  if (showError) text += ': ' + error

  return (
    <div className="inline-flex" style={{ color, padding: '0.1em 0.5em', fontWeight: 'lighter' }}>
      <span>{text}</span>
      {showLoader && <Loader />}
    </div>
  )
}

export default DeviceStatus
