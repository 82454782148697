import { Incident } from 'au-nsi/signal-events'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import http, { handleHttpError } from '../../utils/http'
import seActions from '../SignalEvents/se.actions'
import { rulesMapSelector } from '../SignalEvents/se.selectors'

/**
 * Hook для получения сигнальной ситуации по id
 */
const useIncidentSelector = (id: number) => {
  const [entry, setEntry] = React.useState<Incident>()

  const dispatch = useDispatch()
  const incidents = useSelector((state: ReduxState) => state.incidents.incidents)
  const archive = useSelector((state: ReduxState) => state.incidents.archive)
  const rules = useSelector(rulesMapSelector)

  // проверка на случай если инцидент уже есть либо в текущих, либо в загруженном архиве
  let incident = React.useMemo(() => {
    if (!id) return null

    const result = incidents.find((e) => e.id === id)
    if (result != null) return result

    return archive.find((e) => e.id === id)
  }, [incidents, archive])

  // если инцидетна нет в редаксе (например переход по прямой ссылке к очень старому инциденту),
  // то загружаем его из базы и сохраняем в локальный стейт
  React.useEffect(() => {
    if (!id) return
    if (incident != null) return

    http
      .get('/nsi/v1/incidents/' + id)
      .then((r) => setEntry(r.data))
      .catch(handleHttpError)
  }, [])

  if (incident == null) {
    incident = entry
  }

  const ruleId = incident && incident.type === 'formula' ? incident.formula_id : null

  // также проверяем, что загружена формула, по которой определялся инцидент
  React.useEffect(() => {
    if (ruleId != null && !rules.has(ruleId)) {
      dispatch(seActions.fetchDeletedRule(ruleId))
    }
  }, [ruleId])

  return incident
}

export default useIncidentSelector
