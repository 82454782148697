import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserTask } from './userTasks.types'

export interface IUserTasksState {
  tasks: IUserTask[]
  active: Set<IUserTask['id']>
}

const initialState: IUserTasksState = { tasks: [], active: new Set<IUserTask['id']>() }

const slice = createSlice({
  name: 'userTasks',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IUserTask[]>) => {
      state.tasks = action.payload
    },
    setTaskState: (state, action: PayloadAction<{ id: IUserTask['id']; active: boolean }>) => {
      const index = state.tasks.findIndex((task) => task.id === action.payload.id)
      if (index === -1) return

      if (action.payload.active) state.active.add(action.payload.id)
      else state.active.delete(action.payload.id)
    },
    userTaskCreated: (state, action: PayloadAction<IUserTask>) => {
      state.tasks.push(action.payload)
      state.active.add(action.payload.id)
    },
    userTaskUpdated: (state, action: PayloadAction<IUserTask>) => {
      const index = state.tasks.findIndex((task) => task.id === action.payload.id)
      if (index === -1) return

      state.tasks[index] = action.payload
    },
    userTaskDeleted: (state, action: PayloadAction<{ id: IUserTask['id']; [key: string]: any }>) => {
      const index = state.tasks.findIndex((task) => task.id === action.payload.id)
      if (index === -1) return

      state.tasks.splice(index, 1)
    },
  },
})

export const actions = slice.actions

const userTasksReducer = slice.reducer
export default userTasksReducer
