import React from 'react'
import { useIntl } from 'react-intl'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import http, { handleHttpError } from '../../../utils/http'

/**
 * Модальное окно для подтверждения удаления выбранных участков импульс-архива
 */
const ArchiveDeleteForm = (props: Props) => {
  const intl = useIntl()
  const [loading, setLoading] = React.useState(false)

  // удаление данных из импульс-архива может занять некоторое время и пользователь
  // может уйти из компонента до окончания запроса
  const isCancelled = React.useRef(false)

  React.useEffect(() => {
    return () => {
      isCancelled.current = true
    }
  }, [])

  const handleDelete = () => {
    setLoading(true)
    const ids = Array.from(props.selection)

    http
      .post('/nsi/v1/incidents_archive/delete', { ids })
      .then(() => props.onDelete(props.selection))
      .catch(handleHttpError)
      .finally(() => !isCancelled.current && props.onClose())
  }

  return (
    <Modal onClose={props.onClose} closeOnClickOutside={false}>
      <h2 className="text--center">{intl.formatMessage({ id: 'ChartPlayer.archive.impulse_delete_confirm' })}</h2>
      <ModalFooter
        loading={loading}
        onCancel={props.onClose}
        onSave={handleDelete}
        saveStyle="danger"
        saveMessage={intl.formatMessage({ id: 'common.delete' })}
      />
    </Modal>
  )
}

interface Props {
  selection: Set<number>
  onClose: () => void
  onDelete: (selection: Set<number>) => void
}

export default ArchiveDeleteForm
