import { AxiosInstance } from 'axios'
import EventEmitter from '../../utils/events'
import client from '../../utils/http'
import { parseResponse } from './data.encoding'
import { IDataArray } from './data.types'

/**
 * Класс для загрузки данных измерений с сервера. Должен использоваться как singleton
 * для того чтобы все запросы проходили через один инстанс. Основная задача - принять
 * запросы на загрузку данных от всех компонентов, сформировать из них и отправить один запрос,
 * получить ответ, определить какие данные из него нужны какому компоненту и передать их.
 */
export class DataLoader {
  private counter = 1

  private isFetching = false
  private timer = null

  private clients = new Map<number, OnData>()

  private nextRequestOptions = new Map<number, IRequestOption[]>()

  private prevRequestOptions = new Map<number, IRequestOption[]>()

  public events$ = new EventEmitter<'loading' | 'loaded'>()

  constructor(private http: AxiosInstance) {}

  /**
   * каждый компонент системы который хочет загружать данные должен зарегистрироваться
   * и получить свой id, с которым дальше сможет делать запросы
   */
  public register(cb: OnData) {
    const id = this.counter
    this.counter += 1

    this.clients.set(id, cb)
    return id
  }

  public unregister(id: number) {
    this.clients.delete(id)
    this.nextRequestOptions.delete(id)
    this.prevRequestOptions.delete(id)
  }

  /**
   * Добавить запрос в очередь. Для того чтобы не совершать много запросов (по одному на каждый компонент)
   * они группируются и все данные для всех компонентов загружаются одним запросом.
   * @param clientId id выданный при регистрации
   * @param options данные которые нужно получить
   */
  public load(clientId: number, options: IRequestOption[]) {
    this.nextRequestOptions.set(clientId, options)

    if (!this.isFetching) {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.fetch, 100)
    }
  }

  public cancel(clientId: number) {
    this.prevRequestOptions.delete(clientId)
    this.nextRequestOptions.delete(clientId)
  }

  public isLoading(clientId: number) {
    return this.prevRequestOptions.has(clientId) || this.nextRequestOptions.has(clientId)
  }

  private fetch = () => {
    if (this.nextRequestOptions.size === 0) {
      return
    }

    this.isFetching = true
    this.prevRequestOptions = this.nextRequestOptions
    this.nextRequestOptions = new Map()
    this.events$.next('loading')

    const query = mergeOptions(this.prevRequestOptions)
    const config = { responseType: 'arraybuffer' as const, headers: { Accept: 'application/octet-stream' } }

    this.http
      .post('/back/v1/measurements/query', { query }, config)
      .then((r) => this.handleResponse(r.data, query))
      .catch((error) => this.handleError(error))
      .finally(() => {
        this.isFetching = false
        this.events$.next('loaded')
        this.fetch()
      })
  }

  // обработать ответ с данными от сервера - определить какие данные
  // какой компонент запрашивал и передать их ему
  private handleResponse(buffer: ArrayBuffer, query: IDataQuery[]) {
    const requestOptions = this.prevRequestOptions
    this.prevRequestOptions = new Map()

    const data = parseResponse(buffer, query)
    const usedIndexes = new Set<number>()

    for (const [key, options] of requestOptions.entries()) {
      const result: DataLoaderResponse[] = []

      for (const o of options) {
        const i = query.findIndex((q) => {
          return (
            q.key === o.key && q.tsFrom === o.tsFrom && q.tsTo === o.tsTo && q.aggregationWindow === o.aggregationWindow
          )
        })

        const points = usedIndexes.has(i) ? copyData(data[i]) : data[i]
        result.push({ id: o.device_id, parameters: o.parameters, points })
        usedIndexes.add(i)
      }

      const handler = this.clients.get(key)
      handler(null, result)
    }
  }

  private handleError(err: Error) {
    const requestOptions = this.prevRequestOptions
    this.prevRequestOptions = new Map()

    for (const key of requestOptions.keys()) {
      const handler = this.clients.get(key)
      handler(err, null)
    }
  }
}

// create deep copy of IDataArray
const copyData = (data: IDataArray): IDataArray => {
  const copy: IDataArray = { ...data }

  for (const [key, values] of Object.entries(data)) {
    copy[key] = [...values]
  }

  return copy
}

/**
 * Соединить индивидуальные опции запросов от всех компонентов в один
 */
export const mergeOptions = (requstOptions: Map<number, IRequestOption[]>): IDataQuery[] => {
  const result: IDataQuery[] = []

  for (const options of requstOptions.values()) {
    for (const option of options) {
      const { key, device_id: id, tsFrom, tsTo, aggregationWindow, type = 'processed' } = option

      // если запрос данных по такому устройству с таким же промежутком времени уже был
      // от другого компонента, то просто добавляем новые запрашиваемые параметры,
      // иначе добавляем запрос по этому устройству
      const match = result.find(
        (r) =>
          r.key === key &&
          r.tsFrom === tsFrom &&
          r.tsTo === tsTo &&
          r.aggregationWindow === aggregationWindow &&
          r.type === type
      )

      if (!match) {
        result.push({ key, id, names: [...option.parameters], tsFrom, tsTo, aggregationWindow, type })
        continue
      }

      for (const parameter of option.parameters) {
        if (!match.names.includes(parameter)) {
          match.names.push(parameter)
        }
      }
    }
  }

  return result
}

interface IRequestOption {
  key: string
  device_id: string
  parameters: string[]
  tsFrom: number
  tsTo: number
  aggregationWindow: number
  type?: DataType
}

export interface IDataQuery {
  key: string
  id: string
  names: string[]
  tsFrom: number
  tsTo: number
  aggregationWindow: number
  type: DataType
}

export interface DataLoaderResponse {
  id: string
  parameters: string[]
  points: IDataArray
}

type OnData = (err: Error, data: DataLoaderResponse[]) => void

type DataType = 'raw' | 'processed' | 'irregular' | 'predicted'

export default new DataLoader(client)
