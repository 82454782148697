import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '../../App/app.selectors'
import css from './settings.module.css'
import FrontSettings from './FrontSettings'
import PasswordsSettings from './PasswordsSettings'
import HistoryButton from '../ActionsJournal/HistoryButton'

const SystemSettings = () => {
  const intl = useIntl()
  const allowEditing = useSelector(selectAccessRights)['settings:update']

  return (
    <div className="nsi-main__container">
      <div className={css.block}>
        <div className="nsi-main__header">
          <h2>{intl.formatMessage({ id: 'system.settings.general' })}</h2>
          <HistoryButton resource="settings_front" resourceId="settings_front" />
        </div>
        <FrontSettings allowEditing={allowEditing} />
      </div>

      <div className={css.block}>
        <div className="nsi-main__header">
          <h2>{intl.formatMessage({ id: 'system.settings.passwords' })}</h2>
        </div>
        <PasswordsSettings allowEditing={allowEditing} />
      </div>
    </div>
  )
}

export default SystemSettings
