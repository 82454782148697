import { useIntl } from 'react-intl'
import { formatDate } from '../../../utils/lang'
import { User } from '../collection.interfaces'
import { getUserStatus } from './users.utils'

/**
 * Отображение статуса аккаунта пользователя: на модерации/деактивирован/время регистрации
 */
const UserStatus = (props: Props) => {
  const intl = useIntl()

  const { user } = props
  const status = getUserStatus(user)

  if (status === 'suspended') {
    const msg = intl.formatMessage({ id: 'nsi.user.suspend_warning' })
    return <div className="nsi-cn__status-row is-warning">{msg}</div>
  }

  if (user.registration) {
    const date = formatDate(user.registration, 'd MMMM yyyy')
    const msg = intl.formatMessage({ id: 'nsi.user.registration_info' }, { date })
    return <div className="nsi-cn__status-row">{msg}</div>
  }

  if (user.created_at) {
    const date = formatDate(user.created_at, 'd MMMM yyyy')
    const msg = intl.formatMessage({ id: 'nsi.user.creation_info' }, { date })
    return <div className="nsi-cn__status-row">{msg}</div>
  }

  return null
}

interface Props {
  user: User
}

export default UserStatus
