import { AuPmuVerification } from './AuPmuVerification'
import { AuPmuVerificationCheck } from './AuPmuVerificationCheck/AuPmuVerificationCheck'
import { SotiAssoVerification } from './AuSotiAsso/SotiAssoVerification'
import { AuAepfMs } from './AuAepfMs/AuAepfMs'
import { VerificationService } from './verification.types'

export const createVerification = (type: VerificationService) => {
  switch (type) {
    case 'au-aepf-ms':
      return AuAepfMs
    case 'au-soti-asso':
      return SotiAssoVerification
    case 'au-pmu-verification':
      return AuPmuVerification
    case 'au-pmu-verification-check':
      return AuPmuVerificationCheck
  }
}
