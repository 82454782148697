import { MouseEventHandler } from 'react'
import css from './access.module.css'

const AccessGroupRow = ({ group, name, type, isOpen, isSelected, onClick, onToggle }: Props) => {
  const iconClass = isOpen ? 'tpl-icon open-icon' : 'tpl-icon closed-icon'
  const className = isSelected ? css.item + ' ' + css.selectedItem : css.item

  const onDragStart = (e) => {
    e.dataTransfer.setData('text/plain', type + ':' + group.id)
    e.dataTransfer.dropEffect = 'move'
  }

  return (
    <div
      className={className}
      draggable={true}
      data-type={type}
      data-id={group.id}
      onClick={onClick}
      onDragStart={onDragStart}
    >
      <span className={iconClass} style={{ margin: '0 4px' }} data-type={type} data-id={group.id} onClick={onToggle} />
      <span>{name}</span>
    </div>
  )
}

interface Props {
  type: 'orgs' | 'roles'
  group: { id: number; name: string }
  name: string | JSX.Element
  isOpen: boolean
  isSelected: boolean
  onClick: MouseEventHandler
  onToggle: MouseEventHandler
}

export default AccessGroupRow
