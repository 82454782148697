import { IChartLine, IChartSettings2 } from 'au-nsi/dashboards'
import classnames from 'classnames'
import React from 'react'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import cursorService from '../../services/cursor/cursor.service'
import DataService from '../../services/data/data.service'
import { Boundaries, Margins } from '../interfaces'
import { Handlers } from './chart.interfaces'
import * as interfaces from './chart.interfaces'
import AutoscalePlugin from './plugins/AutoscalePlugin'
import CursorPlugin from './plugins/CursorPlugin'
import PanPlugin from './plugins/PanPlugin'
import VisPlugin from './plugins/VisPlugin'
import ZoomPlugin from './plugins/ZoomPlugin'
import * as defaults from './utils/chart.defaults'
import { countAxes } from './utils/chart.utils'
import { ChartScale } from './utils/scale.utils'

const PIXELS_PER_POINT = 3

/**
 * Компонент для отображения графика
 * Chart только рендерит JSX из стейта и вызывает lifecycle methods и обработчики событий плагинов
 * Весь оснавной функционал вынесен в плагины
 */
export class Chart extends React.Component<Props, State> {
  state: State = {
    boundaries: defaults.boundaries, // координаты svg элемента относительно страницы
    // расстояния между видимой областью графика и границами canvas
    margins: { top: 10, right: 0, bottom: 25, left: 0 },
    frame: this.props.settings.frame || this.props.services[0].clock.getFrame(),
    // значения верхней границы для каждой оси
    maxY: this.props.settings.axes.map((axis) => axis.maxY ?? 1),
    // значения нижней границы для каждой оси
    minY: this.props.settings.axes.map((axis) => axis.minY ?? 0),
  }

  public t0: number = Date.now() - 60 * 1000
  public t1: number = Date.now()

  public wrapper = React.createRef<HTMLDivElement>()
  public zoomRef = React.createRef<HTMLDivElement>()
  public cursorRef = React.createRef<HTMLDivElement>()
  public markersRef = React.createRef<HTMLDivElement>()
  public tooltipRef = React.createRef<HTMLDivElement>()
  public ctx: CanvasRenderingContext2D

  // обновления стейта которые будут применены после вызова всех плагинов
  // плагины не вызывают сами setState т.к. реакт 16.5 не батчит последовательные обновления стейта
  public updates: any = {}

  // размеры внутренней части графика в которой отрисовываются сами данные
  public innerWidth = this.state.boundaries.width - this.state.margins.left - this.state.margins.right
  public innerHeight = this.state.boundaries.height - this.state.margins.top - this.state.margins.bottom

  public showPrediction = false

  // обработчики событий в которые каждый из плагинов может добавлять свои методы
  mountHandlers: Handlers<void> = []
  updateHandlers: Handlers<{ prevProps: Props; prevState: State }> = []
  dataHandlers: Handlers<{ t0: number; t1: number; dataUpdated: boolean }> = []
  mouseDownHandlers: Handlers<React.MouseEvent> = []
  mouseUpHandlers: Handlers<React.MouseEvent> = []
  mouseLeaveHandlers: Handlers<React.MouseEvent> = []
  mouseMoveHandlers: Handlers<{ clientX: number; clientY: number }> = []
  clickHandlers: Handlers<React.MouseEvent> = []

  // плагины реализуют большинство функционала,
  // компонент передается в конструкторе для того чтобы у плагинов был доступ к
  // общим данным: пропсам, стейту, обработчикам событий и т.д.
  autoscalePlugin = new AutoscalePlugin(this)
  cursorPlugin = new CursorPlugin(this)
  panPlugin = new PanPlugin(this)
  visPlugin = new VisPlugin(this)
  zoomPlugin = new ZoomPlugin(this)

  private cursorSubscription: { unsubscribe: () => void }
  private frameUnsubscribe: () => void
  private mouseAnimationId: any
  private executeAnimationId: any

  // предрассчитанные коэффициенты использующиеся в xScale и yScale (т.к. эти функции вызываются
  // при каждой отрисовке на каждую точку данных)
  private xScaleFactor = this.innerWidth / this.state.frame
  private yScaleFactors = this.state.maxY.map((_, i) => this.innerHeight / (this.state.maxY[i] - this.state.minY[i]))

  // to prevent tooltip from overflowing chart it is necessary to explicitly define its height
  // 30px is the height of the row with time (18px) + tooltip padding (4px * 2) + tooltip margin (2px * 2)
  get maxTooltipHeight() {
    return this.state.boundaries.height - this.state.margins.top - this.state.margins.bottom - 30
  }

  // ширина кадра может быть фиксирована в настройках, если нет то используется кадр плеера
  get isFrameFixed() {
    return !!this.props.settings.frame
  }

  // зная метку времени рассчитать ее координату по оси X (в пикселях)
  public xScale = (ts: number) => {
    return this.state.margins.left + (ts - this.t0) * this.xScaleFactor
  }

  // зная координату по оси X рассчитать соответствующее ей время
  public xScaleInverse = (x: number) => {
    return this.t0 + (x - this.state.margins.left) / this.xScaleFactor
  }

  // зная значение параметра рассчитать его координату по оси Y
  public yScales = this.state.maxY.map((_, i) => {
    return (value: number) => this.state.margins.top + (this.state.maxY[i] - value) * this.yScaleFactors[i]
  })

  public yScalesInverse = this.state.maxY.map((_, i) => {
    return (y: number) => this.state.maxY[i] - (y - this.state.margins.top) / this.yScaleFactors[i]
  })

  public yScalesMirror = this.yScales.map((yScale) => {
    return (value: number) => yScale(-value)
  })

  // выбрать шкалу по оси Y для указанной линии: если линия относится к зеркальной оси, то
  // используется шкала отражающая значения относительно нуля, иначе стандартная шкала
  public selectScaleY = (axis: number, line: IChartLine) => {
    return this.props.settings.mirrorY && line.mirror ? this.yScalesMirror[axis] : this.yScales[axis]
  }

  public setFrame = (frame: number) => {
    if (frame !== this.state.frame) this.setState({ frame })

    for (const service of this.props.services) {
      service.setMsPerFrame(frame)
    }
  }

  componentDidMount() {
    const canvas = this.wrapper.current.querySelector('canvas')
    this.ctx = canvas.getContext('2d')

    this.setFrame(this.state.frame)
    this.setMargins()
    this.handleResize()
    this.execute(this.mountHandlers)
    this.subscribe()

    if (this.props.controls.scroll !== false) {
      this.wrapper.current.addEventListener('wheel', this.handleWheel, { passive: false })
    }
  }

  componentWillUnmount() {
    this.cursorSubscription.unsubscribe()
    this.frameUnsubscribe()
    this.wrapper.current.removeEventListener('wheel', this.handleWheel)
    cancelAnimationFrame(this.mouseAnimationId)
    cancelAnimationFrame(this.executeAnimationId)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { props, state } = this
    const shouldResize = prevProps.lastResize !== props.lastResize || prevProps.legendHeight !== props.legendHeight
    const isSizeChanged = prevState.boundaries !== this.state.boundaries

    if (prevProps.settings.frame !== props.settings.frame) {
      const frame = props.settings.frame || props.services[0].clock.getFrame()
      this.setFrame(frame)
    }

    if (prevProps.settings.axes !== props.settings.axes) {
      this.setMargins()
    }

    if (shouldResize) this.handleResize()

    if (isSizeChanged || prevState.frame !== state.frame) {
      this.xScaleFactor = this.innerWidth / this.state.frame
    }

    if (isSizeChanged || prevState.minY !== state.minY || prevState.maxY !== state.maxY) {
      this.yScaleFactors = this.state.maxY.map((maxY, i) => this.innerHeight / (maxY - this.state.minY[i]))
    }

    // Значения minY и maxY из пропсов - заданы пользователем и могут быть не определены если пользователь
    // не фиксировал границы в настройках. Эти же поля в стейте самого компонента - текущие реальные значения
    // используемые для отрисовки, и они могут отличаться из-за автомасштабирования, зума и т.д. Но если пользователь
    // поменял в настройках границы, то их необходимо применить к графику.
    const isLimitsChanged =
      prevProps.settings.axes.some(
        (axes, i) => axes.minY !== props.settings.axes[i].minY || axes.maxY !== props.settings.axes[i].maxY
      ) ||
      (!prevProps.controls.autoscale && props.controls.autoscale)

    if (isLimitsChanged) {
      const minY = props.settings.axes.map((axis, i) => axis.minY ?? state.minY[i])
      const maxY = props.settings.axes.map((axis, i) => axis.maxY ?? state.maxY[i])
      this.setState({ minY, maxY })
    }

    this.execute(this.updateHandlers, { prevProps, prevState })
  }

  scheduleUpdate(update: Partial<State>) {
    Object.assign(this.updates, update)
  }

  // данные приходят каждые 16мс, при кадре >30с больше половины из них вызовут изменение
  // интерфейса меньше 1px, поэтому можно обновлять компонент с меньшей частотой
  private shouldUpdate(t0: number, t1: number) {
    // минимальное изменение времени которое вызовет сдвиг на 1/4 пикселя
    // 1/4 - для более гладкого движения, т.к. при 1 пикселе заметны рывки
    const dt_min = (t1 - t0) / (4 * this.state.boundaries.width)
    const dt_0 = Math.abs(t0 - this.t0)
    const dt_1 = Math.abs(t1 - this.t1)

    return dt_0 >= dt_min || dt_1 >= dt_min
  }

  private subscribe() {
    this.props.services[0].onTick = ({ playerTime, dataUpdated, isOnline }) => {
      // в онлайн режиме и с включенным прогнозом сдвигаем отображаемый кадр в будущее
      // на значение указанное в predictionTime
      const { prediction, predictionTime } = this.props.settings
      this.showPrediction = isOnline && prediction && predictionTime > 0

      const t1 = this.showPrediction ? playerTime + Math.min(predictionTime, this.state.frame) : playerTime
      const t0 = t1 - this.state.frame

      if (dataUpdated || this.shouldUpdate(t0, t1)) {
        this.t0 = t0
        this.t1 = t1
        this.execute(this.dataHandlers, { t0, t1, dataUpdated })
      }

      for (const service of this.props.services) {
        if (service.isLoading) {
          return this.visPlugin.drawLoader()
        }
      }
    }

    this.frameUnsubscribe = this.props.services[0].clock.frame$.subscribe((frame) => {
      if (!this.isFrameFixed) this.setFrame(frame)
    }, true)

    this.cursorSubscription = cursorService.subscribe((cursor) => {
      this.cursorPlugin.onCursor(cursor)
    })
  }

  // выставить отступы самого графика от границ в зависимости от того сколько места займут оси
  private setMargins() {
    const { left, right } = countAxes(this.props.settings)
    const margins = { ...this.state.margins }
    margins.left = 50 * left
    margins.right = 50 * right

    this.innerWidth = this.state.boundaries.width - margins.left - margins.right
    this.innerHeight = this.state.boundaries.height - margins.top - margins.bottom
    this.setState({ margins })
  }

  // обработать изменения размеров окна браузера
  private handleResize = () => {
    const boundaries = this.wrapper.current.getBoundingClientRect()
    const points = Math.round(boundaries.width / PIXELS_PER_POINT)

    for (const service of this.props.services) {
      service.setPointsPerFrame(points)
    }

    const { margins } = this.state
    this.innerWidth = boundaries.width - margins.left - margins.right
    this.innerHeight = boundaries.height - margins.top - margins.bottom
    this.setState({ boundaries })
  }

  // вызвать все обработчики событий зарегистрированных плагинами
  // и обновить стейт только после завершения всех обработчиков
  private execute<T>(funcs: Handlers<T>, arg: T = null) {
    funcs.forEach((f) => f(arg))

    const isUpdated = Object.keys(this.updates).length !== 0
    const isUpdateScheduled = this.executeAnimationId != null

    if (isUpdated && !isUpdateScheduled) {
      this.executeAnimationId = requestAnimationFrame(() => {
        this.setState(this.updates)
        this.updates = {}
        this.executeAnimationId = null
      })
    }
  }

  private handleMouseDown = (e: React.MouseEvent) => {
    this.execute(this.mouseDownHandlers, e)
  }

  private handleMouseUp = (e: React.MouseEvent) => {
    this.execute(this.mouseUpHandlers, e)
  }

  private handleMouseLeave = (e: React.MouseEvent) => {
    this.execute(this.mouseLeaveHandlers, e)
  }

  private handleMouseMove = (e: React.MouseEvent) => {
    cancelAnimationFrame(this.mouseAnimationId)
    const { clientX, clientY } = e

    this.mouseAnimationId = requestAnimationFrame(() => {
      this.execute(this.mouseMoveHandlers, { clientX, clientY })
    })
  }

  private handleClick = (e: React.MouseEvent) => {
    this.execute(this.clickHandlers, e)
  }

  private handleWheel = (e: WheelEvent) => {
    this.zoomPlugin.handleWheelEvent(e)
  }

  render() {
    const { width, height } = this.state.boundaries
    const { axes } = this.props.settings
    const { margins } = this.state

    const chartClass = classnames('line-charts__chart', {
      zoomable: this.props.controls.zoom,
      pannable: this.props.controls.pan,
    })

    const tooltipValues: JSX.Element[] = []
    const markers: JSX.Element[] = []

    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]

      for (const l of axis.lines) {
        // на разных осях могут отображаться данные по одному параметру и с одного устойства,
        // поэтому добавляем в ключ еще и индекс оси
        const key = i + ':' + l.device_id + ':' + l.parameter_id

        tooltipValues.push(
          <div key={key} data-id={key} className="line-chart__tooltip-value" style={{ color: l.color }} />
        )

        markers.push(
          <div key={key} data-id={key} className="line-chart__cursor-marker" style={{ background: l.color }} />
        )
      }
    }

    return (
      <div
        className={chartClass}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleClick}
        ref={this.wrapper}
        style={{ height: `calc(100% - ${this.props.legendHeight}px)` }}
      >
        <canvas width={width} height={height} />

        <div className="line-chart__zoom" ref={this.zoomRef} />

        <div
          className="line-chart__cursor"
          ref={this.cursorRef}
          style={{ top: margins.top, height: this.innerHeight }}
        />

        <div ref={this.markersRef} style={{ opacity: 0 }}>
          {markers}
        </div>

        <div ref={this.tooltipRef} className="line-chart__tooltip">
          <div className="line-chart__tooltip-values" style={{ maxHeight: this.maxTooltipHeight }}>
            {tooltipValues}
          </div>
          <div className="line-chart__tooltip-time" />
        </div>
      </div>
    )
  }
}

interface Props {
  controls: interfaces.ChartControls
  id: number
  lang: string
  lastResize: number
  legendHeight: number
  lineMode: number
  onAutoScale: (scale: ChartScale, index: number) => void
  parameters: Map<string, ParameterDn>
  scales: ChartScale[]
  services: DataService[]
  settings: IChartSettings2
}

interface State {
  boundaries: Boundaries
  margins: Margins
  frame: number
  maxY: number[]
  minY: number[]
}

export default Chart
