import { memo, useEffect } from 'react'
import { IntlShape } from 'react-intl'
import Dropdown, { DropdownOption } from '../../Inputs/Dropdown'
import InputRow from '../../Inputs/InputRow'
import NumberInput from '../../Inputs/NumberInput'
import { SiUnit } from 'au-nsi/parameters'

/**
 * Настройка отображения столбцов (отображать линией или столбцами + ширина столбцов)
 */
const BarsOptions = (props: Props) => {
  const toggle = (
    <InputRow label={props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.bars' })}>
      <Dropdown
        name="bars"
        value={props.bars || false}
        options={props.options}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </InputRow>
  )

  useEffect(() => {
    if (props.disabled && props.bars) props.onChange(false, 'bars')
  }, [props.disabled])

  if (!props.bars) {
    return toggle
  }

  return (
    <>
      <div className="chart__settings-row-block">{toggle}</div>
      <div className="chart__settings-row-block">
        <InputRow label={props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.barsWidth' })}>
          <NumberInput
            fullWidth={true}
            baseUnit={props.units.fraction}
            displayUnit={props.units.percent}
            name="barsWidth"
            onChange={props.onChange}
            prefixes={{}}
            type="parameter"
            units={props.units}
            value={props.barsWidth || 1}
            min={0}
            max={1}
          />
        </InputRow>
      </div>
    </>
  )
}

interface Props {
  bars: boolean
  barsWidth: number
  disabled: boolean
  intl: IntlShape
  onChange: (v: any, key: string) => void
  options: DropdownOption<boolean>[]
  units: Record<string, SiUnit>
}

export default memo(BarsOptions)
