import { FormMode } from '../../shared/interfaces'

interface State<T> {
  mode: FormMode
  items: T[]
  selectedItem: T
  selectedItemId: number | string
  isLoaded: boolean
}

interface Resource {
  id: number | string
  name?: string
}

type Reducer<T> = (state: State<T>, action: any) => State<T>

interface Reducers<T> {
  [name: string]: Reducer<T>
}

type Factory<T> = (action: any) => T
type EmptyValue<T> = T | Factory<T>

export const findItem = <T extends Resource>(items: T[], id: number | string): T => {
  const item = items.find((e) => e.id === id)
  return item && { ...item }
}

/**
 * note about *background* parameter:
 * some actions can be caused by direct user interaction, while others by websocket events
 * therefore *background = true* means that action was caused by another user and received via websockets
 */
export const generateReducers = <T extends Resource>(
  resource: string,
  emptyValue: EmptyValue<T>,
  orderByName = false
) => {
  const SET_MODE = `app/${resource}/MODE`
  const SET_ITEMS = `app/${resource}/SET`
  const ADD_ITEM = `app/${resource}/ADD`
  const UPDATE_ITEM = `app/${resource}/UPDATE`
  const DELETE_ITEM = `app/${resource}/DELETE`
  const SELECT_ITEM = `app/${resource}/SELECT`
  const UPDATE_SELECTED_ITEM = `app/${resource}/UPDATE_SELECTED`

  const reducers: Reducers<T> = {
    [SET_ITEMS]: (state, { items }) => ({ ...state, items, isLoaded: true }),
    [ADD_ITEM]: (state, { item, background }) => {
      const isAlreadyExists = state.items.find((u) => u.id === item.id)

      if (isAlreadyExists && state.mode !== 'create') {
        return state
      }

      const items = isAlreadyExists ? state.items : [...state.items, item]
      if (!isAlreadyExists && orderByName) reorderItem(items, items.length - 1)
      const result = { ...state, items }

      if (!background || isAlreadyExists) {
        result.mode = 'view'
        result.selectedItem = { ...item }
        result.selectedItemId = item.id
      }

      return result
    },
    [UPDATE_ITEM]: (state, { id, updates, background }) => {
      const index = state.items.findIndex((item) => item.id === id)
      if (index === -1) return state

      const items = [...state.items]
      items[index] = { ...items[index], ...updates }
      if (orderByName) reorderItem(items, index)

      const result = { ...state, items }
      const switchToView = !background && state.mode === 'edit' && state.selectedItemId === id
      if (switchToView) result.mode = 'view'

      if (result.selectedItemId === id && result.mode !== 'edit') {
        result.selectedItem = findItem(items, state.selectedItemId)
      }

      return result
    },
    [DELETE_ITEM]: (state, { id, background }) => {
      const items = state.items.filter((item) => item.id !== id)
      const result = { ...state, items }

      const switchToView = !background || state.selectedItemId === id
      if (switchToView) {
        result.mode = 'view'
        result.selectedItemId = null
        result.selectedItem = null
      }

      return result
    },
    [SELECT_ITEM]: (state, { id }) => {
      // do not allow to change selected item while editing, creating or deleting
      if (state.mode !== 'view') {
        return state
      }

      const selectedItem = findItem(state.items, id)
      return { ...state, selectedItem, selectedItemId: id }
    },
    [SET_MODE]: (state, action) => {
      const { mode } = action
      // transition between different modes only through 'view' mode
      // eg. disallow transitioning from 'update' directly to 'create'
      if (mode !== 'view' && state.mode !== 'view') return state

      const result = { ...state, mode }

      if (mode === 'create') {
        result.selectedItem = typeof emptyValue === 'function' ? emptyValue(action) : { ...emptyValue }
        result.selectedItemId = null
      }

      if (mode === 'view') {
        result.selectedItem = findItem(state.items, state.selectedItemId)
      }

      return result
    },
    [UPDATE_SELECTED_ITEM]: (state, { field, value }) => {
      const selectedItem = { ...state.selectedItem, [field]: value }
      return { ...state, selectedItem }
    },
  }

  return reducers
}

/**
 * Переместить элемент с индексом index для сохранения сортировки элементов по названию
 */
const reorderItem = (arr: Resource[], index: number) => {
  const item = arr[index]

  let cursor = index
  while (cursor > 0 && arr[cursor - 1].name > item.name) cursor -= 1
  while (cursor < arr.length - 1 && arr[cursor + 1].name < item.name) cursor += 1

  if (cursor !== index) {
    arr.splice(index, 1)
    arr.splice(cursor, 0, item)
  }
}
