import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ISVGColorSettings, ISVGVisibilitySettings } from 'au-nsi/dashboards'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import React, { useEffect } from 'react'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Dropdown, { DropdownOption } from '../../../shared/Inputs/Dropdown'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { applyColor } from './svg.utils'

const SettingsConditionRow = (props: Props) => {
  const { row, index, parameter } = props

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  // Превью настроек
  useEffect(() => {
    const colorValid = props.type === 'color' && props.svg && row.node_id
    const visibilityValid = props.type === 'visibility' && props.svg && row.node_id

    const cancelVisibility =
      visibilityValid &&
      (() => {
        const node = props.svg.querySelector('#' + row.node_id)
        if (!node) return

        const prevStyles = node.getAttribute('style') || ''

        node.setAttribute('style', 'display: none;')
        const rollback = () => node.setAttribute('style', prevStyles)

        setTimeout(rollback, 500)
        return rollback
      })()

    colorValid && applyColor(props.svg.querySelector('#' + row.node_id), (row as ISVGColorSettings).color)

    return () => {
      colorValid && applyColor(props.svg.querySelector('#' + row.node_id), null)
      visibilityValid && cancelVisibility && cancelVisibility()
    }
  }, [row, props.svg])

  let firstColumn = null

  if (props.type === 'color') {
    firstColumn = (
      <ColorPicker
        color={(row as ISVGColorSettings).color}
        onChange={(color) => props.onChange('color', color, index)}
        position="right"
      />
    )
  }

  if (props.type === 'visibility') {
    firstColumn = (
      <Dropdown
        name="action"
        value={(row as ISVGVisibilitySettings).action}
        options={props.actionOptions}
        onChange={handleChange}
      />
    )
  }

  return (
    <tr>
      <td>{firstColumn}</td>
      <td>
        <Select
          name="node_id"
          options={props.nodeOptions}
          value={row.node_id}
          onChange={handleChange}
          onHover={props.onNodeHover}
          required={true}
        />
      </td>
      <td>
        <Select
          name="device_id"
          options={props.deviceOptions}
          value={row.device_id}
          onChange={handleChange}
          required={true}
        />
      </td>
      <td>
        <Select
          name="parameter_id"
          options={props.parameterOptions}
          value={row.parameter_id}
          onChange={handleChange}
          required={true}
        />
      </td>
      <td>
        <Dropdown
          name="condition"
          options={props.operatorOptions}
          value={row.condition}
          onChange={handleChange}
          compact={true}
        />
      </td>
      <td>
        <NumberInput
          type="parameter"
          name="value"
          value={row.value}
          onChange={handleChange}
          units={props.units}
          prefixes={props.prefixes}
          baseUnit={parameter && parameter.unit}
          basePrefix={parameter && parameter.prefix}
          displayUnit={parameter && parameter.display_unit}
          displayPrefix={parameter && parameter.display_prefix}
        />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={() => props.onRemove(index)} />
      </td>
    </tr>
  )
}

interface Props {
  actionOptions?: DropdownOption<string>[]
  deviceOptions: SelectOption[]
  index: number
  nodeOptions: SelectOption[]
  onChange: (key: string, value: string, index: number) => void
  onNodeHover: (id: string) => void
  onRemove: (index: number) => void
  operatorOptions: DropdownOption<string>[]
  parameter: ParameterDn
  parameterOptions: SelectOption[]
  prefixes: Record<string, SiPrefix>
  row: ISVGColorSettings | ISVGVisibilitySettings
  type: 'color' | 'visibility'
  units: Record<string, SiUnit>
  svg?: SVGSVGElement
}

export default React.memo(SettingsConditionRow)
