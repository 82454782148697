import { ReactComponent as Profile } from 'icons/user.svg'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import useDeviceWidth from '../../hooks/useDeviceWidth'
import { ReactComponent as DoubleLeftIcon } from '../../icons/double-arrow.svg'
import HistoryModal from '../System/ActionsJournal/HistoryModal'
import { systemEnvState } from '../System/system.state'
import ClockIcon from './ClockIcon'
import ConnectionIndicator from './ConnectionIndicator'
import HelpDocsIcon from './HelpDocs/DocsIcon'
import InstallButton from './InstallButton/InstallButton'
import './intl/nav.intl'
import './menu.styles.css'
import mobileAppRoutes from './Nav/app.mobile.routes'
import appRoutes from './Nav/app.routes'
import Links from './Nav/Links'
import Logout from './Nav/Logout'
import MobileNav from './Nav/MobileNav'
import Notifications from './Notifications/Notifications'
import { changelogNotificationModalState } from './UserNotifications/userNotifications.state'

// развилки - пути являющиеся родительскими элементами, но по которым нет конечных страниц
const forks = new Set(['/archive', '/journal', '/catalogs', '/libraries', '/settings', '/settings/system'])
const links = appRoutes.filter((r) => r.id !== 'user')
const mobileLinks = mobileAppRoutes.filter((r) => r.id !== 'user')

const TopMenu = () => {
  const intl = useIntl()
  const envState = useRecoilValue(systemEnvState)
  const [changelogModalState, setChangelogModalState] = useRecoilState(changelogNotificationModalState)
  const [open, setOpen] = useState(false)

  const { isDesktop } = useDeviceWidth()
  const location = useLocation()
  const navigate = useNavigate()

  // скрывем меню, если пользователь кликнул на ссылку и перешел на другую страницу
  // ели же страница является "развилкой" то оставляем меню и показываем ссылки на ее дочерние страницы
  useEffect(() => {
    if (!forks.has(location.pathname)) setOpen(false)
  }, [location])

  const monitoringLink = (
    <a className="top-menu__item" href={envState.grafana_url} target="_blank" rel="noopener noreferrer">
      {intl.formatMessage({ id: 'nav.monitoring' })}
    </a>
  )

  const profileButton = (
    <div
      className="alerts__button"
      title={intl.formatMessage({ id: 'nav.user' })}
      onClick={() => navigate('/user/profile')}
    >
      <Profile width="24" />
    </div>
  )

  // для десктопов меню всегда отображается сверху страницы, для телефонов оно скрыто за левой границей
  // и появляется только когда пользователь его откроет (клик на иконку "бургера")
  const showMenu = open || isDesktop
  const transform = showMenu ? 'translate(0)' : 'translate(-100%)'

  return (
    <div className="top-menu">
      {!isDesktop && <MobileNav routes={mobileLinks} forks={forks} />}

      <div className="top-menu__nav" style={{ transform }}>
        <DoubleLeftIcon className="top-menu__close-icon" onClick={() => setOpen(false)} />
        <Links routes={links} />
        {envState.grafana_url && monitoringLink}
        {!isDesktop && <Logout className="top-menu__item" />}
      </div>

      <div className="top-menu__info">
        <ConnectionIndicator />
        <InstallButton />
        <Notifications />
        {profileButton}
        <HelpDocsIcon />
        <ClockIcon />
      </div>

      {changelogModalState && (
        <HistoryModal
          resource={changelogModalState.resource}
          resourceId={changelogModalState.resource_id}
          onClose={() => setChangelogModalState(null)}
        />
      )}
    </div>
  )
}

export default TopMenu
