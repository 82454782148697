import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import css from '../docs.module.css'
import { actions } from '../docs.reducers'
import { truncateName } from '../docs.utils'
import { IDocument } from 'au-nsi/documents'

/**
 * Заголовок библиотеки для отображения пути текущей папки и перехода по клику к родительским папкам
 */
const DocsNavigation = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const folders = React.useMemo(() => props.documents.filter((d) => d.is_folder), [props.documents])

  const handleClick = (e) => {
    const index = +e.currentTarget.dataset.index
    const path = props.path.slice(0, index + 1)
    dispatch(actions.setPath(path))
  }

  let path = props.path.map((id, i) => {
    const name = i === 0 ? intl.formatMessage({ id: 'documents.root_folder' }) : folders.find((f) => f.id === id)?.name

    return (
      <div key={i} className={css.navigationItem} data-index={i} title={name} onClick={handleClick}>
        {truncateName(name + ' /', 32)}
      </div>
    )
  })

  // при большом уровне вложенности показываем только последние 5 уровней
  if (path.length > 5) path = path.slice(path.length - 5)

  return <div className={css.navigation}>{path}</div>
}

interface Props {
  path: string[]
  documents: IDocument[]
}

export default React.memo(DocsNavigation)
