import { IButtonSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useAppSelector } from '../../../redux/store'
import { showError } from '../../../utils/notifications'
import { selectCommandsMap, selectCommandTypesMap } from '../../Commands/commands.selectors'
import { isCompleteCommand, sendCommands } from '../../Commands/commands.utils'
import CommandsArgumentsModal from '../../Commands/components/CommandArgumentsModal'
import { selectImagesMap } from '../../Libraries/Images/image.selectors'
import { useDashboardNavigation } from '../common/useDashboardNavigation'
import './button.styles.css'

const Button = (props: Props) => {
  const { settings } = props

  const ref = React.useRef<HTMLDivElement>()
  const [modalOpen, setModalOpen] = React.useState(false)

  const navigate = useDashboardNavigation()

  const images = useAppSelector(selectImagesMap)
  const commands = useAppSelector(selectCommandsMap)
  const commandTypes = useAppSelector(selectCommandTypesMap)

  // обработка клика по кнопке
  const handleClick = () => {
    // переход на другой экран
    if (settings.action === 'navigate') {
      return navigate(props.settings.navigate_target)
    }

    // отправка команды
    if (settings.action === 'command') {
      // т.к. настройки кнопки хранятся в json без foreign key, то при удалении устройства
      // команда может больше никуда не ссылаться
      if (settings.commands.some((c) => !commands.has(c.command_id))) {
        return showError('commands.send_not_found')
      }

      // если все аргументы команды уже заданы в настройках, то сразу ее отправляем,
      // если нет - то открываем модальное окно для ввода нехватающих аргументов
      const isComplete = settings.commands.every((cmd) => isCompleteCommand(cmd, commands, commandTypes))

      if (isComplete) sendCommands(settings.commands)
      else setModalOpen(true)
    }
  }

  const isImage = settings.type === 'image'

  const fontSize = (settings.font_size || 16) + 'px'
  const className = 'dashboards-button is-' + settings.style

  const text = isImage ? '' : settings.text
  const backgroundImage = isImage ? renderBackground(images.get(settings.image_id)) : null
  const style = { lineHeight: fontSize, height: fontSize, fontSize, backgroundImage }

  return (
    <React.Fragment>
      {modalOpen && <CommandsArgumentsModal commands={settings.commands} onClose={() => setModalOpen(false)} />}
      <div ref={ref} className={className} title={settings.text} onClick={handleClick}>
        <div className="dashboards-button__text" style={style}>
          {text}
        </div>
      </div>
    </React.Fragment>
  )
}

const renderBackground = (image) => {
  return image ? `url("${image.src_url}")` : null
}

interface Props {
  id: number
  lastResize: number
  settings: IButtonSettings
}

export default Button
