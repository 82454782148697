import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'redux/store'
import Form from 'shared/Forms/Form'
import Datepicker from 'shared/Inputs/Datepicker/Datepicker'
import Dropdown from 'shared/Inputs/Dropdown'
import DurationInput from 'shared/Inputs/DurationInput'
import InputRow from 'shared/Inputs/InputRow'
import TextInput from 'shared/Inputs/TextInput'
import ModalFooter from 'shared/Modal/ModalFooter'
import { access2visibility, visibility2access } from './access.utils'
import { PlayerBookmark, SavedTemplate, SavedTemplateType } from './templates.types'

interface Props {
  type: SavedTemplateType
  item: SavedTemplate
  onCancel: () => void
  onSave: (item: SavedTemplate) => void
}

/**
 * Форма создания и редактирования сохраненного шаблона. Общие поля - это название
 * и настройки доступа, остальные поля зависят от типа шаблона.
 */
const SavedTemplateForm = ({ type, item, onCancel, onSave }: Props) => {
  const intl = useIntl()
  const [draft, setDraft] = useState(item)

  const user = useAppSelector((state) => state.user)

  // видимость закладки - личная или общая
  const visibilityOptions = [
    { value: 'all', title: intl.formatMessage({ id: 'saved_templates.access.all' }) },
    { value: 'user', title: intl.formatMessage({ id: 'saved_templates.access.user' }) },
    { value: 'role', title: intl.formatMessage({ id: 'saved_templates.access.role' }) },
  ]

  if (user.organization_id) {
    visibilityOptions.push({ value: 'org', title: intl.formatMessage({ id: 'saved_templates.access.org' }) })
  }

  const handleDataChange = (value: any, key: string) => {
    setDraft((prev) => ({ ...prev, data: { ...prev.data, [key]: value } } as any))
  }

  const handleAccessChange = (visibility) => {
    setDraft((prev) => ({ ...prev, access: visibility2access(visibility, user) }))
  }

  const handleNameChange = (name: string) => setDraft((prev) => ({ ...prev, name }))
  const handleSave = () => onSave(draft as any)

  const renderTypeFields = () => {
    if (type === 'player_bookmarks') {
      const b = draft as PlayerBookmark

      return (
        <>
          <InputRow label={intl.formatMessage({ id: 'saved_templates.player_bookmarks.ts' })}>
            <Datepicker
              time={b.data.ts}
              onTimeChange={handleDataChange}
              wrapperClass="system__input-wrapper"
              inputClass="nsi-input"
            />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'saved_templates.player_bookmarks.frame' })}>
            <DurationInput name="frame" value={b.data.frame} onChange={handleDataChange} />
          </InputRow>
        </>
      )
    }

    return null
  }

  return (
    <Form editing={true} onCancel={onCancel} onSubmit={handleSave}>
      <InputRow label={intl.formatMessage({ id: 'saved_templates.name' })}>
        <TextInput name="name" value={draft.name} onChange={handleNameChange} required={true} />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'saved_templates.access' })}>
        <Dropdown
          name="access"
          options={visibilityOptions}
          value={access2visibility(draft.access)}
          onChange={handleAccessChange}
        />
      </InputRow>

      <div style={{ lineHeight: '1em' }} className="system__grid">
        {renderTypeFields()}
      </div>

      <ModalFooter onCancel={onCancel} onSave={handleSave} />
    </Form>
  )
}

export default SavedTemplateForm
