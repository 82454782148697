import React from 'react'

class ErrorBoundary extends React.Component<Props, State> {
  state = { isError: false }

  componentDidCatch() {
    this.setState({ isError: true })
  }

  render() {
    return this.state.isError ? (
      <div className="dashboard-card__error">Can not display component</div>
    ) : (
      this.props.children
    )
  }
}

interface Props {
  children: any
}

interface State {
  isError: boolean
}

export default ErrorBoundary
