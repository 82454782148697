import { INumberFormatSettings } from 'au-nsi/dashboards'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import usePopup from '../../../hooks/usePopup'
import { ReactComponent as Icon } from '../../../icons/cog.svg'
import Counter from '../../../shared/Inputs/Counter/Counter'
import InlineSelect from '../../../shared/Inputs/InlineSelect'
import Toggle from '../../../shared/Inputs/Toggle'
import SmoothPanel from '../../../shared/Utils/SmoothPanel/SmoothPanel'
import css from './format.module.css'
import { defaultFormat } from './format.utils'

interface Props {
  name?: string
  settings: INumberFormatSettings
  onChange: (settings: INumberFormatSettings, name: string) => void
}

const NumberFormatSettings = ({ name, settings = defaultFormat, onChange }: Props) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  const format = { ...defaultFormat, ...settings }

  const handleChange = (value: any, key: string) => {
    onChange({ ...format, [key]: value }, name || 'number_format')
  }

  const base = format.base || 10
  const baseOptions = [
    { value: 10, label: 'DEC' },
    { value: 16, label: 'HEX' },
    { value: 2, label: 'BIN' },
  ]

  const renderForm = () => (
    <div className={css.dropdown}>
      <div className={css.header}>
        <h3>{intl.formatMessage({ id: 'dashboards.common.number_format.title' })}</h3>
        <div style={{ fontSize: 12 }}>
          <InlineSelect
            size="small"
            style={{ background: 'var(--bg-default-light)' }}
            value={base}
            options={baseOptions}
            onChange={(v) => handleChange(+v, 'base')}
          />
        </div>
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td className={css.label}>
              {intl.formatMessage({ id: 'dashboards.common.number_format.minimumIntegerDigits' })}
            </td>
            <td>
              <Counter
                name="minimumIntegerDigits"
                value={format.minimumIntegerDigits}
                min={1}
                max={21}
                onChange={handleChange}
              />
            </td>
          </tr>

          {base === 10 && (
            <>
              <tr>
                <td className={css.label}>
                  {intl.formatMessage({ id: 'dashboards.common.number_format.minimumFractionDigits' })}
                </td>
                <td>
                  <Counter
                    name="minimumFractionDigits"
                    value={format.minimumFractionDigits}
                    min={0}
                    max={Math.min(20, format.maximumFractionDigits)}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className={css.label}>
                  {intl.formatMessage({ id: 'dashboards.common.number_format.maximumFractionDigits' })}
                </td>
                <td>
                  <Counter
                    name="maximumFractionDigits"
                    value={format.maximumFractionDigits}
                    min={Math.max(0, format.minimumFractionDigits)}
                    max={20}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className={css.label}>
                  {intl.formatMessage({ id: 'dashboards.common.number_format.useGrouping' })}
                </td>
                <td>
                  <Toggle name="useGrouping" checked={format.useGrouping} size="12px" onChange={handleChange} />
                </td>
              </tr>
            </>
          )}

          {(base === 16 || base === 2) && (
            <tr>
              <td className={css.label}>{intl.formatMessage({ id: 'dashboards.common.number_format.prefix' })}</td>
              <td>
                <input
                  className={'nsi-input ' + css.prefixInput}
                  value={format.prefix || ''}
                  onChange={(e) => handleChange(e.target.value, 'prefix')}
                />
              </td>
            </tr>
          )}

          {base === 16 && (
            <tr>
              <td className={css.label}>{intl.formatMessage({ id: 'dashboards.common.number_format.lowerCase' })}</td>
              <td>
                <Toggle name="lowerCase" checked={format.lowerCase ?? false} size="12px" onChange={handleChange} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )

  return (
    <SmoothPanel
      ref={ref}
      open={open}
      icon={<Icon className={css.icon} color="#FFF" width="20" height="20" onClick={() => setOpen(!open)} />}
      panelContent={renderForm()}
      transformOrigin="top right"
    />
  )
}

export default NumberFormatSettings
