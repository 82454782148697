import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PlusButton from '../../../shared/Forms/PlusButton'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { operatorOptions } from '../condition.utils'
import SettingsConditionRow from './SettingsConditionRow'
import css from './svg.module.css'

/**
 * Таблица с настройками видимости элементов схемы
 */
const SettingsVisibility = (props: Props) => {
  const intl = useIntl()

  const data = useSelector((state: ReduxState) => {
    return {
      parameters: selectDenormalizedParametersMap(state),
      prefixes: state.parameters.prefixes,
      units: state.parameters.units,
    }
  })

  const handleRemove = React.useCallback((index: number) => props.onRemove('visibility', index), [props.onRemove])

  const handleChange = React.useCallback(
    (key: string, value: any, index: number) => props.onChange('visibility', index, key, value),
    [props.onChange]
  )

  const actionOptions = React.useMemo(
    () => [
      { value: 'hide', title: intl.formatMessage({ id: 'dashboards.svg_diagram.settings.action.hide' }) },
      { value: 'blink', title: intl.formatMessage({ id: 'dashboards.svg_diagram.settings.action.blink' }) },
    ],
    []
  )

  const rows = (props.settings.visibility || []).map((row, index) => {
    return (
      <SettingsConditionRow
        svg={props.svg}
        actionOptions={actionOptions}
        deviceOptions={props.deviceOptions}
        index={index}
        key={index}
        nodeOptions={props.nodeOptions}
        onChange={handleChange}
        onNodeHover={props.onHover}
        onRemove={handleRemove}
        operatorOptions={operatorOptions}
        parameter={data.parameters.get(row.parameter_id)}
        parameterOptions={props.parameterOptions}
        prefixes={data.prefixes}
        row={row}
        type="visibility"
        units={data.units}
      />
    )
  })

  return (
    <div className={css.settingsTableWrapper}>
      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.action' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.node_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.device_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.parameter_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.condition' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.visibility.value' })}</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <PlusButton textId="common.add" onClick={() => props.onAdd('visibility')} style={{ marginLeft: 0 }} />
    </div>
  )
}

interface Props {
  svg: SVGSVGElement
  settings: ISVGSettings
  nodeOptions: SelectOption[]
  deviceOptions: SelectOption[]
  parameterOptions: SelectOption[]
  onAdd: (key: string) => void
  onRemove: (key: string, index: number) => void
  onChange: (key: string, index: number, prop: string, value: any) => void
  onHover: (node: string) => void
}

export default SettingsVisibility
