import Loader from '../../../shared/Utils/Loader'

const TableLoader = (props: Props) => {
  return (
    <tr>
      <td colSpan={props.columns} style={{ textAlign: 'center', padding: '1rem 0' }}>
        <Loader />
      </td>
    </tr>
  )
}

interface Props {
  columns: number
}

export default TableLoader
