import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import GanttTable from '../../shared/GanttTable/GanttTable'
import HistoryToggle from '../../shared/Utils/HistoryToggle'
import { PageMode } from '../../shared/interfaces'
import ChartPlayer from '../ChartPlayer/ChartPlayer'
import { Equipment } from 'au-nsi/equipment'
import { selectReceivers } from '../Nsi/nsi.selectors'
import { ChartMode } from './vector.reducers'
import { actions } from './vector.reducers'
import { getMatchedDevices, getSelectedDevices } from './vector.selectors'
import VectorDiagrams from './VectorDiagrams'
import VectorTable from './VectorTable'

import './vector.styles.css'

/**
 * Вся страница несимметрии, включающая диаграмму ганта, таблицу и графики
 */
class VectorPage extends Component<Props> {
  private changeMode = (mode: PageMode) => {
    this.props.dispatch(actions.setPageMode(mode))
  }

  private handleSearch = (s: string) => {
    this.props.dispatch(actions.setSearch(s))
  }

  private handleSelect = (e: any) => {
    const id = e.target.dataset.id
    this.props.dispatch(actions.setRegistrator(id))
  }

  private renderTable = () => {
    return (
      <VectorTable
        equipment={this.props.registrators}
        equipmentMatch={this.props.matchedRegistrators}
        selectedDeviceId={this.props.registratorId}
        onSelect={this.handleSelect}
        onSearch={this.handleSearch}
      />
    )
  }

  private renderGantt = () => {
    return (
      <GanttTable
        page="unbalance"
        registrators={this.props.matchedRegistrators}
        onSelectRegistrator={this.handleSelect}
        selectedId={this.props.registratorId}
        search={this.props.search}
        onSearch={this.handleSearch}
      />
    )
  }

  render() {
    const { selectedRegistrators, mode, chartMode } = this.props

    return (
      <React.Fragment>
        <ChartPlayer page="unbalance" isFrameControlsHidden={chartMode === 'vector'} title={this.props.title || ''}>
          <HistoryToggle mode={this.props.mode} onChange={this.changeMode} />
        </ChartPlayer>
        <div className="pq">
          {mode === 'history' ? this.renderGantt() : this.renderTable()}
          <VectorDiagrams selectedRegistrators={selectedRegistrators} />
        </div>
      </React.Fragment>
    )
  }
}

interface Props {
  registratorId: string
  registrators: Equipment[]
  matchedRegistrators: Equipment[]
  selectedRegistrators: Equipment[]
  search: string
  dispatch: (action: any) => any
  title: string
  mode: PageMode
  chartMode: ChartMode
}

function mapStateToProps(state: ReduxState) {
  const title = state.dashboards.dashboards.find((d) => d.id === 'unbalance')?.name

  return {
    registrators: selectReceivers(state),
    matchedRegistrators: getMatchedDevices(state),
    selectedRegistrators: getSelectedDevices(state),
    ...state.vector,
    title,
  }
}

export default connect(mapStateToProps)(VectorPage)
