import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectTimeUnits } from '../../pages/Parameters/params.selectors'
import { selectTimeUnit } from '../../pages/Parameters/params.utils'
import Dropdown from './Dropdown'
import NumberInput from './NumberInput'
import css from './inputs.module.css'
import { SiUnit } from 'au-nsi/parameters'

/**
 * Компонент для задания интервала времени (1 секунда, 30 минут и т.д.)
 */
const DurationInput = (props: Props) => {
  const ref = useRef<HTMLDivElement>()
  const units = useSelector(selectTimeUnits)

  const { disabled } = props
  const seconds = props.value / 1000

  const [value, setValue] = React.useState(seconds)
  const [unit, setUnit] = React.useState(units.find((u) => u.id === 's'))

  const handleChange = (v: number, u: SiUnit) => {
    const sec = u && u.base_unit_factor ? v * u.base_unit_factor : v
    props.onChange(1000 * sec, props.name)
  }

  const handleValueChange = (v: number) => {
    handleChange(v, unit)
    setValue(v)
  }

  const handleUnitChange = (id: string) => {
    const nextUnit = units.find((u) => u.id === id)
    handleChange(value, nextUnit)
    setUnit(nextUnit)
  }

  // перерасчет единиц измерения
  React.useLayoutEffect(() => {
    // если фокус находится в одном из полей (т.е. пользователь все еще редактирует
    // значение или единицу измерения), то не выполняем перерасчет
    if (ref.current && ref.current.contains(document.activeElement)) return

    const baseUnit = selectTimeUnit(seconds, units)
    if (!baseUnit) return

    const baseValue = baseUnit.base_unit_factor ? seconds / baseUnit.base_unit_factor : seconds
    setUnit(baseUnit)
    setValue(baseValue)
  }, [units, props.value])

  const filterUnits = (array: { value: string; title: string }[], minUnit: string) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === minUnit) {
        return array.slice(0, i + 1)
      }
    }

    return array
  }

  const defaultUnits = units.map((u) => ({ value: u.id, title: u.symbol }))

  const unitOptions = props.minUnit ? filterUnits(defaultUnits, props.minUnit) : defaultUnits

  return (
    <div className={css.durationInput} ref={ref}>
      <NumberInput name="value" type="number" value={value} onChange={handleValueChange} disabled={disabled} min={0} />
      <Dropdown name="unit" value={unit?.id} options={unitOptions} onChange={handleUnitChange} disabled={disabled} />
    </div>
  )
}

interface Props {
  name: string
  value: number
  minUnit?: string
  disabled?: boolean
  onChange: (value: number, name: string) => void
}

export default DurationInput
