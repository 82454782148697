import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import ws from '../../services/ws/ws'
import HelpTooltip from '../../shared/HelpTooltip/HelpTooltip'
import { formatDate } from '../../utils/lang'

const size = 30
const r = size / 2
const viewBox = `0 0 ${size} ${size}`

const transformOrigin = `${r}px ${r}px`
const style: CSSProperties = { transformOrigin, stroke: 'currentColor', strokeWidth: 2, strokeLinecap: 'round' }

const ticks = []

for (let i = 0; i < 12; i++) {
  ticks.push(
    <line
      key={i}
      x1={r}
      x2={r}
      y1="0"
      y2="4"
      stroke="currentColor"
      strokeWidth={1.5}
      style={{ transformOrigin, transform: `rotate(${(i * 360) / 12}deg)` }}
    />
  )
}

const ClockIcon = () => {
  const intl = useIntl()
  const hourHand = useRef<SVGLineElement>()
  const minuteHand = useRef<SVGLineElement>()

  const [now, setNow] = useState(new Date())
  const [delta, setDelta] = useState(0)

  // подсветка если расхождение локального времени с сервером превышает несколько секунд
  const color = delta > 30_000 ? 'var(--danger-text)' : delta > 3_000 ? 'var(--warning)' : 'var(--color)'

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000)
    const unsubscribe = ws.timesync$.subscribe(({ timeDelta }) => setDelta(Math.abs(timeDelta)))

    return () => {
      clearInterval(interval)
      unsubscribe()
    }
  }, [])

  const hours = now.getHours() % 12
  const minutes = now.getMinutes()
  const icon = (
    <svg width={size} height={size} viewBox={viewBox} style={{ color }}>
      <circle r={2} cx={r} cy={r} fill="currentColor" />
      <line
        ref={hourHand}
        x1={r}
        x2={r}
        y1={r}
        y2={8}
        style={{ ...style, transform: `rotate(${((hours + minutes / 60) * 360) / 12}deg)` }}
      />
      <line
        ref={minuteHand}
        x1={r}
        x2={r}
        y1={r}
        y2={2}
        style={{ ...style, transform: `rotate(${(minutes * 360) / 60}deg)` }}
      />
      {ticks}
    </svg>
  )

  return (
    <HelpTooltip position="left" content={icon} style={{ margin: 0, padding: 0 }}>
      <div>{formatDate(now, 'dd MMM yyyy HH:mm:ss')}</div>
      {delta > 1000 && <div>{intl.formatMessage({ id: 'ChartPlayer.server_delta' }, { seconds: delta / 1000 })}</div>}
    </HelpTooltip>
  )
}

export default ClockIcon
