import { Equipment } from 'au-nsi/equipment'
import { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ReactComponent as MapIcon } from '../../../icons/map.svg'
import { ReactComponent as GroupIcon } from '../../../icons/network.svg'
import * as searchUtils from '../../../utils/search'
import GroupsLoader from '../Groups/components/GroupsLoader'
import useDeviceGroup from '../Groups/hooks/useDeviceGroup'
import { recoilPlacePanelState } from '../MapLeftPanel/PlacePanel/PlacePanel'
import { recoilSelectedPlaceState } from '../MapTopology'
import styles from '../maptopology.module.css'
import DeviceState from './DeviceState'

interface IProps {
  device: Equipment
  isSelected: boolean
  onClick?: (id: Equipment['id']) => void
  style?: CSSProperties
  searchPattern?: string
}

/**
  Компонент карточки устройства в списке в карте устройств
  Умеет сообщать о клике по себе, показывать свой статус и подсвечивать результаты поиска (если таковые есть)
*/
const DeviceTile = ({ device, style, onClick, isSelected, searchPattern }: IProps) => {
  const intl = useIntl()
  const group = useDeviceGroup(device.id)
  const [selectedGroup, setSelectedGroup] = useRecoilState(recoilPlacePanelState)
  const [selectedPlace, setSelectedPlace] = useRecoilState(recoilSelectedPlaceState)

  const handleMapClick = (e) => {
    stopEvent(e)
    onClick(device.id)
  }

  const handleGroupClick = (e) => {
    stopEvent(e)

    if (group) {
      setSelectedPlace(group.address)
      setSelectedGroup({ panelMode: 'groups', selectedGroupId: group.id })
    }
  }

  const renderDescription = () => {
    if (onClick) {
      let address = searchPattern ? renderSearchResult(device.address.name || '', searchPattern) : device.address.name
      if (!address) address = noAddressMessage
      return address
    }

    if (device.protocol === 'LP001' && device.attributes.entrance) {
      return intl.formatMessage({ id: 'nsi.lp001.entrance' }) + ' ' + device.attributes.entrance
    }

    return device.notes || intl.formatMessage({ id: 'map.device.no_notes' })
  }

  const renderIcon = () => {
    const mapIcon = !selectedPlace && (
      <MapIcon
        className={styles.deviceTileMapIcon}
        onClick={handleMapClick}
        style={isSelected ? { color: 'var(--primary-90)' } : {}}
      />
    )

    const groupIcon = (
      <GroupsLoader showLoader={false}>
        {!selectedGroup.selectedGroupId && group && (
          <GroupIcon
            onClick={handleGroupClick}
            className={styles.deviceTileGroupIcon}
            title={group?.name}
            style={{ right: selectedPlace ? undefined : '4em' }}
          />
        )}
      </GroupsLoader>
    )

    if (onClick) {
      return (
        <>
          {groupIcon}
          {mapIcon}
        </>
      )
    }

    if (group) return groupIcon
    return null
  }

  const noAddressMessage = intl.formatMessage({ id: 'nsi.no_address' })

  let wrapperStyle = styles.deviceTile
  if (isSelected) wrapperStyle += ' ' + styles.deviceTileSelected

  return (
    <div className={wrapperStyle} style={style}>
      <div className={styles.deviceTileContent}>
        <div className={styles.deviceTileUserInfo}>
          <h2 className={styles.deviceTileName + ' line_clamp'} title={device.name}>
            {searchPattern ? renderSearchResult(device.name, searchPattern) : device.name}
          </h2>
          <span className={styles.deviceTileDescription}>{renderDescription()}</span>
        </div>
        <div className={styles.deviceTileSystemInfo}>
          <DeviceState device={device} />
        </div>
      </div>
      {renderIcon()}
    </div>
  )
}

const LinkDeviceTile = (props: IProps) => {
  return (
    <Link style={props.style} className={styles.deviceTileLink} to={`/map/device/${props.device.id}/main`}>
      <DeviceTile {...props} style={null} />
    </Link>
  )
}

const stopEvent = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

const renderSearchResult = (text: string, pattern: string) => {
  const result = searchUtils.search(text, pattern.toLowerCase().split(' '))
  return result.length > 0 ? searchUtils.renderSearchResult(result) : text
}

export default LinkDeviceTile
