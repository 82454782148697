import DarkBlue from 'icons/widgets/widget-placeholder-dark-blue.svg'
import White from 'icons/widgets/widget-placeholder-white.svg'
import { ThemeContext } from 'pages/App/Theme/ThemeProvider'
import { useContext } from 'react'

const DashboardPlaceHolder = () => {
  const theme = useContext(ThemeContext)
  return theme === 'white' ? White : DarkBlue
}

export default DashboardPlaceHolder
