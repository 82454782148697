import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useHttpLoader from '../../../../../../hooks/useHttpLoader'
import SearchInput from '../../../../../../shared/Inputs/Search/SearchInput'
import Modal from '../../../../../../shared/Modal/Modal'
import ModalFooter from '../../../../../../shared/Modal/ModalFooter'
import http from '../../../../../../utils/http'
import { highlightSearch } from '../../../../../../utils/search'

/**
 * Модальное окно для выбора пользователя для делегации заявки
 */
const RequestAssignModal = ({ requestId, onClose }: Props) => {
  const intl = useIntl()
  const { loading, wait } = useHttpLoader()

  const [search, setSearch] = useState('')
  const [candidates, setCandidates] = useState<Candidate[]>([])
  const [selection, setSelection] = useState<Candidate>()

  useEffect(() => {
    const r = http.get(`/nsi/v1/moderation_requests/${requestId}/candidates`)
    wait(r, (resp) => setCandidates(resp.data))
  }, [])

  const handleClick = (e) => {
    const id = +e.currentTarget.dataset.id
    const candidate = candidates.find((c) => c.id === id)
    setSelection(candidate)
  }

  const handleSave = () => {
    if (!selection) return

    const r = http.post(`/nsi/v1/moderation_requests/${requestId}/assign/${selection.id}`)
    wait(r, () => onClose())
  }

  const searchTerms = search ? search.split(/\s+/g) : null

  let rows = candidates.map((c) => {
    const style = selection && selection.id === c.id ? { backgroundColor: 'var(--selection-80)' } : undefined

    const nameMatch = highlightSearch(c.name, searchTerms)
    const roleMatch = highlightSearch(c.role, searchTerms)
    const orgMatch = highlightSearch(c.organization || '', searchTerms)
    if (search && !nameMatch.isMatch && !roleMatch.isMatch && !orgMatch.isMatch) return null

    return (
      <tr key={c.id} data-id={c.id} onClick={handleClick} style={style}>
        <td>{nameMatch.result}</td>
        <td>{roleMatch.result}</td>
        <td>{orgMatch.result}</td>
      </tr>
    )
  })

  rows = rows.filter((row) => row != null)

  let title = intl.formatMessage({ id: 'user.request.assign' })
  if (selection) title += `: ${selection.name}`

  return (
    <Modal size="lg" onClose={onClose}>
      <h2>{title}</h2>

      <SearchInput onChange={(v) => setSearch(v.toLowerCase().trim())} />

      {rows.length > 0 && (
        <div style={{ maxHeight: '60vh', overflowY: 'auto', marginTop: 12 }}>
          <table className="nsi-table is-compact is-fixed" style={{ cursor: 'pointer' }}>
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'user.request.assign.name' })}</th>
                <th>{intl.formatMessage({ id: 'user.request.assign.role' })}</th>
                <th>{intl.formatMessage({ id: 'user.request.assign.org' })}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      )}

      {rows.length === 0 && !loading && (
        <div className="text--center text--gray" style={{ margin: '1em' }}>
          {intl.formatMessage({ id: 'common.no_results' })}
        </div>
      )}

      <ModalFooter
        loading={loading}
        disabled={!selection}
        onCancel={onClose}
        onSave={handleSave}
        saveMessage={intl.formatMessage({ id: 'common.apply' })}
      />
    </Modal>
  )
}

interface Candidate {
  id: number
  role_id: number
  organization_id?: number
  name: string
  role: string
  organization?: string
}

interface Props {
  requestId: number
  onClose: () => void
}

export default RequestAssignModal
