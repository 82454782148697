import { IDashboardComponent } from 'au-nsi/dashboards'
import { ReactComponent as Gantt } from 'icons/widgets/alerts.svg'
import GaudeLinear from 'icons/widgets/angle_scale.svg'
import { ReactComponent as Button } from 'icons/widgets/button.svg'
import { ReactComponent as Histogram } from 'icons/widgets/histogram.svg'
import { ReactComponent as Image } from 'icons/widgets/image.svg'
import { ReactComponent as Indicator } from 'icons/widgets/indicator.svg'
import { ReactComponent as LinearChart } from 'icons/widgets/linear_chart.svg'
import { ReactComponent as Gaude } from 'icons/widgets/linear_scale.svg'
import { ReactComponent as Map } from 'icons/widgets/map.svg'
import { ReactComponent as PhasePortrait } from 'icons/widgets/phase_portrait.svg'
import { ReactComponent as SvgDiagram } from 'icons/widgets/scheme.svg'
import { ReactComponent as Table } from 'icons/widgets/table.svg'
import { ReactComponent as Text } from 'icons/widgets/text.svg'
import VectorChart from 'icons/widgets/vector_chart.svg'
import { ReactComponent as Windrose } from 'icons/widgets/windrose.svg'

export const Icons = (type: IDashboardComponent['type']) => {
  switch (type) {
    case 'linear_chart':
      return <LinearChart />
    case 'histogram':
      return <Histogram />
    case 'gauge_linear':
      return <Gaude />
    case 'gauge':
      return <img alt={''} src={GaudeLinear} />
    case 'vector_chart':
      return <img alt={''} src={VectorChart} />
    case 'windrose':
      return <Windrose />
    case 'phase_portrait':
      return <PhasePortrait />
    case 'bar_chart':
      return <Histogram />
    case 'gantt':
      return <Gantt />
    case 'map':
      return <Map />
    case 'svg_diagram':
      return <SvgDiagram />
    case 'indicator':
      return <Indicator />
    case 'button':
      return <Button />
    case 'image':
      return <Image />
    case 'table':
      return <Table />
    case 'text':
      return <Text />
    case 'template_variables':
      return <Button />
    default:
      return unreachable(type)
  }
}

function unreachable(_: never): never {
  throw new Error(`Unexpected component type`)
}
