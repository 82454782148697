import { IPhasePortraitSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import { ReduxState } from '../../../redux/store.types'
import DataService from '../../../services/data/data.service'
import { selectLastDefinedPoint } from '../../../services/data/select.utils'
import { Boundaries } from '../../../shared/interfaces'
import { drawLine, drawLoader } from '../../../utils/canvas.utils'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { formatValue } from '../../Parameters/params.utils'
import './portrait.styles.css'
import * as utils from './portrait.utils'
import PortraitGrid from './PortraitGrid'

const margins = { top: 40, right: 50, bottom: 24, left: 24 }

const PhasePortrait = (props: Props) => {
  const { settings } = props

  const service = React.useMemo(() => new DataService(props.id), [])
  useDataRate(service)

  const parameters = useSelector(selectDenormalizedParametersMap)
  const equipment = useSelector((state: ReduxState) => state.nsi.equipment)

  const xParameter = parameters.get(settings.x_parameter_id)
  const yParameter = parameters.get(settings.y_parameter_id)

  const $wrapper = React.useRef<HTMLDivElement>()
  const $canvas = React.useRef<HTMLCanvasElement>()

  const cursor = React.useRef<{ x: number; y: number }>(null) // координаты курсора мыши
  const coords = React.useRef<utils.IHead[]>([]) // координаты точек с текущими значениями параметров
  const selection = React.useRef({ id: null, clicked: false }) // выбранное устройство
  const tooltip = React.useRef({ x: '', y: '', ts: 0 }) // данные и время последнего обновления тултипа

  const [boundaries, setBoundaries] = React.useState<Boundaries>({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 100,
    height: 100,
  })

  const innerWidth = boundaries.width - margins.left - margins.right
  const innerHeight = boundaries.height - margins.top - margins.bottom

  // обработка изменения размера родительского контейнера
  const onResize = () => setBoundaries($wrapper.current.getBoundingClientRect())
  React.useEffect(onResize, [props.lastResize])

  // очистка состояния перед удалением компонента
  React.useEffect(() => {
    service.setPointsPerFrame(200)
    return () => service.destroy()
  }, [])

  // указание данных которые нужны компоненту при изменении его настроек
  React.useEffect(() => {
    const selectors = settings.equipment.map((device_id) => {
      return { device_id, parameters: [settings.x_parameter_id, settings.y_parameter_id] }
    })

    service.setMsPerFrame(settings.timespan)
    service.setDataSelectors(selectors)
  }, [settings])

  // список устройств указанных в настройках компонента
  const devices = React.useMemo(() => {
    return equipment
      .filter((e) => settings.equipment.includes(e.id))
      .map((e) => ({ id: e.id, color: e.color, name: e.shortname, shortname: e.shortname.replace(/\D/g, '') }))
  }, [settings.equipment, equipment])

  // подписка на тики времени и отрисовка данных
  React.useEffect(() => {
    const ctx = $canvas.current.getContext('2d')
    ctx.lineWidth = 2
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = '13px Roboto'

    const xScaleFactor = innerWidth / (settings.x_max - settings.x_min)
    const yScaleFactor = innerHeight / (settings.y_max - settings.y_min)
    const xScale = (v: number) => xScaleFactor * (v - settings.x_min)
    const yScale = (v: number) => yScaleFactor * (settings.y_max - v)

    // отрисовка графика
    const onTick = () => {
      ctx.clearRect(0, 0, innerWidth, innerHeight)

      if (service.isLoading) {
        drawLoader(ctx, innerWidth)
      }

      // если мышь наведена на график и при этом не зафиксировано какое-либо устройство, то рисуем курсор под мышью
      if (cursor.current && !selection.current.id) {
        utils.drawCursor(ctx, cursor.current, { width: innerWidth, height: innerHeight })
      }

      const t1 = service.clock.getPlayerTime()
      const t0 = t1 - settings.timespan

      const heads: utils.IHead[] = []
      coords.current = heads
      // координаты точки выбранного устройства (на который либо просто навели мышь, либо кликнули)
      let selected: utils.IHead = null

      // отрисовка "хвостов"
      for (const e of devices) {
        const chunks = service.selectRange({
          deviceId: e.id,
          parameterId: settings.x_parameter_id,
          t0,
          t1,
          allowOverlap: true,
          includePrediction: false,
        })

        const range = chunks.map((chunk) => {
          return {
            i0: chunk.i0,
            i1: chunk.i1,
            x: chunk.data[settings.x_parameter_id],
            y: chunk.data[settings.y_parameter_id],
          }
        })

        const headPoint = selectLastDefinedPoint(chunks, [settings.x_parameter_id, settings.y_parameter_id])

        ctx.strokeStyle = e.color
        drawLine({ ctx, chunks: range, xScale, yScale })

        // рассчитываем и сохраняем координаты маркера "головы", но рисуем только после отрисовки всех хвостов
        // для того чтобы не было перекрытия
        if (headPoint) {
          const headX = xScale(headPoint[settings.x_parameter_id])
          const headY = yScale(headPoint[settings.y_parameter_id])
          const head = { x: headX, y: headY, id: e.id, color: e.color, name: e.name, shortname: e.shortname }
          heads.push(head)

          if (head.id === selection.current.id) selected = head
        }
      }

      for (const head of heads) {
        utils.drawHead(ctx, head)
      }

      // маркер выбранного устройства рисуем еще раз поверх всего остального
      if (selected) utils.drawHead(ctx, selected)

      // при наличии курсора либо при фиксации фокуса на каком-либо устройстве выводим тултип со значениями параметров
      if (cursor.current != null || selected != null) {
        const now = Date.now()
        const source = selected || cursor.current
        const color = selected ? selected.color : null
        const title = selected ? selected.name : null

        // замедляем скорость перерисовки значений тултипа, т.к. при перерисовке раз в 16мс они мелькают слишком быстро
        if (now - tooltip.current.ts > 120) {
          // значения параметров под курсором получаем обратным преобразованием из его координат на странице
          const xValue = settings.x_min + (source.x * (settings.x_max - settings.x_min)) / innerWidth
          const yValue = settings.y_max - (source.y * (settings.y_max - settings.y_min)) / innerHeight
          tooltip.current.x = `X: ${formatValue(xValue, xParameter)}`
          tooltip.current.y = `Y: ${formatValue(yValue, yParameter)}`
          tooltip.current.ts = now
        }

        const { x, y } = tooltip.current
        utils.drawTooltip(ctx, { title, color, x, y })
      }
    }

    service.onTick = onTick
    onTick()
  }, [settings, boundaries, devices, xParameter, yParameter])

  // обработка движения мыши по графику и кликов
  const handleMouse = (e: React.MouseEvent) => {
    const isClick = e.type === 'click'
    // если зафиксировано устройство то при движении мыши ничего не делаем
    if (!isClick && selection.current.clicked) return

    const x = e.pageX - boundaries.left - margins.left
    const y = e.pageY - boundaries.top - margins.top

    cursor.current = { x, y }
    service.markForUpdate()

    // поиск ближайшего к мыши маркера
    for (let i = coords.current.length - 1; i >= 0; i -= 1) {
      const coord = coords.current[i]
      const dx = x - coord.x
      const dy = y - coord.y
      const d2 = dx * dx + dy * dy

      if (d2 < 144) {
        // найден маркер попадающий под мышь - фиксируем на нем выделение
        selection.current.id = coord.id
        selection.current.clicked = isClick
        document.body.style.cursor = isClick ? 'default' : 'pointer'
        return
      }
    }

    // при клике на пустое место либо при отведении мыши от маркера убираем выбор устройства
    selection.current.clicked = false
    selection.current.id = null
    document.body.style.cursor = 'default'
  }

  // скрываем курсор при выходе мыши за график
  const handleMouseLeave = () => {
    cursor.current = null
    service.markForUpdate()
  }

  return (
    <div ref={$wrapper} className="phase-portrait__wrapper">
      <div className="phase-portrait__title line_clamp" title={settings.title}>
        {settings.title}
      </div>

      <PortraitGrid
        boundaries={boundaries}
        margins={margins}
        settings={settings}
        xParameter={xParameter}
        yParameter={yParameter}
      />

      <canvas
        height={innerHeight}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouse}
        onClick={handleMouse}
        ref={$canvas}
        style={{ top: margins.top, left: margins.left }}
        width={innerWidth}
      />
    </div>
  )
}

interface Props {
  id: number
  lastResize: number
  settings: IPhasePortraitSettings
}

export default PhasePortrait
