import { IAccessSettings } from 'au-nsi/shared'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useHttpLoader from '../../hooks/useHttpLoader'
import { selectAccessRights } from '../../pages/App/app.selectors'
import { useAppSelector } from '../../redux/store'
import { handleHttpError } from '../../utils/http'
import { deepCopy } from '../../utils/misc'
import { showError } from '../../utils/notifications'
import Modal from '../Modal/Modal'
import ModalFooter from '../Modal/ModalFooter'
import SavedTemplateForm from './SavedTemplateForm'
import SavedTemplatesList from './SavedTemplatesList'
import * as api from './templates.api'
import { SavedTemplate, SavedTemplateType } from './templates.types'

interface Props<T extends SavedTemplate> {
  type: SavedTemplateType
  newTemplateData: T['data']
  onClose: () => void
  onSelect: (item: T) => void
}

/**
 * Модальное окно для выбора и управления сохраненными шаблоными (такими как закладки плеера
 * и шаблоны экспорта). Может работать с любым типом шаблонов, т.к. данные для создания нового
 * шаблона и действие при его выборе передаются через Props, а все остальные действия
 * используют общие для всех поля - id, name, access.
 */
const SavedTemplatesModal = <T extends SavedTemplate>({ type, newTemplateData, onClose, onSelect }: Props<T>) => {
  const intl = useIntl()

  const user = useAppSelector((state) => state.user)
  const rights = useAppSelector(selectAccessRights)
  const allowEditing = rights['saved_templates:' + type]

  const { loading, wait } = useHttpLoader()
  const [items, setItems] = useState<T[]>([])

  const [mode, setMode] = useState('view')
  const [selectedItem, setSelectedItem] = useState<T>()

  const validate = (item: T) => {
    const isDuplicate = items.find((e) => e.name === item.name && e.id !== item.id)
    if (isDuplicate) showError('saved_templates.errors.duplicate_name')

    return !isDuplicate
  }

  const handleEdit = (id: number) => {
    const item = items.find((e) => e.id === id)
    setSelectedItem(item)
    setMode('edit')
  }

  const handleCreate = () => {
    const access: IAccessSettings = { restricted: true, users: [user.id], roles: [], orgs: [] }
    setSelectedItem({ id: 0, name: '', data: deepCopy(newTemplateData), access } as any)
    setMode('create')
  }

  const handleEditSave = (item: T) => {
    if (!validate(item)) return
    setMode('view')

    const req = api.updateSavedTemplate(type, item.id, item)
    wait(req, (result) => {
      setItems((prev) => prev.map((e) => (e.id === result.id ? result : e)))
    })
  }

  const handleCreateSave = (item: T) => {
    if (!validate(item)) return
    setMode('view')

    const req = api.createSavedTemplate(type, item)
    wait(req, (result) => {
      setItems((prev) => [...prev, result])
    })
  }

  const handleDelete = (id: number) => {
    api.deleteSavedTemplate(type, id).catch(handleHttpError)
    setItems((prev) => prev.filter((e) => e.id !== id))
  }

  const handleSelect = (id: number) => {
    const item = items.find((e) => e.id === id)
    onSelect(item)
  }

  useEffect(() => {
    wait(api.listSavedTemplates(type).then((r) => setItems(r)))
  }, [])

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} closeOnEscape={false}>
      <h2>{intl.formatMessage({ id: `saved_templates.${type}.${mode}` })}</h2>

      {mode === 'view' && (
        <>
          <SavedTemplatesList
            items={items}
            allowEditing={allowEditing}
            loading={loading}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onSelect={handleSelect}
          />
          <ModalFooter
            disabled={!allowEditing}
            loading={loading}
            saveMessage={intl.formatMessage({ id: `saved_templates.${type}.create` })}
            onCancel={onClose}
            onSave={handleCreate}
          />
        </>
      )}

      {(mode === 'edit' || mode === 'create') && (
        <SavedTemplateForm
          type={type}
          item={selectedItem}
          onCancel={() => setMode('view')}
          onSave={mode === 'edit' ? handleEditSave : handleCreateSave}
        />
      )}
    </Modal>
  )
}

export default SavedTemplatesModal
