import { useIntl } from 'react-intl'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import css from './access.module.css'

const GroupMode = ({ value, search, onChange, onSearch }: Props) => {
  const intl = useIntl()

  return (
    <div className={css.groupMode}>
      <div className={value === 'roles' ? css.selectedGroupMode : undefined} onClick={() => onChange('roles')}>
        {intl.formatMessage({ id: 'access.group_mode.roles' })}
      </div>
      <div className={value === 'orgs' ? css.selectedGroupMode : undefined} onClick={() => onChange('orgs')}>
        {intl.formatMessage({ id: 'access.group_mode.orgs' })}
      </div>
      <div
        className={search ? css.selectedGroupMode : undefined}
        onClick={() => onSearch(!search)}
        style={{ padding: '0 6px', marginLeft: '12px', borderRadius: 2 }}
      >
        <SearchIcon width="16" height="16" />
      </div>
    </div>
  )
}

interface Props {
  value: 'roles' | 'orgs'
  search: boolean
  onChange: (value: 'roles' | 'orgs') => void
  onSearch: (value: boolean) => void
}

export default GroupMode
