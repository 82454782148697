import React from 'react'
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg'

export default class TimeInput extends React.PureComponent<TimeInputProps> {
  private input = React.createRef<HTMLDivElement>()

  private get value() {
    return parseInt(this.input.current.innerText, 10) || 0
  }

  componentDidMount() {
    this.setInnerText()
  }

  componentDidUpdate() {
    this.setInnerText()
  }

  private setInnerText() {
    this.input.current.innerText = this.props.time.toString().padStart(2, '0')
  }

  private increment = () => {
    this.props.onChange(this.props.time + 1)
  }

  private decrement = () => {
    this.props.onChange(this.props.time - 1)
  }

  private handleKeyDown = (e: React.KeyboardEvent) => {
    const isEnter = e.key === 'Enter'
    const isBackspace = e.key === 'Backspace'

    const code = e.key.charCodeAt(0)
    const isDigit = code >= 48 && code <= 57

    if (isEnter) {
      e.preventDefault()
      return this.props.onChange(this.value)
    }

    if (!isBackspace && !isDigit) {
      return e.preventDefault()
    }

    const value = 10 * this.value + code - 48

    if (isDigit && value > this.props.maxValue) {
      return e.preventDefault()
    }
  }

  private handleBlur = () => {
    const value = this.value

    if (value !== this.props.time) {
      this.props.onChange(value)
    }
  }

  private handleWheel = (e) => {
    const handler = e.deltaY > 0 ? this.decrement : this.increment
    handler()
  }

  render() {
    return (
      <div className="timepicker__item" onWheel={this.handleWheel}>
        <ArrowRight className="timepicker__control timepicker__control--next" onClick={this.increment} />
        <div
          contentEditable={true}
          ref={this.input}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          className="timepicker__input"
        />
        <ArrowRight className="timepicker__control timepicker__control--prev" onClick={this.decrement} />
      </div>
    )
  }
}

interface TimeInputProps {
  time: number
  maxValue: number
  onChange: (time: number) => void
}
