import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { FormMode } from '../../../../shared/interfaces'
import { selectAccessRights } from '../../../App/app.selectors'
import PrevButton from '../../../../shared/Utils/PrevButton'
import styles from '../../user.module.css'
import requestStyles from './request.module.css'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { approveRequest, patchUpdateRequest, requestDeleteRequest } from '../moderation.api'
import moderationActions from '../moderation.actions'
import RequestMessages from './RequestMessages/RequestMessages'
import RejectModal, { rejectModalState } from '../ModerationRequests/RejectModal'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { allowModeration } from '../moderation.utils'
import RequestForm from './RequestForm/RequestForm'
import RequestMeta from './components/RequestMeta'
import RequestPageControls from './components/RequestPageControls/RequestPageControls'
import { selectedDraftRequestState } from './request.recoil'
import { ReduxState } from '../../../../redux/store.types'
import { useLinkHandler } from '../moderation.hooks'
import useRequestPageNav from './hooks/useRequestPageNav'
import NextButton from '../../../../shared/Utils/NextButton'
import { IModerationRequest } from 'au-nsi/moderation'
import Form from '../../../../shared/Forms/Form'
import { useNavigate } from 'react-router-dom'

interface IProps<T extends IModerationRequest> {
  selectedRequest: T
}

/**
 * Форма заявки на модерацию.
 *
 * ```
 * Main think компонента - контроль над {selectedDraftRequestState}, т.е:
 * - Обновление в случае, если пришёл апдейт по сокетам/из редакса и форма не редактируется
 * - Контролы управления заявкой и соответсвующие методы.
 *
 * Особенно стоит акцентировать внимание на том, что механизм работы дочерних форм (редактируется/не редактируется)
 * контролируется из текущего компонента:
 * {selectedDraftRequestState} может обновляться в дочерних компнентах каждой конкретной заявки {EquipmentRequestForm},
 * {CatalogRequestForm} и т.д. (в случае, если {mode} === 'editing'). Для этого им достаточно вызвать хук {useRecoilState}
 * и получить драфтовый IModerationRequest с его сеттером.
 *
 * Такое решение необходимо для корректной работы мемоизации форм справочников и их инстансов.
 * ```
 * */
const RequestPageForm = <T extends IModerationRequest>({ selectedRequest }: IProps<T>) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, wait } = useHttpLoader()
  const { prevPage, nextPage, prevId, nextId } = useRequestPageNav(selectedRequest.id)

  const rejectRequest = useSetRecoilState(rejectModalState)
  const [request, setRequest] = useRecoilState(selectedDraftRequestState)
  const userId = useSelector((state: ReduxState) => state.user.id)

  const [mode, setMode] = useState<FormMode>('view')
  const rights = useSelector(selectAccessRights)

  const isModerator = allowModeration(userId, rights, selectedRequest)
  const isCreator = selectedRequest.created_by === userId
  const allowEditing = isModerator || isCreator

  const handleHeaderClick = useLinkHandler()

  useEffect(() => {
    return () => setRequest(null)
  }, [])

  // при изменении заявки синхронизируем драфт
  useEffect(() => {
    // в режиме редактирования оставляем текущие изменения в теле запроса
    // и обновляем только метаданные заявки
    const body = mode === 'view' ? selectedRequest.request_body : request.request_body
    setRequest({ ...selectedRequest, request_body: body as any })
  }, [selectedRequest])

  // выставление драфта равным самой заявке при переходе в режим просмотра
  useEffect(() => {
    if (mode === 'view') setRequest(selectedRequest)
  }, [mode])

  useEffect(() => {
    if (!loading && mode !== 'delete') setMode('view')
  }, [loading])

  if (!request) return null

  const handleUpdate = () => {
    wait(patchUpdateRequest(request), (resp) => {
      if (!resp) return

      setRequest(resp)
      dispatch(moderationActions.requestUpdated(resp))
    })
  }

  const handleDelete = () => {
    wait(requestDeleteRequest(request.id), (resp) => {
      if (!resp) return
      navigate(-1)

      dispatch(moderationActions.requestRemoved(resp.id))
    })
  }

  const handleApprove = () => {
    if (loading) return

    wait(approveRequest(request), (resp) => {
      if (!resp) return

      setRequest({ ...request, moderated_at: Date.now(), moderated_by: userId })
      dispatch(moderationActions.requestUpdated(resp))
    })
  }

  const handleAccessChange = (req: IModerationRequest) => {
    wait(patchUpdateRequest(req), (resp) => {
      if (!resp) return

      setRequest(resp)
      dispatch(moderationActions.requestUpdated(resp))
    })
  }

  const handleReject = () => rejectRequest(selectedRequest)

  return (
    <Form editing={mode === 'edit'} onSubmit={handleUpdate}>
      <div>
        <div className={styles.requestDetails}>
          <div className={styles.requestInfo}>
            {(prevPage || nextPage) && (
              <div className={styles.requestPageNav}>
                <PrevButton
                  disabled={!prevId}
                  onCLick={prevPage}
                  textId={intl.formatMessage({ id: 'user.request.go' }) + (prevId ?? selectedRequest.id + 1).toString()}
                />
                <span onClick={() => navigate('/user/requests/')} className={requestStyles.toList}>
                  {intl.formatMessage({ id: 'user.request.to_list' })}
                </span>
                <NextButton
                  disabled={!nextId}
                  onCLick={nextPage}
                  textId={intl.formatMessage({ id: 'user.request.go' }) + (nextId ?? selectedRequest.id - 1).toString()}
                />
              </div>
            )}
            <div className={styles.requestPageNameBLock}>
              <h2
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: `<span class='text--gray'>№${request.id} — </span>` + request.description[intl.locale],
                }}
                onClick={handleHeaderClick}
              />
            </div>
            <div className={styles.requestPageHead}>
              <RequestMeta request={request} />
              <RequestPageControls
                loading={loading}
                mode={mode}
                onEdit={() => setMode('edit')}
                onCancel={() => setMode('view')}
                onApprove={handleApprove}
                onReject={handleReject}
                onDelete={handleDelete}
                onAccessChange={handleAccessChange}
                allowEditing={allowEditing}
                allowDeleting={isModerator}
                allowAssignment={isModerator}
              />
            </div>
          </div>
          <h2>{intl.formatMessage({ id: 'common.details' })}</h2>
          <div className={requestStyles.requestFormWrapper}>
            <RequestForm editing={mode === 'edit'} />
          </div>
        </div>
        <RequestMessages request={request} />
        <RejectModal />
      </div>
    </Form>
  )
}

export default RequestPageForm
