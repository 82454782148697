import { useState } from 'react'
import styles from './documentsPanel.module.css'
import { openFile } from '../../docs.utils'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import DocumentsEditingList from './DocumentsLists/DocumentsEditingList'
import DocumentsList from './DocumentsLists/DocumentsList'
import SelectFilesModal from '../FileModals/SelectFilesModal'
import { IDocument } from 'au-nsi/documents'

interface IProps {
  resource: IDocument['resource']
  resource_item_id: IDocument['resource_item_id']
  editing: boolean
  name?: string
  documents: IDocument[]
  onChange: (docs: Partial<IDocument>[], name?: string) => void
  requestAccess?: boolean
}

/**
  Панель документов, позволяет удалять и добавлять новые докуметы.

  Возвращает весь список документов в onChange после добавления/удаления.
*/
const DocumentsPanel = ({
  resource,
  resource_item_id,
  editing,
  name,
  onChange,
  documents,
  requestAccess = true,
}: IProps) => {
  const token = useSelector((state: ReduxState) => state.auth.accessToken)

  const [files, setFiles] = useState<FileList>(null)

  const handleChange = <T extends keyof IDocument>(value: IDocument[T], key: T, index: number) => {
    onChange([...documents.slice(0, index), { ...documents[index], [key]: value }, ...documents.slice(index + 1)], name)
  }

  const handleUploadStart = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.addEventListener('change', () => setFiles(input.files), { once: true })
    input.click()
  }

  const handleSubmit = (docs: Partial<IDocument>[]) => {
    onChange([...documents, ...docs], name)
    setFiles(null)
  }

  const handleRemove = (index: number) => {
    onChange([...documents.slice(0, index), ...documents.slice(index + 1)], name)
  }

  const handleDocumentClick = (doc: Partial<IDocument>) => {
    if (doc.id) return openFile(doc as IDocument, token)

    const data_url = `data:${doc.type};base64,` + doc.content
    const a = document.createElement('a')

    a.href = data_url
    a.download = doc.name + '.' + doc.extension
    a.type = doc.type
    a.click()
  }

  return (
    <div className={styles.documentsList}>
      {files && (
        <SelectFilesModal
          requestAccess={requestAccess}
          files={files}
          onClose={() => setFiles(null)}
          resource={resource}
          resource_item_id={resource_item_id}
          onSubmit={handleSubmit}
        />
      )}

      {editing ? (
        <DocumentsEditingList
          allowAccessChange={requestAccess}
          draftDocuments={documents}
          onDocumentClick={handleDocumentClick}
          onRemove={handleRemove}
          onChange={handleChange}
          onAdd={handleUploadStart}
        />
      ) : (
        <DocumentsList draftDocuments={documents} onDocumentClick={handleDocumentClick} />
      )}
    </div>
  )
}

export default DocumentsPanel
