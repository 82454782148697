import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg'
import { CSSProperties } from 'react'

const CollapseToggle = (props: Props) => {
  const style: CSSProperties = props.collapsed ? {} : { transform: 'rotate(90deg)' }

  return (
    <ArrowRight
      onClick={props.onToggle}
      width={props.size}
      height={props.size}
      style={{ ...style, transition: 'transform ease 0.2s', cursor: 'pointer' }}
    />
  )
}

interface Props {
  size: number
  collapsed: boolean
  onToggle: (e?: any) => void
}

export default CollapseToggle
