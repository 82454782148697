import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import { deepCopy } from '../../../utils/misc'
import { selectParameterOptions, selectParametersNames } from '../../Parameters/params.selectors'
import ImportModal from '../Modals/ImportModal'
import { updateParametersMapping } from '../nsi.actions'
import { OPCDAEquipment } from 'au-nsi/equipment'
import { Select } from '@alterouniversal/au-react-components'

interface Props {
  device: OPCDAEquipment
  allowEditing: boolean
}

/**
 * Форма маппинга тэгов на параметры системы для протокола OPC DA
 */
const OPCDAMapping = ({ device, allowEditing }: Props) => {
  const dispatch = useDispatch()
  const translations = useAppSelector((state) => state.language.translations)
  const equipment = useAppSelector((state) => state.nsi.equipment)
  const parameters = useAppSelector(selectParameterOptions)
  const parametersMap = useAppSelector(selectParametersNames)

  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)
  const [draft, setDraft] = useState(() => mapping2object(device.parameters_mapping))

  useEffect(() => {
    if (!editing) setDraft(mapping2object(device.parameters_mapping))
  }, [device.parameters_mapping])

  useEffect(() => {
    if (editing) handleCancel()
  }, [device.id])

  const handleChange = (parameter: string, tag: string) => setDraft((prev) => ({ ...prev, [tag]: parameter }))

  const handleCancel = () => {
    setDraft(mapping2object(device.parameters_mapping))
    setEditing(false)
  }

  const handleSave = () => {
    dispatch(updateParametersMapping(device.id, object2mapping(draft)))
    setEditing(false)
  }

  const importSources = useMemo(
    () => equipment.filter((e) => e.protocol === 'OPCDA' && e.parameters_mapping.length > 0),
    [equipment]
  )

  const handleImport = (id: string) => {
    const source = importSources.find((e) => e.id === id)
    const copy = deepCopy(source.parameters_mapping)
    dispatch(updateParametersMapping(device.id, copy))
  }

  const rows = device.configuration.tags.map(({ tag }, index) => {
    const parameter = draft[tag]
    const selectedOption = parameter ? { value: parameter, label: parametersMap.get(parameter) || parameter } : null

    const input = editing ? (
      <Select name={tag} options={parameters} value={parameter} disabled={!editing} onChange={handleChange} />
    ) : (
      selectedOption?.label
    )

    return (
      <tr key={index}>
        <td>{tag}</td>
        <td>{input}</td>
      </tr>
    )
  })

  return (
    <>
      <table className="nsi-table is-fixed">
        <thead>
          <tr>
            <th>{translations['equipment.parameters_mapping.tag']}</th>
            <th>{translations['equipment.parameters_mapping.parameter_id']}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <PanelButtons
        allowEditing={allowEditing}
        allowImport={allowEditing}
        editing={editing}
        onImport={() => setImporting(true)}
        onEdit={() => setEditing(true)}
        onCancel={handleCancel}
        onSave={handleSave}
      />
      {importing && (
        <ImportModal
          isOpen={importing}
          sources={importSources}
          onClose={() => setImporting(false)}
          onImport={handleImport}
          targetId={device.id}
        />
      )}
    </>
  )
}

const mapping2object = (mapping: OPCDAEquipment['parameters_mapping']) => {
  const result: Record<string, string> = {}
  for (const row of mapping) result[row.tag] = row.parameter_id

  return result
}

const object2mapping = (obj: Record<string, string>) => {
  const result: OPCDAEquipment['parameters_mapping'] = []
  for (const [tag, parameter_id] of Object.entries(obj)) result.push({ tag, parameter_id })

  return result
}

export default OPCDAMapping
