import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAttribute } from '../../../shared/Attributes/attributes.types'
import AttributesEditor from '../../../shared/Attributes/AttributesEditor'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import { selectAccessRights } from '../../App/app.selectors'
import { patchEquipment } from '../nsi.actions'

const AttributesPanel = (props: Props) => {
  const [attributes, setAttributes] = React.useState(props.attributes)
  const [editing, setEditing] = React.useState(false)

  const allowEditing = useSelector(selectAccessRights)['equipment:update']
  const dispatch = useDispatch()

  React.useEffect(() => {
    setAttributes(props.attributes)
  }, [props.attributes])

  const handleChange = (value: IAttribute[]) => {
    setAttributes(value)
  }

  const handleCancel = () => {
    setAttributes(props.attributes)
    setEditing(false)
  }

  const handleSave = () => {
    dispatch(patchEquipment(props.id, { attributes }))
    setEditing(false)
  }

  return (
    <React.Fragment>
      <div style={{ padding: '0 25px' }}>
        <AttributesEditor allowEditing={editing} attributes={attributes} onChange={handleChange} />
      </div>

      <PanelButtons
        editing={editing}
        allowEditing={allowEditing}
        onEdit={() => setEditing(true)}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </React.Fragment>
  )
}

interface Props {
  attributes: IAttribute[]
  id: string
}

export default React.memo(AttributesPanel)
