import { C37Retranslator, C37Transmitter, IEC104Transmitter, Transmitter } from './transmitters.types'

export const createTransmitter = ({ transmitterType }): Transmitter => {
  switch (transmitterType) {
    case 'c37-transmitter':
      return createC37Transmitter()
    case 'c37-retranslator':
      return createC37Retranslator()
    case 'iec104-transmitter':
      return createIEC104Transmitter()
    default:
      throw new Error(`Invalid transmitter type: ${transmitterType}`)
  }
}

const createC37Transmitter = (): C37Transmitter => {
  return {
    id: '',
    type: 'c37-transmitter',
    name: '',
    state: 'STOPPED',
    error: null,
    configuration: {
      port: 0,
      idcode: 1,
      cfg_frame_version: 2,
      connection_type: 'TCP_ONLY',
      type: 'processed',
      freq_format: 'float',
      analogs_format: 'float',
      phasors_format: 'float',
      phasors_coordinates: 'polar',
      data_rate: 50,
      cfgcnt: 0,
    },
    equipment: [],
  }
}

const createC37Retranslator = (): C37Retranslator => {
  return {
    id: '',
    type: 'c37-retranslator',
    name: '',
    state: 'STOPPED',
    error: null,
    configuration: {
      port: 0,
      idcode: 1,
      cfg_frame_version: 2,
      connection_type: 'TCP_ONLY',
      freq_format: 'float',
      analogs_format: 'float',
      phasors_format: 'float',
      phasors_coordinates: 'polar',
    },
    equipment: [],
  }
}

const createIEC104Transmitter = (): IEC104Transmitter => {
  return {
    id: '',
    type: 'iec104-transmitter',
    name: '',
    state: 'STOPPED',
    error: null,
    configuration: {
      port: 0,
      data_rate: 50,
    },
    equipment: [],
  }
}
