import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isFitIntoFilters } from './moderation.utils'
import { IModerationRequest, IRequestMessage } from 'au-nsi/moderation'

export interface ModerationState {
  requests: IModerationRequest[]
  nextPage: string
  loaded: boolean
  filters: {
    resources: Set<IModerationRequest['resource']>
    showOnlyActive: boolean
  }
}

const initialState: ModerationState = {
  requests: [],
  nextPage: null,
  loaded: false,
  filters: {
    resources: new Set(),
    showOnlyActive: false,
  },
}

export const slice = createSlice({
  name: 'app/moderation',
  initialState,
  reducers: {
    insert: (state, action: PayloadAction<IModerationRequest | IModerationRequest[]>) => {
      let inserting = Array.isArray(action.payload) ? action.payload : [action.payload]
      const existsRequestsIds = new Set(state.requests.map((r) => r.id))
      inserting = inserting.filter((r) => !existsRequestsIds.has(r.id))

      state.requests = [...inserting, ...state.requests]
    },
    append: (
      state,
      action: PayloadAction<{
        requests: IModerationRequest[]
        nextPage: string
        cleanBeforePush: boolean
        filters?: ModerationState['filters']
      }>
    ) => {
      if (action.payload.filters) state.filters = action.payload.filters

      if (action.payload.cleanBeforePush) {
        state.requests = action.payload.requests
        state.nextPage = action.payload.nextPage
      } else {
        const exists = new Set(state.requests.map((r) => r.id))
        state.requests.push(...action.payload.requests.filter((r) => !exists.has(r.id)))
        state.requests.sort((a, b) => b.created_at - a.created_at)
        state.nextPage = action.payload.nextPage
      }

      state.loaded = true
    },
    requestCreated: (state, action: PayloadAction<IModerationRequest>) => {
      if (!isFitIntoFilters(action.payload, state.filters)) return
      if (!state.requests.some((r) => r.id === action.payload.id)) {
        state.requests.push(action.payload)
      }
    },
    messageCreated: (
      state,
      action: PayloadAction<{ message: IRequestMessage; requestId: IModerationRequest['id'] }>
    ) => {
      const request = state.requests.find((r) => r.id === action.payload.requestId)
      if (!request) return

      if (!request.messages.some((m) => m.id === action.payload.message.id)) {
        request.messages.push(action.payload.message)
      }
    },
    requestUpdated: (state, action: PayloadAction<IModerationRequest>) => {
      const index = state.requests.findIndex((r) => r.id === action.payload.id)
      if (index === -1) return

      const updated = { ...state.requests[index], ...action.payload }

      if (!isFitIntoFilters(updated as IModerationRequest, state.filters)) {
        state.requests.splice(index, 1)
        return
      }

      state.requests[index] = { ...state.requests[index], ...action.payload } as any
    },
    requestRemoved: (state, action: PayloadAction<number>) => {
      const index = state.requests.findIndex((r) => r.id === action.payload)
      if (index === -1) return

      state.requests.splice(index, 1)
    },

    clean: (state) => {
      state.requests = []
      state.nextPage = null
      state.loaded = false
    },
  },
})

export const moderationSliceActions = slice.actions
export const moderationReducer = slice.reducer
