import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { catalogsActions } from '../../catalogs.actions'
import { postCreateCatalog } from '../../catalogs.api'
import { AnyCatalogWithInstances } from '../../catalogs.types'
import { createCatalog } from '../../catalogs.utils'
import { useState } from 'react'
import { FormMode } from '../../../../shared/interfaces'
import FormButtons from '../../../../shared/Forms/FormButtons'
import SchemaForm from './SchemaForm'
import useCatalogSchemaCallbacks from './useCatalogSchemaCallbacks'
import { ICatalog } from 'au-nsi/catalogs'
import Form from '../../../../shared/Forms/Form'

const SchemaFormCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, wait } = useHttpLoader()

  const [catalog, setCatalog] = useState<AnyCatalogWithInstances>(
    createCatalog(exactCatalogTypeFromPath(location.pathname))
  )
  const [mode, setMode] = useState<FormMode>('create')
  const treeCallbacks = useCatalogSchemaCallbacks({ setter: setCatalog, treeKey: 'schema_tree', deps: [] })

  const handleCatalogCreate = () => {
    const req = postCreateCatalog(catalog)
    const parts = location.pathname.split('/')
    const resource = parts[parts.length - 2]

    wait(req, (res) => {
      if (res === undefined) return
      if (res === null) return navigate(-1)

      dispatch(catalogsActions.catalogCreated(res))
      navigate(`/catalogs/${resource}/${res.id}`)
    })

    return true
  }

  return (
    <Form editing={true} onSubmit={handleCatalogCreate}>
      <SchemaForm
        editing={true}
        schemaObject={catalog}
        treeKey={'schema_tree'}
        nameKey={'name'}
        onInsertIntoGroup={treeCallbacks.handleInsertIntoGroup}
        onUpdate={treeCallbacks.handleUpdate}
        onCreate={treeCallbacks.handleCreate}
        onDelete={treeCallbacks.handleRemove}
        onMove={treeCallbacks.handleCreate}
        onNameChange={(name) => setCatalog({ ...catalog, name })}
      />
      <FormButtons
        mode={mode}
        onEdit={() => setMode('edit')}
        onEditSave={null}
        onCancel={() => navigate(-1)}
        allowEditing={true}
        allowDeleting={false}
        isLoading={loading}
      />
    </Form>
  )
}

const exactCatalogTypeFromPath = (path: string): ICatalog['type'] => {
  const parts = path.split('/')
  const resource = parts[parts.length - 2]

  switch (resource) {
    case 'logbooks':
      return 'logbook'
    case 'passports':
      return 'passport'
    case 'types':
      return 'type'
    default:
      return 'catalog'
  }
}

export default SchemaFormCreate
