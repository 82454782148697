import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PlusButton from '../../../shared/Forms/PlusButton'
import SettingsLinksRow from './SettingsLinksRow'
import css from './svg.module.css'

/**
 * Таблица с настройками элементов-ссылок
 */
const SettingsLinks = (props: Props) => {
  const intl = useIntl()

  const handleRemove = React.useCallback((index: number) => props.onRemove('links', index), [props.onRemove])

  const handleChange = React.useCallback(
    (key: string, value: any, index: number) => props.onChange('links', index, key, value),
    [props.onChange]
  )

  const dashboards = useSelector((state: ReduxState) => state.dashboards.dashboards)
  const dashboardOptions = dashboards.map((d) => ({ value: d.id, label: d.name }))

  const typeOptions = ['dashboard', 'external'].map((value) => ({
    value,
    title: intl.formatMessage({ id: 'dashboards.svg_diagram.settings.link_type.' + value }),
  }))

  const rows = (props.settings.links || []).map((row, index) => {
    return (
      <SettingsLinksRow
        key={index}
        index={index}
        row={row}
        nodeOptions={props.nodeOptions}
        dashboardOptions={dashboardOptions}
        typeOptions={typeOptions}
        onChange={handleChange}
        onNodeHover={props.onHover}
        onRemove={handleRemove}
      />
    )
  })

  return (
    <div className={css.settingsTableWrapper}>
      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.links.node_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.links.type' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.links.target' })}</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <PlusButton textId="common.add" onClick={() => props.onAdd('links')} style={{ marginLeft: 0 }} />
    </div>
  )
}

interface Props {
  settings: ISVGSettings
  nodeOptions: SelectOption[]
  onAdd: (key: string) => void
  onRemove: (key: string, index: number) => void
  onChange: (key: string, index: number, prop: string, value: any) => void
  onHover: (node: string) => void
}

export default SettingsLinks
