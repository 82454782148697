import React from 'react'
import { IntlShape } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../App/app.selectors'
import { selectTimeUnits } from '../../Parameters/params.selectors'
import { formatDuration } from '../../Parameters/params.utils'
import css from './archive.module.css'
import { ArchiveEntry } from './archive.types'

/**
 * Таблица со списком записей в аварийном архиве
 */
const ArchiveTable = (props: Props) => {
  const lang = useSelector(selectLanguage)
  const timeUnits = useSelector(selectTimeUnits)

  const { intl, selection } = props
  const formatter = new Intl.DateTimeFormat(lang, { dateStyle: 'short', timeStyle: 'medium' } as any)

  const handleClick = (e: React.MouseEvent) => {
    const id = +(e.currentTarget as HTMLElement).dataset.id
    props.onSelect(id)
  }

  const rows = props.entries.map((entry) => {
    const duration = formatDuration((entry.ts_end - entry.ts_start) / 1e6, timeUnits)

    // индикация состояния данных относящихся к данному участку импульс-архива
    const backgroundColor = stateColors[entry.state]
    const title = intl.formatMessage({ id: 'ChartPlayer.archive.state.' + entry.state })

    let className = css.tableRow
    if (selection.has(entry.id)) className += ' ' + css.selectedRow

    return (
      <tr key={entry.id} data-id={entry.id} className={className} onClick={handleClick}>
        <td>
          <span className={css.statusIcon} style={{ backgroundColor }} title={title} />
          <span>{formatter.format(entry.ts_start / 1000)}</span>
        </td>
        <td>{formatter.format(entry.ts_end / 1000)}</td>
        <td>{duration}</td>
        <td>{entry.name}</td>
      </tr>
    )
  })

  return (
    <table className="nsi-table is-compact">
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'ChartPlayer.archive.ts_start' })}</th>
          <th>{intl.formatMessage({ id: 'ChartPlayer.archive.ts_end' })}</th>
          <th>{intl.formatMessage({ id: 'ChartPlayer.archive.duration' })}</th>
          <th>{intl.formatMessage({ id: 'ChartPlayer.archive.name' })}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

const stateColors: Record<ArchiveEntry['state'], string> = {
  NONE: 'var(--gray-30)',
  MATERIALIZED: 'var(--text-success)',
  DROPPED: 'var(--danger-text)',
}

interface Props {
  entries: ArchiveEntry[]
  intl: IntlShape
  selection: Set<number>
  onSelect: (id: number) => void
}

export default React.memo(ArchiveTable)
