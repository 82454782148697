import globalClock from '../../services/clock/clock.factory'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import DataService from '../../services/data/data.service'
import { IDataIntervals } from '../../services/data/data.types'
import { ParameterDn } from '../Parameters/params.interfaces'
import { formatValue } from '../Parameters/params.utils'
import css from './maptopology.module.css'

const instances = new Set<number>()

/**
 * Информационное окно, всплывающее при клике на иконку устройства на карте.
 * Т.к. окно должно передаваться в API карт, содержать динамический контент
 * из значений параметров, подписываться и загружать данные только при его
 * открытии, то наиболее простой способ его реализовать с учетом этих
 * требований - через веб компонент.
 */
class MapInfoWindow extends HTMLElement {
  // все публичные поля должны быть переданы при создании компонента
  public parameters: ParameterDn[]
  public deviceID: string
  public irregularParameters: Set<string>
  public dataIntervals: Map<string, IDataIntervals>

  private container: HTMLDivElement
  private service: DataService
  private data: Record<string, number> = {}
  private instaceID = 0

  // при зуме карты элемент почему то постоянно перемещается в DOM дереве и из=за
  // этого постоянно вызываются connectedCallback и disconnectedCallback, поэтому
  // не сразу уничтожаем this.service, иначе на каждой инициализации он будет
  // отправлять запросы в базу за данными
  private destroyTimer

  connectedCallback() {
    clearTimeout(this.destroyTimer)

    this.container = document.createElement('div')
    this.container.className = css.infowindow
    this.appendChild(this.container)

    if (!this.service) {
      // для подписки на данные у каждого инстанса компонента должен быть уникальный id,
      // для генерации которого запоминаем все существующие id в переменной instances
      this.instaceID = instances.size ? Math.max(...instances) + 1 : RESERVED_CHART_IDS.MAP_TOPOLOGY
      instances.add(this.instaceID)

      this.service = new DataService(this.instaceID, { singleValueMode: true })
      this.service.setIrregularParameters(this.irregularParameters)
      this.service.setDataIntervals(this.dataIntervals)

      const parameters = this.parameters.map((p) => p.id)
      this.service.setDataSelectors([{ device_id: this.deviceID, parameters }])

      this.service.onTick = () => {
        if (!this.service.shouldRender()) return

        this.data = this.service.selectCurrentPoint(this.deviceID)
        this.render()
      }
    }

    // на карте нет плеера и всегда должны отображаться онлайн данные
    if (!globalClock.isOnline()) globalClock.setOnline()

    this.render()
  }

  disconnectedCallback() {
    this.removeChild(this.container)

    this.destroyTimer = setTimeout(() => {
      instances.delete(this.instaceID)
      this.service.destroy()
      this.service = null
    }, 1000)
  }

  private renderHeader() {
    const aepsParameter = this.parameters.find((p) => p.id.toLowerCase().endsWith('_total_ac_active_power'))

    // специфическое требование только для проекта АЭПС: должно выводиться
    // состояние батареи (определяется параметром Inverter_Total_AC_active_power)
    if (aepsParameter) {
      const value = this.data[aepsParameter.id]
      if (!value) return

      const header = document.createElement('div')
      this.container.appendChild(header)

      if (value > 0) header.innerHTML = '<b>ЗАРЯД</b>'
      else if (value < 0) header.innerHTML = '<b>РАЗРЯД</b>'
      else header.innerHTML = '<b>ОЖИДАНИЕ</b>'
    }
  }

  // вывод названия и значения каждого из переданных параметров
  private render() {
    this.container.innerHTML = ''
    this.renderHeader()

    for (const p of this.parameters) {
      const value = this.data[p.id]
      const v = value != null ? formatValue(value, p) : '—'

      const div = document.createElement('div')
      div.textContent = `${p.name}: ${v}`
      this.container.appendChild(div)
    }
  }
}

customElements.define('au-maps-infowindow', MapInfoWindow)
