import InputRow from '../../../../../../shared/Inputs/InputRow'
import Toggle from '../../../../../../shared/Inputs/Toggle'
import { boolToText } from '../../../organizations.utils'
import { IntlShape } from 'react-intl'
import { OrganizationType } from 'au-nsi/organizations'

interface IProps {
  organizationType: OrganizationType
  onChange: (val: boolean, name: string) => void
  editing: boolean
  intl: IntlShape
}

/*
  Форма с флажками "Разрешать регистрацию" и "Запрашивать выбор организации при регистрации"
*/

export const RegisterControls = ({ organizationType, onChange, editing, intl }: IProps) => {
  const { accept_registration, register_request_organization } = organizationType

  return (
    <div className={'system__grid'}>
      <InputRow label={intl.formatMessage({ id: 'catalogs.organization.apply_register' })}>
        {editing && (
          <Toggle
            checked={accept_registration}
            onChange={onChange}
            name={'accept_registration'}
            style={{ marginLeft: 0 }}
          />
        )}
        {!editing && boolToText(accept_registration, intl)}
      </InputRow>
      <InputRow
        label={intl.formatMessage({ id: 'catalogs.organization.ask_organization_instance' })}
        style={{ marginLeft: editing ? '0' : '-1.5rem' }}
      >
        {editing && (
          <Toggle
            checked={register_request_organization}
            onChange={onChange}
            name={'register_request_organization'}
            style={{ marginLeft: 0 }}
          />
        )}
        {!editing && boolToText(register_request_organization, intl)}
      </InputRow>
    </div>
  )
}
