import { useEffect, useRef } from 'react'
import { calculateChange } from './huePicker.utils'
import { HSL } from '../../../colorpicker.types'
import styles from './huePicker.module.css'
import { useMouseMove } from '../../../../../../hooks/useMouseMove'

interface IProps {
  color: HSL
  onChange: (hsl: HSL) => void
}

export const HuePicker = ({ color, onChange }: IProps) => {
  const picker = useRef(null)
  const { mouseMove } = useMouseMove({ ref: picker })

  useEffect(() => {
    mouseMove && handleChange(mouseMove)
  }, [mouseMove])

  const handleChange = (ev) => {
    const change = calculateChange(ev, color, picker.current)
    change && onChange(change)
  }

  return (
    <div className={styles.hue}>
      <div className={styles.hueline} ref={picker}>
        <div
          className={styles.pointer}
          style={{ background: `hsl(${Math.round(color.h)}, 100%, 50%)`, left: `${(color.h * 100) / 360}%` }}
        />
      </div>
    </div>
  )
}
