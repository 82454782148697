import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { ReduxState } from '../../../redux/store.types'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import http from '../../../utils/http'
import { formatDate } from '../../../utils/lang'

interface Props {
  onClose: () => void
}

/**
 * Модальное окно для экспорта экранов
 */
const ExportModal = ({ onClose }: Props) => {
  const intl = useIntl()
  const dashboards = useSelector(selectNonSystemDashboards)
  const { loading, wait } = useHttpLoader()

  const [state, setState] = useState({ include_images: true, export_all: false, dashboards: [] as string[] })

  const handleChange = (value: any, key: string) => {
    setState({ ...state, [key]: value })
  }

  // при получении файла экспорта от сервера создаем из него data url для добавления
  // во временную ссылку и вызова его загрузки браузером
  const handleDownload = (data: any) => {
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const time = formatDate(Date.now(), 'yyyy-MM-dd')

    const a = document.createElement('a')
    a.href = url
    a.download = `Dashboards ${time}.json`
    a.click()

    // освобождение ресурсов и закрытие модального окна
    setTimeout(() => {
      URL.revokeObjectURL(url)
      onClose()
    }, 100)
  }

  // при клике на кнопку "экспорт" отправляем запрос на сервер с указанием какие именно экраны надо выгрузить
  const handleExport = () => {
    const selected = state.export_all ? dashboards.map((d) => d.id) : state.dashboards
    if (selected.length === 0) return

    const payload = { dashboards: selected, include_images: state.include_images }
    const req = http.post('/nsi/v1/ui/dashboards/export', payload)

    wait(req, (r) => handleDownload(r.data))
  }

  return (
    <Modal onClose={onClose}>
      <h2>{intl.formatMessage({ id: 'dashboards.export.title' })}</h2>

      <div className="system__input-wrapper">
        <ToggleWithLabel
          name="include_images"
          value={state.include_images}
          onChange={handleChange}
          label={intl.formatMessage({ id: 'dashboards.export.include_images' })}
        />
      </div>

      <div className="system__input-wrapper">
        <ToggleWithLabel
          name="export_all"
          value={state.export_all}
          onChange={handleChange}
          label={intl.formatMessage({ id: 'dashboards.export.export_all' })}
        />
      </div>

      {!state.export_all && (
        <InputRow label={intl.formatMessage({ id: 'dashboards.export.dashboards' })}>
          <MultiSelect name="dashboards" selection={state.dashboards} options={dashboards} onChange={handleChange} />
        </InputRow>
      )}

      <ModalFooter
        loading={loading}
        onCancel={onClose}
        onSave={handleExport}
        saveMessage={intl.formatMessage({ id: 'dashboards.export' })}
      />
    </Modal>
  )
}

const selectNonSystemDashboards = createSelector(
  (state: ReduxState) => state.dashboards.dashboards,
  (dashboards) => dashboards.filter((d) => !d.is_system)
)

export default ExportModal
