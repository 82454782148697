import { SelectOption } from '@alterouniversal/au-react-components'
import { ITableComponent, ITableSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import produce from 'immer'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import PlusButton from '../../../shared/Forms/PlusButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { IDataSource } from '../../Nsi/nsi.interfaces'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import { selectParameterOptions, selectParametersMap } from '../../Parameters/params.selectors'
import TableSettingsRow from './TableSettingsRow'

/**
 * Модальное окно с настройками таблицы
 */
export const TableSettings = (props: Props) => {
  const intl = useIntl()
  const [settings, setSettings] = React.useState(props.component.settings)
  const [error, setError] = React.useState<string>(null)

  React.useEffect(() => {
    if (error != null) setError(null)
  }, [settings])

  const appendColumn = () => {
    const len = settings.columns.length
    const column_index = len > 0 ? settings.columns[len - 1].column_index + 1 : 0
    const column = { column_index, title: '', parameter_id: null, display_unit: null, display_prefix: null }

    setSettings(
      produce(settings, (draft) => {
        draft.columns.push(column)
      })
    )
  }

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleEquipmentChange = React.useCallback((equipment: string[]) => {
    setSettings((prevState) => ({ ...prevState, equipment }))
  }, [])

  const handleColumnChange = React.useCallback((index: number, column) => {
    setSettings((prevState) =>
      produce(prevState, (draft) => {
        const i = draft.columns.findIndex((c) => c.column_index === index)
        draft.columns[i] = column
      })
    )
  }, [])

  const handleColumnRemove = React.useCallback((index: number) => {
    setSettings((prevState) =>
      produce(prevState, (draft) => {
        const i = draft.columns.findIndex((c) => c.column_index === index)
        draft.columns.splice(i, 1)

        for (let j = 0; j < draft.columns.length; j++) {
          draft.columns[j].column_index = j
        }
      })
    )
  }, [])

  const handleSave = () => {
    const err = validateSettings(settings, intl)
    if (err == null) props.onSave(settings)
    else setError(err)
  }

  const columns = settings.columns.map((column) => {
    return (
      <TableSettingsRow
        column={column}
        key={column.column_index}
        onChange={handleColumnChange}
        onRemove={handleColumnRemove}
        parameterMap={props.parameterMap}
        parameterOptions={props.parameters}
        prefixes={props.prefixes}
        units={props.units}
      />
    )
  })

  const displayOptions = [
    { value: 'row', title: intl.formatMessage({ id: 'dashboards.table.settings.display.row' }) },
    { value: 'column', title: intl.formatMessage({ id: 'dashboards.table.settings.display.column' }) },
  ]

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'common.name' })}>
              <TextInput name="title" required={false} value={settings.title} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.table.settings.display' })}>
              <Dropdown name="display" value={settings.display} options={displayOptions} onChange={handleChange} />
            </InputRow>
          </div>

          <table className="nsi-settings-table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.table.settings.columns.column_index' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.table.settings.columns.title' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.table.settings.columns.parameter_id' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.table.settings.columns.display_unit' })}</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>{columns}</tbody>
          </table>

          <PlusButton textId="common.add" onClick={appendColumn} style={{ marginLeft: 0 }} />

          <h3>{intl.formatMessage({ id: 'dashboards.table.settings.equipment' })}</h3>
          <MultiSelect
            options={props.equipment}
            selection={settings.equipment}
            onChange={handleEquipmentChange}
            disabled={false}
          />

          <ModalFooter error={error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

const validateSettings = (settings: ITableSettings, intl: IntlShape): string => {
  if (settings.columns.length === 0) {
    return intl.formatMessage({ id: 'dashboards.table.errors.empty_columns' })
  }

  if (settings.equipment.length === 0) {
    return intl.formatMessage({ id: 'dashboards.table.errors.empty_rows' })
  }

  for (const column of settings.columns) {
    if (!column.parameter_id) {
      return intl.formatMessage({ id: 'dashboards.table.errors.empty_parameter' }, { index: column.column_index + 1 })
    }
  }

  return null
}

interface Props {
  component: ITableComponent
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: ITableSettings) => void
  parameterMap: Map<string, Parameter>
  parameters: SelectOption[]
  prefixes: { [id: string]: SiPrefix }
  title: string
  units: { [id: string]: SiUnit }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameterMap: selectParametersMap(state),
    parameters: selectParameterOptions(state),
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(TableSettings)
