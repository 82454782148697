import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import confirmService from '../../../shared/Modal/confirm.service'
import { selectAccessRights } from '../../App/app.selectors'
import actions from './audio.actions'
import AudioItem from './AudioItem'
import AudioModal from './AudioModal'
import AudioUpload from './AudioUpload'
import { useSelect } from '../../../hooks/useSelect'
import { IAudio } from 'au-nsi/audio'

const AudioPage = () => {
  const dispatch = useDispatch()
  const items = useSelector((state: ReduxState) => state.audio.items)
  const { selectedIndexes, setSelected, setSelectAll, selectedCount } = useSelect({ len: items.length })

  const rights = useSelector(selectAccessRights)
  const allowEditing = !!rights['audio:update']

  // аудио которое воспроизводится в текущий момент
  const [playingId, setPlayingId] = React.useState<string>()

  // аудио выбранное для редактирования
  const [editingItem, setEditingItem] = React.useState<IAudio>()

  React.useEffect(() => {
    dispatch(actions.loadAudio())
  }, [])

  const handleMove = React.useCallback((source: string, target: string) => {
    dispatch(actions.moveAudio(source, target))
  }, [])

  const handlePlay = React.useCallback((id: string) => setPlayingId(id), [])
  const handlePause = React.useCallback(() => setPlayingId(null), [])
  const handleEdit = React.useCallback((file: IAudio) => setEditingItem(file), [])

  const handleDelete = (removedId: IAudio['id']) => {
    const toRemove = [...selectedIndexes]

    if (toRemove.length === 0) toRemove.push(removedId)

    confirmService.requestDeleteConfirmation().then((result) => {
      if (result === 'delete') {
        setSelectAll(false)
        toRemove.forEach((id) => dispatch(actions.deleteAudio(id as string)))
      }
    })
  }

  const handleUpload = React.useCallback((file: IAudio) => {
    dispatch(actions.uploadFile(file))
  }, [])

  const rows = items.map((item, index) => {
    const selected = selectedIndexes.has(item.id)

    return (
      <AudioItem
        key={item.id}
        allowEditing={allowEditing}
        audio={item}
        index={index}
        isPlaying={item.id === playingId}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onMove={handleMove}
        onPause={handlePause}
        onPlay={handlePlay}
        onClick={setSelected}
        selected={selected}
        selectedCount={selectedCount}
        onContextMenuOpen={() => !selected && setSelectAll(false)}
      />
    )
  })

  return (
    <div className="nsi-main__container">
      {allowEditing && <AudioUpload multiple={true} onChange={handleUpload} />}
      <div>{rows}</div>
      {editingItem != null && <AudioModal audio={editingItem} onClose={() => setEditingItem(null)} />}
    </div>
  )
}

export default AudioPage
