// отрисовка курсора мыши
export const drawCursor = (ctx: CanvasRenderingContext2D, cursor: IPoint, size: ISize) => {
  const x = Math.round(cursor.x) + 0.5
  const y = Math.round(cursor.y) + 0.5

  ctx.lineWidth = 1
  ctx.strokeStyle = '#b4b7c6'

  ctx.beginPath()
  ctx.moveTo(0, y)
  ctx.lineTo(size.width, y)
  ctx.stroke()

  ctx.beginPath()
  ctx.moveTo(x, 0)
  ctx.lineTo(x, size.height)
  ctx.stroke()

  ctx.lineWidth = 2
}

// отрисовка текущего состояния отображаемых параметров с подписью устройства
export const drawHead = (ctx: CanvasRenderingContext2D, head: IHead) => {
  ctx.fillStyle = head.color
  ctx.beginPath()
  ctx.arc(head.x, head.y, 12, 0, 2 * Math.PI, false)
  ctx.fill()

  ctx.fillStyle = '#fff'
  ctx.fillText(head.shortname, head.x, head.y)
}

// отрисовка всплывающего окошка со значениями параметров под курсором
export const drawTooltip = (ctx: CanvasRenderingContext2D, tooltip: ITooltip) => {
  ctx.save()

  ctx.textAlign = 'start'
  ctx.textBaseline = 'top'
  ctx.font = '16px Roboto'

  const width = Math.max(
    ctx.measureText(tooltip.title || '').width,
    ctx.measureText(tooltip.x).width,
    ctx.measureText(tooltip.y).width
  )

  const height = tooltip.title ? 80 : 56

  // отрисовка окошка
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
  ctx.beginPath()
  ctx.rect(4, 4, width + 20, height)
  ctx.fill()

  // отрисовка значений параметров
  ctx.fillStyle = '#b4b7c6'
  ctx.fillText(tooltip.x, 14, 12)
  ctx.fillText(tooltip.y, 14, 36)

  // отрисовка названия устройства (если выбрано)
  if (tooltip.title) {
    ctx.fillStyle = tooltip.color
    ctx.fillText(tooltip.title, 14, 60)
  }

  ctx.restore()
}

export interface IHead {
  id: string
  name: string
  shortname: string
  color: string
  x: number
  y: number
}

interface IPoint {
  x: number
  y: number
}

interface ISize {
  width: number
  height: number
}

interface ITooltip {
  title: string
  color: string
  x: string
  y: string
}
