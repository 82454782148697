import classnames from 'classnames'
import { useCombobox } from 'downshift'
import React, { useRef, useState } from 'react'
import { ReactComponent as ArrowLeft } from '../../icons/arrow-right.svg'
import SmoothDropdown from '../Utils/SmoothDropdownBehaivor/SmoothDropdown'
import useVirtualMenu from './Virtual/useVirtualMenu'

/**
 * Компонент для выбора из выпадающего списка.
 * Отличия от Select:
 * - не имеет поля ввода для поиска, поэтому подходит только для небольшого количества опций
 * - позволяет выводить подсказки для предлагаемых вариантов
 * - позволяет делать варианты неактивными
 */
const Dropdown = <T extends unknown>(props: IDropdownProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (!props.disabled) setOpen(!open)
  }

  const handleChange = (changes) => {
    const option = changes.selectedItem

    if (option && !option.disabled) {
      props.onChange(option.value, props.name)
      setOpen(false)
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (!props.disabled) {
      const target = e.target as HTMLDivElement
      const input = target.previousSibling as HTMLInputElement
      input.focus()
    }
  }

  const selectedOption = props.options.find((o) => o.value === props.value) || null
  const selectedTitle = selectedOption && selectedOption.title
  const selectedDescription = selectedOption && selectedOption.description

  const valueClass = classnames({
    'dropdown-value': !props.disabled || props.disabledMode === 'input',
    'dropdown-value--readonly': props.disabled,
  })

  const downshift = useCombobox({
    itemToString,
    onSelectedItemChange: handleChange,
    onIsOpenChange: (c) => setOpen(c.isOpen),
    selectedItem: selectedOption,
    items: props.options,
    isOpen: open,
  })

  const position = useVirtualMenu({ elementRef: ref, isOpen: props.isVirtual && open })

  const renderMenuItems = () =>
    props.options.map((option, index) => {
      const className = classnames('dropdown-option', {
        'is-disabled': option.disabled,
        'is-highlighted': downshift.highlightedIndex === index,
      })

      return (
        <div key={option.title} className={className} {...downshift.getItemProps({ item: option, index })}>
          <div className="dropdown-option__title">{option.title}</div>
          <div className="dropdown-option__description">{option.description}</div>
        </div>
      )
    })

  const wrapperClass = classnames('dropdown-wrapper', { 'is-compact': props.compact })
  const menuClass = classnames('dropdown-menu', { 'is-top': position === 'top' })

  const onKeyDown = (e) => {
    if (e.key === 'Escape' && open) e.preventDefault()
  }

  return (
    <div className={wrapperClass} {...downshift.getComboboxProps()} style={props.fullwidth && { width: '100%' }}>
      <input
        className="visually-hidden dropdown-input"
        {...downshift.getInputProps({ onKeyDown })}
        onFocus={handleOpen}
        disabled={props.disabled}
      />
      <div className={valueClass} onClick={handleClick} title={selectedDescription} ref={ref}>
        {selectedTitle}
      </div>
      {!props.disabled && (
        <ArrowLeft
          onClick={() => setOpen(!open)}
          className={`nsi-select__icon ${open && 'nsi-select__icon_open'} ${position === 'top' && 'to-top'}`}
        />
      )}
      <div className={menuClass} style={open ? {} : { boxShadow: 'none', zIndex: 1 }} {...downshift.getMenuProps()}>
        <SmoothDropdown maxHeight={300} open={open}>
          {renderMenuItems()}
        </SmoothDropdown>
      </div>
    </div>
  )
}

const itemToString = (item: DropdownOption<any>) => (item ? item.title : '')

export interface IDropdownProps<T> {
  options: Array<DropdownOption<T>>
  value: T
  name?: string
  onChange: (value: T, name?: string) => void
  compact?: boolean
  disabled?: boolean
  disabledMode?: 'text' | 'input'
  fullwidth?: boolean
  isVirtual?: boolean
}

export interface DropdownOption<T> {
  value: T
  title: string
  description?: string
  disabled?: boolean
}

export default React.memo(Dropdown)
