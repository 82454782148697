/**
 * считать содержимое файла
 */
export const readFile = (file: File, mode: 'text' | 'binary', cb: (r: string) => void) => {
  const reader = new FileReader()

  if (mode === 'text') reader.readAsText(file)
  else reader.readAsDataURL(file)

  reader.onload = () => {
    const result = reader.result as string

    if (mode === 'text') {
      cb(result)
    } else {
      const i = result.indexOf('base64,')
      cb(result.slice(i + 7))
    }
  }

  reader.onabort = () => {
    console.log('Reading operation is aborted')
    cb(null)
  }

  reader.onerror = () => {
    console.error(reader.error)
    cb(null)
  }
}
