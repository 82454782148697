import { atom } from 'recoil'
import { IUserTask } from '../../../userTasks.types'
import { CSSProperties } from 'react'

export interface TaskFormWrapperPosition {
  x: number
  y: number
  width: CSSProperties['width']
  height: CSSProperties['height']
}

export interface IUserTaskFormWrapperRecoilState {
  [key: IUserTask['id']]: TaskFormWrapperPosition
}

export const userTaskFormWrapperState = atom<IUserTaskFormWrapperRecoilState>({
  key: 'userTaskFormWrapper',
  default: {},
})
export const activeUserTaskFormIdState = atom<IUserTask['id']>({ key: 'activeUserTaskFormId', default: null })
