import { ReduxState } from '../../../redux/store.types'
import http, { handleHttpError } from '../../../utils/http'
import { actions } from './settings.reducers'

const API_URL = '/nsi/v1/ms/settings/'

const loadSettings = (name: string, defaultValue?: any) => (dispatch, getState) => {
  const state: ReduxState = getState()

  if (!state.service_settings[name]) {
    http
      .get(API_URL + name)
      .then((res) => dispatch(actions.set(res.data)))
      .catch((err) => {
        if (err.response?.status === 404) {
          return dispatch(actions.set({ name, settings: defaultValue }))
        }

        throw err
      })
      .catch(handleHttpError)
  }
}

const loadMapSettings = () => (dispatch, getState) => {
  const state: ReduxState = getState()

  if (!state.service_settings['maps']) {
    http
      .get('/nsi/v1/maps/settings')
      .then((r) => dispatch(actions.set({ name: 'maps', settings: r.data })))
      .catch(handleHttpError)
  }
}

const settingsActions = { ...actions, loadSettings, loadMapSettings }

export default settingsActions
