import { ICatalog, IPropertyDescription } from 'au-nsi/catalogs'

export function getSchemaMap(schema: IPropertyDescription[]): Map<IPropertyDescription['id'], IPropertyDescription> {
  return schema.reduce((acc, val) => acc.set(val.id, val), new Map<IPropertyDescription['id'], IPropertyDescription>())
}

export function isSchemasEqual(s1: IPropertyDescription, s2: IPropertyDescription): boolean {
  if (!s1 || !s2) return false

  return (
    s1.type === s2.type &&
    s1.ref_catalog_id === s2.ref_catalog_id &&
    s1.required === s2.required &&
    s1.name === s2.name &&
    s1.default_value === s2.default_value
  )
}

/**
 * Вернуть копию schemaTree, содержащую только properties, с id перечисленными в includes
 * */
export function filterSchemaTree(schemaTree: IPropertyDescription[], includes: Set<IPropertyDescription['id']>) {
  return schemaTree.reduce((acc, val) => {
    if (val.type === 'group') {
      const filteredChildren = filterSchemaTree(val.children, includes)
      return filteredChildren.length === 0 ? acc : acc.concat({ ...val, children: filteredChildren })
    } else if (includes.has(val.id)) {
      return acc.concat(val)
    }

    return acc
  }, [])
}

function findCatalogsDiff(c1: ICatalog, c2: ICatalog): [ICatalog, ICatalog] {
  const c1SchemaMap = getSchemaMap(c1.schema)
  const c2SchemaMap = getSchemaMap(c2.schema)
  const keys = new Set([...c1SchemaMap.keys(), ...c2SchemaMap.keys()])

  const c1Schema: IPropertyDescription[] = []
  const c2Schema: IPropertyDescription[] = []

  for (const key of keys) {
    if (!isSchemasEqual(c1SchemaMap.get(key), c2SchemaMap.get(key))) {
      if (c1SchemaMap.has(key)) c1Schema.push(c1SchemaMap.get(key))
      if (c2SchemaMap.has(key)) c2Schema.push(c2SchemaMap.get(key))
    }
  }

  const c1Ids = new Set(c1Schema.map((p) => p.id))
  const c2Ids = new Set(c2Schema.map((p) => p.id))

  return [
    { ...c1, schema: c1Schema, schema_tree: filterSchemaTree(c1.schema_tree, c1Ids) },
    { ...c2, schema: c2Schema, schema_tree: filterSchemaTree(c2.schema_tree, c2Ids) },
  ]
}

export default findCatalogsDiff
