import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessRights } from '../../App/app.selectors'
import { ackIncident } from '../incident.actions'

/**
 * Кнопка "Взять в работу"
 */
const AckButton = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const allowAck = useSelector(selectAccessRights)['incidents:ack']

  if (!allowAck) return null

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(ackIncident(props.id))
  }

  const className = 'alerts-button ack-button ' + props.level

  return (
    <button data-id={props.id} className={className} onClick={handleClick}>
      {intl.formatMessage({ id: 'alerts.button.ack' })}
    </button>
  )
}

interface Props {
  id: number
  level: string
}

export default React.memo(AckButton)
