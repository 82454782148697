import { ModbusParametersMapping, ModbusTCPClient } from 'au-nsi/equipment'
import { CSSProperties, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import PanelButtons from 'shared/Forms/PanelButtons'
import { useAppSelector } from '../../../../redux/store'
import Form from '../../../../shared/Forms/Form'
import PlusButton from '../../../../shared/Forms/PlusButton'
import HelpTooltip from '../../../../shared/HelpTooltip/HelpTooltip'
import { deepCopy } from '../../../../utils/misc'
import { selectParametersNames } from '../../../Parameters/params.selectors'
import ImportModal from '../../Modals/ImportModal'
import { updateParametersMapping } from '../../nsi.actions'
import { selectEquipmentMap } from '../../nsi.selectors'
import { countMappedParameters } from '../MappingForm'
import MappingImport from '../MappingImport/MappingImport'
import * as utils from './modbus.utils'
import ModbusMappingRow from './ModbusMappingRow'

interface Props {
  device: ModbusTCPClient
  allowEditing: boolean
}

/**
 * Форма настроек маппинга параметров для протокола Modbus
 */
const ModbusMapping = ({ device, allowEditing }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const virtuoso = useRef<VirtuosoHandle>()

  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)
  const [draft, setDraft] = useState(device.parameters_mapping)

  const translations = useAppSelector((state) => state.language.translations)
  const equipment = useAppSelector((state) => state.nsi.equipment)

  const parametersNames = useAppSelector(selectParametersNames)
  const equipmentMap = useAppSelector(selectEquipmentMap)

  const importSources = useMemo(
    () => equipment.filter((e) => e.protocol === device.protocol && e.parameters_mapping.length),
    [equipment, device]
  )

  const conflictingRegisters = utils.findRegisterOverlaps(draft)

  const usedParameters = useMemo(() => utils.selectUsedParameters(device.id, equipmentMap), [device.id, equipmentMap])
  const conflictingParameter = utils.findParametersOverlap(usedParameters, draft)
  if (conflictingParameter) conflictingParameter.parameter = parametersNames.get(conflictingParameter.parameter)

  // параметры используемые в маппинге текущего устройства (в отличии от usedParameters, которые включают
  // параметры из родительского и дочерних виртуальных устройств)
  const parametersUsage = countMappedParameters(draft)

  // размер колонки с номером строки в зависимости от кол-ва цифр в максимальном номере + padding
  const indexSize = `calc(${draft.length.toString().length}ch + 6px + 25px)`
  const columnSizes = `${indexSize} 3fr 2fr 1fr 1fr 1fr 70px 50px 50px 24px`

  useEffect(() => {
    if (!editing) setDraft(device.parameters_mapping)
  }, [device.parameters_mapping])

  useLayoutEffect(() => cancelChanges(), [device.id])

  const cancelChanges = () => {
    setDraft(device.parameters_mapping)
    setEditing(false)
  }

  const saveChanges = () => {
    // из-за виртуализации не все инпуты находятся в DOM, поэтому дефолтная браузерная валидация
    // формы не будет работать, вместо этого сами находим невалидную строку и перемещаемся к ней
    const invalidRow = draft.findIndex((r) => !r.parameter_id || parametersUsage.get(r.parameter_id) > 1)

    if (invalidRow !== -1) {
      virtuoso.current.scrollToIndex(invalidRow)
    } else {
      dispatch(updateParametersMapping(device.id, draft))
      setEditing(false)
    }
  }

  const handleImport = (id: string) => {
    const source = importSources.find((e) => e.id === id)
    const data = deepCopy(source.parameters_mapping)
    dispatch(updateParametersMapping(device.id, data))
    setImporting(false)
  }

  const handleFileImport = (mapping: ModbusParametersMapping[]) => {
    setEditing(true)
    setDraft(mapping)
  }

  const addRow = () => {
    setDraft((prev) => [
      ...prev,
      { parameter_id: '', register: 0, table: 0, type: 'bool', factor: 1, offset: 0, bit_mask: 1 },
    ])
  }

  const removeRow = useCallback((index: number) => {
    setDraft((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }, [])

  const updateRow = useCallback((row, index: number) => {
    setDraft((prev) => [...prev.slice(0, index), row, ...prev.slice(index + 1)])
  }, [])

  const renderRow = (index: number) => {
    return (
      <ModbusMappingRow
        key={index}
        index={index}
        row={draft[index]}
        editing={editing}
        onRemove={removeRow}
        onChange={updateRow}
        invalidRegister={conflictingRegisters.has(index)}
        duplicateParameter={parametersUsage.get(draft[index].parameter_id) > 1}
        columnSizes={columnSizes}
        defaultByteOrder={device.configuration.byte_order}
        defaultRegisterOrder={device.configuration.register_order}
      />
    )
  }

  const Footer = () => editing && <PlusButton textId="common.add_parameter" onClick={addRow} />

  const footerHeight = editing ? 40 : 0
  const height = draft.length * 52 + footerHeight

  const tableStyle: CSSProperties = height > 650 ? { height: '600px', overflowY: 'auto' } : { height: height + 'px' }

  return (
    <Form editing={editing} onSubmit={saveChanges}>
      <div className="nsi-grid-table is-striped">
        <header style={{ gridTemplateColumns: columnSizes }}>
          <div />
          <div className="text--nowrap">{translations['equipment.parameters_mapping.parameter_id']}</div>
          <div className="text--nowrap">{translations['equipment.parameters_mapping.table']}</div>
          <div className="text--nowrap">{translations['equipment.parameters_mapping.register']}</div>
          <div className="text--nowrap">{translations['equipment.parameters_mapping.type']}</div>
          <div className="text--nowrap">{translations['equipment.parameters_mapping.factor']}</div>
          <div>
            <HelpTooltip position="left" content={<span>S/M</span>}>
              {intl.formatMessage({ id: 'nsi.parameters.modbus_offset_mask' })}
            </HelpTooltip>
          </div>
          <div>
            <HelpTooltip position="left" content={<span>B</span>}>
              {intl.formatMessage({ id: 'nsi.parameters.modbus_inverted_bytes' })}
            </HelpTooltip>
          </div>
          <div>
            <HelpTooltip position="left" content={<span>R</span>}>
              {intl.formatMessage({ id: 'nsi.parameters.modbus_inverted_registers' })}
            </HelpTooltip>
          </div>
          <div />
        </header>
        <Virtuoso
          style={tableStyle}
          className="tbody"
          totalCount={draft.length}
          itemContent={renderRow}
          components={{ Footer }}
          ref={virtuoso}
        />
      </div>

      {conflictingRegisters.size > 0 && (
        <div className="text--center text--warning" style={{ margin: '1em' }}>
          {intl.formatMessage(
            { id: 'nsi.parameters.modbus_registers_overlap' },
            { lines: utils.formatOverlaps(conflictingRegisters) }
          )}
        </div>
      )}

      {conflictingParameter && (
        <div className="text--center text--warning" style={{ margin: '1em' }}>
          {intl.formatMessage({ id: 'nsi.parameters.modbus_parameters_overlap' }, conflictingParameter)}
        </div>
      )}

      <PanelButtons
        editing={editing}
        allowEditing={allowEditing}
        allowImport={allowEditing}
        onEdit={() => setEditing(true)}
        onCancel={cancelChanges}
        onSave={saveChanges}
        onImport={() => setImporting(true)}
      >
        <MappingImport
          name={device.name}
          mapping={device.parameters_mapping}
          columns={mappingColumns}
          numericColumns={numericColumns}
          onImport={handleFileImport}
        />
      </PanelButtons>
      <ImportModal
        isOpen={importing}
        onClose={() => setImporting(false)}
        onImport={handleImport}
        sources={importSources}
        targetId={device.id}
      />
    </Form>
  )
}

const mappingColumns = [
  'parameter_id',
  'table',
  'register',
  'type',
  'factor',
  'offset',
  'bit_mask',
  'byte_order',
  'register_order',
]
const numericColumns = ['table', 'register', 'factor', 'offset', 'bit_mask']

export default ModbusMapping
