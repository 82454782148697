import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { ReduxState } from '../../../../redux/store.types'
import { catalogsActions } from '../../catalogs.actions'
import { patchUpdateCatalog, requestCatalogDelete } from '../../catalogs.api'
import FormButtons from '../../../../shared/Forms/FormButtons'
import { useEffect, useState } from 'react'
import { FormMode } from '../../../../shared/interfaces'
import SchemaForm from './SchemaForm'
import useCatalogSchemaCallbacks from './useCatalogSchemaCallbacks'
import confirmService from '../../../../shared/Modal/confirm.service'
import { isAnySchemaRowRemovedOrUpdated } from '../../catalogs.utils'
import { selectAccessRights } from '../../../App/app.selectors'
import { isJsonEqual } from 'utils/misc'
import Form from '../../../../shared/Forms/Form'
import { useParams } from 'react-router-dom'

const SchemaFormRoute = () => {
  const params = useParams()
  const id = parseInt(params.id)

  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()

  const schema = useSelector((state: ReduxState) => state.catalogs.catalogs.find((c) => c.id === id))
  const accessRights = useSelector(selectAccessRights)

  const [mode, setMode] = useState<FormMode>('view')
  const [catalog, setCatalog] = useState(schema)
  const treeCallbacks = useCatalogSchemaCallbacks({ setter: setCatalog, treeKey: 'schema_tree', deps: [] })

  useEffect(() => {
    setMode('view')
    setCatalog(schema)
  }, [id])

  useEffect(() => {
    if (mode === 'view') return setCatalog(schema)
  }, [schema, mode])

  const handleSchemaDelete = async () => {
    if (schema.type === 'logbook') {
      const title = 'catalogs.logbook.delete_confirm'
      const deleteConfirm = await confirmService.requestDeleteConfirmation(title)
      if (deleteConfirm !== 'delete') return
    }

    const req = requestCatalogDelete(schema)

    wait(req, (removed) => {
      removed && dispatch(catalogsActions.catalogDeleted(removed))
      setMode('view')
    })
  }

  const handleSchemaSave = async () => {
    if (schema.type === 'logbook' && isAnySchemaRowRemovedOrUpdated(schema.schema_tree, catalog.schema_tree)) {
      const title = 'catalogs.logbook.delete_field_confirm'
      const options = [{ title: 'common.yes', value: 'yes' }]
      const allowUpdate = await confirmService.requestConfirmation(title, options)
      if (allowUpdate !== 'yes') return
    }

    const isChanged = !isJsonEqual(schema, catalog)
    if (!isChanged) return setMode('view')

    wait(patchUpdateCatalog(catalog), (updated) => {
      updated && dispatch(catalogsActions.catalogUpdated(updated))
      setMode('view')
    })
  }

  if (!schema || !catalog) return null

  const allowEditing =
    schema.type === 'logbook' ? accessRights['catalogs:update_logbook'] : accessRights['catalogs:update']
  return (
    <Form editing={mode === 'edit'} onSubmit={handleSchemaSave}>
      <SchemaForm
        editing={mode === 'edit'}
        schemaObject={catalog}
        treeKey={'schema_tree'}
        nameKey={'name'}
        onInsertIntoGroup={treeCallbacks.handleInsertIntoGroup}
        onUpdate={treeCallbacks.handleUpdate}
        onCreate={treeCallbacks.handleCreate}
        onDelete={treeCallbacks.handleRemove}
        onMove={treeCallbacks.handleMove}
        onNameChange={(name) => setCatalog({ ...catalog, name })}
      />
      <FormButtons
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onDelete={() => setMode('delete')}
        onDeleteConfirm={handleSchemaDelete}
        allowEditing={allowEditing}
        allowDeleting={allowEditing}
        isLoading={loading}
      />
    </Form>
  )
}

export default SchemaFormRoute
