import { IChartLine, IChartSettings, IChartSettings2 } from 'au-nsi/dashboards'

/**
 * Конвертация настроек графика из версии 1 в 2
 */
export const convertTo2 = (settings: IChartSettings | IChartSettings2): IChartSettings2 => {
  if ('version' in settings && settings.version === 2) {
    return settings
  }

  const { title, prediction, predictionTime, aggregationMode, mirrorY, mirrorTitle, ...rest } =
    settings as IChartSettings

  return {
    version: 2,
    title,
    prediction,
    predictionTime,
    aggregationMode,
    mirrorY,
    mirrorTitle,
    axes: [{ ...rest, position: 'right' }],
  }
}

/**
 * Конвертация настроек графика из версии 2 в 1
 */
export const convertTo1 = (settings: IChartSettings | IChartSettings2): IChartSettings => {
  if (!('version' in settings)) {
    return settings
  }

  const { title, prediction, predictionTime, aggregationMode, mirrorY, mirrorTitle, axes } = settings
  return { title, prediction, predictionTime, aggregationMode, mirrorY, mirrorTitle, ...axes[0] }
}

/**
 * Получить список всех параметров заданных в настройках графика на всех осях
 */
export const extractLines = (settings: IChartSettings | IChartSettings2): IChartLine[] => {
  if (!('version' in settings)) {
    return settings.lines
  }

  const result: IChartLine[] = []

  for (const axis of settings.axes) {
    result.push(...axis.lines)
  }

  return result
}
