import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReduxState } from '../../../redux/store.types'
import { stopAudio } from '../../Incidents/incident.actions'
import { DenormalizedIncident } from '../../Incidents/incident.interfaces'
import './alerts.styles.css'
import AlertsList from './AlertsList'

interface Props {
  incidents: DenormalizedIncident[]
  onClose: () => void
}

// список сигнальных ситуаций
const Alerts = ({ incidents, onClose }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const audioId = useSelector((state: ReduxState) => state.incidents.audioId)

  // остановить звуковое уведомление
  const handlePause = () => dispatch(stopAudio())

  const isPlaying = !!audioId

  return (
    <AlertsList
      incidents={incidents}
      navigate={navigate}
      dispatch={dispatch}
      onClose={onClose}
      isPlaying={isPlaying}
      onPause={handlePause}
    />
  )
}

export default Alerts
