import { ReduxState } from '../../redux/store.types'
import { commandsSlice } from './commands.state'
import * as api from './commands.api'
import { handleHttpError } from '../../utils/http'

const actions = commandsSlice.actions

const loadCommands =
  (forceReload = false) =>
  async (dispatch, getState) => {
    const state: ReduxState = getState()

    if (!state.commands.isLoaded || forceReload) {
      api
        .loadCommands()
        .then((r) => dispatch(actions.commandsLoaded(r)))
        .catch(handleHttpError)
    }
  }

const commandsActions = { ...actions, loadCommands }
export default commandsActions
