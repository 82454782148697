import { ILogbookEntry } from 'au-nsi/logbook'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Datepicker from '../../../../../shared/Inputs/Datepicker/Datepicker'
import InputRow from '../../../../../shared/Inputs/InputRow'
import { FormMode } from '../../../../../shared/interfaces'
import ModalFooter from '../../../../../shared/Modal/ModalFooter'
import { formatDate } from '../../../../../utils/lang'
import { selectAccessRights } from '../../../../App/app.selectors'
import { AnyCatalogWithInstances } from '../../../../Catalogs/catalogs.types'
import { createCatalogItem } from '../../../../Catalogs/catalogs.utils'
import InstanceForm from '../../../../Catalogs/components/InstanceForm/InstanceForm'
import DocumentsPanel from '../../../../Libraries/Documents/components/DocumentsPanel/DocumentsPanel'
import DocumentsPanelLoader from '../../../../Libraries/Documents/components/DocumentsPanel/DocumentsPanelLoader'

interface IProps {
  logbookEntry: ILogbookEntry
  logbook: AnyCatalogWithInstances
  onPropertiesChange: (schemaId: string, value: any) => void
  onChange: (value: any, name: string) => void
  formMode: FormMode

  onCreateSave?: () => void
  onCancel?: () => void
  renderButtons?: boolean
  loadDocuments?: boolean
  loading?: boolean
}

const LogbookEntryForm = ({
  logbookEntry,
  logbook,
  onChange,
  formMode = 'view',
  onPropertiesChange,
  onCreateSave,
  onCancel,
  loadDocuments = true,
  loading,
  renderButtons,
}: IProps) => {
  const intl = useIntl()

  const rights = useSelector(selectAccessRights)
  const editing = formMode === 'edit' || formMode === 'create'

  // при клике на "Нет в списке" открывается новое модальное окно создания
  // экземпляра справочника, поэтому текущее модальное окно нужно закрыть,
  // иначе они наложатся друг на друга
  const handleNotListed = () => onCancel()

  const renderDocuments = () => {
    if (loadDocuments) {
      return (
        <DocumentsPanelLoader
          name="files"
          resource="equipment_logbook"
          requestAccess={false}
          resource_item_id={logbookEntry.id}
          editing={editing}
          onChange={onChange}
        />
      )
    }

    return (
      <DocumentsPanel
        name="files"
        resource="equipment_logbook"
        resource_item_id={logbookEntry.id}
        editing={editing}
        documents={logbookEntry.files ?? []}
        onChange={onChange}
      />
    )
  }

  return (
    <>
      {formMode === 'create' && <h2>{intl.formatMessage({ id: 'system.maps.add_logbook' })}</h2>}
      <InputRow label={intl.formatMessage({ id: 'system.maps.logbook_ts' })} style={{ width: '50%' }}>
        {editing && rights['equipment_logbook:moderate'] ? (
          <Datepicker renderTime={false} time={logbookEntry.ts} onTimeChange={(ts) => onChange(ts, 'ts')} />
        ) : (
          formatDate(logbookEntry.ts, 'dd.MM.yyyy')
        )}
      </InputRow>
      <InstanceForm
        item={createCatalogItem(logbook, logbookEntry.values)}
        propertiesKey={'properties'}
        schema={logbook.schema_tree}
        onChange={onPropertiesChange}
        onNotListed={handleNotListed}
        editing={editing}
      />
      <h2 className="system__title" style={{ marginTop: '2em', marginBottom: '0.5em' }}>
        {intl.formatMessage({ id: 'common.attached_documents' })}
      </h2>
      {renderDocuments()}
      {renderButtons !== false && editing && (
        <ModalFooter onCancel={onCancel} onSave={onCreateSave} loading={loading} />
      )}
    </>
  )
}

export default LogbookEntryForm
