import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import { selectEnabledModules } from '../../App/app.selectors'
import ntfStyles from '../Notifications/ntf.module.css'
import NotificationList from './NotificationList/NotificationList'
import * as api from './userNotifications.api'
import styles from './userNotifications.module.css'
import useUserNotifications from './useUserNotifications'
import { IUserNotification } from 'au-nsi/notifications'

interface Props {
  unseenNotifications: number
}

// список личных уведомлений пользователя
const UserNotifications = ({ unseenNotifications }: Props) => {
  const intl = useIntl()
  const modules = useSelector(selectEnabledModules)

  const { notifications, isMore, onlyUnread, setOnlyUnread, loadMore, ack, unack } = useUserNotifications()

  // отметить уведомление как причитанное/непрочитанное
  const handleAck = useCallback(
    (item: IUserNotification) => {
      const payload = { notification_id: item.id, ack_ts: Date.now() }

      if (item.ack_ts) {
        api.unackNotification(item.id)
        unack(payload)
      } else {
        api.ackNotification(item.id)
        ack(payload, onlyUnread)
      }
    },
    [onlyUnread]
  )

  // отметить все как прочитанные
  const handleAckAll = () => {
    const last_ts = notifications[0].created_at
    const payload = { batch: true, last_ts, ack_ts: Date.now() }

    api.ackAllNotifications(last_ts)
    ack(payload, onlyUnread)
  }

  return (
    <>
      <div className={styles.filterBlock}>
        <ToggleWithLabel
          name=""
          label={intl.formatMessage({ id: 'system.messages.show_only_unread' })}
          fontSize="10px"
          value={onlyUnread}
          onChange={setOnlyUnread}
        />
      </div>
      <NotificationList
        onLoadMore={loadMore}
        onAck={handleAck}
        isMore={isMore}
        notifications={notifications}
        modules={modules}
      />

      {unseenNotifications > 0 && (
        <div className={ntfStyles.clearAll} onClick={handleAckAll}>
          {intl.formatMessage({ id: 'system.messages.mark_all' })}
        </div>
      )}
    </>
  )
}

export default UserNotifications
