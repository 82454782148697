import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGroup } from 'au-nsi-elevator/groups'

export interface IGroupsState {
  groups: IGroup[]
  loaded: boolean
}

const initialState: IGroupsState = {
  groups: [],
  loaded: false,
}

export const slice = createSlice({
  name: 'app/ae/groups',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<IGroup[]>) => {
      state.groups = action.payload
      state.loaded = true
    },
    groupCreated: (state, action: PayloadAction<IGroup>) => {
      if (!state.groups.some((g) => g.id === action.payload.id)) {
        state.groups.push(action.payload)
      }
    },
    groupUpdated: (state, action: PayloadAction<IGroup>) => {
      const groupIndex = state.groups.findIndex((g) => g.id === action.payload.id)
      if (groupIndex === -1) return

      state.groups[groupIndex] = { ...state.groups[groupIndex], ...action.payload }
    },
    groupRemoved: (state, action: PayloadAction<Pick<IGroup, 'id'> & Pick<IGroup, 'name'>>) => {
      const groupIndex = state.groups.findIndex((g) => g.id === action.payload.id)
      if (groupIndex === -1) return

      state.groups.splice(groupIndex, 1)
    },
  },
})

const groupsReducer = slice.reducer
export const groupsSliceActions = slice.actions
export default groupsReducer
