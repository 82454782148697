import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import http, { handleHttpError, handleHttpResponse } from '../../../../utils/http'
import { fetchDocuments } from '../documents.api'
import { IDocument } from 'au-nsi/documents'

/**
 * Модальное окно для подтверждения удаления выбранных элементов
 */
const DeleteModal = (props: Props) => {
  const intl = useIntl()
  const state = useSelector((state: ReduxState) => state.documents)

  const [loading, setLoading] = useState(true)
  const [ids, setIds] = useState(props.selection)
  const [folders, setFolders] = useState(() =>
    props.selection.filter((id) => state.items.find((item) => item.id === id).is_folder)
  )

  // получить содержимое папки: если оно уже загружено - берется из redux,
  // если нет - то загружается из НСИ
  const getFolderContent = (id: string): Promise<IDocument[]> => {
    return state.loadedFolders.includes(id)
      ? Promise.resolve(state.items.filter((item) => item.parent_id === id))
      : fetchDocuments({ parent_id: id, resource: props.resource, resource_item_id: props.resource_item_id })
  }

  // проходим по всем дочерним папкам и добавляем их содержимое в список удаляемых элементов
  useEffect(() => {
    if (folders.length === 0) return setLoading(false)

    const next = folders[0]
    getFolderContent(next).then((docs) => {
      const allIds = docs.map((doc) => doc.id)
      const folderIds = docs.filter((doc) => doc.is_folder).map((doc) => doc.id)

      setIds((prev) => [...prev, ...allIds])
      setFolders((prev) => [...prev.slice(1), ...folderIds])
    })
  }, [folders])

  const handleConfirm = () => {
    const url = `/nsi/v1/documents?` + ids.map((id) => `ids=${id}`).join('&')

    setLoading(true)
    http
      .delete(url)
      .then((resp) => {
        handleHttpResponse(resp)
        props.onClose()
      })
      .catch((err) => {
        handleHttpError(err)
        setLoading(false)
      })
  }

  return (
    <Modal onClose={props.onClose}>
      <h2 style={{ textAlign: 'center' }}>
        {intl.formatMessage({ id: 'documents.actions.delete_confirm' }, { count: ids.length })}
      </h2>

      <ModalFooter
        loading={loading}
        onCancel={props.onClose}
        onSave={handleConfirm}
        saveMessage={intl.formatMessage({ id: 'common.delete' })}
      />
    </Modal>
  )
}

interface Props {
  selection: string[]
  onClose: () => void
  resource: string
  resource_item_id: string | number
}

export default DeleteModal
