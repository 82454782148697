import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import ChartsList, { ChartListData } from '../../../shared/LineCharts/ChartsList'
import memoize from '../../../utils/memoize'
import { Equipment } from 'au-nsi/equipment'
import { RESERVED_CHART_IDS } from '../../../services/data/constants'

const parameters = {
  frequency: 'Frequency',
  activePower: 'PowerActiveTriPhase',
  reactivePower: 'PowerReactiveTriPhase',
  voltageMagnitude: 'VoltageMagnitudePhaseA',
  voltageAngle: 'VoltageAnglePhaseA',
  currentMagnitude: 'CurrentMagnitudePhaseA',
  currentAngle: 'CurrentAnglePhaseA',
  dfrequency: 'Dfrequency',
}

const ID = RESERVED_CHART_IDS.TRENDS

class TrendsCharts extends React.Component<Props> {
  private charts: ChartListData[] = [
    { id: ID, settings: { title: 'frequency', lines: [] } },
    { id: ID + 1, settings: { title: 'dfrequency', lines: [] } },
    { id: ID + 2, settings: { title: 'activePower', lines: [] } },
    { id: ID + 3, settings: { title: 'reactivePower', lines: [] } },
    { id: ID + 4, settings: { title: 'voltageMagnitude', lines: [] } },
    { id: ID + 5, settings: { title: 'voltageAngle', lines: [] } },
    { id: ID + 6, settings: { title: 'currentMagnitude', lines: [] } },
    { id: ID + 7, settings: { title: 'currentAngle', lines: [] } },
  ]

  private selectCharts = memoize((trends, equipment) => {
    return this.charts
      .filter((chart) => trends[chart.settings.title])
      .map((chart) => {
        const title = this.props.intl.formatMessage({ id: 'pq.trends.' + chart.settings.title })
        const parameter = parameters[chart.settings.title]
        const lines = equipment.map((e) => ({ device_id: e.id, parameter_id: parameter, color: e.color }))
        const settings = { ...chart.settings, title, lines }

        return { ...chart, settings }
      })
  })

  render() {
    const { showAsGrid, trends } = this.props
    const equipment = this.props.equipment.filter((r) => r.visible)
    const charts = this.selectCharts(trends, equipment)

    return (
      <div className="pq-trends-container">
        <ChartsList showAsGrid={showAsGrid} charts={charts} />
      </div>
    )
  }
}

interface Props {
  equipment: Equipment[]
  trends: { [key: string]: boolean }
  showAsGrid: boolean
  intl: IntlShape
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    trends: state.pq.trends,
    showAsGrid: state.pq.showAsGrid,
  }
}

export default connect(mapStateToProps)(injectIntl(TrendsCharts))
