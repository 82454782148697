import { API_URL } from '../../../shared/constants'
import { IDocument } from 'au-nsi/documents'

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).slice((reader.result as string).indexOf(',') + 1))
    reader.onerror = (error) => reject(error)
  })

export const parseFilename = (filename: string) => {
  const index = filename.lastIndexOf('.')
  if (index === -1) throw new Error(`Invalid filename ${filename}`)

  const name = filename.slice(0, index)
  const extension = filename.slice(index + 1)
  return { name, extension }
}

export const comparator = (a: IDocument, b: IDocument) => {
  if (a.parent_id !== b.parent_id) return compareParents(a.parent_id, b.parent_id)
  else if (a.type === 'folder' && a.type === b.type) return booleanOrder(a.type !== 'folder')
  else return stringOrder(a.name, b.name)
}

const compareParents = (a: string, b: string) => {
  if (!a) return -1
  else if (!b) return 1
  else return stringOrder(a, b)
}

const booleanOrder = (a: boolean) => (a ? -1 : 1)

const stringOrder = (a: string, b: string) => {
  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}

// открытие ссылки на файл в новом окне
export const openFile = (doc, token: string) => {
  const name = doc.name + '.' + doc.extension

  window.open(API_URL + `/nsi/v1/documents/files/${doc.id}/${encodeURIComponent(name)}?accessToken=${token}`)
}

/**
 * Ограничение длины строки. При превышении maxLength будет вырезан участок из середины и заменен на троеточие.
 */
export const truncateName = (name: string, maxLength: number) => {
  const len = name.length
  if (len <= maxLength) return name

  const half = Math.floor(maxLength / 2) - 1
  return name.slice(0, half) + '...' + name.slice(len - half)
}
