import produce from 'immer'
import { useRecoilState } from 'recoil'
import * as api from './userNotifications.api'
import { useCallback } from 'react'
import { NotificationAckEvent } from './userNotifications.types'
import { userNotificationsOnlyReadKey, userNotificationsState } from './userNotifications.state'
import { IUserNotification } from 'au-nsi/notifications'

const pageSize = 20

/**
 * Методы для работы со списокм пользовательских уведомлений
 */
const useUserNotifications = () => {
  const [state, setState] = useRecoilState(userNotificationsState)

  const { notifications, nextPage, onlyUnread, unreadCount } = state

  const setOnlyUnread = (value: boolean) => {
    if (value) localStorage.setItem(userNotificationsOnlyReadKey, 'true')
    else localStorage.removeItem(userNotificationsOnlyReadKey)

    setState((prev) => ({ ...prev, onlyUnread: value }))
  }

  const loadNotifications = (page: string) => {
    api.loadUserMessages(pageSize, page, onlyUnread).then(({ results, nextPage }) => {
      const isFirstPage = !page
      setState((prev) => ({
        ...prev,
        nextPage,
        notifications: isFirstPage ? results : [...prev.notifications, ...results],
        secondPage: isFirstPage ? nextPage : prev.secondPage,
      }))
    })
  }

  // отметка о прочтении сообщения
  const ack = useCallback((event: NotificationAckEvent, removeAcked = false) => {
    setState((prev) =>
      produce(prev, (draft) => {
        // отметить все сообщения до указанного времени как прочитанные
        if (event.batch) {
          for (const item of draft.notifications) {
            if (item.created_at <= event.ack_ts) item.ack_ts = event.ack_ts
          }

          draft.unreadCount = draft.notifications.filter((e) => !e.ack_ts).length
        }

        // отметка одного сообщения
        if (!event.batch) {
          const item = draft.notifications.find((e) => e.id === event.notification_id)

          // среди загруженных сообщений такого нет, значит отмечено более старое
          if (!item) draft.unreadCount -= 1

          // сообщение есть и оно не прочитано
          if (item && !item.ack_ts) {
            draft.unreadCount -= 1
            item.ack_ts = event.ack_ts
          }
        }

        if (removeAcked) {
          draft.notifications = draft.notifications.filter((e) => !e.ack_ts)
        }
      })
    )
  }, [])

  // отметка сообщения как непрочитанного
  const unack = useCallback((event: NotificationAckEvent) => {
    setState((prev) =>
      produce(prev, (draft) => {
        const item = draft.notifications.find((e) => e.id === event.notification_id)

        // сообщения нет в стейте, видимо пользователь отметил одно из более старых
        // сообщений из другой вкладки
        if (!item) draft.unreadCount += 1

        // сообщение есть и оно прочитано (если бы оно уже было непрочитанным,
        // то увеличивать unreadCount было бы не нужно)
        if (item && item.ack_ts) {
          draft.unreadCount += 1
          item.ack_ts = null
        }
      })
    )
  }, [])

  const append = (ntf: IUserNotification) => {
    setState((prev) => ({ ...prev, notifications: [ntf, ...prev.notifications], unreadCount: prev.unreadCount + 1 }))
  }

  // сброс всех уведомлений за пределами первой страницы
  const reset = () => {
    setState((prev) => ({ ...prev, notifications: prev.notifications.slice(0, pageSize), nextPage: prev.secondPage }))
  }

  const reload = () => {
    loadNotifications(null)
    api.loadUnreadCount().then((unreadCount) => setState((prev) => ({ ...prev, unreadCount })))
  }

  const loadMore = () => loadNotifications(nextPage)

  return {
    notifications,
    isMore: nextPage != null,
    unreadCount,
    onlyUnread,
    append,
    ack,
    unack,
    setOnlyUnread,
    reload,
    reset,
    loadMore,
  }
}

export default useUserNotifications
