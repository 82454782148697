import useGroupsEnabledStatus from '../../Groups/hooks/useGroupsEnabledStatus'
import GroupPanel from '../../Groups/components/GroupPanel/GroupPanel'
import HideableLeftMenu from '../../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import styles from '../../maptopology.module.css'
import panelStyles from '../mapLeftPanel.module.css'
import PrevButton from '../../../../shared/Utils/PrevButton'
import ModeToggle from '../ModeToggle'
import PlaceDevicesPanel from './PlaceDevicesPanel'
import GroupsPanel from '../../Groups'
import useGroup from '../../Groups/hooks/useGroup'
import { atom, useRecoilState } from 'recoil'
import usePlaceGroups from '../../Groups/hooks/usePlaceGroups'
import { Equipment } from 'au-nsi/equipment'
import { IPlace } from 'au-nsi/shared'
import { IGroup } from 'au-nsi-elevator/groups'

interface IProps {
  devices: Equipment[]
  selectedPlace: IPlace
  selectedId: Equipment['id']
  onCreateDevice: () => void
  showControls: boolean
  onPlaceSelect: (place: IPlace) => void
}

export const recoilPlacePanelState = atom<{ selectedGroupId: IGroup['id']; panelMode: 'place' | 'groups' }>({
  key: 'maps:placePanel',
  default: { selectedGroupId: null, panelMode: 'place' },
})

const PlacePanel = (props: IProps) => {
  const [state, setState] = useRecoilState(recoilPlacePanelState)

  const group = useGroup(state.selectedGroupId)
  const groups = usePlaceGroups(props.selectedPlace)
  const groupsEnabled = useGroupsEnabledStatus()

  if (group) {
    return <GroupPanel group={group} onPrev={() => setState((prev) => ({ ...prev, selectedGroupId: null }))} />
  }

  if (props.selectedPlace) {
    return (
      <HideableLeftMenu>
        <div className={styles.devicePanel}>
          <div className={styles.addressHeader}>
            <div className={panelStyles.panelControls}>
              <PrevButton onCLick={() => props.onPlaceSelect(undefined)} />
              {groupsEnabled && (
                <ModeToggle
                  groupsCount={groups.length}
                  equipmentCount={props.devices.length}
                  mode={state.panelMode}
                  onChange={(mode) => setState((prev) => ({ ...prev, panelMode: mode }))}
                />
              )}
            </div>
            <h2>{props.selectedPlace.name}</h2>
          </div>

          {state.panelMode === 'place' && (
            <PlaceDevicesPanel
              devices={props.devices}
              selectedPlace={props.selectedPlace}
              selectedId={props.selectedId}
              onCreateDevice={props.onCreateDevice}
              showControls={props.showControls}
            />
          )}

          {state.panelMode === 'groups' && (
            <GroupsPanel
              place={props.selectedPlace}
              onGroupSelect={(id) => setState((prev) => ({ ...prev, selectedGroupId: id }))}
            />
          )}
        </div>
      </HideableLeftMenu>
    )
  }
}

export default PlacePanel
