import GroupsLoader from './GroupsLoader'
import { useMemo } from 'react'
import usePlaceGroups from '../hooks/usePlaceGroups'
import { IPlace } from 'au-nsi/shared'
import { IGroup } from 'au-nsi-elevator/groups'
import { Select } from '@alterouniversal/au-react-components'

interface Props {
  name: string
  groupId: IGroup['id']
  address: IPlace
  onChange: (value: IGroup['id'], name: string) => void
}

const GroupSelect = ({ groupId, address, ...props }: Props) => {
  const placeGroups = usePlaceGroups(address)

  const options = useMemo(
    () => [{ label: '-', value: null }].concat(placeGroups.map((g) => ({ label: g.name, value: g.id }))),
    [placeGroups]
  )

  return (
    <GroupsLoader>
      <Select {...props} options={options} value={groupId} />
    </GroupsLoader>
  )
}

export default GroupSelect
