import { Select } from '@alterouniversal/au-react-components'
import { IAttribute } from 'au-nsi/equipment'
import { memo, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { copyAttributes, getAttributeValue, setAttribute } from '../../../shared/Attributes/attributes.utils'
import FormPanel from '../../../shared/FormPanel/FormPanel'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import Dropdown from '../../../shared/Inputs/Dropdown'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import ImportModal from '../Modals/ImportModal'
import { patchEquipment } from '../nsi.actions'
import { useAEPSOptions } from './AEPSHooks'

interface Props {
  id: string
  attributes: IAttribute[]
  allowEditing: boolean
}

/**
 * Настройки устройства необходимые для проекта АЭПС. В частности, для работы
 * графика план/факт необходимы следующие настройки:
 * - календарь выходных дней (для разных устройств могут быть разные календари)
 * - ценовая зона системного оператора, в котрой находится устройство
 * - субъект РФ коммерческого оператора, в котором находится устройство
 * - параметр в системе, соответствующий параметру SOC накопителя
 * Настройки для карты устройств:
 * - параметры, отображаемые во всплывающем меню при клике по иконке устройства
 */
const AEPSPanel = ({ id, attributes, allowEditing }: Props) => {
  const [open, setOpen] = useState(false)
  const [draft, setDraft] = useState(attributes)
  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()
  const options = useAEPSOptions(open)

  const equipment = useAppSelector((state) => state.nsi.equipment)
  const parameters = useAppSelector((state) => state.parameters.items)

  // список устройств для копирования из них уже заполненных настроек
  const importSources = useMemo(
    () => equipment.filter((e) => e.protocol === 'modbustcp-client' && e.attributes?.length),
    [equipment]
  )

  const priceZone = getAttributeValue(draft, 'aeps.price_zone')
  const federalSubject = getAttributeValue(draft, 'aeps.federal_subject')
  const socParameter = getAttributeValue(draft, 'aeps.soc_parameter_id')
  const mapParameters = getAttributeValue(draft, 'map.infowindow_parameters') || []

  const socOption = useMemo(
    () => options.parameters.find((o) => o.value === socParameter),
    [options.parameters, socParameter]
  )

  const handleChange = (value: any, key: string) => {
    setDraft((prev) => setAttribute(prev, { key, value, type: 'string' }))
  }

  const handleCancel = () => {
    setDraft(attributes)
    setEditing(false)
  }

  // импорт атрибутов из другого устройства, причем не всех, а только настраиваемых в данной форме
  const handleImport = (sourceId: string) => {
    const source = importSources.find((e) => e.id === sourceId)
    const keys = ['aeps.calendar_id', 'aeps.price_zone', 'aeps.federal_subject', 'aeps.soc_parameter_id']
    const updates = copyAttributes(source.attributes as any, attributes, keys)

    dispatch(patchEquipment(id, { attributes: updates }))
    setImporting(false)
  }

  // сохранение введенных настроек
  const handleSave = () => {
    setEditing(false)
    dispatch(patchEquipment(id, { attributes: draft }))
  }

  useEffect(() => {
    if (!editing) setDraft(attributes as IAttribute[])
  }, [attributes])

  useEffect(handleCancel, [id])

  return (
    <FormPanel id="" title="nsi.aeps_panel" open={open} onToggle={() => setOpen(!open)}>
      <table className="nsi-table nsi-connection-table is-fixed">
        <tbody>
          <tr>
            <td>{intl.formatMessage({ id: 'nsi.aeps_panel.calendar_id' })}</td>
            <td>
              <Dropdown
                name="aeps.calendar_id"
                value={getAttributeValue(draft, 'aeps.calendar_id')}
                options={options.calendars}
                disabled={!editing}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'nsi.aeps_panel.price_zone' })}</td>
            <td>
              <Dropdown
                name="aeps.price_zone"
                value={priceZone}
                options={options.zones}
                disabled={!editing}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'nsi.aeps_panel.federal_subject' })}</td>
            <td>
              <Dropdown
                name="aeps.federal_subject"
                value={federalSubject}
                options={options.subjects}
                disabled={!editing}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'nsi.aeps_panel.soc_parameter_id' })}</td>
            <td>
              {editing ? (
                <Select
                  name="aeps.soc_parameter_id"
                  options={options.parameters}
                  value={socParameter}
                  disabled={!editing}
                  onChange={handleChange}
                />
              ) : (
                <span className="system__input-disabled">{socOption?.label}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'nsi.aeps_panel.map_parameters' })}</td>
            <td>
              <MultiSelect
                name="map.infowindow_parameters"
                options={parameters}
                selection={mapParameters as any}
                disabled={!editing}
                onChange={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <PanelButtons
        editing={editing}
        allowEditing={allowEditing}
        allowImport={allowEditing}
        onEdit={() => setEditing(true)}
        onCancel={handleCancel}
        onImport={() => setImporting(true)}
        onSave={handleSave}
      />

      <ImportModal
        isOpen={importing}
        onClose={() => setImporting(false)}
        targetId={id}
        sources={importSources}
        onImport={handleImport}
      />
    </FormPanel>
  )
}

export default memo(AEPSPanel)
