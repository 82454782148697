import LogbookEntryRow from './LogbookEntryRow'
import { useIntl } from 'react-intl'
import InfiniteScroll from '../../../../../shared/Pagination/InfiniteScroll'
import { AnyCatalogWithInstances } from '../../../../Catalogs/catalogs.types'
import { ILogbookEntry } from 'au-nsi/logbook'

interface IProps {
  schema: AnyCatalogWithInstances
  logbooks: ILogbookEntry[]
  onSelect: (selected: ILogbookEntry) => void
  isMore: boolean
  onLoadMore?: () => void
  selectedId?: ILogbookEntry['id']
}

const LogbookEntriesList = ({ logbooks, onLoadMore, onSelect, isMore, selectedId, schema }: IProps) => {
  const intl = useIntl()

  const rows = logbooks.map((logbook, i) => (
    <LogbookEntryRow
      key={logbook.id}
      entry={logbook}
      index={i}
      onClick={(ind) => onSelect(logbooks[ind])}
      selected={logbook.id === selectedId}
      firstValue={schema.schema[0]?.id !== undefined ? logbook.values[schema.schema[0].id] : null}
      secondValue={schema.schema[1]?.id !== undefined ? logbook.values[schema.schema[1].id] : null}
    />
  ))

  return (
    <InfiniteScroll isMore={isMore} loadMore={onLoadMore} parentSelector={'.nsi-main__wrapper'}>
      <table
        className="nsi-table is-compact"
        style={{ tableLayout: 'fixed', marginBottom: '2em', borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
      >
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'catalogs.date' })}</th>
            <th>{intl.formatMessage({ id: 'auth.signup.name' })}</th>
            {schema.schema[0] && <th>{schema.schema[0].name}</th>}
            {schema.schema[1] && <th>{schema.schema[1].name}</th>}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </InfiniteScroll>
  )
}

export default LogbookEntriesList
