import { ReactComponent as ExportIcon } from 'icons/download.svg'
import { ReactComponent as ImportIcon } from 'icons/upload.svg'
import { useIntl } from 'react-intl'
import { showError } from '../../../../utils/notifications'
import css from './import.module.css'
import { parseCSVContent, readCSVFile } from './import.utils'

interface Props {
  name: string
  mapping: any[]
  columns: string[]
  numericColumns: string[]
  onImport: (mapping: any[]) => void
}

/**
 * Импорт конфигурации данных в файл и экспорт из файла
 */
const MappingImport = ({ name, mapping, columns, numericColumns, onImport }: Props) => {
  const intl = useIntl()

  const handleExport = () => {
    const header = columns.join(',')
    const content = mapping.map((row) => columns.map((c) => row[c]).join(',')).join('\r\n')
    const csv = header + '\r\n' + content

    const blob = new Blob([csv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = name + '.csv'
    a.click()

    requestAnimationFrame(() => URL.revokeObjectURL(url))
  }

  // импорт пока что возможен только в том же формате, в котором данные экспортируются
  const handleImport = () => {
    readCSVFile((content) => {
      const { data, error } = parseCSVContent(content, columns, numericColumns)
      if (error) return showError(error)
      else return onImport(data)
    })
  }

  const exportTitle = intl.formatMessage({ id: 'nsi.parameters.export' })
  const importTitle = intl.formatMessage({ id: 'nsi.parameters.import' })

  return (
    <>
      <span className={css.iconBtn} onClick={handleExport} title={exportTitle}>
        <ExportIcon height={18} />
      </span>
      <span className={css.iconBtn} onClick={handleImport} title={importTitle}>
        <ImportIcon height={18} />
      </span>
    </>
  )
}

export default MappingImport
