import React from 'react'
import { onResize } from '../../../hooks/useResize'
import { Boundaries } from '../../../shared/interfaces'
import { Unsubscribe } from '../../../utils/events'
import GanttContext from '../gantt.context'
import TimelineCursor from './TimelineCursor'
import TimelineSummary from './TimelineSummary'

const MIN = 60_000
const HOUR = 60 * MIN

class GanttTimeline extends React.Component<unknown, State> {
  context: React.ContextType<typeof GanttContext>
  static contextType = GanttContext

  state: State = {
    boundaries: { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0 },
    ganttTime: Date.now(),
    ganttFrame: 3600 * 1000,
    playerTime: Date.now(),
  }

  private ganttUnsubscribe: Unsubscribe
  private clearResize: () => void

  private container = React.createRef<HTMLDivElement>()
  private tickSteps = [MIN, 5 * MIN, 10 * MIN, 15 * MIN, 20 * MIN, 30 * MIN, HOUR, 3 * HOUR, 6 * HOUR, 12 * HOUR]

  componentDidMount() {
    this.clearResize = onResize(this.container, this.handleResize)

    this.ganttUnsubscribe = this.context.service.time$.subscribe((times) => {
      this.setState({
        ganttTime: times.ganttTime,
        ganttFrame: times.frame,
        playerTime: times.playerTime,
      })
    })
  }

  componentWillUnmount() {
    this.ganttUnsubscribe()
    this.clearResize()
  }

  // рассчитать метки времени на которые необходимо поместить линии сетки и подписи
  private generateTicks() {
    const maxTicksCount = Math.ceil(this.state.boundaries.width / 60)
    const steps = this.tickSteps

    // находим минимальный шаг, при котором расстояние между метками будет больше 60px
    const step = steps.find((s) => this.state.ganttFrame / s <= maxTicksCount) || steps[steps.length - 1]

    let start = this.state.ganttTime - this.state.ganttFrame
    start = start - (start % step)
    const ticks = [start]

    for (let i = 0; i < maxTicksCount; i++) {
      ticks.push(start + step * (i + 1))
    }

    return ticks
  }

  private handleResize = () => {
    const node = this.container.current
    const boundaries = node.getBoundingClientRect()

    this.context.service.boundaries = boundaries
    this.setState({ boundaries })
  }

  render() {
    const { boundaries } = this.state
    const { width } = boundaries
    const t1 = this.state.ganttTime
    const t0 = t1 - this.state.ganttFrame

    const axis = this.generateTicks().map((ts) => {
      const x = Math.round((width * (ts - t0)) / (t1 - t0))

      return (
        <g key={ts}>
          <line x1={x} x2={x} y1={20} y2={30} />
          <text x={x} y={17} textAnchor="middle">
            {formatTime(ts)}
          </text>
        </g>
      )
    })

    return (
      <div ref={this.container} className="gantt__timeline">
        <svg viewBox={`0 0 ${width} 30`}>{axis}</svg>

        <TimelineSummary boundaries={boundaries} ganttService={this.context.service} />
        <TimelineCursor {...this.state} />
      </div>
    )
  }
}

const formatTime = (ts: number) => {
  const d = new Date(ts)
  const h = d.getHours().toString().padStart(2, '0')
  const m = d.getMinutes().toString().padStart(2, '0')
  return h + ':' + m
}

interface State {
  boundaries: Boundaries
  ganttTime: number
  ganttFrame: number
  playerTime: number
}

export default GanttTimeline
