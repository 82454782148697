import { ITag } from 'au-nsi/shared'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import PlusButton from '../../../shared/Forms/PlusButton'
import { handleHttpError } from '../../../utils/http'
import TagEditRow from './TagEditRow'
import * as api from './tags.api'
import { tagsActions } from './tags.state'

interface Props {
  onCreate: (tag: ITag) => void
}

/**
 * Строка создания нового тэга
 */
const TagCreateRow = ({ onCreate }: Props) => {
  const dispatch = useDispatch()

  const [creating, setCreating] = useState(false)

  if (!creating) {
    return (
      <PlusButton
        textId="nsi.device.tags.add"
        style={{ margin: 0, lineHeight: '36px' }}
        onClick={() => setCreating(true)}
      />
    )
  }

  const handleCancel = () => setCreating(false)

  const handleSave = (tag: ITag) => {
    if (!tag.name) return

    api
      .createTag(tag)
      .then((r) => {
        dispatch(tagsActions.tagCreated(r))
        onCreate(r)
      })
      .catch(handleHttpError)

    handleCancel()
  }

  return <TagEditRow tag={{ id: 0, name: '' }} onCancel={handleCancel} onSave={handleSave} />
}

export default TagCreateRow
