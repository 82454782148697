export interface GridInfo {
  step: number
  values: number[]
}

/** Зная минимальное и максимальное значение по оси Y рассчитать шаг сетки
    так, чтобы получилось не более maxLines линии сетки */
export const calcYGrid = (minY: number, maxY: number, maxLines: number): GridInfo => {
  const step = roundStep((maxY - minY) / maxLines)

  const values: number[] = []
  const result = { step, values }
  if (!step) return result

  let value = minY - (minY % step)
  while (value < maxY) {
    if (value > minY) values.push(value)
    value += step
  }

  return result
}

/**
 * Округлить число вверх до второй значащей цифры делящейся на 5
 */
export const roundStep = (x: number): number => {
  if (x <= 0 || !x) return 0

  let multiplier = 1
  let step = x

  while (step < 10 || step > 100) {
    const factor = step < 10 ? 10 : 0.1

    step *= factor
    multiplier *= factor
  }

  const result = (5 * Math.ceil(step / 5)) / multiplier
  return result > 10 ? Math.round(result) : result
}
