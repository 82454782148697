import { FormMode } from '../../shared/interfaces'
import { generateReducers } from '../Collections/collection.reducers'
import * as utils from './constructor/ast.utils'
import { Formula } from './formulas.interfaces'

export interface FormulasState {
  mode: FormMode
  items: Formula[]
  selectedItem: Formula
  selectedItemId: number
  isLoaded: boolean
}

const defaultState: FormulasState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
}

const newFormula = (action): Formula => {
  return {
    id: 0,
    type: action.formula_type,
    parameter_id: null,
    ast: utils.factories.null(),
    src: 'return math.sqrt(2)',
    is_global: false,
    meta: { arguments: [] },
    equipment: [],
    equipment_matcher: 'eq',
    region_id: null,
    status: 'unknown',
    error: null,
  }
}

const reducers = generateReducers<Formula>('formulas', newFormula)

const formulasReducer = (state: FormulasState = defaultState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default formulasReducer
