import { IncidentsState } from './incident.interfaces'

export const ADD_INCIDENT = 'app/incidents/ADD'
export const SET_INCIDENTS = 'app/incidents/SET'
export const SET_SELECTED_INCIDENT = 'app/incidents/SET_SELECTED_INCIDENT'
export const UPDATE_INCIDENT = 'app/incidents/UPDATE'
export const REMOVE_INCIDENT = 'app/incidents/REMOVE'
export const SET_ARCHIVE_RANGE = 'app/incidents/SET_ARCHIVE_RANGE'
export const SET_ARCHIVE_DATA = 'app/incidents/SET_ARCHIVE_DATA'
export const SET_AUDIO = 'app/incidents/SET_AUDIO'

export const TIMERANGE = 12 * 60 * 60 * 1000 // 12 hours in ms, time to keep current incidents

type Reducer = (state: IncidentsState, action: any) => IncidentsState

const initialState: IncidentsState = {
  incidents: [],
  incidents_start: Date.now() - TIMERANGE,
  archive: [],
  archive_start: 0,
  archive_end: 0,
  isLoaded: false,
  selectedIncidentId: null,
  audioId: null,
}

const incidentsReducer = (state: IncidentsState = initialState, action) => {
  const reducer = reducers[action.type]
  return reducer != null ? reducer(state, action) : state
}

export default incidentsReducer

const reducers: { [name: string]: Reducer } = {
  [ADD_INCIDENT]: (state, action) => {
    const { incident } = action

    const threshold = (Date.now() - TIMERANGE) * 1000
    const incidents = [incident]

    for (const item of state.incidents) {
      // отфильтровать инциденты вышедшие за рамки активного окна
      if (item.ts_end === 0 || item.ts_end > threshold) {
        incidents.push(item)
      }
    }

    return { ...state, incidents }
  },

  [SET_INCIDENTS]: (state, action) => {
    const { incidents } = action
    return { ...state, incidents, isLoaded: true }
  },

  [UPDATE_INCIDENT]: (state, action) => {
    const { id, updates } = action

    const incidents = state.incidents.map((incident) => {
      return incident.id === id ? { ...incident, ...updates } : incident
    })

    return { ...state, incidents }
  },

  [REMOVE_INCIDENT]: (state, action) => {
    const incidents = state.incidents.filter((i) => i.id !== action.id)
    return { ...state, incidents }
  },

  [SET_ARCHIVE_RANGE]: (state, action) => {
    return { ...state, archive_start: action.start, archive_end: action.end }
  },

  [SET_ARCHIVE_DATA]: (state, action) => {
    return { ...state, archive: action.data }
  },

  [SET_SELECTED_INCIDENT]: (state, { id }) => {
    return { ...state, selectedIncidentId: id }
  },

  [SET_AUDIO]: (state, { audioId }) => {
    return { ...state, audioId }
  },
}
