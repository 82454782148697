import { IDashboard } from 'au-nsi/dashboards'
import { Image } from 'au-nsi/images'
import { ReactComponent as PrivateIcon } from 'icons/private.svg'
import React from 'react'
import { useIntl } from 'react-intl'
import useContextMenu from '../../../hooks/useContextMenu'
import useFlip from '../../../hooks/useFlip'
import { ReactComponent as TemplateIcon } from '../../../icons/copy.svg'
import { ReactComponent as SharedIcon } from '../../../icons/shared.svg'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'
import useDashboardPlaceholderUrl from './useDashboardPlaceholderUrl'

const DashboardsTile = (props: Props) => {
  const defaultBackgroundUrl = useDashboardPlaceholderUrl()
  const menuRef = React.useRef<HTMLDivElement>()

  const intl = useIntl()
  const { element, handleTransitionEnd } = useFlip(
    [props.dashboard.user_ordering_index, props.dashboardsLength],
    !props.isDrag && !props.isFilter
  )
  const tileRef = element

  const { dashboard, allowEditing } = props
  const { open, setOpen } = useContextMenu(tileRef, menuRef, !!allowEditing)

  React.useEffect(() => {
    props.shouldPositionUpdate && props.shouldPositionUpdate.update && handleTransitionEnd()
  }, [props.shouldPositionUpdate])

  const handleClick = (ev) => {
    if (ev.ctrlKey) return props.onSelect && handleSelect()

    !props.isDrag && props.onClick(dashboard)
  }

  const handleEdit = (e) => {
    e.stopPropagation()
    props.onEdit(dashboard)
    setOpen(false)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    props.onDelete(dashboard)
    setOpen(false)
  }

  const handleCopy = (e) => {
    e.stopPropagation()
    props.onCopy(dashboard)
    setOpen(false)
  }

  const handleSelect = () => {
    setOpen(false)
    props.onSelect && props.onSelect(dashboard.id, !props.selected)
  }

  const allowSelect = allowEditing && !dashboard.is_system && props.onSelect != null
  const allowCopy = allowEditing && !dashboard.is_system && !props.editing === true
  const allowDelete = allowEditing && !dashboard.is_system

  const menu = (
    <ContextMenu ref={menuRef} open={open}>
      {allowSelect && <ContextMenuOption type={props.selected ? 'cancel' : 'select'} onClick={handleSelect} />}
      {allowEditing && <ContextMenuOption type={'edit'} onClick={handleEdit} />}
      {allowCopy && <ContextMenuOption type={'copy'} onClick={handleCopy} />}
      {allowDelete && <ContextMenuOption type={'delete'} onClick={handleDelete} />}
    </ContextMenu>
  )

  const backgroundImage = props.image ? `url("${props.image.src_url}")` : `url(${defaultBackgroundUrl})`
  const isDraggable = allowEditing && !props.isFilter

  return (
    <div ref={tileRef} className={'dashboards__tile_wrapper'} style={props.isDrag ? { zIndex: 999999 } : {}}>
      <div className={`dashboards__tile${props.isDrop ? ' dashboard__dropping' : ''}`} id={dashboard.id}>
        {menu}
        <div
          onDragStart={(e) => {
            isDraggable && props.onDragStart(e as any, dashboard.id)
          }}
          className={`dashboards__tile-content${props.isDropFolder ? ' dashboard__dropping_folder' : ''}${
            props.selected ? ' dashboards__tile__is_selected' : ''
          }`}
          draggable={isDraggable}
          onClick={handleClick}
          data-test-id="dashboard"
          data-test-name={dashboard.name}
        >
          <div className={'dashboards__tile-image'} style={{ backgroundImage }} />

          {allowEditing && (
            <div className="dashboards__tile-icons">
              {dashboard.is_system && <PrivateIcon />}
              {dashboard.is_template && <TemplateIcon style={{ width: 16, height: 16, opacity: 0.8 }} />}
              {dashboard.access.restricted && <SharedIcon />}
            </div>
          )}
        </div>

        <div className={`dashboards__tile-name`} style={props.isDrop ? { visibility: 'hidden' } : {}}>
          <span className="line_clamp" title={props.isDropFolder ? '' : dashboard.name}>
            {props.isDropFolder ? intl.formatMessage({ id: 'dashboards.create_folder' }) : props.title}
          </span>
        </div>
      </div>
    </div>
  )
}

interface Props {
  allowEditing: boolean
  dashboard: IDashboard
  dashboardsLength: number
  image: Image
  title: string | JSX.Element
  onClick: (d: IDashboard) => void
  onCopy: (d: IDashboard) => void
  onDelete: (d: IDashboard) => void
  onEdit: (d: IDashboard) => void
  onDragStart: (e: DragEvent, id: string | number) => void
  isDrag: boolean
  isDrop: boolean
  isDropFolder: boolean
  isFilter?: boolean
  shouldPositionUpdate?: { update: boolean }
  selected?: boolean
  onSelect?: (id: string, selected: boolean) => void
  editing?: boolean
}

export default React.memo(DashboardsTile)
