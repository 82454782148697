import React, { useRef } from 'react'
import useDnD from '../../../hooks/useDnD'
import useFlip from '../../../hooks/useFlip'
import { ReactComponent as PauseIcon } from '../../../icons/pause.svg'
import { ReactComponent as PlayIcon } from '../../../icons/play.svg'
import { API_URL } from '../../../shared/constants'
import css from './audio.module.css'
import useContextMenu from '../../../hooks/useContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import { IAudio } from 'au-nsi/audio'

const AudioItem = (props: Props) => {
  const { audio, isPlaying } = props

  // обработка изменения порядка перетаскиванием мыши
  const containerRef = useFlip([audio.user_ordering_index, props.index]).element
  const menuRef = useRef(null)

  const dndHandlers = useDnD({
    id: audio.id,
    onDrop: (id) => props.onMove(id, audio.id),
    prefix: 'audio:',
    ref: containerRef,
    style: { background: 'var(--selection-70)' },
  })

  const menu = useContextMenu(containerRef, menuRef, props.allowEditing)

  React.useEffect(() => {
    menu.open && props.onContextMenuOpen()
  }, [menu.open])

  // запуск воспроизведения звука
  React.useEffect(() => {
    if (isPlaying) {
      const element = new Audio(API_URL + `/nsi/v1/audio/${audio.id}/raw`)

      element.addEventListener('ended', () => props.onPause())
      element.addEventListener('canplaythrough', () => {
        element.play()
      })

      // остановка воспроизведения при нажатии на стоп либо при удалении компонента
      return () => element.pause()
    }
  }, [isPlaying])

  const handleDownload = () => {
    const fileLink = API_URL + `/nsi/v1/audio/${audio.id}/raw/${encodeURIComponent(audio.name)}`
    const element = document.createElement('a')
    element.href = fileLink
    element.download = audio.name
    element.target = '_blank'

    element.click()
  }

  const iconProps = { width: '14', height: '14', className: css.icon, style: { marginRight: 8, opacity: 0.6 } }
  const playIcon = <PlayIcon {...iconProps} onClick={() => props.onPlay(audio.id)} />
  const pauseIcon = <PauseIcon {...iconProps} onClick={props.onPause} />

  const renderMenu = () => {
    return (
      <ContextMenu ref={menuRef} open={menu.open} onClick={() => menu.setOpen(false)}>
        {props.selectedCount < 2 && <ContextMenuOption type={'download'} onClick={handleDownload} />}
        {props.allowEditing && props.selectedCount < 2 && (
          <ContextMenuOption type={'edit'} onClick={() => props.onEdit(audio)} />
        )}
        {props.allowEditing && <ContextMenuOption type={'delete'} onClick={() => props.onDelete(audio.id)} />}
      </ContextMenu>
    )
  }

  const dndProps = props.selected || !props.allowEditing ? {} : dndHandlers
  return (
    <div
      style={props.selected ? { background: 'var(--selection-70)' } : {}}
      draggable={true}
      ref={containerRef}
      className={css.block}
      {...dndProps}
    >
      {isPlaying ? pauseIcon : playIcon}
      <div
        style={{ cursor: 'pointer', width: '100%', height: '100%', padding: '10px 0' }}
        onClick={() => props.onClick(audio.id, !props.selected)}
      >
        <span>{props.audio.name}</span>
      </div>
      {renderMenu()}
    </div>
  )
}

interface Props {
  allowEditing: boolean
  audio: IAudio
  index: number
  isPlaying: boolean
  onDelete: (id: string) => void
  onEdit: (file: IAudio) => void
  onMove: (source: string, target: string) => void
  onPause: () => void
  onPlay: (id: string) => void
  onClick: (id: string, selected: boolean) => void
  onContextMenuOpen: () => void
  selected: boolean
  selectedCount: number
}

export default React.memo(AudioItem)
