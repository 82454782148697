import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { selectAccessRights } from '../../App/app.selectors'
import { useSettingsAPI } from './settings.hooks'
import renderFooter from '../system.footer'
import { DQSettings } from 'au-nsi/settings'

const properties = ['max_width_of_ejection_frame', 'max_width_of_missing_frame']

const DataQualityPage = () => {
  const allowEditing = useSelector(selectAccessRights)['settings:update']
  const translations = useSelector((s: ReduxState) => s.language.translations)

  const state = useSettingsAPI<DQSettings>('ms-dq')

  const handleChange = (value: number, key: string) => {
    state.updateDraft({ [key]: value })
  }

  if (state.settings == null || state.draft == null) {
    return null
  }

  const rows = properties.map((p) => {
    return (
      <div key={p}>
        <div className="system__label">{translations['settings_dq.' + p]}</div>
        <div className="system__input-wrapper">
          <NumberInput
            type="number"
            name={p}
            value={state.draft[p]}
            onChange={handleChange}
            disabled={!state.editing}
            disabledStyle="text"
            integer={true}
            min={0}
          />
        </div>
      </div>
    )
  })

  return (
    <div className="nsi-main__container">
      <h2 className="system__title">
        <FormattedMessage id="nav.system.dq" />
      </h2>

      <Form editing={state.editing} onCancel={state.cancelDraft} onSubmit={state.updateSettings}>
        <div className="system__grid">{rows}</div>
        {allowEditing && renderFooter(state)}
      </Form>
    </div>
  )
}

export default DataQualityPage
