import { IUser, UserRole } from 'au-nsi/user'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, useDispatch } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { FormMode } from '../../../shared/interfaces'
import { selectAccessRights } from '../../App/app.selectors'
import { User } from '../collection.interfaces'
import { FormButtonsHOC } from '../collection.utils'
import UserDevicesForm from './DevicesForm/UserDevicesForm'
import UserControls from './UserControls'
import UserForm from './UserForm'
import actions from './users.actions'
import css from './users.module.css'
import UserSecurityStatus from './UserSecurityStatus'
import UserStatus from './UserStatus'

const FormButtons = FormButtonsHOC(actions)

const formTitles = {
  view: 'nsi.user_info',
  create: 'nsi.user_create',
  edit: 'nsi.user_edit',
  delete: 'nsi.user_delete',
}

const UserInfo = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { mode, user } = props
  const editing = mode === 'create' || mode === 'edit'

  const [screen, setScreen] = useState<'profile' | 'devices'>('profile')

  useEffect(() => {
    if (screen !== 'profile') setScreen('profile')
  }, [user && user.id])

  if (!user) return null
  if (screen === 'devices') return <UserDevicesForm user={user} onClose={() => setScreen('profile')} />

  const suspendAccount = () => {
    dispatch(actions.suspendAccount(user.id, !user.suspended))
  }

  const handleChange = (value: any, key: string) => {
    dispatch(actions.updateSelectedItem(key, value))
  }

  return (
    <div className="nsi-main__wrapper">
      <div className="nsi-main__container">
        <div className={css.header}>
          <h2 style={{ margin: 0 }}>{intl.formatMessage({ id: formTitles[mode] })}</h2>
          {mode === 'view' && (
            <UserControls
              allowDevicesScreen={props.allowDevicesScreen}
              allowModeration={props.allowModeration}
              onDevicesScreen={() => setScreen('devices')}
              onSuspend={suspendAccount}
              suspended={user.suspended}
            />
          )}
        </div>

        <div>
          <UserStatus user={user} />
          <UserSecurityStatus user={user} />

          <UserForm
            user={user}
            editing={editing}
            roles={props.roles}
            onChange={handleChange}
            allowChangingCredentials={mode === 'create' || props.allowChangingCredentials}
            allowChangingOrgType={mode === 'create'}
          />
        </div>

        <FormButtons
          allowDeleting={props.allowDeleting}
          allowEditing={props.allowEditing}
          dispatch={dispatch}
          intl={intl}
          mode={mode}
          selectedItem={user}
          validate={validateUser}
        />
      </div>
    </div>
  )
}

interface Props {
  allowModeration: boolean
  allowChangingCredentials: boolean
  allowDevicesScreen: boolean
  allowDeleting: boolean
  allowEditing: boolean
  mode: FormMode
  roles: UserRole[]
  user: IUser
}

function validateUser(user: User) {
  if (!user.name) return { id: 'nsi.user.errors.empty_name' }
  if (!user.role_id) return { id: 'nsi.user.errors.empty_role' }
  if (!user.username) return { id: 'nsi.user.errors.empty_login' }

  // при добавлении нового пользователя пароль обязателен
  if (!user.id || user.password) {
    if (!user.password || user.password.length < 4) return { id: 'nsi.user.errors.invalid_password' }
    if (user.password !== user.password_confirm) return { id: 'nsi.user.errors.invalid_password_confirm' }
  }

  if (!user.password) user.password = undefined
  return null
}

const mapStateToProps = (state: ReduxState) => {
  const rights = selectAccessRights(state)
  const selectedUser = state.users.selectedItem
  const isCurrentUser = selectedUser && selectedUser.id === state.user.id
  const isRegisteredUser = selectedUser && selectedUser.registration
  const isAdmin = selectedUser && selectedUser.id === 1

  // do not allow user to deactivate his own account
  const allowModeration = rights['users:moderate'] && !isCurrentUser && !isAdmin
  const allowDeleting = rights['users:delete'] && !isCurrentUser && !isAdmin

  // изменять логин и пароль может сам пользователь
  // либо администратор, но только у созданных им же аккаунтов
  const allowChangingCredentials = isCurrentUser || (state.user.is_admin && !isRegisteredUser)

  let roles = state.roles.items
  if (isAdmin) roles = roles.filter((r) => r.id === 1) // запретить изменять роль администратора

  return {
    mode: state.users.mode,
    user: selectedUser,
    roles,
    allowModeration,
    allowDevicesScreen: rights['equipment:update_access'] && !isAdmin,
    allowEditing: rights['users:update'],
    allowDeleting,
    allowChangingCredentials,
  }
}

export default connect(mapStateToProps)(UserInfo)
