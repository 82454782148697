import { Select } from '@alterouniversal/au-react-components'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import dashboardActions from '../dashboard.actions'
import css from './template.module.css'

/**
 * Форма задания переменных шаблона. Применима только к экранам-шаблонам.
 */
const TemplateVariables = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const equipment = useSelector((state: ReduxState) => state.nsi.equipment)
  const dashboards = useSelector((state: ReduxState) => state.dashboards.dashboards)
  const variableValues = useSelector((state: ReduxState) => state.dashboards.templateVariables[props.dashboard]) || {}

  const dashboard = dashboards.find((d) => d.id === props.dashboard)
  const settings = dashboard?.template_settings

  // оставляем только устройства с тэгами, указанными в настройках экрана
  const equipmentOptions = useMemo(() => {
    if (!dashboard || !settings) return []

    return equipment
      .filter((e) => !settings.tags || !settings.tags.length || settings.tags.some((t) => e.tags.includes(t)))
      .map((e) => ({ value: e.id, label: e.name }))
  }, [equipment, dashboard])

  if (!dashboard) return null

  if (!dashboard.is_template) {
    return <div className={css.error}>{intl.formatMessage({ id: 'dashboards.template.not_template' })}</div>
  }

  if (!settings || !settings.variables || !settings.variables.length) {
    return <div className={css.error}>{intl.formatMessage({ id: 'dashboards.template.no_variables' })}</div>
  }

  const handleChange = (value: string, device_id: string) => {
    const variables = { ...variableValues, [device_id]: value }
    dispatch(dashboardActions.setTemplateVariables({ dashboard_id: dashboard.id, variables }))
  }

  const variables = settings.variables.filter((v) => v.name && v.device_id).sort((a, b) => a.name.localeCompare(b.name))

  // Select с выбором любого устройства для каждой из переменных
  const inputs = variables.map(({ name, device_id }) => {
    const value = variableValues[device_id] || device_id

    return (
      <div key={device_id}>
        <div style={{ marginTop: 6 }} className="system__label">
          {name}
        </div>
        <Select name={device_id} options={equipmentOptions} value={value} onChange={handleChange} />
      </div>
    )
  })

  return <div className={css.container}>{inputs}</div>
}

interface Props {
  dashboard: string
}

export default TemplateVariables
