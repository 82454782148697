import styles from '../../maptopology.module.css'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '../../../App/app.selectors'
import DeviceTile from '../../components/DeviceTile'
import { memo, useState } from 'react'
import ConnectDeviceModal from '../../components/ConnectDevice/ConnectDeviceModal'
import { selectPassportIdentifiers } from '../../maptopology.selectors'
import { Equipment } from 'au-nsi/equipment'
import { IPlace } from 'au-nsi/shared'

interface IProps {
  devices: Equipment[]
  selectedPlace: IPlace
  selectedId: Equipment['id']
  onCreateDevice: () => void
  showControls: boolean
}

const PlaceDevicesPanel = ({ selectedPlace, devices, onCreateDevice, selectedId, showControls }: IProps) => {
  const intl = useIntl()
  const allowCreating = useSelector(selectAccessRights)['equipment:create']
  const passports = useSelector(selectPassportIdentifiers)

  const [modalType, setModalType] = useState<null | 'connect'>(null)

  const renderControls = () => {
    if (!showControls) return null

    return (
      <div className={styles.addressFooter}>
        {passports.length > 0 && (
          <button className="nsi-button default" onClick={() => setModalType('connect')}>
            {intl.formatMessage({ id: 'system.maps.connect_equipment' })}
          </button>
        )}
        {allowCreating && (
          <button className="nsi-button default" onClick={onCreateDevice}>
            {intl.formatMessage({ id: 'system.maps.create_equipment' })}
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      {modalType === 'connect' && <ConnectDeviceModal onClose={() => setModalType(null)} place={selectedPlace} />}
      <div className={styles.addressDeviceList} style={{ overflow: 'auto' }}>
        {devices.map((d) => (
          <DeviceTile key={d.id} device={d} isSelected={selectedId === d.id} />
        ))}
      </div>
      {renderControls()}
    </>
  )
}

export default memo(PlaceDevicesPanel)
