import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageMode } from '../../shared/interfaces'

export type ChartMode = 'vector' | 'charts'

export interface VectorDiagramState {
  fixUa: boolean
  showCurrent: boolean
  showVoltage: boolean
}

export interface VectorState {
  registratorId: string
  mode: PageMode
  search: string
  chartMode: ChartMode
  showAsGrid: boolean
  vectorDiagram: VectorDiagramState
  charts: {
    current: boolean
    voltage: boolean
    activePower: boolean
    unbalance: boolean
    voltageMagnitudeSeq: boolean
    currentMagnitudeSeq: boolean
  }
}

const initialState: VectorState = {
  registratorId: '',
  mode: 'history',
  search: '',
  chartMode: 'vector',
  showAsGrid: true,
  vectorDiagram: { fixUa: false, showCurrent: true, showVoltage: true },
  charts: {
    current: true,
    voltage: true,
    activePower: true,
    unbalance: true,
    voltageMagnitudeSeq: true,
    currentMagnitudeSeq: true,
  },
}

const slice = createSlice({
  name: 'app/vector',
  initialState,
  reducers: {
    SET_STATE: (state, action: PayloadAction<VectorState>) => {
      Object.assign(state, action.payload)
      state.search = ''
    },
    setRegistrator: (state, action: PayloadAction<string>) => {
      state.registratorId = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setFixUa: (state, action: PayloadAction<boolean>) => {
      state.vectorDiagram.fixUa = action.payload
    },
    setPageMode: (state, action: PayloadAction<PageMode>) => {
      state.mode = action.payload
    },
    setChartMode: (state, action: PayloadAction<ChartMode>) => {
      state.chartMode = action.payload
    },
    toggleGrid: (state) => {
      state.showAsGrid = !state.showAsGrid
    },
    setCharts: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.charts = action.payload as any
    },
    toggleShowCurrent: (state) => {
      state.vectorDiagram.showCurrent = !state.vectorDiagram.showCurrent
    },
    toggleShowVoltage: (state) => {
      state.vectorDiagram.showVoltage = !state.vectorDiagram.showVoltage
    },
  },
})

export default slice.reducer
export const actions = slice.actions
