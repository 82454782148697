import { IChartLine } from 'au-nsi/dashboards'
import { colord } from 'colord'
import systemColorsService from '../../../pages/App/Theme/systemColorsService'
import { calcYGrid, GridInfo } from '../../../pages/Dashboards/charts.utils'
import { DataChunk, drawBars, drawDots, drawLine, drawLoader, fillLine } from '../../../utils/canvas.utils'
import { getNumberPrecision } from '../../../utils/format'
import { Chart } from '../Chart'
import { drawExtremums, findExtremums } from '../utils/extremums'
import { calcTimeStep, formatTime, formatTimeHelp } from '../utils/time.utils'
import { fillBand } from '../utils/vis.utils'

/**
 * Main visualization plugin for chart component
 * responsible for drawing line and axes
 */
class VisPlugin {
  // скрытый канвас для кэширования отрисованных данных, т.к. в онлайн режиме
  // большая часть графика на самом деле не обновляется а сдвигается влево и
  // дорисовываются только новые данные у самого края
  private offscreenCanvas: HTMLCanvasElement
  private offscreenCtx: CanvasRenderingContext2D
  private offscreenT1 = 0 // время последнего рассчета координат линий данных

  // начало и шаг линий сетки по осям
  private xGrid = { start: 0, step: 0 }
  private yGrid: GridInfo = { step: 0, values: [] }

  // кэш цветов использующихся на графике с заданной прозрачностью
  private colors = new Map<string, string>()

  constructor(private chart: Chart) {
    chart.updateHandlers.push(this.onUpdate)
    chart.dataHandlers.push(this.onData)

    this.offscreenCanvas = document.createElement('canvas')
    this.offscreenCtx = this.offscreenCanvas.getContext('2d')
    this.resizeOffscreenCanvas()

    const { minY, maxY, boundaries } = chart.state
    this.yGrid = calcYGrid(minY[0], maxY[0], boundaries.height / 50)
  }

  public drawLoader() {
    drawLoader(this.chart.ctx, this.chart.state.boundaries.width)
  }

  private onUpdate = ({ prevState }) => {
    const { state } = this.chart
    const boundariesChange = prevState.boundaries !== state.boundaries
    const marginsChange = prevState.margins !== state.margins
    const limitsChange = prevState.minY !== state.minY || prevState.maxY !== state.maxY

    if (boundariesChange || marginsChange) {
      this.resizeOffscreenCanvas()
    }

    if (boundariesChange || limitsChange) {
      this.yGrid = calcYGrid(state.minY[0], state.maxY[0], state.boundaries.height / 50)
    }

    this.drawChart(this.chart.t0, this.chart.t1, true, '')
  }

  private onData = ({ t0, t1, dataUpdated }) => {
    const reason = this.chart.props.services[0].getUpdateReason()
    this.drawChart(t0, t1, dataUpdated, reason)
  }

  private resizeOffscreenCanvas() {
    const { width, height } = this.chart.state.boundaries
    const { bottom, right } = this.chart.state.margins
    this.offscreenCanvas.width = width - right
    this.offscreenCanvas.height = height - bottom
  }

  // Отрисовка сетки по оси времени
  private drawXAxis(t0: number, t1: number) {
    const { ctx, xScale } = this.chart
    const { margins, boundaries } = this.chart.state
    const y0 = margins.top
    const y1 = boundaries.height - margins.bottom

    const len = 5
    this.xGrid = calcTimeStep(t1 - t0, t0, len)
    const { start, step } = this.xGrid

    ctx.beginPath()
    ctx.moveTo(margins.left + 0.5, y0)
    ctx.lineTo(margins.left + 0.5, y1)
    ctx.moveTo(boundaries.width - margins.right - 0.5, y0)
    ctx.lineTo(boundaries.width - margins.right - 0.5, y1)

    for (let i = 0; i < len; i++) {
      const ts = start + i * step
      if (ts > t1) continue

      const x = Math.round(xScale(ts)) + 0.5
      ctx.moveTo(x, y0)
      ctx.lineTo(x, y1)
    }

    ctx.stroke()
  }

  // отрисовка подписей к линиям сетки по оси времени
  private drawXAxisText(t0: number, t1: number) {
    const { ctx, xScale } = this.chart
    const { start, step } = this.xGrid

    const { width, height } = this.chart.state.boundaries
    const { bottom, right } = this.chart.state.margins
    const y = height - bottom + 18
    const frame = t1 - t0

    ctx.textBaseline = 'alphabetic'
    ctx.textAlign = 'center'

    for (let i = 0; i < 5; i++) {
      const ts = start + i * step
      if (ts > t1) break

      const x = Math.round(xScale(ts)) + 0.5
      ctx.fillText(formatTime(ts, frame), x, y)
    }

    // подсказка с форматом выводимого времени в правом нижнем углу
    ctx.textAlign = 'right'
    ctx.clearRect(width - right, height - bottom, right, bottom)
    const help = formatTimeHelp(frame, this.chart.props.lang)
    ctx.fillText(help, width - 2, y, right - 4)
  }

  // отрисовка линий сетки по оси Y
  private drawYAxis() {
    const { ctx, yScales } = this.chart
    const { minY, maxY, margins, boundaries } = this.chart.state
    const x0 = margins.left
    const x1 = boundaries.width - margins.right
    const yScale = yScales[0]

    // если включена зеркальная ось, то выделяем линию нуля для разделения
    // графиков на основной и зеркальной осях
    if (this.chart.props.settings.mirrorY) {
      ctx.save()
      ctx.lineWidth = 2
      ctx.strokeStyle = '#b4b7c6'

      for (let i = 0; i < yScales.length; i++) {
        if (maxY[i] > 0 && minY[i] < 0) {
          const zero = Math.round(yScales[i](0))
          ctx.beginPath()
          ctx.moveTo(x0, zero)
          ctx.lineTo(x1, zero)
          ctx.stroke()
        }
      }

      ctx.restore()
    }

    ctx.beginPath()
    ctx.moveTo(x0, margins.top + 0.5)
    ctx.lineTo(x1, margins.top + 0.5)
    ctx.moveTo(x0, boundaries.height - margins.bottom - 0.5)
    ctx.lineTo(x1, boundaries.height - margins.bottom - 0.5)

    for (const value of this.yGrid.values) {
      const y = Math.round(yScale(value)) + 0.5
      ctx.moveTo(x0, y)
      ctx.lineTo(x1, y)
    }

    ctx.stroke()
  }

  // отрисовка подписей к линиям сетки по оси Y
  private drawYAxisText() {
    const colors = systemColorsService.getColors()

    const { ctx, yScales, yScalesInverse } = this.chart
    const { boundaries, margins, minY, maxY } = this.chart.state
    const { settings, scales } = this.chart.props

    ctx.textBaseline = 'middle'
    ctx.strokeStyle = colors['--gray-30']
    ctx.lineWidth = 1

    // количество осей слева и справа, которые уже были отрисованы
    const positions = { left: 0, right: 0 }
    const width = 50

    // подписи по Y делаем для каждой из осей графика
    for (let i = 0; i < settings.axes.length; i++) {
      const position = settings.axes[i].position
      positions[position] += 1

      const isRight = position === 'right'
      const scale = scales[i]

      const axisStep = (maxY[i] - minY[i]) / this.yGrid.values.length
      const stepPrecision = getNumberPrecision(axisStep / scale)

      ctx.textAlign = isRight ? 'start' : 'end'
      const x = isRight ? boundaries.width - positions.right * width : positions.left * width
      const shift = isRight ? 5 : -5

      ctx.beginPath()
      ctx.moveTo(x, margins.top)
      ctx.lineTo(x, boundaries.height - margins.bottom)

      for (let value of this.yGrid.values) {
        const y = Math.round(yScales[0](value)) + 0.5
        if (y < 50 || y > boundaries.height - 25) continue

        // небольшой прочерк на оси рядом с подписью
        ctx.moveTo(x - 3, y)
        ctx.lineTo(x + 3, y)

        // значения сетки рассчитывались по первой оси, поэтому для всех следующих их надо перерассчитать
        value = i === 0 ? value : yScalesInverse[i](y)
        value = settings.mirrorY ? Math.abs(value / scale) : value / scale
        const text = value.toFixed(stepPrecision)

        const textX = text.length < 8 ? x + shift : x // при слишком длинной подписи сдвигаем ее к самой оси
        ctx.fillText(text, textX, y, width)
      }

      ctx.stroke()
    }
  }

  private drawAxisText(ctx: CanvasRenderingContext2D, t0: number, t1: number) {
    const colors = systemColorsService.getColors()

    ctx.fillStyle = colors['--gray-30']
    this.drawXAxisText(t0, t1)
    this.drawYAxisText()
  }

  // отрисовка всего графика - с осями и данными
  private drawChart(t0: number, t1: number, dataUpdated: boolean, updateReason: string) {
    const { width, height } = this.chart.state.boundaries
    const { controls } = this.chart.props
    const { ctx } = this.chart

    // пропускаем если браузер еще не рассчитал размеры компонента
    if (width === 0 || this.offscreenCanvas.width === 0) return

    // очистка всей видимой области
    ctx.clearRect(0, 0, width, height)

    // отрисовка линий сетки
    ctx.strokeStyle = '#5a5b6a'
    ctx.lineWidth = 1
    ctx.font = '12px Roboto'
    this.drawXAxis(t0, t1)
    this.drawYAxis()

    // если включен прогноз то оптимизации с кэшированием части изображения не применимы
    // и каждый раз отрисовываем график заново
    if (this.chart.showPrediction) {
      return this.drawPrediction(ctx, t0, t1)
    }

    if (controls.fillArea) {
      return this.fillOscillations(ctx, t0, t1)
    }

    // используем изображение отрисованное на невидимом канвасе если возможно
    let canUseCache = true

    if (dataUpdated && updateReason !== 'ws') canUseCache = false

    // если кадр сдвинулся более чем на четверть своей ширины то кэш нужно обновлять
    if (this.offscreenT1 > t1 || t1 - this.offscreenT1 > 0.25 * (t1 - t0)) canUseCache = false

    const dt = 2 * this.getTimeStep()

    if (canUseCache) {
      // при использовании кэшированного изображения копируем его в видимую область с необходимым
      // сдвигом и дорисовываем новые данные которых нет в кэше
      const dx = this.chart.xScale(this.offscreenT1) - this.chart.xScale(t1)
      this.copyOffscreenCanvas(dx)
    } else {
      // отрисовываем все заново и сохраняем в кэш
      this.offscreenT1 = t1
      this.offscreenCtx.clearRect(0, 0, width, height)
      this.drawLines(this.offscreenCtx, t0, t1 - dt)
      this.copyOffscreenCanvas(0)
    }

    this.drawLines(ctx, this.offscreenT1 - dt, t1 + dt)

    this.clearTails(ctx)
    this.drawAxisText(ctx, t0, t1)
    this.drawThresholds(ctx)

    // debug helpers: uncomment only in development
    // // draw boundary of cached image
    // if (process.env.NODE_ENV !== 'production') {
    //   ctx.fillStyle = '#ccc'
    //   ctx.fillRect(this.chart.xScale(this.offscreenT1 - dt), 0, 1, height)
    // }

    // // highligh chart on full update
    // if (process.env.NODE_ENV !== 'production' && !canUseCache) {
    //   ctx.fillStyle = 'rgba(0, 255, 0, 0.2)'
    //   ctx.fillRect(0, 0, width, height)
    // }
  }

  // отрисовка самих данных
  private drawLines(ctx: CanvasRenderingContext2D, t0: number, t1: number) {
    const { settings, services, parameters, lineMode } = this.chart.props
    const { xScale, selectScaleY } = this.chart
    ctx.lineWidth = 2

    const aggregationMode = settings.aggregationMode || 2
    const drawMin = (aggregationMode & 1) === 1
    const drawAvg = (aggregationMode & 2) === 2
    const drawMax = (aggregationMode & 4) === 4

    // отрисовка данных по каждой из осей
    for (let i = 0; i < settings.axes.length; i++) {
      const axis = settings.axes[i]
      const service = services[i]

      // отрисовка каждой линии: проход в обратном порядке для того чтобы со включенными опциями stack
      // и заливка первые графики находились на переднем плане и не перекрывались заливкой последнего
      for (let j = axis.lines.length - 1; j > -1; j -= 1) {
        const line = axis.lines[j]
        ctx.strokeStyle = line.color
        ctx.fillStyle = line.color
        const yScale = selectScaleY(i, line)

        const range = service.selectRange({
          deviceId: line.device_id,
          parameterId: line.parameter_id,
          t0,
          t1,
          allowOverlap: !axis.bars,
          includePrediction: this.chart.showPrediction,
          useStackedValues: true,
        })

        const chunks: DataChunk[] = []
        const mins: DataChunk[] = drawMin ? [] : null
        const maxs: DataChunk[] = drawMax ? [] : null

        for (const item of range) {
          const { i0, i1 } = item
          const x = item.data.ts
          const y = item.data[line.parameter_id]

          chunks.push({ i0, i1, x, y })
          if (drawMin) mins.push({ i0, i1, x, y: item.data[line.parameter_id + ':min'] })
          if (drawMax) maxs.push({ i0, i1, x, y: item.data[line.parameter_id + ':max'] })
        }

        const parameter = parameters.get(line.parameter_id)
        const isInteger = parameter && !parameter.type.startsWith('f')
        const isIrregular = parameter && parameter.is_irregular
        const isAggregated = service.isAggregated(line.device_id, line.parameter_id)

        // целочисленные и нерегулярные параметры отрисовываем ступенькой
        let mode: 'step' | 'line' = isInteger || isIrregular ? 'step' : 'line'
        if (axis.lineMode === 'line') mode = 'line'
        if (axis.lineMode === 'step') mode = 'step'

        const step = service.getTimeStep(line.device_id, line.parameter_id)
        const base = settings.mirrorY ? 0 : axis.fillBase ?? this.chart.state.minY[i]
        const disconnectDistance = 4 * step

        // отрисовка столбцов
        if (axis.bars) {
          const widthTime = step * axis.barsWidth
          const widthPixels = (widthTime * this.chart.innerWidth) / this.chart.state.frame
          if (axis.fill) this.setGradient(ctx, line, i)

          drawBars({ ctx, chunks, xScale, yScale, base, width: widthPixels }, axis.fill)
          continue
        }

        // отрисовка линии
        if ((lineMode & 1) === 1) {
          // заливка области между минимальными и максимальными значениями (нужно только при широком кадре,
          // когда включается режим аггрегирования данных)
          if (isAggregated) {
            ctx.fillStyle = this.getColor(line.color, 0.3)
            ctx.strokeStyle = this.getColor(line.color, 0.3)
            if (drawMin && drawMax) fillBand({ ctx, top: maxs, bottom: mins, xScale, yScale, disconnectDistance })
            if (drawMin && !drawMax) drawLine({ ctx, chunks: mins, xScale, yScale, mode, disconnectDistance })
            if (drawMax && !drawMin) drawLine({ ctx, chunks: maxs, xScale, yScale, mode, disconnectDistance })
          }

          // отрисовка среднего значения
          ctx.strokeStyle = line.color
          if (drawAvg || !isAggregated) drawLine({ ctx, chunks, xScale, yScale, mode, disconnectDistance })
        }

        // отрисовка отдельных точек
        if ((lineMode & 2) === 2) {
          drawDots({ ctx, chunks, xScale, yScale })
        }

        // заливка области под графиком
        if (axis.fill) {
          this.setGradient(ctx, line, i)
          fillLine({ ctx, chunks, xScale, yScale, base, mode, disconnectDistance })
        }
      }
    }
  }

  // копировать изображение из скрытого канваса в видимый со сдвигом по оси x
  private copyOffscreenCanvas(dx: number) {
    this.chart.ctx.drawImage(this.offscreenCanvas, dx, 0)
  }

  // заливка области между экстремумами колебаний значения параметра
  private fillOscillations(ctx: CanvasRenderingContext2D, t0: number, t1: number) {
    const { services, settings } = this.chart.props
    const { xScale, yScales } = this.chart
    ctx.lineWidth = 2

    for (let i = 0; i < services.length; i++) {
      const axis = settings.axes[i]
      const service = services[i]

      for (const line of axis.lines) {
        ctx.strokeStyle = line.color
        ctx.fillStyle = this.getColor(line.color, 0.15)

        const range = service.selectRange({
          deviceId: line.device_id,
          parameterId: line.parameter_id,
          t0,
          t1,
          allowOverlap: false,
          includePrediction: false,
        })

        const ts = []
        const values = []

        for (const item of range) {
          const { i0, i1 } = item

          if (i0 !== -1 && i1 !== -1) {
            Array.prototype.push.apply(ts, item.data.ts.slice(i0, i1))
            Array.prototype.push.apply(values, item.data[line.parameter_id].slice(i0, i1))
          }
        }

        const { mins, maxs } = findExtremums(ts, values)
        drawExtremums(ctx, mins, maxs, xScale, yScales[i])
      }
    }

    this.drawAxisText(ctx, t0, t1)
    this.drawThresholds(ctx)
  }

  // отрисовка графика со включенным прогнозом
  private drawPrediction(ctx: CanvasRenderingContext2D, t0: number, t1: number) {
    const delta = this.getTimeStep()
    this.drawLines(ctx, t0 - delta, t1 + delta)

    // границы прогнозируемых данных - от текущего момента до правой границы графика
    const x0 = this.chart.xScale(this.chart.props.services[0].clock.getServerTime())
    const x1 = this.chart.xScale(t1)
    const { top } = this.chart.state.margins

    // затемнение области с прогнозом для визуального отличия
    ctx.fillStyle = 'rgba(26, 44, 72, 0.5)'
    ctx.fillRect(x0, top + 1, x1 - x0, this.chart.innerHeight - 1)

    // линия отображающая текущий момент времени
    ctx.fillStyle = '#b4b7c6'
    ctx.fillRect(x0 - 2, top, 2, this.chart.innerHeight)

    this.clearTails(ctx)
    this.drawAxisText(ctx, t0, t1)
    this.drawThresholds(ctx)
  }

  // отрисовка линий пороговых значений
  private drawThresholds(ctx: CanvasRenderingContext2D) {
    ctx.save()
    ctx.lineWidth = 2

    const { axes } = this.chart.props.settings
    const { left, right } = this.chart.state.margins
    const { minY, maxY, boundaries } = this.chart.state

    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]
      const { thresholds } = axis
      if (!thresholds || thresholds.length === 0) continue

      for (const t of thresholds) {
        if (t.value > minY[i] && t.value < maxY[i]) {
          const y = Math.round(this.chart.yScales[i](t.value))
          ctx.strokeStyle = t.color
          ctx.setLineDash(t.line === 'solid' ? [] : [6, 8])
          ctx.beginPath()
          ctx.moveTo(left, y)
          ctx.lineTo(boundaries.width - right, y)
          ctx.stroke()
        }
      }
    }

    ctx.restore()
  }

  // линия данных рисуется немного дальше правой границы внутренней области и затем стирается
  // после границы для того чтобы конец линии всегда плавно заканчивался на границе
  // и не дергался когда появляется новая точка
  private clearTails(ctx: CanvasRenderingContext2D) {
    const { margins } = this.chart.state
    const { width, height } = this.chart.state.boundaries
    // очистка места занимаемого левыми оями
    ctx.clearRect(0, 0, margins.left, height)
    // очистка места занимаемого правыми оями
    ctx.clearRect(width - margins.right, 0, margins.right, height)
    // очистка нижней границы (ось времени)
    ctx.clearRect(0, height - margins.bottom, width, margins.bottom)
    // очистка отступа над графиком
    ctx.clearRect(0, 0, width, margins.top)
  }

  // получить максимальный шаг по времени среди данных всех устройств отрисовывающихся на графике
  private getTimeStep() {
    const { settings, services } = this.chart.props
    let result = 0

    for (let i = 0; i < services.length; i++) {
      for (const line of settings.axes[i].lines) {
        result = Math.max(result, services[i].getTimeStep(line.device_id, line.parameter_id))
      }
    }

    return result
  }

  // установка градиента от цвета линии у границ графика до прозрачного цвета у основания
  private setGradient(ctx: CanvasRenderingContext2D, line: IChartLine, axisIndex: number) {
    const { height } = this.chart.state.boundaries
    const base = this.chart.props.settings.axes[axisIndex].fillBase ?? this.chart.state.minY[axisIndex]

    const gradient = ctx.createLinearGradient(0, 0, 0, height)
    const color1 = this.getColor(line.color, 0.9)
    const color2 = this.getColor(line.color, 0.1)

    let baseY = this.chart.yScales[axisIndex](base) / height
    if (baseY <= 0) baseY = 1 / height
    if (baseY >= 1) baseY = 1 - 1 / height

    gradient.addColorStop(0, color1)
    gradient.addColorStop(baseY, color2)
    gradient.addColorStop(1, color1)

    ctx.fillStyle = gradient
  }

  // получение цвета с указанной прозрачностью
  private getColor(color: string, opacity: number) {
    const key = color + ':' + opacity
    let result = this.colors.get(key)

    if (!result) {
      const c = colord(color)

      result = c.alpha(opacity).toRgbString()
      this.colors.set(key, result)
    }

    return result
  }
}

export default VisPlugin
