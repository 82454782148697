import { IDashboard } from 'au-nsi/dashboards'
import produce from 'immer'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'
import InputRow from 'shared/Inputs/InputRow'
import MultiSelect from 'shared/Inputs/MultiSelect'
import PlusButton from '../../../shared/Forms/PlusButton'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import TextInput from '../../../shared/Inputs/TextInput'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import actions from '../dashboard.actions'
import { extractDevices } from '../dashboard.utils'

interface Props {
  id: string
  settings: IDashboard['template_settings']
  is_system: IDashboard['is_system']
  onChange: (s: IDashboard['template_settings'], name: string) => void
}

/**
 * Форма настроек переменных шаблона (какие из устройств использующиеся на экране могут использоваться
 * как переменные и зменяться на другие устройства)
 */
const TemplateSettingsForm = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const components = useAppSelector((state) => state.dashboards.components[props.id])
  const equipment = useAppSelector(selectDataSourceOptions)
  const tags = useAppSelector((state) => state.tags)

  const equipmentNames = useMemo(() => new Map(equipment.map((o) => [o.value, o.label])), [equipment])

  // используемые на экране устройства отсортированные по названию
  const devices = useMemo(() => {
    if (!components) return []

    const set = components.reduce((acc, component) => extractDevices(component, acc), new Set<string>())
    const ids = [...set]

    const options = ids.map((id) => ({ value: id, title: equipmentNames.get(id) || id }))

    return options.sort((a, b) => a.title.localeCompare(b.title))
  }, [components, equipmentNames])

  const settings = props.settings || { variables: [], tags: [] }
  const { variables } = settings

  // устройства, которые уже используются в переменных
  const usedDevices = new Set(variables.map((v) => v.device_id))

  useEffect(() => {
    dispatch(actions.loadDashboardComponents(props.id))
  }, [])

  const handleChange = (key: string, value: string, index: number) => {
    const updates = produce(variables, (draft) => {
      draft[index][key] = value
    })

    props.onChange({ ...settings, variables: updates }, 'template_settings')
  }

  const handleRemove = (e) => {
    const index = +e.target.dataset.id
    const updates = variables.filter((_, i) => i !== index)
    props.onChange({ ...settings, variables: updates }, 'template_settings')
  }

  const handleAppend = () => {
    const updates = [...variables, { name: '', device_id: '' }]
    props.onChange({ ...settings, variables: updates }, 'template_settings')
  }

  const handleTagsChange = (tags: number[]) => {
    props.onChange({ ...settings, tags }, 'template_settings')
  }

  const rows = variables.map(({ device_id, name }, i) => {
    const equipmentOptions = devices.filter(({ value }) => value === device_id || !usedDevices.has(value))

    return (
      <tr key={i}>
        <td>
          <TextInput name="" value={name} onChange={(name) => handleChange('name', name, i)} required={true} />
        </td>
        <td>
          <Dropdown
            name=""
            value={device_id}
            onChange={(v) => handleChange('device_id', v as string, i)}
            options={equipmentOptions}
          />
        </td>
        <td align="center" style={{ width: '3ch' }}>
          <RemoveButton id={i} onRemove={handleRemove} />
        </td>
      </tr>
    )
  })

  const tagsInput = (
    <InputRow
      label={intl.formatMessage({ id: 'dashboards.settings.template.tags' })}
      help={intl.formatMessage({ id: 'dashboards.settings.template.tags.help' })}
    >
      <MultiSelect options={tags} selection={settings.tags || []} onChange={handleTagsChange} />
    </InputRow>
  )

  // загрузка
  if (!components) return null

  // системный экран - доступна настройка только тэгов
  if (props.is_system) return tagsInput

  // пустой экран - невозможно использовать как шаблон
  if (devices.length === 0) {
    return (
      <div style={{ padding: 6 }} className="text--center text--gray">
        {intl.formatMessage({ id: 'dashboards.settings.template.no_devices' })}
      </div>
    )
  }

  return (
    <>
      {tagsInput}

      <table className="nsi-settings-table is-fixed">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.settings.template.name' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.settings.template.device_id' })}</th>
            <th style={{ width: '3ch' }} />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      {variables.length < devices.length && (
        <PlusButton style={{ margin: 0 }} textId="dashboards.settings.template.add_variable" onClick={handleAppend} />
      )}

      <div style={{ padding: 6 }} className="text--center text--gray">
        {intl.formatMessage({ id: 'dashboards.settings.template.description' })}
      </div>
    </>
  )
}

export default TemplateSettingsForm
