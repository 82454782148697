import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectEquipmentMap } from '../../../Nsi/nsi.selectors'
import ExpirationModal from './ExpirationModal'

/**
 * Форма задания срока действия при добавлении доступа к новому устройству
 */
const NewEntryForm = (props: Props) => {
  const intl = useIntl()
  const equipment = useSelector(selectEquipmentMap)

  const name = equipment.get(props.id).name
  const title = intl.formatMessage({ id: 'nsi.user.devices_form.add_device_title' }, { name })

  return <ExpirationModal title={title} shouldAnimate={false} onClose={props.onClose} onSave={props.onSave} />
}

interface Props {
  id: string
  onSave: (expires_at: number) => void
  onClose: () => void
}

export default NewEntryForm
