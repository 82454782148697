import React from 'react'
import EventEmitter, { Unsubscribe } from '../../utils/events'
import Gantt from '../../pages/GanttTable/Gantt'
import { GanttData } from '../../services/gantt/gantt.interfaces'
import CellWithRadio from '../RegistratorCell/CellWithRadio'

class GanttRow extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe

  state: State = { data: [] }

  componentDidMount() {
    this.unsubscribe = this.props.data$.subscribe((data) => {
      this.setState({ data })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  private renderDeviceCell() {
    const { registrator, checked, onSelectRegistrator } = this.props
    return <CellWithRadio registrator={registrator} checked={checked} onChange={onSelectRegistrator} />
  }

  render() {
    const { id } = this.props.registrator

    const rows = this.state.data.map((row, i) => {
      const nested = i > 0
      const warning = row.warning.length > 0
      const danger = row.danger.length > 0

      const head = nested ? subrow(row.name) : this.renderDeviceCell()

      return (
        <Gantt.Row key={row.id + id} id={id} nested={nested} warning={warning} danger={danger}>
          {head}
          <Gantt.Chart {...row} />
        </Gantt.Row>
      )
    })

    return <React.Fragment>{rows}</React.Fragment>
  }
}

const subrow = (name: string) => {
  return (
    <span>
      <span className="pq__icon-nested" />
      {name}
    </span>
  )
}

interface Props {
  registrator: any
  checked: boolean
  onSelectRegistrator: (e: any) => void
  data$: EventEmitter<GanttData[]>
}

interface State {
  data: GanttData[]
}

export default GanttRow
