import { ICommand, ICommandType } from 'au-nsi/commands'
import { memo, ReactNode } from 'react'
import InputRow from '../../../shared/Inputs/InputRow'
import CommandArgInput from './CommandArgInput'

interface Props {
  args: ICommand['args']
  args_schema: ICommandType['args_schema']
  editing: boolean
  required: boolean
  onChange: (value: any, arg: string) => void
}

/**
 * Форма задания переменных аргументов команды (дополнение к ConstantArgsForm)
 */
const VariableArgsForm = ({ args, args_schema, editing, required, onChange }: Props) => {
  const rows: ReactNode[] = []

  for (const arg of args_schema) {
    if (arg.is_constant) continue

    rows.push(
      <InputRow compact={true} label={arg.name} key={arg.id}>
        <CommandArgInput
          id={arg.id}
          value={args[arg.id]}
          editing={editing}
          required={required}
          arg_schema={arg}
          onChange={onChange}
        />
      </InputRow>
    )
  }

  return <>{rows}</>
}

export default memo(VariableArgsForm)
