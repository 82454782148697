import { LoaderDots } from '@alterouniversal/au-react-components'
import { Equipment } from 'au-nsi/equipment'
import { IPlace } from 'au-nsi/shared'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import usePersistentState from '../../hooks/usePersistentState'
import { ReduxState } from '../../redux/store.types'
import placeManager from '../../services/geo/placeManager/placeManager'
import GoogleMap from '../../shared/GoogleMap'
import { useGoogleMapsLoader } from '../../shared/GoogleMap/useGoogleMapsLoader'
import { matchSearch } from '../../utils/search'
import { selectDataIntervalsMap } from '../Nsi/nsi.selectors'
import { selectDenormalizedParametersMap, selectIrregularParameters } from '../Parameters/params.selectors'
import ConnectDeviceWidget from './components/ConnectDevice/ConnectDeviceWidget'
import CreateDeviceForm from './components/CreateDeviceForm/CreateDeviceForm'
import EquipmentFilter from './EquipmentFilter'
import './intl/mapTopology.intl'
import './MapInfoWindow'
import MapLeftPanel from './MapLeftPanel/MapLeftPanel'
import { defaultStatusControls, mapEquipmentStateMapping } from './MapLeftPanel/mapPanel.utils'
import styles from './maptopology.module.css'
import { IMapPoint } from './mapTopology.types'
import { getInfoWindowParameters, IMapBounds, selectEquipmentWithValidPlace } from './mapTopology.utils'

// Сохраняем информацию о выбранном месте в глобальный стейт, для его сохранения при навигации на карточку устройства и обратно
export const recoilSelectedPlaceState = atom({
  key: 'maps:selectedPlace',
  default: undefined,
})

// Сохроаняем поисковой запрос, введённый пользователем для его сохранения при переключении между панелями
export const recoilMapSearchValueState = atom({
  key: 'maps:searchValue',
  default: '',
})

/**
  Компонент экрана "Карта устройств"
  В случае, если скрипт карт не загружен, редерит лоадер
  Непосредственно в себе держит список устройств и сортирует их как вздумается пользователю,
  после чего отдаёт их в дочернии компоненты
  Также хранит информацию о выбранной локации и устройстве
*/
const MapTopology = () => {
  const isMapsLoaded = useGoogleMapsLoader()

  const devices = useSelector(selectEquipmentWithValidPlace)
  const parametersMap = useSelector(selectDenormalizedParametersMap)
  const irregularParameters = useSelector(selectIrregularParameters)
  const dataIntervals = useSelector(selectDataIntervalsMap)

  // Отображать в списке только видимые на карте устройства
  const [filterByViewPort, setFilterByViewPort] = usePersistentState<boolean>(false, 'map:filterByViewPort')
  // Прямоугольник карты в координатах
  const [mapViewport, setMapViewport] = useState<IMapBounds>(undefined)
  const [selectedDevice, setSelectedDevice] = useState<Equipment>(undefined)
  const [selectedPlace, setSelectedPlace] = useRecoilState<IPlace>(recoilSelectedPlaceState)
  const searchValue = useRecoilValue(recoilMapSearchValueState)

  // Открыт ли экран создания устройства
  const [isCreatingDevice, setCreatingDevice] = useState(false)

  // Фильтры по статусу устройства. Мапа "статус": true/false (включать в выборку/не включать)
  const [statusControls, setStatusControls] = usePersistentState(defaultStatusControls, 'map:status_controls')

  // Id иконки множества устройств
  const multiplyIcon = useSelector((state: ReduxState) => state.service_settings['maps']?.couple_equipment_icon_id)

  // Фильтруем согласно поисковой строке и настройкам отображаемых статусов
  const foundDevices = useMemo(() => {
    const searchLower = searchValue.toLowerCase()
    const pattern = searchLower.split(' ')

    return devices.filter((d) => {
      if (!statusControls[mapEquipmentStateMapping(d.state)]) return false
      if (selectedPlace && !placeManager.isIPlacesEqual(selectedPlace, d.address)) return false
      if (!searchValue) return true

      return matchSearch(d.name.toLowerCase(), pattern) || matchSearch((d.address.name || '').toLowerCase(), pattern)
    })
  }, [devices, searchValue, statusControls, selectedPlace])

  const equipmentFilter = useMemo(() => new EquipmentFilter(foundDevices), [foundDevices])

  // Получаем точки для отрисовки оборудования на карте
  // В случае, если на одном адресе несколько устройств, заменяем их на одну точку
  const devicesMapPoints = useMemo(() => {
    const placesMap = new Map<string, Equipment[]>()

    for (const device of foundDevices) {
      const placeKey = device.address.name
      if (!placeKey) continue

      if (placesMap.has(placeKey)) placesMap.get(placeKey).push(device)
      else placesMap.set(placeKey, [device])
    }

    const values: IMapPoint[] = []

    for (const value of placesMap.values()) {
      // несколько устройств по одному адресу
      if (value.length > 1) {
        const equipment_ids = value.map((v) => v.id)
        values.push({ ...value[0].address, iconId: multiplyIcon, type: 'multiply', equipment_ids })
        continue
      }

      let modalContent
      const parameters = getInfoWindowParameters(value[0], parametersMap)

      if (parameters) {
        modalContent = document.createElement('au-maps-infowindow') as any
        modalContent.parameters = parameters
        modalContent.irregularParameters = irregularParameters
        modalContent.dataIntervals = dataIntervals
        modalContent.deviceID = value[0].id
      }

      // одно устройство по адресу
      values.push({ ...value[0].address, equipment_id: value[0].id, type: 'single', modalContent })
    }

    return { points: values, placesMap }
  }, [foundDevices, multiplyIcon, parametersMap])

  // Устройства, которые пользователь видит на карте в текущий момент
  const viewPortDevices = useMemo(
    () => mapViewport && equipmentFilter.getFittedIntoBounds(mapViewport),
    [mapViewport, equipmentFilter]
  )

  // Клик по иконке устройств(а) на карте
  const handlePointSelect = useCallback(
    (p: IMapPoint) => {
      if (devicesMapPoints.placesMap.get(p.name).length > 1) {
        return setSelectedPlace(p)
      }

      if (p.type === 'single') {
        setSelectedDevice(foundDevices.find((d) => d.id === p.equipment_id))
      } else {
        setSelectedDevice(undefined)
      }
      setSelectedPlace(undefined)
    },
    [foundDevices]
  )

  // Клик по устройству в списке
  const handleDeviceSelect = useCallback(
    (id: Equipment['id']) => {
      setSelectedDevice(foundDevices.find((d) => d.id === id))
    },
    [foundDevices]
  )

  // Клик по месту на карте
  const handlePlaceSelect = useCallback((place: IPlace) => {
    if (place === undefined) setCreatingDevice(false)
    setSelectedPlace(place)
    setSelectedDevice(undefined)
  }, [])

  // Лоадер поверх экрана на время рендера карты
  const renderLoader = () => (
    <div className={styles.mapLoader}>
      <div />
      <div>
        <LoaderDots />
      </div>
    </div>
  )

  if (!isMapsLoaded) return <div className={styles.mapTopology}>{renderLoader()}</div>

  let filteredDevices: Equipment[]
  if (selectedPlace) filteredDevices = foundDevices
  else filteredDevices = filterByViewPort ? viewPortDevices : foundDevices
  filteredDevices = filteredDevices ?? []

  // если при клике на устройство будет отображаться всплывающее окно,
  // то маркер на этом месте ставить не нужно
  const hidePlaceMarker = !selectedPlace && selectedDevice && getInfoWindowParameters(selectedDevice, parametersMap)

  return (
    <div className={styles.mapTopology}>
      {!mapViewport && renderLoader()}
      <MapLeftPanel
        devices={filteredDevices}
        selectedPlace={selectedPlace}
        onPlaceSelect={handlePlaceSelect}
        onDeviceSelect={handleDeviceSelect}
        statusFilter={statusControls}
        onStatusFilterChange={setStatusControls}
        selectedId={selectedDevice?.id || ''}
        searchPattern={searchValue}
        filterByViewport={filterByViewPort}
        onFilterByViewportChange={setFilterByViewPort}
        onCreateDevice={() => setCreatingDevice(true)}
        showControls={!isCreatingDevice}
      />

      {isCreatingDevice && (
        <div className="nsi-main__wrapper">
          <div className="nsi-main__container">
            <CreateDeviceForm onClose={() => setCreatingDevice(false)} place={selectedPlace} />
          </div>
        </div>
      )}
      {!isCreatingDevice && (
        <GoogleMap
          className={styles.mapContainer}
          place={selectedPlace || selectedDevice?.address}
          onChange={handlePlaceSelect}
          width={'100%'}
          height={'100%'}
          points={devicesMapPoints.points}
          onPointClick={handlePointSelect}
          onBoundsChange={setMapViewport}
          clusterMarkers={true}
          showPlaceMarker={!hidePlaceMarker}
        />
      )}
      <ConnectDeviceWidget />
    </div>
  )
}

export default MapTopology
