import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg'
import { ReactComponent as AddButton } from 'images/icons/nsi/add-button.svg'
import { selectEnabledModules } from '../../App/app.selectors'
import { useDropdownControls } from './tree.hooks'
import { PROTOCOLS } from '../../../shared/constants'
import { EquipmentType } from 'au-nsi/equipment'

/**
 * Компонент отображающий выпадающее меню для добавления новых объектов топологии
 * Выводятся кнопки: 'Добавить регион' и 'Добавить устройство'
 * При клике на 'Добавить устройство' они заменяются на выбор протокола для приема данных
 */
const RegionControls = (props: Props) => {
  const modules = useSelector(selectEnabledModules)
  const { ref, open, setOpen, dropdownStyle } = useDropdownControls()
  const [showProtocols, setShowProtocols] = React.useState(false)

  const protocols = PROTOCOLS.filter((p) => !p.module || modules.has(p.module)).map((p) => p.id)
  const types = ['receiver']

  React.useEffect(() => {
    if (!open && showProtocols) setShowProtocols(false)
  }, [open])

  const handleClick = () => setOpen(!open)

  const handleRegionClick = (e) => {
    e.stopPropagation()
    setOpen(false)
    props.onCreateRegion()
  }

  const handleEquipmentClick = (e) => {
    const { type } = e.currentTarget.dataset

    // если устройство не принимает само данные, либо доступен только один протокол,
    // то сразу создаем устройство без необходимости выбирать протокол
    if (type !== 'receiver' || protocols.length === 1) {
      props.onCreateEquipment(protocols[0], type)
      setOpen(false)
    } else {
      setShowProtocols(true)
    }
  }

  const handleProtocolClick = (e) => {
    const { protocol } = e.currentTarget.dataset
    props.onCreateEquipment(protocol, 'receiver')
    setOpen(false)
  }

  // список поддерживаемых типов устройств
  const equipmentRows = types.map((t) => {
    return (
      <div className="nsi-dropdown__item" key={t} data-type={t} onClick={handleEquipmentClick}>
        {<FormattedMessage id={'nsi.equipment.' + t} />}
      </div>
    )
  })

  // список поддерживаемых протоколов
  const protocolRows = protocols.map((protocol) => {
    return (
      <div className="nsi-dropdown__item" key={protocol} data-protocol={protocol} onClick={handleProtocolClick}>
        {<FormattedMessage id={'nsi.protocols.' + protocol} />}
      </div>
    )
  })

  // кнопка для добавления нового региона
  const regionRow = (
    <div className="nsi-dropdown__item" onClick={handleRegionClick}>
      <FormattedMessage id="nsi.object" />
    </div>
  )

  const dropdown = (
    <div className="nsi-dropdown" style={dropdownStyle}>
      {props.allowCreating && !showProtocols && regionRow}
      {props.allowCreating && !showProtocols && equipmentRows}
      {showProtocols && protocolRows}
    </div>
  )

  if (!props.allowCreating) {
    return null
  }

  const button = props.useButtonIcon ? (
    <AddButton className="nsi__title-icon" onClick={handleClick} />
  ) : (
    <PlusIcon style={{ width: 20, height: 20, color: 'var(--success-70)' }} onClick={handleClick} />
  )

  return (
    <div className="nsi__row-control" ref={ref}>
      {button}
      {open && dropdown}
    </div>
  )
}

interface Props {
  allowCreating: boolean
  onCreateRegion: () => void
  onCreateEquipment: (protocol: string, type: EquipmentType) => void
  useButtonIcon?: boolean
}

export default React.memo(RegionControls)
