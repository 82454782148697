import { IntlShape } from 'react-intl'
import { createSelector } from 'reselect'
import { ReduxState } from '../../../redux/store.types'
import memoize, { multimemoize } from '../../../utils/memoize'
import { Equipment } from 'au-nsi/equipment'

/**
 * Получить сет параметров записываемых самим устройством.
 */
export const getRecordedParameters = memoize((device: Equipment) => {
  const mapping = device.parameters_mapping as Array<{ parameter_id: string }>

  return new Set(mapping.map((e) => e.parameter_id))
})

/**
 * Сформировать опции для выбора настроек рассчитываемого параметра с идентификаторм id
 */
export const constructOptions = multimemoize(
  (
    id: string,
    intl: IntlShape,
    device: Equipment, // оборудование для которого производится настройка
    parameters: Map<string, string>, // названия параметров
    rules: { [id: string]: string[] } // список зависимостей для рассчета
  ) => {
    const recordedParameters = getRecordedParameters(device)
    const computedParameters = new Map<string, boolean>()

    // проверить записывается ли параметр устройством и подготовить соответствующее сообщение
    const isRecorded = recordedParameters.has(id)
    const deviceDescription = isRecorded
      ? intl.formatMessage({ id: 'nsi.cp.source.device_description' })
      : intl.formatMessage({ id: 'nsi.cp.source.device_description.disabled' })

    // проверить можно ли рассчитать параметр и подготовить соответствующее сообщение
    const deps = rules[id] || []
    const missingDeps = deps
      .filter((d) => !isComputable(d, rules, computedParameters, recordedParameters))
      .map((d) => parameters.get(d))
      .join(', ')

    const canBeComputed = missingDeps === ''
    const systemDescription = canBeComputed
      ? intl.formatMessage({ id: 'nsi.cp.source.system_description' })
      : intl.formatMessage({ id: 'nsi.cp.source.system_description.disabled' }, { missing: missingDeps })

    return [
      {
        value: 'device',
        title: intl.formatMessage({ id: 'nsi.cp.source.device' }),
        description: deviceDescription,
        disabled: !isRecorded,
      },
      {
        value: 'system',
        title: intl.formatMessage({ id: 'nsi.cp.source.system' }),
        description: systemDescription,
        disabled: !canBeComputed,
      },
      {
        value: 'none',
        title: intl.formatMessage({ id: 'nsi.cp.source.none' }),
        description: intl.formatMessage({ id: 'nsi.cp.source.none_description' }),
      },
    ]
  }
)

// определить можно ли рассчитать параметр id
const isComputable = (
  id: string,
  rules: { [id: string]: string[] },
  computed: Map<string, boolean>,
  available: Set<string>
) => {
  // записывается самим устройством
  if (available.has(id)) {
    return true
  }

  // кэш уже определенных параметров
  if (computed.has(id)) {
    return computed.get(id)
  }

  // выставляем false до начала рекурсии для избежания бесконечного цикла при круговых зависимостях
  computed.set(id, false)

  // зависимости - параметры которые нужны для рассчета, если зависимостей нет - значит рассчитать невозможно
  const deps = rules[id]
  if (!deps) return false

  // проверяем что каждый параметр из зависимостей либо приходит с устройства либо может быть рассчитан
  const result = deps.every((p) => isComputable(p, rules, computed, available))

  computed.set(id, result)
  return result
}

/**
 * Выбрать оборудование только с заполненными настройками computable_parameters
 */
export const equipmentSelector = createSelector(
  (state: ReduxState) => state.nsi.equipment,
  (equipment) => equipment.filter((e) => e.data_quality_options?.computable_parameters?.length > 0)
)
