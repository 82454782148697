import React from 'react'
import { GanttTotalStats } from '../../../services/gantt/gantt.interfaces'
import GanttService from '../../../services/gantt/gantt.service'
import { Boundaries } from '../../../shared/interfaces'
import { Unsubscribe } from '../../../utils/events'
import { renderItems } from './GanttChart'
import TimelineSlider from './TimelineSlider'

class TimelineSummary extends React.Component<SummaryProps, GanttTotalStats> {
  state: GanttTotalStats = {
    ganttTime: Date.now(),
    ganttFrame: 3600_000,
    t0: 0,
    t1: Date.now(),
    warning: [],
    danger: [],
  }

  private unsubscribe: Unsubscribe

  componentDidMount() {
    this.unsubscribe = this.props.ganttService.total$.subscribe((data) => {
      this.setState(data)
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    const { warning, danger, t0, t1 } = this.state

    return (
      <div className="gantt__summary">
        <svg
          className="gantt__summary-chart"
          viewBox="0 0 1000 10"
          preserveAspectRatio="none"
          shapeRendering="optimizeSpeed"
        >
          {renderItems({ items: warning, level: 'warning', t0, t1 })}
          {renderItems({ items: danger, level: 'danger', t0, t1 })}
        </svg>
        <TimelineSlider
          {...this.props}
          ganttTime={this.state.ganttTime}
          ganttFrame={this.state.ganttFrame}
          t0={t0}
          t1={t1}
        />
      </div>
    )
  }
}

interface SummaryProps {
  boundaries: Boundaries
  ganttService: GanttService
}

export default TimelineSummary
