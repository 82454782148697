import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import { organizationsActions } from '../../organizations.actions'
import OrganizationForm from './OrganizationForm'
import { FormMode } from '../../../../../shared/interfaces'
import { OrganizationTypeWithInstances } from '../../organizations.types'
import { validateItemValues } from '../../../catalogs.validators'
import { Organization } from 'au-nsi/organizations'
import { showError } from '../../../../../utils/notifications'
import { isJsonEqual } from 'utils/misc'
import { useParams } from 'react-router-dom'

export const OrganizationFormRoute = () => {
  const params = useParams()
  const [typeId, organizationId] = [parseInt(params.id), parseInt(params.organizationId)]

  const dispatch = useDispatch()

  const [selectedOrganization, selectedOrganizationType]: [Organization, OrganizationTypeWithInstances] = useSelector(
    (state: ReduxState) => {
      const selectedOrganizationType = state.organizations.organizations.find((o) => o.id === typeId)
      const selectedOrganization = selectedOrganizationType?.instances?.find((i) => i.id === organizationId)

      return [selectedOrganization, selectedOrganizationType]
    }
  )

  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState<FormMode>('view')
  const [draftOrganization, setDraftOrganization] = useState(selectedOrganization)

  useEffect(() => {
    if (mode !== 'edit') setDraftOrganization(selectedOrganization)
  }, [selectedOrganization, mode])

  const handleEditSave = async () => {
    if (!validateItemValues(selectedOrganizationType.organization_schema, draftOrganization.organization_properties)) {
      return showError('common.validation_error')
    }

    if (!isJsonEqual(draftOrganization, selectedOrganization)) {
      setLoading(true)
      await dispatch(organizationsActions.updateOrganization(draftOrganization))
      setLoading(false)
    }

    setMode('view')
  }

  const handleDelete = async () => {
    setLoading(true)
    await dispatch(organizationsActions.deleteOrganization(draftOrganization.id))
    setLoading(false)
  }

  if (!selectedOrganization || !selectedOrganizationType) return null

  return (
    <OrganizationForm
      formMode={mode}
      organizationType={selectedOrganizationType}
      organization={draftOrganization}
      onChange={setDraftOrganization}
      onEdit={() => setMode('edit')}
      onDelete={() => setMode('delete')}
      onCancel={() => setMode('view')}
      onEditSave={handleEditSave}
      onDeleteConfirm={handleDelete}
      loading={loading}
    />
  )
}
