import { nanoid } from '@reduxjs/toolkit'
import { useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import './notification.styles.css'

/**
 * Компонент присутствующий на всех страницах и выводящий список уведомлений при их наличии
 */
const NotificationsList = () => {
  const intl = useIntl()

  const [notifications, setNotifications] = useState<Notification[]>([])

  const removeNotification = (_id: string) => {
    // trigger animation by setting 'deleted' flag
    setNotifications((prev) => prev.map((n) => (n._id === _id ? { ...n, deleted: true } : n)))

    // completely remove item after animation complete
    setTimeout(() => setNotifications((prev) => prev.filter((n) => n._id !== _id)), 500)
  }

  const insertNotification = (notification: Omit<Notification, '_id'>) => {
    const _id = nanoid()

    // auto delete informational messages after short timeout
    if (notification.type === 'info') {
      setTimeout(() => removeNotification(_id), 4000)
    }

    setNotifications((prev) => {
      const alreadyShown = prev.find((e) => e.id === notification.id)
      if (alreadyShown) return prev

      return [{ ...notification, _id, ts: Date.now() }, ...prev].slice(0, 5)
    })
  }

  // уведомления отправляются через CustomEvent для того, чтобы их было максимально просто
  // отправлять из любой части приложения, в том числе из микрофронтендов
  useLayoutEffect(() => {
    window.document.addEventListener('app-notification', (e: CustomEvent) => insertNotification(e.detail))
  }, [])

  // обработка клика по уведомлению: убираем уведомление
  const handleClick = (e) => removeNotification(e.target.dataset.id)

  const items = notifications.map((item) => {
    const { content, id, _id } = item
    let className = 'notification ' + item.type
    if (item.deleted) className += ' is-deleted'

    return (
      <div className={className} key={_id} data-id={_id} onClick={handleClick}>
        {content ? content : intl.formatMessage({ id })}
      </div>
    )
  })

  return <div className="notification__list">{items}</div>
}

interface Notification {
  id: string // public id specified by caller
  _id: string // private id for internal use
  ts?: number
  type: 'info' | 'warn' | 'error'
  content?: string
  deleted?: boolean
}

export default NotificationsList
