import { Lang, PlatformModuleID } from 'au-nsi/shared'

const PRODUCTION = process.env.NODE_ENV === 'production'

/* API */
const url = document.URL
export const BASE_URL = url.split('/')[2]

const isSecure = url.startsWith('https')
const httpProtocol = isSecure ? 'https' : 'http'
const wsProtocol = isSecure ? 'wss' : 'ws'

const devServer = 'https://alpha.au.alterosmart.dev'
const devWsServer = 'wss://alpha.au.alterosmart.dev'

export const DEV_MODE = !!(typeof localStorage !== 'undefined' && localStorage.getItem('DEV_MODE'))

export const API_URL = PRODUCTION ? `${httpProtocol}://${BASE_URL}` : devServer
export const WS_URL = PRODUCTION ? `${wsProtocol}://${BASE_URL}` : devWsServer

/* languages */
export const languages = ['en', 'ru']

let lang =
  (typeof localStorage !== 'undefined' && localStorage.getItem('locale')) ||
  navigator.languages[0] ||
  navigator.language ||
  'en'

lang = lang.split('-')[0] // 'ru-RU' -> 'ru'

export const DEFAULT_LOCALE = (languages.includes(lang) ? lang : languages[0]) as Lang

/* other constants */

export const COLORS = {
  danger: '#f44336',
  gray: '#b4b4bb',
  darkGray: '#5a5b6a',
  success: '#65b168',
  warning: '#FF9857',
  'primary-80': '#0062ae',
  'secondary-80': '#3b457b',
}

export const PROTOCOLS: Array<{ id: string; module: PlatformModuleID }> = [
  { id: 'C.37.118', module: 'C37.118' },
  { id: 'modbustcp-server', module: 'modbustcp-server' },
  { id: 'modbustcp-client', module: 'modbustcp-client' },
  { id: 'IEC104', module: 'IEC104' },
  { id: 'MLP14', module: 'MLP14' },
  { id: 'db-client', module: 'db-client' },
  { id: 'LP001-Server', module: 'LP001-Server' },
  { id: 'LP001', module: 'LP001-Server' },
  { id: 'OPCDA', module: 'OPCDA' },
  { id: 'MQTT-Ob', module: 'MQTT-Ob' },
]

export const defaultTitle = 'AlteroUniversal'
export const defaultLogo = '/logo.png'
