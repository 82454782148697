import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataRateModal from '../Modals/DataRateModal/DataRateModal'
import { patchEquipment } from '../nsi.actions'
import { Equipment } from 'au-nsi/equipment'
import { ReduxState } from '../../../redux/store.types'
import { useIntl } from 'react-intl'

/**
 * Строка с информацией о скорости приема данных. При клике на значение открывается
 * модальное окно с формой для редактирования.
 */
const DataRate = (props: Props) => {
  const [open, setOpen] = React.useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const draft = useSelector((state: ReduxState) =>
    state.data_rates.data_rates.filter((dr) => dr.device_id === props.device.id)
  )

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleChange = (data_rate, data_rate_source) => {
    const action = patchEquipment(props.device.id, { data_rate, data_rate_source })
    dispatch(action)
  }

  return (
    <div className="editable-text__wrapper">
      <div className="editable-text" onClick={openModal}>
        {props.device.data_rate}
      </div>
      {open && (
        <DataRateModal
          intl={intl}
          draft={{ data_rates: draft }}
          device_id={props.device.id}
          data_rate={props.device.data_rate}
          data_rate_source={props.device.data_rate_source}
          translations={props.translations}
          protocol={props.device.protocol}
          onChange={handleChange}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

interface Props {
  device: Equipment
  translations: { [x: string]: string }
  allowEditing: boolean
}

export default DataRate
