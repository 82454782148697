import { InputOptions } from '../../../shared/Forms/forms.utils'
import { Equipment } from 'au-nsi/equipment'
import MappingForm from './MappingForm'

const defaultMapping = { ioa: 0, factor: 1, offset: 0 }

const fields: InputOptions[] = [
  { type: 'number', key: 'factor' },
  { type: 'number', key: 'offset' },
  { type: 'number', key: 'ioa', integer: true },
]

interface Props {
  device: Equipment
  allowEditing: boolean
}

const IEC104Mapping = ({ device, allowEditing }: Props) => {
  return (
    <MappingForm
      columnsSize={[49, 17, 17, 17]}
      device={device}
      fields={fields}
      defaultMapping={defaultMapping}
      allowEditing={allowEditing}
    />
  )
}

export default IEC104Mapping
