import { LoaderDots } from '@alterouniversal/au-react-components'
import { Equipment } from 'au-nsi/equipment'
import { IAepsSettings } from 'au-nsi/settings'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { wsSubscribe } from '../../../../services/ws/ws.events'
import http from '../../../../utils/http'
import { loadMicrofrontend } from '../../../../utils/module.loader'
import { selectAccessRights } from '../../../App/app.selectors'
import { useSettingsAPI } from '../../../System/Settings/settings.hooks'

interface Props {
  device: Equipment
}

// Custom Element registered by au-aeps module
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'au-aeps': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    }
  }
}

/**
 * Управление планом-графиком работы накопителя АЭПС
 */
const DeviceAEPSSettings = ({ device }: Props) => {
  const ref = useRef<any>()
  const intl = useIntl()

  const [moduleLoading, setModuleLoading] = useState(true)
  const [lastUpdate, setLastUpdate] = useState(0)

  const { settings, loading } = useSettingsAPI<IAepsSettings>('aeps-worktime-parser')
  const factoryID = getFactoryID(settings, device.id)

  const rights = useSelector(selectAccessRights)
  const allowEditing = rights['aeps_worktime:update']

  const render = () => {
    const el = ref.current
    if (el) el.props = { http, lang: intl.locale, factoryID, allowEditing, lastUpdate }
  }

  useEffect(() => {
    loadMicrofrontend({
      moduleID: 'AU_AEPS_MODULE',
      scriptSrc: '/nsi/v1/aeps/client/index.js',
      stylesSrc: '/nsi/v1/aeps/client/index.css',
      onload: () => setModuleLoading(false),
    })

    return wsSubscribe('aeps-worktime-parser', () => setLastUpdate(Date.now()))
  }, [])

  useEffect(render, [moduleLoading, factoryID, allowEditing, lastUpdate])

  if (moduleLoading || loading) {
    return <LoaderDots style={{ margin: '3rem 0' }} />
  }

  if (factoryID == null) {
    return (
      <div className="text--center text--danger" style={{ margin: '3rem 0' }}>
        {intl.formatMessage({ id: 'map.aeps.missing_settings' })}
      </div>
    )
  }

  return (
    <div style={{ height: 'calc(100vh - 50px)', margin: '0 -30px' }}>
      <au-aeps ref={ref} />
    </div>
  )
}

// получить id накопителя смапленного на выбранное устройство
const getFactoryID = (settings: IAepsSettings, deviceID: string) => {
  if (!settings) return null

  const mapping = settings.files_mapping.find((e) => e.device_id === deviceID)
  return mapping && mapping.factory_id
}

export default DeviceAEPSSettings
