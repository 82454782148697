import { Equipment } from 'au-nsi/equipment'
import { IMapBounds } from './mapTopology.utils'

class EquipmentFilter {
  private readonly sortedByLat: Equipment[]

  constructor(equipment: Equipment[]) {
    this.sortedByLat = [...equipment]
    this.sortedByLat.sort((a, b) => a.address.lat - b.address.lat)
  }

  private static latGetter(e: Equipment) {
    return e.address.lat
  }

  private static binarySearch<T, U extends Primitive>(array: T[], searchingElement: U, getter: (el: T) => U): number {
    let left = 0
    let right = array.length - 1

    while (left < right) {
      const mid = left + Math.floor((right - left) / 2)
      const el = getter(array[mid])

      if (el === searchingElement) {
        return mid
      } else if (el > searchingElement) {
        right = mid - 1
      } else {
        left = mid + 1
      }
    }

    return left
  }

  public getFittedIntoBounds(bounds: IMapBounds) {
    if (!this.sortedByLat.length) return []

    let leftLatBoundIndex = EquipmentFilter.binarySearch(this.sortedByLat, bounds.sw.lat, EquipmentFilter.latGetter)
    let rightLatBoundIndex = EquipmentFilter.binarySearch(this.sortedByLat, bounds.ne.lat, EquipmentFilter.latGetter)

    if (bounds.sw.lat > this.sortedByLat[leftLatBoundIndex].address.lat) leftLatBoundIndex++
    if (bounds.ne.lat < this.sortedByLat[rightLatBoundIndex].address.lat) rightLatBoundIndex--

    const resultingArray = []
    for (let i = leftLatBoundIndex; i < rightLatBoundIndex + 1; i++) {
      if (bounds.sw.lng <= this.sortedByLat[i].address.lng && this.sortedByLat[i].address.lng <= bounds.ne.lng) {
        resultingArray.push(this.sortedByLat[i])
      }
    }

    return resultingArray
  }
}

type Primitive = number | boolean | string

export default EquipmentFilter
