import React from 'react'
import SettingsPage from '../../App/SettingsPage'
import ArchivePage from '../../Archive/ArchivePage'
import { catalogsRoutes } from '../../Catalogs/catalogs.routes'
import CatalogsPage from '../../Catalogs/CatalogsPage'
import DashboardsPage from '../../Dashboards/DashboardsPage'
import { librariesRoutes } from '../../Libraries/libraries.routes'
import LibrariesPage from '../../Libraries/LibrariesPage'
import MapTopology from '../../MapTopology/MapTopology'
import PqPage from '../../PqDiagram/PqDiagram'
import JournalPage from '../../System/Journal'
import archiveRoutes from './archive.routes'
import journalRoutes from './journal.routes'
import { IRoute } from './nav.utils'
import settingsRoutes from './settings.routes'
import UserPage from '../../User/UserPage'

const ReportsPage = React.lazy(() => import('../../Reports/ReportsPage'))

const appRoutes: IRoute[] = [
  {
    id: 'map',
    path: '/map',
    component: MapTopology,
    modules: ['map_topology'],
    rights: ['dashboards:get_map_topology'],
  },
  { id: 'dashboards', path: '/', component: DashboardsPage, rights: ['dashboards:get', 'dashboards:update'] },
  {
    id: 'alerts',
    path: '/dashboards/alerts/*',
    component: PqPage,
    modules: ['au-signal-events'],
    rights: ['incidents:get'],
  },
  {
    id: 'archive',
    path: '/archive/*',
    component: ArchivePage,
    children: archiveRoutes,
    rights: ['archive:import', 'archive:export'],
  },
  {
    id: 'journal',
    path: '/journal/*',
    component: JournalPage,
    rights: [
      'journal:get_sessions',
      'journal:get_logins',
      'journal:get_logs',
      'journal:get_changelog',
      'incidents:get',
    ],
    children: journalRoutes,
  },
  {
    id: 'catalogs',
    path: '/catalogs/*',
    component: CatalogsPage,
    rights: ['catalogs:get'],
    children: catalogsRoutes,
    modules: ['catalogs'],
  },
  {
    id: 'reports',
    path: '/reports/*',
    component: ReportsPage,
    rights: ['reports:get'],
    modules: ['au-aepf-ms'],
  },
  {
    id: 'libraries',
    path: '/libraries/*',
    rights: ['images:get', 'audio:get', 'documents:get', 'documents:update', 'audio:update', 'images:update'],
    children: librariesRoutes,
    component: LibrariesPage,
  },
  {
    id: 'settings',
    path: '/settings/*',
    component: SettingsPage,
    children: settingsRoutes,
    rights: settingsRoutes.map((route) => route.rights).flat(),
  },
  {
    id: 'user',
    path: '/user/*',
    component: UserPage,
  },
]

export default appRoutes
