export const verificationDangerLevels = (intl, service: string) => {
  switch (service) {
    case 'au-aepf-ms':
    case 'au-soti-asso':
      return [
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_1' }),
          value: 1,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_3' }),
          value: 2,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_4' }),
          value: 3,
        },
      ]
    case 'au-pmu-verification_1':
    case 'au-pmu-verification_2':
      return [
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_1' }),
          value: 1,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_2' }),
          value: 2,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_3' }),
          value: 3,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.danger_level_4' }),
          value: 4,
        },
      ]
    case 'au-pmu-verification_3':
      return [
        {
          label: intl.formatMessage({ id: 'system.verification.phases_error' }),
          value: null,
        },
        {
          label: intl.formatMessage({ id: 'system.verification.polarity_error' }),
          value: null,
        },
      ]
  }
}

export const compareOptions = ['<', '<=', '=', '>=', '>', 'in']

export const verificationOptionsTableHeader = (intl, service: string, headSuffix = '') => {
  switch (service + headSuffix) {
    case 'au-soti-asso-current':
      return [{ id: 'system.verification.danger_level' }, { id: 'system.verification.power_current' }].map((head) =>
        intl.formatMessage(head)
      )
    case 'au-soti-asso-power':
      return [{ id: 'system.verification.danger_level' }, { id: 'system.verification.power_criterion' }].map((head) =>
        intl.formatMessage(head)
      )
    case 'au-aepf-ms':
    case 'au-pmu-verification_1':
      return [{ id: 'system.verification.danger_level' }, { id: 'system.verification.criterion' }].map((head) =>
        intl.formatMessage(head)
      )
    case 'au-pmu-verification_2':
      return [
        { id: 'system.verification.danger_level' },
        { id: 'system.verification.absoluteCriterionAngleOptions' },
      ].map((head) => intl.formatMessage(head))
    case 'au-pmu-verification_3':
      return [{ id: 'system.verification.violation_type' }, { id: 'system.verification.absoluteAngleInfelicity' }].map(
        (head) => intl.formatMessage(head)
      )
  }
}
