import styles from '../../../user.module.css'
import TextareaInput from '../../../../../shared/Inputs/TextareaInput'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import { createRequestMessage } from './requestMessages.api'
import Loader from '../../../../../shared/Utils/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import moderationActions from '../../moderation.actions'
import { createSelector } from 'reselect'
import { nanoid } from '@reduxjs/toolkit'
import { formatDate } from '../../../../../utils/lang'
import { IModerationRequest, IRequestMessage } from 'au-nsi/moderation'

interface IProps {
  request: IModerationRequest
}

/**
 * Компонент чата под формой заявки на модерацию
 * */
const RequestMessages = ({ request }: IProps) => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const userId = useSelector((state: ReduxState) => state.user.id)
  const usersMap = useSelector(usersMapSelector)
  const { wait, loading } = useHttpLoader()
  const [message, setMessage] = useState<IRequestMessage>(generateMessage())

  useEffect(() => {
    setMessage({ ...message, id: nanoid(10) })
  }, [request.messages])

  const handleChange = (val, name) => setMessage({ ...message, [name]: val })

  const handleSend = () => {
    if (loading) return

    const trimmedMessage = message.message.trim()
    if (trimmedMessage.length === 0) return

    wait(createRequestMessage({ ...message, created_at: Date.now(), message: trimmedMessage }, request.id), (resp) => {
      dispatch(moderationActions.messageCreated({ message: resp, requestId: request.id }))
      setMessage({ ...message, id: message.id + 1, message: '' })
    })
  }

  const renderMessages = () => {
    if (request.messages.length === 0) {
      return <span style={{ color: 'var(--gray-30)' }}>{intl.formatMessage({ id: 'user.request.no_messages' })}</span>
    }

    const isModerator = request.created_by !== userId

    return request.messages.map((message) => {
      const isRight = isModerator ? message.userId !== request.created_by : message.userId === userId
      const className = `${styles.requestMessage} ${isRight ? styles.rightRequestMessage : styles.leftRequestMessage}`

      return (
        <div className={className} key={message.id}>
          <span className={styles.requestMessageAuthor}>
            <span>{usersMap[message.userId]?.name || intl.formatMessage({ id: 'common.admin' })}</span>
            <span className={styles.requestMessageTime}>{formatDate(message.created_at, 'dd MMM HH:mm')}</span>
          </span>
          <span>{message.message}</span>
        </div>
      )
    })
  }

  return (
    <div className={styles.requestChatWrapper}>
      <h2>{intl.formatMessage({ id: 'user.requests.comments' })}</h2>
      <div className={styles.messagesWrapper}>{renderMessages()}</div>
      {!request.moderated_at && (
        <div className={styles.requestChatInputWrapper}>
          <TextareaInput
            value={message.message}
            name={'message'}
            onChange={handleChange}
            style={{ margin: 0, width: '100%' }}
          />
          <button className={'nsi-button default'} style={{ maxWidth: '130px' }} onClick={handleSend}>
            {loading ? <Loader /> : intl.formatMessage({ id: 'common.send' })}
          </button>
        </div>
      )}
    </div>
  )
}

const usersMapSelector = createSelector(
  (state: ReduxState) => state.users.items,
  (users) => users.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
)

const generateMessage = () => ({
  message: '',
  id: nanoid(10),
  created_at: undefined,
})

export default RequestMessages
