import { Equipment } from 'au-nsi/equipment'
import { useSelector } from 'react-redux'
import { selectPassports } from '../../../../Catalogs/catalogs.selectors'
import PassportSelect from '../../../../Catalogs/components/Inputs/PassportSelect'
import { useMemo, useState } from 'react'
import InputRow from '../../../../../shared/Inputs/InputRow'
import { useIntl } from 'react-intl'
import TemplateTile from './TemplateTile/TemplateTile'
import styles from './catalogTemplate.module.css'
import PrevButton from '../../../../../shared/Utils/PrevButton'
import { ICatalogItem } from 'au-nsi/catalogs'

interface IProps {
  device: Equipment
  onSelect: (template: ICatalogItem) => void
  onCancel: () => void
}

/**
 * Компонент списка шаблонов в карте устройств
 */
const SelectTemplateScreen = (props: IProps) => {
  const intl = useIntl()
  const passports = useSelector(selectPassports)
  const [draftDevice, setDraftDevice] = useState(props.device)

  const selectedPassport = useMemo(
    () => passports.find((passport) => passport.id === draftDevice.passport_catalog_id),
    [draftDevice.passport_catalog_id, passports]
  )

  const handleChange = <T extends keyof Equipment>(val: Equipment[T], name: T) => {
    setDraftDevice((prev) => ({ ...prev, [name]: val }))
  }

  const renderTemplates = () => {
    if (!selectedPassport) return null
    if (!selectedPassport.templates.length) {
      return <p className="center text--gray">{intl.formatMessage({ id: 'catalogs.templates.no_one_found' })}</p>
    }

    return selectedPassport.templates.map((template) => (
      <TemplateTile
        template={template}
        onSelect={props.onSelect}
        deviceId={props.device.id}
        schema={selectedPassport.schema}
        key={template.id}
      />
    ))
  }

  return (
    <>
      <h2 className={styles.screenHeader}>
        {intl.formatMessage({ id: 'catalogs.templates.select' })}
        <PrevButton onCLick={props.onCancel} />
      </h2>
      <div className={`system__grid ${styles.hr}`}>
        <InputRow label={intl.formatMessage({ id: 'catalogs.schema' })}>
          <PassportSelect
            selectedId={draftDevice.passport_catalog_id}
            name={'passport_catalog_id'}
            onChange={handleChange}
          />
        </InputRow>
      </div>
      <dl>{renderTemplates()}</dl>
    </>
  )
}

export default SelectTemplateScreen
