import css from './mobileLeftMenu.module.css'
import { createPortal } from 'react-dom'
import { forwardRef, PropsWithChildren } from 'react'
import { mobileNavPortalBlockId } from '../../../pages/TopMenu/Nav/MobileNav'

/**
 * Боковое меню экрана для мобильных устройств.
 *
 * Рендерит контекнт в портале в блок с id={mobileNavPortalBlockId}
 * см. {MobileNav.tsx}
 * */
const MobileLeftMenu = forwardRef<HTMLDivElement, PropsWithChildren<unknown>>(({ children }, ref) => {
  const container = document.getElementById(mobileNavPortalBlockId)
  if (!container) return null

  const renderMenu = () => {
    return (
      <div className={css.menuContentWrapper}>
        <div ref={ref} className={css.children}>
          {children}
        </div>
      </div>
    )
  }

  return createPortal(renderMenu(), container)
})

export default MobileLeftMenu
