import { Equipment } from 'au-nsi/equipment'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '../../../../App/app.selectors'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import AEConnectIdModal from './AEConnectIdModal'

interface IProps {
  controllerId: string
  deviceId: Equipment['id']
}

const AEConnectControllerId = (props: IProps) => {
  const intl = useIntl()
  const accessRights = useSelector(selectAccessRights)
  const [modalOpen, setModalOpen] = useState(false)

  if (!accessRights['equipment:configure']) return null

  return (
    <>
      <button className="nsi-button default" onClick={() => setModalOpen(true)} style={{ marginLeft: 0 }}>
        {intl.formatMessage({ id: props.controllerId ? 'map.ae.edit_uid' : 'map.ae.connect_uid' })}
      </button>
      {modalOpen && <AEConnectIdModal {...props} onClose={() => setModalOpen(false)} />}
    </>
  )
}

export default AEConnectControllerId
