import React from 'react'
import { FormattedMessage } from 'react-intl'
import { frame2ms, FrameHuman, ms2frame, units } from './frame.utils'

import './frame.styles.css'

export default class FrameControls extends React.PureComponent<FrameControlsProps> {
  input: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  componentDidMount() {
    this.updateInputValue(this.props.frame)
  }

  componentDidUpdate() {
    this.updateInputValue(this.props.frame)
  }

  private updateInputValue(ms: number) {
    const { value } = ms2frame(ms)
    this.input.current.value = value.toString()
  }

  private onFrameValueChange = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const value = parseFloat(this.input.current.value) || 1
      const { unit } = ms2frame(this.props.frame)
      this.updateFrame({ value, unit })
    }
  }

  private onFrameUnitChange = (e: any) => {
    const { unit, disabled } = e.target.dataset

    if (disabled === 'false') {
      const value = +this.input.current.value
      this.updateFrame({ value, unit })
    }
  }

  private updateFrame(frame: FrameHuman) {
    const { onFrameChange } = this.props
    let ms = frame2ms(frame)

    if (ms < this.props.minFrame) {
      ms = this.props.minFrame
    }

    if (ms > this.props.maxFrame) {
      ms = this.props.maxFrame
    }

    this.updateInputValue(ms)
    return onFrameChange && onFrameChange(ms)
  }

  private renderFrameUnits(selectedUnit: string) {
    const unitNames = Object.keys(units)

    return unitNames.map((unit, i) => {
      const unitValue = units[unit]
      const nextUnitValue = units[unitNames[i + 1]]
      const id = `ChartControls.${unit}`

      let className = 'chart-player__frame-type'

      const disabled = unitValue > this.props.maxFrame || nextUnitValue <= this.props.minFrame
      const selected = selectedUnit === unit

      if (disabled) className += ' chart-player__frame-type_disabled'
      if (selected) className += ' chart-player__frame-type_selected'

      return (
        <span key={id} onClick={this.onFrameUnitChange} data-unit={unit} data-disabled={disabled} className={className}>
          <FormattedMessage id={id} />
        </span>
      )
    })
  }

  render() {
    const frame = ms2frame(this.props.frame)

    return (
      <div className="chart-player__frame">
        <label htmlFor="chart-player-frame">
          <FormattedMessage id="ChartPlayer.frame" />
        </label>
        <input
          id="chart-player-frame"
          className="chart-player__input chart-player__frame-value"
          ref={this.input}
          defaultValue={frame.value.toString()}
          onKeyUp={this.onFrameValueChange}
        />
        {this.renderFrameUnits(frame.unit)}
      </div>
    )
  }
}

export interface FrameControlsProps {
  frame: number
  minFrame: number
  maxFrame: number
  onFrameChange?: (ms: number) => void
}
