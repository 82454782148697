import { ReactComponent as ViewIcon } from '../../icons/view.svg'
import { CSSProperties, useState } from 'react'

export interface PasswordOptions {
  value: string
  name: string
  onChange: (val: string, name: string) => void
  autocomplete?: string
  required?: boolean
  style?: CSSProperties
  className?: string
  disabled?: boolean
}

const PasswordInput = ({
  value,
  name,
  onChange,
  required,
  style,
  className,
  autocomplete,
  disabled,
}: PasswordOptions) => {
  const [inputType, setInputType] = useState('password')

  const handleChange = (ev) => {
    onChange(ev.target.value, name)
  }

  const handleViewToggle = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  if (disabled) return <span>{'*'.repeat(value?.length || 0)}</span>

  return (
    <div style={{ position: 'relative' }}>
      <input
        type={inputType}
        style={style}
        required={required || false}
        className={className || 'nsi-input'}
        onChange={handleChange}
        value={value}
        autoComplete={autocomplete || 'off'}
      />
      <ViewIcon
        style={{
          width: '15px',
          height: '15px',
          top: 'calc(50% - 7.5px)',
          position: 'absolute',
          right: '10px',
          cursor: 'pointer',
        }}
        onClick={handleViewToggle}
      />
    </div>
  )
}

export default PasswordInput
