import { LoaderBg } from '@alterouniversal/au-react-components'
import { IAttribute } from 'au-nsi/equipment'
import { IAepsSettings } from 'au-nsi/settings'
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg'
import TemplateControls from 'pages/ChartPlayer/TemplateControls/TemplateControls'
import { createElement, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { wsSubscribe } from 'services/ws/ws.events'
import Datepicker from 'shared/Inputs/Datepicker/Datepicker'
import Dropdown from 'shared/Inputs/Dropdown'
import { loadMicrofrontend } from 'utils/module.loader'
import { getAttributeValue } from '../../../shared/Attributes/attributes.utils'
import http from '../../../utils/http'
import { showError } from '../../../utils/notifications'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import { selectParametersMap } from '../../Parameters/params.selectors'
import { useSettingsAPI } from '../../System/Settings/settings.hooks'
import css from './aeps.module.css'
import './intl/storageWidget.intl'

const DAY = 24 * 3600_000

/**
 * Экран "План/факт работы накопителя для коммерческой службы".
 * Данный компонент является только оберткой, которая загружает и инициализирует нужный
 * веб-компонент из микросервиса au-nsi-aeps
 */
const AEPSCommercialWidget = () => {
  const params = useParams()
  const ref = useRef<any>()
  const intl = useIntl()

  const { settings } = useSettingsAPI<IAepsSettings>('aeps-worktime-parser')
  const [deviceId, setDeviceId] = useState(params.deviceId)

  const factory_id = settings?.files_mapping.find((e) => e.device_id === deviceId)?.factory_id
  const aiisque_id = settings?.xml_mapping.find((e) => e.device_id === deviceId)?.group_id

  const equipment = useAppSelector(selectEquipmentMap)
  const parameters = useAppSelector(selectParametersMap)
  const dashboards = useAppSelector((state) => state.dashboards.dashboards)

  const [loading, setLoading] = useState(true)
  const [lastUpdate, setLastUpdate] = useState(0)

  // опции для выбора устройства: при переходе с карточки устройства оно уже будет выбрано,
  // а при переходе с главной страницы экранов пользователю надо будет самому выбрать устройство
  const equipmentOptions = useMemo(() => {
    if (!settings) return []
    const result = []

    for (const { device_id } of settings.files_mapping) {
      const device = equipment.get(device_id)
      if (device) result.push({ value: device.id, title: device.name })
    }

    return result
  }, [settings, equipment])

  // разница времени устройства с UTC в миллисекундах
  const timezoneOffset = useMemo(() => {
    const device = equipment.get(deviceId)
    const offset = device?.timezone?.offset
    return offset ? offset * 60_000 : 0
  }, [deviceId, equipment])

  // отображаемый на графике интервал - по умолчанию предыдущий день
  const defaultTimerange = useMemo(() => {
    const today = new Date()
    today.setUTCHours(0)
    today.setUTCMinutes(0)
    today.setUTCSeconds(0)
    today.setUTCMilliseconds(0)

    const t1 = today.valueOf() - timezoneOffset
    const t0 = t1 - DAY
    return { t0, t1 }
  }, [timezoneOffset])

  const [timerange, setTimerange] = useState(defaultTimerange)

  // defaultTimerange может измениться если первоначально список устройств
  // не был загружен и не был известен часовой пояс текущего устройства
  useEffect(() => setTimerange(defaultTimerange), [defaultTimerange])

  // настройки для графика АЭПС задаются в атрибутах для каждого устройства
  const deviceSettings = useMemo(() => {
    const device = equipment.get(deviceId)
    if (!device) return null

    const attributes = device.attributes as IAttribute[]
    const calendar_id = getAttributeValue(attributes, 'aeps.calendar_id')
    const price_zone = getAttributeValue(attributes, 'aeps.price_zone')
    const federal_subject = getAttributeValue(attributes, 'aeps.federal_subject')
    const soc_parameter_id = getAttributeValue(attributes, 'aeps.soc_parameter_id')
    const soc_parameter = parameters.get(soc_parameter_id)
    const timezone = device.timezone

    return { device_id: device.id, calendar_id, price_zone, federal_subject, soc_parameter, timezone }
  }, [deviceId, equipment, parameters])

  // Datepicker принимает и возвращает дату в виде строки,
  // поэтому ее необходимо преобразовать обратно в таймстамп
  const handleDateChange = (date: string, key: string) => {
    const ts = str2ts(date, timezoneOffset)
    setTimerange((prev) => ({ ...prev, [key]: ts }))
  }

  // сдвиг выбранного интервала на день вперед или назад
  const dayForward = () => setTimerange((prev) => ({ t0: prev.t0 + DAY, t1: prev.t1 + DAY }))
  const dayBackward = () => setTimerange((prev) => ({ t0: prev.t0 - DAY, t1: prev.t1 - DAY }))

  useEffect(() => {
    loadMicrofrontend({
      moduleID: 'AU_AEPS_MODULE',
      scriptSrc: '/nsi/v1/aeps/client/index.js',
      stylesSrc: '/nsi/v1/aeps/client/index.css',
      onload: () => setLoading(false),
    })

    return wsSubscribe('aeps-worktime-parser', () => setLastUpdate(Date.now()))
  }, [])

  // передача данных в микрофронтенд при любом их изменении
  useEffect(() => {
    if (ref.current && settings && deviceSettings && timerange.t0 < timerange.t1) {
      const lang = intl.locale
      ref.current.props = { http, lang, factory_id, aiisque_id, timerange, setTimerange, ...deviceSettings, lastUpdate }
    }
  })

  // если пользователь случайно оказался на странице устройства, которого нет
  // в настройках АЭПС, то сообщаем ему об этом
  useEffect(() => {
    if (settings && deviceId && !factory_id) showError('storageWidget.errors.missing_factory_mapping')
  }, [settings, factory_id])

  const inputClass = 'nsi-input ' + css.dateInput
  const datepickerProps = { renderTime: false, onDateChange: handleDateChange, inputClass }

  const allowNextDay = timerange.t1 < defaultTimerange.t1

  const arrowStyle = { width: '18px', cursor: 'pointer', margin: '0 0.5em' }
  const prevDayStyle = { ...arrowStyle, transform: 'rotate(180deg)' }
  const nextDayStyle = {
    ...arrowStyle,
    cursor: allowNextDay ? 'pointer' : 'not-allowed',
    opacity: allowNextDay ? 1 : 0.5,
  }

  const prevDay = intl.formatMessage({ id: 'storageWidget.prev_day' })
  const nextDay = intl.formatMessage({ id: 'storageWidget.next_day' })

  const deviceName = equipment.get(deviceId)?.name || ''
  const title = dashboards.find((d) => d.id === 'aepsc')?.name || ''

  return (
    <>
      <div className={css.header}>
        <div>{intl.formatMessage({ id: 'storageWidget.timerange' })}:</div>
        <ArrowIcon style={prevDayStyle} title={prevDay} onClick={dayBackward} />
        <Datepicker {...datepickerProps} name="t0" date={ts2str(timerange.t0, timezoneOffset)} />
        <div style={{ padding: '0 0.5em' }}>&mdash;</div>
        <Datepicker {...datepickerProps} name="t1" date={ts2str(timerange.t1, timezoneOffset)} />
        <ArrowIcon style={nextDayStyle} title={nextDay} onClick={allowNextDay ? dayForward : null} />

        {!params.deviceId && (
          <>
            <div style={{ marginRight: '1em', marginLeft: '2em' }}>
              {intl.formatMessage({ id: 'storageWidget.device' })}:
            </div>
            <div style={{ width: '200px' }}>
              <Dropdown value={deviceId} options={equipmentOptions} onChange={setDeviceId} />
            </div>

            <div className="chart-player__title">{title}</div>
          </>
        )}

        {params.deviceId && (
          <TemplateControls title={deviceName + ' / ' + title} deviceId={deviceId} currentTemplateId="aepsc" />
        )}
      </div>

      <div style={{ height: 'calc(100vh - 100px)', overflowY: 'auto', background: 'var(--bg-default)' }}>
        {loading ? <LoaderBg /> : createElement('au-aeps-chart', { ref })}
      </div>
    </>
  )
}

// unix timestamp to string 'dd.MM.YYYY'
const ts2str = (ts: number, timezoneOffset: number) => {
  const date = new Date(ts + timezoneOffset)
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()

  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`
}

// string in 'dd.MM.YYYY' to unix timestamp
const str2ts = (str: string, timezoneOffset: number) => {
  const [day, month, year] = str.split('.')
  const date = Date.UTC(+year, +month - 1, +day)

  return date.valueOf() - timezoneOffset
}

export default AEPSCommercialWidget
