import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { Equipment } from 'au-nsi/equipment'
import { selectLogicalPath } from '../nsi.selectors'

/**
 * Информация о родительских устройствах - отображается только для виртуальных устройств,
 * которые являются дочерними элементами составных устройств.
 */
const DeviceLogicalPath = (props: Props) => {
  const path = useSelector((state: ReduxState) => selectLogicalPath(state))

  const handleClick = () => {
    const parent = props.device.path.split('.').pop()
    props.onNavigate(parent)
  }

  return (
    <span className="editable-text nsi-link" onClick={handleClick}>
      {path.join(' -> ')}
    </span>
  )
}

interface Props {
  device: Equipment
  onNavigate: (id: string) => void
}

export default DeviceLogicalPath
