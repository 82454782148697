import AccessRequestForm from './AccessRequetsForm/AccessRequestForm'
import CatalogRequestForm from './CatalogRequestForm/CatalogRequestForm'
import ItemRequestForm from './CatalogItemRequestForm/ItemRequestForm'
import LogbookRequestForm from './LogbookRequestForm'
import OrganizationTypeRequestForm from './OrganizationTypeRequestForm/OrganizationTypeRequestForm'
import EquipmentRequestForm from './EquipmentRequestForm/EquipmentRequestForm'
import UserRequestForm from './UserRequestForm/UserRequestForm'
import DocumentRequestForm from './DocumentRequestForm/DocumentRequestForm'
import OrganizationRequestForm from './OrganizationRequestForm/OrganizationRequestForm'
import { useRecoilValue } from 'recoil'
import { selectedDraftRequestState } from '../request.recoil'
import GroupRequestForm from './GroupRequestForm/GroupRequestForm'

interface IProps {
  editing: boolean
}

const RequestForm = ({ editing }: IProps) => {
  const request = useRecoilValue(selectedDraftRequestState)

  if (request.resource === 'equipment' && request.method === 'POST' && request.url.includes('/access/grant')) {
    return <AccessRequestForm editing={editing} />
  }

  switch (request.resource) {
    case 'catalogs':
      return <CatalogRequestForm editing={editing} />
    case 'catalog_items':
      return <ItemRequestForm editing={editing} />
    case 'equipment_logbook':
      return <LogbookRequestForm editing={editing} />
    case 'organizations_types':
      return <OrganizationTypeRequestForm editing={editing} />
    case 'organizations':
      return <OrganizationRequestForm editing={editing} />
    case 'equipment':
      return <EquipmentRequestForm editing={editing} />
    case 'users':
      return <UserRequestForm editing={editing} />
    case 'documents':
      return <DocumentRequestForm editing={editing} key={request.moderated_at} />
    case 'ae_groups':
      return <GroupRequestForm editing={editing} />
  }

  return null
}

export default RequestForm
