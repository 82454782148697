/**
 * Округление числа до указанного количества значащих цифр
 */
export const roundNumber = (n: number, significantDigits = 4) => {
  if (!n) return n

  const upper = Math.pow(10, significantDigits)
  const lower = upper / 10

  let factor = 1
  let scaled = Math.abs(n)

  while (scaled < lower || scaled > upper) {
    const multiplier = scaled < lower ? 10 : 0.1
    factor *= multiplier
    scaled *= multiplier
  }

  return (Math.sign(n) * Math.round(scaled)) / factor
}

/**
 * Определить порядок первой значащей цифры указанного числа
 */
export const getNumberPrecision = (x: number) => {
  if (!x || x < 0 || x > 1) return 0

  let precision = 1
  let scaled = x

  while (scaled < 0.1) {
    precision += 1
    scaled *= 10
  }

  return precision
}
