import { IDashboard } from 'au-nsi/dashboards'
import { useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import AccessForm from '../../../shared/Access/AccessForm'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import FormTabs from '../../../shared/Utils/FormTabs'
import ImageInput from '../../Libraries/Images/ImageInput'
import TemplateSettingsForm from './TemplateSettingsForm'

interface IProps {
  dashboard: IDashboard
  title: string
  onSave: (d: IDashboard) => void
  onCancel: () => void
}

/**
 * Модальное окно с формой создания/редактирования экрана
 */
const DashboardsModal = ({ dashboard, title, onSave, onCancel }: IProps) => {
  const intl = useIntl()
  const [state, setState] = useState(dashboard)

  const handleChange = (value: any, key: string) => setState({ ...state, [key]: value })

  const handleSave = () => state.name && onSave(state)

  const isNewDashboard = !dashboard.id
  const showTemplateSettings = !isNewDashboard && !dashboard.is_system

  const tabs = [
    intl.formatMessage({ id: 'dashboards.settings.tabs.main' }),
    intl.formatMessage({ id: 'dashboards.settings.tabs.access' }),
  ]

  if (state.is_template) {
    tabs.push(intl.formatMessage({ id: 'dashboards.settings.tabs.template' }))
  }

  const error = validateTemplateSettings(state, intl)
  const disabled = error != null || !state.name

  return (
    <Modal size="lg" onClose={onCancel}>
      <h2>{intl.formatMessage({ id: title })}</h2>

      <Form editing={true} onCancel={onCancel} onSubmit={handleSave}>
        <FormTabs titles={tabs}>
          <div style={{ marginTop: '1.5rem' }}>
            <div className="system__grid">
              <InputRow label={intl.formatMessage({ id: 'common.name' })}>
                <TextInput name="name" value={state.name} onChange={handleChange} required={true} />
              </InputRow>

              <InputRow label={intl.formatMessage({ id: 'system.images.src' })}>
                <ImageInput name="image_id" value={(state as IDashboard).image_id} onChange={handleChange} />
              </InputRow>
            </div>

            {showTemplateSettings && (
              <div className="system__input-wrapper">
                <ToggleWithLabel
                  name="is_template"
                  value={state.is_template || false}
                  onChange={handleChange}
                  label={intl.formatMessage({ id: 'dashboards.settings.is_template' })}
                />
              </div>
            )}
          </div>

          <div style={{ marginTop: '1.5rem' }}>
            <AccessForm access={state.access} onChange={handleChange} />
          </div>

          <div style={{ marginTop: '1.5rem' }}>
            <TemplateSettingsForm
              id={dashboard.id}
              settings={state.template_settings}
              is_system={dashboard.is_system}
              onChange={handleChange}
            />
          </div>
        </FormTabs>

        <ModalFooter error={error} disabled={disabled} onCancel={onCancel} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

// валидация настроек шаблона - должна быть задана минимум одна переменная шаблона
const validateTemplateSettings = (dashboard: IDashboard, intl: IntlShape) => {
  if (!dashboard.is_template) return null

  const error = intl.formatMessage({ id: 'dashboards.settings.errors.no_template_variables' })
  const settings = dashboard.template_settings
  if (!settings || !settings.variables || !settings.variables.length) return error

  const variable = settings.variables.find((v) => v.name && v.device_id)
  if (!variable) return error

  return null
}

export default DashboardsModal
