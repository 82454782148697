import { NsiState } from './nsi.interfaces'
import { orderTopology } from './nsi.utils'

export const SET_STATE = 'app/nsi/SET_STATE'
export const SET_REGIONS = 'app/nsi/SET_REGIONS'
export const ADD_REGION = 'app/nsi/ADD_REGION'
export const UPDATE_REGION = 'app/nsi/UPDATE_REGION'
export const DELETE_REGION = 'app/nsi/DELETE_REGION'
export const UPDATE_REGION_BATCH = 'app/nsi/UPDATE_REGION_BATCH'
export const SELECT_DEVICE = 'app/nsi/SELECT_DEVICE'
export const SET_EQUIPMENT = 'app/nsi/SET_EQUIPMENT'
export const ADD_EQUIPMENT = 'app/nsi/ADD_EQUIPMENT'
export const UPDATE_EQUIPMENT = 'app/nsi/UPDATE_EQUIPMENT'
export const UPDATE_EQUIPMENT_BATCH = 'app/nsi/UPDATE_EQUIPMENT_BATCH'
export const DELETE_EQUIPMENT = 'app/nsi/DELETE_EQUIPMENT'
export const SELECT_REGION = 'app/nsi/SELECT_REGION'
export const SET_FORM_MODE = 'app/nsi/SET_FORM_MODE'
export const TOGGLE_PANEL = 'app/nsi/TOGGLE_PANEL'
export const TOGGLE_INACTIVE_FILTER = 'app/nsi/TOGGLE_INACTIVE_FILTER'
export const SET_DEVICE_PARAMETERS = 'app/nsi/SET_DEVICE_PARAMETERS'

export const NSI_PERSIST_IGNORE = [SET_FORM_MODE, TOGGLE_PANEL]

const defaultState: NsiState = {
  regions: [],
  equipment: [],
  equipmentAll: [],
  selectedDeviceId: null,
  selectedRegionId: null,
  regionsLoaded: false,
  equipmentLoaded: false,
  showInactive: false,
  mode: 'view',
  openPanels: {
    attributes: false,
    commands: false,
    computable_parameters: false,
    connection_config: true,
    data_verification: false,
    protocol_config: false,
    rated_values: false,
    passport: false,
  },
  rawDeviceParameters: {},
  processedDeviceParameters: {},
  deviceParametersLoaded: 0,
}

const nsiReducer = (state: NsiState = defaultState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default nsiReducer

type Reducer = (state: NsiState, action: any) => NsiState

const reducers: { [type: string]: Reducer } = {
  [SET_STATE]: (state, action) => {
    const {
      equipment,
      equipmentAll,
      processedDeviceParameters,
      rawDeviceParameters,
      regions,
      selectedDeviceId,
      selectedRegionId,
      showInactive,
    } = action.payload

    return {
      ...state,
      equipment,
      equipmentAll,
      processedDeviceParameters,
      rawDeviceParameters,
      regions,
      selectedDeviceId,
      selectedRegionId,
      showInactive,
    }
  },

  [SET_REGIONS]: (state, action) => {
    const { regions } = action
    const equipment = [...state.equipment]
    orderTopology(equipment, regions)

    return { ...state, regions, equipment, regionsLoaded: true }
  },

  [ADD_REGION]: (state, { region }) => {
    const isAlreadyExists = state.regions.find((r) => r.id === region.id)
    if (isAlreadyExists) return state

    const regions = [...state.regions, region]
    const equipment = [...state.equipment]
    orderTopology(equipment, regions)

    return { ...state, regions, equipment }
  },

  [UPDATE_REGION]: (state, action) => {
    const regions = state.regions.map((region) => {
      return region.id === action.id ? { ...region, ...action.updates } : region
    })

    const equipment = [...state.equipment]
    orderTopology(equipment, regions)

    return { ...state, regions, equipment }
  },

  [UPDATE_REGION_BATCH]: (state, action) => {
    let orderChanged = false
    let equipment = state.equipment

    const regions = state.regions.map((region) => {
      const updatedRegion = action.updates.find((r) => r.id === region.id)
      if (!updatedRegion) return region

      if (updatedRegion.user_ordering_index || updatedRegion.path) {
        orderChanged = true
      }

      return { ...region, ...updatedRegion }
    })

    if (orderChanged) {
      equipment = [...state.equipment]
      orderTopology(equipment, regions)
    }

    return { ...state, regions, equipment }
  },

  [DELETE_REGION]: (state, action) => {
    // удалить все дочерние элементы удаленного региона
    const regions = state.regions.filter((region) => {
      const parents = region.path.split('.')
      // сравнение строки и числа
      // eslint-disable-next-line
      return !parents.some((p) => p == action.id)
    })

    return { ...state, regions, selectedRegionId: null }
  },

  [SELECT_DEVICE]: (state, action) => {
    if (!action.id) return { ...state, selectedDeviceId: action.id }

    const device = state.equipmentAll.find((d) => d.id === action.id)

    // TODO: find more accurate way to check if it is new device
    const isNewDevice = device.protocol === 'C.37.118' && !device.configuration.target_host
    const openPanels = { ...state.openPanels, connection_config: isNewDevice || state.openPanels.connection_config }

    return { ...state, selectedDeviceId: action.id, openPanels, selectedRegionId: null, mode: 'view' }
  },

  [SET_EQUIPMENT]: (state, action) => {
    const regions = [...state.regions]
    orderTopology(action.equipment, regions)

    const equipment = action.equipment.filter((e) => !e.inactive)
    return { ...state, equipment, equipmentAll: action.equipment, equipmentLoaded: true }
  },

  [ADD_EQUIPMENT]: (state, action) => {
    const { device } = action

    const isAlreadyExists = state.equipmentAll.find((d) => d.id === device.id)
    if (isAlreadyExists) return state

    const equipmentAll = [...state.equipmentAll, device]
    const equipment = [...state.equipment, device]

    const regions = [...state.regions]
    orderTopology(equipment, regions)

    return { ...state, equipment, equipmentAll }
  },

  [UPDATE_EQUIPMENT]: (state, action) => {
    const equipmentAll = state.equipmentAll.map((device) => {
      return device.id === action.id ? { ...device, ...action.updates } : device
    })

    let regions = state.regions

    if (action.updates.user_ordering_index || action.updates.region_id) {
      regions = [...regions]
      orderTopology(equipmentAll, regions)
    }

    const equipment = equipmentAll.filter((e) => !e.inactive)
    return { ...state, equipment, equipmentAll, regions }
  },

  [UPDATE_EQUIPMENT_BATCH]: (state, action) => {
    let orderChanged = false
    let regions = state.regions

    const equipmentAll = state.equipmentAll.map((device) => {
      const update = action.updates.find((e) => e.id === device.id)
      if (!update) return device

      if (update.user_ordering_index || update.region_id) {
        orderChanged = true
      }

      return { ...device, ...update }
    })

    if (orderChanged) {
      regions = [...regions]
      orderTopology(equipmentAll, regions)
    }

    const equipment = equipmentAll.filter((e) => !e.inactive)

    return { ...state, equipment, equipmentAll }
  },

  [DELETE_EQUIPMENT]: (state, action) => {
    const equipmentAll = state.equipmentAll.filter((device) => device.id !== action.id)
    const equipment = state.equipment.filter((device) => device.id !== action.id)

    return { ...state, equipment, equipmentAll, selectedDeviceId: null }
  },

  [SELECT_REGION]: (state, action) => {
    return { ...state, selectedRegionId: action.id, selectedDeviceId: null, mode: 'view' }
  },

  [SET_FORM_MODE]: (state, { mode }) => {
    return { ...state, mode }
  },

  [TOGGLE_PANEL]: (state, action) => {
    const { name } = action
    const openPanels = { ...state.openPanels, [name]: !state.openPanels[name] }

    return { ...state, openPanels }
  },

  [TOGGLE_INACTIVE_FILTER]: (state) => {
    return { ...state, showInactive: !state.showInactive }
  },

  [SET_DEVICE_PARAMETERS]: (state, action) => {
    return {
      ...state,
      rawDeviceParameters: action.raw,
      processedDeviceParameters: action.processed,
      deviceParametersLoaded: Date.now(),
    }
  },
}
