import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import usePasswordRules from '../../../hooks/usePasswordRules'
import { selectLanguage } from '../../App/app.selectors'
import { AnyCatalogWithInstances } from '../../Catalogs/catalogs.types'
import { OrganizationTypeWithInstances } from '../../Catalogs/Organizations/organizations.types'
import { getResponseErrorMessage } from '../auth.utils'
import { AuthWrapper } from '../AuthWrapper'
import { requestRegister, selectRegisterCatalogsDeps } from '../register.api'
import { OrganizationInfoForm } from './OrganizationInfoForm'
import { createUser, UserData, validateOrgProperties, validateUserProperties } from './register.utils'
import { RegisterControlButtons } from './RegisterControlButtons'
import { UserInfoForm } from './UserInfoForm'
import { Organization } from 'au-nsi/organizations'

interface IProps {
  organizationsTypes: OrganizationTypeWithInstances[]
}

export const RegisterForm = ({ organizationsTypes }: IProps) => {
  const intl = useIntl()
  const locale = useSelector(selectLanguage)

  const [user, setUser] = useState<UserData>(createUser(locale))
  const [depsCatalogs, setDepsCatalogs] = useState<AnyCatalogWithInstances[]>([])

  const [page, setPage] = useState<number>(0)
  const [userOrganization, setUserOrganization] = useState<Organization>(undefined)
  const [isCompleted, setCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const { validator, help } = usePasswordRules(true)

  const organizationTypesOptions = useMemo(
    () => organizationsTypes.map((o) => ({ label: o.name, value: o.id })),
    [organizationsTypes]
  )

  const organizationsOptions =
    organizationsTypes
      .find((o) => o.id === user.organization_type_id)
      ?.instances?.map((org) => ({ label: org.name, value: org.id })) || []

  const selectedOrganizationType = organizationsTypes.find((o) => o.id === user.organization_type_id)

  const passwordValid = validator(user.password)
  const firstPageValid = passwordValid && validateUserProperties(user, selectedOrganizationType, userOrganization)
  const secondPageValid = validateOrgProperties(user, selectedOrganizationType)

  useEffect(() => {
    if (selectedOrganizationType?.id) {
      selectRegisterCatalogsDeps(selectedOrganizationType.id).then((r) => setDepsCatalogs(r))
      setUserOrganization(undefined)
    }
  }, [selectedOrganizationType?.id])

  useEffect(() => {
    setUser({ ...user, organization_id: undefined, user_data: {} })
  }, [user.organization_type_id])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!firstPageValid || !secondPageValid) return

    setIsLoading(true)

    requestRegister({
      ...user,
      lang: locale,
      created_organization_properties: userOrganization?.organization_properties,
      created_organization_name: userOrganization?.name,
    })
      .then(() => setCompleted(true))
      .catch((err) => setErrorMsg(getResponseErrorMessage(err, intl)))
      .finally(() => setIsLoading(false))
  }

  const renderStatusMessage = () => {
    if (isCompleted) {
      return (
        <div style={{ textAlign: 'center', color: 'var(--text-success)', marginTop: '20px' }}>
          {intl.formatMessage({ id: 'auth.signup.confirm_email' }, { email: user.username })}
        </div>
      )
    }

    if (errorMsg) {
      return <div style={{ textAlign: 'center', color: 'var(--danger-50)', marginTop: '20px' }}>{errorMsg}</div>
    }

    return null
  }

  return (
    <AuthWrapper
      title={intl.formatMessage({ id: 'auth.signup' })}
      showSignin={true}
      buttonHelp={intl.formatMessage({ id: 'auth.have_account' })}
    >
      {renderStatusMessage()}
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          {page === 0 && !isCompleted && (
            <UserInfoForm
              user={user}
              depsCatalogs={depsCatalogs}
              onChange={setUser}
              selectedOrganizationType={selectedOrganizationType}
              organizationTypesOptions={organizationTypesOptions}
              organizationsOptions={organizationsOptions}
              userOrganization={userOrganization}
              setUserOrganization={setUserOrganization}
              passwordValid={passwordValid}
              passwordHelp={help}
            />
          )}
          {page === 1 && !isCompleted && (
            <div style={{ marginTop: '10px' }}>
              <OrganizationInfoForm
                organizationType={selectedOrganizationType}
                user={user}
                onChange={setUser}
                depsCatalogs={depsCatalogs}
              />
            </div>
          )}
          {!isCompleted && (
            <RegisterControlButtons
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              showNext={firstPageValid}
              showSubmit={firstPageValid && secondPageValid}
            />
          )}
        </form>
      </div>
    </AuthWrapper>
  )
}
