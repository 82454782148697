import { HSL } from '../colorpicker.types'
import { colord } from 'colord'
import { IColorState } from '../ColorPicker'

interface IProps {
  colorState: IColorState
  onChange: (v: HSL, resource: IColorState['resource']) => void
}

/**
 * Компонент для выбора цвета из фиксированного списка цветов
 */
const ColorList = ({ colorState, onChange }: IProps) => {
  return (
    <div style={{ display: 'flex', cursor: 'pointer' }}>
      {SYSTEM_COLORS.map((clr) => (
        <div
          key={clr.h + clr.s + clr.l}
          className={isEqual(colorState.color, clr) ? 'colorpicker__list-selection' : ''}
          onClick={() => onChange(clr, 'list')}
        >
          <div className="colorpicker__list-item" style={{ background: colord(clr).toHslString() }} />
        </div>
      ))}
    </div>
  )
}

const isEqual = (clr1, clr2) => clr1.h === clr2.h && clr1.s === clr2.s && clr1.l === clr2.l

const SYSTEM_COLORS = [
  '#B4B7C6',
  '#9B9B9B',
  '#80EE80',
  '#00FF00',
  '#1b4ea3',
  '#0462AC',
  '#FFFF00',
  '#FF9857',
  '#f44336',
  '#FF0000',
  '#FFFFFF',
].map((color) => colord(color).toHsl())

export default ColorList
