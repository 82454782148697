import { actions } from './catalogs.reducer'
import {
  postCreateCatalog,
  postCreateInstance,
  patchUpdateCatalog,
  requestCatalogDelete,
  patchInstanceUpdate,
  requestInstanceDelete,
} from './catalogs.api'
import { AnyCatalogWithInstances } from './catalogs.types'
import { ICatalogItem } from 'au-nsi/catalogs'

export const createCatalog = (catalog: AnyCatalogWithInstances) => async (dispatch) => {
  const created: AnyCatalogWithInstances = await postCreateCatalog(catalog)

  created && dispatch(actions.catalogCreated(created))
}

export const updateCatalog = (catalog: AnyCatalogWithInstances) => async (dispatch) => {
  const updated: AnyCatalogWithInstances = await patchUpdateCatalog(catalog)

  updated && dispatch(actions.catalogUpdated(updated))
}

export const deleteCatalog = (catalog: AnyCatalogWithInstances) => async (dispatch) => {
  const deleted = await requestCatalogDelete(catalog)

  deleted && dispatch(actions.catalogDeleted(deleted))
}

export const createInstance = (instance: ICatalogItem) => async (dispatch) => {
  const created: ICatalogItem = await postCreateInstance(instance)

  created && dispatch(actions.instanceCreated(created))
}

export const updateInstance = (instance: ICatalogItem) => async (dispatch) => {
  const updated: ICatalogItem = await patchInstanceUpdate(instance)

  updated && dispatch(actions.instanceUpdated(updated))
}

export const deleteInstance = (instance: ICatalogItem) => async (dispatch) => {
  const deleted: ICatalogItem = await requestInstanceDelete(instance)

  deleted && dispatch(actions.instanceDeleted(deleted))
}

export const catalogsActions = {
  ...actions,
  createCatalog,
  updateCatalog,
  deleteCatalog,
  createInstance,
  updateInstance,
  deleteInstance,
}
