import { Equipment } from 'au-nsi/equipment'
import { IPlace } from 'au-nsi/shared'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilState } from 'recoil'
import usePersistentState from '../../../../hooks/usePersistentState'
import usePopup from '../../../../hooks/usePopup'
import { ReactComponent as FilterIcon } from '../../../../icons/filter.svg'
import { ReactComponent as MapIcon } from '../../../../icons/map.svg'
import { ReactComponent as DeviceIcon } from '../../../../icons/object.svg'
import PlaceAutocomplete from '../../../../shared/Inputs/PlacePicker/PlaceAutocomplete'
import SearchInput from '../../../../shared/Inputs/Search/SearchInput'
import SmoothPanel from '../../../../shared/Utils/SmoothPanel/SmoothPanel'
import { isJsonEqual } from '../../../../utils/misc'
import DeviceFilterControls from '../../components/DeviceFilterControls'
import { recoilMapSearchValueState } from '../../MapTopology'
import styles from '../../maptopology.module.css'

interface Props {
  selectedPlace: IPlace
  onPlaceSelect: (selectedPlace: IPlace) => void

  statusFilters: Record<Equipment['state'], boolean>
  onFilterByViewPortChange: (filter: boolean) => void
  onStatusFilterChange: (settings: Record<Equipment['state'], boolean>) => void
  filterByViewPort: boolean
}

/**
 Компонент поиска в карте устройтв

 Умеет:
 -Рендерить обворожительный блок, собственно, поиска и фильтров
 -Селектить места в мире с использованием автокомплита и сообщать о таком селекте
 -Отображать простой инпут поиска и сообщать об изменениях в нём
 -Тоглить панель фильтров отображаемых устройств
 */
const SearchBlock = ({
  selectedPlace,
  onPlaceSelect,
  statusFilters,
  onFilterByViewPortChange,
  filterByViewPort,
  onStatusFilterChange,
}: Props) => {
  const intl = useIntl()

  const statusControlsRef = useRef<HTMLDivElement>(null)
  const { open: statusControlsOpen, setOpen: setStatusControlsOpen } = usePopup(statusControlsRef)
  const [searchViaMap, setSearchViaMap] = usePersistentState<boolean>(false, 'map:search_via_map')
  const [searchValue, setSearchValue] = useRecoilState(recoilMapSearchValueState)

  useEffect(() => {
    searchViaMap && setSearchValue('')
  }, [searchViaMap])

  const searchPlaceholder = searchViaMap
    ? intl.formatMessage({ id: 'nsi.search_by_map' })
    : intl.formatMessage({ id: 'nsi.search_by_name_and_address' })

  const searchElement = searchViaMap ? (
    <PlaceAutocomplete
      value={selectedPlace?.name}
      onChange={(place) => !isJsonEqual(place, {}) && onPlaceSelect(place)}
      wrapperClassName={styles.autocompleteWrapper}
      placeholder={searchPlaceholder}
    />
  ) : (
    <SearchInput placeholder={searchPlaceholder} onChange={setSearchValue} value={searchValue} />
  )

  const swapModeIcon = searchViaMap ? (
    <DeviceIcon onClick={() => setSearchViaMap(false)} />
  ) : (
    <MapIcon onClick={() => setSearchViaMap(true)} />
  )

  const isFilterApplied = filterByViewPort || Object.values(statusFilters).some((v) => !v)
  const filterClass = isFilterApplied ? styles.activeFilterIcon : undefined
  const filterIcon = <FilterIcon className={filterClass} onClick={() => setStatusControlsOpen(!statusControlsOpen)} />

  const statusControls = (
    <DeviceFilterControls
      statusFilters={statusFilters}
      onChange={onStatusFilterChange}
      filterByViewPort={filterByViewPort}
      onFilterByViewPortChange={onFilterByViewPortChange}
    />
  )

  return (
    <div className={styles.deviceSearchBlock}>
      {searchElement}
      {swapModeIcon}
      <SmoothPanel
        ref={statusControlsRef}
        open={statusControlsOpen}
        icon={filterIcon}
        panelContent={statusControls}
        transformOrigin={'top right'}
      />
    </div>
  )
}

export default SearchBlock
