import { IGanttComponent } from 'au-nsi/dashboards'
import React from 'react'
import { connect } from 'react-redux'
import dashboardActions from '../../pages/Dashboards/dashboard.actions'
import Gantt from '../../pages/GanttTable/Gantt'
import { ReduxState } from '../../redux/store.types'
import ganttFactory from '../../services/gantt/gantt.factory'
import GanttService from '../../services/gantt/gantt.service'
import SearchInput from '../Inputs/Search/SearchInput'
import GanttRow from './GanttRow'

/**
 * Диаграмма Ганта отображаемая на страницах НЧК, Несимметрия и Пределы
 */
class GanttTable extends React.Component<Props> {
  private service: GanttService = ganttFactory()

  componentDidMount() {
    const action = dashboardActions.loadDashboardComponents(this.props.page)
    this.props.dispatch(action)
  }

  render() {
    const { component, page } = this.props

    const rows = this.props.registrators.map((registrator) => {
      const { id } = registrator
      const checked = registrator.id === this.props.selectedId
      const { onSelectRegistrator } = this.props

      const data$ = this.service.select(id)

      return (
        <GanttRow
          key={id}
          checked={checked}
          onSelectRegistrator={onSelectRegistrator}
          registrator={registrator}
          data$={data$}
        />
      )
    })

    return (
      <Gantt id={component?.id} page={page} settings={component?.settings} service={this.service} embedded={true}>
        <Gantt.Header>
          <Gantt.Title>
            <SearchInput onChange={this.props.onSearch} />
          </Gantt.Title>
          <Gantt.Timeline />
        </Gantt.Header>
        <Gantt.Body>{rows}</Gantt.Body>
      </Gantt>
    )
  }
}

interface OwnProps {
  page: string
  registrators: any[]
  onSelectRegistrator: (e: any) => void
  selectedId: string
  search: string
  onSearch: (s: string) => void
}

interface Props extends OwnProps {
  component: IGanttComponent
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  // поиск настроек диаграммы в элементах экрана страницы (экран системный и скрыт от пользователя в разделе конструктора)
  const component = state.dashboards.components[ownProps.page]?.find((c) => c.type === 'gantt')

  return { component }
}

export default connect(mapStateToProps)(GanttTable)
