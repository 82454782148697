import { IOrganizationCreateTask } from '../../../userTasks.types'
import { useIntl } from 'react-intl'

interface IProps {
  task: IOrganizationCreateTask
}

const CreateOrganizationTaskDescription = ({ task }: IProps) => {
  const intl = useIntl()

  return (
    <>
      {intl.formatMessage({ id: 'user_tasks.description.organization.create' })}
      {' — '}
      {task.state?.name}
    </>
  )
}

export default CreateOrganizationTaskDescription
