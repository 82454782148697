import { TColor } from './themes.colors'

class SystemColorsService implements IColorsService {
  private readonly colors: Record<TColor, string>

  constructor() {
    this.colors = {} as any
  }

  public getColors() {
    return this.colors
  }

  public setColors(colors: Record<TColor, string>) {
    for (const [key, value] of Object.entries(colors)) {
      document.documentElement.style.setProperty(key, value)
      this.colors[key] = value
    }
  }
}

interface IColorsService {
  getColors: () => Record<TColor, string>
  setColors: (colors: Record<TColor, string>) => void
}

const systemColorsService = new SystemColorsService()
export default systemColorsService
