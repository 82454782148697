import styles from '../maptopology.module.css'
import DeviceTile from './DeviceTile'
import { Equipment } from 'au-nsi/equipment'
import { memo, useEffect, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'

interface IProps {
  devices: Equipment[]
  selectedId: Equipment['id']
  searchPattern: string
  onDeviceSelect: (id: Equipment['id']) => void
  listHeight: number
}

/**
  Компонент списка устройств карты
  Умеет рисовать виртуализированный список устройств,
  сообщать о клике по одному из них, подсвечивать выбранный пользователем и проскроливать к тому, что пришёл в пропсах
*/
const DevicesList = ({ devices, searchPattern, onDeviceSelect, selectedId, listHeight }: IProps) => {
  const listRef = useRef(null)

  useEffect(() => {
    if (!selectedId) return

    const index = devices.findIndex((d) => d.id === selectedId)
    listRef.current.scrollToIndex({ index, align: 'top' })
  }, [selectedId])

  const renderDeviceTile = (index: number) => {
    return (
      <DeviceTile
        device={devices[index]}
        key={devices[index].id}
        onClick={onDeviceSelect}
        isSelected={devices[index].id === selectedId}
        searchPattern={searchPattern}
      />
    )
  }

  return (
    <div className={styles.deviceList}>
      <Virtuoso
        style={{ height: listHeight }}
        totalCount={devices.length}
        width={'100%'}
        ref={listRef}
        itemContent={renderDeviceTile}
      />
    </div>
  )
}

export default memo(DevicesList)
