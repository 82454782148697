import classnames from 'classnames'
import React from 'react'
import { injectIntl } from 'react-intl'
import { ReactComponent as Next } from '../buttons/next.svg'
import { ReactComponent as Pause } from '../buttons/pause.svg'
import { ReactComponent as Play } from '../buttons/play.svg'
import { ReactComponent as Prev } from '../buttons/prev.svg'
import { ReactComponent as SpeedDown } from '../buttons/speed-down.svg'
import { ReactComponent as SpeedUp } from '../buttons/speed-up.svg'
import styles from './replay.module.css'

export class ReplayControls extends React.Component<ReplayControlsProps> {
  shouldComponentUpdate(nextProps) {
    return nextProps.stopped !== this.props.stopped || nextProps.online !== this.props.online
  }

  render() {
    const { stopped, online } = this.props
    const middleBtnTitle = stopped ? 'continue' : 'pause'
    const middleBtnHandler = stopped ? 'onContinue' : 'onPause'
    const middleBtnIcon = stopped ? Play : Pause

    const buttons = [
      { icon: Prev, title: 'frame.prev', handler: 'onPrevFrame' },
      { icon: SpeedDown, title: 'speed.down', handler: 'onSpeedDown' },
      { icon: middleBtnIcon, title: middleBtnTitle, handler: middleBtnHandler },
      { icon: SpeedUp, title: 'speed.up', handler: 'onSpeedUp' },
      { icon: Next, title: 'frame.next', handler: 'onNextFrame' },
    ]

    const elements = buttons.map((button, i) => {
      const Icon = button.icon

      const className = classnames(styles.btn, {
        [styles.btnDisabled]: online,
        [styles.btnFirst]: i === 0,
      })

      return (
        <div
          key={button.title}
          className={className}
          title={this.props.intl.messages[`ChartControls.${button.title}`]}
          onClick={this.props[button.handler]}
        >
          <Icon width={34} height={34} />
        </div>
      )
    })

    return <React.Fragment>{elements}</React.Fragment>
  }
}

export interface ReplayControlsProps {
  stopped: boolean
  online: boolean
  onSpeedUp: () => void
  onSpeedDown: () => void
  onPrevFrame: () => void
  onNextFrame: () => void
  onPause: () => void
  onContinue: () => void
  intl: any
}

export default injectIntl(ReplayControls)
