import { InputOptions } from '../../../shared/Forms/forms.utils'
import { Equipment } from 'au-nsi/equipment'
import MappingForm from './MappingForm'

const fields: InputOptions[] = [
  { type: 'number', key: 'factor' },
  { type: 'number', key: 'offset' },
  { type: 'text', key: 'column' },
]

const defaultMapping = { column: '', factor: 1, offset: 0 }

const DBClientMapping = ({ device, allowEditing }: Props) => {
  return (
    <MappingForm
      device={device}
      allowEditing={allowEditing}
      fields={fields}
      defaultMapping={defaultMapping}
      columnsSize={[40, 20, 20, 20]}
    />
  )
}

interface Props {
  device: Equipment
  allowEditing: boolean
}

export default DBClientMapping
