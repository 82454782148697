import React from 'react'
import { IIncidentDetails } from '../incident.interfaces'

/**
 * Отображение деталей сигнальной ситуации - по каким устройствам и параметрамсработало условие
 */
const IncidentDetails = (props: Props) => {
  const details = props.details.map((row) => {
    // названия и значения параметров по которым была зарегистрирована СС
    const parameters = row.parameters.map((p) => {
      // мин, макс и среднее значения
      const stats = p.stats.map((s) => (
        <span key={s.name} className="alerts-details__item">
          <span className={'alerts-details__icon ' + s.name}></span> <em>{s.value}</em>
        </span>
      ))

      return (
        <div key={p.parameter_id}>
          <div>{p.parameter_name || p.parameter_id}</div>
          <div className="alerts-details__stats">{stats}</div>
        </div>
      )
    })

    return (
      <div className="alerts-details" key={row.device_id}>
        <div className="alerts-details__devices">{row.device_name || row.device_id}</div>
        <div className="alerts-details__parameters">{parameters}</div>
      </div>
    )
  })

  return <React.Fragment>{details}</React.Fragment>
}

interface Props {
  details: IIncidentDetails[]
}

export default IncidentDetails
