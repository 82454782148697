import { useEffect, useState } from 'react'
import { API_URL } from '../../../../shared/constants'

export const elevatorControlModuleId = 'ELEVATOR_CONTROL'
const jsId = elevatorControlModuleId + '_script'
const cssId = elevatorControlModuleId + '_script'

const useLPSettingsLoad = () => {
  const [error, setError] = useState<string>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (document.getElementById(jsId) && document.getElementById(cssId)) return setLoaded(true)

    const styles = document.createElement('link')
    styles.href = API_URL + '/nsi/v1/receiver/client/css'
    styles.rel = 'stylesheet'
    styles.id = cssId
    styles.onerror = (err) => setError(typeof err === 'string' ? err : 'Something went wrong')

    const script = document.createElement('script')
    script.id = jsId
    script.type = 'text/javascript'
    script.src = API_URL + '/nsi/v1/receiver/client/js'
    script.onload = () => setLoaded(true)
    script.onerror = (err) => setError(typeof err === 'string' ? err : 'Something went wrong')

    document.head.appendChild(styles)
    document.head.appendChild(script)
  }, [])

  return { loading: !loaded && !error, error }
}

export default useLPSettingsLoad
