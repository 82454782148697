import { IUserTask } from '../../userTasks.types'
import CreateOrganizationTaskForm from './CreateOrganizationTaskForm/CreateOrganizationTaskForm'
import CreateCatalogItemTaskForm from './CreateCatalogItemTaskForm/CreateCatalogItemTaskForm'
import UserTaskFormWrapper from './UserTaskFormWrapper/UserTaskFormWrapper'

interface IProps {
  task: IUserTask
}

const UserTaskForm = ({ task }: IProps) => {
  let Form

  switch (task.type) {
    case 'create_organization':
      Form = <CreateOrganizationTaskForm task={task} />
      break
    case 'create_catalog_item':
      Form = <CreateCatalogItemTaskForm task={task} />
      break
    default:
      return null
  }

  return <UserTaskFormWrapper task={task}>{Form}</UserTaskFormWrapper>
}

export default UserTaskForm
