import classnames from 'classnames'
import { useCallback } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import MultiDropdown from '../../shared/Inputs/MultiDropdown'
import { actions, ChartMode } from './pq.reducers'

const PqHeader = (props: Props) => {
  const { trends, mode } = props
  const pqIconClass = classnames('pq-trends-header__icon icon-pq is-left', { 'is-active': mode === 'pq' })
  const trendsIconClass = classnames('pq-trends-header__icon icon-trends', { 'is-active': mode === 'trends' })
  const gridIconClass = classnames('pq-trends-header__icon icon-grid', { 'is-active': props.showAsGrid })

  const handleModeClick = useCallback((e) => {
    props.dispatch(actions.setChartMode(e.target.dataset.mode))
  }, [])

  const handleTrendsChange = useCallback((selected: string[]) => {
    const updated = { ...trends }

    for (const key of Object.keys(updated)) updated[key] = false
    for (const key of selected) updated[key] = true

    props.dispatch(actions.setTrends(updated))
  }, [])

  const handleGridClick = useCallback(() => {
    props.dispatch(actions.toggleGrid())
  }, [])

  const trendsDropdownTitle = props.intl.formatMessage({ id: 'lfo.charts.title' })

  const trendsOptions = Object.keys(trends).map((key) => {
    return { title: props.intl.formatMessage({ id: 'pq.trends.' + key }), value: key }
  })

  const trendsValues = Object.keys(trends).filter((key) => trends[key])

  const trendToggles = (
    <MultiDropdown
      title={trendsDropdownTitle}
      options={trendsOptions}
      values={trendsValues}
      onChange={handleTrendsChange}
    />
  )

  const isTrends = props.mode === 'trends'

  return (
    <div className="pq-trends-header">
      <div className={pqIconClass} data-mode="pq" onClick={handleModeClick} />
      <div className={trendsIconClass} data-mode="trends" onClick={handleModeClick} />
      {isTrends && <div className={gridIconClass} onClick={handleGridClick} />}
      {isTrends && trendToggles}
    </div>
  )
}

interface Props {
  mode: ChartMode
  showAsGrid: boolean
  trends: { [id: string]: boolean }
  intl: IntlShape
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    mode: state.pq.chartMode,
    trends: state.pq.trends,
    showAsGrid: state.pq.showAsGrid,
  }
}

export default connect(mapStateToProps)(injectIntl(PqHeader))
