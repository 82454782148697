import { useRef, useEffect, useState } from 'react'
import usePopup from '../../../hooks/usePopup'

import './colorpicker.styles.css'
import { ColorSelector } from './ColorSelector/ColorSelector'
import { colord } from 'colord'
import { HSL } from './colorpicker.types'
import SmoothPanel from '../../Utils/SmoothPanel/SmoothPanel'

interface IProps {
  color: string
  onChange: (v: string) => void
  position?: 'left' | 'right'
  disabled?: boolean
}

const black = colord('#000').toHsl()

// Для избежания издержек при касте цветов fromHsl и toHsl
export interface IColorState {
  color: HSL
  resource: 'list' | 'palette' | 'formatPicker'
}

const ColorPicker = ({ color, onChange, position = 'right', disabled }: IProps) => {
  const ref = useRef<HTMLDivElement>()
  const mounted = useRef<boolean>()

  const { open, setOpen } = usePopup(ref)
  const [colorState, setColorState] = useState<IColorState>({
    color: color ? colord(color).toHsl() : black,
    resource: null,
  })

  const style = colorState ? { background: colord(colorState.color).toHslString() } : { border: '1px solid #ccc' }

  const handleChange = (hsl: HSL, resource: IColorState['resource']) => {
    setColorState({ color: hsl, resource })
  }

  // Вызов onChange при закрытии.
  // mounted необходим, чтобы отличить закрытие от вызова после первоначального рендера
  useEffect(() => {
    if (open) return

    if (mounted.current) onChange(colord(colorState.color).toHex())
    else mounted.current = true
  }, [open])

  useEffect(() => {
    color && setColorState((prev) => ({ ...prev, color: colord(color).toHsl() }))
  }, [color])

  const icon = (
    <div ref={ref} className={disabled ? 'colorpicker is-disabled' : 'colorpicker'}>
      <div onClick={() => setOpen(!open)} className={'colorpicker__color_preview'}>
        <div className="colorpicker__toggle" style={style} />
      </div>
    </div>
  )

  const picker = !disabled && (
    <div className={`colorpicker__dropdown is-${position}`}>
      <ColorSelector colorState={colorState} onChange={handleChange} />
    </div>
  )

  return (
    <SmoothPanel
      ref={ref}
      open={open}
      icon={icon}
      panelContent={picker}
      transformOrigin={position === 'left' ? 'top right' : 'top left'}
    />
  )
}

export default ColorPicker
