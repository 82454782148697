import { Select } from '@alterouniversal/au-react-components'
import { IWindroseParameter } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import Datepicker from '../../../shared/Inputs/Datepicker/Datepicker'
import Dropdown from '../../../shared/Inputs/Dropdown'
import DurationInput from '../../../shared/Inputs/DurationInput'
import InputRow from '../../../shared/Inputs/InputRow'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import css from './windrose.module.css'

/**
 * Часть модальной формы с настройками линии выводимой на розу ветров
 * (в режиме сравнения может выводиться несколько линий)
 */
const WindroseParameter = (props: Props) => {
  const intl = useIntl()
  const { index, row } = props

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  const handleTimeSelect = (value: boolean) => {
    const time = value ? Date.now() : undefined
    props.onChange('time', time, index)
  }

  const handleTimeChange = (time: number) => {
    props.onChange('time', time, index)
  }

  const equipmentOptions = useSelector(selectDataSourceOptions)
  const parameters = useSelector((state: ReduxState) => state.parameters.items)

  const directionOptions = React.useMemo(() => {
    return parameters.filter((p) => p.unit === 'rad' || p.unit === 'deg').map((p) => ({ value: p.id, label: p.name }))
  }, [parameters])

  const speedOptions = React.useMemo(() => {
    return parameters.filter((p) => p.unit === 'm/s').map((p) => ({ value: p.id, label: p.name }))
  }, [parameters])

  const timeOptions = [
    { value: false, title: intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.time.current' }) },
    { value: true, title: intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.time.select' }) },
  ]

  return (
    <div className={css.settingsRow}>
      <InputRow compact={true} label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.device_id' })}>
        <Select name="device_id" options={equipmentOptions} value={row.device_id} onChange={handleChange} />
      </InputRow>

      <InputRow
        compact={true}
        label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.direction_parameter_id' })}
      >
        <Select
          name="direction_parameter_id"
          options={directionOptions}
          value={row.direction_parameter_id}
          onChange={handleChange}
        />
      </InputRow>

      {props.detailed && (
        <InputRow
          compact={true}
          label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.speed_parameter_id' })}
        >
          <Select
            name="speed_parameter_id"
            options={speedOptions}
            value={row.speed_parameter_id}
            onChange={handleChange}
          />
        </InputRow>
      )}

      <InputRow compact={true} label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.timespan' })}>
        <DurationInput name="timespan" value={row.timespan} onChange={handleChange} />
      </InputRow>

      <InputRow compact={true} label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.time' })}>
        <Dropdown value={!!row.time} options={timeOptions} onChange={handleTimeSelect} />
      </InputRow>

      {row.time ? (
        <InputRow compact={true} label={intl.formatMessage({ id: 'dashboards.windrose.settings.parameters.time' })}>
          <Datepicker time={row.time} onTimeChange={handleTimeChange} inputClass="nsi-input" />
        </InputRow>
      ) : (
        <div />
      )}

      {index > 0 && (
        <div className={css.settingsRemove}>
          <RemoveButton id="" onRemove={() => props.onRemove(index)} />
        </div>
      )}
    </div>
  )
}

interface Props {
  index: number
  row: IWindroseParameter
  onChange: (key: string, value: any, index: number) => void
  onRemove: (index: number) => void
  detailed: boolean
}

export default React.memo(WindroseParameter)
