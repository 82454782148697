/**
 * Функции для работы с порядком сортировки элементов в топологии
 */
import http, { handleHttpError } from '../../../utils/http'
import { NsiState } from '../nsi.interfaces'
import { Equipment, Region } from 'au-nsi/equipment'

// задержка синхронизации локальных изменений с сервером, добавлена для повышения отзывчивости интерфейса
// т.к. при перемещении элементов при помощи горячих клавиш возможно большое количество изменений, то они
// сначала применяются локально и только через указанное время батчем отправляются на сервер
const SYNC_DELAY = 1000

// изменения, которые были применены локально, но еще не были отправлены на сервер
let equipmentUpdates: ItemWithOrdering[] = []
let regionsUpdates: ItemWithOrdering[] = []

let equipmentTimer = null
let regionsTimer = null

// добавить новые изменения к уже имеющемуся массиву
const mergeUpdates = (prev: ItemWithOrdering[], additional: ItemWithOrdering[]) => {
  for (const update of additional) {
    const i = prev.findIndex((e) => e.id === update.id)

    if (i !== -1) prev[i] = update
    else prev.push(update)
  }
}

// отправка локально накопленных изменений на сервер и обнуление локального буфера
const syncEquipmentUpdates = () => {
  http.post('/nsi/v1/equipment/order', { equipment: equipmentUpdates }).catch(handleHttpError)
  equipmentUpdates = []
}

const syncRegionsUpdates = () => {
  http.post('/nsi/v1/regions/reorder', { regions: regionsUpdates }).catch(handleHttpError)
  regionsUpdates = []
}

// переместить устройство
export const reorderEquipment = (state: NsiState, direction: 1 | -1): ItemWithOrdering[] => {
  const updates = swapEquipment(state.selectedDeviceId, state.equipmentAll, direction)

  if (updates) {
    mergeUpdates(equipmentUpdates, updates)
    clearTimeout(equipmentTimer)
    equipmentTimer = setTimeout(syncEquipmentUpdates, SYNC_DELAY)
  }

  return updates
}

export const reorderRegions = (state: NsiState, direction: 1 | -1): ItemWithOrdering[] => {
  const updates = swapRegions(state.selectedRegionId, state.regions, direction)

  if (updates) {
    mergeUpdates(regionsUpdates, updates)
    clearTimeout(regionsTimer)
    regionsTimer = setTimeout(syncRegionsUpdates, SYNC_DELAY)
  }

  return updates
}

// поменять местами устройство со следующим/предыдущим
const swapEquipment = (id: string, equipment: Equipment[], direction: 1 | -1) => {
  const device = equipment.find((e) => e.id === id)
  // устройства находящиеся в том же регионе
  const siblings = equipment.filter((e) => e.region_id === device.region_id)

  return swap(device, siblings, direction)
}

// поменять местами регион со следующим/предыдущим
const swapRegions = (id: number, regions: Region[], direction: 1 | -1) => {
  const region = regions.find((r) => r.id === id)
  // путь родительского региона
  const parentPath = region.path.slice(0, region.path.lastIndexOf('.'))

  // находим все регионы находящиеся на одном уровне с тем же родителем
  const siblings = regions.filter((r) => parentPath === r.path.slice(0, r.path.lastIndexOf('.')))

  return swap(region, siblings, direction)
}

// рассчитать изменения необходимые для того чтобы поменять местами element с его соседом в направлении direction
const swap = (element: ItemWithOrdering, siblings: ItemWithOrdering[], direction: 1 | -1): ItemWithOrdering[] => {
  const i = siblings.findIndex((e) => e.id === element.id)

  // крайние случаи - перемещение вверх элемента который и так первый, либо перемещение вниз последнего
  if ((i === 0 && direction === -1) || (i === siblings.length - 1 && direction === 1)) {
    return null
  }

  // меняем user_ordering_index между выбранным элементом и его соседом
  const replacement = siblings[i + direction]
  return [
    { id: element.id, user_ordering_index: replacement.user_ordering_index },
    { id: replacement.id, user_ordering_index: element.user_ordering_index },
  ]
}

interface ItemWithOrdering {
  id: any
  user_ordering_index: number
}
