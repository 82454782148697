import classnames from 'classnames'
import React from 'react'
import css from './tooltip.module.css'

const modifiers = {
  left: css.left,
  right: css.right,
  top: css.top,
  bottom: css.bottom,
}

/**
 * Компонент для вывода подсказок появляющихся при наведении на значок вопроса
 */
const HelpTooltip = (props: Props) => {
  const className = classnames(css.help, modifiers[props.position], { [css.open]: props.open })
  const size = props.size || 18

  return (
    <div className={css.container} style={props.style}>
      {props.content ? props.content : <div className={css.symbol} style={{ width: size, height: size }} />}
      <div className={className}>{props.children}</div>
    </div>
  )
}

interface Props {
  /* содержимое подсказки */
  children: React.ReactNode
  style?: React.CSSProperties
  open?: boolean
  size?: number
  position: 'left' | 'right' | 'top' | 'bottom'
  /* видимый значок подсказки - по умолчанию знак вопроса */
  content?: React.ReactNode
}

export default HelpTooltip
