import { configureStore } from '@reduxjs/toolkit'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { Store } from 'redux'
import languageProviderReducer from '../pages/App/lp.reducers'
import userReducer from '../pages/App/user.reducers'
import authReducers from '../pages/Auth/auth.reducers'
import { catalogsReducer } from '../pages/Catalogs/catalogs.reducer'
import { organizationsReducer } from '../pages/Catalogs/Organizations/organizations.reducer'
import rolesReducer from '../pages/Collections/Roles/roles.reducers'
import usersReducer from '../pages/Collections/Users/users.reducers'
import { commandsReducer } from '../pages/Commands/commands.state'
import dashboardReducer from '../pages/Dashboards/dashboard.reducers'
import formulasReducer from '../pages/Formulas/formulas.reducers'
import incidentsReducer from '../pages/Incidents/incident.reducers'
import lfoReducer from '../pages/LFO/lfo.reducers'
import audioReducer from '../pages/Libraries/Audio/audio.reducers'
import documentsReducer from '../pages/Libraries/Documents/docs.reducers'
import imagesReducer from '../pages/Libraries/Images/image.reducers'
import groupsReducer from '../pages/MapTopology/Groups/groups.reducer'
import dataRatesReducer from '../pages/Nsi/data_rates.reducers'
import nsiReducer from '../pages/Nsi/nsi.reducers'
import tagsReducer from '../pages/Nsi/Tags/tags.state'
import oplimReducer from '../pages/Oplim/oplim.reducers'
import paramsGroupsReducer from '../pages/Parameters/groups.reducers'
import paramsReducer from '../pages/Parameters/params.reducers'
import pqReducers from '../pages/PqDiagram/pq.reducers'
import signalEventsReducer from '../pages/SignalEvents/se.reducers'
import { modulesReducer } from '../pages/System/Modules/modules.state'
import sirviceSettingsReducer from '../pages/System/Settings/settings.reducers'
import transmittersReducers from '../pages/Transmitters/transmitters.reducers'
import { moderationReducer } from '../pages/User/Moderation/moderation.reducer'
import userTasksReducer from '../pages/UserTasks/userTasks.reducer'
import vectorReducer from '../pages/VectorDiagram/vector.reducers'
import { persist } from './redux.utils'
import { ReduxState } from './store.types'

const reducer = {
  audio: audioReducer,
  auth: authReducers,
  catalogs: catalogsReducer,
  commands: commandsReducer,
  organizations: organizationsReducer,
  dashboards: dashboardReducer,
  data_rates: dataRatesReducer,
  documents: documentsReducer,
  formulas: formulasReducer,
  images: imagesReducer,
  incidents: incidentsReducer,
  language: languageProviderReducer,
  lfo: lfoReducer, // TODO: remove from store
  modules: modulesReducer,
  nsi: nsiReducer,
  oplim: oplimReducer, // TODO: remove from store
  parameter_groups: paramsGroupsReducer,
  parameters: paramsReducer,
  pq: pqReducers, // TODO: remove from store
  roles: rolesReducer,
  service_settings: sirviceSettingsReducer,
  signal_events: signalEventsReducer,
  tags: tagsReducer,
  transmitters: transmittersReducers,
  user: userReducer,
  users: usersReducer,
  vector: vectorReducer, // TODO: remove from store
  moderation_requests: moderationReducer,
  userTasks: userTasksReducer,
  groups: groupsReducer,
}

const getMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware<ReduxState>) => {
  return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(persist)
}

export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector

export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getMiddleware(getDefaultMiddleware),
}) as Store<ReduxState>
