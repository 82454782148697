import Tabs from '../../../../../../shared/Pagination/Tabs'
import EquipmentRequestFormLayout from './EquipmentRequestFormLayout'
import { extractStringIdFromUrl } from '../../../moderation.utils'
import { formatAfterEquipment, formatPrevEquipment } from './equipmentRequest.utils'
import EntityRemoved from '../../components/EntityRemoved'
import { useAppSelector } from '../../../../../../redux/store'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import useInstanceFormCallback from '../../../../../Catalogs/components/InstanceForm/useInstanceFormCallback'
import { EquipmentRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const EquipmentRequestForm = ({ editing }: IProps) => {
  const [request, setRequest] = useRecoilState<EquipmentRequest>(selectedDraftRequestState as any)
  const onPassportValuesChange = useInstanceFormCallback({
    setter: setRequest,
    propertiesKey: 'request_body.passport_values',
    deps: [],
  })

  let prevId = request.request_body?.id
  if (!prevId) {
    prevId = request.url.includes('configuration') ? extractId(request.url) : extractStringIdFromUrl(request.url)
  }

  const isClosed = request.rejected || request.moderated_at
  const currentEquipment = useAppSelector((state) => state.nsi.equipmentAll.find((e) => e.id === prevId))

  const empty = <EntityRemoved />

  const prev = isClosed ? request.state_before : currentEquipment

  const renderPrev = () =>
    prev ? (
      <EquipmentRequestFormLayout
        prevDevice={request.request_body}
        equipment={formatPrevEquipment(
          request.state_before ?? currentEquipment,
          request.request_body,
          currentEquipment?.protocol
        )}
        onChange={undefined}
        onPassportValuesChange={undefined}
        editing={false}
      />
    ) : (
      empty
    )

  const renderUpdated = () =>
    request.request_body ? (
      <EquipmentRequestFormLayout
        prevDevice={prev}
        equipment={formatAfterEquipment(prev, request.request_body, currentEquipment?.protocol)}
        onPassportValuesChange={onPassportValuesChange}
        onChange={(updates) => {
          setRequest((prev) => ({ ...prev, request_body: { ...prev.request_body, ...updates } as any }))
        }}
        editing={editing}
      />
    ) : (
      empty
    )

  if (request.method === 'POST') return renderUpdated()
  if (request.method === 'DELETE') return renderPrev()

  const before = { id: 'user.request.before', render: renderPrev }
  const after = { id: 'user.request.after', render: renderUpdated }

  return <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />
}

/**
 * Для запросов вида /:id/configuration
 */
const extractId = (url: string) => {
  const split = url.split('/')
  return split[split.length - 2]
}

export default EquipmentRequestForm
