export const insert = (str: string, index: number, value: string) => {
  return str.substr(0, index) + value + str.substr(index)
}

export const replaceAt = (str: string, index: number, replacement: string) => {
  return str.slice(0, index) + replacement + str.slice(index + 1)
}

export const confirmValue = (str: string, mask: (string | RegExp)[], emptySymbol: string) => {
  if (mask.length !== str.length) return false
  for (let i = 0; i < mask.length; ++i) {
    const exp = mask[i]
    if (exp instanceof RegExp) {
      if (!exp.test(str[i]) && str[i] !== emptySymbol) return false
    } else {
      if (exp !== str[i]) return false
    }
  }

  return true
}
