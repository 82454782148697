import { getSchemaMap, isSchemasEqual } from '../CatalogRequestForm/catalogRequest.diff'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { OrganizationType } from 'au-nsi/organizations'

function findOrganizationTypeDiff(o1: OrganizationType, o2: OrganizationType): [OrganizationType, OrganizationType] {
  let o1Diff = { ...o1 },
    o2Diff = { ...o2 }

  for (const schemaKey of ['organization_schema', 'registration_schema']) {
    const c1SchemaMap = getSchemaMap(o1[schemaKey])
    const c2SchemaMap = getSchemaMap(o2[schemaKey])
    const keys = new Set([...c1SchemaMap.keys(), ...c2SchemaMap.keys()])

    const c1Schema: IPropertyDescription[] = []
    const c2Schema: IPropertyDescription[] = []

    for (const key of keys) {
      if (!isSchemasEqual(c1SchemaMap.get(key), c2SchemaMap.get(key))) {
        if (c1SchemaMap.has(key)) c1Schema.push(c1SchemaMap.get(key))
        if (c2SchemaMap.has(key)) c2Schema.push(c2SchemaMap.get(key))
      }
    }

    o1Diff = { ...o1Diff, [schemaKey]: c1Schema }
    o2Diff = { ...o2Diff, [schemaKey]: c2Schema }
  }

  return [o1Diff, o2Diff]
}

export default findOrganizationTypeDiff
