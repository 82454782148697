import { ITableColumnSettings, ITableSettings } from 'au-nsi/dashboards'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { useIntl } from 'react-intl'
import DataService from '../../../services/data/data.service'
import { IDataSource } from '../../Nsi/nsi.interfaces'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { convert } from '../../Parameters/params.utils'
import { createFormatter } from '../common/format.utils'

const TableContent = (props: Props) => {
  const intl = useIntl()
  const [state, setState] = React.useState<State>({})

  const formatters = React.useMemo(() => {
    return props.settings.columns.map((c) => createFormatter(c.number_format, intl.locale))
  }, [props.settings.columns])

  // на каждый кадр плеера проверяем когда было последнее обновление
  // и обновляем данные если прошла секунда
  React.useEffect(() => {
    const onTick = () => {
      if (!props.service.shouldRender()) return

      const data: State = {}

      for (const id of props.settings.equipment) {
        const point = props.service.selectCurrentPoint(id)

        if (point != null) {
          data[id] = point
        }
      }

      setState(data)
    }

    props.service.onTick = onTick
    onTick()
  }, [props.settings])

  function getValueByColumn(id: string, col: ITableColumnSettings, index: number): string {
    const parameter = props.parameters.get(col.parameter_id)
    const toUnit = props.units[col.display_unit]
    const toPrefix = props.prefixes[col.display_prefix]

    let value = state[id]?.[col.parameter_id]
    let displayValue = ''

    if (parameter && value != null) {
      value = convert(value, { fromUnit: parameter.unit, fromPrefix: parameter.prefix, toUnit, toPrefix })
      const formatter = formatters[index]
      displayValue = formatter.format(value)
    }
    return displayValue
  }

  // данные по одному устройству распологаются в строку, по параметру - в столбец
  const renderColumnMode = () => {
    const rows = props.equipment.map(({ id, shortname }) => {
      const cells = props.settings.columns.map((col, i) => (
        <td key={col.column_index}>{getValueByColumn(id, col, i)}</td>
      ))

      return (
        <tr key={id}>
          <td>{intl.formatMessage({ id: 'dashboards.table.value' }, { name: shortname })}</td>
          {cells}
        </tr>
      )
    })

    const header = (
      <tr key="header" className="is-header">
        <td>{intl.formatMessage({ id: 'dashboards.table.settings.device' })}</td>
        {props.settings.columns.map((c, i) => (
          <td key={i}>{c.title}</td>
        ))}
      </tr>
    )

    rows.unshift(header)

    return rows
  }

  // данные по одному устройству распологаются в столбец, по параметру - в строку
  const renderRowMode = () => {
    const rows = props.settings.columns.map((col, i) => {
      const cells = props.equipment.map(({ id }) => <td key={id}>{getValueByColumn(id, col, i)}</td>)

      return (
        <tr key={col.column_index}>
          <td>{col.title}</td>
          {cells}
        </tr>
      )
    })

    const header = (
      <tr key="header" className="is-header">
        <td>{intl.formatMessage({ id: 'dashboards.table.settings.parameter' })}</td>
        {props.equipment.map((e, i) => (
          <td key={i}>{intl.formatMessage({ id: 'dashboards.table.value' }, { name: e.shortname })}</td>
        ))}
      </tr>
    )

    rows.unshift(header)

    return rows
  }

  return <React.Fragment>{props.settings.display === 'row' ? renderRowMode() : renderColumnMode()}</React.Fragment>
}

interface Props {
  equipment: IDataSource[]
  parameters: Map<string, ParameterDn>
  prefixes: { [id: string]: SiPrefix }
  service: DataService
  settings: ITableSettings
  units: { [id: string]: SiUnit }
}

type State = Record<string, Record<string, number>>

export default TableContent
