import { SelectOption } from '@alterouniversal/au-react-components'
import { ITextSettingsCommon, ITextVariable } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { useIntl } from 'react-intl'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextareaInput from '../../../shared/Inputs/TextareaInput'
import AlignmentSettings from './AlignmentSettings'
import * as utils from './text.utils'
import VarsSettingsRow from './VarsSettingsRow'
import VarsStyleSettings from './VarsStyleSettings'

/**
 * Форма с общими настройками текста (само сообщение, переменные и стили)
 */
const TextSettingsForm = (props: Props) => {
  const intl = useIntl()
  const { settings } = props

  // Кэш настроек переменных на случай если пользователь удалит переменную.
  // В таком случае она удалится из стейта, но ее настройки нужно сохранить
  // и восстановить если пользователь вернет переменную обратно.
  const variableSettings = React.useRef(new Map<string, ITextVariable>())

  const fontStyleOptions = [
    { value: 'normal', title: intl.formatMessage({ id: 'dashboards.text.style.normal' }) },
    { value: 'bold', title: intl.formatMessage({ id: 'dashboards.text.style.bold' }) },
    { value: 'italic', title: intl.formatMessage({ id: 'dashboards.text.style.italic' }) },
  ]

  const handleChange = (value: any, key: string) => {
    props.onChange(utils.syncVariableStyles({ ...settings, [key]: value }))
  }

  const handleTextColorChange = (text_color: string) => {
    props.onChange(utils.syncVariableStyles({ ...settings, text_color }))
  }

  const handleRowChange = (variable) => {
    const variables = settings.variables.map((v) => (v.name === variable.name ? variable : v))
    props.onChange({ variables })
  }

  const handleTextChange = (text) => {
    // перед изменением списка перменных, сохраняем текущие настройки
    const cache = variableSettings.current
    settings.variables.forEach((v) => cache.set(v.name, v))

    const variables = utils.extractPlaceholders(text, settings.variables, cache)
    const updates = variables ? { text, variables } : { text }
    props.onChange(updates)
  }

  const variablesRows = settings.variables.map((v) => {
    return (
      <VarsSettingsRow
        equipmentOptions={props.equipmentOptions}
        key={v.name}
        onChange={handleRowChange}
        parameterOptions={props.parameterOptions}
        parameters={props.parameters}
        prefixes={props.prefixes}
        settings={v}
        units={props.units}
      />
    )
  })

  // таблица с настройками динамических переменных, появляется только если в тексте есть такие переменные
  const variablesTable = (
    <table className="nsi-settings-table" style={{ marginBottom: '1.5rem' }}>
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'dashboards.text.settings.variables.name' })}</th>
          <th>{intl.formatMessage({ id: 'dashboards.text.settings.variables.device_id' })}</th>
          <th>{intl.formatMessage({ id: 'dashboards.text.settings.variables.parameter_id' })}</th>
        </tr>
      </thead>
      <tbody>{variablesRows}</tbody>
    </table>
  )

  // если переменные не указаны, то выводим подсказку как их можно использовать
  const hint = <div className="dashboard-text__hint">{intl.formatMessage({ id: 'dashboards.text.settings.hint' })}</div>

  return (
    <div style={{ marginTop: '10px' }}>
      <div className="system__label">{intl.formatMessage({ id: 'dashboards.text.settings.text' })}</div>
      <TextareaInput value={settings.text} onChange={handleTextChange} />

      {settings.variables.length === 0 && hint}
      {settings.variables.length !== 0 && variablesTable}

      <div className="system__grid" style={{ gridTemplateColumns: '2fr 2fr 1fr 1fr' }}>
        <InputRow label={intl.formatMessage({ id: 'dashboards.text.settings.text_size' })}>
          <NumberInput name="text_size" value={settings.text_size} onChange={handleChange} {...utils.fontSizeProps} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'dashboards.text.settings.text_style' })}>
          <Dropdown name="text_style" value={settings.text_style} options={fontStyleOptions} onChange={handleChange} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'dashboards.text.settings.text_color' })}>
          <ColorPicker color={settings.text_color} onChange={handleTextColorChange} {...utils.colorPickerProps} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'dashboards.text.settings.align' })}>
          <AlignmentSettings name="align" value={settings.align || 'center space-around'} onChange={handleChange} />
        </InputRow>

        <VarsStyleSettings
          settings={settings}
          onChange={handleChange}
          intl={intl}
          fontStyleOptions={fontStyleOptions}
        />
      </div>
    </div>
  )
}

interface Props {
  settings: ITextSettingsCommon
  equipmentOptions: SelectOption[]
  parameterOptions: SelectOption[]
  parameters: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  units: { [id: string]: SiUnit }
  onChange: (settings: Partial<ITextSettingsCommon>) => void
}

export default TextSettingsForm
