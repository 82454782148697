import { IRoute } from '../TopMenu/Nav/nav.utils'
import ImagesPage from './Images/ImagesPage'
import AudioPage from './Audio/AudioPage'
import React from 'react'

const DocumentsPage = React.lazy(() => import('./Documents/DocumentsPage'))

export const librariesRoutes: IRoute[] = [
  {
    id: 'system.documents',
    path: 'documents',
    component: DocumentsPage,
    rights: ['documents:get', 'documents:update'],
  },
  {
    id: 'system.images',
    path: 'images',
    component: ImagesPage,
    rights: ['images:get', 'images:update'],
  },
  {
    id: 'system.audio',
    path: 'audio',
    component: AudioPage,
    rights: ['audio:get', 'audio:update'],
    modules: ['au-signal-events'], // на данный момент аудио используется только для сигнальных ситуаций
  },
]
