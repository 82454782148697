import { Equipment } from 'au-nsi/equipment'
import EquipmentPassportPanel from '../../../Nsi/EquipmentForm/EquipmentPassport/EquipmentPassportPanel'

interface IProps {
  device: Equipment
}

const DeviceScreenPassport = ({ device }: IProps) => {
  return (
    <div className="nsi-main__container">
      <EquipmentPassportPanel
        device={device}
        allowTemplateImport={true}
        formButtonsStyle={{ background: 'var(--bg-default)' }}
      />
    </div>
  )
}

export default DeviceScreenPassport
