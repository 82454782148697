import styles from './userTaskFormWrapper.module.css'
import { memo } from 'react'

interface IProps {
  onResize: (width: number, height: number) => void
  parentSelector: string
}

const ResizeBorder = ({ onResize, parentSelector }: IProps) => {
  const onBorderMouseDown = () => {
    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', onBorderMouseUp)
  }

  const onBorderMouseUp = () => {
    window.removeEventListener('mousemove', handleMove)
    window.removeEventListener('mouseup', onBorderMouseUp)
  }

  const handleMove = (bordersMouseEv: MouseEvent) => {
    if (!bordersMouseEv) return

    const parent = document.querySelector(parentSelector)
    const bbox = parent.getBoundingClientRect()

    if (bbox.left > bordersMouseEv.x) return

    const isLeftMove = bordersMouseEv.x < bbox.right - bbox.width / 2

    const dWidth = Math.ceil(isLeftMove ? bbox.left - bordersMouseEv.x : bordersMouseEv.x - bbox.right)
    const width = bbox.width + dWidth / 2

    const dHeight = bordersMouseEv.y - bbox.bottom

    onResize(width, bbox.height + dHeight / 2)
  }

  return <div className={styles.taskFormBorderBottomRight} onMouseDown={onBorderMouseDown} />
}

export default memo(ResizeBorder)
