import { createContext, PropsWithChildren, useLayoutEffect } from 'react'
import { useAppSelector } from '../../../redux/store'
import { TTheme } from '../../User/Profile/ColorTheme'
import systemColorsService from './systemColorsService'
import themes from './themes.colors'
import { selectTheme } from '../app.selectors'
import usePrefersTheme from './usePrefersTheme'

export const ThemeContext = createContext<TTheme>('dark-blue')

const ThemeProvider = (props: PropsWithChildren<{}>) => {
  const selectedTheme = useAppSelector(selectTheme) || localStorage.getItem('theme')
  const systemTheme = usePrefersTheme()

  const theme = selectedTheme === 'system' ? systemTheme : selectedTheme

  useLayoutEffect(() => {
    systemColorsService.setColors(themes[theme])
    localStorage.setItem('theme', theme)
  }, [theme])

  return <ThemeContext.Provider value={theme as TTheme}>{props.children}</ThemeContext.Provider>
}

export default ThemeProvider
