import CatalogTree from '../components/CatalogTree/CatalogTree'
import '../intl/catalogs.intl'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import SchemaFormRoute from '../components/SchemaForm/SchemaFormRoute'
import InstanceFormRoute from '../components/InstanceForm/InstanceFormRoute'
import SchemaFormCreate from '../components/SchemaForm/SchemaFormCreate'
import InstanceFormCreate from '../components/InstanceForm/InstanceFormCreate'
import { selectCatalogsAccessRights, selectSchemas } from '../catalogs.selectors'

const Schemas = () => {
  const schemas = useSelector(selectSchemas)
  const rights = useSelector(selectCatalogsAccessRights)

  return (
    <>
      <CatalogTree
        title={'nav.schemas'}
        items={schemas}
        allowSchemasEditing={rights.allowCatalogsEdit}
        allowInstancesEditing={rights.allowInstancesEditing}
        instanceCreatePathGenerator={(id) => `/catalogs/schemas/${id}/create`}
        itemCreatePath={'/catalogs/schemas/create'}
        routePrefix={'/catalogs/schemas/'}
      />

      <div className={'nsi-main__wrapper'}>
        <div className={'nsi-main__container'}>
          <Routes>
            <Route path={'create'} element={<SchemaFormCreate />} />
            <Route path={':id'} element={<SchemaFormRoute />} />
            <Route path={':id/create'} element={<InstanceFormCreate />} />
            <Route path={':id/instance/:instanceId'} element={<InstanceFormRoute />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default Schemas
