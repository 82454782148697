import { IAccessSettings } from 'au-nsi/shared'
import { CurrentUserState } from 'pages/App/user.reducers'

// У сохраненных шаблонов упрощенные настройки доступа - доступ может быть у всех, либо только
// у одного пользователя, роли, или организации. Но хранятся они в общем формате IAccessSettings,
// поэтому необходима конвертация из упрощенного представления и обратно
type Visibility = 'user' | 'role' | 'org' | 'all'

export const visibility2access = (v: Visibility, user: CurrentUserState): IAccessSettings => {
  switch (v) {
    case 'user':
      return { restricted: true, users: [user.id], roles: [], orgs: [] }
    case 'role':
      return { restricted: true, users: [], roles: [user.role_id], orgs: [] }
    case 'org':
      return { restricted: true, users: [], roles: [], orgs: [user.organization_id] }
    default:
      return { restricted: false, users: [], roles: [], orgs: [] }
  }
}

export const access2visibility = (access: IAccessSettings): Visibility => {
  if (!access.restricted) return 'all'
  else if (access.users.length) return 'user'
  else if (access.roles.length) return 'role'
  else return 'org'
}
