import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useDeviceWidth from '../../../hooks/useDeviceWidth'
import TableHeader from '../../../shared/Utils/TableHeader'
import http, { handleHttpError } from '../../../utils/http'
import RemoveButton from '../../../shared/Forms/RemoveButton'

interface Session {
  sid: string
  user_name: string
  user_agent: string
  ip: string
}

const Sessions = (props: Props) => {
  const intl = useIntl()
  const [sessions, setSessions] = useState<Session[]>([])
  const { isDesktop } = useDeviceWidth()

  useEffect(() => {
    http
      .get('/nsi/journal/sessions')
      .then((r) => setSessions(r.data))
      .catch(handleHttpError)
  }, [])

  const deleteSession = (e) => {
    const { id: sid } = e.target.dataset

    http
      .post('/nsi/journal/sessions/remove', { sid })
      .then(() => {
        setSessions(sessions.filter((s) => s.sid !== sid))
      })
      .catch(handleHttpError)
  }

  const rows = sessions.map((s) => {
    const button = <RemoveButton id={s.sid} onRemove={deleteSession} />

    return (
      <tr key={s.sid}>
        <td>{s.user_name}</td>
        <td>{s.user_agent}</td>
        <td>
          <code>{s.ip}</code>
        </td>
        <td align="right">{isDesktop && props.allowDeleting ? button : null}</td>
      </tr>
    )
  })

  const userTrans = intl.formatMessage({ id: 'system.auth.user' })
  const agentTrans = intl.formatMessage({ id: 'system.auth.user_agent' })

  return (
    <table className="nsi-table">
      <thead>
        <TableHeader titles={[userTrans, agentTrans, 'IP', '']} />
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

interface Props {
  allowDeleting: boolean
}

export default Sessions
