import { Equipment } from 'au-nsi/equipment'
import classnames from 'classnames'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessRights } from '../../App/app.selectors'
import { getDeviceState, setDeviceState } from '../nsi.actions'

interface Props {
  id: string
  state: Equipment['state']
  protocol: Equipment['protocol']
}

const DeviceControls = ({ id, state, protocol }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const allowEditing = useSelector(selectAccessRights)['equipment:set_state'] && protocol !== 'LP001'

  const isLoading = state === 'STARTING' || state === 'STOPPING' || state === 'READING_CONF'

  const className = classnames('device-state', {
    'is-warning': state === 'WARNING',
    'is-error': state.includes('ERROR'),
  })

  // some protocols require reading config first, while others don't
  const allowReadingConf = protocol === 'C.37.118' || protocol === 'IEC104' || protocol === 'db-client'

  // в нормальной ситуации НСИ следит за переводом устройства из одного состояния в другое,
  // но в случае перезапуска НСИ устройство может зависнуть во временном состоянии. Для того чтобы
  // этого не произошло можно в таких случаях инициировать проверку состояния с фронта
  useEffect(() => {
    if (isLoading) {
      const refresh = () => dispatch(getDeviceState(id))
      const interval = setInterval(refresh, 3_000)

      return () => clearInterval(interval)
    }
  }, [id, isLoading])

  const readConfiguration = () => dispatch(setDeviceState(id, 'READING_CONF'))
  const startReceiver = () => dispatch(setDeviceState(id, 'RUNNING'))
  const stopReceiver = () => dispatch(setDeviceState(id, 'STOPPED'))

  const StartBtn = () => {
    return (
      <button key="start" onClick={startReceiver} className="nsi-button default">
        {intl.formatMessage({ id: 'nsi.actions.start' })}
      </button>
    )
  }

  const StopBtn = () => {
    return (
      <button key="stop" onClick={stopReceiver} className="nsi-button secondary">
        {intl.formatMessage({ id: 'nsi.actions.stop' })}
      </button>
    )
  }

  const ReadConfBtn = () => {
    return (
      <button key="read" onClick={readConfiguration} className="nsi-button secondary">
        {intl.formatMessage({ id: 'nsi.actions.read_conf' })}
      </button>
    )
  }

  if (!allowEditing) return null

  const isLaunched = ['RUNNING', 'STARTING', 'ERROR', 'WARNING'].includes(state)
  let buttons = []

  if (state === 'STOPPED' || state === 'READING_CONF_SUCCESS') {
    buttons = allowReadingConf ? [ReadConfBtn(), StartBtn()] : [StartBtn()]
  } else if (isLaunched) {
    buttons = [StopBtn()]
  } else if (state === 'READING_CONF_ERROR') {
    buttons = [ReadConfBtn()]
  }

  return (
    <div className={className} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      {buttons}
    </div>
  )
}

export default DeviceControls
