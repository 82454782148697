import { LoaderDots } from '@alterouniversal/au-react-components'
import React, { CSSProperties, useEffect, useRef } from 'react'
import './pagination.styles.css'

const InfiniteScroll = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callback = (entry) => {
      if (entry[0].isIntersecting && props.isMore) props.loadMore()
    }

    const observer = new IntersectionObserver(callback, { threshold: 0.9 })
    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [props.loadMore, props.isMore])

  return (
    <div className="inf-scroll__wrapper" style={props.style}>
      {props.children}
      <div ref={ref} className="inf-scroll__loader" style={!props.isMore ? { display: 'none' } : {}}>
        {props.isMore && <LoaderDots style={{ marginTop: '40px' }} />}
      </div>
    </div>
  )
}

interface Props {
  style?: CSSProperties
  parentSelector?: string
  children: React.ReactNode
  loadMore: () => void
  isMore: boolean
}

export default InfiniteScroll
