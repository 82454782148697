import { ReactComponent as FolderIcon } from '../../../../icons/folder.svg'
import css from '../docs.module.css'
import { ReactComponent as FileIcon } from '../icons/file.svg'
import { ReactComponent as ImageIcon } from '../icons/file-image.svg'
import { ReactComponent as PdfIcon } from '../icons/file-pdf.svg'
import { ReactComponent as WordIcon } from '../icons/file-word.svg'
import { CSSProperties } from 'react'
import { IDocument } from 'au-nsi/documents'

/**
 * Иконка документа, зависит от типа (файл, папка) и от типа файла (pdf, word и т.д.)
 */
const DocumentIcon = (props: Props) => {
  const doc = props.document

  const padding = doc.is_folder ? 5 : 15
  const color = doc.is_folder ? 'var(--warning)' : 'var(--gray-30)'
  const iconProps = { className: props.className ?? css.documentIcon, style: props.style ?? { padding, color } }

  if (doc.is_folder) return <FolderIcon {...iconProps} />
  if (doc.type.startsWith('image')) return <ImageIcon {...iconProps} />
  if (doc.extension === 'pdf') return <PdfIcon {...iconProps} />
  if (doc.extension.includes('doc')) return <WordIcon {...iconProps} />

  return <FileIcon {...iconProps} />
}

interface Props {
  document: IDocument
  className?: string
  style?: CSSProperties
}

export default DocumentIcon
