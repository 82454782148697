import { Select } from '@alterouniversal/au-react-components'
import { IMapComponent, IMapSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { IDataSource } from '../../Nsi/nsi.interfaces'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import UnitSelector from '../../Parameters/UnitSelector'

const MapSettings = (props: Props) => {
  const intl = useIntl()
  const [settings, setSettings] = React.useState(props.component.settings)

  const handleChange = (value: any, key: string) => {
    setSettings({ ...settings, [key]: value })
  }

  const handleCenterChange = (value: number, key: string) => {
    const center = { ...settings.center, [key]: value }

    // перемещение всех только что добавленных устройств в новый центр карты
    const equipment = settings.equipment.map((e) => {
      const isNew = props.component.settings.equipment.find((d) => d.id === e.id) == null
      return isNew ? { ...e, ...center } : e
    })

    setSettings({ ...settings, center, equipment })
  }

  const handleEquipmentChange = (selection: string[]) => {
    const equipment = selection.map((id) => {
      return settings.equipment.find((e) => e.id === id) || { id, ...settings.center }
    })

    setSettings({ ...settings, equipment })
  }

  const handleUnitChange = (parameter_unit: string, parameter_prefix: string) => {
    setSettings({ ...settings, parameter_unit, parameter_prefix })
  }

  const typeOptions = ['value', 'vector', 'gradient'].map((value) => {
    return { value, label: intl.formatMessage({ id: 'dashboards.map.settings.type.' + value }) }
  })

  const magnitude = React.useMemo(() => {
    const options = props.parameters.map((p) => ({ value: p.id, label: p.name }))
    const value = props.parameters.find((p) => p.id === settings.parameter_id)
    return { value, options }
  }, [props.parameters, settings.parameter_id])

  const angle = React.useMemo(() => {
    const angles = props.parameters.filter((p) => p.unit === 'rad' || p.unit === 'deg')
    return angles.map((a) => ({ value: a.id, label: a.name }))
  }, [props.parameters, settings.parameter_angle_id])

  const angleInput = () => (
    <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.parameter_angle_id' })}>
      <Select name="parameter_angle_id" options={angle} value={settings.parameter_angle_id} onChange={handleChange} />
    </InputRow>
  )

  const boundariesInputs = () => {
    const inputProps = {
      fullWidth: true,
      units: props.units,
      prefixes: props.prefixes,
      basePrefix: props.prefixes[magnitude.value?.prefix],
      baseUnit: props.units[magnitude.value?.unit],
      displayPrefix: props.prefixes[magnitude.value?.display_prefix],
      displayUnit: props.units[magnitude.value?.display_unit],
      onChange: handleChange,
    }

    return (
      <React.Fragment>
        <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.parameter_min' })}>
          <NumberInput type="parameter" name="parameter_min" value={settings.parameter_min} {...inputProps} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.parameter_max' })}>
          <NumberInput type="parameter" name="parameter_max" value={settings.parameter_max} {...inputProps} />
        </InputRow>
      </React.Fragment>
    )
  }

  const error = validate(settings, intl)
  const isGradient = settings.type === 'gradient' || settings.type === 'area_gradient'

  const handleSave = () => {
    if (!error) props.onSave(settings)
  }

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid three-cols">
            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.title' })}>
              <TextInput value={settings.title} name="title" onChange={handleChange} required={false} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.type' })}>
              <Select options={typeOptions} name="type" value={settings.type} onChange={handleChange} />
            </InputRow>

            <div />

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.center.lat' })}>
              <NumberInput
                type="number"
                name="lat"
                value={settings.center.lat}
                min={-90}
                max={90}
                onChange={handleCenterChange}
                fullWidth={true}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.center.lng' })}>
              <NumberInput
                type="number"
                name="lng"
                value={settings.center.lng}
                min={-180}
                max={180}
                onChange={handleCenterChange}
                fullWidth={true}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.zoom' })}>
              <NumberInput
                type="number"
                name="zoom"
                value={settings.zoom}
                min={1}
                max={20}
                onChange={handleChange}
                fullWidth={true}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.parameter_id' })}>
              <Select
                name="parameter_id"
                options={magnitude.options}
                value={settings.parameter_id}
                onChange={handleChange}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.parameter_unit' })}>
              <UnitSelector
                onChange={handleUnitChange}
                prefixes={props.prefixes}
                referenceUnit={magnitude.value?.unit}
                selectedPrefix={settings.parameter_prefix}
                selectedUnit={settings.parameter_unit}
                units={props.units}
              />
            </InputRow>

            {settings.type === 'vector' ? angleInput() : <div />}
            {isGradient && boundariesInputs()}
          </div>

          <InputRow label={intl.formatMessage({ id: 'dashboards.map.settings.equipment' })}>
            <MultiSelect
              options={props.equipment}
              selection={settings.equipment.map((e) => e.id)}
              onChange={handleEquipmentChange}
              disabled={false}
            />
          </InputRow>

          <ModalFooter error={error} disabled={!!error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

const validate = (settings: IMapSettings, intl: IntlShape) => {
  if (!settings.parameter_id) {
    return intl.formatMessage({ id: 'dashboards.map.errors.empty_parameter' })
  }

  if (!settings.parameter_unit) {
    return intl.formatMessage({ id: 'dashboards.map.errors.empty_unit' })
  }

  if (settings.type === 'vector' && !settings.parameter_angle_id) {
    return intl.formatMessage({ id: 'dashboards.map.errors.empty_angle' })
  }

  return null
}

interface Props {
  component: IMapComponent
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: IMapSettings) => void
  parameters: Parameter[]
  prefixes: { [id: string]: SiPrefix }
  title: string
  units: { [id: string]: SiUnit }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameters: state.parameters.items,
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(MapSettings)
