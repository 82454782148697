import { IAccessSettings } from 'au-nsi/shared'
import produce from 'immer'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ArrowIcon } from '../../icons/long-arrow-right.svg'
import { loadOrganizations } from '../../pages/Catalogs/Organizations/organizations.actions'
import rolesActions from '../../pages/Collections/Roles/roles.actions'
import usersActions from '../../pages/Collections/Users/users.actions'
import { array2map } from '../../utils/misc'
import { highlightSearch } from '../../utils/search'
import HelpTooltip from '../HelpTooltip/HelpTooltip'
import SearchInput from '../Inputs/Search/SearchInput'
import Toggle from '../Inputs/Toggle'
import CollapseButton from '../Utils/CollapseButton'
import css from './access.module.css'
import * as selectors from './access.selectors'
import AccessGroupRow from './AccessRowGroup'
import AccessUserRow from './AccessRowUser'
import GroupMode from './GroupMode'

/**
 * Форма настроек прав доступа
 */
const AccessForm = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadOrganizations())
    dispatch(usersActions.loadItems())
    dispatch(rolesActions.loadItems())
  }, [])

  const [groupMode, setGroupMode] = useState<'roles' | 'orgs'>('roles')
  const [selection, setSelection] = useState<AccessSelection>(generateEmptySelection())
  const [search, setSearch] = useState<string>()

  const [expandedRoles, setExpandedRoles] = useState<number[]>([])
  const [expandedOrgs, setExpandedOrgs] = useState<number[]>([])

  const users = useSelector(selectors.selectUsers)
  const orgs = useSelector(selectors.selectOrganizations)
  const roles = useSelector(selectors.selectRoles)

  const searchTerms = search ? search.split(/\s+/g) : null
  const { userGroups, usersMap } = useMemo(() => selectors.groupUsers(users, searchTerms), [users, search])

  const orgsMap = useMemo(() => array2map(orgs), [orgs])
  const rolesMap = useMemo(() => array2map(roles), [roles])

  // доступ разрешен
  const allowedOrgs = (props.access.orgs || []).filter((id) => orgsMap.has(id))
  const allowedRoles = (props.access.roles || []).filter((id) => rolesMap.has(id))
  const allowedUsers = (props.access.users || []).filter((id) => usersMap.has(id))

  // доступ запрещен (все кроме разрешенных)
  const blockedOrgs = useMemo(() => orgs.filter((e) => !allowedOrgs.includes(e.id)), [orgs, props.access])
  const blockedRoles = useMemo(() => roles.filter((e) => !allowedRoles.includes(e.id)), [roles, props.access])

  // пользователи в левой колонке выводятся сгруппированые либо по ролям либо по организациям
  const groups: Array<{ id: number; name: string }> = groupMode === 'roles' ? blockedRoles : blockedOrgs
  const groupsMap = groupMode === 'roles' ? userGroups.byRole : userGroups.byOrg

  // все группы в колонках схлопнуты или нет
  let isBlacklistCollapsed = true
  let isWhitelistCollapsed = true

  // свернуть/развернуть группу
  const toggleGroup = (id: number, key: string) => {
    const values = key === 'roles' ? expandedRoles : expandedOrgs
    const setter = key === 'roles' ? setExpandedRoles : setExpandedOrgs

    const updates = values.filter((e) => e !== id)
    if (updates.length === values.length) updates.push(id)

    setter(updates)
  }

  const handleGroupToggle = (e) => {
    e.stopPropagation()
    toggleGroup(+e.currentTarget.dataset.id, e.currentTarget.dataset.type)
  }

  // свернуть/развернуть всю левую колонку
  const toggleBlacklist = (isCollapsed: boolean) => {
    const rolesUpdates = expandedRoles.filter((id) => allowedRoles.includes(id))
    const orgsUpdates = expandedOrgs.filter((id) => allowedOrgs.includes(id))

    if (!isCollapsed) {
      for (const { id } of blockedRoles) rolesUpdates.push(id)
      for (const { id } of blockedOrgs) orgsUpdates.push(id)
    }

    setExpandedRoles(rolesUpdates)
    setExpandedOrgs(orgsUpdates)
  }

  // свернуть/развернуть всю правую колонку
  const toggleWhitelist = (isCollapsed: boolean) => {
    const rolesUpdates = expandedRoles.filter((id) => !allowedRoles.includes(id))
    const orgsUpdates = expandedOrgs.filter((id) => !allowedOrgs.includes(id))

    if (!isCollapsed) {
      for (const id of allowedRoles) rolesUpdates.push(id)
      for (const id of allowedOrgs) orgsUpdates.push(id)
    }

    setExpandedRoles(rolesUpdates)
    setExpandedOrgs(orgsUpdates)
  }

  const handleSearch = (v: string) => {
    setSearch(v.toLowerCase())

    if (v && !expandedOrgs.length && !expandedRoles.length) {
      setExpandedRoles(roles.map((r) => r.id))
      setExpandedOrgs(orgs.map((o) => o.id))
    }
  }

  // выбор или снятие выбора по клику на элемент
  const handleClick = (e) => {
    const type = e.currentTarget.dataset.type
    const id = +e.currentTarget.dataset.id

    setSelection((prev) =>
      produce(prev, (draft) => {
        const set: Set<number> = draft[type]
        set.has(id) ? set.delete(id) : set.add(id)
      })
    )
  }

  // переместить выбранные элементы в правую колонку
  const selectionToWhitelist = () => {
    const access = { ...props.access }

    for (const [key, values] of Object.entries(selection)) {
      const updatedValues = new Set([...access[key], ...values])

      access[key] = Array.from(updatedValues)
    }

    props.onChange(access, 'access')
    setSelection(generateEmptySelection())
  }

  // переместить выбранные элементы в левую колонку
  const selectionFromWhitelist = () => {
    const access = { ...props.access }

    for (const [key, values] of Object.entries(selection)) {
      access[key] = access[key].filter((id) => !values.has(id))
    }

    props.onChange(access, 'access')
    setSelection(generateEmptySelection())
  }

  // перетаскивание элемента в левую колонку
  const handleBlacklistDrop = (e) => {
    const [type, id] = extractTransferData(e)

    if (props.access[type].includes(id)) {
      const access = { ...props.access, [type]: props.access[type].filter((e) => e !== id) }
      props.onChange(access, 'access')
    }
  }

  // перетаскивание элемента в правую колонку
  const handleWhitelistDrop = (e) => {
    const [type, id] = extractTransferData(e)

    if (!props.access[type].includes(id)) {
      const access = { ...props.access, [type]: [...props.access[type], id] }
      props.onChange(access, 'access')
    }
  }

  // левая колонка - список тех, у кого нет доступа
  const blacklist = groups.map((g) => {
    const groupUsers = (groupsMap.get(g.id) || []).filter((u) => !allowedUsers.includes(u.id))
    const isSelected = selection[groupMode].has(g.id)
    const isOpen = groupMode === 'roles' ? expandedRoles.includes(g.id) : expandedOrgs.includes(g.id)

    let userRows: JSX.Element[] = null

    if (isOpen) {
      isBlacklistCollapsed = false
      userRows = groupUsers.map((u) => (
        <AccessUserRow
          key={u.id}
          user={u}
          isSelected={selection.users.has(u.id)}
          searchTerms={searchTerms}
          onClick={handleClick}
        />
      ))
    }

    const { isMatch, result } = highlightSearch(g.name, searchTerms)
    if (search && !isMatch && !groupUsers.length) return null

    return (
      <Fragment key={g.id}>
        <AccessGroupRow
          type={groupMode}
          group={g}
          name={result}
          isOpen={isOpen}
          isSelected={isSelected}
          onClick={handleClick}
          onToggle={handleGroupToggle}
        />
        {userRows}
      </Fragment>
    )
  })

  // правая колонка - список разрешенных ролей
  const whitelistRoles = allowedRoles.map((id) => {
    const roleUsers = userGroups.byRole.get(id) || []
    const isOpen = expandedRoles.includes(id)
    const isSelected = selection.roles.has(id)

    const role = rolesMap.get(id)
    let childUsers: JSX.Element[] = null

    if (isOpen) {
      isWhitelistCollapsed = false
      childUsers = roleUsers.map((u) => (
        <AccessUserRow key={u.id} user={u} isSelected={false} searchTerms={searchTerms} onClick={undefined} />
      ))
    }

    const { isMatch, result } = highlightSearch(role.name, searchTerms)
    if (search && !isMatch && !roleUsers.length) return null

    return (
      <Fragment key={id}>
        <AccessGroupRow
          type="roles"
          group={role}
          name={result}
          isOpen={isOpen}
          isSelected={isSelected}
          onClick={handleClick}
          onToggle={handleGroupToggle}
        />
        {childUsers}
      </Fragment>
    )
  })

  // правая колонка - список разрешенных организаций
  const whitelistOrgs = allowedOrgs.map((id) => {
    const orgUsers = userGroups.byOrg.get(id) || []
    const isOpen = expandedOrgs.includes(id)
    const isSelected = selection.orgs.has(id)

    const org = orgsMap.get(id)
    let childUsers: JSX.Element[] = null

    if (isOpen) {
      isWhitelistCollapsed = false
      childUsers = orgUsers.map((u) => (
        <AccessUserRow key={u.id} user={u} isSelected={false} searchTerms={searchTerms} onClick={undefined} />
      ))
    }

    const { isMatch, result } = highlightSearch(org.name, searchTerms)
    if (search && !isMatch && !orgUsers.length) return null

    return (
      <Fragment key={id}>
        <AccessGroupRow
          type="orgs"
          group={org}
          name={result}
          isOpen={isOpen}
          isSelected={isSelected}
          onClick={handleClick}
          onToggle={handleGroupToggle}
        />
        {childUsers}
      </Fragment>
    )
  })

  // правая колонка - список разрешенных пользователей
  const whitelistUsers = allowedUsers.map((id) => {
    return (
      <AccessUserRow
        key={id}
        user={usersMap.get(id)}
        isSelected={selection.users.has(id)}
        searchTerms={searchTerms}
        onClick={handleClick}
        paddingLeft={28}
      />
    )
  })

  // включить/выключить ограничение доступа
  const handleRestrictedChange = (checked: boolean) => {
    props.onChange({ ...props.access, restricted: checked, orgs: [], roles: [], users: [] }, 'access')
  }

  return (
    <>
      <div className={css.toggleRow}>
        <Toggle name="restricted" checked={props.access.restricted} onChange={handleRestrictedChange} />
        <span style={{ marginRight: 16 }}>{intl.formatMessage({ id: 'access.restricted' })}</span>
        <HelpTooltip position="bottom">{intl.formatMessage({ id: 'access.restricted.help' })}</HelpTooltip>

        {props.access.restricted && orgs.length > 0 && (
          <GroupMode
            value={groupMode}
            onChange={setGroupMode}
            search={search != null}
            onSearch={(v) => setSearch(v ? '' : null)}
          />
        )}
      </div>

      {search != null && (
        <div className={css.searchRow}>
          <SearchInput onChange={handleSearch} />
        </div>
      )}

      {props.access.restricted && (
        <div className={css.columns}>
          <div className={css.leftColumn} onDragOver={preventDefault} onDrop={handleBlacklistDrop}>
            <div className={css.columnHeader}>
              <CollapseButton
                isCollapsed={isBlacklistCollapsed}
                onChange={toggleBlacklist}
                style={{ marginRight: 16 }}
              />
              <span>{intl.formatMessage({ id: 'access.deny_access' })}</span>
            </div>

            {blacklist}
          </div>

          <div className={css.separator}>
            <div className={css.separatorLine} />
            <div
              className={css.separatorIcon}
              onClick={selectionToWhitelist}
              title={intl.formatMessage({ id: 'access.move_right' })}
            >
              <ArrowIcon width="24" height="24" />
            </div>
            <div className={css.separatorLine} style={{ height: '20px' }} />
            <div
              className={css.separatorIcon}
              onClick={selectionFromWhitelist}
              title={intl.formatMessage({ id: 'access.move_left' })}
            >
              <ArrowIcon width="24" height="24" style={{ transform: 'rotate(180deg)' }} />
            </div>
            <div className={css.separatorLine} />
          </div>

          <div className={css.rightColumn} onDragOver={preventDefault} onDrop={handleWhitelistDrop}>
            <div className={css.columnHeader}>
              <CollapseButton
                isCollapsed={isWhitelistCollapsed}
                onChange={toggleWhitelist}
                style={{ marginRight: 16 }}
              />
              <span>{intl.formatMessage({ id: 'access.allow_access' })}</span>
            </div>

            {whitelistUsers}
            {whitelistRoles}
            {whitelistOrgs}

            {!search && whitelistRoles.length === 0 && whitelistOrgs.length === 0 && whitelistUsers.length === 0 && (
              <div className="text--center text--gray" style={{ padding: '0 18px' }}>
                {intl.formatMessage({ id: 'access.allowed_empty' })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

interface Props {
  access: IAccessSettings
  onChange: (access: IAccessSettings, key: string) => void
}

interface AccessSelection {
  users: Set<number>
  roles: Set<number>
  orgs: Set<number>
}

const preventDefault = (e) => e.preventDefault()

const extractTransferData = (e) => {
  e.preventDefault()

  const data: string = e.dataTransfer.getData('text/plain')
  const [type, id] = data.split(':')

  return [type, +id]
}

const generateEmptySelection = () => ({
  users: new Set<number>(),
  roles: new Set<number>(),
  orgs: new Set<number>(),
})

export default AccessForm
