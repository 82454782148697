import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import { useState } from 'react'
import Checkbox from '../../../../../../shared/Inputs/Checkbox/Checkbox'
import findCatalogsDiff from './catalogRequest.diff'
import { useIntl } from 'react-intl'
import styles from '../../request.module.css'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import RequestSchemaForm from './RequestSchemaForm'
import { useRecoilValue } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import EntityRemoved from '../../components/EntityRemoved'
import { CatalogRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const CatalogRequestForm = ({ editing }: IProps) => {
  const intl = useIntl()
  const request = useRecoilValue<CatalogRequest>(selectedDraftRequestState as any)
  const currentId = request.request_body?.id ?? extractIntIdFromUrl(request.url)
  const isClosed = request.rejected || request.moderated_at
  const currentCatalog = useSelector((state: ReduxState) => {
    return state.catalogs.catalogs.find((c) => c.id === currentId)
  })

  const [showOnlyDiff, setShowOnlyDiff] = useState(false)

  if (request.method === 'POST') {
    return <RequestSchemaForm editing={editing} catalog={request.request_body} onlyDiffDisplaying={showOnlyDiff} />
  }

  if (request.method === 'DELETE') {
    return <RequestSchemaForm editing={editing} catalog={currentCatalog} onlyDiffDisplaying={showOnlyDiff} />
  }

  const before = { id: 'user.request.before', render: undefined }
  const after = { id: 'user.request.after', render: undefined }

  const empty = <EntityRemoved />
  const prev_catalog = isClosed ? request.state_before : currentCatalog

  const [beforeCatalog, afterCatalog] = showOnlyDiff
    ? findCatalogsDiff(prev_catalog, request.request_body)
    : [prev_catalog, request.request_body]

  before.render = () =>
    prev_catalog ? (
      <RequestSchemaForm editing={false} catalog={beforeCatalog} onlyDiffDisplaying={showOnlyDiff} />
    ) : (
      empty
    )
  after.render = () =>
    request.request_body ? (
      <RequestSchemaForm catalog={afterCatalog as any} editing={editing} onlyDiffDisplaying={showOnlyDiff} />
    ) : (
      empty
    )

  return (
    <>
      <div className={styles.requestOnlyDiff}>
        <Checkbox checked={showOnlyDiff} onChange={setShowOnlyDiff} />
        <span>{intl.formatMessage({ id: 'user.request.show_only_diff' })}</span>
      </div>
      <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />
    </>
  )
}

export default CatalogRequestForm
