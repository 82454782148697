import { API_URL } from '../../../shared/constants'
import { readFile } from '../../../utils/files'
import { Image } from 'au-nsi/images'

/**
 * Генерация ссылки для отображения картинки: если присутствует исходник картинки, то
 * отображаем его инлайн через data URL, если нет - то указываем путь к картинке на сервере
 */
export const generateImageSrc = (image: Image) => {
  return image.src ? generateDataURL(image) : generateImageLink(image)
}

export const generateImageLink = (image: Pick<Image, 'id'>) => {
  return API_URL + `/nsi/v1/images/${image.id}/raw`
}

export const generateDataURL = (image: Pick<Image, 'src' | 'format'>) => {
  return image.format === 'svg'
    ? `data:image/svg+xml,${encodeURIComponent(image.src)}`
    : `data:image/${image.format};base64,${image.src}`
}

// поддерживаемые форматы изображений
export const formats = '.png,.apng,.jpeg,.jpg,.gif,.webp,.svg'

// загрузка в память выбранных пользователем файлов
export const loadFiles = (list: FileList, cb: (image: Pick<Image, 'format' | 'name' | 'src'>) => void) => {
  if (!list || !list.length) return

  for (let i = 0; i < list.length; i++) {
    const file = list[i]
    const { type } = file
    const name = extractName(file.name)

    if (type.startsWith('image')) {
      const format: any = type.includes('svg') ? 'svg' : type.split('/')[1]
      const mode = format === 'svg' ? 'text' : 'binary'

      readFile(file, mode, (src) => {
        if (src != null) cb({ name, format, src })
      })
    }
  }
}

// отбросить расширение файла и оставить только название
const extractName = (fullname: string) => {
  const i = fullname.lastIndexOf('.')
  return fullname.slice(0, i)
}
