import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ITextVariable } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import UnitSelector from '../../Parameters/UnitSelector'
import NumberFormatSettings from '../common/NumberFormatSettings'

/**
 * Настройки привязки параметра приходящего с устройства к переменной заданной в тексте
 */
const VarsSettingsRow = (props: Props) => {
  const { settings } = props
  const parameter = props.parameters.get(settings.parameter_id)

  const handleDeviceChange = (device_id: string) => {
    props.onChange({ ...settings, device_id })
  }

  const handleParameterChange = (parameter_id: string) => {
    const { display_unit, display_prefix } = props.parameters.get(parameter_id)
    props.onChange({ ...settings, parameter_id, display_unit, display_prefix })
  }

  const handleUnitChange = (display_unit, display_prefix) => {
    props.onChange({ ...settings, display_unit, display_prefix })
  }

  const handleFormatChange = (number_format) => {
    props.onChange({ ...settings, number_format })
  }

  return (
    <tr>
      <td>{settings.name}</td>
      <td>
        <Select
          name="device_id"
          options={props.equipmentOptions}
          value={settings.device_id}
          onChange={handleDeviceChange}
        />
      </td>
      <td>
        <Select
          name="parameter_id"
          options={props.parameterOptions}
          value={settings.parameter_id}
          onChange={handleParameterChange}
        />
      </td>
      <td>
        <UnitSelector
          onChange={handleUnitChange}
          prefixes={props.prefixes}
          referenceUnit={parameter?.unit}
          selectedPrefix={settings.display_prefix}
          selectedUnit={settings.display_unit}
          units={props.units}
        />
      </td>
      <td>
        <NumberFormatSettings settings={settings.number_format} onChange={handleFormatChange} />
      </td>
    </tr>
  )
}

interface Props {
  equipmentOptions: SelectOption[]
  onChange: (settings: ITextVariable) => void
  parameterOptions: SelectOption[]
  parameters: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  settings: ITextVariable
  units: { [id: string]: SiUnit }
}

export default React.memo(VarsSettingsRow)
