import { Select, Toggle } from '@alterouniversal/au-react-components'
import { Incident } from 'au-nsi/signal-events'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useInfiniteLoader from '../../../hooks/useInfiniteLoader'
import { useAppSelector } from '../../../redux/store'
import JournalsExport from '../../../shared/Export/JournalsExport'
import InputRow from '../../../shared/Inputs/InputRow'
import Timerange from '../../../shared/Inputs/Timerange'
import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import NoResults from '../../System/common/NoResults'
import IncidentsTable from './IncidentsTable'

/**
 * Журнал сигнальных ситуаций
 */
const IncidentsJournal = () => {
  const intl = useIntl()
  const [showDetails, setShowDetails] = useState(false)
  const [filters, setFilters] = useState({ t0: 0, t1: 0, signal_event_id: null, device_id: null, order: 'DESC' })

  const handleFilterChange = useCallback((value: number, key: keyof typeof filters) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleEventChange = useCallback((id) => handleFilterChange(id, 'signal_event_id'), [])

  const { results, isLoading, isMore, loadMore } = useInfiniteLoader<Incident>({
    url: '/nsi/v1/incidents',
    query: filters,
    size: 50,
    autoreload: true,
  })

  const signalEvents = useAppSelector((state) => state.signal_events.items)
  const devices = useAppSelector((state) => state.nsi.equipment)

  const seOptions = useMemo(() => {
    const result = signalEvents.map((se) => ({ value: se.id, label: se.name }))
    result.unshift({ value: null, label: intl.formatMessage({ id: 'common.all' }) })
    return result
  }, [signalEvents])

  const deviceOptions = useMemo(() => {
    const result = devices.map((d) => ({ value: d.id, label: d.name }))
    result.unshift({ value: null, label: intl.formatMessage({ id: 'common.all' }) })
    return result
  }, [devices])

  const isTail = !filters.t0 && !filters.t1
  const noResults = !isLoading && results.length === 0 && !isMore

  const handleTailChange = () => {
    let t0 = 0
    let t1 = 0

    if (isTail) {
      t1 = Date.now()
      t0 = t1 - 24 * 60 * 60 * 1000
    }

    setFilters((prev) => ({ ...prev, t0, t1 }))
  }

  return (
    <div className="nsi-main__container is-wide">
      <div style={{ display: 'flex', alignItems: 'center', gap: '0 2rem', flexWrap: 'wrap' }}>
        <InputRow compact={true} label={intl.formatMessage({ id: 'system.actions.show_details' })}>
          <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
            <Toggle checked={showDetails} onChange={setShowDetails} />
          </div>
        </InputRow>

        <InputRow compact={true} label={intl.formatMessage({ id: 'system.actions.tail' })}>
          <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
            <Toggle checked={isTail} onChange={handleTailChange} />
          </div>
        </InputRow>

        <InputRow compact={true} label={intl.formatMessage({ id: 'system.actions.signal_event' })}>
          <Select
            name="signal_event_id"
            options={seOptions}
            value={filters.signal_event_id}
            onChange={handleFilterChange}
          />
        </InputRow>

        <InputRow compact={true} label={intl.formatMessage({ id: 'system.actions.device' })}>
          <Select name="device_id" options={deviceOptions} value={filters.device_id} onChange={handleFilterChange} />
        </InputRow>

        {!isTail && (
          <InputRow compact={true} label={intl.formatMessage({ id: 'system.actions.timerange' })}>
            <Timerange
              tsFrom={filters.t0}
              tsTo={filters.t1}
              setTsFrom={(ts) => handleFilterChange(ts, 't0')}
              setTsTo={(ts) => handleFilterChange(ts, 't1')}
            />
          </InputRow>
        )}

        <JournalsExport
          url="/nsi/v1/incidents:export"
          t0={filters.t0}
          t1={filters.t1}
          style={{ marginLeft: 'auto', alignSelf: 'end' }}
        />
      </div>

      {noResults ? (
        <NoResults />
      ) : (
        <InfiniteScroll parentSelector=".nsi-main__wrapper" isMore={isMore} loadMore={loadMore}>
          <IncidentsTable incidents={results} showDetails={showDetails} onSelect={handleEventChange} />
        </InfiniteScroll>
      )}
    </div>
  )
}

export default IncidentsJournal
