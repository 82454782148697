import React from 'react'
import Panel from '../../../shared/FormPanel/FormPanel'
import { Equipment } from 'au-nsi/equipment'
import C37ParametersPanel from './C37'
import DBClientMapping from './DBClient'
import IEC104Mapping from './IEC104'
import MLP14Mapping from './MLP14'
import OPCDAMapping from './OPCDA'
import ModbusMapping from './Modbus/ModbusMapping'

const forms = {
  'C.37.118': C37ParametersPanel,
  'db-client': DBClientMapping,
  'modbustcp-client': ModbusMapping,
  'modbustcp-server': ModbusMapping,
  IEC104: IEC104Mapping,
  MLP14: MLP14Mapping,
  OPCDA: OPCDAMapping,
}

/**
 * Вкладка с настройками маппинга параметров. Для каждого протокола отдельная форма.
 */
const ParametersMappingPanel = (props: Props) => {
  const { device } = props
  const Form: React.ComponentType<{ device: any; allowEditing: boolean }> = forms[device.protocol]

  if (!Form) return null

  return (
    <Panel id="protocol_config" title="nsi.data_config" open={props.isOpen} onToggle={props.onToggle}>
      <Form device={device} allowEditing={props.allowEditing} />
    </Panel>
  )
}

interface Props {
  device: Equipment
  isOpen: boolean
  allowEditing: boolean
  onToggle: (name: string) => void
}

export default ParametersMappingPanel
