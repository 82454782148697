import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import MultiSelect from '../../Inputs/MultiSelect'

/**
 * Поле для задания настройки отрисовки графика при аггрегации данных (какие значения выводить -
 * минимум/максимум/среднее). Кодируется одним числом:
 * нулевой бит - выводить минимум
 * первый бит - выводить среднее
 * второй бит - выводить максимум
 */
const AggregationModeInput = (props: Props) => {
  const intl = useIntl()
  const value = props.value ?? 2

  const options = [
    { id: 1, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.min' }) },
    { id: 2, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.avg' }) },
    { id: 4, name: intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode.max' }) },
  ]

  const selection = useMemo(() => {
    const result: number[] = []

    if ((value & 1) === 1) result.push(1)
    if ((value & 2) === 2) result.push(2)
    if ((value & 4) === 4) result.push(4)

    return result
  }, [value])

  const handleChange = (values: number[]) => {
    let result = 0

    for (const v of values) {
      result = result | v
    }

    props.onChange(result, 'aggregationMode')
  }

  return <MultiSelect options={options} selection={selection} onChange={handleChange} />
}

interface Props {
  value: number
  onChange: (value: number, name: string) => void
}

export default AggregationModeInput
