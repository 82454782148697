import http, { handleHttpError } from '../../../utils/http'
import { IDocument } from 'au-nsi/documents'

interface IDocumentsGetOptions {
  resource?: string
  resource_item_id?: string | number
  parent_id?: string
  ids?: IDocument['id'][]
  displayErrors?: boolean
}

export const fetchDocuments = ({
  resource,
  resource_item_id,
  parent_id = null,
  ids,
  displayErrors = true,
}: IDocumentsGetOptions): Promise<IDocument[]> => {
  let url = '/nsi/v1/documents?parent_id=' + parent_id

  if (resource) url += '&resource=' + resource
  if (resource_item_id) url += '&resource_item_id=' + resource_item_id
  if (ids) url += ids.map((id) => `&ids=${id}`).join('')

  return http
    .get(url)
    .then((r) => r.data)
    .catch((err) => (displayErrors ? handleHttpError(err) : undefined))
}
