import { SearchItem } from './SearchModal'
import styles from './searchmodal.module.css'
import { ReactComponent as ArrowLeft } from '../../../../icons/arrow-right.svg'
import { useIntl } from 'react-intl'
import { memo } from 'react'
import DeviceIcon from '../../Tree/DeviceIcon'
import * as utils from '../../../../utils/search'

interface IProps {
  items: SearchItem[]
  searchPattern: string
  orderedBy: string
  order: boolean
  onClick: (e) => void
  onOrderedByChange: (attr: string) => void
  onOrderChange: () => void
}

/**
  Виртуализированная таблица с устройствами для SearchModal
*/
const Table = ({ items, onClick, onOrderChange, order, searchPattern, orderedBy, onOrderedByChange }: IProps) => {
  const intl = useIntl()

  const handleHeaderRowClick = (name: string) => {
    onOrderedByChange(name)
    onOrderChange()
  }

  const renderRow = (item: SearchItem, index: number) => {
    return (
      <div
        className={`row ${styles.rowGrid}`}
        key={item.id}
        data-id={item.id}
        onClick={onClick}
        style={{ background: index % 2 === 0 ? 'var(--bg-default)' : 'var(--bg-default-light)' }}
      >
        <div
          title={intl.formatMessage({
            id: item.type === 'db-client' ? 'nsi.protocols.db-client' : 'nsi.equipment.' + item.type,
          })}
        >
          <DeviceIcon id={item.id} />
        </div>
        <div>{renderSearchResult(item.name, searchPattern)}</div>
        <div>{renderSearchResult(item.path, searchPattern)}</div>
        <div>{renderSearchResult(item.address, searchPattern)}</div>
        <div>{renderSearchResult(item.id, searchPattern)}</div>
      </div>
    )
  }

  const renderArrow = () => (
    <ArrowLeft style={{ height: '13px', transform: order ? 'rotate(-90deg)' : 'rotate(90deg)', marginLeft: '5px' }} />
  )

  return (
    <div className={`nsi-grid-table is-compact ${styles.table}`}>
      <header className={styles.rowGrid}>
        <div>{intl.formatMessage({ id: 'vars.parameter.type' })}</div>
        <div onClick={() => handleHeaderRowClick('name')}>
          {intl.formatMessage({ id: 'common.name' })}
          {orderedBy === 'name' && renderArrow()}
        </div>
        <div onClick={() => handleHeaderRowClick('path')}>
          {intl.formatMessage({ id: 'nsi.location' })}
          {orderedBy === 'path' && renderArrow()}
        </div>
        <div onClick={() => handleHeaderRowClick('address')}>
          {intl.formatMessage({ id: 'nsi.search.ip' })}
          {orderedBy === 'address' && renderArrow()}
        </div>
        <div>GUID</div>
      </header>
      <div className="tbody" style={{ maxHeight: '50vh', overflow: 'auto' }}>
        {items.map(renderRow)}
      </div>
      {items.length === 0 && <div className={styles.noResults}> {intl.formatMessage({ id: 'nsi.no_results' })} </div>}
    </div>
  )
}

export const EquipmentTable = memo(Table)

const renderSearchResult = (text: string, search: string) => {
  const result = utils.search(text, [search.toLowerCase()])
  return result.length > 0 ? utils.renderSearchResult(result) : text
}
