import classnames from 'classnames'
import React, { CSSProperties } from 'react'
import { ReactComponent as PenIcon } from '../../../icons/pen.svg'
import { showError } from '../../../utils/notifications'
import TextareaInput from '../TextareaInput'
import styles from './editableText.module.css'

const EditableText = (props: Props) => {
  const input = React.useRef<HTMLInputElement | HTMLTextAreaElement>()
  const span = React.useRef<HTMLSpanElement>()

  const [value, setValue] = React.useState(props.value)
  const [editing, setEditing] = React.useState(false)
  const [inputWidth, setInputWidth] = React.useState(12)

  React.useEffect(() => {
    span && span.current && setInputWidth(span.current.offsetWidth + 2)
  }, [span && span.current, editing, value])

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value, editing])

  React.useEffect(() => {
    if (editing) input.current.focus()
  }, [editing])

  const handleChange = (e) => setValue(e.target.value)

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      stopEditing()
    }
  }

  const startEditing = () => {
    if (props.allowEditing) setEditing(true)
  }

  const stopEditing = () => {
    setEditing(false)

    if (value === props.value) return

    const error = props.validator && props.validator(value)
    if (error) return showError(error)

    if (value) props.onChange(value, props.name)
    else return showError('common.validation_error')
  }

  const isInvalid = value !== props.value && props.validator && props.validator(value)
  const className = classnames('editable-text', { 'is-invalid': isInvalid })

  if (editing) {
    const inputProps: any = {
      className,
      value,
      onBlur: stopEditing,
      onKeyUp: handleKeyUp,
      required: true,
      ref: input,
    }

    const Input = props.useTextArea ? (
      <TextareaInput {...inputProps} onChange={setValue} style={props.style} />
    ) : (
      <input {...inputProps} onChange={handleChange} style={{ ...props.style, width: inputWidth + 'px' }} />
    )

    return (
      <>
        {!props.useTextArea && (
          <span
            ref={span}
            className={`${className} ${styles.hiddenSpan}`}
            style={{ ...props.style, whiteSpace: 'pre' }}
          >
            {value}
          </span>
        )}
        {Input}
      </>
    )
  }

  return (
    <div className="editable-text__wrapper" onClick={startEditing}>
      <div style={props.style} className="editable-text">
        {value || '...'}
      </div>
      <PenIcon className="device-name__icon" />
    </div>
  )
}

interface Props {
  allowEditing: boolean
  onChange: (value: string, name?: string) => void
  validator?: (value: string) => string
  value: string
  style?: CSSProperties
  name?: string
  useTextArea?: boolean
}

export default EditableText
