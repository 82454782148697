import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { selectAccessRights } from '../App/app.selectors'
import {
  CatalogLogbookWithInstances,
  CatalogPassportWithInstances,
  CatalogSchemaWithInstances,
  CatalogTypeWithInstances,
} from './catalogs.types'

export const selectAllCatalogs = (state: ReduxState) => state.catalogs.catalogs

export const selectPassports = createSelector(
  (state: ReduxState) => state.catalogs.catalogs,
  (catalogs) => catalogs.filter((c) => c.type === 'passport') as CatalogPassportWithInstances[]
)

export const selectSchemas = createSelector(
  (state: ReduxState) => state.catalogs.catalogs,
  (catalogs) => catalogs.filter((c) => c.type === 'catalog') as CatalogSchemaWithInstances[]
)

export const selectTypes = createSelector(
  (state: ReduxState) => state.catalogs.catalogs,
  (catalogs) => catalogs.filter((c) => c.type === 'type') as CatalogTypeWithInstances[]
)

export const selectLogbooks = createSelector(
  (state: ReduxState) => state.catalogs.catalogs,
  (catalogs) => catalogs.filter((c) => c.type === 'logbook') as CatalogLogbookWithInstances[]
)

export const selectCatalogsAccessRights = createSelector(selectAccessRights, (rights) => ({
  allowCatalogsEdit: rights['catalogs:update'],
  allowInstancesEditing: rights['catalogs:update_items'],
}))
