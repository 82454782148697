import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ICommandType } from 'au-nsi/commands'
import { memo, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/store'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'

interface Props {
  target: string
  target_type: ICommandType['target_type']
  allowed_targets: ICommandType['allowed_targets']
  editing: boolean
  onChange: (target: string, name: 'target') => void
}

/**
 * Поле для выбора получателя команды
 */
const CommandTargetInput = ({ target, target_type, allowed_targets, editing, onChange }: Props) => {
  const intl = useIntl()
  const equipment = useAppSelector((state) => state.nsi.equipment)

  const allowedDevices = useMemo(() => {
    const result: SelectOption[] = []
    if (target_type !== 'device') return result

    for (const e of equipment) {
      if (allowed_targets.includes(e.protocol)) {
        result.push({ value: e.id, label: e.name })
      }
    }

    return result
  }, [target_type, allowed_targets, equipment])

  // если команду можно отправлять только одному сервису, то сразу его выбираем
  useEffect(() => {
    if (target_type === 'service' && allowed_targets.length === 1) {
      onChange(allowed_targets[0], 'target')
    }
  }, [])

  // если команда предназначена для сервисов, то выводим список указанных сервисов
  if (target_type === 'service') {
    const options = allowed_targets.map((value) => ({ value, title: value }))
    return (
      <InputRow compact={true} label={intl.formatMessage({ id: 'commands.target_service' })}>
        <Dropdown name="target" value={target} options={options} onChange={onChange} disabled={!editing} />
      </InputRow>
    )
  }

  // если команда предназначена для устройств, то выводим список устройств с указанными протоколами
  if (target_type === 'device') {
    return (
      <InputRow compact={true} label={intl.formatMessage({ id: 'commands.target_device' })}>
        <Select
          name="target"
          options={allowedDevices}
          value={target}
          onChange={onChange}
          required={true}
          disabled={!editing}
        />
      </InputRow>
    )
  }

  return null
}

export default memo(CommandTargetInput)
