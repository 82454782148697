import { useSelector } from 'react-redux'
import { selectAllCatalogs } from '../../catalogs.selectors'
import InstanceFormWrapper from './InstanceFormWrapper'
import InstanceRow from './InstanceRow'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextInput from '../../../../shared/Inputs/TextInput'
import { useIntl } from 'react-intl'
import { Fragment } from 'react'
import { getNextGroupPath } from './instanceForm.utils'
import styles from './instanceForm.module.css'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface IInstanceObject {
  [key: string]: any
}

interface InstanceProps<T> {
  editing: boolean
  item: T
  propertiesKey: keyof T
  schema: IPropertyDescription[]
  onChange: (key: IPropertyDescription['id'], val: any) => void
  onNotListed?: (catalogId: number) => void
}

interface PropsWithName<T> extends InstanceProps<T> {
  nameKey: keyof T
  onNameChange: (name: string) => void
}

export type IInstanceFormProps<T> = InstanceProps<T> | PropsWithName<T>

const InstanceForm = <T extends IInstanceObject>(props: IInstanceFormProps<T>) => {
  const intl = useIntl()
  const catalogs = useSelector(selectAllCatalogs)

  const renderName = () => {
    if (!('nameKey' in props)) return null

    return (
      <div className="system__grid" style={{ marginBottom: 0 }}>
        <InputRow label={intl.formatMessage({ id: 'catalogs.name' })}>
          <TextInput
            value={props.item[props.nameKey]}
            name=""
            onChange={props.onNameChange}
            disabled={!props.editing}
            disabledClassName="system__input-disabled"
          />
        </InputRow>
      </div>
    )
  }

  const renderNode = (property: IPropertyDescription, path: string) => {
    if (property.type === 'group') {
      const currentPath = getNextGroupPath(path)
      let groupIndex = 0

      return (
        <Fragment key={property.id}>
          <tr style={{ background: 'inherit' }}>
            <td colSpan={2}>
              <div className={styles.groupHeader} style={{ marginTop: currentPath === '1' ? 0 : '1em' }}>
                {currentPath + '.'} {property.name}
              </div>
            </td>
          </tr>
          {property.children.map((prop) =>
            renderNode(prop, currentPath + '.' + (prop.type === 'group' ? groupIndex++ : groupIndex))
          )}
        </Fragment>
      )
    }

    return (
      <InstanceRow
        key={property.id}
        catalogs={catalogs}
        rowSchema={property}
        required={property.required}
        editing={props.editing}
        value={props.item.properties[property.id]}
        onChange={props.onChange}
        onNotListed={props.onNotListed}
      />
    )
  }

  let groupIndex = 0
  return (
    <>
      {renderName()}
      <InstanceFormWrapper editing={props.editing}>
        {props.schema.map((prop) => renderNode(prop, (prop.type === 'group' ? groupIndex++ : groupIndex).toString()))}
      </InstanceFormWrapper>
    </>
  )
}

export default InstanceForm
