import i18n from '../../../translations/i18n'
import router_en from './router_en.json'
import router_ru from './router_ru.json'
import system_en from './system_en.json'
import system_ru from './system_ru.json'

i18n.append([
  { prefix: 'router.', en: router_en, ru: router_ru },
  { prefix: 'system.', en: system_en, ru: system_ru },
])
