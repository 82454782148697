import { memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg'
import styles from '../catalogTemplate.module.css'
import KeyValueTable from '../../../../../../shared/Forms/KeyValueTable/KeyValueTable'
import Loader from '../../../../../../shared/Utils/Loader'
import InstanceRow from '../../../../../Catalogs/components/InstanceForm/InstanceRow'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllCatalogs } from '../../../../../Catalogs/catalogs.selectors'
import TemplateTileControls from './TemplateTileControls'
import { FormMode } from '../../../../../../shared/interfaces'
import useHttpLoader from '../../../../../../hooks/useHttpLoader'
import { Equipment } from 'au-nsi/equipment'
import { catalogsActions } from '../../../../../Catalogs/catalogs.actions'
import { ICatalogItem, IPropertyDescription } from 'au-nsi/catalogs'

interface IProps {
  schema: IPropertyDescription[]
  template: ICatalogItem
  onSelect: (template: ICatalogItem) => void
  deviceId: Equipment['id']
}

/**
 * Компонент карточки шаблона в карте устройств
 *
 * Рендерит заполненную значениями схему, позволяет её выбрать, удалить и редактировать
 */
const TemplateTile = (props: IProps) => {
  const intl = useIntl()
  const { wait, loading } = useHttpLoader()

  const dispatch = useDispatch()
  const catalogs = useSelector(selectAllCatalogs)

  const [draftTemplate, setDraftTemplate] = useState(props.template)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [mode, setMode] = useState<FormMode>('view')

  const handleChange = useCallback((schemaId: IPropertyDescription['id'], value: any) => {
    setDraftTemplate((prev) => ({ ...prev, properties: { ...prev.properties, [schemaId]: value } }))
  }, [])

  const handleDelete = () => {
    wait(dispatch(catalogsActions.deleteInstance(props.template)) as any)
  }

  const handleSave = () => {
    wait(dispatch(catalogsActions.updateInstance(draftTemplate)) as any, () => setMode('view'))
  }

  const renderPreview = () => {
    const pairs = []

    for (let i = 0; i < (detailsOpen ? props.schema.length : 2); ++i) {
      if (props.schema[i]) {
        const Value = (
          <InstanceRow
            editing={mode === 'edit'}
            onChange={handleChange}
            value={draftTemplate.properties[props.schema[i].id]}
            rowSchema={props.schema[i]}
            tableMode={false}
            catalogs={catalogs}
          />
        )
        pairs.push([props.schema[i].name, Value, props.schema[i].id])
      }
    }

    return <KeyValueTable pairs={pairs} keyRowPercentWidth={50} />
  }

  return (
    <div className={styles.template}>
      <dt className={styles.header} style={detailsOpen ? { top: 0 } : undefined}>
        {props.template.name}
        {loading && <Loader className={styles.controlIcon} />}
        {!loading && (
          <TemplateTileControls
            mode={mode}
            onSelect={() => props.onSelect(props.template)}
            onDelete={() => setMode('delete')}
            onDeleteConfirm={handleDelete}
            onEdit={() => setMode('edit')}
            onEditSave={handleSave}
            onCancel={() => setMode('view')}
          />
        )}
      </dt>
      <dd style={{ marginInlineEnd: '40px' }}>{renderPreview()}</dd>
      <span className={styles.details} onClick={() => setDetailsOpen(!detailsOpen)}>
        {intl.formatMessage({ id: 'common.details' })}
        <ArrowRight className={`${styles.arrow} ${detailsOpen ? styles.arrowUp : styles.arrowDown}`} />
      </span>
    </div>
  )
}

export default memo(TemplateTile)
