import React from 'react'
import ModulesPage from '../../System/Modules/ModulesPage'
import DataQualityPage from '../../System/Settings/DataQuality'
import LfoSettings from '../../System/Settings/LfoSettings'
import SmsrSettings from '../../System/Settings/SmsrSettings'
import StorageSettings from '../../System/Storage/StorageSettings'
import { IRoute } from './nav.utils'
import { createVerification } from '../../System/Settings/Verifications/Verification'
import MapsSettings from '../../System/Settings/MapsSettings'
import SystemSettings from '../../System/SystemSettings/SystemSettings'
import AepsSettings from '../../System/Settings/AepsSettings'

const AboutPage = React.lazy(() => import('../../System/About/About'))
const RouterPage = React.lazy(() => import('../../System/Router/RouterEditor'))

const systemRoutes: IRoute[] = [
  { id: 'system.about', path: 'about', component: AboutPage, rights: ['settings:get'] },
  {
    id: 'system.settings',
    path: 'configuration',
    component: SystemSettings,
    rights: ['settings:get'],
  },
  { id: 'system.modules', path: 'modules', component: ModulesPage, rights: ['settings:get_modules'] },
  { id: 'system.storage', path: 'storage', component: StorageSettings, rights: ['settings:get'] },
  {
    id: 'system.dq',
    path: 'dq',
    component: DataQualityPage,
    rights: ['settings:get'],
    modules: ['au-data-quality'],
  },
  {
    id: 'system.router',
    path: 'router',
    component: RouterPage,
    rights: ['settings:get'],
  },
  {
    id: 'system.lfo',
    path: 'lfo',
    component: LfoSettings,
    rights: ['settings:get'],
    modules: ['au-lfo'],
  },
  {
    id: 'system.smsr',
    path: 'smsr',
    component: SmsrSettings,
    rights: ['settings:get'],
    modules: ['au-smsr'],
  },
  {
    id: 'system.au-soti-asso',
    path: 'au-soti-asso',
    component: createVerification('au-soti-asso'),
    rights: ['settings:get'],
    modules: ['au-soti-asso'],
  },
  {
    id: 'system.au-pmu-verification',
    path: 'au-pmu-verification',
    component: createVerification('au-pmu-verification'),
    rights: ['settings:get'],
    modules: ['au-pmu-verification'],
  },
  {
    id: 'system.au-pmu-verification-check',
    path: 'au-pmu-verification-check',
    component: createVerification('au-pmu-verification-check'),
    rights: ['settings:get'],
    modules: ['au-pmu-verification-check'],
  },
  {
    id: 'system.au-aepf-ms',
    path: 'au-aepf-ms',
    component: createVerification('au-aepf-ms'),
    rights: ['settings:get'],
    modules: ['au-aepf-ms'],
  },
  {
    id: 'system.maps',
    path: 'maps',
    component: MapsSettings,
    rights: ['settings:get'],
    modules: ['maps', 'map_topology'],
  },
  {
    id: 'system.aeps',
    path: 'aeps',
    component: AepsSettings,
    rights: ['settings:get'],
    modules: ['aeps-worktime-parser'],
  },
]

export default systemRoutes
