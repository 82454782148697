import { createSelector } from 'reselect'
import { ReduxState } from '../../../redux/store.types'
import { User } from '../collection.interfaces'

/**
 * Получение пользователей сгруппированных по ролям
 */
export const selectGrouppedUsers = createSelector(
  (state: ReduxState) => state.users.items,
  (list) => {
    const users = [...list].sort(usersComparator)
    const result = new Map<number, User[]>()

    for (const user of users) {
      const id = user.role_id
      if (!result.has(id)) result.set(id, [])
      result.get(id).push(user)
    }

    return result
  }
)

/**
 *  Выбрать текущего пользователя
 *  */
export const selectUser = createSelector(
  (state: ReduxState) => state.user,
  (state: ReduxState) => state.users.items,
  (profile, users) => users.find((u) => u.id === profile.id)
)

/**
 * Выбрать организацию текущего пользователя
 * */
export const selectUserOrganization = createSelector(
  (state: ReduxState) => state.organizations.organizations,
  (state: ReduxState) => state.user,
  (organizations, user) => {
    for (const organizationType of organizations) {
      for (const organization of organizationType.instances) {
        if (organization.id === user.organization_id) return organization
      }
    }

    return null
  }
)

/**
 * Сортировка пользователей по статусу: сначала пользователи ожидающие
 * модерации, затем активные, затем деактивированные.
 */
const usersComparator = (a: User, b: User) => {
  const w1 = userWeight(a)
  const w2 = userWeight(b)

  if (w2 !== w1) return w2 - w1
  else if (a.name < b.name) return -1
  else if (a.name > b.name) return 1
  else return 0
}

const userWeight = (user: User) => (user.suspended ? -1 : 1)
