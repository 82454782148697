import React from 'react'

/**
 * Scale canvas to fix blurry text on retina displays
 */
const useCanvasScale = (ref: React.RefObject<HTMLCanvasElement>, width: number, height: number) => {
  React.useEffect(() => {
    const scale = window.devicePixelRatio

    const scaledWidth = Math.floor(width * scale)
    const scaledHeight = Math.floor(height * scale)
    const shouldScale = height !== scaledHeight

    const canvas = ref.current
    const ctx = canvas.getContext('2d')

    canvas.width = scaledWidth
    canvas.height = scaledHeight
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'

    ctx.save()
    if (shouldScale) ctx.scale(scale, scale)

    return () => ctx.restore()
  }, [width, height])
}

export default useCanvasScale
