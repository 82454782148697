import { Equipment } from 'au-nsi/equipment'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { organizationsActions } from '../../../Catalogs/Organizations/organizations.actions'
import InstanceForm from '../../../Catalogs/components/InstanceForm/InstanceForm'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface IProps {
  passport_schema: IPropertyDescription[]
  passport_values: Equipment['passport_values']
  onChange: (schemaId: string, value: any) => void
  editing: boolean
}

/**
 * Форма паспорта устройства.

 * Изо всех сил рекомендуется оборачивать props.onChange в useCallback для коректной мемоизации InstanceRow
*/
const EquipmentPassportForm = (props: IProps) => {
  const dispatch = useDispatch()

  // При заполнении паспорта может потребоваться список организаций (ссылка на организацию)
  useEffect(() => {
    dispatch(organizationsActions.loadOrganizations())
  }, [])

  return (
    <InstanceForm
      editing={props.editing}
      item={{ properties: props.passport_values }}
      propertiesKey={'properties'}
      schema={props.passport_schema}
      onChange={props.onChange}
    />
  )
}

export default EquipmentPassportForm
