import classnames from 'classnames'
import React from 'react'
import { IntlShape } from 'react-intl'
import { ReactComponent as WiFiIcon } from '../../../icons/wifi.svg'
import useFlip from '../../../hooks/useFlip'

const FallbacksItem = (props: Props) => {
  const ref = useFlip([props.index], props.editing).element

  const { index } = props
  const className = classnames('conn-fallbacks__item', { 'is-selected': props.isSelected })

  const handleDragStart = (e) => {
    if (props.editing) {
      e.dataTransfer.setData('text/plain', 'fallback:' + props.index)
      e.dataTransfer.dropEffect = 'move'
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    const data = e.dataTransfer.getData('text/plain')

    if (data.startsWith('fallback:')) {
      e.preventDefault()
      const sourceIndex = +data.split(':')[1]
      props.onSwap(sourceIndex, index)
    }
  }

  const label =
    index === 0
      ? props.intl.formatMessage({ id: 'nsi.connection.main_source' })
      : props.intl.formatMessage({ id: 'nsi.connection.reserve_source' }, { index })

  // иконка для удаления источника, показываем только в режиме редактирования
  const removeIcon = (
    <span data-id={index} onClick={props.onRemove} className="conn-fallbacks__remove">
      &times;
    </span>
  )

  return (
    <div
      className={className}
      data-id={index}
      draggable="true"
      onClick={props.onSelect}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      ref={ref}
    >
      {props.isActive && <WiFiIcon width={16} height={16} style={{ marginRight: '5px' }} />}
      <span>{label}</span>
      {props.editing && index !== 0 && removeIcon}
    </div>
  )
}

interface Props {
  editing: boolean
  index: number
  intl: IntlShape
  isActive: boolean
  isSelected: boolean
  onRemove: React.MouseEventHandler
  onSelect: React.MouseEventHandler
  onSwap: (sourceIndex: number, targetIndex: number) => void
}

export default FallbacksItem
