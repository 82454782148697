import { IGaugeRange } from 'au-nsi/dashboards'

export const colors = {
  info: 'rgb(0,144,255)',
  warning: 'rgb(255,152,87)',
  danger: 'rgb(242,26,81)',
}

const gradients = {
  info: ['rgba(0,144,255,1)', 'rgba(0,144,255,0)'],
  warning: ['rgb(255,152,87,1)', 'rgb(255,152,87,0)'],
  danger: ['rgb(242,26,81,1)', 'rgb(242,26,81,0)'],
}

export const findRange = (ranges: IGaugeRange[], value: number) => {
  for (const range of ranges) {
    if (value >= range.lower && value <= range.upper) {
      return range
    }
  }

  return null
}

/**
 * Определить количество знаков после запятой которые необходимо вывести на подписи к шкале
 * @param valuesRange Интервал выводимых значений
 */
export const getLabelPrecision = (valuesRange: number) => {
  return Math.max(0, -Math.floor(Math.log10(valuesRange)) + 1)
}

const PI2 = Math.PI / 2

/**
 * Отрисовка индикатора датчика в режиме - slider
 */
export const drawArc = (options: IDrawOptions) => {
  const { value, size, ctx } = options
  const center = size / 2
  const r = 0.45 * size
  ctx.lineWidth = Math.round(0.04 * size)

  const angle = options.value2angle(value)

  if (angle > options.startAngle) {
    ctx.strokeStyle = colors.info
    ctx.beginPath()
    ctx.arc(center, center, r, options.startAngle + PI2, angle + PI2)
    ctx.stroke()
  }

  for (const range of options.ranges) {
    if (range.lower >= value) continue

    const start = Math.max(options.startAngle, options.value2angle(range.lower))
    const stop = Math.min(options.stopAngle, angle, options.value2angle(range.upper))
    ctx.strokeStyle = colors[range.type] || range.color
    ctx.beginPath()
    ctx.arc(center, center, r, start + PI2, stop + PI2)
    ctx.stroke()
  }
}

/**
 * Отрисовка индикатора датчика в режиме - arrow
 */
export const drawArrow = (options: IDrawOptions) => {
  const { value, size, ctx } = options
  const center = Math.round(0.5 * size)
  const angle = options.value2angle(value)

  // цвет стрелки в соответствии с тем, в какой зоне находится значение параметра
  const range = findRange(options.ranges, value)
  const color = range ? gradients[range.type] || [range.color, 'rgba(0, 0, 0, 0)'] : gradients.info

  // переход от прозрачного в центре к выбранному цвету на границе
  const gradient = ctx.createRadialGradient(center, center, 0, center, center, center)
  gradient.addColorStop(0, color[1])
  gradient.addColorStop(0.7, color[0])
  ctx.fillStyle = gradient

  // поворот стрелки на нужный угол относительно центра canvas
  ctx.save()
  ctx.translate(center, center)
  ctx.rotate(angle + Math.PI)
  ctx.translate(-center, -center)

  // отрисовка самой стрелки
  ctx.beginPath()
  ctx.moveTo(0.48 * size, 0.5 * size)
  ctx.lineTo(0.49 * size, 0.1 * size)
  ctx.lineTo(0.5 * size, 0.08 * size)
  ctx.lineTo(0.51 * size, 0.1 * size)
  ctx.lineTo(0.52 * size, 0.5 * size)
  ctx.closePath()
  ctx.fill()

  // восстановление системы координат
  ctx.restore()
}

interface IDrawOptions {
  ctx: CanvasRenderingContext2D
  size: number
  value: number
  startAngle: number
  stopAngle: number
  ranges: IGaugeRange[]
  angle2value: (a: number) => number
  value2angle: (v: number) => number
}
