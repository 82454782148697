import { organizationsActions } from '../../organizations.actions'
import { useState } from 'react'
import { createOrganizationType, validateOrganizationType } from '../../organizations.utils'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import { postCreateOrganizationType } from '../../organizations.api'
import { useDispatch } from 'react-redux'
import OrganizationTypeForm from './OrganizationTypeForm/OrganizationTypeForm'
import FormButtons from '../../../../../shared/Forms/FormButtons'
import useOrganizationTypeCallbacks from './useOrganizationTypeCallbacks'
import { showError } from '../../../../../utils/notifications'
import Form from '../../../../../shared/Forms/Form'
import { useNavigate } from 'react-router-dom'

const OrganizationTypeFormCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, wait } = useHttpLoader()

  const [draftOrganization, setDraftOrganization] = useState(createOrganizationType())

  const organizationTypesCallbacks = useOrganizationTypeCallbacks({ setter: setDraftOrganization, deps: [] })

  const handleSave = async () => {
    if (!validateOrganizationType(draftOrganization)) return showError('common.validation_error')
    const req = postCreateOrganizationType(draftOrganization)

    wait(req, (resp) => {
      if (!resp) return navigate(-1)

      dispatch(organizationsActions.organizationTypeCreated(resp))
      navigate('/catalogs/organizations/' + resp.id)
    })
  }

  return (
    <Form editing={true} onSubmit={handleSave}>
      <OrganizationTypeForm
        editing={true}
        organizationType={draftOrganization}
        onMetaChange={organizationTypesCallbacks.handleMetaChange}
        onChange={organizationTypesCallbacks.handleChange}
        onMove={organizationTypesCallbacks.handleMove}
        onRemove={organizationTypesCallbacks.handleRemoveProperty}
        onCreateProperty={organizationTypesCallbacks.handleCreateProperty}
      />
      <FormButtons
        mode={'create'}
        onEdit={null}
        onEditSave={null}
        onCancel={() => navigate(-1)}
        allowEditing={true}
        allowDeleting={false}
        isLoading={loading}
      />
    </Form>
  )
}

export default OrganizationTypeFormCreate
