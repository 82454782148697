import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICommand, ICommandType } from 'au-nsi/commands'
import { orderedInsert, orderedUpdate } from '../../redux/reducer.utils'

export interface CommandsState {
  isLoaded: boolean
  commands: ICommand[]
  command_types: ICommandType[]
}

const initialState: CommandsState = {
  isLoaded: false,
  commands: [],
  command_types: [],
}

const slice = createSlice({
  name: 'app/commands',
  initialState,
  reducers: {
    commandsLoaded: (state, action: PayloadAction<{ commands: ICommand[]; command_types: ICommandType[] }>) => {
      state.command_types = action.payload.command_types
      state.commands = action.payload.commands
      state.isLoaded = true
    },
    commandCreated: (state, action: PayloadAction<ICommand>) => {
      const command = action.payload
      if (state.isLoaded && !state.commands.find((c) => c.id === command.id)) {
        orderedInsert(state.commands, command)
      }
    },
    commandTypeCreated: (state, action: PayloadAction<ICommandType>) => {
      const type = action.payload
      if (state.isLoaded && !state.command_types.find((c) => c.id === type.id)) {
        orderedInsert(state.command_types, type)
      }
    },
    commandUpdated: (state, action: PayloadAction<ICommand>) => {
      if (state.isLoaded) {
        orderedUpdate(state.commands, action.payload)
      }
    },
    commandTypeUpdated: (state, action: PayloadAction<ICommandType>) => {
      if (state.isLoaded) {
        orderedUpdate(state.command_types, action.payload)
      }
    },
    commandDeleted: (state, action: PayloadAction<{ id: number }>) => {
      const index = state.commands.findIndex((c) => c.id === action.payload.id)
      if (index !== -1) state.commands.splice(index, 1)
    },
    commandTypeDeleted: (state, action: PayloadAction<{ id: number }>) => {
      const index = state.command_types.findIndex((c) => c.id === action.payload.id)

      if (index !== -1) {
        state.command_types.splice(index, 1)
        state.commands = state.commands.filter((c) => c.type !== action.payload.id)
      }
    },
  },
})

export const commandsReducer = slice.reducer
export const commandsSlice = slice
