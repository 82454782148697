import './radio.styles.css'

const RadioButton = (props: RadioButtonProps) => {
  return (
    <label className="radio-btn">
      <input type="radio" onChange={props.onChange} data-id={props.id} checked={props.checked} />
      <span className="radio-btn__checkmark" />
    </label>
  )
}

interface RadioButtonProps {
  id: string | number
  checked: boolean
  onChange: (e: any) => void
}

export default RadioButton
