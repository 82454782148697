import { memo, useRef, useState } from 'react'
import clock from '../../services/clock/clock.factory'
import SavedTemplatesModal from '../../shared/SavedTemplates/SavedTemplatesModal'
import { PlayerBookmark } from '../../shared/SavedTemplates/templates.types'

/**
 * Модальное окно для управления закладками
 */
const BookmarksManager = () => {
  const newBookmarkData = useRef<PlayerBookmark['data']>()

  // менеджер открывается при клике на svg иконку
  const [isOpen, setOpen] = useState(false)

  // при клике на закладку - переход к выбранному моменту времени
  const handleSelect = (b: PlayerBookmark) => {
    clock.setReplay()
    clock.setPlayerTime(b.data.ts)
    clock.setFrame(b.data.frame)
    setOpen(false)
  }

  const handleOpen = () => {
    newBookmarkData.current = { ts: clock.getPlayerTime(), frame: clock.getFrame() }
    setOpen(true)
  }

  return (
    <>
      <svg className="chart-player__bookmarks-icon" viewBox="0 0 384 512" onClick={handleOpen}>
        <path
          fill="currentColor"
          d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
        ></path>
      </svg>

      {isOpen && (
        <SavedTemplatesModal
          type="player_bookmarks"
          newTemplateData={newBookmarkData.current}
          onSelect={handleSelect}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default memo(BookmarksManager)
