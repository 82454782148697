import { useIntl } from 'react-intl'
import ChartsList, { ChartListData } from '../../shared/LineCharts/ChartsList'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import { Equipment } from 'au-nsi/equipment'

const PowerCharts = (props: Props) => {
  const intl = useIntl()
  const { device } = props

  const generateSettings = (title: string, parameter_id: string) => {
    const lines = device ? [{ device_id: device.id, parameter_id, color: device.color }] : []
    return { title: intl.formatMessage({ id: title }), lines }
  }

  const charts: ChartListData[] = [
    { id: RESERVED_CHART_IDS.POWER, settings: generateSettings('Power.power.active', 'PowerActiveTriPhase') },
    { id: RESERVED_CHART_IDS.POWER + 1, settings: generateSettings('Power.power.reactive', 'PowerReactiveTriPhase') },
  ]

  return (
    <div style={{ width: '50%' }}>
      <ChartsList showAsGrid={false} charts={charts} />
    </div>
  )
}

interface Props {
  device: Equipment
}

export default PowerCharts
