/**
 * Drag and Drop utils - утилиты для обработки перетаскивания элементов топологии
 */
import http, { handleHttpError } from '../../../utils/http'
import * as actions from '../nsi.actions'
import { NsiState } from '../nsi.interfaces'

/**
 * action для обработки перемещения элемента source на место target
 */
export const handleDropAction = (source: string, target: string) => (dispatch, getState) => {
  if (source === target) return

  const [sourceType, sourceId] = source.split(':')
  const [targetType, targetId] = target.split(':')

  if (sourceType === 'device' && targetType === 'device') {
    device2device(sourceId, targetId, dispatch, getState)
  } else if (sourceType === 'device' && targetType === 'region') {
    device2region(sourceId, +targetId, dispatch)
  } else if (sourceType === 'region' && targetType === 'region') {
    region2region(+sourceId, +targetId, dispatch, getState)
  } else if (sourceType === 'region' && targetType === 'device') {
    region2device(+sourceId, targetId, dispatch, getState)
  }
}

// перемещение устройства на место другого устройства
const device2device = (sourceId: string, targetId: string, dispatch, getState) => {
  const { equipment } = getState().nsi as NsiState
  const target = equipment.find((e) => e.id === targetId)

  // изменение региона, если устройство target находится в другой ветке
  dispatch(actions.patchEquipment(sourceId, { region_id: target.region_id }))

  // изменение позиций устройств
  const devices = equipment.map((e) => ({ id: e.id, user_ordering_index: e.user_ordering_index }))
  devices.sort((a, b) => a.user_ordering_index - b.user_ordering_index)

  const sourceIndex = devices.findIndex((d) => d.id === sourceId)
  const targetIndex = devices.findIndex((d) => d.id === targetId)

  const updates = [{ id: sourceId, user_ordering_index: devices[targetIndex].user_ordering_index }]

  // при перемещении устройства назад последовательно сдвигаем вперед все элементы от targetIndex до sourceIndex
  // при перемещении вперед наоборот идем обратно от targetIndex до sourceIndex и сдвигаем все элементы на позицию назад
  const step = sourceIndex > targetIndex ? 1 : -1

  for (let i = targetIndex; i * step < sourceIndex * step; i += step) {
    updates.push({ id: devices[i].id, user_ordering_index: devices[i + step].user_ordering_index })
  }

  dispatch(actions.equipmentBatchUpdated(updates))
  http.post('/nsi/v1/equipment/order', { equipment: updates }).catch(handleHttpError)
}

// перемещение устройства в регион - просто меняем region_id на нужный
const device2region = (sourceId: string, targetId: number, dispatch) => {
  dispatch(actions.patchEquipment(sourceId, { region_id: targetId }))
}

// перемещение региона в регион - делаем source дочерним элементом target
const region2region = (sourceId: number, targetId: number, dispatch, getState) => {
  const { regions } = getState().nsi as NsiState
  const source = regions.find((r) => r.id === sourceId)
  const target = regions.find((r) => r.id === targetId)

  const targetPath = target.path.split('.').map((id) => +id)

  // проверка на некорректные операции - перемещение региона в самого себя или в один
  // из его дочерних регионов
  const isMoveToSubtree = targetPath.includes(sourceId)
  const isMoveToSelf = target.path + '.' + sourceId === source.path

  if (!isMoveToSubtree && !isMoveToSelf) {
    dispatch(actions.moveRegion({ source_path: source.path, destination_path: target.path }))
  }
}

// перемещение региона в устройство - то же самое что перемещение в родительский регион этого устройства
const region2device = (sourceId: number, targetId: string, dispatch, getState) => {
  const { equipment } = getState().nsi as NsiState
  const targetDevice = equipment.find((e) => e.id === targetId)

  region2region(sourceId, targetDevice.region_id, dispatch, getState)
}
