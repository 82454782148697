import { MaskedInput } from '../MaskedInput/MaskedInput'
import { dateFormat, dateMask, dateRegex, dateTimeFormat, dateTimeMask, dateTimeRegex } from './datepicker.utils'
import { formatDate } from '../../../utils/lang'
import { parse } from 'date-fns'
import { CSSProperties, useRef } from 'react'
import { Calendar } from './Calendar/Calendar'
import TimePicker from './TimePicker'
import './calendar.styles.css'
import { createPortal } from 'react-dom'
import usePopup from '../../../hooks/usePopup'
import { daysCount } from './Calendar/utils'
import SmoothPanel from '../../Utils/SmoothPanel/SmoothPanel'

interface IProps {
  date?: string | null | undefined // dd.mm.yyyy
  onDateChange?: (date: string, name?: string) => void
  time?: number | null | undefined
  name?: string
  onTimeChange?: (time: number, name?: string) => void
  wrapperClass?: string
  inputClass?: string
  renderTime?: boolean
  inPortal?: boolean
  disabled?: boolean
  allowFutureSelect?: boolean
}

/**
  Компонент выбора даты.
  В зависимости от пропсов может возвращать ts выбранного времени, либо оперировать строками в формате dd.MM.yyyy
*/
const Datepicker = ({
  inputClass,
  renderTime,
  date,
  time,
  name,
  onTimeChange,
  onDateChange,
  wrapperClass,
  inPortal,
  disabled,
  allowFutureSelect = false,
}: IProps) => {
  const mask = renderTime === false ? dateMask : dateTimeMask
  const regex = renderTime === false ? dateRegex : dateTimeRegex
  const format = renderTime === false ? dateFormat : dateTimeFormat

  const componentRef = useRef<HTMLDivElement>(null)

  // календарь может рендериться в портале, поэтому стандартный метод
  // определения клика вне компонента не работает
  const isClickOutside = (e: HTMLElement) => {
    const wrapper = document.querySelector('.calendar-wrapper')
    return !wrapper.contains(e) && !componentRef.current.contains(e)
  }

  const { open, setOpen } = usePopup(componentRef, { isOutside: isClickOutside })

  const handleInputChange = (e) => {
    const value = e.target.value
    if (!regex.test(value)) return

    const ts = parse(value, format, new Date()).valueOf()
    if (!allowFutureSelect && ts > Date.now()) return

    if (onTimeChange) onTimeChange(ts, name)
    if (onDateChange) onDateChange(value, name)
  }

  const handleCalendarChange = (ts: number) => {
    if (!allowFutureSelect && ts > Date.now()) return

    if (onTimeChange) onTimeChange(ts, name)
    if (onDateChange) onDateChange(formatDate(ts, format), name)
  }

  const renderCalendar = () => {
    let ts: number
    if (!date && !time) {
      ts = new Date().valueOf()
    } else {
      ts = time || parse(date, format, new Date()).valueOf()
    }

    const style: CSSProperties = {}
    if (inPortal) {
      const bbox = componentRef.current.getBoundingClientRect()

      style.left = bbox.left + 'px'
      style.top = bbox.top + 40 + 'px'
      style.zIndex = 9999
    }

    const component = (
      <div className="calendar-wrapper" style={style}>
        <Calendar onChange={handleCalendarChange} ts={ts} allowFutureSelect={allowFutureSelect} />
        {renderTime !== false && <TimePicker ts={ts} onChange={handleCalendarChange} />}
      </div>
    )

    if (inPortal) return createPortal(component, document.getElementsByTagName('body')[0])
    return component
  }

  const input = (
    <div ref={componentRef} className={wrapperClass} style={{ position: 'relative' }}>
      <MaskedInput
        preprocess={preprocess}
        mask={mask}
        className={inputClass || 'nsi-input'}
        value={getDateValue(time, date, regex, format)}
        onChange={handleInputChange}
        onClick={() => setOpen(true)}
        disabled={disabled}
      />
      {inPortal && open && renderCalendar()}
    </div>
  )

  if (inPortal) return input

  return <SmoothPanel open={open} icon={input} panelContent={renderCalendar()} transformOrigin={'top left'} />
}

// Метод, принимающий nullable ts и строку date. Возвращает строку для masked input
const getDateValue = (ts: number, date: string, reg: RegExp, format: string): string => {
  if (!ts && !date) return ''

  if (ts) return formatDate(ts, format)

  if (!reg.test(date)) return ''

  return date
}

const preprocess = (date: string): string => {
  const split = date.slice(0, 10).split('.')

  const year = parseInt(split[2])
  const month = parseInt(split[1])
  const day = parseInt(split[0])

  let monthStr
  if (split[1] === '00') monthStr = '01'
  else if (month > 12) monthStr = '12'
  else monthStr = split[1]

  let dayStr
  const maxDays = isNaN(month) ? 31 : daysCount(month - 1, year || 2019)
  if (split[0] === '00') dayStr = '01'
  else if (day > maxDays) dayStr = maxDays.toString()
  else dayStr = split[0]

  return `${dayStr}.${monthStr}.${year ? year.toString().padEnd(4, '_') : '____'}` + date.slice(10)
}

export default Datepicker
