import classnames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PageMode } from '../interfaces'

import './HistoryToggle.styles.css'

class HistoryToggle extends React.PureComponent<Props> {
  private selectHistory = () => {
    this.props.onChange('history')
  }

  private selectValues = () => {
    this.props.onChange('values')
  }

  render() {
    const { mode } = this.props
    const historyClass = classnames('history-toggle__btn', { 'is-selected': mode === 'history' })
    const valuesClass = classnames('history-toggle__btn', { 'is-selected': mode === 'values' })

    return (
      <div className="history-toggle">
        <div className={historyClass} onClick={this.selectHistory}>
          <FormattedMessage id="ChartPlayer.history" />
        </div>
        <div className={valuesClass} onClick={this.selectValues}>
          <FormattedMessage id="ChartPlayer.values" />
        </div>
      </div>
    )
  }
}

interface Props {
  mode: PageMode
  onChange: (mode: PageMode) => void
}

export default HistoryToggle
