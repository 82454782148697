import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { FormContext } from 'shared/Forms/Form'
import Loader from '../Utils/Loader'

const ModalFooter = (props: Props) => {
  const intl = useIntl()
  const ctx = useContext(FormContext)

  const saveMessage = props.saveMessage || intl.formatMessage({ id: 'common.save' })
  const saveStyle = props.saveStyle || 'default'
  const saveClass = `nsi-button ${saveStyle}`

  const disabled = Boolean(props.error) || props.disabled

  const handleSave = ctx.form || props.loading || disabled ? undefined : props.onSave

  return (
    <React.Fragment>
      <div className="app-modal__error">{props.error}</div>
      <div className="app-modal__warning">{props.warning}</div>

      <div className="app-modal__footer">
        <button type="button" className="nsi-button inverted" onClick={props.onCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </button>
        <button type="submit" className={saveClass} onClick={handleSave} disabled={disabled}>
          {props.loading ? <Loader /> : saveMessage}
        </button>
      </div>
    </React.Fragment>
  )
}

interface Props {
  error?: string
  warning?: string
  loading?: boolean
  disabled?: boolean
  onCancel: () => void
  onSave: () => void
  saveMessage?: string
  saveStyle?: 'default' | 'danger'
}

export default ModalFooter
