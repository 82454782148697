import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ITableColumnSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import UnitSelector from '../../Parameters/UnitSelector'
import NumberFormatSettings from '../common/NumberFormatSettings'

const TableSettingsRow = (props: Props) => {
  const { column } = props
  const parameter = props.parameterMap.get(column.parameter_id)

  const handleTitleChange = (e) => {
    const title = e.target.value
    props.onChange(column.column_index, { ...column, title })
  }

  // при изменении параметра выставить единицу отображения указанную в выбранном параметре
  const handleParameterChange = (parameter_id: string) => {
    const { display_unit, display_prefix } = props.parameterMap.get(parameter_id)
    props.onChange(column.column_index, { ...column, parameter_id, display_unit, display_prefix })
  }

  const handleUnitChange = (display_unit, display_prefix) => {
    props.onChange(column.column_index, { ...column, display_unit, display_prefix })
  }

  const handleFormatChange = (number_format) => {
    props.onChange(column.column_index, { ...column, number_format })
  }

  const handleRemove = () => props.onRemove(column.column_index)

  return (
    <tr>
      <td>{column.column_index + 1}</td>
      <td>
        <input className="nsi-input" value={column.title} onChange={handleTitleChange} />
      </td>
      <td>
        <Select name="" options={props.parameterOptions} value={column.parameter_id} onChange={handleParameterChange} />
      </td>
      <td>
        <UnitSelector
          onChange={handleUnitChange}
          prefixes={props.prefixes}
          referenceUnit={parameter?.unit}
          selectedPrefix={column.display_prefix}
          selectedUnit={column.display_unit}
          units={props.units}
        />
      </td>
      <td>
        <NumberFormatSettings settings={column.number_format} onChange={handleFormatChange} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

interface Props {
  column: ITableColumnSettings
  onChange: (index: number, column: ITableColumnSettings) => void
  onRemove: (index: number) => void
  parameterOptions: SelectOption[]
  parameterMap: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  units: { [id: string]: SiUnit }
}

export default React.memo(TableSettingsRow)
