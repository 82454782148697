import { createPropertySchema } from '../../catalogs.utils'
import { useCallback } from 'react'
import produce from 'immer'
import { deepCopy } from '../../../../utils/misc'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface IProps<T> {
  treeKey: keyof T
  setter: (state: ((prevState: T) => T) | T) => void
  deps: Array<unknown>
}

interface PropObject {
  [key: string]: any
}

/**
 * Генератор колбеков для управления состоянием объека с массивом IPropertyDescription по ключу {treeKey}
 */
const useCatalogSchemaCallbacks = <T extends PropObject>(props: IProps<T>) => {
  /**
   * Создать property с типом type в группе group. Если группа не указана, property создаётся в руте.
   * */
  const handleCreate = useCallback(
    (groupId?: IPropertyDescription['id'], type: IPropertyDescription['type'] = 'string') => {
      if (!groupId) {
        return props.setter((prev) => {
          return {
            ...prev,
            [props.treeKey]: [
              ...(prev[props.treeKey] as unknown as IPropertyDescription[]),
              createPropertySchema(type),
            ],
          }
        })
      }

      props.setter((prev) => {
        return produce(prev, (draft: T) => {
          const foundGroup = findProperty(groupId, draft[props.treeKey] as unknown as IPropertyDescription[])
          if (!foundGroup) return

          foundGroup.children.push(createPropertySchema(type))
        })
      })
    },
    props.deps
  )

  /**
   * Обновить property {updated}
   */
  const handleUpdate = useCallback((updated: IPropertyDescription) => {
    props.setter((prev) => {
      return produce(prev, (draft: T) => {
        const found = findProperty(updated.id, draft[props.treeKey] as unknown as IPropertyDescription[])
        if (!found) return

        Object.keys(updated).forEach((key) => {
          found[key] = updated[key]
        })
      })
    })
  }, props.deps)

  /**
   * Удалить из дерева property с указанным id
   */
  const handleRemove = useCallback((id: IPropertyDescription['id']) => {
    props.setter((prev) => {
      const clean = (arr: IPropertyDescription[]) => {
        return arr
          .filter((prop) => prop.id !== id)
          .map((prop) => (prop.type === 'group' ? { ...prop, children: clean(prop.children) } : prop))
      }

      return { ...prev, [props.treeKey]: clean(prev[props.treeKey]) }
    })
  }, props.deps)

  /**
   * Поставить property с id равным {from} перед property с id равным {to}.
   * Метод работает как для перемещений в рамках одной группы, так и между ними.
   */
  const handleMove = useCallback((from: IPropertyDescription['id'], to: IPropertyDescription['id']) => {
    if (from === to) return

    props.setter((prev) => {
      return produce(prev, (draft: T) => {
        const toArray = findProperty(to, draft[props.treeKey], true)
        const fromArray = findProperty(from, draft[props.treeKey], true)
        if (!fromArray || !toArray) return

        // найдём элемент, который нужно переместить
        const fromPropertyInd = fromArray.findIndex((p) => p.id === from)
        if (fromPropertyInd === -1) return

        // проверим кейс, в котором группу перетаскивают саму в себя
        if (fromArray[fromPropertyInd].type === 'group' && findProperty(to, fromArray[fromPropertyInd].children)) {
          return
        }

        // Создадим копию перемещаемого элемента и удалим его из массива
        const fromCopy = deepCopy(fromArray[fromPropertyInd])

        fromArray.splice(fromPropertyInd, 1)

        // найдём индекс элемента, к которому треубуется перенести {fromCopy}, после чего вставим его в массив
        const toPropertyInd = toArray.findIndex((p) => p.id === to)
        if (fromPropertyInd === -1 || toPropertyInd === -1) return

        toArray.splice(toPropertyInd, 0, fromCopy)
      })
    })
  }, props.deps)

  /**
   * Вставить элемент с {propertyId} в группу с {groupId}
   * */
  const handleInsertIntoGroup = useCallback(
    (groupId: IPropertyDescription['id'], propertyId: IPropertyDescription['id']) => {
      if (groupId === propertyId) return

      props.setter((prev) => {
        return produce(prev, (draft: T) => {
          const group = findProperty(groupId, draft[props.treeKey])
          if (!group) return

          const propertyArray = findProperty(propertyId, draft[props.treeKey], true)
          if (!propertyArray) return

          const propertyIndex = propertyArray.findIndex((prop) => prop.id === propertyId)

          // Проверка кейса, в котором родителя вкладывают в ребёнка
          if (
            propertyArray[propertyIndex].type === 'group' &&
            findProperty(groupId, propertyArray[propertyIndex].children)
          ) {
            return
          }

          const propertyCopy = deepCopy(propertyArray[propertyIndex])
          propertyArray.splice(propertyIndex, 1)

          group.children.splice(0, 0, propertyCopy)
        })
      })
    },
    []
  )

  return { handleCreate, handleUpdate, handleRemove, handleMove, handleInsertIntoGroup }
}

/**
 * DFS для поиска property с указанным {id} в {tree}.
 */
const findProperty = (id: IPropertyDescription['id'], tree: IPropertyDescription[], returnParent: boolean = false) => {
  for (const property of tree) {
    if (property.id === id) {
      if (returnParent) return tree
      return property
    }

    if (property.type === 'group') {
      const result = findProperty(id, property.children, returnParent)
      if (result) return result
    }
  }

  return null
}

export default useCatalogSchemaCallbacks
