import { FormattedMessage } from 'react-intl'
import CollapseToggle from '../Utils/CollapseToggle'
import './panel.styles.css'
import SmoothDropdown from '../Utils/SmoothDropdownBehaivor/SmoothDropdown'

const FormPanel = (props: Props) => {
  const handleClick = (e) => {
    e.stopPropagation()
    props.onToggle(props.id)
  }

  return (
    <div className="nsi-panel" style={props.minified && { borderRadius: '5px', marginTop: '5px' }}>
      <div
        className="nsi-panel__title"
        onClick={handleClick}
        style={props.minified && { padding: '10px', fontSize: '16px' }}
      >
        <CollapseToggle size={18} collapsed={!props.open} onToggle={handleClick} />
        <span style={{ marginLeft: '0.5em' }}>
          <FormattedMessage id={props.title} />
        </span>
      </div>
      <SmoothDropdown overflow={'visible'} fillMode={'auto'} open={props.open}>
        {props.children}
      </SmoothDropdown>
    </div>
  )
}

interface Props {
  id: string
  open: boolean
  title: string
  onToggle: (id: string) => void
  children?: any
  minified?: boolean
}

export default FormPanel
