import React from 'react'
import { IntlShape } from 'react-intl'
import Dropdown, { DropdownOption } from '../../Inputs/Dropdown'
import InputRow from '../../Inputs/InputRow'
import OptionalNumberInput from './OptionalNumberInput'

/**
 * Настройки заливки графика
 */
const FillOptions = (props: Props) => {
  const showFillBase = props.fill === true && props.unitsInfo.baseUnit != null

  return (
    <React.Fragment>
      <InputRow label={props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.fill' })}>
        <Dropdown name="fill" value={props.fill || false} options={props.options} onChange={props.onChange} />
      </InputRow>

      {showFillBase && (
        <OptionalNumberInput
          name="fillBase"
          value={props.fillBase}
          onChange={props.onChange}
          label="dashboards.linear_chart.settings.axes.fillBase"
          labelForUndefined="dashboards.linear_chart.settings.axes.fill.auto"
          labelForValue="dashboards.linear_chart.settings.axes.fill.value"
          {...props.unitsInfo}
        />
      )}
    </React.Fragment>
  )
}

interface Props {
  fill: boolean
  fillBase: number
  intl: IntlShape
  onChange: (v: any, key: string) => void
  options: DropdownOption<boolean>[]
  unitsInfo: any
}

export default React.memo(FillOptions)
