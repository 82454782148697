import useLPSettingsLoad from './useLPSettingsLoad'
import Lp001SettingsForm from './Lp001SettingsForm'
import { Equipment } from 'au-nsi/equipment'
import { LoaderDots } from '@alterouniversal/au-react-components'

interface IProps {
  device: Equipment
}

const LP001SettingsControl = ({ device }: IProps) => {
  const { error, loading } = useLPSettingsLoad()

  if (error) return <span className="text--danger">{error}</span>
  if (loading) return <LoaderDots style={{ marginTop: '2em' }} />

  return <Lp001SettingsForm device={device} />
}

export default LP001SettingsControl
