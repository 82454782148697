import LogbookRequestForm, { ILogbookRequestFormProps } from './LogbookRequestForm'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import { useEffect, useState } from 'react'
import Loader from '../../../../../../shared/Utils/Loader'
import useHttpLoader from '../../../../../../hooks/useHttpLoader'
import { getLogbookEntry } from '../../../../../MapTopology/DeviceScreen/DeviceLogbook/logbooks.api'
import { useRecoilValue } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import { ILogbookEntry } from 'au-nsi/logbook'
import { EquipmentLogbookRequest } from 'au-nsi/moderation'

/**
 * Компнент-обёртка для LogbookRequestForm.
 * Загружает нужный logbookEntry и прокидывает его в пропсы.
 */
const LogbookRequestFormLoader = (props: Omit<ILogbookRequestFormProps, 'logbookEntry'>) => {
  const request = useRecoilValue<EquipmentLogbookRequest>(selectedDraftRequestState as any)
  const logbookEntryId = request.request_body?.id ?? extractIntIdFromUrl(request.url)

  const { wait, loading } = useHttpLoader()
  const [loaded, setLoaded] = useState<ILogbookEntry>(null)

  useEffect(() => {
    if (request.method === 'POST') return setLoaded(request.request_body)
    if (!logbookEntryId) return

    wait(getLogbookEntry(logbookEntryId), (r) => setLoaded(r))
  }, [logbookEntryId])

  if (loading) return <Loader />
  if (!loaded) return <div className="text--danger">404</div>

  return <LogbookRequestForm {...props} logbookEntry={loaded} />
}

export default LogbookRequestFormLoader
