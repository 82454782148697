import { NotificationSettings } from 'au-nsi/notifications'
import { Lang } from 'au-nsi/shared'
import { UserPreferences } from 'au-nsi/user'
import { ReduxState } from '../../redux/store.types'
import { DEFAULT_LOCALE } from '../../shared/constants'
import http, { handleHttpError } from '../../utils/http'
import usersActions from '../Collections/Users/users.actions'
import { selectPreferences } from './app.selectors'
import { CurrentUserState, SET_LANG, SET_USER, UPDATE_USER, UPDATE_USER_NTF } from './user.reducers'

export const setUser = (user: CurrentUserState) => (dispatch, getState) => {
  if (!user.lang) user.lang = DEFAULT_LOCALE
  const state = getState() as ReduxState

  dispatch({ type: SET_USER, payload: user })

  if (state.user.lang !== user.lang) {
    dispatch({ type: SET_LANG, payload: user.lang })
  }
}

export const setLanguage = (lang: Lang) => async (dispatch, getState) => {
  const state = getState() as ReduxState

  dispatch({ type: SET_LANG, payload: lang })

  if (state.auth.status === 'logged_in') {
    await http.post('/nsi/user/lang', { lang }).catch(handleHttpError)
    dispatch(usersActions.itemUpdated(state.user.id, { lang }))
  }
}

export const updatePreferences = (updates: Partial<UserPreferences>) => (dispatch, getState) => {
  const state = getState() as ReduxState
  const preferences = selectPreferences(state)

  dispatch({ type: UPDATE_USER, payload: { preferences: { ...preferences, ...updates } } })

  http.post('/nsi/user/preferences', { ...preferences, ...updates }).catch((err) => {
    handleHttpError(err)
    dispatch({ type: UPDATE_USER, payload: { preferences } })
  })
}

// изменение настроек уведомлений текущего пользователя
export const updateUserNtfSettings = (updates: NotificationSettings) => (dispatch, getState) => {
  const state = getState() as ReduxState

  if (state.user.id === updates.id) {
    dispatch({ type: UPDATE_USER_NTF, payload: updates })
  }
}

// изменение настроек уведомлений роли текущего пользователя
export const updateRoleNtfSettings = (updates: NotificationSettings) => (dispatch, getState) => {
  const state = getState() as ReduxState

  if (state.user.use_role_ntf_settings && state.user.role_id === updates.id) {
    dispatch({ type: UPDATE_USER_NTF, payload: updates })
  }
}

export const updateUser = (updates: Partial<CurrentUserState>) => ({ type: UPDATE_USER, payload: updates })
