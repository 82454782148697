import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import settingsActions from '../../pages/System/Settings/settings.actions'
import { useEffect, useState } from 'react'
import { selectLanguage } from '../../pages/App/app.selectors'
import { showError } from '../../utils/notifications'

// https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font
const insertBefore = document.head.insertBefore
document.head.insertBefore = ((newElement: HTMLLinkElement, referenceElement) => {
  if (newElement.href && newElement.href.indexOf('fonts.googleapis.com/css?family=Roboto') > -1) return
  insertBefore.call(document.head, newElement, referenceElement)
}) as any

export const useGoogleMapsLoader = () => {
  const dispatch = useDispatch()
  const locale = useSelector(selectLanguage)

  const [isLoaded, setIsLoaded] = useState(false)
  const { needLoad, mapsSettings } = useSelector((state: ReduxState) => ({
    needLoad:
      state.modules.find((m) => m.id === 'maps')?.enabled ||
      state.modules.find((m) => m.id === 'map_topology')?.enabled,
    mapsSettings: state.service_settings['maps'],
  }))

  useEffect(() => {
    if (needLoad) dispatch(settingsActions.loadMapSettings())
  }, [needLoad])

  useEffect(() => {
    if (mapsSettings && !mapsSettings.front_api_key) {
      showError('errors.empty_api_key')
    }

    if (!mapsSettings?.front_api_key) return

    if (document.getElementById('google_maps_script')) {
      setIsLoaded(true)
      return
    }

    // inserting load script
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?language=${locale}&libraries=geometry&key=${mapsSettings.front_api_key}`
    script.async = true
    script.defer = true
    script.id = 'google_maps_script'
    script.onload = () => setIsLoaded(true)

    document.head.appendChild(script)
  }, [needLoad, mapsSettings])

  if (!needLoad || !mapsSettings?.front_api_key) return false

  return isLoaded && window?.google
}
