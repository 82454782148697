import { useEffect } from 'react'
import { refreshToken } from '../../utils/http'
import './sorry_page.css'
import { useIntl } from 'react-intl'
import { LoaderDots } from '@alterouniversal/au-react-components'

export const SorryPage = () => {
  const itnl = useIntl()

  useEffect(() => {
    const intr = setInterval(async () => {
      await refreshToken()
    }, 5000)
    return () => clearInterval(intr)
  }, [])

  return (
    <div className={'sorry-page'}>
      <LoaderDots style={{ marginBottom: '50px' }} />
      <h2>{itnl.formatMessage({ id: 'common.server_sorry' })}</h2>
    </div>
  )
}
