import { TNode } from './tree.types'
import { AnyObject } from 'common'
import { ITreeControls } from './useTree'
import { FocusEvent, useRef } from 'react'
import { KeyboardEvent } from 'react'
import nodeIdService from './nodeId.service'
import TreeNode from './TreeNode'
import useAutoExpand from './useAutoExpand'
import './topology.styles.css'

interface TreeProps<T extends AnyObject> extends ITreeControls<T> {
  nodes: TNode<T>[]
  selectedNodeId: T['id']
  nodeContentRenderer: (node: { node: T }) => JSX.Element | JSX.Element[] | string | null

  leafClassName?: string
  leafActiveClassName?: string
  branchClassName?: string
  branchNameClassName?: string
  branchNameActiveClassName?: string
}

const Tree = <T extends AnyObject>(props: TreeProps<T>) => {
  useAutoExpand(props.nodes, props.selectedNodeId, props.openedNodes, props.setOpenedNodes)

  const tabIndex = useRef(1)
  const tabIndexMap = new Map<number, T['id']>()
  tabIndex.current = 1

  const handleKeyDown = (e: KeyboardEvent<HTMLLIElement> | KeyboardEvent<HTMLSpanElement>) => {
    if (e.ctrlKey || e.altKey || e.shiftKey || !props.handleSelect) return

    const id = nodeIdService.decodeId(e.currentTarget.dataset.id) as T['id']

    // Переключение между нодами стрелочками
    const index = parseInt((e.target as HTMLLIElement).getAttribute('tabindex'))
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let nextIndex = e.key === 'ArrowDown' ? index + 1 : index - 1
      if (nextIndex < 1) nextIndex = tabIndexMap.size
      if (nextIndex > tabIndexMap.size) nextIndex = 1

      props.handleSelect(tabIndexMap.get(nextIndex))
      e.preventDefault()
    }

    // Тогл при нажатии стрелок влево/вправо
    if (e.key === 'ArrowLeft') {
      e.preventDefault()
      props.handleToggle(id, false)
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault()
      props.handleToggle(id, true)
    }
  }

  const handleSelect = (e: FocusEvent<HTMLElement>) => {
    if (props.handleSelect) {
      const id = nodeIdService.decodeId(e.currentTarget.dataset.id) as T['id']
      props.handleSelect(id)
    }
  }

  return (
    <ul role="tree" style={{ listStyleType: 'none' }}>
      {props.nodes.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          onKeyDown={handleKeyDown}
          onSelect={handleSelect}
          onToggle={props.handleToggle}
          nodeContentRenderer={props.nodeContentRenderer as any}
          tabIndex={tabIndex}
          tabIndexMap={tabIndexMap}
          openedNodes={props.openedNodes}
          selectedNodeId={props.selectedNodeId}
          branchClassName={props.branchClassName}
          branchNameClassName={props.branchNameClassName}
          branchNameActiveClassName={props.branchNameActiveClassName}
          leafClassName={props.leafClassName}
          leafActiveClassName={props.leafActiveClassName}
        />
      ))}
    </ul>
  )
}

export default Tree
