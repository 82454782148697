import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import * as utils from '../../redux/reducer.utils'

export interface DashboardsState {
  dashboardsDraft: IDashboard[]
  dashboards: IDashboard[]
  editing: boolean
  components: { [dashboard_id: string]: IDashboardComponent[] }
  loadedDashboards: { [dashboard_id: string]: boolean }
  templateVariables: { [dashboard_id: string]: { [device_id: string]: string } }
  isLoaded: boolean
  controls: {
    autoalign: 'none' | 'grid' | 'components'
    grid_step: number
    allow_editing: boolean
  }
  draggingId: string
}

const initialState: DashboardsState = {
  dashboardsDraft: [],
  dashboards: [],
  editing: false,
  components: {},
  loadedDashboards: {},
  templateVariables: {},
  isLoaded: false,
  controls: { autoalign: 'grid', grid_step: 4, allow_editing: true },
  draggingId: '',
}

const slice = createSlice({
  name: 'app/dashboards',
  initialState,
  reducers: {
    setDashboardsDraft: (state, action: PayloadAction<{ draft: IDashboard[]; editing?: boolean }>) => {
      if (action.payload.editing !== undefined) state.editing = action.payload.editing

      state.dashboardsDraft = action.payload.draft
    },
    setDashboards: (state, action: PayloadAction<IDashboard[]>) => {
      state.dashboards = action.payload
      state.isLoaded = true
    },
    setComponents: (state, action: PayloadAction<{ id: string; components: IDashboardComponent[] }>) => {
      const id = action.payload.id
      state.components[id] = action.payload.components
      state.loadedDashboards[id] = true
    },
    setControls: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload
      state.controls[key] = value
    },
    setTemplateVariables: (
      state,
      action: PayloadAction<{ dashboard_id: string; variables: Record<string, string> }>
    ) => {
      state.templateVariables[action.payload.dashboard_id] = action.payload.variables
    },
    dashboardCreated: (state, action: PayloadAction<IDashboard>) => {
      const id = action.payload.id
      const exists = state.dashboards.find((d) => d.id === id) != null

      if (!exists) {
        state.dashboards.push(action.payload)
        state.components[id] = []
        state.loadedDashboards[id] = true
      }
    },
    dashboardUpdated: (state, action: PayloadAction<IDashboard>) => {
      utils.updateItem(state.dashboards, action.payload)
    },
    dashboardsUpdated: (state, action: PayloadAction<Array<Partial<IDashboard>>>) => {
      utils.updateItems(state.dashboards, action.payload)
    },
    dashboardDeleted: (state, action: PayloadAction<string>) => {
      utils.deleteItem(state.dashboards, action.payload)
    },
    componentCreated: (state, action: PayloadAction<IDashboardComponent>) => {
      const { id, dashboard_id } = action.payload

      if (state.loadedDashboards[dashboard_id]) {
        const components = state.components[dashboard_id]
        const exists = components.find((c) => c.id === id)

        if (!exists) state.components[dashboard_id].push(action.payload)
      }
    },
    componentUpdated: (state, action: PayloadAction<Partial<IDashboardComponent>>) => {
      const { dashboard_id } = action.payload

      if (state.loadedDashboards[dashboard_id]) {
        const components = state.components[dashboard_id]
        const component = components.find((c) => c.id === action.payload.id)

        if (component) Object.assign(component, action.payload)
      }
    },
    componentDeleted: (state, action: PayloadAction<IDashboardComponent>) => {
      const { dashboard_id } = action.payload

      if (state.loadedDashboards[dashboard_id]) {
        const components = state.components[dashboard_id]
        const i = components.findIndex((c) => c.id === action.payload.id)

        if (i !== -1) components.splice(i, 1)
      }
    },
    setDraggingId: (state, action: PayloadAction<{ dashboardId: string }>) => {
      state.draggingId = action.payload.dashboardId
    },
  },
})

export const actions = slice.actions

export default slice.reducer
