import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { AnyCatalogWithInstances } from '../Catalogs/catalogs.types'

/**
 * Выбрать все схемы паспортов в которых есть хотя бы одно поле-идентификатор и оставить только такие поля
 */
export const selectPassportIdentifiers = createSelector(
  (state: ReduxState) => state.catalogs.catalogs,
  (catalogs) => {
    const result: AnyCatalogWithInstances[] = []

    for (const c of catalogs) {
      if (c.type !== 'passport') continue

      const identifiers = c.schema.filter((p) => p.is_identifier)
      if (identifiers.length === 0) continue

      result.push({ ...c, schema: identifiers })
    }

    return result
  }
)
