import React from 'react'
import { FormattedMessage } from 'react-intl'
import useDataService from '../../hooks/useDataService'
import useDataRate from '../../hooks/useDataRate'
import SearchInput from '../../shared/Inputs/Search/SearchInput'
import CellWithRadio from '../../shared/RegistratorCell/CellWithRadio'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import Gantt from '../GanttTable/Gantt'
import { Equipment } from 'au-nsi/equipment'

const parameters: Array<[string, number]> = [
  ['VoltageMagnitudeSeqPositive', 1e3],
  ['VoltageMagnitudeSeqNegative', 1e3],
  ['VoltageMagnitudeSeqZero', 1e3],
  ['CurrentMagnitudeSeqPositive', 1],
  ['CurrentMagnitudeSeqNegative', 1],
  ['CurrentMagnitudeSeqZero', 1],
  ['CurrentUnbalance', 1e-2],
]

const VectorTable = (props: Props) => {
  const [state, setState] = React.useState<State>({})

  const service = useDataService(RESERVED_CHART_IDS.UNBALANCE_TABLE, { singleValueMode: true })
  useDataRate(service)

  React.useEffect(() => {
    const selectors = props.equipment.map((e) => ({ device_id: e.id, parameters: parameters.map((row) => row[0]) }))
    service.setDataSelectors(selectors)

    service.onTick = () => {
      if (!service.shouldRender()) return

      const data: State = {}

      for (const { id } of props.equipment) {
        data[id] = service.selectCurrentPoint(id)
      }

      setState(data)
    }
  }, [props.equipment])

  const table = props.equipmentMatch.map((device) => {
    return (
      <Gantt.Row key={device.id}>
        <CellWithRadio registrator={device} checked={device.id === props.selectedDeviceId} onChange={props.onSelect} />
        <div className="data-table__row">{renderRow(device.id, state)}</div>
      </Gantt.Row>
    )
  })

  return (
    <div className="gantt-table">
      <Header onSearch={props.onSearch} />
      <Gantt.Body>{table}</Gantt.Body>
    </div>
  )
}

const renderRow = (id: string, state: State) => {
  return parameters.map(([name, factor]) => {
    const value = state[id]?.[name]

    return (
      <div key={name} className="vector__cell data-table__cell">
        {renderValue(value, factor)}
      </div>
    )
  })
}

const renderValue = (value: number, factor: number) => {
  return value != null ? (value / factor).toFixed(2) : ''
}

const Header = React.memo((props: { onSearch: (v: string) => void }) => {
  return (
    <Gantt.Header>
      <Gantt.Title>
        <SearchInput onChange={props.onSearch} />
      </Gantt.Title>
      <div className="data-table__row data-table__header">
        <div className="vector__cell data-table__cell">
          U<sub>1</sub>, {<FormattedMessage id="vector.kilovolt" />}
        </div>
        <div className="vector__cell data-table__cell">
          U<sub>2</sub>, {<FormattedMessage id="vector.kilovolt" />}
        </div>
        <div className="vector__cell data-table__cell">
          U<sub>0</sub>, {<FormattedMessage id="vector.kilovolt" />}
        </div>
        <div className="vector__cell data-table__cell">
          I<sub>1</sub>, A
        </div>
        <div className="vector__cell data-table__cell">
          I<sub>2</sub>, A
        </div>
        <div className="vector__cell data-table__cell">
          I<sub>0</sub>, A
        </div>
        <div className="vector__cell data-table__cell">
          I<sub>2</sub>
          /I
          <sub>1</sub>, %
        </div>
      </div>
    </Gantt.Header>
  )
})

interface Props {
  equipment: Equipment[]
  equipmentMatch: Equipment[]
  selectedDeviceId: string
  onSearch: (v: string) => void
  onSelect: (e: any) => void
}

interface State {
  [deviceId: string]: { [parameter: string]: number }
}

export default VectorTable
