import classnames from 'classnames'
import { useCombobox } from 'downshift'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import usePopup from '../../hooks/usePopup'
import SmoothDropdown from '../Utils/SmoothDropdownBehaivor/SmoothDropdown'

function MultiSelect<T extends number | string>(props: Props<T>) {
  const intl = useIntl()
  const wrapperRef = useRef<HTMLDivElement>()

  const { open, setOpen } = usePopup(wrapperRef)
  const [filter, setFilter] = React.useState('')
  const [activeIndex, setActiveIndex] = React.useState(-1)

  // очищаем фильтр при закрытии выпадающего списка
  React.useEffect(() => {
    if (!open && filter) setFilter('')
    setActiveIndex(-1)
  }, [open])

  // id выбранных элементов
  const selection = new Set(props.selection)

  // при клике на компонент выставляем фокус на скрытом инпуте
  const handleClick = (e: React.MouseEvent) => {
    if (!props.disabled) {
      // при удалении элемента список не открывается
      if ((e.target as HTMLLIElement)?.className === 'm-select__selected-item') return

      const target = e.currentTarget as HTMLDivElement
      const input = target.previousSibling as HTMLInputElement
      input.focus()
    }
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase())
  }

  // добавить элемент в список
  const handleSelect = (changes) => {
    props.onChange([...props.selection, changes.selectedItem.id], props.name)
    setOpen(true)
  }

  // убрать элемент из списка
  const handleUnselect = (index: number) => {
    if (!props.disabled) {
      const id = selectedOptions[index]?.id

      props.onChange(
        props.selection.filter((e) => e !== id),
        props.name
      )
    }
  }

  const handleOpen = () => {
    if (!props.disabled && !open) setOpen(true)
  }

  const selectedOptions = []
  const remainingOptions = []

  for (const option of props.options) {
    const isSelected = selection.has(option.id)

    // если элемент выбран, то он будет отображаться в самом поле для ввода
    if (isSelected) {
      selectedOptions.push(option)
      selection.delete(option.id)
      continue
    }

    // если элемент не выбран, то он будет отображаться в выпадающем списке
    if (!filter || option.name.toLowerCase().includes(filter)) {
      remainingOptions.push(option)
    }
  }

  // оставшиеся выбранные элементы которых не оказалось в options (например после удаления устройства)
  for (const id of selection) {
    selectedOptions.push({ id, name: id })
  }

  const downshift = useCombobox({
    itemToString,
    onSelectedItemChange: handleSelect,
    onIsOpenChange: (c) => setOpen(c.isOpen),
    items: remainingOptions,
    selectedItem: null,
    isOpen: open,
  })

  const selectedItems = selectedOptions.map((item, index) => {
    const className = classnames('m-select__selected-item line_clamp', { 'is-active': index === activeIndex })

    return (
      <div key={item.id} className={className} onClick={() => handleUnselect(index)} title={item.name}>
        {item.name}
      </div>
    )
  })

  const remainingItems = remainingOptions.map((item, index) => {
    const className = classnames('m-select__dropdown-item', { 'is-highlighted': downshift.highlightedIndex === index })

    return (
      <div key={item.id} className={className} {...downshift.getItemProps({ item, index })}>
        {item.name}
      </div>
    )
  })

  const className = props.disabled ? 'm-select__wrapper is-disabled' : 'm-select__wrapper'
  const showFilter = filter.length > 0 || remainingOptions.length > 8

  const filterInput = (
    <input
      style={{ marginBottom: '5px', position: 'sticky', top: 0, borderRadius: 0 }}
      className="nsi-input"
      value={filter}
      onChange={handleFilterChange}
      placeholder={intl.formatMessage({ id: 'common.search' })}
    />
  )

  const onKeyDown = (e) => {
    const len = selectedOptions.length + 1

    if (open && e.key === 'Escape') {
      e.preventDefault()
    } else if (!open && e.key === 'ArrowLeft') {
      setActiveIndex((activeIndex - 1 + len) % len)
    } else if (!open && e.key === 'ArrowRight') {
      setActiveIndex((activeIndex + 1) % len)
    } else if (!open && e.key === 'Delete') {
      handleUnselect(activeIndex)
    }
  }

  return (
    <div className={className} ref={wrapperRef} {...downshift.getComboboxProps()}>
      <input
        className="visually-hidden m-select__hidden-input"
        {...downshift.getInputProps({ onKeyDown })}
        onFocus={handleOpen}
        value={filter}
        onChange={handleFilterChange}
        disabled={props.disabled}
      />
      <div className="m-select__input" onClick={handleClick}>
        {selectedItems}
      </div>

      <div
        className="nsi-select__menu top"
        style={open ? { top: 'auto' } : { boxShadow: 'none', zIndex: 1, top: 'auto' }}
        {...downshift.getMenuProps()}
      >
        <SmoothDropdown open={open} maxHeight={360}>
          {showFilter && filterInput}
          {remainingItems}
        </SmoothDropdown>
      </div>
    </div>
  )
}

const itemToString = (item) => (item ? item.name : '')

export interface MultiSelectOption<T extends number | string> {
  id: T
  name: string
}

interface Props<T extends number | string> {
  name?: string
  options: MultiSelectOption<T>[]
  selection: T[] // selected ids
  onChange: (selection: T[], name: string) => void
  disabled?: boolean
}

export default React.memo(MultiSelect)
