import { Incident } from 'au-nsi/signal-events'
import { ReduxState } from '../../redux/store.types'
import http, { handleHttpError } from '../../utils/http'
import { selectLanguage } from '../App/app.selectors'
import seActions from '../SignalEvents/se.actions'
import { SignalEventsState } from '../SignalEvents/se.interfaces'
import { rulesMapSelector } from '../SignalEvents/se.selectors'
import { audioManager, getAudioSettings } from './audio.utils'
import * as C from './incident.reducers'
import { getIncidentDescription } from './incidents.utils'

export const incidentsLoaded = (incidents: Incident[]) => ({ type: C.SET_INCIDENTS, incidents })

export const incidentCreated = (incident: Incident) => (dispatch, getState) => {
  const state: ReduxState = getState()
  const { incidents } = state.incidents

  // проверка, что такого инцидента еще нет
  if (incidents.some((e) => e.id === incident.id)) return

  /* при получении нового инцидента с типом formula необходимо проверить что соответствующая формула
  присутствует в стейте приложения и загрузить ее если нет */
  if (incident.type === 'formula') {
    const fid = incident.formula_id
    const se = state.signal_events
    const isPresent = se.items.find((event) => event.rules.some((rule) => rule.id === fid))

    if (!isPresent && se.deletedRulesCache[fid] == null) {
      dispatch(seActions.fetchDeletedRule(fid))
    }
  }

  const audioSettings = getAudioSettings(incident, state)

  if (audioSettings) {
    const { audioId, audioRepeat } = audioSettings
    const rules = rulesMapSelector(state)
    const lang = selectLanguage(state)

    // если вкладка находится в фоновом режиме и пользователь включил системные уведомления, то отправляем
    // уведомление с названием и описанием сигнальной ситуации
    if (document.visibilityState === 'hidden' && 'Notification' in window && Notification.permission === 'granted') {
      const { title, text } = getIncidentDescription(incident, rules, lang)
      const ntf = new Notification(title, { body: text })

      // при клике на уведомление переходим в список к нужному инциденту
      ntf.onclick = () => {
        window.focus()
        window.history.pushState(null, null, '/alerts/list')
        dispatch(setSelectedIncident(incident.id))
      }
    }

    // start playing audio
    audioManager.start(audioId, audioRepeat, () => dispatch({ type: C.SET_AUDIO, audioId: null }))
    dispatch({ type: C.SET_AUDIO, audioId })
  }

  dispatch({ type: C.ADD_INCIDENT, incident })
}

export const stopAudio = () => (dispatch) => {
  audioManager.stop()
  dispatch({ type: C.SET_AUDIO, audioId: null })
}

export const incidentUpdated = (id: number, updates: Partial<Incident>) => ({ type: C.UPDATE_INCIDENT, id, updates })

export const loadIncidents =
  (forceReload = false) =>
  (dispatch, getState) => {
    const { isLoaded } = getState().incidents

    const shouldLoad = (!isLoaded && !forceReload) || (isLoaded && forceReload)
    if (!shouldLoad) return

    const t0 = Date.now() - C.TIMERANGE

    http.get(`/nsi/v1/incidents?t0=${t0}&order=DESC`).then((res) => {
      const incidents = res.data.results
      dispatch(incidentsLoaded(incidents))

      checkMissingRules(incidents, dispatch, getState)
    })
  }

export const loadArchive = (start: number, end: number) => (dispatch, getState) => {
  dispatch({ type: C.SET_ARCHIVE_RANGE, start, end })

  http.get(`/nsi/v1/incidents?t0=${start}&t1=${end}&order=DESC`).then((res) => {
    const incidents = res.data.results
    dispatch({ type: C.SET_ARCHIVE_DATA, data: incidents })

    checkMissingRules(incidents, dispatch, getState)
  })
}

export const ackIncident = (id: number) => (dispatch) => {
  http
    .post(`/nsi/v1/incidents/${id}/ack`)
    .then((res) => dispatch(incidentUpdated(id, res.data)))
    .catch((err) => handleHttpError(err))
}

export const setSelectedIncident = (id: number) => ({ type: C.SET_SELECTED_INCIDENT, id })

/**
 * Проверить список инцидентов на наличие ссылок на удаленные формулы и загрузить
 * необходимые формулы из архива
 */
const checkMissingRules = (incidents: Incident[], dispatch, getState) => {
  // находим идентификаторы всех загруженных формул
  const presentRules = new Set()
  const seState: SignalEventsState = getState().signal_events

  for (const se of seState.items) {
    for (const rule of se.rules) {
      presentRules.add(rule.id)
    }
  }

  for (const se of Object.values(seState.deletedRulesCache)) {
    for (const rule of se.rules) {
      presentRules.add(rule.id)
    }
  }

  // среди инцидентов ищем ссылки на формулы которых нет в presentRules
  // и диспатчим action для их загрузки
  for (const incident of incidents) {
    if (incident.type === 'formula' && !presentRules.has(incident.formula_id)) {
      dispatch(seActions.fetchDeletedRule(incident.formula_id))
      presentRules.add(incident.formula_id)
    }
  }
}
