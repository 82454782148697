// axis subscription
const AxisSub = (props: Props) => {
  const angle = Math.PI - props.angle
  const x = 50 - 38 * Math.sin(angle)
  const y = 50 - 38 * Math.cos(angle)

  const textAnchor = angle > 0 ? 'start' : 'end'
  // move text slightly down depending on position to place it near axis tick
  const dy = Math.abs(angle) > Math.PI / 4 ? 0 : 4 * Math.cos(angle)

  return (
    <text className="gauge__axis-sub" style={{ fill: 'var(--color)' }} textAnchor={textAnchor} x={x} y={y} dy={dy}>
      {props.value.toFixed(props.precision)}
    </text>
  )
}

interface Props {
  angle: number
  value: number
  precision: number
}

export default AxisSub
