import { IDashboard } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import { selectEquipmentMap } from 'pages/Nsi/nsi.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import DashboardsTile from '../../../Dashboards/components/DashboardsTile'
import { selectImagesMap } from '../../../Libraries/Images/image.selectors'

/**
 * Список дашбордов и шаблонов относящихся к текущему устройству
 */
const DeviceDashboards = ({ device }: { device: Equipment }) => {
  const navigate = useNavigate()

  const dashboards = useDeviceDashboards(device.id)
  const images = useSelector(selectImagesMap)

  const handleClick = (d: IDashboard) => {
    navigate(`/map/device/${device.id}/dashboards/${d.id}`)
  }

  const tiles = dashboards.map((d) => {
    return (
      <DashboardsTile
        key={d.id}
        allowEditing={false}
        dashboard={d}
        dashboardsLength={dashboards.length}
        image={images.get(d.image_id)}
        title={d.name}
        onClick={handleClick}
        onCopy={noop}
        onDelete={noop}
        onDragStart={noop}
        onEdit={noop}
        isDrag={false}
        isDrop={false}
        isDropFolder={false}
      />
    )
  })

  return <div className="dashboards">{tiles}</div>
}

const noop = () => null

/**
 * Хук для выборки шаблонов экранов, применимых к указанному устройству
 */
export const useDeviceDashboards = (deviceId: string) => {
  const dashboards = useAppSelector((state) => state.dashboards.dashboards)
  const equipment = useAppSelector(selectEquipmentMap)

  // выбираем только шаблоны с одной переменной (т.к. эту одну переменную можно автоматически заменить
  // на текущее устройство)
  const templates = useMemo(() => {
    return dashboards.filter((d) => d.is_template && d.template_settings && d.template_settings.variables.length === 1)
  }, [dashboards])

  const device = equipment.get(deviceId)
  if (!device) return templates

  // оставляем только шаблоны без тегов, либо с тэгами, которые
  // также указаны у текущего устройства
  return templates.filter((dashboard) => {
    const tags = dashboard.template_settings.tags
    if (!tags || !tags.length) return true

    return tags.some((t) => device.tags.includes(t))
  })
}

export default DeviceDashboards
