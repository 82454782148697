import { formatNumber } from '../../../pages/Parameters/params.utils'
import cursorService, { ICursor } from '../../../services/cursor/cursor.service'
import { Chart } from '../Chart'
import * as utils from '../utils/chart.utils'

/**
 * Плагин для отображения курсора и вывода попапа со значением
 * данных в ближайшей к курсору точке
 * cursor.t - зафиксировано время курсора и он движется вместе с графиком
 * cursor.x - время не фиксировано и курсор движется за мышью
 */
class CursorPlugin {
  // x - координата точки ближайшей к курсору
  // xLine - координата самого курсора
  private isHidden = true
  private cursor: ICursor = cursorService.getCursor()

  constructor(private chart: Chart) {
    chart.mouseMoveHandlers.push(this.onMouseMove)
    chart.mouseLeaveHandlers.push(this.onMouseLeave)
    chart.clickHandlers.push(this.onClick)
    chart.dataHandlers.push(this.onData)
  }

  get allowCursor() {
    const { controls } = this.chart.props
    return !controls.zoom && !controls.pan && !controls.fillArea
  }

  public onCursor(cursor: ICursor) {
    if (!cursor.component_id || cursor.component_id === this.chart.props.id) {
      this.cursor = cursor
      this.updateCursor()
    } else if (this.cursor.x) {
      this.cursor = { t: null, x: null, component_id: null }
      this.hideCursor()
    }
  }

  private onData = () => {
    this.updateCursor()
  }

  // убрать координаты курсора при выходе мыши за границу графика
  private onMouseLeave = () => {
    if (this.allowCursor && !this.cursor.t) {
      cursorService.setCursor(null, null, this.chart.props.id)
    }
  }

  // при движении мыши, если не фиксировано время то задать координату курсора
  // равной координате указателя мыши
  private onMouseMove = (e) => {
    if (this.allowCursor && !this.cursor.t) {
      const x = e.clientX - this.chart.state.boundaries.left

      if (Math.abs(x - this.cursor.x) > 2) {
        cursorService.setCursor(null, x / this.chart.innerWidth, this.chart.props.id)
      }
    }
  }

  // при клике зафиксировать/убрать время курсора
  private onClick = (e) => {
    if (this.allowCursor) {
      const x = e.clientX - this.chart.state.boundaries.left

      if (this.cursor.t) {
        cursorService.setCursor(null, x / this.chart.innerWidth, this.chart.props.id)
      } else {
        const t = this.chart.xScaleInverse(x)
        cursorService.setCursor(t, null, this.chart.props.id)
      }
    }
  }

  private getCursorPoint() {
    const { x, t } = this.cursor
    const noCursor = !x && !t

    if (!this.allowCursor || noCursor) {
      return null
    }

    if (t != null) {
      return this.getCursorByTime(t)
    } else {
      return this.getCursorByX(x * this.chart.innerWidth)
    }
  }

  // координаты курсора и ближайшей точки при известной координате курсора
  private getCursorByX(xLine: number): CursorInfo {
    const { state } = this.chart
    if (xLine < state.margins.left || xLine > state.boundaries.width - state.margins.right) {
      return null
    }

    const ts = this.chart.xScaleInverse(xLine)
    const { axes } = this.chart.props.settings
    const values: CursorInfo['values'] = {}
    const points: CursorInfo['points'] = {}

    // для каждой линии на каждой оси находим ближайшую к курсору точку
    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]
      const service = this.chart.props.services[i]

      for (const line of axis.lines) {
        const { device_id, parameter_id } = line
        const key = i + ':' + device_id + ':' + parameter_id
        const selectOptions = { deviceId: device_id, parameterId: parameter_id, ts, useStackedValues: false }

        const point = service.selectPoint(selectOptions)
        const pointStacked = axis.stack ? service.selectPoint({ ...selectOptions, useStackedValues: true }) : point
        const yScale = this.chart.selectScaleY(i, line)

        if (point == null || point[parameter_id] == null) continue

        // value - истинное значение параметра, valueStacked - значение отображаемое на графике
        // со включенной опцией stack (valueStacked как правило больше за счет того что является
        // суммой значений с такой же меткой времени по всем предыдущим устройствам)
        const value = point[parameter_id]
        const valueStacked = pointStacked[parameter_id]
        const y = Math.round(yScale(valueStacked))
        const x = this.chart.xScale(pointStacked.ts)

        values[key] = { avg: value, min: point[parameter_id + ':min'], max: point[parameter_id + ':max'] }
        points[key] = { x, y }
      }
    }

    return { xLine, ts, values, points }
  }

  // координаты курсора и ближайшей точки при известном времени
  // x, y - точка курсора отображающая значение в выбранный момент времени
  private getCursorByTime(ts: number): CursorInfo {
    const { t0, t1 } = this.chart
    if (ts < t0 || ts > t1) return null

    const x = +this.chart.xScale(ts).toFixed(1)
    const { axes } = this.chart.props.settings
    const values: CursorInfo['values'] = {}
    const points: CursorInfo['points'] = {}

    // проход по всем осям и всем линиям
    for (let i = 0; i < axes.length; i++) {
      const axis = axes[i]
      const service = this.chart.props.services[i]

      for (const line of axis.lines) {
        const { device_id, parameter_id } = line
        const key = i + ':' + device_id + ':' + parameter_id
        const selectOptions = { deviceId: device_id, parameterId: parameter_id, ts, useStackedValues: false }

        const point = service.selectPoint(selectOptions)
        const pointStacked = axis.stack ? service.selectPoint({ ...selectOptions, useStackedValues: true }) : point
        if (point == null || point[parameter_id] == null) continue

        // см. комментарии в методе getCursorByX
        const yScale = this.chart.selectScaleY(i, line)
        const value = point[parameter_id]
        const valueStacked = pointStacked[parameter_id]
        const cy = Math.round(yScale(valueStacked))
        const cx = this.chart.xScale(pointStacked.ts)

        values[key] = { avg: value, min: point[parameter_id + ':min'], max: point[parameter_id + ':max'] }
        points[key] = { x: cx, y: cy }
      }
    }

    return { xLine: x, ts, values, points }
  }

  private updateCursor() {
    const point = this.getCursorPoint()

    // hide cursor
    if (point == null && !this.isHidden) {
      this.isHidden = true
      this.hideCursor()
    }

    // make cursor visible
    if (point != null) {
      this.isHidden = false
      this.showCursor()
      this.setCursor(point)
    }

    // 3rd case: point == null && this.isHidden
    // in this case do nothing, because cursor is already hidden
  }

  private calculateTooltip(point) {
    const { xLine, ts, values } = point
    const { props, maxTooltipHeight } = this.chart

    let linesCount = 0
    props.settings.axes.forEach((axis) => (linesCount += axis.lines.length))

    const style = utils.tooltipStyles(xLine, maxTooltipHeight, linesCount)
    const time = this.formatTime(ts)
    return { ts, time, values, style }
  }

  private formatTime(ts: number) {
    const date = new Date(ts)
    const span = this.chart.t1 - this.chart.t0

    const h = date.getHours().toString().padStart(2, '0')
    const m = date.getMinutes().toString().padStart(2, '0')
    const s = date.getSeconds().toString().padStart(2, '0')

    if (span < 20) {
      // при ширине кадра меньше 20 миллисекунд показываем с точностью до микросекунд
      const us = Math.round((ts * 1000) % 1000)
        .toString()
        .padStart(3, '0')
      const ms = date.getMilliseconds().toString().padStart(3, '0')
      return `${h}:${m}:${s}.${ms}.${us}`
    } else if (span < 15_000) {
      // при ширине кадра меньше 15 секунд добавляем миллисекунды
      const ms = date.getMilliseconds().toString().padStart(3, '0')
      return `${h}:${m}:${s}.${ms}`
    } else if (span < 86400000) {
      // при ширине кадра меньше дня показываем часы, минуты и секунды
      return `${h}:${m}:${s}`
    } else {
      // при кадре больше дня показываем дату, часы и минуты
      const d = date.getDate().toString().padStart(2, '0')
      const M = (date.getMonth() + 1).toString().padStart(2, '0')
      return `${d}.${M} ${h}:${m}`
    }
  }

  // установить координаты курсора, ближайшей точки, попапа и значений отображаемых в попапе
  private setCursor(point: CursorInfo) {
    const tooltip = this.calculateTooltip(point)
    const minY = this.chart.state.margins.top
    const maxY = minY + this.chart.innerHeight

    const cursor$ = this.chart.cursorRef.current
    const tooltip$ = this.chart.tooltipRef.current
    const markers$ = this.chart.markersRef.current
    if (!cursor$) return

    cursor$.style.transform = `translateX(${point.xLine}px)`

    tooltip$.querySelector('.line-chart__tooltip-time').textContent = tooltip.time
    tooltip$.style.transform = tooltip.style.transform
    tooltip$.style.minWidth = tooltip.style.minWidth

    const { axes } = this.chart.props.settings

    for (let i = 0; i < axes.length; i++) {
      for (const line of axes[i].lines) {
        const key = i + ':' + line.device_id + ':' + line.parameter_id

        const position = point.points[key]
        const text = this.formatValue(i, tooltip.values[key])

        let node: HTMLDivElement = tooltip$.querySelector(`[data-id="${key}"]`)
        if (node) node.textContent = text

        node = markers$.querySelector(`[data-id="${key}"]`)

        const isValid =
          node &&
          position &&
          position.y >= minY &&
          position.y <= maxY &&
          position.x > 0 &&
          position.x < this.chart.state.boundaries.width

        if (isValid) {
          node.style.transform = `translate(${position.x}px, ${position.y}px)`
          node.style.opacity = '1'
        } else {
          node.style.opacity = '0'
        }
      }
    }
  }

  private formatValue(axis: number, value: CursorValue) {
    if (!value || value.avg == null) return '—'

    const scale = this.chart.props.scales[axis]
    const aggregationMode = this.chart.props.settings.aggregationMode ?? 2
    const showMin = (aggregationMode & 1) === 1
    const showMax = (aggregationMode & 4) === 4

    const avg = formatNumber(value.avg / scale)
    let text = avg

    if ((showMin || showMax) && value.min != null) {
      const min = formatNumber(value.min / scale)
      const max = formatNumber(value.max / scale)

      if (min !== avg || max !== avg) {
        text = `${text} (${min} - ${max})`
      }
    }

    return text
  }

  private showCursor() {
    if (this.chart.cursorRef.current) {
      this.chart.cursorRef.current.style.opacity = '1'
      this.chart.markersRef.current.style.opacity = '1'
      this.chart.tooltipRef.current.style.opacity = '1'
    }
  }

  private hideCursor() {
    if (this.chart.cursorRef.current) {
      this.chart.cursorRef.current.style.opacity = '0'
      this.chart.markersRef.current.style.opacity = '0'
      this.chart.tooltipRef.current.style.opacity = '0'
    }
  }
}

interface CursorInfo {
  xLine: number
  ts: number
  values: Record<string, CursorValue>
  points: Record<string, { x: number; y: number }>
}

interface CursorValue {
  min: number
  avg: number
  max: number
}

export default CursorPlugin
