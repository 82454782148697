/**
 * Утилиты для использования в редьюсерах для коллекций ресурсов имеющих порядок
 * задаваемых пользователем (поле user_ordering_index), таких как аудио и изображения.
 * Т.к. данные функции мутируют переданные аргументы, то их использование возможно только
 * вместе с immer.
 */
interface ItemWithIndex {
  id: string | number
  user_ordering_index: number
}

export const appendItem = <T extends ItemWithIndex>(items: T[], item: T) => {
  const { id } = item
  const existingIndex = items.findIndex((d) => d.id === id)

  if (existingIndex === -1) items.push(item)
  else items[existingIndex] = item
}

export const updateItem = <T extends ItemWithIndex>(items: T[], updates: Partial<T>) => {
  const item = items.find((d) => d.id === updates.id)
  const shouldSort = item && updates.user_ordering_index != null

  if (item) Object.assign(item, updates)
  if (shouldSort) items.sort((a, b) => a.user_ordering_index - b.user_ordering_index)
}

export const updateItems = <T extends ItemWithIndex>(items: T[], updates: Partial<T>[]) => {
  let shouldSort = false

  for (const update of updates) {
    const item = items.find((d) => d.id === update.id)
    if (item) Object.assign(item, update)
    if (item && update.user_ordering_index != null) shouldSort = true
  }

  if (shouldSort) items.sort((a, b) => a.user_ordering_index - b.user_ordering_index)
}

export const deleteItem = <T extends ItemWithIndex>(items: T[], id: T['id']) => {
  const i = items.findIndex((d) => d.id === id)
  if (i !== -1) items.splice(i, 1)
}

/**
 * Утилиты для коллекций ресурсов, которые должны быть отсортированы по названию
 */
interface ItemWithName {
  id: string | number
  name: string
}

export const orderedInsert = <T extends ItemWithName>(items: T[], created: T) => {
  const alreadyExists = items.find((e) => e.id === created.id)
  if (alreadyExists) return

  const createdName = created.name

  const lastElement = items[items.length - 1]
  if (items.length === 0 || lastElement.name <= createdName) {
    items.push(created)
    return
  }

  if (items[0].name >= createdName) {
    items.splice(0, 0, created)
    return
  }

  for (let i = 0; i < items.length - 1; ++i) {
    if (items[i].name <= createdName && items[i + 1].name >= createdName) {
      items.splice(i + 1, 0, created)
      return
    }
  }
}

/**
 * Замена элемента массива по id с сохранением сортировки по названию
 */
export const orderedUpdate = <T extends ItemWithName>(items: T[], update: T) => {
  const index = items.findIndex((e) => e.id === update.id)
  if (index === -1) return

  const orderChanged = update.name !== items[index].name

  if (!orderChanged) {
    items[index] = update
  } else {
    items.splice(index, 1)
    orderedInsert(items, update)
  }
}
