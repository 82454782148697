import { MonthPicker } from './MonthPicker'
import { getMonthCalendarDays, IDate } from './utils'
import { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getLocMonths, getLocWeekDays } from '../datepicker.utils'

interface IProps {
  ts: number
  onChange: (number) => void
  allowFutureSelect: boolean
}

export const Calendar = ({ ts, onChange, allowFutureSelect }: IProps) => {
  const intl = useIntl()

  const daysOfWeek = useMemo(() => getLocWeekDays(intl), [intl.locale])
  const monthList = useMemo(() => getLocMonths(intl), [intl.locale])

  const [date, setDate] = useState(new Date(ts))
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())

  useEffect(() => {
    if (ts !== date.valueOf()) {
      setDate(new Date(ts))
    }
  }, [ts])

  useEffect(() => {
    setMonth(date.getMonth())
    setYear(date.getFullYear())
  }, [date])

  const handleSelect = (dateTime: IDate) => {
    setMonth(dateTime.month)
    setYear(dateTime.year)

    if (dateTime.day) {
      const updateDate = new Date(
        dateTime.year,
        dateTime.month,
        dateTime.day,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )

      onChange(updateDate.valueOf())
    }
  }

  const getWeekDayStyles = (weekDay: IDate) => {
    const curDate = new Date()
    return `calendar-week-day is-prev_month is-selectable 
                                ${
                                  weekDay.day === date.getDate() &&
                                  weekDay.month === date.getMonth() &&
                                  weekDay.year === date.getFullYear()
                                    ? 'is-selected'
                                    : ''
                                } 
                                ${month === weekDay.month ? 'is-current_month' : ''} 
                                ${
                                  new Date().valueOf() > new Date(weekDay.year, weekDay.month, weekDay.day).valueOf() ||
                                  allowFutureSelect
                                    ? 'is-selectable'
                                    : 'is-unselectable'
                                }
                                ${
                                  weekDay.day === curDate.getDate() &&
                                  weekDay.month === curDate.getMonth() &&
                                  weekDay.year === curDate.getFullYear()
                                    ? 'is-today'
                                    : ''
                                }`
  }

  const renderWeekDays = (weekDays: IDate[]) => {
    return weekDays.map((weekDay) => {
      return (
        <span
          key={weekDay.month + '' + weekDay.year + weekDay.day}
          className={getWeekDayStyles(weekDay)}
          onClick={() => handleSelect(weekDay)}
        >
          {weekDay.day}
        </span>
      )
    })
  }

  const renderDaysNames = () => {
    return (
      <div className="calendar-days_of_week">
        {daysOfWeek.map((dayName) => {
          return (
            <div key={dayName} className="calendar-days_of_week_day">
              {dayName}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={'calendar'}>
      <MonthPicker monthList={monthList} mmyy={{ month, year }} onChange={handleSelect} />
      <div className={'calendar-month'}>
        {renderDaysNames()}
        {getMonthCalendarDays(month, year).map((weekDays: IDate[]) => (
          <div
            key={new Date(weekDays[0].year, weekDays[0].month, weekDays[0].day).valueOf()}
            className={'calendar-week'}
          >
            {renderWeekDays(weekDays)}
          </div>
        ))}
      </div>
    </div>
  )
}
