import Modal from '../../../../../shared/Modal/Modal'
import InputRow from '../../../../../shared/Inputs/InputRow'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { updateEquipmentConfig } from '../../../../Nsi/nsi.actions'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import { useDispatch } from 'react-redux'
import ModalFooter from '../../../../../shared/Modal/ModalFooter'
import TextInput from '../../../../../shared/Inputs/TextInput'
import { selectUnknownControllers } from '../../../../../shared/AE/ControllerIdInput/unknownControllers.api'
import styles from '../../../../../shared/AE/ControllerIdInput/controllerIdInput.module.css'
import { formatDate } from '../../../../../utils/lang'
import { UnknownController } from '../../../../../shared/AE/ControllerIdInput/AEControllerIdInput'
import { LP001Equipment } from 'au-nsi/equipment'

interface IProps {
  controllerId: string
  deviceId: LP001Equipment['id']
  onClose: () => void
}

const AEConnectIdModal = (props: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()

  const [unknownControllersListOpen, setUnknownControllersListOpen] = useState(false)
  const [unknownControllers, setUnknownControllers] = useState<UnknownController[]>([])
  const [controllerId, setControllerId] = useState(props.controllerId)

  useEffect(() => {
    wait(selectUnknownControllers(), setUnknownControllers)
  }, [])

  const renderUnknownControllersList = () => {
    if (!unknownControllersListOpen) return null
    if (!unknownControllers.length) return null

    const list = unknownControllers.map((controller) => (
      <div
        className={styles.unknownControllerRow}
        key={controller.controllerId}
        onClick={() => setControllerId(controller.controllerId)}
      >
        <span>{controller.controllerId}</span>
        <span>{formatDate(controller.ts, 'dd MMM hh:mm:ss')}</span>
      </div>
    ))

    return (
      <>
        <h3 style={{ marginTop: '1em' }}>{intl.formatMessage({ id: 'nsi.lp001.unknown_list' })}</h3>
        <div className={styles.container}>{list}</div>
      </>
    )
  }

  const handleSubmit = () => {
    const updater = dispatch(updateEquipmentConfig(props.deviceId, { controller_id: controllerId }))
    wait(updater as any, props.onClose)
  }

  return (
    <Modal onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: props.controllerId ? 'map.ae.edit_uid' : 'map.ae.connect_uid' })}</h2>
      <InputRow
        label={intl.formatMessage({ id: 'nsi.controller_id' })}
        tableMode={true}
        style={{ marginBottom: '40px' }}
      >
        <div className="inscription-input__block">
          <TextInput onChange={setControllerId} value={controllerId} name={''} />
          {Boolean(unknownControllers.length) && (
            <span onClick={() => setUnknownControllersListOpen((prev) => !prev)}>
              {intl.formatMessage({ id: 'nsi.lp001.select_from_unknown' })}
            </span>
          )}
        </div>
      </InputRow>
      {renderUnknownControllersList()}
      <ModalFooter loading={unknownControllers && loading} onCancel={props.onClose} onSave={handleSubmit} />
    </Modal>
  )
}

export default AEConnectIdModal
