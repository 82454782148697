import { useAppSelector } from '../../../../redux/store'
import { useMemo } from 'react'
import placeManager from '../../../../services/geo/placeManager/placeManager'
import { IPlace } from 'au-nsi/shared'

/**
 * Выбрать все группы, находящие на месте place
 * */
const usePlaceGroups = (place: IPlace) => {
  const groups = useAppSelector((state) => state.groups.groups)
  return useMemo(() => groups.filter((g) => placeManager.isIPlacesEqual(g.address, place)), [groups, place])
}

export default usePlaceGroups
