import { SelectOption } from '@alterouniversal/au-react-components'
import { IChartComponent, IChartLine, IChartSettings2 } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { IDataSource } from '../../../pages/Nsi/nsi.interfaces'
import { selectDataSources } from '../../../pages/Nsi/nsi.selectors'
import { ReduxState } from '../../../redux/store.types'
import { showError } from '../../../utils/notifications'
import Form from '../../Forms/Form'
import PlusButton from '../../Forms/PlusButton'
import Dropdown from '../../Inputs/Dropdown'
import DurationInput from '../../Inputs/DurationInput'
import InputRow from '../../Inputs/InputRow'
import OptionalInput from '../../Inputs/OptionalInput'
import TextInput from '../../Inputs/TextInput'
import Modal from '../../Modal/Modal'
import ModalFooter from '../../Modal/ModalFooter'
import { convertTo2 } from '../utils/version.utils'
import AggregationModeInput from './AggregationModeInput'
import BarsOptions from './BarsOptions'
import ChartTabs from './ChartTabs'
import FillOptions from './FillOptions'
import LinesTableRow from './LinesTableRow'
import OptionalNumberInput from './OptionalNumberInput'
import PredictionOptions from './PredictionOptions'
import SettingsBlock from './SettingsBlock'
import ThresholdsTableRow from './ThresholdsTableRow'
import {
  selectParameterOptions,
  selectParametersMap,
  selectTimeUnits,
} from '../../../pages/Parameters/params.selectors'

export const ChartSettings = (props: Props) => {
  const intl = useIntl()

  const [state, setState] = React.useState({ settings: convertTo2(props.component.settings), selectedAxis: -1 })
  const [openBlocks, setOpenBlocks] = React.useState({ parameters: true, thresholds: true, other: false })

  const { settings, selectedAxis } = state
  const axis =
    selectedAxis === -1
      ? settings.axes[0]
      : selectedAxis >= settings.axes.length
      ? settings.axes[settings.axes.length - 1]
      : settings.axes[selectedAxis]

  const { selectedUnit, selectedPrefix } = getSelectedUnit(axis.lines, props.parametersMap)
  const unitsInfo = getUnitsInfo(axis.lines, props.parametersMap, props.units, props.prefixes)
  const isParameterSelected = unitsInfo.baseUnit != null

  // хотя бы один из выбранных параметров нерегулярный
  const isIrregularParameters = axis.lines.some((line) => props.parametersMap.get(line.parameter_id)?.is_irregular)

  const equipmentOptions = React.useMemo(() => {
    return props.equipment.map((e) => ({ label: e.name, value: e.id, color: e.color }))
  }, [props.equipment])

  const booleanOptions = [
    { value: true, title: intl.formatMessage({ id: 'common.yes' }) },
    { value: false, title: intl.formatMessage({ id: 'common.no' }) },
  ]

  const axisPositionOptions = [
    { value: 'left', title: intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.position.left' }) },
    { value: 'right', title: intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.position.right' }) },
  ]

  const lineModeOptions = ['line', 'step', 'auto'].map((value) => ({
    value,
    title: intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.lineMode.' + value }),
  }))

  // верхний уровень навигации - переключение между осями
  const generalTabs = React.useMemo(() => {
    const result = settings.axes.map((_, i) =>
      intl.formatMessage({ id: 'dashboards.linear_chart.settings.tabs.axis' }, { index: i + 1 })
    )
    result.unshift(intl.formatMessage({ id: 'dashboards.linear_chart.settings.tabs.general' }))

    return result
  }, [settings.axes.length])

  // только параметры имеющие одну размерность
  const parameterOptions = React.useMemo(() => {
    return props.parameterOptions.filter((o) => {
      if (!selectedUnit) return true

      const parameter = props.parametersMap.get(o.value)
      if (!parameter) return false

      return parameter.unit === selectedUnit && parameter.prefix === selectedPrefix
    })
  }, [selectedUnit, props.equipment, props.parameterOptions])

  const handleFieldChange = React.useCallback((value: any, key: string) => {
    setState((prev) =>
      produce(prev, (draft) => {
        if (prev.selectedAxis === -1) draft.settings[key] = value
        else draft.settings.axes[prev.selectedAxis][key] = value
      })
    )
  }, [])

  const handleTabChange = React.useCallback((index: number) => {
    setState((prev) => ({ ...prev, selectedAxis: index - 1 }))
  }, [])

  const appendAxis = React.useCallback(() => {
    setState((prev) =>
      produce(prev, (draft) => {
        const lines = [{ device_id: null, parameter_id: null, color: null }]
        draft.settings.axes.push({ position: 'right', lines, thresholds: [], bars: false, fill: false })
      })
    )
  }, [])

  const removeAxis = React.useCallback((index: number) => {
    setState((prev) =>
      produce(prev, (draft) => {
        const { axes } = draft.settings
        if (axes.length > 1) axes.splice(index - 1, 1)
      })
    )
  }, [])

  // добавить новую пустую строку
  const appendLine = () => {
    setState((prev) =>
      produce(prev, (draft) => {
        const axis = draft.settings.axes[prev.selectedAxis]
        axis.lines.push({ device_id: null, parameter_id: null, color: null })
      })
    )
  }

  const appendThreshold = () => {
    setState((prev) =>
      produce(prev, (draft) => {
        const axis = draft.settings.axes[prev.selectedAxis]

        if (!axis.thresholds) axis.thresholds = []
        axis.thresholds.push({ value: 0, line: 'solid' as const, color: '#f44336' })
      })
    )
  }

  // удалить строку с указанным индексом
  const removeItem = React.useCallback((index: number, key: 'lines' | 'thresholds') => {
    setState((prev) =>
      produce(prev, (draft) => {
        const axis = draft.settings.axes[prev.selectedAxis]
        axis[key].splice(index, 1)
      })
    )
  }, [])

  // изменить строку с указанным индексом
  const updateItem = React.useCallback((index: number, updates, key: 'lines' | 'thresholds') => {
    setState((prev) =>
      produce(prev, (draft) => {
        const axis = draft.settings.axes[prev.selectedAxis]
        axis[key][index] = updates
      })
    )
  }, [])

  const toggleBlock = (id: string) => setOpenBlocks({ ...openBlocks, [id]: !openBlocks[id] })

  // сохранение настроек
  const handleSave = () => {
    const keys = new Set()
    const hasMirrorAxis = settings.mirrorY === true

    const axes = settings.axes.map((axis, i) => {
      const lines = axis.lines
        // при выключении зеркальной оси все линии переносим на основную ось
        .map((line) => {
          const mirror = hasMirrorAxis && line.mirror ? true : undefined
          return { ...line, mirror }
        })
        // отфильтровать дублирующиеся линии и линии с пустыми настройками
        .filter((line) => {
          const key = i + ':' + line.device_id + ':' + line.parameter_id
          const isValid = line.device_id && line.parameter_id && line.color && !keys.has(key)

          return isValid && keys.add(key)
        })

      return { ...axis, lines }
    })

    for (let i = 0; i < axes.length; i++) {
      if (axes[i].lines.length === 0) {
        const error = intl.formatMessage({ id: 'dashboards.linear_chart.settings.errors.empty_axis' }, { index: i + 1 })
        return showError(error, error)
      }
    }

    props.onSave({ ...settings, axes })
  }

  const lineRows = axis.lines.map((line, i) => {
    return (
      <LinesTableRow
        key={i}
        intl={intl}
        index={i}
        line={line}
        mirrorY={settings.mirrorY}
        equipmentOptions={equipmentOptions}
        parameterOptions={parameterOptions}
        onRemove={removeItem}
        onChange={updateItem}
      />
    )
  })

  const thresholdRows = (axis.thresholds || []).map((row, i) => {
    return (
      <ThresholdsTableRow
        key={i}
        intl={intl}
        index={i}
        row={row}
        onRemove={removeItem}
        onChange={updateItem}
        unitsInfo={unitsInfo}
      />
    )
  })

  // форма настройки оси - рендерится для каждой из осей
  const renderAxisForm = () => (
    <div>
      {/* First tab */}
      <SettingsBlock
        open={openBlocks.parameters}
        onToggle={() => toggleBlock('parameters')}
        title="dashboards.linear_chart.settings.tabs.parameters"
      >
        <div style={{ marginTop: '1em' }}>
          <table className="nsi-settings-table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.lines.device_id' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.lines.parameter_id' })}</th>
                {settings.mirrorY && <th>{intl.formatMessage({ id: 'dashboards.linear_chart.settings.axis' })}</th>}
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.lines.color' })}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{lineRows}</tbody>
          </table>
          <PlusButton textId="common.add" onClick={appendLine} style={{ marginLeft: 0 }} />
        </div>
      </SettingsBlock>

      {/* Second tab */}
      <SettingsBlock
        open={openBlocks.thresholds}
        onToggle={() => toggleBlock('thresholds')}
        title="dashboards.linear_chart.settings.tabs.thresholds"
      >
        <div style={{ marginTop: '1em' }}>
          <table className="nsi-settings-table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.thresholds.value' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.thresholds.line' })}</th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.thresholds.color' })}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{thresholdRows}</tbody>
          </table>
          <PlusButton
            textId="dashboards.linear_chart.settings.add_threshold"
            onClick={appendThreshold}
            style={{ marginLeft: 0 }}
          />
        </div>
      </SettingsBlock>

      {/* Third tab */}
      <SettingsBlock
        open={openBlocks.other}
        onToggle={() => toggleBlock('other')}
        title="dashboards.linear_chart.settings.tabs.other"
      >
        <div className="system__grid" style={{ marginTop: '1em' }}>
          <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.position' })}>
            <Dropdown
              name="position"
              value={axis.position}
              options={axisPositionOptions}
              onChange={handleFieldChange}
            />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.stack' })}>
            <Dropdown name="stack" value={axis.stack || false} options={booleanOptions} onChange={handleFieldChange} />
          </InputRow>

          {isParameterSelected && (
            <OptionalNumberInput
              name="minY"
              value={axis.minY}
              onChange={handleFieldChange}
              label="dashboards.linear_chart.settings.axes.minY"
              labelForUndefined="dashboards.linear_chart.settings.y.auto"
              labelForValue="dashboards.linear_chart.settings.y.value"
              {...unitsInfo}
            />
          )}

          {isParameterSelected && (
            <OptionalNumberInput
              name="maxY"
              value={axis.maxY}
              onChange={handleFieldChange}
              label="dashboards.linear_chart.settings.axes.maxY"
              labelForUndefined="dashboards.linear_chart.settings.y.auto"
              labelForValue="dashboards.linear_chart.settings.y.value"
              {...unitsInfo}
            />
          )}

          <FillOptions
            fill={axis.fill}
            fillBase={axis.fillBase}
            intl={intl}
            onChange={handleFieldChange}
            options={booleanOptions}
            unitsInfo={unitsInfo}
          />

          <BarsOptions
            bars={axis.bars}
            barsWidth={axis.barsWidth}
            disabled={isIrregularParameters}
            intl={intl}
            onChange={handleFieldChange}
            options={booleanOptions}
            units={props.units}
          />

          <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.aggregationWindow' })}>
            <OptionalInput
              name="aggregationWindow"
              value={axis.aggregationWindow}
              defaultValue={1000}
              defaultLabel={intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.aggregationWindow.auto' })}
              onChange={handleFieldChange}
            >
              <DurationInput name="aggregationWindow" value={axis.aggregationWindow} onChange={handleFieldChange} />
            </OptionalInput>
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.lineMode' })}>
            <Dropdown
              name="lineMode"
              options={lineModeOptions}
              value={axis.lineMode || 'auto'}
              onChange={handleFieldChange}
            />
          </InputRow>
        </div>
      </SettingsBlock>
    </div>
  )

  const axesSettings = settings.axes.map((_, i) => {
    return (
      <div key={i} style={{ marginLeft: 18 }}>
        <h2>{generalTabs[selectedAxis + 1]}</h2>
        {i === selectedAxis ? renderAxisForm() : null}

        <ModalFooter onCancel={props.onCancel} onSave={handleSave} />
      </div>
    )
  })

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <ChartTabs
            index={selectedAxis + 1}
            onAppend={appendAxis}
            onIndexChange={handleTabChange}
            onRemove={settings.axes.length > 1 ? removeAxis : undefined}
            titles={generalTabs}
          >
            {/* general settings */}
            <div style={{ marginLeft: 18 }}>
              <h2>{generalTabs[selectedAxis + 1]}</h2>

              <div className="system__grid">
                <InputRow label={intl.formatMessage({ id: 'common.name' })}>
                  <TextInput name="title" value={settings.title} onChange={handleFieldChange} />
                </InputRow>

                <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.aggregationMode' })}>
                  <AggregationModeInput value={settings.aggregationMode} onChange={handleFieldChange} />
                </InputRow>

                <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.mirrorY' })}>
                  <Dropdown
                    name="mirrorY"
                    value={settings.mirrorY || false}
                    options={booleanOptions}
                    onChange={handleFieldChange}
                  />
                </InputRow>

                {settings.mirrorY && (
                  <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.mirrorTitle' })}>
                    <TextInput
                      name="mirrorTitle"
                      value={settings.mirrorTitle}
                      onChange={handleFieldChange}
                      required={false}
                    />
                  </InputRow>
                )}

                <PredictionOptions
                  intl={intl}
                  onChange={handleFieldChange}
                  options={booleanOptions}
                  prediction={settings.prediction}
                  predictionTime={settings.predictionTime}
                  units={props.units}
                  timeUnits={props.timeUnits}
                />

                <InputRow label={intl.formatMessage({ id: 'dashboards.linear_chart.settings.frame' })}>
                  <OptionalInput
                    name="frame"
                    value={settings.frame}
                    defaultValue={30_000}
                    defaultLabel={intl.formatMessage({ id: 'dashboards.linear_chart.settings.frame.auto' })}
                    onChange={handleFieldChange}
                  >
                    <DurationInput name="frame" value={settings.frame} onChange={handleFieldChange} />
                  </OptionalInput>
                </InputRow>
              </div>

              <ModalFooter onCancel={props.onCancel} onSave={handleSave} />
            </div>

            {/* axis settings */}
            {axesSettings}
          </ChartTabs>
        </Form>
      </div>
    </Modal>
  )
}

/**
 * Определить выбранную единицу измерения
 */
const getSelectedUnit = (lines: IChartLine[], parameters: Map<string, Parameter>) => {
  const result = { selectedUnit: null, selectedPrefix: null }
  if (lines.length <= 1) return result

  const line = lines.find((l) => l.parameter_id != null)
  if (!line) return result

  const parameter = parameters.get(line.parameter_id)
  if (!parameter) return result

  result.selectedUnit = parameter.unit
  result.selectedPrefix = parameter.prefix
  return result
}

/**
 * Получить информацию об единицах измерения параметров указанных в настройках
 */
const getUnitsInfo = (
  lines: IChartLine[],
  parameters: Map<string, Parameter>,
  units: { [id: string]: SiUnit },
  prefixes: { [id: string]: SiPrefix }
) => {
  const result = { baseUnit: null, displayUnit: null, displayPrefix: null, units, prefixes }

  const line = lines.find((l) => l.parameter_id != null)
  if (!line) return result

  const parameter = parameters.get(line.parameter_id)

  if (parameter) {
    result.baseUnit = units[parameter.unit]
    result.displayUnit = units[parameter.display_unit]
    result.displayPrefix = prefixes[parameter.display_prefix]
  }

  return result
}

interface Props {
  component: IChartComponent
  dispatch: (action: any) => void
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: IChartSettings2) => void
  parameterOptions: SelectOption[]
  parametersMap: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  timeUnits: SiUnit[]
  title: string
  units: { [id: string]: SiUnit }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameterOptions: selectParameterOptions(state),
    parametersMap: selectParametersMap(state),
    prefixes: state.parameters.prefixes,
    timeUnits: selectTimeUnits(state),
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(ChartSettings)
