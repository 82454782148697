import { IUser, UserRole } from 'au-nsi/user'

/** Сервис для получение уникальных ключей для объектов Ролей и Пользователей для устранения конфликтов */
const usersKeysService = {
  encryptRole: (id: UserRole['id']) => `role:${id}`,
  decryptRole: (key: string) => parseInt(key.split(':')[1]),
  encryptUser: (id: IUser['id']) => `user:${id}`,
  decryptUser: (key: string) => parseInt(key.split(':')[1]),
  isKeyBelongsToUser: (key: string) => key.split(':')[0] === 'user',
}

export default usersKeysService
