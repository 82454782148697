import { useAppSelector } from '../../../../redux/store'
import { useMemo } from 'react'
import { IGroup } from 'au-nsi-elevator/groups'

/**
 * Выбрать группу с нужным id
 * */
const useGroup = (id: IGroup['id']) => {
  const groups = useAppSelector((state) => state.groups.groups)
  const group = useMemo(() => groups.find((g) => g.id === id), [id, groups])

  return group
}

export default useGroup
