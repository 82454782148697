import { KeyboardEventHandler, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import http, { handleHttpError } from '../../../../utils/http'
import css from '../docs.module.css'
import { actions } from '../docs.reducers'
import { IDocument } from 'au-nsi/documents'

/**
 * Название документа, в режиме редактирования выводит текстовый инпут
 */
const DocumentTitle = (props: Props) => {
  const dispatch = useDispatch()
  const doc = props.document

  // отмена редактирования при клике все поля
  useEffect(() => {
    if (props.isEditing) {
      window.addEventListener('click', props.onFinishEditing, { once: true })
    }
  }, [props.isEditing])

  if (!props.isEditing) {
    const name = doc.is_folder ? doc.name : `${doc.name}.${doc.extension}`
    return <div className={css.documentTitle}>{name}</div>
  }

  // сохранение по Enter, отмена по Escape
  const handleKeyDown: KeyboardEventHandler = (e) => {
    const name = (e.target as HTMLInputElement).value

    if (e.key === 'Enter' && name) {
      dispatch(actions.documentUpdated({ id: doc.id, name }))
      http.patch(`/nsi/v1/documents/` + doc.id, { name }).catch(handleHttpError)
    }

    if (e.key === 'Escape' || e.key === 'Enter') {
      props.onFinishEditing()
    }
  }

  return (
    <input
      className={css.titleInput}
      defaultValue={doc.name}
      autoFocus={true}
      onKeyDown={handleKeyDown}
      onClick={(e) => e.preventDefault()}
    />
  )
}

interface Props {
  document: IDocument
  isEditing: boolean
  onFinishEditing: () => void
}

export default DocumentTitle
