import { DataMap, IDataArray, IDataSelector } from './data.types'

/**
 * Добавить онлайн данные из сокета во временный буфер.
 * newData - общий объект со всеми новыми данными из которого нужно
 * выбрать только нужные данному компоненту.
 */
export const pushToBuffer = (buffer: DataMap, newData: DataMap, query: IDataSelector[]) => {
  // итерируем по всем устройствам и извлекаем из сообщения их данные
  for (const { key, device_id, parameters, irregular } of query) {
    const data = buffer.get(key)
    const newPoints = newData.get(device_id)

    // по этому устройству нет данных, переходим к следующему
    if (!data || !newPoints || !newPoints.ts.length) continue

    if (irregular) {
      pushIrregularData(data, newPoints, parameters[0])
      continue
    }

    // данные могут обновляться, поэтому удаляем пересекающиеся метки времени
    const ts = newPoints.ts[0]
    removeAfter(data, ts)

    for (let i = 0; i < newPoints.ts.length; i++) {
      // разные параметры могут приходить с разной частотой, поэтому в пакете может быть
      // только часть параметров, и если в пакете нет ни одного параметра из нужного нам,
      // то отбрасываем такой пакет
      if (isOnlyNulls(newPoints, parameters, i)) continue

      data.ts.push(newPoints.ts[i])

      for (const parameter of parameters) {
        const values = newPoints[parameter]
        const value = values ? values[i] : null
        data[parameter].push(value)
      }
    }
  }
}

// При получении нерегулярных данных в отличие от обычных фильтруем null значения, т.к.
// они возникают из-за того, что разные параметры приходят в разных пакетах и разным ts
const pushIrregularData = (buffer: IDataArray, data: IDataArray, parameter: string) => {
  const newTs = data.ts
  const newValues = data[parameter]
  let startFound = false

  for (let i = 0; i < newValues.length; i++) {
    const v = newValues[i]
    if (v == null) continue

    if (!startFound) {
      startFound = true
      removeAfter(buffer, newTs[i])
    }

    buffer[parameter].push(v)
    buffer.ts.push(newTs[i])
  }
}

// проверка того, что новые данные содержат только одни null значения
const isOnlyNulls = (points: IDataArray, parameters: string[], index: number) => {
  for (const p of parameters) {
    const values = points[p]
    if (values != null && values[index] != null) return false
  }

  return true
}

// сериализация данных в формат приходящий по сокетам с сервера (см. имплементацию в au-back-ui)
// используется только для тестирования метода pushToBuffer
export const serializeData = (data: Map<string, Array<{ [key: string]: number }>>, query: IDataSelector[]) => {
  let size = 10 // header
  let offset = 10

  for (const row of query) {
    const points = data.get(row.device_id) || []
    size += 4 + (1 + row.parameters.length) * 8 * points.length
  }

  const buffer = new ArrayBuffer(size)
  const view = new DataView(buffer)

  for (const row of query) {
    const params = row.parameters
    const points = data.get(row.device_id) || []

    view.setUint32(offset, points.length)
    offset += 4

    for (const point of points) {
      view.setFloat64(offset, point.ts)
      offset += 8

      for (const param of params) {
        const value = point[param]
        view.setFloat64(offset, value != null ? value : NaN)
        offset += 8
      }
    }
  }

  return buffer
}

/**
 * Копировать онлайн данные из буфера без аггрегации и очистить буфер
 */
export const copyFromBuffer = (id: string, buffer: DataMap, onlineData: DataMap) => {
  const batch = buffer.get(id)
  if (batch.ts.length === 0) return

  const onlineValues = onlineData.get(id)
  removeAfter(onlineValues, batch.ts[0])

  for (const [key, value] of Object.entries(batch)) {
    Array.prototype.push.apply(onlineValues[key], value)
    batch[key] = []
  }
}

interface ProcessBufferOptions {
  id: string
  window: number
  parameters: string[]
  residueSize: number // сколько элементов оставить в буфере
  integerParameters: Set<string>
}

/**
 * Аггрегировать данные из буфера и добавить к накопленным онлайн данным. После аггрегации из буфера ненужные данные
 * удаляются, но остается как минимум элементы попадающие в последнее окно (либо *residueSize* элементов если окно
 * слишком маленькое), это сделано из-за того что могут придти обновленные данные с уже аггрегированными метками времени
 * и их надо будет перезаписать в буфере и аггрегировать заново
 */
export const processBuffer = (buffer: DataMap, onlineData: DataMap, options: ProcessBufferOptions) => {
  const { id, integerParameters } = options
  const batch = buffer.get(id)
  const times = batch.ts

  if (times.length === 0) return

  const isEnoughData = times[times.length - 1] - times[0] >= options.window
  if (!isEnoughData) return

  // максимальное кол-во элементов которое можно удалить
  const maxRemoveCount = times.length - options.residueSize
  let removeCount = 0

  let binTs = times[0] - (times[0] % options.window)
  let nextBinTs = binTs + options.window
  let binStart = 0
  let nextBinStart = findLowerLimit(times, binStart, nextBinTs)

  const points = onlineData.get(id)
  removeAfter(points, binTs)

  // проверка наличия минимальных и максимальных значений (например до увеличения ширины кадра они
  // могли не рассчитываться, тогда после увеличения заполняем их средними значениями)
  for (const p of options.parameters) {
    const minKey = p + ':min'
    const maxKey = p + ':max'
    const avg = points[p]

    if (!points[minKey] || !points[maxKey] || points[minKey].length !== avg.length) {
      points[minKey] = [...avg]
      points[maxKey] = [...avg]
    }
  }

  // аггрегация
  while (nextBinStart !== -1) {
    for (const p of options.parameters) {
      const stats = average(batch[p], binStart, nextBinStart - 1)

      if (integerParameters.has(p)) {
        stats.avg = Math.round(stats.avg)
      }

      points[p].push(stats.avg)
      points[p + ':min'].push(stats.min)
      points[p + ':max'].push(stats.max)
    }

    points.ts.push(binTs)

    // запоминаем границы окон аггрегации для того чтобы удалять элементы только по границе
    if (binStart <= maxRemoveCount) {
      removeCount = binStart
    }

    binStart = nextBinStart
    binTs = nextBinTs
    nextBinTs = binTs + options.window
    nextBinStart = findLowerLimit(times, binStart, nextBinTs)
  }

  // удалить из буфера элементы которые уже не нужны
  if (removeCount > 0) {
    for (const values of Object.values(batch)) {
      values.splice(0, removeCount)
    }
  }
}

// найти наименьший индекс массива i > startIndex такой что times[i] >= ts
const findLowerLimit = (times: number[], startIndex: number, ts: number) => {
  for (let i = startIndex; i < times.length; i++) {
    if (times[i] >= ts) return i
  }

  return -1
}

/**
 * Удалить данные с временами меньшими чем ts, если количество удаляемых элементов будет меньше
 * чем batchSize, то данные будут сохранены (для избежания большого количества мелких операций)
 */
export const truncateData = (data: DataMap, ts: number, batchSize = 20) => {
  for (const values of data.values()) {
    const deleteCount = findLowerLimit(values.ts, 0, ts)

    if (deleteCount > batchSize) {
      // последний элемент всегда сохраняем
      const count = Math.min(deleteCount, values.ts.length - 2)

      for (const points of Object.values(values)) {
        points.splice(0, count)
      }
    }
  }
}

/**
 * Удалить последние элементы с метками времени большими или равными *ts*
 */
export const removeAfter = (data: IDataArray, ts: number) => {
  const lastIndex = data.ts.length - 1
  let index = lastIndex

  while (index >= 0 && data.ts[index] >= ts) {
    index -= 1
  }

  const deleteCount = lastIndex - index

  if (deleteCount > 0) {
    for (const arr of Object.values(data)) {
      arr.splice(index + 1, deleteCount)
    }
  }
}

/**
 * Округление значений параметров с целочисленным типом
 */
export const roundIntegers = (data: DataMap, integerParameters: Set<string>) => {
  for (const measurements of data.values()) {
    for (const [parameter, values] of Object.entries(measurements)) {
      if (parameter !== 'ts' && integerParameters.has(parameter)) {
        for (let i = 0; i < values.length; i++) {
          values[i] = Math.round(values[i])
        }
      }
    }
  }
}

/**
 * Рассчитать среднее, минимальное и максимальное значения элементов массива между индексами i0 и i1
 */
const average = (arr: number[], i0: number, i1: number) => {
  let avg = undefined
  let min = undefined
  let max = undefined

  if (i1 < i0 || i1 >= arr.length) return { min, avg, max }

  let count = 0
  let sum = 0

  for (let i = i0; i <= i1; i++) {
    const value = arr[i]
    if (value == null) continue

    count += 1
    sum += value

    if (min === undefined || value < min) min = value
    if (max === undefined || value > max) max = value
  }

  if (count > 0) avg = sum / count

  return { min, avg, max }
}
