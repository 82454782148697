import { Select, SelectOption } from '@alterouniversal/au-react-components'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store.types'

const ResourceInput = (props: Props) => {
  const [editing, setEditing] = React.useState(false)

  const resources: IResource[] = useSelector((state: ReduxState) => {
    return props.type === 'device' ? state.nsi.equipment : state.parameters.items
  })

  const resource = resources.find((e) => e.id === props.value)

  const handleClick = () => {
    if (props.allowEditing) setEditing(true)
  }

  if (!editing) {
    return (
      <div className="attribute__value" onClick={handleClick}>
        {resource ? resource.name : ''}
      </div>
    )
  }

  const options: SelectOption[] = resources.map((e) => ({ value: e.id, label: e.name }))

  const handleSelect = (id: string) => {
    props.onChange(id, props.index, props.name)
    setEditing(false)
  }

  return <Select name={props.name} options={options} autoFocus={true} value={resource.id} onChange={handleSelect} />
}

interface IResource {
  id: string
  name: string
}

interface Props {
  allowEditing: boolean
  index: number
  name: string
  onChange: (value: string, index: number, name: string) => void
  type: string
  value: string
}

export default ResourceInput
