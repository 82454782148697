import { Select } from '@alterouniversal/au-react-components'
import RemoveButton from '../../../../../shared/Forms/RemoveButton'
import NumberInput from '../../../../../shared/Inputs/NumberInput'
import { DataRate } from '../dataRateUtils'

interface IProps {
  index: number
  editable: boolean
  row: DataRate
  parameters: { label: string; value: string }[]
  onChange: (row: DataRate, index: number) => void
  onRemove: (index: number) => void
}

export const DataRateTableRow = ({ row, parameters, index, onChange, onRemove, editable }: IProps) => {
  const handleChange = (val: string | number, name: string) => {
    onChange({ ...row, [name]: val }, index)
  }

  const handleRemove = () => {
    onRemove(index)
  }

  return editable ? (
    <tr>
      <td>
        <Select value={row.parameter_id} name={'parameter_id'} onChange={handleChange} options={parameters || []} />
      </td>
      <td>
        <NumberInput style={{ width: '100%' }} name={'data_rate'} onChange={handleChange} value={row.data_rate} />{' '}
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton onRemove={handleRemove} id={'Remove'} />
      </td>
    </tr>
  ) : (
    <tr>
      <td>{parameters.find((param) => param.value === row.parameter_id).label}</td>
      <td>{row.data_rate}</td>
    </tr>
  )
}
