import AccessExpirationForm from '../../../../../Collections/Users/DevicesForm/AccessExpirationForm'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import { EquipmentAccessRequest } from 'au-nsi/moderation'

/**
 * Форма заявки на подключение оборудования
 */
const AccessRequestForm = ({ editing }: Props) => {
  const [request, setRequest] = useRecoilState<EquipmentAccessRequest>(selectedDraftRequestState as any)

  const rows = request.request_body.equipment.map((e) => {
    return (
      <div style={{ marginBottom: '1rem' }} key={e.id}>
        <div>
          <b>{e.name}</b> {e.address}
        </div>

        <div style={{ fontSize: 14, marginTop: 13 }} className="text--gray">
          {e.notes}
        </div>
      </div>
    )
  })

  const handleExpirationChange = (expires_at: number) => {
    if (editing) {
      const equipment = request.request_body.equipment.map((e) => ({ ...e, expires_at }))
      setRequest({ ...request, request_body: { ...request.request_body, equipment } })
    }
  }

  return (
    <>
      <div style={{ marginBottom: 28 }}>{rows}</div>
      <AccessExpirationForm
        disabled={!editing}
        expiration={request.request_body.equipment[0]?.expires_at}
        onChange={handleExpirationChange}
      />
    </>
  )
}

interface Props {
  editing: boolean
}

export default AccessRequestForm
