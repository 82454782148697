import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import { compareOptions as compareOpt } from '../VerificationOptions/verification.options'
import NumberInput from '../../../../../shared/Inputs/NumberInput'
import produce from 'immer'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import { PmuCheckOption } from 'au-nsi/settings'

interface IProps {
  acceptChange: boolean
  name: string
  options: PmuCheckOption[]
  onChange: (value: PmuCheckOption[], name: string) => void
}

const rowValueUnits = ['Hz', 'percent', 'deg', 'Hz', 'percent', 'deg']

export const PmuCheckOptionsSettings = ({ name, options, onChange, acceptChange }: IProps) => {
  const intl = useIntl()
  const units = useSelector((state: ReduxState) => state.parameters.units)
  const prefixes = useSelector((state: ReduxState) => state.parameters.prefixes)

  const compareOptions = useMemo(
    () =>
      compareOpt.map((option) => {
        return {
          value: option,
          title: intl.formatMessage({ id: 'dashboards.indicator.settings.operator.' + option }).toLowerCase(),
        }
      }),
    []
  )

  const handleChange = (nm: string, rowIndex: number, colIndex: number, value: number) =>
    onChange(
      produce(options, (draft) => {
        draft[rowIndex][nm][colIndex] = value
      }),
      name
    )

  return (
    <table className={'nsi-settings-table'} style={{ tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'system.verification.pure_criterion' })}</th>
          <th>{intl.formatMessage({ id: 'system.verification.value_setting' })}</th>
          <th>{intl.formatMessage({ id: 'system.verification.duration_setting' })}</th>
        </tr>
      </thead>
      <tbody>
        {options.map((option, index) => {
          return (
            <tr key={option.criterion + index}>
              <td style={{ paddingRight: '10px' }}>
                {intl.formatMessage({ id: 'system.verification.' + option.criterion })}
              </td>
              <td>
                {option.valueSetting.length > 0 && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                      {typeof option.valueSetting[0] === 'string' ? (
                        compareOptions.find((o) => o.value === option.valueSetting[0]).title
                      ) : acceptChange ? (
                        <NumberInput
                          type={'parameter'}
                          units={units}
                          prefixes={prefixes}
                          baseUnit={units[rowValueUnits[index]]}
                          displayUnit={units[rowValueUnits[index]]}
                          fullWidth={true}
                          name={''}
                          onChange={(val) => handleChange('valueSetting', index, 0, val)}
                          value={option.valueSetting[0] as number}
                        />
                      ) : (
                        option.valueSetting[0] + ' ' + units[rowValueUnits[index]].symbol
                      )}
                    </div>
                    <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                      {typeof option.valueSetting[1] === 'string' ? (
                        intl.formatMessage({ id: 'system.verification.' + option.valueSetting[1] })
                      ) : acceptChange ? (
                        <NumberInput
                          type={'parameter'}
                          units={units}
                          prefixes={prefixes}
                          displayUnit={units[rowValueUnits[index]]}
                          baseUnit={units[rowValueUnits[index]]}
                          fullWidth={true}
                          name={''}
                          onChange={(val) => handleChange('valueSetting', index, 1, val)}
                          value={option.valueSetting[1] as number}
                        />
                      ) : (
                        option.valueSetting[1] + ' ' + units[rowValueUnits[index]].symbol
                      )}
                    </div>
                  </div>
                )}
              </td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                    {compareOptions.find((o) => o.value === option.durationSetting[0]).title}
                  </div>
                  <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                    {acceptChange ? (
                      <NumberInput
                        type={'parameter'}
                        units={units}
                        prefixes={prefixes}
                        baseUnit={units['s']}
                        displayUnit={units['s']}
                        fullWidth={true}
                        name={''}
                        onChange={(val) => handleChange('durationSetting', index, 1, val)}
                        value={option.durationSetting[1] as number}
                      />
                    ) : (
                      option.durationSetting[1] + ' ' + units['s'].symbol
                    )}
                  </div>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
