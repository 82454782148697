import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Counter from '../../../shared/Inputs/Counter/Counter'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import dashboardActions from '../dashboard.actions'

/**
 * Форма настроек отображения экрана, выезжает из правого края экрана при подведении курсора
 */
const DashboardControls = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const controls = useSelector((state: ReduxState) => state.dashboards.controls)

  const autoalignOptions = ['none', 'grid', 'components'].map((value) => ({
    value,
    title: intl.formatMessage({ id: 'dashboards.controls.autoalign.' + value }),
  }))

  const editingOptions = [
    { value: true, title: intl.formatMessage({ id: 'dashboards.controls.allow_editing.true' }) },
    { value: false, title: intl.formatMessage({ id: 'dashboards.controls.allow_editing.false' }) },
  ]

  const handleChange = (value: any, key: string) => {
    dispatch(dashboardActions.setControls({ key, value }))
  }

  const handleStepChange = (value: number) => {
    handleChange(value, 'grid_step')
  }

  return (
    <div
      className="dashboard__controls"
      onMouseLeave={() => props.onGridChange(false)}
      onMouseEnter={() => props.onGridChange(true)}
    >
      <button
        onClick={props.onComponentAdd}
        className={'nsi-button default'}
        style={{ marginBottom: '20px', width: '100%' }}
      >
        {intl.formatMessage({ id: 'dashboards.add_widget' })}
      </button>

      <InputRow label={intl.formatMessage({ id: 'dashboards.controls.allow_editing' })}>
        <Dropdown
          name="allow_editing"
          value={controls.allow_editing}
          options={editingOptions}
          onChange={handleChange}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.controls.autoalign' })}>
        <Dropdown name="autoalign" value={controls.autoalign} options={autoalignOptions} onChange={handleChange} />
      </InputRow>

      {controls.autoalign === 'grid' && (
        <InputRow label={intl.formatMessage({ id: 'dashboards.controls.grid_step' })}>
          <Counter value={controls.grid_step} onChange={handleStepChange} min={1} max={50} />
        </InputRow>
      )}
    </div>
  )
}

interface Props {
  onGridChange: (showGrid: boolean) => void
  onComponentAdd?: (componentName) => void
}

export default React.memo(DashboardControls)
