import classnames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import JournalsExport from '../../../shared/Export/JournalsExport'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'

const IncidentsHeader = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const showList = location.pathname.includes('/list')
  const showTable = !showList

  const listIconClass = classnames('pq-trends-header__icon icon-list is-left', { 'is-active': showList })
  const tableIconClass = classnames('pq-trends-header__icon icon-table', { 'is-active': showTable })

  const handleIconClick = (e) => {
    const { mode } = e.target.dataset
    navigate('/dashboards/alerts/' + mode)
  }

  const searchInput = (
    <div style={{ marginLeft: '1em', width: '350px', maxWidth: '80%' }}>
      <SearchInput onChange={props.onSearch} />
    </div>
  )

  return (
    <div className="pq-trends-header">
      <div className={listIconClass} data-mode="list" onClick={handleIconClick} />
      <div className={tableIconClass} data-mode="table" onClick={handleIconClick} />

      {showList && searchInput}
      {showList && <JournalsExport url="/nsi/v1/incidents:export" t0={null} t1={null} style={{ marginLeft: 'auto' }} />}
    </div>
  )
}

interface Props {
  onSearch: (s: string) => void
}

export default IncidentsHeader
