import { ReduxState } from 'redux/store.types'
import http, { handleHttpError } from 'utils/http'
import { setTranslations } from './lp.reducers'

export const loadTranslations = (forceReload: boolean) => (dispatch, getState) => {
  const state: ReduxState = getState()
  if (state.language.translationsLoaded && !forceReload) return

  http
    .get('/nsi/v1/system/translations')
    .then((r) => dispatch(setTranslations(r.data)))
    .catch(handleHttpError)
}
