// Returns the number of days in a month
export const daysCount = (month, year) => {
  return new Date(year, month + 1, 0).getDate()
}

const getPastMonthDays = (month: number, year: number, count: number): IDate[] => {
  /*
   Getting grey-dates from the top of calendar to fill all of the first week
   (In case the month does not start on Monday)
   */
  const days = []
  const date = month === 0 ? { month: 11, year: year - 1 } : { month: month - 1, year }

  const pastMonthDaysCount = daysCount(date.month, date.year)

  for (let day = pastMonthDaysCount - count + 1; day <= pastMonthDaysCount; ++day) {
    days.push({ day, ...date })
  }

  return days
}

const getNextMonthDays = (month: number, year: number, count: number): IDate[] => {
  /*
   Getting grey-dates from the bottom of the calendar to fill all of the last week
   (In case the month does not end on Sunday)
   */
  const days = []
  const date =
    month === 11
      ? (() => {
          return { month: 0, year: year + 1 }
        })()
      : { month: month + 1, year }

  for (let day = 1; day <= count; ++day) {
    days.push({ day, ...date })
  }

  return days
}

const getMonthDays = (month: number, year: number): IDate[] => {
  const days = []
  for (let day = 1; day <= daysCount(month, year); ++day) {
    days.push({ day, month, year })
  }
  return days
}

export const getMonthCalendarDays = (month: number, year: number): IDate[][] => {
  let monthStartDay = new Date(year, month, 1).getDay()
  let monthEndDay = new Date(year, month, daysCount(month, year)).getDay()

  if (monthStartDay === 0) monthStartDay = 7
  if (monthEndDay === 0) monthEndDay = 7

  const days = [
    ...getPastMonthDays(month, year, monthStartDay - 1),
    ...getMonthDays(month, year),
    ...getNextMonthDays(month, year, 7 - monthEndDay),
  ]

  /* Splitting by weeks */
  const daysByWeeks = []
  for (let i = 0; i < days.length; i += 7) {
    daysByWeeks.push(days.slice(i, i + 7))
  }

  return daysByWeeks
}

export interface IDate {
  year: number
  month: number
  day?: number
}
