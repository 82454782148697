import React from 'react'

import './counter.styles.css'

const Counter = (props: Props) => {
  // ref is used to allow debouncing changes in parent component and not rerender on every click
  const ref = React.useRef<HTMLDivElement>()

  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current.innerText = props.value + ''
    }
  }, [props.value, ref.current])

  const handleClick = (e) => {
    const inc = e.target.dataset.id === '-' ? -1 : 1
    const value = +ref.current.innerText + inc
    const inRange = (props.min == null || value >= props.min) && (props.max == null || value <= props.max)

    if (inRange) {
      ref.current.innerText = value + ''
      props.onChange(value, props.name)
    }
  }

  return (
    <div className="input-counter">
      <div className="input-counter__button" data-id="-" onClick={handleClick}>
        &minus;
      </div>
      <div ref={ref} />
      <div className="input-counter__button" data-id="+" onClick={handleClick}>
        +
      </div>
    </div>
  )
}

interface Props {
  name?: string
  value: number
  min?: number
  max?: number
  onChange: (v: number, name: string) => void
}

export default Counter
