import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { selectReceivers } from '../Nsi/nsi.selectors'

const getSelectedId = (state: ReduxState) => state.oplim.deviceId
const getSearch = (state: ReduxState) => state.oplim.search

export const getMatchedDevices = createSelector([selectReceivers, getSearch], (devices, search) => {
  if (!search) return devices

  const s = search.toLowerCase()
  return devices.filter((d) => d.name.toLowerCase().includes(s) || d.shortname.toLowerCase().includes(s))
})

export const getSelectedDevices = createSelector([getMatchedDevices, getSelectedId], (registrators, id) =>
  registrators.filter((r) => r.id === id)
)
