import {
  IButtonComponent,
  IChartComponent,
  IChartSettings2,
  IDashboardComponent,
  IGanttComponent,
  IGaugeComponent,
  IImageComponent,
  IMapComponent,
  IPhasePortraitComponent,
  ISVGComponent,
  IScaleComponent,
  ITableComponent,
  ITextComponent,
  IVectorChartComponent,
  IWindroseComponent,
  IHistogramComponent,
  IIndicatorComponent,
  IBarChartComponent,
  ITemplateComponent,
} from 'au-nsi/dashboards'
import { COLORS } from '../../shared/constants'
import { WidgetType } from './dashboard.types'
import { generatePosition } from './position.generator'

type Components = IDashboardComponent[]

type ComponentFactory = (
  components: Components,
  dashboard: string,
  x: number,
  y: number,
  vw: number
) => IDashboardComponent

const generateGantt: ComponentFactory = (components, dashboard, x, y): IGanttComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'gantt',
    settings: { timespan: 3600_000, incidents: [], equipment: [], show_every_device: true },
  }
}

const generateChart: ComponentFactory = (components, dashboard, x, y): IChartComponent => {
  const lines = [{ device_id: null, parameter_id: null, color: null }]
  const settings: IChartSettings2 = {
    version: 2,
    title: '',
    prediction: false,
    aggregationMode: 2,
    mirrorY: false,
    axes: [{ position: 'right', lines, thresholds: [], fill: false, bars: false, barsWidth: 1, stack: false }],
  }

  return { ...generatePosition(components, dashboard, x, y, 0.3), type: 'linear_chart', settings }
}

const generateVectorChart: ComponentFactory = (components, dashboard, x, y): IVectorChartComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'vector_chart',
    settings: {
      title: '',
      vectors: [],
      voltage_nominal: 0,
      current_nominal: 0,
      vector_style: '1',
      grid_parameter: 'current',
      fixed_vector_name: '',
    },
  }
}

const generateGauge: ComponentFactory = (components, dashboard, x, y): IGaugeComponent => {
  const boundaries = { lower: 0, upper: 100 }
  const position = generatePosition(components, dashboard, x, y)

  // т.к. у датчика отсутствует нижняя часть дуги в 90 градусов и он не является полным кругом,
  // то его высота получается немного меньше ширины
  position.h = position.w * (0.55 + 0.45 * Math.cos(Math.PI / 4))

  return {
    ...position,
    type: 'gauge',
    settings: { title: '', mode: 'arrow', device_id: null, parameter_id: null, precision: 1, boundaries, ranges: [] },
  }
}

const generateScale: ComponentFactory = (components, dashboard, x, y): IScaleComponent => {
  const boundaries = { lower: 0, upper: 1000 }

  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'gauge_linear',
    settings: { title: '', mode: 'default', device_id: null, parameter_id: null, precision: 1, boundaries, ranges: [] },
  }
}

const generateTable: ComponentFactory = (components, dashboard, x, y): ITableComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'table',
    settings: {
      columns: [{ column_index: 0, title: '', parameter_id: null, display_unit: null, display_prefix: null }],
      equipment: [],
      title: '',
      display: 'column',
    },
  }
}

const generateText: ComponentFactory = (components, dashboard, x, y, vw): ITextComponent => {
  const position = generatePosition(components, dashboard, x, y)
  position.h = 60 / vw
  position.w = 300 / vw

  return {
    ...position,
    type: 'text',
    settings: {
      text: '',
      align: 'center space-around',
      text_size: 16,
      text_color: '#FFFFFF',
      text_style: 'normal',
      vars_size: 16,
      vars_color: '#FFFFFF',
      vars_style: 'normal',
      variables: [],
      conditions: [],
    },
  }
}

const generatePhasePortrait: ComponentFactory = (components, dashboard, x, y): IPhasePortraitComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'phase_portrait',
    settings: {
      title: '',
      timespan: 2000,
      equipment: [],
      x_parameter_id: null,
      x_min: 0,
      x_max: 1000,
      y_parameter_id: null,
      y_min: 0,
      y_max: 1000,
    },
  }
}

const generateMap: ComponentFactory = (components, dashboard, x, y): IMapComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'map',
    settings: {
      title: '',
      center: { lat: 0, lng: 0 },
      zoom: 1,
      type: 'value',
      equipment: [],
      area: [],
      parameter_id: null,
      parameter_angle_id: null,
      parameter_unit: null,
      parameter_prefix: null,
      parameter_min: 0,
      parameter_max: 1000,
    },
  }
}

const generateButton: ComponentFactory = (components, dashboard, x, y, vw): IButtonComponent => {
  return {
    ...generatePosition(components, dashboard, x, y, 240 / vw, 40 / vw),
    type: 'button',
    settings: {
      type: 'text',
      action: 'command',
      text: '',
      style: 'default',
      font_size: 16,
      navigate_target: '',
      commands: [],
    },
  }
}

const generateSVG: ComponentFactory = (components, dashboard, x, y): ISVGComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'svg_diagram',
    settings: {
      title: '',
      image_id: '',
      text: [],
      color: [],
      visibility: [],
      commands: [],
      links: [],
      movements: [],
      rotations: [],
    },
  }
}

const generateImage: ComponentFactory = (components, dashboard, x, y): IImageComponent => {
  return {
    ...generatePosition(components, dashboard, x, y),
    type: 'image',
    settings: { title: '', image_id: '', dynamic: false, conditions: [] },
  }
}

const generateWindrose: ComponentFactory = (components, dashboard, x, y, vw): IWindroseComponent => {
  const position = generatePosition(components, dashboard, x, y)
  position.h = position.w + 20 / vw

  return {
    ...position,
    type: 'windrose',
    settings: {
      title: '',
      type: 'detailed',
      sectors: 16,
      parameters: [
        {
          device_id: '',
          direction_parameter_id: '',
          speed_parameter_id: '',
          timespan: 30 * 24 * 60 * 60 * 1000,
        },
      ],
    },
  }
}

const generateHistogram: ComponentFactory = (components, dashboard, x, y, vw): IHistogramComponent => {
  const position = generatePosition(components, dashboard, x, y)
  position.h = position.w + 20 / vw

  return {
    ...position,
    type: 'histogram',
    settings: {
      title: '',
      min: undefined,
      max: undefined,
      step_mode: 'auto',
      step: undefined,
      bins: undefined,
      parameters: [
        {
          device_id: '',
          parameter_id: '',
          timespan: 60 * 60 * 1000,
          time: undefined,
          refresh_interval: undefined,
          color: COLORS.warning,
        },
      ],
    },
  }
}

const generateIndicator: ComponentFactory = (components, dashboard, x, y, vw): IIndicatorComponent => {
  const position = generatePosition(components, dashboard, x, y)
  position.h = position.w + 20 / vw

  return {
    ...position,
    type: 'indicator',
    settings: {
      title: '',
      type: 'circle',
      device_id: '',
      parameter_id: '',
      image_id: undefined,
      conditions: [
        {
          operator: '>',
          value: 0,
          range: [0, 0],
          action: 'none',
          color: COLORS.warning,
        },
      ],
    },
  }
}

const generateBarChart: ComponentFactory = (components, dashboard, x, y, vw): IBarChartComponent => {
  const position = generatePosition(components, dashboard, x, y)
  position.h = position.w + 20 / vw

  return {
    ...position,
    type: 'bar_chart',
    settings: {
      title: '',
      bar_width: 100,
      show_names: true,
      show_values: false,
      mirror_axis: false,
      parameters: [
        {
          device_id: '',
          parameter_id: '',
          color: COLORS.warning,
          name: '',
          mirror: false,
        },
      ],
      thresholds: [],
    },
  }
}

const generateTemplateSettings: ComponentFactory = (components, dashboard, x, y): ITemplateComponent => {
  const position = generatePosition(components, dashboard, x, y)

  return { ...position, type: 'template_variables', settings: { title: 'Template variables' } }
}

export const generators: { [type in WidgetType]: ComponentFactory } = {
  bar_chart: generateBarChart,
  button: generateButton,
  gantt: generateGantt,
  gauge: generateGauge,
  gauge_linear: generateScale,
  histogram: generateHistogram,
  image: generateImage,
  indicator: generateIndicator,
  linear_chart: generateChart,
  map: generateMap,
  phase_portrait: generatePhasePortrait,
  svg_diagram: generateSVG,
  table: generateTable,
  template_variables: generateTemplateSettings,
  text: generateText,
  vector_chart: generateVectorChart,
  windrose: generateWindrose,
}
