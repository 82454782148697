import { Equipment } from 'au-nsi/equipment'

export const defaultStatusControls: Record<Equipment['state'], boolean> = {
  RUNNING: true,
  ERROR: true,
  WARNING: true,
  READING_CONF: true,
  READING_CONF_ERROR: true,
  READING_CONF_SUCCESS: true,
  STARTING: true,
  STOPPED: true,
  STOPPING: true,
}

// Для пользователя видны только visibleControls, хотя фактических статусов гораздно больше.
// Функция призвана на каждый из статусов устройства возращать наиболее близкий из visibleControls
export const mapEquipmentStateMapping = (state: Equipment['state']): Equipment['state'] => {
  switch (state) {
    case 'STARTING':
    case 'RUNNING':
    case 'READING_CONF':
    case 'STOPPING':
      return 'RUNNING'
    case 'ERROR':
    case 'READING_CONF_ERROR':
      return 'ERROR'
    case 'WARNING':
      return 'WARNING'
    case 'READING_CONF_SUCCESS':
    case 'STOPPED':
      return 'STOPPED'
    default:
      unreachable(state)
  }
}

// Отображамые в фильтрах статусы
export const visibleControls: Equipment['state'][] = ['RUNNING', 'ERROR', 'WARNING', 'STOPPED']

export const getDeviceStateDescription = (device: Equipment) => {
  switch (device.state) {
    case 'STARTING':
    case 'RUNNING':
    case 'READING_CONF':
    case 'STOPPING':
      return { color: 'var(--text-success)', label: 'RUNNING', title: null }
    case 'WARNING':
      return { color: 'var(--warning)', label: 'WARNING', title: device.error }
    case 'ERROR':
    case 'READING_CONF_ERROR':
      return { color: 'var(--danger-text)', label: 'ERROR', title: device.error }
    case 'READING_CONF_SUCCESS':
    case 'STOPPED':
      return { color: 'var(--gray-30)', label: 'STOPPED', title: null }
    default:
      unreachable(device.state)
  }
}

function unreachable(state: never): never {
  throw new Error(`Unexpected device state ` + state)
}
