import React from 'react'
import { useSelector } from 'react-redux'
import { selectIrregularParameters } from '../pages/Parameters/params.selectors'
import DataService, { IDataServiceOptions } from '../services/data/data.service'

const useDataService = (chartId: number, options?: Partial<IDataServiceOptions>) => {
  const irregularParameters = useSelector(selectIrregularParameters)

  const service = React.useMemo(() => new DataService(chartId, options), [])

  React.useEffect(() => service.setIrregularParameters(irregularParameters), [irregularParameters])

  React.useEffect(() => () => service.destroy(), [])

  return service
}

export default useDataService
