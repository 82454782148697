import { ReactComponent as EditIcon } from 'icons/pen.svg'
import { ReactComponent as DeleteIcon } from 'icons/trash.svg'
import { ReactComponent as DownloadIcon } from 'icons/download.svg'
import { ReactComponent as CopyIcon } from 'icons/copy.svg'
import { ReactComponent as PasteIcon } from 'icons/paste.svg'
import { ReactComponent as CutIcon } from 'icons/cut.svg'
import { ReactComponent as ViewIcon } from 'icons/view.svg'
import { ReactComponent as DisbandIcon } from 'icons/decline.svg'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { ReactComponent as SettingsIcon } from 'icons/cog.svg'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { ReactComponent as LockIcon } from '../../../icons/lock.svg'

interface IProps {
  type:
    | 'delete'
    | 'edit'
    | 'download'
    | 'paste'
    | 'copy'
    | 'cut'
    | 'view'
    | 'disband'
    | 'cancel'
    | 'select'
    | 'settings'
    | 'access'
  onClick: (e) => void

  icon?: ReactNode
  label?: string
}

type PropsWithoutType = IProps | Required<Omit<IProps, 'type'>>

type Props = PropsWithoutType | IProps

const ContextMenuOption = (props: PropsWithChildren<Props>) => {
  const intl = useIntl()
  const isTyped = 'type' in props

  const renderIcon = () => {
    if (props.icon || !isTyped) return props.icon

    switch (props.type) {
      case 'delete':
        return <DeleteIcon />
      case 'edit':
        return <EditIcon />
      case 'download':
        return <DownloadIcon />
      case 'copy':
        return <CopyIcon />
      case 'paste':
        return <PasteIcon />
      case 'cut':
        return <CutIcon />
      case 'view':
        return <ViewIcon />
      case 'cancel':
      case 'disband':
        return <DisbandIcon />
      case 'select':
        return <CheckIcon />
      case 'settings':
        return <SettingsIcon />
      case 'access':
        return <LockIcon />
    }
  }

  const renderLabel = () => {
    if (props.label || !isTyped) return props.label

    switch (props.type) {
      case 'delete':
      case 'edit':
      case 'download':
      case 'view':
      case 'disband':
      case 'cancel':
      case 'select':
      case 'copy':
      case 'settings':
        return intl.formatMessage({ id: 'common.' + props.type })
      case 'cut':
      case 'paste':
        return intl.formatMessage({ id: 'documents.actions.' + props.type })
      case 'access':
        return intl.formatMessage({ id: 'documents.actions.access_settings' })
    }
  }

  const className = cn({
    'nsi-dropdown__item': true,
    danger: isTyped && props.type === 'delete',
    default: isTyped && props.type !== 'delete',
  })

  return (
    <div className={className} onClick={props.onClick}>
      {renderIcon()}
      {renderLabel()}
      {props.children}
    </div>
  )
}

export default ContextMenuOption
