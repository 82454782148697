import React from 'react'
import { useIntl } from 'react-intl'

/**
 * Форма включения браузерных уведомлений
 */
const UserNotificationsForm = () => {
  const intl = useIntl()

  return (
    <React.Fragment>
      <h2 className="system__title" style={{ marginTop: '45px', marginBottom: '10px' }}>
        {intl.formatMessage({ id: 'nav.system.notifications' })}
      </h2>
      {'Notification' in window && <DesktopSettings intl={intl} />}
    </React.Fragment>
  )
}

const DesktopSettings = ({ intl }) => {
  const [status, setStatus] = React.useState(Notification.permission)

  const requestPirmission = () => {
    Notification.requestPermission().then(setStatus)
  }

  switch (status) {
    case 'default':
      return (
        <button className="nsi-button default" onClick={requestPirmission}>
          {intl.formatMessage({ id: 'system.profile.enable_desktop_notifications' })}
        </button>
      )
    case 'granted':
      return (
        <div className="text--success">
          {intl.formatMessage({ id: 'system.profile.desktop_notifications_enabled' })}
        </div>
      )
    case 'denied':
      return (
        <div className="text--warning">
          {intl.formatMessage({ id: 'system.profile.desktop_notifications_disabled' })}
        </div>
      )
    default:
      return null
  }
}

export default UserNotificationsForm
