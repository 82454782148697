import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../redux/store'
import { selectEnabledModules } from '../App/app.selectors'
import ChartPlayer from '../ChartPlayer/ChartPlayer'
import LfoCharts from '../LFO/LfoCharts'
import VectorDiagrams from '../VectorDiagram/VectorDiagrams'
import DigitalsCharts from './DigitalsCharts'
import GanttTable from './GanttTable'
import PowerCharts from './PowerCharts'

const RegistratorPage = () => {
  const navigate = useNavigate()
  const { id, page } = useParams()

  const modules = useAppSelector(selectEnabledModules)
  const equipment = useAppSelector((state) => state.nsi.equipment)
  const device = useMemo(() => equipment.find((e) => e.id === id), [id, equipment])

  const renderCharts = () => {
    if (!device) return null

    if (page === 'lfo') return <LfoCharts selectedRegistrators={[device]} />
    else if (page === 'power') return <PowerCharts device={device} />
    else if (page === 'unbalance') return <VectorDiagrams selectedRegistrators={[device]} />
    else if (page === 'digitals') return <DigitalsCharts device={device} />
  }

  return (
    <>
      <ChartPlayer page={page} />
      <div className="pq">
        <GanttTable navigate={navigate} modules={modules} registrator={device} page={page} id={id} />
        {renderCharts()}
      </div>
    </>
  )
}

export default RegistratorPage
