import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectEquipmentMap } from '../../../../Nsi/nsi.selectors'
import PlusButton from '../../../../../shared/Forms/PlusButton'
import { VerificationLink, VerificationService } from '../verification.types'
import { verificationConfig } from '../verification.utils'
import { VerificationTableRow } from './VerificationTableRow'

interface IProps {
  type: VerificationService
  data: VerificationLink[]
  onChange: (links: any, name: string) => void
  onAddLink: () => void
  acceptChange?: boolean
  acceptedReferenceIds: { value: string; label: string }[]
  acceptedDeviceIds: { value: string; label: string }[]
  name: string
}

export const VerificationTable = ({
  type,
  data,
  onChange,
  onAddLink,
  acceptChange = false,
  acceptedDeviceIds,
  acceptedReferenceIds,
  name,
}: IProps) => {
  const utils = verificationConfig[type]

  const intl = useIntl()
  const equipment = useSelector(selectEquipmentMap)

  const locPeriodOptions = utils.periodOptions.map((option) => {
    return { ...option, title: option.title + ' ' + intl.formatMessage({ id: option.dimension }) }
  })

  const handleLinkChange = (link: VerificationLink, index: number) => {
    onChange([...data.slice(0, index), link, ...data.slice(index + 1)], name)
  }

  const handleLinkRemove = (index: number) => {
    onChange([...data.slice(0, index), ...data.slice(index + 1)], name)
  }

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {utils.tableCols.map((col) => (
            <th key={col}>{intl.formatMessage({ id: col })}</th>
          ))}
          <th style={{ width: '3ch' }} />
        </tr>
      </thead>
    )
  }

  return (
    <>
      <table className={'nsi-settings-table'} style={{ tableLayout: 'fixed' }}>
        {renderTableHeader()}
        <tbody>
          {data.map((link, index) => (
            <VerificationTableRow
              index={index}
              key={link.device_id + index}
              link={link}
              acceptChange={acceptChange}
              periodOptions={locPeriodOptions}
              acceptedDevices={[
                ...acceptedDeviceIds,
                { value: link.device_id, label: equipment.get(link.device_id)?.name || '' },
              ]}
              acceptedReferences={acceptedReferenceIds}
              onChange={handleLinkChange}
              onRemove={handleLinkRemove}
              equipment={equipment}
              type={type}
            />
          ))}
        </tbody>
      </table>
      {acceptChange && (
        <PlusButton
          onClick={onAddLink}
          style={{ marginLeft: '0' }}
          textId={intl.formatMessage({ id: 'system.verification.addReference' })}
        />
      )}
    </>
  )
}
