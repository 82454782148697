import { IGanttSettings } from 'au-nsi/dashboards'
import { loadArchive } from '../../pages/Incidents/incident.actions'
import { store } from '../../redux/store'
import { Boundaries } from '../../shared/interfaces'
import EventEmitter, { combineLatest, filter, map } from '../../utils/events'
import Clock from '../clock/clock.service'
import { GanttData, GanttTimes, GanttTotalStats } from './gantt.interfaces'
import { groupIncidents, selectRange, store2emitter } from './gantt.utils'

const MIN_FRAME = 10 * 60_000
const MAX_FRAME = 24 * 60 * 60_000

const store$ = store2emitter(store)

export default class GanttService {
  private cache: Map<string, EventEmitter<GanttData[]>> = new Map()

  private frame = 3600_000 // ширина кадра на диаграмме ганта
  // ширина смены (время за которое отображается обобщенная сатистика)
  private shiftFrame: number = 10 * 3600_000
  private ganttTime: number = Date.now()

  /**
   * флаг указывающий на наличие изменений (изменен кадр, загружены новые данные и тд)
   * используется для оптимизации
   */
  private isChanged = false

  private lastT0 = 0 // время конца смены
  private lastT1 = 0 // начало смены
  private lastPlayerTime = 0

  // настройки диаграммы заданные пользователем
  private settings: IGanttSettings = null
  private settings$ = new EventEmitter<IGanttSettings>()

  private seNames = new Map<number, string>()

  /* размеры диаграммы ганта в пикселях, используются для оптимизации */
  public boundaries: Boundaries = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: 0,
    width: 10000,
  }

  time$: EventEmitter<GanttTimes>
  total$: EventEmitter<GanttTotalStats>
  private data$: EventEmitter<any>

  constructor(private clock: Clock) {
    this.time$ = map(filter(clock.time$, this.shouldUpdate), this.addGanttTime)

    const state$ = combineLatest(store$, this.settings$)

    // группировка и фильтрация инцидентов при изменении настроек либо инцидентов
    const data$ = map(state$, ([state, settings]) => {
      this.seNames = state.seNames

      const incidents = groupIncidents(state.incidents.incidents, state.seRules, settings)
      const archive = groupIncidents(state.incidents.archive, state.seRules, settings)

      return { ...state.incidents, incidents, archive }
    })

    this.data$ = combineLatest(this.time$, data$)
    this.total$ = map(this.data$, this.calcTotalStats)
  }

  select(id: string) {
    this.isChanged = true
    let result = this.cache.get(id)

    if (!result) {
      result = this._select(id)
      this.cache.set(id, result)
    }

    return result
  }

  setFrame(frame: number) {
    if (frame < this.shiftFrame / 2 && frame > this.shiftFrame / 50) {
      this.frame = frame
      this.isChanged = true
      this.adjustPlayerTime()
    }
  }

  setOnline() {
    this.clock.setOnline()
  }

  setGanttTime(ts: number, force = false) {
    const serverTime = this.clock.getServerTime()

    if (ts < serverTime) {
      if (force) {
        this.clock.setReplay()
        this.clock.setPlayerTime(ts)
      }

      this.ganttTime = Math.round(ts)
      this.isChanged = true
      this.adjustPlayerTime()
    }
  }

  getGanttTime(): GanttTimes {
    return {
      playerTime: this.clock.getPlayerTime(),
      serverTime: this.clock.getServerTime(),
      ganttTime: this.ganttTime,
      frame: this.frame,
    }
  }

  setSettings(settings: IGanttSettings) {
    let frame = settings.timespan

    if (!frame) frame = 3600_000
    if (frame < MIN_FRAME) frame = MIN_FRAME
    if (frame > MAX_FRAME) frame = MAX_FRAME

    this.isChanged = true
    this.frame = frame
    this.shiftFrame = frame * 10

    this.settings = settings
    this.settings$.next(settings)
  }

  /* переместить курсор со временем плеера на диаграмму ганта, если он вышел за ее пределы */
  private adjustPlayerTime() {
    const playerTime = this.clock.getPlayerTime()

    if (playerTime > this.ganttTime) {
      return this.clock.setPlayerTime(this.ganttTime)
    }

    if (playerTime < this.ganttTime - this.frame) {
      return this.clock.setPlayerTime(this.ganttTime - this.frame)
    }
  }

  /**
   * оптимизация
   * уведомлять подписчиков об изменении времени только в том случае
   * если это приведет к изменению хотя бы в 1px
   */
  private shouldUpdate = ({ playerTime }) => {
    if (this.isChanged) return true

    const dt_min = this.frame / this.boundaries.width
    const dt = Math.abs(playerTime - this.lastPlayerTime)

    return dt_min < dt
  }

  private addGanttTime = ({ playerTime, serverTime }) => {
    let ganttTime = this.ganttTime

    if (ganttTime < playerTime) {
      ganttTime = playerTime
    } else if (ganttTime > playerTime + this.frame) {
      ganttTime = playerTime + this.frame
    }

    const times = { playerTime, serverTime, ganttTime, frame: this.frame }
    this.syncInstanceData(times)
    return times
  }

  private syncInstanceData = (times) => {
    if (this.ganttTime !== times.ganttTime) {
      this.ganttTime = times.ganttTime
    }

    if (this.lastPlayerTime !== times.playerTime) {
      this.lastPlayerTime = times.playerTime
    }

    if (this.isChanged === true) {
      this.isChanged = false
    }
  }

  // все сигнальные ситуации за все время смены
  private calcTotalStats = ([times, state]: [GanttTimes, any]) => {
    const { ganttTime, frame: ganttFrame } = times

    let t1 = times.serverTime
    let t0 = t1 - this.shiftFrame

    if (t1 < ganttTime || t0 > ganttTime - ganttFrame) {
      if (this.lastT1 < ganttTime || this.lastT0 > ganttTime - ganttFrame) {
        this.lastT1 = ganttTime
        this.lastT0 = this.lastT1 - this.shiftFrame
      }

      t1 = this.lastT1
      t0 = this.lastT0
    }

    let items = null

    // online mode
    if (t0 >= state.incidents_start) {
      items = state.incidents
    }

    // history mode, get incidents from archive
    if (t0 < state.incidents_start) {
      items = state.archive

      // load history if it's not already loaded
      if (state.archive_start !== t0 || state.archive_end !== t1) {
        store.dispatch(loadArchive(t0, t1) as any)
      }
    }

    const warning = items.get('summary|warning')
    const danger = items.get('summary|danger')

    return { warning, danger, t0, t1, ganttTime, ganttFrame }
  }

  /**
   * получение сигнальных ситуаций попадающих в кадр диаграммы ганта
   * @param id идентификатор оборудования
   */
  private _select(id: string) {
    return map(this.data$, ([{ ganttTime }, data]) => {
      const t1 = ganttTime
      const t0 = t1 - this.frame

      const groups = t0 >= data.incidents_start ? data.incidents : data.archive

      // первый элемент - инциденты которые будут отображаться в основной строке с названием устройства
      // все последующие элементы обозначают дополнительные вложенные строки на каждую сигнальную ситуацию
      // у которой выставлен флаг show_separately и появятся только при наличии инцидентов по этим СС
      const result: GanttData[] = [{ id: 0, name: '', warning: [], danger: [], t0, t1 }]

      for (const row of this.settings.incidents) {
        // инциденты уже сгруппированы по нужному ключу, остается только выбрать попадающие в кадр
        const warnings = groups.get(`${id}|formula|${row.signal_event_id}|warning`) || []
        const dangers = groups.get(`${id}|formula|${row.signal_event_id}|danger`) || []

        const warning = selectRange(warnings, t0, t1)
        const danger = selectRange(dangers, t0, t1)

        if (!row.show_separately) {
          Array.prototype.push.apply(result[0].warning, warning)
          Array.prototype.push.apply(result[0].danger, danger)
        } else if (warning.length > 0 || danger.length > 0) {
          const name = this.seNames.get(row.signal_event_id)
          result.push({ id: row.signal_event_id, name, warning, danger, t0, t1 })
        }
      }

      return result
    })
  }
}
