import { Parameter, ParameterGroup, SiPrefix, SiUnit } from 'au-nsi/parameters'
import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { array2map } from '../../utils/misc'
import { ParameterDn } from './params.interfaces'
import { isInteger } from './params.utils'

const parametersSelector = (state: ReduxState) => state.parameters.items
const groupsSelector = (state: ReduxState) => state.parameter_groups.items
const unitsSelector = (state: ReduxState) => state.parameters.units
const prefixesSelector = (state: ReduxState) => state.parameters.prefixes

export const selectParameterOptions = createSelector(parametersSelector, (parameters) => {
  return parameters.map((p) => ({ value: p.id, label: p.name }))
})

export const selectDenormalizedParameters = createSelector(
  [parametersSelector, groupsSelector, unitsSelector, prefixesSelector],
  (
    parameters: Parameter[],
    groups: ParameterGroup[],
    units: { [id: string]: SiUnit },
    prefixes: { [id: string]: SiPrefix }
  ): ParameterDn[] => {
    return parameters.map((p) => {
      const group = groups.find((g) => g.id === p.group_id)
      const unit = units[p.unit]
      const prefix = prefixes[p.prefix]
      const display_unit = units[p.display_unit]
      const display_prefix = prefixes[p.display_prefix]

      return { ...p, group, unit, prefix, display_unit, display_prefix }
    })
  }
)

export const selectParametersMap = createSelector(parametersSelector, array2map)

export const selectDenormalizedParametersMap = createSelector(selectDenormalizedParameters, array2map)

// из массива параметров создать маппинг id -> name
export const selectParametersNames = createSelector([parametersSelector], (parameters) => {
  const result = new Map<string, string>()

  for (const p of parameters) {
    result.set(p.id, p.name)
  }

  return result
})

export const selectIntegerParameters = createSelector(parametersSelector, (parameters) => {
  const result = parameters.filter((p) => isInteger(p.type)).map((p) => p.id)
  return new Set(result)
})

export const selectIrregularParameters = createSelector(parametersSelector, (parameters) => {
  const result = parameters.filter((p) => p.is_irregular).map((p) => p.id)
  return new Set(result)
})

export const selectTimeUnits = createSelector(unitsSelector, (units) => {
  const timeUnits: SiUnit[] = []

  for (const unit of Object.values(units)) {
    if (unit.id === 's' || unit.base_unit === 's') {
      timeUnits.push(unit)
    }
  }

  // сортировка по убыванию - от дней до наносекунд
  timeUnits.sort((a, b) => {
    const factor1 = a.base_unit_factor || 1
    const factor2 = b.base_unit_factor || 1
    return factor2 - factor1
  })

  return timeUnits
})

const sortByName = <T extends { name: string }>(items: T[]): T[] => {
  const copy = [...items]
  copy.sort((a, b) => (a.name < b.name ? -1 : 1))

  return copy
}

export const selectOrderedParameters = createSelector(parametersSelector, sortByName)
export const selectOrderedGroups = createSelector(groupsSelector, sortByName)
