import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Modal from '../../../shared/Modal/Modal'
import treeBuilder from '../Tree/tree.builder'
import Tree from '../../../shared/Tree/Tree'
import useTree from '../../../shared/Tree/useTree'
import { TNode } from '../../../shared/Tree/tree.types'
import { Region } from 'au-nsi/equipment'
import ModalFooter from 'shared/Modal/ModalFooter'

/**
 * Модальное окно для выбора группы (региона, логического оборудования и т.д.)
 * для перемещения выбранного элемента
 */
const GroupSelectorModal = (props: Props) => {
  const intl = useIntl()

  // т.к. перемещать можно только в группу, то в топологии не нужно отображать листья дерева
  const nodes = useMemo(() => treeBuilder.buildRegionsTree(props.groups, true), [props.groups])
  const tree = useTree<Region>({ persistenceStrategy: 'none', key: 'GroupSelectorModalTree' })

  useEffect(() => {
    tree.handleToggle(1)
  }, [])

  const renderBranch = ({ node }: { node: TNode<Region> }) => {
    return (
      <span data-id={node.id} className="tpl-node__padding">
        <span data-id={node.id}>{node.name}</span>
      </span>
    )
  }

  const handleSubmit = () => {
    if (tree.selectedNodeId) props.onSelect(tree.selectedNodeId)
  }

  // получить полный путь до выбранной группы
  const getSelectedPath = () => {
    const { groups } = props
    if (!tree.selectedNodeId) return ''

    const selectedGroup = groups.find((g) => g.id === tree.selectedNodeId)
    const path = selectedGroup.path.split('.')

    const findGroupName = (id) => {
      // eslint-disable-next-line
      const group = groups.find((g) => g.id == id)
      return group ? group.name : ''
    }

    return path.map(findGroupName).join(' / ')
  }

  const path = getSelectedPath()

  return (
    <Modal onClose={props.onClose}>
      <h2 style={{ paddingBottom: 0 }}>{intl.formatMessage({ id: props.textTitle })}</h2>
      <div className="move-node-tree">
        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <Tree nodes={nodes} {...tree} nodeContentRenderer={renderBranch} />
        </div>

        <div style={{ margin: '8px 16px' }}>{path}</div>
        <ModalFooter
          onCancel={props.onClose}
          onSave={handleSubmit}
          saveMessage={intl.formatMessage({ id: props.textSelect })}
          disabled={tree.selectedNodeId == null}
        />
      </div>
    </Modal>
  )
}

interface Props {
  groups: Region[]
  onClose: () => void
  onSelect: (groupId: number | string) => void
  textTitle: string
  textSelect: string
}

export default GroupSelectorModal
