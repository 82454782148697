import { useSelector } from 'react-redux'
import CatalogTree from '../components/CatalogTree/CatalogTree'
import { Route, Routes } from 'react-router'
import SchemaFormCreate from '../components/SchemaForm/SchemaFormCreate'
import SchemaFormRoute from '../components/SchemaForm/SchemaFormRoute'
import { selectCatalogsAccessRights, selectPassports } from '../catalogs.selectors'

export const Passports = () => {
  const passports = useSelector(selectPassports)
  const rights = useSelector(selectCatalogsAccessRights)

  return (
    <>
      <CatalogTree
        title={'nav.passports'}
        allowSchemasEditing={rights.allowCatalogsEdit}
        allowInstancesEditing={false}
        items={passports}
        displayNesting={false}
        instanceCreatePathGenerator={(id) => `/catalogs/passports/${id}/create`}
        itemCreatePath={'/catalogs/passports/create'}
        routePrefix={'/catalogs/passports/'}
      />

      <div className={'nsi-main__wrapper'}>
        <div className={'nsi-main__container'}>
          <Routes>
            <Route path={'create'} element={<SchemaFormCreate />} />
            <Route path={':id'} element={<SchemaFormRoute />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
