import { IRoute } from '../TopMenu/Nav/nav.utils'
import { Passports } from './Passports/Passports'
import Schemas from './Schemas/Schemas'
import { Types } from './Types/Types'
import { OrganizationsPage } from './Organizations/OrganizationsPage'

import LogbooksSchemas from './Logbooks/LogbooksSchemas'

export const catalogsRoutes: IRoute[] = [
  { id: 'logbooks', path: 'logbooks/*', component: LogbooksSchemas, modules: ['equipment_logbook'] },
  { id: 'organizations', path: 'organizations/*', component: OrganizationsPage },
  { id: 'passports', path: 'passports/*', component: Passports },
  { id: 'schemas', path: 'schemas/*', component: Schemas },
  { id: 'types', path: 'types/*', component: Types },
]
