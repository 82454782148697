import { createCatalogItem } from '../../catalogs.utils'
import { AnyCatalogWithInstances } from '../../catalogs.types'
import { postCreateInstance } from '../../catalogs.api'
import { catalogsActions } from '../../catalogs.actions'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { ReduxState } from '../../../../redux/store.types'
import InstanceForm from './InstanceForm'
import useInstanceFormCallback from './useInstanceFormCallback'
import { useEffect, useState } from 'react'
import PanelButtons from '../../../../shared/Forms/PanelButtons'
import Form from '../../../../shared/Forms/Form'
import { useNavigate, useParams } from 'react-router-dom'

const InstanceFormCreate = () => {
  const params = useParams()
  const navigate = useNavigate()
  const id = parseInt(params.id)

  const dispatch = useDispatch()
  const schema = useSelector((state: ReduxState) => state.catalogs.catalogs.find((c) => c.id === id))
  const [item, setItem] = useState(null)
  const handleChange = useInstanceFormCallback({ setter: setItem, propertiesKey: 'properties', deps: [] })

  const { loading, wait } = useHttpLoader()

  useEffect(() => {
    if (!schema) return
    setItem(createCatalogItem(schema))
  }, [schema])

  const handleCatalogCreate = async () => {
    const create = postCreateInstance(item)

    wait(create, (res) => {
      if (res === null) return

      dispatch(catalogsActions.instanceCreated(res))
      navigate(`/catalogs/${getTypePath(schema.type)}/${id}/instance/${res.id}`)
    })
  }

  if (!item || !schema) return null

  return (
    <Form editing={true} onSubmit={handleCatalogCreate}>
      <InstanceForm
        schema={schema.schema_tree}
        item={item}
        propertiesKey={'properties'}
        onChange={handleChange}
        nameKey={'name'}
        onNameChange={(name) => setItem({ ...item, name })}
        editing={true}
      />
      <PanelButtons
        wrapperClass={'nsi-row nsi-form__footer'}
        editing={true}
        allowEditing={true}
        onEdit={null}
        onCancel={() => navigate(-1)}
        loading={loading}
      />
    </Form>
  )
}

const getTypePath = (type: AnyCatalogWithInstances['type']) => {
  switch (type) {
    case 'catalog':
      return 'schemas'
    case 'type':
      return 'types'
    case 'passport':
      return 'passports'
    case 'logbook':
      return 'logbooks'
  }
}

export default InstanceFormCreate
