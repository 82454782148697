import InstanceForm, { IInstanceFormProps } from './InstanceForm'
import DocumentsPanelLoader from '../../../Libraries/Documents/components/DocumentsPanel/DocumentsPanelLoader'
import { useIntl } from 'react-intl'
import { ICatalogItem } from 'au-nsi/catalogs'
import { IDocument } from 'au-nsi/documents'

type IProps<T> = IInstanceFormProps<T> & {
  resourceItemId: ICatalogItem['id']
  onDocsChange: (files: Omit<IDocument, 'id'>[]) => void
}

const InstanceFormWithDocs = <T extends Object>({ onDocsChange, resourceItemId, ...props }: IProps<T>) => {
  const intl = useIntl()

  return (
    <>
      <InstanceForm {...props} />
      <h2 className="system__title" style={{ marginTop: '2em', marginBottom: '0.5em' }}>
        {intl.formatMessage({ id: 'common.attached_documents' })}
      </h2>
      <DocumentsPanelLoader
        resource={'catalog_items'}
        resource_item_id={resourceItemId}
        editing={props.editing}
        name={'files'}
        onChange={onDocsChange}
      />
    </>
  )
}

export default InstanceFormWithDocs
