import { Incident } from 'au-nsi/signal-events'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { selectUsersMap } from '../../App/app.selectors'
import usersActions from '../../Collections/Users/users.actions'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import seActions from '../../SignalEvents/se.actions'
import { rulesMapSelector } from '../../SignalEvents/se.selectors'
import IncidentsRow from './IncidentsRow'

interface Props {
  incidents: Incident[]
  showDetails: boolean
  onSelect: (seID: number) => void
}

const IncidentsTable = ({ incidents, showDetails, onSelect }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const devices = useAppSelector(selectEquipmentMap)
  const users = useAppSelector(selectUsersMap)
  const rules = useAppSelector(rulesMapSelector)

  useEffect(() => {
    dispatch(usersActions.loadItems())
  }, [])

  // загрузка удаленных формул, на которые еще остались ссылки
  useEffect(() => {
    const missingRules = new Set<number>()

    for (const incident of incidents) {
      if (incident.type === 'formula' && !rules.has(incident.formula_id)) {
        missingRules.add(incident.formula_id)
      }
    }

    for (const id of missingRules) {
      dispatch(seActions.fetchDeletedRule(id))
    }
  }, [incidents])

  const rows = incidents.map((incident) => (
    <IncidentsRow
      key={incident.id}
      incident={incident}
      devices={devices}
      users={users}
      rules={rules}
      showDetails={showDetails}
      onSelect={onSelect}
    />
  ))

  return (
    <table className="nsi-table logs-table s-changelog__table">
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'alerts.ts_start' })}</th>
          <th>{intl.formatMessage({ id: 'alerts.ts_end' })}</th>
          <th>{intl.formatMessage({ id: 'alerts.situation' })}</th>
          <th>{intl.formatMessage({ id: 'alerts.objects' })}</th>
          <th>{intl.formatMessage({ id: 'alerts.ack' })}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

export default IncidentsTable
