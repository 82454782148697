import { SelectOption } from '@alterouniversal/au-react-components'
import { IBarChartComponent, IBarChartparameters, IBarChartSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { IDataSource } from '../../../pages/Nsi/nsi.interfaces'
import { selectDataSources } from '../../../pages/Nsi/nsi.selectors'
import { selectParameterOptions, selectParametersMap } from '../../../pages/Parameters/params.selectors'
import { ReduxState } from '../../../redux/store.types'
import { COLORS } from '../../../shared/constants'
import Form from '../../../shared/Forms/Form'
import PlusButton from '../../../shared/Forms/PlusButton'
import SettingsBlock from '../../../shared/LineCharts/settings/SettingsBlock'
import Modal from '../../../shared/Modal/Modal'
import { showError } from '../../../utils/notifications'
import ModalFooter from './../../../shared/Modal/ModalFooter'
import BarChartSettingsDisplay from './BarChartSettingsDisplay'
import BarChartSettingsParameters from './BarChartSettingsParameters'
import BarChartSettingsThresholds from './BarChartSettingsThresholds'

/**
 * Модальное окно с настройками столбчатой диаграммы
 */
const BarChartSettings = (props: Props) => {
  const intl = useIntl()

  const [settings, setSettings] = React.useState(props.component.settings)
  const [openBlocks, setOpenBlocks] = React.useState({ parameters: true, thresholds: false, display: false })

  const toggleBlock = (id: string) => setOpenBlocks({ ...openBlocks, [id]: !openBlocks[id] })

  const getSelectedUnit = (parametersRows: IBarChartparameters[], parameters: Map<string, Parameter>) => {
    const parameterRow = parametersRows.find((p) => p.parameter_id != null)

    return parameterRow ? parameters.get(parameterRow.parameter_id)?.unit : null
  }

  const getUnitsInfo = (
    parametersRows: IBarChartparameters[],
    parameters: Map<string, Parameter>,
    units: { [id: string]: SiUnit },
    prefixes: { [id: string]: SiPrefix }
  ) => {
    const result = { baseUnit: null, displayUnit: null, displayPrefix: null, units, prefixes }

    const parameterRow = parametersRows.find((p) => p.parameter_id != null)
    if (!parameterRow) return result

    const parameter = parameters.get(parameterRow.parameter_id)

    if (parameter) {
      result.baseUnit = units[parameter.unit]
      result.displayUnit = units[parameter.display_unit]
      result.displayPrefix = prefixes[parameter.display_prefix]
    }

    return result
  }

  const selectedUnit = getSelectedUnit(settings.parameters, props.parametersMap)
  const unitsInfo = getUnitsInfo(settings.parameters, props.parametersMap, props.units, props.prefixes)

  const equipmentOptions = React.useMemo(() => {
    return props.equipment.map((e) => ({ label: e.name, value: e.id, color: e.color }))
  }, [props.equipment])

  const parameterOptions = React.useMemo(() => {
    return props.parameterOptions.filter((o) => {
      if (!selectedUnit) return true

      const parameter = props.parametersMap.get(o.value)
      if (!parameter) return false

      return parameter.unit === selectedUnit
    })
  }, [selectedUnit, props.equipment, props.parameterOptions])

  const booleanOptions = [
    { value: true, title: intl.formatMessage({ id: 'common.yes' }) },
    { value: false, title: intl.formatMessage({ id: 'common.no' }) },
  ]

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleRowRemove = React.useCallback((index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.parameters.splice(index, 1)
      })
    )
  }, [])

  const handleRowChange = React.useCallback((key: string, value: string, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.parameters[index][key] = value
      })
    )
  }, [])

  const handleThresholdsChange = React.useCallback((key: string, value: any, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.thresholds[index][key] = value
      })
    )
  }, [])

  const handleThresholdsRemove = React.useCallback((index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.thresholds.splice(index, 1)
      })
    )
  }, [])

  const handleParametersRowAdd = () => {
    setSettings(
      produce(settings, (draft) => {
        draft.parameters.push({
          name: '',
          device_id: '',
          parameter_id: '',
          color: COLORS.warning,
          mirror: false,
        })
      })
    )
  }

  const handleThresholdsRowAdd = () => {
    setSettings(
      produce(settings, (draft) => {
        draft.thresholds.push({ value: 0, color: COLORS.warning, line: 'solid' })
      })
    )
  }

  const handleSave = () => {
    for (const parameter of settings.parameters) {
      if (!parameter.device_id) return showError('dashboards.bar_chart.errors.device')
      if (!parameter.parameter_id) return showError('dashboards.bar_chart.errors.empty_parameter')
    }

    if (settings.min != null && settings.max != null && settings.min >= settings.max) {
      return showError('dashboards.bar_chart.errors.min')
    }

    props.onSave(settings)
  }

  const thresholdRows = settings.thresholds.map((row, i) => {
    return (
      <BarChartSettingsThresholds
        settings={settings}
        key={i}
        intl={intl}
        index={i}
        row={row}
        onRemove={handleThresholdsRemove}
        onChange={handleThresholdsChange}
        unitsInfo={unitsInfo}
      />
    )
  })

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <SettingsBlock
            open={openBlocks.parameters}
            onToggle={() => toggleBlock('parameters')}
            title="dashboards.bar_chart.settings.tabs.parameters"
          >
            <BarChartSettingsParameters
              settings={settings}
              onAdd={handleParametersRowAdd}
              onChange={handleRowChange}
              onRemove={handleRowRemove}
              equipmentOptions={equipmentOptions}
              parameterOptions={parameterOptions}
              booleanOptions={booleanOptions}
            />
          </SettingsBlock>

          <SettingsBlock
            open={openBlocks.thresholds}
            onToggle={() => toggleBlock('thresholds')}
            title="dashboards.bar_chart.settings.tabs.thresholds"
          >
            <table className="nsi-settings-table" style={{ marginTop: '10px' }}>
              <thead>
                <tr>
                  <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.thresholds.value' })}</th>
                  <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.thresholds.line' })}</th>
                  <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.thresholds.color' })}</th>
                </tr>
              </thead>
              <tbody>{thresholdRows}</tbody>
            </table>
            <PlusButton textId="common.add" onClick={handleThresholdsRowAdd} style={{ marginLeft: 0 }} />
          </SettingsBlock>
          <SettingsBlock
            open={openBlocks.display}
            onToggle={() => toggleBlock('display')}
            title="dashboards.bar_chart.settings.tabs.display"
          >
            <BarChartSettingsDisplay
              settings={settings}
              onChange={handleChange}
              booleanOptions={booleanOptions}
              unitsInfo={unitsInfo}
            />
          </SettingsBlock>

          <ModalFooter onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: IBarChartComponent
  dispatch: (action: any) => void
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: IBarChartSettings) => void
  parameterOptions: SelectOption[]
  parametersMap: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  title: string
  units: { [id: string]: SiUnit }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameterOptions: selectParameterOptions(state),
    parametersMap: selectParametersMap(state),
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}
export default connect(mapStateToProps)(BarChartSettings)
