import { MutableRefObject, useEffect, useState } from 'react'

interface Props {
  elementRef: MutableRefObject<HTMLElement>
  isOpen: boolean
}

type Position = 'top' | 'bottom'

const useVirtualMenu = ({ elementRef, isOpen }: Props): Position => {
  const [position, setPosition] = useState<Position>('bottom')

  useEffect(() => {
    if (!isOpen) return

    const el = elementRef?.current
    if (!el) return

    const parent = getScrollParent(el)?.parentElement ?? document.body

    const parentBox = parent.getBoundingClientRect()
    const elementBox = el.getBoundingClientRect()

    // свободное расстояние до нижней границы (320px - max-height выпадающего меню)
    const bottomSpace = parentBox.bottom - elementBox.bottom
    bottomSpace >= 320 ? setPosition('bottom') : setPosition('top')
  }, [elementRef?.current, isOpen])

  return position
}

const getScrollParent = (node): HTMLElement => {
  if (node == null) return null

  if (node.scrollHeight > node.clientHeight) return node
  else return getScrollParent(node.parentNode)
}

export default useVirtualMenu
