import { CSSProperties, useEffect, useRef } from 'react'
import { HuePicker } from './HuePicker/HuePicker'
import { HSL } from '../../colorpicker.types'
import { useMouseMove } from '../../../../../hooks/useMouseMove'
import styles from './colorPalete.module.css'
import { IColorState } from '../../ColorPicker'

interface IProps {
  colorState: IColorState
  onChange: (v: HSL, resource: IColorState['resource']) => void
  width?: number
  height?: number
  style?: CSSProperties
}

/**
 * Компонент для выбора произвольного цвета из всей палитры
 */
const ColorPalette = ({ colorState, onChange, width = 360, height = 360, style }: IProps) => {
  const { color: hsl } = colorState
  const ref = useRef<HTMLDivElement>()
  const { mouseMove } = useMouseMove({ ref })

  useEffect(() => {
    mouseMove && handleClick(mouseMove)
  }, [mouseMove])

  const handleClick = (e) => {
    const { top, left } = ref.current.getBoundingClientRect()
    const x = e.clientX - left
    const y = e.clientY - top

    if (x > 0 && y > 0 && x <= width && y <= height) {
      onChange({ h: hsl.h, s: (x / width) * 100, l: (y / height) * 100 }, 'palette')
    }
  }

  return (
    <div style={Object.assign({ display: 'flex', flexDirection: 'column' }, style || {})}>
      <div
        style={{
          background: `hsl(${hsl.h}, 100%, 50%)`,
          width: width + 'px',
          height: height + 'px',
          overflow: 'hidden',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <div ref={ref} className={styles.whiteGradient}>
          <div className={styles.blackGradient}>
            <div
              className={styles.pointer}
              style={{
                top: (height * hsl.l) / 100 - 7 + 'px',
                left: (width * hsl.s) / 100 - 7 + 'px',
                backgroundColor: `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`,
              }}
            />
          </div>
        </div>
      </div>
      <HuePicker color={hsl} onChange={(c) => onChange(c, 'palette')} />
    </div>
  )
}

export default ColorPalette
