import React from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg'
import FallbacksItem from './FallbacksItem'

/**
 * Навигация по списку конфигураций для резервных источников данных
 */
const FallbacksList = (props: Props) => {
  const intl = useIntl()

  const handleRemove = (e) => {
    e.stopPropagation()
    props.onRemove(+e.currentTarget.dataset.id)
  }

  const handleSelect = (e) => {
    props.onSelect(+e.currentTarget.dataset.id)
  }

  // список названий резервных источников
  const list = props.items.map((item, i) => {
    // i - текущая позиция, item._index - изначальная позиция
    return (
      <FallbacksItem
        editing={props.editing}
        index={i}
        intl={intl}
        isActive={props.active === i}
        isSelected={props.selected === i}
        key={item._index}
        onRemove={handleRemove}
        onSelect={handleSelect}
        onSwap={props.onSwap}
      />
    )
  })

  // кнопка для добавления еще одного источника данных
  const plusButton = (
    <div className="conn-fallbacks__button" onClick={props.onAppend}>
      <PlusIcon style={{ paddingRight: '3px', width: 24, height: 24, color: 'var(--primary-90)' }} />
      <span>{intl.formatMessage({ id: 'nsi.connection.add_source' })}</span>
    </div>
  )

  return (
    <div className="conn-fallbacks__list">
      {list}
      {props.editing && plusButton}
    </div>
  )
}

interface Props {
  items: Record<string, any>
  editing: boolean
  onAppend: () => void
  onRemove: (index: number) => void
  onSelect: (index: number) => void
  onSwap: (sourceIndex: number, targetIndex: number) => void
  selected: number
  active: number
}

export default React.memo(FallbacksList)
