import { useLayoutEffect, useRef, useState } from 'react'
import usePopup from '../../../hooks/usePopup'

/**
 * вспомогательный хук для работы с выпадающими меню
 */
export const useDropdownControls = () => {
  const ref = useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)
  const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({ top: '30px' })

  // если дропдаун находится слишком близко к нижней границе экрана, то его необходимо переместить
  // и отрисовать над строкой
  useLayoutEffect(() => {
    if (open) {
      const b = ref.current.getBoundingClientRect()

      if (b.bottom + 160 > window.innerHeight) {
        setDropdownStyle({ top: 'unset', bottom: '24px' })
      }
    }
  }, [open])

  return { ref, open, setOpen, dropdownStyle }
}
