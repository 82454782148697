import { Equipment } from 'au-nsi/equipment'
import produce from 'immer'
import { CSSProperties, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useAppSelector } from '../../../redux/store'
import Form from '../../../shared/Forms/Form'
import { InputOptions, renderInput } from '../../../shared/Forms/forms.utils'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import PlusButton from '../../../shared/Forms/PlusButton'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import { deepCopy } from '../../../utils/misc'
import { selectParameterOptions } from '../../Parameters/params.selectors'
import ImportModal from '../Modals/ImportModal'
import { updateParametersMapping } from '../nsi.actions'
import MappingImport from './MappingImport/MappingImport'

interface Props {
  device: Equipment
  fields: InputOptions[]
  defaultMapping: any
  columnsSize: number[]
  allowEditing: boolean
}

/**
 * Общая форма для настройки маппинга параметров для протоколов которые не имеют собственной конфигурации.
 * Рендерит таблицу в которую можно добавлять новые строки, первым элементом в строке является
 * системный параметр, остальные столбцы в таблице настраиваются родительским компонентом и зависят от протокола.
 */
const MappingForm = ({ device, fields, defaultMapping, columnsSize, allowEditing }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const virtuoso = useRef<VirtuosoHandle>()

  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)
  const [draft, setDraft] = useState(device.parameters_mapping)

  useLayoutEffect(() => handleCancel(), [device.id])

  useEffect(() => {
    if (!editing) handleCancel()
  }, [device.parameters_mapping])

  const equipment = useAppSelector((state) => state.nsi.equipment)
  const parameters = useAppSelector(selectParameterOptions)
  const translations = useAppSelector((state) => state.language.translations)

  const importSources = useMemo(
    () => equipment.filter((e) => e.protocol === device.protocol && e.parameters_mapping.length > 0),
    [equipment]
  )

  const grid = useMemo(() => getGridByPercentsArr(columnsSize), [columnsSize])
  const parametersUsage = countMappedParameters(draft)

  const handleRemoveRow = (e) => {
    const index = +e.currentTarget.dataset.id
    setDraft((prev) =>
      produce(prev, (d) => {
        d.splice(index, 1)
      })
    )
  }

  const handleCreateRow = () => {
    setDraft((prev) => [...prev, { ...defaultMapping, parameter_id: '' }])
  }

  const handleChange = (value: any, key: string, index: number) => {
    setDraft((prev) =>
      produce(prev, (d) => {
        d[index][key] = value
      })
    )
  }

  const handleCancel = () => {
    setEditing(false)
    setDraft(device.parameters_mapping)
  }

  const handleSave = () => {
    // из-за виртуализации не все инпуты находятся в DOM, поэтому дефолтная браузерная валидация
    // формы не будет работать, вместо этого сами находим невалидную строку и перемещаемся к ней
    const invalidRow = draft.findIndex((r) => !r.parameter_id || parametersUsage.get(r.parameter_id) > 1)

    if (invalidRow !== -1) {
      virtuoso.current.scrollToIndex(invalidRow)
    } else {
      dispatch(updateParametersMapping(device.id, draft))
      setEditing(false)
    }
  }

  const handleImport = (id: string) => {
    const source = importSources.find((e) => e.id === id)
    const data = deepCopy(source.parameters_mapping)
    const action = updateParametersMapping(device.id, data)

    dispatch(action)
    setImporting(false)
  }

  const handleFileImport = (mapping) => {
    setDraft(mapping)
    setEditing(true)
  }

  // отрисовка одной строки (настройки маппинга одного параметра)
  const renderRow = (index: number) => {
    const row = draft[index]
    if (!row) return null

    const removeIcon = (
      <div style={{ width: '24px' }}>
        <RemoveButton id={index} onRemove={handleRemoveRow} />
      </div>
    )

    const onChange = (v, k) => handleChange(v, k, index)

    // обязательный первый столбец с названием параметра в системе
    const isDuplicate = parametersUsage.get(row.parameter_id) > 1
    const error = isDuplicate ? intl.formatMessage({ id: 'nsi.parameters.duplicate_parameter' }) : ''
    const input: InputOptions = { type: 'select', key: 'parameter_id', options: parameters, required: true, error }
    const param = renderInput({ data: row, input, editing, onChange })

    // остальные столбцы зависящие от протокола
    const columns = fields.map((field) => {
      const renderField = { ...field }
      if (renderField.type === 'number' || renderField.type === 'parameter') renderField.fullWidth = true

      return (
        <div className={editing ? '' : 'line_clamp'} key={field.key}>
          {renderInput({ data: row, input: renderField, editing, onChange })}
        </div>
      )
    })

    const classname = `row ${index % 2 ? 'odd' : 'even'}`
    return (
      <div key={index} className={classname} style={{ gridTemplateColumns: grid }}>
        <div key="parameter_id" className={editing ? '' : 'line_clamp'} title={editing ? '' : row.parameter_id}>
          {param}
        </div>
        {columns}
        {editing && removeIcon}
      </div>
    )
  }

  const header = fields.map((field) => translations['equipment.parameters_mapping.' + field.key])
  header.unshift(translations['equipment.parameters_mapping.parameter_id'])

  const columns = fields.map((field) => field.key)
  const numericColumns = fields.filter((field) => field.type === 'number').map((field) => field.key)
  columns.unshift('parameter_id')

  const Footer = () => editing && <PlusButton textId="common.add_parameter" onClick={() => handleCreateRow()} />

  const footerHeight = editing ? 40 : 0
  const height = draft.length * 52 + footerHeight
  const style: CSSProperties = height > 650 ? { height: '600px', overflowY: 'auto' } : { height: height + 'px' }

  return (
    <Form editing={editing} onSubmit={handleSave}>
      <div className="nsi-table-wrapper">
        <div className="nsi-grid-table is-striped">
          <header style={{ gridTemplateColumns: grid }}>
            {header.map((column) => (
              <div className="text--nowrap" key={column}>
                {column}
              </div>
            ))}
            {editing && <div style={{ width: '24px' }} />}
          </header>
          <Virtuoso
            style={style}
            className="tbody"
            totalCount={draft.length}
            itemContent={renderRow}
            components={{ Footer }}
            ref={virtuoso}
          />
        </div>
      </div>
      <PanelButtons
        editing={editing}
        allowEditing={allowEditing}
        allowImport={allowEditing}
        onEdit={() => setEditing(true)}
        onImport={() => setImporting(true)}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <MappingImport
          name={device.name}
          mapping={device.parameters_mapping}
          columns={columns}
          numericColumns={numericColumns}
          onImport={handleFileImport}
        />
      </PanelButtons>
      <ImportModal
        isOpen={importing}
        onClose={() => setImporting(false)}
        onImport={handleImport}
        sources={importSources}
        targetId={device.id}
      />
    </Form>
  )
}

// подсчет кол-ва использований каждого параметра в маппинге
export const countMappedParameters = (mapping: Equipment['parameters_mapping']) => {
  const result = new Map<string, number>()

  for (const row of mapping) {
    const p = row.parameter_id
    if (!p) continue

    const count = result.get(p) || 0
    result.set(p, count + 1)
  }

  return result
}

const getGridByPercentsArr = (sizes: number[]) => {
  if (!sizes) return 'repeat(auto-fit, minmax(1px, 1fr))'
  const total = sizes.reduce((s, acc) => s + acc, 0)

  return sizes.map((s) => s / total + 'fr').join(' ') + ' 35px'
}

export default MappingForm
