import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../redux/store'
import ganttFactory from '../../services/gantt/gantt.factory'
import ChartPlayer from '../ChartPlayer/ChartPlayer'
import Incidents from '../Incidents/Incidents'
import GanttTable from './gantt/GanttTable'
import { actions } from './pq.reducers'
import { selectEquipmentWithVisibility } from './pq.selectors'
import './pq.styles.css'
import PqHeader from './PqHeader'
import TrendsCharts from './trends/TrendsCharts'

const PQChart = React.lazy(() => import('./chart/PqChartWrapper'))

const PqDiagram = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const data = useAppSelector((state) => {
    const { chartMode } = state.pq
    const registrators = selectEquipmentWithVisibility(state)

    const page = location.pathname.includes('/alerts') ? 'alerts' : 'pq'
    const component = state.dashboards.components[page]?.find((c) => c.type === 'gantt')
    const title = state.dashboards.dashboards.find((d) => d.id === 'pq')?.name

    return {
      title,
      chartMode,
      component,
      registrators,
      search: state.pq.search,
      selectedRegistrators: registrators,
    }
  }, shallowEqual)

  // общий сервис Ганта который будет передан и в диаграмму и в список инцидентов
  const service = React.useMemo(() => ganttFactory(), [])
  const { search, registrators } = data

  // сбросить настройки видимости устройств при нажатии CTRL + A
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault()
        dispatch(actions.resetVisibility(registrators))
      }
    },
    [registrators]
  )

  // добавить обработчик слушающий keydown
  useEffect(() => {
    document.addEventListener('keydown', handleKeyUp)
    return () => document.removeEventListener('keydown', handleKeyUp)
  })

  // отфильтровать устройства по совпадению названия с поиском
  const equipment = useMemo(() => {
    const s = search.toLowerCase()
    return registrators.filter((r) => r.name.toLowerCase().includes(s) || r.shortname.toLowerCase().includes(s))
  }, [registrators, search])

  // отфильтровать скрытые устройства
  const visible = useMemo(() => equipment.filter((r) => r.visible), [equipment])

  const chart =
    data.chartMode === 'pq' ? (
      <Suspense fallback={<div className="pq-chart__container" />}>
        <PQChart equipment={visible} />
      </Suspense>
    ) : (
      <TrendsCharts equipment={equipment} />
    )

  const page = data.chartMode === 'pq' ? 'pq' : 'trends'
  const showAlerts = location.pathname.includes('/alerts')
  const title = showAlerts ? null : data.title

  return (
    <React.Fragment>
      <ChartPlayer page={page} isFrameControlsHidden={showAlerts} title={title} />

      <div className="pq">
        <GanttTable
          component={data.component as any}
          navigate={navigate}
          page={showAlerts ? 'alerts' : 'pq'}
          registrators={equipment}
          search={search}
          service={service}
        />

        <div className="pq-chart-container">
          {!showAlerts && <PqHeader />}
          {showAlerts ? <Incidents service={service} /> : chart}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PqDiagram
