import { FormMode } from '../../shared/interfaces'
import http, { handleHttpError } from '../../utils/http'
import { objectDiff } from '../../utils/misc'

interface Resource {
  id: number | string
}

interface Options {
  // пропустить вычисление разницы при сохранении изменений и отправлять на сервер весь объект
  skipDiffer?: boolean
}

export const generateActions = <T extends Resource>(resource: string, nsiUrl: string, options?: Options) => {
  const SET_MODE = `app/${resource}/MODE`
  const SET_ITEMS = `app/${resource}/SET`
  const ADD_ITEM = `app/${resource}/ADD`
  const UPDATE_ITEM = `app/${resource}/UPDATE`
  const DELETE_ITEM = `app/${resource}/DELETE`
  const SELECT_ITEM = `app/${resource}/SELECT`
  const UPDATE_SELECTED_ITEM = `app/${resource}/UPDATE_SELECTED`

  const actions = {
    setMode: (mode: FormMode, args: Record<string, any> = {}) => ({ type: SET_MODE, mode, ...args }),
    selectItem: (id: number | string) => ({ type: SELECT_ITEM, id }),

    updateSelectedItem: (field: string, value: any) => {
      return { type: UPDATE_SELECTED_ITEM, field, value }
    },

    itemsLoaded: (items: T[]) => ({ type: SET_ITEMS, items, isLoaded: true }),
    itemCreated: (item: T, background = false) => ({ type: ADD_ITEM, item, background }),

    itemUpdated: (id: number | string, updates: Partial<T>, background = false) => {
      return { type: UPDATE_ITEM, id, updates, background }
    },

    itemDeleted: (id: number | string, background = false) => ({ type: DELETE_ITEM, id, background }),

    loadItems:
      (reload = false) =>
      (dispatch, getState) => {
        const { isLoaded } = getState()[resource]
        if (isLoaded && !reload) return

        return http
          .get(nsiUrl)
          .then(({ data }) => dispatch(actions.itemsLoaded(data)))
          .catch((err) => handleHttpError(err))
      },

    createItem: (item: T) => async (dispatch) => {
      return http
        .post(nsiUrl, item)
        .then(({ data }) => dispatch(actions.itemCreated(data)))
        .catch((err) => handleHttpError(err))
    },

    updateItem: (item: Partial<T>) => (dispatch, getState) => {
      const state = getState()
      const initialVersion = state[resource].items.find((e) => e.id === item.id)
      const updates = options?.skipDiffer ? item : objectDiff(initialVersion, item)

      if (Object.keys(updates).length === 0) {
        dispatch(actions.itemUpdated(item.id, {}))
        return Promise.resolve()
      }

      return http
        .patch(nsiUrl + item.id, updates)
        .then(({ data }) => dispatch(actions.itemUpdated(item.id, data)))
        .catch((err) => handleHttpError(err))
    },

    deleteItem: (id: number | string) => (dispatch) => {
      return http
        .delete(nsiUrl + id)
        .then(() => dispatch(actions.itemDeleted(id)))
        .catch((err) => {
          handleHttpError(err)
          actions.setMode('view')
        })
    },
  }

  return actions
}
