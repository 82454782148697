/**
 * Селекторы для данных использующихся во многих компонентах.
 * Для информации о текущем пользователе используется поиск в двух местах:
 * 1. state.user (профиль, обновляется один раз только при загрузке страницы, но сохраняется в локальный кэш)
 * 2. state.users.items (список всех пользователей, не сохраняется в кэш, но обновляется в реалтайме и поэтому
 *    всегда содержит актуальную информацию)
 */
import { AccessRight } from 'au-nsi/shared'
import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'

/**
 * Set из включенных модулей платформы
 */
export const selectEnabledModules = createSelector(
  (state: ReduxState) => state.modules,
  (modules) => new Set(modules.filter((m) => m.enabled).map((m) => m.id))
)

/**
 * Права доступа текущего пользователя
 */
export const selectAccessRights = createSelector(
  (state: ReduxState) => state.user,
  (state: ReduxState) => state.users.items,
  (state: ReduxState) => state.roles.items,
  (profile, users, roles): { [right in AccessRight]?: boolean } => {
    const result: { [right in AccessRight]?: boolean } = {}

    const user = users.find((u) => u.id === profile.id) || profile

    const role = roles.find((r) => r.id === user.role_id)
    if (!role) return profile.access_rights

    for (const right of role.access_rights) {
      result[right] = true
    }

    return result
  }
)

export const selectCurrentUser = createSelector(
  (state: ReduxState) => state.user,
  (state: ReduxState) => state.users.items,
  (profile, users) => users.find((u) => u.id === profile.id) || profile
)

export const selectPreferences = createSelector(
  (state: ReduxState) => state.user,
  (state: ReduxState) => state.users.items,
  (profile, users) => {
    const user = users.find((u) => u.id === profile.id)
    return user?.preferences || profile.preferences
  }
)

/**
 * Язык текущего пользователя
 */
export const selectLanguage = createSelector(selectCurrentUser, (user) => user.lang)

/**
 * Тема интерфейса текущего пользователя
 */
export const selectTheme = createSelector(selectPreferences, (preferences) => preferences?.theme ?? 'dark-blue')

/**
 * Мапа всех пользователей
 */
export const selectUsersMap = createSelector(
  (state: ReduxState) => state.users.items,
  (users) => new Map(users.map((u) => [u.id, u]))
)
