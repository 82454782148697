import { PropsWithChildren, useContext } from 'react'
import { useIntl } from 'react-intl'
import Loader from '../Utils/Loader'
import { FormContext } from './Form'

/**
 * Элементы управления для одного поля ресурса (редактировать, импортировать, отменить)
 * Отличие от FormButtons в том что нет возможности удаления, а только редактирование
 */
const PanelButtons = (props: PropsWithChildren<Props>) => {
  const intl = useIntl()
  const ctx = useContext(FormContext)

  // если компонент находится в форме, то будет вызван onSubmit у формы
  const onSave = ctx.form ? undefined : props.onSave

  const renderImportButton = () => {
    return (
      <button type="button" className="nsi-button secondary" onClick={props.onImport}>
        {intl.formatMessage({ id: 'nsi.import' })}
      </button>
    )
  }

  const renderDefaultButtons = () => {
    return (
      <>
        <button type="button" className="nsi-button default" onClick={props.onEdit}>
          {intl.formatMessage({ id: 'common.edit' })}
        </button>
        {props.allowImport && renderImportButton()}
      </>
    )
  }

  const renderEditButtons = () => {
    return (
      <>
        <button type="button" className="nsi-button inverted" onClick={props.onCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </button>
        <button type="submit" className="nsi-button default" onClick={onSave}>
          {intl.formatMessage({ id: 'common.apply' })}
        </button>
      </>
    )
  }

  if (!props.allowEditing) return null
  if (props.loading) return <Loader />

  return (
    <div className={props.wrapperClass ?? 'nsi-table__footer'}>
      {props.editing ? renderEditButtons() : renderDefaultButtons()}
      {!props.editing && props.children}
    </div>
  )
}

interface Props {
  editing: boolean
  allowEditing: boolean
  onEdit: () => void
  onCancel: () => void
  onSave?: () => void
  allowImport?: boolean
  onImport?: () => void
  wrapperClass?: string
  loading?: boolean
}

export default PanelButtons
