import { IntlShape } from 'react-intl'

/**
 * Преобразование данных полученных от сервера в форму готовую для рендеринга
 */
export const transform = (data: IServerResponse[], options: ITransformOptions): IWindroseData => {
  const result: IWindroseData = { count_total: 0, count_max: 0, angles: [] }

  const includeSpeed = options.speed !== null
  const speedLimit = 15

  // генерация пустых бинов
  for (let i = 0; i < options.sectors; i += 1) {
    const angle = (i * 2 * Math.PI) / options.sectors
    const bucket = { angle, count_angle: 0, speeds: [] }
    result.angles.push(bucket)

    for (let i = 0; i <= speedLimit; i += 2) {
      bucket.speeds.push({ speed: i, count_speed: 0 })
    }
  }

  // заполнение бинов полученными данными
  for (const row of data) {
    const count = row.$count
    const direction = options.angleUnit === 'rad' ? row[options.direction] : (Math.PI * row[options.direction]) / 180
    const speed = includeSpeed ? row[options.speed] : null

    result.count_total += count
    const angle = result.angles.find((a) => Math.abs(a.angle - direction) < 0.00001)
    angle.count_angle += count

    if (includeSpeed) {
      // если бин с такой скоростью не найден, значет скорость превышает максимальную,
      // такие элементы помещаем в последний бин
      const bucket = angle.speeds.find((s) => s.speed === speed) || angle.speeds[angle.speeds.length - 1]
      bucket.count_speed += count
    }
  }

  // поиск максимального счетчика по углу
  for (const { count_angle } of result.angles) {
    if (count_angle > result.count_max) {
      result.count_max = count_angle
    }
  }

  // рассчет кумулятивных сум по скорости
  for (const angle of result.angles) {
    let sum = 0

    for (const bucket of angle.speeds) {
      sum += bucket.count_speed
      bucket.count_cumulative = sum
    }
  }

  return result
}

/**
 * Генерация внутренних кругов сетки, так чтобы они шли не чаще 50px
 */
export const generateGrid = (r: number) => {
  const count = Math.floor(r / 50)
  const lines: number[] = []

  for (let i = 1; i <= count; i++) {
    lines.push((i * r) / count)
  }

  return lines
}

/**
 * Генерация подписей к углам диаграммы
 */
export const generateLabels = (r: number, intl: IntlShape) => {
  const count = r > 200 ? 16 : 8
  const labels: Array<{ angle: number; label: string; textAnchor: string; dominantBaseline: string }> = []

  const named = {
    0: intl.formatMessage({ id: 'dashboards.windrose.N' }),
    90: intl.formatMessage({ id: 'dashboards.windrose.E' }),
    180: intl.formatMessage({ id: 'dashboards.windrose.S' }),
    270: intl.formatMessage({ id: 'dashboards.windrose.W' }),
  }

  for (let i = 0; i < count; i++) {
    const angle = (2 * Math.PI * i) / count

    const angleDeg = (360 * i) / count
    const angleRotated = (angleDeg + 90) % 360
    const label = named[angleDeg] || angleDeg + '°'

    const textAnchor = angleDeg % 180 === 0 ? 'middle' : angleDeg < 180 ? 'start' : 'end'
    const dominantBaseline = angleRotated % 180 === 0 ? 'middle' : angleRotated < 180 ? 'baseline' : 'hanging'

    labels.push({ angle, label, textAnchor, dominantBaseline })
  }

  return labels
}

/**
 * Цветовая шкала для обозначения скорости ветра (из дизайна)
 */
export const speedColors = {
  1: '#004945',
  3: '#385545',
  5: '#696045',
  7: '#996A45',
  9: '#C17043',
  11: '#BC513F',
  13: '#B62E3A',
  15: '#AF0034',
}

/**
 * Цветовая шкала для различных параметров в режиме сравнения (из Grafana)
 */
export const comparisonColors = [
  'rgb(126, 178, 109)',
  'rgb(234, 184, 57)',
  'rgb(110, 208, 224)',
  'rgb(226, 77, 66)',
  'rgb(31, 120, 193)',
  'rgb(186, 67, 169)',
  'rgb(80, 134, 66)',
  'rgb(193, 92, 23)',
]

export interface IWindroseData {
  count_total: number
  count_max: number
  angles: Array<{
    angle: number
    count_angle: number
    speeds: Array<{
      speed: number
      count_speed: number
      count_cumulative: number
    }>
  }>
}

interface ITransformOptions {
  sectors: number
  direction: string
  speed?: string
  angleUnit: string
}

interface IServerResponse {
  $count: number
  [parameter: string]: number
}
