import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import InputRow from '../../../shared/Inputs/InputRow'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import actions from './image.actions'
import { generateImageSrc, formats, loadFiles } from './image.utils'
import { Image } from 'au-nsi/images'

/**
 * Модальное окно для редактирования изображения
 */
const UpdateForm = (props: Props) => {
  const areaRef = React.useRef<HTMLDivElement>()
  const inputRef = React.useRef<HTMLInputElement>()

  const intl = useIntl()
  const dispatch = useDispatch()

  const [image, setImage] = React.useState({ ...props.image })
  const src = React.useMemo(() => generateImageSrc(image), [image.src])

  const handleNameChange = (e) => {
    setImage({ ...image, name: e.target.value })
  }

  const handleSrcChange = (e) => loadFiles(e.target.files, (res) => setImage({ ...image, ...res }))

  const handleSave = () => {
    dispatch(actions.updateImage(image))
    props.onClose()
  }

  // обработчки перетаскивания изображения из файлового менеджера
  const handleDragOver = (e) => {
    e.preventDefault()
    areaRef.current.style.border = '1px solid var(--gray-30)'
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    areaRef.current.style.border = '1px solid transparent'
  }

  const handleDrop = (e) => {
    handleDragLeave(e)
    loadFiles(e.dataTransfer.files, (res) => setImage({ ...image, ...res }))
  }

  return (
    <Modal onClose={props.onClose} closeOnClickOutside={false}>
      <div>
        <h2>{intl.formatMessage({ id: 'system.images.edit_title' })}</h2>

        <InputRow label={intl.formatMessage({ id: 'system.images.name' })}>
          <input className="nsi-input" value={image.name} onChange={handleNameChange} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.images.src' })}>
          <div
            ref={areaRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ border: '1px solid transparent' }}
          >
            <img
              alt=""
              src={src}
              style={{ maxWidth: '100%', cursor: 'pointer' }}
              onClick={() => inputRef.current.click()}
            />
            <input ref={inputRef} className="nsi-input" type="file" accept={formats} onChange={handleSrcChange} />
          </div>
        </InputRow>

        <ModalFooter onCancel={props.onClose} onSave={handleSave} />
      </div>
    </Modal>
  )
}

interface Props {
  image: Image
  onClose: () => void
}

export default UpdateForm
