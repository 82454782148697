import { SelectOption } from '@alterouniversal/au-react-components'
import { IGaugeComponent, IGaugeSettings, IScaleComponent } from 'au-nsi/dashboards'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { selectDataSourceOptions } from '../../pages/Nsi/nsi.selectors'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import { selectDenormalizedParametersMap, selectParameterOptions } from '../../pages/Parameters/params.selectors'
import { ReduxState } from '../../redux/store.types'
import { COLORS } from '../constants'
import Form from '../Forms/Form'
import { InputOptions, renderInput } from '../Forms/forms.utils'
import PlusButton from '../Forms/PlusButton'
import Modal from '../Modal/Modal'
import ModalFooter from '../Modal/ModalFooter'
import GaugeRangeSettings from './GaugeRangeSettings'

const boundariesKeys = ['lower', 'upper']

const gaugeTypes = ['arrow', 'slider']
const scaleTypes = ['default', 'tubular', 'compact', 'default_vertical', 'tubular_vertical', 'compact_vertical']

export const GaugeSettings = (props: Props) => {
  const intl = useIntl()
  const [error, setError] = React.useState<string>(null)

  const [settings, setSettings] = React.useState({
    ...props.component.settings,
    ...props.component.settings.boundaries,
  })

  // изменял ли пользователь настройки границ
  const [boundariesTouched, setBoundariesTouched] = React.useState(settings.parameter_id != null)

  const handleChange = (value, key: string) => {
    setSettings({ ...settings, [key]: value })

    if (!boundariesTouched && boundariesKeys.includes(key)) setBoundariesTouched(true)
    if (error) setError(null)
  }

  const handleSave = () => {
    if (!settings.device_id) return setError(intl.formatMessage({ id: 'dashboards.gauge.errors.empty_device' }))
    if (!settings.parameter_id) return setError(intl.formatMessage({ id: 'dashboards.gauge.errors.empty_parameter' }))
    if (settings.upper <= settings.lower)
      return setError(intl.formatMessage({ id: 'dashboards.gauge.errors.invalid_range' }))

    props.onSave({ ...settings, boundaries: { lower: settings.lower, upper: settings.upper } })
  }

  const handleRangeAdd = () => {
    const ranges = settings.ranges || []
    setSettings({
      ...settings,
      ranges: [...ranges, { lower: 0, upper: 0, type: 'warning', color: COLORS.warning }],
    })
  }

  const handleRangeChange = (range, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.ranges[index] = range
      })
    )
  }

  const handleRangeRemove = (index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.ranges.splice(index, 1)
      })
    )
  }

  const types = props.component.type === 'gauge' ? gaugeTypes : scaleTypes
  const modeOptions = types.map((t) => ({ value: t, title: intl.formatMessage({ id: 'dashboards.gauge.mode.' + t }) }))

  React.useEffect(() => {
    const shouldResetParameter =
      settings.parameter_id && props.parameterOptions.find((o) => o.value === settings.parameter_id) == null

    if (shouldResetParameter) setSettings({ ...settings, parameter_id: null })
  }, [settings.device_id])

  const parameter = props.parameters.get(settings.parameter_id)

  const parameterInfo = {
    baseUnit: parameter && parameter.unit,
    basePrefix: parameter && parameter.prefix,
    displayUnit: parameter && parameter.display_unit,
    displayPrefix: parameter && parameter.display_prefix,
    units: props.units,
    prefixes: props.prefixes,
  }

  // при изменении параметра необходимо изменить дефолтные значения границ
  // т.к. например по умолчанию верхняя граница равна 100, а при выборе параметра
  // измеряемого в мегаваттах ее надо выставить равной 100 * 1e6
  React.useEffect(() => {
    if (!boundariesTouched) {
      const exponent = parameterInfo.displayPrefix ? parameterInfo.displayPrefix.exponent : 0
      const factor = Math.pow(10, exponent)
      setSettings({ ...settings, upper: 100 * factor })
    }
  }, [settings.parameter_id])

  const inputs: InputOptions[] = [
    { type: 'text', key: 'title' },
    { type: 'dropdown', key: 'mode', options: modeOptions },
    { type: 'select', key: 'device_id', options: props.equipmentOptions },
    { type: 'select', key: 'parameter_id', options: props.parameterOptions },
    { type: 'parameter', key: 'lower', fullWidth: true, ...parameterInfo },
    { type: 'parameter', key: 'upper', fullWidth: true, ...parameterInfo },
    { type: 'number', key: 'precision', integer: true, min: 0, max: 6, fullWidth: true },
  ]

  const grid = inputs.map((input) => {
    return (
      <div key={input.key}>
        <div className="system__label">{intl.formatMessage({ id: 'dashboards.gauge.settings.' + input.key })}</div>
        <div className="system__input-wrapper">
          {renderInput({ data: settings, input, editing: true, onChange: handleChange })}
        </div>
      </div>
    )
  })

  const ranges = (settings.ranges || []).map((range, index) => {
    return (
      <GaugeRangeSettings
        key={index}
        index={index}
        range={range}
        unitsInfo={parameterInfo}
        onChange={handleRangeChange}
        onRemove={handleRangeRemove}
      />
    )
  })

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">{grid}</div>

          <table className="nsi-settings-table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.gauge.settings.ranges.type' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.gauge.settings.ranges.lower' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.gauge.settings.ranges.upper' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.gauge.settings.ranges.color' })}</th>
                <th />
              </tr>
            </thead>
            <tbody>{ranges}</tbody>
          </table>
          <PlusButton textId="dashboards.gauge.settings.add_range" onClick={handleRangeAdd} style={{ marginLeft: 0 }} />

          <ModalFooter error={error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: IGaugeComponent | IScaleComponent
  equipmentOptions: SelectOption[]
  onCancel: () => void
  onSave: (settings: IGaugeSettings) => void
  parameterOptions: SelectOption[]
  parameters: Map<string, ParameterDn>
  prefixes: Record<string, SiPrefix>
  title: string
  units: Record<string, SiUnit>
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipmentOptions: selectDataSourceOptions(state),
    parameterOptions: selectParameterOptions(state),
    parameters: selectDenormalizedParametersMap(state),
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(GaugeSettings)
