import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import http, { handleHttpError } from './http'

class HttpQueue {
  private cancelToken: CancelTokenSource = null

  public onStart = () => null
  public onFinish = () => null
  public onData: OnData = () => null
  public onError = (err) => handleHttpError(err)

  public cancel() {
    if (this.cancelToken != null) {
      this.cancelToken.cancel()
    }
  }

  public get(url: string, ctx?: any, timeout = 30_000) {
    if (this.cancelToken != null) {
      this.cancelToken.cancel()
    }

    this.cancelToken = axios.CancelToken.source()
    this.onStart()

    return http
      .get(url, { timeout, cancelToken: this.cancelToken.token })
      .then((r) => {
        this.cancelToken = null
        this.onData(r, ctx)
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.cancelToken = null
          this.onFinish()
          this.onError(err)
        }
      })
  }
}

type OnData = (r: AxiosResponse, ctx?: any) => void

export default HttpQueue
