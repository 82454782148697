import { IBarChartSettings, IBarChartThresholds } from 'au-nsi/dashboards'
import React from 'react'
import { IntlShape } from 'react-intl'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Dropdown from '../../../shared/Inputs/Dropdown'
import NumberInput from '../../../shared/Inputs/NumberInput'

const BarChartSettingsThresholds = (props: Props) => {
  const { row, index } = props

  const lineOptions = [
    { value: 'solid', title: props.intl.formatMessage({ id: 'dashboards.bar_chart.settings.thresholds.line.solid' }) },
    {
      value: 'dashed',
      title: props.intl.formatMessage({ id: 'dashboards.bar_chart.settings.thresholds.line.dashed' }),
    },
  ]

  const handleRemove = () => props.onRemove(index, 'thresholds')

  const handleValue = (value: number) => props.onChange('value', value, index)

  const handleColor = (color: string) => props.onChange('color', color, index)

  const handleLine = (line: IBarChartThresholds['line']) => props.onChange('line', line, index)

  return (
    <tr>
      <td>
        <NumberInput type="parameter" fullWidth={true} value={row.value} onChange={handleValue} {...props.unitsInfo} />
      </td>
      <td>
        <Dropdown name="line" options={lineOptions} value={row.line} onChange={handleLine} />
      </td>
      <td align="center" style={{ width: '8ch' }}>
        <ColorPicker position="left" color={row.color} onChange={handleColor} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

interface Props {
  settings: IBarChartSettings
  intl: IntlShape
  index: number
  row: IBarChartThresholds
  onChange: (name: string, value: any, index: number) => void
  onRemove: (index: number, key: string) => void
  unitsInfo: any
}

export default React.memo(BarChartSettingsThresholds)
