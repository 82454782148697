import styles from '../logbooks.module.css'
import { formatDate } from '../../../../../utils/lang'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import { memo } from 'react'
import { ILogbookEntry } from 'au-nsi/logbook'

interface IProps {
  entry: ILogbookEntry
  index: number
  onClick: (index: number) => void
  selected?: boolean
  firstValue: any
  secondValue: any
}

const LogbookEntryRow = ({ entry, index, onClick, selected, firstValue, secondValue }: IProps) => {
  // @ts-ignore
  const createdBy = useSelector((state: ReduxState) => state.users.items.find((user) => user.id === entry.created_by))

  return (
    <tr className={`${styles.logbookEntry} ${selected ? styles.selectedEntryRow : ''}`} onClick={() => onClick(index)}>
      <td>{entry.ts ? formatDate(entry.ts, 'dd.MM.yyyy') : '-'}</td>
      <td>{createdBy?.name || '-'}</td>
      {firstValue !== null && <td style={{ background: 'var(--bg-default-light)' }}>{firstValue || '-'}</td>}
      {secondValue !== null && <td style={{ background: 'var(--bg-default-light)' }}>{secondValue || '-'}</td>}
    </tr>
  )
}

export default memo(LogbookEntryRow)
