import { useCallback, useEffect, useState } from 'react'
import { produce, enableMapSet } from 'immer'
enableMapSet()

interface IProps {
  len: number
}

export const useSelect = ({ len }: IProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState(new Set<number | string>())
  const [selectedAll, setSelectedAll] = useState(false)

  useEffect(() => {
    setSelectedAll(selectedIndexes.size === len)
  }, [selectedIndexes.size])

  const setSelectAll = useCallback(
    (v: boolean) => {
      setSelectedAll(v)
      setSelectedIndexes(v ? new Set(Array(len).keys()) : new Set())
    },
    [len]
  )

  const setSelected = useCallback((index: number | string, selected: boolean) => {
    setSelectedIndexes((prev) =>
      produce(prev, (draft) => {
        selected ? draft.add(index) : draft.delete(index)
      })
    )
  }, [])

  return {
    selectedCount: selectedIndexes.size,
    selectedIndexes,
    selectedAll,

    setSelected,
    setSelectAll,
  }
}
