import { FormMode } from '../../interfaces'
import { useIntl } from 'react-intl'
import { ReactComponent as EditIcon } from 'icons/pen.svg'
import { ReactComponent as DeleteIcon } from 'icons/trash.svg'
import { ReactComponent as SaveIcon } from 'icons/save.svg'
import { ReactComponent as CancelIcon } from 'icons/decline.svg'
import styles from './formIcons.module.css'
import { PropsWithChildren } from 'react'

export interface IFormIconsProps {
  mode: FormMode
  onEdit: () => void
  onDelete?: () => void
  onCancel: () => void
  allowEditing: boolean
  allowDeleting: boolean
  className?: string
}

const FormIcons = (props: PropsWithChildren<IFormIconsProps>) => {
  const intl = useIntl()
  if (!props.allowEditing && !props.allowDeleting) return null

  const renderCancel = () => {
    if (props.mode === 'view') return null

    return (
      <span onClick={props.onCancel}>
        <CancelIcon /> {intl.formatMessage({ id: 'common.cancel' })}
      </span>
    )
  }

  const renderEditButton = () => {
    if (!props.allowEditing || props.mode !== 'view') return null

    return (
      <span onClick={props.onEdit}>
        <EditIcon />
        {intl.formatMessage({ id: 'common.edit' })}
      </span>
    )
  }

  const renderDeleteButton = () => {
    if (!props.allowDeleting || props.mode !== 'view') return null

    return (
      <span onClick={props.onDelete}>
        <DeleteIcon />
        {intl.formatMessage({ id: 'common.delete' })}
      </span>
    )
  }

  const renderSaveButton = () => {
    if (props.mode !== 'edit') return null

    return (
      <button type="submit">
        <SaveIcon />
        {intl.formatMessage({ id: 'common.save' })}
      </button>
    )
  }

  return (
    <div className={props.className || styles.formIcons}>
      {props.children}
      {renderCancel()}
      {renderEditButton()}
      {renderDeleteButton()}
      {renderSaveButton()}
    </div>
  )
}

export default FormIcons
