import { Incident } from 'au-nsi/signal-events'
import { ReduxState } from '../../redux/store.types'
import { API_URL } from '../../shared/constants'
import { rulesMapSelector } from '../SignalEvents/se.selectors'
import { getIncidentDevices } from './incidents.utils'

class AudioManager {
  private audio: HTMLAudioElement
  private iteration = 0

  start(audioId: string, repeat: number, onStop: () => void) {
    const audio = new Audio(API_URL + `/nsi/v1/audio/${audioId}/raw`)

    this.stop()
    this.audio = audio

    audio.play().catch((err) => console.warn('Can not play audio:', err))
    audio.addEventListener('abort', () => null)

    audio.addEventListener('ended', () => {
      if (this.iteration < repeat) {
        this.iteration += 1

        audio.currentTime = 0
        audio.play()
      } else {
        this.stop()
        onStop()
      }
    })
  }

  stop() {
    if (this.audio) {
      this.audio.pause()
      this.audio = null
      this.iteration = 0
    }
  }
}

export const audioManager = new AudioManager()

// получить аудио настройки сигнальной ситуации по которой произошел указанный инцидент
export const getAudioSettings = (incident: Incident, state: ReduxState) => {
  return incident.type === 'formula' ? getFormulaAudioSettings(incident, state) : getTypeAudioSettings(incident, state)
}

// получить настройки аудио для сигнальной ситуации по пользовательской формуле
const getFormulaAudioSettings = (incident: Incident, state: ReduxState) => {
  const rules = rulesMapSelector(state)
  const notificationSettings = state.user.notifications

  const ruleId = incident.formula_id
  const se = rules.get(ruleId)
  if (!se) return null

  const rule = se.rules.find((r) => r.id === ruleId)
  if (!rule) return null

  const audioId: string = se[rule.level + '_sound']
  const audioRepeat: number = se[rule.level + '_sound_repeat'] || 0

  // выбран ли сигнал в настройках сигнальной ситуации
  if (!audioId) return null

  // включено ли аудио уведомление для текущего пользователя
  const isTurnedOn = notificationSettings.formula.find(
    (e) => e.signal_event_id === se.id && e.level === rule.level && e.method === 'audio'
  )

  return isTurnedOn ? { audioId, audioRepeat, incident } : null
}

// получить настройки аудио для системной сигнальной ситуации
const getTypeAudioSettings = (incident: Incident, state: ReduxState) => {
  const notificationSettings = state.user.notifications

  const seType = state.signal_events.types.find((t) => t.id === incident.type)
  if (!seType || !seType.sound) return null

  const devices = new Set(getIncidentDevices(incident))

  const isTurnedOn = notificationSettings.equipment.find(
    (e) => e.type === incident.type && e.method === 'audio' && devices.has(e.equipment_id)
  )

  return isTurnedOn ? { audioId: seType.sound, audioRepeat: seType.sound_repeat || 0, incident } : null
}
