import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

/**
 * Перенаправление на форму логина с сохранением информации о том на какую страницу
 * пытался попасть пользователь для того чтобы при успешном входе сразу перейти к ней
 */
const LoginRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    navigate('/login', { state: { from: location.pathname } })
  }, [])

  return <div className="loading-screen" />
}

export default LoginRedirect
