import { useEffect, useState } from 'react'
import Clock from '../../services/clock/clock.service'
import Datepicker from '../../shared/Inputs/Datepicker/Datepicker'
import { formatDate } from '../../utils/lang'

interface Props {
  clock: Clock
  readonly: boolean
  onTimeChange: (time: number) => void
}

const TimeControls = ({ clock, readonly, onTimeChange }: Props) => {
  const [displayTime, setDisplayTime] = useState(Date.now())

  // время в clock изменяется каждый фрейм (~16мс), но т.к. отображаются только секунды,
  // то локальный стейт синхронизируем реже
  useEffect(() => {
    return clock.time$.subscribe(({ playerTime }) => {
      setDisplayTime((prev) => (Math.abs(playerTime - prev) >= 1000 ? playerTime : prev))
    })
  }, [])

  if (readonly) {
    return <div className="chart-player__time">{formatDate(displayTime, 'dd.MM.yyyy HH:mm:ss')}</div>
  }

  return (
    <Datepicker
      time={displayTime}
      onTimeChange={onTimeChange}
      wrapperClass="chart-player__time"
      inputClass="chart-player__time-input"
      disabled={readonly}
    />
  )
}

export default TimeControls
