import styles from '../catalogTemplate.module.css'
import { FormMode } from '../../../../../../shared/interfaces'
import { ReactComponent as EditIcon } from 'icons/pen.svg'
import { ReactComponent as CancelIcon } from 'icons/decline.svg'
import { ReactComponent as DeleteIcon } from 'icons/trash.svg'
import { ReactComponent as SaveIcon } from 'icons/save.svg'
import { ReactComponent as ConfirmIcon } from 'icons/check.svg'
import { useIntl } from 'react-intl'

interface IProps {
  mode: Omit<FormMode, 'create'>
  onSelect: () => void
  onDelete: () => void
  onDeleteConfirm: () => void
  onEdit: () => void
  onEditSave: () => void
  onCancel: () => void
}

const TemplateTileControls = (props: IProps) => {
  const intl = useIntl()

  const renderEditBtn = () => {
    return (
      <div className={styles.controlBtn} onClick={props.onEdit}>
        {intl.formatMessage({ id: 'common.edit' })}
        <EditIcon className="text--info" />
      </div>
    )
  }

  const renderCancelBtn = () => {
    return (
      <div className={styles.controlBtn} onClick={props.onCancel}>
        {intl.formatMessage({ id: 'common.cancel' })}
        <CancelIcon />
      </div>
    )
  }

  const renderDeleteBtn = () => {
    return (
      <div onClick={props.onDelete} className={styles.controlBtn}>
        {intl.formatMessage({ id: 'common.delete' })}
        <DeleteIcon className="text--danger" />
      </div>
    )
  }

  const renderSaveBtn = () => {
    return (
      <div onClick={props.onEditSave} className={styles.controlBtn}>
        {intl.formatMessage({ id: 'common.save' })}
        <SaveIcon className="text--success" />
      </div>
    )
  }

  const renderConfirmBtn = () => {
    return (
      <div onClick={props.mode === 'view' ? props.onSelect : props.onDeleteConfirm} className={styles.controlBtn}>
        {intl.formatMessage({ id: props.mode === 'view' ? 'catalogs.template.select' : 'common.confirm' })}
        <ConfirmIcon className="text--success" style={{ height: '16px' }} />
      </div>
    )
  }

  let Buttons
  switch (props.mode) {
    case 'view':
      Buttons = (
        <>
          {renderConfirmBtn()}
          {renderEditBtn()}
          {renderDeleteBtn()}
        </>
      )
      break
    case 'edit':
      Buttons = (
        <>
          {renderCancelBtn()}
          {renderSaveBtn()}
        </>
      )
      break
    case 'delete':
      Buttons = (
        <>
          {renderCancelBtn()}
          {renderConfirmBtn()}
        </>
      )
  }

  return <div className={styles.controls}>{Buttons}</div>
}

export default TemplateTileControls
