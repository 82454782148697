import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import { useEffect, useMemo, useState } from 'react'
import { catalogsActions } from '../../catalogs.actions'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { patchInstanceUpdate, requestInstanceDelete } from '../../catalogs.api'
import http, { handleHttpError, handleHttpResponse } from '../../../../utils/http'
import { isJsonEqual } from '../../../../utils/misc'
import { findDocumentsDiff } from './instanceForm.utils'
import useInstanceFormCallback from './useInstanceFormCallback'
import InstanceFormWithDocs from './InstanceFormWithDocs'
import FormButtons from '../../../../shared/Forms/FormButtons'
import { FormMode } from '../../../../shared/interfaces'
import { ICatalogItemWithFiles } from '../../catalogs.types'
import { IDocument } from 'au-nsi/documents'
import Form from '../../../../shared/Forms/Form'
import { useParams } from 'react-router-dom'

const InstanceFormRoute = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [schemaId, instanceId] = [parseInt(params.id), parseInt(params.instanceId)]
  const { wait, loading } = useHttpLoader()

  const currentDocuments = useSelector((state: ReduxState) => state.documents.items)
  const schema = useSelector((state: ReduxState) => state.catalogs.catalogs.find((c) => c.id === schemaId))
  const instance = useMemo(
    () => schema?.instances?.find((instance) => instance.id === instanceId),
    [schema, instanceId]
  )

  const [mode, setMode] = useState<FormMode>('view')
  const [item, setItem] = useState<ICatalogItemWithFiles>(instance)

  const onChange = useInstanceFormCallback({ setter: setItem, propertiesKey: 'properties', deps: [] })

  useEffect(() => {
    if (instanceId !== item?.id) setMode('view')
    if (mode === 'view') setItem(instance)
  }, [instance, mode])

  if (!item) return null

  const handleInstanceDelete = () => {
    const req = requestInstanceDelete(item)

    wait(req, (removed) => {
      dispatch(catalogsActions.instanceDeleted(removed))
      setMode('view')
    })
  }

  const handleInstanceSave = async () => {
    const fetch = async () => {
      const { files, ...updated } = item

      if (files) {
        const requests = []
        const { createdFiles, removedIds, updatedFiles } = findDocumentsDiff(currentDocuments, files as IDocument[])

        if (removedIds.length) {
          requests.push(
            http
              .delete(`/nsi/v1/documents?${removedIds.map((id, i) => `${i === 0 ? '' : '&'}ids=${id}`).join('')}`)
              .then(handleHttpResponse)
              .catch(handleHttpError)
          )
        }
        if (createdFiles.length) {
          requests.push(
            http.post(`/nsi/v1/documents/files`, createdFiles).then(handleHttpResponse).catch(handleHttpError)
          )
        }

        for (const file of updatedFiles) {
          requests.push(http.patch('/nsi/v1/documents/' + file.id, file))
        }

        await Promise.all(requests)
      }

      if (!isJsonEqual(updated, instance)) {
        return patchInstanceUpdate(updated).then((r) => r ?? updated)
      }
    }

    wait(fetch(), (res) => {
      if (res) dispatch(catalogsActions.instanceUpdated(res))
      setMode('view')
    })
  }

  return (
    <Form editing={mode === 'edit'} onSubmit={handleInstanceSave}>
      <InstanceFormWithDocs
        item={item}
        resourceItemId={item.id}
        propertiesKey={'properties'}
        nameKey={'name'}
        onChange={onChange}
        editing={mode === 'edit'}
        onNameChange={(name) => setItem({ ...item, name })}
        onDocsChange={(files) => setItem({ ...item, files })}
        schema={schema.schema_tree}
      />
      <FormButtons
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onDelete={() => setMode('delete')}
        onDeleteConfirm={handleInstanceDelete}
        allowEditing={true}
        allowDeleting={true}
        isLoading={loading}
      />
    </Form>
  )
}

export default InstanceFormRoute
