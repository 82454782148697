import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import classNames from 'classnames'
import { onResize } from 'hooks/useResize'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAppSelector } from '../../../redux/store'
import { defaultTitle } from '../../../shared/constants'
import GaugeSettings from '../../../shared/Gauge/GaugeSettings'
import ChartSettings from '../../../shared/LineCharts/settings/ChartSettings'
import confirmService from '../../../shared/Modal/confirm.service'
import VectorGraphSettings from '../../../shared/VectorGraph/VectorGraphSettings'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import playerArchiveState from '../../ChartPlayer/Archive/archive.state'
import ChartPlayer from '../../ChartPlayer/ChartPlayer'
import commandsActions from '../../Commands/commands.actions'
import GanttModal from '../../GanttTable/components/GanttModal'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import { systemPublicState } from '../../System/system.state'
import BarChartSettings from '../BarChart/BarChartSettings'
import ButtonSettings from '../Button/ButtonSettings'
import actions from '../dashboard.actions'
import { DashboardsState } from '../dashboard.reducers'
import { CardMoveType, IAutoalignData, WidgetType } from '../dashboard.types'
import { constructTitle } from '../dashboard.utils'
import * as utils from '../generators'
import HistogramSettings from '../Histogram/HistogramSettings'
import ImageSettings from '../Image/ImageSettings'
import IndicatorSettings from '../Indicator/IndicatorSettings'
import MapSettings from '../Map/MapSettings'
import PhasePortraitSettings from '../PhasePortrait/PhasePortraitSettings'
import SVGSettings from '../SVG/SVGSettings'
import TableSettings from '../Table/TableSettings'
import TemplateSettings from '../Template/TemplateSettings'
import TextSettings from '../Text/TextSettings'
import WindroseSettings from '../Windrose/WindroseSettings'
import * as autoalign from './autoalign.utils'
import DashboardCard from './DashboardCard'
import DashboardControls from './DashboardControls'
import DashboardGrid from './DashboardGrid'
import { WidgetTypeSelectModal } from './TypeSelectModal/WidgetTypeSelectModal'

const modals: { [type in WidgetType]: React.ComponentType<any> } = {
  bar_chart: BarChartSettings,
  button: ButtonSettings,
  gantt: GanttModal,
  gauge: GaugeSettings,
  gauge_linear: GaugeSettings,
  histogram: HistogramSettings,
  image: ImageSettings,
  indicator: IndicatorSettings,
  linear_chart: ChartSettings,
  map: MapSettings,
  phase_portrait: PhasePortraitSettings,
  svg_diagram: SVGSettings,
  table: TableSettings,
  template_variables: TemplateSettings,
  text: TextSettings,
  vector_chart: VectorGraphSettings,
  windrose: WindroseSettings,
}

const widgetTypes: WidgetType[] = [
  'linear_chart',
  'table',
  'text',
  'indicator',

  'histogram',
  'bar_chart',
  'gauge',
  'gauge_linear',

  'svg_diagram',
  'button',
  'image',
  'phase_portrait',

  'map',
  'gantt',
  'vector_chart',
  'windrose',
]

export const Dashboard = (props: Props) => {
  const intl = useIntl()
  const [viewport, setViewport] = React.useState(0)

  const archiveEntry = useRecoilValue(playerArchiveState)
  const systemInfo = useRecoilValue(systemPublicState)

  // т.к. при перетаскивании и масштабировании обновления происходят с высокой частотой, то они
  // сначала сохраняются только локально и только периодически отправляются на сервер и в редакс
  const positionChanges = React.useRef(null)

  const dashboardRef = React.useRef<HTMLDivElement>()
  const [menuOpen, setMenuOpen] = React.useState(false)

  const allowedWidgets = useMemo(() => {
    let allowedTypes = [...widgetTypes]
    if (!props.modules.has('maps')) allowedTypes = allowedTypes.filter((t) => t !== 'map')
    if (props.dashboard?.is_template && !props.components.some((c) => c.type === 'template_variables')) {
      allowedTypes.push('template_variables')
    }

    return allowedTypes
  }, [props.modules, props.dashboard?.is_template, props.components])

  const gridStep = Math.round((props.controls.grid_step * viewport) / 100)

  // данные необходимые для рассчета координат компонентов при перетаскивании с включенным
  // автовыравниванием
  const autoalignHintRef = React.useRef<HTMLDivElement>()
  const autoalignDataRef = React.useRef<IAutoalignData>(autoalign.defaultData)
  autoalignDataRef.current.components = props.components
  autoalignDataRef.current.viewport = viewport
  autoalignDataRef.current.gridStep = gridStep
  autoalignDataRef.current.mode = props.controls.autoalign

  // удаление, редактирование, добавление
  const [state, setState] = React.useState({ mode: 'view', component: null })
  const [showGrid, setGrid] = React.useState(false)
  const [isMoving, setMoving] = React.useState(false) // пользователь перетаскивает компонент

  // отмена автоматического выравнивания при нажатии на Esc
  const cancelAutoalign = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      autoalign.cancel(autoalignHintRef, autoalignDataRef)
    }
  }, [])

  // синхронизировать локальные изменения с сервером и стором
  const syncPositionChanges = React.useCallback(() => {
    const changes = positionChanges.current

    if (changes != null) {
      // если включено автоматическое выравнивание, то берем координаты из него
      const autoposition = autoalignDataRef.current
      if (!autoposition.hidden) {
        const vw = autoposition.viewport
        changes.x = autoposition.x / vw
        changes.y = autoposition.y / vw
        changes.w = autoposition.w / vw
        changes.h = autoposition.h / vw
      }

      props.onPositionUpdate(positionChanges.current)
    }

    autoalign.reset(autoalignHintRef, autoalignDataRef, cancelAutoalign)
    positionChanges.current = null
    setMoving(false)
  }, [])

  // открыть модальное окно с настройками выбранного компонента
  const handleCreate = (type: IDashboardComponent['type']) => {
    const component = utils.generators[type](props.components, props.dashboard.id, null, null, viewport)

    if (type === 'template_variables') props.onCreate(component)
    else setState({ mode: 'create', component })

    setMenuOpen(false)
  }

  // отправить запрос на сервер на создание нового компонента
  const handleCreateFinal = (settings) => {
    props.onCreate({ ...state.component, settings })
    handleCancel()
  }

  // открыть модальное окно для редактирования настроек компонента
  const handleEdit = React.useCallback((component) => {
    setState({ mode: 'edit', component })
  }, [])

  // сохранить измененные настройки
  const handleEditFinal = (settings) => {
    props.onSettingsUpdate({ ...state.component, settings })
    handleCancel()
  }

  // запросить подтверждение и затем удалить компонент
  const handleDelete = React.useCallback((component) => {
    confirmService.requestDeleteConfirmation().then((res) => {
      if (res !== 'cancel') props.onDelete(component)
      handleCancel()
    })
  }, [])

  const handleCancel = (reason?: string) => {
    if (reason !== 'click-outside') {
      setState({ mode: 'view', component: null })
    }
  }

  // загружаем компоненты экрана
  React.useEffect(() => {
    props.onMount()
  }, [props.id])

  // добавляем название экрана в название страницы
  React.useEffect(() => {
    const title = systemInfo.title || defaultTitle

    if (props.dashboard && props.dashboard.name) {
      document.title = props.dashboard.name + ' - ' + title

      return () => {
        document.title = title
      }
    }
  }, [props.dashboard && props.dashboard.name])

  // выставление ширины дашборда равной ширине родительского контейнера
  React.useEffect(() => {
    if (dashboardRef.current) {
      let timer
      setViewport(dashboardRef.current.offsetWidth)

      const clearObserver = onResize(dashboardRef, () => {
        clearTimeout(timer)
        timer = setTimeout(() => setViewport(dashboardRef.current.offsetWidth), 100)
      })

      return () => {
        clearObserver()
        clearTimeout(timer)
      }
    }
  }, [dashboardRef.current])

  // подстроить высоту экрана так чтобы вошли все элементы
  React.useEffect(() => {
    let height = 0

    for (const component of props.components) {
      height = Math.max(height, (component.y + component.h) * viewport)
    }

    if (dashboardRef.current) dashboardRef.current.style.height = height + 'px'
  }, [viewport, props.components])

  // обработка перетаскивания или изменения размеров компонентов
  const handleMove = React.useCallback(
    (position: IDashboardComponent, type: CardMoveType) => {
      positionChanges.current = position

      if (type !== 'layer') {
        const height = parseFloat(dashboardRef.current.style.height)
        const edge = (position.y + position.h) * viewport
        if (edge > height) dashboardRef.current.style.height = edge + 10 + 'px'

        autoalign.suggest({
          position,
          data: autoalignDataRef,
          hint: autoalignHintRef,
          oncancel: cancelAutoalign,
          height,
          type,
        })
      }

      if (!isMoving) setMoving(true)
    },
    [viewport, isMoving]
  )

  const title = React.useMemo(
    () => constructTitle(props.dashboard, props.dashboards, props.templateDevice),
    [props.dashboard, props.dashboards, props.templateDevice]
  )

  // маппинг отображаемых устройств на другие, указанные в переменных шаблона, если это экран-шаблон
  const deviceMapping = React.useMemo(() => {
    if (!props.dashboard || !props.dashboard.is_template) return null

    // если передан templateDevice, значит это шаблон в который подставляется одно это устройство
    if (props.templateDevice) {
      const initialDevice = props.dashboard.template_settings.variables[0].device_id
      const replacingDevice = props.templateDevice.id
      return new Map([[initialDevice, replacingDevice]])
    }

    // шаблон с произвольным количеством подставляемых устройств
    const result = new Map<string, string>()
    for (const [key, value] of Object.entries(props.templateVariables || {})) {
      result.set(key, value)
    }

    return result
  }, [props.dashboard, props.templateDevice, props.templateVariables])

  if (!props.dashboard || !viewport) return <div className="dashboard is-loading" ref={dashboardRef} />

  const components = props.components.map((c) => {
    return (
      <DashboardCard
        allowEditing={props.allowEditing && props.controls.allow_editing}
        deviceMapping={deviceMapping}
        component={c}
        intl={intl}
        key={c.id}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onMove={handleMove}
        onMoveFinish={syncPositionChanges}
        viewport={viewport}
      />
    )
  })

  // модальное окно для добавления или редактирования компонента
  const renderModal = () => {
    const isCreating = state.mode === 'create'
    const isEditing = state.mode === 'edit'
    if (!isCreating && !isEditing) return null

    const { type } = state.component
    const Modal = modals[type]
    const onSave = isCreating ? handleCreateFinal : handleEditFinal
    const title = 'dashboards.add.' + type

    return <Modal title={title} component={state.component} onSave={onSave} onCancel={handleCancel} />
  }

  const className = classNames('dashboard', { 'is-archive': archiveEntry, 'is-embedded': props.embeddedMode })

  const renderGrid = (isMoving || showGrid) && props.controls.autoalign === 'grid'
  const renderControls = props.allowEditing && !isMoving

  return (
    <React.Fragment>
      {!props.embeddedMode && (
        <ChartPlayer page={props.dashboard.id} deviceId={props.templateDevice?.id} title={title} />
      )}

      <div ref={dashboardRef} className={className}>
        {components}
        {menuOpen && props.allowEditing && (
          <WidgetTypeSelectModal types={allowedWidgets} onSelect={handleCreate} onClose={() => setMenuOpen(false)} />
        )}
        {props.allowEditing && renderModal()}
        <div className="dashboard__autoalign-hint" ref={autoalignHintRef} />

        {renderGrid && <DashboardGrid gridStep={gridStep} />}
      </div>

      {renderControls && <DashboardControls onGridChange={setGrid} onComponentAdd={() => setMenuOpen(true)} />}
    </React.Fragment>
  )
}

interface Props {
  allowEditing: boolean
  components: IDashboardComponent[]
  controls: DashboardsState['controls']
  dashboard: IDashboard
  dashboards: IDashboard[]
  id: string
  modules: Set<string>
  templateDevice?: Equipment
  templateVariables?: Record<string, string>
  embeddedMode?: boolean
  onCreate: (c: IDashboardComponent) => void
  onDelete: (c: IDashboardComponent) => void
  onMount: () => void
  onPositionUpdate: (c: IDashboardComponent) => void
  onSettingsUpdate: (c: IDashboardComponent) => void
}

const DashboardRoute = () => {
  const dispatch = useDispatch()
  const { id, deviceId } = useParams()

  const props = useAppSelector((state) => {
    const { dashboards } = state.dashboards

    const dashboard = dashboards.find((d) => d.id === id)
    const components = state.dashboards.components[id] || []
    const controls = state.dashboards.controls

    const devices = selectEquipmentMap(state)
    const modules = selectEnabledModules(state)
    const rights = selectAccessRights(state)

    const isTemplate = deviceId && dashboard && dashboard.is_template
    const allowEditing = rights['dashboards:update'] && !isTemplate

    // если в url указан device_id (переход с карточки устройства),
    // то значит это шаблон экрана, который надо отрисовать с указанным устройством
    const templateDevice = deviceId ? devices.get(deviceId) : null
    const templateVariables = state.dashboards.templateVariables[id]

    return { allowEditing, components, controls, dashboard, dashboards, id, modules, templateDevice, templateVariables }
  }, shallowEqual)

  const onMount = () => {
    dispatch(actions.loadDashboardComponents(id))
    dispatch(commandsActions.loadCommands())
  }

  const onCreate = (component: IDashboardComponent) =>
    dispatch(actions.createComponent(component.dashboard_id, component))
  const onSettingsUpdate = (component: IDashboardComponent) => dispatch(actions.updateComponentSettings(component))
  const onPositionUpdate = (component: IDashboardComponent) => dispatch(actions.updateComponentPosition(component))
  const onDelete = (component: IDashboardComponent) =>
    dispatch(actions.deleteComponent(component.id, component.dashboard_id))

  const handlers = { onMount, onCreate, onSettingsUpdate, onPositionUpdate, onDelete }

  return <Dashboard {...props} {...handlers} />
}

export default DashboardRoute
