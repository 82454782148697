export interface UserDevice {
  id: string
  name: string
  reason: 'unrestricted' | 'user' | 'role' | 'organization'
  expires_at?: number
}

export const comparator = (a: UserDevice, b: UserDevice) => {
  const w1 = getWeight(a)
  const w2 = getWeight(b)

  return w1 !== w2 ? w2 - w1 : a.name.localeCompare(b.name)
}

const getWeight = (e: UserDevice) => {
  switch (e.reason) {
    case 'user':
      return 3
    case 'role':
      return 2
    case 'organization':
      return 1
    default:
      return 0
  }
}

export const DAY = 24 * 60 * 60 * 1000

// конец текущего дня в 24:00
export const getMidnight = () => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date.valueOf() + DAY
}
