import { FormattedMessage } from 'react-intl'

const NoResults = () => {
  return (
    <div className="s-logs__no-results">
      <FormattedMessage id="common.no_results" />
    </div>
  )
}

export default NoResults
