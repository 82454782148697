import { TooltipState } from './Tooltip'
import { comparisonColors, IWindroseData } from './windrose.utils'
import css from './windrose.module.css'

/**
 * Отрисовка всех линий указанных в настройках розы ветров в режиме сравнения
 */
const WindroseComparison = (props: Props) => {
  const { data, center, radius } = props

  const paths = [] // контуры розы ветров для всех параметров
  const dots = [] // точки значений для всех параметров и углов

  const handleMouseEnter = (e) => {
    const { angle, percent } = e.currentTarget.dataset
    props.onHover({ angle: +angle, speed: null, percent: +percent })
  }

  for (let i = 0; i < data.length; i += 1) {
    const row = data[i]
    if (!row) continue

    const color = comparisonColors[i]
    let d = ''

    for (const angleBucket of row.angles) {
      const { angle } = angleBucket
      const r = (radius * angleBucket.count_angle) / row.count_max

      const command = angle === 0 ? 'M' : 'L'
      const x = center + r * Math.sin(angle)
      const y = center - r * Math.cos(angle)
      d += `${command}${x},${y} `

      // точка со значением изначально скрыта, показывается при наведении мышью
      dots.push(
        <circle
          key={dots.length}
          className={css.sectorDot}
          cx={x}
          cy={y}
          r={6}
          fill={color}
          data-angle={angle}
          data-percent={angleBucket.count_angle / row.count_total}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => props.onHover(null)}
        />
      )
    }

    d += 'Z'
    paths.push(<path key={i} d={d} stroke={color} strokeWidth="2" fill="none" />)
  }

  const grid =
    data[0] &&
    data[0].angles.map(({ angle }) => {
      return (
        <line
          key={angle}
          x1={center}
          y1={center}
          x2={center - radius * Math.sin(angle)}
          y2={center - radius * Math.cos(angle)}
          stroke="rgba(0, 0, 0, 1)"
        />
      )
    })

  return (
    <g>
      {grid}
      {paths}
      {dots}
    </g>
  )
}

interface Props {
  data: IWindroseData[]
  center: number
  radius: number
  onHover: (data: TooltipState) => void
}

export default WindroseComparison
