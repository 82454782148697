import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Dropdown from '../../../../shared/Inputs/Dropdown'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextareaInput from '../../../../shared/Inputs/TextareaInput'
import Modal from '../../../../shared/Modal/Modal'
import InstanceRow from '../../../Catalogs/components/InstanceForm/InstanceRow'
import styles from '../../maptopology.module.css'
import { selectPassportIdentifiers } from '../../maptopology.selectors'
import Loader from '../../../../shared/Utils/Loader'
import http from '../../../../utils/http'
import { AxiosResponse } from 'axios'
import { selectEquipmentMap } from '../../../Nsi/nsi.selectors'
import { useSetRecoilState } from 'recoil'
import stateConnectDevice from './connectDevice.recoil'
import { IPlace } from 'au-nsi/shared'

interface IProps {
  place: IPlace
  onClose: () => void
}

/**
 * Форма подключения устройства
 */
const ConnectDeviceModal = ({ onClose, place }: IProps) => {
  const intl = useIntl()
  const { loading, wait } = useHttpLoader()

  const passports = useSelector(selectPassportIdentifiers)
  const equipment = useSelector(selectEquipmentMap)

  const setConnectingDevices = useSetRecoilState(stateConnectDevice)
  const [passportType, setPassportType] = useState<number>(passports[0].id)
  const [values, setValues] = useState<Record<string, any>>({})
  const [notes, setNotes] = useState('')
  const [error, setError] = useState<string>()

  const passportOptions = passports.map((p) => ({ value: p.id, title: p.name }))
  const passport = passports.find((p) => p.id === passportType)

  const handlePassportChange = (v: number) => {
    setPassportType(v)
    setValues({})
  }

  const handleValueChange = (key: string, value: any) => {
    if (error) setError(null)
    setValues({ ...values, [key]: value })
  }

  // Отправка заявки: сначала отправляем запрос для поиска устройства по выбранному адресу и с
  // веденными значениями паспорта. Затем, если устройство найдено, отправляем заявку на получение
  // прав доступа с id устройства
  const handleSend = () => {
    const payload = { address: place, passport_catalog_id: passportType, passport_values: values }
    const p = http.post('/nsi/v1/equipment/match', payload)

    const onError = () => setError(intl.formatMessage({ id: 'system.maps.connect_equipment.not_found' }))

    const onData = (r: AxiosResponse) => {
      const { id, name, address } = r.data
      if (equipment.has(id)) {
        return setError(intl.formatMessage({ id: 'system.maps.connect_equipment.already_connected' }))
      }

      setConnectingDevices((prev) => [...prev, { id, name, address: address.name, notes }])
      onClose()
    }

    wait(p, onData, onError)
  }

  // ввод полей-идентификаторов указанных в схеме паспорта
  const inputs = passport.schema.map((row, i) => {
    return (
      <InstanceRow
        key={i}
        value={values[row.id]}
        rowSchema={row}
        editing={true}
        catalogs={null}
        onChange={handleValueChange}
      />
    )
  })

  const renderFooter = () => {
    const isValid = passport.schema.every((p) => values[p.id] != null)
    if (!isValid) return null

    if (loading)
      return (
        <div className="center">
          <Loader />
        </div>
      )

    return (
      <div className={styles.createDeviceFooter}>
        <button onClick={handleSend} className="nsi-button default">
          {intl.formatMessage({ id: 'system.maps.request_connect' })}
        </button>
      </div>
    )
  }

  return (
    <Modal onClose={onClose} size="md">
      <h2>{intl.formatMessage({ id: 'system.maps.connect_equipment' })}</h2>
      <InputRow label={intl.formatMessage({ id: 'nsi.address' })}>{place.name}</InputRow>

      {passports.length > 1 && (
        <InputRow label={intl.formatMessage({ id: 'system.maps.connect_equipment.device_type' })}>
          <Dropdown name="" options={passportOptions} value={passportType} onChange={handlePassportChange} />
        </InputRow>
      )}

      <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 4px', marginBottom: '1em' }}>
        <tbody>{inputs}</tbody>
      </table>

      <InputRow label={intl.formatMessage({ id: 'system.maps.connect_equipment.notes' })}>
        <TextareaInput value={notes} onChange={setNotes} />
      </InputRow>

      <div className="text--center text--danger">{error}</div>

      {renderFooter()}
    </Modal>
  )
}

export default ConnectDeviceModal
