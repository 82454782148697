import { Select } from '@alterouniversal/au-react-components'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { getRelatedUnits } from './params.utils'

/**
 * Компонент для выбора единицы измерения (si unit + si prefix)
 */
const UnitSelector = (props: Props) => {
  // список опций для выбора единицы отображения параметра
  const options = React.useMemo(() => {
    return getRelatedUnits(props.referenceUnit, props.units, props.prefixes).map(({ unit, prefix }) => {
      const prefixId = prefix ? prefix.id : 'null'
      const value = prefixId + ':' + unit.id
      const label = formatUnitLabel(unit, prefix)
      return { value, label }
    })
  }, [props.units, props.prefixes, props.referenceUnit])

  const handleChange = (value: string) => {
    const [prefix, unit] = value.split(':')
    props.onChange(unit, prefix === 'null' ? null : prefix, props.name)
  }

  const value = props.selectedUnit ? props.selectedPrefix + ':' + props.selectedUnit : ''

  return <Select name={props.name} options={options} value={value} onChange={handleChange} disabled={props.disabled} />
}

export const formatUnitLabel = (unit: SiUnit, prefix: SiPrefix) => {
  if (!unit) return ''

  const prefixName = prefix ? prefix.name : ''
  const prefixSymbol = prefix ? prefix.symbol : ''

  let shortname = (prefixSymbol + unit.symbol).trim()
  if (shortname !== '') {
    shortname = ` (${shortname})`
  }

  return prefixName + unit.name + shortname
}

interface Props {
  name?: string
  onChange: (unit: string, prefix: string, name?: string) => void
  prefixes: { [id: string]: SiPrefix }
  referenceUnit: string
  selectedPrefix: string
  selectedUnit: string
  units: { [id: string]: SiUnit }
  disabled?: boolean
}

export default UnitSelector
