import http, { handleHttpError } from '../../../utils/http'
import { ModerationState } from './moderation.reducer'
import { IModerationRequest } from 'au-nsi/moderation'

const API_PREFIX = '/nsi/v1/moderation_requests'

export const getRequests = async (
  size = 20,
  nextPage: string,
  filters: ModerationState['filters']
): Promise<{ results: IModerationRequest[]; nextPage: string }> => {
  let query = ''
  if (nextPage) query += `&page=${nextPage}`
  if (filters.showOnlyActive) query += `&onlyActive=${filters.showOnlyActive}`
  for (const resource of filters.resources) query += `&resources=${resource}`

  return http
    .get(API_PREFIX + `?size=${size}` + query)
    .then((r) => r.data)
    .catch((err) => {
      handleHttpError(err)
      return { results: [], nextPage: null }
    })
}

export const getRequest = async (id: number) => {
  return http.get(API_PREFIX + '/' + id).then((r) => r.data)
}

export const approveRequest = async (request: IModerationRequest) => {
  let url = '/nsi/' + request.url

  if (request.url.includes('?')) url += '&x_approve_requestid=' + request.id
  else url += '?x_approve_requestid=' + request.id

  return http[request.method.toLowerCase()](url, request.request_body)
    .then((r) => r.data)
    .catch(handleHttpError)
}

export const patchUpdateRequest = async <T extends IModerationRequest>(updated: T): Promise<T> => {
  let url = '/nsi/' + updated.url

  if (updated.url.includes('?')) url += '&x_update_requestid=' + updated.id
  else url += '?x_update_requestid=' + updated.id

  return http[updated.method.toLowerCase()](url, updated.request_body)
    .then((r) => r.data)
    .catch(handleHttpError)
}

export const requestDeleteRequest = async (id: IModerationRequest['id']) => {
  const removed = await http
    .delete(API_PREFIX + '/' + id)
    .then((r) => r.data)
    .catch(handleHttpError)

  return removed
}

export const rejectRequest = async <T extends IModerationRequest>(request: T, reason: string): Promise<T> => {
  let url = '/nsi/' + request.url

  if (request.url.includes('?')) url += '&x_reject_requestid=' + request.id
  else url += '?x_reject_requestid=' + request.id

  if (reason) url += '&reason=' + encodeURIComponent(reason)

  return http[request.method.toLowerCase()](url, request.request_body)
    .then((r) => r.data)
    .catch(handleHttpError)
}
