import { useIntl } from 'react-intl'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { PLacePicker } from '../../../../../../shared/Inputs/PlacePicker/PlacePicker'
import EquipmentRequestFormPassport from './EquipmentRequestFormPassport'
import TextareaInput from '../../../../../../shared/Inputs/TextareaInput'
import AEControllerIdInput from '../../../../../../shared/AE/ControllerIdInput/AEControllerIdInput'
import NumberInput from '../../../../../../shared/Inputs/NumberInput'
import GroupSelect from '../../../../../MapTopology/Groups/components/GroupSelect'
import useGroup from '../../../../../MapTopology/Groups/hooks/useGroup'
import GroupsLoader from '../../../../../MapTopology/Groups/components/GroupsLoader'
import { Equipment, LP001Equipment } from 'au-nsi/equipment'

interface IProps {
  prevDevice: Equipment
  equipment: Partial<Equipment>
  onPassportValuesChange: (schemaId: string, value: any) => void
  onChange: (updated: Partial<Equipment>) => void
  editing: boolean
}

const EquipmentRequestFormLayout = ({ equipment, prevDevice, onChange, editing, onPassportValuesChange }: IProps) => {
  const intl = useIntl()
  const groupId = (equipment as LP001Equipment)?.attributes?.group_id
  const group = useGroup(groupId)

  const handleChange = (val: any, name: string) => {
    onChange && onChange({ ...equipment, [name]: val })
  }

  const renderShortName = () => {
    if (!equipment.shortname) return null

    return (
      <div className="nsi-row">
        <div className="nsi-label">{intl.formatMessage({ id: 'nsi.shortname' })}</div>
        <div>
          <TextInput value={equipment.shortname} onChange={handleChange} name={'shortname'} disabled={!editing} />
        </div>
      </div>
    )
  }

  const renderConfiguration = () => {
    if (equipment.protocol !== 'LP001' || !equipment.configuration) return null
    const { configuration } = equipment

    return (
      <div className="nsi-row">
        <div className="nsi-label">{intl.formatMessage({ id: 'nsi.controller_id' })}</div>
        <div>
          <AEControllerIdInput
            value={editing ? configuration.controller_id : configuration.controller_id || '-'}
            onChange={(id) => onChange({ ...equipment, configuration: { ...configuration, controller_id: id } })}
            name={'parameters_mapping'}
            disabled={!editing}
          />
        </div>
      </div>
    )
  }

  const renderAddress = () => {
    if (!equipment.address) return null

    return (
      <div className="nsi-row">
        <div className="nsi-label">{intl.formatMessage({ id: 'nsi.address' })}</div>

        <PLacePicker
          selectedPlace={equipment.address}
          onChange={(address) => handleChange(address, 'address')}
          isEditing={editing}
          controlByParent={true}
        />
      </div>
    )
  }

  const renderNotes = () => {
    if (!equipment.notes) return null

    return (
      <div className="nsi-row">
        <div className="nsi-label" style={{ alignSelf: 'flex-start' }}>
          {intl.formatMessage({ id: 'nsi.device.notes' })}
        </div>
        {!editing && (
          <div style={{ whiteSpace: 'pre-wrap', lineHeight: '20px', marginTop: '6px' }}>{equipment.notes}</div>
        )}
        {editing && (
          <TextareaInput
            value={equipment.notes}
            onChange={(notes) => handleChange(notes, 'notes')}
            style={{ margin: 0, width: '100%' }}
          />
        )}
      </div>
    )
  }

  const renderAttributes = () => {
    if (!equipment.attributes || equipment.protocol !== 'LP001') return null

    return (
      <>
        <div className="nsi-row">
          <div className="nsi-label">{intl.formatMessage({ id: 'nsi.lp001.entrance' })}</div>
          {!editing && <div>{equipment.attributes.entrance || '-'}</div>}
          {editing && (
            <NumberInput
              name={''}
              integer={true}
              min={0}
              max={100}
              onChange={(val) => onChange({ ...equipment, attributes: { ...equipment.attributes, entrance: val } })}
              value={equipment.attributes.entrance}
            />
          )}
        </div>
        <GroupsLoader>
          <div className="nsi-row">
            <div className="nsi-label">{intl.formatMessage({ id: 'map.ae.group' })}</div>
            {!editing && (group?.name || '-')}
            {editing && (
              <GroupSelect
                groupId={equipment.attributes.group_id}
                address={equipment.address ?? prevDevice.address}
                name={''}
                onChange={(val) => onChange({ ...equipment, attributes: { ...equipment.attributes, group_id: val } })}
              />
            )}
          </div>
        </GroupsLoader>
      </>
    )
  }

  return (
    <div>
      {renderShortName()}
      {renderConfiguration()}
      {renderAddress()}
      {renderAttributes()}
      {renderNotes()}
      {equipment.passport_values && equipment.passport_catalog_id && (
        <EquipmentRequestFormPassport
          device={equipment as Equipment}
          onChange={onPassportValuesChange}
          editing={editing}
          prevDevice={prevDevice}
        />
      )}
    </div>
  )
}

export default EquipmentRequestFormLayout
