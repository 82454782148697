import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { IChartLine } from 'au-nsi/dashboards'
import React from 'react'
import { IntlShape } from 'react-intl'
import RemoveButton from '../../Forms/RemoveButton'
import ColorPicker from '../../Inputs/Colorpicker/ColorPicker'
import Dropdown from '../../Inputs/Dropdown'

/**
 * Строка таблицы настроек отображаемых на графике линий
 */
const LinesTableRow = (props: Props) => {
  const { line, index } = props

  const axisOptions = [
    { value: false, title: props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axis.main' }) },
    { value: true, title: props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axis.mirror' }) },
  ]

  const handleRemove = () => props.onRemove(index, 'lines')

  // изменение устройства
  const handleDevice = (device_id: string) => {
    const updates = { ...line, device_id }

    // если цвет линии еще не задан, то выставляем цвет устройства
    if (!line.color) {
      const device = props.equipmentOptions.find((o) => o.value === device_id)
      updates.color = device.color
    }

    props.onChange(index, updates, 'lines')
  }

  // изменение параметра
  const handleParameter = (parameter_id: string) => props.onChange(index, { ...line, parameter_id }, 'lines')

  // изменение цвета линии
  const handleColor = (color: string) => props.onChange(index, { ...line, color }, 'lines')

  // изменение оси
  const handleAxis = (mirror: boolean) => props.onChange(index, { ...line, mirror }, 'lines')

  return (
    <tr>
      <td style={{ width: '24ch' }}>
        <Select name="" options={props.equipmentOptions} value={line.device_id} onChange={handleDevice} />
      </td>
      <td>
        <Select name="" options={props.parameterOptions} value={line.parameter_id} onChange={handleParameter} />
      </td>
      {props.mirrorY && (
        <td>
          <Dropdown value={line.mirror || false} options={axisOptions} onChange={handleAxis} />
        </td>
      )}
      <td align="center">
        <ColorPicker position="left" color={line.color} onChange={handleColor} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

interface Props {
  intl: IntlShape
  index: number
  line: IChartLine
  equipmentOptions: Array<{ label: string; value: string; color: string }>
  parameterOptions: SelectOption[]
  mirrorY: boolean
  onChange: (index: number, line: IChartLine, key: string) => void
  onRemove: (index: number, key: string) => void
}

export default React.memo(LinesTableRow)
