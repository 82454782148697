import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { comparator } from './docs.utils'
import { IDocument } from 'au-nsi/documents'

export interface DocumentsState {
  items: IDocument[]
  loadedFolders: string[] // библиотека загружается не вся, а только нужные папки
  loadedDocuments: string[] // индивидуально загруженные документы (без загрузки всей родительской папки)
  cuttedItems: string[] // id вырезанных элементов (для перемещения через команды вырезать/вставить)
  path: string[] // путь до текущей папки, первый элемент всегда null - корневая папка
}

const initialState: DocumentsState = {
  items: [],
  loadedFolders: [],
  loadedDocuments: [],
  cuttedItems: [],
  path: [null],
}

const slice = createSlice({
  name: 'app/documents',
  initialState,
  reducers: {
    cleanDocuments: (state) => {
      state.items = []
      state.loadedDocuments = []
      state.loadedFolders = []
      state.cuttedItems = []
      state.path = [null]
    },
    // загружена папка со всем содержимым
    setDocuments: (state, action: PayloadAction<{ folder: string; documents: IDocument[] }>) => {
      const { folder } = action.payload
      state.items = state.items.filter((item) => item.parent_id !== folder)
      state.items.push(...action.payload.documents)
      state.loadedFolders.push(folder)
    },
    // загружены отдельные документы
    addDocuments: (state, action: PayloadAction<IDocument[]>) => {
      for (const doc of action.payload) {
        const isExist = doc.id ? state.items.find((d) => d.id === doc.id) : false

        if (!isExist) {
          state.items.push(doc)
          state.loadedDocuments.push(doc.id)
        }
      }
    },
    setPath: (state, action: PayloadAction<string[]>) => {
      state.path = action.payload
    },
    setCuttedItems: (state, action: PayloadAction<string[]>) => {
      state.cuttedItems = action.payload
    },
    documentCreated: (state, action: PayloadAction<IDocument>) => {
      const doc = action.payload
      let shouldHandle = !state.items.find((item) => item.id === doc.id)
      if (state.items?.length > 0) {
        shouldHandle =
          shouldHandle &&
          state.items[0].resource === action.payload.resource &&
          state.items[0].resource_item_id === action.payload.resource_item_id
      }

      if (shouldHandle) {
        state.items.push(doc)
        state.items.sort(comparator)
      }
    },
    documentUpdated: (state, action: PayloadAction<Partial<IDocument>>) => {
      const updates = action.payload
      const doc = state.items.find((item) => item.id === updates.id)

      if (doc) Object.assign(doc, updates)
      if (doc && updates.name) state.items.sort(comparator)
    },
    documentsUpdated: (state, action: PayloadAction<Array<Partial<IDocument>>>) => {
      let shouldSort = false

      for (const updates of action.payload) {
        const doc = state.items.find((item) => item.id === updates.id)

        if (doc) Object.assign(doc, updates)
        if (doc && updates.name) shouldSort = true
      }

      if (shouldSort) state.items.sort(comparator)
    },
    documentDeleted: (state, action: PayloadAction<string>) => {
      const i = state.items.findIndex((item) => item.id === action.payload)
      if (i !== -1) state.items.splice(i, 1)
      if (state.path.includes(action.payload)) state.path = [null]
    },
    invalidateCache: (state) => {
      state.loadedFolders = []
    },
  },
})

export const actions = slice.actions

const documentsReducer = slice.reducer
export default documentsReducer
