import { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import groupsActions from '../groups.actions'
import { useAppSelector } from '../../../../redux/store'
import useGroupsEnabledStatus from '../hooks/useGroupsEnabledStatus'
import { LoaderDots } from '@alterouniversal/au-react-components'

interface Props {
  showLoader?: boolean
}

const GroupsLoader = (props: PropsWithChildren<Props>) => {
  const dispatch = useDispatch()
  const groupsLoaded = useAppSelector((state) => state.groups.loaded)
  const groupsEnabled = useGroupsEnabledStatus()

  useEffect(() => {
    if (!groupsEnabled) return
    if (!groupsLoaded && groupsEnabled) {
      dispatch(groupsActions.loadGroups())
    }
  }, [groupsEnabled])

  if (!groupsEnabled) return null
  if (!groupsLoaded && props.showLoader !== false) return <LoaderDots />

  return <>{props.children}</>
}

export default GroupsLoader
