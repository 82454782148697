import PlaceAutocomplete from './PlaceAutocomplete'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as MapIcon } from '../../../icons/map.svg'
import styles from './placepicker.module.css'
import { ReactComponent as CheckIcon } from '../../../icons/check.svg'
import RemoveButton from '../../Forms/RemoveButton'
import { useGoogleMapsLoader } from '../../GoogleMap/useGoogleMapsLoader'
import usePopup from '../../../hooks/usePopup'
import PlaceSelectModal from './PlaceSelectModal'
import { IPlace } from 'au-nsi/shared'

interface IProps {
  selectedPlace: IPlace
  onSave?: (place: IPlace, name?: string) => void
  allowEditing?: boolean
  controlByParent?: boolean
  isEditing?: boolean
  onChange?: (place: IPlace, name?: string) => void
  name?: string
}

export const PLacePicker = ({
  selectedPlace,
  onSave,
  allowEditing = true,
  isEditing = false,
  controlByParent,
  onChange,
  name,
}: IProps) => {
  const mapsLoaded = useGoogleMapsLoader()

  const [place, setPlace] = useState<IPlace>(selectedPlace)
  const [mapOpen, setMapOpen] = useState(false)

  const componentRef = useRef<HTMLDivElement>(null)
  const { open: editing, setOpen: setEditing } = usePopup(componentRef, {
    initialState: isEditing,
    active: !controlByParent,
  })

  useEffect(() => {
    if (controlByParent || !editing) setPlace(selectedPlace)
  }, [selectedPlace, editing])

  useEffect(() => {
    setEditing(isEditing)
  }, [isEditing])

  const handleChange = (updated: IPlace) => {
    if (controlByParent) onChange(updated, name)
    else setPlace(updated)
  }

  const handleSave = () => {
    onSave(place, name)
    setEditing(false)
  }

  const renderEditing = () => {
    let wrapperClass = styles.container
    if (mapOpen) wrapperClass += ' ' + styles.mapOpen

    const mapIcon = !mapOpen && mapsLoaded && (
      <MapIcon className={styles.mapIcon} onClick={() => setMapOpen(!mapOpen)} />
    )
    const cancelIcon = !mapOpen && <RemoveButton className={styles.mapIcon} onRemove={() => setEditing(false)} />

    // показываем только если был выбран новый адрес
    const saveIcon = place?.name !== selectedPlace?.name && !mapOpen && (
      <CheckIcon className={styles.mapIcon} onClick={handleSave} />
    )

    return (
      <div className={wrapperClass} ref={componentRef}>
        <PlaceAutocomplete value={place?.name || ''} onChange={handleChange} wrapperClassName={styles.searchBlock} />

        <div className={styles.controls}>
          {mapIcon}
          {!controlByParent && saveIcon}
          {!controlByParent && cancelIcon}
        </div>
      </div>
    )
  }

  const renderStatic = () => {
    return (
      <div className="editable-text__wrapper">
        <div
          className="editable-text"
          ref={componentRef}
          onClick={() => !controlByParent && allowEditing && setEditing(true)}
        >
          <div>{place?.name || '-'}</div>
        </div>
      </div>
    )
  }

  if (editing && mapOpen) {
    return <PlaceSelectModal place={place} onChange={handleChange} onClose={() => setMapOpen(false)} />
  }

  return editing ? renderEditing() : renderStatic()
}
