import { Equipment } from 'au-nsi/equipment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessRights } from '../../../App/app.selectors'
import DocumentsGrid from '../../../Libraries/Documents/components/DocumentsGrid'
import DocumentsSearch from '../../../Libraries/Documents/components/DocumentsSearch'
import { actions } from '../../../Libraries/Documents/docs.reducers'
import css from '../../../Libraries/Documents/docs.module.css'

interface IProps {
  device: Equipment
}

const DeviceFiles = ({ device }: IProps) => {
  const dispatch = useDispatch()
  const [isSearch, setSearch] = useState(false)
  const [selection, setSelection] = useState<string[]>([])

  const allowEditing = useSelector(selectAccessRights)['equipment:update']

  useEffect(() => {
    return () => {
      dispatch(actions.cleanDocuments())
    }
  }, [])

  return (
    <div className={css.page} onClick={() => setSelection([])}>
      {isSearch && (
        <DocumentsSearch
          selection={selection}
          onSelect={setSelection}
          onCancel={() => setSearch(false)}
          resource={'equipment'}
          resource_item_id={device.id}
        />
      )}
      {!isSearch && (
        <DocumentsGrid
          allowEditing={allowEditing}
          selection={selection}
          onSelect={setSelection}
          onSearch={() => setSearch(true)}
          resource={'equipment'}
          resource_item_id={device.id}
        />
      )}
    </div>
  )
}

export default DeviceFiles
