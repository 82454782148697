import { useSetRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import { useCallback } from 'react'
import EntityRemoved from '../../components/EntityRemoved'
import SchemaForm from '../../../../../Catalogs/components/SchemaForm/SchemaForm'
import useCatalogSchemaCallbacks from '../../../../../Catalogs/components/SchemaForm/useCatalogSchemaCallbacks'
import { CatalogRequest } from 'au-nsi/moderation'
import { ICatalog } from 'au-nsi/catalogs'

interface IProps {
  editing: boolean
  catalog: ICatalog
  onlyDiffDisplaying: boolean
}

type Setter<T> = ((prevState: T) => T) | T

const RequestSchemaForm = ({ editing, catalog, onlyDiffDisplaying }: IProps) => {
  const setRequest = useSetRecoilState<CatalogRequest>(selectedDraftRequestState as any)

  const setter = useCallback((p: Setter<ICatalog>) => {
    if (typeof p === 'function') {
      setRequest((prev) => ({ ...prev, request_body: p(prev.request_body) }))
    } else {
      setRequest((prev) => ({ ...prev, request_body: p }))
    }
  }, [])

  const treeCallbacks = useCatalogSchemaCallbacks({ setter, treeKey: 'schema_tree', deps: [] })

  if (!catalog) return <EntityRemoved />

  return (
    <SchemaForm
      editing={editing}
      schemaObject={catalog}
      treeKey={'schema_tree'}
      nameKey={'name'}
      onInsertIntoGroup={treeCallbacks.handleInsertIntoGroup}
      onUpdate={treeCallbacks.handleUpdate}
      onMove={onlyDiffDisplaying ? () => {} : treeCallbacks.handleMove}
      onDelete={treeCallbacks.handleRemove}
      onCreate={treeCallbacks.handleCreate}
      onNameChange={(name) => setRequest((prev) => ({ ...prev, request_body: { ...prev.request_body, name } }))}
    />
  )
}

export default RequestSchemaForm
