import { SelectOption } from '@alterouniversal/au-react-components'
import { C37Equipment, C37ParametersMapping, Equipment } from 'au-nsi/equipment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import Tabs from '../../../shared/Pagination/Tabs'
import { selectParameterOptions, selectParametersNames } from '../../Parameters/params.selectors'
import ImportModal from '../Modals/ImportModal'
import { updateParametersMapping } from '../nsi.actions'
import { getConfigurationParameters } from '../nsi.utils'
import Analogs from './C37/Analogs'
import Digitals from './C37/Digitals'
import { countUsedParameters, DeviceParamsMapping, ParamMapping } from './C37/parameters.utils'
import Phasors from './C37/Phasors'
import MappingImport from './MappingImport/MappingImport'

/**
 * Панель редактирования маппинга параметров для протокола C.37.118
 */
class C37ParametersPanel extends React.Component<Props, State> {
  state: State = {
    editing: false,
    importing: false,
    deviceParamsMapping: this.mapDeviceParams(),
  }

  componentDidUpdate(prevProps: Props) {
    const paramsChanged = prevProps.parameters !== this.props.parameters
    const paramsMappingChanged = prevProps.device.parameters_mapping !== this.props.device.parameters_mapping

    if ((paramsChanged || paramsMappingChanged) && !this.state.editing) {
      const deviceParamsMapping = this.mapDeviceParams()
      this.setState({ deviceParamsMapping })
    }

    if (prevProps.device.id !== this.props.device.id && this.state.editing) {
      this.setState({ editing: false })
    }
  }

  // проверить что конфигурация была считана с устройства
  private checkConfiguration(): boolean {
    const config = this.props.device.protocol_configuration
    return (
      config != null &&
      config.analogs != null &&
      config.digitals != null &&
      config.phasors != null &&
      config.DATA_RATE != null
    )
  }

  // преобразовать сохраненный в НСИ маппинг в более удобный формат
  private mapDeviceParams() {
    const deviceParameters = new Set(getConfigurationParameters(this.props.device))
    const mapping = this.props.device.parameters_mapping || []
    const result = {}

    for (const item of mapping) {
      // добавляем только параметры которые действительно передаются устройством, т.к. при изменении
      // конфигурации в parameters_mapping могут остаться ссылки на параметры которые больше не передаются
      if (deviceParameters.has(item.name)) {
        const id = item.parameter_id

        result[item.name] = {
          label: this.props.paramsDict.get(id),
          value: id,
          factor: item.factor,
          offset: item.offset,
        }
      }
    }

    return result
  }

  // преобразовать локальный маппинг в формат для сохранения в НСИ
  private remapDeviceParams() {
    const result = []
    const mapping = this.state.deviceParamsMapping

    for (const key of Object.keys(mapping)) {
      result.push({
        parameter_id: mapping[key].value,
        name: key,
        factor: mapping[key].factor,
        offset: mapping[key].offset,
      })
    }

    return result
  }

  // при редактировании формы сохранять изменения в стэйте компонента
  // и синхронизировать с сервером и редакс стором только после нажатия кнопки 'Применить'
  private handleChange = (deviceParameter: string, updates: Partial<ParamMapping>) => {
    const { deviceParamsMapping } = this.state
    let mapping = deviceParamsMapping[deviceParameter] || { label: '', value: '', factor: 1, offset: 0 }

    mapping = { ...mapping, ...updates }

    if (updates.value) {
      mapping.label = this.props.paramsDict.get(updates.value)
    }

    this.setState({ deviceParamsMapping: { ...deviceParamsMapping, [deviceParameter]: mapping } })
  }

  // удалить маппинг параметра с названием deviceParameter
  private handleRemove = (deviceParameter: string) => {
    const deviceParamsMapping = { ...this.state.deviceParamsMapping }
    delete deviceParamsMapping[deviceParameter]

    this.setState({ deviceParamsMapping })
  }

  private startEditing = () => {
    this.setState({ editing: true })
  }

  private startImporting = () => {
    this.setState({ importing: true, editing: false, deviceParamsMapping: this.mapDeviceParams() })
  }

  private cancelImporting = () => {
    this.setState({ importing: false })
  }

  private handleImport = (id: string) => {
    const importSource = this.props.equipment.find((d) => d.id === id)

    if (importSource.protocol === 'C.37.118') {
      const action = updateParametersMapping(this.props.device.id, importSource.parameters_mapping)

      this.props.dispatch(action)
      this.setState({ importing: false })
    }
  }

  private handleFileImport = (mapping: C37ParametersMapping[]) => {
    const deviceParamsMapping = {}

    for (const item of mapping) {
      deviceParamsMapping[item.name] = {
        label: this.props.paramsDict.get(item.parameter_id),
        value: item.parameter_id,
        factor: item.factor,
        offset: item.offset,
      }
    }

    this.setState({ deviceParamsMapping, editing: true })
  }

  // отбросить локальные изменения и вернуться к состоянию стора
  private cancelChanges = () => {
    this.setState({ editing: false, deviceParamsMapping: this.mapDeviceParams() })
  }

  // сохранить локальные изменения
  private applyChanges = () => {
    const parameters_mapping = this.remapDeviceParams()
    const action = updateParametersMapping(this.props.device.id, parameters_mapping)

    this.props.dispatch(action)
    this.setState({ editing: false })
  }

  private generateTabs() {
    const { editing, deviceParamsMapping } = this.state
    const options = this.props.parameters
    const parametersUsage = countUsedParameters(deviceParamsMapping)

    const config = this.props.device.protocol_configuration
    const phasors = config.phasors || []
    const analogs = config.analogs || []
    const digitals = config.digitals || []

    const renderPhasors = () => (
      <Phasors
        options={options}
        deviceParamsMapping={deviceParamsMapping}
        parametersUsage={parametersUsage}
        phasors={phasors}
        editing={editing}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
      />
    )

    const renderAnalogs = () => (
      <Analogs
        options={options}
        deviceParamsMapping={deviceParamsMapping}
        parametersUsage={parametersUsage}
        analogs={analogs}
        editing={editing}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
      />
    )

    const renderDigitals = () => (
      <Digitals
        options={options}
        deviceParamsMapping={deviceParamsMapping}
        parametersUsage={parametersUsage}
        digitals={digitals}
        editing={editing}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
      />
    )

    return [
      { id: 'nsi.parameters.phasors', render: renderPhasors },
      { id: 'nsi.parameters.analogs', render: renderAnalogs },
      { id: 'nsi.parameters.digitals', render: renderDigitals },
    ]
  }

  render() {
    const configHasBeenRead = this.checkConfiguration()
    if (!configHasBeenRead) {
      return (
        <div className="nsi-placeholder">
          <FormattedMessage id="nsi.parameters.config_not_read" />
        </div>
      )
    }

    const { device } = this.props

    return (
      <div>
        <Tabs tabs={this.generateTabs()} />
        <PanelButtons
          editing={this.state.editing}
          allowEditing={this.props.allowEditing}
          allowImport={true}
          onEdit={this.startEditing}
          onCancel={this.cancelChanges}
          onSave={this.applyChanges}
          onImport={this.startImporting}
        >
          <MappingImport
            name={device.name}
            mapping={device.parameters_mapping}
            columns={['name', 'parameter_id', 'factor', 'offset']}
            numericColumns={['factor', 'offset']}
            onImport={this.handleFileImport}
          />
        </PanelButtons>
        <ImportModal
          isOpen={this.state.importing}
          onClose={this.cancelImporting}
          onImport={this.handleImport}
          sources={this.props.equipment}
          targetId={device.id}
        />
      </div>
    )
  }
}

interface Props {
  parameters: SelectOption[]
  paramsDict: Map<string, string>
  equipment: Equipment[]
  device: C37Equipment
  allowEditing: boolean
  dispatch: (action: any) => void
}

interface State {
  editing: boolean
  importing: boolean
  deviceParamsMapping: DeviceParamsMapping
}

const mapStateToProps = (state: ReduxState) => {
  // оборудование которое будет предложено для импорта настроек
  const equipment = state.nsi.equipment.filter((e) => {
    return e.protocol === 'C.37.118' && e.parameters_mapping.length > 0
  })

  const parameters = selectParameterOptions(state)

  return {
    equipment,
    parameters,
    paramsDict: selectParametersNames(state),
  }
}

export default connect(mapStateToProps)(C37ParametersPanel)
