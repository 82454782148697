import css from './windrose.module.css'
import { comparisonColors } from './windrose.utils'

/**
 * Легенда для розы ветров в режиме сравнения: выводит соответствие цвета
 * линии с параметрами указанными в настройках компонента
 */
const LegendComparison = (props: Props) => {
  const items = []

  for (let i = 0; i < props.count; i++) {
    const color = comparisonColors[i]

    items.push(
      <div className={css.legendItem} key={i}>
        <div className={css.legendColor} style={{ background: color }} />
        <div className={css.legendText}>{i + 1}</div>
      </div>
    )
  }

  return <div className={css.legend}>{items}</div>
}

interface Props {
  count: number
}

export default LegendComparison
