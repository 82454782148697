import { useIntl } from 'react-intl'
import { useSettingsAPI } from '../../settings.hooks'
import { PmuCheckDeviceTable } from './PmuCheckDeviceTable'
import renderFooter from '../../../system.footer'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import { PmuCheckOptionsSettings } from './PmuCheckOptionsSettings'
import { PmuVerificationCheckSettings } from 'au-nsi/settings'

export const AuPmuVerificationCheck = () => {
  const intl = useIntl()
  const settings = useSettingsAPI<PmuVerificationCheckSettings>('au-pmu-verification-check')
  const equipment = useSelector((state: ReduxState) =>
    state.nsi.equipment
      .filter((e) => e.protocol === 'C.37.118')
      .map((e) => {
        return { name: e.name, id: e.id }
      })
  )

  const handleChange = (value: any, name: string) => settings.updateDraft({ [name]: value })

  return (
    <div className={'nsi-main__container'}>
      <h2 className={'system__title'}>{intl.formatMessage({ id: 'nav.system.au-pmu-verification-check.full' })}</h2>
      <PmuCheckDeviceTable
        name={'pmu_frequency_devices'}
        header={'system.verification.pmu_frequency_devices'}
        deviceOptions={equipment}
        devices={settings.draft?.pmu_frequency_devices || []}
        acceptChange={settings.editing}
        onChange={handleChange}
      />
      <div style={{ margin: '20px 0' }} />
      <PmuCheckDeviceTable
        name={'pmu_voltage_devices'}
        header={'system.verification.pmu_voltage_devices'}
        devices={settings.draft?.pmu_voltage_devices || []}
        deviceOptions={equipment}
        acceptChange={settings.editing}
        onChange={handleChange}
      />
      <div style={{ margin: '20px 0' }} />
      <PmuCheckDeviceTable
        name={'pmu_voltage_angle_devices'}
        header={'system.verification.pmu_voltage_angle_devices'}
        devices={settings.draft?.pmu_voltage_angle_devices || []}
        deviceOptions={equipment}
        acceptChange={settings.editing}
        onChange={handleChange}
      />
      <div className={'system__content-footer'} />
      {settings.draft && settings.draft.options && (
        <PmuCheckOptionsSettings
          acceptChange={settings.editing}
          name={'options'}
          options={settings.draft.options}
          onChange={handleChange}
        />
      )}
      {renderFooter(settings)}
    </div>
  )
}
