import { DataMap, IDataArray, IDataSelector } from '../data/data.types'
import { ITimesync, WSSubscription } from './ws.types'

/**
 * parse time synchronization message in binary format
 */
export const parseTimesync = (buffer: ArrayBuffer): ITimesync => {
  const view = new DataView(buffer)
  const serverTime = view.getFloat64(2)
  const processingDelay = view.getFloat64(10)

  return { timeDelta: Date.now() - serverTime, processingDelay }
}

/**
 * Парсинг приходящих по веб-сокетам бинарных данных
 */
export const parseData = (bytes: ArrayBuffer, query: IDataSelector[]) => {
  const result: DataMap = new Map()

  const view = new DataView(bytes)
  let offset = 10

  // итерируем по всем устройствам и извлекаем из сообщения их данные
  for (const { device_id, parameters } of query) {
    const data: IDataArray = { ts: [] }
    for (const p of parameters) {
      data[p] = []
    }

    result.set(device_id, data)

    const len = view.getUint32(offset)
    offset += 4

    // по этому устройству нет данных, переходим к следующему
    if (len === 0) continue

    // проходим по части сообщения содержащей значения параметров
    for (let i = 0; i < len; i++) {
      data.ts.push(view.getFloat64(offset))
      offset += 8

      for (const param of parameters) {
        const value = view.getFloat64(offset)
        const isValid = !Number.isNaN(value) && Number.isFinite(value)
        data[param].push(isValid ? value : null)
        offset += 8
      }
    }
  }

  return result
}

/**
 * Объединить подписки на данные от разных компонентов в одну общую подписку содержащую
 * все запрашиваемые устройства и параметры
 */
export const mergeSelectors = (subscriptions: WSSubscription[]): IDataSelector[] => {
  const query = new Map<string, Set<string>>()

  for (const s of subscriptions) {
    for (const selector of s.query) {
      if (!query.has(selector.device_id)) {
        query.set(selector.device_id, new Set())
      }

      const parameters = query.get(selector.device_id)
      for (const p of selector.parameters) {
        parameters.add(p)
      }
    }
  }

  const result: IDataSelector[] = []

  for (const [id, parameters] of query.entries()) {
    result.push({ device_id: id, parameters: Array.from(parameters) })
  }

  return result
}
