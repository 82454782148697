import { ISVGAnimationsSettings } from 'au-nsi/dashboards'
import css from '../svg.module.css'
import { setSvgTransform, svgUnwrap, svgWrap } from './animations.svg.methods'

export interface AnimationsState {
  [key: string]: number | boolean // setInterval type is number
}

export const directionOptions = {
  movements: [
    { label: 'left', value: 'left' },
    { label: 'top', value: 'top' },
    { label: 'bottom', value: 'bottom' },
    { label: 'right', value: 'right' },
  ],
  rotations: [
    { label: 'clockwise', value: 'clockwise' },
    { label: 'anticlockwise', value: 'anticlockwise' },
  ],
}

const animateMark = 'animate'
const animationFrameRate = 30
const rotateOrigins = 'transform-box: fill-box; transform-origin: center;'

const prepareSVG = (settings: ISVGAnimationsSettings[], svg: SVGSVGElement) => {
  /*
    Оборачиваем анимируемые элементы в дополнительные группы
   */
  if (!svg) return false

  settings.forEach((setting) => {
    if (!setting.node_id) return

    const existBlock = svg.querySelector('#' + setting.node_id + animateMark)
    if (!existBlock) {
      let element: HTMLElement = svg.querySelector('#' + setting.node_id)

      if (element) {
        // Если у родителя есть transform rotate, то ось переврнута также и у интересующего нас элемента.
        // В таком случае, стоит применять изменения к самому родителю
        const parent = element.parentElement

        if (parent && setting.type === 'movements') {
          const parentTransform = parent.getAttribute('transform')
          if (parentTransform && parentTransform.indexOf('rotate') !== -1) {
            element = parent
          }
        }

        svgWrap(element, setting, animateMark)
      }
    } else {
      // Проверяем, корректно ли выставлен дополнительный элемент: если нет - выставляем правильно.
      // Выполняется в случае смены настроек.
      if ((setting.hide_self && existBlock.tagName !== 'svg') || (!setting.hide_self && existBlock.tagName === 'svg')) {
        svgUnwrap(existBlock)
        svgWrap(svg.querySelector('#' + setting.node_id), setting, animateMark)
      }
    }
  })
}

const animateElement = (setting: ISVGAnimationsSettings, svg: SVGSVGElement, revert: boolean = false): boolean => {
  /*
    Apply needed styles to animation block
   */
  if (!svg) return false

  const { duration, distance, node_id, type, direction, hide_self } = setting
  if (!duration || !node_id || !direction) return

  const animationBlock: HTMLElement | SVGSVGElement = svg.querySelector('#' + node_id + animateMark)
  if (!animationBlock) return false

  const animateHiding = () => {
    /*
      Apply hide self animations
    */
    const xAxisAnimate = ['left', 'right'].indexOf(direction) !== -1
    const cof = revert ? -1 : 1

    const animateWidth = (curWidth) => {
      if ((!revert && Math.abs(curWidth - animationStart) < 0.001) || (revert && curWidth > animationEnd)) return

      setting.direction === 'right'
        ? setSvgTransform(animationBlock.firstElementChild, `translate(${animationEnd - curWidth} 0)`)
        : animationBlock.setAttribute('width', curWidth.toString())

      setTimeout(() => animateWidth(curWidth - frameDecr * cof), (1 / animationFrameRate) * 1000)
    }

    const animateHeight = (curHeight) => {
      if ((!revert && Math.abs(curHeight - animationStart) < 0.001) || (revert && curHeight > animationEnd)) return

      setting.direction === 'bottom'
        ? setSvgTransform(animationBlock.firstElementChild, `translate(0 ${animationEnd - curHeight})`)
        : animationBlock.setAttribute('height', curHeight.toString())

      setTimeout(() => animateHeight(curHeight - frameDecr * cof), (1 / animationFrameRate) * 1000)
    }

    const bbox = (animationBlock as SVGGraphicsElement).getBBox()

    const animationStart = xAxisAnimate
      ? bbox.x + ((100 - distance) / 100) * bbox.width
      : bbox.y + ((100 - distance) / 100) * bbox.height

    const animationEnd = xAxisAnimate ? bbox.x + bbox.width : bbox.y + bbox.height

    const frameDecr = (animationEnd - animationStart) / (duration * animationFrameRate)

    !revert && animationBlock.setAttribute('width', (bbox.x + bbox.width).toString())
    !revert && animationBlock.setAttribute('height', (bbox.y + bbox.height).toString())

    if (!xAxisAnimate) {
      revert ? animateHeight(animationStart) : animateHeight(animationEnd)
    } else {
      revert ? animateWidth(animationStart) : animateWidth(animationEnd)
    }
  }

  const animateMoves = () => {
    /*
      Apply styles about linear moves
   */
    animationBlock.setAttribute('transform', 'rotate(0 0 0)')
    let dx = ['left', 'right'].indexOf(direction) !== -1 ? 1 : 0
    let dy = ['top', 'bottom'].indexOf(direction) !== -1 ? 1 : 0

    if (direction === 'right') dx *= -1
    if (direction === 'bottom') dy *= -1

    const bbox = animationBlock.getBoundingClientRect()
    dx *= (distance / 100) * bbox.width
    dy *= (distance / 100) * bbox.height

    const styles = `
      transition: all ease ${duration}s;
      transform: translate(${-dx}px, ${-dy}px);
    `

    animationBlock.setAttribute('style', styles)
  }

  const animateRotations = () => {
    /*
      Apply styles about rotations around it's center
   */

    const cof = direction === 'clockwise' ? 1 : -1

    const styles = rotateOrigins + `transition: all ease ${duration}s; transform: rotate(${distance * cof}deg);`

    if (!animationBlock.style.transition) {
      /*
        Handling first time animation to fix chrome issue
       */

      animationBlock.setAttribute(
        'style',
        rotateOrigins + `transition: all ease 0.02s; transform: rotate(${distance * cof}deg);`
      )

      setTimeout(() => {
        cancelAnimations([setting], svg)
        animationBlock.setAttribute('style', styles)
      }, 20)
    } else {
      animationBlock.setAttribute('style', styles)
    }
  }

  type === 'movements' ? (hide_self ? animateHiding() : animateMoves()) : animateRotations()
  return true
}

const cancelAnimations = (settings: ISVGAnimationsSettings[], svg: SVGSVGElement, force: boolean = false) => {
  if (!svg) return false

  for (const setting of settings) {
    if (!setting) continue

    const node_id = setting.node_id
    const animationBlock: HTMLElement | SVGSVGElement = svg.querySelector('#' + node_id + animateMark)
    if (!animationBlock) continue

    if (animationBlock.tagName === 'svg') {
      force
        ? (() => {
            animationBlock.removeAttribute('width')
            animationBlock.removeAttribute('height')
            setSvgTransform(animationBlock.firstElementChild, '')
          })()
        : (() => {
            animateElement(setting, svg, true)
          })()

      return
    }

    animationBlock.setAttribute('class', '')
    const styles = animationBlock.getAttribute('style')

    if (styles && styles.indexOf('rotate')) {
      animationBlock.setAttribute('style', rotateOrigins + `transition: ${animationBlock.style.transition};`)
      return
    }
    animationBlock.setAttribute('style', `transition: ${animationBlock.style.transition};`)
  }
}

const animate = (setting: ISVGAnimationsSettings, svg: SVGSVGElement, once: boolean = false) => {
  if (!svg) return false

  const startCycleAnimation = () => {
    // Animate
    animateElement(setting, svg)
    // After animation end start reverse animation
    setTimeout(() => cancelAnimations([setting], svg), setting.duration * 1000)

    return window.setInterval(() => {
      animateElement(setting, svg)
      setTimeout(() => cancelAnimations([setting], svg), setting.duration * 1000)
    }, 2 * setting.duration * 1000) // Obviously, reverse_time + normal_time = 2 * duration
  }

  const startOneWayRotation = () => {
    const animationBlock = svg.querySelector('#' + setting.node_id + animateMark)
    animationBlock.setAttribute(
      'class',
      setting.direction === 'clockwise' ? css.clockwiseAnimation : css.anticlockwiseAnimation
    )
    animationBlock.setAttribute('style', rotateOrigins + `animation-duration: ${setting.duration}s;`)
    return true
  }

  if ((!setting.rotate_one_way && !setting.cycle) || once) return animateElement(setting, svg)

  return setting.rotate_one_way ? startOneWayRotation() : startCycleAnimation()
}

export const svgAnimation = {
  prepareSVG,
  cancelAnimations,
  animate,
}
