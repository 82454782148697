import { API_URL } from '../shared/constants'

interface LoadOptions {
  moduleID: string
  scriptSrc: string
  stylesSrc: string
  onload: () => void
}

/**
 * динамическая загрузка js и css модуля микрофронтенда
 */
export const loadMicrofrontend = ({ moduleID, scriptSrc, stylesSrc, onload }: LoadOptions) => {
  if (document.getElementById(moduleID)) return onload()

  const styles = document.createElement('link')
  styles.rel = 'stylesheet'
  styles.type = 'text/css'
  styles.href = API_URL + stylesSrc

  const script = document.createElement('script')
  script.id = moduleID
  script.type = 'text/javascript'
  script.src = API_URL + scriptSrc
  script.onload = onload

  const head = document.getElementsByTagName('head')[0]
  head.appendChild(styles)
  head.appendChild(script)
}
