import { CSSProperties, forwardRef, ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'

interface IProps {
  open: boolean
  icon: ReactNode
  panelContent: ReactNode
  transformOrigin: CSSProperties['transformOrigin']
  animationTime?: number
  style?: CSSProperties
}

/**
 * Компнент-обрётка для плавного открытия/закрытия меню, например, уведомлений
 */
const SmoothPanel = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { panelContent, icon, open, transformOrigin, style = {}, animationTime = 300 } = props

  const handleEnter = (block) => {
    if (!panelContent) return

    block.style.transformOrigin = transformOrigin
    block.style.transition = `transform ease ${animationTime}ms, opacity ease ${animationTime}ms`
    block.style.opacity = 0.1
    block.style.transform = 'scale(0.5)'
  }

  const handleEntering = (block) => {
    if (!panelContent) return

    block.style.opacity = 1
    block.style.transform = 'scale(1)'
  }

  const handleExit = (block) => {
    if (!panelContent) return

    block.style.opacity = 0
    block.style.transform = 'scale(0.5)'
  }

  // CSSTransition children must be react node, not null | false
  return (
    <div style={{ position: 'relative', ...style }} ref={ref}>
      {icon}
      <CSSTransition
        in={open}
        unmountOnExit={true}
        timeout={300}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExiting={handleExit as any}
      >
        {panelContent || <></>}
      </CSSTransition>
    </div>
  )
})

export default SmoothPanel
