import { Image } from 'au-nsi/images'
import React, { useEffect, useRef } from 'react'
import useContextMenu from '../../../hooks/useContextMenu'
import useDnD from '../../../hooks/useDnD'
import useFlip from '../../../hooks/useFlip'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'
import SystemImage from '../../../shared/Utils/Image'
import styles from './image.module.css'
import { generateImageLink } from './image.utils'

interface IProps {
  name: string | JSX.Element
  image: Image
  imagesLength: number
  selected: boolean
  onMove: (sourceId: string, targetId: string) => void
  onSelect: (id: Image['id'], value: boolean) => void
  onEdit?: () => void
  onRemove?: (id: Image['id']) => void
  allowEditing?: boolean
  selectedCount?: number
  onContextMenuOpen?: () => void
}

/**
 * Компонент отображающий картинку и ее название
 */
const ImageTile = ({
  name,
  image,
  imagesLength,
  selected,
  onMove,
  onSelect,
  onRemove,
  onEdit,
  selectedCount,
  allowEditing,
  onContextMenuOpen,
}: IProps) => {
  const containerRef = useFlip([image.user_ordering_index, imagesLength]).element
  const menuRef = useRef(null)

  const menu = useContextMenu(containerRef, menuRef)

  useEffect(() => {
    menu.open && onContextMenuOpen()
  }, [menu.open])

  // обработчики перетаскивания для изменения положения изображения в библиотеке
  const dndHandlers = useDnD({
    id: image.id,
    onDrop: (id) => onMove(id, image.id),
    prefix: 'image:',
    ref: containerRef,
    style: { border: '1px solid var(--primary-80)' },
  })

  const closeMenu = (e) => {
    e.stopPropagation()
    menu.setOpen(false)
    return true
  }

  // открыть изображение в новой вкладке
  // без setTimeout в Firefox меню не закрывается и вся страница зависает
  const openImage = () => {
    setTimeout(() => window.open(generateImageLink(image), '_blank'))
  }

  const handleCLick = (e) => {
    if (e.ctrlKey) onSelect(image.id, !selected)
    else if (e.type === 'click') openImage()
  }

  const renderContextMenu = () => {
    const isMultiSelect = selectedCount > 1

    return (
      <ContextMenu ref={menuRef} open={menu.open}>
        {!isMultiSelect && <ContextMenuOption type={'view'} onClick={(e) => closeMenu(e) && openImage()} />}
        {!isMultiSelect && allowEditing && (
          <ContextMenuOption type={'edit'} onClick={(e) => closeMenu(e) && onEdit()} />
        )}
        {selectedCount >= 0 && allowEditing && (
          <ContextMenuOption type={'delete'} onClick={(e) => closeMenu(e) && onRemove(image.id)} />
        )}
      </ContextMenu>
    )
  }

  return (
    <span
      className={styles.tile}
      draggable={!selected}
      onClick={handleCLick}
      ref={containerRef as any}
      {...(selected ? {} : dndHandlers)}
    >
      <SystemImage
        className={styles.tileImage}
        style={{ border: selected ? '2px solid var(--primary-80)' : '' }}
        image={image}
        title={image.fullname}
      />

      <div className={`${styles.tileText} line_clamp`} title={image.fullname}>
        {name}
      </div>
      {renderContextMenu()}
    </span>
  )
}

export default React.memo(ImageTile)
