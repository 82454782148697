import { useState } from 'react'
import { createOrganization, validateOrganization } from '../../organizations.utils'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import OrganizationForm from './OrganizationForm'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import { postCreateOrganization } from '../../organizations.api'
import { organizationsActions } from '../../organizations.actions'
import { validateItemValues } from '../../../catalogs.validators'
import { showError } from '../../../../../utils/notifications'
import { useNavigate, useParams } from 'react-router-dom'

const OrganizationFormCreate = () => {
  const params = useParams()
  const navigate = useNavigate()
  const typeId = parseInt(params.id)

  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()
  const organizationType = useSelector((state: ReduxState) =>
    state.organizations.organizations.find((o) => o.id === typeId)
  )

  const [draftOrganization, setDraftOrganization] = useState(createOrganization(organizationType))

  const handleCreate = () => {
    if (
      !validateItemValues(organizationType.organization_schema, draftOrganization.organization_properties) ||
      !validateOrganization(draftOrganization)
    ) {
      return showError('common.validation_error')
    }

    const req = postCreateOrganization(draftOrganization)

    wait(req, (resp) => {
      if (!resp) return

      dispatch(organizationsActions.organizationCreated(resp))
      navigate(`/catalogs/organizations/${typeId}/instance/${resp.id}`)
    })
  }

  return (
    <OrganizationForm
      formMode={'create'}
      onChange={setDraftOrganization}
      organization={draftOrganization}
      organizationType={organizationType}
      onCreateSave={handleCreate}
      loading={loading}
      onCancel={() => navigate(-1)}
    />
  )
}

export default OrganizationFormCreate
