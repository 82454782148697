import { Image } from 'au-nsi/images'
import { HTMLProps, memo, useEffect, useRef } from 'react'
import http from '../../utils/http'

interface IProps extends HTMLProps<HTMLDivElement> {
  image: Image
}

const SystemImage = ({ image, ...props }: IProps) => {
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (image.format !== 'svg') {
      ref.current.style.backgroundImage = `url("${image.src_url}")`
    } else {
      // SVG проходит sanitize перед сохранением в систему, так что инъекций можно не опасаться.
      // вставка в DOM необходма для использования внутри svg системных css констант для их адаптации под разные темы
      http.get(`/nsi/v1/images/${image.id}/raw`).then((r) => {
        ref.current.innerHTML = r.data

        const svg = ref.current.firstElementChild
        const w = svg.getAttribute('width')
        const h = svg.getAttribute('height')
        if (w && h) {
          svg.setAttribute('viewBox', `0 0 ${parseInt(w)} ${parseInt(h)}`)
          svg.setAttribute('height', `100%`)
          svg.setAttribute('width', `100%`)
        }
      })
    }
  }, [])

  return <div ref={ref} {...props} />
}

export default memo(SystemImage)
