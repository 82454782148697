import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as documentsActions } from '../../../../../Libraries/Documents/docs.reducers'
import { fetchDocuments } from '../../../../../Libraries/Documents/documents.api'
import { extractIdsFromUrl } from '../../../moderation.utils'
import DocumentsPanel from '../../../../../Libraries/Documents/components/DocumentsPanel/DocumentsPanel'
import { ReduxState } from '../../../../../../redux/store.types'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import { IDocument } from 'au-nsi/documents'
import { DocumentRequest } from 'au-nsi/moderation'
import { showError } from '../../../../../../utils/notifications'

interface IProps {
  editing: boolean
}

const DocumentRequestForm = ({ editing }: IProps) => {
  const dispatch = useDispatch()
  const documents = useSelector((state: ReduxState) => state.documents.items)
  const [request, setRequest] = useRecoilState<DocumentRequest>(selectedDraftRequestState as any)

  useEffect(() => {
    if (request.method === 'DELETE' && !request.moderated_at) {
      fetchDocuments({ ids: extractIdsFromUrl(request.url), displayErrors: false }).then(
        (r) => r && dispatch(documentsActions.addDocuments(r))
      )
    }

    return () => {
      dispatch(documentsActions.cleanDocuments())
    }
  }, [])

  const handleChange = (docs: Partial<IDocument>[]) => {
    if (docs.length === 0) {
      return showError('user.request.documents.at_least_one_must_be')
    }

    setRequest((prev) => ({ ...prev, request_body: docs as IDocument[] }))
  }

  if (request.method !== 'POST' && request.method !== 'DELETE') return <span>Unsupported request type</span>

  return (
    <DocumentsPanel
      editing={editing}
      resource={request.request_body?.[0].resource}
      resource_item_id={request.request_body?.[0].resource_item_id}
      onChange={handleChange}
      documents={request.request_body ?? documents}
    />
  )
}

export default DocumentRequestForm
