import { useState } from 'react'
import { useIntl } from 'react-intl'
import useHttpLoader from '../../../hooks/useHttpLoader'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextareaInput from '../../../shared/Inputs/TextareaInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import http, { handleHttpResponse } from '../../../utils/http'
import { Equipment } from 'au-nsi/equipment'

/**
 * Модальное окно продления периода доступа к устройству
 */
const ExtendAccessModal = ({ user_id, expires, device, onClose }: Props) => {
  const intl = useIntl()
  const { wait, loading } = useHttpLoader()

  const [days, setDays] = useState(30)
  const [notes, setNotes] = useState('')

  const handleSave = () => {
    const expires_at = expires + days * 24 * 60 * 60 * 1000
    const { id, name, address } = device
    const request = { user_id: user_id, equipment: [{ id, name, address: address.name, expires_at, notes }] }

    wait(http.post('/nsi/v1/equipment/access/grant', request), (r) => {
      handleHttpResponse(r)
      onClose()
    })
  }

  return (
    <Modal onClose={onClose}>
      <h2>{intl.formatMessage({ id: 'nsi.device.extend_access' })}</h2>

      <InputRow label={intl.formatMessage({ id: 'nsi.device.extend_access.days' })}>
        <NumberInput
          type="number"
          name="days"
          value={days}
          onChange={setDays}
          integer={true}
          fullWidth={true}
          min={1}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'system.maps.connect_equipment.notes' })}>
        <TextareaInput value={notes} onChange={setNotes} />
      </InputRow>

      <ModalFooter loading={loading} onCancel={onClose} onSave={handleSave} />
    </Modal>
  )
}

interface Props {
  user_id: number
  expires: number
  device: Equipment
  onClose: () => void
}

export default ExtendAccessModal
