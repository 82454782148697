import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as ObjectIcon } from '../../icons/object.svg'
import { ReactComponent as SensorIcon } from '../../icons/sensor.svg'
import TernaryCheckbox from '../Inputs/Checkbox/TernaryCheckbox'
import SearchInput from '../Inputs/Search/SearchInput'
import * as searchUtils from '../../utils/search'
import { SelectedParameters } from './selector.interfaces'
import './selector.styles.css'
import * as utils from './selector.utils'
import { Equipment, Region } from 'au-nsi/equipment'
import treeBuilder from '../../pages/Nsi/Tree/tree.builder'
import useTree from '../Tree/useTree'
import Tree from '../Tree/Tree'
import { TNode } from '../Tree/tree.types'
import CollapseButton from '../Utils/CollapseButton'

/**
 * Компонент для выбора устройств и параметров из топологии
 */
const DeviceParametersSelector = (props: Props) => {
  // скрывать/показывать список параметров для устройства
  const [openDevices, setOpenDevices] = useState<{ [id: string]: boolean }>({})

  const nodes = useMemo(
    () => treeBuilder.build(props.regions, props.equipment, { composeEquipment: false, withRoot: true }),
    [props.regions, props.equipment]
  )
  const tree = useTree({ persistenceStrategy: 'inmemory', key: 'DeviceParametersSelectorTree' })

  // раскрываем корневой элемент, остальные по умолчанию свернуты
  useEffect(() => {
    tree.handleToggle(1, true)
  }, [])

  const [search, setSearch] = useState('')
  const searchParts = search.split(' ')

  // состояние выбора промежуточных объектов топологии (выбрано, невыбрано, промежуточное)
  const { regionsState, equipmentState } = useMemo(() => {
    const eState = utils.computeEquipmentState(props.deviceParams, props.selectedParams)
    const rState = utils.computeRegionsState(nodes, eState)

    return { equipmentState: eState, regionsState: rState }
  }, [props.deviceParams, props.selectedParams, nodes])

  // составление сетов с id устройств и регионов подходящие под поиск
  const searchResults = useMemo(() => {
    const deviceMatches = new Set<string>()
    const regionMatches = new Set<number>()

    for (const e of props.equipment) {
      if (e.name.toLowerCase().includes(search)) deviceMatches.add(e.id)
    }

    for (const r of props.regions) {
      if (r.name.toLowerCase().includes(search)) regionMatches.add(r.id)
    }

    return { deviceMatches, regionMatches }
  }, [search, props.equipment, props.regions])

  // раскрыть/скрыть отображение параметров для конкретного устройства
  const toggleOpenDevices = useCallback(
    (e) => {
      const { id } = e.target.dataset
      setOpenDevices({ ...openDevices, [id]: !openDevices[id] })
    },
    [openDevices]
  )

  // обработать нажатие на чекбокс параметра
  const toggleParameter = useCallback(
    (equipmentId: string, paramId: string) => {
      const prevState = props.selectedParams[equipmentId] || {}
      const isSelected = !prevState[paramId]
      const equipmentParams = { ...prevState, [paramId]: isSelected }
      const result = { ...props.selectedParams, [equipmentId]: equipmentParams }
      props.setSelectedParams(result)
    },
    [props.selectedParams]
  )

  // обработать нажатие на чекбокс оборудование
  const toggleDevice = useCallback(
    (_, id: string) => {
      const nextState = equipmentState[id] === 'unchecked'
      const selectedParams = props.deviceParams[id] || []
      const res = {}

      for (const param of selectedParams) {
        res[param] = nextState
      }

      props.setSelectedParams({ ...props.selectedParams, [id]: res })
    },
    [equipmentState, props.selectedParams]
  )

  const handleCollapseAllToggle = (isCollapsed: boolean) => {
    if (!isCollapsed) tree.setOpenedNodes(new Set(props.regions.map((r) => r.id)))
    else tree.setOpenedNodes(new Set())
  }

  // отрисовка списка параметров
  const renderParameters = (equipmentId: string) => {
    const deviceParams = props.deviceParams[equipmentId] || []

    const rows = deviceParams.map((p) => {
      const selectedParams = props.selectedParams[equipmentId] || {}
      const state = selectedParams[p] ? 'checked' : 'unchecked'

      return (
        <li role="treeitem" key={p}>
          <span className="tpl-node-inline-flex export__param-name">
            <TernaryCheckbox state={state} name={p} onChange={() => toggleParameter(equipmentId, p)} />
            <span className="tpl-icon parameter-icon" />
            <span>{props.parameters.get(p) || p}</span>
          </span>
        </li>
      )
    })

    return <ul style={{ listStyleType: 'none' }}>{rows}</ul>
  }

  // отрисовка элемента с оборудованием в топологии
  const renderEquipment = (e: TNode<Equipment>) => {
    // при поиске показываем устройство если оно либо родительский регион подходит под поиск
    if (search && !searchResults.deviceMatches.has(e.id) && !searchResults.regionMatches.has(e.region_id)) {
      return null
    }

    const isComposite = !!e.children?.length
    const state = equipmentState[e.id] || 'unchecked'
    const isOpen = openDevices[e.id]

    const iconClass = classnames('tpl-icon', { 'open-icon': isOpen, 'closed-icon': !isOpen })

    return (
      <>
        <span className="tpl-node__padding tpl-node-inline-flex" key={e.id}>
          {!isComposite && <span data-id={e.id} className={iconClass} onClick={toggleOpenDevices} />}
          <TernaryCheckbox state={state} name={e.id} onChange={toggleDevice} />
          <SensorIcon width="20" height="20" style={{ margin: '0 10px', opacity: 0.5 }} />
          <span>{renderName(e.name, searchParts)}</span>
        </span>
        {isOpen && renderParameters(e.id)}
      </>
    )
  }

  // отрисовка элемента дерева с регионом
  const renderRegion = (r: TNode<Region>) => {
    if (search && !shouldShowNode(r, searchResults.deviceMatches, searchResults.regionMatches)) {
      return null
    }

    const state = regionsState[r.id] || 'unchecked'

    // обработать нажатие на чекбокс региона
    const toggleSelection = () => {
      const res = utils.toggleRegion({
        id: r.id,
        node: r,
        regionsState,
        selectedParams: props.selectedParams,
        deviceParams: props.deviceParams,
      })

      props.setSelectedParams(res)
    }

    return (
      <span className="tpl-node__padding tpl-node-inline-flex" key={r.id}>
        <TernaryCheckbox state={state} onChange={toggleSelection} />
        <ObjectIcon style={{ margin: '0 10px', width: 20, height: 20, opacity: 0.6 }} />
        <span data-id={r.id}>{renderName(r.name, searchParts)}</span>
      </span>
    )
  }

  const renderNode = ({ node }) => {
    if ('region_id' in node) return renderEquipment(node)
    return renderRegion(node)
  }

  return (
    <div className="export__topology">
      {props.showSearch && (
        <div className="nsi-tree__header-row">
          <SearchInput onChange={(s) => setSearch(s.toLowerCase())} />
          <CollapseButton
            className="nsi__title-icon"
            style={{ marginLeft: 20, cursor: 'pointer' }}
            isCollapsed={tree.openedNodes.size !== props.regions.length}
            onChange={handleCollapseAllToggle}
          />
        </div>
      )}
      <Tree
        nodes={nodes}
        {...tree}
        nodeContentRenderer={renderNode}
        branchNameClassName="tpl-branch-name tpl-node-no-hover"
        leafClassName="tpl-leaf tpl-node-no-hover"
        handleSelect={null}
      />
    </div>
  )
}

const renderName = (name: string, search: string[]) => {
  if (!search || !search.length) return name

  const results = searchUtils.search(name, search)
  return results.length ? searchUtils.renderSearchResult(results) : name
}

/**
 * Узел дерева отображается при поиске если либо он сам либо хотя бы один из дочерних
 * узлов попадает под поиск
 */
const shouldShowNode = (
  node: TNode<Region> | TNode<Equipment>,
  deviceMatches: Set<string | number>,
  regionMatches: Set<number>
) => {
  if (!node.children?.length) return deviceMatches.has(node.id)

  return (
    regionMatches.has(node.id as number) || node.children.some((n) => shouldShowNode(n, deviceMatches, regionMatches))
  )
}

interface Props {
  equipment: Equipment[]
  regions: Region[]
  parameters: Map<string, string>
  deviceParams: { [id: string]: string[] }
  selectedParams: SelectedParameters
  setSelectedParams: (v: SelectedParameters) => void
  showSearch?: boolean
}

export default DeviceParametersSelector
