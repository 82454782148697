import classnames from 'classnames'
import { useRef } from 'react'
import usePopup from '../../../hooks/usePopup'
import SmoothPanel from '../../../shared/Utils/SmoothPanel/SmoothPanel'
import css from './alignment.module.css'

interface Props {
  name: string
  value: string
  onChange: (value: string, name: string) => void
}

/**
 * Настройки выравнивания текста
 */
const AlignmentSettings = ({ name, value, onChange }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  const handleClick = (e) => {
    const value = e.currentTarget.dataset.id
    onChange(value, name)
    setOpen(false)
  }

  const icon = (
    <div ref={ref} className={css.icon}>
      <Block alignment={value} selected={false} onClick={() => setOpen(!open)} />
    </div>
  )

  const blocks = options.map((alignment) => (
    <Block key={alignment} alignment={alignment} selected={alignment === value} onClick={handleClick} />
  ))

  const menu = (
    <div style={{ right: 'unset', left: -60 }} className={'nsi-dropdown ' + css.blocksGrid}>
      {blocks}
    </div>
  )

  return (
    <SmoothPanel
      ref={ref}
      open={open}
      icon={icon}
      panelContent={menu}
      transformOrigin="top center"
      style={{ width: 'fit-content' }}
    />
  )
}

interface BlockProps {
  alignment: string
  selected: boolean
  onClick: (e) => void
}

const Block = ({ alignment, selected, onClick }: BlockProps) => {
  const [alignItems, justifyContent] = alignment.split(' ')
  const className = classnames(css.block, { [css.blockSelected]: selected })

  return (
    <div className={className} style={{ alignItems, justifyContent }} data-id={alignment} onClick={onClick}>
      <div />
      <div />
      <div />
    </div>
  )
}

const options = [
  ['start start', 'center start', 'end start'],
  ['start center', 'center center', 'end center'],
  ['start end', 'center end', 'end end'],
  ['start space-around', 'center space-around', 'end space-around'],
].flat()

export default AlignmentSettings
