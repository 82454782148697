import produce from 'immer'
import { SystemMessage } from 'au-nsi/shared'
import { atom, useRecoilState } from 'recoil'
import { handleHttpError } from '../../../utils/http'
import * as api from './messages.api'
import { MessageAckEvent } from './messages.types'

// непрочитанные системные сообщения
const unreadMessagesState = atom<{ results: SystemMessage[]; nextPage: string; total: number }>({
  key: 'unreadSystemMessagesState',
  default: { results: [], nextPage: null, total: 0 },
})

// хук для работы с непрочитанными системными сообщениями
export const useUnreadMessages = () => {
  const [state, setState] = useRecoilState(unreadMessagesState)

  const load = (page?: string) => {
    return api
      .loadUnreadMessages(page)
      .then((r) =>
        page
          ? setState((prev) => ({ ...prev, results: [...prev.results, ...r.results], nextPage: r.nextPage }))
          : setState(r)
      )
      .catch(handleHttpError)
  }

  // добавить новые сообщения
  const append = (messages: SystemMessage[]) => {
    setState((prev) => ({ ...prev, results: [...messages, ...prev.results], total: prev.total + messages.length }))
  }

  // обновление сообщений
  const update = (messages: SystemMessage[]) => {
    setState((prev) =>
      produce(prev, (draft) => {
        for (const m of messages) {
          const item = draft.results.find((r) => r.id === m.id)
          if (item) Object.assign(item, m)
        }

        draft.results.sort((a, b) => b.ts - a.ts)
      })
    )
  }

  // отметка о прочтении одного сообщения
  // Т.к. метод может вызываться 2 раза (сразу при клике и после получения веб-сокет сообщения),
  // то используется аргумент updateTotal, чтобы счетчик сообщений не уменьшался дважды
  const handleSingleAck = (e: MessageAckEvent, updateTotal = true) => {
    setState((prev) =>
      produce(prev, (draft) => {
        const index = draft.results.findIndex((r) => r.id === e.message_id)

        if (updateTotal) draft.total -= 1
        if (index !== -1) draft.results.splice(index, 1)
      })
    )
  }

  // отметка о прочтении всех сообщений
  const handleBatchAck = (e: MessageAckEvent) => {
    setState((prev) =>
      produce(prev, (draft) => {
        draft.results = draft.results.filter((r) => r.ts_start > e.ack_ts)
        draft.total = draft.results.length
      })
    )
  }

  const handleAck = (e: MessageAckEvent, updateTotal = true) => {
    return e.batch ? handleBatchAck(e) : handleSingleAck(e, updateTotal)
  }

  const reload = () => load()
  const loadMore = () => load(state.nextPage)
  const isMore = state.nextPage != null

  // при снятии отметки о прочтении это сообщение необходимо добавить в список,
  // и единственный способ его получить - это перезагрузить список
  const handleUnack = reload

  return {
    messages: state.results,
    isMore,
    total: state.total,
    append,
    update,
    handleAck,
    handleUnack,
    reload,
    loadMore,
  }
}
