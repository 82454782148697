import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useHttpLoader from '../../../hooks/useHttpLoader'
import confirmService from '../../../shared/Modal/confirm.service'
import http, { handleHttpError } from '../../../utils/http'
import { formatDate } from '../../../utils/lang'
import { Equipment } from 'au-nsi/equipment'
import ExtendAccessModal from '../components/ExtendAccessModal'

/**
 * Кнопки отключения устройства от аккаунта пользователя и продления периода доступа
 */
const DeviceAccessButtons = ({ user_id, device }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { wait } = useHttpLoader()

  const [expires, setExpires] = useState<number>()
  const [showModal, setModal] = useState(false)

  // показываем кнопку для продления доступа за 15 дней до окончания
  const allowExtending = expires && expires - Date.now() < 15 * DAY

  useEffect(() => {
    const p = http.get('/nsi/v1/equipment/access/expiration/' + device.id)
    wait(p, (r) => setExpires(r.data.expires_at))
  }, [])

  const handleClick = () => {
    const title = intl.formatMessage({ id: 'nsi.device.detach_confirm' })

    confirmService.requestDeleteConfirmation(title).then((result) => {
      if (result !== 'delete') return

      http
        .post('/nsi/v1/equipment/access/revoke', { user_id, equipment: [device.id] })
        .then(() => navigate('/map'))
        .catch(handleHttpError)
    })
  }

  return (
    <>
      <button className="nsi-button danger" onClick={handleClick} style={{ marginLeft: 0 }}>
        {intl.formatMessage({ id: 'nsi.device.detach' })}
      </button>

      {allowExtending && (
        <button className="nsi-button default" onClick={() => setModal(true)} style={{ marginLeft: 0 }}>
          {intl.formatMessage({ id: 'nsi.device.extend_access' })}
        </button>
      )}

      <div className="text--gray" style={{ fontSize: 13 }}>
        {expires &&
          intl.formatMessage({ id: 'nsi.device.access_expiration' }, { date: formatDate(expires, 'dd MMM yyyy') })}
      </div>

      {showModal && (
        <ExtendAccessModal user_id={user_id} expires={expires} device={device} onClose={() => setModal(false)} />
      )}
    </>
  )
}

const DAY = 24 * 60 * 60 * 1000

interface Props {
  user_id: number
  device: Equipment
}

export default DeviceAccessButtons
