import { IDate } from './utils'

interface IProps {
  mmyy: IDate
  onChange: (date) => void
  monthList: string[]
}

export const MonthPicker = ({ mmyy, onChange, monthList }: IProps) => {
  const month = mmyy.month
  const year = mmyy.year

  const decMonth = () => {
    month === 0 ? onChange({ month: 11, year: year - 1 }) : onChange({ month: month - 1, year })
  }

  const incMonth = () => {
    month === 11 ? onChange({ month: 0, year: year + 1 }) : onChange({ month: month + 1, year })
  }

  return (
    <div className="calendar-month_header">
      <span onClick={decMonth} className={'calendar-header_button is-prev'} title={'Previous month'} />
      <div className={'calendar-month_header_title'}>{monthList[month] + ' ' + year}</div>
      <span onClick={incMonth} className={'calendar-header_button is-next'} title={'Next month'} />
    </div>
  )
}
