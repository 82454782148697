import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { IVectorChartVector } from 'au-nsi/dashboards'
import React from 'react'
import RemoveButton from '../Forms/RemoveButton'
import ColorPicker from '../Inputs/Colorpicker/ColorPicker'

const colorPickerProps: any = { position: 'left', showList: true, defaultMode: 'list' }

const VectorGraphSettingsRow = (props: Props) => {
  const { vector, index, magnitudeParameterOptions, angleParameterOptions } = props

  const handleRemove = () => props.onRemove(index)

  // изменение устройства
  const handleDevice = (device_id: string) => {
    const updates = { ...vector, device_id }

    // если цвет линии еще не задан, то выставляем цвет устройства
    if (!vector.color) {
      const device = props.equipmentOptions.find((o) => o.value === device_id)
      updates.color = device.color
    }

    props.onChange(index, updates)
  }

  // изменение параметра магнитуды
  const handleMagnitudeParameter = (magnitude_parameter_id: string) => {
    props.onChange(index, { ...vector, magnitude_parameter_id })
  }

  // изменение параметра угла
  const handleAngleParameter = (angle_parameter_id: string) => {
    props.onChange(index, { ...vector, angle_parameter_id })
  }

  // изменение цвета линии
  const handleColor = (color: string) => {
    props.onChange(index, { ...vector, color })
  }

  const handleNameChange = (e) => {
    const name = e.target.value
    props.onChange(index, { ...vector, name })
  }

  return (
    <tr>
      <td style={{ width: '24ch' }}>
        <Select name="" options={props.equipmentOptions} value={vector.device_id} onChange={handleDevice} />
      </td>
      <td>
        <Select
          name=""
          options={magnitudeParameterOptions}
          value={vector.magnitude_parameter_id}
          onChange={handleMagnitudeParameter}
        />
      </td>
      <td>
        <Select
          name=""
          options={angleParameterOptions}
          value={vector.angle_parameter_id}
          onChange={handleAngleParameter}
        />
      </td>
      <td>
        <input className="nsi-input" value={vector.name} onChange={handleNameChange} />
      </td>
      <td align="center">
        <ColorPicker {...colorPickerProps} color={vector.color} onChange={handleColor} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

interface Props {
  index: number
  vector: IVectorChartVector
  equipmentOptions: Array<{ label: string; value: string; color: string }>
  magnitudeParameterOptions: SelectOption[]
  angleParameterOptions: SelectOption[]
  onChange: (index: number, vector: IVectorChartVector) => void
  onRemove: (index: number) => void
}

export default React.memo(VectorGraphSettingsRow)
