import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { systemPublicState } from '../../System/system.state'
import css from '../user.module.css'
import AccountForm from './Account'
import LanguageForm from './Language'
import UserNotificationsForm from './UserNotifications'
import ColorTheme from './ColorTheme'
import SecuritySettings from './SecuritySettings'

const ProfilePage = () => {
  const intl = useIntl()
  const systemInfo = useRecoilValue(systemPublicState)

  const pdpLink = (
    <a className={css.profilePDP} href="/nsi/auth/pdp_agreement" target="_blank">
      {intl.formatMessage({ id: 'user.profile.pdp_agreement' })}
    </a>
  )

  return (
    <div className="nsi-main__container">
      <ColorTheme />
      <LanguageForm />
      <AccountForm />
      <UserNotificationsForm />
      <SecuritySettings />
      {systemInfo.pdp_agreement_id && pdpLink}
    </div>
  )
}

export default ProfilePage
