import { Select } from '@alterouniversal/au-react-components'
import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import { Image } from 'au-nsi/images'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import AccessForm from '../../../shared/Access/AccessForm'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import Loader from '../../../shared/Utils/Loader'
import http, { handleHttpError } from '../../../utils/http'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import { replaceDevices } from '../dashboard.utils'

/**
 * Модальное окно с формой импорта экранов
 */
const ImportModal = (props: Props) => {
  const intl = useIntl()
  const dataSources = useSelector(selectDataSourceOptions)

  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const data: ImportData = React.useMemo(() => {
    try {
      return parseContent(props.content)
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }, [])

  // настройки как и какие экраны импортировать
  const [state, setState] = React.useState({
    import_all: true,
    replace_existing: false,
    dashboards: [] as string[],
    access: { restricted: false, rule: '', users: [], roles: [], orgs: [] },
  })

  // сопоставление устройств из импортируемого файла и устройств в системе, т.к. при переносе
  // между стендами id устройств будут отличаться
  const [devicesMap, setDevicesMap] = React.useState(() => {
    const result = new Map<string, string>()

    for (const row of data.devices) {
      // пытаемся автоматически сопоставить устройства по id или названию
      const pair = dataSources.find((s) => s.value === row.id || s.label === row.name)
      result.set(row.id, pair?.value)
    }

    return result
  })

  // извлечение только тех устройств которые используются на импортируемых экранах
  // (т.к. можно импортировать только часть экранов из файла)
  const usedDevices = React.useMemo(() => {
    if (state.import_all) {
      return new Set(data.devices.map((d) => d.id))
    }

    const result = new Set<string>()

    for (const id of state.dashboards) {
      const { devices } = data.dashboards.find((d) => d.dashboard.id === id)

      for (const device of devices) {
        result.add(device)
      }
    }

    return result
  }, [state])

  const handleChange = (value: any, key: string) => {
    setState({ ...state, [key]: value })
  }

  // изменение пользователем маппинга импортируемых устройств на существующие
  const handleDevicesChange = (existingId: string, importedId: string) => {
    const map = new Map([...devicesMap])
    map.set(importedId, existingId)
    setDevicesMap(map)
  }

  // обработка нажатия кнопки "Импорт"
  const handleImport = () => {
    setLoading(true)

    const { replace_existing } = state

    // отправляем в нСИ только выбранные экраны
    const dashboards = data.dashboards.filter((d) => state.import_all || state.dashboards.includes(d.dashboard.id))
    if (dashboards.length === 0) return

    for (const { dashboard } of dashboards) {
      dashboard.access = state.access
    }

    // аналогично с изображениями - отправляем только те которые используются на выбранных экранах
    const usedImages = new Set<string>()
    for (const d of dashboards) {
      for (const image of d.images) usedImages.add(image)
    }

    const images = data.images.filter((i) => usedImages.has(i.id))
    dashboards.forEach((d) => d.components.forEach((c) => replaceDevices(c, devicesMap)))

    // отправка файла в НСИ, ответ не важен, т.к. при успешном импорте новые экраны и
    // компоненты придут по вебсокетам
    http
      .post('/nsi/v1/ui/dashboards/import', { replace_existing, images, dashboards })
      .catch(handleHttpError)
      .finally(() => props.onClose())
  }

  // таблица для сопоставления устройств, фильтруем неиспользуемые чтобы при импорте только
  // части экранов пользователю пришлось маппить меньше устройств
  const deviceRows = data.devices
    .filter((d) => usedDevices.has(d.id))
    .map((importedDevice) => {
      const existingDeviceId = devicesMap.get(importedDevice.id)

      return (
        <tr key={importedDevice.id}>
          <td>{importedDevice.name}</td>
          <td>
            <Select
              name={importedDevice.id}
              value={existingDeviceId}
              options={dataSources}
              onChange={handleDevicesChange}
            />
          </td>
        </tr>
      )
    })

  return (
    <Modal size="lg" onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: 'dashboards.import.title' })}</h2>

      <div className="system__input-wrapper">
        <ToggleWithLabel
          name="replace_existing"
          value={state.replace_existing}
          onChange={handleChange}
          label={intl.formatMessage({ id: 'dashboards.import.replace_existing' })}
        />
      </div>

      <div className="system__input-wrapper">
        <ToggleWithLabel
          name="import_all"
          value={state.import_all}
          onChange={handleChange}
          label={intl.formatMessage({ id: 'dashboards.import.import_all' })}
        />
      </div>

      {!state.import_all && (
        <InputRow label={intl.formatMessage({ id: 'dashboards.import.dashboards' })}>
          <MultiSelect
            name="dashboards"
            options={data.dashboards.map((d) => d.dashboard)}
            selection={state.dashboards}
            onChange={handleChange}
          />
        </InputRow>
      )}

      <table className="nsi-settings-table" style={{ marginBottom: 25 }}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.import.device_imported' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.import.device_existing' })}</th>
          </tr>
        </thead>
        <tbody>{deviceRows}</tbody>
      </table>

      <AccessForm access={state.access} onChange={handleChange} />

      {loading && (
        <div className="text--center" style={{ padding: '12px' }}>
          <Loader />
        </div>
      )}

      {!loading && (
        <ModalFooter
          error={error ? intl.formatMessage({ id: 'dashboards.import.error' }) : null}
          onCancel={props.onClose}
          onSave={handleImport}
          saveMessage={intl.formatMessage({ id: 'dashboards.import' })}
        />
      )}
    </Modal>
  )
}

/**
 * Парсинг и валидация загруженного пользователем файла
 * TODO: более строгая проверка на соответствие интерфейсу ImportData
 */
const parseContent = (file: string) => {
  const data = JSON.parse(file)
  assert(Array.isArray(data.images))
  assert(Array.isArray(data.dashboards))
  assert(Array.isArray(data.devices))
  return data
}

const assert = (condition: boolean) => {
  if (!condition) throw new Error('File is invalid')
}

// формат в котором импортируются экраны из НСИ
interface ImportData {
  dashboards: Array<{
    dashboard: IDashboard
    components: IDashboardComponent[]
    images: string[] // изображения использующиеся на данном экране
    devices: string[] // устройства использующиеся на данном экране
  }>
  images: Image[]
  devices: Array<{ id: string; name: string }>
}

interface Props {
  onClose: () => void
  content: string
}

export default ImportModal
