import { ICommand, ICommandType } from 'au-nsi/commands'
import http from 'utils/http'

const prefix = '/nsi/v1/commands/'

interface CommandsData {
  commands: ICommand[]
  command_types: ICommandType[]
}

export const loadCommands = () => {
  return http.get<CommandsData>(prefix).then((r) => r.data)
}

export const createCommand = (command: ICommand) => {
  return http.post<ICommand>(prefix, command).then((r) => r.data)
}

export const updateCommand = (command: ICommand) => {
  return http.patch<ICommand>(prefix + command.id, command).then((r) => r.data)
}

export const deleteCommand = (id: ICommand['id']) => http.delete(prefix + id)

export const replicateCommand = (id: ICommand['id'], names: string[]) => {
  return http.post<ICommand[]>(prefix + id + '/replicate', { names }).then((r) => r.data)
}

export const createCommandType = (type: ICommandType) => {
  return http.post<ICommandType>(prefix + 'types/', type).then((r) => r.data)
}

export const updateCommandType = (type: ICommandType) => {
  return http.patch<ICommandType>(prefix + 'types/' + type.id, type).then((r) => r.data)
}

export const deleteCommandType = (id: ICommandType['id'], force = false) => {
  let url = prefix + 'types/' + id
  if (force) url += '?force=true'

  return http.delete(url)
}

export const loadTargetServices = () => http.get<string[]>(prefix + 'options/target_services').then((r) => r.data)

export const exportCommands = (command_types: number[], only_command_types: boolean) => {
  return http
    .post(prefix + 'export', { command_types, only_command_types }, { responseType: 'arraybuffer' })
    .then((r) => r.data)
}

export const importCommands = (on_conflict_update: boolean, body: any) => {
  const contentType = body instanceof ArrayBuffer ? 'application/octet-stream' : 'application/json'
  const headers = { 'content-type': contentType }
  const url = prefix + 'import?on_conflict_update=' + on_conflict_update

  return http.post<CommandsData>(url, body, { headers }).then((r) => r.data)
}
