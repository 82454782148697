import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { IBarChartSettings } from 'au-nsi/dashboards'
import React from 'react'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Dropdown from '../../../shared/Inputs/Dropdown'
import TextInput from '../../../shared/Inputs/TextInput'

const BarChartParameterRow = (props: Props) => {
  const { index, row } = props
  const color = row.color

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  const handleColorChange = (color: string) => {
    props.onChange('color', color, index)
  }

  const handleRemove = () => props.onRemove(index)

  return (
    <tr>
      <td>
        <TextInput name="name" required={false} value={row.name} onChange={handleChange} />
      </td>

      <td>
        <Select name="device_id" options={props.equipmentOptions} value={row.device_id} onChange={handleChange} />
      </td>
      <td>
        <Select name="parameter_id" options={props.parameterOptions} value={row.parameter_id} onChange={handleChange} />
      </td>
      {props.settings.mirror_axis === true && (
        <td>
          <Dropdown name="mirror" value={row.mirror || false} options={props.booleanOptions} onChange={handleChange} />
        </td>
      )}
      <td>
        <ColorPicker position="left" color={color} onChange={handleColorChange} />
      </td>
      <td align="center">
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

type IBarChartParameter = IBarChartSettings['parameters'][0]

interface Props {
  settings: IBarChartSettings
  key: number
  index: number
  row: IBarChartParameter
  onChange: (key: string, value: any, index: number) => void
  onRemove: (index: number) => void
  booleanOptions: { value: boolean; title: string }[]
  equipmentOptions: Array<{ label: string; value: string; color: string }>
  parameterOptions: SelectOption[]
}

export default React.memo(BarChartParameterRow)
