import { Select } from '@alterouniversal/au-react-components'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/store'
import InputRow from '../../../shared/Inputs/InputRow'
import CommandTargetInput from './CommandTargetInput'
import VariableArgsForm from './VariableArgsForm'

interface Props {
  command_id: number
  target: string
  args: Record<string, any>
  editing: boolean
  required: boolean
  onChange: (value: any, key: string) => void
}

/**
 * Форма для выбора команды и задания ее получателя (сервис или устройство)
 * и не-константных аргументов
 */
const CommandSelectForm = (props: Props) => {
  const intl = useIntl()

  const { command_id, target, args, editing, required, onChange } = props

  const commands = useAppSelector((state) => state.commands.commands)
  const types = useAppSelector((state) => state.commands.command_types)

  const typesMap = useMemo(() => new Map(types.map((t) => [t.id, t])), [types])
  const command = useMemo(() => (command_id ? commands.find((c) => c.id === command_id) : null), [command_id, commands])
  const commandType = command ? typesMap.get(command.type) : null

  const commandOptions = useMemo(() => commands.map((c) => ({ value: c.id, label: c.name })), [commands, types])

  const handleArgChange = (value, arg) => {
    onChange({ ...args, [arg]: value }, 'args')
  }

  return (
    <>
      <InputRow compact={true} label={intl.formatMessage({ id: 'commands.command_id' })}>
        <Select
          name="command_id"
          options={commandOptions}
          value={command && command.id}
          disabled={!editing}
          required={true}
          onChange={onChange}
        />
      </InputRow>

      {commandType && (
        <CommandTargetInput
          target={target}
          target_type={commandType.target_type}
          allowed_targets={commandType.allowed_targets}
          editing={editing}
          onChange={onChange}
        />
      )}

      {commandType && (
        <VariableArgsForm
          args={args}
          args_schema={commandType.args_schema}
          editing={editing}
          required={required}
          onChange={handleArgChange}
        />
      )}
    </>
  )
}

export default CommandSelectForm
