import { createSelector } from 'reselect'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { selectDenormalizedParameters } from '../../Parameters/params.selectors'
import { Equipment } from 'au-nsi/equipment'
import { selectDevice as deviceSelector } from '../nsi.selectors'
import { ParameterGroup } from 'au-nsi/parameters'

const groupsSelector = (state) => state.parameter_groups.items

// from all system parameters select only those that are measured directly by device
export const deviceParametersSelector = createSelector(
  deviceSelector,
  selectDenormalizedParameters,
  (device: Equipment, parameters: ParameterDn[]) => {
    if (!device || device.protocol !== 'C.37.118') return []

    const recordedParameters = new Set(['Frequency', 'Dfrequency'])
    const mapping = device.parameters_mapping
    const config = device.protocol_configuration

    const analogs = config.analogs || []
    const digitals = config.digitals || []
    const phasors = config.phasors || []

    for (const p of analogs) {
      const param = mapping.find((row) => row.name === p.name)
      recordedParameters.add(param && param.parameter_id)
    }

    for (const p of digitals) {
      const param = mapping.find((row) => row.name === p.name)
      recordedParameters.add(param && param.parameter_id)
    }

    for (const p of phasors) {
      const magnitude = mapping.find((row) => row.name === p.magnitudeName)
      recordedParameters.add(magnitude && magnitude.parameter_id)

      const angle = mapping.find((row) => row.name === p.angleName)
      recordedParameters.add(angle && angle.parameter_id)
    }

    return parameters.filter((p) => recordedParameters.has(p.id))
  }
)

/**
 * select only roots of ltree
 * element is a root if its path does not include dot character
 */
export const selectRootParameterGroups = createSelector(groupsSelector, (groups: ParameterGroup[]) => {
  return groups.filter((g) => !g.path.includes('.'))
})
