import { PlatformModuleID } from 'au-nsi/shared'
import { UserRole } from 'au-nsi/user'
import React from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Checkbox from '../../../shared/Inputs/Checkbox/Checkbox'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'
import { FormMode } from '../../../shared/interfaces'
import memoize from '../../../utils/memoize'
import { highlightSearch, search } from '../../../utils/search'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import HistoryButton from '../../System/ActionsJournal/HistoryButton'
import { AccessRightRecord } from '../collection.interfaces'
import { FormButtonsHOC } from '../collection.utils'
import { selectGrouppedUsers } from '../Users/users.selectors'
import actions from './roles.actions'

const FormButtons = FormButtonsHOC(actions)

class RolesForm extends React.Component<Props, State> {
  state: State = { searchValue: '' }

  private shownRights: Set<string>
  private shownCheckedRights: Set<string>

  private formTitles = {
    view: 'nsi.role_info',
    create: 'nsi.role_create',
    edit: 'nsi.role_edit',
    delete: 'nsi.role_delete',
  }

  private get isEditing() {
    const { mode } = this.props
    return mode === 'create' || mode === 'edit'
  }

  private get isAdminRole() {
    return this.props.role.id === 1
  }

  private handleNameChange = (e) => {
    const name = e.target.value
    this.props.dispatch(actions.updateSelectedItem('name', name))
  }

  private renderNameInput() {
    return (
      <input
        className="nsi-input"
        style={{ '--input-height': '40px' } as any}
        value={this.props.role.name}
        onChange={this.handleNameChange}
        required={true}
      />
    )
  }

  private toggleRight = (checked: boolean, id: string) => {
    if (!this.isEditing || this.isAdminRole) return

    const { role } = this.props
    const access_rights = checked ? [...role.access_rights, id] : role.access_rights.filter((r) => r !== id)
    this.props.dispatch(actions.updateSelectedItem('access_rights', access_rights))
  }

  // включить/выключить все видимые права
  private toggleAllRights = (selected: boolean) => {
    const rights = new Set(this.props.role.access_rights)
    for (const id of this.shownRights) {
      if (selected) rights.add(id)
      else rights.delete(id)
    }

    this.props.dispatch(actions.updateSelectedItem('access_rights', [...rights]))
  }

  /**
   * Render panel for each access right group, and inside each panel
   * render row for each right in that group
   */
  private renderRights = () => {
    const { role, accessRights, accessGroups } = this.props
    const isAdminRole = this.isAdminRole
    const searchLower = this.state.searchValue.toLowerCase().split(' ')

    this.shownRights = new Set()
    this.shownCheckedRights = new Set()

    const panels = accessGroups.map((group) => {
      const groupName = this.props.intl.formatMessage({ id: 'rights.' + group })
      const isGroupMatch = !!search(groupName, searchLower).length

      const prefix = group + ':'
      // access rights that belong to the group
      let rights = accessRights.filter((right) => right.id.startsWith(prefix))
      rights.sort(nameComparator)

      if (!isGroupMatch) rights = rights.filter((r) => search(r.name, searchLower).length)
      if (rights.length === 0) return null

      const total = rights.length // total number of rights in the group
      let checked = 0 // number of user rights in the group

      const rows = rights.map((right) => {
        this.shownRights.add(right.id)
        const isChecked = role.access_rights.includes(right.id)

        if (isChecked) {
          this.shownCheckedRights.add(right.id)
          checked += 1
        }

        return (
          <div key={right.id} className="nsi-cn__form-subrow">
            <Checkbox
              checked={isChecked}
              disabled={!this.isEditing || isAdminRole}
              name={right.id}
              onChange={this.toggleRight}
            />
            <div style={{ paddingLeft: '12px' }}>{highlightSearch(right.name, searchLower).result}</div>
          </div>
        )
      })

      return (
        <div key={group} className="nsi-panel is-open">
          <div className="nsi-cn__right-group">
            <span>{highlightSearch(groupName, searchLower).result}</span>
            <span className="nsi-cn__rights-count">
              {checked} / {total}
            </span>
          </div>
          <div style={{ padding: '1rem 0' }}>{rows}</div>
        </div>
      )
    })

    return <React.Fragment>{panels}</React.Fragment>
  }

  render() {
    const { role } = this.props
    if (!role) return null

    const title = this.formTitles[this.props.mode]
    const rights = this.renderRights()

    return (
      <div className="nsi-main__wrapper">
        <div className="nsi-main__container" style={{ marginBottom: 0 }}>
          <div className="nsi-main__header">
            <h2>
              <FormattedMessage id={title} />
            </h2>
            {this.props.showHistory && <HistoryButton resource="user_roles" resourceId={role.id} />}
          </div>

          <div className="nsi-cn__form-row">
            <div className="nsi-cn__form-label">
              <FormattedMessage id="nsi.role.name" />
            </div>
            <div className="nsi-cn__form-input" style={{ height: '40px', lineHeight: '40px' }}>
              {this.isEditing ? this.renderNameInput() : role.name}
            </div>
          </div>
          <div className="nsi-cn__form-search-box">
            <SearchInput value={this.state.searchValue} onChange={(searchValue) => this.setState({ searchValue })} />
            {!this.isAdminRole && (this.props.mode === 'create' || this.props.mode === 'edit') && (
              <div className="inline-flex" style={{ flexShrink: 0 }}>
                <Checkbox
                  checked={this.shownRights.size > 0 && this.shownRights.size === this.shownCheckedRights.size}
                  onChange={this.toggleAllRights}
                />
                {this.props.intl.formatMessage({ id: 'common.select_all' })}
              </div>
            )}
          </div>

          <div style={{ padding: '0 4px' }}>{rights}</div>
          <div className="nsi-cn__form-footer">
            <FormButtons
              mode={this.props.mode}
              intl={this.props.intl}
              selectedItem={role}
              dispatch={this.props.dispatch}
              allowEditing={this.props.allowEditing}
              allowDeleting={this.props.allowDeleting}
              validate={validateRole}
            />
          </div>
        </div>
      </div>
    )
  }
}

interface State {
  searchValue: string
}

interface Props {
  mode: FormMode
  role: UserRole
  intl: IntlShape
  accessRights: AccessRightRecord[]
  accessGroups: string[]
  allowEditing: boolean
  allowDeleting: boolean
  showHistory: boolean
  dispatch: (action: any) => void
}

function validateRole(role: UserRole) {
  if (!role.name) return { id: 'nsi.role.errors.empty_name' }

  return null
}

const mapStateToProps = (state: ReduxState, props) => {
  const { intl } = props

  const rights = selectAccessRights(state)
  const modules = selectEnabledModules(state)

  const accessRights = state.roles.accessRights.filter((r) => {
    if (!r.module_id) return true

    return r.module_id.split('$').every((module) => modules.has(module as PlatformModuleID))
  })
  const accessGroups = getAccessGroups(accessRights, intl)
  const users = selectGrouppedUsers(state)

  const role = state.roles.selectedItem
  const isEmptyRole = role && !users.has(role.id)
  const allowDeleting = rights['roles:delete'] && isEmptyRole

  return {
    mode: state.roles.mode,
    role,
    accessRights,
    accessGroups,
    allowEditing: rights['roles:update'],
    allowDeleting,
    showHistory: rights['journal:get_changelog'],
  }
}

/**
 * From list of access rights get list of groups
 * Group id determined by resource, for example right 'users:create' will be
 * assigned 'users' group
 */
const getAccessGroups = memoize((accessRights: AccessRightRecord[], intl: IntlShape): string[] => {
  const groups = []

  for (const right of accessRights) {
    const group = right.id.split(':')[0] // 'users:create' -> 'users'
    const len = groups.length

    // rights are ordered by id, each id starts with resource, therefore to check if
    // current group is already included in the result we only have to compare it with
    // the last group in the result
    if (len === 0 || groups[len - 1]?.id !== group) {
      groups.push({ id: group, name: intl.formatMessage({ id: 'rights.' + group }) })
    }
  }

  groups.sort(nameComparator)

  return groups.map((g) => g.id)
})

const nameComparator = (a, b) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export default injectIntl(connect(mapStateToProps)(RolesForm))
