import { memo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ReactComponent as AcceptIcon } from '../../../../icons/check.svg'
import { ReactComponent as DeclineIcon } from '../../../../icons/decline.svg'
import { ReduxState } from '../../../../redux/store.types'
import { formatDate } from '../../../../utils/lang'
import styles from '../../user.module.css'
import { useLinkHandler } from '../moderation.hooks'
import { IModerationRequest } from 'au-nsi/moderation'

interface IProps {
  request: IModerationRequest
}

const ModerationRequestRow = ({ request }: IProps) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const handleClick = useLinkHandler()

  const moderator = useSelector((state: ReduxState) => state.users.items.find((u) => u.id === request.moderated_by))
  const date = request.updated_at
    ? intl.formatMessage({ id: 'user.request.updated_at' }, { date: formatDate(request.updated_at, 'dd MMM HH:mm') })
    : intl.formatMessage({ id: 'user.request.created_at' }, { date: formatDate(request.created_at, 'dd MMM HH:mm') })

  const renderModerateResult = () => {
    if (!request.moderated_at) return null

    const icon = request.rejected ? (
      <DeclineIcon style={{ color: 'var(--danger-text)' }} className={styles.requestRowStatusIcon} />
    ) : (
      <AcceptIcon style={{ color: 'var(--text-success)' }} className={styles.requestRowStatusIcon} />
    )

    const name = moderator?.name ? moderator.name + ', ' : ''
    const data = { date: formatDate(request.moderated_at, 'dd MMM HH:mm'), name }
    const textId = request.rejected ? 'user.request.rejectedBy' : 'user.request.approvedBy'

    return (
      <div className={styles.requestRowStatus}>
        {icon}
        <span>{intl.formatMessage({ id: textId }, data)}</span>
      </div>
    )
  }

  return (
    <div className={styles.requestRow} data-moderated={!!request.moderated_at} ref={ref}>
      <div className={styles.requestRowLine}>
        <div className={styles.requestRowName}>
          <span>№{request.id}</span>
        </div>
        <div>{date}</div>
      </div>
      <div className={styles.requestRowLine} style={{ marginTop: '.5em', display: 'block' }}>
        <span dangerouslySetInnerHTML={{ __html: request.description[intl.locale] }} onClick={handleClick} />
      </div>
      {request.moderated_at && (
        <div>
          <hr className={styles.requestHr} />
          <div className={styles.requestRowLine}>{request.moderated_at && renderModerateResult()}</div>
        </div>
      )}
    </div>
  )
}

const Linked = (props: IProps) => (
  <NavLink to={`/user/requests/${props.request.id}`} style={{ textDecoration: 'none', color: 'currentColor' }}>
    <ModerationRequestRow {...props} />
  </NavLink>
)

export default memo(Linked)
