import { IDashboard } from 'au-nsi/dashboards'
import produce from 'immer'

/* Перемещает выбранный элемент на позицию мыши */
export const move = (e: MouseEvent, DOMEl: HTMLElement) => {
  const parentBox = DOMEl.parentElement.getBoundingClientRect()
  DOMEl.style.left = e.clientX - DOMEl.offsetWidth / 2 - parentBox.left + 'px'
  DOMEl.style.top = e.clientY - DOMEl.offsetHeight / 2 - parentBox.top + 'px'
}

export const generateStyles = (left: number, top: number, width: number, height: number) => {
  return `position: relative; left: ${left}px, top: ${top}px; width: ${width}px; height: ${height}px; zIndex: 999999`
}

/*
 Возвращает процент пересечения прямоугольников в формате 0.xx
 Предполагает, что прямоугольники имеют равную (либо соу клоуз) высоту и ширину
*/
export const getIntersectArea = (rect1: DOMRect, rect2: DOMRect) => {
  // Координаты прямоугольника пересечения
  const x1 = Math.max(rect1.left, rect2.left)
  const y1 = Math.max(rect1.top, rect2.top)
  const x2 = Math.min(rect1.left + rect1.width, rect2.left + rect2.width)
  const y2 = Math.min(rect1.top + rect1.height, rect2.top + rect2.height)

  // Прямоугольники не пересекаются, либо соприкасаются
  if (x1 >= x2 || y1 >= y2) return 0

  const rectArea = rect1.width * rect1.height
  const intersectArea = (x2 - x1) * (y2 - y1)

  return intersectArea / rectArea
}

export const moveDashboards = (dashboards: IDashboard[], sourceId: string, targetId: string) => {
  return produce(dashboards, (dashboards) => {
    const folderReassign = (prev: IDashboard, next: IDashboard) => {
      /* Смена дашборда, образующего папку */
      dashboards.forEach((d) => {
        if (d.folder_id === prev.id) {
          d.folder_id = next.id
        }
      })

      const nextDashInd = dashboards.findIndex((d) => d.id === next.id)
      const prevDashInd = dashboards.findIndex((d) => d.id === prev.id)

      dashboards[prevDashInd].folder_id = next.id
      dashboards[nextDashInd].folder_name = dashboards[prevDashInd].folder_name
      dashboards[prevDashInd].folder_name = null
    }

    dashboards.sort((a, b) => a.user_ordering_index - b.user_ordering_index)
    let sourceInd = dashboards.findIndex((d) => d.id.toString() === sourceId.toString())
    let targetInd = dashboards.findIndex((d) => d.id.toString() === targetId.toString())

    if (sourceInd === -1 || targetInd === -1) return

    if (
      dashboards[targetInd].folder_id === dashboards[targetInd].id ||
      dashboards[sourceInd].folder_id === dashboards[sourceInd].id
    ) {
      const filter = () => {
        dashboards = [
          dashboards.find((d) => d.id === d.folder_id && d.folder_id === dashboards[targetInd].folder_id),
          ...dashboards.filter((d) => d.folder_id === dashboards[targetInd].folder_id && d.id !== d.folder_id),
        ]

        sourceInd = dashboards.findIndex((d) => d.id === sourceId)
        targetInd = dashboards.findIndex((d) => d.id === targetId)
      }

      if (
        dashboards[sourceInd].folder_id === dashboards[sourceInd].id &&
        dashboards[sourceInd].folder_id === dashboards[targetInd].folder_id
      ) {
        filter()
        folderReassign(dashboards[sourceInd], dashboards[sourceInd + 1])
      } else if (dashboards[sourceInd].folder_id === dashboards[targetInd].folder_id) {
        filter()
        folderReassign(dashboards[targetInd], dashboards[sourceInd])
      }
    }

    const step = targetInd < sourceInd ? 1 : -1
    const targetOrderInd = dashboards[targetInd].user_ordering_index
    for (let i = targetInd; i * step < sourceInd * step; i += step) {
      dashboards[i].user_ordering_index = dashboards[i + step].user_ordering_index
    }
    dashboards[sourceInd].user_ordering_index = targetOrderInd
  })
}

export const addDashboardToFolder = (dashboards: IDashboard[], folderId: string, dashboardId: string) => {
  return produce(dashboards, (dashboards) => {
    const pushingDash = dashboards.find((d) => d.id === dashboardId)
    const folderDashboards = [
      ...dashboards.filter((d) => d.folder_id === folderId && d.id !== folderId),
      pushingDash,
    ].sort((a, b) => a.user_ordering_index - b.user_ordering_index)

    for (let i = 0; i < folderDashboards.length; ++i) {
      if (folderDashboards[i].user_ordering_index > pushingDash.user_ordering_index)
        [folderDashboards[i].user_ordering_index, pushingDash.user_ordering_index] = [
          pushingDash.user_ordering_index,
          folderDashboards[i].user_ordering_index,
        ]
    }

    pushingDash.folder_id = folderId
  })
}

export const removeFromFolder = (dashboards: IDashboard[], dashboardId: string) => {
  return produce(dashboards, (dashboards) => {
    const dashInd = dashboards.findIndex((d) => d.id === dashboardId)

    let folder_id = dashboards[dashInd].folder_id

    if (folder_id === dashboardId) {
      /* Удаление образующего элемента */
      const folderElements = [
        dashboards[dashInd],
        ...dashboards
          .filter((d) => d.folder_id === folder_id && d.id !== d.folder_id)
          .sort((a, b) => a.user_ordering_index - b.user_ordering_index),
      ]

      const prevOrderIndex = folderElements[0].user_ordering_index
      folderElements[0].user_ordering_index = folderElements[1].user_ordering_index
      folderElements[1].user_ordering_index = prevOrderIndex
      folderElements[1].folder_name = dashboards[dashInd].folder_name
      folder_id = folderElements[1].id

      folderElements.forEach((e) => (e.folder_id = folder_id))
    }

    dashboards[dashInd].folder_id = null
    const folderElements = dashboards.filter((d) => d.folder_id === folder_id)
    if (folderElements.length === 1) {
      folderElements[0].folder_id = null
      folderElements[0].folder_name = null
    }
  })
}

export const createFolder = (dashboards: IDashboard[], sourceId: string, targetId: string): IDashboard[] => {
  if (sourceId === targetId) return

  return produce(dashboards, (dashboards) => {
    const sourceInd = dashboards.findIndex((d) => d.id === sourceId)
    const targetInd = dashboards.findIndex((d) => d.id === targetId)

    dashboards[sourceInd] = { ...dashboards[sourceInd], folder_id: targetId }
    dashboards[targetInd] = { ...dashboards[targetInd], folder_id: targetId }
  })
}

export const disbandFolder = (dashboards: IDashboard[], folderId: string) => {
  return dashboards.map((d) => {
    if (d.folder_id === folderId) return { ...d, folder_id: null, folder_name: null }
    return d
  })
}

export const groupIntoFolder = (dashboards: IDashboard[], id_list: Set<string>): IDashboard[] => {
  const first_id = id_list.values().next().value
  const folder = dashboards.find((d) => d.id === first_id)
  if (folder === undefined) return dashboards

  return dashboards.map((d) => {
    if (d.id === folder.id) return { ...d, folder_id: folder.id, folder_name: '' }
    if (id_list.has(d.id)) return { ...d, folder_id: folder.id, folder_name: null }

    return d
  })
}

export const removeList = (dashboards: IDashboard[], id_list: Set<string>): IDashboard[] => {
  return dashboards.filter((d) => !id_list.has(d.id))
}

export const removeFolder = (dashboards: IDashboard[], folder_id: string) => {
  const idsToRemove = new Set<string>()
  for (const d of dashboards) {
    d.folder_id === folder_id && idsToRemove.add(d.id)
  }

  return removeList(dashboards, idsToRemove)
}

export const renameFolder = (dashboards: IDashboard[], folder_id: string, name: string) => {
  return dashboards.map((d) => (d.id === folder_id ? { ...d, folder_name: name } : d))
}

export const updateDashboard = (dashboards: IDashboard[], updated: IDashboard) => {
  return dashboards.map((d) => (d.id === updated.id ? { ...d, ...updated } : d))
}
