import { IModerationRequest } from 'au-nsi/moderation'
import { IAccessSettings } from 'au-nsi/shared'
import { ReactComponent as SettingsIcon } from 'icons/lock.svg'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import { generateAccess } from '../../../../../../shared/Access/access.utils'
import AccessModal from '../../../../../../shared/Access/AccessModal'
import { CurrentUserState } from '../../../../../App/user.reducers'

interface IProps<T extends IModerationRequest> {
  request: T
  onChange: (request: T) => void
}

const RequestAccessButton = <T extends IModerationRequest>({ request, onChange }: IProps<T>) => {
  const intl = useIntl()
  const user = useSelector((state: ReduxState) => state.user)
  const [modalOpen, setModalOpen] = useState(false)
  const shouldRender =
    request.resource === 'equipment' && request.method !== 'DELETE' && !request.url.includes('access/grant')
  if (!shouldRender) return null

  const handleSave = (access: IAccessSettings) => {
    switch (true) {
      case request.resource === 'equipment':
        onChange({ ...request, request_body: { ...request.request_body, access } })
    }
    setModalOpen(false)
  }

  return (
    <>
      {modalOpen && (
        <AccessModal access={extractAccess(request, user)} onSave={handleSave} onCancel={() => setModalOpen(false)} />
      )}
      <span onClick={() => setModalOpen(true)}>
        <SettingsIcon style={{ height: '18px' }} />
        {intl.formatMessage({ id: 'nsi.device.access_settings' })}
      </span>
    </>
  )
}

const extractAccess = (request: IModerationRequest, user: CurrentUserState) => {
  switch (request.resource) {
    case 'equipment':
      return (request.request_body as any).access ?? generateAccess(user)
  }
}

export default RequestAccessButton
