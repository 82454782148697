import { ReactComponent as LockIcon } from 'icons/lock.svg'
import { ReactComponent as EditIcon } from 'icons/pen.svg'
import { ReactComponent as DeleteIcon } from 'icons/trash.svg'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { highlightSearch } from '../../utils/search'
import SearchInput from '../Inputs/Search/SearchInput'
import css from './templates.module.css'
import { SavedTemplateBase } from './templates.types'

interface Props {
  items: SavedTemplateBase[]
  allowEditing: boolean
  loading: boolean
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  onSelect: (id: number) => void
}

/**
 * Список сохраненных шаблонов с возможностью поиска. Т.к. в списке выводится только имя,
 * то тип шаблона не важен.
 */
const SavedTemplatesList = ({ items, allowEditing, loading, onEdit, onDelete, onSelect }: Props) => {
  const intl = useIntl()

  const [search, setSearch] = useState('')
  const searchTerms = search.toLowerCase().trim().split(/\s+/g)

  const [deletingID, setDeletingID] = useState<number>()

  const handleSelect = (e) => onSelect(+e.currentTarget.dataset.id)

  const handleEdit = (e) => {
    e.stopPropagation()
    onEdit(+e.currentTarget.dataset.id)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    setDeletingID(+e.currentTarget.dataset.id)
  }

  const handleDeleteCancel = (e) => {
    e.stopPropagation()
    setDeletingID(null)
  }

  const handleDeleteConfirm = (e) => {
    e.stopPropagation()
    onDelete(deletingID)
  }

  const rows = []

  for (const item of items) {
    const { isMatch, result } = highlightSearch(item.name, searchTerms)
    if (search && !isMatch) continue

    rows.push(
      <div key={item.id} data-id={item.id} className={css.listRow} onClick={handleSelect}>
        <div>{result}</div>
        {item.access.restricted && <LockIcon className="svg-icon" style={{ opacity: 0.7 }} height={14} />}

        <div style={{ marginLeft: 'auto' }} />

        {allowEditing && !deletingID && (
          <>
            <EditIcon className="svg-icon" height={18} data-id={item.id} onClick={handleEdit} />
            <DeleteIcon className="svg-icon" height={16} data-id={item.id} onClick={handleDelete} />
          </>
        )}

        {deletingID === item.id && (
          <>
            <span className="text--danger" style={{ flexShrink: 0 }} onClick={handleDeleteConfirm}>
              {intl.formatMessage({ id: 'common.delete' })}
            </span>
            <span className="text--gray" style={{ flexShrink: 0 }} onClick={handleDeleteCancel}>
              {intl.formatMessage({ id: 'common.cancel' })}
            </span>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <SearchInput onChange={setSearch} />
      <div style={{ marginBottom: '1em' }} />

      {rows}

      {rows.length === 0 && !loading && (
        <div className="text--gray text--center">{intl.formatMessage({ id: 'common.no_results' })}</div>
      )}
    </>
  )
}

export default SavedTemplatesList
