import { Equipment } from 'au-nsi/equipment'
import { useIntl } from 'react-intl'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import { visibleControls } from '../MapLeftPanel/mapPanel.utils'
import styles from '../maptopology.module.css'

interface Props {
  statusFilters: Record<Equipment['state'], boolean>
  onChange: (controls: Record<Equipment['state'], boolean>) => void
  filterByViewPort: boolean
  onFilterByViewPortChange: (filter: boolean) => void
}

/**
  Компонент панели фильтра устройств
  Умеет тоглить фильтры по статусу
  Содержит в себе настройку "Отображать только видимые на карте устройства" (filterByViewPort) и может её тоглить

  ! Отображат фильтры только по статусам, перечисленным в visibleControl (mapTopology.utils) !
*/
const DeviceFilterControls = ({ statusFilters, onChange, filterByViewPort, onFilterByViewPortChange }: Props) => {
  const intl = useIntl()

  const handleChange = (value: any, key: string) => onChange({ ...statusFilters, [key]: value })

  const StatusControls = visibleControls.map((key) => {
    const label = intl.formatMessage({ id: 'nsi.device_state.' + key })

    return (
      <ToggleWithLabel
        key={key}
        name={key}
        label={label}
        value={statusFilters[key]}
        onChange={handleChange}
        style={{ marginTop: '8px' }}
      />
    )
  })

  return (
    <div className={styles.deviceStatusControls}>
      <h2 className="system__title" style={{ marginBottom: '1em' }}>
        {intl.formatMessage({ id: 'map.device_filters' })}
      </h2>
      <ToggleWithLabel
        name="filter"
        label={intl.formatMessage({ id: 'map.device_filters.viewport' })}
        value={filterByViewPort}
        onChange={onFilterByViewPortChange}
      />

      <div className="section-title" style={{ marginTop: '21px' }}>
        {intl.formatMessage({ id: 'map.device_filters.states' })}
      </div>
      {StatusControls}
    </div>
  )
}

export default DeviceFilterControls
