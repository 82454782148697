import classnames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import GanttTable from '../../shared/GanttTable/GanttTable'
import HistoryToggle from '../../shared/Utils/HistoryToggle'
import { PageMode } from '../../shared/interfaces'
import ThreePhaseCircuit from '../../shared/ThreePhaseCircuit/ThreePhaseCircuit'
import ChartPlayer from '../ChartPlayer/ChartPlayer'
import { Equipment } from 'au-nsi/equipment'
import { selectReceivers } from '../Nsi/nsi.selectors'
import GaugeList from './GaugeList'
import { actions } from './oplim.reducers'
import { getMatchedDevices, getSelectedDevices } from './oplim.selectors'
import OplimTable from './OplimTable'

import './oplim.styles.css'

class Oplim extends Component<Props> {
  private changeCurrentRegistratorId = (e) => {
    const id = e.target.dataset.id
    this.props.dispatch(actions.selectDevice(id))
  }

  private changeMode = (mode: PageMode) => {
    this.props.dispatch(actions.setMode(mode))
  }

  private handleSearch = (s: string) => {
    this.props.dispatch(actions.setSearch(s))
  }

  private renderTable() {
    return (
      <OplimTable
        equipment={this.props.registrators}
        equipmentMatch={this.props.matchedRegistrators}
        selectedDeviceId={this.props.deviceId}
        onSelect={this.changeCurrentRegistratorId}
        search={this.props.search}
        onSearch={this.handleSearch}
      />
    )
  }

  private renderGantt() {
    return (
      <GanttTable
        page="oplim"
        registrators={this.props.matchedRegistrators}
        onSelectRegistrator={this.changeCurrentRegistratorId}
        selectedId={this.props.deviceId}
        search={this.props.search}
        onSearch={this.handleSearch}
      />
    )
  }

  render() {
    const { mode } = this.props

    const classNameWrapper = classnames('oplim__chart-container', {
      'is-empty': !this.props.deviceId,
    })

    const registrator = this.props.selectedRegistrators[0]
    const registratorName = registrator && registrator.name

    return (
      <React.Fragment>
        <ChartPlayer page="oplim" isFrameControlsHidden={true} title={this.props.title || ''}>
          <HistoryToggle mode={this.props.mode} onChange={this.changeMode} />
        </ChartPlayer>
        <div className="pq">
          {mode === 'history' ? this.renderGantt() : this.renderTable()}
          <div className={classNameWrapper}>
            <div>{registratorName}</div>
            <div className="oplim__circuit">
              {registrator && <ThreePhaseCircuit deviceId={registrator.id} viewBox="0 0 450 300" />}
            </div>
            <GaugeList registrator={registrator} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

interface Props {
  registrators: Equipment[]
  matchedRegistrators: Equipment[]
  selectedRegistrators: Equipment[]
  deviceId: string
  mode: PageMode
  title: string
  search: string
  dispatch: (action: any) => void
}

const mapStateToProps = (state: ReduxState) => {
  const title = state.dashboards.dashboards.find((d) => d.id === 'oplim')?.name

  return {
    registrators: selectReceivers(state),
    matchedRegistrators: getMatchedDevices(state),
    selectedRegistrators: getSelectedDevices(state),
    ...state.oplim,
    title,
  }
}

export default connect(mapStateToProps)(Oplim)
