import { SelectOption } from '@alterouniversal/au-react-components'
import { IBarChartSettings } from 'au-nsi/dashboards'
import { useIntl } from 'react-intl'
import PlusButton from '../../../shared/Forms/PlusButton'
import BarChartParameterRow from './BarChartParameterRow'

const BarChartSettingsParameters = (props: Props) => {
  const intl = useIntl()

  const rows = props.settings.parameters.map((c, i) => {
    return (
      <BarChartParameterRow
        settings={props.settings}
        key={i}
        index={i}
        row={c}
        onChange={props.onChange}
        onRemove={props.onRemove}
        equipmentOptions={props.equipmentOptions}
        parameterOptions={props.parameterOptions}
        booleanOptions={props.booleanOptions}
      />
    )
  })

  return (
    <div style={{ marginTop: '10px' }}>
      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.parameters.name' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.parameters.device_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.parameters.parameter_id' })}</th>
            {props.settings.mirror_axis === true && (
              <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.parameters.mirror' })} </th>
            )}
            <th>{intl.formatMessage({ id: 'dashboards.bar_chart.settings.parameters.color' })}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <PlusButton textId="common.add" onClick={props.onAdd} style={{ marginLeft: 0 }} />
    </div>
  )
}

interface Props {
  settings: IBarChartSettings
  onAdd: () => void
  onRemove: (index: number) => void
  onChange: (key: string, value: any, index: number) => void
  booleanOptions: { value: boolean; title: string }[]
  equipmentOptions: Array<{ label: string; value: string; color: string }>
  parameterOptions: SelectOption[]
}

export default BarChartSettingsParameters
