import React from 'react'
import { FormattedMessage } from 'react-intl'
import confirmService, { ConfirmOption } from './confirm.service'
import Modal from './Modal'

/**
 * Компонент для запроса подтверждения действия у пользователя.
 * Получает запросы на подтверждение через confirm.service и выводит модальное окно
 * с кнопками для отмены и продолжения операции.
 * Выбор пользователя передает обратно через confirm.service
 */
class ConfirmModal extends React.Component<unknown, State> {
  state: State = { isOpen: false, title: '', options: [] }

  private confirm: (value: string) => void
  private cancel: () => void

  componentDidMount() {
    confirmService.subscribe(({ title, options, confirm, cancel }) => {
      this.confirm = confirm
      this.cancel = cancel
      this.setState({ title, options, isOpen: true })
    })
  }

  private handleClose = () => {
    this.setState({ isOpen: false })
    this.cancel()
  }

  private handleConfirm = (e) => {
    const { value } = e.target.dataset
    this.setState({ isOpen: false })
    this.confirm(value)
  }

  render() {
    if (!this.state.isOpen) return null

    const options = this.state.options.map((option) => {
      return (
        <button key={option.value} data-value={option.value} className="nsi-button danger" onClick={this.handleConfirm}>
          <FormattedMessage id={option.title} />
        </button>
      )
    })

    return (
      <Modal size={'sm'} onClose={this.handleClose}>
        <div className="confirm-modal">
          <h2 style={{ paddingBottom: '1rem' }}>
            <FormattedMessage id={this.state.title} />
          </h2>
          <div className="app-modal__footer">
            <button key="cancel" className="nsi-button inverted" onClick={this.handleClose}>
              <FormattedMessage id="common.cancel" />
            </button>
            {options}
          </div>
        </div>
      </Modal>
    )
  }
}

interface State {
  isOpen: boolean
  title: string
  options: ConfirmOption[]
}

export default ConfirmModal
