import { ILSCodec } from './LocalStorageStrategy'

class SetLsCodec implements ILSCodec<Set<unknown>> {
  public stringify(set: Set<unknown>): string {
    return JSON.stringify(Array.from(set))
  }

  public parse(s: string): Set<unknown> {
    return new Set(JSON.parse(s))
  }
}

const setLsCodec = new SetLsCodec()
export default setLsCodec
