import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import useInfiniteLoader from '../../../hooks/useInfiniteLoader'
import Datepicker from '../../../shared/Inputs/Datepicker/Datepicker'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import { IChangelogItem } from './actions.types'
import ActionsTable from './ActionsTable'

interface Props {
  resource: string
  resourceId: string | number
  onClose: () => void
}

/**
 * Модальное окно загружающее и отображающее историю изменений указанного ресурса
 */
const HistoryModal = ({ resource, resourceId, onClose }: Props) => {
  const intl = useIntl()
  const [filters, setFilters] = useState({ t0: 0, t1: 0 })

  const handleChange = useCallback((value: number, key: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleTailChange = (tail: boolean) => {
    let t0 = 0
    let t1 = 0

    if (!tail) {
      const now = new Date()
      t1 = now.valueOf()
      t0 = now.setMonth(now.getMonth() - 1)
    }

    setFilters({ t0, t1 })
  }

  const { results, isLoading, isMore, loadMore } = useInfiniteLoader<IChangelogItem>({
    url: `/nsi/journal/changelog/${resource}/${resourceId}`,
    query: filters,
  })

  const showTail = !filters.t0 && !filters.t1
  const noResults = !isLoading && results.length === 0

  return (
    <Modal size="lg" style={{ margin: '20px auto' }} onClose={onClose} closeOnClickOutside={true} closeOnEscape={true}>
      <h2>{intl.formatMessage({ id: 'system.actions.history.title' })}</h2>

      <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <ToggleWithLabel
          name=""
          value={showTail}
          label={intl.formatMessage({ id: 'system.actions.tail' })}
          onChange={handleTailChange}
        />

        {!showTail && (
          <>
            <div style={{ marginLeft: 'auto' }} />
            <Datepicker name="t0" time={filters.t0} onTimeChange={handleChange} />
            <div style={{ margin: '0 1em' }}>—</div>
            <Datepicker name="t1" time={filters.t1} onTimeChange={handleChange} />
          </>
        )}
      </div>

      <div id="HistoryModalScroll" style={{ maxHeight: 'calc(100vh - 180px)', overflow: 'auto' }}>
        <InfiniteScroll parentSelector="#HistoryModalScroll" isMore={isMore} loadMore={loadMore}>
          <ActionsTable items={results} showDetails={true} compactHeader={true} onSelect={() => null} />
        </InfiniteScroll>
      </div>

      {noResults && (
        <div className="text--center text--gray" style={{ marginBottom: '1rem', marginTop: '-1rem' }}>
          {intl.formatMessage({ id: 'common.no_results' })}
        </div>
      )}
    </Modal>
  )
}

export default HistoryModal
