import { LoaderBg } from '@alterouniversal/au-react-components'
import classnames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router'
import http from '../../../utils/http'
import { loadDocsModule } from './docs.loader'
import css from './docs.module.css'
import { ReactComponent as Icon } from './info.svg'

/**
 * Иконка справки, при клике открывает выезжающую панель со справкой по текущей странице
 */
const HelpDocsIcon = () => {
  const ref = useRef<any>()
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  // последняя страница не из документации для возвращения назад
  // из полностраничной версии документации
  const lastAppLocation = useRef('/')

  // страница документации, отображаемая в правой панели, необходима
  // для открытия этой же страницы при переходе к полностраничному режиму
  const docsLocation = useRef('/docs')

  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const { pathname } = location
  const isActive = open || location.pathname.startsWith('/docs')

  // при клике на иконку открываем/закрываем правую панель, либо если пользователь
  // находится в модуле документации, то возвращаем его на последнюю страницу приложения
  const toggleOpen = () => {
    if (!pathname.startsWith('/docs')) setOpen(!open)
    else navigate(lastAppLocation.current)
  }

  // для отображения документации вызываем метод модуля, подгруженного из сервиса au-docs
  const render = (page) => {
    const onLocationChange = (nextPath: string) => {
      docsLocation.current = nextPath

      if (nextPath.startsWith('/docs')) render(nextPath)
      else navigate(nextPath)
    }

    const el = ref.current
    if (el) el.props = { http, lang: intl.locale, location: page, onLocationChange }
  }

  useEffect(() => {
    if (open) loadDocsModule(() => setLoaded(true))
    else setLoaded(false)
  }, [open])

  useEffect(() => {
    if (loaded) render(pathname)
  }, [loaded])

  useEffect(() => {
    // скрываем панель при переходе на другую страницу
    if (open) return setOpen(false)
    if (!pathname.startsWith('/docs')) lastAppLocation.current = pathname
  }, [pathname])

  const btnStyle = isActive ? { backgroundColor: 'var(--primary-80)', color: '#fff' } : undefined

  return (
    <>
      <div
        className="alerts__button"
        style={btnStyle}
        title={intl.formatMessage({ id: 'nav.docs' })}
        onClick={toggleOpen}
      >
        <Icon width="16" height="16" />
      </div>

      <div className={classnames(css.panel, { [css.openPanel]: open })}>
        <div className={css.header}>
          <div onClick={() => navigate(docsLocation.current)}>
            {intl.formatMessage({ id: 'nav.docs.open_full_page' })}
          </div>
          <div onClick={() => setOpen(false)}>{intl.formatMessage({ id: 'nav.docs.close' })}</div>
        </div>

        <div style={{ height: 'calc(100% - 40px)' }}>
          {open && !loaded && <LoaderBg />}
          {open && loaded && <au-docs ref={ref}></au-docs>}
        </div>
      </div>
    </>
  )
}

export default HelpDocsIcon
