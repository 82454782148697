import { Equipment } from 'au-nsi/equipment'
import { randomColor } from './nsi.utils'

const configurations = {
  'C.37.118': {
    cfg_frame_version: 2,
    connection_type: 'TCP_ONLY',
    target_host: '',
    target_port: 0,
    idcode: 0,
    reconnect_interval: 1000,
    reconnect_attempts: 3,
    connection_timeout: 500,
    drop_frame_with_old_timestamp: true,
    drop_timeout: 100,
  },
  'modbustcp-server': {
    tcp_port: 0,
    tcp_timeout: 0,
    device_id: 0,
    byte_order: 'be',
  },
  'modbustcp-client': {
    host: '',
    port: 80,
    timeout: 0,
    unit_identifier: 0,
    byte_order: 'be',
    request_interval: 1000,
    reconnect_interval: 1000,
    reconnect_attempts: 3,
  },
  IEC104: {
    target_host: '',
    target_port: 0,
    reconnect_interval: 1000,
    reconnect_attempts: 3,
    connection_timeout: 0,
    drop_frame_with_old_timestamp: true,
    drop_timeout: 100,
  },
  MLP14: {
    target_host: '',
    target_port: 0,
    reconnect_interval: 1000,
    reconnect_attempts: 3,
    connection_timeout: 0,
  },
  'db-client': {
    driver: 'SQL Server',
    target_host: '',
    target_port: 1433,
    username: '',
    password: '',
    database: '',
    request_interval: 1000,
    table: '',
    ts_column: 'ts',
    ts_format: 'timestamp',
    ts_range: 0,
  },
  OPCDA: {
    target_host: '',
    target_port: 8080,
    prog_id: '',
    nodes: ['localhost'],
    polling_period_ms: 1000,
    tags: [],
  },
  'MQTT-Ob': {
    target_host: '',
    target_port: 1883,
    username: '',
    password: '',
    asset_id: '',
    timeout: 5000,
  },
}

const data_quality_options = {
  tolerances: {},
  computable_parameters: [],
}

const rated_values = {
  active_power: 220_000_000,
  power_factor: 0.85,
  stator_voltage: +(15.75 / Math.sqrt(3)).toFixed(3) * 1e3,
  stator_current: 9500,
  excitation_voltage: 0,
  excitation_current: 0,
}

export const createDevice = (options: Partial<Equipment>) => {
  const configuration = { ...configurations[options.protocol], publish_subject: 'raw.proto' }

  return {
    protocol_configuration: {},
    parameters_mapping: [],
    configuration,
    data_quality_options,
    rated_values,
    ...options,
    color: randomColor(),
    id: '',
  }
}
