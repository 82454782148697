import ReactDOM from 'react-dom'
import styles from './placepicker.module.css'
import PlaceAutocomplete from './PlaceAutocomplete'
import Loader from '../../Utils/Loader'
import { useState } from 'react'
import { IPlace } from 'au-nsi/shared'
import GoogleMap from '../../GoogleMap'

interface IProps {
  place: IPlace
  onChange: (place: IPlace) => void
  onClose: () => void
}

const PlaceSelectModal = (props: IProps) => {
  const [mapRendered, setMapRendered] = useState(false)

  const handleShadowClick = (e) => {
    if (e.target.className === 'app-modal__shadow') props.onClose()
  }

  return ReactDOM.createPortal(
    <div className="app-modal__shadow" onClick={handleShadowClick}>
      <div className={styles.placePickerModal}>
        <PlaceAutocomplete
          value={props.place?.name || ''}
          onChange={props.onChange}
          wrapperClassName={styles.mapSearchBlock}
        />
        {!mapRendered && (
          <div className={styles.mapLoader}>
            <Loader />
          </div>
        )}
        <GoogleMap
          className={styles.mapContainer}
          width={'70vw'}
          height={'70vh'}
          onChange={props.onChange}
          place={props.place}
          onBoundsChange={(b) => b && setMapRendered(true)}
        />
      </div>
    </div>,
    document.getElementsByTagName('body')[0]
  )
}

export default PlaceSelectModal
