import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import Loader from '../../../shared/Utils/Loader'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import NumberInput from '../../../shared/Inputs/NumberInput'
import http, { handleHttpError } from '../../../utils/http'
import * as actions from '../nsi.actions'
import { Equipment } from 'au-nsi/equipment'

/**
 * Модальное окно для указания количества копий устройства которые необходимо создать
 */
const ReplicationModal = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [replicas, setReplicas] = React.useState(1)
  const [isLoading, setLoading] = React.useState(false)

  // при подтверждении пользователем отправить запрос на сервер для создания указанного кол-ва копий
  const handleSave = () => {
    setLoading(true)

    http
      .post(`/nsi/v1/equipment/${props.device.id}/replicate`, { replicas })
      .then((r) => r.data.forEach((item) => dispatch(actions.equipmentCreated(item))))
      .catch(handleHttpError)
      .finally(() => props.onClose())
  }

  const loader = (
    <div style={{ textAlign: 'center' }}>
      <Loader />
    </div>
  )

  return (
    <Modal onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: 'nsi.replicate' }, { name: props.device.name })}</h2>

      <div className="system__label">{intl.formatMessage({ id: 'nsi.replicate.count' })}</div>
      <div className="system__input-wrapper">
        <NumberInput
          type="number"
          name=""
          value={replicas}
          onChange={setReplicas}
          min={1}
          max={100}
          integer={true}
          fullWidth={true}
        />
      </div>

      {isLoading ? loader : <ModalFooter onCancel={props.onClose} onSave={handleSave} />}
    </Modal>
  )
}

interface Props {
  device: Equipment
  onClose: () => void
}

export default ReplicationModal
