import { PlaceManager } from './placeManager.interface'
import googleMapsApi, { IAutocompleteResponse } from './googleMaps.api'
import { IPlace } from 'au-nsi/shared'

const placeService: PlaceManager<IPlace, IAutocompleteResponse> = {
  convertCoordinatesPlace: async (coords, locale: string) => {
    const place: IPlace = await googleMapsApi.requestReverseGeocode(coords.lat, coords.lng, locale).then(
      (r) =>
        r && {
          name: r.formatted_address,
          lng: r.geometry.location.lng,
          lat: r.geometry.location.lat,
          place_id: r.place_id,
          postcode: r.address_components.find((c) => c.types.includes('postal_code'))?.long_name,
          houseNumber: r.address_components.find((c) => c.types.includes('street_number'))?.long_name,
          city: r.address_components.find(
            (c) =>
              c.types.includes('administrative_area_level_2') ||
              (c.types.includes('locality') && c.types.includes('political'))
          )?.short_name,
          street: r.address_components.find((c) => c.types.includes('route'))?.long_name,
        }
    )

    return place
  },
  getAddressPredictions: async (addressName: string, locale: string) => {
    return googleMapsApi.requestAutocomplete(addressName, locale)
  },
  isPlaceValid: (p) => {
    return Boolean(p && p.lng && p.lat && p.name)
  },
  isCoordinatesEqual: (c1, c2) => {
    if ('google' in window) {
      const pt1 = new google.maps.LatLng(c1.lat, c1.lng)
      const pt2 = new google.maps.LatLng(c2.lat, c2.lng)

      return pt1.equals(pt2)
    }

    return Math.abs(c1.lat - c2.lat) < 1e-6 && Math.abs(c1.lng - c2.lng) < 1e-6
  },
  isIPlacesEqual: (p1, p2) => {
    if (!placeService.isPlaceValid(p1) || !placeService.isPlaceValid(p2)) return false

    if (p1.name === p2.name) return true
    if (p1.place_id && p1.place_id === p2.place_id) return true
    if (p1.street && p1.houseNumber && p1.street === p2.street && p1.houseNumber === p2.houseNumber) {
      if (p1.postcode) return p1.postcode === p2.postcode
      if (p1.city) return p1.city === p2.city
    }

    return placeService.isCoordinatesEqual(p1, p2)
  },
  getPlaceInfo: async (placeId: IPlace['place_id'], locale: string) => {
    const r = await googleMapsApi.requestPlaceDetails(placeId, locale)

    return {
      name: r.formatted_address,
      lng: r.geometry.location.lng,
      lat: r.geometry.location.lat,
      place_id: r.place_id,
      postcode: r.address_components.find((c) => c.types.includes('postal_code'))?.long_name,
      houseNumber: r.address_components.find((c) => c.types.includes('street_number'))?.long_name,
      street: r.address_components.find((c) => c.types.includes('route'))?.long_name,
      city: r.address_components.find(
        (c) =>
          c.types.includes('administrative_area_level_2') ||
          (c.types.includes('locality') && c.types.includes('political'))
      )?.short_name,
    }
  },
  factoryPlace: () => ({ name: '', place_id: '', lng: 0, lat: 0, street: '', houseNumber: '', postcode: '', city: '' }),
}

export default placeService
