import { ITag } from 'au-nsi/shared'
import http from 'utils/http'

const path = '/nsi/v1/tags/'

export const createTag = (tag: Omit<ITag, 'id'>) => {
  return http.post<ITag>(path, tag).then((r) => r.data)
}

export const updateTag = (id: number, updates: ITag) => {
  return http.patch<ITag>(path + id, updates).then((r) => r.data)
}

export const deleteTag = (id: number) => {
  return http.delete(path + id)
}
