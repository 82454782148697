import React from 'react'

/**
 * Обработчики для перетаскивания элементов списка
 */
const useDnD = (options: IDnDOptions) => {
  // https://stackoverflow.com/a/21002544
  const ref = React.useRef(0)

  const applyStyles = () => {
    for (const [key, value] of Object.entries(options.style)) {
      options.ref.current.style[key] = value
    }
  }

  const resetStyles = () => {
    for (const key of Object.keys(options.style)) {
      options.ref.current.style[key] = null
    }
  }

  const onDragStart = (e) => {
    e.dataTransfer.setData('text/plain', options.prefix + options.id)
    e.dataTransfer.dropEffect = 'move'
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragEnter = () => {
    ref.current += 1
    if (ref.current === 1) applyStyles()
  }

  const onDragLeave = () => {
    ref.current -= 1
    if (ref.current === 0) resetStyles()
  }

  const onDragEnd = () => resetStyles()

  const onDrop = (e) => {
    e.preventDefault()
    resetStyles()

    const data: string = e.dataTransfer.getData('text/plain')

    if (data.startsWith(options.prefix)) {
      options.onDrop(data.slice(options.prefix.length))
    }
  }

  return { onDragStart, onDragOver, onDragEnter, onDragLeave, onDragEnd, onDrop }
}

interface IDnDOptions {
  // id текущего элемента
  id: string | number
  // вызывается с id элемента который перетащили на место текущего
  onDrop: (id: string) => void
  // для разделения разных коллекций (чтобы например не было возможности
  // перетащить устройство в изображения)
  prefix: string
  // контейнер текущего элемента
  ref: React.RefObject<HTMLElement>
  // стиль который будет применен при drag hover
  style: React.CSSProperties
}

export default useDnD
