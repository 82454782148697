import { generateActions } from '../Collections/collection.actions'
import { Formula } from './formulas.interfaces'
import ast2str from './constructor/ast2str'
import { extractArguments } from './constructor/ast.utils'

const actions = generateActions<Formula>('formulas', '/nsi/formulas/', { skipDiffer: true })

// компиляция формулы в js код перед сохранением
const preformat = (formula: Formula): Formula => {
  const result = { ...formula }

  if (formula.type === 'formula') {
    result.meta = { arguments: [] }
    extractArguments(formula.ast, formula.is_global, result.meta.arguments)
    result.src = 'return ' + ast2str(formula.ast, formula.is_global, result.meta.arguments)
  }

  return result
}

const updateItem = (formula: Formula) => actions.updateItem(preformat(formula))
const createItem = (formula: Formula) => actions.createItem(preformat(formula))

const formulasActions = { ...actions, updateItem, createItem }

export default formulasActions
