import { useIntl } from 'react-intl'
import styles from './saveAsTemplate.module.css'
import { useMemo, useRef, useState } from 'react'
import usePopup from '../../../../../hooks/usePopup'
import TextInput from '../../../../../shared/Inputs/TextInput'
import { AnyCatalogWithInstances } from '../../../catalogs.types'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllCatalogs } from '../../../catalogs.selectors'
import { catalogsActions } from '../../../catalogs.actions'
import { ReactComponent as SaveIcon } from 'icons/save.svg'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import Loader from '../../../../../shared/Utils/Loader'
import { selectAccessRights } from '../../../../App/app.selectors'
import { ICatalogItem } from 'au-nsi/catalogs'
import { showError } from '../../../../../utils/notifications'

interface IProps {
  catalogId: AnyCatalogWithInstances['id']
  properties: ICatalogItem['properties']
}

/**
 * Компонент сохранения значения справочника, как шаблона
 *
 * Выводит надпись "Сохранить как шаблон", при клике на которую пользователя просят ввести имя шаблона, после чего отправляет запрос в nsi
 * на создание соответветсвующего шаблона
 */
const SaveAsTemplateInscription = (props: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const inscriptionRef = useRef<HTMLDivElement>(null)
  const { open, setOpen } = usePopup(inscriptionRef)
  const { wait, loading } = useHttpLoader()

  const rights = useSelector(selectAccessRights)
  const catalogs = useSelector(selectAllCatalogs)
  const templateCatalog = useMemo(() => catalogs.find((c) => c.id === props.catalogId), [catalogs, props.catalogId])

  const [name, setName] = useState('')

  const handleSave = () => {
    if (!name) return showError('nsi.user.errors.empty_name')

    const template: ICatalogItem = {
      id: undefined,
      name,
      type: 'template',
      catalog_id: templateCatalog.id,
      properties: props.properties,
      access: undefined,
    }

    wait(dispatch(catalogsActions.createInstance(template)) as any, () => {
      setOpen(false)
    })
  }

  const renderSaveBtn = () => {
    if (loading) return <Loader style={{ padding: 0 }} />

    return <SaveIcon onClick={handleSave} />
  }

  const renderNameInput = () => {
    if (!open) return

    const placeholder = intl.formatMessage({ id: 'catalogs.template.enter_name' })
    return (
      <div className={styles.templateNameInput}>
        <TextInput
          name={'name'}
          value={name}
          onChange={setName}
          required={false}
          placeholder={placeholder}
          autofocus={true}
        />
        {renderSaveBtn()}
      </div>
    )
  }

  if (!rights['catalogs:update_templates']) return null

  return (
    <div className={styles.saveAsTemplate} ref={inscriptionRef} onClick={() => setOpen(true)}>
      <span>{intl.formatMessage({ id: 'catalogs.save_as_template' })}</span>
      {renderNameInput()}
    </div>
  )
}

export default SaveAsTemplateInscription
