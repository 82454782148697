import { AnyCatalogWithInstances } from './catalogs.types'
import { IntlShape } from 'react-intl'
import { nanoid } from '@reduxjs/toolkit'
import { isJsonEqual } from '../../utils/misc'
import { ICatalogItem, IPropertyDescription } from 'au-nsi/catalogs'
import { SelectOption } from '@alterouniversal/au-react-components'

export const createCatalog = <T extends AnyCatalogWithInstances>(type: T['type']): T => {
  return {
    id: -1,
    name: '',
    type,
    schema: [],
    instances: [],
    schema_tree: [],
    templates: [],
  } as T
}

export const createPropertySchema = (type: IPropertyDescription['type'] = 'string'): IPropertyDescription => {
  return {
    id: nanoid(10),
    name: '',
    type,
    default_value: '',
    required: true,
    children: type === 'group' ? [] : undefined,
  } as IPropertyDescription
}

export const createCatalogItem = (
  catalog: AnyCatalogWithInstances,
  properties: Record<string, any> = {}
): ICatalogItem => {
  const values =
    Object.keys(properties).length > 0
      ? properties
      : catalog.schema.reduce(
          (properties, schema) => Object.assign(properties, { [schema.id]: schema.default_value }),
          {}
        )

  return {
    id: -1,
    name: '',
    type: 'item',
    catalog_id: catalog.id,
    properties: values,
  } as ICatalogItem
}

export const catalogItemToObject = (
  instance: { catalog_id: number; properties: Record<string, any>; [key: string]: any },
  catalogs: AnyCatalogWithInstances[],
  rendered: Set<number> = new Set<number>()
) => {
  const catalog = catalogs.find((c) => c.id === instance.catalog_id)

  return catalog.schema.reduce((acc, schema) => {
    if (schema.type !== 'object' && schema.type !== 'ref')
      return Object.assign(acc, { [schema.name]: instance.properties[schema.id] })

    // Пользовательский тип
    if (schema.type === 'object') {
      return Object.assign(acc, {
        [schema.name]: getCatalogById(catalogs, schema.ref_catalog_id).instances.find(
          (inst) => inst.id === instance.properties[schema.id]
        )?.name,
      })
    }

    // Ссылка
    const referenceInstance = catalogs
      .find((c) => c.id === schema.ref_catalog_id)
      ?.instances.find((inst) => inst.id === instance.properties[schema.id])
    if (!referenceInstance || rendered.has(referenceInstance.id)) return acc

    return Object.assign(acc, {
      [schema.name + ': ' + referenceInstance.name]: catalogItemToObject(
        referenceInstance,
        catalogs,
        rendered.add(referenceInstance.id)
      ),
    })
  }, {})
}

const types: SelectOption[] = [
  { value: 'string', label: 'catalogs.string' },
  { value: 'number', label: 'catalogs.number' },
  { value: 'ref', label: 'catalogs.ref' },
  { value: 'object', label: 'catalogs.type' },
  { value: 'date', label: 'catalogs.date' },
  { value: 'boolean', label: 'catalogs.boolean' },
  { value: 'text', label: 'catalogs.text' },
  { value: 'organization_ref', label: 'catalogs.organization_ref' },
]

export const typeOptions = (intl): SelectOption[] => {
  return types.map((option) => {
    return { ...option, label: intl.formatMessage({ id: option.label }) }
  })
}

const booleanOptions: SelectOption[] = [
  { value: true, label: 'true' },
  { value: false, label: 'false' },
]

export const getBooleanOptions = (intl: IntlShape) =>
  booleanOptions.map((o) => ({ ...o, label: intl.formatMessage({ id: 'catalogs.' + o.label }) }))

export const getCatalogById = (catalogs: AnyCatalogWithInstances[], id: number) => {
  return catalogs.find((catalog) => catalog.id === id)
}

export const getCatalogByInstanceId = (catalogs: AnyCatalogWithInstances[], id: ICatalogItem['id']) => {
  for (const catalog of catalogs)
    for (const instance of catalog.instances) if (instance.id === id) return { instance, catalog }

  return { catalog: undefined, instance: undefined }
}

export const validateInstanceValue = (value: any, schema: IPropertyDescription) => {
  if (!schema.required && !value) return value

  if (schema.type === 'number' && isNaN(value)) return null
  if (schema.type === 'string' && typeof value !== 'string') return null
  if (schema.type === 'ref' && isNaN(value)) return null
  if (schema.type === 'organization_ref' && isNaN(value)) return null
  if (schema.type === 'object' && isNaN(value)) return null
  if (schema.type === 'date' && !Date.parse(value?.toString()?.split('.')?.reverse()?.join('-'))) {
    return null
  }
  if (schema.type === 'boolean' && typeof value !== 'boolean') return null

  return value
}

export const getSchemaDefaultValuesMap = (schema: IPropertyDescription[]) => {
  return schema.reduce((acc, val) => ({ ...acc, [val.id]: val.default_value }), {})
}

export const isAnySchemaRowRemovedOrUpdated = (
  source: IPropertyDescription[],
  current: IPropertyDescription[]
): boolean => {
  const fillMap = (map: Map<IPropertyDescription['id'], IPropertyDescription>, rows: IPropertyDescription[]) => {
    for (const r of rows) {
      if (r.type === 'group') fillMap(map, r.children)
      map.set(r.id, r)
    }
  }

  const sourceSchemaMap = new Map<IPropertyDescription['id'], IPropertyDescription>()
  const currentSchemaMap = new Map<IPropertyDescription['id'], IPropertyDescription>()

  fillMap(sourceSchemaMap, source)
  fillMap(currentSchemaMap, current)

  for (const [sourceId, sourceProperty] of sourceSchemaMap.entries()) {
    if (!currentSchemaMap.has(sourceId)) return true
    if (!isJsonEqual(sourceProperty, currentSchemaMap.get(sourceId))) return true
  }

  return false
}

/**
 * Распаристь строку вида "catalogs/<name>/<catalogId>", либо "catalogs/<name>/<catalogId>/instance/<instanceId>"
 * */
export const parseCatalogsUrl = (path: string) => {
  const split = path.split('/')

  const id1 = +split[split.length - 3]
  const id2 = +split[split.length - 1]

  if (!id1 && id2) {
    return {
      type: 'catalog',
      selectedCatalogId: id2,
    }
  }

  if (id1 && id2) {
    return {
      type: 'instance',
      selectedCatalogId: id1,
      selectedItemId: id2,
    }
  }

  return null
}
