import GroupsLoader from '../../../../../MapTopology/Groups/components/GroupsLoader'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import useGroup from '../../../../../MapTopology/Groups/hooks/useGroup'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import GroupRequestFormLayout from './GroupRequestFormLayout'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import { IGroup } from 'au-nsi-elevator/groups'
import { GroupRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const GroupRequestForm = ({ editing }: IProps) => {
  const [request, setRequest] = useRecoilState<GroupRequest>(selectedDraftRequestState as any)
  const prevGroup = useGroup(request.request_body?.id || extractIntIdFromUrl(request.url))

  const handleChange = (value: any, key: keyof IGroup) => {
    setRequest({ ...request, request_body: { ...request.request_body, [key]: value } })
  }

  const renderForm = () => {
    const requestBodyForm = (
      <GroupRequestFormLayout group={request.request_body} editing={editing} onChange={handleChange} />
    )
    const prevGroupForm = <GroupRequestFormLayout group={prevGroup} editing={false} onChange={null} />

    if (request.method === 'POST') return requestBodyForm
    if (request.method === 'DELETE') return prevGroupForm
    if (request.method === 'PATCH') {
      const tabs = [
        { id: 'prev', render: () => prevGroupForm },
        { id: 'potential', render: () => requestBodyForm },
      ]
      return <Tabs tabs={tabs} />
    }

    return null
  }

  return <GroupsLoader>{renderForm()}</GroupsLoader>
}

export default GroupRequestForm
