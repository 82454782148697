import { atom } from 'recoil'
import { IUserNotification } from 'au-nsi/notifications'

export interface UserNotificationsState {
  notifications: IUserNotification[]
  secondPage: string
  nextPage: string
  onlyUnread: boolean
  unreadCount: number
}

export const userNotificationsOnlyReadKey = 'userNotificationsState.onlyUnread'
export const userNotificationsState = atom<UserNotificationsState>({
  key: 'userNotificationsState',
  default: {
    notifications: [],
    secondPage: null,
    nextPage: null,
    onlyUnread: !!localStorage.getItem(userNotificationsOnlyReadKey),
    unreadCount: 0,
  },
})

/** Состояние модального окна с историей действий, открывающегося при клике на уведомление */
export const changelogNotificationModalState = atom<{ resource: string; resource_id: string }>({
  key: 'changelogNotificationModalState',
  default: null,
})
