import { ReactComponent as Icon } from 'icons/download.svg'
import { CSSProperties, useState } from 'react'
import { useIntl } from 'react-intl'
import css from './export.module.css'
import JournalsExportModal from './JournalsExportModal'

interface Props {
  url: string
  t0: number
  t1: number
  title?: string
  style?: CSSProperties
}

/**
 * Кнопка экспорта журналов и сигнальных ситуаций. При клике открывает модальное окно для
 * выбора временного интервала и формата экспорта. Для экспорта данных будет отправлен GET
 * запрос на url переданный в пропсах.
 */
const JournalsExport = ({ style, url, title, t0, t1 }: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  return (
    <>
      <span className={css.button} style={style} title={title || intl.formatMessage({ id: 'shared.export' })}>
        <Icon width="20px" height="20px" onClick={() => setOpen(true)} />
      </span>
      {open && <JournalsExportModal onClose={() => setOpen(false)} url={url} title={title} t0={t0} t1={t1} />}
    </>
  )
}

export default JournalsExport
