import ColorPalette from './ColorPalete/ColorPalette'
import ColorList from './ColorList'
import { colord } from 'colord'
import { ColorFormat, HEX, HSL, RGB } from '../colorpicker.types'
import { ColorFormatPicker } from './ColorFormatPicker'
import { IColorState } from '../ColorPicker'

interface IProps {
  colorState: IColorState
  onChange: (v: HSL, resource: IColorState['resource']) => void
}

const colorFormats: [ColorFormat<RGB>, ColorFormat<HSL>, ColorFormat<HEX>] = [
  {
    codes: ['R', 'G', 'B'],
    validation: (color: RGB) => colord(color).isValid(),
    toHsl: (color: RGB) => colord(color).toHsl(),
    fromHsl: (color: HSL) => colord(color).toRgb(),
    format: (color: RGB) =>
      Object.keys(color).reduce((acc, key) => ({ ...acc, [key]: Math.min(Math.max(color[key], 0), 255) }), {}) as RGB,
  },
  {
    codes: ['H', 'S', 'L'],
    validation: (color: HSL) =>
      color.h >= 0 && color.h <= 360 && color.l >= 0 && color.l <= 100 && color.s >= 0 && color.s <= 100,
    toHsl: (color: HSL) => colord(color).toHsl(),
    fromHsl: (color: HSL) => color,
    format: ({ h, s, l }: HSL) => ({
      h: Math.max(Math.min(360, h), 0),
      s: Math.max(Math.min(s, 100), 0),
      l: Math.max(Math.min(l, 100), 0),
    }),
  },
  {
    codes: ['HEX'],
    validation: (color: HEX) => colord(color.hex).isValid() && color.hex.length === 7,
    toHsl: (color: HEX) => colord(color.hex).toHsl(),
    fromHsl: (color: HSL) => {
      return { hex: colord(color).toHex() }
    },
    format: (color: HEX) => ({ hex: '#' + color.hex.slice(1, 6) }),
  },
]

export const ColorSelector = ({ colorState, onChange }: IProps) => {
  return (
    <div style={{ width: '360px' }}>
      <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
        <ColorList colorState={colorState} onChange={onChange} />
        <hr style={{ height: '2px', width: '100%', background: 'var(--bg-600)', border: '0' }} />
        <ColorPalette
          style={{ marginBottom: '30px' }}
          width={340}
          height={130}
          colorState={colorState}
          onChange={onChange}
        />
        <ColorFormatPicker colorState={colorState} onChange={onChange} formats={colorFormats} />
      </div>
    </div>
  )
}
