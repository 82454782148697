import produce from 'immer'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/store'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { selectCommandsMap, selectCommandTypesMap } from '../commands.selectors'
import { CommandOptions, isCompleteCommand, sendCommands } from '../commands.utils'
import VariableArgsForm from './VariableArgsForm'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'

interface Props {
  commands: CommandOptions[]
  onClose: () => void
}

/**
 * Модальное окно для ввода аргументов перед отправкой команд устройству
 */
const CommandsArgumentsModal = (props: Props) => {
  const intl = useIntl()
  const devicesMap = useAppSelector(selectEquipmentMap)
  const commandsMap = useAppSelector(selectCommandsMap)
  const commandTypesMap = useAppSelector(selectCommandTypesMap)

  const [commands, setCommands] = useState([...props.commands])

  const handleChange = (value: any, key: string, index: number) => {
    setCommands((prev) =>
      produce(prev, (draft) => {
        draft[index].args[key] = value
      })
    )
  }

  // если введены все необходимые поля то отправляем команды и закрываем модальное окно
  const handleSave = () => {
    const isComplete = commands.every((command) => isCompleteCommand(command, commandsMap, commandTypesMap))

    if (isComplete) {
      sendCommands(commands)
      props.onClose()
    }
  }

  const rows = commands.map((c, i) => {
    const command = commandsMap.get(c.command_id)
    if (!command) return null

    const commandType = commandTypesMap.get(command.type)
    if (!commandType) return null

    const device = devicesMap.get(c.target)
    const target = device ? device.name : c.target

    return (
      <div key={i} style={{ marginBottom: '2em' }}>
        <div className="section-title" style={{ margin: '0.5em 0' }}>
          {command.name} ({target})
        </div>
        <div className="system__grid">
          <VariableArgsForm
            args={c.args}
            args_schema={commandType.args_schema}
            editing={true}
            required={true}
            onChange={(value, key) => handleChange(value, key, i)}
          />
        </div>
      </div>
    )
  })

  const saveMessage = intl.formatMessage({ id: 'dashboards.button.commands.send' })

  return (
    <Modal onClose={props.onClose}>
      <div>
        {rows}
        <ModalFooter onCancel={props.onClose} onSave={handleSave} saveMessage={saveMessage} />
      </div>
    </Modal>
  )
}

export default CommandsArgumentsModal
