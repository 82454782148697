import { useIntl } from 'react-intl'
import GanttService from '../../../services/gantt/gantt.service'
import IncidentsListBody from './IncidentsListBody'

const IncidentsList = (props: Props) => {
  const intl = useIntl()

  return (
    <div className="alerts-list__container" id="alerts-list__container">
      <div className="alerts-list__row is-header">
        <div className="alerts-list__col col_situation">{intl.formatMessage({ id: 'alerts.situation' })}</div>
        <div className="alerts-list__col col_objects">{intl.formatMessage({ id: 'alerts.objects' })}</div>
        <div className="alerts-list__col col_values">{intl.formatMessage({ id: 'alerts.values' })}</div>
        <div className="alerts-list__col col_ack">{intl.formatMessage({ id: 'alerts.ack' })}</div>
      </div>
      <IncidentsListBody {...props} />
    </div>
  )
}

interface Props {
  search: string
  service: GanttService
  onOpen: (id: number) => void
}

export default IncidentsList
