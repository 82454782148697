import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import InputRow from '../../../../shared/Inputs/InputRow'
import { useIntl } from 'react-intl'
import { AnyCatalogWithInstances } from '../../../Catalogs/catalogs.types'
import styles from '../../maptopology.module.css'
import { selectEnabledModules } from '../../../App/app.selectors'
import { protocolOptions } from '../../../Nsi/Modals/SearchModal/searchModal.utils'
import { equipmentCreated } from '../../../Nsi/nsi.actions'
import { ReduxState } from '../../../../redux/store.types'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import { CurrentUserState } from '../../../App/user.reducers'
import { generateAccess } from '../../../../shared/Access/access.utils'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Loader from '../../../../shared/Utils/Loader'
import http, { handleHttpError, handleHttpResponse } from '../../../../utils/http'
import { createDevice } from '../../../Nsi/device.factory'
import { selectPassports } from '../../../Catalogs/catalogs.selectors'
import useInstanceFormCallback from '../../../Catalogs/components/InstanceForm/useInstanceFormCallback'
import EquipmentPassportForm from '../../../Nsi/EquipmentForm/EquipmentPassport/EquipmentPassportForm'
import SelectTemplateScreen from '../../DeviceScreen/DevicePassport/SelectTemplate/SelectTemplateScreen'
import { applyEquipmentTemplate } from '../../../Nsi/EquipmentForm/EquipmentPassport/EquipmentPassportPanel'
import PrevButton from '../../../../shared/Utils/PrevButton'
import { validateItemValues } from '../../../Catalogs/catalogs.validators'
import AECreateDeviceNameInput from './AECreateDeviceNameInput'
import AEControllerIdInput from '../../../../shared/AE/ControllerIdInput/AEControllerIdInput'
import { ICatalogItem } from 'au-nsi/catalogs'
import { IPlace } from 'au-nsi/shared'
import { Equipment, LP001Equipment } from 'au-nsi/equipment'
import { showError } from '../../../../utils/notifications'
import { Select } from '@alterouniversal/au-react-components'

interface IProps {
  onClose: () => void
  place: IPlace
}

/**
  Форма создания устройства с указанным местом.
  Заполняется имя, протокол (если в системе доступен только один протокол, он автоматически выбирается)
  Выбирается схема паспорта и заполняются соотвествующие значения.
  Создаваемые тут устройства должны быть ресиверами
  После создания добавляются в конец рута в топологии
*/
const CreateDeviceForm = ({ onClose, place }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()
  const user = useSelector((state: ReduxState) => state.user)
  const translations = useSelector((state: ReduxState) => state.language.translations)
  const passports = useSelector(selectPassports)
  const modules = useSelector(selectEnabledModules)

  // LP001-Server можно создавать только через топологию
  const protocolsOptions = useMemo(
    () => protocolOptions(intl).filter((p) => modules.has(p.module) && p.value !== 'LP001-Server'),
    [modules]
  )

  const passportOptions = useMemo(
    () => passports.map((catalog) => ({ label: catalog.name, value: catalog.id })),
    [passports]
  )

  const [isTemplateImport, setTemplateImport] = useState(false)
  const [device, setDevice] = useState<Partial<Equipment>>(generateEquipment(place, user))
  const protocol = device.protocol || ''

  const handlePropertiesChange = useInstanceFormCallback({
    setter: setDevice,
    propertiesKey: 'passport_values',
    deps: [],
  })

  // В случае illegal magic обновляем права
  useEffect(() => setDevice({ ...device, access: generateAccess(user) }), [user.organization_id])

  // Для LP001, в силу специфики протокола, выставляем частоту 1гц
  useEffect(() => {
    if (protocol.startsWith('LP001')) setDevice({ ...device, data_rate: 1 })
    else setDevice({ ...device, data_rate: 50 })
  }, [protocol])

  // Если активен всего один протокол, выбираем его
  useEffect(() => {
    if (protocolsOptions.length === 1) {
      setDevice({ ...device, protocol: protocolsOptions[0].value as any })
    }
  }, [protocolsOptions])

  // При выборе паспорта заполняем поля дефолтными значениями схемы
  const handlePassportSelect = (selected: AnyCatalogWithInstances['id']) => {
    const passport = passports.find((p) => p.id === selected)
    const passport_values = {}

    for (const property of passport.schema) {
      const id = property.id
      if (property.autofill_mode === 'address') passport_values[id] = device.address.name
      else passport_values[id] = property.default_value
    }

    setDevice({
      ...device,
      passport_catalog_id: selected,
      passport_values,
    })
  }

  const handleChange = (value: any, name: string) => {
    setDevice({ ...device, [name]: value })
  }

  const handleSelectTemplate = (template: ICatalogItem) => {
    setDevice(applyEquipmentTemplate(device as Equipment, template, passports))
    setTemplateImport(false)
  }

  const selectedPassport = device.passport_catalog_id && passports.find((p) => p.id === device.passport_catalog_id)

  const handleSave = () => {
    if (!validateEquipment(device as Equipment, selectedPassport)) {
      return showError('common.validation_error')
    }

    const req = http
      .post('/nsi/v1/equipment/', createDevice({ ...device, shortname: device.name.slice(0, 9) }))
      .then(handleHttpResponse)
      .catch(handleHttpError)

    wait(req, (data) => {
      if (data) dispatch(equipmentCreated(data))

      // Не закрываем в случае обработанной ошибки, чтобы не терялись пользовательские данные
      if (data !== undefined) onClose()
    })
  }

  const renderPassport = () => {
    if (!selectedPassport) return null

    return (
      <>
        <h2>{intl.formatMessage({ id: 'passport' })}</h2>
        <EquipmentPassportForm
          passport_values={device.passport_values}
          passport_schema={selectedPassport.schema_tree}
          onChange={handlePropertiesChange}
          editing={true}
        />
      </>
    )
  }

  // В рамках проекта AlteroElevator необходимо вывводить id контроллера для создания устройства
  const renderControllerID = () => {
    if (protocol !== 'LP001') return null
    const { configuration } = device as LP001Equipment

    return (
      <InputRow label={translations['equipment.configuration.controller_id']}>
        <AEControllerIdInput
          value={configuration.controller_id}
          name={''}
          onChange={(controller_id) =>
            setDevice({ ...device, configuration: { ...configuration, controller_id } as any })
          }
        />
      </InputRow>
    )
  }

  const renderDataRate = () => {
    // Для LP001, в силу специфики протокола, выставляем частоту 1гц
    if (protocol.startsWith('LP001')) return null

    return (
      <InputRow label={intl.formatMessage({ id: 'nsi.region_data_rate' })}>
        <NumberInput value={device.data_rate} name={'data_rate'} onChange={handleChange} fullWidth={true} />
      </InputRow>
    )
  }

  const renderButtons = () => {
    if (loading) {
      return (
        <div className={styles.createDeviceFooter}>
          <Loader />
        </div>
      )
    }

    return (
      <div className={styles.createDeviceFooter}>
        <button onClick={() => setTemplateImport(true)} className="nsi-button secondary">
          {intl.formatMessage({ id: 'catalogs.template.import' })}
        </button>
        <button onClick={handleSave} className="nsi-button default">
          {intl.formatMessage({ id: 'common.save' })}
        </button>
      </div>
    )
  }

  if (isTemplateImport) {
    return (
      <SelectTemplateScreen
        device={device as Equipment}
        onSelect={handleSelectTemplate}
        onCancel={() => setTemplateImport(false)}
      />
    )
  }

  return (
    <>
      {!isTemplateImport && (
        <div className={styles.deviceScreenPageHeader}>
          <h2>{intl.formatMessage({ id: 'system.maps.create_equipment' })}</h2>
          <PrevButton onCLick={onClose} style={{ marginBottom: '1em' }} />
        </div>
      )}
      <div className="system__grid">
        {protocolsOptions.length > 1 && (
          <InputRow label={intl.formatMessage({ id: 'system.modules.protocol' })}>
            <Select
              required={true}
              value={device.protocol}
              name={'protocol'}
              onChange={handleChange}
              options={protocolsOptions}
            />
          </InputRow>
        )}
        <InputRow label={intl.formatMessage({ id: 'common.unique_name' })}>
          <AECreateDeviceNameInput
            device={device as Equipment}
            value={device.name}
            name={'name'}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'catalogs.passport_schema' })}>
          <Select
            required={true}
            value={device.passport_catalog_id}
            name={'schema'}
            onChange={handlePassportSelect}
            options={passportOptions}
          />
        </InputRow>
        {renderControllerID()}
        {renderDataRate()}
      </div>
      {renderPassport()}
      {renderButtons()}
    </>
  )
}

const validateEquipment = (device: Equipment, passport: AnyCatalogWithInstances) => {
  // Для LP001 id контроллера обязательно к заполнению
  if (device.protocol === 'LP001' && !device.configuration.controller_id) {
    return false
  }

  return (
    passport &&
    validateItemValues(passport.schema, device.passport_values) &&
    device.name &&
    device.protocol &&
    device.data_rate
  )
}

const generateEquipment = (place: IPlace, user: CurrentUserState): Partial<Equipment> => ({
  address: place,
  name: ' ',
  passport_values: {},
  protocol: undefined,
  region_id: 1,
  path: '',
  type: 'receiver',
  passport_catalog_id: undefined,
  access: generateAccess(user),
  configuration: {},
})

export default CreateDeviceForm
