import { IChartThreshold } from 'au-nsi/dashboards'
import React from 'react'
import { IntlShape } from 'react-intl'
import RemoveButton from '../../Forms/RemoveButton'
import ColorPicker from '../../Inputs/Colorpicker/ColorPicker'
import Dropdown from '../../Inputs/Dropdown'
import NumberInput from '../../Inputs/NumberInput'

/**
 * Строка таблицы настроек линий предельных значений
 */
const ThresholdsTableRow = (props: Props) => {
  const { row, index } = props

  const lineOptions = [
    {
      value: 'solid',
      title: props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.thresholds.line.solid' }),
    },
    {
      value: 'dashed',
      title: props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.axes.thresholds.line.dashed' }),
    },
  ]

  const handleRemove = () => props.onRemove(index, 'thresholds')

  const handleValue = (value: number) => props.onChange(index, { ...row, value }, 'thresholds')

  const handleColor = (color: string) => props.onChange(index, { ...row, color }, 'thresholds')

  const handleLine = (line: IChartThreshold['line']) => props.onChange(index, { ...row, line }, 'thresholds')

  return (
    <tr>
      <td>
        <NumberInput type="parameter" fullWidth={true} value={row.value} onChange={handleValue} {...props.unitsInfo} />
      </td>
      <td>
        <Dropdown name="" options={lineOptions} value={row.line} onChange={handleLine} />
      </td>
      <td align="center" style={{ width: '8ch' }}>
        <ColorPicker position="left" color={row.color} onChange={handleColor} />
      </td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={handleRemove} />
      </td>
    </tr>
  )
}

interface Props {
  intl: IntlShape
  index: number
  row: IChartThreshold
  onChange: (index: number, row: IChartThreshold, key: string) => void
  onRemove: (index: number, key: string) => void
  unitsInfo: any
}

export default React.memo(ThresholdsTableRow)
