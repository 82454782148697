import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ReactComponent as VerticalDotsIcon } from '../../../icons/vertical-dots.svg'
import { ReactComponent as ArchiveIcon } from '../../../icons/archive.svg'
import { ReactComponent as MoveIcon } from '../../../icons/move.svg'
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg'
import { ReactComponent as StartIcon } from '../../../icons/play.svg'
import { ReactComponent as StopIcon } from '../../../icons/pause.svg'
import * as actions from '../nsi.actions'
import { useDropdownControls } from './tree.hooks'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'

/**
 * Компонент отображающий выпадающее меню с пунктами "удалить", "переместить в", "архивировать" и т.д.
 */
const RowControls = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { ref, open, setOpen, dropdownStyle } = useDropdownControls()

  // открытие меню также по клику правой кнопки по родительской строке
  React.useEffect(() => {
    const row = ref.current?.parentElement?.parentElement
    if (!row) return

    const openMenu = (e: MouseEvent) => {
      e.preventDefault()
      setOpen(true)
    }

    row.addEventListener('contextmenu', openMenu)
    return () => row.removeEventListener('contextmenu', openMenu)
  }, [])

  const handleClick = () => setOpen(!open)

  const handleMoveUp = (e) => {
    e.stopPropagation()
    dispatch(actions.reorderSelectedItem(-1))
  }

  const handleMoveDown = (e) => {
    e.stopPropagation()
    dispatch(actions.reorderSelectedItem(1))
  }

  const deleteRow = <ContextMenuOption onClick={props.onDelete} type="delete" />

  const moveRow = (
    <ContextMenuOption onClick={props.onMove} label={intl.formatMessage({ id: 'common.movein' })} icon={<MoveIcon />} />
  )

  const archiveRow = (
    <ContextMenuOption
      onClick={props.onArchive}
      icon={<ArchiveIcon />}
      label={intl.formatMessage({ id: props.isArchived ? 'nsi.device.restore' : 'nsi.device.archive' })}
    />
  )

  const upRow = (
    <ContextMenuOption
      onClick={handleMoveUp}
      label={intl.formatMessage({ id: 'nsi.move_up' })}
      icon={<ArrowRight style={{ transform: 'rotate(-90deg)' }} />}
    >
      <span className="text--gray font--x-small">&nbsp;(Alt + &uarr;)</span>
    </ContextMenuOption>
  )

  const downRow = (
    <ContextMenuOption
      onClick={handleMoveDown}
      label={intl.formatMessage({ id: 'nsi.move_down' })}
      icon={<ArrowRight style={{ transform: 'rotate(90deg)' }} />}
    >
      <span className="text--gray font--x-small">&nbsp;(Alt + &darr;)</span>
    </ContextMenuOption>
  )

  const startRow = (
    <ContextMenuOption
      onClick={props.onStart}
      icon={<StartIcon />}
      label={intl.formatMessage({ id: 'nsi.start_all' })}
    />
  )

  const stopRow = (
    <ContextMenuOption onClick={props.onStop} icon={<StopIcon />} label={intl.formatMessage({ id: 'nsi.stop_all' })} />
  )

  const accessRow = <ContextMenuOption onClick={props.onAccess} type={'access'} />
  const dropdown = (
    <ContextMenu style={dropdownStyle} open={open} animationOrigin={'right top'}>
      {props.allowReplicating && <ContextMenuOption onClick={props.onReplicate} type="copy" />}
      {props.allowArchiving && archiveRow}
      {props.allowMoving && moveRow}
      {props.allowReordering && upRow}
      {props.allowReordering && downRow}
      {props.allowChangingState && startRow}
      {props.allowChangingState && stopRow}
      {props.allowChangingAccess && accessRow}
      {props.allowDeleting && deleteRow}
    </ContextMenu>
  )

  if (!props.allowDeleting && !props.allowMoving) {
    return null
  }

  return (
    <div className="nsi__row-control" onClick={handleClick} ref={ref}>
      <VerticalDotsIcon width={20} height={20} style={{ color: 'var(--primary-90)' }} />
      {dropdown}
    </div>
  )
}

interface Props {
  allowArchiving?: boolean
  allowChangingAccess?: boolean
  allowChangingState: boolean
  allowDeleting: boolean
  allowMoving: boolean
  allowReordering: boolean
  allowReplicating?: boolean
  isArchived?: boolean
  onAccess?: () => void
  onArchive?: () => void
  onDelete: () => void
  onMove: () => void
  onReplicate?: () => void
  onStart?: () => void
  onStop?: () => void
}

export default React.memo(RowControls)
