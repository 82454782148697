import { useSelector } from 'react-redux'
import CatalogTree from '../components/CatalogTree/CatalogTree'
import { Route, Routes } from 'react-router'
import SchemaFormCreate from '../components/SchemaForm/SchemaFormCreate'
import SchemaFormRoute from '../components/SchemaForm/SchemaFormRoute'
import InstanceFormCreate from '../components/InstanceForm/InstanceFormCreate'
import InstanceFormRoute from '../components/InstanceForm/InstanceFormRoute'
import { selectCatalogsAccessRights, selectTypes } from '../catalogs.selectors'

export const Types = () => {
  const types = useSelector(selectTypes)
  const rights = useSelector(selectCatalogsAccessRights)

  return (
    <>
      <CatalogTree
        title={'nav.types'}
        items={types}
        instanceCreatePathGenerator={(id) => `/catalogs/types/${id}/create`}
        itemCreatePath={'/catalogs/types/create'}
        routePrefix={'/catalogs/types/'}
        allowInstancesEditing={rights.allowInstancesEditing}
        allowSchemasEditing={rights.allowCatalogsEdit}
      />

      <div className={'nsi-main__wrapper'}>
        <div className={'nsi-main__container'}>
          <Routes>
            <Route path={'create'} element={<SchemaFormCreate />} />
            <Route path={':id'} element={<SchemaFormRoute />} />
            <Route path={':id/create'} element={<InstanceFormCreate />} />
            <Route path={':id/instance/:instanceId'} element={<InstanceFormRoute />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
