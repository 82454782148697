import { IncidentsArchiveSettings, SECondition, SignalEvent, SignalEventRule } from './se.interfaces'

type SEOperator = SECondition['condition']['operator']

export const isRangeOperator = (operator: SEOperator) => {
  return operator === 'in' || operator === 'out'
}

export const isRateOperator = (operator: SEOperator) => {
  return operator === 'rate_lt' || operator === 'rate_gt'
}

type ValidationResult = { type: 'error'; error: string } | { type: 'ok'; result: SignalEvent }

export const validateSignalEvent = (signalEvent: SignalEvent): ValidationResult => {
  const se = preformatSignalEvent(signalEvent)

  if (!se.name) {
    return { type: 'error', error: 'errors.validation.empty_name' }
  }

  if (!se.description) {
    return { type: 'error', error: 'nsi.se.errors.description_missing' }
  }

  if (se.rules.length === 0) {
    return { type: 'error', error: 'nsi.se.errors.rules_missing' }
  }

  for (const rule of se.rules) {
    const { conditions } = rule.formula

    if (rule.type === 'formula' && conditions.length === 0) {
      return { type: 'error', error: 'nsi.se.errors.conditions_empty' }
    }

    for (const condition of conditions) {
      if (condition.condition.parameter == null) {
        return { type: 'error', error: 'nsi.se.errors.parameter_missing' }
      }

      if (rule.is_global && condition.equipment.value.length === 0) {
        return { type: 'error', error: 'nsi.se.errors.equipment_missing' }
      }
    }

    for (const arg of rule.meta.arguments) {
      if (!arg.parameter_id || (rule.is_global && !arg.device_id)) {
        return { type: 'error', error: 'nsi.se.errors.argument_missing' }
      }
    }
  }

  cleanArchiveSettings(se.warning_archive_settings)
  cleanArchiveSettings(se.danger_archive_settings)

  return { type: 'ok', result: se }
}

// очистка неиспользуемых настроек сигнальной ситуации в зависимости от типа
const preformatSignalEvent = (se: SignalEvent): SignalEvent => {
  const isGlobal = se.rules.some((r) => r.is_global)
  const isScript = se.rules.some((r) => r.type === 'script')

  const rules = se.rules.map((r) => {
    const equipment = isGlobal ? [] : r.equipment
    const src = isScript ? r.src : ''
    const meta = isScript ? r.meta : { arguments: [] }
    const formula = isScript ? { ...r.formula, conditions: [] } : r.formula
    return { ...r, equipment, src, meta, formula }
  })

  return { ...se, rules }
}

// удаление полей которые не используются при выбранном правиле сохранения данных в аварийный архив
const cleanArchiveSettings = (settings: IncidentsArchiveSettings) => {
  if (!settings) return
  if (!settings.rule.includes('parameters:select')) settings.selected_parameters = undefined
  if (!settings.rule.includes('equipment:select')) settings.selected_equipment = undefined
}

export const defaultArchiveSettings: IncidentsArchiveSettings = {
  enabled: false,
  delta_start: 60,
  delta_end: 60,
  rule: 'parameters:match/equipment:match',
}

export const signalEventFactory = (): SignalEvent => {
  return {
    id: Math.random(),
    name: '',
    description: '',
    created_at: Date.now(),
    deleted: false,
    rules: [ruleFactory('warning', 'formula', false), ruleFactory('danger', 'formula', false)],
    warning_archive_settings: { ...defaultArchiveSettings },
    danger_archive_settings: { ...defaultArchiveSettings },
    danger_sound: null,
    danger_sound_repeat: 0,
    warning_sound: null,
    warning_sound_repeat: 0,
  }
}

export const ruleFactory = (
  level: SignalEventRule['level'],
  type: SignalEventRule['type'],
  is_global: boolean
): SignalEventRule => {
  return {
    id: Math.random(),
    type,
    level,
    is_global,
    require_ack: true,
    formula: { conditions: [conditionFactory()], durationStart: 2, durationEnd: 10 },
    equipment: [],
    equipment_matcher: 'eq',
    src: '',
    meta: { arguments: [] },
    status: 'unknown',
  }
}

export const conditionFactory = (): SECondition => {
  return {
    operator: 'and',
    condition: { parameter: null, operator: 'gt', value: 0 },
    equipment: { operator: 'any', value: [] },
  }
}
