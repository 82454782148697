import React from 'react'

const AttributeInput = (props: StringProps | NumberProps) => {
  const [editing, setEditing] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>()
  const divRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    if (editing) calcWidth()
  }, [editing])

  const handleClick = () => {
    if (props.allowEditing) setEditing(true)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') handleFinish(e)
  }

  const handleFinish = (e) => {
    const v: string = e.target.value

    if (props.type === 'number') {
      const value = parseFloat(v)
      if (!Number.isNaN(value)) props.onChange(value, props.index, props.name)
    } else {
      props.onChange(v, props.index, props.name)
    }

    setEditing(false)
  }

  const calcWidth = () => {
    const { value } = inputRef.current
    const div = divRef.current
    div.innerText = value

    div.style.visibility = 'hidden'
    div.style.display = 'block'
    const width = Math.max(div.offsetWidth, 24)

    inputRef.current.style.width = width + 'px'
    div.style.visibility = 'visible'
    div.style.display = 'none'
  }

  const showInput = editing || (props.name === 'key' && !props.value)
  const className = props.name === 'key' ? 'attribute__value is-key' : 'attribute__value'

  if (!showInput || !props.allowEditing) {
    return (
      <div className={className} onClick={handleClick}>
        {props.value}
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="attribute__value" ref={divRef} style={{ display: 'none', whiteSpace: 'pre-wrap' }} />
      <input
        autoFocus={true}
        className={className + ' attribute__input'}
        defaultValue={props.value}
        onBlur={handleFinish}
        onChange={calcWidth}
        onKeyUp={handleKeyUp}
        ref={inputRef}
      />
    </React.Fragment>
  )
}

interface BaseProps {
  allowEditing: boolean
  index: number
  name: string
}

interface StringProps extends BaseProps {
  type: 'string'
  value: string
  onChange: (v: string, index: number, name: string) => void
}

interface NumberProps extends BaseProps {
  type: 'number'
  value: number
  onChange: (v: number, index: number, name: string) => void
}

export default AttributeInput
