import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { ValidationResult } from '../../../shared/interfaces'
import http, { handleHttpError } from '../../../utils/http'
import { showError } from '../../../utils/notifications'
import actions from './settings.actions'

export interface SettingsState<T> {
  settings: T
  draft: T
  editing: boolean
  loading: boolean
  startEditing: () => void
  updateSettings: () => void
  updateDraft: (updates: Partial<T>) => void
  cancelDraft: () => void
}

export const useSettingsAPI = <T>(name: string, validate?: (settings: T) => ValidationResult): SettingsState<T> => {
  const settings: T = useSelector((state: ReduxState) => state.service_settings[name])
  const [draftSettings, setDraftSettings] = useState<T>(settings)
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.loadSettings(name))
  }, [])

  useEffect(() => {
    if (!editing) setDraftSettings(settings)
  }, [settings])

  const startEditing = () => setEditing(true)

  const cancelDraft = () => {
    setDraftSettings({ ...settings })
    setEditing(false)
  }

  const updateDraft = (updates: Partial<T>) => {
    if (editing) setDraftSettings({ ...draftSettings, ...updates })
  }

  const updateSettings = () => {
    if (validate && validate(draftSettings)) {
      const res = validate(draftSettings)
      const message = intl.formatMessage({ id: res.id }, res.values)
      return showError(message, message)
    }

    setLoading(true)

    http
      .post('/nsi/v1/ms/settings/' + name, draftSettings)
      .then((res) => {
        dispatch(actions.set(res.data))
        setEditing(false)
      })
      .catch(handleHttpError)
      .finally(() => setLoading(false))
  }

  return {
    settings,
    updateSettings,
    draft: draftSettings,
    updateDraft,
    cancelDraft,
    editing,
    loading,
    startEditing,
  }
}
