import { ReduxState } from '../../../redux/store.types'
import http, { handleHttpError } from '../../../utils/http'
import { generateActions } from '../collection.actions'
import { SET_FILTERS, TOGGLE_USERNAMES, UsersFilters } from './users.reducers'
import { IUser } from 'au-nsi/user'

const API_URL = '/nsi/users/'

const actions = generateActions<IUser>('users', API_URL)

const suspendAccount = (id: number, suspended: boolean) => (dispatch) => {
  return http
    .post(API_URL + id + '/suspend', { suspended })
    .then(({ data }) => dispatch(actions.itemUpdated(id, data)))
    .catch((err) => handleHttpError(err))
}

// выставить язык для нового пользователя такой же как у текущего пользователя
const createItem = (user: IUser) => (dispatch, getState) => {
  const state = getState() as ReduxState
  user.lang = state.user.lang

  dispatch(actions.createItem(user))
}

const loadItems = () => (dispatch, getState) => {
  const accessRights = getState().user.access_rights

  if (accessRights['users:get'] || accessRights['users:get_org_members']) dispatch(actions.loadItems())
}

const toggleUsernames = () => ({ type: TOGGLE_USERNAMES })

const setFilters = (filters: UsersFilters) => ({ type: SET_FILTERS, payload: filters })

const usersActions = {
  ...actions,
  createItem,
  suspendAccount,
  setFilters,
  toggleUsernames,
  loadItems,
}
export default usersActions
