class EventEmitter<T = any> {
  private subscribers: Subscriber<T>[] = []
  private value: T // кэш последнего полученного значения

  /**
   * @param init функция, которая будет вызвана при появлении первого подписчика
   * @param cleanup функция, которая будет вызвана при удалении последнего подписчика
   */
  constructor(private init?: () => void, private cleanup?: () => void) {}

  /**
   * @param cb функция, которая будет вызвана для каждого нового значения
   * @param emitLastValue немедленно вызвать обработчик с последним значением
   */
  public subscribe(cb: Subscriber<T>, emitLastValue = false): Unsubscribe {
    this.addSubscriber(cb, emitLastValue)
    return () => this.removeSubscriber(cb)
  }

  /**
   * Отправка нового значения всем имеющимся подписчикам
   */
  public next(value: T) {
    this.value = value

    for (const cb of this.subscribers) {
      cb(value)
    }
  }

  private addSubscriber(cb: Subscriber<T>, emitLastValue = false) {
    // вызвать обработчик при появлении первого подписчика
    if (this.subscribers.length === 0 && this.init) {
      this.init()
    }

    if (emitLastValue && this.value != null) {
      cb(this.value)
    }

    this.subscribers.push(cb)
  }

  private removeSubscriber(cb: Subscriber<T>) {
    this.subscribers = this.subscribers.filter((s) => s !== cb)

    // удален последний подписчик
    if (this.subscribers.length === 0 && this.cleanup) {
      this.cleanup()
    }
  }
}

/**
 * Создание нового эмиттера со значениями отфильтрованными обработчиком cb
 */
export const filter = <T>(emitter: EventEmitter<T>, cb: (value: T) => boolean) => {
  let unsubscribe: Unsubscribe

  // подписываемся на исходный эмиттер только после появления первого подписчика
  // у нового эмиттера
  const init = () => {
    unsubscribe = emitter.subscribe((value) => cb(value) && e.next(value))
  }

  // при удалении всех подписчиков нового эмиттера также отключаемся от исходного
  const cleanup = () => unsubscribe()

  const e = new EventEmitter<T>(init, cleanup)
  return e
}

/**
 * Создание нового эмиттера со значениями преобразованными обработчиком cb
 */
export const map = <T, R>(emitter: EventEmitter<T>, cb: (value: T) => R) => {
  let unsubscribe: Unsubscribe

  const init = () => {
    unsubscribe = emitter.subscribe((value) => e.next(cb(value)))
  }

  const cleanup = () => unsubscribe()

  const e = new EventEmitter<R>(init, cleanup)
  return e
}

/**
 * Создание нового эмиттера со значениями равными массиву из последних значений e1 и e2
 */
export const combineLatest = <T, R>(e1: EventEmitter<T>, e2: EventEmitter<R>) => {
  let value1: T = null
  let value2: R = null
  let s1: Unsubscribe = null
  let s2: Unsubscribe = null

  const next = () => {
    if (value1 != null && value2 != null) e.next([value1, value2])
  }

  const init = () => {
    s1 = e1.subscribe((v) => {
      value1 = v
      next()
    }, true)

    s2 = e2.subscribe((v) => {
      value2 = v
      next()
    }, true)
  }

  const cleanup = () => {
    s1()
    s2()
  }

  const e = new EventEmitter<[T, R]>(init, cleanup)
  return e
}

type Subscriber<T> = (value: T) => void

export type Unsubscribe = () => void

export default EventEmitter
