import { useIntl } from 'react-intl'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import http from '../../../../utils/http'
import { formatMemory } from '../../../../utils/misc'
import { IDocument } from 'au-nsi/documents'

/**
 * Модальное окно для подтверждения замены содержимого файла
 */
const FileReplaceModal = (props: Props) => {
  const intl = useIntl()
  const { loading, wait } = useHttpLoader()

  const doc = props.document
  const name = props.file.name
  const size = formatMemory(props.file.size / 1024)

  const handleSave = () => {
    const p = http.put(`/nsi/v1/documents/files/${doc.id}/content`, props.file)
    wait(p, () => props.onClose())
  }

  return (
    <Modal onClose={props.onClose} closeOnEscape={true}>
      <h2 style={{ textAlign: 'center' }}>
        {intl.formatMessage({ id: 'documents.actions.replace_confirm' }, { name, size })}
      </h2>

      <ModalFooter
        loading={loading}
        onCancel={props.onClose}
        onSave={handleSave}
        saveMessage={intl.formatMessage({ id: 'documents.actions.replace_document' })}
      />
    </Modal>
  )
}

interface Props {
  document: IDocument
  file: File
  onClose: () => void
}

export default FileReplaceModal
