import { memo, useMemo, useState } from 'react'
import { useAppSelector } from '../../../redux/store'
import { selectAccessRights } from '../../App/app.selectors'
import DeviceTagsModal from './DeviceTagsModal'
import css from './tags.module.css'

interface Props {
  id: string
  tags: number[]
}

/**
 * Список тэгов устройства
 */
const DeviceTags = ({ id, tags }: Props) => {
  const allowEditing = useAppSelector(selectAccessRights)['equipment:update_tags']
  const tagsList = useAppSelector((state) => state.tags)
  const tagsMap = useMemo(() => new Map(tagsList.map((t) => [t.id, t.name])), [tagsList])

  const [editing, setEditing] = useState(false)

  const handleClick = () => allowEditing && setEditing(true)
  const handleClose = () => setEditing(false)

  const items = tags.map((tag, index) => (
    <span key={index} className={css.tag}>
      {tagsMap.get(tag)}
    </span>
  ))

  return (
    <>
      <div className="editable-text__wrapper" onClick={handleClick}>
        <div className={'editable-text ' + css.tagsLine}>{items.length ? items : '-'}</div>
      </div>
      {editing && <DeviceTagsModal id={id} tags={tags} onClose={handleClose} />}
    </>
  )
}

export default memo(DeviceTags)
