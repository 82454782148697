import { IGaugeSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import useDataRate from '../../hooks/useDataRate'
import useDataService from '../../hooks/useDataService'
import { selectDenormalizedParametersMap } from '../../pages/Parameters/params.selectors'
import { convert, formatUnit, formatValue } from '../../pages/Parameters/params.utils'
import { drawLoader } from '../../utils/canvas.utils'
import AxisMark from './AxisMark'
import AxisSub from './AxisSub'
import './gauge.styles.css'
import * as utils from './gauge.utils'
import GaugeArc from './GaugeArc'

const pi = Math.PI
const a0 = pi / 4
const totalAngle = 2 * pi - 2 * a0

const marksCount = 10
const subsCount = 5
const markAngles = []
const subAngles = []
const markAnglesDelta = totalAngle / marksCount
const subAnglesDelta = totalAngle / subsCount

for (let i = 0; i <= marksCount; i++) {
  markAngles.push(a0 + markAnglesDelta * i)
}

for (let i = 0; i <= subsCount; i++) {
  subAngles.push(a0 + subAnglesDelta * i)
}

/**
 * Gauge coordinates, all angles are computed from bottom Y axis clockwise:
 *
 * (0, 0)        |           (100, 0)
 *               |
 *               |
 *               | (50, 50)
 * ----------------------------->
 *              /|
 *             / |
 *            /  |
 *    <- angles  |
 *          /    |
 * (0, 100)      v           (100, 100)
 */
const Gauge = (props: Props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>()
  const svgRef = React.useRef<SVGSVGElement>()
  const valueRef = React.useRef<HTMLDivElement>()
  const lastValue = React.useRef('')

  const [size, setSize] = React.useState(0) // размер датчика в пикселях

  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  const parameters = useSelector(selectDenormalizedParametersMap)
  const p = parameters.get(props.settings.parameter_id)

  const { mode, boundaries } = props.settings
  const totalRange = boundaries.upper - boundaries.lower

  const ranges = props.settings.ranges.filter(
    (r) => r.lower < r.upper && r.lower < boundaries.upper && r.upper > boundaries.lower
  )

  const startAngle = a0
  const stopAngle = startAngle + totalAngle

  const angle2value = (angle: number) => {
    return boundaries.lower + (totalRange * (angle - startAngle)) / totalAngle
  }

  const value2angle = (value: number) => {
    return startAngle + (totalAngle * (value - boundaries.lower)) / totalRange
  }

  React.useEffect(() => {
    service.setDataSelectors([{ device_id: props.settings.device_id, parameters: [props.settings.parameter_id] }])
    const ctx = canvasRef.current.getContext('2d')

    const reset = () => {
      if (lastValue.current !== '') {
        ctx.clearRect(0, 0, size, size)
        lastValue.current = ''
        valueRef.current.innerText = ''
      }

      if (service.isLoading) {
        drawLoader(ctx, size)
      }
    }

    const onTick = () => {
      // 1. получаем текущее значение параметра
      const point = service.selectCurrentPoint(props.settings.device_id)
      const value = point && point[props.settings.parameter_id]

      if (value == null) return reset()

      // 2. используя значение параметра рассчитываем угол датчика
      let v = value
      if (value < boundaries.lower) v = boundaries.lower
      if (value > boundaries.upper) v = boundaries.upper

      // 3. форматируем выводимое значение параметра (для избежания мелькания выводим только раз в секунду)
      if (service.shouldRender()) {
        lastValue.current = formatValue(value, p, false, props.settings.precision)
        valueRef.current.innerText = lastValue.current
      }

      // 4. отрисовывка индикатора датчика на canvas
      ctx.clearRect(0, 0, size, size)

      const fn = props.settings.mode === 'arrow' ? utils.drawArrow : utils.drawArc
      fn({ size, ctx, value: v, startAngle, stopAngle, ranges, angle2value, value2angle })
    }

    onTick()
    service.onTick = onTick
  }, [props.settings, size, p])

  // выставить размеры canvas равными размеру родительского контейнера
  React.useEffect(() => {
    const rect = svgRef.current.getBoundingClientRect()
    setSize(Math.min(rect.width, rect.height))
  }, [props.lastResize])

  const axisWidth = mode === 'arrow' ? 2 : 1
  const labelPrecision = utils.getLabelPrecision(boundaries.upper - boundaries.lower)

  const marks = markAngles.map((angle) => {
    const value = angle2value(angle)
    const range = utils.findRange(ranges, value)
    const color = range ? utils.colors[range.type] : utils.colors.info
    return <AxisMark key={angle} angle={angle} color={color} />
  })

  const subs = subAngles.map((angle, i) => {
    let value = boundaries.lower + (i * (boundaries.upper - boundaries.lower)) / subsCount

    if (p != null) {
      const options = { fromUnit: p.unit, fromPrefix: p.prefix, toUnit: p.display_unit, toPrefix: p.display_prefix }
      value = convert(value, options)
    }

    return <AxisSub key={angle} angle={angle} value={value} precision={labelPrecision} />
  })

  const arcs = ranges.map((range, i) => {
    const color = utils.colors[range.type] || range.color
    const alpha0 = Math.max(startAngle, value2angle(range.lower))
    const alpha1 = Math.min(stopAngle, value2angle(range.upper))
    return <GaugeArc key={i} width={axisWidth} color={color} startAngle={alpha0} endAngle={alpha1} />
  })

  // display parameter name with its unit
  let legend = props.settings.title
  if (p != null) {
    // если заголовок не задан, то использовать название параметра
    if (!legend) legend = p.name

    legend += formatUnit(p, 'comma')
  }

  const valueSize = Math.round(0.12 * size)
  const legendSize = Math.round(0.06 * size)

  return (
    <div className="gauge">
      <svg viewBox="0 0 100 100" ref={svgRef}>
        <GaugeArc width={axisWidth} color={utils.colors.info} startAngle={startAngle} endAngle={stopAngle} />
        {arcs}
        {marks}
        {subs}
      </svg>

      <canvas ref={canvasRef} width={size} height={size} style={{ position: 'absolute', top: 0, left: 0 }} />

      <div className="gauge__value" ref={valueRef} style={{ fontSize: valueSize }} />
      <div className="gauge__title line_clamp" style={{ fontSize: legendSize }} title={legend}>
        {legend}
      </div>
    </div>
  )
}

interface Props {
  id: number
  lastResize: number
  settings: IGaugeSettings
}

export default Gauge
