import React from 'react'

/**
 * FLIP - First, Last, Invert, Progress
 * Анимация плавного перемещения элемента из начальной позиции в конечную
 * @param deps Список переменных при изменении которых необходимо анимировать
 * @param enabled Показывать анимацию
 */
const useFlip = (deps: React.DependencyList, enabled = true) => {
  const lastPosition = React.useRef<DOMRect>()
  const scrollParent = React.useRef<IScrollParent>({ node: null, scroll: 0 })
  const element = React.useRef<HTMLDivElement>()

  // после завершения анимации по перемещению необходимо запомнить новые координаты элемента
  const handleTransitionEnd = React.useCallback(() => {
    if (element && element.current) lastPosition.current = element.current.getBoundingClientRect()
    scrollParent.current.scroll = getScrollTop(scrollParent)
  }, [element, element.current])

  React.useEffect(() => {
    scrollParent.current.node = getScrollParent(element.current)
    handleTransitionEnd()

    const node = element.current

    node.addEventListener('transitionend', handleTransitionEnd)
    return () => node.removeEventListener('transitionend', handleTransitionEnd)
  }, [element, element.current])

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(handleTransitionEnd)
    resizeObserver.observe(element.current)

    return () => resizeObserver.disconnect()
  }, [element, element.current])

  // Сама анимация - перемещаем элемент в предыдущее положение и из него делаем transition в текущее
  React.useLayoutEffect(() => {
    if (!enabled) handleTransitionEnd()

    if (element.current && lastPosition.current) {
      const first = lastPosition.current
      const last = element.current.getBoundingClientRect()

      const dx = first.left - last.left
      // изменение высоты относительно viewport
      const dy1 = first.top - last.top
      // изменение прокрутки относительно родительского элемента
      const dy2 = scrollParent.current.scroll - getScrollTop(scrollParent)

      element.current.style.transform = `translate(${dx}px, ${dy1 + dy2}px)`
      element.current.style.transition = `none`

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (element && element.current) {
            element.current.style.transform = 'none'
            element.current.style.transition = `transform .3s ease-in-out`
          }
        })
      })
    }
  }, deps)

  return { element, handleTransitionEnd }
}

// найти ближайший родительский элемент со скроллом
const getScrollParent = (node): HTMLElement => {
  if (!node) return null

  if (node.scrollHeight > node.clientHeight) {
    const overflowY = window.getComputedStyle(node).overflowY

    if (overflowY !== 'visible' && overflowY !== 'hidden') {
      return node
    }
  }

  return getScrollParent(node.parentNode)
}

const getScrollTop = (ref: React.MutableRefObject<IScrollParent>) => {
  const node = ref.current.node
  const top = node && node.scrollTop
  return top || 0
}

interface IScrollParent {
  node: HTMLElement
  scroll: number
}

export default useFlip
