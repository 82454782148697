import { IntlShape } from 'react-intl'
import { PROTOCOLS } from '../../../../shared/constants'
import { createSelector } from 'reselect'
import { ReduxState } from '../../../../redux/store.types'
import { ITag, PlatformModuleID } from 'au-nsi/shared'
import { SelectOption } from '@alterouniversal/au-react-components'

export const statusOptions = (intl: IntlShape): SelectOption[] => [
  { label: intl.formatMessage({ id: 'common.all' }), value: undefined },
  { label: intl.formatMessage({ id: 'nsi.device_state.RUNNING' }), value: 'RUNNING' },
  { label: intl.formatMessage({ id: 'nsi.device_state.STOPPED' }), value: 'STOPPED' },
  { label: intl.formatMessage({ id: 'nsi.device_state.ERROR' }), value: 'ERROR' },
  { label: intl.formatMessage({ id: 'nsi.device_state.WARNING' }), value: 'WARNING' },
  { label: intl.formatMessage({ id: 'nsi.device_state.READING_CONF_SUCCESS' }), value: 'READING_CONF_SUCCESS' },
  { label: intl.formatMessage({ id: 'nsi.device_state.READING_CONF_ERROR' }), value: 'READING_CONF_ERROR' },
]

export const protocolOptions = (
  intl: IntlShape,
  protocols: Array<{ id: string; module: PlatformModuleID }> = PROTOCOLS
) => {
  const result = [{ label: intl.formatMessage({ id: 'common.all' }), value: undefined, module: undefined }]

  for (const protocol of protocols) {
    result.push({
      label: intl.formatMessage({ id: 'nsi.protocols.' + protocol.id }),
      value: protocol.id,
      module: protocol.module,
    })
  }

  return result
}

export const tagOptions = (intl: IntlShape, tags: ITag[]) => {
  const result = [{ label: intl.formatMessage({ id: 'common.all' }), value: undefined }]

  for (const tag of tags) {
    result.push({ label: tag.name, value: tag.id })
  }

  return result
}

/**
 * Выбрать массив из протоколов, у каждого из которых есть хотябы одно устройство
 * */
export const selectUsedProtocols = createSelector(
  (state: ReduxState) => state.nsi.equipment,
  (equipment) => {
    const set = equipment.reduce((acc, val) => acc.add(val.protocol), new Set())

    return PROTOCOLS.filter((p) => set.has(p.id))
  }
)
