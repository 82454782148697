import UserTaskDescription from '../UserTaskDescription/UserTaskDescription'
import { IUserTask } from '../../userTasks.types'
import { useDispatch } from 'react-redux'
import userTasksActions from '../../userTasks.actions'
import styles from './userTaskTab.module.css'

interface IProps {
  task: IUserTask
  isActive: boolean
}

/**
 * Компонент вкладки пользовательского таска. Рендреится в правом нижнем углу страницы.
 */
const UserTaskTab = (props: IProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    if (props.isActive) dispatch(userTasksActions.disableTask(props.task.id))
    else dispatch(userTasksActions.activateTask(props.task.id))
  }

  return (
    <div data-active={props.isActive} className={styles.taskDescription} onClick={handleClick}>
      <UserTaskDescription task={props.task} />
    </div>
  )
}

export default UserTaskTab
