import { Equipment } from 'au-nsi/equipment'
import { AnyCatalogWithInstances } from '../../../Catalogs/catalogs.types'
import { ReduxState } from '../../../../redux/store.types'
import { selectAccessRights } from '../../../App/app.selectors'
import produce from 'immer'
import { ILogbookEntry } from 'au-nsi/logbook'

export const createLogbookEntry = (deviceId: Equipment['id'], schema: AnyCatalogWithInstances): ILogbookEntry => ({
  id: -1,
  schema_id: schema.id,
  equipment_id: deviceId,
  ts: Date.now(),
  values: schema.schema.reduce((acc, val) => ({ ...acc, [val.id]: val.default_value }), {}),
  created_by: undefined,
})

export const orderedInsert = (logbooks: ILogbookEntry[], inserting: ILogbookEntry) => {
  if (logbooks.some((logbook) => logbook.id === inserting.id)) return logbooks

  return produce(logbooks, (draftLogbooks) => {
    if (!draftLogbooks || draftLogbooks.length === 0) {
      draftLogbooks.push(inserting)
      return
    }
    if (inserting.ts >= draftLogbooks[0].ts) {
      draftLogbooks.splice(0, 0, inserting)
      return
    }
    if (inserting.ts <= draftLogbooks[draftLogbooks.length - 1].ts) {
      draftLogbooks.push(inserting)
      return
    }

    for (let i = 0; i < draftLogbooks.length - 1; ++i) {
      if (draftLogbooks[i].ts >= inserting.ts && draftLogbooks[i + 1].ts <= inserting.ts) {
        draftLogbooks.splice(i, 0, inserting)
        return
      }
    }
  })
}

export const selectLogbooksRights = (state: ReduxState) => {
  const rights = selectAccessRights(state)

  return {
    allowEditing: rights['equipment_logbook:create'],
    allowApprove: rights['equipment_logbook:moderate'],
  }
}
