import React from 'react'
import { generateImageSrc } from '../../Libraries/Images/image.utils'
import { getValueType, isEmpty } from './actions.utils'
import { formatDate } from '../../../utils/lang'

/**
 * Компонент для отображения деталий действия
 */
const DetailsNode = (props: Props) => {
  const keys = Object.keys(props.targetObject)
  const rows: React.ReactNode[] = []

  for (const key of keys) {
    const v = props.targetObject[key]
    if (isEmpty(v)) continue

    const name = props.translations[props.translationsPrefix + '.' + key] || key

    const value = renderValue(key, v, props)
    if (value == null) continue

    rows.push(
      <div className="s-changelog__details-item" key={key}>
        <span>{name}</span>: {value}
      </div>
    )
  }

  return <div className="s-changelog__details-node">{rows}</div>
}

// отображение отдельного значения из объекта изменений
const renderValue = (key: string, value: any, props: Props): React.ReactNode | React.ReactNodeArray => {
  const type = getValueType(value)
  const prefix = props.translationsPrefix + '.' + key

  // случаи требующие специальной обработки для более компактного/понятного представления
  if (props.resource === 'signal_events' && key === 'condition') {
    return renderSignalEventCondition(key, value)
  }

  if (props.resource === 'signal_events' && key === 'equipment') {
    return renderSignalEventEquipment(key, value, props)
  }

  if (props.resource === 'signal_events' && key === 'conditions') {
    return renderSignalEventConditions(key, value, props)
  }

  if (props.resource === 'equipment' && key === 'color') {
    return renderEquipmentColor(key, value)
  }

  if (props.resource === 'ui_dashboard_components' && key === 'color' && typeof value === 'string') {
    return renderEquipmentColor(key, value)
  }

  if (props.resource === 'se_notifications' && key === 'formula') {
    return renderNotificationsFormula(key, value, props)
  }

  if (props.resource === 'se_notifications' && key === 'equipment') {
    return renderNotificationsEquipment(key, value, props)
  }

  if (props.resource === 'images' && key === 'src') {
    return renderImage(key, value, props)
  }

  if (props.resource === 'audio' && key === 'src') {
    return renderAudio(key, value, props)
  }

  if (props.resource === 'documents' && key === 'src') {
    return renderDocument(key, value, props)
  }

  if (props.resource === 'ui_dashboard_components' && key === 'name') {
    return value ? value.replace('\n', ' / ') : ''
  }

  if (props.resource === 'users' && key === 'registration') {
    return formatDate(value, 'dd MMM yyyy')
  }
  // общий случай: рекурсивный вывод пар ключ-значение
  switch (type) {
    case 'string':
      return props.translations[`${props.translationsPrefix}.${key}.${value}`] || value

    case 'primitive':
      return '' + value

    case 'string_array':
      const colors = { deleted: 'var(--danger-text)', created: 'var(--text-success)' }
      const icons = { deleted: '- ', created: '+ ' }

      const children = value.map((e, i) => {
        const text = props.translations[`${props.translationsPrefix}.${key}.${e}`] || e
        const color = props.highlightDiff ? colors[props.highlightMode] : '#fff'
        const icon = props.highlightDiff ? icons[props.highlightMode] : ''

        return (
          <div style={{ color }} key={i}>
            {icon + text}
          </div>
        )
      })

      return <div className="s-changelog__details-node">{children}</div>

    case 'primitive_array':
      return value.join(', ')

    case 'object':
      return <DetailsNode {...props} translationsPrefix={prefix} targetObject={value} />

    case 'object_array':
      return (
        <div className="s-changelog__details-array">
          {value.map((item, i) => (
            <DetailsNode key={i} {...props} translationsPrefix={prefix} targetObject={item} />
          ))}
        </div>
      )

    default:
      return '' + value
  }
}

// отображение условий сигнальной ситуации
const renderSignalEventConditions = (_, value: any, props: Props) => {
  const children = value.map((v, i) => {
    const operator = i > 0 ? v.operator + ' ' : ''
    const condition = renderValue('condition', v.condition, props)
    const equipment = renderValue('equipment', v.equipment, props)

    return (
      <div key={i} className="s-changelog__details-node">
        {operator}
        {condition} {equipment}
      </div>
    )
  })

  return <div className="s-changelog__details-node">{children}</div>
}

// отображение условия срабатывания сигнальной ситуации
const renderSignalEventCondition = (_, value: any) => {
  return `${value.parameter} ${value.operator} ${value.value}`
}

// отображение настроек оборудования из условия сигнальной ситуации
const renderSignalEventEquipment = (key: string, value: any, props: Props) => {
  const equipment = renderValue('value', value.value, props)
  if (!equipment) return null

  return (
    <React.Fragment>
      <span key={key}>{value.operator}</span>: {equipment}
    </React.Fragment>
  )
}

// отображение цвета оборудования
const renderEquipmentColor = (_, value: any) => {
  return (
    <span>
      {value} <span className="s-changelog__details-color" style={{ backgroundColor: value }} />
    </span>
  )
}

// отображение настроек уведомлений о сигнальных ситуациях по формулам
const renderNotificationsFormula = (_, value: any, props: Props) => {
  const changes = value.map((v) => `${v.method || 'web'}: ${v.signal_event} (${v.level})`)
  return renderValue('', changes, props)
}

// отображение настроек уведомлений о сигнальных ситуациях из data quality
const renderNotificationsEquipment = (_, value: any, props: Props) => {
  const changes = value.map((v) => `${v.method || 'web'}: ${v.type} ${v.equipment}`)
  return renderValue('', changes, props)
}

// отображение загруженного пользователем изображения
const renderImage = (_, __, props: Props) => {
  return (
    <img alt="" src={generateImageSrc(props.targetObject as any)} style={{ maxWidth: '160px', maxHeight: '160px' }} />
  )
}

// отображение аудио в base64: показываем дефолтный браузерный плеер
const renderAudio = (_, __, props: Props) => {
  const audio = props.targetObject
  const format = audio.format ? `audio/${audio.format}` : 'audio'
  const src = `data:${format};base64,${audio.src}`

  return <audio controls={true} src={src} />
}

const renderDocument = (_, __, props: Props) => {
  const doc = props.targetObject
  const type = doc.type || 'application/octet-stream'

  return (
    <a style={{ color: '#fff' }} href={`data:${type};base64,${doc.src}`} target="__blank">
      Download
    </a>
  )
}

interface Props {
  resource: string
  targetObject: { [key: string]: any }
  translations: { [key: string]: string }
  translationsPrefix: string
  highlightDiff?: boolean
  highlightMode?: 'deleted' | 'created'
}

export default DetailsNode
