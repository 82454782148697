import { IGanttComponent } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import React from 'react'
import { connect } from 'react-redux'
import GanttService from '../../../services/gantt/gantt.service'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'
import dashboardActions from '../../Dashboards/dashboard.actions'
import Gantt from '../../GanttTable/Gantt'
import { actions } from '../pq.reducers'
import GanttRow from './GanttRow'

class Table extends React.Component<Props> {
  componentDidMount() {
    const action = dashboardActions.loadDashboardComponents(this.props.page)
    this.props.dispatch(action)
  }

  private handleRowClick = (id) => {
    if (this.props.page === 'pq') {
      this.props.navigate(`/registrators/${id}/power`)
    }
  }

  private toggleVisibility = (id) => {
    this.props.dispatch(actions.toggleVisibility(id))
  }

  private handleSearch = (value) => {
    this.props.dispatch(actions.setSearch(value))
  }

  private renderRows() {
    return this.props.registrators.map((registrator) => {
      const { id } = registrator
      const data$ = this.props.service.select(id)

      return (
        <GanttRow
          key={id}
          registrator={registrator}
          onClick={this.handleRowClick}
          onVisibilityToggle={this.toggleVisibility}
          data$={data$}
        />
      )
    })
  }

  render() {
    const { component, page } = this.props

    return (
      <Gantt id={component?.id} page={page} service={this.props.service} settings={component?.settings} embedded={true}>
        <Gantt.Header>
          <Gantt.Title>
            <SearchInput onChange={this.handleSearch} />
          </Gantt.Title>
          <Gantt.Timeline />
        </Gantt.Header>
        <Gantt.Body>{this.renderRows()}</Gantt.Body>
      </Gantt>
    )
  }
}

interface Props {
  page: string
  registrators: Equipment[]
  search: string
  navigate: (to: string) => void
  component: IGanttComponent
  service: GanttService
  dispatch: (action: any) => void
}

export default connect()(Table)
