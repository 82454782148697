import http, { handleHttpError } from '../../../utils/http'
import { actions } from './image.reducers'
import { generateImageLink } from './image.utils'
import { Image } from 'au-nsi/images'
import { CreateImageDTO } from './images.types'

const PATH = `/nsi/v1/images/`

const loadImages = () => (dispatch, getState) => {
  const state = getState().images
  if (state.isLoaded) return

  http
    .get(PATH)
    .then((r) => dispatch(actions.setImages(r.data)))
    .catch(handleHttpError)
}

// загрузка кода изображения (т.к. при загрузке всего списка код слишком больших изображений пропускается)
const loadImageSource = (id: string) => (dispatch, getState) => {
  const images = getState().images.items
  const image = images.find((img) => img.id === id)

  // если нет такого изображения или его код уже загружен
  if (!image || image.src) return

  const url = generateImageLink(image)

  fetch(url)
    .then((r) => r.text())
    .then((src) => dispatch(actions.imageUpdated({ id, src })))
    .catch((err) => console.error(err))
}

// загрузка нескольких изображений одновременно
const createImages = (images: CreateImageDTO[]) => (dispatch) => {
  const PARALLELISM = 4

  let count = 0

  const next = () => {
    if (count >= PARALLELISM || images.length === 0) return

    count += 1
    const image = images.shift()

    uploadImage(image, dispatch).finally(() => {
      count -= 1
      next()
    })
  }

  for (let i = 0; i < PARALLELISM; i++) {
    next()
  }
}

// отправка запроса на сервер для сохранения изображения
const uploadImage = (image: CreateImageDTO, dispatch) => {
  return http
    .post(PATH, image)
    .then((r) => dispatch(actions.imageCreated(r.data)))
    .catch(handleHttpError)
}

const updateImage = (image: Image) => (dispatch) => {
  const updates = { ...image }
  if (!updates.src) updates.src = null

  http
    .put(PATH + image.id, updates)
    .then((r) => dispatch(actions.imageUpdated({ ...r.data, id: image.id })))
    .catch(handleHttpError)
}

const deleteImage = (id: string) => (dispatch) => {
  http
    .delete(PATH + id)
    .then(() => dispatch(actions.imageDeleted(id)))
    .catch(handleHttpError)
}

// переместить изображение с sourceId на место изображения с targetId
const moveImage = (sourceId: string, targetId: string) => (dispatch, getState) => {
  if (sourceId === targetId) return

  const images = getState().images.items
  const sourceIndex = images.findIndex((d) => d.id === sourceId)
  const targetIndex = images.findIndex((d) => d.id === targetId)

  const updates = [{ id: sourceId, user_ordering_index: images[targetIndex].user_ordering_index }]
  const step = sourceIndex > targetIndex ? 1 : -1

  for (let i = targetIndex; i * step < sourceIndex * step; i += step) {
    updates.push({ id: images[i].id, user_ordering_index: images[i + step].user_ordering_index })
  }

  dispatch(actions.imagesUpdated(updates))
  http.post(PATH + `reorder`, { images: updates }).catch(handleHttpError)
}

const imageActions = { ...actions, loadImages, loadImageSource, createImages, updateImage, deleteImage, moveImage }

export default imageActions
