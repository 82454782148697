import { IUserTask } from '../../userTasks.types'
import CreateCatalogItemTaskDescription from './CreateCatalogItemTaskDescription/CreateCatalogItemTaskDescription'
import CreateOrganizationTaskDescription from './CreateOrganizationTaskDescription/CreateOrganizationTaskDescription'
import { HTMLProps, memo } from 'react'

interface IProps extends HTMLProps<HTMLSpanElement> {
  task: IUserTask
}

/**
 * Компонент описания пользовательского таска.
 *
 * Например: "kM - Создание инстанса справочника"
 */
const UserTaskDescription = ({ task, ...spanProps }: IProps) => {
  let Description
  switch (task.type) {
    case 'create_catalog_item':
      Description = <CreateCatalogItemTaskDescription task={task} />
      break
    case 'create_organization':
      Description = <CreateOrganizationTaskDescription task={task} />
      break
    default:
      return null
  }

  return <span {...spanProps}>{Description}</span>
}

export default memo(UserTaskDescription)
