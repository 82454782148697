import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import './base.styles.css'
import App from './pages/App/App'
import ErrorBoundary from './pages/App/ErrorBoundary'
import LanguageProvider from './pages/App/LanguageProvider'
import { store } from './redux/store'
import './sanitize.css'
import './shared/Inputs/inputs.styles.css'
import ThemeProvider from './pages/App/Theme/ThemeProvider'
import { createRoot } from 'react-dom/client'

const MOUNT_NODE = document.getElementById('app')
const root = createRoot(MOUNT_NODE)

const render = () => {
  const tree = (
    // <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <RecoilRoot>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </RecoilRoot>
          </ErrorBoundary>
        </BrowserRouter>
      </LanguageProvider>
    </Provider>
    // </React.StrictMode>
  )

  root.render(tree)
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/serviceWorker.js').then(
      function (registration) {
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      },
      function (err) {
        console.log('ServiceWorker registration failed: ', err)
      }
    )
  })
}

render()
