import produce from 'immer'
import { generateReducers } from '../Collections/collection.reducers'
import { SignalEvent, SignalEventRule, SignalEventsState } from './se.interfaces'
import { signalEventFactory } from './se.utils'

export const ADD_EVENT_TO_DELETED_CACHE = 'app/signal_events/AETDC'
export const RULE_UPDATED = 'app/signal_event/RULE_UPDATED'

const initialState: SignalEventsState = {
  mode: 'view',
  items: [],
  types: [],
  selectedItem: null,
  selectedItemId: null,
  selectedTypeId: null,
  isLoaded: false,
  isTypesLoaded: false,
  deletedRulesCache: {},
}

const reducers: any = generateReducers<SignalEvent>('signal_events', signalEventFactory)

/**
 * override default reducer for UPDATE action
 */
reducers[`app/signal_events/UPDATE`] = (state: SignalEventsState, { updates, background }) => {
  // поиск индекса элемента который необходимо обновить
  const index = state.items.findIndex((e) => e.id === updates.id)

  if (index === -1) return state

  const result = { ...state }
  const initialItem = state.items[index]

  result.items = state.items.map((e) => (e.id === updates.id ? { ...e, ...updates } : e))

  // if item that needs to be updated was selected, we need to change selected item to new signal event with new id
  // and switch mode from 'edit' to 'view'
  if (updates.id === state.selectedItemId) {
    result.selectedItemId = updates.id
    result.selectedItem = { ...result.items[index] }
    if (!background) result.mode = 'view'
  }

  // если id сигнальной ситуации изменился, то предыдущую версию надо сохранить в deletedRulesCache
  if (updates.rules) {
    result.deletedRulesCache = { ...result.deletedRulesCache }

    for (const rule of initialItem.rules) {
      result.deletedRulesCache[rule.id] = initialItem
    }
  }

  return result
}

reducers[ADD_EVENT_TO_DELETED_CACHE] = (state, { ruleId, signalEvent }) => {
  const deletedRulesCache = { ...(state as any).deletedRulesCache }
  deletedRulesCache[ruleId] = signalEvent

  return { ...state, deletedRulesCache }
}

reducers[RULE_UPDATED] = (state: SignalEventsState, { updates }: { updates: SignalEventRule }) => {
  return produce(state, (draft) => {
    const index = draft.items.findIndex(
      (se) => se.id === updates.signal_event_id && se.rules.some((r) => r.id === updates.id)
    )

    if (index !== -1) {
      const item = draft.items[index]
      const rule = item.rules.find((r) => r.id === updates.id)
      Object.assign(rule, updates)
    }
  })
}

reducers['app/signal_events_types/SET'] = (state: SignalEventsState, { payload }) => {
  return payload ? { ...state, types: payload, isTypesLoaded: true } : state
}

reducers['app/signal_events_types/SELECT'] = (state: SignalEventsState, { payload }) => {
  return { ...state, selectedTypeId: payload, selectedItem: null, selectedItemId: null }
}

reducers['app/signal_events_types/UPDATE'] = (state: SignalEventsState, { payload }) => {
  const index = state.types.findIndex((e) => e.id === payload.id)
  if (index === -1) return state

  const type = state.types[index]
  const types = [...state.types.slice(0, index), { ...type, ...payload }, ...state.types.slice(index + 1)]
  return { ...state, types }
}

reducers['app/signal_events_types/DELETE'] = (state: SignalEventsState, { payload }) => {
  return { ...state, types: state.types.filter((t) => t.id !== payload.id) }
}

reducers['app/signal_events_types/CREATE'] = (state: SignalEventsState, { payload }) => {
  return { ...state, types: [...state.types, payload] }
}

const seReducer = (state: SignalEventsState = initialState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default seReducer
