import { useIntl } from 'react-intl'
import InputRow from '../../../../shared/Inputs/InputRow'
import MultiSelect from '../../../../shared/Inputs/MultiSelect'
import { useMemo } from 'react'
import { IPropertyDescription } from 'au-nsi/catalogs'
import Dropdown from '../../../../shared/Inputs/Dropdown'

interface Props {
  editing: boolean
  schemaTree: IPropertyDescription[]
  onChange: (updated: IPropertyDescription) => void
}

/**
 * Дополнительные настройки схемы для паспортов устройств:
 * - выбор полей, которые являются идентификаторами
 * - выбор поля, обозначающее адрес (чтобы копировать адрес устройства в это поле паспорта)
 */
const ExtraPassportSettings = ({ editing, schemaTree, onChange }: Props) => {
  const intl = useIntl()

  const identifierOptions: Array<{ id: string; name: string }> = []
  const addressOptions: Array<{ value: string; title: string }> = []
  const selection: string[] = []

  let addressProperty: IPropertyDescription
  addressOptions.push({ value: undefined, title: intl.formatMessage({ id: 'common.no' }) })

  const schema = useMemo(() => flatShemaTree(schemaTree), [schemaTree])

  for (const property of schema) {
    const { type } = property

    const canBeIdentifier = property.required && property.name && (type === 'string' || type === 'number')
    const canBeAddress = property.name && type === 'string'

    if (property.is_identifier) selection.push(property.id)
    if (property.is_identifier || canBeIdentifier) identifierOptions.push(property)
    if (canBeAddress) addressOptions.push({ value: property.id, title: property.name })
    if (property.autofill_mode === 'address') addressProperty = property
  }

  const handleIdentifiersChange = (ids: string[]) => {
    const added = ids.find((id) => !selection.includes(id))
    const removed = selection.find((id) => !ids.includes(id))

    const id = added || removed
    const is_identifier = added ? true : false

    const index = schema.findIndex((p) => p.id === id)
    onChange({ ...schema[index], is_identifier })
  }

  const handleAddressChange = (id: string) => {
    const newAddressProperty = schema.find((p) => p.id === id)
    onChange({ ...addressProperty, autofill_mode: undefined })
    onChange({ ...newAddressProperty, autofill_mode: 'address' })
  }

  return (
    <div className="system__grid" style={{ marginTop: '2rem' }}>
      <InputRow label={intl.formatMessage({ id: 'catalogs.identifiers' })}>
        <MultiSelect
          selection={selection}
          options={identifierOptions}
          onChange={handleIdentifiersChange}
          disabled={!editing}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'catalogs.address_property' })}>
        <Dropdown
          name="address"
          value={addressProperty?.id}
          options={addressOptions}
          onChange={handleAddressChange}
          disabled={!editing}
          disabledMode="input"
        />
      </InputRow>
    </div>
  )
}

const flatShemaTree = (tree: IPropertyDescription[]) => {
  const flatted = []
  const recursiveFlat = (nodeArray: IPropertyDescription[]) => {
    for (const node of nodeArray) {
      if (node.type === 'group') recursiveFlat(node.children)
      else flatted.push(node)
    }
  }

  recursiveFlat(tree)
  return flatted
}

export default ExtraPassportSettings
