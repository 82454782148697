import { useCallback } from 'react'
import produce from 'immer'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface InstanceObject {
  [key: string]: any
}

interface IProps<T> {
  /** Возможно также передавать путь в виде 'key1.key2.key3...' */
  propertiesKey: string
  setter: (state: ((prevState: T) => T) | T) => void
  deps: unknown[]
}

const useInstanceFormCallback = <T extends InstanceObject>(props: IProps<T>) => {
  const onChange = useCallback((schemaId: IPropertyDescription['id'], value: any) => {
    props.setter((prev) => {
      return produce(prev, (draft: T) => {
        const path = props.propertiesKey.split('.')

        let values = draft
        for (const key of path) values = values[key]
        ;(values as Object)[schemaId] = value
      })
    })
  }, props.deps)

  return onChange
}

export default useInstanceFormCallback
