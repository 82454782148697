import { IPropertyDescription } from 'au-nsi/catalogs'

export const validateItemValues = (schema: IPropertyDescription[], values: Record<string, any>) => {
  let res = true

  for (const schemaRow of schema) {
    if (!schemaRow.required) continue

    switch (schemaRow.type) {
      case 'number':
        res = res && !isNaN(values[schemaRow.id])
        break
      case 'date':
      case 'string':
        res = res && typeof values[schemaRow.id] === 'string' && values[schemaRow.id].length > 0
        break
      case 'boolean':
        res = res && typeof values[schemaRow.id] === 'boolean'
        break
    }

    if (schemaRow.type === 'group') res = res && validateItemValues(schemaRow.children, values)
  }

  return res
}
