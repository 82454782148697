import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import usePasswordRules from '../../hooks/usePasswordRules'
import { API_URL } from '../../shared/constants'
import InputRow from '../../shared/Inputs/InputRow'
import PasswordInput from '../../shared/Inputs/PasswordInput'
import Loader from '../../shared/Utils/Loader'
import { getResponseErrorMessage } from './auth.utils'
import { AuthWrapper } from './AuthWrapper'

/**
 * Форма восстановления пароля 2: задание нового пароля
 */
const PasswordReset2 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState('')

  const [pwd, setPwd] = useState('')
  const [pwdConfirm, setPwdConfirm] = useState('')

  const { validator, help } = usePasswordRules(true)

  const search = new URLSearchParams(location.search)
  const username = search.get('username')
  const code = search.get('code')

  useEffect(() => {
    if (!username || !code) navigate('/login')
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(API_URL + '/nsi/auth/reset/confirm', { username, code, password: pwd })
      .then(() => setCompleted(true))
      .catch((err) => {
        setError(
          err.response && err.response.status < 429
            ? intl.formatMessage({ id: 'auth.reset.final_error' })
            : getResponseErrorMessage(err, intl)
        )
      })
      .finally(() => setLoading(false))
  }

  const pwdStyle = !validator(pwd) ? { border: '1px solid var(--danger-text)' } : undefined
  const pwdConfirmStype = pwd !== pwdConfirm ? { border: '1px solid var(--danger-text)' } : undefined

  return (
    <AuthWrapper showSignin={true} title={intl.formatMessage({ id: 'auth.reset.title' })}>
      <div className="login-form">
        {!completed && (
          <form onSubmit={handleSubmit}>
            <InputRow
              label={intl.formatMessage({ id: 'auth.reset.new_password' })}
              help={<span className="text--prewrap">{help}</span>}
            >
              <PasswordInput value={pwd} name="" onChange={setPwd} style={pwdStyle} />
            </InputRow>
            <InputRow label={intl.formatMessage({ id: 'auth.reset.new_password_confirm' })}>
              <PasswordInput value={pwdConfirm} name="" onChange={setPwdConfirm} style={pwdConfirmStype} />
            </InputRow>

            <div className="login-form__error">{error}</div>

            <button
              type="submit"
              className="nsi-button default"
              disabled={loading || pwd.length < 4 || pwd !== pwdConfirm}
              style={{ display: 'block', marginLeft: 'auto' }}
            >
              {loading ? <Loader /> : intl.formatMessage({ id: 'auth.reset.submit' })}
            </button>
          </form>
        )}

        {completed && (
          <div className="text--success" style={{ textAlign: 'center', margin: '20px' }}>
            {intl.formatMessage({ id: 'auth.reset.final_success' })}
          </div>
        )}
      </div>
    </AuthWrapper>
  )
}

export default PasswordReset2
