import { UserRole } from 'au-nsi/user'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../../icons/filter.svg'
import { useAppSelector } from '../../../redux/store'
import CreateButton from '../../../shared/Forms/CreateButton'
import HideableLeftMenu from '../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'
import Toggle from '../../../shared/Inputs/Toggle'
import Tree from '../../../shared/Tree/Tree'
import useTree from '../../../shared/Tree/useTree'
import CollapseButton from '../../../shared/Utils/CollapseButton'
import { highlightSearch } from '../../../utils/search'
import { User } from '../collection.interfaces'
import { getCreateOptions } from '../collection.utils'
import usersActions from '../Users/users.actions'
import { getUserStatus } from '../Users/users.utils'
import FiltersModal from './FiltersModal'
import usersKeysService from './usersKeys.service'
import styles from './usersTree.module.css'

const UsersTree = ({ allowUsersCreate, allowRolesCreate, ...props }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const showUsernames = useAppSelector((state) => state.users.showUsernames)
  const filters = useAppSelector((state) => state.users.filters)

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [search, setSearch] = useState('')

  const searchTerms = search.split(/\s+/g).filter((v) => v)
  const isFiltersApplied =
    filters.organization_type_id != null || filters.organization_id != null || filters.status != null

  // построить дерево (узлы - это роли, а листья - пользователи) и подменить id на уникальные ключи
  const nodes = useMemo(() => {
    const searchOrFilter = !!search || isFiltersApplied
    const result = []

    for (const role of props.roles) {
      const users = props.users.get(role.id) || []
      const children = []

      for (const user of users) {
        const status = getUserStatus(user)
        if (filters.status && filters.status !== status) continue
        if (filters.organization_id && filters.organization_id !== user.organization_id) continue
        if (filters.organization_type_id && filters.organization_type_id !== user.organization_type_id) continue

        const userMatch = highlightSearch(showUsernames ? user.username : user.name, searchTerms)
        if (search && !userMatch.isMatch) continue

        children.push({ ...user, name: userMatch.result, id: usersKeysService.encryptUser(user.id) })
      }

      const roleMatch = highlightSearch(role.name, searchTerms)

      // при поиске скрываем роль если ни она ни ее пользователи не попали в результат
      if (searchOrFilter && !roleMatch.isMatch && children.length === 0) continue

      result.push({ ...role, name: roleMatch.result, id: usersKeysService.encryptRole(role.id), children })
    }

    return result
  }, [props.roles, props.users, filters, search, showUsernames])

  const tree = useTree({ persistenceStrategy: 'inmemory', key: props.treeKey })

  useEffect(() => {
    if (props.selectedRoleId) {
      const id = usersKeysService.encryptRole(props.selectedRoleId)
      return tree.handleSelect(id)
    }

    if (props.selectedUserId) {
      const id = usersKeysService.encryptUser(props.selectedUserId)
      return tree.handleSelect(id)
    }
  }, [props.selectedRoleId, props.selectedUserId])

  const createOptions = getCreateOptions(intl, allowUsersCreate, allowRolesCreate)
  const handleCreate = (option: string) => (option === 'user' ? props.onUserCreate() : props.onRoleCreate())

  const handleSelect = (id: string) => {
    const isUser = usersKeysService.isKeyBelongsToUser(id)
    const handler = isUser ? props.onUserSelect : props.onRoleSelect
    const nodeId = isUser ? usersKeysService.decryptUser(id) : usersKeysService.decryptRole(id)

    handler(nodeId)
  }

  // свернуть/развернуть все роли
  const toggleRoles = (isCollapsed: boolean) => {
    const updates = isCollapsed
      ? new Set<number>()
      : new Set(props.roles.map((r) => usersKeysService.encryptRole(r.id)))
    tree.setOpenedNodes(updates)
  }

  // если все роли свернуты, то при поиске автоматически их разворачиваем,
  // чтобы сразу были видны результаты поиска
  const handleSearch = (v: string) => {
    if (v && tree.openedNodes.size === 0) toggleRoles(false)
    setSearch(v.toLowerCase())
  }

  const renderUser = (user: User) => {
    return (
      <span className="inline-flex tpl-node__padding" data-id={user.id} style={{ paddingLeft: '10px', width: '100%' }}>
        <span>{user.name}</span>
        {props.renderUserIcon && props.renderUserIcon(user)}
      </span>
    )
  }

  const renderRole = (role: UserRole) => {
    return (
      <span className="link_normalize inline-flex tpl-node__padding" data-id={role.id} style={{ width: '100%' }}>
        <span>{role.name}</span>
        {props.renderRoleIcon && props.renderRoleIcon(role)}
      </span>
    )
  }

  const renderNode = ({ node }) => {
    if ('role_id' in node) return renderUser({ ...node, id: usersKeysService.decryptUser(node.id) })
    return renderRole({ ...node, id: usersKeysService.decryptRole(node.id) })
  }

  return (
    <HideableLeftMenu>
      <div className="nsi-tree_title">
        {props.title}
        <div className="nsi__title-controls">
          <div className={'nsi__row-control'} style={{ paddingRight: 0 }}>
            <CollapseButton
              className="nsi__title-icon"
              isCollapsed={tree.openedNodes.size === 0}
              onChange={toggleRoles}
            />
          </div>

          <div
            className="nsi__row-control"
            style={allowRolesCreate || allowUsersCreate ? { paddingRight: '0' } : { paddingRight: '8px' }}
          >
            <FilterIcon
              data-active={isFiltersApplied}
              className={`nsi__title-icon ${styles.filterIcon}`}
              onClick={() => setFiltersOpen(true)}
            />
          </div>

          {(allowRolesCreate || allowUsersCreate) && (
            <div className="nsi__row-control">
              <CreateButton options={createOptions} onSelect={handleCreate} disabled={props.isCreating} />
            </div>
          )}
        </div>
      </div>

      <div className="nsi-tree__header-row" style={{ marginLeft: '6px' }}>
        <Toggle name="" checked={showUsernames} onChange={() => dispatch(usersActions.toggleUsernames())} size="10px" />
        {intl.formatMessage({ id: 'nsi.user.show_usernames' })}
      </div>

      <div className="nsi-tree__header-row" style={{ marginTop: '1px' }}>
        <SearchInput onChange={handleSearch} />
      </div>

      <Tree nodes={nodes} nodeContentRenderer={renderNode} {...tree} handleSelect={handleSelect} />

      {filtersOpen && <FiltersModal onClose={() => setFiltersOpen(false)} />}
    </HideableLeftMenu>
  )
}

interface Props {
  allowRolesCreate: boolean
  allowUsersCreate: boolean
  isCreating?: boolean
  isLoaded?: boolean
  onRoleCreate?: () => void
  onRoleSelect: (id: UserRole['id']) => void
  onUserCreate?: () => void
  onUserSelect: (id: User['id']) => void
  renderRoleIcon?: (role: UserRole) => ReactNode
  renderUserIcon?: (user: User) => ReactNode
  roles: UserRole[]
  selectedRoleId: number
  selectedUserId: number
  users: Map<number, User[]>
  treeKey: string
  title: string
}

export default UsersTree
