import i18n from '../../../translations/i18n'
import export_en from './export_en.json'
import export_ru from './export_ru.json'
import import_en from './import_en.json'
import import_ru from './import_ru.json'

i18n.append([
  { prefix: 'export.', en: export_en, ru: export_ru },
  { prefix: 'import.', en: import_en, ru: import_ru },
])
