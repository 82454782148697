import { useIntl } from 'react-intl'
import Links from '../TopMenu/Nav/Links'
import AppRoutes from '../TopMenu/Nav/Routes'
import { librariesRoutes } from './libraries.routes'
import HideableLeftMenu from '../../shared/HideableLeftMenu/HIdeableLeftMenu'

const LibrariesPage = () => {
  const intl = useIntl()

  const icon = <span className="system__nav-icon" />

  const navMenu = (
    <HideableLeftMenu>
      <div className="nsi-tree_title">{intl.formatMessage({ id: 'nav.libraries' })}</div>

      <div className="system__nav">
        <Links routes={librariesRoutes} className="system__nav-item" icon={icon} />
      </div>
    </HideableLeftMenu>
  )

  return (
    <div className="nsi nsi-journal">
      {navMenu}
      <div className="nsi-main__wrapper" style={{ position: 'relative' }}>
        <AppRoutes routes={librariesRoutes} fallback="" />
      </div>
    </div>
  )
}

export default LibrariesPage
