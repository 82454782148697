import groupsApi, { PatchGroupObject } from './equipmentGroups.api'
import { groupsSliceActions } from './groups.reducer'
import { Equipment } from 'au-nsi/equipment'
import { ReduxState } from '../../../redux/store.types'
import { equipmentUpdated } from '../../Nsi/nsi.actions'
import { IGroup } from 'au-nsi-elevator/groups'

const loadGroups = () => async (dispatch) => {
  return groupsApi.getGroups().then((r) => r && dispatch(groupsSliceActions.load(r)))
}

const createGroup = (group: Omit<IGroup, 'id'>) => async (dispatch) => {
  return groupsApi.postGroup(group).then((r) => r && dispatch(groupsSliceActions.groupCreated(r)))
}

const updateGroup = (group: PatchGroupObject) => async (dispatch) => {
  return groupsApi.patchGroup(group).then((r) => r && dispatch(groupsSliceActions.groupUpdated(r)))
}

const deleteGroup = (id: IGroup['id']) => async (dispatch) => {
  return groupsApi.deleteGroup(id).then((r) => r && dispatch(groupsSliceActions.groupRemoved(r)))
}

const changeEquipmentGroup = (groupId: IGroup['id'], equipmentId: Equipment['id']) => async (dispatch, getState) => {
  const equipment = (getState() as ReduxState).nsi.equipment.find((e) => e.id === equipmentId)
  if (!equipment || equipment.protocol !== 'LP001') return

  return groupsApi
    .patchEquipmentGroup(equipmentId, equipment.attributes, groupId)
    .then((r) => r && dispatch(equipmentUpdated(equipmentId, r)))
}

const groupsActions = { loadGroups, createGroup, updateGroup, deleteGroup, changeEquipmentGroup }
export default groupsActions
