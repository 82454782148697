import React from 'react'
import idb from '../utils/idb'

/**
 * Хук для кэширования данных загружаемых с сервера
 * @param key уникальный ключ
 * @param fetcher функция запрашивающая данные
 */
const useCache = <T>(key: string, fetcher: () => Promise<T>, options: IOptions<T> = {}) => {
  const [state, setState] = React.useState({ data: options.defaultValue, isLoading: true })

  React.useEffect(() => {
    // если выставлен этот флаг, значит данные пока не нужны и загружать их надо будет позже
    if (options.delayLoading) return

    // данные уже были загружены
    if (!state.isLoading) return

    let canceled = false

    // данные сохраненные в локальном кэше
    idb.get(key).then((data) => {
      if (data != null && !canceled) {
        setState((prev) => (prev.isLoading ? { ...prev, data } : prev))
      }
    })

    // загрузка данных с сервера и обновление кэша
    fetcher().then((data) => {
      if (data != null && !canceled) {
        setState({ data, isLoading: false })
        idb.set(key, data)
      }
    })

    // отмена, если компонент удалился еще до того как данные успели загрузиться
    return () => {
      canceled = true
    }
  }, [options.delayLoading])

  return state
}

interface IOptions<T> {
  defaultValue?: T
  delayLoading?: boolean
}

export default useCache
