import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import { ParameterDn } from './params.interfaces'

interface ConvertOptions {
  fromUnit: SiUnit
  fromPrefix?: SiPrefix
  toUnit: SiUnit
  toPrefix?: SiPrefix
}

// convert value to a different unit, e.g. from V to kV, or from seconds to hours
export const convert = (value: number, options: ConvertOptions): number => {
  let result = value

  const fromBase = options.fromUnit.base_unit || options.fromUnit.id
  const toBase = options.toUnit.base_unit || options.toUnit.id

  if (fromBase !== toBase) {
    console.error(`can not convert from ${options.fromUnit.id} to ${options.toUnit.id}`)
    return result
  }

  if (options.fromPrefix) {
    result *= Math.pow(10, options.fromPrefix.exponent)
  }

  const factor1 = options.fromUnit.base_unit_factor
  if (factor1) result *= factor1

  const factor2 = options.toUnit.base_unit_factor
  if (factor2) result /= factor2

  if (options.toPrefix) {
    result /= Math.pow(10, options.toPrefix.exponent)
  }

  return result
}

export const convertParameter = (value: number, parameter: ParameterDn) => {
  if (!parameter) return value

  return convert(value, {
    fromUnit: parameter.unit,
    fromPrefix: parameter.prefix,
    toUnit: parameter.display_unit,
    toPrefix: parameter.display_prefix,
  })
}

/**
 * Отформатировать значение для отображения пользователю. Само значение будет преобразовано
 * из единиц СИ использующихся для хранения в единицы заданные для отображения. Также
 * будет добавлен суффикс с единицей измерения в которых выводится значение.
 */
export const formatValue = (value: number, parameter: ParameterDn, includeUnit = true, precision?: number): string => {
  if (!parameter) return formatNumber(value, precision)

  const convertedValue = convert(value, {
    fromUnit: parameter.unit,
    fromPrefix: parameter.prefix,
    toUnit: parameter.display_unit,
    toPrefix: parameter.display_prefix,
  })

  let result = formatNumber(convertedValue, precision)
  if (includeUnit) result += formatUnit(parameter, 'space')

  return result.trimEnd()
}

export const formatUnit = (parameter: ParameterDn, format: 'comma' | 'space' | 'none') => {
  const shouldSkip =
    !parameter ||
    !parameter.display_unit ||
    parameter.display_unit.id === 'unit' ||
    parameter.display_unit.id === 'fraction'

  if (shouldSkip) return ''

  const prefix = parameter.display_prefix?.symbol || ''
  const unit = parameter.display_unit.symbol

  return unitFormats[format] + prefix + unit
}

const unitFormats = { comma: ', ', space: ' ', none: '' }

export const formatNumber = (value: number, precision?: number): string => {
  if (precision) return value.toFixed(precision)

  const abs = Math.abs(value)

  if (value > 1e6) return value.toExponential(2)
  else if (Number.isInteger(abs)) return value.toString()
  else if (abs < 1e-3) return value.toExponential(2)
  else if (abs < 1) return value.toFixed(3)
  else if (abs < 10) return value.toFixed(2)
  else if (abs < 100) return value.toFixed(1)
  else return value.toFixed(0)
}

/**
 * Определить является ли тип данных целочисленным (т.е. любой тип кроме f32 и f64)
 */
export const isInteger = (parameterType: string) => {
  return parameterType && !parameterType.startsWith('f')
}

/**
 * Получить список единиц измерения в которые может быть преобразована единица с unitId
 */
export const getRelatedUnits = (
  unitId: string,
  units: { [id: string]: SiUnit },
  prefixes: { [id: string]: SiPrefix }
) => {
  const base = units[unitId]
  if (!base) return []

  const baseUnit = base.base_unit || base.id
  const results: Array<{ unit: SiUnit; prefix: SiPrefix }> = []

  for (const unit of Object.values(units)) {
    const canBeConverted = unit.id === baseUnit || unit.base_unit === baseUnit

    if (canBeConverted) {
      results.push({ unit, prefix: null })

      if (unit.prefixable) {
        for (const prefix of Object.values(prefixes)) {
          results.push({ unit, prefix })
        }
      }
    }
  }

  return results
}

/**
 * Выбрать наиболее подходящую единицу измерения для отображения интервала времени
 */
export const selectTimeUnit = (seconds: number, timeUnits: SiUnit[]) => {
  if (!seconds) {
    return timeUnits.find((u) => u.id === 's')
  }

  for (const unit of timeUnits) {
    if (unit.id !== 's' && unit.base_unit !== 's') {
      throw new Error(`Only time units can be passed, use selectTimeUnits selectors for this`)
    }

    const factor = unit.base_unit_factor || 1
    if (factor <= seconds) return unit
  }

  return timeUnits[timeUnits.length - 1]
}

/**
 * Отформатировать продолжительность интервала времени
 */
export const formatDuration = (seconds: number, timeUnits: SiUnit[]) => {
  // основная единица измерения
  const unit1 = selectTimeUnit(seconds, timeUnits)
  if (!unit1) return `${seconds} s`

  const factor1 = unit1.base_unit_factor || 1
  const value1 = Math.floor(seconds / factor1)

  const remainder = seconds - value1 * factor1

  // если остаток слишком мал по сравнению с основной единицей, то не показываем его
  // (например вместо 3 мин 10 мс показываем просто 3 мин)
  if (remainder * 100 < seconds) {
    return `${value1} ${unit1.symbol}`
  }

  const unit2 = selectTimeUnit(remainder, timeUnits)
  const factor2 = unit2.base_unit_factor || 1
  const value2 = Math.floor(remainder / factor2)

  return `${value1} ${unit1.symbol} ${value2} ${unit2.symbol}`
}
