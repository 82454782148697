import { useIntl } from 'react-intl'
import Links from '../TopMenu/Nav/Links'
import userRoutes from './user.routes'
import AppRoutes from '../TopMenu/Nav/Routes'
import Logout from '../TopMenu/Nav/Logout'
import './intl/user.intl'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadOrganizations } from '../Catalogs/Organizations/organizations.actions'
import usersActions from '../Collections/Users/users.actions'
import { Route } from 'react-router'
import RequestPage from './Moderation/RequestPage'
import moderationActions from './Moderation/moderation.actions'
import rolesActions from '../Collections/Roles/roles.actions'
import { wsSubscribe } from '../../services/ws/ws.events'
import HideableLeftMenu from '../../shared/HideableLeftMenu/HIdeableLeftMenu'
import { IModerationRequest } from 'au-nsi/moderation'

const UserPage = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    return wsSubscribe<IModerationRequest>('moderation_requests', ({ method, payload }) => {
      if (method === 'create') dispatch(moderationActions.insert(payload))
      if (method === 'update') dispatch(moderationActions.requestUpdated(payload))
      if (method === 'delete') dispatch(moderationActions.requestRemoved(payload?.id || (payload as any)))
    })
  }, [])

  useEffect(() => {
    dispatch(usersActions.loadItems())
    dispatch(rolesActions.loadItems())
    dispatch(loadOrganizations())

    return () => {
      dispatch(moderationActions.clean())
    }
  }, [])

  const icon = <span className="system__nav-icon" />
  const style = { height: 'calc(100vh - var(--header-height) / 2)' }

  return (
    <div className="nsi" style={style}>
      <HideableLeftMenu style={style}>
        <div className="nsi-tree_title">{intl.formatMessage({ id: 'nav.user' })}</div>
        <div className="system__nav">
          <Links routes={userRoutes} className="system__nav-item" icon={icon} />
          <Logout className="system__nav-item" />
        </div>
      </HideableLeftMenu>

      <div className="nsi-main__wrapper" style={style}>
        <AppRoutes routes={userRoutes} fallback={'/'}>
          <Route path="requests/:id" element={<RequestPage />} />
        </AppRoutes>
      </div>
    </div>
  )
}

export default UserPage
