import React from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from '../../../shared/Modal/Modal'

export class ImportModal extends React.Component<Props, State> {
  state = { selectedId: null, search: '' }

  private handleSelect = (e) => {
    const { id } = e.target.dataset
    this.setState({ selectedId: id })
  }

  private handleImport = () => {
    const { selectedId } = this.state

    if (selectedId != null) {
      this.props.onImport(selectedId)
    }
  }

  private handleCancel = () => {
    this.props.onClose()
  }

  private handleSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() })
  }

  // отфильтровать оборудование у которого параметры еще не настроены и по строке поиска
  private filterSearch = (d: ImportSource) => {
    return d.name.toLowerCase().includes(this.state.search) && d.id !== this.props.targetId
  }

  render() {
    if (!this.props.isOpen) {
      return null
    }

    const equipment = this.props.sources.filter(this.filterSearch).map((source) => {
      const { id } = source
      const className = id === this.state.selectedId ? 'nsi-import__row is-selected' : 'nsi-import__row'

      return (
        <div key={source.id} data-id={source.id} onClick={this.handleSelect} className={className}>
          <span className="registrator-cell__color" style={{ backgroundColor: source.color }} />
          <span>{source.name}</span>
        </div>
      )
    })

    const noResults = (
      <div className="text--gray text--center font--small">
        <FormattedMessage id="common.no_results" />
      </div>
    )

    return (
      <Modal onClose={this.props.onClose}>
        <div className="nsi-import__search">
          <input className="nsi-input" value={this.state.search} onChange={this.handleSearch} placeholder="Search..." />
        </div>

        <div className="nsi-import__list">{equipment.length > 0 ? equipment : noResults}</div>

        <div>
          <button className="nsi-button inverted" onClick={this.handleCancel}>
            <FormattedMessage id="common.cancel" />
          </button>
          <button className="nsi-button default" onClick={this.handleImport}>
            <FormattedMessage id="nsi.import" />
          </button>
        </div>
      </Modal>
    )
  }
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onImport: (id: string) => void
  sources: ImportSource[]
  // id объекта куда будет произведен импорт (для того чтобы не выводить его самого в списке)
  targetId: string
}

interface State {
  selectedId: string
  search: string
}

export interface ImportSource {
  id: string
  name: string
  color: string
}

export default ImportModal
