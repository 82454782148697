import { useEffect, useState } from 'react'
import ws from '../services/ws/ws'
import LastValuesLoader from '../services/data/LastValuesLoader'
import { wsEvents } from '../services/ws/ws.events'

const useParameterValue = (equipmentId: string, parameterId: string, type: 'processes' | 'irregular' = 'irregular') => {
  const [value, setValue] = useState<number>(undefined)

  const loadValue = () => {
    LastValuesLoader.load([{ parameters: [parameterId], type, id: equipmentId }], (data) => {
      const parameterValue = data.has(equipmentId) ? data.get(equipmentId)[parameterId]?.value : undefined
      setValue(parameterValue)
    })
  }

  useEffect(() => {
    loadValue()
    const subscriptionId = equipmentId + '_' + parameterId

    ws.subscribe({
      id: subscriptionId,
      onData: (data) => {
        const parameterValues = data.get(equipmentId)[parameterId]
        if (parameterValues?.length > 0) setValue(parameterValues[parameterValues.length - 1])
      },
      query: [{ device_id: equipmentId, parameters: [parameterId] }],
    })

    const unsubscribe = wsEvents.subscribe(({ resource, payload }) => {
      if (resource === 'ws-connection' && payload === 'reconnected') loadValue()
    })

    return () => {
      ws.unsubscribe(subscriptionId)
      unsubscribe()
    }
  }, [])

  return value
}

export default useParameterValue
