import { IChartComponent, IChartThreshold } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import { Incident } from 'au-nsi/signal-events'
import { COLORS } from '../../shared/constants'
import { SignalEvent } from '../SignalEvents/se.interfaces'
import { getIncidentParameters, getIncidentRule } from './incidents.utils'

/**
 * Сгенерировать настройки линейных графиков для отображения на них сигналной ситуации
 */
export const generateCharts = (options: GenerateChartsOptions): IChartComponent[] => {
  const { incident, equipment } = options
  const width = window.innerWidth
  const h = 400 / width // 400px
  const w = 0.5 // половина экрана

  const result: IChartComponent[] = []
  const thresholds = extractThresholds(incident, options.seRules)
  const parameters: Array<{ parameter_id: string; devices: string[] }> = []
  const aggregationMode = 1 | 2 | 4 // draw min + avg + max

  // из деталей инцидента извлекаем информацию по каким устройствам и параметрам он произошел
  // и преобразуем из формата {[device_id]: parameters[]} в {[parameter_id]: devices[]}
  for (const [device_id, params] of Object.entries(getIncidentParameters(incident))) {
    for (const parameter_id of params) {
      const record = parameters.find((r) => r.parameter_id === parameter_id)

      if (record) record.devices.push(device_id)
      else parameters.push({ parameter_id, devices: [device_id] })
    }
  }

  let id = 1
  let index = 0

  // для каждого параметра генерируем настройки графика и располагаем их последовательно по сетке
  for (const { parameter_id, devices } of parameters) {
    const lines = devices.map((device_id) => {
      const device = equipment.get(device_id)
      const color = device ? device.color : '#fff'

      return { device_id, parameter_id, color }
    })

    result.push({
      id,
      dashboard_id: '0',
      type: 'linear_chart',
      x: (index * w) % 1,
      y: h * Math.floor(index / 2),
      w,
      h,
      z: 1,
      settings: {
        version: 2,
        title: parameter_id,
        axes: [{ position: 'right', lines, thresholds: thresholds[parameter_id] || [] }],
        aggregationMode,
      },
    })

    id += 1
    index += 1
  }

  return result
}

/**
 * Извлечь линии предельных значений из формулы по которой рассчитывался инцидент
 */
const extractThresholds = (
  incident: Incident,
  seRules: Map<number, SignalEvent>
): Record<string, IChartThreshold[]> => {
  // недостоверные значения (выбросы)
  if (incident.type === 'dq_unreliable') {
    return extractUnreliableThresholds(incident)
  }

  // инцидент по пользовательской формуле
  const result: Record<string, IChartThreshold[]> = {}
  const rule = getIncidentRule(incident, seRules)
  if (!rule) return result

  for (const c of rule.formula.conditions) {
    // пропускаем условия на скорость изменения параметра, т.к. их не отобразить на графике
    const operator = c.condition.operator
    if (operator === 'rate_gt' || operator === 'rate_lt') continue

    const parameter = c.condition.parameter
    const color = rule.level === 'warning' ? COLORS.warning : COLORS.danger
    const values = Array.isArray(c.condition.value) ? c.condition.value : [c.condition.value]
    const thresholds = result[parameter] || []

    for (const value of values) {
      thresholds.push({ value, line: 'dashed', color })
    }

    result[parameter] = thresholds
  }

  return result
}

/**
 * В сигнальной ситуации из-за недостоверных значений границы достоверности
 * сохраняются в самом объекте инцидента в поле details
 */
const extractUnreliableThresholds = (incident: Incident) => {
  const result: Record<string, IChartThreshold[]> = {}

  const color = COLORS.warning
  const line = 'dashed'

  for (const parameters of Object.values(incident.details)) {
    if (!parameters || typeof parameters !== 'object') continue

    for (const [parameter, limits] of Object.entries(parameters)) {
      if (!limits || typeof limits !== 'object') continue

      const thresholds = result[parameter] || []
      const min = limits.min_threshold
      const max = limits.max_threshold

      if (min != null) thresholds.push({ value: min, line, color })
      if (max != null) thresholds.push({ value: max, line, color })
      result[parameter] = thresholds
    }
  }

  return result
}

interface GenerateChartsOptions {
  incident: Incident
  equipment: Map<string, Equipment>
  seRules: Map<number, SignalEvent>
}
