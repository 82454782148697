import { generateActions } from '../Collections/collection.actions'
import { Transmitter } from './transmitters.types'

const API_URL = '/nsi/v1/transmitters/'

const actions = generateActions<Transmitter>('transmitters', API_URL, { skipDiffer: true })

// при добавлении нового трансмиттера, перед отображением формы необходимо знать
// тип создаваемого трансмиттера
const setCreateMode = (transmitterType: string) => ({
  type: `app/transmitters/MODE`,
  mode: 'create',
  transmitterType,
})

const transmittersActions = { ...actions, setCreateMode }
export default transmittersActions
