import { HelpTooltip, Select } from '@alterouniversal/au-react-components'
import produce from 'immer'
import { selectPreferences } from 'pages/App/app.selectors'
import { updatePreferences } from 'pages/App/user.actions'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'
import Form from 'shared/Forms/Form'
import PlusButton from 'shared/Forms/PlusButton'
import RemoveButton from 'shared/Forms/RemoveButton'
import MultiSelect from 'shared/Inputs/MultiSelect'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import { useDeviceDashboards } from '../DeviceDashboards/DeviceDashboards'

interface Props {
  onClose: () => void
}

/**
 * Модальное окно для настройки главной страницы карточки устройства
 */
const HomeDashboardsModal = ({ onClose }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const preferences = useAppSelector(selectPreferences)
  const dashboards = useDeviceDashboards(null)
  const equipment = useAppSelector((state) => state.nsi.equipment)
  const tags = useAppSelector((state) => state.tags)

  const [draft, setDraft] = useState(preferences?.home_dashboards || [])

  const addCondition = () => {
    setDraft((prev) => [...prev, { dashboard_id: null, match: 'devices', devices: [], tags: [] }])
  }

  const removeCondition = (index: number) => {
    setDraft((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  const handleChange = (value, name: string) => {
    const [key, i] = name.split(':')
    const index = +i

    setDraft((prev) =>
      produce(prev, (d) => {
        d[index][key] = value
      })
    )
  }

  const handleSave = () => {
    dispatch(updatePreferences({ home_dashboards: draft }))
    onClose()
  }

  const dashboardOptions = dashboards.filter((d) => !d.is_system).map((d) => ({ value: d.id, label: d.name }))

  const conditions = [
    { value: 'devices', label: intl.formatMessage({ id: 'map.device.home_dashboards.match.devices' }) },
    { value: 'tags', label: intl.formatMessage({ id: 'map.device.home_dashboards.match.tags' }) },
    { value: 'all', label: intl.formatMessage({ id: 'map.device.home_dashboards.match.all' }) },
  ]

  const rows = draft.map((row, i) => {
    return (
      <tr key={i}>
        <td>
          <Select
            name={'dashboard_id:' + i}
            options={dashboardOptions}
            value={row.dashboard_id}
            onChange={handleChange}
            required={true}
          />
        </td>
        <td>
          <Select name={'match:' + i} options={conditions} value={row.match} onChange={handleChange} />
        </td>
        <td>
          {row.match === 'devices' && (
            <MultiSelect name={'devices:' + i} options={equipment} selection={row.devices} onChange={handleChange} />
          )}
          {row.match === 'tags' && (
            <MultiSelect name={'tags:' + i} options={tags} selection={row.tags} onChange={handleChange} />
          )}
        </td>
        <td>
          <RemoveButton onRemove={() => removeCondition(i)} />
        </td>
      </tr>
    )
  })

  return (
    <Modal onClose={onClose} size="lg">
      <Form editing={true} onSubmit={handleSave}>
        <h2>{intl.formatMessage({ id: 'map.device.home_dashboards_settings' })}</h2>
        <table className="nsi-settings-table is-fixed">
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'map.device.home_dashboards.dashboard' })}</th>
              <th className="inline-flex" style={{ gap: '6px' }}>
                <span>{intl.formatMessage({ id: 'map.device.home_dashboards.condition' })}</span>
                <HelpTooltip position="top" size={15}>
                  <span slot="content">{intl.formatMessage({ id: 'map.device.home_dashboards.condition.help' })}</span>
                </HelpTooltip>
              </th>
              <th />
              <th style={{ width: '3ch' }} />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <PlusButton style={{ margin: 0 }} textId="map.device.home_dashboards.add_condition" onClick={addCondition} />

        <ModalFooter onCancel={onClose} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

export default HomeDashboardsModal
