/**
 * параметр считанный с устройства -> параметр в системе
 * дополненная версия parameters_mapping хранящегося в НСИ
 * (добавлен перевод системного параметра)
 */
export interface DeviceParamsMapping {
  [deviceParameter: string]: ParamMapping
}

export interface ParamMapping {
  label: string // название параметра в системе (ru/en)
  value: string // id параметра в системе
  factor: number
  offset: number
}

/**
 * Подсчет использования системных параметров в маппинге (для валидации
 * использования только один раз)
 */
export const countUsedParameters = (mapping: DeviceParamsMapping) => {
  const result = new Map<string, number>()

  for (const { value } of Object.values(mapping)) {
    if (!value) continue

    const count = result.get(value) || 0
    result.set(value, count + 1)
  }

  return result
}
