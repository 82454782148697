import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { createEquipment } from '../nsi.actions'
import { Equipment } from 'au-nsi/equipment'
import DeviceControls from './DeviceControls'
import DeviceIcon from './DeviceIcon'
import RowControls from './RowControls'

const DeviceRow = (props: Props) => {
  const ref = React.useRef<HTMLSpanElement>()
  const dispatch = useDispatch()
  const { device } = props

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', 'device:' + device.id)
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDragOver = (e) => e.preventDefault()

  const handleDragEnter = () => {
    ref.current.style.background = 'var(--bg-selected)'
  }

  const handleDragLeave = () => {
    ref.current.style.background = null
  }

  const handleDrop = (e) => {
    e.preventDefault()
    handleDragLeave()

    const source = e.dataTransfer.getData('text/plain')
    props.onDrop(source, 'device:' + device.id)
  }

  // создание нового виртуального устройства входящего в состав текущего устройства
  const handleCreate = () => {
    const { id, name, protocol, type, region_id, path } = props.device
    const newPath = path ? path + '.' + id : id
    dispatch(createEquipment({ name, protocol, type, region_id, path: newPath }))
  }

  /* ручное добавление виртуальных устройств разрешено только для некоторых протоколов */
  const showDeviceControls =
    !device.path && ['IEC104', 'OPCDA', 'modbustcp-client', 'MQTT-Ob'].includes(device.protocol)

  return (
    <span
      className="tpl-node__padding tpl-node-inline-flex"
      data-id={device.id}
      draggable={props.allowMoving}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      ref={ref}
    >
      <DeviceIcon id={device.id} style={{ margin: '0 10px', flexShrink: 0 }} />
      <span>{device.name}</span>
      {device.inactive && showInactiveMsg()}

      <div className="nsi__row-controls">
        {showDeviceControls && <DeviceControls onCreate={handleCreate} />}

        <RowControls
          allowArchiving={props.allowDeleting}
          allowChangingAccess={props.allowChangingAccess}
          allowChangingState={false}
          allowDeleting={props.allowDeleting}
          allowMoving={props.allowMoving}
          allowReordering={props.allowReordering}
          allowReplicating={props.allowCreating}
          isArchived={device.inactive}
          onAccess={props.onAccess}
          onArchive={props.onArchive}
          onDelete={props.onDelete}
          onMove={props.onMove}
          onReplicate={props.onReplicate}
        />
      </div>
    </span>
  )
}

const showInactiveMsg = () => {
  return (
    <span style={{ color: 'var(--gray-30)', fontStyle: 'italic', paddingLeft: '1ch' }}>
      <FormattedMessage id="nsi.inactive_device" />
    </span>
  )
}

interface Props {
  allowChangingAccess: boolean
  allowCreating: boolean
  allowDeleting: boolean
  allowMoving: boolean
  allowReordering: boolean
  device: Equipment
  onAccess: () => void
  onArchive: () => void
  onDelete: () => void
  onDrop: (source: string, target: string) => void
  onMove: () => void
  onReplicate: () => void
  selectedId: string
}

export default React.memo(DeviceRow)
