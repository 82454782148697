import Toggle from './Toggle'
import { CSSProperties } from 'react'

const ToggleWithLabel = (props: Props) => {
  return (
    <div className="toggle-row" style={props.style}>
      <Toggle name={props.name} checked={props.value} onChange={props.onChange} size={props.fontSize} />
      <span>{props.label}</span>
    </div>
  )
}

interface Props {
  name: string
  label: string
  value: boolean
  onChange: (v: boolean, name: string) => void
  fontSize?: string
  style?: CSSProperties
}

export default ToggleWithLabel
