import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import EventEmitter from '../../../utils/events'
import css from './windrose.module.css'

/**
 * Всплывающее окно с выводом значений угла и скорости ветра в секторе попавшем под мышку
 */
const WindroseTooltip = (props: Props) => {
  const [state, setState] = React.useState<TooltipState>()

  React.useEffect(() => props.data$.subscribe((data) => setState(data)), [])

  const units = useSelector((state: ReduxState) => state.parameters.units)
  const mps = units['m/s'] || { symbol: '' }

  if (!state) return null

  const degrees = (180 * state.angle) / Math.PI

  let speed = ''

  if (state.speed == null) speed = ''
  else if (state.speed === 0) speed = '<2'
  else if (state.speed === 14) speed = '>14'
  else speed = `${state.speed} - ${state.speed + 2}`

  return (
    <div className={css.tooltip}>
      <div>{Number.isInteger(degrees) ? degrees : degrees.toFixed(2)}°</div>
      {speed && <div>{speed + mps.symbol}</div>}
      <div>{(100 * state.percent).toFixed(2)}%</div>
    </div>
  )
}

interface Props {
  data$: EventEmitter<TooltipState>
}

export interface TooltipState {
  angle: number
  speed: number
  percent: number
}

export default WindroseTooltip
