import { PlatformModuleID } from 'au-nsi/shared'
import React from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import { FormMode } from '../../shared/interfaces'
import { selectEnabledModules } from '../App/app.selectors'
import EquipmentForm from './EquipmentForm'
import './intl/nsi.intl'
import './nsi.styles.css'
import RegionForm from './RegionForm/RegionForm'
import Tree from './Tree/Tree'

const Nsi = (props: Props) => {
  const showRegionForm = props.selectedRegionId != null || props.mode === 'create'
  const showEquipmentForm = props.selectedDeviceId != null

  const form = showRegionForm ? <RegionForm /> : showEquipmentForm ? <EquipmentForm /> : null

  return (
    <React.Fragment>
      <Tree />
      {form}
    </React.Fragment>
  )
}

interface Props {
  dispatch: (action: any) => void
  selectedDeviceId: string
  selectedRegionId: number
  mode: FormMode
  modules: Set<PlatformModuleID>
}

function mapStateToProps(state: ReduxState) {
  return {
    selectedDeviceId: state.nsi.selectedDeviceId,
    selectedRegionId: state.nsi.selectedRegionId,
    mode: state.nsi.mode,
    modules: selectEnabledModules(state),
  }
}

export default connect(mapStateToProps)(Nsi)
