import React, { useCallback, useEffect, useState } from 'react'

interface PopupOptions {
  initialState: boolean
  active: boolean
  isOutside: (node: HTMLElement) => boolean
}

// hook for handling clicks ouside and presses of the escape key
const usePopup = (ref: React.RefObject<HTMLElement>, options: Partial<PopupOptions> = {}) => {
  const active = options.active ?? true
  const [open, setOpen] = useState(options.initialState ?? false)

  const handleClick = useCallback(
    (e) => {
      const isOutside = options.isOutside ? options.isOutside(e.target) : !ref.current.contains(e.target)
      if (isOutside) setOpen(false)
    },
    [active]
  )

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    const isEscape = e.key === 'Escape'

    if (isEscape) {
      e.preventDefault()
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (active && open && ref.current) {
      document.addEventListener('mousedown', handleClick)
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('mousedown', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [open, active])

  return { open, setOpen }
}

// при потере фокуса необходимо скрыть выпадающее меню, но если фокус перемещен
// на поле поиска внутри самого меню, то его необходимо оставить
export const handleBlur = (e: FocusEvent, wrapperRef: React.RefObject<HTMLElement>, onExit: () => void) => {
  if (e.relatedTarget && e.relatedTarget !== document.body) {
    const focus = e.relatedTarget as Node
    const wrapper = wrapperRef.current

    if (!focus || !wrapper.contains(focus)) {
      return onExit()
    }

    focus.addEventListener('blur', (event: FocusEvent) => handleBlur(event, wrapperRef, onExit), { once: true })
  }
}

export default usePopup
