import db from '../../utils/idb'
import { IUserTask } from './userTasks.types'

const key = 'user.tasks'

const loadTasks = async () => {
  return (await db.get(key)) || []
}

const addTask = async (task: IUserTask): Promise<IUserTask> => {
  const prev: IUserTask[] = (await db.get(key)) || []

  prev.push(task)
  await db.set(key, prev)

  return task
}

const updateTask = async (task: IUserTask): Promise<IUserTask> => {
  const prev: IUserTask[] = (await db.get(key)) || []
  const index = prev.findIndex((t) => t.id === task.id)
  if (index === -1) return null

  const updated = { ...prev[index], ...task }
  prev[index] = updated
  await db.set(key, prev)

  return updated
}

const deleteTask = async (task: IUserTask): Promise<IUserTask['id']> => {
  const prev: IUserTask[] = (await db.get(key)) || []
  const index = prev.findIndex((t) => t.id === task.id)
  if (index === -1) return null

  prev.splice(index, 1)
  await db.set(key, prev)

  return task.id
}

const userTasksDb = { loadTasks, addTask, updateTask, deleteTask }
export default userTasksDb
