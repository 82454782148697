import FormIcons, { IFormIconsProps } from '../../../../../../shared/Forms/FormIcons/FormIcons'
import { useIntl } from 'react-intl'
import styles from './requestPageControls.module.css'
import RequestAccessButton from './RequestAccessButton'
import { useRecoilValue } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import Loader from '../../../../../../shared/Utils/Loader'
import RequestAssignButton from './RequestAssignButton'
import { IModerationRequest } from 'au-nsi/moderation'

interface IProps extends IFormIconsProps {
  loading: boolean
  allowAssignment: boolean
  onApprove: () => void
  onReject: () => void
  onAccessChange: (req: IModerationRequest) => void
}

const RequestPageControls = ({
  onApprove,
  onReject,
  onAccessChange,
  loading,
  allowAssignment,
  ...formIconsProps
}: IProps) => {
  const intl = useIntl()
  const request = useRecoilValue(selectedDraftRequestState)

  if (request.moderated_at) return null

  if (loading) {
    return (
      <div className={styles.requestPageControls} style={{ marginRight: 'auto' }}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.requestPageControls}>
      {formIconsProps.allowDeleting && (
        <>
          <button className="nsi-button default" onClick={onApprove}>
            {intl.formatMessage({ id: 'user.request.approve' })}
          </button>
          <button className="nsi-button danger" onClick={onReject}>
            {intl.formatMessage({ id: 'user.request.reject' })}
          </button>

          <div className={styles.requestPageControlsHr} />
        </>
      )}
      <FormIcons {...formIconsProps}>
        {allowAssignment && <RequestAssignButton requestId={request.id} />}
        <RequestAccessButton request={request} onChange={onAccessChange} />
      </FormIcons>
    </div>
  )
}

export default RequestPageControls
