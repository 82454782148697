import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import http, { handleHttpError } from '../../../utils/http'
import { selectTimeUnits } from '../../Parameters/params.selectors'
import { formatDuration } from '../../Parameters/params.utils'
import { ArchiveEntry } from './archive.types'

/**
 * Модальное окно для подтверждения объединения нескольких участков импульс-архива в один
 */
const ArchiveMergeForm = (props: Props) => {
  const intl = useIntl()
  const timeUnits = useSelector(selectTimeUnits)

  const [loading, setLoading] = React.useState(false)
  const [name, setName] = React.useState('') // название нового участка

  const handleMerge = () => {
    if (!name) return

    setLoading(true)
    const ids = Array.from(props.selection)

    http
      .post('/nsi/v1/incidents_archive/merge', { ids, name })
      .then((r) => props.onMerge(r.data, props.selection))
      .catch(handleHttpError)
      .finally(props.onClose)
  }

  const entries = props.entries.filter((e) => props.selection.has(e.id))
  const t0 = Math.min(...entries.map((e) => e.ts_start))
  const t1 = Math.max(...entries.map((e) => e.ts_end))

  // длительность участка который получится в результате объединения
  const duration = formatDuration((t1 - t0) / 1e6, timeUnits)

  return (
    <Modal onClose={props.onClose} closeOnClickOutside={false}>
      <h2 className="text--center">
        {intl.formatMessage({ id: 'ChartPlayer.archive.impulse_merge_confirm' }, { duration })}
      </h2>

      <Form editing={true} onCancel={props.onClose} onSubmit={handleMerge}>
        <InputRow label={intl.formatMessage({ id: 'ChartPlayer.archive.form.name' })}>
          <TextInput name="" value={name} onChange={setName} />
        </InputRow>

        <ModalFooter
          loading={loading}
          onCancel={props.onClose}
          onSave={handleMerge}
          saveStyle="danger"
          saveMessage={intl.formatMessage({ id: 'ChartPlayer.archive.impulse_merge' })}
        />
      </Form>
    </Modal>
  )
}

interface Props {
  entries: ArchiveEntry[]
  selection: Set<number>
  onClose: () => void
  onMerge: (result: ArchiveEntry, selection: Set<number>) => void
}

export default ArchiveMergeForm
