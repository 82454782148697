import { IDataIntervals, IDataSelector } from './data.types'

export const isEqualArrays = <T extends string | number>(a: T[], b: T[]) => {
  if (a === b) return true
  if (!a || !b) return false
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }

  return true
}

/**
 * Сгруппировать селекторы параметров приходящих с одного устройства и с одним data rate
 */
export const groupSelectors = ({ selectors, dataIntervals, irregularParameters }: GroupSelectorsOptions) => {
  const result: Required<IDataSelector>[] = []

  for (const selector of selectors) {
    const { device_id } = selector
    const deviceIntervals = dataIntervals.get(device_id) || defaultInterval

    for (let i = 0; i < selector.parameters.length; i++) {
      const parameter = selector.parameters[i]
      const key = getDataKey({ deviceId: device_id, parameterId: parameter, dataIntervals, irregularParameters })

      const row = result.find((r) => r.key === key)

      if (!row) {
        const irregular = irregularParameters.has(parameter)
        const interval = irregular ? 0 : deviceIntervals.parameters.get(parameter) || deviceIntervals.default
        result.push({ key, device_id, parameters: [parameter], interval, irregular })
      } else {
        row.parameters.push(parameter)
      }
    }
  }

  return result
}

interface GroupSelectorsOptions {
  selectors: IDataSelector[]
  dataIntervals: Map<string, IDataIntervals>
  irregularParameters: Set<string>
}

/**
 * Получение ключа по которому хранятся данные с указанного устройства по указанному
 * параметру. По умолчанию ключом служит id устройства, но в случае если параметр
 * имеет отдельный data rate, то данные хранятся по отдельному ключу.
 */
export const getDataKey = (options: GetDataKeyOptions) => {
  const { deviceId, parameterId } = options

  const isIrregular = options.irregularParameters.has(parameterId)
  if (isIrregular) return deviceId + '::' + parameterId

  const record = options.dataIntervals.get(deviceId)
  if (!record) return deviceId

  const interval = record.parameters.get(parameterId)
  return interval ? deviceId + ':' + interval : deviceId
}

interface GetDataKeyOptions {
  deviceId: string
  parameterId: string
  dataIntervals: Map<string, IDataIntervals>
  irregularParameters: Set<string>
}

const defaultInterval = { default: 0, parameters: new Map() }
