import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import InfiniteScroll from '../../../../shared/Pagination/InfiniteScroll'
import { changelogNotificationModalState } from '../userNotifications.state'
import NotificationItem from './NotificationItem'
import { IUserNotification } from 'au-nsi/notifications'
import { PlatformModuleID } from 'au-nsi/shared'

interface IProps {
  notifications: IUserNotification[]
  modules: Set<PlatformModuleID>
  onLoadMore: () => void
  onAck: (item: IUserNotification) => void
  isMore: boolean
}

const NotificationList = ({ notifications, modules, onLoadMore, onAck, isMore }: IProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const setModalState = useSetRecoilState(changelogNotificationModalState)

  useEffect(() => {
    if (!notifications?.length) onLoadMore()
  }, [])

  const handleClick = useCallback((item: IUserNotification) => {
    if (!item.ack_ts) onAck(item)

    switch (item.resource) {
      case 'moderation_requests':
      case 'moderation_request_messages':
        return navigate(`/user/requests/${item.payload.request_id}`)
      case 'user_actions':
        if (item.payload.action !== 'delete') setModalState(item.payload)
        return handleActionClick(item)
      case 'scheduler_events':
        return navigate(`/settings/scheduler/jobs?task_id=${item.payload.task_id}`)
    }
  }, [])

  // переход на страницу ресурса при клике на уведомление о действии над этим ресурсом
  const handleActionClick = (item: IUserNotification) => {
    if (item.resource !== 'user_actions') return

    const { resource, resource_id: id, action } = item.payload
    if (resource === 'equipment' && action === 'delete') {
      return modules.has('map_topology') ? navigate(`/map`) : navigate(`/settings/topology`)
    }

    switch (resource) {
      case 'equipment':
        return modules.has('map_topology') ? navigate(`/map/device/${id}/main`) : navigate(`/settings/topology`)
      case 'equipment_logbook':
        return navigate(`/map/device/${id}/main`)
      case 'formulas':
        return navigate(`/settings/formulas`)
      case 'parameters':
        return navigate(`/settings/parameters`)
      case 'settings_router':
        return navigate(`/settings/system/router`)
      case 'settings_storage':
        return navigate(`/settings/system/storage`)
      case 'signal_events':
        return navigate(`/settings/signal-events`)
      case 'user_roles':
      case 'users':
        return navigate(`/settings/users`)
    }
  }

  const isMobile = window.innerWidth < 600
  const maxHeight = isMobile ? window.innerHeight - 100 : Math.round(window.innerHeight * 0.6)

  return (
    <InfiniteScroll loadMore={onLoadMore} isMore={isMore} style={{ maxHeight, overflow: 'auto' }}>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} item={notification} onAck={onAck} onClick={handleClick} />
      ))}
      {notifications.length === 0 && !isMore && (
        <div className="center text--gray" style={{ minHeight: '32px' }}>
          {intl.formatMessage({ id: 'nav.notifications.empty_list' })}
        </div>
      )}
    </InfiniteScroll>
  )
}

export default NotificationList
