import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

interface IProps<T extends unknown> {
  initialValue: T
  onChange: (value: T) => void
  debounceTimeout?: number
}

/**
 * Вызов onChange по прошествии debounceTimeout.
 * При изменении value, старый таймаут сбрасывается и создаётся новый
 * */
const useDebounceState = <T extends unknown>(props: IProps<T>): [T, Dispatch<SetStateAction<T>>] => {
  const mounted = useRef<boolean>(false)
  const timeout = useRef<number>(null)
  const [value, setValue] = useState<T>(props.initialValue)

  useEffect(() => {
    // При первом рендере вызывать onChange смысла нет
    if (!mounted.current) {
      mounted.current = true
      return
    }

    const debounceTimeout = props.debounceTimeout ?? 300
    timeout.current = window.setTimeout(() => props.onChange(value), debounceTimeout)
    return () => timeout.current && window.clearTimeout(timeout.current)
  }, [props.debounceTimeout, value])

  return [value, setValue]
}

export default useDebounceState
