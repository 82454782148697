const DAY = 24 * 60 * 60 * 1000
export const MONTH = 30 * DAY

export const defaults = {
  lfo: 30_000,
  trends: 30_000,
  pq: 2_000,
  power: 30_000,
  vector: 2_000,
  oplim: 1_000,
  unbalance: 30_000,
  digitals: 30_000,
}

export const limits: Record<string, { min: number; max: number }> = {
  lfo: { min: 1_000, max: MONTH },
  trends: { min: 1_000, max: MONTH },
  pq: { min: 1_000, max: 60_000 },
  power: { min: 1_000, max: MONTH },
  unbalance: { min: 1_000, max: MONTH },
  digitals: { min: 1_000, max: MONTH },
}
