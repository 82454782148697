import React from 'react'
import { IRoute } from './nav.utils'

const ExportPage = React.lazy(() => import('../../Archive/Export/ExportPage'))
const ImportPage = React.lazy(() => import('../../Archive/Import/ImportPage'))

const archiveRoutes: IRoute[] = [
  { id: 'archive.export', path: 'export', component: ExportPage, rights: ['archive:export'] },
  { id: 'archive.import', path: 'import/*', component: ImportPage, rights: ['archive:import'] },
]

export default archiveRoutes
