import { useRef } from 'react'
import { useIntl } from 'react-intl'
import usePopup from '../../../hooks/usePopup'
import { ReactComponent as DotsIcon } from '../../../icons/vertical-dots.svg'
import css from './users.module.css'

/**
 * Выпадающее меню со списком дополнительных действий с аккаунтом пользователя
 */
const UserControls = (props: Props) => {
  const intl = useIntl()
  const { allowModeration, allowDevicesScreen } = props

  const ref = useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  if (!allowModeration && !allowDevicesScreen) return null

  const suspendLabel = props.suspended ? 'nsi.user.activate_account' : 'nsi.user.suspend_account'

  const menu = (
    <div className="nsi-dropdown" onClick={() => setOpen(false)} style={{ top: 30 }}>
      {allowDevicesScreen && (
        <div className="nsi-dropdown__item" onClick={props.onDevicesScreen}>
          {intl.formatMessage({ id: 'nsi.user.show_connected_devices' })}
        </div>
      )}
      {allowModeration && (
        <div className="nsi-dropdown__item danger" onClick={props.onSuspend}>
          {intl.formatMessage({ id: suspendLabel })}
        </div>
      )}
    </div>
  )

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <DotsIcon width={24} height={24} className={css.controlsIcon} onClick={() => setOpen(!open)} />
      {open && menu}
    </div>
  )
}

interface Props {
  allowDevicesScreen: boolean
  allowModeration: boolean
  onDevicesScreen: () => void
  onSuspend: () => void
  suspended: boolean
}

export default UserControls
