import { Equipment, Region } from 'au-nsi/equipment'
import { TNode } from '../../../shared/Tree/tree.types'

interface BuildOptions {
  composeEquipment: boolean
  withRoot: boolean
}

// дерево регионов и оборудования
const build = (r: Region[], e: Equipment[], options: Partial<BuildOptions> = {}) => {
  const composeEquipment = options.composeEquipment ?? true
  const withRoot = options.withRoot ?? false

  // create copies to be able to directly mutate elements
  const equipment = e.map((v) => ({ ...v }))
  const regions = r.map((v) => ({ ...v, children: [] }))

  const equipmentMap = new Map(equipment.map((v) => [v.id, v]))
  const regionsMap = new Map(regions.map((v) => [v.id, v]))

  const roots: TNode<Region>[] = []

  for (const region of regions) {
    const path = region.path.split('.')
    const parentId = +path[path.length - 2]

    if (!parentId) {
      roots.push(region)
    } else {
      const parent = regionsMap.get(parentId)
      parent.children.push(region)
    }
  }

  for (const device of equipment) {
    let parent

    if (composeEquipment && device.path) {
      const parentId = device.path.split('.').pop()
      parent = equipmentMap.get(parentId)
    } else {
      parent = regionsMap.get(device.region_id)
    }

    if (!parent.children) parent.children = [device]
    else parent.children.push(device)
  }

  return withRoot ? roots : roots[0].children
}

// дерево только регионов
const buildRegionsTree = (regions: Region[], withRoot = false) => {
  return build(regions, [], { withRoot })
}

const treeBuilder = { build, buildRegionsTree }
export default treeBuilder
