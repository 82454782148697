import React, { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useDeviceWidth from '../../../hooks/useDeviceWidth'
import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import { handleHttpError } from '../../../utils/http'
import { selectLanguage } from '../../App/app.selectors'
import ntfCss from '../Notifications/ntf.module.css'
import * as api from './messages.api'
import css from './messages.module.css'
import { formatMessageTime, getMessageColor } from './messages.utils'
import { useUnreadMessages } from './useUnreadMessages'

/**
 * Список системных сообщений о состоянии системы
 */
const SystemMessages = ({ onClose }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const lang = useSelector(selectLanguage)
  const { isMobile } = useDeviceWidth()

  const { messages, isMore, loadMore, handleAck } = useUnreadMessages()

  // при клике на уведомление о состоянии системы переход в раздел "О системе"
  const handleClick = (e: React.MouseEvent) => {
    const { type } = (e.currentTarget as HTMLDivElement).dataset

    if (['MQ', 'disk_usage', 'resource_usage', 'impulse_size', 'logs_size'].includes(type)) {
      navigate('/settings/system/about')
      onClose()
    }
  }

  const ackMessage = (id: number) => {
    handleAck({ message_id: id, ack_ts: Date.now() }, false)
    return api.ackMessage(id).catch(handleHttpError)
  }

  const hideMessage = (e: React.MouseEvent) => {
    e.stopPropagation()
    const id = +(e.currentTarget as HTMLDivElement).dataset.id
    ackMessage(id)
  }

  const clearAll = () => {
    const last_ts = messages[0].ts
    handleAck({ message_id: null, ack_ts: last_ts, batch: true, last_ts })
    return api.ackAllMessages(last_ts).catch(handleHttpError)
  }

  const openJournal = () => {
    navigate('/journal/system')
    onClose()
  }

  const list = messages.map((m) => {
    const className = 'alert-item alert-card is-' + m.level

    return (
      <div key={m.type + ':' + m.id} className="alert-item__wrapper">
        <div className={className} data-id={m.id} data-type={m.type} onClick={handleClick}>
          <div className="alert-item__header" style={{ marginBottom: 8, opacity: 0.8, justifyContent: 'flex-start' }}>
            {m.is_active && <div className={css.indicator} style={{ background: getMessageColor(m) }} />}
            <div style={{ fontSize: 12 }}>{formatMessageTime(m)}</div>

            <span data-id={m.id} data-type={m.type} style={{ fontSize: 20, marginLeft: 'auto' }} onClick={hideMessage}>
              &times;
            </span>
          </div>

          <div style={{ whiteSpace: 'pre-wrap' }}>{m.message[lang]}</div>
        </div>
      </div>
    )
  })

  const listStyle: CSSProperties = isMobile ? {} : { overflowY: 'auto', maxHeight: '60vh' }

  return (
    <>
      <div className={css.notificationsHeader}>
        <div onClick={openJournal}>{intl.formatMessage({ id: 'system.messages.open_journal' })}</div>
      </div>

      <div style={listStyle}>
        <InfiniteScroll isMore={isMore} loadMore={loadMore}>
          {list}
        </InfiniteScroll>
      </div>

      {messages.length === 0 && (
        <div className="center text--gray" style={{ minHeight: '32px' }}>
          {intl.formatMessage({ id: 'nav.notifications.empty_list' })}
        </div>
      )}

      {messages.length > 0 && (
        <div className={ntfCss.clearAll} onClick={clearAll}>
          {intl.formatMessage({ id: 'system.messages.clear_all' })}
        </div>
      )}
    </>
  )
}

interface Props {
  onClose: () => void
}

export default SystemMessages
