import { ComponentProps } from 'react'
import Toggle from './Toggle'
import styles from './inputs.module.css'

const ToggleTableCell = (props: ComponentProps<typeof Toggle>) => {
  return (
    <td className={styles.toggleTableCell}>
      <Toggle {...props} />
    </td>
  )
}

export default ToggleTableCell
