import Loader from '../../../shared/Utils/Loader'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import Checkbox from '../../../shared/Inputs/Checkbox/Checkbox'
import { API_URL } from '../../../shared/constants'
import { useRecoilValue } from 'recoil'
import { systemPublicState } from '../../System/system.state'

interface IProps {
  isLoading: boolean
  page: number
  setPage: (page: number) => void
  showNext: boolean
  showSubmit: boolean
}

export const RegisterControlButtons = ({ isLoading, page, setPage, showNext, showSubmit }: IProps) => {
  const intl = useIntl()
  const publicSystemState = useRecoilValue(systemPublicState)
  const [userAgreementConfirmed, setUserAgreementConfirmed] = useState(false)

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (page === 0 && showNext) {
    return (
      <button
        className="nsi-button default"
        onClick={() => setPage(1)}
        style={{ display: 'block', marginLeft: 'auto' }}
      >
        {intl.formatMessage({ id: 'auth.signup.next' })}
      </button>
    )
  }

  if (page === 1) {
    const renderLink = (str) => (
      <a href={API_URL + '/nsi/auth/pdp_agreement'} target="_blank" rel="noreferrer">
        {str}
      </a>
    )

    return (
      <>
        {publicSystemState.pdp_agreement_id && (
          <div style={{ display: 'inline-flex', alignItems: 'center', gap: '5px', marginBottom: '2em' }}>
            <Checkbox checked={userAgreementConfirmed} onChange={setUserAgreementConfirmed} />
            <span className="pdp_link">{intl.formatMessage({ id: 'auth.pdp_agreement' }, { a: renderLink })}</span>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button className="nsi-button default" onClick={() => setPage(0)}>
            {intl.formatMessage({ id: 'auth.signup.prev' })}
          </button>

          {showSubmit && (!publicSystemState.pdp_agreement_id || userAgreementConfirmed) && (
            <button type="submit" className="nsi-button default">
              {intl.formatMessage({ id: 'auth.signup' })}
            </button>
          )}
        </div>
      </>
    )
  }

  return null
}
