import { IVectorChartSettings } from 'au-nsi/dashboards'
import { colord } from 'colord'
import React, { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import useCanvasScale from '../../hooks/useCanvasScale'
import { selectDenormalizedParametersMap } from '../../pages/Parameters/params.selectors'
import { formatValue } from '../../pages/Parameters/params.utils'
import DataService from '../../services/data/data.service'
import './vector.styles.css'
import { calculateMouseAngle, drawBackground, drawTooltip, drawVector } from './vector.utils'

/**
 * Векторная диаграмма.
 * В электроэнергетике углы на векторной диаграмме отсчитываются против часовой стрелки,
 * поэтому в нескольких местах вместо angle используется -angle.
 */
const VectorGraph = (props: Props) => {
  const { settings, service } = props

  const [rect, setRect] = React.useState<DOMRect>()
  const mouseAngleRef = React.useRef<number>() // угол координаты мыши

  const wrapperRef = React.useRef<HTMLDivElement>()
  const backgroundRef = React.useRef<HTMLCanvasElement>()
  const canvasRef = React.useRef<HTMLCanvasElement>()

  const size = rect ? Math.min(rect.width, rect.height) : 0
  useCanvasScale(backgroundRef, size, size)
  useCanvasScale(canvasRef, size, size)

  const parameters = useSelector(selectDenormalizedParametersMap)

  // выставить размеры canvas равными размеру родительского контейнера
  React.useEffect(() => {
    setRect(wrapperRef.current.getBoundingClientRect())
  }, [props.lastResize])

  // отрисовка диаграммы на canvas
  React.useEffect(() => {
    const backgroundCtx = backgroundRef.current.getContext('2d')
    const ctx = canvasRef.current.getContext('2d')

    const { title } = settings
    const gridNominalValue = settings.grid_parameter === 'voltage' ? settings.voltage_nominal : settings.current_nominal

    const vectors = settings.vectors.map((v) => {
      const angle_parameter = parameters.get(v.angle_parameter_id)
      const magnitude_parameter = parameters.get(v.magnitude_parameter_id)

      const color = colord(v.color)

      const colorMuted = color.alpha(0.25).toRgbString()

      return { ...v, angle_parameter, magnitude_parameter, colorMuted }
    })

    const fixedVector = settings.fixed_vector_name ? vectors.find((v) => v.name === settings.fixed_vector_name) : null

    // отрисовка статического фона
    drawBackground({ ctx: backgroundCtx, size, title, nominalValue: gridNominalValue })

    // т.к. для разных векторов могут нужны быть данные от одного и того же устройства,
    // то используется кэш, чтобы каждый раз не искать данные по устройству, если они уже
    // были найдены для другого вектора
    const cache = new Map<string, Record<string, number>>()

    const onTick = () => {
      cache.clear()
      ctx.clearRect(0, 0, size, size)
      ctx.lineWidth = 2

      // если выбран вектор, который необходимо зафиксировать на нуле, то вычетаем его угол из всех векторов
      let angleDelta = 0

      if (fixedVector != null) {
        const point = service.selectCurrentPoint(fixedVector.device_id)
        if (point != null) cache.set(fixedVector.device_id, point)

        const angle = point && point[fixedVector.angle_parameter_id]
        if (angle) angleDelta = angle
      }

      const mouseAngle = mouseAngleRef.current
      let closestVectorDistance = Math.PI / 2
      let closestVector: typeof vectors[0] = null

      // отрисовка каждого вектора указанного в настройках
      for (const vector of vectors) {
        let point = cache.get(vector.device_id)

        if (point === undefined) {
          point = service.selectCurrentPoint(vector.device_id)
          cache.set(vector.device_id, point)
        }

        if (point == null) continue

        let value = point[vector.magnitude_parameter_id]
        let angle = point[vector.angle_parameter_id]
        if (value == null || angle == null) continue

        const nominalValue =
          vector.magnitude_parameter && vector.magnitude_parameter.unit.id === 'V'
            ? settings.voltage_nominal
            : settings.current_nominal

        value = Math.min(value, nominalValue)
        angle -= angleDelta
        const style = settings.vector_style
        drawVector({ ctx, angle, value, size, nominalValue, color: vector.color, colorMuted: vector.colorMuted, style })

        // поиск вектора ближайшего к позиции курсора
        if (mouseAngle != null && angleDistance(angle, mouseAngle) < closestVectorDistance) {
          closestVector = vector
          closestVectorDistance = angleDistance(angle, mouseAngle)
        }
      }

      // если пользователь навел курсор на диаграмму, то выводим подпись с
      // названием и значением параметра ближайшего вектора
      if (closestVector) {
        const point = cache.get(closestVector.device_id)
        const angle = point[closestVector.angle_parameter_id] - angleDelta
        const value = point[closestVector.magnitude_parameter_id]
        const valueStr = formatValue(value, closestVector.magnitude_parameter)
        const { color, name } = closestVector

        drawTooltip({ ctx, size, angle, name, value: valueStr, color })
      }
    }

    onTick()
    service.onTick = onTick
  }, [size, props.settings])

  const handleMouseLeave = () => {
    mouseAngleRef.current = null
    service.markForUpdate('cursor')
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    const x = e.pageX - rect.left
    const y = e.pageY - rect.top

    mouseAngleRef.current = -calculateMouseAngle(x, y, size)
    service.markForUpdate('cursor')
  }

  return (
    <div ref={wrapperRef} className="vector-wrapper">
      <canvas ref={backgroundRef} style={canvasStyle} />
      <canvas ref={canvasRef} style={canvasStyle} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} />
    </div>
  )
}

const canvasStyle: CSSProperties = { position: 'absolute', top: 0, left: 0 }

// угол между двумя углами
const angleDistance = (a: number, b: number) => Math.abs(normalizeAngle(a) - normalizeAngle(b))

// перевести угол в интервал от 0 до 2pi
const normalizeAngle = (angle: number) => (angle < 0 ? angle + 2 * Math.PI : angle) % (2 * Math.PI)

interface Props {
  id: number // component id
  lastResize: number
  settings: IVectorChartSettings
  service: DataService
}

export default VectorGraph
