import { useIntl } from 'react-intl'
import Toggle from '../../../shared/Inputs/Toggle'

interface Props {
  online: boolean
  onSetOnline: () => void
  onSetOffline: () => void
}

const OnlineControls = ({ online, onSetOnline, onSetOffline }: Props) => {
  const intl = useIntl()
  const onChange = online ? onSetOffline : onSetOnline

  return (
    <div style={{ width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Toggle name="online" checked={online} onChange={onChange} style={{ display: 'flex' }} />
      <div>{intl.formatMessage({ id: 'ChartControls.online' })}</div>
    </div>
  )
}

export default OnlineControls
