/**
 * Преобразовать значения для фильтров полученные с сервера в опции для использования в компоненте Select
 */
export const formatFilters = (filters, unselectedOption) => {
  filters.users = filters.users.map((u) => ({ value: '' + u.id, label: u.name }))
  filters.actions = filters.actions.map((a) => ({ value: a.id, label: a.name }))
  filters.resources = filters.resources.map((r) => ({ value: r.id, label: r.name }))

  filters.users.unshift(unselectedOption)
  filters.actions.unshift(unselectedOption)
  filters.resources.unshift(unselectedOption)

  const { columns, subresources = {} } = filters
  filters.columns = {}
  filters.subresources = {}

  for (const [resource, cols] of Object.entries(columns)) {
    filters.columns[resource] = Object.entries(cols).map(([value, label]) => ({ value, label }))

    filters.columns[resource].sort((a, b) => (a.label < b.label ? -1 : 1))
    filters.columns[resource].unshift(unselectedOption)
  }

  for (const [resource, subr] of Object.entries(subresources)) {
    filters.subresources[resource] = Object.entries(subr).map(([value, label]) => ({ value, label }))

    filters.subresources[resource].sort((a, b) => (a.label < b.label ? -1 : 1))
    filters.subresources[resource].unshift(unselectedOption)
  }

  return filters
}

export const getValueType = (v: unknown) => {
  if (typeof v === 'string') return 'string'
  else if (isPrimitive(v)) return 'primitive'
  else if (isStringArray(v)) return 'string_array'
  else if (isPrimitiveArray(v)) return 'primitive_array'
  else if (Array.isArray(v)) return 'object_array'
  else return 'object'
}

const isPrimitive = (v) => {
  const type = typeof v
  return v == null || type === 'string' || type === 'number' || type === 'boolean'
}

const isPrimitiveArray = (v) => {
  return Array.isArray(v) && v.length > 0 && isPrimitive(v[0])
}

const isStringArray = (v) => {
  return isPrimitiveArray(v) && typeof v[0] === 'string'
}

export const isEmpty = (v) => {
  if (v == null) return true
  else if (Array.isArray(v) && v.length === 0) return true
  else if (typeof v === 'object' && Object.keys(v).length === 0) return true
  else return false
}
