import { Equipment } from 'au-nsi/equipment'

export const formatPrevEquipment = <T extends Equipment>(
  prev: T,
  updated: Partial<T>,
  protocol?: Equipment['protocol']
): Partial<T> => {
  const formatted = { ...updated, protocol: prev.protocol }
  for (const key in formatted) formatted[key] = prev[key]

  formatted.protocol = prev.protocol ?? updated.protocol ?? protocol
  return formatted
}

export const formatAfterEquipment = <T extends Equipment>(
  prev: T,
  updated: Partial<T>,
  protocol?: Equipment['protocol']
): Partial<T> => {
  const formatted = { ...updated, protocol: updated?.protocol ?? prev?.protocol ?? protocol }

  return formatted
}
