import { Incident } from 'au-nsi/signal-events'
import React from 'react'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { SignalEvent } from '../../SignalEvents/se.interfaces'
import * as utils from '../incidents.utils'
import IncidentDetails from './IncidentDetails'
import IncidentsTableFooter from './IncidentsTableFooter'
import IncidentTime from './IncidentTime'

const IncidentsTableItem = (props: Props) => {
  const { incident } = props

  const handleClick = () => props.onClick(incident.id)

  const level = utils.getIncidentLevel(incident, props.rules)
  const { title, text } = utils.getIncidentDescription(incident, props.rules, props.lang)

  const details = utils.getIncidentDetails(incident, {
    devices: props.devices,
    parameters: props.parameters,
    lang: props.lang,
  })

  const className = 'alerts-table__cell alert-card ' + level

  return (
    <div key={incident.id} className={className} onClick={handleClick}>
      <div className="alert-item__header">
        <div>
          <div className="alerts-text is-bold">{title}</div>
          <div className="alerts-text">{text}</div>
        </div>
        <IncidentTime incident={incident} />
      </div>

      <div className="alerts-table__cell-body">
        <IncidentDetails details={details} />
      </div>

      <IncidentsTableFooter incident={incident} rules={props.rules} level={level} users={props.users} />
    </div>
  )
}

interface Props {
  incident: Incident
  devices: { [id: string]: string }
  users: { [id: number]: string }
  parameters: Map<string, ParameterDn>
  rules: Map<number, SignalEvent>
  lang: string
  onClick: (id: number) => void
}

export default React.memo(IncidentsTableItem)
