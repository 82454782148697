import RequestPageForm from './RequestPageForm'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import { getRequest } from '../moderation.api'
import { AxiosError } from 'axios'
import moderationActions from '../moderation.actions'
import { IModerationRequest } from 'au-nsi/moderation'
import { LoaderDots } from '@alterouniversal/au-react-components'
import { useParams } from 'react-router-dom'

/**
 * Компонент-провайдер данных для RequestPageForm. Пытается найти заявку с нужным id в Redux-стейте, если её там нет -
 * шлёт завпрос на сервер. В случае, если данные достать не удалось, выводит сообщение об удалении заявки.
 */
const RequestPageProvider = () => {
  const params = useParams()
  const intl = useIntl()
  const dispatch = useDispatch()
  const id = parseInt(params.id)

  const moderation_requests = useSelector((state: ReduxState) => state.moderation_requests.requests)

  const [isFetched, setIsFetched] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [request, setRequest] = useState<IModerationRequest>(moderation_requests.find((r) => r.id === id))

  useEffect(() => {
    const found_request = moderation_requests.find((r) => r.id === id)

    if (found_request) {
      setIsNotFound(false)
      setRequest(found_request)
      return
    }

    getRequest(id)
      .then((r) => {
        setIsNotFound(false)
        setIsFetched(true)
        dispatch(moderationActions.insert(r))
      })
      .catch((err: AxiosError) => err.response.status === 404 && setIsNotFound(true))
  }, [moderation_requests, id])

  useEffect(() => {
    return () => {
      if (isFetched) dispatch(moderationActions.requestRemoved(id))
    }
  }, [isFetched, id])

  if (isNotFound) {
    return <h2 className="text--danger">{intl.formatMessage({ id: 'user.request.removed' })}</h2>
  }

  if (!request) {
    return <LoaderDots />
  }

  return (
    <div className="nsi-main__container">
      <RequestPageForm selectedRequest={request} />
    </div>
  )
}

export default RequestPageProvider
