import { IBarChartSettings } from 'au-nsi/dashboards'
import { useIntl } from 'react-intl'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import OptionalInput from '../../../shared/Inputs/OptionalInput'
import TextInput from '../../../shared/Inputs/TextInput'

const BarChartSettingsDisplay = (props: Props) => {
  const intl = useIntl()

  return (
    <div className="system__grid" style={{ marginTop: '10px' }}>
      <InputRow label={intl.formatMessage({ id: 'common.name' })}>
        <TextInput name="title" required={false} value={props.settings.title} onChange={props.onChange} />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.mirror_axis' })}>
        <Dropdown
          name="mirror_axis"
          value={props.settings.mirror_axis || false}
          options={props.booleanOptions}
          onChange={props.onChange}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.min' })}>
        <OptionalInput
          name="min"
          value={props.settings.min}
          defaultValue={0}
          defaultLabel={intl.formatMessage({ id: 'dashboards.bar_chart.settings.y.auto' })}
          onChange={props.onChange}
        >
          <NumberInput
            integer={true}
            name="min"
            onChange={props.onChange}
            type="parameter"
            value={props.settings.min}
            {...props.unitsInfo}
          />
        </OptionalInput>
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.max' })}>
        <OptionalInput
          name="max"
          value={props.settings.max}
          defaultValue={0}
          defaultLabel={intl.formatMessage({ id: 'dashboards.bar_chart.settings.y.auto' })}
          onChange={props.onChange}
        >
          <NumberInput
            integer={true}
            name="max"
            onChange={props.onChange}
            type="parameter"
            value={props.settings.max}
            {...props.unitsInfo}
          />
        </OptionalInput>
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.show_names' })}>
        <Dropdown
          name="show_names"
          value={props.settings.show_names || false}
          options={props.booleanOptions}
          onChange={props.onChange}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.show_values' })}>
        <Dropdown
          name="show_values"
          value={props.settings.show_values || false}
          options={props.booleanOptions}
          onChange={props.onChange}
        />
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'dashboards.bar_chart.settings.bar_width' })}>
        <NumberInput
          name="bar_width"
          onChange={props.onChange}
          value={props.settings.bar_width ?? 100}
          min={1}
          max={100}
          fullWidth={true}
        />
      </InputRow>
    </div>
  )
}

interface Props {
  settings: IBarChartSettings
  onChange: (value: any, name: string) => void
  booleanOptions: { value: boolean; title: string }[]
  unitsInfo: any
}

export default BarChartSettingsDisplay
