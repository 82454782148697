import { useState } from 'react'
import Modal from '../../../../shared/Modal/Modal'
import { useIntl } from 'react-intl'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextInput from '../../../../shared/Inputs/TextInput'
import TextareaInput from '../../../../shared/Inputs/TextareaInput'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import { useDispatch } from 'react-redux'
import groupsActions from '../groups.actions'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { IPlace } from 'au-nsi/shared'
import { IGroup } from 'au-nsi-elevator/groups'

interface IProps {
  place: IPlace
  onClose: () => void
}

const CreateGroupModal = (props: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()
  const [group, setGroup] = useState<IGroup>({ name: '', address: props.place, description: '', id: 0 })

  const handleChange = (value: string, key: keyof IGroup) => {
    setGroup({ ...group, [key]: value })
  }

  const handleSave = () => {
    wait(dispatch(groupsActions.createGroup(group)) as any).then(props.onClose)
  }

  return (
    <Modal size={'md'} onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: 'map.ae.groups.add' })}</h2>
      <InputRow label={intl.formatMessage({ id: 'common.name' })}>
        <TextInput value={group.name} name="name" onChange={handleChange} />
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'common.description' })}>
        <TextareaInput required={true} value={group.description} name="description" onChange={handleChange} />
      </InputRow>
      <ModalFooter onCancel={props.onClose} onSave={handleSave} loading={loading} />
    </Modal>
  )
}

export default CreateGroupModal
