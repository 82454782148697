import { Equipment } from 'au-nsi/equipment'
import styles from '../../maptopology.module.css'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import PlusButton from '../../../../shared/Forms/PlusButton'
import { useCallback, useEffect, useMemo, useState } from 'react'
import DeviceLogbookModal from './DeviceLogbookModal/DeviceLogbookModal'
import { AnyCatalogWithInstances } from '../../../Catalogs/catalogs.types'
import { createLogbookEntry } from './logbooks.api'
import LogbookEntriesList from './LogbookEntriesList/LogbookEntriesList'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { orderedInsert, selectLogbooksRights } from './logbooks.utils'
import { wsSubscribe } from '../../../../services/ws/ws.events'
import useInfiniteLoader from '../../../../hooks/useInfiniteLoader'
import { ILogbookEntry } from 'au-nsi/logbook'
import { useParams } from 'react-router-dom'

interface Props {
  device: Equipment
}

const DeviceLogbook = (props: Props) => {
  const { wait, loading } = useHttpLoader()
  const { logbookId } = useParams()

  const { allowEditing } = useSelector(selectLogbooksRights)
  const query = useMemo(() => ({ schema_id: logbookId, equipment_id: props.device.id }), [logbookId, props.device.id])

  const logbook = useSelector((state: ReduxState) =>
    state.catalogs.catalogs.find((c) => c.id === parseInt(logbookId))
  ) as AnyCatalogWithInstances

  const [isCreating, setIsCreating] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState(undefined)
  const { results, isMore, loadMore, setResults } = useInfiniteLoader<ILogbookEntry>({
    url: '/nsi/v1/equipment_logbook',
    query,
  })

  useEffect(() => {
    return wsSubscribe<ILogbookEntry>('equipment_logbook', ({ method, payload }) => {
      if (payload.schema_id !== parseInt(logbookId)) return

      if (method === 'create') handleCreate(payload, false)
      else if (method === 'delete') setResults((prev) => prev.filter((it) => it.id !== payload.id))
    })
  }, [])

  useEffect(() => {
    setResults(() => [])
  }, [logbookId])

  const handleCreate = useCallback(async (logbookEntry: ILogbookEntry, shouldRequest = true) => {
    if (!shouldRequest) return logbookEntry && setResults((prev) => orderedInsert(prev, logbookEntry))

    wait(createLogbookEntry(logbookEntry), (created) => {
      created && setResults((prev) => orderedInsert(prev, created))
      setIsCreating(false)
    })
  }, [])

  const handleClose = () => {
    setSelectedEntry(undefined)
    setIsCreating(false)
  }

  if (!logbook) {
    return (
      <div className={styles.mapTopologyLoading}>
        <h2 style={{ color: 'var(--danger-50)' }}>404 Not Found</h2>
      </div>
    )
  }

  return (
    <div className="nsi-main__container">
      <div className={styles.deviceScreenPageHeader}>
        <h2>{logbook.name}</h2>
        {allowEditing && <PlusButton onClick={() => setIsCreating(true)} textId={'system.maps.add_logbook'} />}
      </div>
      <div>
        {(isCreating || selectedEntry) && (
          <DeviceLogbookModal
            device={props.device}
            logbook={logbook}
            onClose={handleClose}
            onCreateSave={handleCreate}
            initialMode={isCreating ? 'create' : 'view'}
            logbookEntry={selectedEntry}
            loading={loading}
          />
        )}
        <LogbookEntriesList
          schema={logbook}
          logbooks={results}
          onLoadMore={loadMore}
          onSelect={setSelectedEntry}
          isMore={isMore}
        />
      </div>
    </div>
  )
}

export default DeviceLogbook
