import { PropsWithChildren } from 'react'

interface Props {
  editing: boolean
}

const InstanceFormWrapper = ({ editing, ...props }: PropsWithChildren<Props>) => {
  const thead = (
    <tr>
      <th />
      <th />
    </tr>
  )

  const tableClass = editing ? 'nsi-settings-table' : 'nsi-settings-table is-striped'
  return (
    <table className={tableClass} style={{ tableLayout: 'fixed' }}>
      <thead>{thead}</thead>
      <tbody>{props.children}</tbody>
    </table>
  )
}

export default InstanceFormWrapper
