import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { selectAccessRights } from '../../App/app.selectors'
import { useSettingsAPI } from './settings.hooks'
import renderFooter from '../system.footer'
import { SMSRSettings } from 'au-nsi/settings'

const properties = ['Trab', 'Tava', 'Tout', 'Koef', 'delf', 'delu', 'dUblok', 'Tdo', 'Tpo', 'Tava1']

const SmsrSettings = () => {
  const intl = useIntl()
  const allowEditing = useSelector(selectAccessRights)['settings:update']
  const translations = useSelector((s: ReduxState) => s.language.translations)
  const equipment = useSelector((state: ReduxState) => state.nsi.equipment)

  const state = useSettingsAPI<SMSRSettings>('au-smsr')

  if (state.settings == null || state.draft == null) {
    return null
  }

  const handleChange = (value: any, key: string) => {
    state.updateDraft({ [key]: value })
  }

  const rows = properties.map((p) => {
    return (
      <div key={p}>
        <div className="system__label">{translations['settings_smsr.' + p]}</div>
        <div className="system__input-wrapper">
          <NumberInput
            type="number"
            name={p}
            value={state.draft[p]}
            onChange={handleChange}
            fullWidth={true}
            disabled={!state.editing}
            disabledStyle="text"
          />
        </div>
      </div>
    )
  })

  return (
    <div className="nsi-main__container">
      <h2 className="system__title">{intl.formatMessage({ id: 'nav.system.smsr' })}</h2>

      <Form editing={state.editing} onCancel={state.cancelDraft} onSubmit={state.updateSettings}>
        <div className="system__grid">{rows}</div>

        <InputRow label={translations['settings_smsr.equipment']}>
          <MultiSelect
            name="equipment"
            options={equipment}
            selection={state.draft.equipment || []}
            onChange={handleChange}
            disabled={!state.editing}
          />
        </InputRow>

        {allowEditing && renderFooter(state)}
      </Form>
    </div>
  )
}

export default SmsrSettings
