import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormMode } from '../interfaces'
import confirmService from '../Modal/confirm.service'
import Loader from '../Utils/Loader'
import { FormContext } from './Form'

/**
 * Элементы управления для редактирования всего объекта (редактировать, удалить, отменить)
 */
const FormButtons = (props: Props) => {
  const ctx = useContext(FormContext)
  const className = props.isFooter === false ? 'nsi-row' : 'nsi-row nsi-form__footer'

  // если кнопки обернуты в форму, то будет вызван onSubmit у формы
  const onCreateSave = ctx.form ? undefined : props.onCreateSave
  const onEditSave = ctx.form ? undefined : props.onEditSave

  if (props.isLoading) {
    return (
      <div className={className} style={{ display: 'flex', justifyContent: 'center', ...props.style }}>
        <Loader />
      </div>
    )
  }

  if (props.mode === 'view') {
    const edit = (
      <button type="button" className="nsi-button default" onClick={props.onEdit}>
        <FormattedMessage id="common.edit" />
      </button>
    )

    const handleDelete = props.confirmationModal
      ? () =>
          confirmService.requestDeleteConfirmation(props.confirmationModal).then((r) => {
            r === 'delete' && props.onDeleteConfirm()
          })
      : props.onDelete

    const remove = (
      <button type="button" className="nsi-button danger" onClick={handleDelete}>
        <FormattedMessage id="common.delete" />
      </button>
    )

    return props.allowEditing || props.allowDeleting || props.children ? (
      <div className={className} style={props.style}>
        {props.allowEditing && edit}
        {props.allowDeleting && remove}
        {props.children}
      </div>
    ) : null
  }

  if (props.mode === 'create') {
    return (
      <div className={className} style={props.style}>
        <button type="button" className="nsi-button inverted" onClick={props.onCancel}>
          <FormattedMessage id="common.cancel" />
        </button>
        <button type="submit" className="nsi-button default" onClick={onCreateSave}>
          <FormattedMessage id="common.save" />
        </button>
      </div>
    )
  }

  if (props.mode === 'edit') {
    return (
      <div className={className} style={props.style}>
        <button type="button" className="nsi-button inverted" onClick={props.onCancel}>
          <FormattedMessage id="common.cancel" />
        </button>
        <button type="submit" className="nsi-button default" onClick={onEditSave}>
          <FormattedMessage id="common.save" />
        </button>
      </div>
    )
  }

  if (props.mode === 'delete') {
    return (
      <div className={className} style={props.style}>
        <span style={{ paddingRight: '1em' }}>
          <FormattedMessage id="common.confirm_deletion" />
        </span>
        <button type="button" className="nsi-button inverted" onClick={props.onCancel}>
          <FormattedMessage id="common.cancel" />
        </button>
        <button type="button" className="nsi-button danger" onClick={() => props.onDeleteConfirm()}>
          <FormattedMessage id="common.delete" />
        </button>
      </div>
    )
  }

  return null
}

interface Props {
  mode: FormMode
  onEdit: () => void
  onEditSave?: () => void
  onCreateSave?: () => void
  onDelete?: () => void
  onDeleteConfirm?: () => void
  onCancel: () => void
  allowEditing: boolean
  allowDeleting: boolean
  isFooter?: boolean
  isLoading?: boolean
  confirmationModal?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

export default FormButtons
