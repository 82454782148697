import { OrganizationTypeWithInstances } from '../../Catalogs/Organizations/organizations.types'
import { Organization } from 'au-nsi/organizations'

export interface UserData {
  name: string
  username: string
  password: string
  password_confirm: string
  lang: string
  organization_type_id: OrganizationTypeWithInstances['id']
  organization_id: Organization['id']
  user_data: Record<string, any>
  created_organization_name: string
  created_organization_properties: Record<string, any>
}

export const createUser = (locale: string): UserData => ({
  name: '',
  username: '',
  password: '',
  password_confirm: '',
  lang: locale,
  organization_id: undefined,
  organization_type_id: undefined,
  user_data: {},
  created_organization_name: undefined,
  created_organization_properties: undefined,
})

export const validateUserProperties = (
  user: UserData,
  orgType: OrganizationTypeWithInstances,
  userOrganization: Organization
): boolean => {
  if (!user.organization_type_id || !orgType) return false
  if (orgType.register_request_organization && !user.organization_id && !userOrganization?.name) return false
  if (!/^\S+@\S+\.\S+$/.test(user.username)) return false
  if (user.password !== user.password_confirm) return false
  if (!user.name) return false

  return true
}

export const validateOrgProperties = (user: UserData, orgType: OrganizationTypeWithInstances): boolean => {
  if (!orgType) return false

  for (const row of orgType.registration_schema) {
    if (!row.required) continue

    const value = user.user_data[row.id]
    if (value == null || value === '') return false
  }

  return true
}
