import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageMode } from '../../shared/interfaces'

export interface LfoState {
  registratorId: string
  mode: PageMode
  search: string
  showAsGrid: boolean
  visibleCharts: {
    amplitudeF: boolean
    amplitudeP: boolean
    dampingFactorF: boolean
    dampingFactorP: boolean
    frequencyF: boolean
    frequencyP: boolean
  }
}

const initialState: LfoState = {
  registratorId: null,
  mode: 'history',
  search: '',
  showAsGrid: false,
  visibleCharts: {
    amplitudeF: true,
    amplitudeP: true,
    dampingFactorF: true,
    dampingFactorP: true,
    frequencyF: true,
    frequencyP: true,
  },
}

const slice = createSlice({
  name: 'app/lfo',
  initialState,
  reducers: {
    SET_STATE: (state, action: PayloadAction<LfoState>) => {
      Object.assign(state, action.payload)
      state.search = ''
    },
    selectDevice: (state, action: PayloadAction<string>) => {
      state.registratorId = action.payload
    },
    setMode: (state, action: PayloadAction<PageMode>) => {
      state.mode = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setVisibleCharts: (state, action) => {
      state.visibleCharts = action.payload
    },
    toggleChart: (state, action: PayloadAction<string>) => {
      state.visibleCharts[action.payload] = !state.visibleCharts[action.payload]
    },
    toggleGrid: (state) => {
      state.showAsGrid = !state.showAsGrid
    },
  },
})

export default slice.reducer
export const actions = slice.actions
