import { Suspense } from 'react'
import { useGoogleMapsLoader } from '../../../shared/GoogleMap/useGoogleMapsLoader'
import Loader from '../../../shared/Utils/Loader'
import GMap from './GMap'
import { useSelector } from 'react-redux'
import { selectEnabledModules } from '../../App/app.selectors'
import { useIntl } from 'react-intl'

/**
 * Загрузка карты и библиотек для нее только если они используются
 */
const MapLoader = (props) => {
  const intl = useIntl()
  const modules = useSelector(selectEnabledModules)
  const isLoaded = useGoogleMapsLoader()

  if (!modules.has('maps')) {
    return (
      <div className="center text--danger" style={{ height: '100%' }}>
        {intl.formatMessage({ id: 'system.modules.maps_not_enabled' })}
      </div>
    )
  }

  if (!isLoaded) {
    return (
      <div className="center" style={{ height: '100%' }}>
        <Loader />
      </div>
    )
  }

  return (
    <Suspense fallback={<div />}>
      <GMap {...props} />
    </Suspense>
  )
}

export default MapLoader
