import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import PasswordInput from '../../../shared/Inputs/PasswordInput'
import { useIntl } from 'react-intl'
import { OrganizationTypeWithInstances } from '../../Catalogs/Organizations/organizations.types'
import { UserData } from './register.utils'
import { useState } from 'react'
import OrganizationModal from './OrganizationModal'
import { AnyCatalogWithInstances } from '../../Catalogs/catalogs.types'
import { Organization } from 'au-nsi/organizations'
import { Select, SelectOption } from '@alterouniversal/au-react-components'

interface IProps {
  user: UserData
  onChange: (user: UserData) => void
  selectedOrganizationType: OrganizationTypeWithInstances
  organizationTypesOptions: SelectOption[]
  organizationsOptions: SelectOption[]
  passwordValid: boolean
  passwordHelp: string

  userOrganization: Organization
  setUserOrganization: (updates: Organization) => void
  depsCatalogs: AnyCatalogWithInstances[]
}

export const UserInfoForm = ({
  user,
  onChange,
  organizationTypesOptions,
  selectedOrganizationType,
  organizationsOptions,
  passwordValid,
  passwordHelp,
  userOrganization,
  setUserOrganization,
  depsCatalogs,
}: IProps) => {
  const intl = useIntl()
  const handleChange = (value: any, name: string) => onChange({ ...user, [name]: value })
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false)

  const pwdStyle = !passwordValid ? { border: '1px solid var(--danger-80)' } : undefined
  const pwdConfirmStyle = user.password !== user.password_confirm ? { border: '1px solid var(--danger-80)' } : undefined
  const emailStyles = !/^\S+@\S+\.\S+$/.test(user.username) ? { border: '1px solid var(--danger-80)' } : undefined

  return (
    <div>
      {organizationModalOpen && (
        <OrganizationModal
          depsCatalogs={depsCatalogs}
          organizationType={selectedOrganizationType}
          onSave={(org) => {
            setOrganizationModalOpen(false)
            setUserOrganization(org)
          }}
          onClose={() => setOrganizationModalOpen(false)}
        />
      )}
      <InputRow label={intl.formatMessage({ id: 'auth.signup.organization_type' })}>
        <Select
          required={true}
          value={user.organization_type_id}
          name={'organization_type_id'}
          onChange={handleChange}
          options={organizationTypesOptions}
        />
      </InputRow>

      {selectedOrganizationType?.register_request_organization && (
        <InputRow label={intl.formatMessage({ id: 'auth.signup.organization' })}>
          <div className="inscription-input__block">
            {userOrganization && <input className={'nsi-input'} value={userOrganization.name} disabled={true} />}
            {!userOrganization && (
              <Select
                required={true}
                value={user.organization_id}
                name={'organization_id'}
                onChange={handleChange}
                options={organizationsOptions}
              />
            )}

            {userOrganization && (
              <span onClick={() => setUserOrganization(undefined)}>{intl.formatMessage({ id: 'common.cancel' })}</span>
            )}
            {!userOrganization && (
              <span onClick={() => setOrganizationModalOpen(true)}>
                {intl.formatMessage({ id: 'auth.signup.my_organization_not_found' })}
              </span>
            )}
          </div>
        </InputRow>
      )}

      <InputRow label={intl.formatMessage({ id: 'auth.signup.name' })}>
        <TextInput value={user.name} name={'name'} onChange={handleChange} required={true} />
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'auth.signup.email' })}>
        <TextInput value={user.username} name={'username'} onChange={handleChange} type={'email'} style={emailStyles} />
      </InputRow>
      <InputRow
        label={intl.formatMessage({ id: 'auth.signup.password' })}
        help={<span className="text--prewrap">{passwordHelp}</span>}
      >
        <PasswordInput value={user.password} name="password" onChange={handleChange} style={pwdStyle} required={true} />
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'auth.signup.password_confirm' })}>
        <PasswordInput
          value={user.password_confirm}
          name="password_confirm"
          onChange={handleChange}
          style={pwdConfirmStyle}
          required={true}
        />
      </InputRow>
    </div>
  )
}
