import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { ReactComponent as ClearIcon } from '../../../icons/clear.svg'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import styles from './search.module.css'

/**
 * Text input with search and clear icons
 */
class SearchInput extends React.Component<Props> {
  private ref = React.createRef<HTMLInputElement>()
  private timeout = null

  componentDidMount() {
    if (this.props.value) this.ref.current.value = this.props.value
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  componentDidUpdate(prevProps: Props) {
    if (typeof this.props.value !== 'string') return

    if (prevProps.value !== this.props.value) this.ref.current.value = this.props.value
  }

  private handleChange = () => {
    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.props.onChange(this.ref.current.value)
      this.timeout = null
    }, this.props.throttling || 300)
  }

  private clearSearch = () => {
    this.props.onChange('')
    this.ref.current.value = ''
  }

  render() {
    const placeholder = this.props.placeholder || this.props.intl.formatMessage({ id: 'common.search' })

    return (
      <div className={styles.wrapper}>
        <input
          ref={this.ref}
          className={'nsi-input ' + styles.input}
          onChange={this.handleChange}
          placeholder={placeholder}
          style={this.props.inputStyle}
          autoFocus={!!this.props.autofocus}
        />
        <SearchIcon
          style={{ position: 'absolute', top: '8px', left: '8px', width: 24, height: 24, color: 'var(--gray-30)' }}
        />
        {this.props.displayClearIcon !== false && (
          <ClearIcon
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              cursor: 'pointer',
              width: 24,
              height: 24,
              borderRadius: '50%',
              background: 'var(--bg-default-light)',
            }}
            onClick={this.clearSearch}
          />
        )}
      </div>
    )
  }
}

interface Props {
  inputStyle?: React.CSSProperties
  intl: IntlShape
  onChange: (value: string) => void
  placeholder?: string
  autofocus?: boolean
  throttling?: number
  displayClearIcon?: boolean
  value?: string
}

export default injectIntl(SearchInput)
