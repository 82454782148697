import { AnyCatalogWithInstances } from '../../../../../Catalogs/catalogs.types'
import InstanceForm from '../../../../../Catalogs/components/InstanceForm/InstanceForm'
import { ICatalogItem, IPropertyDescription } from 'au-nsi/catalogs'

interface IProps {
  catalog: AnyCatalogWithInstances
  catalogItem: ICatalogItem
  onChange: (schemaId: IPropertyDescription['id'], value: any) => void
  editing: boolean
}

const RequestInstanceForm = ({ onChange, editing, catalog, catalogItem }: IProps) => {
  return (
    <InstanceForm
      editing={editing}
      item={catalogItem}
      nameKey="name"
      propertiesKey="properties"
      schema={catalog.schema_tree}
      onChange={onChange}
    />
  )
}

export default RequestInstanceForm
