import { IUser } from 'au-nsi/user'
import { tagsActions } from 'pages/Nsi/Tags/tags.state'
import { batch } from 'react-redux'
import http, { handleHttpError } from '../../utils/http'
import { catalogsActions } from '../Catalogs/catalogs.actions'
import rolesActions from '../Collections/Roles/roles.actions'
import usersActions from '../Collections/Users/users.actions'
import dashboardActions from '../Dashboards/dashboard.actions'
import { loadIncidents } from '../Incidents/incident.actions'
import imageActions from '../Libraries/Images/image.actions'
import { defaultNotificationSettings } from '../NotificationSettings/settings.api'
import { dataRatesActions } from '../Nsi/data_rates.reducers'
import { SET_EQUIPMENT, SET_REGIONS } from '../Nsi/nsi.reducers'
import groupsActions from '../Parameters/groups.actions'
import paramsActions from '../Parameters/params.actions'
import { SET_UNITS } from '../Parameters/params.reducers'
import seActions from '../SignalEvents/se.actions'
import { modulesActions } from '../System/Modules/modules.state'
import { PlatformModule } from '../System/Modules/modules.types'
import { setUser } from './user.actions'
import { CurrentUserState } from './user.reducers'

/**
 * Загрузить все необходимые ресурсы
 */
export const loadResources = () => (dispatch) => {
  dispatch(imageActions.loadImages())

  return http
    .get('/nsi/user/init')
    .then(({ data }) => {
      const user: CurrentUserState = mapAccessRights(data.user)
      if (!user.notifications) user.notifications = defaultNotificationSettings
      checkTimezoneOffset(user)

      const modules = data.modules as PlatformModule[]
      const userRole = { id: user.role_id, name: user.role, access_rights: data.user.access_rights }

      const userInfo: IUser = {
        id: user.id,
        lang: user.lang,
        name: user.name,
        email: user.email,
        role_id: user.role_id,
        username: user.username,
        preferences: user.preferences,
        security_settings: user.security_settings,
        use_role_ntf_settings: user.use_role_ntf_settings,
        is_admin: user.is_admin,
        suspended: false,
      }

      const units = {}
      const prefixes = {}

      for (const unit of data.si_units) {
        units[unit.id] = unit
      }

      for (const prefix of data.si_prefixes) {
        prefixes[prefix.id] = prefix
      }

      const catalogs = { catalogs: data.catalogs, catalog_items: data.catalog_items, templates: data.templates ?? [] }

      batch(() => {
        dispatch(setUser(user))
        dispatch(tagsActions.setTags(data.tags))
        dispatch(modulesActions.setModules(modules))
        dispatch(dashboardActions.setDashboards(data.dashboards))
        dispatch({ type: SET_EQUIPMENT, equipment: data.equipment })
        dispatch({ type: SET_REGIONS, regions: data.regions })
        dispatch(seActions.itemsLoaded(data.signal_events))
        dispatch(seActions.seTypesLoaded(data.signal_events_types))
        dispatch(paramsActions.itemsLoaded(data.parameters))
        dispatch(groupsActions.itemsLoaded(data.parameter_groups))
        dispatch({ type: SET_UNITS, units, prefixes })
        dispatch(dataRatesActions.set({ data_rates: data.data_rates }))
        dispatch(catalogsActions.catalogsLoaded(catalogs))
        // частичная информация только о текущем пользователе, полный список
        // ресурсов будет загружен позже когда в нем появится необходимость
        dispatch(rolesActions.itemCreated(userRole, true))
        dispatch(usersActions.itemCreated(userInfo, true))
      })

      // инциденты необходимо загружать после сигнальных ситуаций, т.к. в них идет проверка
      // и дозагрузка отсутствующих правил
      if (modules.find((m) => m.id === 'au-signal-events' && m.enabled) && user.access_rights['incidents:get']) {
        dispatch(loadIncidents())
      }
    })
    .catch((err) => handleHttpError(err))
}

/**
 * Преобразовать права доступа из массива в объект для удобства проверки
 */
const mapAccessRights = (user) => {
  const { access_rights } = user
  const result = { ...user, access_rights: {} }

  for (const right of access_rights) {
    result.access_rights[right] = true
  }

  return result
}

/**
 * Обновлении информации о часовом поясе пользователя, если она отличается от сохраненной на сервере
 */
const checkTimezoneOffset = (user: CurrentUserState) => {
  const offset = new Date().getTimezoneOffset()

  if (offset !== user.timezone_offset) {
    user.timezone_offset = offset

    http.post('/nsi/user/timezone', { offset }).catch((err) => console.error(err))
  }
}
