import { createSelector } from 'reselect'
import { User } from '../../pages/Collections/collection.interfaces'
import { ReduxState } from '../../redux/store.types'
import { matchSearch } from '../../utils/search'

// список всех организаций
export const selectOrganizations = createSelector(
  (state: ReduxState) => state.organizations.organizations,
  (types) => types.flatMap((t) => t.instances)
)

// список всех ролей за исключением Админа
export const selectRoles = createSelector(
  (state: ReduxState) => state.roles.items,
  (roles) => roles.filter((r) => r.id !== 1)
)

// список всех пользователей за исключением Админа
export const selectUsers = createSelector(
  (state: ReduxState) => state.users.items,
  (users) => users.filter((u) => u.id !== 1 && u.role_id !== 1)
)

// пользователи сгруппированные по ролям и организациям
export const groupUsers = (users: User[], searchTerms: string[]) => {
  const usersMap = new Map<number, User>()
  const byRole = new Map<number, User[]>()
  const byOrg = new Map<number, User[]>()

  for (const user of users) {
    if (searchTerms && !matchSearch(user.name, searchTerms)) continue

    const role = user.role_id
    const org = user.organization_id || 0

    if (!byRole.has(role)) byRole.set(role, [])
    if (!byOrg.has(org)) byOrg.set(org, [])

    usersMap.set(user.id, user)
    byRole.get(role).push(user)
    byOrg.get(org).push(user)
  }

  return { usersMap, userGroups: { byRole, byOrg } }
}
