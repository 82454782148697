import { IFormulaArgument } from '../formulas.interfaces'
import { ASTNode } from './ast.interfaces'

/**
 * Convert AST to JavaScript code
 * *skipParens* parameter is used to omit additional parantheses around top level expressions
 */
function ast2str(ast: ASTNode, isGlobal: boolean, args: IFormulaArgument[], skipParens = true): string {
  let res: string

  switch (ast.type) {
    case 'constant':
      return constants[ast.value]

    case 'parameter':
      const equipment = ast.args[0].value
      const arg = args.find((e) => e.parameter_id === ast.value && (!isGlobal || e.device_id === equipment))
      return arg.name

    case 'number':
      return ast.value.toString()

    case 'unaryFunction':
      const argument = ast2str(ast.args[0], isGlobal, args, true)
      return `math.${ast.value}(${argument})`

    case 'binaryFunction':
      const arg1 = ast2str(ast.args[0], isGlobal, args, false)
      const arg2 = ast2str(ast.args[1], isGlobal, args, false)
      return `math.${ast.value}(${arg1}, ${arg2})`

    case 'unaryOperator':
      res = ast2str(ast.args[0], isGlobal, args, isGlobal)
      return res.includes(' ') ? `${ast.value}(${res})` : ast.value + res

    case 'binaryOperator':
      const left = ast2str(ast.args[0], isGlobal, args, false)
      const right = ast2str(ast.args[1], isGlobal, args, false)
      res = `${left} ${ast.value} ${right}`
      return skipParens ? res : `(${res})`

    case 'ternaryOperator':
      const cond = ast2str(ast.args[0], isGlobal, args, false)
      const exprT = ast2str(ast.args[1], isGlobal, args, false)
      const exprF = ast2str(ast.args[2], isGlobal, args, false)
      res = `${cond} and ${exprT} or ${exprF}`
      return skipParens ? res : `(${res})`

    default:
      throw new Error(`Can not convert node type ${ast.type}`)
  }
}

const constants = {
  e: 'math.exp(1)',
  pi: 'math.pi',
}

export default ast2str
