import { CSSProperties, memo } from 'react'
import { useIntl } from 'react-intl'
import styles from '../userNotifications.module.css'
import { formatDate } from '../../../../utils/lang'
import { IUserNotification } from 'au-nsi/notifications'

interface IProps {
  item: IUserNotification
  style?: CSSProperties
  onClick: (item: IUserNotification) => void
  onAck: (item: IUserNotification) => void
}

const NotificationItem = ({ item, style, onAck, onClick }: IProps) => {
  const intl = useIntl()
  const className = !item.ack_ts ? 'is-info' : 'is-seen'
  const ackTitle = !item.ack_ts ? 'system.messages.mark_as_read' : 'system.messages.mark_as_unread'

  const handleAck = (e) => {
    e.stopPropagation()
    onAck(item)
  }

  return (
    <div style={style} className="alert-item__wrapper" onClick={() => onClick(item)}>
      <div className={`alert-item alert-card ${className} ${styles.card}`}>
        <div className={styles.cardHeader}>
          <div>{formatDate(item.created_at, 'dd MMM HH:mm')}</div>
          <div className={styles.ackIcon} title={intl.formatMessage({ id: ackTitle })} onClick={handleAck} />
        </div>

        <div>
          <span dangerouslySetInnerHTML={{ __html: item.message[intl.locale] }} />
        </div>
      </div>
    </div>
  )
}

export default memo(NotificationItem)
