import RequestRow from './ModerationRequestRow'
import { useIntl } from 'react-intl'
import styles from '../../user.module.css'
import { IModerationRequest } from 'au-nsi/moderation'

interface IProps {
  requests: IModerationRequest[]
  allLoaded: boolean
}

const ModerationRequestsList = ({ allLoaded, requests }: IProps) => {
  const intl = useIntl()
  const requestsTiles = requests.map((r) => <RequestRow key={r.id} request={r} />)

  return (
    <div className={styles.requestsList}>
      {requestsTiles.length === 0 && allLoaded && (
        <div className="text--gray">{intl.formatMessage({ id: 'user.requests.no_one_found' })}</div>
      )}
      {requestsTiles}
    </div>
  )
}

export default ModerationRequestsList
