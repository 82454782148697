import React from 'react'
import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg'
import ganttFactory from '../../services/gantt/gantt.factory'
import GanttService from '../../services/gantt/gantt.service'
import Gantt from '../GanttTable/Gantt'
import { Equipment } from 'au-nsi/equipment'
import GanttRow from './GanttRow'
import styles from './registrator.module.css'
import { PlatformModuleID } from 'au-nsi/shared'

class GanttTable extends React.PureComponent<Props> {
  private service: GanttService = ganttFactory()

  private gotoPq = () => {
    this.props.navigate(`/dashboards/pq`)
  }

  private gotoPower = () => {
    const { id } = this.props
    this.props.navigate(`/registrators/${id}/power`)
  }

  private gotoLfo = () => {
    const { id } = this.props
    this.props.navigate(`/registrators/${id}/lfo`)
  }

  private gotoUnbalance = () => {
    const { id } = this.props
    this.props.navigate(`/registrators/${id}/unbalance`)
  }

  private gotoDigitals = () => {
    const { id } = this.props
    this.props.navigate(`/registrators/${id}/digitals`)
  }

  render() {
    const { registrator, page, modules } = this.props

    const lfoRow = <GanttRow page="lfo" selected={page === 'lfo'} onClick={this.gotoLfo} />

    return (
      <Gantt service={this.service} embedded={true} id={0} page="" settings={null}>
        <Gantt.Header>
          <Gantt.Title>
            <ArrowRight className={styles.backBtn} onClick={this.gotoPq} />
            <div>{registrator && registrator.shortname}</div>
          </Gantt.Title>
          <Gantt.Timeline />
        </Gantt.Header>
        <Gantt.Body>
          <GanttRow page="power" selected={page === 'power'} onClick={this.gotoPower} />
          {modules.has('au-lfo') && lfoRow}
          <GanttRow page="unbalance" selected={page === 'unbalance'} onClick={this.gotoUnbalance} />
          <GanttRow page="digitals" selected={page === 'digitals'} onClick={this.gotoDigitals} />
        </Gantt.Body>
      </Gantt>
    )
  }
}

interface Props {
  id: string
  modules: Set<PlatformModuleID>
  page: string
  registrator: Equipment
  navigate: (to: string) => void
}

export default GanttTable
