import { useIntl } from 'react-intl'
import Datepicker from '../../../../shared/Inputs/Datepicker/Datepicker'
import InputRow from '../../../../shared/Inputs/InputRow'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import ToggleWithLabel from '../../../../shared/Inputs/ToggleWithLabel'
import { DAY, getMidnight } from './devices-form.utils'

const AccessExpirationForm = ({ disabled, expiration, onChange }: Props) => {
  const intl = useIntl()
  const today = getMidnight()
  const now = Date.now()

  const expirationDays = expiration > now ? Math.floor((expiration - now) / DAY) : 0

  const handleToggle = (v: boolean) => onChange(v ? 0 : today + 30 * DAY)

  const handleDaysChange = (days: number) => {
    onChange(today + days * DAY)
  }

  const handleTimeChange = (ts: number) => {
    if (ts > now) onChange(ts)
  }

  return (
    <>
      <ToggleWithLabel
        name=""
        value={!expiration}
        onChange={handleToggle}
        label={intl.formatMessage({ id: 'nsi.user.devices_form.expiration_settings.unlimited' })}
      />

      {!!expiration && (
        <div className="system__grid" style={{ marginTop: '2rem' }}>
          <InputRow label={intl.formatMessage({ id: 'nsi.user.devices_form.expiration_settings.days' })}>
            <NumberInput
              type="number"
              name="days"
              value={expirationDays}
              onChange={handleDaysChange}
              integer={true}
              min={1}
              fullWidth={true}
              disabled={disabled}
            />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'nsi.user.devices_form.expiration_settings.date' })}>
            <Datepicker
              time={expiration}
              onTimeChange={handleTimeChange}
              disabled={disabled}
              allowFutureSelect={true}
            />
          </InputRow>
        </div>
      )}

      {!!expiration && expiration <= now && (
        <div className="text--gray text--center" style={{ margin: '-1em 0 1em 0' }}>
          {intl.formatMessage({ id: 'nsi.user.devices_form.expiration_settings.expired' })}
        </div>
      )}
    </>
  )
}

interface Props {
  disabled?: boolean
  expiration: number
  onChange: (v: number) => void
}

export default AccessExpirationForm
