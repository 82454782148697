import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as CollectionIcon } from '../../../icons/collection.svg'
import { ReactComponent as DatabaseIcon } from '../../../icons/database.svg'
import { ReactComponent as SensorIcon } from '../../../icons/sensor.svg'
import { Equipment } from 'au-nsi/equipment'
import { selectCompositeDevices, selectEquipmentMap } from '../nsi.selectors'

/**
 * Иконка измерительного устройства выводимая разным цветом в зависимости от состояния ресивера
 */
const DeviceIcon = (props: Props) => {
  const equipment = useSelector(selectEquipmentMap)
  const compositeDevices = useSelector(selectCompositeDevices)

  const device = equipment.get(props.id)
  if (!device) return null

  const iconProps = { width: 20, height: 20, color: colors[device.state], style: props.style }
  const isComposite = compositeDevices.has(props.id)
  const isDatabase = device.protocol === 'db-client'

  if (isDatabase) return <DatabaseIcon {...iconProps} />
  else if (isComposite) return <CollectionIcon {...iconProps} style={{ ...iconProps.style, marginLeft: '0' }} />
  else return <SensorIcon {...iconProps} />
}

const colors: Record<Equipment['state'], string> = {
  ERROR: 'var(--danger-text)',
  WARNING: 'var(--warning)',
  READING_CONF: 'var(--color)',
  READING_CONF_ERROR: 'var(--color)',
  READING_CONF_SUCCESS: 'var(--color)',
  RUNNING: 'var(--text-success)',
  STARTING: 'var(--color)',
  STOPPED: 'var(--color)',
  STOPPING: 'var(--color)',
}

interface Props {
  id: string
  style?: React.CSSProperties
}

export default DeviceIcon
