import { Select } from '@alterouniversal/au-react-components'
import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import { Parameter } from 'au-nsi/parameters'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { useAppSelector } from '../../../redux/store'
import { ReduxState } from '../../../redux/store.types'
import InputRow from '../../../shared/Inputs/InputRow'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import Loader from '../../../shared/Utils/Loader'
import { deepCopy } from '../../../utils/misc'
import { selectEquipmentMap, selectEquipmentOptions } from '../../Nsi/nsi.selectors'
import { selectParameterOptions, selectParametersMap } from '../../Parameters/params.selectors'
import dashboardActions from '../dashboard.actions'
import { extractDevices, extractParameters, replaceDevices, replaceParameters } from '../dashboard.utils'
import { generatePosition } from '../position.generator'

interface IProps<T extends IDashboardComponent> {
  widget: T
  onClose: () => void
}

const WidgetCopyModal = <T extends IDashboardComponent>(props: IProps<T>) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()

  const dashboards = useSelector((state: ReduxState) => state.dashboards.dashboards)
  const components = useAppSelector((state) => state.dashboards.components)
  const equipmentOptions = useSelector(selectEquipmentOptions)
  const equipmentMap = useSelector(selectEquipmentMap)
  const parametersOptions = useSelector(selectParameterOptions)
  const parametersMap = useSelector(selectParametersMap)

  const deviceList = useMemo(() => Array.from(extractDevices(props.widget, new Set<Equipment['id']>())), [props.widget])
  const parametersList = useMemo(
    () => Array.from(extractParameters(props.widget, new Set<Parameter['id']>())),
    [props.widget]
  )

  const [copyDashboards, setCopyDashboards] = useState<Array<IDashboard['id']>>([props.widget.dashboard_id])
  const [mapDevices, setMapDevices] = useState(false)
  const [deviceMapping, setDeviceMapping] = useState<Record<Equipment['id'], Equipment['id']>>({})
  const [mapParameters, setMapParameters] = useState(false)
  const [parametersMapping, setParametersMapping] = useState<Record<Parameter['id'], Parameter['id']>>({})

  const handleCopy = () => {
    const dashboardComponents = components[props.widget.dashboard_id]

    const newWidget = deepCopy(props.widget)
    const newPosition = generatePosition(
      dashboardComponents,
      props.widget.dashboard_id,
      null,
      null,
      props.widget.w,
      props.widget.h
    )

    replaceDevices(newWidget, new Map(Object.entries(deviceMapping)))
    replaceParameters(newWidget, new Map(Object.entries(parametersMapping)))

    // Рассчитанная позиция применяется только для копии на текущий экран. При копировании виджета на другие экраны
    // он встаёт на ту же позицию, на какой находился на исходном. (https://alterosmart.atlassian.net/browse/AE-687?focusedCommentId=15164)
    const requests = Promise.all(
      copyDashboards.map((id) => {
        const wgt = newWidget.dashboard_id === id ? { ...newWidget, ...newPosition } : newWidget
        return dispatch(dashboardActions.createComponent(id, wgt))
      })
    )

    wait(requests, props.onClose)
  }

  const renderEquipmentMapping = () => {
    const devices = deviceList.map((deviceId) => (
      <tr>
        <td>{equipmentMap.get(deviceId)?.name}</td>
        <td>
          <Select
            value={deviceMapping[deviceId] ?? deviceId}
            onChange={(mapped) => setDeviceMapping({ ...deviceMapping, [deviceId]: mapped })}
            options={equipmentOptions}
            name={''}
          />
        </td>
      </tr>
    ))

    return (
      <table className="nsi-settings-table is-fixed" style={{ marginBottom: '1em' }}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.copy.map_from' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.copy.map_to' })}</th>
          </tr>
        </thead>
        <tbody>{devices}</tbody>
      </table>
    )
  }

  const renderParametersMapping = () => {
    const parameters = parametersList.map((parameterId) => (
      <tr>
        <td>{parametersMap.get(parameterId)?.name}</td>
        <td>
          <Select
            value={parametersMapping[parameterId] ?? parameterId}
            onChange={(mapped) => setParametersMapping({ ...parametersMapping, [parameterId]: mapped })}
            options={parametersOptions}
            name={''}
          />
        </td>
      </tr>
    ))

    return (
      <table className="nsi-settings-table is-fixed" style={{ marginBottom: '2em' }}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.copy.map_from_parameter' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.copy.map_to' })}</th>
          </tr>
        </thead>
        <tbody>{parameters}</tbody>
      </table>
    )
  }

  return (
    <Modal size={'lg'} onClose={props.onClose} closeOnClickOutside={false} closeOnEscape={true}>
      <InputRow label={intl.formatMessage({ id: 'dashboards.copy.widget' })} style={{ marginBottom: '2em' }}>
        <MultiSelect name={''} options={dashboards} onChange={setCopyDashboards as any} selection={copyDashboards} />
      </InputRow>
      <ToggleWithLabel
        name={''}
        style={{ marginBottom: '1em' }}
        label={intl.formatMessage({ id: 'dashboards.copy.map_devices' })}
        value={mapDevices}
        onChange={setMapDevices}
      />
      {mapDevices && renderEquipmentMapping()}
      <ToggleWithLabel
        name={''}
        style={{ marginBottom: '1em' }}
        label={intl.formatMessage({ id: 'dashboards.copy.map_parameters' })}
        value={mapParameters}
        onChange={setMapParameters}
      />
      {mapParameters && renderParametersMapping()}

      {!loading && copyDashboards.length > 0 && (
        <ModalFooter loading={loading} onCancel={props.onClose} onSave={handleCopy} />
      )}
      {loading && <Loader />}
      {copyDashboards.length === 0 && !loading && (
        <div className="text--danger center">
          {intl.formatMessage({ id: 'dashboards.copy.widget.select_at_least_one' })}
        </div>
      )}
    </Modal>
  )
}

export default WidgetCopyModal
