import { VerificationOptions } from './VerificationOptions/VerificationOptions'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import MultiSelect from '../../../../shared/Inputs/MultiSelect'
import { useMemo } from 'react'
import { selectAccessRights } from '../../../App/app.selectors'
import { useSettingsAPI } from '../settings.hooks'
import renderFooter from '../../system.footer'
import { useIntl } from 'react-intl'
import { PmuVerificationSettings } from 'au-nsi/settings'

export const AuPmuVerification = () => {
  const intl = useIntl()
  const deg = useSelector((state: ReduxState) => state.parameters.units['deg'])

  const settings = useSettingsAPI<PmuVerificationSettings>('au-pmu-verification')

  const parametersOptions = useMemo(
    () => [
      { id: 'voltage', name: intl.formatMessage({ id: 'vector.charts.voltage' }) },
      { id: 'current', name: intl.formatMessage({ id: 'pq.trends.currentMagnitude' }) },
    ],
    [intl.locale]
  )

  const allowEditing = useSelector(selectAccessRights)['settings:update']
  const equipment = useSelector((state: ReduxState) =>
    state.nsi.equipment
      .filter((e) => e.protocol === 'C.37.118')
      .map((e) => {
        return { name: e.name, id: e.id }
      })
  )

  const handleChange = (value: any, name: string) => {
    settings.updateDraft({ [name]: value })
  }

  const renderTable = () => (
    <table style={{ tableLayout: 'fixed' }} className={'nsi-settings-table'}>
      <thead>
        <tr>
          <th style={{ width: '65%' }}>{intl.formatMessage({ id: 'system.verification.do_for_devices' })}</th>
          <th>{intl.formatMessage({ id: 'system.verification.using_parameters' })}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <MultiSelect
              disabled={!settings.editing}
              name={'devices'}
              options={equipment}
              onChange={handleChange}
              selection={settings.draft.devices}
            />
          </td>
          <td>
            <MultiSelect
              disabled={!settings.editing}
              name={'parameters'}
              options={parametersOptions}
              onChange={handleChange}
              selection={settings.draft.parameters}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )

  return (
    <div className={'nsi-main__container'}>
      <h2 className={'system__title'}>{intl.formatMessage({ id: 'nav.system.au-pmu-verification' })}</h2>

      {settings.draft && renderTable()}
      {settings.draft && (
        <>
          <div className={'system__content-footer'} />
          <VerificationOptions
            name={'relativeCriterionOptions'}
            settings={settings.draft.relativeCriterionOptions}
            onChange={handleChange}
            acceptChange={settings.editing}
            service={'au-pmu-verification_1'}
            validate={true}
          />
          <div className={'system__content-footer'} />
          <VerificationOptions
            name={'absoluteCriterionAngleOptions'}
            settings={settings.draft.absoluteCriterionAngleOptions}
            onChange={handleChange}
            acceptChange={settings.editing}
            service={'au-pmu-verification_2'}
            unit={deg}
            validate={true}
          />
          <div className={'system__content-footer'} />
          <VerificationOptions
            name={'absoluteAngleInfelicity'}
            settings={settings.draft.absoluteAngleInfelicity}
            onChange={handleChange}
            acceptChange={settings.editing}
            service={'au-pmu-verification_3'}
            unit={deg}
          />
        </>
      )}
      {allowEditing && renderFooter(settings)}
    </div>
  )
}
