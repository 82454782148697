import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import useDeviceWidth from '../../../hooks/useDeviceWidth'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import { filter, IRoute } from './nav.utils'

interface Props {
  routes: IRoute[]
  className?: string
  parentPath?: string
  icon?: React.ReactNode
}

/**
 * Компонент для рендеринга элементов навигации
 */
const Links = ({ routes, icon, className = 'top-menu__item', parentPath = '' }: Props) => {
  const intl = useIntl()
  const location = useLocation()
  const { isDesktop } = useDeviceWidth()

  const modules = useSelector(selectEnabledModules)
  const rights = useSelector(selectAccessRights)

  const activeClassName = className + ' is-active'

  const links = filter(routes, rights, modules).map((route) => {
    const { path } = route
    const to = path.endsWith('/*') ? path.slice(0, -2) : path

    // отображаем вложенные дочерние ссылки только если пользователь находится на этом элементе или его детях
    const showChildren = !isDesktop && route.children && location.pathname !== '/' && location.pathname.startsWith(to)

    return (
      <React.Fragment key={route.id}>
        <NavLink
          className={({ isActive }) => (isActive ? activeClassName : className)}
          to={parentPath + to}
          end={route.exact || route.path === '/'}
        >
          {icon}
          {intl.formatMessage({ id: `nav.` + route.id })}
        </NavLink>

        {showChildren && (
          <span className="top-menu__children">
            <Links routes={route.children} icon={icon} className={className} parentPath={parentPath + to + '/'} />
          </span>
        )}
      </React.Fragment>
    )
  })

  return <>{links}</>
}

export default Links
