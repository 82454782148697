import React from 'react'

const nextModes = [0, 3, 1, 2]

/**
 * Иконка для выбора режима отрисовки данных:
 * 1 - отрисовка линией
 * 2 - отрисовка отдельных точек
 * 3 - отрисовка и линией и точками
 *
 * При клике на иконку переход происходит следующим образом:
 * 1 -> 3 -> 2 -> 1 и т.д. по кругу
 */
const ModeSelector = (props: Props) => {
  const mode = props.lineMode

  const handleClick = () => {
    props.onChange(nextModes[mode])
  }

  const isLine = (mode & 1) === 1
  const isDots = (mode & 2) === 2

  return (
    <span title={props.title}>
      <svg className="line-chart__icon" viewBox="0 0 16 16" onClick={handleClick}>
        {isLine && <line x1="0" y1="8" x2="16" y2="8" stroke="currentColor" strokeWidth="1.5" />}
        {isDots && <circle cx="8" cy="8" r="3" fill="currentColor" />}
      </svg>
    </span>
  )
}

interface Props {
  lineMode: number
  onChange: (m: number) => void
  title: string
}

export default React.memo(ModeSelector)
