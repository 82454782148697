import { IUserTask } from './userTasks.types'
import { actions } from './userTasks.reducer'
import userTasksDb from './userTasks.db'

const loadTasks = () => async (dispatch) => {
  const tasks = await userTasksDb.loadTasks()

  dispatch(actions.init(tasks))
}

const createUserTask = (task: IUserTask) => async (dispatch) => {
  await userTasksDb.addTask(task)

  dispatch(actions.userTaskCreated(task))
}

const updateUserTask = (task: IUserTask) => async (dispatch) => {
  await userTasksDb.updateTask(task)

  dispatch(actions.userTaskUpdated(task))
}

const deleteUserTask = (task: IUserTask) => async (dispatch) => {
  await userTasksDb.deleteTask(task)

  dispatch(actions.userTaskDeleted(task))
}

const activateTask = (id: IUserTask['id']) => (dispatch) => {
  dispatch(actions.setTaskState({ id, active: true }))
}

const disableTask = (id: IUserTask['id']) => (dispatch) => {
  dispatch(actions.setTaskState({ id, active: false }))
}

const userTasksActions = { createUserTask, updateUserTask, deleteUserTask, loadTasks, activateTask, disableTask }
export default userTasksActions
