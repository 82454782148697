import { IDashboardComponent } from 'au-nsi/dashboards'
import React, { useState } from 'react'
import { IntlShape } from 'react-intl'
import useContextMenu from '../../../hooks/useContextMenu'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'
import Gauge from '../../../shared/Gauge/Gauge'
import ScaleComponent from '../../../shared/Gauge/Scale'
import Counter from '../../../shared/Inputs/Counter/Counter'
import ChartPanel from '../../../shared/LineCharts/ChartPanel'
import VectorGraphWrapper from '../../../shared/VectorGraph/VectorGraphWrapper'
import { deepCopy } from '../../../utils/misc'
import BarChart from '../BarChart/BarChart'
import Button from '../Button/Button'
import { CardMoveType, WidgetType } from '../dashboard.types'
import { replaceDevices } from '../dashboard.utils'
import GanttTable from '../Gantt/GanttTable'
import Histogram from '../Histogram/Histogram'
import Image from '../Image/ImageComponent'
import Indicator from '../Indicator/Indicator'
import GeoMap from '../Map/MapLoader'
import PhasePortrait from '../PhasePortrait/PhasePortrait'
import SVGDiagram from '../SVG/SVGDiagram'
import Table from '../Table/Table'
import TemplateVariables from '../Template/TemplateVariables'
import Text from '../Text/Text'
import Windrose from '../Windrose/Windrose'
import CardPositionControls from './CardPositionControls'
import ErrorBoundary from './ErrorBoundary'
import WidgetCopyModal from './WidgetCopyModal'

const components: { [type in WidgetType]: React.ComponentType<any> } = {
  bar_chart: BarChart,
  button: Button,
  gantt: GanttTable,
  gauge: Gauge,
  gauge_linear: ScaleComponent,
  histogram: Histogram,
  image: Image,
  indicator: Indicator,
  linear_chart: ChartPanel,
  map: GeoMap,
  phase_portrait: PhasePortrait,
  svg_diagram: SVGDiagram,
  table: Table,
  template_variables: TemplateVariables,
  text: Text,
  vector_chart: VectorGraphWrapper,
  windrose: Windrose,
}

const DashboardCard = (props: Props) => {
  const { component } = props

  const [lastResize, setResize] = React.useState(0)
  const [copyModalOpen, setCopyModalOpen] = useState(false)

  const cardRef = React.useRef<HTMLDivElement>()
  const menuRef = React.useRef<HTMLDivElement>()

  const { open, setOpen } = useContextMenu(cardRef, menuRef, props.allowEditing)

  // при закрытии меню синхронизировать изменения слоя (если они были)
  React.useEffect(() => {
    if (!open) props.onMoveFinish()
  }, [open])

  const handleEdit = () => {
    props.onEdit(component)
    setOpen(false)
  }

  const handleDelete = () => {
    props.onDelete(component)
    setOpen(false)
  }

  const handleCopy = () => {
    setCopyModalOpen(true)
    setOpen(false)
  }

  const handleLayer = (z: number) => {
    cardRef.current.style.zIndex = z + ''
    props.onMove({ ...component, z }, 'layer')
  }

  const menu = (
    <ContextMenu ref={menuRef} open={open && props.allowEditing}>
      <div className="nsi-dropdown__item">
        <div>{props.intl.formatMessage({ id: 'dashboards.layer' })}:</div>
        <Counter value={component.z} min={1} onChange={handleLayer} />
      </div>
      <ContextMenuOption type={'settings'} onClick={handleEdit} />
      {component.type !== 'template_variables' && <ContextMenuOption type={'copy'} onClick={handleCopy} />}
      <ContextMenuOption type={'delete'} onClick={handleDelete} />
    </ContextMenu>
  )

  const Component: any = components[component.type]

  return (
    <div ref={cardRef} className="dashboard-card">
      {copyModalOpen && <WidgetCopyModal widget={component} onClose={() => setCopyModalOpen(false)} />}
      <ErrorBoundary>
        <Component
          id={component.id}
          dashboard={component.dashboard_id}
          lastResize={lastResize}
          settings={applyMapping(component, props.deviceMapping)}
        />
      </ErrorBoundary>

      <CardPositionControls {...props} cardRef={cardRef} onResize={() => setResize(lastResize + 1)} />
      {menu}
    </div>
  )
}

// замена устройств, заданных в настройках виджета, на указанные в маппинге
const applyMapping = (c: IDashboardComponent, mapping: Map<string, string>) => {
  if (!mapping || !mapping.size) return c.settings

  const copy = deepCopy(c)
  replaceDevices(copy, mapping)

  return copy.settings
}

interface Props {
  allowEditing: boolean
  component: IDashboardComponent
  deviceMapping: Map<string, string>
  intl: IntlShape
  onDelete: (component: IDashboardComponent) => void
  onEdit: (component: IDashboardComponent) => void
  onMove: (position: IDashboardComponent, type: CardMoveType) => void
  onMoveFinish: () => void
  viewport: number
}

export default React.memo(DashboardCard)
