import { FormattedMessage } from 'react-intl'
import AppRoutes from '../TopMenu/Nav/Routes'
import journalRoutes from '../TopMenu/Nav/journal.routes'
import Links from '../TopMenu/Nav/Links'
import HideableLeftMenu from '../../shared/HideableLeftMenu/HIdeableLeftMenu'

const Journal = () => {
  const icon = <span className="system__nav-icon" />

  const tree = (
    <HideableLeftMenu>
      <div className="nsi-tree_title">
        <FormattedMessage id="nav.journal" />
      </div>
      <div className="system__nav">
        <Links routes={journalRoutes} className="system__nav-item" icon={icon} />
      </div>
    </HideableLeftMenu>
  )

  return (
    <div className="nsi nsi-journal">
      {tree}

      <div className="nsi-main__wrapper">
        <AppRoutes routes={journalRoutes} fallback="" />
      </div>
    </div>
  )
}

export default Journal
