import { IImageComponent, IImageSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import Form from '../../../shared/Forms/Form'
import PlusButton from '../../../shared/Forms/PlusButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { showError } from '../../../utils/notifications'
import ImageInput from '../../Libraries/Images/ImageInput'
import css from './image.module.css'
import ImageCondition from './ImageCondition'

const ImageSettings = (props: Props) => {
  const intl = useIntl()
  const [settings, setSettings] = React.useState(props.component.settings)

  const boolOptions = [
    { value: true, title: intl.formatMessage({ id: 'common.yes' }) },
    { value: false, title: intl.formatMessage({ id: 'common.no' }) },
  ]

  const handleSave = () => {
    if (!settings.image_id) {
      return showError('dashboards.image.errors.empty_image')
    }

    const conditions = settings.conditions.filter((row) => row.image_id && row.device_id && row.parameter_id)
    props.onSave({ ...settings, conditions })
  }

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleConditionRemove = (index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.conditions.splice(index, 1)
      })
    )
  }

  const handleConditionChange = (key: string, value: string, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.conditions[index][key] = value
      })
    )
  }

  const handleConditionAdd = () => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.conditions.push({ image_id: '', device_id: '', parameter_id: '', condition: '>', value: 0 })
      })
    )
  }

  const conditions = settings.conditions.map((condition, index) => {
    return (
      <ImageCondition
        key={index}
        index={index}
        condition={condition}
        onChange={handleConditionChange}
        onRemove={handleConditionRemove}
      />
    )
  })

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className={css.settingsRow}>
            <InputRow label={intl.formatMessage({ id: 'common.name' })}>
              <TextInput name="title" required={false} value={settings.title} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.image.settings.image_id' })}>
              <ImageInput name="image_id" value={settings.image_id} onChange={handleChange} />
            </InputRow>
          </div>

          <div className={css.settingsRow}>
            <InputRow label={intl.formatMessage({ id: 'dashboards.image.settings.dynamic' })}>
              <Dropdown name="dynamic" value={settings.dynamic} options={boolOptions} onChange={handleChange} />
            </InputRow>
          </div>

          {settings.dynamic && conditions}
          {settings.dynamic && (
            <PlusButton
              textId="dashboards.image.settings.add_condition"
              onClick={handleConditionAdd}
              style={{ marginLeft: 0 }}
            />
          )}

          <ModalFooter onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: IImageComponent
  onCancel: () => void
  onSave: (settings: IImageSettings) => void
  title: string
}

export default ImageSettings
