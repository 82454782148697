import React from 'react'
import { useIntl } from 'react-intl'
import useDataService from '../../hooks/useDataService'
import useDataRate from '../../hooks/useDataRate'
import ThreePhaseSVG from './ThreePhaseSVG'

import './styles.css'

const initialState = {
  i1: '',
  i2: '',
  i2i1: '',
  iA: '',
  iB: '',
  iN: '',
  iC: '',
  uAB: '',
  uBC: '',
  uCA: '',
}

const parameters = [
  'CurrentMagnitudeSeqPositive',
  'CurrentMagnitudeSeqNegative',
  'CurrentUnbalance',
  'CurrentMagnitudePhaseA',
  'CurrentMagnitudePhaseB',
  'NeutralConductorCurrent',
  'CurrentMagnitudePhaseC',
  'VoltageMagnitudeLineAB',
  'VoltageMagnitudeLineBC',
  'VoltageMagnitudeLineCA',
]

const ThreePhaseCircuit = (props: Props) => {
  const [state, setState] = React.useState(initialState)
  const intl = useIntl()

  const service = useDataService(888, { singleValueMode: true })
  useDataRate(service)

  React.useEffect(() => {
    const kilovolt = intl.formatMessage({ id: 'vector.kilovolt' })

    service.setDataSelectors([{ device_id: props.deviceId, parameters }])

    service.onTick = () => {
      if (!service.shouldRender()) return

      const data = service.selectCurrentPoint(props.deviceId)
      if (!data) return

      setState({
        i1: `${num2str(data.CurrentMagnitudeSeqPositive)} A`,
        i2: `${num2str(data.CurrentMagnitudeSeqNegative)} A`,
        i2i1: `${num2str(data.CurrentUnbalance * 100)} %`,
        iA: `${num2str(data.CurrentMagnitudePhaseA)} A`,
        iB: `${num2str(data.CurrentMagnitudePhaseB)} A`,
        iN: `${num2str(data.NeutralConductorCurrent)} A`,
        iC: `${num2str(data.CurrentMagnitudePhaseC)} A`,
        uAB: `${num2str(data.VoltageMagnitudeLineAB / 1e3)} ${kilovolt}`,
        uBC: `${num2str(data.VoltageMagnitudeLineBC / 1e3)} ${kilovolt}`,
        uCA: `${num2str(data.VoltageMagnitudeLineCA / 1e3)} ${kilovolt}`,
      })
    }
  }, [props.deviceId])

  return (
    <div className="three-phase-circuit">
      <ThreePhaseSVG viewBox={props.viewBox} {...state} />
    </div>
  )
}

const num2str = (num: number): string => {
  return num != null && !Number.isNaN(num) ? num.toFixed(2) : ''
}

interface Props {
  deviceId: string
  viewBox: string
}

export default ThreePhaseCircuit
