import React, { CSSProperties, FocusEventHandler, ForwardedRef, forwardRef } from 'react'
import InputWrapper from './InputWrapper'
import useCombinedRefs from '../../hooks/useCombinedRef'

const TextareaInput = forwardRef((props: Props, forwardRef: ForwardedRef<HTMLTextAreaElement>) => {
  const innerTextareaRef = React.useRef<HTMLTextAreaElement>()
  const textarea = useCombinedRefs(innerTextareaRef, forwardRef)
  const hiddendiv = React.useRef<HTMLDivElement>()
  const timer = React.useRef(null)

  React.useEffect(() => {
    if (!props.disabled) {
      textarea.current.value = props.value ?? ''
      calcHeight()
    }

    return () => clearTimeout(timer.current)
  }, [props.disabled])

  React.useEffect(() => {
    if (textarea.current && textarea.current.value !== props.value) {
      textarea.current.value = props.value ?? ''
      calcHeight()
    }
  }, [props.value])

  // Рассчет размеров textarea для того чтобы она автоматически подстраивалась
  // под введенный пользователем текст. Содержимое копируется в скрытый div,
  // рассчитываем получившуюся высоту этого div и применяем ее к textarea.
  const calcHeight = () => {
    const { value } = textarea.current
    const div = hiddendiv.current
    div.innerText = (value.endsWith('\n') ? value + 'AZ' : value) ?? ''

    div.style.visibility = 'hidden'
    div.style.display = 'block'
    const height = Math.max(div.offsetHeight + 4, 56)

    textarea.current.style.height = height + 'px'
    div.style.visibility = 'visible'
    div.style.display = 'none'
  }

  const syncChanges = () => {
    props.onChange(textarea.current.value, props.name)
  }

  const handleBlur = (e) => {
    if (props.onBlur) props.onBlur(e)
    syncChanges()
  }

  // небольшая задержка пропагации изменений в родительский компонент для того чтобы не
  // перерендеривать все дерево на каждое нажатие клавиши
  const handleChange = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(syncChanges, 500)
    calcHeight()
  }

  if (props.useWrapper === false) {
    return (
      <>
        <textarea
          required={props.required}
          ref={textarea}
          className="nsi-input"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div ref={hiddendiv} className="textarea-hiddendiv" />
      </>
    )
  }

  return (
    <InputWrapper value={props.value ?? ''} disabled={props.disabled} style={props.style}>
      <textarea
        required={props.required}
        ref={textarea}
        className="nsi-input"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div ref={hiddendiv} className="textarea-hiddendiv" />
    </InputWrapper>
  )
})

interface Props {
  value: string
  onChange: (value: string, name: string) => void
  required?: boolean
  name?: string
  disabled?: boolean
  style?: CSSProperties
  useWrapper?: boolean
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
}

export default TextareaInput
