import * as api from './calendar.api'

/**
 * Парсинг json или txt файла с информацией о выходных днях в формате
 * с сайта http://xmlcalendar.ru/
 */
export const parseHolidaysFile = (content: string): api.ServerFormat => {
  const isJSON = content.startsWith('{')
  const isTXT = /^[\d.\-\s]+$/gm.test(content)

  if (isJSON) return parseJSON(content)
  else if (isTXT) return parseTXT(content)
  else throw new Error('Unsupported file structure')
}

const parseJSON = (content: string) => {
  const data = JSON.parse(content)

  for (const month of data.months) {
    if (typeof month.days === 'string') {
      month.days = month.days.split(',').map((v) => parseInt(v))
    }
  }

  return data
}

const parseTXT = (content: string) => {
  const result: api.ServerFormat = { calendar_id: null, year: null, months: [] }

  const separator = content.includes('-') ? '-' : '.'
  const lines = content.trim().split('\n')

  for (const line of lines) {
    const parts = line.trim().split(separator)
    const year = +parts[0]
    const month = +parts[1]
    const day = +parts[2]

    result.year = year

    const m = result.months.find((e) => e.month === month)
    if (m) m.days.push(day)
    else result.months.push({ month, days: [day] })
  }

  return result
}
