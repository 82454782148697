import React from 'react'
import TimeInput from './TimeInput'
import './time.styles.css'

export default class TimePicker extends React.PureComponent<TimePickerProps> {
  private get time() {
    const d = new Date(this.props.ts)
    const hours = d.getHours()
    const minutes = d.getMinutes()
    const seconds = d.getSeconds()
    return { hours, minutes, seconds }
  }

  private handleHoursChange = (hours: number) => {
    const d = new Date(this.props.ts)
    this.props.onChange(d.setHours(hours))
  }

  private handleMinutesChange = (minutes: number) => {
    const d = new Date(this.props.ts)
    this.props.onChange(d.setMinutes(minutes))
  }

  private handleSecondsChange = (seconds: number) => {
    const d = new Date(this.props.ts)
    this.props.onChange(d.setSeconds(seconds))
  }

  render() {
    const { hours, minutes, seconds } = this.time

    return (
      <div className="timepicker">
        <TimeInput time={hours} onChange={this.handleHoursChange} maxValue={24} />
        <div className="timepicker__separator">:</div>
        <TimeInput time={minutes} onChange={this.handleMinutesChange} maxValue={60} />
        <div className="timepicker__separator">:</div>
        <TimeInput time={seconds} onChange={this.handleSecondsChange} maxValue={60} />
      </div>
    )
  }
}

interface TimePickerProps {
  ts: number
  onChange: (ts: number) => void
}
