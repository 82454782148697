import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { Equipment } from 'au-nsi/equipment'
import { SotiAssoLink } from 'au-nsi/settings'
import { useIntl } from 'react-intl'
import RemoveButton from '../../../../../shared/Forms/RemoveButton'
import Dropdown, { DropdownOption } from '../../../../../shared/Inputs/Dropdown'
import { VerificationLink, VerificationService } from '../verification.types'
import { formatPeriod, verificationConfig } from '../verification.utils'

interface IProps {
  link: VerificationLink
  acceptChange: boolean
  onChange: (current: VerificationLink, index: number) => void
  onRemove: (index: number) => void
  periodOptions: DropdownOption<number>[]
  acceptedDevices: SelectOption[]
  acceptedReferences: SelectOption[]
  equipment: Map<any, Equipment>
  index: number
  type: VerificationService
}

export const VerificationTableRow = ({
  link,
  acceptChange,
  periodOptions,
  onChange,
  onRemove,
  acceptedDevices,
  acceptedReferences,
  equipment,
  index,
  type,
}: IProps) => {
  const intl = useIntl()

  const handleChange = (value, name) => {
    onChange({ ...link, [name]: value }, index)
  }

  const renderLastCol = () => {
    if (type === 'au-soti-asso') {
      const s_link = link as SotiAssoLink
      const typeOptions = verificationConfig[type].typeOptions.map((opt) => {
        return { ...opt, title: intl.formatMessage({ id: opt.title }) }
      })

      return acceptChange ? (
        <div
          style={
            s_link.compare_type === 'average'
              ? { width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }
              : { width: '100%' }
          }
        >
          <Dropdown
            name={'compare_type'}
            value={typeOptions.find((option) => option.value === s_link.compare_type)?.value}
            onChange={handleChange}
            options={typeOptions}
          />
          {s_link.compare_type === 'average' && (
            <Dropdown
              value={periodOptions.find((option) => option.value === link.period)?.value}
              name={'period'}
              onChange={handleChange}
              options={periodOptions}
            />
          )}
        </div>
      ) : (
        <>
          {intl.formatMessage({ id: 'system.verification.' + s_link.compare_type })}
          {s_link.period && s_link.compare_type === 'average' && ', ' + formatPeriod(link, intl, type)}
        </>
      )
    }

    return acceptChange ? (
      <Dropdown
        value={periodOptions.find((option) => option.value === link.period)?.value}
        name={'period'}
        onChange={handleChange}
        options={periodOptions}
      />
    ) : (
      <>{formatPeriod(link, intl, type)}</>
    )
  }

  return acceptChange ? (
    <tr>
      <td>
        <Select name={'reference_id'} value={link.reference_id} onChange={handleChange} options={acceptedReferences} />
      </td>
      <td>
        <Select name={'device_id'} value={link.device_id} onChange={handleChange} options={acceptedDevices} />
      </td>
      <td>{renderLastCol()}</td>
      <td align="center">
        <RemoveButton onRemove={() => onRemove(index)} id={'Remove'} />
      </td>
    </tr>
  ) : (
    <tr>
      <td>{equipment.get(link.reference_id)?.name || link.reference_id}</td>
      <td>{equipment.get(link.device_id)?.name || link.device_id} </td>
      <td>{renderLastCol()}</td>
    </tr>
  )
}
