import classnames from 'classnames'
import React from 'react'
import css from './tabs.module.css'

const FormTabs = (props: Props) => {
  const [index, setIndex] = React.useState(0)

  // при выходе за границу (при удалении таба например) переключаемся на последний из имеющихся
  React.useLayoutEffect(() => {
    if (index >= props.titles.length) {
      setIndex(props.titles.length - 1)
    }
  }, [props.titles.length])

  const handleClick = (e) => {
    setIndex(+e.currentTarget.dataset.index)
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    props.onRemove(+e.currentTarget.dataset.index)
  }

  const tab = React.Children.toArray(props.children)[index]

  const header = props.titles.map((title, i) => {
    const className = classnames(css.tab, { [css.tabActive]: index === i })

    return (
      <div key={title} data-index={i} className={className} onClick={handleClick}>
        <span>{title}</span>

        {i > 0 && props.onRemove && (
          <span data-index={i} onClick={handleRemove} className={css.removeIcon}>
            &times;
          </span>
        )}
      </div>
    )
  })

  // если разрешено добавление новых табов, то последним элементом добавляем для этого кнопку
  if (props.onAppend) {
    const handleAppend = () => {
      props.onAppend()
      setIndex(props.titles.length)
    }

    header.push(
      <div key="__append" className={css.tab + ' ' + css.tabAppend} onClick={handleAppend}>
        +
      </div>
    )
  }

  return (
    <div>
      <div className={css.tabs}>{header}</div>
      {tab}
    </div>
  )
}

interface Props {
  titles: string[]
  children: React.ReactNodeArray
  onAppend?: () => void
  onRemove?: (index: number) => void
}

export default FormTabs
