import React from 'react'
import { GanttData } from '../../../services/gantt/gantt.interfaces'
import CellWithToggle from '../../../shared/RegistratorCell/CellWithToggle'
import EventEmitter, { Unsubscribe } from '../../../utils/events'
import Gantt from '../../GanttTable/Gantt'
import { Equipment } from 'au-nsi/equipment'

class PqGanttRow extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe

  state: State = { data: [] }

  componentDidMount() {
    this.unsubscribe = this.props.data$.subscribe((data) => {
      this.setState({ data })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  private toggleVisibility = (id) => {
    this.props.onVisibilityToggle(id)
  }

  private handleClick = () => {
    this.props.onClick(this.props.registrator.id)
  }

  private renderDeviceCell() {
    const { registrator } = this.props
    return <CellWithToggle registrator={registrator} visible={registrator.visible} onChange={this.toggleVisibility} />
  }

  render() {
    const { registrator } = this.props
    const { id } = registrator

    const rows = this.state.data.map((row, i) => {
      const nested = i > 0
      const warning = row.warning.length > 0
      const danger = row.danger.length > 0

      const head = nested ? subrow(row.name) : this.renderDeviceCell()

      return (
        <Gantt.Row
          key={row.id + id}
          id={id}
          nested={nested}
          warning={warning}
          danger={danger}
          onClick={this.handleClick}
        >
          {head}
          <Gantt.Chart {...row} />
        </Gantt.Row>
      )
    })

    return <React.Fragment>{rows}</React.Fragment>
  }
}

const subrow = (name: string) => {
  return (
    <span>
      <span className="pq__icon-nested" />
      {name}
    </span>
  )
}

interface Props {
  registrator: Equipment
  data$: EventEmitter<GanttData[]>
  onClick: (id: string) => void
  onVisibilityToggle: (id: string) => void
}

interface State {
  data: GanttData[]
}

export default PqGanttRow
