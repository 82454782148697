import React from 'react'

/**
 * Сетка отображаемая поверх экрана для выравнивания элементов
 */
const DashboardGrid = (props: Props) => {
  const { gridStep } = props

  return (
    <svg width="100%" height="100%" style={{ position: 'absolute' }}>
      <pattern id="pattern-dashboard-grid" x="0" y="0" width={gridStep} height={gridStep} patternUnits="userSpaceOnUse">
        <circle cx="0" cy="0" r="2" fill="rgba(255, 255, 255, 0.8)" />
        <line x1="1" x2={gridStep} y1="1" y2="1" stroke="var(--gray-30)" strokeWidth={0.4} style={{ opacity: '0.5' }} />
        <line x1="1" x2="1" y1="1" y2={gridStep} stroke="var(--gray-30)" strokeWidth={0.4} style={{ opacity: '0.5' }} />
      </pattern>

      <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-dashboard-grid)"></rect>
    </svg>
  )
}

interface Props {
  gridStep: number
}

export default React.memo(DashboardGrid)
