import styles from './userTasks.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import { useEffect } from 'react'
import userTasksActions from './userTasks.actions'
import UserTaskForm from './components/UserTaskForm/UserTaskForm'
import UserTaskTab from './components/UserTaskTab/UserTaskTab'

/**
 * Компонент окон с пользовательскими задачами
 */
const UserTasks = () => {
  const dispatch = useDispatch()
  const tasks = useSelector((state: ReduxState) => state.userTasks.tasks)
  const active = useSelector((state: ReduxState) => state.userTasks.active)

  useEffect(() => {
    dispatch(userTasksActions.loadTasks())
  }, [])

  const Descriptions = tasks.map((task) => <UserTaskTab task={task} isActive={active.has(task.id)} key={task.id} />)
  const Forms = tasks.filter((t) => active.has(t.id)).map((task) => <UserTaskForm task={task} key={task.id} />)

  return (
    <div className={styles.userTaskPanel}>
      {Descriptions}
      {Forms}
    </div>
  )
}

export default UserTasks
