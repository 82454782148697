import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { actions as documentsActions } from '../../../../../Libraries/Documents/docs.reducers'
import styles from '../../request.module.css'
import Checkbox from '../../../../../../shared/Inputs/Checkbox/Checkbox'
import { useIntl } from 'react-intl'
import findCatalogItemsDiff from './catalogItemRequests.diff'
import RequestInstanceForm from './RequestInstanceForm'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import EntityRemoved from '../../components/EntityRemoved'
import { getCatalogByInstanceId } from '../../../../../Catalogs/catalogs.utils'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { IDocument } from 'au-nsi/documents'
import { CatalogItemRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const ItemRequestForm = ({ editing }: IProps) => {
  const intl = useIntl()

  const [request, setRequest] = useRecoilState<CatalogItemRequest>(selectedDraftRequestState as any)

  const body = request.request_body as CatalogItemRequest['request_body']
  const currentId = body?.id ?? extractIntIdFromUrl(request.url)
  const isClosed = request.rejected || request.moderated_at

  const dispatch = useDispatch()
  const catalogs = useSelector((state: ReduxState) => state.catalogs.catalogs)
  const { instance: currentItem } = useMemo(() => getCatalogByInstanceId(catalogs, currentId), [catalogs, currentId])
  const catalog = useMemo(
    () => catalogs.find((c) => c.id === currentItem?.catalog_id || c.id === body?.catalog_id),
    [currentItem?.catalog_id, catalogs, body?.catalog_id]
  )

  const [showOnlyDiff, setShowOnlyDiff] = useState(false)

  useEffect(() => {
    if (request.request_body?.files?.length > 0) {
      dispatch(documentsActions.addDocuments(request.request_body.files as IDocument[]))
    }

    return () => {
      dispatch(documentsActions.cleanDocuments())
    }
  }, [currentId, request])

  const handleChange = useCallback((schemaId: IPropertyDescription['id'], value: any) => {
    setRequest((prev) => ({
      ...prev,
      request_body: { ...prev.request_body, properties: { ...prev.request_body.properties, [schemaId]: value } },
    }))
  }, [])

  const empty = <EntityRemoved />
  const itemBefore = isClosed ? request.state_before : currentItem

  const [beforeItem, afterItem, formattedCatalog] = showOnlyDiff
    ? findCatalogItemsDiff(itemBefore, body, catalog, 'properties')
    : [itemBefore, body, catalog]

  const props = { onChange: handleChange, editing, catalog: formattedCatalog }

  const renderPrev = () => (beforeItem && catalog ? <RequestInstanceForm {...props} catalogItem={beforeItem} /> : empty)
  const renderAfter = () => (afterItem && catalog ? <RequestInstanceForm {...props} catalogItem={afterItem} /> : empty)

  if (request.method === 'POST') return renderAfter()
  if (request.method === 'DELETE') return renderPrev()

  const before = { id: 'user.request.before', render: renderPrev }
  const after = { id: 'user.request.after', render: renderAfter }

  return (
    <>
      <div className={styles.requestOnlyDiff}>
        <Checkbox checked={showOnlyDiff} onChange={setShowOnlyDiff} />
        <span>{intl.formatMessage({ id: 'user.request.show_only_diff' })}</span>
      </div>
      <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />
    </>
  )
}

export default ItemRequestForm
