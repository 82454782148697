import { Incident } from 'au-nsi/signal-events'
import { useIntl } from 'react-intl'
import { formatDate } from '../../../utils/lang'
import { DenormalizedIncident } from '../incident.interfaces'

const IncidentTime = (props: Props) => {
  const intl = useIntl()
  const { ts_start, ts_end } = props.incident

  const startTime = formatDate(ts_start / 1000, 'HH:mm:ss')
  const endTime = ts_end ? formatDate(ts_end / 1000, 'HH:mm:ss') : intl.formatMessage({ id: 'common.now' })

  const date = props.includeDate ? formatDate(ts_start / 1000, 'dd MMM YYY') + ' | ' : ''

  return (
    <div className="alerts-time">
      {date} {startTime} — {endTime}
    </div>
  )
}

interface Props {
  incident: Incident | DenormalizedIncident
  includeDate?: boolean
}

export default IncidentTime
