import classnames from 'classnames'
import React from 'react'
import usePopup from '../../../hooks/usePopup'
import { formatDistanceStrict } from '../../../utils/lang'
import NumberInput from '../../../shared/Inputs/NumberInput'

const units = {
  d: 1,
  m: 30,
  y: 365,
}

const unitsFmt = {
  d: 'day',
  m: 'month',
  y: 'year',
}

const DurationSelector = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  const duration = parseDuration(props.duration || '', { value: 100, unit: 'y' })

  const handleInputChange = (num: number) => {
    const res = num + duration.unit
    props.onChange(res, num * units[duration.unit])
  }

  const handleSelectChange = (e) => {
    e.stopPropagation()
    const unit = e.target.dataset.id
    const res = duration.value + unit
    props.onChange(res, duration.value * units[unit])
    setOpen(false)
  }

  const options = ['d', 'm', 'y'].map((u) => {
    const label = formatUnit(duration.value, u)
    const className = classnames('system__ds-dropdown-option', { 'is-selected': u === duration.unit })

    return (
      <span className={className} data-id={u} key={u} onClick={handleSelectChange}>
        {label}
      </span>
    )
  })

  const formattedUnit = formatUnit(duration.value, duration.unit)

  if (!props.editing) {
    return (
      <span className="system__input-disabled text--nowrap">
        {duration.value} &nbsp;{formattedUnit}
      </span>
    )
  }

  return (
    <div className="system__dur-select">
      <NumberInput
        name={''}
        type={'number'}
        integer={true}
        min={1}
        value={duration.value}
        onChange={handleInputChange}
      />
      <span ref={ref} onClick={() => setOpen(!open)} className="system__ds-dropdown">
        <span>{formattedUnit}</span>
        <span className="system__ds-dropdown-icon" />
        {open && <span className="system__ds-dropdown-options">{options}</span>}
      </span>
    </div>
  )
}

interface Props {
  duration: string
  onChange: (duration: string, duration_days: number) => void
  editing: boolean
}

interface Duration {
  value: number
  unit: 'y' | 'm' | 'd'
}

const parseDuration = (str: string, def: Duration): Duration => {
  const value = +str.slice(0, str.length - 1)
  const unit = str.slice(str.length - 1)

  return !Number.isNaN(value) && ['y', 'm', 'd'].includes(unit) ? ({ value, unit } as Duration) : def
}

const formatUnit = (delta: number, unit: string): string => {
  const label = formatDistanceStrict(delta, unitsFmt[unit])
  return label.split(' ')[2]
}

export default DurationSelector
