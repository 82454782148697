import { ICatalog, IPropertyDescription } from 'au-nsi/catalogs'
import { Fragment, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import PlusButton from '../../../../shared/Forms/PlusButton'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextInput from '../../../../shared/Inputs/TextInput'
import { selectAllCatalogs, selectSchemas, selectTypes } from '../../catalogs.selectors'
import { typeOptions } from '../../catalogs.utils'
import { getNextGroupPath } from '../InstanceForm/instanceForm.utils'
import ExtraPassportSettings from './ExtraPassportSettings'
import SchemaGroupWrapper from './SchemaGroupWrapper/SchemaGroupWrapper'
import SchemaRow from './SchemaRow'
import SchemaTableWrapper from './SchemaTableWrapper/SchemaTableWrapper'

interface IProps<T> {
  editing: boolean
  schemaObject: T

  nameKey: keyof T
  treeKey: keyof T

  onNameChange: (name: string) => void
  onInsertIntoGroup: (groupId: IPropertyDescription['id'], propertyId: IPropertyDescription['id']) => void
  onCreate: (groupId: IPropertyDescription['id'] | null, type: IPropertyDescription['type']) => void
  onUpdate: (updated: IPropertyDescription) => void
  onDelete: (id: IPropertyDescription['id']) => void
  onMove: (from: IPropertyDescription['id'], to: IPropertyDescription['id']) => void
}

const SchemaForm = <T extends { type?: ICatalog['type'] }>(props: IProps<T>) => {
  const intl = useIntl()

  const catalogs = useSelector(selectAllCatalogs)
  const types = useSelector(selectTypes)
  const schemas = useSelector(selectSchemas)

  const { catalogsOptions, userTypesOptions } = useMemo(
    () => ({
      catalogsOptions: schemas.map((c) => ({ label: c.name, value: c.id })),
      userTypesOptions: types.map((c) => ({ label: c.name, value: c.id })),
    }),
    [types, schemas]
  )
  const locTypeOptions = useMemo(() => typeOptions(intl), [])

  const renderName = () => {
    const value = props.schemaObject?.[props.nameKey] as unknown as string
    if (!props.editing) return <h2>{value ?? '-'}</h2>

    return (
      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'catalogs.name' })}>
          <TextInput value={value} name={'name'} onChange={props.onNameChange} />
        </InputRow>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <tr style={{ background: 'inherit' }}>
        {props.editing && <th style={{ width: '46px' }} />}
        <th>{intl.formatMessage({ id: 'catalogs.schema_property' })}</th>
        <th>{intl.formatMessage({ id: 'catalogs.data_type' })}</th>
        <th>{intl.formatMessage({ id: 'catalogs.schema_default_value' })}</th>
        <th style={{ width: '96px' }}>{intl.formatMessage({ id: 'catalogs.required' })}</th>
        {props.editing && <th style={{ width: '24px' }} />}
      </tr>
    )
  }

  const renderNode = (property: IPropertyDescription, path: string) => {
    if (property.type === 'group') {
      const currentPath = getNextGroupPath(path)
      let groupIndex = 0

      // Для первого листа ренедерим хедер
      return (
        <SchemaGroupWrapper
          path={currentPath + '.'}
          key={property.id}
          group={property}
          editing={props.editing}
          onInsertIntoGroup={props.onInsertIntoGroup}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
          onMove={props.onMove}
        >
          {property.children.map((prop, index) => {
            if (index === groupIndex && prop.type !== 'group') {
              return (
                <Fragment key={prop.id}>
                  {renderHeader()}
                  {renderNode(prop, currentPath + '.' + groupIndex.toString())}
                </Fragment>
              )
            }
            return renderNode(prop, currentPath + '.' + (prop.type === 'group' ? groupIndex++ : groupIndex))
          })}
        </SchemaGroupWrapper>
      )
    }

    return (
      <SchemaRow
        key={property.id}
        editing={props.editing}
        row={property}
        catalogs={catalogs}
        typeOptions={locTypeOptions}
        catalogsOptions={catalogsOptions}
        userTypesOptions={userTypesOptions}
        onChange={props.onUpdate}
        onRemove={props.onDelete}
        onTreeMove={props.onMove}
      />
    )
  }

  let groupIndex = 0
  return (
    <>
      {renderName()}
      {props.schemaObject.type !== 'type' && (
        <>
          <SchemaTableWrapper editing={props.editing} hideHead={true}>
            {(props.schemaObject[props.treeKey] as unknown as IPropertyDescription[]).map((prop) =>
              renderNode(prop, (prop.type === 'group' ? groupIndex++ : groupIndex).toString())
            )}
          </SchemaTableWrapper>

          {props.editing && (
            <div style={{ display: 'inline-flex', gap: '.5em' }}>
              <PlusButton
                onClick={() => props.onCreate(null, 'group')}
                textId={'catalogs.add_group'}
                style={{ marginLeft: 0 }}
              />
              <PlusButton
                onClick={() => props.onCreate(null, 'string')}
                textId={'catalogs.add_schema'}
                style={{ marginLeft: 0 }}
              />
            </div>
          )}
        </>
      )}
      {props.schemaObject?.type === 'passport' && (
        <ExtraPassportSettings
          editing={props.editing}
          schemaTree={props.schemaObject[props.treeKey] as any}
          onChange={props.onUpdate}
        />
      )}
    </>
  )
}

export default SchemaForm
