import { IDocument } from 'au-nsi/documents'
import { IAccessSettings } from 'au-nsi/shared'
import { ReactComponent as LockIcon } from 'icons/lock.svg'
import { ReactComponent as ReplaceIcon } from 'icons/replace.svg'
import { memo, MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useContextMenu from '../../../../hooks/useContextMenu'
import useDnD from '../../../../hooks/useDnD'
import { ReduxState } from '../../../../redux/store.types'
import AccessModal from '../../../../shared/Access/AccessModal'
import ContextMenu from '../../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../../shared/Forms/ContextMenu/ContextMenuOption'
import http, { handleHttpError } from '../../../../utils/http'
import css from '../docs.module.css'
import { actions } from '../docs.reducers'
import { openFile } from '../docs.utils'
import DocumentIcon from './DocumentIcon'
import DocumentTitle from './DocumentTitle'
import FileReplaceModal from './FileReplaceModal'

const Document = (props: Props) => {
  const doc = props.document
  const { selection, allowEditing } = props
  const isSelected = selection.includes(doc.id)
  const isMultiSelect = selection.length > 1

  const intl = useIntl()
  const dispatch = useDispatch()
  const token = useSelector((state: ReduxState) => state.auth.accessToken)
  const path = useSelector((state: ReduxState) => state.documents.path)

  const [renaming, setRenaming] = useState(false)
  const [openAccessModal, setAccessModal] = useState(false)
  const [replacement, setReplacement] = useState<File>()

  const containerRef = useRef<HTMLDivElement>()
  const menuRef = useRef<HTMLDivElement>()
  const menuInfo = useContextMenu(containerRef, menuRef)

  // выбор документа по клику правой кнопкой мыши
  useEffect(() => {
    if (menuInfo.open && !isSelected) props.onSelect(doc.id, false)
  }, [menuInfo.open])

  const isDropTarget = allowEditing && doc.is_folder && !isSelected

  const handleDrop = () => {
    if (isDropTarget) props.onDrop(doc.id)
  }

  // обработка drag and drop для перетаскивания элементов в папки
  const dndHandlers = useDnD({
    id: doc.id,
    onDrop: handleDrop,
    prefix: 'documents:',
    ref: containerRef,
    style: isDropTarget ? { border: '1px solid var(--primary-80)' } : {},
  })

  const onDragStart = (e) => {
    if (!isSelected) props.onSelect(doc.id, false)
    dndHandlers.onDragStart(e)
  }

  // выбор текущего элемента
  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    props.onSelect(doc.id, e.ctrlKey)
  }

  // переход в папку по двойному клику
  const handleFolderDoubleClick = () => {
    dispatch(actions.setPath([...path, doc.id]))
  }

  // загрузка файла по двойному клику
  const handleFileDoubleClick = () => openFile(doc, token)

  const closeContextMenu: MouseEventHandler = (e) => {
    e.stopPropagation()
    menuInfo.setOpen(false)
  }

  // переименование элемента
  const handleRenameClick: MouseEventHandler = (e) => {
    closeContextMenu(e)
    setRenaming(true)
  }

  // удаление элемента
  const handleDeleteClick: MouseEventHandler = (e) => {
    closeContextMenu(e)
    props.onDelete()
  }

  // настройка прав доступа
  const handleAccessClick: MouseEventHandler = (e) => {
    closeContextMenu(e)
    setAccessModal(true)
  }

  // вырезать выделенные элементы
  const handleCutClick: MouseEventHandler = (e) => {
    closeContextMenu(e)
    props.onCut()
  }

  // заменить файл на другой
  const handleReplaceClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    menuInfo.setOpen(false)

    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = false
    input.accept = '.' + doc.extension
    input.click()
    input.addEventListener('change', () => setReplacement(input.files[0]), { once: true })
  }

  const handleAccessChange = (access: IAccessSettings) => {
    setAccessModal(false)

    http
      .patch('/nsi/v1/documents/' + doc.id, { access })
      .then(() => dispatch(actions.documentUpdated({ id: doc.id, access })))
      .catch(handleHttpError)
  }

  // контекстное меню, открывающееся по клику правой мыши,
  // набор действий зависит от типа (файл, папка) и от количества выбранных элементов (один или больше)
  const menu: JSX.Element[] = []

  if (props.isModerator && isSelected && !isMultiSelect && allowEditing) {
    menu.push(
      <ContextMenuOption
        key="edit"
        type="edit"
        label={intl.formatMessage({ id: 'documents.actions.rename' })}
        onClick={handleRenameClick}
      />
    )

    menu.push(<ContextMenuOption key="access" type="access" onClick={handleAccessClick} />)
  }

  if (props.isModerator && isSelected && !isMultiSelect && !doc.is_folder && allowEditing) {
    menu.push(
      <ContextMenuOption
        key="replace"
        icon={<ReplaceIcon />}
        label={intl.formatMessage({ id: 'documents.actions.replace_document' })}
        onClick={handleReplaceClick}
      />
    )
  }

  if (props.isModerator && isSelected && props.cuttedItems.length === 0 && allowEditing) {
    menu.push(<ContextMenuOption key="cut" type="cut" onClick={handleCutClick} />)
  }

  if (isSelected && allowEditing) {
    menu.push(<ContextMenuOption key="delete" type="delete" onClick={handleDeleteClick} />)
  }

  const handler = doc.is_folder ? handleFolderDoubleClick : handleFileDoubleClick

  let className = css.document
  if (isSelected) className += ' ' + css.selectedDocument

  return (
    <>
      <div
        data-id={doc.id}
        ref={containerRef}
        className={className}
        {...dndHandlers}
        onClick={handleClick}
        onDoubleClick={handler}
        onDragStart={onDragStart}
        draggable={props.isModerator}
        title={doc.name}
      >
        <DocumentIcon document={doc} />
        <DocumentTitle document={doc} isEditing={renaming} onFinishEditing={() => setRenaming(false)} />
        {doc.access.restricted && <LockIcon className={css.accessIcon} />}

        <ContextMenu open={menuInfo.open} ref={menuRef}>
          {menu}
        </ContextMenu>
      </div>

      {replacement && <FileReplaceModal document={doc} file={replacement} onClose={() => setReplacement(null)} />}

      {openAccessModal && (
        <AccessModal access={doc.access} onCancel={() => setAccessModal(false)} onSave={handleAccessChange} />
      )}
    </>
  )
}

interface Props {
  allowEditing: boolean
  cuttedItems: string[]
  document: IDocument
  onCut: () => void
  onDelete: () => void
  onDrop: (id: string) => void
  onSelect: (id: string, isCtrl: boolean) => void
  selection: string[]
  isModerator: boolean
}

export default memo(Document)
