import { UnknownController } from './AEControllerIdInput'
import { formatDate } from '../../../utils/lang'
import { useIntl } from 'react-intl'
import Modal from '../../Modal/Modal'
import styles from './controllerIdInput.module.css'

interface IProps {
  unknownControllers: UnknownController[]
  onClose: () => void
  onSelect: (id: string) => void
}

const AESelectUnknownIdModal = (props: IProps) => {
  const intl = useIntl()

  const renderList = () => {
    return props.unknownControllers.map((controller) => (
      <div
        className={styles.unknownControllerRow}
        key={controller.controllerId}
        onClick={() => props.onSelect(controller.controllerId)}
      >
        <span>{controller.controllerId}</span>
        <span>{formatDate(controller.ts, 'dd MMM hh:mm:ss')}</span>
      </div>
    ))
  }

  return (
    <Modal onClose={props.onClose} size={'md'}>
      <h2>{intl.formatMessage({ id: 'nsi.lp001.unknown_list' })}</h2>
      <div>{renderList()}</div>
    </Modal>
  )
}

export default AESelectUnknownIdModal
