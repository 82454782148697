import { IUser } from 'au-nsi/user'
import { FormMode } from '../../../shared/interfaces'
import { generateReducers } from '../collection.reducers'

export const TOGGLE_USERNAMES = 'users/TOGGLE_USERNAMES'
export const SET_FILTERS = 'users/SET_FILTERS'

export interface UsersState {
  mode: FormMode
  items: IUser[]
  selectedItem: IUser
  selectedItemId: number
  isLoaded: boolean
  showUsernames: boolean // show usernames instead of names
  filters: UsersFilters
}

export interface UsersFilters {
  organization_type_id: number
  organization_id: number
  status: string
}

const initialState: UsersState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
  showUsernames: false,
  filters: { organization_type_id: null, organization_id: null, status: null },
}

const userFactory = (): IUser => ({
  id: 0,
  lang: 'en',
  name: '',
  email: '',
  username: '',
  role_id: null,
  suspended: false,
  is_admin: false,
  user_data: {},
  use_role_ntf_settings: true,
  security_settings: { '2fa_method': '', '2fa_options': {}, login_notification: false },
})

const reducers = generateReducers<IUser>('users', userFactory, true)

reducers[TOGGLE_USERNAMES] = (state: UsersState) => {
  return { ...state, showUsernames: !state.showUsernames }
}

reducers[SET_FILTERS] = (state: UsersState, action) => {
  return { ...state, filters: action.payload }
}

const usersReducer = (state: UsersState = initialState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default usersReducer
