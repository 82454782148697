import { CSSProperties, ForwardedRef, forwardRef, PropsWithChildren } from 'react'
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg'
import usePersistentState from '../../hooks/usePersistentState'
import styles from './hideableLeftMenu.module.css'
import classNames from 'classnames'
import useDeviceWidth from '../../hooks/useDeviceWidth'
import MobileLeftMenu from './MobileLeftMenu/MobileLeftMenu'

const stateKey = 'hideableLeftMenu:isOpen'

interface IProps {
  style?: CSSProperties
  contentStyle?: CSSProperties
}

/**
 * Боквое меню экрана с возможностью скрытия. Адаптируется под мобильные экраны.
 * В случае с десктопом рендерится в вызванном месте и участвует во flow. Иначе - портал и отображение поверх
 * всей страницы.
 * */
const HideableLeftMenu = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const [open, setOpen] = usePersistentState<boolean>(true, stateKey)
    const { isMobile, isTablet } = useDeviceWidth()

    if (isMobile || isTablet) {
      return <MobileLeftMenu ref={ref}>{props.children}</MobileLeftMenu>
    }

    const menuClass = classNames({ [styles.menu]: true, [styles.menuOpened]: open, [styles.menuClosed]: !open })
    const shadowClass = classNames({
      [styles.menuShadow]: true,
      [styles.menuShadowOpen]: open,
      [styles.menuShadowClose]: !open,
    })

    // Класс nsi-tree нужен для селектов паррента в дочерних компонентах, например, для использования scrollIntoView.
    return (
      <div className={`${menuClass} nsi-tree`} style={props.style}>
        <div onClick={() => setOpen(!open)} className={styles.icon}>
          <ArrowRight />
          <ArrowRight />
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div className={styles.menuContent} style={props.contentStyle} ref={ref}>
            <div className={shadowClass} />
            {props.children}
          </div>
        </div>
      </div>
    )
  }
)

export default HideableLeftMenu
