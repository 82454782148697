import { IChartSettings2 } from 'au-nsi/dashboards'
import { useEffect, useState } from 'react'
import { ParameterDn } from '../../pages/Parameters/params.interfaces'
import Clock from '../../services/clock/clock.service'
import { IDataIntervals } from '../../services/data/data.types'
import ChartWrapper from './ChartWrapper'
import { ChartScale } from './utils/scale.utils'

/**
 * Обертка для отображения статичного линейного графика без элементов управления (масштабирование и т.д.)
 * и возможности изменить время или кадр
 */
const StaticChart = (props: Props) => {
  const [scales, setScales] = useState<ChartScale[]>(props.settings.axes.map(() => 1))

  const parameters = props.settings.axes.map((axis) => props.parameters.get(axis.lines[0].parameter_id))
  const canScale = parameters.map((p) => p && p.unit && p.unit.prefixable)

  const onAutoScale = (v: ChartScale, index: number) => {
    if (canScale[index]) {
      const copy = [...scales]
      copy[index] = v
      setScales(copy)
    }
  }

  useEffect(() => {
    const { clock, ts, frame } = props
    clock.setReplay()
    clock.setPlayerTime(ts)
    clock.setFrame(frame)
  }, [props.ts, props.frame])

  return (
    <ChartWrapper
      clock={props.clock}
      controls={controls}
      dataIntervals={props.dataIntervals}
      id={props.id}
      lastResize={0}
      legendHeight={0}
      lineMode={props.lineMode}
      linesHidden={linesHidden}
      onAutoScale={onAutoScale}
      parameters={props.parameters}
      scales={scales}
      settings={props.settings}
    />
  )
}

const controls = { autoscale: true, zoom: false, pan: false, fillArea: false, scroll: false }
const linesHidden = new Set<number>()

interface Props {
  id: number
  ts: number
  frame: number
  clock: Clock
  settings: IChartSettings2
  parameters: Map<string, ParameterDn>
  lineMode: number
  dataIntervals?: Map<string, IDataIntervals>
}

export default StaticChart
