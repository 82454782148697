import { ReactComponent as ShareIcon } from 'icons/redirect.svg'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import RequestAssignModal from './RequestAssignModal'

/**
 * Делегация заявки на модерацию другому пользователю
 */
const RequestAssignButton = ({ requestId }: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  return (
    <>
      <span onClick={() => setOpen(true)}>
        <ShareIcon width={18} height={18} />
        {intl.formatMessage({ id: 'user.request.assign' })}
      </span>
      {open && <RequestAssignModal requestId={requestId} onClose={() => setOpen(false)} />}
    </>
  )
}

interface Props {
  requestId: number
}

export default RequestAssignButton
