import classnames from 'classnames'
import React from 'react'
import usePopup from '../../hooks/usePopup'
import Checkbox from './Checkbox/Checkbox'
import { ReactComponent as ArrowLeft } from '../../icons/arrow-right.svg'
import SmoothDropdown from '../Utils/SmoothDropdownBehaivor/SmoothDropdown'

/**
 * Компонент для выбора нескольких значений из выпадающего списка.
 */
function MultiDropdown<T>(props: Props<T>) {
  const ref = React.useRef()
  const { open, setOpen } = usePopup(ref)

  const handleOpen = () => setOpen(true)

  const menuItems = props.options.map((option) => {
    const isSelected = props.values.includes(option.value)

    const handleClick = () => {
      const values = isSelected ? props.values.filter((v) => v !== option.value) : [...props.values, option.value]
      props.onChange(values)
    }

    return (
      <div key={option.title} className="dropdown-option" onClick={handleClick}>
        <div className="dropdown-option__title">
          <Checkbox checked={isSelected} name={option.title} onChange={handleClick} />
          <span style={{ paddingLeft: '6px' }}>{option.title}</span>
        </div>
      </div>
    )
  })

  const valueClass = classnames('dropdown-value is-highlighted', { 'is-active': open })

  return (
    <div ref={ref} className="dropdown-wrapper">
      <div className={valueClass} onClick={handleOpen}>
        <span>{props.title}</span>
        <ArrowLeft className={`nsi-select__icon ${open && 'nsi-select__icon_open'} `} />
      </div>
      <div className="dropdown-menu top" style={open ? {} : { boxShadow: 'none', zIndex: 1 }}>
        <SmoothDropdown open={open} maxHeight={300}>
          {menuItems}
        </SmoothDropdown>
      </div>
    </div>
  )
}

interface Props<T> {
  title: React.ReactNode
  options: Array<DropdownOption<T>>
  values: T[]
  name?: string
  onChange: (values: T[], name?: string) => void
}

interface DropdownOption<T> {
  value: T
  title: string
}

export default React.memo(MultiDropdown)
