import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilState } from 'recoil'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import { applyTitleAndLogo } from '../../../utils/html.utils'
import http, { handleHttpError } from '../../../utils/http'
import ImageInput from '../../Libraries/Images/ImageInput'
import { systemPublicState } from '../system.state'
import DocumentsInput from '../../Libraries/Documents/DocumentsInput'
import { IDocument } from 'au-nsi/documents'

const SystemPublicSettings = (props: Props) => {
  const intl = useIntl()
  const [systemInfo, setSystemInfo] = useRecoilState(systemPublicState)

  const [title, setTitle] = useState(systemInfo.title)
  const [logo, setLogo] = useState(systemInfo.logo)
  const [pdpAgreementId, setPdpAgreementId] = useState<IDocument['id']>(systemInfo.pdp_agreement_id)
  const isChanged =
    title !== systemInfo.title || logo !== systemInfo.logo || pdpAgreementId !== systemInfo.pdp_agreement_id

  const handleCancel = () => {
    setTitle(systemInfo.title)
    setLogo(systemInfo.logo)
    setPdpAgreementId(systemInfo.pdp_agreement_id)
  }

  const handleSave = () => {
    const updated = { title, logo, pdp_agreement_id: pdpAgreementId }
    setSystemInfo(updated)
    applyTitleAndLogo(title, logo)

    http.post('/nsi/v1/ms/settings/au-front', updated).catch(handleHttpError)
  }

  return (
    <>
      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'system.about.title' })}>
          <TextInput name="title" value={title} onChange={setTitle} required={false} disabled={!props.allowEditing} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.about.logo' })}>
          <ImageInput name="logo" value={logo} onChange={setLogo} editing={props.allowEditing} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.settings.document.pdp_agreement' })}>
          <DocumentsInput
            name={''}
            disabled={!props.allowEditing}
            value={[pdpAgreementId]}
            onChange={(val) => setPdpAgreementId(val[1])}
            resource={'library'}
          />
        </InputRow>
      </div>

      {isChanged && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="nsi-button inverted" onClick={handleCancel}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </button>
          <button className="nsi-button default" onClick={handleSave}>
            {intl.formatMessage({ id: 'common.save' })}
          </button>
        </div>
      )}
    </>
  )
}

interface Props {
  allowEditing: boolean
}

export default SystemPublicSettings
