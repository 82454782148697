import { useAppSelector } from '../../../../redux/store'
import TextInput from '../../../../shared/Inputs/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserOrganization } from '../../../Collections/Users/users.selectors'
import { useEffect } from 'react'
import { organizationsActions } from '../../../Catalogs/Organizations/organizations.actions'
import Loader from '../../../../shared/Utils/Loader'
import { Equipment, LP001Equipment } from 'au-nsi/equipment'

interface IProps<T extends Equipment> {
  device: T
  name: string
  value: string
  onChange: (val: string, name: string) => void
}

/**
 * В рамках ТЗ лифтовиков необходимо генерировать имя оборудования по шаблону
 * (Короткое имя организации)__(ID контроллера)
 * */
const AECreateDeviceNameInput = <T extends Equipment>(props: IProps<T>) => {
  const dispatch = useDispatch()
  const user = useAppSelector((store) => store.user)
  const organizationsLoaded = useAppSelector((store) => store.organizations.isLoaded)
  const organization = useSelector(selectUserOrganization)

  useEffect(() => {
    dispatch(organizationsActions.loadOrganizations())
  }, [])

  let value = props.value
  const needReplaceName = props.device.protocol === 'LP001' && !user.is_admin

  if (needReplaceName) {
    if (!organizationsLoaded) return <Loader style={{ marginLeft: 0 }} />

    if (!organization?.shortname) value = (props.device as LP001Equipment).configuration.controller_id || '-'
    else value = organization.shortname + '__' + ((props.device as LP001Equipment).configuration.controller_id ?? '')
  }

  return <TextInput value={value} name={props.name} onChange={props.onChange} disabled={needReplaceName} />
}

export default AECreateDeviceNameInput
