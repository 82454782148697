import { Select } from '@alterouniversal/au-react-components'
import { IGanttComponent, IGanttSettings } from 'au-nsi/dashboards'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import NumberInput from '../../../shared/Inputs/NumberInput'
import Toggle from '../../../shared/Inputs/Toggle'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'

/**
 * Модальное окно с настройками диаграммы Ганта
 */
const GanttModal = (props: Props) => {
  const intl = useIntl()

  const si = useSelector(
    (state: ReduxState) => ({ units: state.parameters.units, prefixes: state.parameters.prefixes }),
    shallowEqual
  )

  const se = useSelector((state: ReduxState) => state.signal_events.items)
  const devices = useSelector((state: ReduxState) => state.nsi.equipment)

  // словарь с названиями сигнальных ситуаций
  const seMap = useMemo(() => {
    return se.reduce((acc, event) => acc.set(event.id, event.name), new Map())
  }, [se])

  // копия настроек для возможности отмены
  const [settings, setSettings] = useState({ ...props.component.settings, incidents: undefined })
  const [incidents, setIncidents] = useState([...props.component.settings.incidents])

  const handleEquipmentToggle = () => setSettings({ ...settings, show_every_device: !settings.show_every_device })
  const handleEquipmentChange = (equipment) => setSettings({ ...settings, equipment })
  const handleTimespanChange = (timespan) => setSettings({ ...settings, timespan })

  // сохранить настройки
  const handleSave = () => {
    props.onSave({ ...settings, incidents })
  }

  // добавить новую строку
  const handleAdd = (id: number) => {
    setIncidents([...incidents, { incident_type: 'formula', signal_event_id: id, show_separately: false }])
  }

  // удалить строку из формы
  const handleRemove = (e) => {
    const i = +e.target.dataset.id
    setIncidents([...incidents.slice(0, i), ...incidents.slice(i + 1)])
  }

  // сменить значение флага "Показывать отдельной строкой"
  const handleIncidentToggle = (_, id) => {
    const i = +id

    const result = incidents.map((incident, index) => {
      return i === index ? { ...incident, show_separately: !incident.show_separately } : incident
    })

    setIncidents(result)
  }

  // каждая строка содержит сигнальную ситуацию которая должна отображаться на этой диаграмме Ганта
  const rows = incidents.map((incident, i) => {
    return (
      <tr key={incident.incident_type + ':' + incident.signal_event_id}>
        <td>{seMap.get(incident.signal_event_id)}</td>
        <td>
          <Toggle name={i + ''} checked={incident.show_separately} onChange={handleIncidentToggle} size="12px" />
        </td>
        <td>
          <RemoveButton id={i + ''} onRemove={handleRemove} />
        </td>
      </tr>
    )
  })

  // фильтруем уже выбранные сигнальные ситуации
  const options = se
    .filter((event) => incidents.find((i) => i.signal_event_id === event.id) == null)
    .map((event) => ({ label: event.name, value: event.id }))

  // последней строкой выводим дропдаун для добавления новой СС на диаграмму
  if (options.length > 0) {
    rows.push(
      <tr key={incidents.length}>
        <td>
          <Select name="" options={options} value={null} onChange={handleAdd} />
        </td>
        <td />
        <td />
      </tr>
    )
  }

  const devicesToggle = (
    <div style={{ display: 'flex', alignItems: 'center', margin: '18px 0' }}>
      <span>{intl.formatMessage({ id: 'alerts.gantt.show_every_device' })}</span>
      <Toggle name="show_every_device" checked={settings.show_every_device} onChange={handleEquipmentToggle} />
    </div>
  )

  const devicesSelect = (
    <div>
      <div className="system__label">{intl.formatMessage({ id: 'alerts.gantt.equipment' })}</div>
      <div className="system__input-wrapper">
        <MultiSelect
          options={devices}
          selection={settings.equipment || []}
          onChange={handleEquipmentChange}
          disabled={false}
        />
      </div>
    </div>
  )

  return (
    <Modal onClose={props.onCancel}>
      <div style={{ fontSize: '1rem' }}>
        <h2>{intl.formatMessage({ id: 'alerts.gantt.title' })}</h2>

        <div>
          <div className="system__label">{intl.formatMessage({ id: 'alerts.gantt.timespan' })}</div>
          <div className="system__input-wrapper">
            <NumberInput
              baseUnit={si.units.ms}
              displayUnit={si.units.min}
              name="timespan"
              onChange={handleTimespanChange}
              prefixes={si.prefixes}
              type="parameter"
              units={si.units}
              min={600_000}
              max={12 * 3600_000}
              value={settings.timespan}
            />
          </div>
        </div>

        <table className="nsi-settings-table">
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'alerts.gantt.name' })}</th>
              <th>{intl.formatMessage({ id: 'alerts.gantt.show_separately' })}</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>

        {!props.hideEquipmentSettings && devicesToggle}
        {!props.hideEquipmentSettings && !settings.show_every_device && devicesSelect}

        <ModalFooter onCancel={props.onCancel} onSave={handleSave} />
      </div>
    </Modal>
  )
}

interface Props {
  component: IGanttComponent
  hideEquipmentSettings?: boolean
  onCancel: () => void
  onSave: (settings: IGanttSettings) => void
}

export default GanttModal
