import { IChartSettings } from 'au-nsi/dashboards'
import classnames from 'classnames'
import React, { CSSProperties } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import useResize from '../../hooks/useResize'
import ChartPanel from './ChartPanel'

const ChartsList = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>()

  /**
   * lastResize - время последнего изменения размеров графиков, используется
   * для того чтобы при сворачивании/разворачивании одного графика другие смогли отреагировать
   * и перерасчитать размеры svg
   */
  const [lastResize, setLastResize] = React.useState(0)

  useResize(ref, () => setLastResize((prev) => prev + 1))

  React.useEffect(() => {
    setLastResize((prev) => prev + 1)
  }, [props.charts.length, props.showAsGrid])

  const charts = props.charts.map((chart) => {
    return <ChartPanel key={chart.id} id={chart.id} settings={chart.settings} lastResize={lastResize} />
  })

  const className = classnames('line-charts__container', { 'is-grid': props.showAsGrid })
  const style = getListStyle(charts.length, props.showAsGrid)

  return (
    <div ref={ref} className={className} style={style}>
      {charts}
    </div>
  )
}

/**
 * Для отображения сетки с графиками одинаковой вышины в Chrome необходимо вручную
 * задать высоту каждой строки в процентах (иначе при увеличении кол-ва графиков старые
 * не уменьшаются в размере, а новые попадают за границу экрана)
 */
const getListStyle = (chartsCount: number, showAsGrid: boolean): CSSProperties => {
  if (!showAsGrid) return {}

  const rows = Math.ceil(chartsCount / 2)
  const percent = Math.floor(100 / rows)
  const gridTemplateRows = []

  for (let i = 0; i < rows; i++) {
    gridTemplateRows.push(percent + '%')
  }

  return { gridTemplateRows: gridTemplateRows.join(' ') }
}

interface Props {
  charts: ChartListData[]
  showAsGrid: boolean
  intl: IntlShape
}

export interface ChartListData {
  id: number
  settings: IChartSettings
}

export default injectIntl(ChartsList)
