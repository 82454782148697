import { Chart } from '../Chart'
import { Point, Rect } from '../chart.interfaces'
import * as utils from '../utils/chart.utils'

/**
 * Ручное масштабирование графика
 */
class ZoomPlugin {
  private zoomStart: Point = { x: 0, y: 0 }
  private zooming = false

  constructor(private chart: Chart) {
    chart.mouseDownHandlers.push(this.startZooming)
    chart.mouseMoveHandlers.push(this.recomputeZoom)
    chart.mouseUpHandlers.push(this.applyZoom)
    chart.mouseLeaveHandlers.push(this.stopZooming)
  }

  // при клике мышкой по графику с включенной функцией зума
  // переходим в режим масштабирования
  private startZooming = (e) => {
    const chart = this.chart
    if (!chart.props.controls.zoom) return

    const rect = chart.state.boundaries
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    this.zoomStart = { x, y }
    this.zooming = true
    this.chart.zoomRef.current.style.opacity = '1'
    this.positionZoom({ x, y, width: 1, height: 1 })
  }

  private stopZooming = () => {
    if (this.zooming) {
      this.zooming = false
      this.hideZoom()
    }
  }

  // перерасчет координат зума при движении мыши по графику
  private recomputeZoom = (e) => {
    if (!this.zooming) return

    const chart = this.chart
    const { boundaries } = chart.state
    const mouse = { x: e.clientX - boundaries.left, y: e.clientY - boundaries.top }

    const coords = utils.calculateZoom(this.zoomStart, mouse, boundaries, chart.state.margins)
    this.positionZoom(coords)
  }

  // применение зума когда пользователь отпустил левую кнопку мыши
  private applyZoom = (e) => {
    if (!this.zooming) return

    const chart = this.chart
    const { boundaries } = chart.state
    const mouse = { x: e.clientX - boundaries.left, y: e.clientY - boundaries.top }

    const zoom = utils.calculateZoom(this.zoomStart, mouse, boundaries, chart.state.margins)

    if (zoom.width > utils.zoomResolution && zoom.width < chart.innerWidth) {
      // уменьшение кадра при движении мыши слева на право
      let t0 = chart.xScaleInverse(zoom.x)
      let t1 = chart.xScaleInverse(zoom.x + zoom.width)

      // увеличение кадра при движении справа на лево
      if (zoom.x < this.zoomStart.x) {
        t0 = 2 * chart.t0 - t0
        t1 = 2 * chart.t1 - t1
      }

      const { clock } = this.chart.props.services[0]
      clock.setPlayerTime(t1)
      this.setFrame(t1 - t0)
    }

    if (zoom.height > utils.zoomResolution && zoom.height < chart.innerHeight) {
      // приближение при движении мыши сверху вниз
      let minY = chart.yScalesInverse.map((scale) => scale(zoom.y + zoom.height))
      let maxY = chart.yScalesInverse.map((scale) => scale(zoom.y))

      // отдаление при движении мыши снизу вверх
      // если пользователь задал границы графика то зумить можно только внутри этих границ
      if (zoom.y < this.zoomStart.y) {
        const { axes } = chart.props.settings

        minY = minY.map((_, i) => {
          const lowerLimit = axes[i].minY ?? Number.NEGATIVE_INFINITY
          return Math.max(2 * chart.state.minY[i] - minY[i], lowerLimit)
        })

        maxY = maxY.map((_, i) => {
          const upperLimit = axes[i].maxY ?? Number.POSITIVE_INFINITY
          return Math.min(2 * chart.state.maxY[i] - maxY[i], upperLimit)
        })
      }

      chart.setState({ minY, maxY })
    }

    this.zooming = false
    this.hideZoom()
  }

  private positionZoom(coords: Rect) {
    const zoom = this.chart.zoomRef.current
    zoom.style.transform = `translate(${coords.x}px, ${coords.y}px)`
    zoom.style.width = coords.width + 'px'
    zoom.style.height = coords.height + 'px'
  }

  private hideZoom() {
    this.chart.zoomRef.current.style.opacity = '0'
  }

  // изменение ширины кадра при прокрутке колеса мыши
  public handleWheelEvent = (e: WheelEvent) => {
    const { boundaries, margins } = this.chart.state
    // обрабатываем прокрутку только при попадании во внутреннюю область
    if (e.pageX < boundaries.left + margins.left || e.pageX > boundaries.right - margins.right) return

    e.preventDefault()
    const { t0, t1 } = this.chart
    const width = this.chart.innerWidth
    const right = boundaries.left + margins.left + width
    const frame = t1 - t0

    const factor = e.deltaY > 0 ? 1 / 1.1 : 1.1
    const dFrame = Math.round(frame * (1 - factor))
    const dt = Math.round((dFrame * (right - e.clientX)) / width)

    if (dFrame !== 0) {
      const { clock } = this.chart.props.services[0]
      clock.movePlayerTime(-dt)
      this.setFrame(frame - dFrame)
    }
  }

  private setFrame(frame: number) {
    const { clock } = this.chart.props.services[0]
    return this.chart.isFrameFixed ? this.chart.setFrame(frame) : clock.setFrame(frame)
  }
}

export default ZoomPlugin
