import { Select } from '@alterouniversal/au-react-components'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectPassports } from '../../catalogs.selectors'
import { AnyCatalogWithInstances } from '../../catalogs.types'

interface Props {
  name: string
  selectedId: AnyCatalogWithInstances['id']
  onChange: (value: AnyCatalogWithInstances['id'], name: string) => void
}

const PassportSelect = ({ selectedId, ...selectProps }: Props) => {
  const passports = useSelector(selectPassports)
  const passportOptions = useMemo(
    () => passports.map((passport) => ({ label: passport.name, value: passport.id })),
    [passports]
  )

  return <Select options={passportOptions} value={selectedId} {...selectProps} />
}

export default PassportSelect
