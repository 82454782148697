import React from 'react'
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg'
import { readFile } from '../../../utils/files'
import css from './audio.module.css'
import { IAudio } from 'au-nsi/audio'

/**
 * Поле для загрузки аудио файлов
 */
const AudioUpload = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>()
  const areaRef = React.useRef<HTMLDivElement>()

  const loadFiles = (list: FileList) => {
    if (!list || !list.length) return
    const length = props.multiple ? list.length : 1

    for (let i = 0; i < length; i++) {
      const file = list[i]
      const { type, name } = file
      const format = type.slice(6) as IAudio['format']

      if (!type.startsWith('audio')) continue

      readFile(file, 'binary', (src) => {
        props.onChange({ id: '', format, name, src, user_ordering_index: 0 })
      })
    }
  }

  const handleFilesChange = (e) => loadFiles(e.target.files)

  const handleDragOver = (e) => {
    e.preventDefault()
    areaRef.current.style.opacity = '1'
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    areaRef.current.style.opacity = null
  }

  const handleDrop = (e) => {
    handleDragLeave(e)
    loadFiles(e.dataTransfer.files)
  }

  return (
    <div
      className={css.uploadField}
      onClick={() => inputRef.current.click()}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      ref={areaRef}
    >
      <UploadIcon width="18" height="18" />
      <input
        ref={inputRef}
        className="nsi-input"
        type="file"
        accept=".wav,.mp3,.mp4,.aac,.ogg,.webm,.flac"
        onChange={handleFilesChange}
        multiple={props.multiple}
      />
    </div>
  )
}

interface Props {
  multiple: boolean
  onChange: (file: Omit<IAudio, 'updated_at' | 'created_at'>) => void
}

export default React.memo(AudioUpload)
