import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { speedColors } from './windrose.utils'
import css from './windrose.module.css'

/**
 * Легенда для детальной розы ветров: выводит цветовую шкалу использующуюся
 * для отображения скорости ветра
 */
const LegendDetailed = () => {
  const units = useSelector((state: ReduxState) => state.parameters.units)
  const unit = units['m/s']
  const symbol = unit ? unit.symbol : ''

  const entries = Object.entries(speedColors)

  const items = entries.map(([key, color], i) => {
    const speed = +key
    const min = speed - 1
    const max = speed + 1

    let label = `${min} - ${max}`

    if (i === 0) label = `<${max} ${symbol}`
    else if (i === entries.length - 1) label = `>${min} ${symbol}`

    return (
      <div className={css.legendItem} key={speed}>
        <div className={css.legendColor} style={{ background: color }} />
        <div className={css.legendText}>{label}</div>
      </div>
    )
  })

  return <div className={css.legend}>{items}</div>
}

export default LegendDetailed
