import { IAccessSettings } from 'au-nsi/shared'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import Modal from '../../shared/Modal/Modal'
import ModalFooter from '../../shared/Modal/ModalFooter'
import { deepCopy } from '../../utils/misc'
import AccessForm from './AccessForm'

const AccessModal = (props: Props) => {
  const intl = useIntl()
  const [access, setAccess] = useState(() => deepCopy(props.access))

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <h2>{intl.formatMessage({ id: 'access.modal_title' })}</h2>
      <AccessForm access={access} onChange={setAccess} />
      <ModalFooter onCancel={props.onCancel} onSave={() => props.onSave(access)} />
    </Modal>
  )
}

interface Props {
  access: IAccessSettings
  onSave: (access: IAccessSettings) => void
  onCancel: () => void
}

export default AccessModal
