import { AccessRight, PlatformModuleID } from 'au-nsi/shared'

/**
 * Отфильтровать пути связанные с отключенным функционалом и пути к которым нет доступа
 * у текущего пользователя
 */
export const filter = (routes: IRoute[], rights: Record<string, boolean>, modules: Set<string>) => {
  return routes.filter((route) => {
    const isDisabled = route.modules && route.modules.every((id) => !modules.has(id))
    if (isDisabled) return false

    const isAllowed = !route.rights || route.rights.some((r) => rights[r] === true)
    return isAllowed
  })
}

export interface IRoute {
  id: string
  path: string
  component: React.ComponentClass | React.FunctionComponent
  rights?: AccessRight[] // список прав необходимых для доступа
  modules?: PlatformModuleID[] // к каким модулям относится путь
  children?: IRoute[] // дочерние пути
  exact?: boolean
}
