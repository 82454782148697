import { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as Icon } from '../../icons/arrow-right.svg'
import css from './utils.module.css'

const CollapseButton = (props: Props) => {
  const intl = useIntl()
  const { isCollapsed } = props

  const title = isCollapsed ? 'common.expand_all' : 'common.collapse_all'
  const transform = isCollapsed ? undefined : 'rotate(90deg)'
  const passedStyle = props.style || {}

  return (
    <div
      className={props.className || css.collapseButton}
      style={{ fontSize: 14, lineHeight: '20px', ...passedStyle }}
      onClick={() => props.onChange(!isCollapsed)}
    >
      <Icon
        width="16px"
        height="16px"
        title={intl.formatMessage({ id: title })}
        style={{ transform, transition: 'transform 0.4s ease-out' }}
      />
    </div>
  )
}

interface Props {
  isCollapsed: boolean
  onChange: (isCollapsed: boolean) => void
  className?: string
  style?: CSSProperties
}

export default CollapseButton
