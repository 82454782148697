import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { SignalEvent } from './se.interfaces'

const signalEventsSelector = (state: ReduxState) => state.signal_events.items
const deletedRulesSelector = (state: ReduxState) => state.signal_events.deletedRulesCache

export const rulesMapSelector = createSelector(
  [signalEventsSelector, deletedRulesSelector],
  (signalEvents: SignalEvent[], cache: { [id: number]: SignalEvent }) => {
    const rules = new Map<number, SignalEvent>()

    for (const e of signalEvents) {
      for (const rule of e.rules) {
        rules.set(rule.id, e)
      }
    }

    for (const [ruleId, e] of Object.entries(cache)) {
      rules.set(+ruleId, e)
    }

    return rules
  }
)
