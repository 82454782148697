import fmt from 'date-fns/format'
import ru from 'date-fns/locale/ru'

let locale = null

export const setLocale = (lang: string) => {
  locale = lang === 'ru' ? ru : null
}

export const formatDate = (date: Date | number, str: string) => {
  return fmt(date, str, { locale })
}

export const formatDistanceStrict = (delta: number, unit: Intl.RelativeTimeFormatUnit) => {
  if (!('RelativeTimeFormat' in Intl)) {
    return delta > 0 ? `in ${delta} ${unit}s` : `${delta} ${unit}s ago`
  }

  const formatter = new Intl.RelativeTimeFormat(locale?.code || 'en', { numeric: 'always', style: 'long' })
  return formatter.format(delta, unit)
}
