// draw arc path representing part of gauge axis
const GaugeArc = (props: Props) => {
  const R = 50 // half of total component width
  const w = 5 // distance from border
  const r = R - w

  const x0 = R - r * Math.sin(props.startAngle)
  const y0 = R + r * Math.cos(props.startAngle)
  const x1 = R - r * Math.sin(props.endAngle)
  const y1 = R + r * Math.cos(props.endAngle)

  const largeArcFlag = props.endAngle - props.startAngle > Math.PI ? '1' : '0'

  const d = `M ${x0} ${y0} A ${r} ${r} 0 ${largeArcFlag} 1 ${x1} ${y1}`
  return <path d={d} stroke={props.color} strokeWidth={props.width} fill="none" />
}

interface Props {
  startAngle: number
  endAngle: number
  color: string
  width: number
}

export default GaugeArc
