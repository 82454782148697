import { SystemMessage } from 'au-nsi/shared'
import useInfiniteLoader from 'hooks/useInfiniteLoader'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Virtuoso } from 'react-virtuoso'
import Datepicker from 'shared/Inputs/Datepicker/Datepicker'
import Dropdown from 'shared/Inputs/Dropdown'
import InputRow from 'shared/Inputs/InputRow'
import { wsEvents } from '../../../services/ws/ws.events'
import JournalsExport from '../../../shared/Export/JournalsExport'
import Toggle from '../../../shared/Inputs/Toggle'
import Loader from '../../../shared/Utils/Loader'
import * as api from './messages.api'
import css from './messages.module.css'
import * as utils from './messages.utils'

const levels = ['info', 'warn', 'error']
const types: SystemMessage['type'][] = [
  'MQ',
  'disk_usage',
  'resource_usage',
  'impulse_size',
  'logs_size',
  'notice',
  'custom',
]

/**
 * Журнал системных сообщений
 */
const SystemMessagesPage = () => {
  const intl = useIntl()
  const fmt = (id: string) => intl.formatMessage({ id })

  const headerRef = useRef<HTMLDivElement>()
  const [height, setHeight] = useState(200)

  const [filters, setFilters] = useState({ type: null, level: null, t0: null, t1: null })
  const isTail = filters.t0 == null || filters.t1 == null

  const { results, isLoading, isMore, loadMore, setResults } = useInfiniteLoader<SystemMessage>({
    url: `/nsi/v1/system/messages`,
    query: filters,
    size: 40,
  })

  useEffect(() => {
    if (headerRef.current) {
      const rect = headerRef.current.getBoundingClientRect()
      const h = Math.floor(window.innerHeight - rect.bottom - 16)
      setHeight(h)
    }
  }, [results.length > 0])

  // реалтайм обновление списка при добавлении новых сообщении, квитировании и т.д.
  useEffect(() => {
    return wsEvents.subscribe((e) => {
      switch (true) {
        case e.resource === 'system_messages' && e.method === 'create':
          return setResults((prev) =>
            utils.insertMessages(prev, e.payload).filter((m) => utils.matchFilters(m, filters))
          )
        case e.resource === 'system_messages' && e.method === 'update':
          return setResults((prev) => utils.updateMessages(prev, e.payload))
        case e.resource === 'system_messages_acks' && e.method === 'create':
          return setResults((prev) => utils.ackMessages(prev, e.payload))
        case e.resource === 'system_messages_acks' && e.method === 'delete':
          return setResults((prev) => utils.unackMessage(prev, e.payload))
      }
    })
  }, [filters])

  const handleChange = (value: any, key: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleTailChange = (value: boolean) => {
    if (value) setFilters((prev) => ({ ...prev, t0: null, t1: null }))
    else setFilters((prev) => ({ ...prev, ...defaultTimerange() }))
  }

  // отметить сообщение как прочитанное/непрочитанное
  const handleAck = (e) => {
    const id = +e.currentTarget.dataset.id
    const message = results.find((m) => m.id === id)
    return message.ack_ts ? api.unackMessage(id) : api.ackMessage(id)
  }

  const levelOptions = levels.map((value) => ({ value, title: fmt('system.messages.level.' + value) }))
  levelOptions.unshift({ value: null, title: fmt('common.all') })

  const typeOptions = types.map((value) => ({ value, title: fmt('system.messages.type.' + value) }))
  typeOptions.unshift({ value: null, title: fmt('common.all') })

  const renderRow = (index: number) => {
    const message = results[index]
    const text = message.message[intl.locale]
    const color = utils.getMessageColor(message)

    const ackClass = message.ack_ts ? css.unackIcon : css.ackIcon
    const ackTitle = message.ack_ts ? 'system.messages.mark_as_unread' : 'system.messages.mark_as_read'

    return (
      <div key={message.id} className={css.row} style={{ color }}>
        {message.is_active && <span className={css.indicator} style={{ background: color }} />}
        <span style={{ whiteSpace: 'nowrap' }}>{utils.formatMessageTime(message)}</span>
        <span style={{ whiteSpace: 'nowrap' }}>[{fmt('system.messages.type.' + message.type)}]</span>
        <span>{text}</span>

        <span
          data-id={message.id}
          className={ackClass}
          onClick={handleAck}
          title={intl.formatMessage({ id: ackTitle })}
        />
      </div>
    )
  }

  const Footer = () => <div className={css.footer}>{isMore && <Loader />}</div>

  return (
    <div className="nsi-main__container is-wide" style={{ marginBottom: 0 }}>
      <div className="system__grid" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 40px' }} ref={headerRef}>
        <InputRow label={intl.formatMessage({ id: 'system.messages.level' })}>
          <Dropdown name="level" options={levelOptions} value={filters.level} onChange={handleChange} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.messages.type' })}>
          <Dropdown name="type" options={typeOptions} value={filters.type} onChange={handleChange} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'system.messages.tail' })}>
          <Toggle
            name="tail"
            checked={isTail}
            onChange={handleTailChange}
            style={{ margin: 0, position: 'relative', top: 8 }}
          />
        </InputRow>

        {!isTail && (
          <>
            <InputRow label={intl.formatMessage({ id: 'system.messages.t0' })}>
              <Datepicker name="t0" time={filters.t0} onTimeChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'system.messages.t1' })}>
              <Datepicker name="t1" time={filters.t1} onTimeChange={handleChange} />
            </InputRow>
          </>
        )}

        {isTail && (
          <>
            <div />
            <div />
          </>
        )}

        <InputRow label="">
          <JournalsExport
            url="/nsi/v1/system/messages:export"
            title={intl.formatMessage({ id: 'system.messages.export' })}
            style={{ height: 40 }}
            t0={filters.t0}
            t1={filters.t1}
          />
        </InputRow>
      </div>

      {results.length > 0 && (
        <Virtuoso
          style={{ height }}
          totalCount={results.length}
          itemContent={(index) => renderRow(index)}
          components={{ Footer }}
          endReached={isMore ? loadMore : undefined}
        />
      )}

      {!isLoading && results.length === 0 && (
        <div className="text--center text--gray">{intl.formatMessage({ id: 'common.no_results' })}</div>
      )}
    </div>
  )
}

const defaultTimerange = () => {
  const now = new Date()
  const t1 = now.valueOf()

  now.setMonth(now.getMonth() - 1)
  const t0 = now.valueOf()

  return { t0, t1 }
}

export default SystemMessagesPage
