import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlatformModule } from './modules.types'
import http, { handleHttpError } from '../../../utils/http'

const slice = createSlice({
  name: 'app/modules',
  initialState: [] as PlatformModule[],
  reducers: {
    SET_STATE: (_, action: PayloadAction<PlatformModule[]>) => {
      return action.payload
    },
    setModules: (_, action: PayloadAction<PlatformModule[]>) => {
      return action.payload.sort((a, b) => (a.name < b.name ? -1 : 1))
    },
    updateModule: (state, action: PayloadAction<Partial<PlatformModule>>) => {
      const updates = action.payload
      const item = state.find((m) => m.id === updates.id)
      if (item) Object.assign(item, updates)
    },
  },
})

const loadModules = () => async (dispatch) => {
  const modules = await http
    .get('/nsi/v1/modules')
    .then((r) => r.data)
    .catch(handleHttpError)

  if (modules) dispatch(slice.actions.setModules(modules))
}

export const modulesReducer = slice.reducer
export const modulesActions = { ...slice.actions, loadModules }
