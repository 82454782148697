const memoize = <R>(func: (...args: any) => R) => {
  let lastResult: R = null
  let lastArgs = []
  let called = false

  const isEqual = (value, index) => value === lastArgs[index]

  return (...args): R => {
    if (called && args.length === lastArgs.length && args.every(isEqual)) {
      return lastResult
    }

    lastResult = func(...args)
    lastArgs = args
    called = true
    return lastResult
  }
}

/**
 * Мемоизация множества возвращаемых функцией значений. Результаты вызова функции
 * с различным первым аргументом кэшируются отдельно
 */
export const multimemoize = <R>(func: (id: string, ...args: any) => R) => {
  const lastResults: { [id: string]: R } = {}
  const lastArgs: { [id: string]: any[] } = {}
  const called: { [id: string]: boolean } = {}

  return (id: string, ...args): R => {
    const isEqual = (value, index) => value === lastArgs[id][index]

    if (called[id] && args.length === lastArgs[id].length && args.every(isEqual)) {
      return lastResults[id]
    }

    lastResults[id] = func(id, ...args)
    lastArgs[id] = args
    called[id] = true
    return lastResults[id]
  }
}

export default memoize
