import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { IVectorChartComponent, IVectorChartSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { IDataSource } from '../../pages/Nsi/nsi.interfaces'
import { selectDataSources } from '../../pages/Nsi/nsi.selectors'
import { selectParameterOptions, selectParametersMap } from '../../pages/Parameters/params.selectors'
import { ReduxState } from '../../redux/store.types'
import NumberInput from '../../shared/Inputs/NumberInput'
import ModalFooter from '../../shared/Modal/ModalFooter'
import Form from '../Forms/Form'
import PlusButton from '../Forms/PlusButton'
import Dropdown from '../Inputs/Dropdown'
import InputRow from '../Inputs/InputRow'
import TextInput from '../Inputs/TextInput'
import Modal from '../Modal/Modal'
import VectorGraphSettingsRow from './VectorGraphSettingsRow'

/**
 * Модальное окно с настройками векторной диаграммы
 */
export const VectorGraphSettings = (props: Props) => {
  const intl = useIntl()
  const [settings, setSettings] = React.useState(props.component.settings)
  const [error, setError] = React.useState<string>(null)

  React.useEffect(() => {
    if (error != null) setError(null)
  }, [settings])

  const vectorNameOptions = React.useMemo(() => {
    const result = [{ label: intl.formatMessage({ id: 'dashboards.vector_chart.settings.fix_vector_no' }), value: '' }]
    for (const vector of settings.vectors) {
      result.push({ label: vector.name, value: vector.name })
    }

    return result
  }, [settings.vectors])

  const equipmentOptions = React.useMemo(() => {
    return props.equipment.map((e) => ({ label: e.name, value: e.id, color: e.color }))
  }, [props.equipment])

  const magnitudeParameterOptions = React.useMemo(() => {
    return props.parameterOptions.filter((o) => {
      const parameter = props.parameters.get(o.value)
      return parameter && (parameter.unit === 'V' || parameter.unit === 'A')
    })
  }, [props.parameters, props.parameterOptions])

  const angleParameterOptions = React.useMemo(() => {
    return props.parameterOptions.filter((o) => {
      const parameter = props.parameters.get(o.value)
      return parameter && parameter.unit === 'rad'
    })
  }, [props.equipment, props.parameters])

  const vectorStyleOptions = [
    { value: '1', title: intl.formatMessage({ id: 'dashboards.vector_chart.settings.vector_style.1' }) },
    { value: '2', title: intl.formatMessage({ id: 'dashboards.vector_chart.settings.vector_style.2' }) },
    { value: '3', title: intl.formatMessage({ id: 'dashboards.vector_chart.settings.vector_style.3' }) },
  ]

  const gridParameterOptions = [
    { value: 'current', title: intl.formatMessage({ id: 'dashboards.vector_chart.settings.grid_parameter.current' }) },
    { value: 'voltage', title: intl.formatMessage({ id: 'dashboards.vector_chart.settings.grid_parameter.voltage' }) },
  ]

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  // добавить новую пустую строку
  const appendLine = () => {
    const vectors = [
      ...settings.vectors,
      { device_id: null, magnitude_parameter_id: null, angle_parameter_id: null, color: null, name: '' },
    ]
    setSettings({ ...settings, vectors })
  }

  // удалить строку с указанным индексом
  const removeLine = React.useCallback((index: number) => {
    setSettings((prev) => {
      const vectors = prev.vectors.filter((_, i) => i !== index)
      return { ...prev, vectors }
    })
  }, [])

  // изменить строку с указанным индексом
  const updateLine = React.useCallback((index: number, updates) => {
    setSettings((prev) => {
      const vectors = prev.vectors.map((vector, i) => (index === i ? updates : vector))
      return { ...prev, vectors }
    })
  }, [])

  // перед сохранением отфильтровать дублирующиеся линии и линии с пустыми настройками
  const handleSave = () => {
    const err = validateSettings(settings, intl)
    if (err !== null) return setError(err)

    const keys = new Set()

    const vectors = settings.vectors.filter((vector) => {
      const key =
        vector.device_id + ':' + vector.magnitude_parameter_id + ':' + vector.angle_parameter_id + ':' + vector.name
      const isValid =
        vector.device_id &&
        vector.magnitude_parameter_id &&
        vector.angle_parameter_id &&
        vector.name &&
        vector.color &&
        !keys.has(key)

      return isValid && keys.add(key)
    })

    props.onSave({ ...settings, vectors })
  }

  const rows = settings.vectors.map((vector, i) => {
    const key = vector.device_id + ':' + vector.magnitude_parameter_id + ':' + vector.angle_parameter_id
    return (
      <VectorGraphSettingsRow
        key={key + i}
        index={i}
        vector={vector}
        equipmentOptions={equipmentOptions}
        magnitudeParameterOptions={magnitudeParameterOptions}
        angleParameterOptions={angleParameterOptions}
        onRemove={removeLine}
        onChange={updateLine}
      />
    )
  })

  const voltUnit = Object.values(props.units).find((u) => u.id === 'V')
  const amperUnit = Object.values(props.units).find((u) => u.id === 'A')

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'common.name' })}>
              <TextInput name="title" value={settings.title} onChange={handleChange} required={false} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.vector_chart.settings.fixed_vector_name' })}>
              <Select
                name="fixed_vector_name"
                options={vectorNameOptions}
                value={settings.fixed_vector_name}
                onChange={handleChange}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.vector_chart.settings.voltage_nominal' })}>
              <NumberInput
                name="voltage_nominal"
                type="parameter"
                value={settings.voltage_nominal}
                onChange={handleChange}
                fullWidth={true}
                baseUnit={voltUnit}
                displayUnit={voltUnit}
                displayPrefix={null}
                units={props.units}
                prefixes={props.prefixes}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.vector_chart.settings.current_nominal' })}>
              <NumberInput
                name="current_nominal"
                type="parameter"
                value={settings.current_nominal}
                onChange={handleChange}
                fullWidth={true}
                baseUnit={amperUnit}
                displayUnit={amperUnit}
                displayPrefix={null}
                units={props.units}
                prefixes={props.prefixes}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.vector_chart.settings.vector_style' })}>
              <Dropdown
                name="vector_style"
                value={settings.vector_style}
                options={vectorStyleOptions}
                onChange={handleChange}
              />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.vector_chart.settings.grid_parameter' })}>
              <Dropdown
                name="grid_parameter"
                value={settings.grid_parameter}
                options={gridParameterOptions}
                onChange={handleChange}
              />
            </InputRow>
          </div>

          <table className="nsi-settings-table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.vector_chart.settings.vectors.device_id' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.vector_chart.settings.vectors.magnitude_parameter_id' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.vector_chart.settings.vectors.angle_parameter_id' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.vector_chart.settings.vectors.name' })}</th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'dashboards.vector_chart.settings.vectors.color' })}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <PlusButton textId="common.add" onClick={appendLine} style={{ marginLeft: 0 }} />

          <ModalFooter error={error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: IVectorChartComponent
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: IVectorChartSettings) => void
  parameterOptions: SelectOption[]
  parameters: Map<string, Parameter>
  title: string
  units: { [id: string]: SiUnit }
  prefixes: { [id: string]: SiPrefix }
}

const validateSettings = (settings: IVectorChartSettings, intl: IntlShape) => {
  for (const vector of settings.vectors) {
    if (!vector.device_id || !vector.magnitude_parameter_id || !vector.angle_parameter_id || !vector.color) {
      return intl.formatMessage({ id: 'dashboards.vector_chart.settings.fill_all_fields' })
    }
  }

  return null
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameterOptions: selectParameterOptions(state),
    parameters: selectParametersMap(state),
    units: state.parameters.units,
    prefixes: state.parameters.prefixes,
  }
}

export default connect(mapStateToProps)(VectorGraphSettings)
