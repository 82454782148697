import { NsiResource } from 'au-nsi/shared'
import { batch } from 'react-redux'
import { Store } from 'redux'
import * as userActions from '../../pages/App/user.actions'
import { catalogsActions } from '../../pages/Catalogs/catalogs.actions'
import { organizationsActions } from '../../pages/Catalogs/Organizations/organizations.actions'
import rolesActions from '../../pages/Collections/Roles/roles.actions'
import usersActions from '../../pages/Collections/Users/users.actions'
import commandsActions from '../../pages/Commands/commands.actions'
import dashboardActions from '../../pages/Dashboards/dashboard.actions'
import formulasActions from '../../pages/Formulas/formulas.actions'
import * as incidentsActions from '../../pages/Incidents/incident.actions'
import audioActions from '../../pages/Libraries/Audio/audio.actions'
import { actions as docsActions } from '../../pages/Libraries/Documents/docs.reducers'
import imageActions from '../../pages/Libraries/Images/image.actions'
import { groupsSliceActions } from '../../pages/MapTopology/Groups/groups.reducer'
import { dataRatesActions } from '../../pages/Nsi/data_rates.reducers'
import * as nsiActions from '../../pages/Nsi/nsi.actions'
import { tagsActions } from '../../pages/Nsi/Tags/tags.state'
import groupActions from '../../pages/Parameters/groups.actions'
import parameterActions from '../../pages/Parameters/params.actions'
import seActions from '../../pages/SignalEvents/se.actions'
import { modulesActions } from '../../pages/System/Modules/modules.state'
import settingsActions from '../../pages/System/Settings/settings.actions'
import transmittersActions from '../../pages/Transmitters/transmitters.actions'
import { ReduxState } from '../../redux/store.types'
import { WSAction, wsEvents } from './ws.events'

/**
 * Модуль для обработки событий приходящих по веб-сокетам. Большинство сообщений передаются
 * в redux store через создание нужного action. Некоторые сообщения передаются напрямую
 * в нужный модуль через event emitter своего модуля.
 */
export const dispatchAction = (store: Store, action: WSAction) => {
  wsEvents.next(action)

  const handler = handlers[action.resource] && handlers[action.resource][action.method]
  if (handler) handler(store, action.payload)
}

/**
 * Обработка батча из нескольких сообщений
 */
export const dispatchActions = (store: Store, actions: WSAction[]) => {
  batch(() => {
    for (const action of actions) dispatchAction(store, action)
  })
}

/**
 * При восстановлении вебсокет соединения перезагрузить ресурсы которые могли обновиться
 * за то время пока соединение было разорвано
 */
export const dispatchReconnect = (store: Store) => {
  const state: ReduxState = store.getState()

  wsEvents.next({ payload: 'reconnected', resource: 'ws-connection', method: 'update' })
  store.dispatch(nsiActions.reloadRegions() as any)
  store.dispatch(nsiActions.reloadEquipment() as any)

  if (state.modules.find((m) => m.id === 'au-signal-events')) {
    store.dispatch(incidentsActions.loadIncidents(true) as any)
  }
}

/**
 * Сгенерировать обработчики событий для событий вебсокетов
 * *actions* - action creators которые были сгенерированы при помощи collection.actions.ts
 */
const generateCollectionHandlers = (actions): Handlers => {
  return {
    create: (store, payload) => {
      store.dispatch(actions.itemCreated(payload, true))
    },
    update: (store, payload) => {
      store.dispatch(actions.itemUpdated(payload.id, payload, true))
    },
    delete: (store, payload) => {
      store.dispatch(actions.itemDeleted(payload.id, true))
    },
  }
}

const incidentsHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(incidentsActions.incidentCreated(payload))
  },
  update: (store, payload) => {
    store.dispatch(incidentsActions.incidentUpdated(payload.id, payload))
  },
}

const equipmentHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(nsiActions.equipmentCreated(payload))
  },
  update: (store, payload) => {
    store.dispatch(nsiActions.equipmentUpdated(payload.id, payload))
  },
  batch_update: (store, payload) => {
    store.dispatch(nsiActions.equipmentBatchUpdated(payload))
  },
  delete: (store, payload) => {
    store.dispatch(nsiActions.equipmentDeleted(payload.id))
  },
}

const regionsHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(nsiActions.regionCreated(payload))
  },
  update: (store, payload) => {
    store.dispatch(nsiActions.regionUpdated(payload.id, payload))
  },
  batch_update: (store, payload) => {
    store.dispatch(nsiActions.regionBatchUpdated(payload))
  },
  delete: (store, payload) => {
    store.dispatch(nsiActions.regionDeleted(payload))
  },
}

const groupHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(groupActions.itemCreated(payload, true))
  },
  update: (store, payload) => {
    store.dispatch(groupActions.itemUpdated(payload.id, payload, true))
  },
  batch_update: (store, payload) => {
    batch(() => {
      payload.forEach((item) => store.dispatch(groupActions.itemUpdated(item.id, item)))
    })
  },
  delete: (store, payload) => {
    store.dispatch(groupActions.itemDeleted(payload, true))
  },
}

const dashboardsHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(dashboardActions.dashboardCreated(payload))
  },
  update: (store, payload) => {
    store.dispatch(dashboardActions.dashboardUpdated(payload))
  },
  batch_update: (store, payload) => {
    store.dispatch(dashboardActions.dashboardsUpdated(payload))
  },
  delete: (store, payload) => {
    store.dispatch(dashboardActions.dashboardDeleted(payload.id))
  },
}

// при изменении настроек видимости экранов их необходимо польностью перезагрузить,
// т.к. для пользователя могли стать доступны новые экраны или наоборот скрыться
const dashboardsAccessHandlers: Handlers = {
  update: (store) => {
    store.dispatch(dashboardActions.loadDashboards(true))
  },
}

const equipmentAccessHandlers: Handlers = {
  update: (store, payload) => {
    const { id } = payload
    return id ? store.dispatch(nsiActions.reloadDevice(id)) : store.dispatch(nsiActions.reloadEquipment())
  },
}

const documentsAccessHandlers: Handlers = {
  update: (store) => {
    store.dispatch(docsActions.invalidateCache())
  },
}

const dashboardComponentsHandlers: Handlers = {
  create: (store, payload) => {
    store.dispatch(dashboardActions.componentCreated(payload))
  },
  update: (store, payload) => {
    store.dispatch(dashboardActions.componentUpdated(payload))
  },
  delete: (store, payload) => {
    store.dispatch(dashboardActions.componentDeleted(payload))
  },
}

const imageHandlers: Handlers = {
  create: (store, payload) => store.dispatch(imageActions.imageCreated(payload)),
  update: (store, payload) => store.dispatch(imageActions.imageUpdated(payload)),
  batch_update: (store, payload) => store.dispatch(imageActions.imagesUpdated(payload)),
  delete: (store, payload) => store.dispatch(imageActions.imageDeleted(payload.id)),
}

const audioHandlers: Handlers = {
  create: (store, payload) => store.dispatch(audioActions.itemCreated(payload)),
  update: (store, payload) => store.dispatch(audioActions.itemUpdated(payload)),
  batch_update: (store, payload) => store.dispatch(audioActions.itemsUpdated(payload)),
  delete: (store, payload) => store.dispatch(audioActions.itemDeleted(payload.id)),
}

const documentsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(docsActions.documentCreated(payload)),
  update: (store, payload) => store.dispatch(docsActions.documentUpdated(payload)),
  batch_update: (store, payload) => store.dispatch(docsActions.documentsUpdated(payload)),
  delete: (store, payload) => store.dispatch(docsActions.documentDeleted(payload.id)),
}

const seTypesHandlers: Handlers = {
  update: (store, payload) => store.dispatch(seActions.seTypeUpdated(payload)),
  delete: (store, payload) => store.dispatch(seActions.seTypeDeleted(payload)),
  create: (store, payload) => store.dispatch(seActions.seTypeCreated(payload)),
}

const settingsHandlers: Handlers = {
  update: (store, payload) => store.dispatch(settingsActions.set(payload)),
}

const modulesHandlers: Handlers = {
  update: (store, payload) => store.dispatch(modulesActions.updateModule(payload)),
}

const dataRatesHandler: Handlers = {
  update: (store, payload) => store.dispatch(dataRatesActions.set(payload)),
}

const catalogsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(catalogsActions.catalogCreated(payload)),
  update: (store, payload) => store.dispatch(catalogsActions.catalogUpdated(payload)),
  delete: (store, payload) => store.dispatch(catalogsActions.catalogDeleted(payload)),
}

const catalogsItemsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(catalogsActions.instanceCreated(payload)),
  update: (store, payload) => store.dispatch(catalogsActions.instanceUpdated(payload)),
  delete: (store, payload) => store.dispatch(catalogsActions.instanceDeleted(payload)),
}

const organizationsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(organizationsActions.organizationCreated(payload)),
  update: (store, payload) => store.dispatch(organizationsActions.organizationUpdated(payload)),
  delete: (store, payload) => store.dispatch(organizationsActions.organizationDeleted(payload)),
}

const organizationsTypesHandlers: Handlers = {
  create: (store, payload) => store.dispatch(organizationsActions.organizationTypeCreated(payload)),
  update: (store, payload) => store.dispatch(organizationsActions.organizationTypeUpdated(payload)),
  delete: (store, payload) => store.dispatch(organizationsActions.organizationTypeDeleted(payload)),
}

const commandsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(commandsActions.commandCreated(payload)),
  update: (store, payload) => store.dispatch(commandsActions.commandUpdated(payload)),
  delete: (store, payload) => store.dispatch(commandsActions.commandDeleted(payload)),
  import: (store) => store.dispatch(commandsActions.loadCommands(true)),
}

const commandTypesHandlers: Handlers = {
  create: (store, payload) => store.dispatch(commandsActions.commandTypeCreated(payload)),
  update: (store, payload) => store.dispatch(commandsActions.commandTypeUpdated(payload)),
  delete: (store, payload) => store.dispatch(commandsActions.commandTypeDeleted(payload)),
}

const parameterHandlers: Handlers = {
  ...generateCollectionHandlers(parameterActions),
  import: (store) => {
    store.dispatch(parameterActions.loadItems(true))
    store.dispatch(groupActions.loadItems(true))
  },
}

const seRulesHandlers: Handlers = {
  update: (store, payload) => store.dispatch(seActions.ruleUpdated(payload)),
}

const equipmentGroupsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(groupsSliceActions.groupCreated(payload)),
  update: (store, payload) => store.dispatch(groupsSliceActions.groupUpdated(payload)),
  delete: (store, payload) => store.dispatch(groupsSliceActions.groupRemoved(payload)),
}

const tagsHandlers: Handlers = {
  create: (store, payload) => store.dispatch(tagsActions.tagCreated(payload)),
  update: (store, payload) => store.dispatch(tagsActions.tagUpdated(payload)),
  delete: (store, payload) => store.dispatch(tagsActions.tagDeleted(payload.id)),
}

const userNtfSettingsHandlers: Handlers = {
  update: (store, payload) => store.dispatch(userActions.updateUserNtfSettings(payload)),
}

const roleNtfSettingsHandlers: Handlers = {
  update: (store, payload) => store.dispatch(userActions.updateRoleNtfSettings(payload)),
}

const rolesHandlers = generateCollectionHandlers(rolesActions)
const seHandlers = generateCollectionHandlers(seActions)
const transmittersHandlers = generateCollectionHandlers(transmittersActions)
const usersHandlers = generateCollectionHandlers(usersActions)
const formulasHandlers = generateCollectionHandlers(formulasActions)

const handlers: { [resource in Resource]?: Handlers } = {
  ae_groups: equipmentGroupsHandlers,
  audio: audioHandlers,
  catalog_items: catalogsItemsHandlers,
  catalogs: catalogsHandlers,
  command_types: commandTypesHandlers,
  commands: commandsHandlers,
  data_rates: dataRatesHandler,
  documents: documentsHandlers,
  documents_access: documentsAccessHandlers,
  equipment: equipmentHandlers,
  equipment_access: equipmentAccessHandlers,
  formulas: formulasHandlers,
  images: imageHandlers,
  incidents: incidentsHandlers,
  modules: modulesHandlers,
  organizations: organizationsHandlers,
  organizations_types: organizationsTypesHandlers,
  parameter_groups: groupHandlers,
  parameters: parameterHandlers,
  regions: regionsHandlers,
  role_notifications_settings: roleNtfSettingsHandlers,
  settings: settingsHandlers,
  signal_events: seHandlers,
  signal_events_rules: seRulesHandlers,
  signal_events_types: seTypesHandlers,
  tags: tagsHandlers,
  transmitters: transmittersHandlers,
  ui_dashboard_components: dashboardComponentsHandlers,
  ui_dashboards: dashboardsHandlers,
  ui_dashboards_access: dashboardsAccessHandlers,
  user_notifications_settings: userNtfSettingsHandlers,
  user_roles: rolesHandlers,
  users: usersHandlers,
}

type Handler = (store: Store<ReduxState, any>, payload: any) => void

interface Handlers {
  create?: Handler
  update?: Handler
  delete?: Handler
  import?: Handler
  batch_update?: Handler
}

type Resource = NsiResource | 'ae_groups'
