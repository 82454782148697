import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGComponent, ISVGSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import FormTabs from '../../../shared/Utils/FormTabs'
import { showError } from '../../../utils/notifications'
import actions from '../../Libraries/Images/image.actions'
import { selectImagesMap } from '../../Libraries/Images/image.selectors'
import ImageInput from '../../Libraries/Images/ImageInput'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import SettingsAnimations from './animations/SettingsAnimations'
import SettingsColor from './SettingsColor'
import SettingsCommands from './SettingsCommands'
import SettingsLinks from './SettingsLinks'
import SettingsText from './SettingsText'
import SettingsVisibility from './SettingsVisibility'
import css from './svg.module.css'
import { findNodes, fitSVG } from './svg.utils'

/**
 * Модальное окно с настройками SVG схемы
 */
const SVGSettings = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const imageContainer = React.useRef<HTMLDivElement>()

  // уже использованные узлы (для того чтобы не предлагать их пользователю повторно)
  const usedNodes = React.useRef(new Set<string>())

  const [settings, setSettings] = React.useState(props.component.settings)
  const [textOptions, setTextOptions] = React.useState<SelectOption[]>([])
  const [nodeOptions, setNodeOptions] = React.useState<SelectOption[]>([])

  const data = useSelector((s: ReduxState) => {
    return {
      images: selectImagesMap(s),
      equipment: selectDataSources(s),
      parameters: s.parameters.items,
    }
  }, shallowEqual)

  const deviceOptions = React.useMemo(
    () => data.equipment.map((e) => ({ value: e.id, label: e.name })),
    [data.equipment]
  )

  const parameterOptions = React.useMemo(
    () => data.parameters.map((e) => ({ value: e.id, label: e.name })),
    [data.parameters]
  )

  const tabs = React.useMemo(
    () =>
      ['text', 'color', 'visibility', 'commands', 'links', 'movements', 'rotations'].map((id) =>
        intl.formatMessage({ id: 'dashboards.svg_diagram.settings.' + id })
      ),
    []
  )

  const image = data.images.get(settings.image_id)

  const updateUsedNodes = (s: ISVGSettings) => {
    usedNodes.current = new Set(s.text.map((row) => row.node_id))
  }

  // при выборе узла пользователем подсвечиваем его расположение внутри svg
  const outlineNode = React.useCallback((id: string) => {
    const e: SVGElement = imageContainer.current.querySelector('#' + id)
    if (!e) return

    let rect = document.getElementById('svg_diagram_outline') as HTMLDivElement

    if (!rect) {
      rect = document.createElement('div')
      rect.setAttribute('id', 'svg_diagram_outline')
    }

    const box = e.getBoundingClientRect()
    const containerBox = imageContainer.current.getBoundingClientRect()
    const x = Math.round(box.left - containerBox.left) - 3
    const y = Math.round(box.top - containerBox.top) - 3

    // начать css анимацию заново
    rect.classList.remove(css.outline)
    rect.classList.add(css.outline)

    rect.style.width = Math.round(box.width) + 6 + 'px'
    rect.style.height = Math.round(box.height) + 6 + 'px'
    rect.style.top = y + 'px'
    rect.style.left = x + 'px'

    imageContainer.current.appendChild(rect)
  }, [])

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleRowRemove = React.useCallback((key: string, index: number) => {
    setSettings((prev) => {
      const next = produce(prev, (draft) => {
        draft[key].splice(index, 1)
      })

      if (key === 'text') updateUsedNodes(next)
      return next
    })
  }, [])

  const handleRowChange = React.useCallback((key: string, index: number, prop: string, value: any) => {
    // querySelector по числам выкидывает DOM Exception
    if (prop === 'node_id' && !isNaN(value?.[0])) return

    setSettings((prev) => {
      const next = produce(prev, (draft) => {
        draft[key][index][prop] = value
      })

      if (key === 'text' && prop === 'node_id') updateUsedNodes(next)
      return next
    })
  }, [])

  const handleRowAdd = (key: string) => {
    const factories = {
      text: () => ({ node_id: '', device_id: '', parameter_id: '' }),
      color: () => ({ node_id: '', device_id: '', parameter_id: '', condition: '>', value: 0, color: '#f44336' }),
      visibility: () => ({ node_id: '', device_id: '', parameter_id: '', condition: '>', value: 0, action: 'hide' }),
      commands: () => ({ node_id: '', target: '', command_id: null, args: {} }),
      links: () => ({ node_id: '', type: 'dashboard', target: '' }),
      movements: () => ({
        node_id: '',
        device_id: '',
        parameter_id: '',
        condition: '>',
        value: 0,
        direction: 'left',
        distance: 100,
        duration: 1,
        cycle: false,
        hide_self: false,
        type: 'movements',
        rotate_one_way: false,
      }),
      rotations: () => ({
        node_id: '',
        device_id: '',
        parameter_id: '',
        condition: '>',
        value: 0,
        direction: 'clockwise',
        distance: 90,
        duration: 1,
        cycle: false,
        type: 'rotations',
        hide_self: false,
        rotate_one_way: false,
      }),
    }

    const prev = settings[key] || []
    setSettings({ ...settings, [key]: [...prev, factories[key]()] })
  }

  // валидация и сохранение настроек
  const handleSave = () => {
    if (!settings.image_id) {
      return showError('dashboards.svg_diagram.errors.empty_image')
    }

    // отфильтровать не до конца заполненные строки
    const filter = (row) => row.node_id && row.device_id && row.parameter_id
    const text = settings.text.filter(filter)
    const color = (settings.color || []).filter(filter)
    const visibility = (settings.visibility || []).filter(filter)
    const commands = (settings.commands || []).filter((c) => c.node_id && c.command_id && c.target)
    const movements = (settings.movements || []).filter(filter).filter((c) => c.duration && c.distance)
    const rotations = (settings.rotations || []).filter(filter).filter((c) => c.duration && c.distance)
    props.onSave({ ...settings, text, color, visibility, commands, movements, rotations })
  }

  React.useEffect(() => updateUsedNodes(settings), [])

  // при выборе изображения пользователем показываем его предпросмотр
  React.useLayoutEffect(() => {
    const div = imageContainer.current

    if (!image) {
      div.innerHTML = null
      return
    }

    if (!image.src) {
      dispatch(actions.loadImageSource(image.id))
      return
    }

    div.innerHTML = image.src
    const { width } = div.getBoundingClientRect()
    const svg = div.querySelector('svg')
    if (!svg) return

    fitSVG(svg, width, 280)
    const { all, text, lastIncorrectId } = findNodes(svg)
    if (lastIncorrectId) {
      showError(
        intl.formatMessage({ id: 'dashboards.svg_diagram.errors.incorrectSelectorId' }, { id: lastIncorrectId })
      )
    }

    setTextOptions(text.map((t) => ({ value: t, label: t })))
    setNodeOptions(all.map((n) => ({ value: n, label: n })))
  }, [image])

  return (
    <Modal size="hg" onClose={props.onCancel}>
      <div>
        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className={css.settingsForm}>
            <div>
              <h2>{intl.formatMessage({ id: props.title })}</h2>

              <InputRow label={intl.formatMessage({ id: 'common.name' })}>
                <TextInput name="title" required={false} value={settings.title} onChange={handleChange} />
              </InputRow>

              <InputRow label={intl.formatMessage({ id: 'dashboards.svg_diagram.settings.image_id' })}>
                <ImageInput name="image_id" formats={formats} value={settings.image_id} onChange={handleChange} />
              </InputRow>

              <div ref={imageContainer} className={css.container} />
            </div>

            <div>
              <FormTabs titles={tabs}>
                <SettingsText
                  settings={settings}
                  textOptions={textOptions}
                  deviceOptions={deviceOptions}
                  parameterOptions={parameterOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                  usedNodes={usedNodes.current}
                />

                <SettingsColor
                  svg={imageContainer.current && imageContainer.current.querySelector('svg')}
                  settings={settings}
                  nodeOptions={nodeOptions}
                  deviceOptions={deviceOptions}
                  parameterOptions={parameterOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />

                <SettingsVisibility
                  svg={imageContainer.current && imageContainer.current.querySelector('svg')}
                  settings={settings}
                  nodeOptions={nodeOptions}
                  deviceOptions={deviceOptions}
                  parameterOptions={parameterOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />

                <SettingsCommands
                  settings={settings}
                  nodeOptions={nodeOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />

                <SettingsLinks
                  settings={settings}
                  nodeOptions={nodeOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />

                <SettingsAnimations
                  svg={imageContainer.current && imageContainer.current.querySelector('svg')}
                  type={'movements'}
                  settings={settings}
                  deviceOptions={deviceOptions}
                  parameterOptions={parameterOptions}
                  nodeOptions={nodeOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />

                <SettingsAnimations
                  svg={imageContainer.current && imageContainer.current.querySelector('svg')}
                  type={'rotations'}
                  settings={settings}
                  deviceOptions={deviceOptions}
                  parameterOptions={parameterOptions}
                  nodeOptions={nodeOptions}
                  onHover={outlineNode}
                  onAdd={handleRowAdd}
                  onChange={handleRowChange}
                  onRemove={handleRowRemove}
                />
              </FormTabs>
            </div>
          </div>

          <ModalFooter onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

// разрешать выбирать только svg изображения из библиотеки изображений
const formats = ['svg']

interface Props {
  component: ISVGComponent
  onCancel: () => void
  onSave: (settings: ISVGSettings) => void
  title: string
}

export default SVGSettings
