import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import ChartsList, { ChartListData } from '../../shared/LineCharts/ChartsList'
import { Equipment } from 'au-nsi/equipment'
import { RESERVED_CHART_IDS } from '../../services/data/constants'

const ID = RESERVED_CHART_IDS.UNBALANCE

/**
 * Линейные графики на странице несимметрии
 */
const VectorCharts = (props: Props) => {
  const intl = useIntl()

  const charts = React.useMemo(() => {
    const result: ChartListData[] = []
    const { device } = props

    if (props.charts.current) {
      const lines = generatePhaseLines(device, 'CurrentMagnitude')
      result.push({ id: ID, settings: { title: 'vector.charts.current', lines } })
    }

    if (props.charts.voltage) {
      const lines = generatePhaseLines(device, 'VoltageMagnitude')
      result.push({ id: ID + 1, settings: { title: 'vector.charts.voltage', lines } })
    }

    if (props.charts.activePower) {
      const lines = [{ device_id: device.id, parameter_id: 'PowerActiveTriPhase', color: device.color }]
      result.push({ id: ID + 2, settings: { title: 'vector.charts.activePower', lines } })
    }

    if (props.charts.unbalance) {
      const lines = [{ device_id: device.id, parameter_id: 'CurrentUnbalance', color: device.color }]
      result.push({ id: ID + 3, settings: { title: 'vector.charts.unbalance', lines } })
    }

    if (props.charts.voltageMagnitudeSeq) {
      const lines = generateSequenceLines(device, 'VoltageMagnitudeSeq')
      result.push({ id: ID + 4, settings: { title: 'vector.charts.voltageMagnitudeSeq', lines } })
    }

    if (props.charts.currentMagnitudeSeq) {
      const lines = generateSequenceLines(device, 'CurrentMagnitudeSeq')
      result.push({ id: ID + 5, settings: { title: 'vector.charts.currentMagnitudeSeq', lines } })
    }

    for (const row of result) {
      row.settings.title = intl.formatMessage({ id: row.settings.title })
    }

    return result
  }, [props.device, props.charts])

  return (
    <div className="pq-trends-container">
      <ChartsList showAsGrid={props.showAsGrid} charts={charts} />
    </div>
  )
}

const generatePhaseLines = (device: Equipment, parameter: string) => {
  return ['A', 'B', 'C'].map((phase) => {
    const color = generatePhaseColor(phase)
    return { device_id: device.id, parameter_id: parameter + 'Phase' + phase, color }
  })
}

const generateSequenceLines = (device: Equipment, parameter: string) => {
  return ['Positive', 'Negative', 'Zero'].map((sequence) => {
    const color = generatePhaseColor(sequence)
    return { device_id: device.id, parameter_id: parameter + sequence, color }
  })
}

const generatePhaseColor = (phase: string): string => {
  if (phase === 'A' || phase === 'Positive') return '#FFFF00'
  else if (phase === 'B' || phase === 'Negative') return '#00FF00'
  else return '#FF0000'
}

interface Props {
  device: Equipment
  charts: { [key: string]: boolean }
  showAsGrid: boolean
}

const mapStateToProps = (state: ReduxState) => {
  return {
    charts: state.vector.charts,
    showAsGrid: state.vector.showAsGrid,
  }
}

export default connect(mapStateToProps)(VectorCharts)
