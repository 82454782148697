import { AnyCatalogWithInstances } from './catalogs.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICatalogItem } from 'au-nsi/catalogs'

export interface CatalogsState {
  isLoaded: boolean
  catalogs: AnyCatalogWithInstances[]
}

const initialState: CatalogsState = {
  isLoaded: false,
  catalogs: [],
}

interface ServerCatalogsResp {
  catalogs: AnyCatalogWithInstances[]
  catalog_items: ICatalogItem[]
  templates: ICatalogItem[]
}

export const slice = createSlice({
  name: 'app/catalogs',
  initialState,
  reducers: {
    catalogsLoaded: (state, action: PayloadAction<ServerCatalogsResp>) => {
      state.isLoaded = true
      const serverResp = action.payload

      state.catalogs = serverResp.catalogs.map((catalog) => ({
        ...catalog,
        instances: serverResp.catalog_items.filter((instance) => instance.catalog_id === catalog.id),
        templates: serverResp.templates.filter((t) => t.catalog_id === catalog.id),
      }))
    },
    catalogCreated: (state, action: PayloadAction<AnyCatalogWithInstances>) => {
      if (action.payload && !state.catalogs.some((catalog) => catalog.id === action.payload.id)) {
        state.catalogs.push({ ...action.payload, instances: [] })
      }
    },
    catalogUpdated: (state, action: PayloadAction<AnyCatalogWithInstances>) => {
      const catalogIndex = state.catalogs.findIndex((catalog) => catalog.id === action.payload.id)
      if (catalogIndex !== -1) {
        state.catalogs[catalogIndex] = { ...action.payload, instances: state.catalogs[catalogIndex].instances }
      }
    },
    catalogDeleted: (state, action: PayloadAction<AnyCatalogWithInstances>) => {
      state.catalogs = state.catalogs.filter((catalog) => catalog.id !== action.payload.id)
    },
    instanceCreated: (state, action: PayloadAction<ICatalogItem>) => {
      if (!action.payload) return
      const key = action.payload.type === 'template' ? 'templates' : 'instances'

      const catalog = state.catalogs.find((catalog) => catalog.id === action.payload.catalog_id)
      if (!(catalog?.[key] || []).some((instance) => instance.id === action.payload.id)) {
        catalog[key].push(action.payload)
      }
    },
    instanceUpdated: (state, action: PayloadAction<ICatalogItem>) => {
      const key = action.payload.type === 'template' ? 'templates' : 'instances'

      const catalog = state.catalogs.find((catalog) => catalog.id === action.payload.catalog_id)
      const instanceIndex = (catalog?.[key] || []).findIndex((instance) => instance.id === action.payload.id)
      if (instanceIndex !== -1) catalog[key][instanceIndex] = action.payload
    },
    instanceDeleted: (state, action: PayloadAction<ICatalogItem>) => {
      const catalog = state.catalogs.find((catalog) => catalog.id === action.payload.catalog_id)
      if (!catalog) return

      const itemIndex = catalog.instances.findIndex((it) => it.id === action.payload.id)
      const templateIndex = catalog.templates.findIndex((t) => t.id === action.payload.id)
      if (itemIndex === -1 && templateIndex === -1) return

      const key = templateIndex !== -1 ? 'templates' : 'instances'
      catalog[key].splice(key === 'templates' ? templateIndex : itemIndex, 1)
    },
  },
})

export const actions = slice.actions
export const catalogsReducer = slice.reducer
