import { atom } from 'recoil'
import { IDocument } from 'au-nsi/documents'

export const systemEnvState = atom({
  key: 'systemEnvState',
  default: { version: '0.0.0', grafana_url: '' },
})

export const systemPublicState = atom<{ title: string; logo: string; pdp_agreement_id: IDocument['id'] }>({
  key: 'systemPublicState',
  default: null,
})
