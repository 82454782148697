import { batch } from 'react-redux'
import http, { handleHttpError } from '../../utils/http'
import { generateActions } from '../Collections/collection.actions'
import { ParameterGroup } from 'au-nsi/parameters'

const GROUPS_URL = '/nsi/v1/parameters/groups/'

const actions = generateActions<ParameterGroup>('parameter_groups', GROUPS_URL)

/**
 * Redefine default update action, because updating name and updating path
 * requires making requests to different endpoints
 */
actions.updateItem = (group: ParameterGroup) => (dispatch, getState) => {
  const groups = getState().parameter_groups.items
  const prevValue = groups.find((g) => g.id === group.id)

  if (prevValue && prevValue.name !== group.name) {
    http
      .patch(GROUPS_URL + group.id, { name: group.name })
      .then(({ data }) => dispatch(actions.itemUpdated(group.id, data)))
      .catch((err) => handleHttpError(err))
  }

  if (prevValue && prevValue.path !== group.path) {
    http
      .post(`${GROUPS_URL}/${group.id}/move`, { path: group.path })
      .then(({ data }) => {
        // data is an array, because moving one group also moves all subgroups
        batch(() => {
          data.forEach((item) => dispatch(actions.itemUpdated(item.id, item)))
        })
      })
      .catch((err) => handleHttpError(err))
  }

  return Promise.resolve()
}

/**
 * Redefine default delete action to delete group locally before request is completed
 */
actions.deleteItem = (id: number) => (dispatch) => {
  dispatch(itemDeleted(id))

  return http.delete(GROUPS_URL + id).catch(handleHttpError)
}

/**
 * Redefine default delete actions, because with current group we also need
 * to remove all its subgroups
 */
const itemDeleted =
  (id: number, background = false) =>
  (dispatch, getState) => {
    const groups = getState().parameter_groups.items
    const _id = id.toString()

    // find deleted group with all subgroups
    const removed = groups.filter((group) => {
      const parents = group.path.split('.')
      return parents.some((p) => p === _id)
    })

    batch(() => {
      removed.forEach((g) => dispatch(actions.itemDeleted(g.id, background)))
    })
  }

const groupsActions = { ...actions, itemDeleted }

export default groupsActions
