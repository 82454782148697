import { loadTranslations } from 'pages/App/lp.actions'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import FormPanel from '../../../shared/FormPanel/FormPanel'
import HelpTooltip from '../../../shared/HelpTooltip/HelpTooltip'
import Toggle from '../../../shared/Inputs/Toggle'
import http, { handleHttpError } from '../../../utils/http'
import { selectAccessRights } from '../../App/app.selectors'
import paramsActions from '../../Parameters/params.actions'
import css from './modules.module.css'
import { modulesActions } from './modules.state'
import { PlatformModule } from './modules.types'

/**
 * Страница включения/выключения модулей системы
 */
const ModulesPage = () => {
  const dispatch = useDispatch()
  const modules = useSelector((state: ReduxState) => state.modules)
  const canToggle = useSelector(selectAccessRights)['settings:update_modules']

  const modules2draft = () => Object.fromEntries(modules.map((m) => [m.id, m.enabled]))

  const [panels, setPanels] = useState({ services: true, protocols: true, ui: true })
  const [draft, setDraft] = useState(modules2draft)

  useEffect(() => {
    setDraft(modules2draft())
  }, [modules])

  const togglePanel = (id: string) => setPanels({ ...panels, [id]: !panels[id] })

  // включение/выключение модуля - сразу обновляем локальный стейт, затем отправляем запрос в НСИ,
  // при успешном запросе обновляем стейт в redux, при ошибке откатываем локальные изменения
  const handleToggle = (enabled: boolean, id: string) => {
    if (canToggle) {
      const m = modules.find((e) => e.id === id)
      const updates = { id, enabled }
      setDraft((prev) => ({ ...prev, [id]: enabled }))

      http
        .post('/nsi/v1/modules/status', updates)
        .then(() => {
          dispatch(modulesActions.updateModule(updates))
          // при включении модулей могут добавляться новые параметры, поэтому их необходимо перезагрузить
          if (m.type === 'service') dispatch(paramsActions.loadItems(true))
          // также могут добавиться новые переводы для включенного модуля
          if (enabled) dispatch(loadTranslations(true))
        })
        .catch((err) => {
          setDraft((prev) => ({ ...prev, [id]: !enabled }))
          handleHttpError(err)
        })
    }
  }

  const renderModule = (m: PlatformModule) => {
    return (
      <div key={m.id} className={css.row}>
        <Toggle name={m.id} size="14px" checked={draft[m.id]} onChange={handleToggle} />
        <span>{m.name}</span>

        <HelpTooltip position="top" style={{ marginLeft: '8px' }}>
          {m.description}
        </HelpTooltip>
      </div>
    )
  }

  const services = modules.filter((m) => m.type === 'service').map(renderModule)
  const protocols = modules.filter((m) => m.type === 'protocol').map(renderModule)
  const ui = modules.filter((m) => m.type === 'ui').map(renderModule)

  return (
    <div className="nsi-main__container">
      <FormPanel id="services" open={panels.services} title="system.modules.services" onToggle={togglePanel}>
        <div className={css.block}>{services}</div>
      </FormPanel>

      <FormPanel id="protocols" open={panels.protocols} title="system.modules.protocols" onToggle={togglePanel}>
        <div className={css.block}>{protocols}</div>
      </FormPanel>

      <FormPanel id="ui" open={panels.ui} title="system.modules.ui" onToggle={togglePanel}>
        <div className={css.block}>{ui}</div>
      </FormPanel>
    </div>
  )
}

export default ModulesPage
