import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as ClearIcon } from '../../../icons/clear.svg'
import styles from './image.module.css'
import { selectImages } from './image.selectors'
import usePopup from '../../../hooks/usePopup'
import SmoothDropdown from '../../../shared/Utils/SmoothDropdownBehaivor/SmoothDropdown'

/**
 * Инпут для выбора изображения
 */
const ImageInput = (props: Props) => {
  const intl = useIntl()
  const input = React.useRef<HTMLInputElement>()
  const wrapper = React.useRef<HTMLDivElement>()

  const { open, setOpen } = usePopup(wrapper)
  const [filter, setFilter] = React.useState('') // поиск по названию

  const allImages = useSelector(selectImages)
  const image = allImages.find((im) => im.id === props.value)

  // фильтр по форматам изображений (если указан)
  const images = React.useMemo(() => {
    return props.formats ? allImages.filter((img) => props.formats.includes(img.format)) : allImages
  }, [allImages, props.formats])

  // выставить название выбранного изображения в поле для ввода
  React.useEffect(() => {
    if (props.editing === false) return

    input.current.value = image ? image.name + '.' + image.format : intl.formatMessage({ id: 'common.not_selected' })
    setFilter('')
  }, [image, props.editing])

  // выбрать изображение при клике на него
  const handleSelect = (e) => {
    props.onChange(e.currentTarget.dataset.id, props.name)
    setOpen(false)
  }

  // убрать выбор изображения
  const handleClear = () => {
    props.onChange(undefined, props.name)
    setOpen(false)
  }

  // обрабатываем blur только если он вызван переключением на другое поле через Tab
  const handleBlur = (e: React.FocusEvent) => {
    if (e.relatedTarget) setOpen(false)
  }

  // выпадающий список с доступными изображениями
  const renderMenu = () => {
    const filtered = filter ? images.filter((img) => img.fullname.toLowerCase().includes(filter)) : images

    const items = filtered.map((img) => {
      return (
        <div key={img.id} data-id={img.id} className={styles.tile} onClickCapture={handleSelect}>
          <div className={styles.tileImage} style={{ backgroundImage: `url("${img.src_url}")` }} />
          <div className={`${styles.tileText} line_clamp`} title={img.fullname}>
            {img.fullname}
          </div>
        </div>
      )
    })

    // если в списке нет изображений, то вместо него выводим сообщение
    if (items.length === 0) {
      const message = images.length === 0 ? 'system.images.library_is_empty' : 'common.no_results'
      return (
        <div className="nsi-select__menu text--gray text--center" style={{ padding: '6px' }}>
          {intl.formatMessage({ id: message })}
        </div>
      )
    }

    const className = `nsi-select__menu ${styles.library} ${styles.menuLibrary}`
    return (
      <SmoothDropdown className={className} maxHeight={300} open={open}>
        {items}
      </SmoothDropdown>
    )
  }

  if (props.editing === false) return <span>{image?.name || '-'}</span>

  return (
    <div ref={wrapper} className="nsi-select">
      <input
        className="nsi-input nsi-select__input"
        onFocus={() => setOpen(true)}
        onBlur={handleBlur}
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
        ref={input}
      />
      {renderMenu()}
      <ClearIcon
        style={{ width: 24, height: 24, position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }}
        onClick={handleClear}
      />
    </div>
  )
}

interface Props {
  name?: string
  formats?: string[]
  value: string
  onChange: (value: string, name?: string) => void
  editing?: boolean
}

export default React.memo(ImageInput)
