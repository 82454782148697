import classnames from 'classnames'

const GanttBlock = (props: Props) => {
  const className = classnames('gantt__block', {
    'gantt__block--selectable': props.selectable,
  })

  return <div className={className}>{props.children}</div>
}

GanttBlock.defaultProps = {
  selectable: true,
}

interface Props {
  children: any
  selectable?: boolean
}

export default GanttBlock
