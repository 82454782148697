import { AuthState } from '../../pages/Auth/auth.reducers'
import { store } from '../../redux/store'
import { WS_URL } from '../../shared/constants'
import EventEmitter from '../../utils/events'
import { ensureAuthorization } from '../../utils/http'
import { IDataSelector } from '../data/data.types'
import { dispatchAction, dispatchActions, dispatchReconnect } from './ws.dispatcher'
import { ITimesync, WSSubscription } from './ws.types'
import { mergeSelectors, parseData, parseTimesync } from './ws.utils'

const STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
}

/**
 * Модуль для получения онлайн данных с ui-back по вебсокетам.
 *
 * Подключается к редакс стору и автоматически устанавливает соединение
 * с сервером после успешной авторизации
 */
export class WSClient {
  public timesync$ = new EventEmitter<ITimesync>()
  public connected$ = new EventEmitter<boolean>()

  private auth: AuthState
  private ws: WebSocket
  private lastMessage = Date.now()

  private heartbeatInterval = 90 * 1000 // 90s (server sends timesync every 60s)
  private reconnectInterval = 3000 // 3s

  private messageTypes = { data: 1, timesync: 2, reconnect: 3 }

  private subscriptions: WSSubscription[] = []
  private serverSubscription: ServerSubscription = { id: 1, counter: 0, query: [] }
  private prevServerSubscription: ServerSubscription = { id: 1, counter: 0, query: [] }
  private subscriptionTimer: number = null
  private isSubscribed = false

  private timer = null
  private isReconnecting = false

  constructor() {
    store.subscribe(this.handleStoreChange)
    setInterval(this.heartbeat, this.heartbeatInterval)
  }

  /**
   * Подписаться на получение онлайн данных
   */
  public subscribe(subscription: WSSubscription) {
    this.subscriptions.push(subscription)
    this.scheduleSubscribeCommand()
  }

  /**
   * Изменить параметры подписки (т.е. запросить данные по другим параметрам или устройствам)
   */
  public updateSubscription(subscription: WSSubscription) {
    const index = this.subscriptions.findIndex((s) => s.id === subscription.id)
    if (index === -1) return console.error(`WS: can not update subscription ` + subscription.id)

    this.subscriptions[index] = subscription
    this.scheduleSubscribeCommand()
  }

  /**
   * Убрать подписку и перестать получать по ней данные с сервера
   */
  public unsubscribe(id: WSSubscription['id']) {
    const index = this.subscriptions.findIndex((s) => s.id === id)
    if (index === -1) return console.error(`WS: can not remove subscription ` + id)

    this.subscriptions.splice(index, 1)
    this.scheduleSubscribeCommand()
  }

  private connect() {
    if (this.ws) {
      return 'already connected'
    }

    if (this.auth.status !== 'logged_in') {
      return console.log('ws: aborting connect, not authorized')
    }

    ensureAuthorization().then((token) => {
      const ws = new WebSocket(WS_URL + `/back/ws/ws?token=${token}`)
      this.ws = ws
      this.ws.binaryType = 'arraybuffer'

      ws.addEventListener('close', this.onclose)
      ws.addEventListener('message', this.onmessage)
      ws.addEventListener('open', this.onopen)
    })
  }

  private terminate() {
    clearTimeout(this.timer)

    if (!this.ws) {
      return 'already terminated'
    }

    this.ws.removeEventListener('close', this.onclose)
    this.ws.removeEventListener('message', this.onmessage)
    this.ws.removeEventListener('open', this.onopen)

    const state = this.ws.readyState
    if (state === STATES.OPEN || state === STATES.CONNECTING) {
      this.ws.close()
    }

    this.ws = null
  }

  private onclose = (e?: CloseEvent) => {
    this.terminate()
    this.connected$.next(false)

    // переподключиться, если соединение закрылось с ошибкой
    if (!e || e.code !== 1000) {
      this.isReconnecting = true
      this.timer = setTimeout(() => this.connect(), this.reconnectInterval)
    }
  }

  private onmessage = (message) => {
    this.lastMessage = Date.now()

    const { data } = message

    if (data instanceof ArrayBuffer) {
      return this.handleBinaryMessage(data)
    } else {
      return this.handleJSONMessage(data.toString())
    }
  }

  private onopen = () => {
    console.log('WS: connected to server')
    this.lastMessage = Date.now()

    if (this.isReconnecting) {
      this.isReconnecting = false

      console.log('WS: reconnected, reloading resources')
      dispatchReconnect(store)
    }

    this.isSubscribed = false
    this.scheduleSubscribeCommand()
    this.connected$.next(true)
  }

  private handleJSONMessage(content: string) {
    let msg = null

    try {
      msg = JSON.parse(content)
    } catch (error) {
      console.error(error)
    }

    const { type } = msg

    switch (type) {
      case 'action':
        return dispatchAction(store, msg.action)
      case 'actions':
        return dispatchActions(store, msg.actions)
      default:
        return console.warn('WS: Unknown message type', msg)
    }
  }

  private handleBinaryMessage(buffer: ArrayBuffer) {
    let view = new DataView(buffer, 0, 2)
    const version = view.getInt8(0)
    const type = view.getInt8(1)

    if (version === 2 && type === this.messageTypes.data) {
      view = new DataView(buffer, 0, 10)
      const counter = view.getUint32(6)

      // данные в основном должны приходить по текущей подписке, но после обновления
      // может придти пакет от предыдущей
      const query =
        this.serverSubscription.counter === counter
          ? this.serverSubscription.query
          : this.prevServerSubscription.counter === counter
          ? this.prevServerSubscription.query
          : null

      if (!query) return

      try {
        const data = parseData(buffer, query)
        this.subscriptions.forEach((s) => s.onData(data, s.query))
      } catch (error) {
        console.error(error)
      }

      return
    }

    if (version === 1 && type === this.messageTypes.timesync) {
      const data = parseTimesync(buffer)
      return this.timesync$.next(data)
    }

    if (version === 1 && type === this.messageTypes.reconnect) {
      console.log('NATS reconnect, reloading resources...')
      return dispatchReconnect(store)
    }

    console.warn(`Can not handle binary message (version: ${version}, type: ${type})`)
  }

  private handleStoreChange = () => {
    const auth = store.getState().auth
    if (auth === this.auth) return

    this.auth = auth

    // открыть соединение если пользователь авторизовался
    if (this.auth.accessToken && !this.ws) {
      return this.connect()
    }

    // закрыть соединение если пользователь разлогинился
    if (!this.auth.accessToken && this.ws) {
      return this.terminate()
    }
  }

  /**
   * Отправка команды на следующем фрейме для того чтобы все компоненты
   * успели зарегистрировать свои подписки на данные.
   */
  private scheduleSubscribeCommand() {
    if (this.subscriptionTimer == null) {
      this.subscriptionTimer = requestAnimationFrame(this.sendSubscribeCommand)
    }
  }

  /**
   * Отправка команды на сервер, для того чтобы сервер начал отправлять
   * неоходимые данные.
   */
  private sendSubscribeCommand = () => {
    this.subscriptionTimer = null
    if (!this.ws || this.ws.readyState !== STATES.OPEN) return

    const query = mergeSelectors(this.subscriptions)
    const counter = this.serverSubscription.counter + 1
    const id = this.serverSubscription.id

    // сохраняем предыдущую подписку, т.к. она может понадобиться для парсинга
    // пришедших по ней запоздавших пакетов
    this.prevServerSubscription = this.serverSubscription
    this.serverSubscription = { id, counter, query }

    if (this.isSubscribed && query.length === 0) {
      const msg = JSON.stringify({ type: 'v2/UNSUBSCRIBE', id })
      this.ws.send(msg)
      this.isSubscribed = false
    }

    if (this.isSubscribed && query.length > 0) {
      const msg = JSON.stringify({ type: 'v2/UPDATE', id, counter, query })
      this.ws.send(msg)
    }

    if (!this.isSubscribed && query.length > 0) {
      const msg = JSON.stringify({ type: 'v2/SUBSCRIBE', id, counter, query })
      this.ws.send(msg)
      this.isSubscribed = true
    }
  }

  /**
   * Проверка статуса соединения.
   * В нормальной ситуации сообщения должны приходить каждые ~500мс,
   * если сообщений нет то необходимо переподключиться
   */
  private heartbeat = () => {
    const isConnectionBroken = this.ws && this.lastMessage < Date.now() - this.heartbeatInterval

    if (isConnectionBroken) {
      console.log('Missed heartbeat, reconnecting...')
      this.onclose()
    }
  }
}

interface ServerSubscription {
  id: number
  counter: number
  query: IDataSelector[]
}

export default new WSClient()
