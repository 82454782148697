import { ICatalog, ICatalogItem } from 'au-nsi/catalogs'

// Хелпер <Tree /> для устранения конфиликотов ID
const catalogTreeIdCodec = {
  encodeInstance: (instance: Pick<ICatalogItem, 'id'>, parentId: ICatalogItem['catalog_id']) => {
    return instance?.id ? `i_${instance.id}_${parentId}` : null
  },
  decodeInstance: (id: string): [ICatalogItem['id'], ICatalogItem['catalog_id']] => {
    const sp = id.split('_')
    return [parseInt(sp[1]), parseInt(sp[2])]
  },
  encodeCatalog: (catalog: Pick<ICatalog, 'id'>) => (catalog?.id ? `c_${catalog.id}` : null),
  decodeCatalog: (id: string) => parseInt(id.split('_')[1]),
  isIdBelongsToCatalog: (id: string) => id.split('_')[0] === 'c',
}

export default catalogTreeIdCodec
