const START = 1e6

/**
 * Для всех графиков нужны уникальные идентификаторы. Для графиков из конструктора
 * они генерируются базой данных. Для системных графиков используются эти ID.
 */
export const RESERVED_CHART_IDS = {
  PQ: START,
  TRENDS: START + 100,
  POWER: START + 200,
  DIGITALS: START + 300,
  LFO: START + 400,
  LFO_TABLE: START + 450,
  UNBALANCE: START + 500,
  UNBALANCE_TABLE: START + 550,
  UNBALANCE_VECTOR: START + 580,
  OPLIM_GAUGE: START + 600,
  OPLIM_TABLE: START + 650,
  AEPS: START + 700,
  MAP_TOPOLOGY: START + 750,
}
