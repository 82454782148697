import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import usePasswordRules from '../../../hooks/usePasswordRules'
import { ReduxState } from '../../../redux/store.types'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import InputRow from '../../../shared/Inputs/InputRow'
import PasswordInput from '../../../shared/Inputs/PasswordInput'
import TextInput from '../../../shared/Inputs/TextInput'
import http, { handleHttpError } from '../../../utils/http'
import { updateUser } from '../../App/user.actions'
import styles from '../user.module.css'
import { selectUser } from '../../Collections/Users/users.selectors'
import { showError } from '../../../utils/notifications'

const AccountForm = () => {
  const intl = useIntl()
  const [isEditing, setEditing] = useState(false)
  const [account, setAccount] = useState({ username: '', password: '' })

  const { validator, help } = usePasswordRules(isEditing)
  const passwordValid = !account.password || validator(account.password)
  const passwordStyle = !passwordValid ? { border: '1px solid var(--danger-80)' } : undefined

  const user = useSelector(selectUser)
  const role = useSelector(selectRole)

  const dispatch = useDispatch()

  const handleEdit = () => {
    setAccount({ username: user.username, password: '' })
    setEditing(true)
  }

  const handleCancel = () => setEditing(false)

  const handleSave = () => {
    if (!account.username) {
      return showError('nsi.user.errors.empty_login')
    }

    if (account.password && !passwordValid) {
      return showError('nsi.user.errors.invalid_password')
    }

    if (!account.password) account.password = undefined

    http.post('/nsi/user/account', account).catch(handleHttpError)

    setEditing(false)
    dispatch(updateUser({ ...account }))
  }

  const handleChange = (value: string, key: string) => {
    setAccount({ ...account, [key]: value })
  }

  const usernameInput = () => (
    <TextInput name="username" value={account.username} required={true} onChange={handleChange} />
  )

  const passwordInput = () => (
    <PasswordInput name="password" style={passwordStyle} value={account.password} onChange={handleChange} />
  )

  if (!user || !role) return null

  return (
    <React.Fragment>
      <h2 className="system__title" style={{ marginTop: '45px', marginBottom: '20px' }}>
        {intl.formatMessage({ id: 'nav.system.account' })}
      </h2>

      <form>
        <InputRow label={intl.formatMessage({ id: 'system.profile.name' })} tableMode={true}>
          {user.name}
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.profile.role' })} tableMode={true}>
          {role.name}
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.profile.username' })} tableMode={true}>
          {isEditing ? usernameInput() : user.username}
        </InputRow>

        {isEditing && (
          <InputRow
            label={intl.formatMessage({ id: 'system.profile.password' })}
            help={<span className="text--prewrap">{help}</span>}
            tableMode={true}
          >
            {passwordInput()}
          </InputRow>
        )}
      </form>

      <PanelButtons
        wrapperClass={styles.profile__formButtons}
        editing={isEditing}
        allowEditing={true}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </React.Fragment>
  )
}

const selectRole = createSelector(
  (state: ReduxState) => state.user,
  (state: ReduxState) => state.roles.items,
  (profile, roles) => roles.find((r) => r.id === profile.role_id)
)

export default AccountForm
