export const url = '/nsi/data_rate/device'

export interface DataRate {
  device_id: string
  parameter_id: string
  data_rate: number
}

export interface DataRates {
  data_rates: DataRate[]
}

export const tableHeader = ['import.table.parameter', 'nsi.region_data_rate']
