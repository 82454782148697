import React from 'react'
import { useSelector } from 'react-redux'
import { selectDataIntervalsMap } from '../pages/Nsi/nsi.selectors'
import DataService from '../services/data/data.service'

/**
 * Хук для передачи информации о частоте приема данных с устройства в сервис работы с данными
 */
const useDataRate = (service: DataService) => {
  const dataIntervals = useSelector(selectDataIntervalsMap)

  React.useEffect(() => {
    service.setDataIntervals(dataIntervals)
  }, [dataIntervals])
}

export default useDataRate
