import { useIntl } from 'react-intl'
import http from '../../../utils/http'
import idb from '../../../utils/idb'

/**
 * Ссылка для выхода из приложения
 */
const Logout = (props: Props) => {
  const intl = useIntl()

  // отправляется запрос на сервер для удаления сессии и удаляются все локальные данные из браузера
  const handleLogout = () => {
    http
      .post('/nsi/auth/logout', {}, { withCredentials: true })
      .catch((err) => console.error(err))
      .finally(() => {
        localStorage.clear()
        idb.clear()
        window.location.href = '/login'
      })
  }

  return (
    <span className={props.className} style={{ display: 'flex', alignItems: 'center' }} onClick={handleLogout}>
      <span className="system__nav-icon is-logout" />
      <span>{intl.formatMessage({ id: 'nav.system.logout' })}</span>
    </span>
  )
}

interface Props {
  className: string
}

export default Logout
