import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ISVGLinksSettings } from 'au-nsi/dashboards'
import React from 'react'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import Dropdown, { DropdownOption } from '../../../shared/Inputs/Dropdown'
import TextInput from '../../../shared/Inputs/TextInput'

const SettingsLinksRow = (props: Props) => {
  const { row, index } = props

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  // если ссылка на другой дашборд, то выводим список все экранов для выбора нужного,
  // если ссылка на внешний ресурс, то выводим тестовое поле для ввода url
  const targetInput =
    row.type === 'dashboard' ? (
      <Select
        name="target"
        options={props.dashboardOptions}
        value={row.target}
        onChange={handleChange}
        required={true}
      />
    ) : (
      <TextInput name="target" value={row.target} onChange={handleChange} required={true} />
    )

  return (
    <tr>
      <td>
        <Select
          name="node_id"
          options={props.nodeOptions}
          value={row.node_id}
          onChange={handleChange}
          onHover={props.onNodeHover}
          required={true}
        />
      </td>
      <td>
        <Dropdown name="type" value={row.type} options={props.typeOptions} onChange={handleChange} />
      </td>
      <td>{targetInput}</td>
      <td align="center" style={{ width: '3ch' }}>
        <RemoveButton id="" onRemove={() => props.onRemove(index)} />
      </td>
    </tr>
  )
}

interface Props {
  dashboardOptions: SelectOption[]
  index: number
  nodeOptions: SelectOption[]
  typeOptions: DropdownOption<string>[]
  onChange: (key: string, value: string, index: number) => void
  onNodeHover: (id: string) => void
  onRemove: (index: number) => void
  row: ISVGLinksSettings
}

export default React.memo(SettingsLinksRow)
