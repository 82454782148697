import { useDeviceDashboards } from 'pages/MapTopology/DeviceScreen/DeviceDashboards/DeviceDashboards'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'

/**
 * Хук для навигации с текущего дашборда на другой (https://alterosmart.atlassian.net/browse/AS-356).
 * Т.к. дашборд может отображаться в двух местах - на главной странице
 * экранов и в списке экранов-шаблонов на карточке устройства, то
 * переход должен происходить с учетом этого:
 * - с самостоятельного экрана на такой же самостоятельный экран
 * - с экрана-шаблона на шаблон в карточке того же устройства
 */
export const useDashboardNavigation = () => {
  const params = useParams()
  const deviceId = params.device_id

  const navigate = useNavigate()
  const templates = useDeviceDashboards(deviceId)
  const dashboards = useAppSelector((state) => state.dashboards.dashboards)

  const toDashboard = (dashboardId: string) => {
    navigate(`/dashboards/${dashboardId}`)
  }

  const toTemplate = (dashboardId: string, deviceId: string) => {
    navigate(`/map/device/${deviceId}/dashboards/${dashboardId}`)
  }

  return (dashboardId: string) => {
    // сначала проверяем виден ли вообще целевой экран текущему пользователю
    const canAccess = dashboards.find((d) => d.id === dashboardId)
    if (!canAccess) return

    // в данный момент мы не на карточке устройства
    if (!deviceId) return toDashboard(dashboardId)

    const isTemplate = templates.find((d) => d.id === dashboardId)
    return isTemplate ? toTemplate(dashboardId, deviceId) : toDashboard(dashboardId)
  }
}
