import { SelectOption } from '@alterouniversal/au-react-components'
import { ISVGAnimationsSettings, ISVGSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import PlusButton from '../../../../shared/Forms/PlusButton'
import { selectDenormalizedParametersMap } from '../../../Parameters/params.selectors'
import { operatorOptions } from '../../condition.utils'
import css from '../svg.module.css'
import SettingsAnimationsRow from './SettingsAnimationsRow'

interface IProps {
  type: string
  settings: ISVGSettings
  nodeOptions: SelectOption[]
  deviceOptions: SelectOption[]
  parameterOptions: SelectOption[]
  onAdd: (key: string) => void
  onRemove: (key: string, index: number) => void
  onChange: (key: string, index: number, prop: string, value: any) => void
  onHover: (node: string) => void
  svg: SVGSVGElement
}

/**
 * Таблица с настройками анимации элементов схемы
 */
const SettingsAnimations = ({
  settings,
  nodeOptions,
  onAdd,
  onRemove,
  onChange,
  onHover,
  type,
  deviceOptions,
  parameterOptions,
  svg,
}: IProps) => {
  const animations: ISVGAnimationsSettings[] = settings[type] || []

  const parameters = useSelector(selectDenormalizedParametersMap)
  const prefixes = useSelector((state: ReduxState) => state.parameters.prefixes)
  const units = useSelector((state: ReduxState) => state.parameters.units)

  const intl = useIntl()

  const handleRemove = React.useCallback((index: number) => onRemove(type, index), [onRemove])

  const handleChange = React.useCallback(
    (key: string, value: any, index: number) => onChange(type, index, key, value),
    [onChange]
  )

  const rows = animations.map((row, ind) => {
    return (
      <SettingsAnimationsRow
        key={row.type + ind}
        deviceOptions={deviceOptions}
        index={ind}
        nodeOptions={nodeOptions}
        onChange={handleChange}
        onNodeHover={onHover}
        onRemove={handleRemove}
        operatorOptions={operatorOptions}
        parameter={parameters.get(row.parameter_id)}
        parameterOptions={parameterOptions}
        row={row}
        units={units}
        prefixes={prefixes}
        svg={svg}
      />
    )
  })

  return (
    <div className={css.settingsTableWrapper}>
      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.node_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.device_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.parameter_id' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.condition' })}</th>
            <th>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.value' })}</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <PlusButton textId="common.add" onClick={() => onAdd(type)} style={{ marginLeft: 0 }} />
    </div>
  )
}

export default SettingsAnimations
