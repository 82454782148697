import classnames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { useRecoilState } from 'recoil'
import Clock from '../../../services/clock/clock.service'
import ArchiveModal from './ArchiveModal'
import css from './archive.module.css'
import playerArchiveState from './archive.state'
import { ArchiveEntry } from './archive.types'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '../../App/app.selectors'

/**
 * Навигация по архивным данным - появляется только при переходе в оффлайн и
 * позволяет переключаться между линейным и аварийным архивами
 */
const ArchiveManager = (props: Props) => {
  const intl = useIntl()
  const prevClockState = React.useRef<ClockState>()

  const [open, setOpen] = React.useState(false)
  const [entry, setEntry] = useRecoilState(playerArchiveState)
  const allowAccess = useSelector(selectAccessRights)['incidents_archive:get']

  // переход к выбранному участку аварийного архива
  const handleSelect = (e: ArchiveEntry) => {
    setEntry(e)
    setOpen(false)

    const t0 = e.ts_start / 1000
    const t1 = e.ts_end / 1000

    const { clock } = props

    // сохранение состояния плеера до перехода в архив для возможности вернуться
    // к тем же настройкам после выхода
    if (prevClockState.current == null) {
      prevClockState.current = {
        ts: clock.getPlayerTime(),
        frame: props.clock.getFrame(),
        limits: clock.getFrameLimits(),
      }
    }

    // перемещаем кадр на выбранный участок времени и запрещаем выходить за его пределы
    props.clock.setTimeBoundaries(null, null)
    props.clock.setPlayerTime(t1)
    props.clock.setFrame(t1 - t0)
    props.clock.setFrameLimits(prevClockState.current.limits.min, t1 - t0)
    props.clock.setTimeBoundaries(t0, t1)
  }

  // переход обратно к линейному архиву и восстановление изначальных времени и кадра плеера
  const handleExit = () => {
    if (entry) {
      setEntry(null)
    }

    if (entry && prevClockState.current) {
      const prevState = prevClockState.current
      props.clock.setTimeBoundaries(null, null)
      props.clock.setFrameLimits(prevState.limits.min, prevState.limits.max)
      props.clock.setPlayerTime(prevState.ts)
      props.clock.setFrame(prevState.frame)
    }

    setOpen(false)
  }

  React.useEffect(() => {
    if (props.online && entry) handleExit()
  }, [props.online])

  if (props.online || !allowAccess) return null

  const title = entry
    ? intl.formatMessage({ id: 'ChartPlayer.archive.impulse' }) + ': ' + entry.name
    : intl.formatMessage({ id: 'ChartPlayer.archive.linear' })

  const className = classnames(css.playerButton, { [css.playerButtonArchive]: entry != null })

  return (
    <div className={className} data-test-id="player-archive-button">
      <span onClick={() => setOpen(true)}>{title}</span>
      {open && <ArchiveModal onClose={handleExit} onSelect={handleSelect} mode="view" />}
    </div>
  )
}

interface ClockState {
  ts: number
  frame: number
  limits: { min: number; max: number }
}

interface Props {
  online: boolean
  clock: Clock
}

export default ArchiveManager
