import { IHistogramSettings } from 'au-nsi/dashboards'
import { colord } from 'colord'
import { roundNumber } from '../../../utils/format'
import { ParameterDn } from '../../Parameters/params.interfaces'

export const makeColors = (originalColor: string) => {
  const color = colord(originalColor)

  const normal = color.alpha(0.6).toRgbString()

  const selected = color.alpha(0.8).toRgbString()

  return { normal, selected }
}

export const transform = (data: Record<string, number>[], options: TransformOptions): HistogramData => {
  const { parameter, min, max } = options

  let minValue = 0
  let maxValue = 0
  let maxCount = 0
  let totalCount = 0
  const values: number[] = []
  const counts: number[] = []

  for (let i = 0; i < data.length; i++) {
    const count = data[i].$count
    const value = data[i][parameter]

    if (min != null && value < min) continue
    if (max != null && value > max) continue

    if (i === 0) minValue = value
    if (i === data.length - 1) maxValue = value
    if (count > maxCount) maxCount = count

    totalCount += count
    values.push(value)
    counts.push(count)
  }

  return { values, counts, minValue, maxValue, maxCount, totalCount }
}

/**
 * Формирование запроса для отправки в TSDB
 */
export const constructQuery = ({ settings, parameters, size }: ConstructQueryOptions) => {
  if (!size || !size.width) return null

  const tsTo = settings.parameters[0].time || Date.now()
  const tsFrom = tsTo - settings.parameters[0].timespan

  let step: number = undefined
  let bins: number = undefined

  const stepDefined = settings.step_mode === 'step' && settings.step
  const binsDefined = settings.step_mode === 'bins' && settings.bins
  const limitsDefined = settings.min != null && settings.max != null

  if (stepDefined) {
    step = settings.step
  } else if (binsDefined && limitsDefined) {
    step = (settings.max - settings.min) / settings.bins
  } else if (binsDefined) {
    bins = settings.bins
  } else if (limitsDefined) {
    step = (settings.max - settings.min) / (size.width / 10)
  } else {
    bins = Math.floor(size.width / 10)
  }

  if (step) step = roundNumber(step, 2)

  const parameterId = settings.parameters[0].parameter_id
  const parameter = parameters.get(parameterId)
  const type = parameter && parameter.is_irregular ? 'irregular' : 'processed'

  return {
    query: 'histogram',
    type,
    id: settings.parameters[0].device_id,
    tsTo,
    tsFrom,
    groups: [{ parameter: parameterId, step, bins }],
  }
}

interface ConstructQueryOptions {
  settings: IHistogramSettings
  parameters: Map<string, ParameterDn>
  size: DOMRect
}

interface TransformOptions {
  parameter: string
  min: number
  max: number
}

export interface HistogramData {
  values: number[]
  counts: number[]
  minValue: number
  maxValue: number
  maxCount: number
  totalCount: number
}
