import React from 'react'
import { useIntl } from 'react-intl'
import CollapseToggle from '../../Utils/CollapseToggle'
import css from './chart.module.css'

const SettingsBlock = (props: Props) => {
  const intl = useIntl()
  const { open } = props

  return (
    <>
      <div className={css.blockTitle} onClick={props.onToggle}>
        <CollapseToggle size={16} collapsed={!open} onToggle={() => null} />
        <span style={{ marginLeft: 8 }}>{intl.formatMessage({ id: props.title })}</span>
      </div>
      {open && props.children}
    </>
  )
}

interface Props {
  title: string
  children: React.ReactNode | React.ReactNodeArray
  open: boolean
  onToggle: () => void
}

export default SettingsBlock
