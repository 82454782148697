import { UserLocationReceiver } from './userLocationReceiver.interface'
import { Coordinates } from '../../../pages/Nsi/nsi.interfaces'

const userLocationService: UserLocationReceiver<Coordinates, any> = {
  getUserLocation: () => {
    const pastPosition = JSON.parse(localStorage.getItem('user_position'))

    return new Promise((resolve, _) => {
      if (pastPosition?.coords) return resolve(pastPosition)

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const cords = { lng: position.coords.longitude, lat: position.coords.latitude }
          localStorage.setItem(
            'user_position',
            JSON.stringify({ lng: position.coords.longitude, lat: position.coords.latitude })
          )

          resolve(cords)
        },
        () => resolve({ lng: 37.618423, lat: 55.751244 })
      )
    })
  },
}

export default userLocationService
