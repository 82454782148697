import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/store'
import { API_URL } from '../constants'
import Datepicker from '../Inputs/Datepicker/Datepicker'
import Dropdown from '../Inputs/Dropdown'
import InputRow from '../Inputs/InputRow'
import ToggleWithLabel from '../Inputs/ToggleWithLabel'
import Modal from '../Modal/Modal'
import ModalFooter from '../Modal/ModalFooter'
import { formatTimerange } from './export.utils'

interface Props {
  url: string
  t0: number
  t1: number
  title?: string
  onClose: () => void
}

/**
 * Модальное окно настроек экспорта
 */
const JournalsExportModal = (props: Props) => {
  const { onClose } = props

  const intl = useIntl()
  const token = useAppSelector((state) => state.auth.accessToken)

  const [compression, setCompression] = useState('gzip')
  const [format, setFormat] = useState('csv')
  const [t0, setT0] = useState(props.t0)
  const [t1, setT1] = useState(props.t1)

  const handleToggle = (exportAll: boolean) => {
    let t0 = null
    let t1 = null

    if (!exportAll) {
      t1 = Date.now()
      t0 = t1 - 24 * 60 * 60 * 1000
    }

    setT0(t0)
    setT1(t1)
  }

  const handleExport = () => {
    let url = API_URL + props.url + `?format=${format}&compression=${compression}`
    if (t0) url += `&t0=${t0}`
    if (t1) url += `&t1=${t1}`
    url += `&accessToken=${token}`
    url += `&timerange=` + encodeURIComponent(formatTimerange(t0, t1))

    const a = document.createElement('a')
    a.target = '_blank'
    a.href = url
    a.click()

    onClose()
  }

  const formatOptions = [
    { value: 'csv', title: 'CSV' },
    { value: 'jsonl', title: 'JSON' },
  ]

  const compressionOptions = [
    { value: 'none', title: intl.formatMessage({ id: 'common.no' }) },
    { value: 'gzip', title: 'gzip' },
  ]

  return (
    <Modal onClose={onClose}>
      <h2>{props.title || intl.formatMessage({ id: 'shared.export' })}</h2>

      <div style={{ marginBottom: '2em' }}>
        <ToggleWithLabel
          name=""
          value={!t0 && !t1}
          onChange={handleToggle}
          label={intl.formatMessage({ id: 'shared.export.export_all' })}
        />
      </div>

      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'shared.export.format' })}>
          <Dropdown value={format} onChange={setFormat} options={formatOptions} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'shared.export.compression' })}>
          <Dropdown value={compression} onChange={setCompression} options={compressionOptions} />
        </InputRow>

        {!!t0 && (
          <InputRow label={intl.formatMessage({ id: 'shared.export.t0' })}>
            <Datepicker time={t0} onTimeChange={setT0} />
          </InputRow>
        )}

        {!!t1 && (
          <InputRow label={intl.formatMessage({ id: 'shared.export.t1' })}>
            <Datepicker time={t1} onTimeChange={setT1} />
          </InputRow>
        )}
      </div>

      <ModalFooter onCancel={onClose} onSave={handleExport} saveMessage={intl.formatMessage({ id: 'shared.export' })} />
    </Modal>
  )
}

export default JournalsExportModal
