import classnames from 'classnames'
import React from 'react'
import { IntlShape } from 'react-intl'
import { formatDate } from '../../../utils/lang'
import { IChangelogItem, ICreateItem, IExportItem, IUpdateItem, IChangelogSelection } from './actions.types'
import DetailsNode from './DetailsNode'

class ActionsRow extends React.Component<Props, State> {
  state: State = { showDetails: this.props.showDetails }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.showDetails !== this.props.showDetails) {
      this.setState({ showDetails: this.props.showDetails })
    }
  }

  private renderDetails() {
    let details = null

    switch (this.props.item._action) {
      case 'create':
      case 'export':
      case 'import':
        details = this.renderCreateDetails(this.props.item)
        break
      case 'update':
        details = this.renderUpdateDetails(this.props.item)
    }

    return (
      <tr className="s-changelog__details text--prewrap">
        <td colSpan={5}>{details}</td>
      </tr>
    )
  }

  private renderCreateDetails(item: ICreateItem | IExportItem) {
    return <DetailsNode resource={item._resource} targetObject={item.details} {...this.selectTranslations(item)} />
  }

  private renderUpdateDetails(item: IUpdateItem) {
    return (
      <div className="s-changelog__details-diff">
        <DetailsNode
          resource={item._resource}
          targetObject={item.details.state_before}
          {...this.selectTranslations(item)}
          highlightDiff={true}
          highlightMode="deleted"
        />
        <DetailsNode
          resource={item._resource}
          targetObject={item.details.state_after}
          {...this.selectTranslations(item)}
          highlightDiff={true}
          highlightMode="created"
        />
      </div>
    )
  }

  private renderFullHeader() {
    const { item, translations } = this.props
    const time = formatDate(item.ts, 'd LLL HH:mm:ss')

    const actionClass = classnames('text--nowrap', {
      'text--success': item._action === 'create',
      'text--warning': item._action === 'update',
      'text--danger': item._action === 'delete',
    })

    const toggleTitle = this.props.intl.formatMessage({ id: 'system.actions.toggle_details' })
    const nameTitle = this.props.intl.formatMessage({ id: 'system.actions.show_history' })

    let resource = translations[item._resource] || item.resource
    if (item.subresource) resource += ` (${item.subresource})`

    const { name, subName } = parseName(item)

    return (
      <tr className="cursor--pointer" title={toggleTitle} onClick={this.handleToggle}>
        <td>{time}</td>
        <td className={actionClass}>{item.action}</td>
        <td>{item.user}</td>
        <td>{resource}</td>
        <td>
          <span title={nameTitle} onClick={this.handleResourceClick}>
            {name}
          </span>
          <span title={nameTitle} onClick={this.handleSubresourceClick}>
            {subName ? ' / ' + subName : ''}
          </span>
        </td>
      </tr>
    )
  }

  private renderCompactHeader() {
    const { item } = this.props
    const time = formatDate(item.ts, 'd LLL HH:mm:ss')

    return (
      <tr>
        <td colSpan={5}>
          <span>{time}</span>
          <span style={{ paddingLeft: 25 }}>{item.user}</span>
        </td>
      </tr>
    )
  }

  private selectTranslations(item: IChangelogItem) {
    if (item._resource === 'ui_dashboard_components') {
      return {
        translations: this.props.intl.messages as Record<string, string>,
        translationsPrefix: 'dashboards.' + item._subresource + '.settings',
      }
    }

    return {
      translations: this.props.translations,
      translationsPrefix: item._resource,
    }
  }

  private handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.setState((state) => ({ showDetails: !state.showDetails }))
  }

  private handleResourceClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    const { item } = this.props
    const { name } = parseName(item)
    this.props.onSelect({ resource: item._resource, resource_id: item.resource_id, name })
  }

  private handleSubresourceClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    const { item } = this.props
    const { subName } = parseName(item)
    this.props.onSelect({
      resource: item._resource,
      subresource: item._subresource,
      resource_id: item.resource_id,
      subresource_id: item.subresource_id,
      name: subName,
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.compactHeader ? this.renderCompactHeader() : this.renderFullHeader()}
        {this.state.showDetails && this.props.item._action !== 'delete' && this.renderDetails()}
      </React.Fragment>
    )
  }
}

const parseName = (item: IChangelogItem) => {
  const { name } = item.details
  if (!name) return { name: '', subName: '' }
  if (!item.subresource) return { name, subName: '' }

  const separator = name.indexOf('\n')
  if (separator === -1) return { name, subName: '' }

  const resourceName = name.slice(0, separator)
  const subName = name.slice(separator + 1)

  return { name: resourceName, subName }
}

interface Props {
  item: IChangelogItem
  showDetails: boolean
  compactHeader?: boolean
  onSelect: (item: IChangelogSelection) => void
  intl: IntlShape
  translations: { [key: string]: string }
}

interface State {
  showDetails: boolean
}

export default ActionsRow
