import { Select } from '@alterouniversal/au-react-components'
import { IIndicatorComponent, IIndicatorSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Form from '../../../shared/Forms/Form'
import PlusButton from '../../../shared/Forms/PlusButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { showError } from '../../../utils/notifications'
import ImageInput from '../../Libraries/Images/ImageInput'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import { selectParameterOptions } from '../../Parameters/params.selectors'
import { checkIfConditionsValid } from './indicator.utils'
import IndicatorCondtionRow from './IndicatorCondtionRow'

/**
 * Модальное кно с настройками индикатора
 */
const IndicatorSettings = (props: Props) => {
  const intl = useIntl()

  const [settings, setSettings] = React.useState(props.component.settings)

  let formattedIntersectedRows
  const intersectedRows = checkIfConditionsValid(settings.conditions)
  if (intersectedRows) {
    formattedIntersectedRows = Object.values(intersectedRows).map((r) => (r = r + 1))
    formattedIntersectedRows[0] > formattedIntersectedRows[1] && formattedIntersectedRows.reverse()
    formattedIntersectedRows = formattedIntersectedRows.join(', ')
  }
  const warning = intersectedRows
    ? intl.formatMessage({ id: 'dashboards.indicator.warning.conditions' }, { rows: formattedIntersectedRows })
    : null

  const parameterOptions = useSelector(selectParameterOptions)
  const parameterOption = parameterOptions.find((o) => o.value === settings.parameter_id)

  const equipmentOptions = useSelector(selectDataSourceOptions)

  const typeOptions = ['circle', 'square', 'image'].map((type) => ({
    value: type,
    title: intl.formatMessage({ id: 'dashboards.indicator.settings.type.' + type }),
  }))

  const isImage = settings.type === 'image'

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleRowRemove = React.useCallback((index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.conditions.splice(index, 1)
      })
    )
  }, [])

  const handleRowChange = React.useCallback((key: string, value: string, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.conditions[index][key] = value
      })
    )
  }, [])

  // добавление новой строки с настройками выводимого текста
  const handleRowAdd = () => {
    setSettings(
      produce(settings, (draft) => {
        draft.conditions.push({
          operator: '>',
          value: 0,
          range: [0, 0],
          action: 'none',
          color: '#ff9857',
        })
      })
    )
  }

  const ignoreDefaultOperator = settings.conditions.find((o) => o.operator === 'default') != null
  const ignoreNoDataOperator = settings.conditions.find((o) => o.operator === 'nodata') != null

  const rows = settings.conditions.map((c, i) => {
    return (
      <IndicatorCondtionRow
        key={i}
        index={i}
        row={c}
        onChange={handleRowChange}
        onRemove={handleRowRemove}
        parameterOption={parameterOption}
        ignoreDefaultOperator={ignoreDefaultOperator}
        ignoreNoDataOperator={ignoreNoDataOperator}
      />
    )
  })

  const handleSave = () => {
    if (!settings.device_id) return showError('dashboards.indicator.errors.device')
    if (!settings.parameter_id) return showError('dashboards.indicator.errors.parameter')
    if (settings.type === 'image' && !settings.image_id) {
      return showError('dashboards.indicator.errors.image_id')
    }

    // выставление границ интервалов в правильном порядке
    const conditions = settings.conditions.map((c) => {
      if (c.operator !== 'in' && c.operator !== 'out') return c

      const range: [number, number] = c.range[0] > c.range[1] ? [c.range[1], c.range[0]] : c.range
      return { ...c, range }
    })

    props.onSave({ ...settings, conditions })
  }

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'common.name' })}>
              <TextInput name="title" required={false} value={settings.title} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.indicator.settings.type' })}>
              <Dropdown name="type" value={settings.type} options={typeOptions} onChange={handleChange} />
            </InputRow>

            {isImage && (
              <InputRow label={intl.formatMessage({ id: 'dashboards.indicator.settings.image_id' })}>
                <ImageInput name="image_id" value={settings.image_id} onChange={handleChange} />
              </InputRow>
            )}

            <InputRow label={intl.formatMessage({ id: 'dashboards.indicator.settings.device_id' })}>
              <Select name="device_id" options={equipmentOptions} value={settings.device_id} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.indicator.settings.parameter_id' })}>
              <Select
                name="parameter_id"
                options={parameterOptions}
                value={settings.parameter_id}
                onChange={handleChange}
              />
            </InputRow>
          </div>

          <table className="nsi-settings-table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.indicator.settings.conditions.operator' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.indicator.settings.conditions.value' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.indicator.settings.conditions.action' })}</th>
                <th style={{ width: '44px' }}>
                  {intl.formatMessage({ id: 'dashboards.indicator.settings.conditions.color' })}
                </th>
                <th style={{ width: '26px' }} />
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <PlusButton textId="common.add" onClick={handleRowAdd} style={{ marginLeft: 0 }} />

          <ModalFooter warning={warning} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: IIndicatorComponent
  onCancel: () => void
  onSave: (settings: IIndicatorSettings) => void
  title: string
}

export default IndicatorSettings
