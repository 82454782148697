import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { loadCache } from '../../redux/redux.utils'
import { ReduxState } from '../../redux/store.types'
import ConfirmModal from '../../shared/Modal/Confirm'
import { applyTitleAndLogo } from '../../utils/html.utils'
import http, { handleHttpError, refreshToken } from '../../utils/http'
import LoadingScreen from '../Auth/LoadingScreen'
import LoginForm from '../Auth/LoginForm'
import PasswordReset1 from '../Auth/PasswordReset1'
import PasswordReset2 from '../Auth/PasswordReset2'
import { selectRegisterOrganizations } from '../Auth/register.api'
import EmailConfirm from '../Auth/Register/EmailConfirm'
import { RegisterForm } from '../Auth/Register/RegisterForm'
import { OrganizationTypeWithInstances } from '../Catalogs/Organizations/organizations.types'
import Dashboard from '../Dashboards/components/Dashboard'
import DashboardPreview from '../Dashboards/components/DashboardPreview'
import LfoPage from '../LFO/LfoPage'
import Notification from '../Notification/Notification'
import OplimPage from '../Oplim/Oplim'
import PqPage from '../PqDiagram/PqDiagram'
import Registrator from '../Registrator/Registrator'
import { SorryPage } from '../SorryPage/SorryPage'
import { systemEnvState, systemPublicState } from '../System/system.state'
import appRoutes from '../TopMenu/Nav/app.routes'
import AppRoutes from '../TopMenu/Nav/Routes'
import TopMenu from '../TopMenu/TopMenu'
import UnbalancePage from '../VectorDiagram/VectorPage'
import { loadResources } from './app.actions'
import './app.styles.css'
import LoginRedirect from './LoginRedirect'
import DeviceScreen from '../MapTopology/DeviceScreen/DeviceScreen'
import UserTasks from '../UserTasks'
import HelpDocsPage from '../TopMenu/HelpDocs/DocsPage'
import AEPSStorageWidget from '../Dashboards/AEPSStorageWidget'
import AEPSCommercialWidget from 'pages/Dashboards/AEPSStorageWidget/AEPSCommercialWidget'

const App = () => {
  const dispatch = useDispatch()
  const setSystemEnv = useSetRecoilState(systemEnvState)
  const [systemInfo, setSystemPublicInfo] = useRecoilState(systemPublicState)
  const [organizationsTypes, setOrganizationsTypes] = useState<OrganizationTypeWithInstances[]>([])

  const user = useSelector((state: ReduxState) => state.user)

  const loginStatus = useSelector((state: ReduxState) => state.auth.status)

  React.useEffect(() => {
    loadCache(dispatch).then(() => refreshToken())

    const title = localStorage.getItem('document.title')
    const logo = localStorage.getItem('document.logo')
    applyTitleAndLogo(title, logo)

    selectRegisterOrganizations().then((r) => setOrganizationsTypes(r))

    http
      .get('/nsi/auth/system_info')
      .then((r) => {
        const info = r.data
        setSystemPublicInfo(info)

        const { title, logo } = info
        applyTitleAndLogo(title, logo)
        if (title) localStorage.setItem('document.title', title)
        if (logo) localStorage.setItem('document.logo', logo)
      })
      .catch((err) => {
        console.error(err)
        setSystemPublicInfo({ title: '', logo: '', pdp_agreement_id: '' })
      })
  }, [])

  React.useEffect(() => {
    if (loginStatus === 'logged_in') {
      dispatch(loadResources())
      http
        .get('/nsi/v1/system/env')
        .then((r) => setSystemEnv(r.data))
        .catch(handleHttpError)
    }
  }, [loginStatus])

  if (!systemInfo || loginStatus === 'unknown' || (loginStatus === 'logged_in' && user.id === 0)) {
    return <LoadingScreen />
  }

  const allowRegister = organizationsTypes.length > 0
  const signupPage = allowRegister ? <RegisterForm organizationsTypes={organizationsTypes} /> : <Navigate to="/login" />
  const signupConfirmPage = allowRegister ? <EmailConfirm /> : <Navigate to="/login" />

  switch (loginStatus) {
    case 'logged_in':
      return (
        <>
          <TopMenu />
          <AppRoutes routes={appRoutes} fallback="nsi nsi-journal">
            <Route path="/dashboards/pq" element={<PqPage />} />
            <Route path="/dashboards/lfo" element={<LfoPage />} />
            <Route path="/dashboards/unbalance" element={<UnbalancePage />} />
            <Route path="/dashboards/oplim" element={<OplimPage />} />
            <Route path="/dashboards/aepso" element={<AEPSStorageWidget />} />
            <Route path="/dashboards/aepsc" element={<AEPSCommercialWidget />} />
            <Route path="/dashboards/alerts" element={<PqPage />} />
            <Route path="/dashboards/incidents/:id" element={<DashboardPreview />} />
            <Route path="/dashboards/:id" element={<Dashboard />} />
            <Route path="/map/device/:deviceId/*" element={<DeviceScreen />} />
            <Route path="/registrators/:id/:page" element={<Registrator />} />
            <Route path="/docs/*" element={<HelpDocsPage />} />
          </AppRoutes>

          <UserTasks />
          <Notification />
          <ConfirmModal />
        </>
      )
    case 'logged_out':
      return (
        <Routes>
          <Route path="/login/recover" element={<PasswordReset1 />} />
          <Route path="/login/reset" element={<PasswordReset2 />} />
          <Route path="/login" element={<LoginForm allowRegister={allowRegister} />} />
          <Route path="/signup" element={signupPage} />
          <Route path="/signup/confirm" element={signupConfirmPage} />

          <Route path="*" element={<LoginRedirect />} />
        </Routes>
      )
    case 'server_error':
      return <SorryPage />
  }
}

export default App
