import { ModerationState } from './moderation.reducer'
import { IModerationRequest } from 'au-nsi/moderation'
import { SelectOption } from '@alterouniversal/au-react-components'

/**
 * Разрешено ли пользователю модерирование заявки
 */
export const allowModeration = (userId: number, userRights: Record<string, boolean>, request: IModerationRequest) => {
  return userRights[request.moderation_right] || request.assigned_to.includes(userId)
}

/**
  Список ресурсов, подлежащих модерации
*/
export const moderatelyResources: SelectOption[] = [
  { label: 'user.request.resource.organizations_types', value: 'organizations_types' },
  { label: 'user.request.resource.organizations', value: 'organizations' },
  { label: 'user.request.resource.catalogs', value: 'catalogs' },
  { label: 'user.request.resource.equipment', value: 'equipment' },
  { label: 'user.request.resource.equipment_logbook', value: 'equipment_logbook' },
  { label: 'user.request.resource.catalog_items', value: 'catalog_items' },
  { label: 'user.request.resource.users', value: 'users' },
  { label: 'user.request.resource.documents', value: 'documents' },
]

/**
 Достать INTEGER id ресурса из url запроса (для PATCH и DELETE запросов, заканчивающихся на /:id)
 */
export const extractIntIdFromUrl = (url: string) => {
  const split = url.split('/')
  return parseInt(split[split.length - 1])
}

/**
 Достать STRING id ресурса из url запроса (для PATCH и DELETE запросов, заканчивающихся на /:id)
 */
export const extractStringIdFromUrl = (url: string) => {
  const split = url.split('/')
  return split[split.length - 1]
}

/**
 Достать все параметры с ключом ids из query запроса
 */
export const extractIdsFromUrl = (url: string) => {
  const query = url.split('?')[1]
  if (!query) return []

  const ids = []
  const queryParams = query.split('&').map((keyValue) => keyValue.split('='))
  for (const keyValue of queryParams) {
    if (keyValue[0] === 'ids') ids.push(keyValue[1])
  }

  return ids
}

/**
 * Проверить, соответсвует ли заявка фильтрам
 * */
export const isFitIntoFilters = (request: IModerationRequest, filters: ModerationState['filters']) => {
  if (filters.showOnlyActive && !request.moderated_at) return false
  return filters.resources.size ? filters.resources.has(request.resource) : true
}

/**
 * Отфильтровать {requests} слогласно {filters}
 * */
export const filterRequests = (requests: IModerationRequest[], filters: ModerationState['filters']) => {
  return requests.filter((r) => isFitIntoFilters(r, filters))
}
