import { useEffect, useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { defaultPasswordRules, PasswordRules } from '../../../hooks/usePasswordRules'
import { ReduxState } from '../../../redux/store.types'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import http, { handleHttpError } from '../../../utils/http'
import { isJsonEqual } from '../../../utils/misc'
import actions from '../Settings/settings.actions'

/**
 * Форма настроек сложности паролей
 */
const PasswordsSettings = ({ allowEditing }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const settings: NsiSettings = useSelector((state: ReduxState) => state.service_settings['au-nsi'])
  const translations = useSelector((state: ReduxState) => state.language.translations)

  const [draft, setDraft] = useState(settings ? settings.passwords : defaultSettings.passwords)

  useEffect(() => {
    dispatch(actions.loadSettings('au-nsi', defaultSettings))
  }, [])

  useLayoutEffect(() => {
    if (settings) setDraft(settings.passwords)
  }, [settings])

  const handleChange = (value: any, key: string) => {
    setDraft((prev) => ({ ...prev, [key]: value }))
  }

  const isChanged = settings && !isJsonEqual(settings.passwords, draft)

  const handleCancel = () => setDraft(settings.passwords)

  const handleSave = () => {
    const result = { ...settings, passwords: draft }
    dispatch(actions.set({ name: 'au-nsi', settings: result }))

    http.post('/nsi/v1/ms/settings/au-nsi', result).catch(handleHttpError)
  }

  const toggles = ['require_lowercase', 'require_uppercase', 'require_digits', 'require_symbols'].map((id) => {
    return (
      <ToggleWithLabel
        key={id}
        name={id}
        label={translations['settings_nsi.passwords.' + id]}
        value={draft[id]}
        onChange={handleChange}
        fontSize="12px"
        style={{ marginBottom: 6, paddingLeft: '.5em' }}
      />
    )
  })

  return (
    <>
      <div className="system__grid" style={{ marginBottom: 0 }}>
        <InputRow label={translations['settings_nsi.passwords.min_length']}>
          <NumberInput
            name="min_length"
            type="number"
            value={draft.min_length}
            integer={true}
            fullWidth={true}
            min={1}
            onChange={handleChange}
            disabled={!allowEditing}
          />
        </InputRow>
      </div>
      <InputRow label={intl.formatMessage({ id: 'system.settings.passwords.must_include' })}>{toggles}</InputRow>

      {isChanged && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="nsi-button inverted" onClick={handleCancel}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </button>
          <button className="nsi-button default" onClick={handleSave}>
            {intl.formatMessage({ id: 'common.save' })}
          </button>
        </div>
      )}
    </>
  )
}

interface NsiSettings {
  passwords: PasswordRules
}

const defaultSettings: NsiSettings = {
  passwords: defaultPasswordRules,
}

interface Props {
  allowEditing: boolean
}

export default PasswordsSettings
