import ws from '../ws/ws'
import Clock from './clock.service'

/**
 * В большинстве случаев используется один глобальный clock для синхронизации
 * плеера и всех графиков. В редких случаях может быть необходимость, чтобы у
 * каких то компонентов время отличалось от времени основного плеера, тогда
 * необходимо создание нового отдельного инстанса через фабрику createClock.
 */
const globalClock = new Clock(ws)

export const createClock = () => new Clock(ws)
export default globalClock
