import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { API_URL } from '../../../shared/constants'
import Loader from '../../../shared/Utils/Loader'
import { getResponseErrorMessage } from '../auth.utils'
import { AuthWrapper } from '../AuthWrapper'

/**
 * Страница на которую попадает пользователь при клике на ссылку в письме для подтверждения регистрации
 */
const EmailConfirm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState<string>()
  const [isLoading, setLoading] = useState(true)

  const search = new URLSearchParams(location.search)
  const code = search.get('code')
  const username = search.get('username')

  useEffect(() => {
    if (!code || !username) {
      return navigate('/login')
    }

    axios
      .post(API_URL + '/nsi/auth/register/confirm', { code, username })
      .catch((err) => {
        setError(
          err.response && err.response.status === 404
            ? intl.formatMessage({ id: 'auth.signup.confirm.error' })
            : getResponseErrorMessage(err, intl)
        )
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <AuthWrapper title={intl.formatMessage({ id: 'auth.signup' })} showSignin={true}>
      <div style={{ textAlign: 'center', margin: '20px' }}>
        {isLoading && <Loader />}
        <span className="text--danger">{error}</span>
        <span className="text--success">
          {!isLoading && !error && intl.formatMessage({ id: 'auth.signup.confirm.success' })}
        </span>
      </div>
    </AuthWrapper>
  )
}

export default EmailConfirm
