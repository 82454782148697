import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as utils from '../../../redux/reducer.utils'
import { Image } from 'au-nsi/images'

export interface ImagesState {
  items: Image[]
  isLoaded: boolean
}

const initialState: ImagesState = {
  items: [],
  isLoaded: false,
}

const slice = createSlice({
  name: 'app/images',
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<Image[]>) => {
      state.items = action.payload
      state.isLoaded = true
    },
    imageCreated: (state, action: PayloadAction<Image>) => {
      if (state.isLoaded) {
        utils.appendItem(state.items, action.payload)
      }
    },
    imageUpdated: (state, action: PayloadAction<Partial<Image>>) => {
      utils.updateItem(state.items, action.payload)
    },
    imagesUpdated: (state, action: PayloadAction<Array<Partial<Image>>>) => {
      utils.updateItems(state.items, action.payload)
    },
    imageDeleted: (state, action: PayloadAction<string>) => {
      utils.deleteItem(state.items, action.payload)
    },
  },
})

export const actions = slice.actions

export default slice.reducer
