/**
 * Набор утилит для работы с canvas
 */

/**
 * Отрисовка индикатора загрузки в правом верхнем углу канваса
 */
export const drawLoader = (ctx: CanvasRenderingContext2D, width: number) => {
  const ts = Date.now()
  const radius = 6
  const padding = 8
  const size = 2 * (radius + padding)

  const period = 1000
  const angle1 = ((ts % period) / period) * Math.PI * 2
  const angle2 = angle1 + Math.PI * 1.5

  ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)'
  ctx.lineWidth = 2

  ctx.clearRect(width - size, 0, size, size)
  ctx.beginPath()
  ctx.arc(width - radius - padding, radius + padding, radius, angle1, angle2)
  ctx.stroke()
}

/**
 * отрисовка сплошной линией
 */
export const drawLine = (options: IDrawLineOptions) => {
  const { ctx, chunks, xScale, yScale } = options
  const drawSteps = options.mode === 'step'

  // максимальное расстояние между двумя точками которое соединять линией
  const DELTA = options.disconnectDistance || null

  let isStart = true
  let vx = 0
  let vy = 0
  let px = 0
  let py = 0

  ctx.beginPath()

  for (const { i0, i1, x, y } of chunks) {
    if (i0 === -1 || i1 === -1 || i0 > i1 || !x || !y) continue

    for (let i = i0; i <= i1; i++) {
      vx = x[i]
      vy = y[i]

      if (vx == null || vy == null) {
        isStart = true
        continue
      }

      if (DELTA !== null && i !== i0 && vx - x[i - 1] > DELTA) {
        isStart = true
        continue
      }

      if (isStart) {
        px = xScale(vx)
        py = yScale(vy)
        ctx.moveTo(px, py)
        isStart = false
        continue
      }

      px = xScale(vx)

      if (drawSteps) {
        ctx.lineTo(px, py)
      }

      py = yScale(vy)
      ctx.lineTo(px, py)
    }
  }

  ctx.stroke()
}

/**
 * отрисовка столбцами
 */
export const drawBars = (options: IDrawBarsOptions, fill: boolean) => {
  const { ctx, chunks, xScale, yScale } = options
  const base = yScale(options.base)

  for (const { i0, i1, x, y } of chunks) {
    if (i0 === -1 || i1 === -1 || i0 > i1 || !x || !y) continue

    for (let i = i0; i <= i1; i++) {
      const vx = x[i]
      const vy = y[i]

      if (vx == null || vy == null) {
        continue
      }

      const px = xScale(vx)
      const py = yScale(vy)

      const y0 = Math.min(py, base)
      const h = Math.max(py, base) - y0

      // контур столбца
      ctx.strokeRect(px, y0, options.width, h)

      // заливка внутренней области
      if (fill) {
        ctx.clearRect(px + 1, y0 + 1, options.width - 2, h - 2)
        ctx.fillRect(px + 1, y0 + 1, options.width - 2, h - 2)
      }
    }
  }
}

const PI2 = 2 * Math.PI

/**
 * отрисовка отдельными точками
 */
export const drawDots = (options: IDrawOptions) => {
  const { ctx, chunks, xScale, yScale } = options
  let vx = 0
  let vy = 0

  for (const { i0, i1, x, y } of chunks) {
    if (i0 === -1 || i1 === -1 || i0 > i1 || !x || !y) continue

    for (let i = i0; i <= i1; i++) {
      vx = x[i]
      vy = y[i]

      if (vx != null && vy != null) {
        ctx.beginPath()
        ctx.arc(xScale(vx), yScale(vy), 4, 0, PI2, false)
        ctx.fill()
      }
    }
  }
}

/**
 * заливка области под графиком
 */
export const fillLine = (options: IFillLineOptions) => {
  const { ctx, chunks, xScale, yScale } = options
  const base = yScale(options.base)
  const DELTA = options.disconnectDistance || null
  const drawSteps = options.mode === 'step'

  let isStart = true
  let vx = 0
  let vy = 0
  let px = 0
  let py = 0

  ctx.beginPath()
  ctx.moveTo(0, base)

  for (const { i0, i1, x, y } of chunks) {
    if (i0 === -1 || i1 === -1 || i0 > i1 || !x || !y) continue

    for (let i = i0; i <= i1; i++) {
      vx = x[i]
      vy = y[i]

      if (vx == null || vy == null) {
        isStart = true
        continue
      }

      if (DELTA !== null && i !== i0 && vx - x[i - 1] > DELTA) {
        isStart = true
        continue
      }

      if (isStart) {
        ctx.lineTo(px, base)
        px = xScale(vx)
        ctx.lineTo(px, base)
        isStart = false
      }

      px = xScale(vx)

      if (drawSteps) {
        ctx.lineTo(px, py)
      }

      py = yScale(vy)
      ctx.lineTo(px, py)
    }
  }

  ctx.lineTo(px, base)
  ctx.closePath()
  ctx.fill()
}

interface IDrawOptions {
  chunks: DataChunk[]
  ctx: CanvasRenderingContext2D
  xScale: Scale
  yScale: Scale
}

interface IDrawLineOptions extends IDrawOptions {
  disconnectDistance?: number
  mode?: 'line' | 'step'
}

interface IFillLineOptions extends IDrawOptions {
  disconnectDistance?: number
  mode?: 'line' | 'step'
  base: number
}

interface IDrawBarsOptions extends IDrawOptions {
  base: number
  width: number
}

type Scale = (v: number) => number

export interface DataChunk {
  i0: number
  i1: number
  x: number[]
  y: number[]
}
