import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { IDataIntervals } from '../../services/data/data.types'
import { array2map } from '../../utils/misc'
import { selectParametersNames } from '../Parameters/params.selectors'
import { DeviceParameters, IDataSource } from './nsi.interfaces'
import { Equipment } from 'au-nsi/equipment'

const equipmentSelector = (state: ReduxState) => state.nsi.equipment
const equipmentAllSelector = (state: ReduxState) => state.nsi.equipmentAll
const deviceIdSelector = (state: ReduxState) => state.nsi.selectedDeviceId
const regionsSelector = (state: ReduxState) => state.nsi.regions
const regionIdSelector = (state: ReduxState) => state.nsi.selectedRegionId

/**
 * Найти устройство по выбранному id
 */
export const selectDevice = createSelector(
  equipmentAllSelector,
  deviceIdSelector,
  (equipment: Equipment[], deviceId: string) => {
    return deviceId ? equipment.find((e) => e.id === deviceId) : null
  }
)

/**
 * Выбрать устройства передающие данные (а не являющиеся просто контейнерами для других устройств)
 */
export const selectReceivers = createSelector(equipmentSelector, (equipment) => {
  return equipment.filter((e) => e.type !== 'aggregator')
})

/**
 * Выбрать идентификаторы устройств, которые являются составными (содержат дочерние виртуальные устройства)
 */
export const selectCompositeDevices = createSelector(equipmentAllSelector, (equipment) => {
  const result = new Set<string>()

  for (const e of equipment) {
    if (e.path) {
      for (const parent of e.path.split('.')) {
        result.add(parent)
      }
    }
  }

  return result
})

export const selectEquipmentNames = createSelector(equipmentSelector, (equipment) => {
  const names = new Set<string>()
  const shortnames = new Set<string>()

  for (const { name, shortname } of equipment) {
    names.add(name)
    shortnames.add(shortname)
  }

  return { names, shortnames }
})

export const selectRegion = createSelector(regionsSelector, regionIdSelector, (regions, selectedId) => {
  return selectedId ? regions.find((r) => r.id === selectedId) : null
})

/**
 * Из массива регионов сформировать Map для быстрого поиска по id
 */
export const selectRegionsMap = createSelector(regionsSelector, array2map)

/**
 * Из массива оборудования сформировать Map для быстрого поиска по id
 */
export const selectEquipmentMap = createSelector(equipmentAllSelector, array2map)

/**
 * Определить положение устройства в дереве регионов
 * device.region_id -> ['parent1', 'parent2', 'node']
 */
export const selectPhysicalPath = createSelector([selectDevice, selectRegionsMap], (device, regions) => {
  if (!device || !device.region_id) return []

  const region = regions.get(device.region_id)
  if (!region) return []

  return region.path.split('.').map((id) => regions.get(+id).name)
})

export const selectEquipmentPaths = createSelector(equipmentAllSelector, selectRegionsMap, (equipment, regions) => {
  const result = new Map<string, string>()

  for (const e of equipment) {
    const region = regions.get(e.region_id)
    const path = region.path.split('.').map((id) => +id)
    path.shift()

    const location = path.map((id) => regions.get(id).name).join(' / ')
    result.set(e.id, location)
  }

  return result
})

/**
 * Определить положение устройства в дереве других устройств
 * device.path -> ['receiver1']
 */
export const selectLogicalPath = createSelector([selectDevice, equipmentAllSelector], (device, equipment) => {
  if (!device || !device.path) return []

  return device.path.split('.').map((id) => {
    const parent = equipment.find((e) => e.id === id)
    return parent ? parent.name : ''
  })
})

/**
 * Выбрать список регионов в которые можно переместить выбранный регион,
 * т.е. все регионы за исключением выбранного и его детей
 */
export const selectTargetRegions = createSelector(regionsSelector, regionIdSelector, (regions, selectedId) => {
  if (!selectedId) return regions
  const sid = selectedId.toString()

  return regions.filter((r) => {
    const path = r.path.split('.')
    return !path.some((id) => id === sid)
  })
})

/**
 * Определение состояния региона - отражение состояния находящихся в нем устройств
 */
export const selectRegionsState = createSelector(equipmentSelector, selectRegionsMap, (devices, regions) => {
  // id региона -> состояние региона
  // состояние может быть равно 0 (по умолчанию), 1 (содержит запущенные устройства), 2 (предупреждение) или 3 (ошибка)
  const result = new Map<number, number>()

  // проходим по каждому устройству и в зависимости от его состояния
  // задаем состояние всех его родительских регионов
  for (const device of devices) {
    let deviceState = 0
    if (device.state === 'ERROR') deviceState = 3
    else if (device.state === 'WARNING') deviceState = 2
    else if (device.state === 'RUNNING') deviceState = 1

    const region = regions.get(device.region_id)

    if (!region || !deviceState) continue

    const parents = region.path.split('.')

    for (const parent of parents) {
      const id = +parent

      const prevState = result.get(id) || 0
      result.set(id, Math.max(prevState, deviceState))
    }
  }

  return result
})

/**
 * Выбрать маппинг id устройства на интервал между измерениями, в мс (обратная величина от частоты передачи данных)
 */
export const selectDataIntervalsMap = createSelector(
  equipmentSelector,
  regionsSelector,
  (state: ReduxState) => state.data_rates.data_rates,
  (equipment, regions, dataRates) => {
    const result = new Map<string, IDataIntervals>()

    // виртуальные modbus устройства могут присылать данные с id корневого устройства но своим data rate,
    // поэтому для нормального отображения виджетов необходимо внести информацию о частоте приема всех
    // параметров таких виртуальных устройств в данные о корневом устройстве
    const complexDevices = new Set<string>()

    // data rate по каждому устройству
    for (const device of equipment) {
      const interval = device.data_rate ? 1000 / device.data_rate : 0
      result.set(device.id, { default: interval, parameters: new Map() })

      const isComplexDevice =
        device.protocol === 'modbustcp-client' && device.configuration.publish_with_root_id && !device.path
      if (isComplexDevice) complexDevices.add(device.id)
    }

    // некоторые данные могут записываться с id регионов, поэтому для них тоже нужно знать data rate
    for (const region of regions) {
      const interval = region.data_rate ? 1000 / region.data_rate : 0
      result.set(region.id.toString(), { default: interval, parameters: new Map() })
    }

    // исключения для отдельных параметров, которые приходят от устройств с другим data rate
    for (const { device_id, parameter_id, data_rate } of dataRates) {
      const interval = data_rate ? 1000 / data_rate : 0
      const record = result.get(device_id)

      if (record && interval) record.parameters.set(parameter_id, interval)
    }

    // см. комментарий перед определением complexDevices
    if (complexDevices.size > 0) {
      for (const device of equipment) {
        const parent_id = device.path
        if (!parent_id || !complexDevices.has(parent_id)) continue

        const interval = device.data_rate ? 1000 / device.data_rate : 0
        const parent = result.get(parent_id)
        if (!parent || !interval) continue

        for (const { parameter_id } of device.parameters_mapping) {
          parent.parameters.set(parameter_id, interval)
        }
      }
    }

    return result
  }
)

/**
 * Выборка параметров устройств отсортированных по названию
 */
const rawSelector = (state: ReduxState) => state.nsi.rawDeviceParameters
const processedSelector = (state: ReduxState) => state.nsi.processedDeviceParameters

const sortParameters = (parameters: DeviceParameters, names: Map<string, string>) => {
  const result: { [device_id: string]: string[] } = {}

  for (const [device, ids] of Object.entries(parameters)) {
    result[device] = [...ids]

    result[device].sort((a, b) => {
      const nameA = names.get(a)
      const nameB = names.get(b)

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    })
  }

  return result
}

export const selectRawParameters = createSelector(rawSelector, selectParametersNames, sortParameters)
export const selectProcessedParameters = createSelector(processedSelector, selectParametersNames, sortParameters)

/**
 * Выбор объектов топологии которые являются источниками данных - устройства + регионы
 */
export const selectDataSources = createSelector(selectReceivers, regionsSelector, (equipment, regions) => {
  const result: IDataSource[] = []

  for (const device of equipment) {
    result.push({
      id: device.id,
      name: device.name,
      shortname: device.shortname,
      color: device.color,
      group: 'equipment',
      address: device.address,
    })
  }

  for (const region of regions) {
    result.push({
      id: region.id.toString(),
      name: region.name,
      shortname: region.name,
      color: '#ff9857',
      group: 'regions',
    })
  }

  return result
})

/**
 * Сформировать опции из оборудования для использования в Select
 */
export const selectDataSourceOptions = createSelector(selectDataSources, (equipment) => {
  return equipment.map((e) => ({ value: e.id, label: e.name }))
})

export const selectEquipmentOptions = createSelector(equipmentSelector, (equipment) => {
  return equipment.map((e) => ({ value: e.id, label: e.name }))
})
