import { IHistogramSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import useCanvasScale from '../../../hooks/useCanvasScale'
import Loader from '../../../shared/Utils/Loader'
import { getNumberPrecision } from '../../../utils/format'
import http, { handleHttpError } from '../../../utils/http'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { convertParameter, formatNumber, formatValue } from '../../Parameters/params.utils'
import css from './histogram.module.css'
import { constructQuery, HistogramData, makeColors, transform } from './histogram.utils'

const Histogram = (props: Props) => {
  const { settings } = props
  const margins = { top: 38, right: 0, bottom: 16, left: 0 }
  const parameters = useSelector(selectDenormalizedParametersMap)

  const container = React.useRef<HTMLDivElement>()
  const canvasRef = React.useRef<HTMLCanvasElement>()

  const [size, setSize] = React.useState<DOMRect>()
  const [loading, setLoading] = React.useState(false)
  const [selectedBar, setSelectedBar] = React.useState<number>()

  const [data, setData] = React.useState<HistogramData>({
    values: [],
    counts: [],
    minValue: 0,
    maxValue: 0,
    maxCount: 0,
    totalCount: 0,
  })

  const innerWidth = size ? size.width - margins.left - margins.right : 0
  const innerHeight = size ? size.height - margins.top - margins.bottom : 0
  const barSize = innerWidth / data.values.length

  const width = size ? size.width : undefined
  const height = size ? size.height : undefined
  useCanvasScale(canvasRef, width, height)

  function callApi(query) {
    if (query == null) return Promise.resolve(null)

    setLoading(true)
    const options = { parameter: query.groups[0].parameter, min: settings.min, max: settings.max }

    return http
      .post('/back/v1/tsdb/query', query)
      .then((r) => transform(r.data, options))
      .catch(handleHttpError)
      .finally(() => setLoading(false))
  }

  function drawChart() {
    if (!size) return

    const ctx = canvasRef.current.getContext('2d')
    ctx.clearRect(0, 0, size.width, size.height)
    ctx.font = '12px Roboto'

    const colors = makeColors(settings.parameters[0].color)
    const parameter = parameters.get(settings.parameters[0].parameter_id)

    // точность с которой выводить подписи в зависимости от ширины шага
    const step = (data.maxValue - data.minValue) / data.values.length
    const precision = getNumberPrecision(convertParameter(step, parameter))

    // расстояние между столбцами добавляем только при широких столбцах
    const padding = barSize < 4 ? 0 : 1

    for (let i = 0; i < data.values.length; i++) {
      const value = data.values[i]
      const count = data.counts[i]

      const isSelected = i === selectedBar
      const color = isSelected ? colors.selected : colors.normal
      ctx.fillStyle = color

      const height = (innerHeight * count) / data.maxCount
      const x = margins.left + i * barSize
      const y = size.height - margins.bottom - height
      ctx.fillRect(x + padding, y, barSize - 2 * padding, height)

      ctx.fillStyle = '#FFF'
      ctx.textAlign = x < 40 ? 'start' : x > innerWidth - 40 ? 'end' : 'center'

      // tooltip with percent
      if (isSelected) {
        const percent = formatNumber((100 * count) / data.totalCount) + ' %'
        ctx.fillText(percent, x + barSize / 2, y - 2)
      }

      // value subscription
      if (isSelected || (!selectedBar && i === 0) || (!selectedBar && i === data.values.length - 1)) {
        const text = formatValue(value, parameter, true, precision)
        ctx.fillText(text, x + barSize / 2, size.height - 3)
      }
    }
  }

  function handleMouseMove(e: React.MouseEvent) {
    const x = e.pageX - size.left
    const y = e.pageY - size.top

    const index = Math.floor(x / barSize)
    const count = data.counts[index] || 0
    const height = (innerHeight * count) / data.maxCount
    const selectedIndex = y + 60 >= size.height - height - margins.bottom ? index : null

    if (selectedIndex !== selectedBar) {
      setSelectedBar(selectedIndex)
    }
  }

  // выставление размеров canvas равными размерам контейнера
  React.useEffect(() => {
    const rect = container.current.getBoundingClientRect()
    rect.width = Math.floor(rect.width)
    rect.height = Math.floor(rect.height)
    setSize(rect)
  }, [props.lastResize])

  // запрос на сервер, обработка данных
  React.useEffect(() => {
    let timer = null
    let canceled = false

    const request = () => {
      const refreshTime = settings.parameters[0].refresh_interval
      const query = constructQuery({ settings, parameters, size })

      callApi(query).then((r) => {
        if (r && !canceled) setData(r)
        if (refreshTime && !canceled) timer = setTimeout(request, refreshTime)
      })
    }

    request()

    return () => {
      canceled = true
      clearTimeout(timer)
    }
  }, [settings, size && size.width])

  // отрисовка в canvas
  React.useEffect(() => {
    drawChart()
  }, [props.settings, data, size, selectedBar])

  return (
    <div ref={container} className={css.container}>
      <h2 className={'line_clamp'} title={settings.title}>
        {settings.title}
      </h2>
      <canvas
        ref={canvasRef}
        className={css.canvas}
        onMouseMove={handleMouseMove}
        onMouseOut={() => setSelectedBar(null)}
        width={size ? size.width : 0}
        height={size ? size.height : 0}
      />
      {loading && <Loader style={{ position: 'absolute', top: 8, right: 8, opacity: 0.5 }} />}
    </div>
  )
}

interface Props {
  id: number
  lastResize: number
  settings: IHistogramSettings
}

export default Histogram
