import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import ChartsList from '../../shared/LineCharts/ChartsList'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import { Equipment } from 'au-nsi/equipment'
import LfoChartsHeader from './LfoChartsHeader'

const ID = RESERVED_CHART_IDS.LFO

const panels: Array<[number, string, string]> = [
  [ID, 'lfo.charts.amplitudeF', 'FrequencyLfoMagnitude'],
  [ID + 1, 'lfo.charts.amplitudeP', 'PowerActiveTriPhaseLfoMagnitude'],
  [ID + 2, 'lfo.charts.dampingFactorF', 'FrequencyLfoDampingFactor'],
  [ID + 3, 'lfo.charts.dampingFactorP', 'PowerActiveTriPhaseLfoDampingFactor'],
  [ID + 4, 'lfo.charts.frequencyF', 'FrequencyLfoFrequency'],
  [ID + 5, 'lfo.charts.frequencyP', 'PowerActiveTriPhaseLfoFrequency'],
]

const LfoCharts = (props: Props) => {
  const intl = useIntl()

  const selectedCharts = React.useMemo(() => {
    const device = props.selectedRegistrators[0]

    const generateSettings = (title: string, parameter_id: string) => {
      const lines = device ? [{ device_id: device.id, parameter_id, color: device.color }] : []
      return { title: intl.formatMessage({ id: title }), lines }
    }

    return panels
      .filter((row) => {
        const id = row[1].split('.')[2]
        return props.charts[id]
      })
      .map((row) => {
        return { id: row[0], settings: generateSettings(row[1], row[2]) }
      })
  }, [props.selectedRegistrators, props.charts])

  return (
    <div className="pq-chart-container">
      <LfoChartsHeader />
      <div className="pq-trends-container">
        <ChartsList showAsGrid={props.showAsGrid} charts={selectedCharts} />
      </div>
    </div>
  )
}

interface Props {
  charts: { [key: string]: boolean }
  registrators: Equipment[]
  selectedRegistrators: Equipment[]
  showAsGrid: boolean
}

function mapStateToProps(state: ReduxState) {
  return {
    registrators: state.nsi.equipment,
    charts: state.lfo.visibleCharts,
    showAsGrid: state.lfo.showAsGrid,
  }
}

export default connect(mapStateToProps)(LfoCharts)
