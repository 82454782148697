import { generateReducers } from '../Collections/collection.reducers'
import { ParameterGroupsState } from './params.interfaces'
import { ParameterGroup } from 'au-nsi/parameters'

const defaultState: ParameterGroupsState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
}

const newGroup: ParameterGroup = {
  id: 0,
  name: '',
  path: '',
}

const reducers = generateReducers<ParameterGroup>('parameter_groups', newGroup, true)

const groupsReducers = (state: ParameterGroupsState = defaultState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default groupsReducers
