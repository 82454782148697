import { Select } from '@alterouniversal/au-react-components'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PlusButton from '../../../shared/Forms/PlusButton'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { ToleranceSettings } from './vp.interfaces'

const TolerancesTable = (props: Props) => {
  const [showNewRow, setShowNewRow] = useState(false)

  const { editing } = props

  // prepare options to show in dropdown for adding new parameter
  // and filter parameters that are already added
  const options = useMemo(() => {
    return props.parameters
      .filter((p) => props.rows.find((r) => r.parameter.id === p.id) == null)
      .map((p) => ({ value: p.id, label: p.name }))
  }, [props.parameters, props.rows.length])

  // add new row with selected parameter
  const addParameter = (id: string) => {
    const parameter = props.parameters.find((p) => p.id === id)

    const row = { parameter, lower_limit: 0, upper_limit: 0 }
    props.onChange([...props.rows, row])
    setShowNewRow(false)
  }

  // user clicks 'X' to remove row
  const removeParameter = (e) => {
    const { id } = e.target.dataset
    const result = props.rows.filter((r) => r.parameter.id !== id)
    props.onChange(result)
  }

  const removeNewRow = useCallback(() => setShowNewRow(false), [])

  // user types into input and updates upper or lower limits
  const handleChange = (value: number, name: string) => {
    const [key, id] = name.split(':')
    const row = props.rows.find((r) => r.parameter.id === id)
    const factor = row.parameter.display_prefix ? Math.pow(10, row.parameter.display_prefix.exponent) : 1

    row[key] = value * factor
    props.onChange(props.rows)
  }

  // render table rows
  const rows = props.rows.map((r) => {
    const factor = r.parameter.display_prefix ? Math.pow(10, r.parameter.display_prefix.exponent) : 1
    const lower_limit = r.lower_limit / factor
    const upper_limit = r.upper_limit / factor

    const input1 = <NumberInput name={'lower_limit:' + r.parameter.id} value={lower_limit} onChange={handleChange} />
    const input2 = <NumberInput name={'upper_limit:' + r.parameter.id} value={upper_limit} onChange={handleChange} />

    // render inputs if user edits the table, otherwise display just values
    return (
      <tr key={r.parameter.id}>
        <td className="text--nowrap">
          {r.parameter.name}
          ,&nbsp;
          {r.parameter.display_prefix && r.parameter.display_prefix.symbol}
          {r.parameter.display_unit.symbol}
        </td>
        <td>{editing ? input1 : lower_limit}</td>
        <td>{editing ? input2 : upper_limit}</td>
        {editing && (
          <td>
            <RemoveButton id={r.parameter.id} onRemove={removeParameter} />
          </td>
        )}
      </tr>
    )
  })

  // render button allowing adding new row to the table
  const footer = <PlusButton textId="common.add_parameter" onClick={() => setShowNewRow(true)} />

  const showFooter = editing && !showNewRow && options.length !== 0

  const newRow = (
    <tr key="0">
      <td>
        <Select name="" options={options} value={null} onChange={addParameter} autoFocus={true} />
      </td>
      <td />
      <td />
      <td>
        <RemoveButton onRemove={removeNewRow} />
      </td>
    </tr>
  )

  return (
    <React.Fragment>
      <table className="nsi-table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="nsi.data_verification.param" />
            </th>
            <th>
              <FormattedMessage id="nsi.data_verification.lower_limit" />
            </th>
            <th>
              <FormattedMessage id="nsi.data_verification.upper_limit" />
            </th>
            {editing && <th />}
          </tr>
        </thead>
        <tbody>
          {rows}
          {editing && showNewRow && newRow}
        </tbody>
      </table>
      {showFooter && footer}
    </React.Fragment>
  )
}

interface Props {
  rows: ToleranceSettings[]
  parameters: ParameterDn[]
  editing: boolean
  onChange: (rows: ToleranceSettings[]) => void
}

export default TolerancesTable
