import { Equipment } from 'au-nsi/equipment'
import produce from 'immer'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import Dropdown from '../../../shared/Inputs/Dropdown'
import http from '../../../utils/http'
import { selectParametersNames } from '../../Parameters/params.selectors'
import ImportModal from '../Modals/ImportModal'
import { patchEquipment } from '../nsi.actions'
import { constructOptions, equipmentSelector } from './cp.utils'

interface Props {
  device: Equipment
  allowEditing: boolean
}

/**
 * Компонет для настройки рассчитываемых параметров
 */
const ComputableParameters = ({ device, allowEditing }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const equipment = useSelector(equipmentSelector)
  const parameters = useSelector(selectParametersNames)

  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)
  const [draft, setDraft] = useState(device.data_quality_options.computable_parameters || [])

  useLayoutEffect(() => handleCancel(), [device.id])

  useEffect(() => {
    if (!editing) handleCancel()
  }, [device.data_quality_options.computable_parameters])

  // список параметров необходимых для расчета параметра с parameter_id
  const [rules, setRules] = useState<{ [parameter_id: string]: string[] }>({})

  useEffect(() => {
    const cache = sessionStorage.getItem('computed_parameters_rules')

    if (cache != null) setRules(JSON.parse(cache))
    else loadComputingRules()
  }, [])

  /**
   * Загрузка информации о зависимости параметров между собой, преобразование из массива в объект
   * и сохранение кэша в sessionStorage
   */
  const loadComputingRules = async () => {
    const r = await http.get<{ id: string; deps: string[] }[]>('/nsi/v1/equipment/constants/computable_parameters')

    const result = {}
    for (const item of r.data) {
      result[item.id] = item.deps
    }

    sessionStorage.setItem('computed_parameters_rules', JSON.stringify(result))
    setRules(result)
  }

  const handleChange = (source: string, id: string) => {
    setDraft((prev) =>
      produce(prev, (drft) => {
        const i = drft.findIndex((e) => e.id === id)
        drft[i].source = source
      })
    )
  }

  const handleCancel = () => {
    setEditing(false)
    setDraft(device.data_quality_options.computable_parameters || [])
  }

  const handleImport = (sourceId: string) => {
    const source = equipment.find((e) => e.id === sourceId)
    const { computable_parameters } = source.data_quality_options

    const data_quality_options = { ...device.data_quality_options, computable_parameters }
    const action = patchEquipment(device.id, { data_quality_options })

    dispatch(action)
    setImporting(false)
  }

  const handleSave = () => {
    const data_quality_options = { ...device.data_quality_options, computable_parameters: draft }
    const action = patchEquipment(device.id, { data_quality_options })

    dispatch(action)
    setEditing(false)
  }

  const rows = draft.map((row) => {
    const name = parameters.get(row.id)

    const options = constructOptions(row.id, intl, device, parameters, rules)

    return (
      <tr key={row.id}>
        <td className="line_clamp" title={name}>
          {name}
        </td>
        <td>
          <Dropdown
            name={row.id}
            options={options}
            value={row.source}
            onChange={handleChange}
            disabled={!editing}
            compact={true}
            isVirtual={true}
          />
        </td>
      </tr>
    )
  })

  return (
    <>
      <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <table className="nsi-table is-striped is-fixed">
          <thead>
            <tr>
              <th style={{ width: '70%' }}>{intl.formatMessage({ id: 'nsi.cp.parameter' })}</th>
              <th style={{ width: '30%' }}>{intl.formatMessage({ id: 'nsi.cp.source' })}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      <PanelButtons
        editing={editing}
        allowEditing={allowEditing}
        allowImport={allowEditing}
        onEdit={() => setEditing(true)}
        onImport={() => setImporting(true)}
        onCancel={handleCancel}
        onSave={handleSave}
      />
      <ImportModal
        isOpen={importing}
        sources={equipment}
        targetId={device.id}
        onClose={() => setImporting(false)}
        onImport={handleImport}
      />
    </>
  )
}

export default ComputableParameters
