import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageMode } from '../../shared/interfaces'

export interface OplimState {
  deviceId: string
  mode: PageMode
  search: string
}

const initialState: OplimState = {
  deviceId: '',
  mode: 'values',
  search: '',
}

const slice = createSlice({
  name: 'app/oplim',
  initialState,
  reducers: {
    SET_STATE: (state, action: PayloadAction<OplimState>) => {
      Object.assign(state, action.payload)
      state.search = ''
    },
    selectDevice: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload
    },
    setMode: (state, action: PayloadAction<PageMode>) => {
      state.mode = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
  },
})

export default slice.reducer
export const actions = slice.actions
