import React from 'react'
import useDeviceWidth from '../../hooks/useDeviceWidth'
import Links from '../TopMenu/Nav/Links'
import AppRoutes from '../TopMenu/Nav/Routes'
import settingsRoutes from '../TopMenu/Nav/settings.routes'

import '../../pages/Collections/collection.styles.css'
import '../../pages/Formulas/formulas.styles.css'
import '../../pages/SignalEvents/se.styles.css'
import '../../pages/System/system.styles.css'

const SettingsPage = () => {
  const { isDesktop } = useDeviceWidth()

  const className = isDesktop ? 'nsi' : 'nsi nsi-journal'

  return (
    <React.Fragment>
      {isDesktop && (
        <div className="top-menu is-submenu">
          <Links routes={settingsRoutes} />
        </div>
      )}

      <div className={className}>
        <AppRoutes routes={settingsRoutes} fallback="" />
      </div>
    </React.Fragment>
  )
}

export default SettingsPage
