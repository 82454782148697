import { SystemMessage } from 'au-nsi/shared'
import { formatDate } from '../../../utils/lang'
import { MessageAckEvent, MessageFilters } from './messages.types'

const format = 'd LLL HH:mm:ss'

export const formatMessageTime = (message: SystemMessage) => {
  return message.ts === message.ts_start
    ? formatDate(message.ts, format)
    : formatDate(message.ts_start, format) + ' — ' + formatDate(message.ts, format)
}

export const getMessageColor = (message: SystemMessage) => {
  if (message.level === 'error') return 'var(--danger-text)'
  else if (message.level === 'warn') return 'var(--warning)'
  else return 'var(--primary-80)'
}

export const matchFilters = (message: SystemMessage, filters: MessageFilters) => {
  if (filters.level && filters.level !== message.level) return false
  if (filters.type && filters.type !== message.type) return false
  if (filters.t0 && filters.t0 > message.ts) return false
  if (filters.t1 && filters.t1 < message.ts) return false
  return true
}

export const insertMessages = (state: SystemMessage[], messages: SystemMessage[]) => {
  const copy = [...state]

  for (const message of messages) {
    const i = copy.findIndex((e) => e.id === message.id && e.type === message.type)

    if (i !== -1) copy[i] = message
    else copy.push(message)
  }

  return copy.sort((a, b) => b.ts - a.ts)
}

export const updateMessages = (state: SystemMessage[], messages: SystemMessage[]) => {
  const copy = [...state]

  for (const message of messages) {
    const i = state.findIndex((e) => e.id === message.id)
    if (i !== -1) copy[i] = { ...copy[i], ...message }
  }

  return copy.sort((a, b) => b.ts - a.ts)
}

export const ackMessages = (state: SystemMessage[], ack: MessageAckEvent) => {
  return state.map((m) => {
    const acked = ack.message_id === m.id || (ack.batch && ack.last_ts >= m.ts_start)
    return acked ? { ...m, ack_ts: ack.ack_ts } : m
  })
}

export const unackMessage = (state: SystemMessage[], ack: MessageAckEvent) => {
  return state.map((m) => (m.id === ack.message_id ? { ...m, ack_ts: null } : m))
}
