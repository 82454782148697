import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { IPhasePortraitComponent, IPhasePortraitSettings } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { IDataSource } from '../../Nsi/nsi.interfaces'
import { selectDataSources } from '../../Nsi/nsi.selectors'

/**
 * Модальное окно с настройками фазового портрета
 */
export const PhasePortraitSettings = (props: Props) => {
  const [settings, setSettings] = React.useState(props.component.settings)
  const [error, setError] = React.useState<string>(null)
  const intl = useIntl()

  const parameterOptions: SelectOption[] = React.useMemo(() => {
    return props.parameters.map((p) => ({ value: p.id, label: p.name }))
  }, [props.parameters])

  const xParameter = React.useMemo(
    () => props.parameters.find((p) => p.id === settings.x_parameter_id),
    [props.parameters, settings.x_parameter_id]
  )

  const yParameter = React.useMemo(
    () => props.parameters.find((p) => p.id === settings.y_parameter_id),
    [props.parameters, settings.y_parameter_id]
  )

  React.useEffect(() => {
    if (error != null) setError(null)
  }, [settings])

  const handleSave = () => {
    const err = validateSettings(settings, intl)
    if (err == null) props.onSave(settings)
    else setError(err)
  }

  const handleEquipmentChange = React.useCallback((equipment: string[]) => {
    setSettings((prevState) => ({ ...prevState, equipment }))
  }, [])

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((s) => ({ ...s, [key]: value }))
  }, [])

  const renderAxisSettings = (axis: 'x' | 'y') => {
    const selectedParameters = { x: xParameter, y: yParameter }
    const parameter = selectedParameters[axis]

    const inputOptions = {
      basePrefix: parameter && props.prefixes[parameter.prefix],
      baseUnit: parameter && props.units[parameter.unit],
      displayPrefix: parameter && props.prefixes[parameter.display_prefix],
      displayUnit: parameter && props.units[parameter.display_unit],
      prefixes: props.prefixes,
      units: props.units,
    }

    return (
      <React.Fragment>
        <div>
          <div className="system__label">
            {intl.formatMessage({ id: `dashboards.phase_portrait.settings.${axis}_parameter_id` })}
          </div>
          <div className="system__input-wrapper">
            <Select
              name={axis + '_parameter_id'}
              options={parameterOptions}
              value={parameter && parameter.id}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <div className="system__label">
            {intl.formatMessage({ id: `dashboards.phase_portrait.settings.${axis}_min` })}
          </div>
          <div className="system__input-wrapper">
            <NumberInput
              type="parameter"
              name={axis + '_min'}
              value={settings[axis + '_min']}
              onChange={handleChange}
              fullWidth={true}
              {...inputOptions}
            />
          </div>
        </div>

        <div>
          <div className="system__label">
            {intl.formatMessage({ id: `dashboards.phase_portrait.settings.${axis}_max` })}
          </div>
          <div className="system__input-wrapper">
            <NumberInput
              type="parameter"
              name={axis + '_max'}
              value={settings[axis + '_max']}
              onChange={handleChange}
              fullWidth={true}
              {...inputOptions}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid three-cols">
            <div>
              <div className="system__label">
                {intl.formatMessage({ id: 'dashboards.phase_portrait.settings.title' })}
              </div>
              <div className="system__input-wrapper">
                <TextInput name="title" value={settings.title} onChange={handleChange} required={false} />
              </div>
            </div>

            <div>
              <div className="system__label">
                {intl.formatMessage({ id: 'dashboards.phase_portrait.settings.timespan' })}
              </div>
              <div className="system__input-wrapper">
                <NumberInput
                  baseUnit={props.units.ms}
                  displayUnit={props.units.s}
                  fullWidth={true}
                  min={0.001}
                  name="timespan"
                  onChange={handleChange}
                  prefixes={props.prefixes}
                  type="parameter"
                  units={props.units}
                  value={settings.timespan}
                />
              </div>
            </div>

            <div />

            {renderAxisSettings('x')}
            {renderAxisSettings('y')}
          </div>

          <h3>{intl.formatMessage({ id: 'dashboards.phase_portrait.settings.equipment' })}</h3>
          <MultiSelect
            options={props.equipment}
            selection={settings.equipment}
            onChange={handleEquipmentChange}
            disabled={false}
          />

          <ModalFooter error={error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

const validateSettings = (settings: IPhasePortraitSettings, intl: IntlShape): string => {
  if (!settings.x_parameter_id || !settings.y_parameter_id) {
    return intl.formatMessage({ id: 'dashboards.phase_portrait.errors.no_parameter' })
  }

  if (settings.x_min >= settings.x_max || settings.y_min >= settings.y_max) {
    return intl.formatMessage({ id: 'dashboards.phase_portrait.errors.invalid_axes_range' })
  }

  return null
}

interface Props {
  component: IPhasePortraitComponent
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: IPhasePortraitSettings) => void
  parameters: Parameter[]
  prefixes: Record<string, SiPrefix>
  title: string
  units: Record<string, SiUnit>
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameters: state.parameters.items,
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(PhasePortraitSettings)
