import { IAutoalignData, CardMoveType, WidgetType } from '../dashboard.types'

// у каких типов графиков сохранять соотношение сторон при масштабировании
export const preserveRatio: { [type in WidgetType]?: boolean } = { gauge: true, windrose: true, vector_chart: true }

export const defaultData: IAutoalignData = {
  x: 0,
  y: 0,
  w: 100,
  h: 100,
  mode: 'none',
  gridStep: 100,
  viewport: 1024,
  components: [],
  hidden: true,
  canceled: false,
  oncancelRegistered: false,
}

// обнуление информации об автовыравнивании
export const reset = (hint: Hint, data: Data, oncancel: Oncancel) => {
  hideHint(hint, data)

  window.removeEventListener('keydown', oncancel)
  data.current.canceled = false
  data.current.oncancelRegistered = false
}

// пользователь отключил автовыравнивание
export const cancel = (hint: Hint, data: Data) => {
  hideHint(hint, data)
  data.current.canceled = true
}

/**
 * Автовыравнивание: при перетаскивании компонентов экрана находим близкие координаты
 * куда можно поместить компонент для выравнивания (по границам сетки или других компонентов)
 * и показываем это предложение пользователю
 */
export const suggest = (options: ISuggestOptions) => {
  const d = options.data.current
  if (d.canceled) return

  const { mode } = d
  if (mode === 'none') return

  if (!d.oncancelRegistered) {
    window.addEventListener('keydown', options.oncancel)
    d.oncancelRegistered = true
  }

  const { position } = options
  const width = d.viewport

  // максимальное расстояние на которое можно сдвинуть элемент для его выравнивания
  // переводим из пикселей в доли от ширины экрана
  const maxDistance = 30 / width

  // координаты самого перетаскиваемого элемента который нужно выровнять
  const px0 = position.x
  const px1 = position.x + position.w
  const py0 = position.y
  const py1 = position.y + position.h

  // смещение углов которое и нужно рассчитать
  let dx0 = 0
  let dy0 = 0
  let dx1 = 0
  let dy1 = 0

  // поиск ближайших линий сетки
  if (mode === 'grid') {
    const { gridStep } = d
    const x0 = Math.round((px0 * width) / gridStep)
    const y0 = Math.round((py0 * width) / gridStep)
    const x1 = Math.round((px1 * width) / gridStep)
    const y1 = Math.round((py1 * width) / gridStep)

    dx0 = -px0 + (x0 * gridStep) / width
    dy0 = -py0 + (y0 * gridStep) / width
    dx1 = -px1 + (x1 * gridStep) / width
    dy1 = -py1 + (y1 * gridStep) / width
  }

  // проходим по всем другим компонентам и ищем среди их границ близкие к целевому элементу
  if (mode === 'components') {
    for (const component of d.components) {
      // пропускаем сам элемент
      if (component.id === position.id) continue

      // пропускаем слишком далекие элементы
      const distanceX = Math.abs(component.x + component.w / 2 - position.x - position.w / 2)
      const distanceY = Math.abs(component.y + component.h / 2 - position.y - position.h / 2)
      const d2 =
        Math.pow(distanceX, 2) +
        Math.pow(distanceY, 2) -
        Math.pow(component.w / 2 + position.w / 2, 2) -
        Math.pow(component.h / 2 + position.h / 2, 2)

      if (d2 > Math.pow(maxDistance, 2)) continue

      const cx0 = component.x
      const cx1 = component.x + component.w
      const cy0 = component.y
      const cy1 = component.y + component.h

      // сравниваем расстояние до всех 4-х границ и если среди них есть достаточно
      // близкие то выравниваем по ним
      if (Math.abs(cx0 - px0) < maxDistance) dx0 = absmin(dx0, cx0 - px0)
      if (Math.abs(cx1 - px0) < maxDistance) dx0 = absmin(dx0, cx1 - px0)
      if (Math.abs(cy0 - py0) < maxDistance) dy0 = absmin(dy0, cy0 - py0)
      if (Math.abs(cy1 - py0) < maxDistance) dy0 = absmin(dy0, cy1 - py0)
      if (Math.abs(cx0 - px1) < maxDistance) dx1 = absmin(dx1, cx0 - px1)
      if (Math.abs(cx1 - px1) < maxDistance) dx1 = absmin(dx1, cx1 - px1)
      if (Math.abs(cy0 - py1) < maxDistance) dy1 = absmin(dy1, cy0 - py1)
      if (Math.abs(cy1 - py1) < maxDistance) dy1 = absmin(dy1, cy1 - py1)
    }
  }

  // если нашелся вариант как можно выровнять элемент то показываем его пользователю
  if (dx0 !== 0 || dx1 !== 0 || dy0 !== 0 || dy1 !== 0) {
    if (options.type === 'move') {
      // при простом перетаскивании (не ресайзе) необходимо сохранять размер элемента, поэтому
      // если сдвигается верхняя граница, то нижнюю надо сдвинуть на такое же значение
      if (dx0 === 0) dx0 = dx1
      else dx1 = dx0

      if (dy0 === 0) dy0 = dy1
      else dy1 = dy0
    } else if (preserveRatio[position.type]) {
      // при ресайзе некоторых элементов нужно следить за тем чтобы их
      // соотношение сторон при этом не изменилось
      const ratio = position.w / position.h

      if (dx0 === 0) dx0 = dy0 * ratio
      else dy0 = dx0 / ratio

      if (dx1 === 0) dx1 = dy1 * ratio
      else dy1 = dx1 / ratio
    }

    let x = position.x + dx0
    let y = position.y + dy0
    let w = position.w - dx0 + dx1
    const h = position.h - dy0 + dy1

    if (x < 0) x = 0
    if (y < 0) y = 0
    if (x + w > 1) w = 1 - x
    // if (y + h > options.height / width) return hideHint(options.hint, options.data)

    positionHint(options.hint, options.data, { x: x * width, y: y * width, w: w * width, h: h * width })
  } else {
    hideHint(options.hint, options.data)
  }
}

// скрыть индикатор автовыравнивания
const hideHint = (hint: Hint, data: Data) => {
  data.current.hidden = true
  hint.current.style.opacity = '0'
}

// сохранить рассчитанную позицию компонента и отметить это место полупрозрачным
// прямоугольником для уведомления пользователя
const positionHint = (hint: Hint, data: Data, p: IPosition) => {
  const d = data.current
  const style = hint.current.style

  if (d.hidden) {
    d.hidden = false
    style.opacity = '0.1'
  }

  if (d.x !== p.x || d.y !== p.y || d.w !== p.w || d.h !== p.h) {
    d.x = p.x
    d.y = p.y
    d.w = p.w
    d.h = p.h

    style.transform = `translate(${p.x}px, ${p.y}px) scale(${p.w / 100}, ${p.h / 100})`
  }
}

const absmin = (a: number, b: number) => {
  if (a === 0) return b
  else if (b === 0) return a
  else return Math.abs(a) < Math.abs(b) ? a : b
}

type Hint = React.RefObject<HTMLDivElement>
type Data = React.RefObject<IAutoalignData>
type Oncancel = (e: KeyboardEvent) => void

interface IPosition {
  id?: number
  type?: WidgetType
  x: number
  y: number
  w: number
  h: number
}

interface ISuggestOptions {
  hint: Hint
  data: Data
  position: IPosition
  height: number
  type: CardMoveType
  oncancel: Oncancel
}
