import { Select } from '@alterouniversal/au-react-components'
import { IDashboard } from 'au-nsi/dashboards'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import ToggleWithLabel from '../../../shared/Inputs/ToggleWithLabel'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import ImageInput from '../../Libraries/Images/ImageInput'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import actions from '../dashboard.actions'
import { extractDevices } from '../dashboard.utils'
import { copyDashboard } from './copy.utils'

interface IProps {
  dashboard: IDashboard
  onClose: () => void
}

/**
 * Модальное окно для создания копии экрана с возможностью замены устройств из первоначального экрана на новые
 */
const CopyModal = ({ dashboard, onClose }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [state, setState] = useState(dashboard)
  const [mapDevices, setMapDevices] = useState(false)
  const [mapping, setMapping] = useState(new Map<string, string>())

  const components = useSelector((state: ReduxState) => state.dashboards.components[dashboard.id])
  const equipment = useSelector(selectDataSourceOptions)
  const equipmentNames = useMemo(() => new Map(equipment.map((o) => [o.value, o.label])), [equipment])

  const { loading, wait } = useHttpLoader()

  useEffect(() => {
    dispatch(actions.loadDashboardComponents(dashboard.id))
  }, [])

  const handleChange = (value: any, key: string) => setState({ ...state, [key]: value })

  const handleMappingChange = (target: string, source: string) => {
    const updates = new Map(mapping)
    updates.set(source, target)
    setMapping(updates)
  }

  const handleSave = () => {
    const operation = copyDashboard({ dashboard: state, components, mapping, dispatch })
    wait(operation, onClose)
  }

  // список устройств использующихся на исходном экране
  const devices = useMemo(() => {
    const result = (components || []).reduce((acc, c) => extractDevices(c, acc), new Set<string>())
    return Array.from(result)
  }, [components])

  const rows =
    mapDevices &&
    devices.map((id) => {
      const mappingTarget = mapping.get(id) || id

      return (
        <tr key={id}>
          <td>{equipmentNames.get(id) || id}</td>
          <td>
            <Select name={id} options={equipment} value={mappingTarget} onChange={handleMappingChange} />
          </td>
        </tr>
      )
    })

  return (
    <Modal size="lg" onClose={onClose}>
      <h2>{intl.formatMessage({ id: 'dashboards.copy' })}</h2>

      <Form editing={true} onCancel={onClose} onSubmit={handleSave}>
        <div className="system__grid">
          <InputRow label={intl.formatMessage({ id: 'common.name' })}>
            <TextInput name="name" value={state.name} onChange={handleChange} />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'system.images.src' })}>
            <ImageInput name="image_id" value={(state as IDashboard).image_id} onChange={handleChange} />
          </InputRow>

          <ToggleWithLabel
            name=""
            label={intl.formatMessage({ id: 'dashboards.copy.map_devices' })}
            value={mapDevices}
            onChange={setMapDevices}
          />
        </div>

        {mapDevices && (
          <table className="nsi-settings-table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'dashboards.copy.map_from' })}</th>
                <th>{intl.formatMessage({ id: 'dashboards.copy.map_to' })}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        )}

        <ModalFooter loading={components == null || loading} onCancel={onClose} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

export default CopyModal
