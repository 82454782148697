import { useIntl } from 'react-intl'
import { useMemo } from 'react'

const useAepsSettingsTranslations = () => {
  const intl = useIntl()

  return useMemo(
    () => ({
      email_settings: intl.formatMessage({ id: 'system.aeps.email_settings' }),
      xml_settings: intl.formatMessage({ id: 'system.aeps.xml_settings' }),
      xml_mapping: intl.formatMessage({ id: 'system.aeps.xml_mapping' }),
      xml_success_dir: intl.formatMessage({ id: 'system.aeps.xml_success_dir' }),
      files_mapping: intl.formatMessage({ id: 'system.aeps.files_mapping' }),
      factory_id: intl.formatMessage({ id: 'system.aeps.factory_id' }),
      files_settings: intl.formatMessage({ id: 'system.aeps.xlsx_settings' }),
      files_success_dir: intl.formatMessage({ id: 'system.aeps.files_success_dir' }),
      files_error_dir: intl.formatMessage({ id: 'system.aeps.files_error_dir' }),
      files_target_pattern: intl.formatMessage({ id: 'system.aeps.files_target_pattern' }),
      email_host: intl.formatMessage({ id: 'system.aeps.email_host' }),
      email_port: intl.formatMessage({ id: 'system.aeps.email_port' }),
      email_user: intl.formatMessage({ id: 'system.aeps.email_user' }),
      email_password: intl.formatMessage({ id: 'system.aeps.email_password' }),
      group_id: intl.formatMessage({ id: 'system.aeps.group_id' }),
      device_id: intl.formatMessage({ id: 'nsi.equipment' }),
      parameter_id: intl.formatMessage({ id: 'attributes.type.parameter' }),
      add: intl.formatMessage({ id: 'common.add' }),
    }),
    [intl.locale]
  )
}

export default useAepsSettingsTranslations
