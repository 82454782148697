import { SelectOption } from '@alterouniversal/au-react-components'
import classnames from 'classnames'
import { CSSProperties } from 'react'

const InlineSelect = (props: Props) => {
  const disabledOptions = props.disabledOptions || []

  const handleClick = (e) => {
    const { value } = e.target.dataset

    if (!disabledOptions.includes(value)) {
      props.onChange(value)
    }
  }

  const buttons = props.options.map((o) => {
    const className = classnames('inline-select__button', {
      'is-active': o.value === props.value,
      'is-disabled': disabledOptions.includes(o.value),
    })

    return (
      <div key={o.value} data-value={o.value} className={className} onClick={handleClick}>
        {o.label}
      </div>
    )
  })

  const size = props.size || 'normal'
  const className = `inline-select is-${size}`

  return (
    <div className={className} style={props.style}>
      {buttons}
    </div>
  )
}

interface Props {
  value: string | number
  options: SelectOption[]
  disabledOptions?: string[]
  size?: 'normal' | 'small'
  style?: CSSProperties
  onChange: (value: string) => void
}

export default InlineSelect
