import { IWindroseSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Loader from '../../../shared/Utils/Loader'
import EventEmitter from '../../../utils/events'
import http, { handleHttpError } from '../../../utils/http'
import { selectParametersMap } from '../../Parameters/params.selectors'
import LegendComparison from './LegendComparison'
import LegendDetailed from './LegendDetailed'
import WindroseTooltip, { TooltipState } from './Tooltip'
import css from './windrose.module.css'
import { generateGrid, generateLabels, IWindroseData, transform } from './windrose.utils'
import WindroseComparison from './WindroseComparison'
import WindroseDetailed from './WindroseDetailed'

const Windrose = (props: Props) => {
  const intl = useIntl()
  const container = React.useRef<HTMLDivElement>()

  const [size, setSize] = React.useState(0)
  const [data, setData] = React.useState<IWindroseData[]>([])
  const [loading, setLoading] = React.useState(false)

  // EventEmitter для передачи событий наведения мыши напрямую в компонент попап
  // (иначе пришлось бы передавать через стейт общего родительского компонента и
  // рендерить график при каждом движении мыши)
  const tooltip$ = React.useMemo(() => new EventEmitter<TooltipState>(), [])
  const handleHover = (data: TooltipState) => tooltip$.next(data)

  const parameters = useSelector(selectParametersMap)

  const { settings } = props
  const isDetailed = settings.type === 'detailed'

  // выставление размеров SVG равными размерам контейнера
  React.useEffect(() => {
    const { width } = container.current.getBoundingClientRect()
    setSize(Math.round(width))
  }, [props.lastResize])

  // загрузка данных при изменении настроек
  React.useEffect(() => {
    const rows = isDetailed ? settings.parameters.slice(0, 1) : settings.parameters

    // если изменилось кол-во отображаемых параметров, то обнуляем настройки
    // если не изменилось, то оставляем до получения новых данных для избежания "мигания" графика
    setData((prev) => (prev.length === rows.length ? prev : rows.map(() => null)))

    const fetchData = () => {
      const requests = []

      for (let i = 0; i < rows.length; i += 1) {
        const row = rows[i]
        const tsTo = row.time || Date.now()

        const parameter = parameters.get(row.direction_parameter_id)
        if (!parameter) continue

        // в детальном режиме запрашиваем и углы и скорости, в режиме сравнения - только углы
        const groups = [{ parameter: row.direction_parameter_id, step: 360 / settings.sectors, angle: parameter.unit }]
        if (isDetailed) groups.push({ parameter: row.speed_parameter_id, step: 2, angle: undefined })

        const query = {
          query: 'histogram',
          type: 'processed',
          id: row.device_id,
          tsFrom: tsTo - row.timespan,
          tsTo,
          groups,
        }

        const request = http
          .post('/back/v1/tsdb/query', query)
          .catch(handleHttpError)
          // eslint-disable-next-line
          .then((r) => {
            if (!r || cancelled) return

            const result = transform(r.data, {
              sectors: settings.sectors,
              direction: row.direction_parameter_id,
              speed: isDetailed ? row.speed_parameter_id : null,
              angleUnit: parameter.unit,
            })

            setData((prev) => [...prev.slice(0, i), result, ...prev.slice(i + 1)])
          })

        requests.push(request)
      }

      setLoading(true)
      Promise.all(requests).then(() => setLoading(false))
    }

    // debounce для избежания нескольких запросов при начальной загрузке,
    // пока настройки и параметры еще не подгрузились
    let cancelled = false
    const timeout = setTimeout(fetchData, 300)

    return () => {
      clearTimeout(timeout)
      cancelled = true
    }
  }, [settings, parameters])

  const R = size / 2 // координаты центра SVG
  const r = Math.max(R - 24, 0) // радиус самого графика (без отступов для подписей)

  // сетка внутренних окружностей
  const circles = generateGrid(r).map((radius) => {
    return <circle key={radius} cx={R} cy={R} r={radius} className={css.innerCircle} />
  })

  // подписи к углам
  const labels = generateLabels(r, intl).map(({ angle, label, textAnchor, dominantBaseline }) => {
    const x = R + (r + 5) * Math.sin(angle)
    const y = R - (r + 5) * Math.cos(angle)

    return (
      <text key={angle} x={x} y={y} textAnchor={textAnchor} dominantBaseline={dominantBaseline}>
        {label}
      </text>
    )
  })

  return (
    <div ref={container} className={css.container}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle cx={R} cy={R} r={r} style={{ fill: '#161C27' }} />
        {circles}
        {labels}
        {isDetailed ? (
          <WindroseDetailed data={data[0]} center={R} radius={r} onHover={handleHover} />
        ) : (
          <WindroseComparison data={data} center={R} radius={r} onHover={handleHover} />
        )}
      </svg>

      {isDetailed ? <LegendDetailed /> : <LegendComparison count={settings.parameters.length} />}

      <WindroseTooltip data$={tooltip$} />
      {loading && <Loader style={{ position: 'absolute', top: 8, right: 8, opacity: 0.5 }} />}
    </div>
  )
}

interface Props {
  id: number
  lastResize: number
  settings: IWindroseSettings
}

export default Windrose
