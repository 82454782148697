import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import NumberInput from '../../../shared/Inputs/NumberInput'
import { selectAccessRights } from '../../App/app.selectors'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import settingsActions from '../../System/Settings/settings.actions'
import ImportModal from '../Modals/ImportModal'
import { patchEquipment } from '../nsi.actions'
import { Equipment } from 'au-nsi/equipment'

// номинальные параметры использующиеся в КСВД
const ksvdFields = [
  { id: 'active_power', unit: 'W', prefix: 'M' },
  { id: 'power_factor', unit: 'unit', prefix: null },
  { id: 'stator_voltage', unit: 'V', prefix: 'k' },
  { id: 'stator_current', unit: 'A', prefix: null },
  { id: 'excitation_voltage', unit: 'V', prefix: null },
  { id: 'excitation_current', unit: 'A', prefix: null },
]

// параметры необходимые только для генераторов включенных в расчет СМСР
const smsrFields = [
  { id: 'reactive_power', unit: 'var', prefix: 'M' },
  { id: 'dQacc', unit: 'var', prefix: 'M' },
  { id: 'Ifm', unit: 'unit', prefix: null },
  { id: 'Ufm', unit: 'unit', prefix: null },
  { id: 'Uf_Ug', unit: 'unit', prefix: null },
  { id: 'dUavg', unit: 'V', prefix: null },
  { id: 'Qmin', unit: 'var', prefix: 'M' },
  { id: 'Ndiskr', unit: 'unit', prefix: null },
]

/**
 * Форма номинальных параметров устройства
 */
const RatedValuesPanel = (props: Props) => {
  const [ratedValues, setRatedValues] = React.useState(props.device.rated_values || {})
  const [editing, setEditing] = React.useState(false)
  const [importing, setImporting] = React.useState(false)

  const dispatch = useDispatch()

  // загрузка настроек СМСР для определения включено ли текущее устройство в его расчет
  React.useEffect(() => {
    dispatch(settingsActions.loadSettings('au-smsr'))
  }, [])

  React.useEffect(() => {
    if (editing) setEditing(false)
  }, [props.device.id])

  React.useEffect(() => {
    setRatedValues(props.device.rated_values || {})
  }, [props.device.rated_values])

  const handleEdit = () => setEditing(true)
  const startImport = () => setImporting(true)
  const cancelImport = () => setImporting(false)

  const handleCancel = () => {
    setRatedValues(props.device.rated_values || {})
    setEditing(false)
  }

  const handleSave = () => {
    dispatch(patchEquipment(props.device.id, { rated_values: ratedValues }))
    setEditing(false)
  }

  const handleChange = (value: number, name: string) => {
    setRatedValues({ ...ratedValues, [name]: value })
  }

  const handleImport = (sourceId: string) => {
    const targetId = props.device.id
    const source = props.equipment.find((e) => e.id === sourceId)
    const updates = { rated_values: { ...source.rated_values } }

    dispatch(patchEquipment(targetId, updates))
    setImporting(false)
  }

  const fields = React.useMemo(() => {
    const showSmsrFields = props.smsrEquipment.includes(props.device.id)
    return showSmsrFields ? ksvdFields.concat(smsrFields) : ksvdFields
  }, [props.device.id, props.smsrEquipment])

  const { units, prefixes } = props

  const rows = fields.map((field) => {
    const name = field.id
    const value = ratedValues[name] || 0
    const unit = units[field.unit]
    const prefix = prefixes[field.prefix]

    return (
      <tr key={name}>
        <td>{props.translations['equipment.rated_values.' + name]}</td>
        <td style={{ width: '40%' }}>
          <NumberInput
            baseUnit={unit}
            disabled={!editing}
            disabledStyle="text"
            displayPrefix={prefix}
            displayUnit={unit}
            fullWidth={true}
            name={field.id}
            onChange={handleChange}
            prefixes={prefixes}
            type="parameter"
            units={units}
            value={value}
          />
        </td>
      </tr>
    )
  })

  return (
    <React.Fragment>
      <Form editing={editing} onCancel={handleCancel} onSubmit={handleSave}>
        <table className="nsi-table" style={{ tableLayout: 'fixed' }}>
          <tbody>{rows}</tbody>
        </table>
        <PanelButtons
          editing={editing}
          allowEditing={props.allowEditing}
          allowImport={props.allowEditing}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onSave={handleSave}
          onImport={startImport}
        />
      </Form>

      <ImportModal
        isOpen={importing}
        onClose={cancelImport}
        onImport={handleImport}
        sources={props.equipment}
        targetId={props.device.id}
      />
    </React.Fragment>
  )
}

interface Props {
  allowEditing: boolean
  device: Equipment
  equipment: Equipment[]
  prefixes: Record<string, SiPrefix>
  smsrEquipment: string[]
  translations: { [key: string]: string }
  units: Record<string, SiUnit>
}

const mapStateToProps = (state: ReduxState) => {
  return {
    allowEditing: selectAccessRights(state)['equipment:update'],
    equipment: state.nsi.equipment,
    prefixes: state.parameters.prefixes,
    smsrEquipment: state.service_settings['au-smsr']?.equipment || [],
    translations: state.language.translations,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(RatedValuesPanel)
