import { DeviceParameters } from '../../pages/Nsi/nsi.interfaces'
import { CheckboxState } from '../Inputs/Checkbox/TernaryCheckbox'
import { EquipmentState, RegionsState, SelectedParameters } from './selector.interfaces'
import { Equipment, Region } from 'au-nsi/equipment'
import { TNode } from '../Tree/tree.types'

interface ToggleRegionOptions {
  id: number
  node: TNode<Region>
  regionsState: { [id: number]: CheckboxState }
  selectedParams: SelectedParameters
  deviceParams: DeviceParameters
}

/**
 * при нажатии на чекбокс региона пересчитать состояния дочерних элементов
 * a) если выбор региона добавлен, то сделать выбранными все дочернии регионы и оборудование
 * b) если выбор отменен, то отменить выбор у всех дочерних регионов и оборудования
 */
export const toggleRegion = (options: ToggleRegionOptions) => {
  const selectedParams = { ...options.selectedParams }

  const nextState = options.regionsState[options.id] === 'unchecked'

  const children = [...options.node.children]

  while (children.length !== 0) {
    const node = children.pop()

    if (node.children?.length) {
      children.push(...node.children)
    }

    if (!node.children?.length) {
      const { id } = node
      const parameters = options.deviceParams[id] || []

      if (!selectedParams[id]) {
        selectedParams[id] = {}
      }

      for (const param of parameters) {
        selectedParams[id][param] = nextState
      }
    }
  }

  return selectedParams
}

/**
 * Рассчитать состояние оборудование
 * *checked* - если выбраны все параметры у этого оборудования
 */
export const computeEquipmentState = (
  deviceParams: DeviceParameters,
  selectedParams: SelectedParameters
): EquipmentState => {
  const result: EquipmentState = {}

  for (const id of Object.keys(deviceParams)) {
    const total = deviceParams[id].length
    const _selected = selectedParams[id] || {}

    const selected = Object.keys(_selected).filter((p) => _selected[p]).length
    result[id] = computeCheckboxState(total, selected)
  }

  return result
}

const computeCheckboxState = (total: number, selected: number): CheckboxState => {
  if (selected === 0) return 'unchecked'
  if (selected === total) return 'checked'
  return 'partial'
}

/**
 * Рассчитать состояние региона
 * *checked* - если выбраны все дочерние устройства этого региона
 */
export const computeRegionsState = (
  nodes: TNode<Equipment | Region>[],
  equipmentState: EquipmentState
): RegionsState => {
  const state: RegionsState = {}

  const compute = (node: TNode<Equipment | Region>): CheckboxState => {
    // для устройств состояние уже рассчитано
    const isDevice = 'region_id' in node
    if (isDevice) return equipmentState[node.id]

    // для регионов рассчитываем сначала состояние всех его дочерних элементов
    // и на оснве этих данных уже состояние его самого
    let checked = 0
    let partial = 0

    for (const child of node.children || []) {
      const childState = compute(child)

      if (childState === 'checked') checked += 1
      else if (childState === 'partial') partial += 1
    }

    let nodeState: CheckboxState

    if (checked === 0 && partial === 0) nodeState = 'unchecked'
    else if (checked === node.children.length) nodeState = 'checked'
    else nodeState = 'partial'

    state[node.id] = nodeState
    return nodeState
  }

  nodes.forEach(compute)
  return state
}
