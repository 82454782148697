import classnames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import PlusButton from '../../../../shared/Forms/PlusButton'
import confirmService from '../../../../shared/Modal/confirm.service'
import Loader from '../../../../shared/Utils/Loader'
import PrevButton from '../../../../shared/Utils/PrevButton'
import RelativeTime from '../../../../shared/Utils/RelativeTime'
import http, { handleHttpError } from '../../../../utils/http'
import { formatDate } from '../../../../utils/lang'
import SearchModal from '../../../Nsi/Modals/SearchModal/SearchModal'
import { selectEquipmentMap } from '../../../Nsi/nsi.selectors'
import { User } from '../../collection.interfaces'
import css from './devices-form.module.css'
import { comparator, UserDevice } from './devices-form.utils'
import ExpirationModal from './ExpirationModal'
import NewEntryForm from './NewEntryForm'

/**
 * Таблица со списком устройств к которым у выбранного пользователя есть доступ
 */
const UserDevicesForm = ({ user, onClose }: Props) => {
  const intl = useIntl()
  const equipmentMap = useSelector(selectEquipmentMap)

  const { loading, wait } = useHttpLoader()
  const [devices, setDevices] = useState<UserDevice[]>([])
  const [newEntry, setNewEntry] = useState<string>()
  const [selection, setSelection] = useState(new Set<string>())
  const [modal, setModal] = useState<'edit' | 'add'>()

  // фильтр для того чтобы при добавлении доступа к новому устройству не показывать
  // в поиске уже и так подключенные устройства
  const searchFilter = useMemo(() => {
    const usedDevices = new Set(devices.map((d) => d.id))
    return (e) => !usedDevices.has(e.id)
  }, [devices])

  // выбор устройства при клике по строке
  const handleRowClick = (e) => {
    const { id } = e.currentTarget.dataset
    const isSelected = selection.has(id)

    const nextSelection = new Set(selection)
    isSelected ? nextSelection.delete(id) : nextSelection.add(id)
    setSelection(nextSelection)
  }

  // удаление выбранных строк
  const handleDelete = () => {
    const message = intl.formatMessage({ id: 'nsi.user.devices_form.confirm_revoke_access' })

    confirmService.requestDeleteConfirmation(message).then((status) => {
      if (status !== 'delete') return

      setDevices(devices.filter((d) => !selection.has(d.id)))
      setSelection(new Set())

      http
        .post('/nsi/v1/equipment/access/revoke', { user_id: user.id, equipment: [...selection] })
        .catch(handleHttpError)
    })
  }

  // выставление срока действия доступа к выбранным устройствам
  const handleUpdate = (expires_at: number) => {
    const equipment = newEntry ? [{ id: newEntry, expires_at }] : [...selection].map((id) => ({ id, expires_at }))

    if (newEntry) setNewEntry(null)

    http
      .post('/nsi/v1/equipment/access/grant', { user_id: user.id, equipment })
      .then(() => {
        const nextDevices = [...devices]

        for (const { id } of equipment) {
          const index = nextDevices.findIndex((d) => d.id === id)
          if (index !== -1) nextDevices[index] = { ...nextDevices[index], expires_at }
          else nextDevices.push({ id, expires_at, name: equipmentMap.get(id).name, reason: 'user' })
        }

        setDevices(nextDevices.sort(comparator))
        setSelection(new Set())
      })
      .catch(handleHttpError)
  }

  const handleClose = () => {
    setModal(null)
    newEntry && setNewEntry(null)
  }

  useEffect(() => {
    const p = http.get('/nsi/v1/equipment/access/users/' + user.id)
    wait(p, (r) => setDevices(r.data.sort(comparator)))
  }, [])

  const rows = devices.map((d) => {
    // редактирование возможно только если доступ выдан конкретно пользователю (если доступ выдан например роли,
    // то ни отменить его, ни выставить срок действия через эту форму невозможно)
    const isClickable = d.reason === 'user'
    const reason = intl.formatMessage({ id: 'nsi.user.devices_form.reason.' + d.reason, defaultMessage: '-' })

    const className = classnames(css.row, {
      [css.clickableRow]: isClickable,
      [css.selectedRow]: selection.has(d.id),
    })

    return (
      <tr key={d.id} data-id={d.id} className={className} onClick={isClickable ? handleRowClick : undefined}>
        <td>{d.name}</td>
        <td>{reason}</td>
        <td>
          {d.expires_at ? (
            <span>
              <RelativeTime ts={d.expires_at} />
              &nbsp;({formatDate(d.expires_at, 'dd MMM yyyy')})
            </span>
          ) : (
            intl.formatMessage({ id: 'nsi.user.devices_form.expires_at.unlimited' })
          )}
        </td>
      </tr>
    )
  })

  const formTitle = intl.formatMessage({ id: 'nsi.user.devices_form.expiration_settings' })

  return (
    <div className="nsi-main__wrapper">
      <div className="nsi-main__container">
        <div className={css.header}>
          <PrevButton onCLick={onClose} />

          {selection.size === 0 && (
            <PlusButton textId="nsi.user.devices_form.add_device" onClick={() => setModal('add')} />
          )}

          {selection.size > 0 && (
            <div>
              <button className="nsi-button secondary" onClick={() => setModal('edit')}>
                {intl.formatMessage({ id: 'nsi.user.devices_form.add_expiration' })}
              </button>
              <button className="nsi-button danger" onClick={handleDelete}>
                {intl.formatMessage({ id: 'nsi.user.devices_form.revoke_access' })}
              </button>
            </div>
          )}
        </div>

        <table className={'nsi-table is-compact ' + css.table}>
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'nsi.user.devices_form.device' })}</th>
              <th>{intl.formatMessage({ id: 'nsi.user.devices_form.reason' })}</th>
              <th>{intl.formatMessage({ id: 'nsi.user.devices_form.expires_at' })}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>

        <div style={{ display: 'flex', justifyContent: 'center' }}>{loading && <Loader />}</div>
      </div>

      {modal === 'edit' && <ExpirationModal title={formTitle} onClose={handleClose} onSave={handleUpdate} />}
      {modal === 'add' && <SearchModal onClose={handleClose} onSelect={setNewEntry} filter={searchFilter} />}
      {newEntry && <NewEntryForm id={newEntry} onClose={handleClose} onSave={handleUpdate} />}
    </div>
  )
}

interface Props {
  user: User
  onClose: () => void
}

export default UserDevicesForm
