import { AnyObject } from 'common'
import { TNode } from './tree.types'
import useTreeState, { TTreePersistenceStrategy } from './useTreeState/useTreeState'
import { produce } from 'immer'

interface IProps {
  persistenceStrategy: TTreePersistenceStrategy
  key: string
}

export interface ITreeControls<T extends AnyObject> {
  selectedNodeId: TNode<T>['id']
  openedNodes: Set<TNode<T>['id']>

  handleToggle: (nodeId: TNode<T>['id'], active?: boolean) => void
  handleSelect: (nodeId: TNode<T>['id']) => void
  setOpenedNodes: (nodes: Set<TNode<T>['id']>) => void
}

const useTree = <T extends AnyObject>(props: IProps): ITreeControls<T> => {
  const state = useTreeState<T>(props.persistenceStrategy, props.key)

  const handleToggle = (nodeId: TNode<T>['id'], active?: boolean) => {
    let fn

    if (active === undefined) fn = state.openedNodes.has(nodeId) ? immutableSetDelete : immutableSetAdd
    else fn = active ? immutableSetAdd : immutableSetDelete

    state.setOpenedNodes(fn(state.openedNodes, nodeId))
  }

  return {
    openedNodes: state.openedNodes,
    selectedNodeId: state.selectedNodeId,
    handleSelect: state.setSelectedNodeId,
    setOpenedNodes: state.setOpenedNodes,
    handleToggle,
  } as ITreeControls<T>
}

const immutableSetAdd = <T>(set: Set<T>, key: T) => {
  return produce(set, (draft: Set<T>) => {
    draft.add(key)
  })
}

const immutableSetDelete = <T>(set: Set<T>, key: T) => {
  return produce(set, (draft: Set<T>) => {
    draft.delete(key)
  })
}

export default useTree
