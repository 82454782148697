import Modal from '../../../shared/Modal/Modal'
import { useIntl } from 'react-intl'

interface IProps {
  onClose: () => void
  item: object
  header: string
}

export const ObjectModal = ({ item, onClose, header }: IProps) => {
  const intl = useIntl()

  const renderObject = (object: object) => (
    <div className={'s-changelog__details-node'} style={{ paddingLeft: '10px' }}>
      {Object.keys(object).map((key) => {
        if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
          return (
            <div key={key} className={'s-changelog__details-item'}>
              {key}
              {renderObject(object[key])}
            </div>
          )
        }

        return (
          <div key={key} className={'s-changelog__details-item'}>
            {key}: {object[key]?.toString() || '-'}
          </div>
        )
      })}
    </div>
  )

  return (
    <Modal onClose={onClose}>
      <h2>{intl.formatMessage({ id: header })}</h2>
      <div style={{ lineHeight: '20px' }}>{renderObject(item)}</div>
    </Modal>
  )
}
