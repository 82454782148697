import { useEffect, useRef } from 'react'

/**
 * Хук вызова коллбека onUnmount при анмаунте функционального компонента - родителя.
 *
 * Нужен в случае, если есть необходимость вызвать колбек один раз, но при этом колбек периодически обновляется в
 * завимисти от каких-либо депсов.
 */
const useUnmount = (onUnmount: () => void) => {
  const onUnmountRef = useRef(onUnmount)

  useEffect(() => {
    return () => {
      onUnmountRef.current()
    }
  }, [])

  useEffect(() => {
    onUnmountRef.current = onUnmount
  }, [onUnmount])
}

export default useUnmount
