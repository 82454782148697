import { useAppSelector } from '../../../../redux/store'
import { selectEnabledModules } from '../../../App/app.selectors'

/**
 * Вернуть, включён ли модуль групп.
 * Хук нужен для удобного переноса логики в случае вынесеня групп в отдельный модуль / добавление в core, а не адаптер
 * */
const useGroupsEnabledStatus = (): boolean => {
  const modules = useAppSelector(selectEnabledModules)

  return modules.has('LP001-Server')
}

export default useGroupsEnabledStatus
