import { ParameterDn } from '../../Parameters/params.interfaces'
import { ToleranceSettings } from './vp.interfaces'

export const parseTolerances = (tolerances: { [param: string]: string }, params: ParameterDn[]) => {
  const result: ToleranceSettings[] = []

  for (const key of Object.keys(tolerances)) {
    const parameter = params.find((p) => p.id === key)

    if (parameter) {
      const { lower_limit, upper_limit } = parseTolerance(tolerances[key])
      result.push({ parameter, lower_limit, upper_limit })
    }
  }

  return result
}

// transform tolerances back into format supported by ms-dq
export const serializeTolerances = (tolerances: ToleranceSettings[]): { [param: string]: string } => {
  const result = {}

  for (const item of tolerances) {
    // ms-dq requires fractional digits to always to be present
    const a = Number.isInteger(item.lower_limit) ? item.lower_limit.toFixed(1) : item.lower_limit.toString()
    const b = Number.isInteger(item.upper_limit) ? item.upper_limit.toFixed(1) : item.upper_limit.toString()
    result[item.parameter.id] = `[${a};${b}]`
  }

  return result
}

// extract limits from string with format '[12, 34]'
export const parseTolerance = (str: string) => {
  const [a = '', b = ''] = str.split(';')

  const lower_limit = +a.slice(1) || 0
  const upper_limit = +b.slice(0, b.length - 1) || 0

  return { lower_limit, upper_limit }
}
