import EditableText from '../../../shared/Inputs/EditableText/EditableText'
import { useDispatch } from 'react-redux'
import { patchEquipment } from '../nsi.actions'
import { LP001Equipment } from 'au-nsi/equipment'
import { showError } from '../../../utils/notifications'

interface IProps {
  device: LP001Equipment
}

const AEEntranceInput = ({ device }: IProps) => {
  const dispatch = useDispatch()

  const handleSave = (val: string) => {
    const entrance = Number(val)
    if (val === '' || !aeValidateEntrance(entrance)) {
      return showError('common.validation_error')
    }

    const attributes = device.attributes ?? {}
    dispatch(patchEquipment(device.id, { attributes: { ...attributes, entrance } } as LP001Equipment))
  }

  return <EditableText allowEditing={true} onChange={handleSave} value={device.attributes?.entrance?.toString()} />
}

export const aeValidateEntrance = (entrance: number): boolean => {
  return !isNaN(entrance) && entrance >= 0 && entrance <= 100 && Number.isInteger(entrance)
}

export default AEEntranceInput
