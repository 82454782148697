import { FormattedMessage } from 'react-intl'
import Gantt from '../GanttTable/Gantt'

const GanttRow = (props: Props) => {
  return (
    <Gantt.Row selected={props.selected} onClick={props.onClick}>
      <div>
        <FormattedMessage id={'nav.' + props.page} />
      </div>
      <div />
    </Gantt.Row>
  )
}

interface Props {
  page: string
  selected: boolean
  onClick: (e: any) => void
}

export default GanttRow
