// draw short line representing axis tick
const AxisMark = (props: Props) => {
  const angle = Math.PI - props.angle
  const x1 = 50 - 42 * Math.sin(angle)
  const y1 = 50 - 42 * Math.cos(angle)
  const x2 = 50 - 41 * Math.sin(angle)
  const y2 = 50 - 41 * Math.cos(angle)

  return <line stroke={props.color} strokeWidth="1" strokeLinecap="round" x1={x1} y1={y1} x2={x2} y2={y2} />
}

interface Props {
  angle: number
  color: string
}

export default AxisMark
