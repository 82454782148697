/**
 * Связующуй сервис между ConfirmModal компонентом и любым другим компонентом
 * которому необходимо запросить подтверждение от пользователя
 */
class ConfirmService {
  private cb = null

  /**
   * Запросить подтверждение. После выбора пользователя будет
   * возвращено либо 'cancel' либо одно из значений переданных в options
   */
  public requestConfirmation(title: string, options: ConfirmOption[]): Promise<string> {
    return new Promise((resolve) => {
      const confirm = (value) => resolve(value)
      const cancel = () => resolve('cancel')

      this.cb({ title, options, confirm, cancel })
    })
  }

  public requestDeleteConfirmation(title = 'common.confirm_deletion') {
    const options = [{ title: 'common.delete', value: 'delete' }]
    return this.requestConfirmation(title, options) as Promise<'delete' | 'cancel'>
  }

  public subscribe(cb) {
    this.cb = cb
  }
}

export interface ConfirmOption {
  title: string
  value: string
}

export default new ConfirmService()
