import { actions } from './organizations.reducer'
import {
  patchUpdateOrganization,
  patchUpdateOrganizationType,
  postCreateOrganization,
  postCreateOrganizationType,
  requestDeleteOrganization,
  requestDeleteOrganizationType,
  selectOrganizations,
} from './organizations.api'
import { OrganizationTypeWithInstances } from './organizations.types'
import { FormMode } from '../../../shared/interfaces'
import { Organization } from 'au-nsi/organizations'

export const loadOrganizations =
  (forceReload = false) =>
  async (dispatch, getState) => {
    const state = getState()
    if (state.isLoaded && !forceReload) return

    dispatch(actions.organizationsLoaded(await selectOrganizations()))
  }

export const setFormMode = (mode: FormMode) => (dispatch) => {
  dispatch(actions.setState({ formMode: mode }))
}

export const organizationTypeCreateStart = () => (dispatch) => {
  dispatch(
    actions.setState({ formMode: 'create', selectedOrganizationId: undefined, selectedOrganizationTypeId: undefined })
  )
}

export const organizationCreateStart = (typeId: OrganizationTypeWithInstances['id']) => (dispatch) => {
  dispatch(
    actions.setState({ formMode: 'create', selectedOrganizationTypeId: typeId, selectedOrganization: undefined })
  )
}

export const createOrganizationType = (organizationType: OrganizationTypeWithInstances) => async (dispatch) => {
  const created: OrganizationTypeWithInstances = await postCreateOrganizationType(organizationType)

  if (created) {
    dispatch(actions.organizationTypeCreated(created))
    dispatch(actions.setState({ selectedOrganizationTypeId: created.id, formMode: 'view' }))
  }
}

export const updateOrganizationType = (organizationType: OrganizationTypeWithInstances) => async (dispatch) => {
  const updated = await patchUpdateOrganizationType(organizationType)

  if (updated) {
    dispatch(actions.organizationTypeUpdated(updated))
    dispatch(actions.setState({ formMode: 'view' }))
  }
}

export const deleteOrganizationType = (organizationTypeId: OrganizationTypeWithInstances['id']) => async (dispatch) => {
  const deleted = await requestDeleteOrganizationType(organizationTypeId)

  if (deleted) {
    dispatch(
      actions.setState({ formMode: 'view', selectedOrganizationTypeId: undefined, selectedOrganizationId: undefined })
    )
    dispatch(actions.organizationTypeDeleted(deleted))
  }
}

export const createOrganization = (organization: Organization) => async (dispatch) => {
  const created: Organization = await postCreateOrganization(organization)

  if (created) {
    dispatch(
      actions.setState({
        formMode: 'view',
        selectedOrganizationId: created.id,
        selectedOrganizationTypeId: created.organization_type_id,
      })
    )
    dispatch(actions.organizationCreated(created))
  }
}

export const updateOrganization = (organization: Organization) => async (dispatch) => {
  const updated: Organization = await patchUpdateOrganization(organization)

  if (updated) {
    dispatch(actions.organizationUpdated(updated))
  }
}

export const deleteOrganization = (organizationId: Organization['id']) => async (dispatch) => {
  const deleted: Organization = await requestDeleteOrganization(organizationId)

  if (deleted) {
    dispatch(
      actions.setState({ formMode: 'view', selectedOrganizationId: undefined, selectedOrganizationTypeId: undefined })
    )
    dispatch(actions.organizationDeleted(deleted))
  }
}

export const organizationsActions = {
  ...actions,
  organizationCreateStart,
  organizationTypeCreateStart,
  setFormMode,
  loadOrganizations,
  createOrganization,
  createOrganizationType,
  updateOrganization,
  updateOrganizationType,
  deleteOrganizationType,
  deleteOrganization,
}
