import { ISVGAnimationsSettings, ISVGColorSettings, ISVGSettings, ISVGVisibilitySettings } from 'au-nsi/dashboards'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { selectCommandsMap, selectCommandTypesMap } from '../../Commands/commands.selectors'
import { CommandOptions, isCompleteCommand, sendCommands } from '../../Commands/commands.utils'
import CommandsArgumentsModal from '../../Commands/components/CommandArgumentsModal'
import CommandSelectModal from '../../Commands/components/CommandSelectModal'
import actions from '../../Libraries/Images/image.actions'
import { selectImagesMap } from '../../Libraries/Images/image.selectors'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { formatValue } from '../../Parameters/params.utils'
import { useDashboardNavigation } from '../common/useDashboardNavigation'
import { matchCondition } from '../condition.utils'
import { AnimationsState, svgAnimation } from './animations/animations.utils'
import css from './svg.module.css'
import * as utils from './svg.utils'

const SVGDiagram = (props: Props) => {
  const dispatch = useDispatch()
  const navigate = useDashboardNavigation()

  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  // состояние выбора команды: к элементу схемы может быть привязан список комманд,
  // при клике на такой элемент сначала нужно открыть модальное окно для выбора одной команды из списка,
  // а затем второе окно для ввода аргументов выбранной команды
  const [commandsState, setCommandsState] = React.useState<ICommandsState>({ commands: [], step: null })

  const imageContainer = React.useRef<HTMLDivElement>()

  const commandsMap = useSelector(selectCommandsMap)
  const commandTypesMap = useSelector(selectCommandTypesMap)
  const parameters = useSelector(selectDenormalizedParametersMap)
  const images = useSelector(selectImagesMap)
  const image = images.get(props.settings.image_id)

  const resetCommandsState = () => setCommandsState({ commands: [], step: null })

  // выбор команд: если у команд нет незаполненных аргументов, то сразу их отправляем,
  // если есть - то открываем форму для ввода аргументов
  const selectCommands = (commands: CommandOptions[]) => {
    const isComplete = commands.every((c) => isCompleteCommand(c, commandsMap, commandTypesMap))

    if (!isComplete) {
      setCommandsState({ commands, step: 'enter_args' })
    } else {
      sendCommands(commands)
      resetCommandsState()
    }
  }

  // запрос всех указанных в настройках параметров и устройств
  React.useEffect(() => {
    const { text = [], color = [], visibility = [], movements = [], rotations = [] } = props.settings
    const selectors = []

    for (const { device_id, parameter_id } of [...text, ...color, ...visibility, ...movements, ...rotations]) {
      const selector = selectors.find((s) => s.device_id === device_id)

      if (!selector) selectors.push({ device_id, parameters: [parameter_id] })
      else if (!selector.parameters.includes(parameter_id)) selector.parameters.push(parameter_id)
    }

    service.setDataSelectors(selectors)
  }, [props.settings])

  // масштабировать изображение до размеров компонента заданных пользователем
  const resizeSVG = () => {
    const svg = imageContainer.current.querySelector('svg')

    if (svg) {
      const parent = imageContainer.current.parentNode as HTMLDivElement
      const { width, height } = parent.getBoundingClientRect()
      utils.fitSVG(svg, width, height)
    }
  }

  // изменение изображения: специально пересоздается при каждом изменении
  // настроек, чтобы случайно не осталось устаревших стилей или обработчиков
  React.useLayoutEffect(() => {
    if (!image) return

    if (!image.src) {
      dispatch(actions.loadImageSource(image.id))
      return
    }

    const div = imageContainer.current
    div.innerHTML = image.src
    resizeSVG()
  }, [image, props.settings])

  // ресайзинг компонента пользователем
  React.useEffect(resizeSVG, [props.lastResize])

  // обработка кликов по элементам схемы к которым привязаны команды
  React.useEffect(() => {
    const groups = utils.groupConditions(props.settings.commands || [])
    const handlers: Array<{ node: SVGElement; listener: () => void }> = []

    for (const [node_id, settings] of groups.entries()) {
      const node = queryNode(imageContainer.current, node_id)
      if (!node) continue

      node.style.cursor = 'pointer'

      // обработчик клика: если привязана только одна команда, то сразу выбираем ее,
      // если список, то показываем меню выбора пользователю
      const listener = () => {
        const commands = settings.filter((cmd) => commandsMap.has(cmd.command_id))

        if (commands.length === 0) return
        if (commands.length === 1) return selectCommands(commands)

        setCommandsState({ commands, step: 'select_commands' })
      }

      node.addEventListener('click', listener)
      handlers.push({ node, listener })
    }

    return () => {
      for (const item of handlers) {
        item.node.removeEventListener('click', item.listener)
        item.node.style.cursor = null
      }
    }
  }, [props.settings, image, commandsMap])

  // обработка кликов по элементам которые являются ссылками на другие страницы
  React.useEffect(() => {
    const links = props.settings.links || []
    const handlers: Array<{ node: SVGElement; listener: () => void }> = []

    for (const link of links) {
      const node = queryNode(imageContainer.current, link.node_id)
      if (!node) continue

      node.style.cursor = 'pointer'

      const listener = () => {
        if (link.type === 'dashboard') navigate(link.target)
        else window.open(link.target, undefined, 'noopener,noreferrer')
      }

      node.addEventListener('click', listener)
      handlers.push({ node, listener })
    }

    return () => {
      for (const item of handlers) {
        item.node.removeEventListener('click', item.listener)
        item.node.style.cursor = null
      }
    }
  }, [props.settings, image])

  // вывод значений параметров в указанные узлы SVG
  React.useEffect(() => {
    const svg = imageContainer.current && imageContainer.current.querySelector('svg')

    const nodes = new Map<string, SVGElement>()
    const { text = [], color = [], visibility = [], rotations = [], movements = [] } = props.settings
    const animations: ISVGAnimationsSettings[] = rotations.concat(movements)
    svgAnimation.prepareSVG(animations, svg)

    // поиск всех узлов указанных в настройках
    for (const row of [...text, ...color, ...visibility]) {
      if (nodes.has(row.node_id)) continue

      const node = queryNode(imageContainer.current, row.node_id)
      if (node) nodes.set(row.node_id, node)
    }

    const animationsGroups = utils.groupConditions(animations)
    const animationsCache = new Map<string, ISVGAnimationsSettings>()
    const animationsState: AnimationsState = {}

    const colorGroups = utils.groupConditions(color)
    const colorCache = new Map<string, ISVGColorSettings>()

    const visibilityGroups = utils.groupConditions(visibility)
    const visibilityCache = new Map<string, ISVGVisibilitySettings>()

    const onTick = () => {
      // обработка условий видимости элементов
      for (const [nodeId, conditions] of visibilityGroups.entries()) {
        const node = nodes.get(nodeId)
        if (!node) continue

        const match = matchCondition(service, conditions)
        const cache = visibilityCache.get(nodeId)

        // если условие не изменилось, то не трогаем DOM
        if (cache === match) continue
        else visibilityCache.set(nodeId, match)

        if (!match) {
          node.classList.remove(css.blinking)
          node.style.opacity = null
        } else if (match.action === 'hide') {
          node.classList.remove(css.blinking)
          node.style.opacity = '0'
        } else if (match.action === 'blink') {
          node.classList.add(css.blinking)
          node.style.opacity = '1'
        }
      }

      // обработка настроек цветов
      for (const [nodeId, conditions] of colorGroups.entries()) {
        const node = nodes.get(nodeId)
        if (!node) continue

        const match = matchCondition(service, conditions)
        const cache = colorCache.get(nodeId)

        // если условие не изменилось, то не трогаем DOM
        if (cache !== match) {
          colorCache.set(nodeId, match)
          utils.applyColor(node, match ? match.color : null)
        }
      }

      for (const [nodeId, conditions] of animationsGroups.entries()) {
        const match = matchCondition(service, conditions)

        if (!match) {
          typeof animationsState[nodeId] === 'number' && window.clearInterval(+animationsState[nodeId])
          if (animationsState[nodeId]) {
            animationsState[nodeId] = false
            svgAnimation.cancelAnimations(conditions, svg)
            animationsCache.set(nodeId, null)
          }
          continue
        }

        const cache = animationsCache.get(nodeId)

        if (cache !== match) {
          animationsCache.set(nodeId, match)
          if (match.cycle || match.rotate_one_way) {
            animationsState[nodeId] = svgAnimation.animate({ ...match, node_id: nodeId }, svg)
          } else {
            animationsState[nodeId] = svgAnimation.animate({ ...match, node_id: nodeId }, svg)
          }
        }
      }

      // изменение текста не чаще раза в секунду
      if (!service.shouldRender()) return

      for (const row of text) {
        const node = nodes.get(row.node_id)
        const parameter = parameters.get(row.parameter_id)

        if (node && parameter) {
          const p = service.selectCurrentPoint(row.device_id)
          const value = p?.[row.parameter_id]
          node.textContent = value != null ? formatValue(value, parameter) : '—'
        }
      }
    }

    service.onTick = onTick
    onTick()

    return () => {
      // Чистим все цикличные анимации
      Object.keys(animationsState).forEach((nodeId) => {
        if (typeof animationsState[nodeId] === 'number') {
          window.clearInterval(+animationsState[nodeId])
        }
      })
    }
  }, [props.settings, image])

  return (
    <React.Fragment>
      <div ref={imageContainer} className={css.container} style={{ height: '100%' }} />

      {commandsState.step === 'select_commands' && (
        <CommandSelectModal
          commands={commandsState.commands}
          onClose={resetCommandsState}
          onSelect={(commands) => selectCommands(commands)}
        />
      )}

      {commandsState.step === 'enter_args' && (
        <CommandsArgumentsModal commands={commandsState.commands} onClose={resetCommandsState} />
      )}
    </React.Fragment>
  )
}

const queryNode = (parent: HTMLDivElement, id: string) => {
  // querySelector can throw SYNTAX_ERR exception if node_id is invalid
  try {
    const node: SVGElement = parent.querySelector('#' + id)
    return node
  } catch (error) {
    console.error(error)
    return null
  }
}

interface ICommandsState {
  commands: CommandOptions[]
  step: 'select_commands' | 'enter_args'
}

interface Props {
  id: number
  lastResize: number
  settings: ISVGSettings
}

export default SVGDiagram
