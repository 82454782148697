import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { IPlace } from 'au-nsi/shared'
import { Coordinates } from '../Nsi/nsi.interfaces'
import { Equipment } from 'au-nsi/equipment'
import { ParameterDn } from '../Parameters/params.interfaces'
import { getAttributeValue } from '../../shared/Attributes/attributes.utils'

export interface IMapBounds {
  sw: Coordinates
  ne: Coordinates
}

export const isAddressValid = (address: IPlace) => {
  return address && address.lng && address.lat && address.name
}

export const selectEquipmentWithValidPlace = createSelector(
  (state: ReduxState) => state.nsi.equipment,
  (equipment) => equipment.filter((e) => isAddressValid(e.address))
)

/**
 * Извлечь из атрибутов настройки того, значения каких параметров должны отображаться
 * в информационном окне при клике по устройству
 */
export const getInfoWindowParameters = (device: Equipment, parametersMap: Map<string, ParameterDn>) => {
  const attrValue = getAttributeValue(device.attributes as any, 'map.infowindow_parameters')
  if (!attrValue || !Array.isArray(attrValue)) return null

  const ids = attrValue as any as string[]
  const parameters = ids.map((id) => parametersMap.get(id)).filter((p) => p != null)

  return parameters.length > 0 ? parameters : null
}
