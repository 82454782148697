import { CommandArgSchema } from 'au-nsi/commands'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextInput from '../../../shared/Inputs/TextInput'
import Toggle from '../../../shared/Inputs/Toggle'
import { convertValueToType } from '../commands.utils'

interface Props {
  id: string
  value: any
  editing: boolean
  required: boolean
  arg_schema: CommandArgSchema
  onChange: (value: any, id: string) => void
}

const CommandArgInput = ({ id, value, editing, required, arg_schema, onChange }: Props) => {
  const type = arg_schema.type
  const val = convertValueToType(value, type)

  if (type === 'string') {
    return (
      <TextInput
        name={id}
        value={val}
        onChange={onChange}
        disabled={!editing}
        disabledClassName="system__input-disabled"
        required={required}
      />
    )
  }

  if (type === 'number') {
    return (
      <NumberInput
        type="number"
        name={id}
        value={val}
        onChange={onChange}
        disabled={!editing}
        disabledStyle="text"
        required={required}
        allowUndefined={!required}
        fullWidth={true}
      />
    )
  }

  if (type === 'boolean') {
    return <Toggle name={id} checked={val} onChange={onChange} />
  }

  return null
}

export default CommandArgInput
