import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import rolesActions from '../../../../../Collections/Roles/roles.actions'
import UserForm from '../../../../../Collections/Users/UserForm'
import { useIntl } from 'react-intl'
import InstanceRow from '../../../../../Catalogs/components/InstanceForm/InstanceRow'
import css from '../../../../../Collections/Users/users.module.css'
import InputRow from '../../../../../../shared/Inputs/InputRow'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import HelpTooltip from '../../../../../../shared/HelpTooltip/HelpTooltip'
import styles from './userRequestForm.module.css'
import { RegistrationRequest } from 'au-nsi/moderation'

const UserRequestForm = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [request, setRequest] = useRecoilState<RegistrationRequest>(selectedDraftRequestState as any)

  const registerWithOrganization =
    request.request_body.created_organization_name && request.request_body.created_organization_properties
  const roles = useSelector((state: ReduxState) => state.roles.items)
  const organizationTypes = useSelector((state: ReduxState) => state.organizations.organizations)

  const catalogs = useSelector((state: ReduxState) => state.catalogs.catalogs)

  useEffect(() => {
    dispatch(rolesActions.loadItems())
  }, [])

  const handleChange = (value: any, key: string) => {
    setRequest({ ...request, request_body: { ...request.request_body, [key]: value } })
  }

  const handleOrganizationChange = useCallback((schemaId: string, value: any) => {
    setRequest((prev) => ({
      ...prev,
      request_body: {
        ...prev.request_body,
        created_organization_properties: { ...prev.request_body.created_organization_properties, [schemaId]: value },
      },
    }))
  }, [])

  const renderName = () => {
    if (!props.editing) return request.request_body.created_organization_name

    return (
      <TextInput
        value={request.request_body.created_organization_name}
        name={'created_organization_name'}
        onChange={handleChange}
      />
    )
  }

  const organizationType = organizationTypes.find((o) => o.id === request.request_body.organization_type_id)
  const organizationRows =
    registerWithOrganization &&
    organizationType &&
    organizationType.organization_schema.map((schema) => (
      <InstanceRow
        key={schema.id}
        rowSchema={schema}
        catalogs={catalogs}
        editing={props.editing}
        onChange={handleOrganizationChange}
        value={request.request_body.created_organization_properties[schema.id]}
      />
    ))

  return (
    <>
      <h3 className={styles.subheader} style={{ marginTop: 0 }}>
        {intl.formatMessage({ id: 'system.auth.user' })}
      </h3>
      <UserForm
        user={request.request_body}
        editing={props.editing}
        onChange={handleChange}
        roles={roles}
        allowChangingOrgType={false}
        allowChangingCredentials={false}
      />
      {registerWithOrganization && organizationType && (
        <div>
          <h3 className={styles.subheader}>
            {intl.formatMessage({ id: 'user.request.user.create_organization' })}
            <HelpTooltip position={'top'}>
              {intl.formatMessage({ id: 'user.request.user.create_organization.description' })}
            </HelpTooltip>
          </h3>
          <InputRow label={intl.formatMessage({ id: 'common.name' })} tableMode={true}>
            {renderName()}
          </InputRow>
          <table className={css.userDataTable} style={{ marginBottom: 0 }}>
            <tbody>{organizationRows}</tbody>
          </table>
        </div>
      )}
    </>
  )
}

interface Props {
  editing: boolean
}

export default UserRequestForm
