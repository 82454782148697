import React from 'react'

/**
 * Вызвать обработчик cb при изменении размеров элемента ref
 */
const useResize = (ref: React.RefObject<Element>, cb: () => void) => {
  React.useEffect(() => {
    if (ref.current) {
      const clear = onResize(ref, cb)
      return () => clear()
    }
  }, [ref.current])
}

export const onResize = (ref: React.RefObject<Element>, cb: () => void) => {
  cb()

  let clear = null
  const ResizeObserver = (window as any).ResizeObserver

  if (ResizeObserver) {
    const observer = new ResizeObserver(cb)
    observer.observe(ref.current)
    clear = () => observer.disconnect()
  } else {
    window.addEventListener('resize', cb)
    clear = () => window.removeEventListener('resize', cb)
  }

  return clear
}

export default useResize
