import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { atom, useRecoilState } from 'recoil'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextInput from '../../../../shared/Inputs/TextInput'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import moderationActions from '../moderation.actions'
import { rejectRequest } from '../moderation.api'
import { IModerationRequest } from 'au-nsi/moderation'

export const rejectModalState = atom<IModerationRequest>({ key: 'ModerationRejectModalState', default: null })

/**
 * Модальное окно подтверждения отклонения заявки
 */
const RejectModal = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()

  const [request, setRequest] = useRecoilState(rejectModalState)
  const [reason, setReason] = useState('')

  if (!request) return null

  const handleClose = () => setRequest(null)

  const handleReject = () => {
    if (loading) return

    wait(rejectRequest(request, reason), (resp) => {
      setRequest(null)
      if (!resp) return

      dispatch(moderationActions.requestUpdated(resp))
    })
  }

  return (
    <Modal onClose={handleClose}>
      <h2>{intl.formatMessage({ id: 'user.request.reject_title' })}</h2>

      <InputRow label={intl.formatMessage({ id: 'user.request.reject_reason' })}>
        <TextInput name="" value={reason} onChange={setReason} required={false} />
      </InputRow>

      <ModalFooter
        loading={loading}
        onCancel={handleClose}
        onSave={handleReject}
        saveMessage={intl.formatMessage({ id: 'user.request.reject' })}
      />
    </Modal>
  )
}

export default RejectModal
