import React from 'react'
import { ReactComponent as ObjectIcon } from '../../../icons/object.svg'
import RegionControls from './RegionControls'
import RowControls from './RowControls'
import { EquipmentType, Region } from 'au-nsi/equipment'

const RegionRow = (props: Props) => {
  const ref = React.useRef<HTMLSpanElement>()
  const { region } = props

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', 'region:' + region.id)
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDragOver = (e) => e.preventDefault()

  const handleDragEnter = () => {
    ref.current.style.background = 'var(--bg-selected)'
  }

  const handleDragLeave = () => {
    ref.current.style.background = null
  }

  const handleDrop = (e) => {
    e.preventDefault()
    handleDragLeave()

    const source = e.dataTransfer.getData('text/plain')
    props.onDrop(source, 'region:' + region.id)
  }

  return (
    <span
      className="tpl-node__padding tpl-node-inline-flex"
      data-id={region.id}
      draggable={props.allowEditing}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      ref={ref}
    >
      <ObjectIcon
        style={{ marginRight: '10px', width: 20, height: 20, color: colors[props.regionState], flexShrink: 0 }}
      />
      <span>{region.name}</span>

      <div className="nsi__row-controls">
        <RegionControls
          allowCreating={props.allowCreating}
          onCreateEquipment={props.onCreateDevice}
          onCreateRegion={props.onCreateRegion}
        />
        <RowControls
          allowChangingState={props.allowChangingState}
          allowDeleting={props.allowDeleting}
          allowMoving={props.allowEditing}
          allowReordering={props.allowEditing}
          onDelete={props.onDelete}
          onMove={props.onMove}
          onStart={props.onStart}
          onStop={props.onStop}
        />
      </div>
    </span>
  )
}

// индикация цветом состояния региона
// 3 - есть устройства с ошибкой
// 2 - есть устройства с предупреждением
// 1 - есть запущенные устройства
// 0 - по умолчанию
const colors = ['var(--color)', 'var(--text-success)', 'var(--warning)', 'var(--danger-text)']

interface Props {
  allowChangingState: boolean
  allowCreating: boolean
  allowDeleting: boolean
  allowEditing: boolean
  onCreateDevice: (protocol: string, type: EquipmentType) => void
  onCreateRegion: () => void
  onDelete: () => void
  onDrop: (source: string, target: string) => void
  onMove: () => void
  onStart: () => void
  onStop: () => void
  region: Region
  regionState: number
  selectedId: number
}

export default React.memo(RegionRow)
