import http, { handleHttpError } from '../../../utils/http'
import { actions } from './audio.reducers'
import { IAudio } from 'au-nsi/audio'

const PATH = `/nsi/v1/audio/`

const loadAudio = () => (dispatch, getState) => {
  const state = getState().audio
  if (state.isLoaded) return

  http
    .get(PATH)
    .then((r) => dispatch(actions.setItems(r.data)))
    .catch(handleHttpError)
}

const uploadFile = (file: Omit<IAudio, 'created_at' | 'updated_at' | 'id'>) => (dispatch) => {
  return http
    .post(PATH, file)
    .then((r) => dispatch(actions.itemCreated(r.data)))
    .catch(handleHttpError)
}

const updateAudio = (item: IAudio) => (dispatch) => {
  http
    .put(PATH + item.id, item)
    .then((r) => dispatch(actions.itemUpdated(r.data)))
    .catch(handleHttpError)
}

const deleteAudio = (id: string) => (dispatch) => {
  http
    .delete(PATH + id)
    .then(() => dispatch(actions.itemDeleted(id)))
    .catch(handleHttpError)
}

const moveAudio = (sourceId: string, targetId: string) => (dispatch, getState) => {
  if (sourceId === targetId) return

  const items = getState().audio.items
  const sourceIndex = items.findIndex((d) => d.id === sourceId)
  const targetIndex = items.findIndex((d) => d.id === targetId)

  const updates = [{ id: sourceId, user_ordering_index: items[targetIndex].user_ordering_index }]
  const step = sourceIndex > targetIndex ? 1 : -1

  for (let i = targetIndex; i * step < sourceIndex * step; i += step) {
    updates.push({ id: items[i].id, user_ordering_index: items[i + step].user_ordering_index })
  }

  dispatch(actions.itemsUpdated(updates))
  http.post(PATH + `reorder`, { audio: updates }).catch(handleHttpError)
}

const audioActions = { ...actions, loadAudio, uploadFile, updateAudio, deleteAudio, moveAudio }
export default audioActions
