export type LoginStatus = 'unknown' | 'logged_in' | 'logged_out' | 'server_error'

export interface AuthState {
  status: LoginStatus
  accessToken: string
  accessExpires: number
}

const SET_TOKEN = 'app/auth/SET_TOKEN'
const LOGOUT = 'app/auth/LOGOUT'
const SERVER_ERROR = 'app/auth/SERVER_ERROR'

const defaultState: AuthState = {
  status: 'unknown',
  accessToken: '',
  accessExpires: 0,
}

const authReducer = (state = defaultState, action: any): AuthState => {
  switch (action.type) {
    case SET_TOKEN:
      if (process.env.NODE_ENV !== 'production') {
        localStorage.setItem('accessToken', action.token)
      }

      return { status: 'logged_in', accessToken: action.token, accessExpires: action.expires }
    case LOGOUT:
      return { status: 'logged_out', accessToken: '', accessExpires: 0 }
    case SERVER_ERROR:
      return { ...defaultState, status: 'server_error' }
    default:
      return state
  }
}

export default authReducer

export const actions = {
  setToken: (token: string, expires: number) => ({ type: SET_TOKEN, token, expires }),
  logout: () => ({ type: LOGOUT }),
  serverError: () => ({ type: SERVER_ERROR }),
}
