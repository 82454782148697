import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router'
import http from '../../../utils/http'
import { loadDocsModule } from './docs.loader'

/**
 * Страница пользовательской документации. Компонент рендерит только контейнер,
 * само содержание страницы рендерит динамически подгружаемый скрипт из микросервиса au-docs
 */
const HelpDocsPage = () => {
  const ref = useRef<any>()
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const render = () => {
    const onLocationChange = (nextLocation: string) => navigate(nextLocation)
    const path = location.pathname

    const el = ref.current
    if (el) el.props = { http, lang: intl.locale, location: path, onLocationChange }
  }

  useEffect(() => loadDocsModule(render), [location])

  return (
    <div style={{ height: 'calc(100vh - 50px)' }}>
      <au-docs ref={ref}></au-docs>
    </div>
  )
}

export default HelpDocsPage
