import { ITableSettings } from 'au-nsi/dashboards'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { ReduxState } from '../../../redux/store.types'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import './table.styles.css'
import TableContent from './TableContent'

/**
 * Компонент дашборда для вывода данных в табличной форме
 */
const Table = (props: Props) => {
  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  // запрос всех указанных в настройках параметров и устройств
  React.useEffect(() => {
    const selectors = []
    const parameters = props.settings.columns.map((c) => c.parameter_id)

    for (const id of props.settings.equipment) {
      selectors.push({ device_id: id, parameters: [...parameters] })
    }

    service.setDataSelectors(selectors)
  }, [props.settings])

  const store = useSelector((state: ReduxState) => {
    return {
      equipment: selectDataSources(state),
      parameters: selectDenormalizedParametersMap(state),
      units: state.parameters.units,
      prefixes: state.parameters.prefixes,
    }
  }, shallowEqual)

  // выбранные устройства выводить в том же порядке, в каком они расположены в топологии
  const selectedDevices = React.useMemo(() => {
    const selected = new Set(props.settings.equipment)

    return store.equipment.filter((e) => selected.has(e.id))
  }, [store.equipment, props.settings.equipment])

  // по умолчанию таблица растягивается на весь компонент, но если
  // пользователь слишком сильно уменьшил компонент, то фиксируем
  // минимальный размер таблицы и добавляем скролл
  const minWidth =
    props.settings.display === 'column'
      ? (props.settings.columns.length + 1) * 60
      : (props.settings.equipment.length + 1) * 30
  const minHeight =
    props.settings.display === 'column'
      ? (props.settings.equipment.length + 1) * 30
      : (props.settings.columns.length + 1) * 60

  return (
    <div className="dashboard-table__wrapper">
      <table className="dashboard-table" style={{ minHeight, minWidth }}>
        <tbody>
          <TableContent
            equipment={selectedDevices}
            parameters={store.parameters}
            prefixes={store.prefixes}
            service={service}
            settings={props.settings}
            units={store.units}
          />
        </tbody>
      </table>
    </div>
  )
}

interface Props {
  id: number
  settings: ITableSettings
}

export default Table
