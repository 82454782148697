import { useEffect, useRef, useState } from 'react'
import { handleHttpError } from '../utils/http'

const useHttpLoader = () => {
  const [loading, setLoading] = useState(false)
  const canceled = useRef<boolean>()

  useEffect(() => {
    return () => {
      canceled.current = true
    }
  }, [])

  const wait = <T>(p: Promise<T>, onLoad?: (v: T) => void, onError?: (err) => void) => {
    setLoading(true)

    return p
      .then((r) => {
        if (!canceled.current) {
          onLoad && onLoad(r)
          setLoading(false)
        }
      })
      .catch((err) => {
        if (!canceled.current) {
          onError ? onError(err) : handleHttpError(err)
          setLoading(false)
        }
      })
  }

  return { loading, wait }
}

export default useHttpLoader
