import { IDocument } from 'au-nsi/documents'
import { IAccessSettings } from 'au-nsi/shared'
import { ReactComponent as OpenIcon } from 'icons/download.svg'
import { ReactComponent as AccessIcon } from 'icons/lock.svg'
import { memo, useState } from 'react'
import AccessModal from '../../../../../../shared/Access/AccessModal'
import PlusButton from '../../../../../../shared/Forms/PlusButton'
import RemoveButton from '../../../../../../shared/Forms/RemoveButton'
import { formatMemory } from '../../../../../../utils/misc'
import DocumentIcon from '../../DocumentIcon'
import styles from '../documentsPanel.module.css'

interface IDocumentsEditingListProps {
  draftDocuments: Partial<IDocument>[]
  allowAccessChange: boolean
  onChange: <T extends keyof IDocument>(value: IDocument[T], key: T, index: number) => void
  onDocumentClick: (doc: Partial<IDocument>) => void
  onRemove: (index: number) => void
  onAdd: () => void
}

const DocumentsEditingList = ({
  draftDocuments,
  onRemove,
  onDocumentClick,
  onAdd,
  onChange,
  allowAccessChange,
}: IDocumentsEditingListProps) => {
  return (
    <div>
      <table className="nsi-table is-compact">
        <thead>
          <tr>
            <th style={{ width: '24px' }} />
            <th />
            <th style={{ width: '35px' }} />
            <th style={{ width: '24px' }} />
            {allowAccessChange && <th style={{ width: '24px' }} />}
          </tr>
        </thead>
        <tbody>
          {draftDocuments.map((doc, i) => (
            <DocumentRow
              allowAccessChange={allowAccessChange}
              key={doc.id ?? doc.name}
              doc={doc}
              index={i}
              onDocumentClick={onDocumentClick}
              onChange={onChange}
              onRemove={onRemove}
            />
          ))}
        </tbody>
      </table>
      <PlusButton onClick={onAdd} textId={'common.add'} style={{ marginLeft: 0 }} />
    </div>
  )
}

const DocumentRow = memo(
  ({
    doc,
    index,
    allowAccessChange,
    onDocumentClick,
    onRemove,
    onChange,
  }: {
    doc: Partial<IDocument>
    index: number
    allowAccessChange: boolean
    onChange: <T extends keyof IDocument>(value: IDocument[T], key: T, index: number) => void
    onDocumentClick: (doc: Partial<IDocument>) => void
    onRemove: (index: number) => void
  }) => {
    const [accessModalOpen, setAccessModalOpen] = useState(false)

    const handleAccessChange = (access: IAccessSettings) => {
      onChange(access, 'access', index)
      setAccessModalOpen(false)
    }

    return (
      <tr className={styles.documentsListItem}>
        {accessModalOpen && (
          <AccessModal access={doc.access} onSave={handleAccessChange} onCancel={() => setAccessModalOpen(false)} />
        )}
        <td>
          <DocumentIcon document={doc as IDocument} style={{ width: '24px', height: '24px' }} />
        </td>
        <td>
          <span>
            {doc.name}.{doc.extension}
          </span>
          {doc.size_bytes && <span className="text--gray">, {formatMemory(doc.size_bytes / 1024)}</span>}
        </td>
        {allowAccessChange && (
          <td>
            <AccessIcon onClick={() => setAccessModalOpen(true)} className={styles.documentsListDownloadButton} />
          </td>
        )}

        <td>
          <OpenIcon onClick={() => onDocumentClick(doc)} className={styles.documentsListDownloadButton} />
        </td>

        <td style={{ verticalAlign: 'bottom' }}>
          <RemoveButton onRemove={() => onRemove(index)} />
        </td>
      </tr>
    )
  }
)

export default DocumentsEditingList
