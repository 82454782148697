import classnames from 'classnames'
import { ReactComponent as Envelope } from 'icons/envelope.svg'
import { ReactComponent as Alert } from 'images/icons/alert.svg'
import { ReactComponent as Bell } from 'images/icons/bell.svg'
import { useEffect, useRef, useState } from 'react'
import css from './ntf.module.css'

interface Props {
  count: number
  icon: 'user' | 'system' | 'incidents'
  size: number
  color?: string
}

/**
 * Иконка уведомления с количеством сообщений
 */
const NtfIcon = ({ count, color, size, icon }: Props) => {
  const prevCount = useRef<number>()
  const [flash, setFlash] = useState(false)

  // мигание при увеличении счетчика
  useEffect(() => {
    if (prevCount.current != null && count > prevCount.current) setFlash(true)
    prevCount.current = count
  }, [count])

  const countClass = classnames(css.iconCounter, { [css.flash]: flash })

  return (
    <div className={css.icon} style={{ color }}>
      {icon === 'user' && <Envelope width={size} />}
      {icon === 'system' && <Bell width={size} />}
      {icon === 'incidents' && <Alert width={size} />}

      {count > 0 && (
        <div className={countClass} onAnimationEnd={() => setFlash(false)}>
          {count}
        </div>
      )}
    </div>
  )
}

export default NtfIcon
