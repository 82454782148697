import { IRoute } from '../TopMenu/Nav/nav.utils'
import ProfilePage from './Profile/ProfilePage'
import ModerationPage from './Moderation/ModerationPage'

const userRoutes: IRoute[] = [
  { id: 'system.profile', path: 'profile', component: ProfilePage },
  {
    id: 'user.all_requests',
    path: 'requests',
    component: ModerationPage,
    modules: ['moderation'],
  },
]

export default userRoutes
