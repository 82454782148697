import { IGanttSettings } from 'au-nsi/dashboards'
import React from 'react'
import GanttService from '../../services/gantt/gantt.service'
import { Boundaries } from '../../shared/interfaces'

export interface IGanttContext {
  bodyRect: Boundaries
  service: GanttService
  setBodyRect: (rect: Boundaries) => void
  settings: IGanttSettings
}

const GanttContext = React.createContext<IGanttContext>({
  bodyRect: null,
  service: null,
  setBodyRect: () => null,
  settings: null,
})

export default GanttContext
