import { OrganizationTypeWithInstances } from '../../Catalogs/Organizations/organizations.types'
import Modal from '../../../shared/Modal/Modal'
import { useState } from 'react'
import { createOrganization } from '../../Catalogs/Organizations/organizations.utils'
import { useIntl } from 'react-intl'
import TextInput from '../../../shared/Inputs/TextInput'
import InputRow from '../../../shared/Inputs/InputRow'
import css from '../../Collections/Users/users.module.css'
import InstanceRow from '../../Catalogs/components/InstanceForm/InstanceRow'
import FormButtons from '../../../shared/Forms/FormButtons'
import { AnyCatalogWithInstances } from '../../Catalogs/catalogs.types'
import { Organization } from 'au-nsi/organizations'

interface IProps {
  organizationType: OrganizationTypeWithInstances
  onSave: (organization: Organization) => void
  onClose: () => void
  depsCatalogs: AnyCatalogWithInstances[]
}

const OrganizationModal = ({ organizationType, onClose, onSave, depsCatalogs }: IProps) => {
  const intl = useIntl()
  const [draftOrganization, setDraftOrganization] = useState(createOrganization(organizationType))
  const [error, setError] = useState<string>('')

  const handleChange = (value: any, name: string) => setDraftOrganization({ ...draftOrganization, [name]: value })

  const handleOrganizationChange = (schemaId: string, value: any) =>
    setDraftOrganization({
      ...draftOrganization,
      organization_properties: { ...draftOrganization.organization_properties, [schemaId]: value },
    })

  const handleSave = () => {
    if (validateOrganization(organizationType, draftOrganization)) {
      onSave(draftOrganization)
    } else {
      setError('router.errors.all_fields_must_be_filled')
    }
  }

  const organizationForm =
    organizationType &&
    organizationType.organization_schema.map((schema) => (
      <InstanceRow
        key={schema.id}
        rowSchema={schema}
        catalogs={depsCatalogs}
        editing={true}
        onChange={handleOrganizationChange}
        value={draftOrganization.organization_properties[schema.id]}
      />
    ))

  const renderErrorMessage = () => {
    if (!error) return null

    return (
      <div style={{ marginTop: '1em', textAlign: 'right' }} className="text--danger">
        {intl.formatMessage({ id: error })}
      </div>
    )
  }

  return (
    <Modal onClose={onClose} size={'lg'}>
      <h2>{intl.formatMessage({ id: 'auth.signup.create_organization' })}</h2>

      <InputRow label={intl.formatMessage({ id: 'nsi.user.organization' })} tableMode={true}>
        <TextInput value={draftOrganization.name} name={'name'} onChange={handleChange} />
      </InputRow>

      <table className={css.userDataTable} style={{ marginBottom: 0 }}>
        <tbody>{organizationForm}</tbody>
      </table>
      {renderErrorMessage()}
      <FormButtons
        style={{ marginBottom: 0, display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
        mode={'edit'}
        onEdit={undefined}
        onEditSave={handleSave}
        onCancel={onClose}
        allowEditing={true}
        allowDeleting={false}
      />
    </Modal>
  )
}

const validateOrganization = (organizationType: OrganizationTypeWithInstances, organization: Organization): boolean => {
  if (!organization?.name || !organizationType) return false

  for (const schema of organizationType.organization_schema) {
    if (
      schema.required &&
      (organization.organization_properties[schema.id] === undefined ||
        organization.organization_properties[schema.id] === null ||
        organization.organization_properties[schema.id] === '')
    ) {
      return false
    }
  }

  return true
}

export default OrganizationModal
