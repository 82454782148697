import { TooltipState } from './Tooltip'
import { IWindroseData } from './windrose.utils'
import { speedColors } from './windrose.utils'
import css from './windrose.module.css'

/**
 * Отрисовка углов и скоростей в детальном режиме
 */
const WindroseDetailed = (props: Props) => {
  const { data, center, radius } = props
  if (!data) return null

  const alpha = Math.PI / data.angles.length
  const sectors = []

  for (const angleBucket of data.angles) {
    for (let i = angleBucket.speeds.length - 1; i >= 0; i -= 1) {
      const speedBucket = angleBucket.speeds[i]

      if (speedBucket.count_speed === 0) {
        continue
      }

      const degrees = (180 * angleBucket.angle) / Math.PI
      const r = (radius * speedBucket.count_cumulative) / data.count_max
      const y = center - r * Math.cos(alpha)

      let d = `M${center},${center} `
      d += `L${center - r * Math.sin(alpha)},${y} `
      d += `A${radius},${r},0,0,1,${center + r * Math.sin(alpha)},${y}`
      d += 'Z'

      const transform = `rotate(${degrees} ${center} ${center})`
      const fill = speedColors[speedBucket.speed + 1]

      sectors.push(
        <path
          className={css.sectorPath}
          key={sectors.length}
          d={d}
          transform={transform}
          fill={fill}
          color={fill}
          data-angle={angleBucket.angle}
          data-speed={speedBucket.speed}
          data-percent={speedBucket.count_speed / data.count_total}
        />
      )
    }
  }

  const grid = data.angles.map(({ angle }) => {
    return (
      <line
        key={angle}
        x1={center}
        y1={center}
        x2={center - radius * Math.sin(angle - alpha)}
        y2={center - radius * Math.cos(angle - alpha)}
        stroke="rgba(0, 0, 0, 1)"
      />
    )
  })

  // при наведении на участок, отправляем соответствующие ему значения для отображения в попапе
  const handleMouseOver = (e) => {
    const { angle, speed, percent } = e.target.dataset

    const data = angle ? { angle: +angle, speed: +speed, percent: +percent } : null
    props.onHover(data)
  }

  return (
    <g onMouseOver={handleMouseOver} onMouseLeave={() => props.onHover(null)}>
      {sectors}
      {grid}
    </g>
  )
}

interface Props {
  data: IWindroseData
  center: number
  radius: number
  onHover: (data: TooltipState) => void
}

export default WindroseDetailed
