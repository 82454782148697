import { Select } from '@alterouniversal/au-react-components'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import { CSSProperties } from 'react'
import Dropdown, { DropdownOption } from '../Inputs/Dropdown'
import NumberInput from '../Inputs/NumberInput'
import PasswordInput from '../Inputs/PasswordInput'
import TextInput from '../Inputs/TextInput'

/**
 * Утилита для перевода объекта с описанием инпута (текстовое поле, число, выпадающий список и т.д.)
 * в действительный компонент. Используется для отрисовки форм с большим кол-вом полей, котрые можно задать
 * в объекте конфигурации и не отрисовывать каждое отдельное поле.
 */
export const renderInput = (options: RenderInputOptions) => {
  if (!options.editing) {
    return <span className={options.disabledWrapperClass}>{extractValue(options.input, options.data)}</span>
  }

  const { input, data, onChange } = options

  switch (input.type) {
    case 'text':
    case 'string':
      return <TextInput name={input.key} value={data[input.key]} onChange={onChange} formatter={input.formatter} />
    case 'number':
    case 'parameter':
      return <NumberInput name={input.key} value={data[input.key]} onChange={onChange} {...input} />
    case 'select':
      return (
        <Select
          name={input.key}
          onChange={onChange}
          options={input.options}
          value={data[input.key]}
          disabled={input.disabled}
          required={input.required}
          error={input.error}
        />
      )
    case 'dropdown':
      return <Dropdown name={input.key} value={data[input.key]} options={input.options} onChange={onChange} />
    case 'password':
      return (
        <PasswordInput
          autocomplete={input.autocomplete}
          value={data[input.key]}
          name={input.key}
          onChange={onChange}
          className={input.className}
          required={input.required}
        />
      )
    default:
      return null
  }
}

const extractValue = (input: InputOptions, data) => {
  if (input.type === 'select') {
    const option = input.options.find((o) => o.value === data[input.key])
    return option ? option.label : data[input.key]
  }

  if (input.type === 'dropdown') {
    const option = input.options.find((o) => o.value === data[input.key])
    return option ? option.title : data[input.key]
  }

  if (input.type === 'password') {
    const length = data[input.key]?.length || 0
    return '*'.repeat(length)
  }

  return data[input.key]
}

interface RenderInputOptions {
  data: { [key: string]: any }
  input: InputOptions
  editing: boolean
  onChange: (v: any, key: string) => void
  disabledWrapperClass?: string
}

interface TextInputOptions {
  type: 'text' | 'string'
  key: string
  formatter?: (v: string) => string
}

interface NumberInputOptions {
  type: 'number'
  key: string
  style?: CSSProperties
  allowUndefined?: boolean
  fullWidth?: boolean
  integer?: boolean
  max?: number
  min?: number
}

interface ParameterInputOptions {
  type: 'parameter'
  key: string
  basePrefix?: SiPrefix
  baseUnit: SiUnit
  displayPrefix?: SiPrefix
  displayUnit: SiUnit
  fullWidth?: boolean
  max?: number
  min?: number
  prefixes: Record<string, SiPrefix>
  units: Record<string, SiUnit>
}

interface SelectInputOptions {
  type: 'select'
  key: string
  options: { value: any; label: string }[]
  disabled?: boolean
  required?: boolean
  error?: string
}

interface DropdownInputOptions {
  type: 'dropdown'
  key: string
  options: Array<DropdownOption<any>>
  isVirtual?: boolean
}

interface PasswordOptions {
  type: 'password'
  key: string
  required?: boolean
  className?: string
  autocomplete?: string
}

export type InputOptions =
  | DropdownInputOptions
  | NumberInputOptions
  | ParameterInputOptions
  | SelectInputOptions
  | TextInputOptions
  | PasswordOptions
