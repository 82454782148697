import { OrganizationTypeWithInstances } from '../../../organizations.types'
import { IntlShape, useIntl } from 'react-intl'
import SchemaTableWrapper from '../../../../components/SchemaForm/SchemaTableWrapper/SchemaTableWrapper'
import SchemaRow from '../../../../components/SchemaForm/SchemaRow'
import { useSelector } from 'react-redux'
import { typeOptions } from '../../../../catalogs.utils'
import { Fragment, useMemo } from 'react'
import PlusButton from '../../../../../../shared/Forms/PlusButton'
import styles from '../../../organizations.module.css'
import { NameAndRoleControls } from './NameAndRoleControls'
import { RegisterControls } from './RegisterControls'
import { selectAllCatalogs, selectSchemas, selectTypes } from '../../../../catalogs.selectors'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { OrganizationType } from 'au-nsi/organizations'

interface IProps {
  editing: boolean
  organizationType: OrganizationType | OrganizationTypeWithInstances

  onMetaChange: (value: any, name: string) => void
  onChange: (updatedRow: IPropertyDescription, schemaKey: string) => void
  onMove: (index1: number, index2: number, schemaKey: string) => void
  onRemove: (id: string, schemaKey: string) => void
  onCreateProperty: (schemaKey: string) => void
}

const OrganizationTypeForm = ({ editing, organizationType, onMetaChange, onCreateProperty, ...rowProps }: IProps) => {
  const intl = useIntl()

  const catalogs = useSelector(selectAllCatalogs)
  const schemas = useSelector(selectSchemas)
  const types = useSelector(selectTypes)

  const { catalogsOptions, userTypesOptions } = useMemo(
    () => ({
      catalogsOptions: schemas.map((c) => ({ label: c.name, value: c.id })),
      userTypesOptions: types.map((c) => ({ label: c.name, value: c.id })),
    }),
    [types, schemas]
  )
  const locTypeOptions = useMemo(() => typeOptions(intl), [])

  const renderTables = () => {
    const renderHeader = (key: string) => {
      if (key === 'registration_schema') {
        return (
          <h3 style={{ marginBottom: '20px' }}>{intl.formatMessage({ id: 'catalogs.organization.register_data' })}</h3>
        )
      }
      return (
        <h3 style={{ marginBottom: '20px', marginTop: organizationType.accept_registration ? '40px' : 0 }}>
          {intl.formatMessage({ id: 'catalogs.organization.schema' })}
        </h3>
      )
    }

    return ['registration_schema', 'organization_schema'].map((schemaKey) => (
      <Fragment key={schemaKey}>
        {renderHeader(schemaKey)}
        <SchemaTableWrapper editing={editing} key={schemaKey} renderDefaultValue={schemaKey === 'organization_schema'}>
          {schemaKey === 'registration_schema' && renderDefaultRegisterProps(intl, editing)}
          {(organizationType[schemaKey] as IPropertyDescription[]).map((schema, index) => (
            <SchemaRow
              index={index}
              rowName={schemaKey}
              key={schema.id}
              row={schema}
              catalogs={catalogs}
              catalogsOptions={catalogsOptions}
              userTypesOptions={userTypesOptions}
              typeOptions={locTypeOptions}
              {...rowProps}
              editing={editing}
              renderDefaultValue={schemaKey === 'organization_schema'}
            />
          ))}
        </SchemaTableWrapper>
        {editing && (
          <PlusButton onClick={() => onCreateProperty(schemaKey)} textId={'common.add'} style={{ marginLeft: 0 }} />
        )}
      </Fragment>
    ))
  }

  return (
    <>
      <NameAndRoleControls
        organizationType={organizationType}
        onChange={onMetaChange}
        editing={editing}
        intl={intl}
        shouldRenderName={true}
      />
      <RegisterControls organizationType={organizationType} onChange={onMetaChange} editing={editing} intl={intl} />
      {renderTables()}
    </>
  )
}

const renderDefaultRegisterProps = (intl: IntlShape, editing: boolean) => {
  const className = editing ? styles.defaultPasswordTd : ''
  return (
    <>
      <tr draggable={false}>
        {editing && <td />}
        <td className={className}>{intl.formatMessage({ id: 'nsi.user.name' })}</td>
        <td className={className}>{intl.formatMessage({ id: 'catalogs.text' })}</td>
        <td className={className}>{intl.formatMessage({ id: 'common.yes' })}</td>
      </tr>
      <tr draggable={false}>
        {editing && <td />}
        <td className={className}>E-Mail</td>
        <td className={className}>{intl.formatMessage({ id: 'catalogs.text' })}</td>
        <td className={className}>{intl.formatMessage({ id: 'common.yes' })}</td>
      </tr>

      <tr draggable={false}>
        {editing && <td />}
        <td className={className}>{intl.formatMessage({ id: 'nsi.user.password' })}</td>
        <td className={className}>{intl.formatMessage({ id: 'catalogs.text' })}</td>
        <td className={className}>{intl.formatMessage({ id: 'common.yes' })}</td>
      </tr>
    </>
  )
}

export default OrganizationTypeForm
