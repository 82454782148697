import { UserRole } from 'au-nsi/user'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as LockIcon } from '../../../icons/lock.svg'
import { ReactComponent as AdminIcon } from '../../../icons/user-shield.svg'
import { ReduxState } from '../../../redux/store.types'
import { selectAccessRights } from '../../App/app.selectors'
import { loadOrganizations } from '../../Catalogs/Organizations/organizations.actions'
import { User } from '../collection.interfaces'
import rolesActions from '../Roles/roles.actions'
import RolesForm from '../Roles/RolesForm'
import UserInfo from '../Users/UserInfo'
import usersActions from '../Users/users.actions'
import { selectGrouppedUsers } from '../Users/users.selectors'
import UsersTree from './UsersTree'
import styles from './usersTree.module.css'

export const UsersPage = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { users, roles, isLoaded, selectedRole, selectedUser, creating, rights } = useSelector((state: ReduxState) => ({
    users: selectGrouppedUsers(state),
    roles: state.roles.items,
    isLoaded: state.users.isLoaded && state.roles.isLoaded,
    selectedRole: state.roles.selectedItemId,
    selectedUser: state.users.selectedItemId,
    creating: state.users.mode === 'create' || state.roles.mode === 'create',
    rights: selectAccessRights(state),
  }))
  const [selectedType, setSelectedType] = useState<'user' | 'role' | undefined>(undefined)

  useEffect(() => {
    !creating && setSelectedType(selectedRole ? 'role' : selectedUser ? 'user' : undefined)
  }, [creating])

  useEffect(() => {
    dispatch(rolesActions.loadAccessRights())
    dispatch(usersActions.loadItems())
    dispatch(rolesActions.loadItems())
    dispatch(loadOrganizations())
  }, [])

  const handleRoleSelect = (id: UserRole['id']) => {
    if (!creating) {
      dispatch(usersActions.selectItem(undefined))
      dispatch(rolesActions.selectItem(id))
      setSelectedType('role')
    }
  }

  const handleUserSelect = (id: User['id']) => {
    if (!creating) {
      dispatch(rolesActions.selectItem(undefined))
      dispatch(usersActions.selectItem(id))
      setSelectedType('user')
    }
  }

  const handleUserCreate = () => {
    if (!creating) {
      setSelectedType('user')
      dispatch(rolesActions.selectItem(undefined))
      dispatch(usersActions.setMode('create'))
    }
  }

  const handleRoleCreate = () => {
    if (!creating) {
      setSelectedType('role')
      dispatch(usersActions.selectItem(undefined))
      dispatch(rolesActions.setMode('create'))
    }
  }

  // иконка для админской роли
  const renderRoleIcon = (role: UserRole) => {
    if (role.id === 1) {
      return (
        <AdminIcon
          className={styles.icon}
          style={{ margin: '0 10px 0 auto' }}
          title={intl.formatMessage({ id: 'nsi.user.admin_role_info' })}
        />
      )
    }

    return null
  }

  // иконки для админа и заблокированных пользователей
  const renderUserIcon = (user: User) => {
    if (user.suspended) {
      return <LockIcon className={styles.icon} title={intl.formatMessage({ id: 'nsi.user.suspend_warning_short' })} />
    }

    if (user.id === 1) {
      return <AdminIcon className={styles.icon} title={intl.formatMessage({ id: 'nsi.user.admin_info' })} />
    }

    return null
  }

  return (
    <>
      <UsersTree
        allowRolesCreate={rights['roles:create']}
        allowUsersCreate={rights['users:create']}
        isCreating={creating}
        isLoaded={isLoaded}
        onRoleCreate={handleRoleCreate}
        onRoleSelect={handleRoleSelect}
        onUserCreate={handleUserCreate}
        onUserSelect={handleUserSelect}
        renderRoleIcon={renderRoleIcon}
        renderUserIcon={renderUserIcon}
        roles={roles}
        selectedRoleId={selectedRole}
        selectedUserId={selectedUser}
        users={users}
        treeKey="UsersTree:UsersPage"
        title={intl.formatMessage({ id: 'nav.users' })}
      />
      {selectedType === 'user' && <UserInfo />}
      {selectedType === 'role' && <RolesForm />}
    </>
  )
}
