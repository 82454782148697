import { Equipment, ModbusParametersMapping, ModbusTCPClient } from 'au-nsi/equipment'

// сколько регистров занимает указанный тип данных
const sizes = {
  bool: 1,
  byte: 1,
  sbyte: 1,
  short: 1,
  ushort: 1,
  int: 2,
  uint: 2,
  float: 2,
  long: 4,
  ulong: 4,
  double: 4,
}

/**
 * Поиск пересекающихся регистров, т.к. они могут означать ошибку конфигурации
 */
export const findRegisterOverlaps = (mapping: ModbusParametersMapping[]) => {
  const overlaps = new Set<number>() // номера строк в которых найдены пересекающиеся регистры

  const bounds = findRegisterBounds(mapping)

  // список всех регистров от минимального до максимального с указанием какой строкой используется этот регистр
  const registers: number[][] = bounds.map(({ min, max }) =>
    min && max && max - min < 2048 ? new Array(max - min).fill(null) : []
  )

  for (let i = 0; i < mapping.length; i++) {
    const { table, register, type } = mapping[i]

    // в одном регистре может быть 16 булевых значений, поэтому для них не проверяем пересечения
    if (type === 'bool') continue

    const r = registers[table]
    const b = bounds[table]
    const size = sizes[type]
    if (!r || !b || !size) continue

    // один параметр может занимать несколько регистров, для каждого из них проверяем
    // не используется ли этот регистр другим параметром
    for (let j = 0; j < size; j++) {
      const position = register + j - b.min

      if (r[position] == null) r[position] = i
      else overlaps.add(r[position]).add(i)
    }
  }

  return overlaps
}

// минимумы и максимумы для каждой из четырех (0,1,3,4) таблиц модбаса
const findRegisterBounds = (mapping: ModbusParametersMapping[]) => {
  const bounds = Array.from({ length: 5 }, () => ({ min: null, max: null }))

  for (const { table, register } of mapping) {
    const b = bounds[table]
    if (b == null || register == null) continue

    if (b.min == null || register < b.min) b.min = register
    if (b.max == null || register > b.max) b.max = register
  }

  return bounds
}

// форматирование номеров строк, содержащих пересекающиеся регистры
export const formatOverlaps = (overlaps: Set<number>) => {
  const lines = Array.from(overlaps)
  return lines.map((n) => n + 1).join(', ')
}

/**
 * Виртуальные модбас устройства при включенной опции publish_with_root_id отправляют свои данные
 * с id корневого устройства. В такой ситуации необходимо проверить, что конфигурация
 * данных всех таких устройств не содержит одинаковых параметров.
 */
export const selectUsedParameters = (id: string, equipment: Map<string, Equipment>) => {
  const usedParameters = new Map<string, string>() // parameter_id -> device name
  const self = equipment.get(id)

  const rootId = self.path ? self.path.split('.')[0] : id
  const root = equipment.get(rootId) as ModbusTCPClient

  const checkOverlap = root.configuration.publish_with_root_id
  if (!checkOverlap) return usedParameters

  for (const device of equipment.values()) {
    if (device.id === id) continue

    const shouldInclude = (device.path && device.path.startsWith(rootId)) || device.id === rootId
    if (!shouldInclude) continue

    for (const { parameter_id } of device.parameters_mapping) {
      usedParameters.set(parameter_id, device.name)
    }
  }

  return usedParameters
}

export const findParametersOverlap = (usedParameters: Map<string, string>, mapping: ModbusParametersMapping[]) => {
  if (!usedParameters.size) return null

  for (const { parameter_id } of mapping) {
    const usedInDevice = usedParameters.get(parameter_id)

    if (usedInDevice) return { device: usedInDevice, parameter: parameter_id }
  }

  return null
}
