import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as utils from '../../../redux/reducer.utils'
import { IAudio } from 'au-nsi/audio'

export interface AudioState {
  items: IAudio[]
  isLoaded: boolean
}

const initialState: AudioState = {
  items: [],
  isLoaded: false,
}

const slice = createSlice({
  name: 'app/audio',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<IAudio[]>) => {
      state.items = action.payload
      state.isLoaded = true
    },
    itemCreated: (state, action: PayloadAction<IAudio>) => {
      if (state.isLoaded) {
        utils.appendItem(state.items, action.payload)
      }
    },
    itemUpdated: (state, action: PayloadAction<Partial<IAudio>>) => {
      utils.updateItem(state.items, action.payload)
    },
    itemsUpdated: (state, action: PayloadAction<Array<Partial<IAudio>>>) => {
      utils.updateItems(state.items, action.payload)
    },
    itemDeleted: (state, action: PayloadAction<string>) => {
      utils.deleteItem(state.items, action.payload)
    },
  },
})

export const actions = slice.actions

export default slice.reducer
