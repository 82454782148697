import { DataMap, IDataChunk, Timespan } from './data.types'

/**
 * Найти индекс ближайшего по времени элемента, -1 если не найден
 * В лучшем случае O(1) - если все элементы расположены через равные промежутки,
 * в худшем случае - O(N)
 * w - допустимый выход за границы массива по времени, мс
 * preferPrevious - возвращать индекс для значения с ts не большим, чем переданный
 */
export const findIndex = (arr: number[], ts: number, w = 0, preferPrevious = true): number => {
  const lastIndex = arr.length - 1
  if (lastIndex < 0) return -1

  const t0 = arr[0]
  const t1 = arr[lastIndex]
  if (ts < t0 && ts > t0 - w) return 0
  if (ts > t1 && ts < t1 + w) return lastIndex
  if (ts < t0 || ts > t1) return -1

  // бесконечное окно используется для нерегулярных параметров, поэтому в таком случае
  // текущее предположение не работает и переходим к обычному бинарному поиску
  if (w === Number.POSITIVE_INFINITY) return bsearch(arr, ts)

  const interval = (t1 - t0) / lastIndex

  // индекс элемента со временем ts, если бы все элементы располагались через interval
  let index = Math.round((ts - t0) / interval)

  const step = ts - arr[index] > 0 ? +1 : -1
  let nextIndex = index + step

  while (nextIndex >= 0 && nextIndex <= lastIndex && Math.abs(arr[index] - ts) > Math.abs(arr[nextIndex] - ts)) {
    index = nextIndex
    nextIndex += step
  }

  return preferPrevious && index > 0 && arr[index] > ts ? index - 1 : index
}

export const bsearch = (arr: number[], ts: number): number => {
  let i0 = 0
  let i1 = arr.length - 1

  while (i1 - i0 > 1) {
    const center = i0 + Math.floor((i1 - i0) / 2)
    const value = arr[center]
    if (value === ts) return center

    if (ts < value) i1 = Math.max(center - 1, i0 + 1)
    else i0 = Math.min(center + 1, i1 - 1)
  }

  // always select elements smaller than ts
  return arr[i1] <= ts ? i1 : arr[i0] <= ts ? i0 : i0 - 1
}

export const findBoundaries = (arr: number[], t0: number, t1: number) => {
  if (arr.length === 0) return { i0: -1, i1: -1 }

  const i0 = arr[0] < t0 ? findIndex(arr, t0, 0, false) : 0
  const i1 = arr[arr.length - 1] > t1 ? findIndex(arr, t1, 0, false) : arr.length - 1

  return i0 !== -1 && i1 !== -1 ? { i0, i1 } : { i0: -1, i1: -1 }
}

export const timespan = (data: DataMap): Timespan => {
  let t0 = Number.MAX_SAFE_INTEGER
  let t1 = 0

  for (const { ts } of data.values()) {
    if (ts.length > 0) {
      if (ts[0] < t0) {
        t0 = ts[0]
      }

      if (ts[ts.length - 1] > t1) {
        t1 = ts[ts.length - 1]
      }
    }
  }

  return { t0, t1 }
}

/**
 * Найти последнюю точку в которой все указанные параметры не равны null
 */
export const selectLastDefinedPoint = (chunks: IDataChunk[], parameters: string[]) => {
  const result: { [key: string]: number } = { ts: null }

  for (const p of parameters) {
    result[p] = null
  }

  for (let j = chunks.length - 1; j >= 0; j--) {
    const chunk = chunks[j]

    if (chunk.i0 === -1 || chunk.i1 === -1) continue

    for (let i = chunk.i1; i >= chunk.i0; i--) {
      for (const p of parameters) {
        const value = chunk.data[p][i]

        if (value == null) continue
        else result[p] = value
      }

      result.ts = chunk.data.ts[i]
      return result
    }
  }

  return null
}
