import React, { CSSProperties } from 'react'

class TextInput extends React.Component<TextInputProps> {
  private defaultFormatter = (v: string) => {
    if (!v) return ''

    return v.trim()
  }

  private handleChange = (e) => {
    let value = e.target.value

    if (this.props.formatter) {
      value = this.props.formatter(value)
    }

    this.props.onChange(value, this.props.name)
  }

  private handleBlur = () => {
    const value = this.props.value ?? ''
    const formatter = this.props.formatter || this.defaultFormatter
    const formatted = formatter(value)

    if (formatted !== value) {
      this.props.onChange(formatted, this.props.name)
    }
  }

  render() {
    const required = this.props.required ?? true
    const value = this.props.value ?? this.props.defaultValue ?? ''

    if (this.props.disabled) {
      return <div className={this.props.disabledClassName}>{value}</div>
    }

    return (
      <input
        defaultValue={this.props.defaultValue}
        type={this.props.type || ''}
        className="nsi-input"
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        required={required}
        autoFocus={this.props.autofocus}
        value={value}
        style={this.props.style}
        placeholder={this.props.placeholder}
        minLength={this.props.minlength}
        maxLength={this.props.maxlength}
      />
    )
  }
}

export interface TextInputProps {
  value: string
  name: string
  onChange: (val: string, name: string) => void
  formatter?: (val: string) => string
  disabled?: boolean
  disabledClassName?: string
  required?: boolean // default true
  autofocus?: boolean
  type?: string
  style?: CSSProperties
  placeholder?: string
  maxlength?: number
  minlength?: number
  defaultValue?: string
}

export default TextInput
