import { FormMode } from '../../shared/interfaces'
import { generateReducers } from '../Collections/collection.reducers'
import { createTransmitter } from './transmitters.factory'
import { Transmitter } from './transmitters.types'

export interface TransmittersState {
  mode: FormMode
  items: Transmitter[]
  selectedItem: Transmitter
  selectedItemId: string
  isLoaded: boolean
}

const defaultState: TransmittersState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
}

const reducers = generateReducers<Transmitter>('transmitters', createTransmitter)

const transmittersReducer = (state: TransmittersState = defaultState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default transmittersReducer
