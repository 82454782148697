import { PropsWithChildren, useRef } from 'react'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import TextInput from '../../../../../shared/Inputs/TextInput'
import RemoveButton from '../../../../../shared/Forms/RemoveButton'
import useDnD from '../../../../../hooks/useDnD'
import usePopup from '../../../../../hooks/usePopup'
import { useIntl } from 'react-intl'
import styles from './schemaGroupWrapper.module.css'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface Props {
  path: string
  editing: boolean

  group: IPropertyDescription
  onInsertIntoGroup: (groupId: IPropertyDescription['id'], propertyId: IPropertyDescription['id']) => void
  onUpdate: (property: IPropertyDescription) => void
  onCreate: (id: IPropertyDescription['id'], type: IPropertyDescription['type']) => void
  onDelete: (id: IPropertyDescription['id']) => void
  onMove: (from: IPropertyDescription['id'], to: IPropertyDescription['id']) => void
}

const SchemaGroupWrapper = (props: PropsWithChildren<Props>) => {
  const intl = useIntl()
  const rowRef = useRef()
  const controlsRef = useRef()
  const { open, setOpen } = usePopup(controlsRef)

  const dndHandlers = useDnD({
    id: props.group.id,
    onDrop: (id) => props.onInsertIntoGroup(props.group.id, id),
    prefix: 'catalogs:',
    ref: rowRef,
    style: {},
  })

  const renderName = () => {
    if (!props.editing) {
      return (
        <div className={`${styles.groupHeader} ${styles.colWrapper}`}>
          <span>{props.path} </span>
          {props.group.name}
        </div>
      )
    }

    return (
      <TextInput
        style={{ marginTop: '25px', background: 'var(--bg-550)' }}
        value={props.group.name}
        name={''}
        onChange={(name) => props.onUpdate({ ...props.group, name })}
      />
    )
  }

  const renderControls = () => {
    return (
      <div className={`relative ${styles.colWrapper}`} ref={controlsRef}>
        <PlusIcon className={styles.plusIcon} onClick={() => setOpen(true)} />
        {open && (
          <div className={styles.dropdownMenu}>
            <div
              className="nsi-dropdown__item"
              onClick={() => {
                props.onCreate(props.group.id, 'group')
                setOpen(false)
              }}
            >
              {intl.formatMessage({ id: 'catalogs.add_group' })}
            </div>
            <div
              className="nsi-dropdown__item"
              onClick={() => {
                props.onCreate(props.group.id, 'string')
                setOpen(false)
              }}
            >
              {intl.formatMessage({ id: 'catalogs.add_schema' })}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <tr draggable={props.editing} ref={rowRef} {...dndHandlers} style={{ background: 'inherit' }}>
        {props.editing && (
          <td className={styles.groupPath}>
            <div className={styles.colWrapper}>{props.path}</div>
          </td>
        )}
        <td colSpan={3}>{renderName()}</td>
        {props.editing && <td style={{ textAlign: 'center' }}>{renderControls()}</td>}
        {props.editing && (
          <td>
            <RemoveButton className={styles.colWrapper} onRemove={() => props.onDelete(props.group.id)} />
          </td>
        )}
      </tr>
      {props.children}
    </>
  )
}

export default SchemaGroupWrapper
