import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Virtuoso } from 'react-virtuoso'
import { ReactComponent as SoundIcon } from '../../../icons/sound.svg'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'
import IncidentDetails from '../../Incidents/components/IncidentDetails'
import IncidentTime from '../../Incidents/components/IncidentTime'
import { setSelectedIncident } from '../../Incidents/incident.actions'
import { DenormalizedIncident } from '../../Incidents/incident.interfaces'
import { searchIncidents } from '../../Incidents/incidents.utils'

export class AlertsList extends React.PureComponent<Props, State> {
  state: State = { search: '' }

  private handleItemClick = (e) => {
    const id = +e.currentTarget.dataset.id

    this.props.dispatch(setSelectedIncident(id))
    this.props.navigate('/dashboards/alerts/list')
    this.props.onClose()
  }

  private handleSearch = (search: string) => {
    this.setState({ search })
  }

  private filterIncidents() {
    const words = this.state.search.toLowerCase().split(' ')
    return searchIncidents(this.props.incidents, words)
  }

  private renderItem = (incidents: DenormalizedIncident[], index) => {
    const item = incidents[index]
    const className = 'alert-item alert-card ' + item.level

    return (
      <div key={item.id} className="alert-item__wrapper">
        <div className={className} data-id={item.id} onClick={this.handleItemClick}>
          <div className="alert-item__header">
            <div className="alerts-text is-bold">{item.title}</div>
            <IncidentTime incident={item} />
          </div>

          <div className="alerts-text">{item.text}</div>

          <div className="alert-item__footer">
            <IncidentDetails details={item.details} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const applySearch = this.state.search.length > 2
    const items = applySearch ? this.filterIncidents() : this.props.incidents
    const emptyMessage = applySearch ? 'common.no_results' : 'nav.notifications.empty_list'

    // рассчет высоты уведомления с индексом index (т.к. высота зависит от количества устройств
    // и параметров в деталях уведомления)
    const getItemSize = (index: number) => {
      const item = items[index]
      let h = 92 // базовая высота шапки + отступы

      for (const device of Object.values(item.details)) {
        const values = Object.values(device.parameters)
        if (values.length === 0) h += 8

        for (const parameter of values) {
          // добавляем высоту строки таблицы для каждого параметра (если инцидент завершен и для него
          // посчитаны мин и макс значения, то две строки)
          h += parameter.stats.length > 0 ? 40 : 24
        }
      }

      return h
    }

    const isMobile = window.innerWidth < 600
    const maxHeight = isMobile ? window.innerHeight - 100 : Math.round(window.innerHeight * 0.6)

    const height = getListHeight(items, getItemSize, maxHeight)

    return (
      <>
        <div className="alerts__list-header">
          <SearchInput onChange={this.handleSearch} />

          {this.props.isPlaying && <SoundIcon className="alerts__list-icon" onClick={this.props.onPause} />}
        </div>

        <Virtuoso style={{ height }} totalCount={items.length} itemContent={(index) => this.renderItem(items, index)} />

        {items.length === 0 && (
          <div className="center text--gray" style={{ minHeight: '32px' }}>
            <FormattedMessage id={emptyMessage} />
          </div>
        )}
      </>
    )
  }
}

// рассчет высоты списка уведомлений - если уведомлений много, то используем максимально
// допустимую высоту, иначе используем сумму высот элементов списка
const getListHeight = (items, getItemSize, maxHeight) => {
  if (items.length > 5) return maxHeight

  const height = items.reduce((h, _, index) => h + getItemSize(index), 0) + 8
  return Math.min(height, maxHeight)
}

interface State {
  search: string
}

interface Props {
  dispatch: (action: any) => void
  navigate: (to: string) => void
  incidents: DenormalizedIncident[]
  isPlaying: boolean
  onClose: () => void
  onPause: () => void
}

export default AlertsList
