import { generateActions } from '../Collections/collection.actions'
import { Parameter } from 'au-nsi/parameters'
import * as C from './params.reducers'

const PARAMETERS_URL = '/nsi/v1/parameters/'

const actions = generateActions<Parameter>('parameters', PARAMETERS_URL)

const toggleShowIds = () => ({ type: C.TOGGLE_SHOW_IDS })

const paramsActions = { ...actions, toggleShowIds }

export default paramsActions
