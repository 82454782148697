import { Incident } from 'au-nsi/signal-events'
import { useIntl } from 'react-intl'
import { formatDate } from '../../../utils/lang'
import { SignalEvent } from '../../SignalEvents/se.interfaces'
import { IncidentAckState } from '../incident.interfaces'
import * as utils from '../incidents.utils'
import AckButton from './AckButton'

const IncidentsTableFooter = (props: Props) => {
  const intl = useIntl()
  const { incident } = props
  const state = utils.getAckState(incident, props.rules)

  if (state === IncidentAckState.None) return null

  // если требуется подтверждение, то выводим сообщение и кнопку для квитирования
  if (state === IncidentAckState.WaitingAck) {
    return (
      <div className="alerts-table__cell-footer">
        <div>{intl.formatMessage({ id: 'alerts.ack_wait' })}</div>
        <AckButton id={incident.id} level={props.level} />
      </div>
    )
  }

  // если уже взято в работу, то выводим время и пользователя
  const time = formatDate(incident.ack_ts, 'HH:mm:ss')

  return (
    <div className="alerts-table__cell-footer">
      <div>{intl.formatMessage({ id: 'alerts.acked' })}</div>
      <div>
        {props.users[incident.ack_user]}
        <span style={{ padding: '0 8px' }}>|</span>
        {time}
      </div>
    </div>
  )
}

interface Props {
  incident: Incident
  level: string
  rules: Map<number, SignalEvent>
  users: { [id: number]: string }
}

export default IncidentsTableFooter
