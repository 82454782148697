import { useDeviceDashboards } from 'pages/MapTopology/DeviceScreen/DeviceDashboards/DeviceDashboards'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import usePopup from '../../../hooks/usePopup'
import styles from './templateControls.module.css'
import { ITemplateControlsLink } from './templateControls.types'

interface IProps {
  title: string
  deviceId: string
  currentTemplateId: string
}

/**
 * Выпадающее меню для навигации по шаблонам экрана (для перехода из одного шаблона на другой без
 * необходимости возвращаться на карточку устройства)
 */
const TemplateControls = ({ title, deviceId, currentTemplateId }: IProps) => {
  const intl = useIntl()
  const ref = useRef()
  const dashboards = useDeviceDashboards(deviceId)
  const { open, setOpen } = usePopup(ref)

  useEffect(() => setOpen(false), [currentTemplateId])

  const renderLinks = () => {
    const links: Array<ITemplateControlsLink> = dashboards.map((d) => ({
      id: d.id,
      name: d.name,
      link: `/map/device/${deviceId}/dashboards/${d.id}`,
    }))

    return links
      .filter((d) => d.id !== currentTemplateId)
      .map((d) => (
        <Link key={d.id} className={`nsi-dropdown__item ${styles.link}`} to={d.link}>
          {d.name}
        </Link>
      ))
  }

  return (
    <div ref={ref} onClick={() => setOpen(true)} className={styles.container} data-open={open}>
      <span className="line_clamp">{title}</span>
      {open && (
        <div className="nsi-dropdown contextmenu" style={{ minWidth: '100%', width: 'fit-content' }}>
          <Link className={`nsi-dropdown__item ${styles.link}`} to={`/map/device/${deviceId}/dashboards`}>
            {intl.formatMessage({ id: 'dashboards.templates.to_main' })}
          </Link>
          {renderLinks()}
        </div>
      )}
    </div>
  )
}

export default TemplateControls
