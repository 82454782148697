import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import GroupSelectorModal from '../Modals/GroupSelectorModal'
import { patchEquipment } from '../nsi.actions'
import { selectPhysicalPath } from '../nsi.selectors'

/**
 * Компонент отображающий расположение устройства в иерархии регионов (групп)
 * с возможностью переместить в другую группу по клику на расположение
 */
const DevicePath = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const regions = useSelector((state: ReduxState) => state.nsi.regions)
  const path = useSelector(selectPhysicalPath)

  const [showModal, setShowModal] = useState(false)

  const openModal = () => props.allowEditing && setShowModal(true)
  const closeModal = () => setShowModal(false)

  const handleSelect = (region_id: number) => {
    const action = patchEquipment(props.deviceId, { region_id })
    dispatch(action)
    closeModal()
  }

  const location = path.slice(1).join(' / ')

  return (
    <div className="editable-text__wrapper">
      <span className={'editable-text'} onClick={openModal}>
        {location === '' ? intl.formatMessage({ id: 'nsi.location.root' }) : location}
      </span>

      {showModal && (
        <GroupSelectorModal
          groups={regions}
          onClose={closeModal}
          onSelect={handleSelect}
          textSelect="common.move"
          textTitle="common.movein"
        />
      )}
    </div>
  )
}

interface Props {
  deviceId: string
  allowEditing: boolean
}

export default React.memo(DevicePath)
