import React from 'react'
import { useIntl } from 'react-intl'
import usePopup from '../../hooks/usePopup'
import { ReactComponent as PlusIcon } from '../../icons/plus.svg'

const types = ['string', 'number', 'object', 'parameter', 'device']

const AttributeDropdown = (props: Props) => {
  const intl = useIntl()
  const ref = React.useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  const toggleOpen = () => setOpen(!open)

  const handleSelect = (e) => {
    props.onSelect(e.target.dataset.type)
    setOpen(false)
  }

  const options = types.map((type) => {
    return (
      <div key={type} data-type={type} className="nsi-dropdown__item" onClick={handleSelect}>
        {intl.formatMessage({ id: 'attributes.type.' + type })}
      </div>
    )
  })

  const menu = <div className="nsi-dropdown">{options}</div>

  return (
    <div className="attribute__dropdown" ref={ref}>
      <div onClick={toggleOpen}>
        <PlusIcon style={{ width: 24, height: 24, color: 'var(--primary-80)' }} />
        <span style={{ paddingLeft: '6px' }}>{intl.formatMessage({ id: 'attributes.add_field' })}</span>
      </div>

      {open && menu}
    </div>
  )
}

interface Props {
  onSelect: (type: string) => void
}

export default AttributeDropdown
