import { IUserTask } from '../../../userTasks.types'
import { CSSProperties, PropsWithChildren, useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'
import styles from './userTaskFormWrapper.module.css'
import RemoveButton from '../../../../../shared/Forms/RemoveButton'
import { useDispatch } from 'react-redux'
import { ReactComponent as MinimizeIcon } from 'icons/window-minimize.svg'
import userTasksActions from '../../../userTasks.actions'
import { useRecoilState } from 'recoil'
import { userTaskFormWrapperState, activeUserTaskFormIdState } from './userTaskFormWrapper.recoil'
import ResizeBorder from './ResizeBorder'
import UserTaskDescription from '../../UserTaskDescription/UserTaskDescription'
import userTaskEvents from '../../../userTasks.events'

interface Props {
  task: IUserTask
}

/**
 * Компонент-обёртка для вкладки пользователького таска. Рендерит верхнюю панель, кнопки закрытия и сворачивания.
 *
 * Отвечает за drag и resize.
 * Состояние вкладок (размер, положение и активную) хранит в recoil стейте.
 */
const UserTaskFormWrapper = ({ task, ...props }: PropsWithChildren<Props>) => {
  const dispatch = useDispatch()

  const ref = useRef<HTMLDivElement>()
  const headerRef = useRef<HTMLDivElement>()

  const [activeId, setActiveId] = useRecoilState(activeUserTaskFormIdState)
  const [positions, setPositions] = useRecoilState(userTaskFormWrapperState)

  const setActive = () => setActiveId(task.id)

  const handleDragStart = (ev) => {
    ev.preventDefault()
    setActive()
    const bbox = headerRef.current.getBoundingClientRect()

    const dx = ev.pageX - bbox.left
    const dy = ev.pageY - bbox.top

    const moveAt = (e) => {
      const updates = {
        x: e.x - dx,
        y: e.y - dy,
      }

      updates.x = Math.min(Math.max(0, updates.x), window.innerWidth - bbox.width)
      updates.y = Math.max(0, updates.y)

      setPositions((prev) => ({
        ...prev,
        [task.id]: { ...prev[task.id], ...updates },
      }))
    }

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', moveAt)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    window.addEventListener('mousemove', moveAt)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleResize = useCallback(
    (width, height) => {
      setPositions((prev) => ({
        ...prev,
        [task.id]: {
          ...prev[task.id],
          height: Math.min(Math.max(300, height), document.documentElement.clientHeight * 0.82) + 'px',
          width: Math.max(300, width) + 'px',
        },
      }))
    },
    [setPositions]
  )

  // Header окна
  const handleRemove = () => {
    dispatch(userTasksActions.deleteUserTask(task))
    userTaskEvents.next({ type: 'cancel', id: task.id })
  }

  const handleMinimize = () => dispatch(userTasksActions.disableTask(task.id))

  const FormHeader = (
    <div className={styles.taskFormHeader} ref={headerRef} draggable={true} onDragStart={handleDragStart}>
      <UserTaskDescription task={task} className="line_clamp" />
      <MinimizeIcon className={styles.taskFormHeaderMinimizeIcon} onClick={handleMinimize} />
      <RemoveButton onRemove={handleRemove} />
    </div>
  )

  const dataId = task.id + task.created_at
  const taskSelector = `div[data-id="${dataId}"]`
  const Form = (
    <div
      className={styles.taskForm}
      style={generatePositionStyle(positions[task.id], activeId === task.id)}
      ref={ref}
      data-id={dataId}
      onClick={setActive}
    >
      {FormHeader}
      <div className={styles.taskFormContent}>
        <ResizeBorder onResize={handleResize} parentSelector={taskSelector} />
        <div className={styles.taskFormBodyWrapper}>{props.children} </div>
      </div>
    </div>
  )

  return createPortal(Form, document.getElementsByTagName('body')[0])
}

const generatePositionStyle = (
  cords: {
    x: number
    y: number
    width: CSSProperties['width']
    height: CSSProperties['height']
  },
  isActive
): CSSProperties => {
  if (!cords) return {}

  return {
    left: cords.x + 'px',
    top: cords.y + 'px',
    height: cords.height || '',
    width: cords.width || '',
    zIndex: isActive ? 100 : 99, // в .taskForm классе zIndex === 99
  }
}

export default UserTaskFormWrapper
