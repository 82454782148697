import { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { ReactComponent as ArrowRight } from '../../icons/arrow-right.svg'
import styles from './utils.module.css'

export interface IPrevButtonProps {
  onCLick: () => void
  className?: string
  arrowStyle?: CSSProperties
  style?: CSSProperties
  textId?: string
  disabled?: boolean
}

const PrevButton = ({
  onCLick,
  className = '',
  style,
  textId = 'system.maps.prev',
  arrowStyle,
  disabled,
}: IPrevButtonProps) => {
  const intl = useIntl()

  return (
    <div onClick={onCLick} className={styles.prevButton + ' ' + className} style={style} data-disabled={disabled}>
      <ArrowRight className={styles.prevButtonIcon} style={arrowStyle} />
      <span>{intl.formatMessage({ id: textId })}</span>
    </div>
  )
}

export default PrevButton
