import { IIndicatorSettings } from 'au-nsi/dashboards'
import React from 'react'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { API_URL, COLORS } from '../../../shared/constants'
import { matchers } from '../condition.utils'
import css from './indicator.module.css'
import { matchersFor2 } from './indicator.utils'

const Indicator = (props: Props) => {
  const { settings } = props
  const { type } = settings

  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  const container = React.useRef<HTMLDivElement>()

  const [color, setColor] = React.useState('')
  const [action, setAction] = React.useState('none')
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  const width = Math.min(size.width, size.height)
  const height = width

  const imageId = settings.type === 'image' ? settings.image_id : null
  const imageUrl = `url(${API_URL}/nsi/v1/images/${imageId}/raw)`

  const applyCondition = (c: { color: string; action: string }) => {
    setColor(c.color)
    setAction(c.action)
  }

  React.useEffect(() => {
    if (settings.conditions.length === 0) {
      return (service.onTick = () => null)
    }

    const selectors = [{ device_id: settings.device_id, parameters: [settings.parameter_id] }]
    service.setDataSelectors(selectors)

    const fallback = { color: COLORS.gray, action: 'none' }
    const defaultCondition = settings.conditions.find((c) => c.operator === 'default') || fallback
    const nodataCondition = settings.conditions.find((c) => c.operator === 'nodata') || fallback

    const conditions = settings.conditions.filter((c) => c.operator !== 'nodata' && c.operator !== 'default')

    const onTick = () => {
      const data = service.selectCurrentPoint(settings.device_id)
      const value = data && data[settings.parameter_id]

      if (value == null) {
        return applyCondition(nodataCondition)
      }

      for (const condition of conditions) {
        let matched = false

        if (condition.operator === 'in') {
          matched = matchersFor2['in'](value, condition.range[0], condition.range[1])
        } else if (condition.operator === 'out') {
          matched = matchersFor2['out'](value, condition.range[0], condition.range[1])
        } else {
          matched = matchers[condition.operator](value, condition.value)
        }

        if (matched) return applyCondition(condition)
      }

      applyCondition(defaultCondition)
    }

    service.onTick = onTick
    onTick()
  }, [settings])

  React.useEffect(() => {
    const containerSize = container.current.getBoundingClientRect()
    setSize({ width: Math.floor(containerSize.width), height: Math.floor(containerSize.height) })
  }, [props.lastResize])

  const background = color ? color : null
  const borderRadius = type === 'circle' ? '50%' : null
  const className = css[action]

  const showTitle = size.width - width >= 90

  return (
    <div ref={container} className={css.container}>
      {imageId && (
        <div
          className={className + ' ' + css.image}
          style={{ width, height, background: color, maskImage: imageUrl }}
        />
      )}

      {(type === 'square' || type === 'circle') && (
        <div className={className} style={{ background, width, height, borderRadius }} />
      )}

      <div className={css.title}>{showTitle && settings.title}</div>
    </div>
  )
}

interface Props {
  id: number
  lastResize: number
  settings: IIndicatorSettings
}

export default Indicator
