import { MouseEvent } from 'react'
import { useNavigate } from 'react-router'

/**
 * Обработка клика и переход к ресурсу при клике по элементу типа
 * <span class="resource-link" data-resource="catalogs" data-id="1">Some text</span>
 */
export const useLinkHandler = () => {
  const navigate = useNavigate()

  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof HTMLSpanElement && e.target.className === 'resource-link') {
      e.preventDefault()
      e.stopPropagation()

      const { id, resource } = e.target.dataset

      switch (resource) {
        case 'catalogs':
          return navigate(`/catalogs/schemas/${id}`)
        case 'catalog_items':
          return navigate(`/catalogs/schemas/${e.target.dataset.catalog}/instance/${id}`)
        case 'equipment':
          return navigate(`/map/device/${id}/main`)
        default:
          break
      }
    }
  }

  return handleClick
}
