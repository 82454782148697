import { Incident } from 'au-nsi/signal-events'
import i18n from '../../translations/i18n'
import { ParameterDn } from '../Parameters/params.interfaces'
import { formatValue } from '../Parameters/params.utils'
import { SignalEvent, SignalEventRule } from '../SignalEvents/se.interfaces'
import { DenormalizedIncident, IncidentAckState, IIncidentDetails } from './incident.interfaces'

/**
 * Получить id устройств по которым произошел инцидент
 */
export const getIncidentDevices = (incident: Incident | DenormalizedIncident): string[] => {
  switch (incident.type) {
    case 'dq_irremovable':
    case 'dq_unreliable':
    case 'dq_ZeroValues':
    case 'formula':
      return Object.keys(incident.details)
    default:
      return []
  }
}

export const getIncidentParameters = (incident: Incident): Record<string, string[]> => {
  const result: Record<string, string[]> = {}

  switch (incident.type) {
    case 'dq_irremovable':
    case 'dq_unreliable':
    case 'formula':
      for (const [id, parameters] of Object.entries(incident.details)) {
        result[id] = Object.keys(parameters)
      }
      break
    case 'dq_ZeroValues':
      for (const id of Object.keys(incident.details)) {
        result[id] = []
      }
      break
  }

  return result
}

/**
 * Получить описание инцидента
 */
export const getIncidentDescription = (
  incident: Incident,
  se: Map<number, SignalEvent>,
  lang: string
): { title: any; text: any } => {
  const messages = i18n.getMessages()

  switch (incident.type) {
    case 'dq_irremovable':
      return { title: messages[lang]['alerts.dq_irremovable'], text: '' }
    case 'dq_unreliable':
      return { title: messages[lang]['alerts.dq_unreliable'], text: '' }
    case 'dq_ZeroValues':
      return { title: messages[lang]['alerts.dq_ZeroValues'], text: '' }
    case 'formula':
      const event = se.get(incident.formula_id)
      const title = event ? event.name : ''
      const text = event ? event.description : ''
      return { title, text }
    default:
      return { title: 'UNEXPECTED', text: 'FIX ME' }
  }
}

export const getIncidentDetails = (incident: Incident, options: IDetailsOptions): IIncidentDetails[] => {
  switch (incident.type) {
    case 'dq_irremovable':
      return getIrremovableDetails(incident, options)
    case 'dq_unreliable':
    case 'formula':
      return getFormulaDetails(incident, options)
    case 'dq_ZeroValues':
      return getZeroValuesDetails(incident, options)
    default:
      return []
  }
}

const getFormulaDetails = (incident: Incident, options: IDetailsOptions): IIncidentDetails[] => {
  return Object.entries(incident.details || {}).map(([device_id, details]) => {
    const device_name = options.devices[device_id] || ''
    const parameters: IIncidentDetails['parameters'] = []

    for (const [parameter_id, values] of Object.entries(details)) {
      if (!values || typeof values !== 'object') continue

      const parameter = options.parameters.get(parameter_id)
      const parameter_name = parameter?.name || ''
      const parameter_value = formatValue(values.value, parameter)
      const stats = [{ name: 'current', value: parameter_value }]

      // после завершения инцидента ему добавляется статистика по минимальному, максимальному
      // и среднему значениям
      if (incident.ts_end) {
        const min = formatValue(values.min, parameter, false)
        const max = formatValue(values.max, parameter, false)
        const avg = formatValue(values.avg, parameter, false)

        stats.push({ name: 'min', value: min }, { name: 'max', value: max }, { name: 'avg', value: avg })
      }

      parameters.push({ parameter_id, parameter_name, parameter_value, stats })
    }

    return { device_id, device_name, parameters }
  })
}

const getIrremovableDetails = (incident: Incident, options: IDetailsOptions): IIncidentDetails[] => {
  return Object.entries(incident.details).map(([device_id, details]) => {
    const device_name = options.devices[device_id]

    const parameters = Object.keys(details).map((parameter_id) => {
      const parameter = options.parameters.get(parameter_id)
      const parameter_name = parameter?.name

      return { parameter_id, parameter_name, parameter_value: null, stats: [] }
    })

    return { device_id, device_name, parameters }
  })
}

const getZeroValuesDetails = (incident: Incident, options: IDetailsOptions): IIncidentDetails[] => {
  return Object.keys(incident.details).map((device_id) => {
    const device_name = options.devices[device_id]
    return { device_id, device_name, parameters: [] }
  })
}

interface IDetailsOptions {
  parameters: Map<string, ParameterDn>
  devices: { [id: string]: string }
  lang: string
}

/**
 * Получить уровень опасности инцидента (предупреждение, тревога)
 */
export const getIncidentLevel = (incident: Incident, se: Map<number, SignalEvent>): string => {
  if (incident.ts_end !== 0 && incident.ack_user != null) {
    return 'is-notice'
  }

  switch (incident.type) {
    case 'formula':
      const event = se.get(incident.formula_id)
      const rule = event && event.rules.find((r) => r.id === incident.formula_id)
      return rule ? 'is-' + rule.level : 'is-notice'
    default:
      return 'is-warning'
  }
}

/**
 * Получить формулу по которой рассчитывался инцидент
 */
export const getIncidentRule = (incident: Incident, seRules: Map<number, SignalEvent>): SignalEventRule => {
  if (incident.type !== 'formula') return null

  const se = seRules.get(incident.formula_id)
  return se ? se.rules.find((r) => r.id === incident.formula_id) : null
}

/**
 * Получить состояние подтверждения инцидента
 * - Acked - взят в работу
 * - WaitingAck - ожидается взятие в работу
 * - None - взятие в работу не требуется
 */
export const getAckState = (incident: Incident, se: Map<number, SignalEvent>): IncidentAckState => {
  if (incident.ack_user) {
    return IncidentAckState.Acked
  }

  switch (incident.type) {
    case 'dq_irremovable':
    case 'dq_unreliable':
      return IncidentAckState.None
    case 'formula':
      const event = se.get(incident.formula_id)
      const rule = event && event.rules.find((r) => r.id === incident.formula_id)

      return rule && rule.require_ack ? IncidentAckState.WaitingAck : IncidentAckState.None
    default:
      return IncidentAckState.None
  }
}

// отфильтровать инциденты не попадающие в результаты поиска
export const searchIncidents = (incidents: DenormalizedIncident[], words: string[]) => {
  if (words.length === 0) return incidents

  return incidents.filter((item) => {
    const title = item.title.toLowerCase()

    return words.every((word) => {
      // поиск по названию сигнальной ситуации
      if (title.includes(word)) return true

      for (const details of item.details) {
        // поиск по устройству
        if (details.device_name.toLowerCase().includes(word)) return true

        // поиск по параметру
        for (const p of details.parameters) {
          if (p.parameter_name.toLowerCase().includes(word)) return true
        }
      }

      return false
    })
  })
}
