import { AnyObject } from 'common'
import { TNode } from './tree.types'
import { useEffect } from 'react'

/** Хук, открывающий все ноды на пути к выбранной ноде */
const useAutoExpand = <T extends AnyObject>(
  nodes: TNode<T>[],
  selectedNodeId: TNode<T>['id'],
  openedNodes: Set<TNode<T>['id']>,
  setOpenedNodes: (nodes: Set<TNode<T>['id']>) => void
) => {
  useEffect(() => {
    if (!selectedNodeId) return

    const wayToNode = findWayFromRootToNode(nodes, selectedNodeId)
    if (!wayToNode?.length) return

    if (!wayToNode.every((id) => openedNodes.has(id))) {
      setOpenedNodes(new Set<TNode<T>['id']>([...openedNodes, ...wayToNode]))
    }
  }, [selectedNodeId])
}

/** dfs для поиска пути до указанной ноды */
const findWayFromRootToNode = <T extends AnyObject>(
  nodes: TNode<T>[],
  selectedNodeId: TNode<T>['id']
): Array<TNode<T>['id']> => {
  const dfs = (node: TNode<T>, way: Array<TNode<T>['id']> = []): Array<TNode<T>['id']> => {
    if (node.id === selectedNodeId) return way
    if (!node.children) return null

    for (const n of node.children) {
      const w = dfs(n, way.concat(node.id as T['id']))
      if (w) return w
    }
  }

  for (const node of nodes) {
    const way = dfs(node)
    if (way) return way
  }
}

export default useAutoExpand
