import { useEffect, useState } from 'react'
import { organizationsActions } from '../../../../../Catalogs/Organizations/organizations.actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../../../redux/store.types'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import styles from '../../request.module.css'
import Checkbox from '../../../../../../shared/Inputs/Checkbox/Checkbox'
import { useIntl } from 'react-intl'
import findOrganizationTypeDiff from './organizationTypeRequest.diff'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import OrganizationTypeSchemaRequestForm from './OrganizationTypeSchemaRequestForm'
import { useRecoilValue } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import EntityRemoved from '../../components/EntityRemoved'
import { OrganizationTypeRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const OrganizationTypeRequestForm = ({ editing }: IProps) => {
  const intl = useIntl()
  const request = useRecoilValue<OrganizationTypeRequest>(selectedDraftRequestState as any)
  const prevId = request?.request_body?.id || extractIntIdFromUrl(request.url)
  const isClosed = request.rejected || request.moderated_at
  const dispatch = useDispatch()

  const currentOrgType = useSelector((state: ReduxState) =>
    state.organizations.organizations.find((org) => org.id === prevId)
  )

  const [showOnlyDiff, setShowOnlyDiff] = useState(false)

  useEffect(() => {
    dispatch(organizationsActions.loadOrganizations())
  }, [])

  const empty = <EntityRemoved />

  const prev = isClosed ? request.state_before : currentOrgType

  const [beforeOrganizationType, afterOrganizationType] = showOnlyDiff
    ? findOrganizationTypeDiff(prev, request.request_body)
    : [prev, request.request_body]

  const renderPrev = () =>
    beforeOrganizationType ? (
      <OrganizationTypeSchemaRequestForm
        editing={editing}
        organizationType={beforeOrganizationType}
        onlyDiffDisplaying={showOnlyDiff}
      />
    ) : (
      empty
    )

  const renderRequested = () =>
    afterOrganizationType ? (
      <OrganizationTypeSchemaRequestForm
        editing={editing}
        organizationType={afterOrganizationType}
        onlyDiffDisplaying={showOnlyDiff}
      />
    ) : (
      empty
    )

  if (request.method === 'POST') return renderRequested()
  if (request.method === 'DELETE') return renderPrev()

  const before = { id: 'user.request.before', render: renderPrev }
  const after = { id: 'user.request.after', render: renderRequested }

  return (
    <>
      <div className={styles.requestOnlyDiff}>
        <Checkbox checked={showOnlyDiff} onChange={setShowOnlyDiff} />
        <span>{intl.formatMessage({ id: 'user.request.show_only_diff' })}</span>
      </div>
      <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />
    </>
  )
}

export default OrganizationTypeRequestForm
