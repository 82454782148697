import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import { ReduxState } from '../../../../../redux/store.types'
import { showError } from '../../../../../utils/notifications'
import { validateItemValues } from '../../../../Catalogs/catalogs.validators'
import OrganizationForm from '../../../../Catalogs/Organizations/components/OrganizationForms/OrganizationForm'
import { organizationsActions } from '../../../../Catalogs/Organizations/organizations.actions'
import * as api from '../../../../Catalogs/Organizations/organizations.api'
import { createOrganization, validateOrganization } from '../../../../Catalogs/Organizations/organizations.utils'
import userTasksActions from '../../../userTasks.actions'
import userTaskEvents from '../../../userTasks.events'
import { IOrganizationCreateTask } from '../../../userTasks.types'
import useUnmount from '../useUnmount'

interface IProps {
  task: IOrganizationCreateTask
}

const CreateOrganizationTaskForm = ({ task }: IProps) => {
  const dispatch = useDispatch()
  const organizations = useSelector((state: ReduxState) => state.organizations.organizations)

  const organizationType = useMemo(
    () => organizations.find((org) => org.id === task.organization_type_id),
    [task.organization_type_id, organizations]
  )

  const { loading, wait } = useHttpLoader()
  const [draftPayload, setDraftPayload] = useState(task.state ?? createOrganization(organizationType))

  const handleUnmount = () => {
    dispatch(userTasksActions.updateUserTask({ ...task, state: draftPayload }))
  }
  useUnmount(handleUnmount)

  if (!organizationType) return <span className="text--danger">Not found</span>

  const handleSave = async () => {
    if (
      !validateItemValues(organizationType.organization_schema, draftPayload.organization_properties) ||
      !validateOrganization(draftPayload)
    ) {
      return showError('common.validation_error')
    }

    const request = api.postCreateOrganization(draftPayload)

    wait(request, (result) => {
      dispatch(userTasksActions.deleteUserTask(task))
      if (!result) return

      dispatch(organizationsActions.organizationCreated(result))
      userTaskEvents.next({ type: 'complete', id: task.id, result })
    })
  }

  return (
    <OrganizationForm
      loading={loading}
      onChange={setDraftPayload}
      formMode={'edit'}
      organization={draftPayload}
      organizationType={organizationType}
      onEditSave={handleSave}
      onCancel={() => dispatch(userTasksActions.deleteUserTask(task))}
    />
  )
}

export default CreateOrganizationTaskForm
