import EventEmitter from '../../utils/events'
import { WSClient } from '../ws/ws'
import { ITimesync } from '../ws/ws.types'

/**
 * Сервис для работы со временем плеера
 *
 * Любой другой сервис или компонент при необходимости знать текущее время плеера
 * должен подписываться на playerTime$
 */
export default class Clock {
  time$ = new EventEmitter<ClockTime>()
  frame$ = new EventEmitter<number>()

  private playerTime: number = Date.now()
  private serverTime: number = Date.now()
  private tickStart: number = Date.now()
  private serverDelay = 0

  private frame = 30_000
  private frameLimits = { min: 1, max: 365 * 24 * 60 * 60 * 1000 }

  private renders = new Set<number>() // ids of already rendered components
  private lastRender = 0 // timestamp in milliseconds of the last render
  private tickRenders = 0 // number of renders in the current frame

  // границы времени в которых может находиться плеер (используется например при просмотре аварийного архива)
  private minTime: number = null
  private maxTime: number = null

  private online = true
  private stopped = true
  private speed = 1

  online$ = new EventEmitter<boolean>()
  stopped$ = new EventEmitter<boolean>()
  speed$ = new EventEmitter<number>()

  private animationId: any = null
  private wsUnsubscribe: () => void = null

  /**
   * Количество клиентов подписанных на время плеера.
   * Если не останется ни одного клиента Clock будет остановлен
   * и будет запущен снова при появлении первого клиента.
   */
  private subscribers = 0
  private isRunning = false

  constructor(private ws: WSClient) {}

  registerComponent() {
    this.subscribers += 1

    if (!this.isRunning) {
      this.startClock()
    }
  }

  unregisterComponent() {
    this.subscribers -= 1

    if (this.subscribers === 0) {
      this.stopClock()
    }
  }

  /**
   * Определить необходимо ли компоненту отображать новые данные. Предназначено для компонентов
   * которые обновляют данные не чаще раза в секунду (таких как таблица, текст и т.д.) для того чтобы
   * все такие компоненты на экране обновлялись синхронно.
   */
  shouldRender(id: number) {
    if (this.tickStart - this.lastRender > 1000) {
      this.lastRender = this.tickStart
      this.renders.clear()
    }

    // для избежания падения fps из-за обновления большого количества компонентов за один фрейм,
    // разбиваем обновления на меньшие части за несколько последовательных фреймов
    if (this.tickRenders < 4 && !this.renders.has(id)) {
      this.renders.add(id)
      this.tickRenders += 1
      return true
    }

    return false
  }

  invalidateRender(id: number) {
    this.renders.delete(id)
  }

  // Ручное выставление времени плеера
  setPlayerTime(ts: number) {
    if (!this.online && ts <= this.serverTime) {
      if (this.minTime != null && ts - this.frame < this.minTime) ts = this.minTime + this.frame
      if (this.maxTime != null && ts > this.maxTime) ts = this.maxTime

      this.playerTime = ts
      this.forcedTick()
    }
  }

  // Сдвинуть время плеера на dt микросекунд
  movePlayerTime(dt: number) {
    this.setPlayerTime(this.playerTime + dt)
  }

  getPlayerTime() {
    return this.playerTime
  }

  getServerTime() {
    return this.serverTime
  }

  isOnline() {
    return this.online
  }

  isStopped() {
    return this.stopped
  }

  getSpeed() {
    return this.speed
  }

  // переход в режим онлайн данных
  setOnline() {
    this.setPlayerTime(this.serverTime)
    this.online = true
    this.online$.next(true)
  }

  // переход в режим просмотра архивных данных
  setReplay() {
    this.online = false
    this.online$.next(false)
    this.setStopped(true)
  }

  setSpeed(speed: number) {
    this.speed = speed
    this.speed$.next(speed)
  }

  setStopped(stopped: boolean) {
    if (stopped !== this.stopped) {
      this.stopped = stopped
      this.stopped$.next(stopped)
    }
  }

  getFrame() {
    return this.frame
  }

  setFrame(frame: number) {
    if (frame < this.frameLimits.min) frame = this.frameLimits.min
    if (frame > this.frameLimits.max) frame = this.frameLimits.max
    if (this.minTime != null && this.playerTime - frame < this.minTime) frame = this.playerTime - this.minTime

    this.frame = frame
    this.frame$.next(frame)
  }

  getFrameLimits() {
    return { ...this.frameLimits }
  }

  setFrameLimits(min: number, max: number) {
    this.frameLimits = { min, max }
  }

  setTimeBoundaries(minTime: number, maxTime: number) {
    this.minTime = minTime
    this.maxTime = maxTime
  }

  forcedTick = () => {
    this.time$.next({ playerTime: this.playerTime, serverTime: this.serverTime, forcedTick: true })
  }

  private tick = () => {
    this.animationId = window.requestAnimationFrame(this.tick)

    const now = Date.now()
    const dt = now - this.tickStart
    this.tickStart = now
    this.tickRenders = 0

    const serverTime = now - this.serverDelay
    const playerTime = this.online ? serverTime : this.playerTime + Math.ceil(dt * this.playerSpeed)

    if (!this.online && this.maxTime != null && playerTime > this.maxTime) return

    // перейти в онлайн при ускоренной перемотке истории и достижении текущего времени
    if (playerTime > serverTime && !this.online && !this.stopped) {
      this.setOnline()
    }

    this.playerTime = playerTime
    this.serverTime = serverTime
    this.time$.next({ playerTime, serverTime, forcedTick: false })
  }

  private startClock() {
    this.animationId = window.requestAnimationFrame(this.tick)
    this.isRunning = true
    this.wsUnsubscribe = this.ws.timesync$.subscribe(this.handleTimesync, true)
  }

  private stopClock() {
    this.wsUnsubscribe()
    window.cancelAnimationFrame(this.animationId)
    this.isRunning = false
  }

  private get playerSpeed() {
    if (this.online) return 1
    else if (this.stopped) return 0
    else return this.speed
  }

  private setServerTime(ts: number, syncWithPlayerTime = false) {
    this.serverTime = ts
    this.serverDelay = Date.now() - ts

    if (this.online && syncWithPlayerTime) {
      this.playerTime = ts
    }

    this.forcedTick()
  }

  private handleTimesync = ({ timeDelta, processingDelay }: ITimesync) => {
    const delay = Math.min(processingDelay, 1000)

    const serverTime = Date.now() - timeDelta - delay - 300
    const shouldSync = serverTime < this.serverTime || serverTime > this.serverTime + 300

    if (shouldSync) {
      this.setServerTime(serverTime, true)
    }
  }
}

export interface ClockTime {
  playerTime: number
  serverTime: number
  forcedTick: boolean
}
