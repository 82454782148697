import { useIntl } from 'react-intl'
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg'
import { useDropdownControls } from './tree.hooks'

/**
 * Выпадающее меню для добавления новых виртуальных устройств
 */
const DeviceControls = (props: Props) => {
  const intl = useIntl()
  const { ref, open, setOpen, dropdownStyle } = useDropdownControls()

  const handleClick = () => {
    props.onCreate()
    setOpen(false)
  }

  const dropdown = (
    <div className="nsi-dropdown" style={dropdownStyle}>
      <div className="nsi-dropdown__item" onClick={handleClick}>
        {intl.formatMessage({ id: 'nsi.add_virtual_device' })}
      </div>
    </div>
  )

  return (
    <div className="nsi__row-control" ref={ref}>
      <PlusIcon style={{ width: 20, height: 20, color: 'var(--success-70)' }} onClick={() => setOpen(!open)} />
      {open && dropdown}
    </div>
  )
}

interface Props {
  onCreate: () => void
}

export default DeviceControls
