import { OrganizationTypeWithInstances } from '../Catalogs/Organizations/organizations.types'
import { nanoid } from '@reduxjs/toolkit'
import { ICatalogItemCreateTask, IOrganizationCreateTask } from './userTasks.types'
import { AnyCatalogWithInstances } from '../Catalogs/catalogs.types'

export const generateCreateOrganizationTask = (
  organizationTypeId: OrganizationTypeWithInstances['id']
): IOrganizationCreateTask => {
  return {
    id: nanoid(10),
    created_at: Date.now(),
    type: 'create_organization',
    organization_type_id: organizationTypeId,
    state: null,
  }
}

export const generateCreateCatalogItemTask = (catalogId: AnyCatalogWithInstances['id']): ICatalogItemCreateTask => {
  return {
    id: nanoid(10),
    created_at: Date.now(),
    type: 'create_catalog_item',
    catalog_id: catalogId,
    state: null,
  }
}
