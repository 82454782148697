import { useState } from 'react'
import { useIntl } from 'react-intl'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'

interface Props {
  loading: boolean
  onClose: () => void
  onConfirm: (code: string) => void
}

/**
 * Модальное окно для введения кода подтверждения отправляемого на почту
 * при включении 2FA
 */
const SecuritySettingsModal = ({ loading, onClose, onConfirm }: Props) => {
  const intl = useIntl()
  const [code, setCode] = useState('')

  return (
    <Modal onClose={onClose}>
      <h2>{intl.formatMessage({ id: 'user.profile.security.enter_code' })}</h2>

      <TextInput name="code" value={code} onChange={setCode} />

      <ModalFooter loading={loading} onCancel={onClose} onSave={() => code && onConfirm(code)} />
    </Modal>
  )
}

export default SecuritySettingsModal
