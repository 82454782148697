import stateConnectDevice from './connectDevice.recoil'
import { useRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import styles from './connectDevice.module.css'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Loader from '../../../../shared/Utils/Loader'
import http, { handleHttpResponse } from '../../../../utils/http'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'

const ConnectDeviceWidget = () => {
  const intl = useIntl()
  const { wait, loading } = useHttpLoader()
  const [connectingDevices, setConnectingDevices] = useRecoilState(stateConnectDevice)
  const user = useSelector((state: ReduxState) => state.user)

  if (connectingDevices.length === 0) return null

  const handleCancel = () => {
    setConnectingDevices([])
  }

  const handleConnect = () => {
    wait(http.post('/nsi/v1/equipment/access/grant', { user_id: user.id, equipment: connectingDevices }), (r) => {
      handleHttpResponse(r)
      setConnectingDevices([])
    })
  }

  const renderFooter = () => {
    if (loading) return <Loader />

    return (
      <div className={styles.connectDeviceWidgetButtons}>
        <button className="nsi-button secondary" onClick={handleCancel}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </button>
        <button className="nsi-button default" onClick={handleConnect}>
          {intl.formatMessage({ id: 'map.device.connect' })}
        </button>
      </div>
    )
  }

  const message = intl.formatMessage(
    { id: 'map.device.connect_message' },
    {
      count: connectingDevices.length,
      cnt: (chunk) => (
        <span className={styles.deviceWidgetMessageCount} key={connectingDevices.length}>
          {chunk}
        </span>
      ),
    }
  )

  return (
    <div className={styles.connectDeviceWidget}>
      <h2 className={styles.connectDeviceWidgetMessage}>{message as any}</h2>
      {renderFooter()}
    </div>
  )
}

export default ConnectDeviceWidget
