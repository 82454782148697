const ThreePhaseSVG = (props) => (
  <svg viewBox={props.viewBox}>
    <text x={7} y={18}>
      I &nbsp;= {props.i1}
    </text>
    <text x={11} y={22} className="subscript">
      1
    </text>

    <text x={7} y={36}>
      I &nbsp;= {props.i2}
    </text>
    <text x={11} y={40} className="subscript">
      2
    </text>

    <text x={7} y={54}>
      I &nbsp;/ I &nbsp;= {props.i2i1}
    </text>
    <text x={11} y={58} className="subscript">
      2
    </text>
    <text x={28} y={58} className="subscript">
      1
    </text>

    <text x={330} y={30}>
      I &nbsp;= {props.iA}
    </text>
    <text x={334} y={34} className="subscript">
      A
    </text>

    <text x={330} y={156}>
      I &nbsp;= {props.iN}
    </text>
    <text x={334} y={160} className="subscript">
      N
    </text>

    <text x={330} y={230}>
      I &nbsp;= {props.iB}
    </text>
    <text x={334} y={234} className="subscript">
      B
    </text>

    <text x={330} y={280}>
      I &nbsp;= {props.iC}
    </text>
    <text x={334} y={284} className="subscript">
      C
    </text>

    <text x={175} y={40}>
      A
    </text>
    <text x={300} y={220}>
      B
    </text>
    <text x={78} y={220}>
      C
    </text>
    <text x={300} y={154}>
      N
    </text>

    <text x={210} y={90}>
      U &nbsp;&nbsp;= {props.uAB}
    </text>
    <text x={218} y={94} className="subscript">
      AB
    </text>

    <text x={275} y={194}>
      U &nbsp;&nbsp;= {props.uBC}
    </text>
    <text x={283} y={198} className="subscript">
      BC
    </text>

    <text x={130} y={220}>
      U &nbsp;&nbsp;= {props.uCA}
    </text>
    <text x={138} y={224} className="subscript">
      CA
    </text>

    <g fill="none" strokeLinecap="square" strokeMiterlimit={3} transform="matrix(.85641 0 0 .91096 21.927 -1.067)">
      <g transform="rotate(-90 -228.97 412.655)">
        <path d="M0 841.89h42.52" />
      </g>
      <g transform="rotate(-30 -1106.834 218.429)">
        <path d="M0 841.89h42.52" />
      </g>
      <g transform="translate(200.266 -828.283)">
        <path d="M0 841.89h240.94" />
      </g>
      <g transform="translate(347.558 -573.165)">
        <path d="M0 841.89h93.65" />
      </g>
      <g transform="rotate(90 313.07 581.793)">
        <path d="M0 841.89h28.35" />
      </g>
      <g transform="translate(52.973 -522.907)">
        <path d="M0 841.89h388.24" />
      </g>
      <g transform="rotate(90 514.272 527.879)">
        <path d="M0 841.89h24.09" />
      </g>
      <g transform="rotate(-150 33.21 491.654)">
        <path d="M0 841.89h42.52" />
      </g>
      <g transform="rotate(-30 -1043.056 456.458)">
        <path d="M0 841.89h23.62" />
      </g>
      <g transform="rotate(30 1255.127 1159.792)">
        <path d="M0 841.89h23.62" />
      </g>
      <g transform="translate(186.093 -700.724)">
        <path d="M14.17 756.85A14.173 14.173-90 000 771.02a14.173 14.173-180 0014.17 14.18A14.173 14.173-90 000 799.37a14.173 14.173-90 0014.17 14.17A14.173 14.173 0 000 827.72a14.173 14.173-180 0014.17 14.17" />
      </g>
      <g transform="rotate(120 309.502 587.767)">
        <path d="M14.17 756.85A14.173 14.173-90 000 771.02a14.173 14.173-180 0014.17 14.18A14.173 14.173-90 000 799.37a14.173 14.173-90 0014.17 14.17A14.173 14.173 0 000 827.72a14.173 14.173-180 0014.17 14.17" />
      </g>
      <g transform="rotate(60 566.773 610.169)">
        <path d="M14.17 756.85A14.173 14.173-90 000 771.02a14.173 14.173-180 0014.17 14.18A14.173 14.173-90 000 799.37a14.173 14.173-90 0014.17 14.17A14.173 14.173 0 000 827.72a14.173 14.173-180 0014.17 14.17" />
      </g>
      <g transform="translate(200.266 -658.205)">
        <path d="M0 841.89h240.94" />
      </g>
      <g transform="translate(196.014 -795.685)">
        <circle cx={4.25} cy={837.64} r={4.25} fill="none" />
      </g>
      <g transform="translate(72.707 -583.087)">
        <circle cx={4.25} cy={837.64} r={4.25} fill="none" />
      </g>
      <g transform="matrix(-1 0 0 1 327.825 -583.087)">
        <circle cx={4.25} cy={837.64} r={4.25} fill="none" />
      </g>
      <g transform="matrix(-1 0 0 1 327.825 -653.953)">
        <circle cx={4.25} cy={837.64} r={4.25} fill="#1b4ea3" />
      </g>
      <g transform="rotate(30 1264.58 1124.517)">
        <path d="M0 841.89h10.42" />
      </g>
      <g transform="rotate(89.585 500.833 544.871)">
        <path d="M0 841.89h9.92" />
      </g>
      <g transform="rotate(-30 -1059.105 396.556)">
        <path d="M0 841.89h10.42" />
      </g>
      <g fill="none" strokeLinecap="square" strokeMiterlimit={3} transform="rotate(90 302.76 592.3)">
        <path d="M0 841.89h28.35" />
      </g>
    </g>
  </svg>
)

export default ThreePhaseSVG
