import { OrganizationTypeWithInstances } from '../../../../../Catalogs/Organizations/organizations.types'
import { ReduxState } from '../../../../../../redux/store.types'
import { useSelector } from 'react-redux'
import Tabs from '../../../../../../shared/Pagination/Tabs'
import { useCallback, useState } from 'react'
import findOrganizationsDiff from './organizationRequests.diff'
import styles from '../../request.module.css'
import Checkbox from '../../../../../../shared/Inputs/Checkbox/Checkbox'
import { useIntl } from 'react-intl'
import OrganizationRequestTable from './OrganizationRequestTable'
import { extractIntIdFromUrl } from '../../../moderation.utils'
import { useRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import EntityRemoved from '../../components/EntityRemoved'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { Organization } from 'au-nsi/organizations'
import { OrganizationRequest } from 'au-nsi/moderation'

interface IProps {
  editing: boolean
}

const OrganizationRequestForm = ({ editing }: IProps) => {
  const intl = useIntl()

  const [request, setRequest] = useRecoilState<OrganizationRequest>(selectedDraftRequestState as any)

  const draftBody = request.request_body
  const prevId = draftBody?.id || extractIntIdFromUrl(request.url)
  const isClosed = request.rejected || request.moderated_at

  const catalogs = useSelector((state: ReduxState) => state.catalogs.catalogs)

  const [prev, organizationType] = useSelector((state: ReduxState) => {
    const organization = selectOrganizationById(prevId, state)
    const organizationType = state.organizations.organizations.find(
      (o) => o.id === (organization?.organization_type_id || draftBody?.organization_type_id)
    )

    return [organization, organizationType] as [Organization, OrganizationTypeWithInstances]
  })
  const [showOnlyDiff, setShowOnlyDiff] = useState(false)

  const handleChange = useCallback((schemaId: IPropertyDescription['id'], value: any) => {
    setRequest((prev) => {
      return {
        ...prev,
        request_body: {
          ...prev.request_body,
          organization_properties: { ...prev.request_body.organization_properties, [schemaId]: value },
        },
      }
    })
  }, [])

  const empty = <EntityRemoved />
  const prevOrganization = isClosed ? request.state_before : prev

  const [beforeOrganization, afterOrganization, filteredOrganizationType] = showOnlyDiff
    ? findOrganizationsDiff(prevOrganization, draftBody, organizationType)
    : [prevOrganization, draftBody, organizationType]

  const props = {
    editing,
    onChange: handleChange,
    organizationType: filteredOrganizationType,
    catalogs,
    onNameChange: (name) => setRequest((prev) => ({ ...prev, request_body: { ...prev.request_body, name } })),
  }
  const renderPrev = () =>
    beforeOrganization && organizationType ? (
      <OrganizationRequestTable {...props} organization={beforeOrganization} editing={false} />
    ) : (
      empty
    )
  const renderUpdated = () =>
    afterOrganization && organizationType ? (
      <OrganizationRequestTable {...props} organization={afterOrganization} />
    ) : (
      empty
    )

  if (request.method === 'POST') return renderUpdated()
  if (request.method === 'DELETE') return renderPrev()

  const before = { id: 'user.request.before', render: renderPrev }
  const after = { id: 'user.request.after', render: renderUpdated }

  return (
    <>
      <div className={styles.requestOnlyDiff}>
        <Checkbox checked={showOnlyDiff} onChange={setShowOnlyDiff} />
        <span>{intl.formatMessage({ id: 'user.request.show_only_diff' })}</span>
      </div>
      <Tabs tabs={[before, after]} tabsStyle={{ marginBottom: '2em' }} />{' '}
    </>
  )
}

const selectOrganizationById = (id: Organization['id'], state: ReduxState) => {
  for (const organizationType of state.organizations.organizations) {
    const found = organizationType.instances.find((o) => o.id === id)
    if (found) return found
  }
}

export default OrganizationRequestForm
