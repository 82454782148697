import { IAepsSettings } from 'au-nsi/settings'
import { useAppSelector } from '../../../../redux/store'
import { selectEquipmentMap, selectEquipmentOptions } from '../../../Nsi/nsi.selectors'
import TextInput from '../../../../shared/Inputs/TextInput'
import useAepsSettingsTranslations from './useAepsSettingsTranslations'
import PlusButton from '../../../../shared/Forms/PlusButton'
import RemoveButton from '../../../../shared/Forms/RemoveButton'
import { Select } from '@alterouniversal/au-react-components'

interface IProps {
  editing: boolean
  xml_mapping: IAepsSettings['xml_mapping']
  onChange: (settings: IAepsSettings['xml_mapping'], name: 'xml_mapping') => void
  translations: ReturnType<typeof useAepsSettingsTranslations>
}

const XMLMappingTable = (props: IProps) => {
  const equipmentMap = useAppSelector(selectEquipmentMap)
  const equipmentOptions = useAppSelector(selectEquipmentOptions)

  const handleRemove = (e) => {
    const { id: index } = e.currentTarget.dataset

    const updated = [...props.xml_mapping.slice(0, +index), ...props.xml_mapping.slice(+index + 1)]
    props.onChange(updated, 'xml_mapping')
  }

  const handleChange = (value: string, name: string) => {
    const index = parseInt(name)
    const propName = name.slice(name.indexOf('_') + 1)

    const updated = [
      ...props.xml_mapping.slice(0, index),
      { ...props.xml_mapping[index], [propName]: value },
      ...props.xml_mapping.slice(index + 1),
    ]

    props.onChange(updated, 'xml_mapping')
  }

  const handleCreate = () => {
    props.onChange([...props.xml_mapping, { device_id: '', group_id: '' }], 'xml_mapping')
  }

  const renderEditingRow = (row: IAepsSettings['xml_mapping'][number], index: number) => {
    const sIndex = index.toString()
    const selectedEquipment = equipmentMap.get(row.device_id)

    return (
      <tr key={index}>
        <td>
          <TextInput
            value={row.group_id}
            name={sIndex + '_group_id'}
            onChange={handleChange}
            required={true}
            minlength={1}
            maxlength={10}
          />
        </td>
        <td>
          <Select
            value={selectedEquipment.id}
            name={sIndex + '_device_id'}
            onChange={handleChange}
            options={equipmentOptions}
            required={true}
          />
        </td>
        <td>
          <RemoveButton id={index} onRemove={handleRemove} />
        </td>
      </tr>
    )
  }

  const renderStaticRow = (row: IAepsSettings['xml_mapping'][number]) => {
    return (
      <tr key={row.group_id + row.device_id}>
        <td>{row.group_id}</td>
        <td>{equipmentMap.get(row.device_id)?.name}</td>
      </tr>
    )
  }

  const renderRow = (row: IAepsSettings['xml_mapping'][number], index: number) => {
    if (props.editing) return renderEditingRow(row, index)
    return renderStaticRow(row)
  }

  return (
    <>
      <table className="nsi-settings-table is-fixed">
        <thead>
          <tr>
            <th>{props.translations.group_id}</th>
            <th>{props.translations.device_id}</th>
            {props.editing && <th style={{ width: '24px' }} />}
          </tr>
        </thead>
        <tbody>{props.xml_mapping.map(renderRow)}</tbody>
      </table>
      {props.editing && <PlusButton onClick={handleCreate} textId={props.translations.add} style={{ marginLeft: 0 }} />}
    </>
  )
}

export default XMLMappingTable
