import React, { PropsWithChildren, useCallback } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import FormButtons from '../../shared/Forms/FormButtons'
import { FormMode, ValidationResult } from '../../shared/interfaces'
import { showError } from '../../utils/notifications'

interface Actions<T> {
  createItem: (e: T) => void
  updateItem: (e: Partial<T>) => void
  deleteItem: (id: number | string) => void
  updateSelectedItem: (field: string, value: any) => void
  setMode: (mode: FormMode) => void
}

interface Resource {
  id: number | string
}

/**
 * Валидация формы, должен вернуть null если ошибок нет, либо id ошибки если есть.
 */
type Validator<T> = (item: T) => ValidationResult

// common handlers for components working with forms
export const useHandlers = <T extends Resource>(
  actions: Actions<T>,
  item: T,
  mode: FormMode,
  validate?: Validator<T>
) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const handleCancel = () => dispatch(actions.setMode('view'))
  const handleDelete = () => dispatch(actions.setMode('delete'))
  const handleDeleteConfirm = () => dispatch(actions.deleteItem(item.id))
  const handleEdit = () => dispatch(actions.setMode('edit'))

  const handleChange = useCallback(
    (field: string, value: any) => dispatch(actions.updateSelectedItem(field, value)),
    []
  )
  const handleChangeInverted = useCallback((value: any, field: string) => handleChange(field, value), [handleChange])

  const handleCreateSave = () => {
    const error = validate && validate(item)

    if (error) {
      const msg = intl.formatMessage({ id: error.id }, error.values)
      showError(msg, msg)
    } else {
      dispatch(actions.createItem(item))
    }
  }

  const handleEditSave = () => {
    const error = validate && validate(item)

    if (error) {
      const msg = intl.formatMessage({ id: error.id }, error.values)
      showError(msg, msg)
    } else {
      dispatch(actions.updateItem(item))
    }
  }

  const handleSave = () => {
    if (mode === 'edit') handleEditSave()
    else if (mode === 'create') handleCreateSave()
  }

  return {
    handleCancel,
    handleChange,
    handleChangeInverted,
    handleDelete,
    handleDeleteConfirm,
    handleEdit,
    handleSave,
  }
}

/**
 * HOC для автоматической связи обработчиков нажатия кнопок из компонента FormButtons и действий
 * сгенерированных при помощи generateActions из collection.actions
 */
export const FormButtonsHOC = <T extends Resource>(actions: Actions<T>) => {
  return class FormButtonsWrapper extends React.Component<PropsWithChildren<ButtonsProps<T>>> {
    private handleCancel = () => {
      this.props.dispatch(actions.setMode('view'))
    }

    private handleCreateSave = () => {
      const item = this.props.selectedItem
      const error = this.props.validate && this.props.validate(item)

      if (error) {
        const msg = this.props.intl.formatMessage({ id: error.id }, error.values)
        showError(msg, msg)
      } else {
        this.props.dispatch(actions.createItem(item))
      }
    }

    private handleDelete = () => {
      this.props.dispatch(actions.setMode('delete'))
    }

    private handleDeleteConfirm = () => {
      this.props.dispatch(actions.deleteItem(this.props.selectedItem.id))
    }

    private handleEdit = () => {
      this.props.dispatch(actions.setMode('edit'))
    }

    private handleEditSave = () => {
      const item = this.props.selectedItem
      const error = this.props.validate && this.props.validate(item)

      if (error) {
        const msg = this.props.intl.formatMessage({ id: error.id }, error.values)
        showError(msg, msg)
      } else {
        this.props.dispatch(actions.updateItem(item))
      }
    }

    render() {
      return (
        <FormButtons
          mode={this.props.mode}
          allowDeleting={this.props.allowDeleting}
          allowEditing={this.props.allowEditing}
          onCancel={this.handleCancel}
          onCreateSave={this.handleCreateSave}
          onDelete={this.handleDelete}
          onDeleteConfirm={this.handleDeleteConfirm}
          onEdit={this.handleEdit}
          onEditSave={this.handleEditSave}
        >
          {this.props.children}
        </FormButtons>
      )
    }
  }
}

export const getCreateOptions = (intl: IntlShape, allowUserCreate: boolean, allowRoleCreate: boolean) => {
  const options = []
  if (allowUserCreate) options.push({ value: 'user', title: intl.formatMessage({ id: 'nsi.user_create' }) })
  if (allowRoleCreate) options.push({ value: 'role', title: intl.formatMessage({ id: 'nsi.role_create' }) })
  return options
}

interface ButtonsProps<T> {
  allowDeleting: boolean
  allowEditing: boolean
  dispatch: (action: any) => void
  intl?: IntlShape
  mode: FormMode
  selectedItem: T
  validate?: Validator<T>
}
