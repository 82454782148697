import { PropsWithChildren, useEffect, useState } from 'react'
import globalClock from '../../services/clock/clock.factory'
import ArchiveManager from './Archive/ArchiveManager'
import FrameControls from './FrameControls/FrameControls'
import OnlineControls from './OnlineControls/OnlineControls'
import * as settings from './player.settings'
import './player.styles.css'
import BookmarksManager from './PlayerBookmarks'
import ReplayControls from './ReplayControls/ReplayControls'
import SpeedControls from './SpeedControls'
import TemplateControls from './TemplateControls/TemplateControls'
import TimeControls from './TimeControls'

interface Props {
  page?: string
  title?: string
  deviceId?: string
  isFrameControlsHidden?: boolean
}

const ChartPlayer = ({ page, title, deviceId, isFrameControlsHidden, children }: PropsWithChildren<Props>) => {
  const clock = globalClock
  const storageKey = 'player:frame:' + page

  const [frame, setFrame] = useState(clock.getFrame())
  const [speed, setSpeed] = useState(clock.getSpeed())
  const [online, setOnline] = useState(clock.isOnline())
  const [stopped, setStopped] = useState(clock.isStopped())

  useEffect(() => {
    const s1 = clock.frame$.subscribe((frame) => {
      localStorage.setItem(storageKey, frame + '')
      setFrame(frame)
    })

    const s2 = clock.speed$.subscribe((v) => setSpeed(v))
    const s3 = clock.online$.subscribe((v) => setOnline(v))
    const s4 = clock.stopped$.subscribe((v) => setStopped(v))

    const subscriptions = [s1, s2, s3, s4]
    clock.registerComponent()

    return () => {
      subscriptions.forEach((unsub) => unsub())
      clock.unregisterComponent()
    }
  }, [])

  useEffect(() => {
    const frame = +localStorage.getItem(storageKey) || settings.defaults[page] || 30_000
    const limits = settings.limits[page] || { min: 1, max: 12 * settings.MONTH }

    clock.setFrame(frame)
    clock.setFrameLimits(limits.min, limits.max)
  }, [page])

  const onSpeedUp = () => {
    if (!online && speed < 8) clock.setSpeed(speed * 2)
  }

  const onSpeedDown = () => {
    if (!online && speed > 1 / 8) clock.setSpeed(speed / 2)
  }

  const onPrevFrame = () => {
    if (!online) clock.setPlayerTime(clock.getPlayerTime() - frame)
  }

  const onNextFrame = () => {
    if (!online) clock.setPlayerTime(clock.getPlayerTime() + frame)
  }

  const onPause = () => {
    if (!online) clock.setStopped(true)
  }

  const onContinue = () => {
    if (!online) clock.setStopped(false)
  }

  const onSetOnline = () => clock.setOnline()
  const onSetOffline = () => clock.setReplay()

  const onTimeChange = (ts: number) => clock.setPlayerTime(ts)
  const onFrameChange = (frame: number) => clock.setFrame(frame)

  const limits = clock.getFrameLimits()

  return (
    <div className="chart-player">
      {children}
      <TimeControls clock={clock} readonly={online} onTimeChange={onTimeChange} />
      <OnlineControls online={online} onSetOnline={onSetOnline} onSetOffline={onSetOffline} />

      <ReplayControls
        stopped={stopped}
        online={online}
        onSpeedUp={onSpeedUp}
        onSpeedDown={onSpeedDown}
        onPrevFrame={onPrevFrame}
        onNextFrame={onNextFrame}
        onPause={onPause}
        onContinue={onContinue}
      />

      <SpeedControls speed={online ? 1 : speed} />

      {!isFrameControlsHidden && (
        <FrameControls frame={frame} minFrame={limits.min} maxFrame={limits.max} onFrameChange={onFrameChange} />
      )}

      <span style={{ marginBottom: '3px' }}>
        <BookmarksManager />
      </span>
      <ArchiveManager online={online} clock={clock} />

      {deviceId ? (
        <TemplateControls title={title} deviceId={deviceId} currentTemplateId={page} />
      ) : (
        <div className="chart-player__title">{title}</div>
      )}
    </div>
  )
}

export default ChartPlayer
