import { batch } from 'react-redux'
import { NSI_PERSIST_IGNORE } from '../pages/Nsi/nsi.reducers'
import idb from '../utils/idb'

const persistInclude = [
  { prefix: 'app/lang/', key: 'language' },
  { prefix: 'app/nsi/', key: 'nsi' },
  { prefix: 'app/user/', key: 'user' },
  { prefix: 'app/modules/', key: 'modules' },
  { prefix: 'app/lfo/', key: 'lfo' },
  { prefix: 'app/oplim/', key: 'oplim' },
  { prefix: 'app/pq/', key: 'pq' },
  { prefix: 'app/vector/', key: 'vector' },
]

const persistIgnore = new Set([
  'app/lfo/SET_SEARCH',
  'app/oplim/SET_SEARCH',
  'app/pq/SET_SEARCH',
  'app/vector/SET_SEARCH',
  ...NSI_PERSIST_IGNORE,
])

/**
 * redux middleware для сохранения состояния в IndexedDB, срез состояния с ключом key
 * будет сохранен если action.type начинается с префикса prefix
 */
export const persist = (store) => (next) => (action) => {
  const result = next(action)

  const match = persistInclude.find((item) => action.type.startsWith(item.prefix))

  if (match !== undefined && !persistIgnore.has(action.type) && !action.type.endsWith('SET_STATE')) {
    const state = store.getState()[match.key]
    idb.set(match.key + '.state', state)
  }

  return result
}

/**
 * загрузка кэша состояния модулей указанных в persistInclude
 */
export const loadCache = (dispatch) => {
  const operations = persistInclude.map(({ key, prefix }) => {
    return idb.get(key + '.state').then((state) => (state ? { type: prefix + 'SET_STATE', payload: state } : noop))
  })

  return Promise.all(operations).then((actions) => {
    batch(() => actions.forEach((a) => dispatch(a)))
  })
}

const noop = { type: 'app/NOOP' }
