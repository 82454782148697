import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import styles from './schemaTableWrapper.module.css'

interface Props {
  editing: boolean
  renderDefaultValue?: boolean
  hideHead?: boolean
}

const SchemaTableWrapper = ({
  editing,
  children,
  renderDefaultValue = true,
  hideHead = false,
}: PropsWithChildren<Props>) => {
  const intl = useIntl()

  const thead = (
    <tr>
      {editing && <th style={{ width: '46px' }} />}
      <th>{intl.formatMessage({ id: 'catalogs.schema_property' })}</th>
      <th>{intl.formatMessage({ id: 'catalogs.data_type' })}</th>
      {renderDefaultValue && <th>{intl.formatMessage({ id: 'catalogs.schema_default_value' })}</th>}
      <th style={{ width: '96px' }}>{intl.formatMessage({ id: 'catalogs.required' })}</th>
      {editing && <th style={{ width: '24px' }} />}
    </tr>
  )

  const tableClass = editing ? 'nsi-settings-table is-fixed' : 'nsi-settings-table is-striped is-fixed'
  return (
    <table className={tableClass}>
      <thead className={hideHead ? styles.hiddenThead : ''}>{thead}</thead>
      <tbody>{children}</tbody>
    </table>
  )
}

export default SchemaTableWrapper
