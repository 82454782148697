import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import GanttService from '../../services/gantt/gantt.service'
import usersActions from '../Collections/Users/users.actions'
import IncidentsHeader from './components/IncidentsHeader'
import IncidentsList from './components/IncidentsList'
import IncidentsModal from './components/IncidentsModal'
import IncidentsTable from './components/IncidentsTable'
import './incidents.styles.css'

/**
 * Страница списка инцидентов по сигнальным ситуациям
 */
const Incidents = (props: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [search, setSearch] = useState('')

  const showList = location.pathname.includes('/list')

  // если в url указан id инцидента, то показываем модальное окно с детальной информацией
  const selectedId = React.useMemo(() => {
    if (!location.search) return null

    const s = new URLSearchParams(location.search)
    return +s.get('id')
  }, [location.search])

  useEffect(() => {
    dispatch(usersActions.loadItems())
  }, [])

  // открыть модальное окно с инцидентом
  const openIncident = (id: number) => {
    const path = location.pathname
    navigate(path + '?id=' + id)
  }

  // закрыть модальное окно с инцидентом
  const closeIncident = () => {
    navigate(location.pathname)
  }

  return (
    <React.Fragment>
      <IncidentsHeader onSearch={setSearch} />

      {showList ? (
        <IncidentsList search={search} service={props.service} onOpen={openIncident} />
      ) : (
        <IncidentsTable onOpen={openIncident} />
      )}

      {selectedId != null && <IncidentsModal id={selectedId} onClose={closeIncident} />}
    </React.Fragment>
  )
}

interface Props {
  // т.к. список инцидентов связан с диаграммой Ганта (диаграмма контролирует
  // интервал времени за который отображать инциденты), а у каждой диаграммы свой
  // инстанс сервиса, то родительский компонент должен передать именно тот сервис
  // который используется диаграммой на странице инцидентов
  service: GanttService
}

export default Incidents
