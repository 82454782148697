import React from 'react'
import clock from '../../../services/clock/clock.factory'

class GanttChart extends React.Component<Props> {
  private handleClick = (e) => {
    e.stopPropagation()
    const t0 = +e.target.dataset.start
    const t1 = +e.target.dataset.end

    if (e.target.dataset.ended === 'true') {
      clock.setReplay()
    }

    clock.setPlayerTime(t1)
    clock.setFrame(t1 - t0)
  }

  render() {
    const { warning, danger, t0, t1 } = this.props

    return (
      <svg className="gantt__chart" viewBox="0 0 1000 10" preserveAspectRatio="none">
        {renderItems({ items: warning, level: 'warning', t0, t1, onClick: this.handleClick })}
        {renderItems({ items: danger, level: 'danger', t0, t1, onClick: this.handleClick })}
      </svg>
    )
  }
}

const colors = {
  notice: '#9B9B9B',
  warning: '#FF9857',
  danger: '#f44336',
}

export const renderItems = (options: RenderItemsOptions) => {
  const { items, level, t0, t1 } = options
  const totalLength = t1 - t0
  const className = options.onClick ? 'gantt__chart-item' : ''

  return items.map((item) => {
    const end = item[1] || Date.now()
    const start = item[0]

    const length = end - start

    // ceil для того чтобы ширина не оказалась 0 и всегда была минимум 1px
    const width = Math.ceil((1000 * length) / totalLength)
    const left = Math.round((1000 * (start - t0)) / totalLength)
    const fill = colors[level]

    return (
      <rect
        key={item[2]}
        className={className}
        x={left}
        y="0"
        width={width}
        height="10"
        fill={fill}
        data-start={start}
        data-end={end}
        data-ended={!!item[1]}
        onClick={options.onClick}
      />
    )
  })
}

interface RenderItemsOptions {
  items: number[][]
  level: string
  t0: number
  t1: number
  onClick?: (e: any) => void
}

interface Props {
  t0: number
  t1: number
  warning: number[][]
  danger: number[][]
}

export default GanttChart
