export const readCSVFile = (cb: (content: string) => void) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = '.csv'

  input.onchange = () => {
    const file = input.files[0]
    const reader = new FileReader()

    reader.onload = () => cb(reader.result as string)
    reader.readAsText(file, 'utf-8')
  }

  input.click()
}

export const parseCSVContent = (content: string, columns: string[], numeric: string[]) => {
  const lines = content.trim().split('\n')
  lines.shift() // remove header

  if (lines.length === 0) {
    return { data: null, error: 'nsi.parameters.import.invalid_csv' }
  }

  const numericIndexes = numeric.map((name) => columns.findIndex((c) => c === name))
  const table = lines.map((line) => parseCSVLine(line, numericIndexes))

  for (const line of table) {
    if (line.length !== columns.length) {
      return { data: null, error: 'nsi.parameters.import.invalid_line_length' }
    }
  }

  // преобразуем из массива массивов в массив объектов
  const data = table.map((row) => {
    const item = {}
    columns.forEach((c, i) => (item[c] = row[i]))

    return item
  })

  return { data, error: null }
}

const parseCSVLine = (line: string, numeric: number[]) => {
  return line
    .trim()
    .split(',')
    .map((v, i) => {
      if (!v) return undefined
      else if (numeric.includes(i)) return +v || 0
      else return v
    })
}
