import { useSettingsAPI } from '../settings.hooks'
import { IAepsSettings } from 'au-nsi/settings'
import InputRow from '../../../../shared/Inputs/InputRow'
import renderFooter from '../../system.footer'
import useAepsSettingsTranslations from './useAepsSettingsTranslations'
import TextInput from '../../../../shared/Inputs/TextInput'
import XMLMappingTable from './XMLMappingTable'
import FilesMappingTable from './FilesMappingTable'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import { useEffect } from 'react'
import PasswordInput from '../../../../shared/Inputs/PasswordInput'
import { normalizeSettings } from './aepsSettings.utils'
import SOPHUploadButton from './SOPHUploadButton'
import Form from '../../../../shared/Forms/Form'

const AepsSettings = () => {
  const translations = useAepsSettingsTranslations()
  const settingsApi = useSettingsAPI<IAepsSettings>('aeps-worktime-parser')

  useEffect(() => {
    if (!settingsApi.editing) {
      settingsApi.updateDraft(normalizeSettings(settingsApi.settings))
    }
  }, [settingsApi.settings])

  const handleChange = (value: any, name: string) => {
    settingsApi.updateDraft({ ...settingsApi.draft, [name]: value })
  }

  const inputProps = {
    onChange: handleChange,
    required: true,
    defaultValue: settingsApi.editing ? undefined : '-',
    disabled: !settingsApi.editing,
  }

  return (
    <div className="nsi-main__container">
      <Form editing={settingsApi.editing} onSubmit={settingsApi.updateSettings}>
        <div className="section_with_separator">
          <h2 className="system__title">{translations.email_settings}</h2>
          <div className="system__grid" style={{ marginBottom: 0 }}>
            <InputRow label={translations.email_host}>
              <TextInput value={settingsApi?.draft?.email_host} name={'email_host'} {...inputProps} />
            </InputRow>
            <InputRow label={translations.email_port}>
              {settingsApi.editing ? (
                <NumberInput
                  value={settingsApi?.draft?.email_port}
                  name={'email_port'}
                  {...inputProps}
                  fullWidth={true}
                  integer={true}
                />
              ) : (
                <span>{settingsApi?.draft?.email_port ?? '-'}</span>
              )}
            </InputRow>
            <InputRow label={translations.email_user}>
              <TextInput value={settingsApi?.draft?.email_user} name={'email_user'} {...inputProps} />
            </InputRow>
            <InputRow label={translations.email_password}>
              <PasswordInput value={settingsApi?.draft?.email_password} name={'email_password'} {...inputProps} />
            </InputRow>
          </div>
        </div>

        <div className="section_with_separator">
          <h2 className="system__title">{translations.xml_settings}</h2>
          <div className="system__grid">
            <InputRow label={translations.xml_success_dir}>
              <TextInput value={settingsApi?.draft?.xml_success_dir} name={'xml_success_dir'} {...inputProps} />
            </InputRow>
          </div>
          <div>
            <h2 className="system__title" style={{ fontWeight: 'lighter', fontSize: '20px' }}>
              {translations.xml_mapping}
            </h2>
            <XMLMappingTable
              editing={settingsApi.editing}
              xml_mapping={settingsApi?.draft?.xml_mapping ?? []}
              onChange={handleChange}
              translations={translations}
            />
          </div>
        </div>

        <div>
          <h2 className="system__title">{translations.files_settings}</h2>
          <div className="system__grid">
            <InputRow label={translations.files_error_dir}>
              <TextInput value={settingsApi?.draft?.files_error_dir} name={'files_error_dir'} {...inputProps} />
            </InputRow>
            <InputRow label={translations.files_success_dir}>
              <TextInput value={settingsApi?.draft?.files_success_dir} name={'files_success_dir'} {...inputProps} />
            </InputRow>
            <InputRow label={translations.files_target_pattern}>
              <TextInput
                value={settingsApi?.draft?.files_target_pattern}
                name={'files_target_pattern'}
                {...inputProps}
              />
            </InputRow>
          </div>
          <div>
            <h2 className="system__title" style={{ fontWeight: 'lighter', fontSize: '20px' }}>
              {translations.files_mapping}
            </h2>
            <FilesMappingTable
              editing={settingsApi.editing}
              files_mapping={settingsApi?.draft?.files_mapping ?? []}
              onChange={handleChange}
              translations={translations}
            />
          </div>
        </div>
        {renderFooter(settingsApi, <SOPHUploadButton />, null)}
      </Form>
    </div>
  )
}

export default AepsSettings
