import { useSelector } from 'react-redux'
import CatalogTree from '../components/CatalogTree/CatalogTree'
import { ReduxState } from '../../../redux/store.types'
import { selectAccessRights } from '../../App/app.selectors'
import { Route, Routes } from 'react-router'
import OrganizationTypeFormRoute from './components/OrganizationTypeForms/OrganizationTypeFormRoute'
import OrganizationTypeFormCreate from './components/OrganizationTypeForms/OrganizationTypeFormCreate'
import { OrganizationFormRoute } from './components/OrganizationForms/OrganizationFormRoute'
import OrganizationFormCreate from './components/OrganizationForms/OrganizationFormCreate'

export const OrganizationsPage = () => {
  const organizations = useSelector((state: ReduxState) => state.organizations.organizations)
  const [allowTypesEditing, allowOrganizationsEditing] = useSelector((state: ReduxState) => {
    const rights = selectAccessRights(state)

    return [rights['catalogs:update'], rights['catalogs:update_items']]
  })

  return (
    <>
      <CatalogTree
        title={'catalogs.organization.types'}
        items={organizations}
        instanceCreatePathGenerator={(id) => `/catalogs/organizations/${id}/create`}
        itemCreatePath={'/catalogs/organizations/create'}
        routePrefix={'/catalogs/organizations/'}
        allowSchemasEditing={allowTypesEditing}
        allowInstancesEditing={allowOrganizationsEditing}
      />
      <div className={'nsi-main__wrapper'}>
        <div className={'nsi-main__container'}>
          <Routes>
            <Route path={'create'} element={<OrganizationTypeFormCreate />} />
            <Route path={':id'} element={<OrganizationTypeFormRoute />} />
            <Route path={':id/create'} element={<OrganizationFormCreate />} />
            <Route path={':id/instance/:organizationId'} element={<OrganizationFormRoute />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
