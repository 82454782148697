import { IGaugeSettings } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import React from 'react'
import useResize from '../../hooks/useResize'
import { RESERVED_CHART_IDS } from '../../services/data/constants'
import Gauge from '../../shared/Gauge/Gauge'

const ID = RESERVED_CHART_IDS.OPLIM_GAUGE

const GaugeList = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>()
  const [lastResize, setResize] = React.useState(0)
  useResize(ref, () => setResize((prev) => prev + 1))

  const gauges = []

  if (props.registrator) {
    const device_id = props.registrator.id
    const rv = props.registrator.rated_values

    // temporary arbitrary limits
    const limit1 = 4 * rv.stator_voltage
    const limit2 = 2 * rv.stator_current
    const limit3 = rv.active_power

    const settings1: IGaugeSettings = {
      title: '',
      mode: 'arrow',
      precision: 2,
      parameter_id: 'VoltageMagnitudePhaseA',
      device_id,
      boundaries: { lower: 0, upper: limit1 },
      ranges: [
        { lower: 0.6 * limit1, upper: 0.8 * limit1, type: 'warning', color: '' },
        { lower: 0.8 * limit1, upper: limit1, type: 'danger', color: '' },
      ],
    }

    const settings2: IGaugeSettings = {
      title: '',
      mode: 'slider',
      precision: 0,
      parameter_id: 'CurrentMagnitudePhaseA',
      device_id,
      boundaries: { lower: 0, upper: limit2 },
      ranges: [
        { lower: 0.6 * limit2, upper: 0.8 * limit2, type: 'warning', color: '' },
        { lower: 0.8 * limit2, upper: limit2, type: 'danger', color: '' },
      ],
    }

    const settings3: IGaugeSettings = {
      title: '',
      mode: 'arrow',
      precision: 1,
      parameter_id: 'PowerActivePhaseA',
      device_id,
      boundaries: { lower: 0, upper: limit3 },
      ranges: [
        { lower: 0.6 * limit3, upper: 0.8 * limit3, type: 'warning', color: '' },
        { lower: 0.8 * limit3, upper: limit3, type: 'danger', color: '' },
      ],
    }

    gauges.push(
      <Gauge settings={settings1} key={ID} id={ID} lastResize={lastResize} />,
      <Gauge settings={settings2} key={ID + 1} id={ID + 1} lastResize={lastResize} />,
      <Gauge settings={settings3} key={ID + 2} id={ID + 2} lastResize={lastResize} />
    )
  }

  return (
    <div ref={ref} className="oplim__gauge-list">
      {gauges}
    </div>
  )
}

interface Props {
  registrator: Equipment
}

export default React.memo(GaugeList)
