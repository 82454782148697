import { FormattedMessage } from 'react-intl'
import Loader from '../../shared/Utils/Loader'
import { SettingsState } from './Settings/settings.hooks'
import { ReactNode } from 'react'

function renderFooter<T>(state: SettingsState<T>, defaultChildren: ReactNode = null, onSubmit?: () => void) {
  if (state.loading) return renderLoader()
  else if (state.editing) return renderFormFooter(state, onSubmit)
  else return renderDefaultFooter(state, defaultChildren)
}

// buttons before user starts editing
function renderDefaultFooter<T>(state: SettingsState<T>, children?: ReactNode) {
  return (
    <div className="system__content-footer">
      <button key={'edit'} type="button" className="system__button is-default" onClick={state.startEditing}>
        <FormattedMessage id="common.edit" />
      </button>
      {children}
    </div>
  )
}

// button after user starts editing
function renderFormFooter<T>(state: SettingsState<T>, onSubmit?: () => void) {
  return (
    <div className="system__content-footer">
      <button key={'cancel'} type="button" className="system__button is-secondary" onClick={state.cancelDraft}>
        <FormattedMessage id="common.cancel" />
      </button>
      <button
        key={'save'}
        type="submit"
        className="system__button is-default"
        onClick={onSubmit === undefined ? state.updateSettings : onSubmit}
      >
        <FormattedMessage id="common.save" />
      </button>
    </div>
  )
}

function renderLoader() {
  return (
    <div className="system__content-footer" style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader />
    </div>
  )
}

export default renderFooter
