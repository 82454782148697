import React from 'react'
import { formatDate } from '../../../utils/lang'
import AckButton from './AckButton'
import IncidentDetails from './IncidentDetails'
import { DenormalizedIncident, IncidentAckState } from '../incident.interfaces'
import IncidentTime from './IncidentTime'

/**
 * Компонент для отображения одной сигнальной ситуации. Если сигнальная ситуация отмечена
 * как выбранная (isSelected) то она будет выделена при помощи CSS анимации. В начале и конце анимации
 * будут вызваны соответствующие обработчики из props, для того чтобы у родительского компонента
 * была возможность прокрутить список к выбранному элементу в начале и снять выделение в конце.
 */
const IncidentsListItem = (props: Props) => {
  const { incident } = props

  let className = 'alerts-list__row alert-card ' + incident.level
  if (props.isSelected) className += ' is-selected'

  const handleClick = () => props.onClick(incident.id)

  return (
    <div
      key={incident.id}
      className={className}
      onAnimationEnd={props.onFlashEnd}
      onAnimationStart={props.onFlashStart}
      onClick={handleClick}
    >
      <div className="alerts-list__col col_situation">
        <div className="alerts-text is-bold">{incident.title}</div>
        <IncidentTime incident={incident} includeDate={true} />
        <div className="alerts-text">{incident.text}</div>
      </div>

      <div className="alerts-list__col col_details">
        <IncidentDetails details={incident.details} />
      </div>

      <div className="alerts-list__col col_ack">
        {incident.ack_state === IncidentAckState.WaitingAck && <AckButton id={incident.id} level={incident.level} />}

        {incident.ack_state === IncidentAckState.Acked && (
          <React.Fragment>
            <div>{formatDate(incident.ack_ts, 'HH:mm:ss')}</div>
            <div className="alerts-list__user">{incident.ack_user}</div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

interface Props {
  incident: DenormalizedIncident
  isSelected: boolean
  onFlashStart: (e: React.AnimationEvent<HTMLDivElement>) => void
  onFlashEnd: (e: React.AnimationEvent<HTMLDivElement>) => void
  onClick: (id: number) => void
}

export default React.memo(IncidentsListItem)
