import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react'

/**
 * Хак для использования forwarded ref внутри компонента
 * */
const useCombinedRefs = <T extends HTMLElement>(
  ...refs: (MutableRefObject<T> | ForwardedRef<T>)[]
): MutableRefObject<T> => {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export default useCombinedRefs
