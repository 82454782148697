import { IDashboard } from 'au-nsi/dashboards'
import { memo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useContextMenu from '../../../../hooks/useContextMenu'
import useFlip from '../../../../hooks/useFlip'
import ContextMenu from '../../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../../shared/Forms/ContextMenu/ContextMenuOption'
import { selectImagesMap } from '../../../Libraries/Images/image.selectors'
import actions from '../../dashboard.actions'
import useDashboardPlaceholderUrl from '../useDashboardPlaceholderUrl'
import styles from './dashboardsfolder.module.css'
import { DashboardsFolderModal } from './DashboardsFolderModal'

interface IProps {
  dashboards: IDashboard[]
  folder: IDashboard
  onClick: (d: IDashboard) => void
  onCopy: (d: IDashboard) => void
  onDelete: (d: IDashboard) => void
  onEdit: (d: IDashboard) => void
  onDragStart: (e: DragEvent, id: string | number) => void
  dashboardsLength: number
  isDrag: boolean
  allowEditing: boolean
}

export const DashboardsFolder = memo(
  ({
    dashboards,
    folder,
    onClick,
    onCopy,
    onDelete,
    onEdit,
    onDragStart,
    isDrag,
    dashboardsLength,
    allowEditing,
  }: IProps) => {
    const dashboardPlaceholder = useDashboardPlaceholderUrl()
    const dispatch = useDispatch()

    const images = useSelector(selectImagesMap)

    const folderRef = useFlip([dashboardsLength, folder.user_ordering_index], !isDrag).element
    const menuRef = useRef()

    const { open: menuOpen, setOpen: setMenuOpen } = useContextMenu(folderRef, menuRef, !!allowEditing)

    const [open, setOpen] = useState(false)

    const onDashboardRemove = (dashboardId: string) => {
      setOpen(false)
      dispatch(actions.removeFromDraftFolder(dashboardId))
    }

    const handleFolderDisband = () => {
      dispatch(actions.disbandDraftFolder(folder.id))
      setMenuOpen(false)
    }

    const handleFolderRemove = () => {
      dispatch(actions.removeDraftFolder(folder.id))
      setMenuOpen(false)
    }

    const handleClick = (e) => {
      !e.ctrlKey && !isDrag && setOpen(!open)
    }

    const renderFolderInner = () => {
      const cols = []

      for (let i = 0; i < 4; ++i) {
        const background = dashboards[i]
          ? dashboards[i].image_id
            ? `url(${images.get(dashboards[i].image_id).src_url})`
            : `url(${dashboardPlaceholder})`
          : ''

        const border = dashboards[i] ? '2px solid var(--bg-default)' : 'none'

        cols.push(
          <div
            className={styles.dashboardsFolderInnerDash}
            key={folder.id + 'inner' + i}
            style={{ background, border }}
          />
        )
      }

      if (dashboards.length > 4)
        cols[3] = (
          <div
            className={styles.dashboardsFolderInnerDash}
            key={folder.id + 'inner3'}
            style={{ background: 'var(--bg-default)', border: '2px solid var(--bg-default)' }}
          >{`+${dashboards.length - 3}`}</div>
        )

      return (
        <div onClick={handleClick} className={styles.dashboardsFolderInner} style={{ overflow: 'hidden' }}>
          {cols}
        </div>
      )
    }

    const menu = (
      <ContextMenu ref={menuRef} open={menuOpen}>
        <ContextMenuOption type={'disband'} onClick={handleFolderDisband} />
        <ContextMenuOption type={'delete'} onClick={handleFolderRemove} />
      </ContextMenu>
    )

    const draggable = allowEditing && !open

    return (
      <div ref={folderRef} style={isDrag && !open ? { zIndex: 999999 } : { position: 'relative' }}>
        {menu}
        <div
          className={styles.dashboardsFolder}
          id={folder.id + 'folder'}
          data-test-id={'folder'}
          data-test-dashboards={JSON.stringify(dashboards.map((d) => d.id))}
          draggable={draggable}
          onDragStart={(e) => {
            draggable && onDragStart(e as any, folder.id)
          }}
        >
          {open && (
            <DashboardsFolderModal
              dashboards={dashboards}
              folder={folder}
              folderRef={folderRef}
              images={images}
              onClose={() => setOpen(false)}
              onClick={onClick}
              onCopy={onCopy}
              onDelete={onDelete}
              onEdit={onEdit}
              onDashboardRemove={onDashboardRemove}
              onNameChange={(name) => dispatch(actions.renameDraftFolder(folder.id, name))}
            />
          )}
          {renderFolderInner()}
          <div className={'dashboards__tile-name'}>
            <span className={'line_clamp'} title={folder.folder_name}>
              {folder.folder_name}
            </span>
          </div>
        </div>
      </div>
    )
  }
)
