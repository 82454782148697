import React from 'react'
import { Unsubscribe } from '../../../utils/events'
import rAF from '../../../utils/rAF'
import GanttContext from '../gantt.context'
import { Boundaries } from '../../../shared/interfaces'
import globalClock from '../../../services/clock/clock.factory'

class TimelineCursor extends React.Component<Props, State> {
  context: React.ContextType<typeof GanttContext>
  static contextType = GanttContext

  state = { playerFrame: globalClock.getFrame() }

  private unsubscribe: Unsubscribe

  componentDidMount() {
    this.unsubscribe = globalClock.frame$.subscribe((playerFrame) => {
      this.setState({ playerFrame })
    }, true)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  private startDragging = () => {
    document.addEventListener('mouseup', this.stopDragging)
    document.addEventListener('mousemove', this.moveCursor)
  }

  private stopDragging = () => {
    document.removeEventListener('mouseup', this.stopDragging)
    document.removeEventListener('mousemove', this.moveCursor)
  }

  private moveCursor = rAF((e: MouseEvent) => {
    const x = e.clientX
    const { right, width } = this.props.boundaries
    const { ganttFrame, ganttTime } = this.props

    const time = ganttTime - Math.round((ganttFrame * (right - x)) / width)
    globalClock.setPlayerTime(time)
  })

  private calcStyles() {
    const { playerFrame } = this.state
    const { boundaries, ganttTime, ganttFrame, playerTime } = this.props
    const { width } = boundaries

    const translateX = Math.round((width * (playerTime - ganttTime + ganttFrame)) / ganttFrame)
    const cursorWidth = Math.min(Math.round((width * playerFrame) / ganttFrame), translateX)
    const cursorHeight = this.context.bodyRect.height

    return { translateX, cursorWidth, cursorHeight }
  }

  render() {
    const { translateX, cursorWidth, cursorHeight } = this.calcStyles()

    return (
      <div className="gantt__cursor" style={{ transform: `translateX(${translateX - 2}px)` }}>
        <div className="gantt__cursor-grabber" onMouseDown={this.startDragging} />
        <div className="gantt__cursor-mark" style={{ width: cursorWidth, height: cursorHeight }} />
      </div>
    )
  }
}

interface Props {
  boundaries: Boundaries
  ganttTime: number
  ganttFrame: number
  playerTime: number
}

interface State {
  playerFrame: number
}

export default TimelineCursor
