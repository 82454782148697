import InstanceRow from '../../Catalogs/components/InstanceForm/InstanceRow'
import { OrganizationTypeWithInstances } from '../../Catalogs/Organizations/organizations.types'
import { AnyCatalogWithInstances } from '../../Catalogs/catalogs.types'
import { UserData } from './register.utils'

interface IProps {
  organizationType: OrganizationTypeWithInstances
  user: UserData
  onChange: (user: UserData) => void
  depsCatalogs: AnyCatalogWithInstances[]
}

export const OrganizationInfoForm = ({ organizationType, user, onChange, depsCatalogs }: IProps) => {
  return (
    <table className={'nsi-settings-table'} style={{ tableLayout: 'fixed', marginBottom: '30px' }}>
      <tbody>
        {organizationType.registration_schema.map((schema) => (
          <InstanceRow
            key={schema.id}
            value={user.user_data[schema.id]}
            rowSchema={schema}
            editing={true}
            onChange={(id, val) => onChange({ ...user, user_data: { ...user.user_data, [id]: val } })}
            catalogs={depsCatalogs}
          />
        ))}
      </tbody>
    </table>
  )
}
