import useDeviceWidth from '../../hooks/useDeviceWidth'
import { ReactComponent as SortAsc } from '../../icons/sort-asc.svg'
import { ReactComponent as SortDesc } from '../../icons/sort-desc.svg'

/**
 * Адаптивная шапка таблицы - на мобильных выводит одну ячейку со всеми заголовками,
 * на десктопах - по отдельной ячейке на каждый заголовок
 */
const TableHeader = (props: Props) => {
  const { isMobile } = useDeviceWidth()
  const { titles, sortOrder, sortColumn, onClick } = props

  if (isMobile) {
    return (
      <tr>
        <th>{titles.filter((t) => t).join(' / ')}</th>
      </tr>
    )
  }

  // иконка обозначающая по какой колонке и в каком порядке отсортирована таблица
  const Icon = sortOrder > 0 ? SortDesc : SortAsc
  const style = onClick ? { cursor: 'pointer' } : undefined

  const columns = titles.map((title, index) => {
    return (
      <th key={title} onClick={onClick ? () => onClick(index) : undefined}>
        {title}
        {index === sortColumn && <Icon style={{ margin: '0 6px', width: 13, height: 13 }} />}
      </th>
    )
  })

  return <tr style={style}>{columns}</tr>
}

interface Props {
  titles: string[]
  sortOrder?: number
  sortColumn?: number
  onClick?: (index: number) => void
}

export default TableHeader
