import { OrganizationTypeWithInstances } from '../../organizations.types'
import { useCallback } from 'react'
import { createPropertySchema } from '../../../catalogs.utils'
import produce from 'immer'
import { IPropertyDescription } from 'au-nsi/catalogs'

interface IProps {
  setter: (
    state: ((prevState: OrganizationTypeWithInstances) => OrganizationTypeWithInstances) | OrganizationTypeWithInstances
  ) => void
  deps: Array<unknown>
}

const useOrganizationTypeCallbacks = ({ setter, deps }: IProps) => {
  const handleChange = useCallback((updated: IPropertyDescription, schemaKey: string) => {
    setter((prev) => {
      const index = prev[schemaKey].findIndex((schema) => schema.id === updated.id)
      if (index === -1) return prev

      return {
        ...prev,
        [schemaKey]: [...prev[schemaKey].slice(0, index), updated, ...prev[schemaKey].slice(index + 1)],
      }
    })
  }, deps)

  const handleMetaChange = useCallback((value: any, name: keyof OrganizationTypeWithInstances) => {
    setter((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleCreateProperty = useCallback((schemaKey: string) => {
    setter((prev) => {
      return {
        ...prev,
        [schemaKey]: [...prev[schemaKey], createPropertySchema()],
      }
    })
  }, deps)

  const handleRemoveProperty = useCallback((id: string, schemaKey: string) => {
    setter((prev) => {
      const index = prev[schemaKey].findIndex((schema) => schema.id === id)
      if (index === -1) return prev

      return {
        ...prev,
        [schemaKey]: [...prev[schemaKey].slice(0, index), ...prev[schemaKey].slice(index + 1)],
      }
    })
  }, deps)

  const handleMove = useCallback((index1: number, index2: number, schemaKey: string) => {
    setter((prev) => {
      return produce(prev, (draft: OrganizationTypeWithInstances) => {
        const sp = draft[schemaKey][index1]
        draft[schemaKey][index1] = draft[schemaKey][index2]
        draft[schemaKey][index2] = sp
      })
    })
  }, deps)

  return { handleChange, handleCreateProperty, handleRemoveProperty, handleMove, handleMetaChange }
}

export default useOrganizationTypeCallbacks
