import { Select } from '@alterouniversal/au-react-components'
import { IButtonComponent, IButtonSettings, IDashboard } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import ImageInput from '../../Libraries/Images/ImageInput'
import ButtonSettingsCommands from './ButtonSettingsCommands'

const styles = ['default', 'light', 'info', 'warning', 'danger']
const actions = ['navigate', 'command']
const types = ['text', 'image']

/**
 * Модальное окно с настройками кнопки
 */
const ButtonSettings = (props: Props) => {
  const intl = useIntl()
  const [settings, setSettings] = React.useState(props.component.settings)

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleSave = () => props.onSave(settings)

  const styleOptions = React.useMemo(() => {
    return styles.map((s) => ({ value: s, title: intl.formatMessage({ id: 'dashboards.button.settings.style.' + s }) }))
  }, [])

  const typeOptions = React.useMemo(() => {
    return types.map((t) => ({ value: t, title: intl.formatMessage({ id: 'dashboards.button.settings.type.' + t }) }))
  }, [])

  const actionOptions = React.useMemo(() => {
    return actions.map((a) => ({
      value: a,
      title: intl.formatMessage({ id: 'dashboards.button.settings.action.' + a }),
    }))
  }, [])

  const dashboardOptions = React.useMemo(() => {
    return props.dashboards.map((d) => ({ value: d.id, label: d.name }))
  }, [props.dashboards])

  const isNavigate = settings.action === 'navigate'
  const isCommand = settings.action === 'command'
  const isImage = settings.type === 'image'

  const error = validate(settings)

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.action' })}>
              <Dropdown name="action" value={settings.action} options={actionOptions} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.type' })}>
              <Dropdown name="type" value={settings.type || 'text'} options={typeOptions} onChange={handleChange} />
            </InputRow>

            {isImage && (
              <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.image_id' })}>
                <ImageInput name="image_id" value={settings.image_id} onChange={handleChange} />
              </InputRow>
            )}

            <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.text' })}>
              <TextInput name="text" value={settings.text} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.style' })}>
              <Dropdown name="style" value={settings.style} options={styleOptions} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.font_size' })}>
              <NumberInput
                fullWidth={true}
                integer={true}
                max={100}
                min={6}
                name="font_size"
                onChange={handleChange}
                type="number"
                value={settings.font_size || 16}
              />
            </InputRow>

            {isNavigate && (
              <InputRow label={intl.formatMessage({ id: 'dashboards.button.settings.navigate_target' })}>
                <Select
                  name="navigate_target"
                  options={dashboardOptions}
                  value={settings.navigate_target}
                  onChange={handleChange}
                />
              </InputRow>
            )}
          </div>

          {isCommand && <ButtonSettingsCommands commands={settings.commands} onChange={handleChange} />}

          <ModalFooter
            error={error && intl.formatMessage({ id: error })}
            onSave={handleSave}
            onCancel={props.onCancel}
          />
        </Form>
      </div>
    </Modal>
  )
}

const validate = (settings: IButtonSettings) => {
  if (settings.action === 'navigate') {
    if (!settings.navigate_target) return 'dashboards.button.errors.empty_navigate_target'
  }

  if (settings.action === 'command') {
    if (settings.commands.length === 0) return 'dashboards.button.errors.empty_command'
  }

  return null
}

interface Props {
  component: IButtonComponent
  dashboards: IDashboard[]
  equipment: Equipment[]
  onCancel: () => void
  onSave: (settings: IButtonSettings) => void
  title: string
}

const mapStateToProps = (state: ReduxState) => {
  return {
    dashboards: state.dashboards.dashboards,
    equipment: state.nsi.equipment,
  }
}

export default connect(mapStateToProps)(ButtonSettings)
