import http, { handleHttpError } from '../../utils/http'
import { generateActions } from '../Collections/collection.actions'
import { SignalEvent, SignalEventRule, SignalEventType } from './se.interfaces'
import { ADD_EVENT_TO_DELETED_CACHE, RULE_UPDATED } from './se.reducers'

const API_URL = '/nsi/signal_events/'

/* signal events */

const actions = generateActions<SignalEvent>('signal_events', API_URL)

const updateRule = (rule: SignalEventRule) => (dispatch, getState) => {
  const se = getState().signal_events.selectedItem
  const rules = se.rules.filter((r) => r.level !== rule.level)
  rules.push(rule)

  dispatch(actions.updateSelectedItem('rules', rules))
}

const updateRuleField = (key: string, value: any, level: string) => (dispatch, getState) => {
  const se = getState().signal_events.selectedItem
  const rules = se.rules.map((r) => (r.level === level ? { ...r, [key]: value } : r))

  dispatch(actions.updateSelectedItem('rules', rules))
}

const fetchDeletedRule = (id: number) => async (dispatch) => {
  try {
    const r1 = await http.get(API_URL + 'rules/' + id)
    const rule = r1.data

    const r2 = await http.get(API_URL + rule.signal_event_id)
    const se = r2.data

    se.rules = [rule]
    dispatch(addDeletedRule(id, r2.data))
  } catch (error) {
    console.error(error)
  }
}

const addDeletedRule = (ruleId: number, signalEvent: SignalEvent) => {
  return { type: ADD_EVENT_TO_DELETED_CACHE, ruleId, signalEvent }
}

const ruleUpdated = (updates: SignalEventRule) => {
  return { type: RULE_UPDATED, updates }
}

/* signal event types */

const loadSETypes = () => (dispatch, getState) => {
  const state = getState().signal_events
  if (state.isTypesLoaded) return

  http
    .get(API_URL + 'types')
    .then((r) => dispatch(seTypesLoaded(r.data)))
    .catch(handleHttpError)
}

const selectSEType = (id: string) => ({ type: `app/signal_events_types/SELECT`, payload: id })

const updateSEType = (updates: SignalEventType) => (dispatch) => {
  dispatch(seTypeUpdated(updates))

  http.patch(API_URL + 'types/' + updates.id, updates).catch(handleHttpError)
}

const seTypeUpdated = (updates: SignalEventType) => ({ type: `app/signal_events_types/UPDATE`, payload: updates })
const seTypeDeleted = (updates: SignalEventType) => ({ type: `app/signal_events_types/DELETE`, payload: updates })
const seTypeCreated = (updates: SignalEventType) => ({ type: `app/signal_events_types/CREATE`, payload: updates })

const seTypesLoaded = (payload: SignalEventType[]) => ({ type: `app/signal_events_types/SET`, payload })

const seActions = {
  ...actions,
  updateRule,
  updateRuleField,
  fetchDeletedRule,
  addDeletedRule,
  ruleUpdated,
  loadSETypes,
  selectSEType,
  updateSEType,
  seTypeUpdated,
  seTypesLoaded,
  seTypeDeleted,
  seTypeCreated,
}

export default seActions
