import { ReactNode, useContext, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useContextMenu from '../../../hooks/useContextMenu'
import ContextMenu from '../../../shared/Forms/ContextMenu/ContextMenu'
import ContextMenuOption from '../../../shared/Forms/ContextMenu/ContextMenuOption'
import actions from '../../Dashboards/dashboard.actions'
import GanttContext from '../gantt.context'
import GanttModal from './GanttModal'

interface Props {
  id: number
  page: string
  children: ReactNode
}

/**
 * Компонент открывающий меню при клике правой кнопки мыши по диаграмме ганта. В меню выводится
 * один пункт - Настройки, при выборе которого открывается модальное окно с настройками диаграммы.
 */
const GanttContainer = ({ id, page, children }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>()
  const menuRef = useRef<HTMLDivElement>()

  const dispatch = useDispatch()
  const context = useContext(GanttContext)
  const showSettings = context.settings != null

  const { open, setOpen } = useContextMenu(wrapperRef, menuRef, true, true)
  const [modalOpen, setModalOpen] = useState(false)

  const component: any = { id, dashboard_id: page, settings: context.settings }

  const openModal = () => {
    setModalOpen(true)
    setOpen(false)
  }

  const handleSave = (settings) => {
    dispatch(actions.updateComponentSettings({ ...component, settings }))
    setModalOpen(false)
  }

  const menu = (
    <ContextMenu ref={menuRef} open={showSettings && open}>
      <ContextMenuOption type="settings" onClick={openModal} />
    </ContextMenu>
  )

  return (
    <div className="gantt-table" ref={wrapperRef}>
      {menu}
      {modalOpen && (
        <GanttModal
          component={component}
          hideEquipmentSettings={true}
          onCancel={() => setModalOpen(false)}
          onSave={handleSave}
        />
      )}
      {children}
    </div>
  )
}

export default GanttContainer
