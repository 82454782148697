import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../../pages/App/app.selectors'
import Loader from '../../Utils/Loader'
import styles from './placepicker.module.css'
import SearchInput from '../Search/SearchInput'
import placeManager from '../../../services/geo/placeManager/placeManager'
import { IPlace } from 'au-nsi/shared'
import { SelectOption } from '@alterouniversal/au-react-components'

interface IProps {
  value: string
  onChange: (place: IPlace, name: string) => void
  name?: string
  placeholder?: string
  wrapperClassName?: string
}

const throttleTimeout = 800

/**
  Компонент поиска мест по миру с автокомплитом.
*/
const PlaceAutocomplete = ({ onChange, name, wrapperClassName, value, placeholder }: IProps) => {
  const locale = useSelector(selectLanguage)
  const [searchValue, setSearchValue] = useState('')
  const [autocompleteOptions, setAutocompleteOptions] = useState<SelectOption[]>([])
  const [loadingGeocode, setLoadingGeocode] = useState(false)
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false)

  useEffect(() => {
    setSearchValue(value)
  }, [value])

  const handleSearchValueChange = (value: string) => {
    if (loadingGeocode) return

    setSearchValue(value)
    if (value.trim() === '') {
      onChange({} as any, name)
      return setAutocompleteOptions([])
    }

    setLoadingAutocomplete(true)
    placeManager
      .getAddressPredictions(value, locale)
      .then((r) => {
        const options = r.predictions.map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id,
        }))
        setAutocompleteOptions(options)
      })
      .finally(() => setLoadingAutocomplete(false))
  }

  const handleChange = async (place_id: string) => {
    setLoadingGeocode(true)
    const place = await placeManager.getPlaceInfo(place_id, locale)
    if (!place) return
    setLoadingGeocode(false)

    setAutocompleteOptions([])
    setSearchValue(place.name)
    onChange(place, name)
  }

  const renderOptions = () => {
    return autocompleteOptions.map((option) => (
      <div
        className={'nsi-select__menu-item is-selectable'}
        key={option.label}
        onClick={() => handleChange(option.value)}
      >
        {option.label}
      </div>
    ))
  }

  return (
    <div className={wrapperClassName} style={{ opacity: loadingGeocode ? 0.5 : 1 }}>
      <div className="nsi-select" style={{ position: 'relative' }}>
        <SearchInput
          onChange={handleSearchValueChange}
          inputStyle={loadingAutocomplete ? { paddingRight: '40px' } : {}}
          placeholder={placeholder}
          displayClearIcon={!loadingAutocomplete}
          throttling={throttleTimeout}
          value={searchValue}
        />
        {loadingAutocomplete && <Loader style={{ position: 'absolute', right: '-5px', top: '8px' }} />}

        {autocompleteOptions.length > 0 && (
          <div className="nsi-select__menu">
            {loadingGeocode && <Loader className={styles.placePickerLoader} />}
            {renderOptions()}
          </div>
        )}
      </div>
    </div>
  )
}

export default PlaceAutocomplete
