import { IChartAxisSettings } from 'au-nsi/dashboards'
import DataService from '../../../services/data/data.service'
import { Chart } from '../Chart'
import * as utils from '../utils/chart.utils'
import { max2scale } from '../utils/scale.utils'

/**
 * Автоматическое масштабирование оси Y
 */
class AutoscalePlugin {
  private lastExecution = 0

  // применять автомасштабирование только если нажата его иконка
  // и если пользователь не зафиксировал в настройках верхнюю и нижнюю границы
  private get shouldAutoscale() {
    const { controls, settings } = this.chart.props
    return controls.autoscale && settings.axes.some((axis) => axis.maxY == null || axis.minY == null)
  }

  constructor(private chart: Chart) {
    chart.dataHandlers.push(this.onData)
    chart.updateHandlers.push(this.onUpdate)
  }

  private onData = ({ dataUpdated }) => {
    if (this.shouldAutoscale) {
      const now = Date.now()

      // если данные не изменились, а только сдвинулись границы кадра, то можно пропустить несколько
      // рендерингов, т.к. для пользователя задержка автомасштабирования в 200мс будет незаметной
      if (dataUpdated || now - this.lastExecution > 200) {
        this.autoscale()
        this.lastExecution = now
      }
    }
  }

  private onUpdate = () => {
    if (this.shouldAutoscale) {
      this.autoscale()
      this.lastExecution = Date.now()
    }
  }

  private autoscale() {
    const chart = this.chart
    const { t0, t1 } = chart
    const { settings } = chart.props
    const aggregationMode = settings.aggregationMode ?? 2

    const minY = [...chart.state.minY]
    const maxY = [...chart.state.maxY]
    let isUpdated = false

    // у каждой оси свой масштаб, поэтому проходим по всем
    for (let i = 0; i < settings.axes.length; i++) {
      const axis = settings.axes[i]
      const service = chart.props.services[i]
      const includePrediction = chart.props.settings.prediction || false
      let minimum = null
      let maximum = null

      // поиск минимума и максимума среди всех значений попавших в кадр
      for (const { i0, i1, values, mirror } of iterate({ axis, service, aggregationMode, t0, t1, includePrediction })) {
        if (!values) continue

        for (let i = i0; i <= i1; i++) {
          const value = mirror ? -values[i] : values[i]

          if (value == null) continue
          if (minimum == null || value < minimum) minimum = value
          if (maximum == null || value > maximum) maximum = value
        }
      }

      if (minimum == null || maximum == null) continue

      const extremum = Math.max(Math.abs(minimum), Math.abs(maximum))

      // дополнительные проверки на случай если обе границы просто равны или обе равны нулю
      let span = maximum - minimum || extremum || 1

      // если минимум и максимум слишком близки и отличаются только в пятом знаке после запятой
      if (span * 1e5 < minimum) span = minimum

      // В обычной ситуации нижняя граница графика может быть ближе к линии, т.к. там нет
      // заголовка и элементов управления. Но если добавлена зеркальная ось, то там выводится ее название
      // и значит необходимо оставлять такой же большой отступ как сверху.
      let axisMinY = settings.mirrorY
        ? utils.updateTopBoundary(chart.state.minY[i], minimum, -span)
        : utils.updateBottomBoundary(chart.state.minY[i], minimum, span)

      let axisMaxY = utils.updateTopBoundary(chart.state.maxY[i], maximum, span)

      // проверки на случай если одна из границ зафиксирована пользователем
      if (axis.minY != null) axisMinY = axis.minY
      if (axis.maxY != null) axisMaxY = axis.maxY

      if (axisMinY !== minY[i] || axisMaxY !== maxY[i]) {
        minY[i] = axisMinY
        maxY[i] = axisMaxY
        isUpdated = true
      }

      const scale = max2scale(extremum)
      if (scale !== chart.props.scales[i]) {
        chart.props.onAutoScale(scale, i)
      }
    }

    if (isUpdated) {
      chart.scheduleUpdate({ minY, maxY })
    }
  }
}

// итератор по всем значениям всех параметров попадающих в кадр
function* iterate(options: IterateOptions) {
  const { axis, service, aggregationMode, t0, t1, includePrediction } = options
  const checkMin = (aggregationMode & 1) === 1
  const checkMax = (aggregationMode & 4) === 4

  for (const line of axis.lines) {
    const chunks = service.selectRange({
      deviceId: line.device_id,
      parameterId: line.parameter_id,
      t0,
      t1,
      allowOverlap: true,
      useStackedValues: true,
      includePrediction,
    })

    if (!chunks) continue

    for (const chunk of chunks) {
      const { i0, i1 } = chunk

      if (checkMin) {
        yield { i0, i1, values: chunk.data[line.parameter_id + ':min'], mirror: line.mirror }
      }

      if (checkMax) {
        yield { i0, i1, values: chunk.data[line.parameter_id + ':max'], mirror: line.mirror }
      }

      yield { i0, i1, values: chunk.data[line.parameter_id], mirror: line.mirror }
    }
  }
}

interface IterateOptions {
  axis: IChartAxisSettings
  service: DataService
  aggregationMode: number
  t0: number
  t1: number
  includePrediction: boolean
}

export default AutoscalePlugin
