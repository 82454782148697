import { CSSProperties, PropsWithChildren } from 'react'
import { CSSTransition } from 'react-transition-group'
import './smoothDropdown.css'

interface Props {
  className?: string
  style?: CSSProperties
  maxHeight?: number
  overflow?: 'visible' | 'hidden' | 'auto'
  fillMode?: FillMode
  open: boolean
}

const SmoothDropdown = ({
  children,
  className,
  style,
  maxHeight,
  overflow = 'auto',
  open,
}: PropsWithChildren<Props>) => {
  const handleEnter = (block) => {
    if (!block) return

    const ht = maxHeight ? Math.min(maxHeight, block.offsetHeight) : block.offsetHeight

    if (maxHeight && block.offsetHeight > maxHeight) block.style.overflow = overflow
    else block.style.overflow = 'hidden'

    block.style.maxHeight = 0
    block.dataset.targetHeight = ht + 'px'
  }

  const handleEntering = (block) => {
    if (!block) return

    block.style.maxHeight = block.dataset.targetHeight
    delete block.dataset.targetHeight
  }

  const handleEntered = (block) => {
    block.style.overflow = overflow
    if (!maxHeight) block.style.maxHeight = '10000px'
  }

  const handleExit = (block) => {
    if (!block) return

    block.style.maxHeight = block.offsetHeight + 'px'
    if (!maxHeight || block.offsetHeight < maxHeight) block.style.overflow = 'hidden'
    else block.style.overflow = 'auto'
  }

  return (
    <CSSTransition
      in={open}
      timeout={300}
      unmountOnExit={true}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      classNames={'smoothDropdownBehavior'}
      onExit={handleExit as any}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default SmoothDropdown
