import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'

const Tabs = (props: Props) => {
  const [selectedIndex, setIndex] = React.useState(0)

  const tabs = props.tabs.map(({ id }, i) => {
    const active = i === selectedIndex
    const className = active ? 'nsi-tab is-active' : 'nsi-tab'

    return (
      <div key={id} className={className} data-id={id} onClick={() => setIndex(i)}>
        <FormattedMessage id={id} />
      </div>
    )
  })

  const { render } = props.tabs[selectedIndex]

  return (
    <React.Fragment>
      <div className="nsi-tabs" style={props.tabsStyle}>
        {tabs}
      </div>
      {render()}
    </React.Fragment>
  )
}

interface Props {
  tabs: Array<{ id: string; render: () => React.ReactElement }>
  tabsStyle?: CSSProperties
}

export default Tabs
