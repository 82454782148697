import { IMapSettings } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import arrowRight from '../../../icons/long-arrow.svg'

export const getVectorHtml = (angle: number) => {
  return `<div class='vector-icon-map center'>
            <div style="width: 25px; height: 25px; background-image: url(${arrowRight}); transform: rotate(${angle}rad)"/>
          </div>`
}

export const generateGradientHtml = (updates: IUpdates, magnitudeMin: number, magnitudeMax: number) => {
  let value = (updates.magnitude - magnitudeMin) / (magnitudeMax - magnitudeMin)
  if (value < 0) value = 0
  if (value > 1) value = 1

  const color = updates.magnitude != null ? `hsla(${value * 270 - 15}, 100%, 50%, 0.8)` : `rgba(0, 0, 0, 0.6)`
  return `<div class='center'><div style='border-radius: 50%; width: 24px; height: 24px; background: ${color}; border: 1px solid var(--bg-600);'/></div>`
}

export const generatePopup = (info: {
  name: string
  magnitudeName: string
  magnitudeValue: string | number
  angleName: string
  angleValue: string | number
}) => {
  let result = `
    <b>${info.name || '—'}</b><br>
    <b>${info.magnitudeName}:</b> ${info.magnitudeValue}`

  if (info.angleName) {
    result += `<br><b>${info.angleName}:</b> ${info.angleValue}`
  }

  return `<div style='color: black;'>${result}</div>`
}

/**
 * Форматирование значения угла для вывода на экран
 */
export const formatAngle = (radians: number) => {
  if (radians == null) return '—'

  // перевод из [-pi, pi] в [0, 2pi]
  const normalized = (radians + 2 * Math.PI) % (2 * Math.PI)

  // перевод в градусы
  const deg = (180 * normalized) / Math.PI

  // перевод дробной части в минуты (1 минута = 1/60 градуса)
  const min = 60 * (Math.abs(deg) % 1)

  return `${deg.toFixed(0)}°${min.toFixed(0)}′`
}

export const generateMapPoints = (
  equipment: IMapSettings['equipment'],
  equipmentInfo: Map<Equipment['id'], Partial<Equipment>>
) => {
  return equipment.map((eq) => {
    const updates: any = { equipment_id: eq.id, modalContent: '' }
    const address = equipmentInfo.get(eq.id)?.address
    if (address) {
      updates.lat = address.lat
      updates.lng = address.lng
    }

    return { ...eq, ...updates }
  })
}

export interface IUpdates {
  magnitude: number
  angle: number
  magnitudeStr: string
  angleStr: string
  fullUpdate: boolean
}
