import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'

const SizeSelector = (props: Props) => {
  const value = props.value || 0
  const units = useSelector((state: ReduxState) => state.parameters.units)

  return (
    <InputRow label={props.label}>
      <NumberInput
        type="parameter"
        name={props.name}
        value={value}
        onChange={props.onChange}
        units={units}
        prefixes={prefixes}
        baseUnit={units.byte}
        displayUnit={units.GB}
        disabled={!props.editing}
        disabledStyle="text"
      />
    </InputRow>
  )
}

const prefixes = {}

interface Props {
  name: string
  value: number
  onChange: (value: number, name: string) => void
  editing: boolean
  label: string
}

export default SizeSelector
