/**
 * Утилиты для работы с условиями на значения параметров встречающиеся
 * в нескольких компонентах.
 * (Пример условия: если параметр А на устройстве Б больше 100)
 */
import { IParameterCondition } from 'au-nsi/dashboards'
import DataService from '../../services/data/data.service'

/**
 * Выбрать первое сработавшее условие
 */
export const matchCondition = <T extends IParameterCondition>(service: DataService, conditions: T[]) => {
  for (const c of conditions) {
    const p = service.selectCurrentPoint(c.device_id)
    if (p == null) continue

    const value = p[c.parameter_id]
    if (value == null) continue

    const match = matchers[c.condition](value, c.value)
    if (match) return c
  }

  return null
}

export const operatorOptions = [
  { value: '<', title: '<' },
  { value: '<=', title: '≤' },
  { value: '=', title: '=' },
  { value: '!=', title: '≠' },
  { value: '>', title: '>' },
  { value: '>=', title: '≥' },
]

export const matchers: Record<string, Matcher> = {
  '<': (p, v) => p < v,
  '<=': (p, v) => p <= v,
  '=': (p, v) => p === v,
  '!=': (p, v) => p !== v,
  '>=': (p, v) => p >= v,
  '>': (p, v) => p > v,
}

type Matcher = (parameterValue: number, conditionValue: number) => boolean
