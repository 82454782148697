import { IRoute } from './nav.utils'
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg'
import { createPortal } from 'react-dom'
import { useRecoilValue } from 'recoil'
import { systemEnvState } from '../../System/system.state'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import css from '../../../shared/HideableLeftMenu/MobileLeftMenu/mobileLeftMenu.module.css'
import styles from '../../../shared/HideableLeftMenu/hideableLeftMenu.module.css'
import { FormattedMessage, useIntl } from 'react-intl'
import PrevButton from '../../../shared/Utils/PrevButton'
import Links from './Links'

interface IProps {
  routes: IRoute[]
  forks: Set<string>
}

export const mobileNavPortalBlockId = 'system__nav__screen__panel'

/**
 * Выдвижное меню для мобильных устройств. Рендерится поверх всей старницы (position: fixed).
 *
 * Может включать в себя боковую панель текущего экрана (например, дерево устройств в топологии).
 * Для этого компонент {MobileLeftMenu}, вызываемый непсредственно в экране (в случае, если это необходимо)
 * рендерит содержимое панели экрана в портале в блок с id {mobileNavPortalBlockId}.
 * */
const MobileNav = (props: IProps) => {
  const intl = useIntl()
  const childrenRef = useRef<HTMLDivElement>(null)
  const envState = useRecoilValue(systemEnvState)

  // Отображаем {children} или навигацию по приложению
  const [screenPanelOpen, setScreenPanelOpen] = useState(false)
  // Пришли ли children от {MobileLeftMenu}
  const [hasChildren, setHasChildren] = useState(false)
  // Открыто/закрыто всё меню
  const [open, setOpen] = useState(false)

  // Если произошла мутация блока с id={mobileNavPortalBlockId}, значит {MobileLeftMenu} зарендерило что-то в нём в
  // портале, либо было разманчено. В случае, если множество детей непусто, считаем, что нужно показать экран
  useEffect(() => {
    if (!childrenRef.current) return

    const observer = new MutationObserver(() => {
      const open = !!childrenRef.current.children?.length
      setScreenPanelOpen(open)
      setHasChildren(open)
    })

    observer.observe(childrenRef.current, { childList: true })

    return () => observer.disconnect()
  }, [childrenRef.current])

  const menuClass = classNames({ [css.menu]: true, [css.menuClosed]: !open, [css.menuOpened]: open })
  const iconClass = classNames({ [styles.icon]: true, [css.icon]: true, [css.iconOpened]: open })

  const renderMenu = () => {
    if (screenPanelOpen) return null

    const monitoringLink = (
      <a className="top-menu__item" href={envState.grafana_url} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="nav.monitoring" />
      </a>
    )

    return (
      <div className={`top-menu__nav ${css.menuContentWrapper}`}>
        {!hasChildren && <h2 className={css.routesHeading}>{intl.formatMessage({ id: 'nav.system_nav' })}</h2>}
        <Links routes={props.routes} />
        {envState.grafana_url && monitoringLink}
      </div>
    )
  }

  const Menu = (
    <>
      <div className={menuClass}>
        {hasChildren && (
          <PrevButton
            className={css.routesSwitch}
            onCLick={() => setScreenPanelOpen(!screenPanelOpen)}
            textId={screenPanelOpen ? 'nav.menu.to_system_routes' : 'nav.menu.to_screen_panel'}
          />
        )}
        <div id={mobileNavPortalBlockId} ref={childrenRef} style={{ display: screenPanelOpen ? '' : 'none' }} />
        {!screenPanelOpen && renderMenu()}
      </div>

      <div onClick={() => setOpen(!open)} className={iconClass}>
        <ArrowRight />
        <ArrowRight />
      </div>
    </>
  )

  return createPortal(Menu, document.getElementsByTagName('body')[0])
}

export default MobileNav
