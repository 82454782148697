import { useEffect, useState } from 'react'
import { BeforeInstallPromptEvent } from './installButton.types'
import { ReactComponent as InstallIcon } from 'icons/download.svg'
import styles from './installButton.module.css'
import { useIntl } from 'react-intl'

/**
 Кнопка установки приложения.

 На момент написания документации работает только в хроме,
 в виду отсутствия beforeinstallprompt евента в остальных браузерах.
*/
const InstallButton = () => {
  const intl = useIntl()
  const [deferPrompt, setDeferPrompt] = useState<BeforeInstallPromptEvent>(null)

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault()
      setDeferPrompt(e)
    }
    const handleAppInstall = () => {
      setDeferPrompt(null)
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    window.addEventListener('appinstalled', handleAppInstall)
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
      window.removeEventListener('appinstall', handleAppInstall)
    }
  }, [])

  const handleInstall = async () => {
    await deferPrompt.prompt()
    setDeferPrompt(null)
  }

  if (!deferPrompt) return null

  return (
    <InstallIcon
      title={intl.formatMessage({ id: 'common.install_app' })}
      className={styles.installButton}
      onClick={handleInstall}
    />
  )
}

export default InstallButton
