import { formatDate } from '../../../../../utils/lang'
import KeyValueTable from '../../../../../shared/Forms/KeyValueTable/KeyValueTable'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import confirmService from '../../../../../shared/Modal/confirm.service'
import http from '../../../../../utils/http'
import css from '../request.module.css'
import { IModerationRequest } from 'au-nsi/moderation'
import { selectUsersMap } from '../../../../App/app.selectors'

interface IProps {
  request: IModerationRequest
}

/**
 * Рендер статуса, автора, модератора, даты создания, последнего измнения и модерации
 * */
const RequestMeta = ({ request }: IProps) => {
  const intl = useIntl()
  const users = useSelector(selectUsersMap)

  const getRequestStatus = () => {
    if (!request.moderated_at) return <span>{intl.formatMessage({ id: 'user.request.status.active' })}</span>
    if (request.rejected)
      return <span className="text--danger">{intl.formatMessage({ id: 'user.request.status.rejected' })}</span>
    return <span className="text--success">{intl.formatMessage({ id: 'user.request.status.approved' })}</span>
  }

  const renderCreatedBy = () => {
    return users.get(request.created_by)?.name || intl.formatMessage({ id: 'common.unknown' })
  }

  const getCreateDate = () => {
    return formatDate(request.created_at, 'dd MMM HH:mm')
  }

  const getLastUpdate = () => {
    const update_time = Math.max(request.created_at, request.updated_at, request.moderated_at)
    return formatDate(update_time, 'dd MMM HH:mm')
  }

  // удалить назначенного модератора после подтверждения
  const removeAssignee = (e) => {
    const { id, name } = e.currentTarget.dataset
    const title = intl.formatMessage({ id: 'user.request.remove_assignment' }, { name })

    confirmService.requestDeleteConfirmation(title).then((r) => {
      if (r !== 'delete') return

      http.delete(`/nsi/v1/moderation_requests/${request.id}/assign/${id}`).catch(console.error)
    })
  }

  const pairs: Array<[string, any]> = [
    ['nsi.user.filters.status', getRequestStatus()],
    ['user.request.created_by', renderCreatedBy()],
    ['user.request.create_date', getCreateDate()],
    ['user.request.update_date', getLastUpdate()],
  ]

  if (request.moderated_by) {
    const moderator = users.get(request.moderated_by)?.name || intl.formatMessage({ id: 'common.admin' })
    pairs.push(['user.request.moderated_by', moderator])
  }

  // список назначенных модераторов
  if (request.assigned_to?.length) {
    const assignees = request.assigned_to.map((id) => {
      const name = users.get(id)?.name
      if (!name) return null

      return (
        <div key={id} data-id={id} data-name={name} onClick={removeAssignee} className={css.assignee}>
          {name}
        </div>
      )
    })

    pairs.push(['user.request.assigned_to', assignees])
  }

  return <KeyValueTable pairs={pairs} />
}

export default RequestMeta
