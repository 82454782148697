import useInfiniteLoader from 'hooks/useInfiniteLoader'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import Datepicker from '../../../../shared/Inputs/Datepicker/Datepicker'
import Dropdown from '../../../../shared/Inputs/Dropdown'
import InputRow from '../../../../shared/Inputs/InputRow'
import InfiniteScroll from '../../../../shared/Pagination/InfiniteScroll'
import { Equipment } from 'au-nsi/equipment'
import { IChangelogItem } from '../../../System/ActionsJournal/actions.types'
import ActionsTable from '../../../System/ActionsJournal/ActionsTable'
import NoResults from '../../../System/common/NoResults'

const DeviceChangelog = ({ device }: Props) => {
  const intl = useIntl()
  const translations = useSelector((state: ReduxState) => state.language.translations)

  const [filters, setFilters] = useState({ updated_columns: '', ...defaultTimerange() })

  const { results, isLoading, isMore, loadMore } = useInfiniteLoader<IChangelogItem>({
    url: `/nsi/journal/changelog/equipment/${device.id}`,
    query: filters,
  })

  const handleChange = (value: any, key: string) => setFilters((prev) => ({ ...prev, [key]: value }))

  const columnOptions = columns.map((value) => ({ value, title: translations['equipment.' + value] }))
  columnOptions.unshift({ value: '', title: intl.formatMessage({ id: 'common.all' }) })

  const noResults = !isLoading && results.length === 0

  return (
    <div className="nsi-main__container">
      <div className="system__grid three-cols">
        <InputRow label={intl.formatMessage({ id: 'map.device.changelog.updated_columns' })}>
          <Dropdown
            name="updated_columns"
            value={filters.updated_columns}
            options={columnOptions}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'map.device.changelog.t0' })}>
          <Datepicker name="t0" time={filters.t0} onTimeChange={handleChange} />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'map.device.changelog.t1' })}>
          <Datepicker name="t1" time={filters.t1} onTimeChange={handleChange} />
        </InputRow>
      </div>

      <InfiniteScroll parentSelector=".nsi-main__wrapper" isMore={isMore} loadMore={loadMore}>
        <ActionsTable items={results} showDetails={true} compactHeader={true} onSelect={noop} />
      </InfiniteScroll>

      {noResults && <NoResults />}
    </div>
  )
}

const noop = () => null

const columns = [
  'configuration',
  'parameters_mapping',
  'passport_values',
  'passport_catalog_id',
  'name',
  'shortname',
  'color',
  'state',
  'error',
  'data_rate',
  'attributes',
]

const defaultTimerange = () => {
  const now = new Date()
  now.setHours(now.getHours() + 1)
  now.setMinutes(0)
  now.setSeconds(0)
  now.setMilliseconds(0)
  const t1 = now.valueOf()

  now.setMonth(now.getMonth() - 1)
  const t0 = now.valueOf()

  return { t0, t1 }
}

interface Props {
  device: Equipment
}

export default DeviceChangelog
