import classnames from 'classnames'
import React from 'react'
import { IntlShape } from 'react-intl'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import { ChartScale } from '../utils/scale.utils'
import { scale2str, scales } from '../utils/scale.utils'

/**
 * Компонент для принудительного выбора множителя для графика (Кило, Мега, ...)
 */
const ScaleSelector = (props: Props) => {
  const getPrefixSymbol = (prefixId: string) => {
    const prefix = props.prefixes[prefixId]
    return prefix ? prefix.symbol : ''
  }

  const handleClick = (e) => {
    const scale = +e.target.dataset.scale || null
    props.onChange(scale as ChartScale, props.index)
  }

  // display scale + measurement unit in human format
  // e.g. scale = kilo, unit = watt => KW
  const renderLabel = (scale: ChartScale) => {
    const prefixId = scale2str(scale)
    const unit = props.unit.symbol
    const prefix = getPrefixSymbol(prefixId)

    return <span className="chart__icon-dropdown-btn">{prefix + unit}</span>
  }

  const renderMenu = () => {
    const unit = props.unit.symbol

    const menuItems = scales.map((v) => {
      const prefixId = scale2str(v)
      const prefix = getPrefixSymbol(prefixId)
      const className = classnames('chart__icon-dropdown-menu__item', { 'is-selected': v === props.userScale })

      return (
        <span className={className} key={v} data-scale={v} onClick={handleClick}>
          {prefix + unit}
        </span>
      )
    })

    // additional item for auto scale
    const className = classnames('chart__icon-dropdown-menu__item', { 'is-selected': !props.userScale })

    menuItems.push(
      <span className={className} key={0} onClick={handleClick}>
        {props.intl.formatMessage({ id: 'units.auto' })}
      </span>
    )

    return <span className="chart__icon-dropdown-menu">{menuItems}</span>
  }

  const showMenu = props.autoScale && props.unit && props.unit.prefixable
  const transform = props.left ? 'translate(-100%)' : 'translate(100%)'

  return (
    <span
      className="chart__scale-dropdown chart__icon-dropdown line-chart__icon"
      style={{ left: props.left, right: props.right, transform }}
    >
      {showMenu && renderLabel(props.userScale || props.autoScale)}
      {showMenu && renderMenu()}
    </span>
  )
}

interface Props {
  autoScale: ChartScale
  index: number
  intl: IntlShape
  left?: number
  onChange: (scale: ChartScale, index: number) => void
  prefixes: { [id: string]: SiPrefix }
  right?: number
  unit: SiUnit
  userScale: ChartScale
}

export default React.memo(ScaleSelector)
