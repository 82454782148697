import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InputRow from '../../../shared/Inputs/InputRow'
import Modal from '../../../shared/Modal/Modal'
import { LoaderRow } from '../../../shared/Utils/Loader'
import { formatDate } from '../../../utils/lang'
import { selectLanguage } from '../../App/app.selectors'
import { selectTimeUnits } from '../../Parameters/params.selectors'
import { formatDuration } from '../../Parameters/params.utils'
import { rulesMapSelector } from '../../SignalEvents/se.selectors'
import SeFormulaPreview from '../../SignalEvents/SeFormulaPreview'
import { getIncidentDescription, getIncidentRule } from '../incidents.utils'
import useIncidentSelector from '../useIncidentSelector'
import IncidentsChartPreview from './IncidentsChartPreview'

/**
 * Модальное окно с детальной информацией об инциденте
 */
const IncidentsModal = (props: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const seRules = useSelector(rulesMapSelector)
  const lang = useSelector(selectLanguage)
  const timeUnits = useSelector(selectTimeUnits)

  const incident = useIncidentSelector(props.id)
  const [now, setNow] = React.useState(Date.now())

  React.useEffect(() => {
    if (incident && !incident.ts_end) {
      const timer = setInterval(() => setNow(Date.now()), 1000)
      return () => clearInterval(timer)
    }
  }, [incident])

  if (!incident) {
    return (
      <Modal size="lg" onClose={props.onClose} closeOnEscape={true}>
        <LoaderRow />
      </Modal>
    )
  }

  const description = getIncidentDescription(incident, seRules, lang)
  const seRule = getIncidentRule(incident, seRules)

  const start = incident.ts_start / 1000
  const end = incident.ts_end ? incident.ts_end / 1000 : now
  const duration = formatDuration((end - start) / 1000, timeUnits)

  const handleExport = () => {
    navigate('/archive/export?incident_id=' + props.id)
  }

  const handleDashboard = () => {
    navigate('/dashboards/incidents/' + props.id)
  }

  return (
    <Modal size="lg" onClose={props.onClose} closeOnEscape={true} shouldAnimate={false}>
      <h2>{description.title}</h2>

      <InputRow label={intl.formatMessage({ id: 'alerts.description' })}>{description.text}</InputRow>

      <InputRow label={intl.formatMessage({ id: 'alerts.duration' })}>
        <div>
          {formatDate(start, 'dd MMM yyyy HH:mm:ss')}
          <span style={{ padding: '0 1em' }}>&mdash;</span>
          {formatDate(end, 'dd MMM yyyy HH:mm:ss')}

          <span className="text--gray" style={{ paddingLeft: '1em' }}>
            ({duration})
          </span>
        </div>
      </InputRow>

      {seRule && (
        <InputRow label={intl.formatMessage({ id: 'alerts.formula' })}>
          {seRule.type === 'formula' ? <SeFormulaPreview formula={seRule.formula} /> : seRule.src}
        </InputRow>
      )}

      <IncidentsChartPreview incident={incident} seRules={seRules} />

      <div className="app-modal__footer">
        <button type="button" className="nsi-button default" onClick={handleDashboard}>
          {intl.formatMessage({ id: 'alerts.show_dashboard' })}
        </button>
        <button type="button" className="nsi-button default" onClick={handleExport}>
          {intl.formatMessage({ id: 'alerts.export' })}
        </button>
        <button type="button" className="nsi-button inverted" onClick={props.onClose}>
          {intl.formatMessage({ id: 'common.close' })}
        </button>
      </div>
    </Modal>
  )
}

interface Props {
  id: number
  onClose: () => void
}

export default IncidentsModal
