import React from 'react'
import { IntlShape } from 'react-intl'
import { selectTimeUnit } from '../../../pages/Parameters/params.utils'
import Dropdown, { DropdownOption } from '../../Inputs/Dropdown'
import InputRow from '../../Inputs/InputRow'
import NumberInput from '../../Inputs/NumberInput'
import { SiUnit } from 'au-nsi/parameters'

/**
 * Настройки отображения прогнозируемых данных
 */
const PredictionOptions = (props: Props) => {
  const predictionTime = props.predictionTime ?? 1000
  const unit = selectTimeUnit(predictionTime / 1e3, props.timeUnits)

  const toggle = (
    <InputRow label={props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.prediction' })}>
      <Dropdown name="prediction" value={props.prediction || false} options={props.options} onChange={props.onChange} />
    </InputRow>
  )

  if (!props.prediction) {
    return toggle
  }

  return (
    <div className="chart__settings-row-block">
      {toggle}

      <InputRow label={props.intl.formatMessage({ id: 'dashboards.linear_chart.settings.predictionTime' })}>
        <NumberInput
          baseUnit={props.units.ms}
          displayUnit={unit}
          fullWidth={true}
          name="predictionTime"
          onChange={props.onChange}
          prefixes={{}}
          type="parameter"
          units={props.units}
          value={predictionTime}
        />
      </InputRow>
    </div>
  )
}

interface Props {
  intl: IntlShape
  onChange: (v: any, key: string) => void
  options: DropdownOption<boolean>[]
  prediction: boolean
  predictionTime: number
  units: Record<string, SiUnit>
  timeUnits: SiUnit[]
}

export default React.memo(PredictionOptions)
