import { IVectorChartSettings } from 'au-nsi/dashboards'
import React from 'react'
import useDataRate from '../../hooks/useDataRate'
import useDataService from '../../hooks/useDataService'
import VectorGraph from './VectorGraph'

const VectorGraphWrapper = (props: Props) => {
  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  React.useEffect(() => {
    const selectors = []

    for (const vector of props.settings.vectors) {
      const selector = selectors.find((s) => s.device_id === vector.device_id)

      if (selector) {
        selector.parameters.push(vector.angle_parameter_id, vector.magnitude_parameter_id)
      } else {
        selectors.push({
          device_id: vector.device_id,
          parameters: [vector.angle_parameter_id, vector.magnitude_parameter_id],
        })
      }
    }

    service.setDataSelectors(selectors)
  }, [props.settings.vectors, service])

  return <VectorGraph {...props} service={service} />
}

interface Props {
  id: number
  lastResize: number
  settings: IVectorChartSettings
}

export default VectorGraphWrapper
