import { useEffect, useState } from 'react'
import { fetchDocuments } from '../../documents.api'
import { actions } from '../../docs.reducers'
import { useDispatch, useSelector } from 'react-redux'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import Loader from '../../../../../shared/Utils/Loader'
import DocumentsPanel from './DocumentsPanel'
import { ReduxState } from '../../../../../redux/store.types'
import { IDocument } from 'au-nsi/documents'

interface IProps {
  resource: IDocument['resource']
  resource_item_id: string | number
  editing?: boolean
  name?: string
  onChange: (docs: Partial<IDocument>[], name?: string) => void
  requestAccess?: boolean
}

/**
 * Загружает доступные документы по ресурсу и его итему, после чего рендерит DocumentsPanel
 */
const DocumentsPanelLoader = ({ resource_item_id, resource, editing, name, onChange, requestAccess }: IProps) => {
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()
  const documents = useSelector((state: ReduxState) => state.documents.items)
  const [draftDocuments, setDraftDocuments] = useState<IDocument[]>([])

  useEffect(() => {
    if (!editing) setDraftDocuments(documents)
  }, [documents, editing])

  useEffect(() => {
    if (resource_item_id !== -1 && resource_item_id !== undefined && !isNaN(resource_item_id as number)) {
      wait(fetchDocuments({ resource, resource_item_id }), (r) => {
        dispatch(actions.addDocuments(r))
      })
    }

    return () => {
      dispatch(actions.cleanDocuments())
    }
  }, [resource_item_id])

  if (loading) {
    return (
      <div className="center_vertical">
        <Loader />
      </div>
    )
  }

  return (
    <DocumentsPanel
      requestAccess={requestAccess}
      resource={resource}
      resource_item_id={resource_item_id}
      onChange={(docs) => {
        setDraftDocuments(docs as IDocument[])
        onChange(docs, name)
      }}
      editing={editing}
      name={name}
      documents={draftDocuments}
    />
  )
}

export default DocumentsPanelLoader
