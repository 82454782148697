import {
  ASTNode,
  BinaryFuncNode,
  BinaryOperatorNode,
  ConstantNode,
  EquipmentNode,
  NullNode,
  NumberNode,
  ParameterNode,
  TernaryNode,
  UnaryFuncNode,
  UnaryOperatorNode,
} from './ast.interfaces'
import { IFormulaArgument } from '../formulas.interfaces'

/**
 * Find node in the tree with *targetIndex* using pre-order traversal
 */
export const findNode = (node: ASTNode, targetIndex: number, memory = { index: 0 }) => {
  if (targetIndex === memory.index) {
    return node
  }

  if (!node.args) {
    return null
  }

  for (const arg of node.args) {
    memory.index += 1
    const res = findNode(arg, targetIndex, memory)

    if (res != null) return res
  }
}

/**
 * Find index of the first *null* node (used in validation before saving to server)
 */
export const findNullNode = (node: ASTNode, checkEquipment: boolean, memory = { index: 0 }): number => {
  if (node.type === 'null') {
    return memory.index
  }

  if (checkEquipment && node.type === 'equipment' && node.value == null) {
    return memory.index
  }

  if (!node.args) {
    return null
  }

  for (const arg of node.args) {
    memory.index += 1
    const res = findNullNode(arg, checkEquipment, memory)

    if (res != null) return res
  }
}

/**
 * Извлечь список параметров и устройств использующихся в формуле
 */
export const extractArguments = (node: ASTNode, isGlobal: boolean, args: IFormulaArgument[]) => {
  if (node.type === 'parameter') {
    const name = 'p' + args.length
    return args.push({ type: 'value', name, device_id: node.args[0].value || '', parameter_id: node.value })
  }

  if (node.args) {
    for (const arg of node.args) {
      extractArguments(arg, isGlobal, args)
    }
  }
}

// change node type and value
export const replaceNode = (original: ASTNode, replacement: ASTNode) => {
  // if node type is not changed, keep its children and change only value
  if (original.type === replacement.type) {
    return (original.value = replacement.value)
  }

  const copy = { ...original }

  // replace it with completely new node (all children will be deleted)
  Object.assign(original, replacement)

  // preserve arguments if both nodes are functions
  if (isFunction(copy) && isFunction(replacement)) {
    const len = Math.min(copy.args.length, original.args.length)
    for (let i = 0; i < len; i++) {
      original.args[i] = copy.args[i]
    }
  }

  // make value node child of function node
  if (isValue(copy) && isFunction(replacement)) {
    original.args[0] = copy
  }
}

const isFunction = (node: ASTNode): boolean => {
  return node.type === 'unaryFunction' || node.type === 'binaryFunction'
}

const isValue = (node: ASTNode): boolean => {
  return node.type === 'constant' || node.type === 'number' || node.type === 'parameter'
}

// factory for null node
const nil = (valueType?: 'boolean' | 'number'): NullNode => {
  const node: NullNode = { type: 'null', value: undefined, args: undefined }
  return valueType ? { ...node, valueType } : node
}
const equipment = (value: string = null): EquipmentNode => ({ type: 'equipment', value, args: undefined })

// factories for each node type
export const factories = {
  null: nil,
  equipment,
  number: (value: number): NumberNode => ({ type: 'number', value, args: undefined }),
  constant: (value): ConstantNode => ({ type: 'constant', value, args: undefined }),
  parameter: (value: string): ParameterNode => ({ type: 'parameter', value, args: [equipment()] }),
  unaryOperator: (value): UnaryOperatorNode => {
    const valueType = value === '!' ? 'boolean' : 'number'
    return { type: 'unaryOperator', value, args: [nil(valueType)] }
  },
  binaryOperator: (value): BinaryOperatorNode => {
    const valueType = value === '&&' || value === '||' ? 'boolean' : 'number'
    return { type: 'binaryOperator', value, args: [nil(valueType), nil(valueType)] }
  },
  unaryFunction: (value): UnaryFuncNode => ({ type: 'unaryFunction', value, args: [nil('number')] }),
  binaryFunction: (value): BinaryFuncNode => ({ type: 'binaryFunction', value, args: [nil('number'), nil('number')] }),
  ternaryOperator: (): TernaryNode => ({
    type: 'ternaryOperator',
    value: undefined,
    args: [nil('boolean'), nil('number'), nil('number')],
  }),
}
