import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PlusButton from '../../../shared/Forms/PlusButton'
import { selectEnabledModules } from '../../App/app.selectors'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import StorageExceptionsRow from './StorageExceptionsRow'
import { IStorageException } from 'au-nsi/settings'

/**
 * Таблица с настройками индивидуальных сроков хранения данных от разных устройств
 */
const StorageExceptions = (props: Props) => {
  const exceptions = props.exceptions || []

  const intl = useIntl()
  const deviceOptions = useSelector(selectDataSourceOptions)
  const parameters = useSelector((state: ReduxState) => state.parameters.items)
  const modules = useSelector(selectEnabledModules)

  if (!modules.has('settings:data_retention_exceptions') && exceptions.length === 0) return null
  if (!props.editing && exceptions.length === 0) return null

  const handleAdd = () => {
    props.onChange([
      ...exceptions,
      { device_id: '', apply_to_all_parameters: true, parameters: [], retention: '1y', retention_days: 365 },
    ])
  }

  const handleChange = (row: IStorageException, index: number) => {
    props.onChange([...exceptions.slice(0, index), row, ...exceptions.slice(index + 1)])
  }

  const handleRemove = (index: number) => {
    props.onChange([...exceptions.slice(0, index), ...exceptions.slice(index + 1)])
  }

  const rows = exceptions.map((row, i) => {
    return (
      <StorageExceptionsRow
        key={i}
        index={i}
        editing={props.editing}
        row={row}
        deviceOptions={deviceOptions}
        parameters={parameters}
        onChange={handleChange}
        onRemove={handleRemove}
      />
    )
  })

  const namespace = 'settings_storage.data_retention_exceptions'

  return (
    <React.Fragment>
      <br />
      <h2>{props.translations[namespace]}</h2>
      <div className="text--warning" style={{ marginTop: '-1em', marginBottom: '2em' }}>
        {intl.formatMessage({ id: 'system.storage.exceptions_warning' })}
      </div>

      <table className="nsi-settings-table">
        <thead>
          <tr>
            <th className="text--nowrap">{props.translations[namespace + '.device_id']}</th>
            <th className="text--nowrap">{props.translations[namespace + '.retention']}</th>
            <th className="text--nowrap">{intl.formatMessage({ id: 'system.storage.all_parameters' })}</th>
            <th>{props.translations[namespace + '.parameters']}</th>
            {props.editing && <th />}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      {props.editing && <PlusButton textId="common.add" onClick={handleAdd} style={{ marginLeft: 0 }} />}
    </React.Fragment>
  )
}

interface Props {
  editing: boolean
  exceptions: IStorageException[]
  onChange: (exceptions: IStorageException[]) => void
  translations: Record<string, string>
}

export default StorageExceptions
