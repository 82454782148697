import appRoutes from './app.routes'
import { deepCopy } from '../../../utils/misc'
import { IRoute } from './nav.utils'

const filterRouteChildrenById = (routes: IRoute[], id: string) => {
  for (let i = 0; i < routes.length; ++i) {
    if (routes[i].children) filterRouteChildrenById(routes[i].children, id)
    if (routes[i].id === id) return delete routes[i].children
  }
}

const getMobileRoutes = () => {
  const routes = deepCopy(appRoutes)
  // Навигация вкладки "Система" дублирует состояние панели экрана
  filterRouteChildrenById(routes, 'system')

  return routes
}

const mobileAppRoutes = getMobileRoutes()
export default mobileAppRoutes
