import React from 'react'
import usePopup from '../../hooks/usePopup'
import { ReactComponent as CreateBtnSvg } from 'images/icons/nsi/add-button.svg'

/**
 * Кнопка + при нажатии на которую появляется дропдаун с опциями, при клике на опцию
 * дропдаун будет убран и вызван onSelect с выбранным значением
 */
const CreateButton = (props: Props) => {
  const ref = React.useRef()
  const { open, setOpen } = usePopup(ref)

  const handleOpen = () => !props.disabled && setOpen(true)

  const handleSelect = (e) => {
    setOpen(false)
    props.onSelect(e.target.dataset.id)
  }

  const options = props.options.map((o) => (
    <div key={o.value} data-id={o.value} className="nsi-dropdown__item" onClick={handleSelect}>
      {o.title}
    </div>
  ))

  const popup = <div className="nsi-dropdown">{options}</div>

  return (
    <React.Fragment>
      <div className="font--small" style={{ position: 'absolute', top: -4, right: 20, lineHeight: '18px' }} ref={ref}>
        {open && popup}
      </div>
      <CreateBtnSvg className="nsi-tree-add-btn" onClick={handleOpen} />
    </React.Fragment>
  )
}

interface Props {
  options: Array<{ value: string; title: string }>
  onSelect: (option: string) => void
  disabled?: boolean
}

export default CreateButton
