import { Select } from '@alterouniversal/au-react-components'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import Modal from '../../../shared/Modal/Modal'
import usersActions from '../Users/users.actions'

/**
 * Модальное окно задания фильтров пользователей: по типу, организации и статусу
 */
const FiltersModal = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const filters = useSelector((state: ReduxState) => state.users.filters)
  const orgs = useSelector((state: ReduxState) => state.organizations.organizations)

  const [draft, setDraft] = useState(filters)

  const handleChange = (value, key: string) => {
    const updates = { ...draft, [key]: value }
    if (key === 'organization_type_id') updates.organization_id = null
    setDraft(updates)
  }

  const handleReset = () => {
    dispatch(usersActions.setFilters({ organization_type_id: null, organization_id: null, status: null }))
    props.onClose()
  }

  const handleSave = () => {
    dispatch(usersActions.setFilters(draft))
    props.onClose()
  }

  const statuses = [
    { value: null, title: intl.formatMessage({ id: 'nsi.user.filters.all' }) },
    { value: 'active', title: intl.formatMessage({ id: 'nsi.user.filters.status.active' }) },
    { value: 'suspended', title: intl.formatMessage({ id: 'nsi.user.filters.status.suspended' }) },
  ]

  const selectedOrgType = orgs.find((e) => e.id === draft.organization_type_id)

  const typeOptions = orgs.map((o) => ({ value: o.id, label: o.name }))
  typeOptions.unshift({ value: null, label: intl.formatMessage({ id: 'nsi.user.filters.all' }) })

  const orgOptions = selectedOrgType ? selectedOrgType.instances.map((i) => ({ value: i.id, label: i.name })) : []
  orgOptions.unshift({ value: null, label: intl.formatMessage({ id: 'nsi.user.filters.all' }) })

  return (
    <Modal onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: 'nsi.user.filters.title' })}</h2>

      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'nsi.user.filters.org_type' })}>
          <Select
            name="organization_type_id"
            options={typeOptions}
            value={draft.organization_type_id}
            onChange={handleChange}
          />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'nsi.user.filters.org' })}>
          <Select name="organization_id" options={orgOptions} value={draft.organization_id} onChange={handleChange} />
        </InputRow>

        <InputRow label={intl.formatMessage({ id: 'nsi.user.filters.status' })}>
          <Dropdown name="status" value={draft.status} options={statuses} onChange={handleChange} />
        </InputRow>
      </div>

      <div className="app-modal__footer">
        <button type="button" className="nsi-button inverted" onClick={props.onClose}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </button>
        <button className="nsi-button default" onClick={handleReset}>
          {intl.formatMessage({ id: 'nsi.user.filters.reset' })}
        </button>
        <button className="nsi-button default" onClick={handleSave}>
          {intl.formatMessage({ id: 'nsi.user.filters.apply' })}
        </button>
      </div>
    </Modal>
  )
}

interface Props {
  onClose: () => void
}

export default FiltersModal
