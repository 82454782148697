import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import http, { handleHttpError } from '../../utils/http'
import { actions } from './dashboard.reducers'
import { getUpdates } from './dashboard.utils'
import {
  addDashboardToFolder,
  createFolder,
  disbandFolder,
  groupIntoFolder,
  moveDashboards,
  removeFolder,
  removeFromFolder,
  removeList,
  renameFolder,
  updateDashboard,
} from './components/useDashboardsMove.utils'

const loadDashboards =
  (forceReload = false) =>
  (dispatch, getState) => {
    const state = getState().dashboards
    if (state.isLoaded && !forceReload) return

    http
      .get(`/nsi/v1/ui/dashboards`)
      .then((r) => dispatch(actions.setDashboards(r.data)))
      .catch(handleHttpError)
  }

const createDashboard = (d: { name: string }) => (dispatch) => {
  http
    .post('/nsi/v1/ui/dashboards', d)
    .then((r) => dispatch(actions.dashboardCreated(r.data)))
    .catch(handleHttpError)
}

const loadDashboardComponents = (id: string) => (dispatch, getState) => {
  const state = getState().dashboards
  if (state.loadedDashboards[id]) return

  http
    .get(`/nsi/v1/ui/dashboards/${id}/components`)
    .then((r) => dispatch(actions.setComponents({ id, components: r.data })))
    .catch(handleHttpError)
}

const createComponent = (dashboardId: string, component) => async (dispatch) => {
  return http
    .post(`/nsi/v1/ui/dashboards/${dashboardId}/components`, component)
    .then((r) => dispatch(actions.componentCreated(r.data)))
    .catch(handleHttpError)
}

const updateComponentSettings = (component: IDashboardComponent) => (dispatch) => {
  const { id, dashboard_id } = component
  const url = `/nsi/v1/ui/dashboards/${dashboard_id}/components/${id}/settings`

  http
    .put(url, component.settings)
    .then((r) => dispatch(actions.componentUpdated(r.data)))
    .catch(handleHttpError)
}

const updateComponentPosition = (component: IDashboardComponent) => (dispatch) => {
  const { settings, ...position } = component
  const url = `/nsi/v1/ui/dashboards/${component.dashboard_id}/components/${component.id}/position`

  dispatch(actions.componentUpdated(position))
  http.put(url, position).catch(handleHttpError)
}

const deleteComponent = (id: number, dashboard_id: string) => (dispatch) => {
  http
    .delete(`/nsi/v1/ui/dashboards/${dashboard_id}/components/${id}`)
    .then((r) => dispatch(actions.componentDeleted(r.data)))
    .catch(handleHttpError)
}

const cancelDraft = () => (dispatch, getState) => {
  const exists = getState().dashboards.dashboards

  dispatch(actions.setDashboardsDraft({ draft: exists, editing: false }))
}

const saveDraft = () => async (dispatch, getState) => {
  const past = getState().dashboards.dashboards
  const draft = getState().dashboards.dashboardsDraft
  const updates = getUpdates(past, draft)
  dispatch(actions.setDashboards(draft))
  await http.patch('/nsi/v1/ui/dashboards', updates)
}

const createDraftFolder = (sourceId: string, targetId: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: createFolder(draft, sourceId, targetId), editing: true }))
}

const disbandDraftFolder = (folderId: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: disbandFolder(draft, folderId), editing: true }))
}

const groupIntoDraftFolder = (id_list: Set<string>) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: groupIntoFolder(draft, id_list), editing: true }))
}

const removeDraftList = (id_list: Set<string>) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: removeList(draft, id_list), editing: true }))
}

const removeDraftFolder = (folder_id: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: removeFolder(draft, folder_id), editing: true }))
}

const renameDraftFolder = (folder_id: string, name: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: renameFolder(draft, folder_id, name), editing: true }))
}

const updateDraftDashboard = (updated: IDashboard) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: updateDashboard(draft, updated), editing: true }))
}

const addDashboardToDraftFolder = (folderId: string, dashboardId: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: addDashboardToFolder(draft, folderId, dashboardId), editing: true }))
}

const removeFromDraftFolder = (dashboardId: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: removeFromFolder(draft, dashboardId), editing: true }))
}

const swapDashboards = (sourceId: string, targetId: string) => (dispatch, getState) => {
  const draft: IDashboard[] = getState().dashboards.dashboardsDraft

  dispatch(actions.setDashboardsDraft({ draft: moveDashboards(draft, sourceId, targetId), editing: true }))
}

const dashboardActions = {
  ...actions,
  createComponent,
  createDashboard,
  deleteComponent,
  loadDashboardComponents,
  loadDashboards,
  updateComponentPosition,
  updateComponentSettings,
  saveDraft,
  cancelDraft,
  createDraftFolder,
  disbandDraftFolder,
  groupIntoDraftFolder,
  removeDraftList,
  removeDraftFolder,
  renameDraftFolder,
  updateDraftDashboard,
  addDashboardToDraftFolder,
  removeFromDraftFolder,
  swapDashboards,
}

export default dashboardActions
