import { OrganizationTypeWithInstances } from '../../../../../Catalogs/Organizations/organizations.types'
import { getSchemaMap } from '../CatalogRequestForm/catalogRequest.diff'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { Organization } from 'au-nsi/organizations'

function findOrganizationsDiff(
  o1: Organization,
  o2: Organization,
  type: OrganizationTypeWithInstances
): [Organization, Organization, OrganizationTypeWithInstances] {
  const typeSchemaMap = getSchemaMap(type.organization_schema)
  const updatedTypeSchema: IPropertyDescription[] = []

  const updatedProperties1 = {}
  const updatedProperties2 = {}
  const keys = new Set([...Object.keys(o1.organization_properties), ...Object.keys(o2.organization_properties)])

  for (const key of keys) {
    if (o1.organization_properties[key] !== o2.organization_properties[key]) {
      updatedProperties1[key] = o1.organization_properties[key]
      updatedProperties2[key] = o2.organization_properties[key]

      const schemaRow = typeSchemaMap.get(key)
      if (schemaRow) updatedTypeSchema.push(schemaRow)
    }
  }

  return [
    { ...o1, organization_properties: updatedProperties1 },
    { ...o2, organization_properties: updatedProperties2 },
    { ...type, organization_schema: updatedTypeSchema },
  ]
}

export default findOrganizationsDiff
