import { AxiosError } from 'axios'
import { batch } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import { FormMode } from '../../shared/interfaces'
import http, { handleHttpError, handleHttpResponse } from '../../utils/http'
import { createDevice } from './device.factory'
import { MovedRegion, NsiPanel, NsiState } from './nsi.interfaces'
import * as C from './nsi.reducers'
import { generateUniqueName, isDataReceiver } from './nsi.utils'
import { reorderEquipment, reorderRegions } from './Tree/reorder.utils'
import { Equipment, EquipmentState, Region } from 'au-nsi/equipment'

const REGIONS_URL = '/nsi/v1/regions/'
const EQUIPMENT_URL = '/nsi/v1/equipment/'

/* region actions */

export const reloadRegions = () => (dispatch) => {
  http
    .get(REGIONS_URL)
    .then(({ data }) => dispatch({ type: C.SET_REGIONS, regions: data }))
    .catch(handleHttpError)
}

export const addRegion = (region: Partial<Region>) => (dispatch) => {
  http
    .post(REGIONS_URL, region)
    .then(({ data }) => {
      batch(() => {
        dispatch(regionCreated(data))
        dispatch(setFormMode('view'))
        dispatch(setSelectedRegionId(data.id))
      })
    })
    .catch(handleHttpError)
}

export const updateRegion = (id: number, updates: Partial<Region>) => (dispatch) => {
  http
    .patch(REGIONS_URL + id, updates)
    .then(({ data }) => {
      batch(() => {
        dispatch(regionUpdated(id, data))
        dispatch(setFormMode('view'))
      })
    })
    .catch(handleHttpError)
}

export const moveRegion = (region: MovedRegion) => (dispatch) => {
  http
    .post(REGIONS_URL + '/move', region)
    .then(({ data }) => dispatch(regionBatchUpdated(data)))
    .catch(handleHttpError)
}

export const deleteRegion = (id: number) => (dispatch) => {
  http
    .delete(REGIONS_URL + id)
    .then(() => {
      batch(() => {
        dispatch(regionDeleted({ id }))
        dispatch(setSelectedRegionId(null))
        dispatch(setFormMode('view'))
      })
    })
    .catch(handleHttpError)
}

export const regionCreated = (region: Region) => ({ type: C.ADD_REGION, region })
export const regionUpdated = (id: number, updates: Partial<Region>) => ({ type: C.UPDATE_REGION, id, updates })
export const regionBatchUpdated = (updates) => ({ type: C.UPDATE_REGION_BATCH, updates })
export const regionDeleted = ({ id }) => ({ type: C.DELETE_REGION, id })

/* equipment actions */

export const reloadEquipment = () => (dispatch) => {
  http
    .get(EQUIPMENT_URL)
    .then(({ data }) => dispatch({ type: C.SET_EQUIPMENT, equipment: data }))
    .catch(handleHttpError)
}

// загрузка устройства после изменения его настроек видимости: если устройство стало видно
// пользователю, то добавляем его к другим, если наоборот скрылось (статус 404), то удаляем
export const reloadDevice = (id: string) => (dispatch) => {
  http
    .get(EQUIPMENT_URL + id)
    .then((r) => dispatch(equipmentCreated(r.data)))
    .catch((err: AxiosError) => {
      const status = err.response?.status
      if (status === 404 || status === 403) dispatch(equipmentDeleted(id))
    })
}

export const createEquipment = (options: CreateOptions & Partial<Equipment>) => (dispatch, getState) => {
  const state = getState() as ReduxState
  const { name, shortname } = generateUniqueName(options.name, options.name.slice(0, 1), state.nsi.equipmentAll)

  const device = createDevice({ ...options, name, shortname })
  postEquipment(device, dispatch)
}

export interface CreateOptions {
  name: string
  region_id: number
  protocol: Equipment['protocol']
  type: Equipment['type']
}

const postEquipment = (device, dispatch) => {
  http
    .post(EQUIPMENT_URL, device)
    .then(handleHttpResponse)
    .then((data) => {
      if (!data) return

      dispatch(equipmentCreated(data))
      dispatch({ type: C.SELECT_DEVICE, id: data.id })
    })
    .catch(handleHttpError)
}

export const patchEquipment = (id: string, updates: Partial<Equipment>) => async (dispatch) => {
  const updated = await http
    .patch(EQUIPMENT_URL + id, updates)
    .then(handleHttpResponse)
    .catch(handleHttpError)

  if (updated) dispatch(equipmentUpdated(id, updates))
  return updated
}

/**
 * Изменение адреса устройства. Вынесено в отдельный метод, т.к. может быть необходимость
 * также изменить паспорт устройства, если в нем есть поле с адресом.
 */
export const updateEquipmentAddress = (id: string, updates: Partial<Equipment>) => (dispatch, getState) => {
  const state: ReduxState = getState()
  const defaultAction = () => dispatch(patchEquipment(id, updates))

  if (!updates.address || !updates.address.name) return defaultAction()

  const device = state.nsi.equipment.find((e) => e.id === id)
  if (!device.passport_catalog_id) return defaultAction()

  const passport = state.catalogs.catalogs.find((c) => c.id === device.passport_catalog_id)
  if (!passport) return defaultAction()

  const addressProperty = passport.schema.find((p) => p.autofill_mode === 'address')
  if (!addressProperty) return defaultAction()

  const passport_values = { ...device.passport_values, [addressProperty.id]: updates.address.name }
  return dispatch(patchEquipment(id, { ...updates, passport_values }))
}

export const updateEquipmentConfig = (id: string, configuration: any) => async (dispatch) => {
  try {
    const resp = await http.put(EQUIPMENT_URL + id + '/configuration', { configuration }).then(handleHttpResponse)
    if (!resp) return

    dispatch(equipmentUpdated(id, resp))
  } catch (error) {
    handleHttpError(error)
  }
}

export const updateParametersMapping =
  (id: string, parameters_mapping: Equipment['parameters_mapping']) => async (dispatch) => {
    const updates = { parameters_mapping }

    try {
      await http.put(EQUIPMENT_URL + id + '/parameters', updates)
      dispatch(equipmentUpdated(id, updates as any))
    } catch (error) {
      handleHttpError(error)
    }
  }

export const moveEquipment = (id: string, path: string) => (dispatch) => {
  http
    .put(EQUIPMENT_URL + id + '/path', { path })
    .then((r) => {
      batch(() => {
        for (const item of r.data) {
          dispatch(equipmentUpdated(item.id, item))
        }
      })
    })
    .catch(handleHttpError)
}

export const deleteEquipment = (id: string) => (dispatch) => {
  http
    .delete(EQUIPMENT_URL + id)
    .then(() => dispatch(equipmentDeleted(id)))
    .catch(handleHttpError)
}

export const equipmentCreated = (device: Equipment) => ({ type: C.ADD_EQUIPMENT, device })

export const equipmentUpdated = (id: string, updates: Partial<Equipment>) => {
  return { type: C.UPDATE_EQUIPMENT, id, updates }
}

export const equipmentBatchUpdated = (updates: Array<Partial<Equipment>>) => {
  return { type: C.UPDATE_EQUIPMENT_BATCH, updates }
}

export const equipmentDeleted = (id: string) => ({ type: C.DELETE_EQUIPMENT, id })

export const setSelectedDeviceId = (id: string) => ({ type: C.SELECT_DEVICE, id })

export const getDeviceState = (id: string) => async (dispatch, getState) => {
  try {
    const { data } = await http.get(EQUIPMENT_URL + id + '/state')
    const device = getState().nsi.equipment.find((d) => d.id === id)

    // состояние устройства изменилось
    if (device.state !== data.state) {
      dispatch(equipmentUpdated(id, data))
    }
  } catch (error) {
    console.error(error)
  }
}

export const setDeviceState = (id: string, state: EquipmentState) => (dispatch) => {
  postDeviceState(id, state, dispatch)
}

// изменение состояния всех дочерних устройств выбранного региона
export const setDevicesState = (state: 'RUNNING' | 'STOPPED') => (dispatch, getState) => {
  const { equipment, regions, selectedRegionId } = getState().nsi as NsiState
  const id = selectedRegionId.toString()

  const allowedTransitions: Record<string, EquipmentState[]> = {
    RUNNING: ['STOPPED', 'READING_CONF_SUCCESS'],
    STOPPED: ['RUNNING', 'STARTING', 'ERROR'],
  }

  // находим все дочерние устройства находящиеся в нужном состоянии
  const devices: string[] = []

  for (const device of equipment) {
    // пропускаем виртуальные устройства
    if (!isDataReceiver(device)) continue

    const region = regions.find((r) => r.id === device.region_id)
    const isTarget = region && region.path.split('.').includes(id)

    if (isTarget && allowedTransitions[state].includes(device.state)) {
      devices.push(device.id)
    }
  }

  const LIMIT = 4
  let requests = 0

  // параллельная отправка запросов на изменение состояния, но не более чем LIMIT одновременно
  ;(function next() {
    if (devices.length === 0 || requests >= LIMIT) return

    const device = devices.shift()
    requests += 1

    postDeviceState(device, state, dispatch).finally(() => {
      requests -= 1
      next()
    })

    next()
  })()
}

const postDeviceState = (id: string, state: EquipmentState, dispatch) => {
  return http
    .post(EQUIPMENT_URL + id + '/state', { state })
    .then(({ data }) => dispatch(equipmentUpdated(id, data)))
    .catch(handleHttpError)
}

export const setSelectedRegionId = (id: number) => ({ type: C.SELECT_REGION, id })

export const setFormMode = (mode: FormMode) => ({ type: C.SET_FORM_MODE, mode })

export const togglePanel = (name: NsiPanel) => ({ type: C.TOGGLE_PANEL, name })

export const toggleInactiveFilter = () => ({ type: C.TOGGLE_INACTIVE_FILTER })

/**
 * Переместить вверх или вниз выбранный объект в топологии
 */
export const reorderSelectedItem = (direction: 1 | -1) => (dispatch, getState) => {
  const state: NsiState = getState().nsi
  const isRegion = state.selectedRegionId != null
  const isDevice = state.selectedDeviceId != null

  if (!isRegion && !isDevice) return

  const updates = isRegion ? reorderRegions(state, direction) : reorderEquipment(state, direction)

  if (updates) {
    const action = isRegion ? regionBatchUpdated(updates) : equipmentBatchUpdated(updates)
    dispatch(action)
  }
}

export const loadDeviceParameters = () => (dispatch, getState) => {
  const state: NsiState = getState().nsi
  const now = Date.now()

  // обновление кэша не чаще раза в минуту
  if (now - state.deviceParametersLoaded < 60_000) return

  // выставить время запроса, иначе может отправиться несколько одинаковых запросов
  dispatch({ type: C.SET_DEVICE_PARAMETERS, raw: {}, processed: {} })

  const p1 = http.get(`/back/v1/parameters/raw`)
  const p2 = http.get(`/back/v1/parameters/processed`)

  Promise.all([p1, p2])
    .then(([r1, r2]) => dispatch({ type: C.SET_DEVICE_PARAMETERS, raw: r1.data, processed: r2.data }))
    .catch(handleHttpError)
}
