import { SelectOption } from '@alterouniversal/au-react-components'
import { ITextComponent, ITextSettings, ITextSettingsCommon } from 'au-nsi/dashboards'
import { Parameter, SiPrefix, SiUnit } from 'au-nsi/parameters'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import Form from '../../../shared/Forms/Form'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import FormTabs from '../../../shared/Utils/FormTabs'
import { IDataSource } from '../../Nsi/nsi.interfaces'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import { selectParameterOptions, selectParametersMap } from '../../Parameters/params.selectors'
import * as utils from './text.utils'
import TextConditionForm from './TextConditionForm'
import TextSettingsForm from './TextSettingsForm'

/**
 * Модальное окно с настройками компонента "Текст"
 */
export const TextSettings = (props: Props) => {
  const intl = useIntl()

  const [settings, setSettings] = React.useState(props.component.settings)
  const conditions = settings.conditions || []

  const equipmentOptions = React.useMemo(() => {
    return props.equipment.map((e) => ({ label: e.shortname, value: e.id, color: e.color }))
  }, [props.equipment])

  const handleSave = () => props.onSave(settings)

  // изменение настроек основного текста
  const handleChange = (updates: Partial<ITextSettingsCommon>) => {
    setSettings({ ...settings, ...updates })
  }

  // изменение настроек условия по указанному индексу
  const handleConditionChange = (updates, index: number) => {
    setSettings({
      ...settings,
      conditions: settings.conditions.map((c, i) => (i === index ? { ...c, ...updates } : c)),
    })
  }

  // удаление условия
  const handleConditionRemove = (index: number) => {
    setSettings({
      ...settings,
      conditions: settings.conditions.filter((_, i) => i !== index - 1),
    })
  }

  // добавление нового условия со своими настройками
  const handleAppend = () => {
    const copy = JSON.parse(JSON.stringify(settings))
    copy.conditions = undefined
    copy.condition = { device_id: '', parameter_id: '', condition: '>', value: 0 }
    copy.variables = []
    copy.text = ''
    setSettings({ ...settings, conditions: [...conditions, copy] })
  }

  // настройки разбиты по табам - в первом настройки основного текста, далее идет
  // произвольное количество условий
  const tabTitles = conditions.map((_, i) =>
    intl.formatMessage({ id: 'dashboards.text.settings.condition_index' }, { index: i + 1 })
  )
  tabTitles.unshift(intl.formatMessage({ id: 'dashboards.text.settings.condition_main' }))

  const tabs = conditions.map((condition, index) => {
    return (
      <TextConditionForm
        key={index}
        index={index}
        settings={condition}
        equipmentOptions={equipmentOptions}
        parameterOptions={props.parameterOptions}
        parameters={props.parameters}
        prefixes={props.prefixes}
        units={props.units}
        onChange={handleConditionChange}
      />
    )
  })

  const error = utils.validateSettings(settings, intl)

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <FormTabs titles={tabTitles} onAppend={handleAppend} onRemove={handleConditionRemove}>
            <TextSettingsForm
              settings={settings}
              equipmentOptions={equipmentOptions}
              parameterOptions={props.parameterOptions}
              parameters={props.parameters}
              prefixes={props.prefixes}
              units={props.units}
              onChange={handleChange}
            />

            {tabs}
          </FormTabs>

          <ModalFooter error={error} onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

interface Props {
  component: ITextComponent
  equipment: IDataSource[]
  onCancel: () => void
  onSave: (settings: ITextSettings) => void
  parameterOptions: SelectOption[]
  parameters: Map<string, Parameter>
  prefixes: { [id: string]: SiPrefix }
  title: string
  units: { [id: string]: SiUnit }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    equipment: selectDataSources(state),
    parameterOptions: selectParameterOptions(state),
    parameters: selectParametersMap(state),
    prefixes: state.parameters.prefixes,
    units: state.parameters.units,
  }
}

export default connect(mapStateToProps)(TextSettings)
