import { IMapSettings } from 'au-nsi/dashboards'
import { Equipment } from 'au-nsi/equipment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { useAppSelector } from '../../../redux/store'
import GoogleMap from '../../../shared/GoogleMap'
import { IOverlay } from '../../../shared/GoogleMap/useOverlayViews'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { formatValue } from '../../Parameters/params.utils'
import {
  formatAngle,
  generateGradientHtml,
  generateMapPoints,
  generatePopup,
  getVectorHtml,
  IUpdates,
} from './map.utils'

interface IProps {
  id: number
  dashboard: string
  lastResize: number
  settings: IMapSettings
}

const GMap = ({ id, lastResize, settings }: IProps) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const service = useDataService(id, { singleValueMode: true })
  useDataRate(service)

  const store = useAppSelector(
    (s) => ({
      devices: selectDataSources(s),
      parameters: selectDenormalizedParametersMap(s),
      prefixes: s.parameters.prefixes,
      units: s.parameters.units,
    }),
    shallowEqual
  )
  const equipmentInfo = React.useMemo(
    () => new Map<string, Partial<Equipment>>(store.devices.map((d) => [d.id, { name: d.name, address: d.address }])),
    [store.devices]
  )

  const equipment = useAppSelector((state) => state.nsi.equipment)
  // Если устройство есть в стейте - оно доступно пользователю
  const allowedEquipment = useMemo(() => {
    const ids = new Set(equipment.map((e) => e.id))

    return settings.equipment.filter((e) => ids.has(e.id))
  }, [equipment])

  const [mapHeight, setMapHeight] = useState(0)
  // Маркеры устройств на карте
  const [mapPoints, setMapPoints] = useState(generateMapPoints(allowedEquipment, equipmentInfo))
  // Оверлеи, с информацией о частоте / стрелкой вектора угла.
  const [postScriptOverlays, setPostscriptOverlay] = useState<IOverlay[]>([])

  useEffect(() => {
    setMapPoints(generateMapPoints(allowedEquipment, equipmentInfo))
  }, [settings.equipment])

  useEffect(() => {
    if (!mapRef || !mapRef.current) return

    setMapHeight(mapRef.current.parentElement.offsetHeight)
  }, [lastResize, mapRef.current])

  useEffect(() => {
    if (!mapPoints) return

    setPostscriptOverlay(
      mapPoints.map((p) => ({
        position: { lat: p.lat, lng: p.lng },
        id: p.equipment_id || p.id,
        content: '',
      }))
    )
  }, [mapPoints.length])

  // Подписываемся на данные
  useEffect(() => {
    const isVector = settings.type === 'vector'
    const parameters = isVector ? [settings.parameter_id, settings.parameter_angle_id] : [settings.parameter_id]

    const selectors = settings.equipment.map((e) => ({ device_id: e.id, parameters }))
    service.setDataSelectors(selectors)

    service.onTick = () => {
      const fullUpdate = service.shouldRender()

      if (fullUpdate) {
        // параметр угла
        const angle = isVector ? store.parameters.get(settings.parameter_angle_id) : null
        const isDegrees = angle && angle.unit && angle.unit.id === 'deg'

        // параметр амплитуды с единицами измерения указанными в настройках карты
        let magnitude = store.parameters.get(settings.parameter_id)

        if (magnitude != null) {
          magnitude = {
            ...magnitude,
            display_unit: store.units[settings.parameter_unit],
            display_prefix: store.prefixes[settings.parameter_prefix],
          }
        }

        const updates: IUpdates[] = []

        for (let i = 0; i < settings.equipment.length; i++) {
          const e = settings.equipment[i]

          const point = service.selectCurrentPoint(e.id)

          const magnitudeValue = point?.[settings.parameter_id]
          let angleValue = isVector ? point?.[settings.parameter_angle_id] : null

          let magnitudeStr = '—'
          let angleStr = '—'

          // перевод градусов в радианы
          if (isDegrees && angleValue != null) angleValue = (Math.PI * angleValue) / 180

          // форматирование значения амплитуды для вывода
          if (magnitudeValue != null) magnitudeStr = formatValue(magnitudeValue, magnitude)

          // форматирование значения угла
          if (angleValue != null) angleStr = formatAngle(angleValue)

          updates.push({
            magnitude: magnitudeValue,
            angle: angleValue,
            magnitudeStr,
            angleStr,
            fullUpdate,
          })
        }

        // Обновляем информацию о точках в модальном окне
        if (magnitude) {
          setMapPoints((prev) =>
            prev.map((it, i) => ({
              ...it,
              modalContent: generatePopup({
                name: equipmentInfo.get(settings.equipment[i].id)?.name,
                angleName: angle?.name,
                angleValue: updates[i].angleStr,
                magnitudeName: magnitude.name,
                magnitudeValue: updates[i].magnitudeStr,
              }),
            }))
          )
        }

        // Обновляем подпись снизу иконки устройства
        setPostscriptOverlay((prev) =>
          prev.map((it, i) => {
            let content = updates[i].magnitudeStr

            if (settings.type === 'vector') {
              content = getVectorHtml(updates[i].angle)
            }

            if (settings.type === 'gradient') {
              content = generateGradientHtml(updates[i], settings.parameter_min, settings.parameter_max)
            }

            return { ...it, content }
          })
        )
      }
    }
  }, [settings.equipment, settings.type, store.parameters])

  return (
    <GoogleMap
      key={id}
      place={undefined}
      onChange={undefined}
      width={'100%'}
      height={mapHeight + 'px'}
      ref={mapRef}
      points={mapPoints as any}
      allowSelectPlace={false}
      overlays={postScriptOverlays}
      config={{ center: settings.center, zoom: settings.zoom }}
      rememberPlace={false}
    />
  )
}

export default GMap
