import classnames from 'classnames'
import React from 'react'
import './checkbox.styles.css'

class TernaryCheckbox extends React.Component<Props> {
  private handleChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { state, onChange, name } = this.props
    onChange(state, name)
  }

  render() {
    const className = classnames('ternary-checkbox', this.props.state, { 'is-disabled': this.props.disabled })

    return (
      <span className={className} onClick={this.handleChange}>
        <span className="ternary-checkbox__checkmark" />
      </span>
    )
  }
}

export type CheckboxState = 'checked' | 'unchecked' | 'partial'

interface Props {
  state: CheckboxState
  name?: string
  disabled?: boolean
  onChange: (state: CheckboxState, name?: string) => void
}

export default TernaryCheckbox
