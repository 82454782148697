import { UserRole } from 'au-nsi/user'
import { FormMode } from '../../../shared/interfaces'
import { AccessRightRecord } from '../collection.interfaces'
import { generateReducers } from '../collection.reducers'

export const SET_ACCESS_RIGHTS = 'app/roles/SET_ACCESS_RIGHTS'

export interface RolesState {
  mode: FormMode
  items: UserRole[]
  selectedItem: UserRole
  selectedItemId: number
  isLoaded: boolean
  accessRights: AccessRightRecord[]
  isRightsLoaded: boolean
}

const initialState: RolesState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
  accessRights: [],
  isRightsLoaded: false,
}

const newRole: UserRole = {
  id: 0,
  name: '',
  access_rights: [],
}

const reducers = generateReducers<UserRole>('roles', newRole, true)

reducers[SET_ACCESS_RIGHTS] = (state, { accessRights }) => ({ ...state, accessRights, isRightsLoaded: true })

const rolesReducer = (state = initialState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default rolesReducer
