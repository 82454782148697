import React from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { DeviceParamsMapping, ParamMapping } from './parameters.utils'
import { Digital } from 'au-nsi/equipment'
import { Select } from '@alterouniversal/au-react-components'

class Digitals extends React.Component<Props> {
  private handleChange = (systemParameter: string, deviceParameter: string) => {
    this.props.onChange(deviceParameter, { value: systemParameter })
  }

  render() {
    const { digitals, deviceParamsMapping, parametersUsage, options, intl } = this.props
    const placeholder = { label: '', value: null }

    if (digitals.length === 0) {
      return (
        <div className="nsi-placeholder">
          <FormattedMessage id="nsi.no_results" />
        </div>
      )
    }

    const rows = digitals.map(({ name }, i) => {
      const option = deviceParamsMapping[name] || placeholder

      const isDuplicate = parametersUsage.get(option.value) > 1
      const error = isDuplicate ? intl.formatMessage({ id: 'nsi.parameters.duplicate_parameter' }) : ''

      const input = (
        <Select
          name={name}
          value={option.value}
          options={options}
          onChange={this.handleChange}
          onClear={this.props.onRemove}
          error={error}
        />
      )

      return (
        <tr key={i}>
          <td>{name}</td>
          <td>{this.props.editing ? input : <span>{option.label}</span>}</td>
        </tr>
      )
    })

    return (
      <table className="nsi-table single-color">
        <thead>
          <tr>
            <th style={{ width: '50%' }}>
              <FormattedMessage id="nsi.parameters.device" />
            </th>
            <th>
              <FormattedMessage id="nsi.parameters.system" />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }
}

interface Props {
  intl: IntlShape
  options: { value: string; label: string }[]
  digitals: Digital[]
  editing: boolean
  deviceParamsMapping: DeviceParamsMapping
  parametersUsage: Map<string, number>
  onChange: (deviceParameter: string, updates: Partial<ParamMapping>) => void
  onRemove: (deviceParameter: string) => void
}

export default injectIntl(Digitals)
