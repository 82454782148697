import { IHistogramComponent, IHistogramSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Form from '../../../shared/Forms/Form'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import NumberInput from '../../../shared/Inputs/NumberInput'
import OptionalInput from '../../../shared/Inputs/OptionalInput'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { roundNumber } from '../../../utils/format'
import http from '../../../utils/http'
import { showError } from '../../../utils/notifications'
import { selectParametersMap } from '../../Parameters/params.selectors'
import HistogramParameter from './HistogramParameter'

/**
 * Модальное окно с настройками гистограммы
 */
const HistogramSettings = (props: Props) => {
  const intl = useIntl()
  const parametersMap = useSelector(selectParametersMap)

  const [settings, setSettings] = React.useState(props.component.settings)
  const [range, setRange] = React.useState({ min: 0, max: 0 })
  const parameters = settings.parameters[0]

  // запрос минимального и максимального значений параметра для подставления
  // в дефолтные значения пределов
  React.useEffect(() => {
    if (!parameters.device_id || !parameters.parameter_id) return

    const parameter = parametersMap.get(parameters.parameter_id)
    const type = parameter && parameter.is_irregular ? 'irregular' : 'processed'

    let canceled = false
    const tsTo = parameters.time || Date.now()
    const tsFrom = tsTo - parameters.timespan

    const query = {
      query: 'distribution',
      type,
      id: parameters.device_id,
      tsFrom,
      tsTo,
      parameters: [parameters.parameter_id],
    }

    http.post('/back/v1/tsdb/query', query).then((r) => {
      const data = r.data[parameters.parameter_id]
      if (data && !canceled) setRange({ min: roundNumber(data.min), max: roundNumber(data.max) })
    })

    return () => {
      canceled = true
    }
  }, [parameters.device_id, parameters.parameter_id, parameters.time, parameters.timespan])

  const stepOptions = ['auto', 'step', 'bins'].map((stepMode) => ({
    value: stepMode,
    title: intl.formatMessage({ id: 'dashboards.histogram.settings.step_mode.' + stepMode }),
  }))

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => {
      return { ...prev, [key]: value }
    })
  }, [])

  const handleParameterChange = React.useCallback((key: string, value: string) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.parameters[0][key] = value
      })
    )
  }, [])

  const handleSave = () => {
    if (!parameters.device_id) return showError('dashboards.histogram.errors.device')
    if (!parameters.parameter_id) return showError('dashboards.histogram.errors.parameter')
    if (!parameters.timespan) return showError('dashboards.histogram.errors.timespan')

    if (parameters.refresh_interval && parameters.refresh_interval < 5000) {
      return showError('dashboards.histogram.errors.refresh_interval')
    }

    if (settings.min != null && settings.max != null && settings.min >= settings.max) {
      return showError('dashboards.histogram.errors.min')
    }

    props.onSave(settings)
  }

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <h2>{intl.formatMessage({ id: props.title })}</h2>

      <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
        <div className="system__grid">
          <InputRow label={intl.formatMessage({ id: 'common.name' })}>
            <TextInput name="title" value={settings.title} onChange={handleChange} />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.step_mode' })}>
            <Dropdown
              name="step_mode"
              value={settings.step_mode || 'auto'}
              options={stepOptions}
              onChange={handleChange}
            />
          </InputRow>

          {settings.step_mode === 'step' && (
            <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.step_mode.step' })}>
              <NumberInput name="step" onChange={handleChange} type="number" value={settings.step} fullWidth={true} />
            </InputRow>
          )}

          {settings.step_mode === 'bins' && (
            <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.step_mode.bins' })}>
              <NumberInput
                integer={true}
                max={2000}
                min={1}
                name="bins"
                onChange={handleChange}
                type="number"
                value={settings.bins}
                fullWidth={true}
              />
            </InputRow>
          )}

          <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.min' })}>
            <OptionalInput
              name="min"
              value={settings.min}
              defaultValue={range.min}
              defaultLabel={intl.formatMessage({ id: 'dashboards.histogram.settings.y.auto' })}
              onChange={handleChange}
            >
              <NumberInput integer={true} name="min" onChange={handleChange} type="number" value={settings.min} />
            </OptionalInput>
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.max' })}>
            <OptionalInput
              name="max"
              value={settings.max}
              defaultValue={range.max}
              defaultLabel={intl.formatMessage({ id: 'dashboards.histogram.settings.y.auto' })}
              onChange={handleChange}
            >
              <NumberInput integer={true} name="max" onChange={handleChange} type="number" value={settings.max} />
            </OptionalInput>
          </InputRow>
        </div>
        <div>{<HistogramParameter settings={settings} onChange={handleParameterChange} />}</div>

        <ModalFooter onSave={handleSave} onCancel={props.onCancel} />
      </Form>
    </Modal>
  )
}

interface Props {
  component: IHistogramComponent
  onCancel: () => void
  onSave: (settings: IHistogramSettings) => void
  title: string
}

export default HistogramSettings
