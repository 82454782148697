/**
 * Класс для сохранения координат курсора на линейных графиках
 * и передачи изменений во все компоненты
 */
class CursorService {
  private isMultiCursor = false
  private cursor: ICursor = { t: null, x: null, component_id: null }

  private subscribers: Array<(c: ICursor) => void> = []

  subscribe(cb: (c: ICursor) => void) {
    if (this.subscribers.length === 0) {
      // ALT + K включает/отключает режим мультикурсора
      window.addEventListener('keyup', this.handleKeyup)
    }

    this.subscribers.push(cb)

    const unsubscribe = () => {
      const i = this.subscribers.findIndex((s) => s === cb)
      this.subscribers.splice(i, 1)

      if (this.subscribers.length === 0) {
        window.removeEventListener('keyup', this.handleKeyup)
        this.cursor = { t: null, x: null, component_id: null }
      }
    }

    return { unsubscribe }
  }

  setCursor(t: number, x: number, component_id: number) {
    this.cursor = { t, x, component_id: this.isMultiCursor ? null : component_id }
    this.next()
  }

  getCursor() {
    return this.cursor
  }

  private next() {
    for (const cb of this.subscribers) {
      cb(this.cursor)
    }
  }

  private handleKeyup = (e: KeyboardEvent) => {
    if (e.altKey && e.key === 'k') {
      this.isMultiCursor = !this.isMultiCursor

      if (this.isMultiCursor) this.cursor.component_id = null
      this.next()
    }
  }
}

export interface ICursor {
  t: number
  x: number
  component_id: number
}

export default new CursorService()
