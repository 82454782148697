import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import usePopup from '../../../hooks/usePopup'
import { useAppSelector } from '../../../redux/store'
import { wsEvents } from '../../../services/ws/ws.events'
import SmoothPanel from '../../../shared/Utils/SmoothPanel/SmoothPanel'
import { DenormalizedIncident } from '../../Incidents/incident.interfaces'
import { activeDenormalizedIncidentsSelector } from '../../Incidents/incidents.selectors'
import '../Alerts/alerts.styles.css'
import NtfIcon from './NtfIcon'
import NtfPanel from './NtfPanel'
import useDeviceWidth from '../../../hooks/useDeviceWidth'
import useUserNotifications from '../UserNotifications/useUserNotifications'
import { IUserNotification } from 'au-nsi/notifications'
import { useUnreadMessages } from '../Messages/useUnreadMessages'
import { SystemMessage } from 'au-nsi/shared'
import { selectAccessRights } from '../../App/app.selectors'

/**
 * Общий раздел уведомлений (пользовательские, системные и сигнальные ситуации) отображающий
 * кол-во сообщений в каждом разделе и открывающий список по клику.
 */
const Notifications = () => {
  const intl = useIntl()
  const { isMobile } = useDeviceWidth()
  const location = useLocation()

  const ref = useRef<HTMLDivElement>()
  const { open, setOpen } = usePopup(ref)

  const [tab, setTab] = useState('user')

  const rights = useAppSelector(selectAccessRights)
  const incidents = useAppSelector(activeDenormalizedIncidentsSelector)
  const userNotifications = useUserNotifications()
  const systemMessages = useUnreadMessages()

  const messagesColor = getMessagesColor(systemMessages.messages)
  const incidentsColor = getIncidentsColor(incidents)

  // закрытие списка при переходе на другую страницу
  useEffect(() => setOpen(false), [location])

  // Т.к. данный компонент всегда находится на странице, а компоненты списков рендерятся
  // только при открытии, то подписка и обработка всех веб-сокет соединений происходит здесь
  useEffect(() => {
    const reload = () => {
      userNotifications.reload()
      if (rights['settings:get_messages']) systemMessages.reload()
    }

    reload()

    return wsEvents.subscribe(({ resource, method, payload }) => {
      switch (true) {
        case resource === 'ws-connection' && payload === 'reconnected':
          return reload()
        case resource === 'user_notifications' && method === 'create':
          handleNewNotification(payload)
          return userNotifications.append(payload)
        case resource === 'user_notifications_acks' && method === 'create':
          return userNotifications.ack(payload, userNotifications.onlyUnread)
        case resource === 'user_notifications_acks' && method === 'delete':
          return userNotifications.unack(payload)
        case resource === 'system_messages' && method === 'create':
          payload.forEach((item: SystemMessage) => item.is_active && item.level === 'error' && handleNewMessage(item))
          return systemMessages.append(payload)
        case resource === 'system_messages' && method === 'update':
          return systemMessages.update(payload)
        case resource === 'system_messages_acks' && method === 'create':
          return systemMessages.handleAck(payload)
        case resource === 'system_messages_acks' && method === 'delete':
          return systemMessages.handleUnack()
      }
    })
  }, [userNotifications.onlyUnread])

  // системное уведомление о активном системном сообщении
  const handleNewMessage = (msg: SystemMessage) => {
    if (document.visibilityState === 'hidden' && 'Notification' in window && Notification.permission === 'granted') {
      const body = msg.message[intl.locale]
      const ntf = new Notification(document.title, { body })
      ntf.onclick = () => window.focus()
    }

    setTab('system')
    setOpen(true)
  }

  // при получении нового уведомления отправляем системное уведомление (если они включены)
  const handleNewNotification = (item: IUserNotification) => {
    // сообщение может содержать html, поэтому его необходимо сначала перевести в обычный текст
    const span = document.createElement('span')
    span.innerHTML = item.message[intl.locale]

    if (document.visibilityState === 'hidden' && 'Notification' in window && Notification.permission === 'granted') {
      const ntf = new Notification(document.title, { body: span.textContent })
      ntf.onclick = () => {
        window.focus()
        setTab('user')
        setOpen(true)
      }
    }
  }

  const showMessages = systemMessages.total > 0
  const showIncidents = incidents.length > 0
  const showNotifications = userNotifications.unreadCount > 0 || (!showMessages && !showIncidents)

  const icon = (
    <div className="alerts__button" onClick={() => setOpen(!open)}>
      {showNotifications && <NtfIcon icon="user" count={userNotifications.unreadCount} size={18} />}
      {showMessages && <NtfIcon icon="system" count={systemMessages.total} color={messagesColor} size={16} />}
      {showIncidents && <NtfIcon icon="incidents" count={incidents.length} color={incidentsColor} size={18} />}
    </div>
  )

  const panel = (
    <NtfPanel
      tab={tab}
      onTabChange={setTab}
      onClose={() => setOpen(false)}
      incidents={incidents}
      unseenNotifications={userNotifications.unreadCount}
    />
  )

  if (isMobile) {
    return (
      <div ref={ref}>
        {icon}
        {open && panel}
      </div>
    )
  }

  return <SmoothPanel ref={ref} open={open} icon={icon} panelContent={panel} transformOrigin="top right" />
}

const getMessagesColor = (messages: SystemMessage[]) => {
  let isWarn = false

  for (const message of messages) {
    if (message.level === 'error') return 'var(--danger-text)'
    if (message.level === 'warn') isWarn = true
  }

  return isWarn ? 'var(--warning)' : undefined
}

const getIncidentsColor = (incidents: DenormalizedIncident[]) => {
  let isWarn = false

  for (const incident of incidents) {
    if (incident.level === 'is-error') return 'var(--danger-text)'
    if (incident.level === 'is-warning') isWarn = true
  }

  return isWarn ? 'var(--warning)' : undefined
}

export default Notifications
