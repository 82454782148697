import { IDashboard, IDashboardComponent } from 'au-nsi/dashboards'
import http from '../../../utils/http'
import { deepCopy } from '../../../utils/misc'
import actions from '../dashboard.actions'
import { replaceDevices } from '../dashboard.utils'

/**
 * Создание копии экрана со всеми компонентами
 */
export const copyDashboard = async (options: ICopyOptions) => {
  const { dispatch } = options

  // создаем новый экран с указанным именем
  const r = await http.post('/nsi/v1/ui/dashboards', options.dashboard)
  dispatch(actions.dashboardCreated(r.data))
  const dashboard = r.data

  const components = options.components.map((c) => ({ ...deepCopy(c), dashboard_id: dashboard.id }))
  for (const c of components) replaceDevices(c, options.mapping)

  const total = components.length
  let completed = 0
  if (total === 0) return

  return new Promise((resolve, reject) => {
    // копирование последнего компонента, удаление его из списка и рекурсивный вызов для
    // копирования следующего элемента
    const copy = () => {
      const component = components.pop()
      if (!component) return

      http
        .post(`/nsi/v1/ui/dashboards/${dashboard.id}/components`, component)
        .then((res) => dispatch(actions.componentCreated(res.data)))
        .catch(reject)
        .finally(() => {
          completed += 1
          if (components.length > 0) copy()
          if (completed === total) resolve(null)
        })
    }

    // копирование элементов параллельно в 8 потоков
    for (let i = 0; i < 8; i++) copy()
  })
}

interface ICopyOptions {
  dashboard: IDashboard
  components: IDashboardComponent[]
  mapping: Map<string, string>
  dispatch: (action: any) => any
}
