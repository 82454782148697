import { Select } from '@alterouniversal/au-react-components'
import { IHistogramSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Datepicker from '../../../shared/Inputs/Datepicker/Datepicker'
import DurationInput from '../../../shared/Inputs/DurationInput'
import InputRow from '../../../shared/Inputs/InputRow'
import OptionalInput from '../../../shared/Inputs/OptionalInput'
import { selectDataSourceOptions } from '../../Nsi/nsi.selectors'
import { selectParameterOptions } from '../../Parameters/params.selectors'

/**
 * Часть модальной формы с настройками Гистограммы
 */
const HistogramParameter = (props: Props) => {
  const intl = useIntl()

  const settings = props.settings
  const parameters = settings.parameters[0]

  const color = parameters.color
  const colorPickerProps = { position: 'left' as const, showList: true, defaultMode: 'list' as const }

  const parameterOptions = useSelector(selectParameterOptions)
  const equipmentOptions = useSelector(selectDataSourceOptions)

  const handleColorChange = (value: string) => {
    props.onChange('color', value)
  }

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value)
  }

  return (
    <div className="system__grid">
      <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.device_id' })}>
        <Select name="device_id" options={equipmentOptions} value={parameters.device_id} onChange={handleChange} />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.parameter_id' })}>
        <Select
          name="parameter_id"
          options={parameterOptions}
          value={parameters.parameter_id}
          onChange={handleChange}
        />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.time' })}>
        <OptionalInput
          name="time"
          value={parameters.time}
          defaultValue={Date.now()}
          defaultLabel={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.time.current' })}
          onChange={handleChange}
        >
          <Datepicker name="time" time={parameters.time} onTimeChange={handleChange} inputClass="nsi-input" />
        </OptionalInput>
      </InputRow>

      {!parameters.time && (
        <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.refresh_interval' })}>
          <DurationInput
            name="refresh_interval"
            value={parameters.refresh_interval || 0}
            minUnit={'s'}
            onChange={handleChange}
          />
        </InputRow>
      )}

      <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.timespan' })}>
        <DurationInput name="timespan" value={parameters.timespan} onChange={handleChange} />
      </InputRow>

      <InputRow label={intl.formatMessage({ id: 'dashboards.histogram.settings.parameters.color' })}>
        <ColorPicker color={color} onChange={handleColorChange} {...colorPickerProps}></ColorPicker>
      </InputRow>
    </div>
  )
}

interface Props {
  settings: IHistogramSettings
  onChange: (key: string, value: any) => void
}

export default React.memo(HistogramParameter)
