import { Incident } from 'au-nsi/signal-events'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import { selectLanguage } from '../../App/app.selectors'
import { ParameterDn } from '../../Parameters/params.interfaces'
import { SignalEvent } from '../../SignalEvents/se.interfaces'
import { rulesMapSelector } from '../../SignalEvents/se.selectors'
import * as select from '../incidents.selectors'
import IncidentsTableItem from './IncidentsTableItem'

const IncidentsTable = (props: Props) => {
  const cells = props.incidents.map((incident) => (
    <IncidentsTableItem
      key={incident.id}
      incident={incident}
      devices={props.devices}
      users={props.users}
      parameters={props.parameters}
      rules={props.rules}
      lang={props.lang}
      onClick={props.onOpen}
    />
  ))

  return (
    <div className="alerts-list__container">
      <div className="alerts-table">{cells}</div>
    </div>
  )
}

interface Props {
  incidents: Incident[]
  devices: { [id: string]: string }
  users: { [id: number]: string }
  parameters: Map<string, ParameterDn>
  rules: Map<number, SignalEvent>
  lang: string
  dispatch: (action: any) => void
  onOpen: (id: number) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    incidents: select.uniqueIncidentsSelector(state),
    parameters: select.parametersMapSelector(state),
    devices: select.devicesNameSelector(state),
    users: select.usersNameSelector(state),
    rules: rulesMapSelector(state),
    lang: selectLanguage(state),
  }
}

export default connect(mapStateToProps)(IncidentsTable)
