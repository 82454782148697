import { Select } from '@alterouniversal/au-react-components'
import { Equipment } from 'au-nsi/equipment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/store'
import InputRow from '../../../../shared/Inputs/InputRow'
import SearchInput from '../../../../shared/Inputs/Search/SearchInput'
import Modal from '../../../../shared/Modal/Modal'
import { selectEquipmentPaths } from '../../nsi.selectors'
import { EquipmentTable } from './EquipmentTable'
import styles from './searchmodal.module.css'
import { protocolOptions, selectUsedProtocols, statusOptions, tagOptions } from './searchModal.utils'

/**
 * Модальное окно для поиска устройств
 */
const SearchModal = (props: Props) => {
  const intl = useIntl()

  const equipment = useAppSelector((state) => state.nsi.equipmentAll)
  const tags = useAppSelector((state) => state.tags)
  const paths = useAppSelector(selectEquipmentPaths)
  const usedProtocols = useAppSelector(selectUsedProtocols)

  const [search, setSearch] = React.useState('')
  const [protocol, setProtocol] = React.useState<string>()
  const [status, setStatus] = React.useState<string>()
  const [tag, setTag] = React.useState<number>()
  const [orderBy, setOrderBy] = React.useState<string>('name')
  const [order, setOrder] = React.useState(true)

  const locStatusOptions = React.useMemo(() => statusOptions(intl), [intl.locale])
  const locProtocolOptions = React.useMemo(() => protocolOptions(intl, usedProtocols), [intl.locale])
  const locTagOptions = React.useMemo(() => tagOptions(intl, tags), [tags])

  const handleClick = React.useCallback(
    (e) => {
      props.onSelect(e.currentTarget.dataset.id)
      props.onClose()
    },
    [props.onClose, props.onSelect]
  )

  const handleOrderChange = React.useCallback(() => setOrder((prev) => !prev), [])

  const items: SearchItem[] = React.useMemo(() => {
    return equipment
      .filter(
        (e) =>
          (!protocol || protocol === e.protocol) &&
          (!status || e.state === status) &&
          (!tag || e.tags.includes(tag)) &&
          (!props.filter || props.filter(e))
      )
      .map((e) => {
        const { id, name, state } = e
        const address = getDeviceAddress(e)
        const name_lower = name.toLowerCase()
        const path = paths.get(id)

        return {
          id,
          name,
          name_lower,
          address,
          state,
          path,
          path_lower: path.toLowerCase(),
          type: e.protocol === 'db-client' ? 'db-client' : e.type,
        }
      })
      .sort((a, b) =>
        order ? (a[orderBy] ?? '').localeCompare(b[orderBy] ?? '') : (b[orderBy] ?? '').localeCompare(a[orderBy] ?? '')
      )
  }, [equipment, status, protocol, tag, order, orderBy, paths, props.filter])

  const results = search ? findAll(items, search) : items

  return (
    <Modal onClose={props.onClose} size="lg" style={{ padding: '0' }} closeOnEscape={true}>
      <div className={styles.modalHeader}>
        <h2>{intl.formatMessage({ id: 'nsi.search.title' })}</h2>

        <div className={styles.searchForm}>
          <InputRow style={{ width: '50%' }} label={intl.formatMessage({ id: 'nsi.search.placeholder' })}>
            <SearchInput onChange={(v) => setSearch(v.toLowerCase())} autofocus={true} />
          </InputRow>

          <InputRow label={intl.formatMessage({ id: 'system.modules.protocol' })}>
            <Select value={protocol} name={''} onChange={setProtocol} options={locProtocolOptions} />
          </InputRow>
          <InputRow label={intl.formatMessage({ id: 'nsi.device_state' })}>
            <Select value={status} name={''} onChange={setStatus} options={locStatusOptions} />
          </InputRow>
          <InputRow label={intl.formatMessage({ id: 'nsi.device.tags' })}>
            <Select value={tag} name={''} onChange={setTag} options={locTagOptions} />
          </InputRow>
        </div>
        {!search && !protocol && !status && (
          <span>{intl.formatMessage({ id: 'nsi.search.total_count' }, { count: results.length })}</span>
        )}
        {(search || protocol || status) && (
          <span>{intl.formatMessage({ id: 'nsi.search.found_count' }, { count: results.length })}</span>
        )}
      </div>
      <EquipmentTable
        items={results}
        searchPattern={search}
        onClick={handleClick}
        order={order}
        onOrderChange={handleOrderChange}
        orderedBy={orderBy}
        onOrderedByChange={setOrderBy}
      />
    </Modal>
  )
}

const findAll = (equipment: SearchItem[], pattern: string) => {
  return equipment.filter(
    (e) =>
      e.name_lower.includes(pattern) ||
      e.id.includes(pattern) ||
      e.path_lower.includes(pattern) ||
      e.address.includes(pattern)
  )
}

const getDeviceAddress = (d: Equipment) => {
  switch (d.protocol) {
    case 'C.37.118':
    case 'IEC104':
    case 'MLP14':
    case 'modbustcp-client':
      return `${d.configuration.target_host || 0}:${d.configuration.target_port || 0}`
    case 'modbustcp-server':
      return d.configuration.tcp_port + ''
    default:
      return ''
  }
}

export interface SearchItem {
  id: string
  name: string
  path: string
  path_lower: string
  name_lower: string
  address: string
  state: Equipment['state']
  type: string
}

interface Props {
  onClose: () => void
  onSelect: (id: Equipment['id']) => void
  filter?: (e: Equipment) => boolean
}

export default SearchModal
