import { ReactComponent as Icon } from 'icons/history.svg'
import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import css from './history.module.css'
import HistoryModal from './HistoryModal'

interface Props {
  resource: string
  resourceId: string | number
}

/**
 * Кнопка открывающая по клику модальное окно с историей изменений указанного объекта
 */
const HistoryButton = (props: Props) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      className={css.button}
      title={intl.formatMessage({ id: 'system.actions.history.help' })}
      onClick={() => setShowModal(true)}
    >
      <Icon width="18px" height="18px" />
      {showModal && <HistoryModal {...props} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default memo(HistoryButton)
