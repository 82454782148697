import { useState } from 'react'
import HideableLeftMenu from '../../../../../shared/HideableLeftMenu/HIdeableLeftMenu'
import styles from '../../../maptopology.module.css'
import panelStyles from '../../../MapLeftPanel/mapLeftPanel.module.css'
import PrevButton from '../../../../../shared/Utils/PrevButton'
import DeviceTile from '../../../components/DeviceTile'
import { useDispatch } from 'react-redux'
import groupsActions from '../../groups.actions'
import { useIntl } from 'react-intl'
import SearchModal from '../../../../Nsi/Modals/SearchModal/SearchModal'
import placeManager from '../../../../../services/geo/placeManager/placeManager'
import useGroupEquipment from '../../hooks/useGroupEquipment'
import useGroupsAccessRights from '../../hooks/useGroupsAccessRights'
import useHttpLoader from '../../../../../hooks/useHttpLoader'
import confirmService from '../../../../../shared/Modal/confirm.service'
import GroupForm from './GroupForm'
import { useSetRecoilState } from 'recoil'
import { recoilSelectedPlaceState } from '../../../MapTopology'
import { IGroup } from 'au-nsi-elevator/groups'
import { Equipment } from 'au-nsi/equipment'
import { LoaderDots } from '@alterouniversal/au-react-components'

interface IProps {
  group: IGroup
  onPrev: () => void
}

const GroupPanel = ({ group, onPrev }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { wait, loading } = useHttpLoader()
  const setSelectedPlace = useSetRecoilState(recoilSelectedPlaceState)

  const accessRights = useGroupsAccessRights()
  const groupEquipment = useGroupEquipment(group.id)

  const [selectModalOpen, setSelectModalOpen] = useState(false)

  const handleChange = async (value: any, key: keyof IGroup) => {
    if (key === 'address' && groupEquipment.length) {
      const title = intl.formatMessage({ id: 'map.ae.group.update_address.description' })
      const options = [{ value: 'yes', title: intl.formatMessage({ id: 'common.yes' }) }]

      const resp = await confirmService.requestConfirmation(title, options)
      if (resp !== 'yes') return
    }

    wait(dispatch(groupsActions.updateGroup({ ...group, [key]: value })) as any).finally(() => {
      if (key === 'address') setSelectedPlace(value)
    })
  }

  const handleAddEquipment = (id: Equipment['id']) => {
    wait(dispatch(groupsActions.changeEquipmentGroup(group.id, id)) as any)
  }

  const handleRemove = () => {
    confirmService.requestDeleteConfirmation().then((resp) => {
      if (resp === 'delete') wait(dispatch(groupsActions.deleteGroup(group.id)) as any)
    })
  }

  const renderFooter = () => {
    if (loading) {
      return (
        <div className="center center_vertical" style={{ marginTop: 'auto', padding: '1em 1.5em', height: '64px' }}>
          <LoaderDots />
        </div>
      )
    }

    const addToGroupBtn = accessRights.addEquipmentToGroup && (
      <button className="nsi-button default" onClick={() => setSelectModalOpen(true)}>
        {intl.formatMessage({ id: 'map.ae.groups.add_equipment' })}
      </button>
    )

    const removeGroupBtn = accessRights.delete && (
      <button className="nsi-button danger" onClick={handleRemove}>
        {intl.formatMessage({ id: 'map.ae.group.delete' })}
      </button>
    )

    return (
      <div className={styles.addressFooter}>
        {addToGroupBtn}
        {removeGroupBtn}
      </div>
    )
  }

  if (!group) return null
  return (
    <HideableLeftMenu>
      <div className={styles.devicePanel}>
        <div className={styles.addressHeader}>
          <div className={panelStyles.panelControls}>
            <PrevButton onCLick={onPrev} />
          </div>
          <GroupForm group={group} onChange={handleChange} />
        </div>
        <div style={{ overflow: 'auto' }}>
          {groupEquipment.map((e) => (
            <DeviceTile key={e.id} device={e} isSelected={false} />
          ))}
        </div>
        {renderFooter()}
      </div>
      {selectModalOpen && (
        <SearchModal
          onClose={() => setSelectModalOpen(false)}
          onSelect={handleAddEquipment}
          filter={(e) =>
            e.protocol === 'LP001' &&
            placeManager.isIPlacesEqual(e.address, group.address) &&
            !groupEquipment.map((ge) => ge.id).includes(e.id)
          }
        />
      )}
    </HideableLeftMenu>
  )
}

export default GroupPanel
