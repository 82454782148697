import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useHttpLoader from '../../../hooks/useHttpLoader'
import { useAppSelector } from '../../../redux/store'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { patchEquipment } from '../nsi.actions'
import TagCreateRow from './TagCreateRow'
import TagRow from './TagRow'
import css from './tags.module.css'

interface Props {
  id: string
  tags: number[]
  onClose: () => void
}

/**
 * Модальное окно задания тэгов устройства. Также позволяет управлять
 * общим списком доступных тэгов.
 */
const DeviceTagsModal = ({ id, tags, onClose }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()

  const [draft, setDraft] = useState(tags)

  const tagsList = useAppSelector((state) => state.tags)
  const tagsMap = useMemo(() => new Map(tagsList.map((t) => [t.id, t.name])), [tagsList])
  const unusedTags = tagsList.filter((tag) => !draft.includes(tag.id))

  const handleRemove = (id: number) => {
    setDraft((prev) => prev.filter((e) => e !== id))
  }

  const handleSelect = (id: number) => {
    setDraft((prev) => [...prev, id])
  }

  const handleSave = () => {
    // no changes
    if (draft === tags) return onClose()

    const action = patchEquipment(id, { tags: draft })
    const request = action(dispatch)
    wait(request, onClose)
  }

  const selection = draft.map((id) => {
    return (
      <span key={id} className={css.tag + ' ' + css.tagSelectable} onClick={() => handleRemove(id)}>
        {tagsMap.get(id)}
      </span>
    )
  })

  const rows = unusedTags.map((tag) => <TagRow key={tag.id} tag={tag} onClick={handleSelect} />)

  return (
    <Modal onClose={onClose}>
      <h2>{intl.formatMessage({ id: 'nsi.device.tags' })}</h2>

      <div className={css.tagsLine + ' ' + css.tagsInput} style={{ marginBottom: '1em' }}>
        {selection}
      </div>
      {rows}

      <TagCreateRow onCreate={(tag) => handleSelect(tag.id)} />

      <ModalFooter loading={loading} onCancel={onClose} onSave={handleSave} />
    </Modal>
  )
}

export default DeviceTagsModal
