import EventEmitter from '../../utils/events'

export interface WSAction<T = any> {
  resource: string
  method: 'create' | 'update' | 'batch_update' | 'delete'
  payload: T
}

/**
 * Event emitter for all messages from nsi
 */
export const wsEvents = new EventEmitter<WSAction>()

/**
 * Subscribe to events for specific resource
 */
export const wsSubscribe = <T>(resource: string, cb: (v: WSAction<T>) => void) => {
  const handler = (event: WSAction) => {
    if (event.resource === resource) cb(event)
  }

  return wsEvents.subscribe(handler)
}
