import http, { handleHttpError } from '../../../utils/http'
import userLocationReceiver from '../userLocationReciever/userLocationReceiver'

const API_PREFIX = '/nsi/v1/maps'

interface IPrediction {
  description: string // Полное наменование адреса
  place_id: string
  matched_substrings: { length: number; offset: number }[]
}

export interface IAutocompleteResponse {
  status: string
  predictions: IPrediction[]
}

export interface IReverseGeocodeResponse {
  address_components: { long_name: string; short_name: string; types: string[] }[]
  place_id: string
  geometry: {
    location: { lat: number; lng: number }
    viewport: {
      northeast: {
        lat: number
        lng: number
      }
      southwest: { lat: number; lng: number }
    }
  }
  formatted_address: string
}

export interface IPlaceDetailsResponse {
  formatted_address: string
  address_components: { long_name: string; short_name: string; types: string[] }[]
  geometry: {
    location: { lat: number; lng: number }
    viewport: {
      northeast: {
        lat: number
        lng: number
      }
      southwest: { lat: number; lng: number }
    }
  }
  place_id: string
}

const requestAutocomplete = async (input: string, locale: string): Promise<IAutocompleteResponse> => {
  let position = JSON.parse(localStorage.getItem('map_center'))

  if (!position) position = await userLocationReceiver.getUserLocation()
  const result = await http
    .get(
      API_PREFIX +
        `/places/autocomplete?locale=${locale}&input=${input}&user_lat=${position.lat}&user_lng=${position.lng}`
    )
    .then((r) => r.data)
    .catch(handleHttpError)

  if (!result) return { status: 'FAILED', predictions: [] }

  return result
}

const requestReverseGeocode = async (lat: number, lng: number, locale: string): Promise<IReverseGeocodeResponse> => {
  return await http
    .get(API_PREFIX + `/reverse_geocode?locale=${locale}&lat=${lat}&lng=${lng}`)
    .then((r) => r.data)
    .catch(handleHttpError)
}

const requestPlaceDetails = async (place_id: string, locale: string): Promise<IPlaceDetailsResponse> => {
  return await http
    .get(API_PREFIX + `/places?locale=${locale}&place_id=${place_id}`)
    .then((r) => r.data.result)
    .catch(handleHttpError)
}

const googleMapsApi = {
  requestAutocomplete,
  requestPlaceDetails,
  requestReverseGeocode,
}

export default googleMapsApi
