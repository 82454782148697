import { Equipment } from 'au-nsi/equipment'
import usePopup from '../../../hooks/usePopup'
import { useEffect, useRef, useState } from 'react'
import TextareaInput from '../../../shared/Inputs/TextareaInput'

interface IProps {
  notes: Equipment['notes']
  onSave: (notes: string) => void
}

const DeviceNotes = ({ notes, onSave }: IProps) => {
  const ref = useRef()

  const [draftNotes, setDraftNotes] = useState(notes)
  const { open: editing, setOpen: setEditing } = usePopup(ref)

  useEffect(() => {
    if (!editing) setDraftNotes(notes)
  }, [notes])

  useEffect(() => {
    if (!editing && notes !== draftNotes) onSave(draftNotes)
  }, [editing])

  const renderText = () => {
    if (!editing) {
      return (
        <div className="editable-text" style={{ whiteSpace: 'pre-wrap', lineHeight: '20px', marginTop: '3px' }}>
          {draftNotes || '-'}
        </div>
      )
    }

    return <TextareaInput value={draftNotes} onChange={setDraftNotes} style={{ marginBottom: 0, width: '100%' }} />
  }

  return (
    <div
      className={editing ? '' : 'editable-text__wrapper'}
      style={{ whiteSpace: editing ? 'normal' : 'pre-wrap' }}
      ref={ref}
      onClick={() => setEditing(true)}
    >
      {renderText()}
    </div>
  )
}

export default DeviceNotes
