import documentsStyles from '../../../docs.module.css'
import DocumentIcon from '../../DocumentIcon'
import DocumentTitle from '../../DocumentTitle'
import styles from '../documentsPanel.module.css'
import { useIntl } from 'react-intl'
import { IDocument } from 'au-nsi/documents'

interface IDocumentsGridProps {
  draftDocuments: Partial<IDocument>[]
  onDocumentClick: (doc: IDocument) => void
}

const DocumentsList = ({ draftDocuments, onDocumentClick }: IDocumentsGridProps) => {
  const intl = useIntl()

  return (
    <div className={styles.documentsScroll}>
      {draftDocuments.length === 0 && (
        <span className={styles.documentsListNoResults}>{intl.formatMessage({ id: 'common.no_attachments' })}</span>
      )}
      {draftDocuments.map((doc) => (
        <div
          title={doc.name}
          className={documentsStyles.document}
          onClick={() => onDocumentClick(doc as IDocument)}
          key={doc.id ?? doc.name}
        >
          <DocumentIcon document={doc as IDocument} />
          <DocumentTitle document={doc as IDocument} isEditing={false} onFinishEditing={() => {}} />
        </div>
      ))}
    </div>
  )
}

export default DocumentsList
