import { IChartSettings2 } from 'au-nsi/dashboards'
import { Incident } from 'au-nsi/signal-events'
import React from 'react'
import { useSelector } from 'react-redux'
import { createClock } from '../../../services/clock/clock.factory'
import StaticChart from '../../../shared/LineCharts/StaticChart'
import { extractLines } from '../../../shared/LineCharts/utils/version.utils'
import { selectEquipmentMap } from '../../Nsi/nsi.selectors'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'
import { formatUnit } from '../../Parameters/params.utils'
import { SignalEvent } from '../../SignalEvents/se.interfaces'
import { generateCharts } from '../preview.utils'

/**
 * Превью графиков параметров по которым произошла сигнальная ситуация
 */
const IncidentsChartPreview = (props: Props) => {
  const equipment = useSelector(selectEquipmentMap)
  const parameters = useSelector(selectDenormalizedParametersMap)

  // отдельный clock, чтобы не изменять время глобального плеера
  // и выставить кадр только на этих графиках на время сигнальной ситуации
  const clock = React.useMemo(() => createClock(), [])

  const now = Date.now()

  const start = props.incident.ts_start / 1000
  let end = props.incident.ts_end / 1000 || now
  let frame = end - start

  // делаем кадр на 10% больше в каждую сторону для отображения контекста
  // до и после сигнальной ситуации
  end = Math.min(end + frame * 0.1, now)
  frame = frame * 1.2

  const charts = React.useMemo(
    () => generateCharts({ ...props, equipment }),
    [props.incident, props.seRules, equipment]
  )

  // т.к. это превью, то рендерятся статические графики без элементов управления (zoom, pan, etc.)
  const grid = charts.map((chart, i) => {
    const lines = extractLines(chart.settings)
    const parameter_id = lines[0].parameter_id
    const parameter = parameters.get(parameter_id)

    const legend = lines.map((line, i) => {
      const device = equipment.get(line.device_id)

      return (
        <React.Fragment key={line.device_id}>
          {i > 0 && ', '}
          <span style={{ color: line.color, fontWeight: 'bold', fontStyle: 'normal' }}> &mdash; </span>
          <span>{device ? device.shortname : line.device_id}</span>
        </React.Fragment>
      )
    })

    return (
      <React.Fragment key={i}>
        <div style={{ fontStyle: 'italic' }}>
          <span>
            {parameter ? parameter.name : parameter_id}
            {formatUnit(parameter, 'comma')}
          </span>
          &nbsp;({legend})
        </div>
        <div style={{ height: 200 }}>
          <StaticChart
            id={chart.id}
            clock={clock}
            settings={chart.settings as IChartSettings2}
            parameters={parameters}
            lineMode={1}
            ts={end}
            frame={frame}
          />
        </div>
      </React.Fragment>
    )
  })

  return <div>{grid}</div>
}

interface Props {
  incident: Incident
  seRules: Map<number, SignalEvent>
}

export default IncidentsChartPreview
