import { useAppSelector } from '../../../../redux/store'
import { selectAccessRights } from '../../../App/app.selectors'

interface IGroupsAccess {
  delete: boolean
  update: boolean
  moderate: boolean
  addEquipmentToGroup: boolean
}

const useGroupsAccessRights = (): IGroupsAccess => {
  const accessRights = useAppSelector(selectAccessRights)

  return {
    delete: accessRights['ae_groups:delete'] ?? false,
    update: accessRights['ae_groups:update'] ?? false,
    moderate: accessRights['ae_groups:moderate'] ?? false,
    addEquipmentToGroup: accessRights['equipment:update'] ?? false,
  }
}

export default useGroupsAccessRights
