import { SystemMessage } from 'au-nsi/shared'
import http from '../../../utils/http'

export const loadUnreadMessages = async (page?: string) => {
  let url = '/nsi/v1/system/messages?onlyUnread=true&includeTotal=true&size=20'
  if (page) url += '&page=' + page

  const r = await http.get(url)
  return r.data as { results: SystemMessage[]; nextPage: string; total: number }
}

export const ackMessage = (id: SystemMessage['id']) => {
  return http.post(`/nsi/v1/system/messages/${id}/ack`)
}

export const ackAllMessages = (ts: number) => {
  return http.post(`/nsi/v1/system/messages/ack?last_ts=` + ts)
}

export const unackMessage = (id: SystemMessage['id']) => {
  return http.delete(`/nsi/v1/system/messages/${id}/ack`)
}
