import TextInput, { TextInputProps } from '../../Inputs/TextInput'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import AESelectUnknownIdModal from './AESelectUnknownIdModal'
import { selectUnknownControllers } from './unknownControllers.api'

/**
 * AE-262
 *
 * TextInput с опцией "выбрать из неопознанных"
 * Неопознанные лифты - те, что постучались, в ресивер, но их id контроллера не было найдено в базе.
 */
const AEControllerIdInput = (props: TextInputProps) => {
  const intl = useIntl()
  const [modalOpen, setModalOpen] = useState(false)
  const [unknownLifts, setUnknownLifts] = useState<UnknownController[]>([])

  const handleSelect = (id: string) => {
    setModalOpen(false)
    props.onChange(id, props.name)
  }

  useEffect(() => {
    if (props.disabled) return

    selectUnknownControllers().then(setUnknownLifts)
  }, [props.disabled])

  return (
    <div className="inscription-input__block">
      {modalOpen && (
        <AESelectUnknownIdModal
          unknownControllers={unknownLifts}
          onClose={() => setModalOpen(false)}
          onSelect={handleSelect}
        />
      )}

      <TextInput {...props} />
      {!props.disabled && Boolean(unknownLifts.length) && (
        <span onClick={() => setModalOpen(true)}>{intl.formatMessage({ id: 'nsi.lp001.select_from_unknown' })}</span>
      )}
    </div>
  )
}

export interface UnknownController {
  controllerId: string
  ts: number
}

export default AEControllerIdInput
