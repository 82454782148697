import { IDataQuery } from './DataLoader'
import { IDataArray } from './data.types'

/**
 * Parse binary response for archive data request. See documentation for au-back-ui and au-tsdb
 * for format description.
 */
export const parseResponse = (buffer: ArrayBuffer, query: IDataQuery[]): IDataArray[] => {
  const result: IDataArray[] = []
  const view = new DataView(buffer)
  let offset = 0

  for (const q of query) {
    const isAggregation = !!q.aggregationWindow
    const parameterSize = isAggregation ? 3 : 1

    const item: IDataArray = { ts: [] }
    result.push(item)

    for (const parameter of q.names) {
      item[parameter] = []

      if (isAggregation) {
        item[parameter + ':min'] = []
        item[parameter + ':max'] = []
      }
    }

    const bytes = view.getFloat64(offset)
    offset += 8

    const rows = bytes / (8 + 8 * q.names.length * parameterSize)

    for (let i = 0; i < rows; i++) {
      item.ts.push(view.getFloat64(offset))
      offset += 8

      for (const parameter of q.names) {
        if (isAggregation) {
          item[parameter + ':min'].push(getFloat64(view, offset))
          item[parameter].push(getFloat64(view, offset + 8))
          item[parameter + ':max'].push(getFloat64(view, offset + 16))
        } else {
          item[parameter].push(getFloat64(view, offset))
        }

        offset += 8 * parameterSize
      }
    }
  }

  return result
}

const getFloat64 = (view: DataView, offset: number) => {
  const value = view.getFloat64(offset)
  return !Number.isNaN(value) && Number.isFinite(value) ? value : null
}
