import { ITextSettings, ITextSettingsCommon, ITextSettingsCondition, ITextVariable } from 'au-nsi/dashboards'
import { IntlShape } from 'react-intl'
import DataService from '../../../services/data/data.service'
import { matchers } from '../condition.utils'

/**
 * Из текста введенного пользователем извлечь список переменных (переменные задаются с использованием
 * символа '$', например '$a' или '$10')
 * variables - текущий список переменных и их настроек
 * cache - настройки всех предыдущих переменных (которые могли быть уже удалены)
 */
export const extractPlaceholders = (text: string, variables: ITextVariable[], cache: VarCache) => {
  let matches = text.match(/^\$[\w\d]+|\s\$[\w\d]+/gi) || []
  matches = matches.map((m) => m.trim())

  let result = [...variables]
  let updated = false

  // добавляем новые переменные которые появились в тексте
  for (const match of matches) {
    const isPresent = result.find((v) => v.name === match)

    if (!isPresent) {
      // если это новая переменная, то сначала смотрим задавал ли пользователь ее настройки раньше,
      // и если нет, то оставляем пустые значения
      const variable = cache.get(match) || {
        name: match,
        device_id: null,
        parameter_id: null,
        display_unit: null,
        display_prefix: null,
      }

      result.push(variable)
      updated = true
    }
  }

  // из списка переменных удаляем те, символов которых больше нет в тексте
  if (result.length !== matches.length) {
    updated = true
    result = result.filter((v) => matches.includes(v.name))
  }

  return updated ? result : null
}

type VarCache = Map<string, ITextVariable>

/**
 * Разбить строку на части состоящие из статического текста и переменных
 */
export const tokenizeText = (line: string) => {
  const tokens: Array<{ type: 'string' | 'variable'; value: string }> = []
  const re = /^\$[\w\d]+|\s\$[\w\d]+/gi
  let match = re.exec(line)
  let lastIndex = 0

  while (match != null) {
    let index = match.index
    const len = match[0].trim().length
    if (line[index] === ' ') index += 1

    const str = line.slice(lastIndex, index)
    const variable = line.slice(index, index + len)

    if (str) tokens.push({ type: 'string', value: str })
    tokens.push({ type: 'variable', value: variable })

    match = re.exec(line)
    lastIndex = index + len
  }

  const tail = line.slice(lastIndex)
  if (tail) tokens.push({ type: 'string', value: tail })

  return tokens
}

export const fontSizeProps = { integer: true, min: 6, max: 100, fullWidth: true }

export const colorPickerProps: any = { position: 'left', showList: true, defaultMode: 'list' }

export const validateSettings = (settings: ITextSettings, intl: IntlShape) => {
  // все переменные должны быть привязаны к параметрам
  for (const v of settings.variables) {
    if (!v.device_id) return intl.formatMessage({ id: 'dashboards.text.errors.no_device' }, { name: v.name })
    if (!v.parameter_id) return intl.formatMessage({ id: 'dashboards.text.errors.no_parameter' }, { name: v.name })
  }

  const conditions = settings.conditions || []

  // все условия должны быть заполнены
  for (let i = 0; i < conditions.length; i++) {
    const { condition, variables } = conditions[i]

    if (!condition.device_id)
      return intl.formatMessage({ id: 'dashboards.text.errors.no_condition_device' }, { index: i + 1 })
    if (!condition.parameter_id)
      return intl.formatMessage({ id: 'dashboards.text.errors.no_condition_parameter' }, { index: i + 1 })

    // переменные в условиях должы быть заполнены аналогично переменным в основном тексте
    for (const v of variables) {
      if (!v.device_id) return intl.formatMessage({ id: 'dashboards.text.errors.no_device' }, { name: v.name })
      if (!v.parameter_id) return intl.formatMessage({ id: 'dashboards.text.errors.no_parameter' }, { name: v.name })
    }
  }

  return null
}

// при изменении настроек стилей текста также изменить стили переменных (только если переменных еще нет)
export const syncVariableStyles = (settings: ITextSettingsCommon) => {
  if (settings.variables.length === 0) {
    settings.vars_color = settings.text_color
    settings.vars_size = settings.text_size
    settings.vars_style = settings.text_style
  }

  return settings
}

export const getFontStyle = (style: string) => {
  const fontStyle = style === 'italic' ? 'italic' : 'normal'
  const fontWeight = style === 'bold' ? 'bold' : ('normal' as any)
  return { fontStyle, fontWeight }
}

/**
 * Итерация по всем параметрам указанным в настройках текстового компонента (они могут
 * быть указаны в настройках переменных, в настройках условий и переменных в условиях)
 */
export function* eachParameter(settings: ITextSettings) {
  for (const v of settings.variables) {
    yield { device_id: v.device_id, parameter_id: v.parameter_id }
  }

  for (const { condition, variables } of settings.conditions || []) {
    yield { device_id: condition.device_id, parameter_id: condition.parameter_id }

    for (const v of variables) {
      yield { device_id: v.device_id, parameter_id: v.parameter_id }
    }
  }
}

/**
 * Найти вариант настроек для которого выполняется указанное в нем условие на значение параметров
 */
export const matchCondition = (service: DataService, conditions: ITextSettingsCondition[]) => {
  for (const item of conditions) {
    const c = item.condition
    const p = service.selectCurrentPoint(c.device_id)
    if (p == null) continue

    const value = p[c.parameter_id]
    if (value == null) continue

    const match = matchers[c.condition](value, c.value)
    if (match) return item
  }

  return null
}
