import { useState } from 'react'
import { useIntl } from 'react-intl'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import DocumentsGrid from './DocumentsGrid'
import DocumentsSearch from './DocumentsSearch'

/**
 * Модальное окно для выбора списка документов. Рендерит библиотеку документов,
 * но без возможности редактирования.
 */
const DocumentsSelectModal = (props: Props) => {
  const intl = useIntl()
  const [selection, setSelection] = useState<string[]>([])
  const [search, setSearch] = useState(true)

  const handleSave = () => {
    props.onSelect(selection)
    props.onClose()
  }

  return (
    <Modal size="lg" onClose={props.onClose}>
      <div onClick={() => setSelection([])}>
        <h2>{intl.formatMessage({ id: 'documents.select_modal_title' })}</h2>
        {search ? (
          <DocumentsSearch
            selection={selection}
            onSelect={setSelection}
            onCancel={() => setSearch(false)}
            maxHeight="60vh"
          />
        ) : (
          <DocumentsGrid
            selection={selection}
            onSelect={setSelection}
            onSearch={() => setSearch(true)}
            allowEditing={false}
            maxHeight="60vh"
          />
        )}

        <ModalFooter
          onCancel={props.onClose}
          onSave={handleSave}
          saveMessage={intl.formatMessage({ id: 'documents.select' })}
        />
      </div>
    </Modal>
  )
}

interface Props {
  onClose: () => void
  onSelect: (selection: string[]) => void
}

export default DocumentsSelectModal
