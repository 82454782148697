// форматирование выбранного интервала времени для включения в название файла
export const formatTimerange = (t0: number, t1: number) => {
  // экспорт за все время - указываем текущую дату
  if (!t0 && !t1) return formatDate(Date.now())

  const d0 = new Date(t0)
  const d1 = new Date(t1)
  const isSameDate =
    d0.getFullYear() === d1.getFullYear() && d0.getMonth() === d1.getMonth() && d0.getDate() === d1.getDate()

  // экспорт за несколько дней - указываем даты начала и конца
  if (!isSameDate) return formatDate(t0) + ' - ' + formatDate(t1)

  // экспорт за один день - указываем дату и время начала и конца
  return `${formatDate(t0)} (${formatTime(t0)} - ${formatTime(t1)})`
}

// YYYY-MM-DD
const formatDate = (ts: number) => new Date(ts).toISOString().slice(0, 10)

// стандартный hh:mm:ss не используется, т.к. двоеточие не подходит для названия файла
const formatTime = (ts: number) => {
  const date = new Date(ts)
  const h = date.getHours()
  const m = date.getMinutes()
  const s = date.getSeconds()

  return `${h}h${m}m${s}s`
}
