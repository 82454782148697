import { IGaugeSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import useCanvasScale from '../../hooks/useCanvasScale'
import useDataRate from '../../hooks/useDataRate'
import useDataService from '../../hooks/useDataService'
import { selectDenormalizedParametersMap } from '../../pages/Parameters/params.selectors'
import { formatUnit, formatValue } from '../../pages/Parameters/params.utils'
import * as utils from './gauge.utils'
import css from './scale.module.css'
import { drawAxis, drawLabels, drawTicks, getLabelSteps } from './scale.utils'

/**
 * Компонент шкала - линейный аналог шкального датчика
 */
const ScaleComponent = (props: Props) => {
  const { settings } = props
  const { boundaries, ranges } = settings

  const mode = settings.mode.split('_')[0]
  const isCompact = settings.mode.startsWith('compact')
  const isTubular = settings.mode.startsWith('tubular')
  const isVertical = settings.mode.endsWith('vertical')

  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  const lastValue = React.useRef(0)
  const containerRef = React.useRef<HTMLDivElement>()
  const valueRef = React.useRef<HTMLSpanElement>()

  const canvasRef = React.useRef<HTMLCanvasElement>() // отрисовка самого значения
  const backgroundCanvasRef = React.useRef<HTMLCanvasElement>() // статичный фон - ось, подписи и т.д.

  const [size, setSize] = React.useState<DOMRect>()

  const containerWidth = size ? size.width : 64
  const containerHeight = size ? size.height : 64

  const width = isVertical ? containerHeight : containerWidth
  const height = isCompact ? 8 : isVertical ? 60 : 46

  useCanvasScale(canvasRef, width, height)
  useCanvasScale(backgroundCanvasRef, width, height)

  const parameters = useSelector(selectDenormalizedParametersMap)
  const parameter = parameters.get(settings.parameter_id)

  // перевод значения параметра в расположение соответствующей ему точки на шкале
  const value2pixel = (value: number) => {
    const px = ((value - boundaries.lower) * width) / (boundaries.upper - boundaries.lower)
    return Math.round(px)
  }

  React.useEffect(() => {
    setSize(containerRef.current.getBoundingClientRect())
  }, [props.lastResize])

  // отрисовка статических элементов шкалы на фоновом канвасе
  React.useEffect(() => {
    const ctx = backgroundCanvasRef.current.getContext('2d')
    const type = mode as any

    ctx.clearRect(0, 0, width, height)
    drawAxis({ ctx, ranges, type, value2pixel, width })

    if (!isCompact) {
      const start = boundaries.lower
      const stop = boundaries.upper
      const steps = getLabelSteps(stop - start, width)
      drawTicks({ ctx, ranges, start, stop, steps: steps * 2, type, value2pixel })
      drawLabels({ ctx, height, parameter, start, steps, stop, value2pixel, isVertical })
    }
  }, [settings, parameter, width])

  // подписка на данные
  React.useEffect(() => {
    service.setDataSelectors([{ device_id: settings.device_id, parameters: [settings.parameter_id] }])

    const ctx = canvasRef.current.getContext('2d')

    const offset = isTubular ? 3 : 0
    const h = isCompact ? 5 : 10
    const minX = isTubular ? 2 : 0
    const maxX = isTubular ? width - 2 : width

    const reset = () => {
      if (valueRef.current && valueRef.current.innerText) {
        valueRef.current.innerText = ''
        ctx.clearRect(0, 0, width, height)
      }
    }

    // перерисовка шкалы при изменении данных
    const onTick = () => {
      const point = service.selectCurrentPoint(props.settings.device_id)
      const value = point && point[props.settings.parameter_id]

      if (value == null) return reset()

      ctx.clearRect(0, 0, width, height)
      const x = value2pixel(value)

      ctx.fillStyle = utils.colors.info
      ctx.fillRect(minX, offset, bounds(x, minX, maxX) - minX, h)

      for (const range of ranges) {
        if (value > range.lower) {
          const x0 = Math.max(minX, value2pixel(range.lower))
          const x1 = Math.min(maxX, value2pixel(range.upper), x)
          ctx.fillStyle = utils.colors[range.type] || range.color
          ctx.fillRect(x0, offset, x1 - x0, h)
        }
      }

      if (service.shouldRender() && valueRef.current != null) {
        lastValue.current = value
        valueRef.current.innerText = formatValue(value, parameter, false, settings.precision)
      }
    }

    onTick()
    service.onTick = onTick
  }, [settings, parameter, width])

  // при уменьшении высоты компонента сначала скрываем название
  const showTitle = !isCompact && !isVertical && size && size.height > height + 64

  // при дальнейшем уменьшении высоты скрываем выводимое значение и оставляем только канвас
  const valueSize = isCompact ? 18 : 42
  const showValue = isVertical ? containerWidth > height + 60 : containerHeight > height + valueSize

  const style = { transform: undefined }

  // основной стиль - горизонтальный, в вертикальном режиме просто вращаем весь канвас
  if (isVertical) {
    style.transform = `rotate(-90deg) translateY(${containerWidth}px)`
  }

  let containerClass = css.container
  if (isVertical) containerClass += ' ' + css.containerVertical

  return (
    <div ref={containerRef} className={containerClass}>
      {showValue && (
        <div className={isCompact ? css.valueCompact : css.valueBold}>
          <span ref={valueRef} />
          <span className={css.unit}>{formatUnit(parameter, 'none')}</span>
        </div>
      )}

      {showTitle && (
        <div className={`${css.title} line_clamp`} title={settings.title}>
          {settings.title}
        </div>
      )}

      <canvas ref={canvasRef} style={style} />
      <canvas ref={backgroundCanvasRef} style={style} />
    </div>
  )
}

const bounds = (value: number, min: number, max: number) => {
  if (value < min) return min
  else if (value > max) return max
  else return value
}

interface Props {
  id: number
  lastResize: number
  settings: IGaugeSettings
}

export default ScaleComponent
