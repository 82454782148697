import SelectFilesModal from './SelectFilesModal'
import http, { handleHttpError, handleHttpResponse } from '../../../../../utils/http'
import { IDocument } from 'au-nsi/documents'

/**
 * Модальное окно для подтверждения и загрузки выбранных файлов
 */
const UploadFilesModal = (props: Props) => {
  const handleSubmit = async (documents: IDocument[]) => {
    const r = await http.post('/nsi/v1/documents/files', documents).then(handleHttpResponse).catch(handleHttpError)
    props.onClose(r ?? [])
  }

  return (
    <SelectFilesModal
      requestAccess={true}
      files={props.files}
      resource={props.resource}
      resource_item_id={props.resource_item_id}
      onClose={() => props.onClose([])}
      onSubmit={handleSubmit}
      parent_id={props.parent_id}
    />
  )
}

interface Props {
  files: FileList
  parent_id?: string
  onClose: (documents?: IDocument[]) => void
  resource: IDocument['resource']
  resource_item_id: string | number
}

export default UploadFilesModal
