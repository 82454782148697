import Datepicker from './Datepicker/Datepicker'

const Timerange = (props: Props) => {
  return (
    <div className="timerange">
      <Datepicker
        time={props.tsFrom}
        onTimeChange={props.setTsFrom}
        wrapperClass="timerange__time"
        inputClass="timerange__time-input"
      />
      <span className="timerange-separator">&mdash;</span>
      <Datepicker
        time={props.tsTo}
        onTimeChange={props.setTsTo}
        wrapperClass="timerange__time"
        inputClass="timerange__time-input"
      />
    </div>
  )
}

interface Props {
  tsFrom: number
  tsTo: number
  setTsFrom: (t: number) => void
  setTsTo: (t: number) => void
}

export default Timerange
