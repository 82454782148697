import { DataMap, IDataSelector } from './data.types'

/**
 * Расчет кумулятивных сум всех параметров указанных в selectors по одинаковым таймстампам для
 * отображения на графике stacked chart. Для примера ожидаемого поведения см. stack.example.png.
 * Результаты сохраняются с отдельным ключом в DataMap, во-первых, чтобы не модифицировать исходные данные,
 * во-вторых, потому что метки времени у суммированных значений могут отличаться от исходных
 */
export const computeStack = (data: DataMap, selectors: IDataSelector[], steps: Map<string, number>) => {
  let prev: { key: string; parameter: string }

  for (const next of eachSelector(selectors)) {
    const stackedKey = next.key + ':stack' // ключ с которым в DataMap сохранятся суммированные значения

    if (prev == null) {
      const values = data.get(next.key)
      const copy = { ts: [...values.ts], [next.parameter]: [...values[next.parameter]] }
      data.set(stackedKey, copy)
      prev = next
      continue
    }

    // значения суммированные по предыдущим параметрам (предыдущий слой стэка)
    const prevData = data.get(prev.key + ':stack')
    const prevValues = prevData[prev.parameter]
    const prevTs = prevData.ts
    const prevLen = prevTs.length
    const prevStep = steps.get(prev.key) || 1

    // значения текущего параметра (следующий слой стэка)
    const nextData = data.get(next.key)
    const nextValues = nextData[next.parameter]
    const nextTs = nextData.ts
    const nextLen = nextTs.length
    const nextStep = steps.get(next.key) || 1

    let prevIndex = 0
    let nextIndex = 0
    const stackedValues: number[] = []
    const stackedTs: number[] = []

    // проходим по всем меткам времени из предыдущего слоя и текущего в порядке возрастания
    // и для каждой точки определяем текущее значение в стэке
    while (prevIndex < prevLen || nextIndex < nextLen) {
      const nextts = nextTs[nextIndex] || Number.MAX_SAFE_INTEGER
      const prevts = prevTs[prevIndex] || Number.MAX_SAFE_INTEGER

      const ts = nextts < prevts ? nextts : prevts
      let value = 0

      if (nextts === prevts) {
        value = prevValues[prevIndex] + nextValues[nextIndex]
        prevIndex += 1
        nextIndex += 1
      } else if (nextts < prevts) {
        const prevValue = prevTs[prevIndex - 1] && ts - prevTs[prevIndex - 1] < prevStep ? prevValues[prevIndex - 1] : 0
        value = prevValue + nextValues[nextIndex]
        nextIndex += 1
      } else {
        const nextValue = nextTs[nextIndex - 1] && ts - nextTs[nextIndex - 1] < nextStep ? nextValues[nextIndex - 1] : 0
        value = prevValues[prevIndex] + nextValue
        prevIndex += 1
      }

      stackedValues.push(value || 0)
      stackedTs.push(ts)
    }

    const stackedData = data.get(stackedKey) || { ts: [] }
    data.set(stackedKey, stackedData)
    stackedData[next.parameter] = stackedValues
    stackedData.ts = stackedTs
    prev = next
  }
}

function* eachSelector(selectors: IDataSelector[]) {
  for (const { key, parameters } of selectors) {
    for (const parameter of parameters) {
      yield { key, parameter }
    }
  }
}
