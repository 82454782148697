import { ITemplateComponent } from 'au-nsi/dashboards'
import { useIntl } from 'react-intl'
import Modal from '../../../shared/Modal/Modal'

/**
 * Модальное окно с настройками компонента "Форма переменных шаблона".
 * У формы никаких настроек нет, но модальное окно все равно нужно для
 * соответствия другим компонентам экрана.
 */
const TemplateSettings = (props: Props) => {
  const intl = useIntl()

  return (
    <Modal onClose={props.onCancel}>
      <h2>{intl.formatMessage({ id: props.title })}</h2>

      <div style={{ whiteSpace: 'pre-wrap' }} className="text--center text--gray">
        {intl.formatMessage({ id: 'dashboards.template.settings.description' })}
      </div>

      <div className="app-modal__footer">
        <button className="nsi-button inverted" onClick={props.onCancel}>
          {intl.formatMessage({ id: 'common.close' })}
        </button>
      </div>
    </Modal>
  )
}

interface Props {
  component: ITemplateComponent
  onCancel: () => void
  onSave: (settings: { title: string }) => void
  title: string
}

export default TemplateSettings
