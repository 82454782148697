import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import classnames from 'classnames'
import React, { CSSProperties } from 'react'
import { convert, getRelatedUnits } from '../../pages/Parameters/params.utils'
import memoize from '../../utils/memoize'

/**
 * Input for numeric values.
 * Optional props baseUnit, displayUnit, and displayPrefix can be used to present value to user
 * in convenient form. For example, if value internally stored in volts, for large values we can display
 * it in kilovolts, so instead of '123000' user will see '123 kV'.
 */
class NumberInput extends React.Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>()

  private re = /^[+-]?([0-9]*[.])?[0-9]*$/

  constructor(props: Props) {
    super(props)

    const { value } = this.props
    this.state = { valid: this.validateNum(value), value: this.formatInput(value) }
  }

  private get isParameterMode() {
    const { props } = this
    return (
      props.type === 'parameter' && props.baseUnit != null && props.displayUnit != null && props.baseUnit.id !== 'unit'
    )
  }

  componentDidUpdate(prevProps: Props) {
    const value = this.formatInput(this.props.value)

    // update state if props changed and input is not focused
    if (value !== this.state.value && document.activeElement !== this.inputRef.current) {
      this.setState({ value, valid: true })
    }

    if (prevProps.error !== this.props.error || prevProps.disabled !== this.props.disabled) {
      if (!this.props.disabled && this.props.error != null) {
        this.inputRef.current.setCustomValidity(this.props.error)
      }
    }
  }

  private listUnits() {
    const props = this.props as PropsWithUnits
    return this.isParameterMode ? listUnits(props.baseUnit, props.units, props.prefixes) : []
  }

  // when user press Enter propagate changes to the parent component
  private handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') this.handleBlur(e)
  }

  // on blur propagate changes to the parent component
  private handleBlur = (e) => {
    if (!e.target.value && this.props.allowUndefined) {
      return this.props.onChange(undefined, this.props.name)
    }

    if (!this.state.valid) return this.setState({ valid: true, value: this.formatInput(this.props.value) })

    const { value, unit, prefix } = this.parseInput(e.target.value)
    const val = this.parseValue(value, unit, prefix)

    if (val !== this.props.value) this.props.onChange(val, this.props.name)
  }

  // validate input value and set validation status with input value in component state
  private handleChange = (e) => {
    const { value, unit, prefix } = this.parseInput(e.target.value)

    const valid = this.validateStr(value, unit, prefix)
    this.setState({ valid, value: e.target.value })

    if (valid) {
      const val = this.parseValue(value, unit, prefix)
      this.props.onChange(val, this.props.name)
    }
  }

  private validateStr(value: string, unit: SiUnit, prefix: SiPrefix) {
    if (!this.re.test(value)) return false

    const num = this.parseValue(value, unit, prefix)
    return this.validateNum(num)
  }

  private validateNum(num: number) {
    if (Number.isNaN(num)) return false
    if (this.props.min != null && num < this.props.min) return false
    if (this.props.max != null && num > this.props.max) return false
    if (this.props.integer && !Number.isInteger(num)) return false
    return true
  }

  // распарсить строку на само значение, единицу измерения и префикс
  // например '100 kHz' -> value: '100', unit: Hertz, prefix: kilo
  private parseInput = (value: string): { value: string; unit: SiUnit; prefix: SiPrefix } => {
    if (this.isParameterMode) {
      // перебираем все доступные комбинации единиц и префиксов
      for (const { label, unit, prefix } of this.listUnits()) {
        if (value.endsWith(label)) {
          return { value: value.slice(0, -label.length).trim(), unit, prefix }
        }
      }

      // некорректное либо отсутствующее обозначение единицы измерения, берем из указанных в пропсах
      const props = this.props as PropsWithUnits
      return { value, unit: props.displayUnit, prefix: props.displayPrefix }
    }

    return { value, unit: null, prefix: null }
  }

  // при работе с размерными величинами необходимо их преобразовать из пользовательских единиц измерения в базовые
  private parseValue(value: string, unit: SiUnit, prefix: SiPrefix) {
    let val = +value

    if (this.isParameterMode) {
      const { baseUnit, basePrefix } = this.props as PropsWithUnits
      val = convert(val, { fromUnit: unit, fromPrefix: prefix, toUnit: baseUnit, toPrefix: basePrefix })
    }

    return val
  }

  // add measurement unit to number (100 -> '100 kHz')
  private formatInput = (number: number) => {
    if (number == null) {
      return ''
    } else if (this.isParameterMode) {
      const { displayUnit, displayPrefix, baseUnit, basePrefix } = this.props as PropsWithUnits
      const prefix = displayPrefix ? displayPrefix.symbol : ''
      const options = { fromUnit: baseUnit, fromPrefix: basePrefix, toUnit: displayUnit, toPrefix: displayPrefix }

      return convert(number, options) + ' ' + prefix + displayUnit.symbol
    } else {
      return number.toString()
    }
  }

  render() {
    if (this.props.disabled && this.props.disabledStyle === 'text') {
      return <span className="system__input-disabled">{this.state.value}</span>
    }

    const className = classnames('nsi-input numeric', this.props.className, {
      invalid: !this.state.valid,
      wide: this.props.fullWidth,
    })

    return (
      <input
        style={this.props.style}
        ref={this.inputRef}
        className={className}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        required={this.props.required ?? !this.props.allowUndefined}
        disabled={this.props.disabled}
      />
    )
  }
}

// список единиц измерения имеющие одинаковую размерность с baseUnit
// например для Ампер это будет nA, mA, A, kA и т.д.
const listUnits = memoize((baseUnit, units, prefixes) => {
  const list = getRelatedUnits(baseUnit && baseUnit.id, units, prefixes)

  const result = list.map(({ unit, prefix }) => {
    const label = prefix ? prefix.symbol + unit.symbol : unit.symbol
    return { label, unit, prefix }
  })

  // сортировка для того чтобы единицы с префиксами шли до единиц без префиксов
  return result.sort((a, b) => b.label.length - a.label.length)
})

interface BaseProps {
  style?: CSSProperties
  type?: string
  className?: string
  disabled?: boolean
  disabledStyle?: 'text' | 'input'
  fullWidth?: boolean // by default input will be 6em wide, fullWidth make it fill all parent container
  integer?: boolean
  max?: number
  min?: number
  allowUndefined?: boolean
  name: string
  onChange: (value: number, name: string) => void
  value: number
  required?: boolean
  error?: string
}

// пропсы для ввода обычного числа без единиц измерения
interface PropsWithoutUnits extends BaseProps {
  type?: 'number'
}

// пропсы для ввода числа имеющего размерность и единицу измерения
interface PropsWithUnits extends BaseProps {
  type: 'parameter'
  basePrefix?: SiPrefix
  baseUnit: SiUnit
  displayPrefix?: SiPrefix
  displayUnit: SiUnit
  units: { [key: string]: SiUnit }
  prefixes: { [key: string]: SiPrefix }
}

type Props = PropsWithoutUnits | PropsWithUnits

interface State {
  valid: boolean
  value: string
}

export default NumberInput
