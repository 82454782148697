import { generateReducers } from '../Collections/collection.reducers'
import { ParametersState } from './params.interfaces'
import { Parameter } from 'au-nsi/parameters'

export const SET_UNITS = 'app/nsi/SET_SI_UNITS'
export const TOGGLE_SHOW_IDS = 'app/parameters/TOGGLE_SHOW_IDS'

const defaultState: ParametersState = {
  mode: 'view',
  items: [],
  selectedItem: null,
  selectedItemId: null,
  isLoaded: false,
  units: {},
  prefixes: {},
  showIds: false,
}

const newParameter: Parameter = {
  id: '',
  group_id: null,
  name: '',
  description: '',
  type: 'f64',
  unit: 'unit',
  prefix: null,
  display_unit: 'unit',
  display_prefix: null,
  is_irregular: false,
}

const reducers = generateReducers<Parameter>('parameters', newParameter, true)

reducers[SET_UNITS] = (state, { units, prefixes }) => ({ ...state, units, prefixes })
reducers[TOGGLE_SHOW_IDS] = (state: any) => ({ ...state, showIds: !state.showIds })

const paramsReducer = (state: ParametersState = defaultState, action) => {
  const reducer = reducers[action.type]
  return reducer ? reducer(state, action) : state
}

export default paramsReducer
