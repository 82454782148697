import Checkbox from '../../../shared/Inputs/Checkbox/Checkbox'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import MultiSelect from '../../../shared/Inputs/MultiSelect'
import DurationSelector from './DurationSelector'
import { IStorageException } from 'au-nsi/settings'
import { Select, SelectOption } from '@alterouniversal/au-react-components'

const StorageExceptionsRow = (props: Props) => {
  const { row, index, editing } = props

  const handleChange = (value: any, key: string) => {
    const updates = { ...row, [key]: value }
    if (key === 'apply_to_all_parameters' && value === true) {
      updates.parameters = []
    }

    props.onChange(updates, index)
  }

  const handleRetentionChange = (retention: string, retention_days: number) => {
    props.onChange({ ...row, retention, retention_days }, index)
  }

  const selectedDevice = props.deviceOptions.find((o) => o.value === row.device_id)
  const deviceInput = (
    <Select name="device_id" options={props.deviceOptions} value={row.device_id} onChange={handleChange} />
  )

  const parametersInput = (
    <MultiSelect
      name="parameters"
      options={props.parameters}
      selection={row.parameters}
      onChange={handleChange}
      disabled={!editing}
    />
  )

  return (
    <tr>
      <td>{editing ? deviceInput : selectedDevice?.label}</td>
      <td>
        <DurationSelector duration={row.retention} onChange={handleRetentionChange} editing={editing} />
      </td>
      <td>
        <Checkbox
          name="apply_to_all_parameters"
          checked={row.apply_to_all_parameters}
          disabled={!editing}
          onChange={handleChange}
        />
      </td>
      <td>{row.apply_to_all_parameters ? null : parametersInput}</td>
      {editing && (
        <td align="center" style={{ width: '3ch' }}>
          <RemoveButton id="" onRemove={() => props.onRemove(index)} />
        </td>
      )}
    </tr>
  )
}

interface Props {
  index: number
  row: IStorageException
  deviceOptions: SelectOption[]
  parameters: Array<{ id: string; name: string }>
  editing: boolean
  onChange: (row: IStorageException, index: number) => void
  onRemove: (index: number) => void
}

export default StorageExceptionsRow
