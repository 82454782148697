import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import { useSettingsAPI } from './settings.hooks'
import { useIntl } from 'react-intl'
import renderFooter from '../system.footer'
import ImageInput from '../../Libraries/Images/ImageInput'
import { useSelector } from 'react-redux'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import useHttpLoader from '../../../hooks/useHttpLoader'
import Loader from '../../../shared/Utils/Loader'
import http, { handleHttpError } from '../../../utils/http'
import { IMapsSettings } from 'au-nsi/settings'
import { showInfo } from '../../../utils/notifications'

const MapsSettings = () => {
  const intl = useIntl()
  const { wait, loading } = useHttpLoader()

  const modules = useSelector(selectEnabledModules)
  const allowEditing = useSelector(selectAccessRights)['settings:update']

  const settings = useSettingsAPI<IMapsSettings>('maps')

  const handleChange = (value: any, name: string) => settings.updateDraft({ [name]: value })

  const frontKey = settings.editing
    ? settings.draft?.front_api_key
    : '*'.repeat(settings.settings?.front_api_key?.length || 0)
  const nsiKey = settings.editing
    ? settings.draft?.nsi_api_key
    : '*'.repeat(settings.settings?.nsi_api_key?.length || 0)
  const mapIdValue = settings.editing ? settings.draft.map_id : '*'.repeat(settings.settings?.map_id?.length || 0)
  const whiteMapIdValue = settings.editing
    ? settings.draft.white_map_id
    : '*'.repeat(settings.settings?.white_map_id?.length || 0)

  const updateAddresses = () => {
    wait(http.post('/nsi/v1/maps:updateAddress').catch(handleHttpError))
  }

  const updateTimezones = () => {
    const request = http
      .post('/nsi/v1/maps:updateTimezones')
      .then(() => showInfo('system.maps.update_timezones.success'))
      .catch(handleHttpError)

    wait(request)
  }

  const renderRefreshButtons = () => {
    if (!allowEditing) return null
    if (loading) return <Loader />

    return (
      <>
        <button className="system__button is-secondary" onClick={updateAddresses}>
          {intl.formatMessage({ id: 'system.maps.update_addresses' })}
        </button>
        {modules.has('timezones') && (
          <button className="system__button is-secondary" onClick={updateTimezones}>
            {intl.formatMessage({ id: 'system.maps.update_timezones' })}
          </button>
        )}
      </>
    )
  }

  return (
    <div className={'nsi-main__container'}>
      <h2 className={'system__title'}>{intl.formatMessage({ id: 'nav.system.maps' })}</h2>
      <div className={'system__grid'}>
        <InputRow label={intl.formatMessage({ id: 'system.maps.front_api_key' })}>
          <TextInput disabled={!settings.editing} value={frontKey} name={'front_api_key'} onChange={handleChange} />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.nsi_api_key' })}>
          <TextInput disabled={!settings.editing} value={nsiKey} name={'nsi_api_key'} onChange={handleChange} />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.map_id' })}>
          <TextInput
            required={false}
            disabled={!settings.editing}
            value={mapIdValue}
            name={'map_id'}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.white_map_id' })}>
          <TextInput
            required={false}
            disabled={!settings.editing}
            value={whiteMapIdValue}
            name={'white_map_id'}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.marker_icon_id' })}>
          <ImageInput
            onChange={handleChange}
            value={settings?.draft?.marker_icon_id || undefined}
            editing={settings.editing}
            name={'marker_icon_id'}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.equipment_icon_id' })}>
          <ImageInput
            onChange={handleChange}
            value={settings?.draft?.equipment_icon_id || undefined}
            editing={settings.editing}
            name={'equipment_icon_id'}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'system.maps.couple_equipment_icon_id' })}>
          <ImageInput
            onChange={handleChange}
            value={settings?.draft?.couple_equipment_icon_id || undefined}
            editing={settings.editing}
            name={'couple_equipment_icon_id'}
          />
        </InputRow>
      </div>
      {allowEditing && renderFooter(settings, renderRefreshButtons())}
    </div>
  )
}

export default MapsSettings
