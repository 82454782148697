import classnames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import css from './chart.module.css'
import { ReactComponent as CreateBtnSvg } from '../../../images/icons/nsi/add-button.svg'

const ChartTabs = (props: Props) => {
  const intl = useIntl()
  const [index, setIndex] = React.useState(props.index || 0)

  const changeIndex = (i: number) => {
    props.onIndexChange(i)
    setIndex(i)
  }

  // при выходе за границу (при удалении таба например) переключаемся на последний из имеющихся
  React.useLayoutEffect(() => {
    if (index >= props.titles.length) {
      changeIndex(props.titles.length - 1)
    }
  }, [props.titles.length])

  const handleClick = (e) => {
    changeIndex(+e.currentTarget.dataset.index)
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    props.onRemove(+e.currentTarget.dataset.index)
  }

  const tab = React.Children.toArray(props.children)[index]

  const header = props.titles.map((title, i) => {
    const className = classnames(css.tab, { [css.tabActive]: index === i })

    return (
      <div key={title} data-index={i} className={className} onClick={handleClick}>
        <span>{title}</span>

        {i > 0 && (
          <span data-index={i} onClick={handleRemove} className={css.removeIcon}>
            &times;
          </span>
        )}
      </div>
    )
  })

  const handleAppend = () => {
    props.onAppend()
    changeIndex(props.titles.length)
  }

  return (
    <div className={css.form}>
      <div className={css.tabs}>
        <div style={{ padding: 0 }} className="nsi-tree_title">
          <h2 style={{ paddingRight: 70, whiteSpace: 'nowrap' }}>{intl.formatMessage({ id: 'common.settings' })}</h2>
          <div className="nsi__title-controls">
            <CreateBtnSvg className="nsi-tree-add-btn" onClick={handleAppend} />
          </div>
        </div>
        {header}
      </div>
      {tab}
    </div>
  )
}

interface Props {
  titles: string[]
  children: React.ReactNodeArray
  index: number
  onAppend: () => void
  onIndexChange: (index: number) => void
  onRemove: (index: number) => void
}

export default ChartTabs
