import { loadMicrofrontend } from '../../../utils/module.loader'

// Custom Element registered by au-docs module
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'au-docs': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    }
  }
}

const moduleID = 'AU_DOCS_MODULE'
const scriptSrc = '/api/docs/v1/lib/index.js'
const stylesSrc = '/api/docs/v1/lib/index.css'

// динамическая загрузка кода модуля пользовательской документации
export const loadDocsModule = (onload: () => void) => loadMicrofrontend({ moduleID, scriptSrc, stylesSrc, onload })
