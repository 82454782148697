import { IGanttSettings } from 'au-nsi/dashboards'
import { Incident } from 'au-nsi/signal-events'
import { Store, Unsubscribe } from 'redux'
import { IncidentsState } from '../../pages/Incidents/incident.interfaces'
import { SignalEventsState } from '../../pages/SignalEvents/se.interfaces'
import { ReduxState } from '../../redux/store.types'
import EventEmitter from '../../utils/events'
import memoize from '../../utils/memoize'

/**
 * Subscribe to redux store and return EventEmitter witch will be emitting values
 * only when incidents or signal events change
 */
export const store2emitter = (store: Store<ReduxState>): EventEmitter<IStoreSlice> => {
  const initialState = store.getState()
  let lastIncidents = initialState.incidents
  let lastSignalEvents = initialState.signal_events

  let unsubscribe: Unsubscribe

  const init = () => {
    unsubscribe = store.subscribe(() => {
      const state = store.getState()
      const incidents = state.incidents
      const signalEvents = state.signal_events

      if (incidents !== lastIncidents || signalEvents !== lastSignalEvents) {
        lastIncidents = incidents
        lastSignalEvents = signalEvents

        emitter.next({ incidents, ...signalEventsToMaps(signalEvents) })
      }
    })
  }

  const cleanup = () => unsubscribe()

  const emitter = new EventEmitter<IStoreSlice>(init, cleanup)
  emitter.next({ incidents: lastIncidents, ...signalEventsToMaps(lastSignalEvents) })

  return emitter
}

interface IStoreSlice {
  incidents: IncidentsState
  seRules: Map<number, { id: number; level: string }>
  seNames: Map<number, string>
}

/**
 * Преобразовать список настроек сигнальных ситуаций в словари для дальнейшего поиска
 * имени и уровня по идентификатору формулы
 */
const signalEventsToMaps = memoize((se: SignalEventsState) => {
  const seRules = new Map<number, { id: number; level: string }>()
  const seNames = new Map<number, string>()

  for (const item of se.items) {
    seNames.set(item.id, item.name)

    for (const rule of item.rules) {
      seRules.set(rule.id, { id: item.id, level: rule.level })
    }
  }

  for (const item of Object.values(se.deletedRulesCache)) {
    for (const rule of item.rules) {
      seRules.set(rule.id, { id: item.id, level: rule.level })
    }
  }

  return { seRules, seNames }
})

/**
 * Создать Map (key -> incidents) из массива инцидентов и отсортировать по ts_end в убывающем порядке
 * структура ключа - '{device id}|{incident type}|{signal event id}|{incident level}'
 * структура значений - [[ts_start, ts_end, incident_id]] - массив массивов из 3х элементов содержащий
 * время начала инцидента, время конца и его id (все остальные данные о инцидентах на диаграмме не нужны)
 */
export const groupIncidents = (
  allIncidents: Incident[],
  seRules: Map<number, { id: number; level: string }>,
  settings: IGanttSettings
) => {
  const eventsFilter = new Set(settings.incidents.map((row) => row.signal_event_id))
  const deviceFilter = settings.show_every_device ? null : new Set(settings.equipment)

  // оставляем только инциденты по сигнальным ситуациям и устройствам заданным в настройках диаграммы
  const incidents = allIncidents.filter((incident) => {
    if (incident.type !== 'formula') return false

    const se = seRules.get(incident.formula_id)
    if (!se) return false

    const eventsMatch = eventsFilter.has(se.id)
    const deviceMatch = !deviceFilter || Object.keys(incident.details).some((id) => deviceFilter.has(id))
    return eventsMatch && deviceMatch
  })

  // Вычисляем обобщенную статистику за все время, важно делать до последующей сортировки
  // т.к. mergeIncidents ожидает инциденты отсортированные по ts_start (именно так они приходят из стора).
  // Для статистики не важны отдельные инциденты, поэтому объединяем вместе элементы которые все равно
  // сольются при отображении (< 1px при размере диаграммы ~500px и отображаемом времени статистики в 10 * timespan)
  const delta = Math.max(settings.timespan / 50, 60_000)
  const summary = mergeIncidents(incidents, seRules, delta)

  const result = new Map<string, number[][]>()
  result.set('summary|warning', summary.warning).set('summary|danger', summary.danger)

  // для следующей части необходима сортировка по ts_end
  incidents.sort((a, b) => {
    if (a.ts_end === b.ts_end) return 0
    if (a.ts_end === 0) return -1
    if (b.ts_end === 0) return 1
    return b.ts_end - a.ts_end
  })

  // группируем инциденты по составному ключу (устройство + сигнальная ситуация + уровень)
  // и преобразуем в более компактный вариант
  for (const incident of incidents) {
    if (incident.type !== 'formula') continue

    const se = seRules.get(incident.formula_id)
    if (!se) continue

    const postfix = `|formula|${se.id}|${se.level}`

    for (const deviceId of Object.keys(incident.details)) {
      const key = deviceId + postfix

      let items = result.get(key)
      if (!items) {
        items = []
        result.set(key, items)
      }

      items.push([incident.ts_start / 1000, incident.ts_end / 1000, incident.id])
    }
  }

  return result
}

/**
 * Merge all overlapping incidents into one for displaying in Gantt timeline summary
 * delta - difference in ms between two incidents that can be merged into one
 * incidents should be sorted by ts_start in descending order
 */
export const mergeIncidents = (
  incidents: Incident[],
  seRules: Map<number, { id: number; level: string }>,
  delta = 0
) => {
  const result = { warning: [], danger: [] }

  let id = 0 // синтетический уникальный id, необходимый для каждой группы

  for (const incident of incidents) {
    const level = seRules.get(incident.formula_id).level
    const arr = result[level]
    if (!arr) continue

    const ts_start = incident.ts_start / 1000
    const ts_end = incident.ts_end / 1000

    if (arr.length === 0) {
      arr.push([ts_start, ts_end, id++])
      continue
    }

    const lastItem = arr[arr.length - 1]

    // overlap - start of the next incident is before the end of the previous
    const shouldMerge = ts_end + delta >= lastItem[0] || ts_end === 0

    if (shouldMerge) {
      // extend previous incident
      lastItem[0] = minTs(ts_start, lastItem[0])
      lastItem[1] = maxTs(ts_end, lastItem[1])
    } else {
      arr.push([ts_start, ts_end, id++])
    }
  }

  return result
}

const minTs = (ts1: number, ts2: number) => (ts1 < ts2 ? ts1 : ts2)

const maxTs = (ts1: number, ts2: number) => {
  // 0 means current time and therefore considered to be greater than any other value
  if (ts1 === 0 || ts2 === 0) {
    return 0
  }

  return ts1 > ts2 ? ts1 : ts2
}

/**
 * Выбрать инциденты попадающие в интервал от t0 до t1
 * incidents = [[ts_start, ts_end]] и должны быть отсортированы по ts_end в убывающем порядке
 */
export const selectRange = (incidents: number[][], t0: number, t1: number) => {
  const result = []

  // filter incidents that fall inside the frame
  // optimized for most common scenario - online mode, in that case gantt frame (1h) is much less than
  // shift frame (12h), therfore it only necessary to scan ~1/12 of the whole array
  for (const item of incidents) {
    const startsBefore = item[0] < t1
    const endsAfter = item[1] > t0 || item[1] === 0

    if (startsBefore && endsAfter) {
      result.push(item)
    } else if (item[1] < t0 && item[1] !== 0) {
      // incident ended before the start of the frame, it means all following
      // incidents are also outside the frame (because groupIncidents sorts by ts_end DESC)
      break
    }
  }

  return result
}
