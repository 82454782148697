import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../../redux/store'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import moderationActions from '../../moderation.actions'
import { IModerationRequest } from 'au-nsi/moderation'

/**
 * Хук, предоставляющий высокоуровненовое API для переключения между экранами с заявками
 * */
const useRequestPageNav = (currentId: IModerationRequest['id']) => {
  const isFromList = useRef(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const requests = useAppSelector((state) => state.moderation_requests.requests)
  const currentIndex = useMemo(() => requests.findIndex((r) => r.id === currentId), [requests, currentId])

  useEffect(() => {
    if (requests.length === 1 || !isFromList) {
      isFromList.current = false
      return
    }

    if (!requests[currentIndex + 1]) dispatch(moderationActions.loadRequests())
  }, [currentIndex, requests])

  const prevPage = () => {
    if (requests[currentIndex - 1]) navigate(getPageLink(requests[currentIndex - 1].id))
  }

  const nextPage = () => {
    if (requests[currentIndex + 1]) navigate(getPageLink(requests[currentIndex + 1].id))
  }

  if (!isFromList.current) return { nextId: null, nextPage: null, prevPage: null, prevId: null }
  return {
    nextId: requests[currentIndex + 1]?.id,
    prevId: requests[currentIndex - 1]?.id,
    prevPage: !!requests[currentIndex - 1] ? prevPage : null,
    nextPage: !!requests[currentIndex + 1] ? nextPage : null,
  }
}

const getPageLink = (requestId: IModerationRequest['id']) => `/user/requests/${requestId}`

export default useRequestPageNav
