import { Select } from '@alterouniversal/au-react-components'
import React from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import JournalsExport from '../../../shared/Export/JournalsExport'
import Timerange from '../../../shared/Inputs/Timerange'
import InfiniteScroll from '../../../shared/Pagination/InfiniteScroll'
import TableHeader from '../../../shared/Utils/TableHeader'
import HttpQueue from '../../../utils/http.queue'
import { formatDate } from '../../../utils/lang'
import NoResults from '../common/NoResults'

interface LoginInfo {
  id: number
  ts: number
  success: boolean
  error_msg: string
  ip: string
  user_name: string
  user_agent: string
}

const WEEK = 7 * 24 * 60 * 60 * 1000

class LoginsTable extends React.Component<Props, State> {
  state: State = {
    results: [],
    t0: Date.now() - WEEK,
    t1: Date.now(),
    isLoading: true,
    size: 25,
    nextPage: null,
    selectedType: 'all',
  }

  private http = new HttpQueue()

  private successMessage = (
    <span>
      <FormattedMessage id="system.auth.login_success" />
    </span>
  )

  private typeOptions = [
    { label: this.props.intl.formatMessage({ id: 'system.auth.status.all' }), value: 'all' },
    { label: this.props.intl.formatMessage({ id: 'system.auth.status.success' }), value: 'success' },
    { label: this.props.intl.formatMessage({ id: 'system.auth.status.error' }), value: 'error' },
  ]

  constructor(props: Props) {
    super(props)

    this.http.onData = (r, ctx) => {
      const data = r.data.results
      const nextPage = r.data.nextPage
      const results = ctx.append ? [...this.state.results, ...data] : data

      this.setState({ results, isLoading: false, nextPage })
    }
    this.http.onStart = () => this.setState({ isLoading: true })
    this.http.onFinish = () => this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    this.http.cancel()
  }

  private fetchData = () => {
    const url = this.constructUrl(null)
    this.http.get(url, { append: false })
  }

  private fetchMore = () => {
    const url = this.constructUrl(this.state.nextPage)
    this.http.get(url, { append: true })
  }

  private constructUrl(nextPage: string) {
    let url = `/nsi/journal/logins?size=${this.state.size}`
    url += `&type=${this.state.selectedType}`
    url += `&t0=${this.state.t0}&t1=${this.state.t1}`
    if (nextPage) url += `&page=${nextPage}`

    return url
  }

  private handleTypeChange = (value: string) => {
    this.setState({ selectedType: value }, () => this.fetchData())
  }

  private setT0 = (t0: number) => this.setState({ t0 }, () => this.fetchData())
  private setT1 = (t1: number) => this.setState({ t1 }, () => this.fetchData())

  render() {
    const rows = this.state.results.map((l) => {
      const msg = l.success ? this.successMessage : <span className="text--danger">{l.error_msg}</span>
      const time = formatDate(l.ts, 'd LLL HH:mm:ss')

      return (
        <tr className="font--x-small" key={l.id}>
          <td className="text--nowrap">{time}</td>
          <td>{l.user_name}</td>
          <td className="text--nowrap">{l.user_agent}</td>
          <td className="text--nowrap">
            <code>{l.ip}</code>
          </td>
          <td>{msg}</td>
        </tr>
      )
    })

    let tableClass = 'nsi-table is-compact'
    if (this.state.isLoading) tableClass += ' is-loading'

    const { intl } = this.props
    const timeTrans = intl.formatMessage({ id: 'system.auth.time' })
    const userTrans = intl.formatMessage({ id: 'system.auth.user' })
    const agentTrans = intl.formatMessage({ id: 'system.auth.user_agent' })
    const messageTrans = intl.formatMessage({ id: 'system.auth.message' })

    return (
      <div>
        <div className="s-logs__header-row">
          <div className="s-logs__header-item">
            <div className="s-logs__header-label">
              <FormattedMessage id="system.auth.status" />
            </div>
            <Select
              name="selectedType"
              options={this.typeOptions}
              value={this.state.selectedType}
              onChange={this.handleTypeChange}
            />
          </div>

          <div className="s-logs__header-item">
            <div className="s-logs__header-label">
              <FormattedMessage id="system.auth.timerange" />
            </div>
            <Timerange tsFrom={this.state.t0} tsTo={this.state.t1} setTsFrom={this.setT0} setTsTo={this.setT1} />
          </div>

          <JournalsExport
            url="/nsi/journal/logins:export"
            t0={this.state.t0}
            t1={this.state.t1}
            style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: '20px' }}
          />
        </div>

        <InfiniteScroll
          parentSelector=".nsi-main__wrapper"
          isMore={this.state.nextPage != null}
          loadMore={this.fetchMore}
        >
          <table className={tableClass}>
            <thead>
              <TableHeader titles={[timeTrans, userTrans, agentTrans, 'IP', messageTrans]} />
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </InfiniteScroll>

        {!this.state.isLoading && this.state.results.length === 0 && <NoResults />}
      </div>
    )
  }
}

interface State {
  results: LoginInfo[]
  t0: number
  t1: number
  isLoading: boolean
  size: number
  nextPage: string
  selectedType: string
}

interface Props {
  intl: IntlShape
}

export default injectIntl(LoginsTable)
