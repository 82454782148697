import { useIntl } from 'react-intl'
import { AnyCatalogWithInstances } from '../../../../../Catalogs/catalogs.types'
import { OrganizationTypeWithInstances } from '../../../../../Catalogs/Organizations/organizations.types'
import InstanceRow from '../../../../../Catalogs/components/InstanceForm/InstanceRow'
import InputRow from '../../../../../../shared/Inputs/InputRow'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { Organization } from 'au-nsi/organizations'

interface IProps {
  organizationType: OrganizationTypeWithInstances
  organization: Organization
  catalogs: AnyCatalogWithInstances[]
  editing: boolean
  onChange: (schemaId: IPropertyDescription['id'], value: any) => void
  onNameChange: (name: string) => void
}

const OrganizationRequestTable = ({
  editing,
  organizationType,
  organization,
  catalogs,
  onChange,
  onNameChange,
}: IProps) => {
  const intl = useIntl()

  const rows = organizationType.organization_schema.map((schema) => (
    <InstanceRow
      key={schema.id}
      rowSchema={schema}
      editing={editing}
      onChange={onChange}
      catalogs={catalogs}
      value={organization.organization_properties[schema.id]}
    />
  ))

  const tableClass = editing ? 'nsi-settings-table is-fixed' : 'nsi-settings-table is-striped is-fixed'
  return (
    <>
      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'catalogs.name' })} style={{ paddingLeft: '3px' }}>
          <TextInput value={organization.name} name={'name'} onChange={onNameChange} disabled={!editing} />
        </InputRow>
      </div>
      <table className={tableClass}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: 'catalogs.schema_property' })}</th>
            <th>{intl.formatMessage({ id: 'catalogs.value' })}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default OrganizationRequestTable
