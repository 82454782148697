import { TTreePersistenceStrategy } from './useTreeState'
import IStoreTreeStrategy from '../../../utils/percistenceStrategies/strategy.interface'
import { AnyObject } from 'common'
import InMemoryStrategy from '../../../utils/percistenceStrategies/InMemoryStrategy'
import LocalStorageStrategy from '../../../utils/percistenceStrategies/LocalStorageStrategy/LocalStorageStrategy'
import SetLsCodec from '../../../utils/percistenceStrategies/LocalStorageStrategy/SetLsCodec'

const storeTreeStrategy: Record<TTreePersistenceStrategy | 'setLocalstorage', IStoreTreeStrategy<AnyObject>> = {
  inmemory: new InMemoryStrategy(),
  localstorage: new LocalStorageStrategy(),
  setLocalstorage: new LocalStorageStrategy(SetLsCodec),
  none: null,
}

export default storeTreeStrategy
