import classnames from 'classnames'
import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import { Equipment } from 'au-nsi/equipment'

import './cell.styles.css'

class RegistratorCell extends React.Component<Props> {
  private renderWarning() {
    const { registrator, intl } = this.props
    const title = intl.formatMessage({ id: 'nsi.device_state.' + registrator.state })
    return <span title={title} className="registrator-cell__warning" />
  }

  render() {
    const { props } = this
    const { registrator } = props

    const indicatorClass = classnames('registrator-cell__indicator', {
      'is-warning': props.warning,
      'is-danger': props.danger,
    })

    return (
      <div className="registrator-cell">
        <span className={indicatorClass} />
        {props.children}
        <span className="registrator-cell__color" style={{ backgroundColor: registrator.color }} />
        <span title={registrator.name}>{registrator.shortname || registrator.name}</span>
        {registrator.state !== 'RUNNING' && this.renderWarning()}
      </div>
    )
  }
}

interface Props {
  registrator: Equipment
  children: any
  danger?: boolean
  warning?: boolean
  intl: IntlShape
  dispatch: (action: any) => void
}

export default connect()(injectIntl(RegistratorCell))
