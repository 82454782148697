import { ITextSettingsCommon } from 'au-nsi/dashboards'
import React from 'react'
import { IntlShape } from 'react-intl'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import Dropdown, { DropdownOption } from '../../../shared/Inputs/Dropdown'
import NumberInput from '../../../shared/Inputs/NumberInput'
import * as utils from './text.utils'

/**
 * Блок с настройками стиля отображения переменных в тексте (появляется только при наличии переменных)
 */
const VarsStyleSettings = (props: Props) => {
  const { intl, settings } = props
  if (settings.variables.length === 0) return null

  const handleColorChange = (color) => {
    props.onChange(color, 'vars_color')
  }

  return (
    <React.Fragment>
      <div>
        <div className="system__label">{intl.formatMessage({ id: 'dashboards.text.settings.vars_size' })}</div>
        <div className="system__input-wrapper">
          <NumberInput name="vars_size" value={settings.vars_size} onChange={props.onChange} {...utils.fontSizeProps} />
        </div>
      </div>

      <div>
        <div className="system__label">{intl.formatMessage({ id: 'dashboards.text.settings.vars_style' })}</div>
        <div className="system__input-wrapper">
          <Dropdown
            name="vars_style"
            value={settings.vars_style}
            options={props.fontStyleOptions}
            onChange={props.onChange}
          />
        </div>
      </div>

      <div>
        <div className="system__label">{intl.formatMessage({ id: 'dashboards.text.settings.vars_color' })}</div>
        <div className="system__input-wrapper">
          <ColorPicker color={settings.vars_color} onChange={handleColorChange} {...utils.colorPickerProps} />
        </div>
      </div>
    </React.Fragment>
  )
}

interface Props {
  fontStyleOptions: DropdownOption<string>[]
  intl: IntlShape
  onChange: (value: any, key: string) => void
  settings: ITextSettingsCommon
}

export default VarsStyleSettings
