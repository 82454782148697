const SET_TRANSLATIONS = 'app/lang/SET_TRANSLATIONS'

export interface LanguageState {
  translations: { [key: string]: string }
  translationsLoaded: boolean
}

const initialState: LanguageState = {
  translations: {},
  translationsLoaded: false,
}

export function setTranslations(translations: { [key: string]: string }) {
  return { type: SET_TRANSLATIONS, translations, translationsLoaded: true }
}

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return { ...state, translations: action.translations, translationsLoaded: true }
    default:
      return state
  }
}

export default languageProviderReducer
