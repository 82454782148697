import { IImageSettings } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import useDataRate from '../../../hooks/useDataRate'
import useDataService from '../../../hooks/useDataService'
import { selectImagesMap } from '../../Libraries/Images/image.selectors'
import { matchers } from '../condition.utils'
import css from './image.module.css'

const ImageComponent = (props: Props) => {
  const service = useDataService(props.id, { singleValueMode: true })
  useDataRate(service)

  const { settings } = props
  const [imageId, setImageId] = React.useState(settings.image_id)

  const images = useSelector(selectImagesMap)
  const image = images.get(imageId)

  // запрос всех указанных в настройках параметров и устройств
  React.useEffect(() => {
    if (!settings.dynamic || settings.conditions.length === 0) {
      return service.setDataSelectors([])
    }

    const selectors = []

    for (const { device_id, parameter_id } of props.settings.conditions) {
      const selector = selectors.find((s) => s.device_id === device_id)

      if (selector) selector.parameters.push(parameter_id)
      else selectors.push({ device_id, parameters: [parameter_id] })
    }

    service.setDataSelectors(selectors)
  }, [settings])

  // обработка условий динамических изображений
  React.useEffect(() => {
    if (!settings.dynamic || settings.conditions.length === 0) {
      return (service.onTick = () => null)
    }

    const onTick = () => {
      for (const condition of settings.conditions) {
        const p = service.selectCurrentPoint(condition.device_id)
        if (p == null) continue

        const value = p[condition.parameter_id]
        if (value == null) continue

        const matched = matchers[condition.condition](value, condition.value)

        // найдено выполненное условие - выставляем связанное с ним изображение
        if (matched) {
          return imageId !== condition.image_id && setImageId(condition.image_id)
        }
      }

      // no condition matched, using default image
      if (imageId !== settings.image_id) {
        setImageId(settings.image_id)
      }
    }

    service.onTick = onTick
    onTick()
  }, [settings, imageId])

  const backgroundImage = image ? `url("${image.src_url}")` : undefined

  return <div className={css.card} style={{ backgroundImage }} />
}

interface Props {
  id: number
  lastResize: number
  settings: IImageSettings
}

export default ImageComponent
