export type ChartScale = 1e-6 | 1e-3 | 1 | 1e3 | 1e6 | 1e9

const scalesMap = {
  1e-6: 'μ',
  1e-3: 'm',
  1: '',
  1e3: 'k',
  1e6: 'M',
  1e9: 'G',
}

export const scale2str = (scale: ChartScale) => {
  return scalesMap[scale]
}

export const scales: ChartScale[] = [1e9, 1e6, 1e3, 1, 1e-3, 1e-6]

// determine what scale needs to be applied by maximum chart value
export const max2scale = (max: number): ChartScale => {
  if (!max) return 1

  for (const scale of scales) {
    if (max >= scale) return scale
  }

  return scales[scales.length - 1]
}
