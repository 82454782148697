import DevicesPanel from './DevicesPanel/DevicesPanel'
import PlacePanel from './PlacePanel/PlacePanel'
import { IPlace } from 'au-nsi/shared'
import { Equipment } from 'au-nsi/equipment'

interface IProps {
  devices: Equipment[]
  selectedId: string
  onDeviceSelect: (id: Equipment['id']) => void
  onPlaceSelect: (place: IPlace) => void
  selectedPlace: IPlace
  statusFilter: Record<Equipment['state'], boolean>
  onStatusFilterChange: (settings: Record<Equipment['state'], boolean>) => void
  filterByViewport: boolean
  onFilterByViewportChange: (filter: boolean) => void
  searchPattern?: string
  onCreateDevice: () => void
  showControls: boolean
}

const MapLeftPanel = (props: IProps) => {
  if (props.selectedPlace) {
    return (
      <PlacePanel
        devices={props.devices}
        selectedPlace={props.selectedPlace}
        selectedId={props.selectedId}
        onCreateDevice={props.onCreateDevice}
        showControls={props.showControls}
        onPlaceSelect={props.onPlaceSelect}
      />
    )
  }

  return (
    <DevicesPanel
      devices={props.devices}
      selectedId={props.selectedId}
      searchPattern={props.searchPattern}
      onDeviceSelect={props.onDeviceSelect}
      onPlaceSelect={props.onPlaceSelect}
      selectedPlace={props.selectedPlace}
      statusFilter={props.statusFilter}
      onStatusFilterChange={props.onStatusFilterChange}
      filterByViewport={props.filterByViewport}
      onFilterByViewportChange={props.onFilterByViewportChange}
    />
  )
}

export default MapLeftPanel
