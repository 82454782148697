import { useSetRecoilState } from 'recoil'
import { selectedDraftRequestState } from '../../request.recoil'
import useOrganizationTypeCallbacks from '../../../../../Catalogs/Organizations/components/OrganizationTypeForms/useOrganizationTypeCallbacks'
import OrganizationTypeForm from '../../../../../Catalogs/Organizations/components/OrganizationTypeForms/OrganizationTypeForm/OrganizationTypeForm'
import { useCallback } from 'react'
import { OrganizationTypeRequest } from 'au-nsi/moderation'
import { OrganizationType } from 'au-nsi/organizations'

interface IProps {
  editing: boolean
  organizationType: OrganizationType
  onlyDiffDisplaying: boolean
}

type Setter<T> = ((prevState: T) => T) | T

const OrganizationTypeSchemaRequestForm = ({ editing, organizationType, onlyDiffDisplaying }: IProps) => {
  const setRequest = useSetRecoilState<OrganizationTypeRequest>(selectedDraftRequestState as any)

  const setter = useCallback((p: Setter<OrganizationType>) => {
    if (typeof p === 'function') {
      setRequest((prev) => ({ ...prev, request_body: p(prev.request_body) }))
    } else {
      setRequest((prev) => ({ ...prev, request_body: p }))
    }
  }, [])

  const organizationTypeCallbacks = useOrganizationTypeCallbacks({ setter, deps: [] })

  return (
    <OrganizationTypeForm
      editing={editing}
      organizationType={organizationType}
      onMetaChange={organizationTypeCallbacks.handleMetaChange}
      onChange={organizationTypeCallbacks.handleChange}
      onMove={onlyDiffDisplaying ? () => {} : organizationTypeCallbacks.handleMove}
      onRemove={organizationTypeCallbacks.handleRemoveProperty}
      onCreateProperty={organizationTypeCallbacks.handleCreateProperty}
    />
  )
}

export default OrganizationTypeSchemaRequestForm
