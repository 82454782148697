import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { API_URL } from '../shared/constants'

/**
 * Хук для валидации и вывода подсказки при задании пользовательского пароля
 * в соответствии с настройками заданными админом системы. Нстройки загружаются
 * только при shouldLoad = true, чтобы не загружать их например в режиме просмотра.
 */
const usePasswordRules = (shouldLoad: boolean) => {
  const intl = useIntl()
  const [rules, setRules] = useState(defaultPasswordRules)

  useEffect(() => {
    if (shouldLoad) {
      axios
        .get(API_URL + '/nsi/auth/password_rules')
        .then((r) => setRules(r.data))
        .catch(console.error)
    }
  }, [shouldLoad])

  const validator = (pwd: string) => {
    if (pwd.length < rules.min_length) return false
    if (rules.require_lowercase && !/[a-zа-я]/.test(pwd)) return false
    if (rules.require_uppercase && !/[A-ZА-Я]/.test(pwd)) return false
    if (rules.require_digits && !/\d/.test(pwd)) return false
    if (rules.require_symbols && !/[^a-zа-я0-9]/i.test(pwd)) return false

    return true
  }

  let help = intl.formatMessage({ id: 'passwords.min_length' }, { len: rules.min_length })
  if (rules.require_lowercase) help += '\n' + intl.formatMessage({ id: 'passwords.require_lowercase' })
  if (rules.require_uppercase) help += '\n' + intl.formatMessage({ id: 'passwords.require_uppercase' })
  if (rules.require_digits) help += '\n' + intl.formatMessage({ id: 'passwords.require_digits' })
  if (rules.require_symbols) help += '\n' + intl.formatMessage({ id: 'passwords.require_symbols' })

  return { validator, help }
}

export interface PasswordRules {
  min_length: number
  require_lowercase: boolean
  require_uppercase: boolean
  require_digits: boolean
  require_symbols: boolean
}

export const defaultPasswordRules: PasswordRules = {
  min_length: 4,
  require_lowercase: false,
  require_uppercase: false,
  require_digits: false,
  require_symbols: false,
}

export default usePasswordRules
