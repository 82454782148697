import { Icons } from './typeselect.utils'
import Modal from '../../../../shared/Modal/Modal'
import SearchInput from '../../../../shared/Inputs/Search/SearchInput'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './typeselect.module.css'
import { Slider } from '../../../../shared/Slider/Slider'
import { WidgetType } from '../../dashboard.types'

/**
  Модальное окно для выбора типа создаваемоего графического компонента (линейный график, индикатор и.т.д)
*/
interface IProps {
  types: WidgetType[]
  onSelect: (selected: string) => void
  onClose: () => void
}

export const WidgetTypeSelectModal = ({ types, onSelect, onClose }: IProps) => {
  const intl = useIntl()

  const [searchValue, setSearchValue] = useState('')

  const slideFilter = (d: string) => {
    const dashName = intl.formatMessage({ id: 'dashboards.add.' + d }).toLowerCase()
    return dashName.startsWith(searchValue.toLowerCase())
  }

  const renderSlides = () => {
    const slides = []
    const filteredTypes = searchValue ? types.filter(slideFilter) : types

    for (let i = 0; i < Math.ceil(filteredTypes.length / 16); ++i) {
      const renderDashboards = filteredTypes.slice(i * 16, Math.min((i + 1) * 16, filteredTypes.length))

      slides.push(
        <div key={i + 'dash'} className={styles.typeselectGrid}>
          {renderDashboards.map((d) => {
            const chartName = intl.formatMessage({ id: 'dashboards.add.' + d })

            return (
              <div key={d} onClick={() => onSelect(d)} className={styles.typeselectGridItem}>
                {Icons(d)}
                <p style={{ textAlign: 'center' }}>{chartName}</p>
              </div>
            )
          })}
        </div>
      )
    }

    return slides
  }

  return (
    <Modal size={'lg'} onClose={onClose} closeOnEscape={true}>
      <SearchInput onChange={setSearchValue} />
      <div className={styles.typeselectHr} />
      <div className={styles.typeselectSliderWrapper}>
        <Slider slides={renderSlides()} />
      </div>
    </Modal>
  )
}
