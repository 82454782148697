import http from '../../../utils/http'
import { IUserNotification } from 'au-nsi/notifications'

export const loadUserMessages = async (size = 20, nextPage: string, onlyUnread = false) => {
  let url = `/nsi/v1/notifications?size=${size}`
  if (nextPage) url += `&page=${nextPage}`
  if (onlyUnread) url += `&onlyUnread=true`

  const r = await http.get(url)
  return r.data as { results: IUserNotification[]; nextPage: string }
}

// получить полное кол-во непрочитанных уведомлений
export const loadUnreadCount = async () => {
  const r = await http.get(`/nsi/v1/notifications?onlyUnread=true&includeTotal=true&size=1`)
  return r.data.total as number
}

export const ackNotification = (id: number) => {
  return http.post(`/nsi/v1/notifications/${id}/ack`)
}

export const unackNotification = (id: number) => {
  return http.delete(`/nsi/v1/notifications/${id}/ack`)
}

export const ackAllNotifications = (last_ts: number) => {
  return http.post(`/nsi/v1/notifications/ack?last_ts=` + last_ts)
}
