import { RefObject, useEffect, useState, useCallback } from 'react'

interface IProps {
  ref: RefObject<HTMLElement>
}

export const useMouseMove = ({ ref }: IProps) => {
  const [ev, setEv] = useState(null)
  const [startEv, setStartEv] = useState(null)

  useEffect(() => {
    ref && ref.current && ref.current.addEventListener('mousedown', handleMouseDown)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      removeEventListeners()
    }
  }, [ref])

  const removeEventListeners = useCallback(() => {
    setStartEv(null)
    window.removeEventListener('mousemove', setEv)
    window.removeEventListener('mouseup', removeEventListeners)
  }, [])

  const handleMouseDown = useCallback((ev) => {
    setEv(ev)
    setStartEv(ev)
    window.addEventListener('mousemove', setEv)
    window.addEventListener('mouseup', removeEventListeners)
  }, [])

  return {
    mouseMove: ev,
    stopMove: removeEventListeners,
    startEv,
  }
}
