import React from 'react'
import Nsi from '../../Nsi/Nsi'
import SystemPage from '../../System/System'
import { IRoute } from './nav.utils'
import systemRoutes from './system.routes'
import { UsersPage } from '../../Collections/UsersPage/UsersPage'

const FormulasPage = React.lazy(() => import('../../Formulas/FormulasPage'))
const ParametersPage = React.lazy(() => import('../../Parameters/page/ParametersPage'))
const NotificationsPage = React.lazy(() => import('../../NotificationSettings/NotificationsPage'))
const SignalEventsPage = React.lazy(() => import('../../SignalEvents/SePage'))
const TransmittersPage = React.lazy(() => import('../../Transmitters/page/TransmittersPage'))
const SchedulerPage = React.lazy(() => import('../../Scheduler/SchedulerPage'))
const CommandsPage = React.lazy(() => import('../../Commands/CommandsPage'))

const settingsRoutes: IRoute[] = [
  { id: 'topology', path: 'topology', component: Nsi, rights: ['equipment:get'] },
  {
    id: 'signal-events',
    path: 'signal-events',
    component: SignalEventsPage,
    rights: ['signal_events:get'],
    modules: ['au-signal-events'],
  },
  {
    id: 'notifications',
    path: 'notifications',
    component: NotificationsPage,
    rights: ['users:change_notifications', 'users:change_own_notifications'],
  },
  { id: 'users', path: 'users', component: UsersPage, rights: ['roles:get'] },
  {
    id: 'formulas',
    path: 'formulas',
    component: FormulasPage,
    rights: ['formulas:get', 'formulas:update'],
    modules: ['au-compute'],
  },
  {
    id: 'parameters',
    path: 'parameters',
    component: ParametersPage,
    rights: ['parameters:get'],
  },
  {
    id: 'transmitters',
    path: 'transmitters',
    component: TransmittersPage,
    rights: ['transmitters:get'],
    modules: ['C37.118', 'IEC104'],
  },
  {
    id: 'commands',
    path: 'commands',
    component: CommandsPage,
    rights: ['commands:get', 'commands:update'],
    modules: ['commands'],
  },
  {
    id: 'scheduler',
    path: 'scheduler/*',
    component: SchedulerPage,
    rights: ['scheduler:get', 'scheduler:update'],
    modules: ['au-scheduler'],
  },
  {
    id: 'system',
    path: 'system/*',
    component: SystemPage,
    children: systemRoutes,
    rights: ['settings:get', 'settings:get_modules'],
  },
]

export default settingsRoutes
