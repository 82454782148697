import { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import SOPHUploadModal from './SOPHUploadModal'

/**
 * Кнопка для загрузки csv файла с информацией о часах пиковой нагрузки системного оператора
 */
const SOPHUploadButton = () => {
  const ref = useRef<HTMLInputElement>()
  const intl = useIntl()
  const [file, setFile] = useState<File>()

  const handleClose = () => {
    setFile(null)
    ref.current.value = null
  }

  return (
    <>
      <button type="button" className="system__button is-default" onClick={() => ref.current.click()}>
        {intl.formatMessage({ id: 'system.aeps.upload_soph' })}
      </button>
      <input ref={ref} type="file" accept=".csv" className="nsi-input" onChange={(e) => setFile(e.target.files[0])} />
      {file && <SOPHUploadModal file={file} onClose={handleClose} />}
    </>
  )
}

export default SOPHUploadButton
