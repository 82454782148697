import { IComponentBase, IDashboardComponent } from 'au-nsi/dashboards'
import React from 'react'
import { CardMoveType } from '../dashboard.types'
import { preserveRatio } from './autoalign.utils'

// l - left, r - right, t - top, b - bottom
const factors = {
  lt: { x: 1, y: 1, w: -1, h: -1 },
  lb: { x: 1, y: 0, w: -1, h: 1 },
  rt: { x: 0, y: 1, w: 1, h: -1 },
  rb: { x: 0, y: 0, w: 1, h: 1 },
}

const minHeights = {
  button: 30,
  gauge: 120,
  gauge_linear: 30,
  linear_chart: 120,
  text: 30,
  vector_chart: 180,
  windrose: 100,
}

const minWidths = {
  button: 30,
  gantt: 360,
  gauge: 120,
  linear_chart: 160,
  text: 30,
  vector_chart: 180,
  windrose: 80,
}

/**
 * Элементы управления для перетаскивания и изменения размера элементов экрана
 */
const CardPositionControls = (props: Props) => {
  const c = props.component
  const vw = props.viewport

  const handleDrag = (onMove, onFinish?) => {
    function cleanup(e) {
      window.removeEventListener('mousemove', onMove)
      window.removeEventListener('mouseup', cleanup)

      if (onFinish) onFinish(e)
      props.onMoveFinish()
    }

    window.addEventListener('mousemove', onMove)
    window.addEventListener('mouseup', cleanup)
  }

  // при нажатии на верхнюю часть панели перейти в режим перетаскивания
  const handleMove = (event) => {
    const card = props.cardRef.current
    const [x, y] = parseTranslate(card.style.transform)
    const width = parseFloat(card.style.width)
    const x0 = event.pageX
    const y0 = event.pageY

    handleDrag((e) => {
      let x1 = x + e.pageX - x0
      let y1 = y + e.pageY - y0

      if (x1 < 0) x1 = 0
      if (y1 < 0) y1 = 0
      if (x1 > vw - width) x1 = vw - width

      card.style.transform = `translate(${x1}px, ${y1}px)`
      props.onMove({ ...c, x: x1 / vw, y: y1 / vw }, 'move')
    })
  }

  // при нажатии на углы панели перейти в режим изменения размеров
  const handleResize = (event) => {
    const card = props.cardRef.current
    const factor = factors[event.currentTarget.dataset.id]

    const minHeight = minHeights[c.type] || 50
    const minWidth = minWidths[c.type] || 50

    // начальные размеры в момент нажатия
    const [x0, y0] = parseTranslate(card.style.transform)
    const w0 = parseFloat(card.style.width)
    const h0 = parseFloat(card.style.height)
    const startX = event.pageX
    const startY = event.pageY

    // при движении мыши рассчитать новые размеры
    const calcPosition = (e) => {
      let dx = e.pageX - startX
      let dy = e.pageY - startY

      // запрещаем делать элемент меньше указанных минимальных размеров
      if (w0 + factor.w * dx < minWidth) dx = -(w0 - minWidth) * factor.w
      if (h0 + factor.h * dy < minHeight) dy = -(h0 - minHeight) * factor.h

      // запрет на выход элемента за границы экрана
      if (x0 + factor.x * dx < 0) dx = -x0 * factor.x
      if (y0 + factor.y * dy < 0) dy = -y0 * factor.y
      if (x0 + factor.x * dx + w0 + factor.w * dx > vw) dx = vw - x0 - w0

      if (preserveRatio[c.type]) {
        dx = Math.abs(Math.max(dx, dy)) * Math.sign(dx)
        dy = dx * (h0 / w0) * factor.w * factor.h
      }

      const x = x0 + factor.x * dx
      const y = y0 + factor.y * dy
      const w = w0 + factor.w * dx
      const h = h0 + factor.h * dy

      props.onMove({ ...c, x: x / vw, y: y / vw, w: w / vw, h: h / vw }, 'resize')
      return { x, y, w, h }
    }

    const onMove = (e) => {
      const { x, y, w, h } = calcPosition(e)
      card.style.transform = `translate(${x}px, ${y}px) scale(${w / w0}, ${h / h0})`
    }

    const onFinish = (e) => {
      const { x, y, w, h } = calcPosition(e)

      card.style.transform = `translate(${x}px, ${y}px)`
      card.style.width = w + 'px'
      card.style.height = h + 'px'
      props.onResize()
    }

    handleDrag(onMove, onFinish)
  }

  // задание позиции элемента
  React.useEffect(() => {
    const card = props.cardRef.current

    // специальный компонент, который всегда должен находиться поверх всех других
    const isFixedLayer = c.type === 'template_variables'

    card.style.zIndex = isFixedLayer ? '998' : c.z + ''
    card.style.width = Math.round(vw * c.w) + 'px'
    card.style.height = Math.round(vw * c.h) + 'px'
    card.style.transform = `translate(${Math.round(vw * c.x)}px, ${Math.round(vw * c.y)}px)`
    props.onResize()
  }, [vw, c.x, c.y, c.w, c.h, c.z])

  if (!props.allowEditing) return null

  return (
    <React.Fragment>
      <div className="dashboard-card__controls is-top" onMouseDown={handleMove} />
      <div data-id="rb" className="dashboard-card__controls is-rb" onMouseDown={handleResize} />
      <div data-id="lb" className="dashboard-card__controls is-lb" onMouseDown={handleResize} />
      <div data-id="rt" className="dashboard-card__controls is-rt" onMouseDown={handleResize} />
      <div data-id="lt" className="dashboard-card__controls is-lt" onMouseDown={handleResize} />
    </React.Fragment>
  )
}

// из определения стиля 'translate(1px, 2px)' извлечь значения x и y
const parseTranslate = (translate: string) => {
  const xStart = translate.indexOf('(') + 1
  const xEnd = translate.indexOf('px')

  const yStart = translate.indexOf(', ') + 2
  const yEnd = translate.indexOf('px)')

  const x = +translate.slice(xStart, xEnd) || 0
  const y = +translate.slice(yStart, yEnd) || 0

  return [x, y]
}

interface Props {
  allowEditing: boolean
  cardRef: React.RefObject<HTMLDivElement>
  component: IDashboardComponent
  onMove: (position: IComponentBase, type: CardMoveType) => void
  onMoveFinish: () => void
  onResize: () => void
  viewport: number
}

export default CardPositionControls
