import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectAccessRights, selectEnabledModules } from '../../App/app.selectors'
import { DenormalizedIncident } from '../../Incidents/incident.interfaces'
import Alerts from '../Alerts/Alerts'
import SystemMessages from '../Messages/SystemMessages'
import UserNotifications from '../UserNotifications/UserNotifications'
import css from './ntf.module.css'
import NtfIcon from './NtfIcon'

interface Props {
  tab: string
  onTabChange: (tab: string) => void
  onClose: () => void
  incidents: DenormalizedIncident[]
  unseenNotifications: number
}

/**
 * Выпадающее меню со списком уведомлений разбитых на табы по типу
 */
const NtfPanel = (props: Props) => {
  const { tab, onClose } = props

  const intl = useIntl()
  const modules = useSelector(selectEnabledModules)
  const rights = useSelector(selectAccessRights)

  const showSystem = rights['settings:get_messages']
  const showIncidents = modules.has('au-signal-events') && rights['incidents:get']

  const tabs = ['user']
  if (showSystem) tabs.push('system')
  if (showIncidents) tabs.push('incidents')

  const header = tabs.map((id) => {
    const className = classnames(css.tab, { [css.selectedTab]: id === tab })

    return (
      <div key={id} className={className} onClick={() => props.onTabChange(id)}>
        <NtfIcon icon={id as any} count={0} size={14} />
        <span style={{ marginLeft: 6 }}>{intl.formatMessage({ id: 'nav.notifications.' + id })}</span>
      </div>
    )
  })

  return (
    <div className="alerts__list">
      {tabs.length > 1 && <div className={css.header}>{header}</div>}

      {tab === 'user' && <UserNotifications unseenNotifications={props.unseenNotifications} />}
      {tab === 'system' && <SystemMessages onClose={onClose} />}
      {tab === 'incidents' && <Alerts incidents={props.incidents} onClose={onClose} />}
    </div>
  )
}

export default NtfPanel
