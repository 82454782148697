import { useState, useRef, useEffect } from 'react'
import { confirmValue, replaceAt, insert } from './utils'

const emptySymbol = '_'

interface IProps {
  mask: (string | RegExp)[]
  className: string
  value: string
  onChange: any
  onClick: any
  disabled?: boolean
  preprocess?: (input: string) => string
}

export const MaskedInput = ({ mask, className, value, onChange, onClick, disabled, preprocess }: IProps) => {
  const inputRef = useRef(null)

  const [cursorUpdated, setCursorUpdated] = useState(false)
  const [inputValue, setInputValue] = useState(fitValueIntoMask(value, mask, emptySymbol))
  const [cursorIndex, setCursorIndex] = useState(value.length)

  useEffect(() => {
    setInputValue(fitValueIntoMask(value, mask, emptySymbol))
  }, [value])

  useEffect(() => {
    inputRef.current.setSelectionRange(cursorIndex, cursorIndex)
  }, [cursorIndex, cursorUpdated])

  const handleChange = (ev) => {
    let newValue: string

    if (ev.target.value.length === inputValue.length) {
      // Handling paste
      newValue = ev.target.value
    } else {
      // Handling typing
      newValue = ev.target.value.length < inputValue.length ? handleDelete(ev) : handleAdd(ev)
    }

    if (confirmValue(newValue, mask, emptySymbol)) {
      if (preprocess) newValue = preprocess(newValue)

      setInputValue(newValue)
      ev.target.value = newValue
      onChange(ev)
    }
    setCursorUpdated(!cursorUpdated)
  }

  const handleDelete = (ev) => {
    const deletedPosition = ev.target.selectionStart
    setCursorIndex(deletedPosition)
    return insert(ev.target.value, deletedPosition, emptySymbol)
  }

  const handleAdd = (ev) => {
    const addPosition = ev.target.selectionStart
    const newValue = ev.target.value

    const addingSymbol = newValue[addPosition - 1]
    let emptyFieldInd = inputValue.slice(addPosition - 1).indexOf(emptySymbol)

    if (emptyFieldInd !== -1) {
      emptyFieldInd += addPosition - 1
      setCursorIndex(emptyFieldInd + 1)
      return replaceAt(inputValue, emptyFieldInd, addingSymbol)
    } else {
      setCursorIndex(ev.target.selectionStart - 1)
      return inputValue
    }
  }

  return (
    <input
      ref={inputRef}
      onClick={onClick}
      className={className}
      value={inputValue}
      onChange={handleChange}
      disabled={disabled}
    />
  )
}

const fitValueIntoMask = (value: string, mask: (string | RegExp)[], emptySymbol) => {
  let resultingString = ''

  for (let i = 0; i < mask.length; ++i) {
    if (typeof mask[i] === 'string') resultingString += mask[i]
    else if ((mask[i] as RegExp).test(value[i])) resultingString += value[i]
    else resultingString += emptySymbol
  }

  return resultingString
}
