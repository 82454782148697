import React from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * Компонент для обработки непредвиденных и не перехваченных ошибок.
 * Выводит сообщение ошибки с предложением перезагрузить страницу или вернуться на главную
 */
class ErrorBoundary extends React.Component<any, State> {
  state: State = { hasError: false, errorMsg: '' }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMsg: error.message }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
  }

  private reloadPage = () => location.reload() // eslint-disable-line
  private movetoMain = () => location.replace('/') // eslint-disable-line

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <div className="app-error-boundary">
        <h1>
          <FormattedMessage id="Notifications.uncaught_error" />
        </h1>
        <div className="app-error-boundary__message">{this.state.errorMsg}</div>
        <div>
          <button className="nsi-button default" onClick={this.reloadPage}>
            <FormattedMessage id="Notifications.reload_page" />
          </button>
          <button className="nsi-button default" onClick={this.movetoMain}>
            <FormattedMessage id="Notifications.reload_home" />
          </button>
        </div>
      </div>
    )
  }
}

interface State {
  hasError: boolean
  errorMsg: string
}

export default ErrorBoundary
