import { IAccessSettings } from 'au-nsi/shared'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import AccessForm from '../../../../shared/Access/AccessForm'
import Form from '../../../../shared/Forms/Form'
import InputRow from '../../../../shared/Inputs/InputRow'
import TextInput from '../../../../shared/Inputs/TextInput'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import http from '../../../../utils/http'
import { actions } from '../docs.reducers'

/**
 * Модальное окно создания новой папки
 */
const CreateFolderModal = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { loading, wait } = useHttpLoader()

  const [folder, setFolder] = useState<{ name: string; parent_id: string; access: IAccessSettings }>({
    name: '',
    parent_id: props.parent_id,
    access: { restricted: false, orgs: [], roles: [], users: [] },
  })

  const handleChange = (value, key: string) => setFolder({ ...folder, [key]: value })

  const handleSave = () => {
    if (folder.name) {
      const p = http.post('/nsi/v1/documents/folders', {
        ...folder,
        resource: props.resource,
        resource_item_id: props.resource_item_id,
      })

      wait(p, (r) => {
        dispatch(actions.documentCreated(r.data))
        props.onClose()
      })
    }
  }

  return (
    <Modal size="lg" onClose={props.onClose}>
      <h2>{intl.formatMessage({ id: 'documents.create_folder' })}</h2>

      <Form editing={true} onCancel={props.onClose} onSubmit={handleSave}>
        <InputRow label={intl.formatMessage({ id: 'common.name' })}>
          <TextInput name="name" value={folder.name} onChange={handleChange} autofocus={true} />
        </InputRow>

        <AccessForm access={folder.access} onChange={(access) => handleChange(access, 'access')} />

        <ModalFooter loading={loading} onCancel={props.onClose} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

interface Props {
  onClose: () => void
  parent_id?: string
  resource?: string
  resource_item_id?: string
}

export default CreateFolderModal
