import { useEffect, useState } from 'react'
import { TTheme } from '../../User/Profile/ColorTheme'

const mediaQuery = '(prefers-color-scheme: light)'

/** Хук для получения системной темы пользователя */
const usePrefersTheme = () => {
  const [theme, setTheme] = useState<TTheme>(window.matchMedia(mediaQuery).matches ? 'white' : 'dark-blue')

  useEffect(() => {
    return window.matchMedia(mediaQuery).addEventListener('change', (e) => {
      if (e.matches) setTheme('white')
      else if (theme !== 'dark-blue') setTheme('dark-blue')
    })
  }, [])

  return theme
}

export default usePrefersTheme
