import { Equipment } from 'au-nsi/equipment'
import produce from 'immer'
import React from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import AEControllerIdInput from '../../../shared/AE/ControllerIdInput/AEControllerIdInput'
import Panel from '../../../shared/FormPanel/FormPanel'
import Form from '../../../shared/Forms/Form'
import { InputOptions, renderInput } from '../../../shared/Forms/forms.utils'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import TextInput from '../../../shared/Inputs/TextInput'
import './connection.styles.css'
import FallbacksList from './FallbacksList'
import OPCDATags from './OPCDATags'

const formatHost = (v: string) => v.replace(/\s/g, '')

const config2array = (configuration) => {
  const fallbacks = configuration.fallbacks || []
  return [{ ...configuration, fallbacks: undefined }, ...fallbacks].map((item, _index) => ({ ...item, _index }))
}

const array2config = (configs) => {
  const primary = configs[0]
  const fallbacks = configs.slice(1)
  return { ...primary, fallbacks }
}

/**
 * Форма с настройками подключения ресивера к устройству. В зависимости от протокола
 * устройства выводятся соответствующие этому протоколу поля.
 */
class ConnectionPanel extends React.Component<Props, State> {
  state: State = {
    editing: false,
    selectedIndex: 0,
    configuration: config2array(this.props.device.configuration),
  }

  private C37ModeOptions = [
    { label: 'TCP_ONLY', value: 'TCP_ONLY' },
    { label: 'UDP_ONLY', value: 'UDP_ONLY' },
    { label: 'TCP_UDP', value: 'TCP_UDP' },
  ]

  private cfgFrameOptions = [
    { label: 'CFG_2', value: 2 },
    // { label: 'CFG_3', value: 3 },
  ]

  private boolOptions = [
    { label: this.props.intl.formatMessage({ id: 'common.yes' }), value: true },
    { label: this.props.intl.formatMessage({ id: 'common.no' }), value: false },
  ]

  private byteOrderOptions = [
    { value: 'be', label: this.props.intl.formatMessage({ id: 'nsi.connection.byte_order.be' }) },
    { value: 'le', label: this.props.intl.formatMessage({ id: 'nsi.connection.byte_order.le' }) },
  ]

  private workModeOptions = [
    { value: 'cyclic', label: this.props.intl.formatMessage({ id: 'nsi.connection.iec104_work_mode_cyclic' }) },
    {
      value: 'interrogation',
      label: this.props.intl.formatMessage({ id: 'nsi.connection.iec104_work_mode_interrogation' }),
    },
  ]

  private dbDriverOptions = [
    { value: 'SQL Server', label: 'SQL Server' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'Oracle', label: 'Oracle' },
  ]

  private timeFormatOptions = [
    { value: 'timestamp', label: 'TIMESTAMP' },
    { value: 'ISO 8601', label: 'ISO 8601 (string)' },
    { value: 's', label: 's (number)' },
    { value: 'ms', label: 'ms (number)' },
    { value: 'μs', label: 'μs (number)' },
    { value: 'ns', label: 'ns (number)' },
  ]

  // описание полей формы для каждого протокола
  private inputs: { [protocol: string]: InputOptions[] } = {
    'C.37.118': [
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'number', key: 'idcode', integer: true, fullWidth: true },
      { type: 'select', key: 'connection_type', options: this.C37ModeOptions },
      { type: 'select', key: 'cfg_frame_version', options: this.cfgFrameOptions },
      { type: 'number', key: 'reconnect_interval', integer: true, fullWidth: true },
      { type: 'number', key: 'reconnect_attempts', integer: true, fullWidth: true },
      { type: 'number', key: 'connection_timeout', integer: true, fullWidth: true, min: 1 },
      { type: 'select', key: 'drop_frame_with_old_timestamp', options: this.boolOptions },
      { type: 'number', key: 'drop_timeout', integer: true, fullWidth: true },
    ],
    'modbustcp-server': [
      { type: 'number', key: 'tcp_port', integer: true, fullWidth: true },
      { type: 'number', key: 'tcp_timeout', integer: true, fullWidth: true },
      { type: 'number', key: 'device_id', integer: true, fullWidth: true },
      { type: 'select', key: 'byte_order', options: this.byteOrderOptions },
    ],
    'modbustcp-client': [
      { type: 'text', key: 'host', formatter: formatHost },
      { type: 'number', key: 'port', integer: true, fullWidth: true },
      { type: 'number', key: 'request_interval', integer: true, fullWidth: true, min: 1000 },
      { type: 'number', key: 'timeout', integer: true, fullWidth: true },
      { type: 'number', key: 'unit_identifier', integer: true, fullWidth: true },
      { type: 'select', key: 'byte_order', options: this.byteOrderOptions },
      { type: 'select', key: 'register_order', options: this.byteOrderOptions },
      { type: 'number', key: 'reconnect_interval', integer: true, fullWidth: true },
      { type: 'number', key: 'request_attempts', integer: true, fullWidth: true },
    ],
    IEC104: [
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'number', key: 'reconnect_interval', integer: true, fullWidth: true },
      { type: 'number', key: 'reconnect_attempts', integer: true, fullWidth: true },
      { type: 'number', key: 'connection_timeout', integer: true, fullWidth: true },
      { type: 'select', key: 'drop_frame_with_old_timestamp', options: this.boolOptions },
      { type: 'number', key: 'drop_timeout', integer: true, fullWidth: true },
      { type: 'select', key: 'work_mode', options: this.workModeOptions },
      { type: 'number', key: 'request_interval', integer: true, fullWidth: true },
    ],
    MLP14: [
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'number', key: 'reconnect_interval', integer: true, fullWidth: true },
      { type: 'number', key: 'reconnect_attempts', integer: true, fullWidth: true },
      { type: 'number', key: 'connection_timeout', integer: true, fullWidth: true },
    ],
    'db-client': [
      { type: 'select', key: 'driver', options: this.dbDriverOptions },
      { type: 'text', key: 'data_schema' },
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'text', key: 'username' },
      { type: 'password', key: 'password', autocomplete: 'new-password' },
      { type: 'text', key: 'database' },
      { type: 'number', key: 'request_interval', integer: true, fullWidth: true },
      { type: 'text', key: 'table' },
      { type: 'text', key: 'ts_column' },
      { type: 'select', key: 'ts_format', options: this.timeFormatOptions },
      { type: 'number', key: 'ts_range', integer: true, fullWidth: true },
    ],
    'LP001-Server': [
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'number', key: 'connection_timeout', integer: true, fullWidth: true },
      { type: 'number', key: 'replicas', integer: true, fullWidth: true, min: 1, max: 10 },
    ],
    LP001: [{ type: 'text', key: 'controller_id' }],
    OPCDA: [
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
    ],
    OPCDA_VIRTUAL: [
      { type: 'text', key: 'prog_id' },
      { type: 'text', key: 'nodes' },
      { type: 'number', key: 'polling_period_ms', integer: true, fullWidth: true },
      { type: 'text', key: 'tags' },
    ],
    MODBUS_VIRTUAL: [
      { type: 'number', key: 'request_interval', integer: true, fullWidth: true, min: 1000 },
      { type: 'number', key: 'unit_identifier', integer: true, fullWidth: true },
      { type: 'select', key: 'byte_order', options: this.byteOrderOptions },
      { type: 'select', key: 'register_order', options: this.byteOrderOptions },
      { type: 'number', key: 'request_attempts', integer: true, fullWidth: true },
    ],
    'MQTT-Ob': [
      { type: 'text', key: 'target_host', formatter: formatHost },
      { type: 'number', key: 'target_port', integer: true, fullWidth: true },
      { type: 'text', key: 'username' },
      { type: 'password', key: 'password', autocomplete: 'new-password' },
      { type: 'number', key: 'timeout', integer: true, fullWidth: true },
    ],
    MQTTOB_VIRTUAL: [{ type: 'text', key: 'asset_id' }],
  }

  componentDidUpdate(prevProps: Props) {
    const newConfig = this.props.device.configuration

    if (prevProps.device.configuration !== newConfig && !this.state.editing) {
      this.setState({ configuration: config2array(newConfig) })
    }

    if (prevProps.device.id !== this.props.device.id) {
      this.setState({ editing: false, selectedIndex: 0, configuration: config2array(newConfig) })
    }

    if (this.state.selectedIndex >= this.state.configuration.length) {
      this.setState({ selectedIndex: this.state.configuration.length - 1 })
    }
  }

  private handleChange = (value: any, key: string) => {
    const configuration = produce(this.state.configuration, (draft) => {
      draft[this.state.selectedIndex][key] = value
    })

    this.setState({ configuration })
  }

  private handleSubjectChange = (value: string) => {
    const configuration = produce(this.state.configuration, (draft) => {
      draft[0].publish_subject = value
    })

    this.setState({ configuration })
  }

  private startEditing = () => {
    this.setState({ editing: true })
  }

  private applyChanges = () => {
    const configuration = array2config(this.state.configuration)
    this.props.onChange(configuration)
    this.setState({ editing: false })
  }

  private cancelChanges = () => {
    this.setState({ editing: false, configuration: config2array(this.props.device.configuration) })
  }

  // добавить еще один резервный источник данных, копировав настройки подключения из основного
  private appendConfiguration = () => {
    const { configuration } = this.state
    const selectedIndex = configuration.length

    const copy = { ...configuration[0], _index: selectedIndex }
    for (const item of configuration) {
      copy._index = Math.max(copy._index, item._index + 1)
    }

    this.setState({ selectedIndex, configuration: [...configuration, copy] })
  }

  // удалить указанный источник данных
  private removeConfiguration = (index: number) => {
    const configuration = this.state.configuration.filter((_, i) => i !== index)
    const selectedIndex = Math.min(this.state.selectedIndex, configuration.length - 1)

    this.setState({ selectedIndex, configuration })
  }

  // показать конфигурацию подключения к указанному источнику данных
  private selectConfiguration = (index: number) => {
    this.setState({ selectedIndex: index })
  }

  // поменять два источника данных местами
  private swapConfigurations = (sourceIndex: number, targetIndex: number) => {
    const configuration = this.state.configuration.slice()
    const source = configuration[sourceIndex]
    const target = configuration[targetIndex]
    configuration[sourceIndex] = target
    configuration[targetIndex] = source

    this.setState({ configuration, selectedIndex: targetIndex })
  }

  private renderInput(input: InputOptions, config: Record<string, any>) {
    const { protocol } = this.props.device
    const { editing } = this.state

    switch (true) {
      case protocol === 'LP001' && input.key === 'controller_id':
        return (
          <AEControllerIdInput
            name={'controller_id'}
            disabled={!editing}
            value={config.controller_id}
            onChange={this.handleChange}
          />
        )
      case protocol === 'OPCDA' && input.key === 'nodes':
        return (
          <TextInput
            name={input.key}
            value={config.nodes.join(',')}
            disabled={!editing}
            onChange={(value, key) => this.handleChange(value.split(','), key)}
          />
        )
      case protocol === 'OPCDA' && input.key === 'tags':
        return <OPCDATags tags={config.tags} editing={editing} onChange={this.handleChange} />
      default:
        return renderInput({ data: config, input, editing, onChange: this.handleChange })
    }
  }

  private renderForm(inputs: InputOptions[], config: Record<string, any>) {
    const { translations } = this.props
    const { protocol } = this.props.device
    const { editing, selectedIndex, configuration } = this.state
    const isVirtual = !!this.props.device.path

    if (protocol === 'OPCDA' && isVirtual) {
      inputs = this.inputs.OPCDA_VIRTUAL
    }

    if (protocol === 'modbustcp-client' && isVirtual) {
      inputs = this.inputs.MODBUS_VIRTUAL
    }

    if (protocol === 'modbustcp-client' && this.props.isComposite) {
      inputs = [...inputs, { type: 'select', key: 'publish_with_root_id', options: this.boolOptions }]
    }

    if (protocol === 'MQTT-Ob' && isVirtual) {
      inputs = this.inputs.MQTTOB_VIRTUAL
    }

    const rows = inputs.map((row) => {
      return (
        <tr key={row.key}>
          <td>{translations['equipment.configuration.' + row.key]}</td>
          <td>{this.renderInput(row, config)}</td>
        </tr>
      )
    })

    // общая настройка для всех протоколов - в какую очередь отправлять полученные сообщения
    const subject = configuration[0].publish_subject ?? ''
    if (!isVirtual) {
      rows.push(
        <tr key="publish_subject">
          <td>{translations['equipment.configuration.publish_subject']}</td>
          <td>
            <TextInput name="publish_subject" value={subject} onChange={this.handleSubjectChange} disabled={!editing} />
          </td>
        </tr>
      )
    }

    return (
      <table className="nsi-table nsi-connection-table" style={{ tableLayout: 'fixed' }}>
        <tbody key={selectedIndex}>
          <tr>
            <td>{this.props.translations['equipment.protocol']}</td>
            <td>
              <FormattedMessage id={'nsi.protocols.' + protocol} />
            </td>
          </tr>
          {rows}
        </tbody>
      </table>
    )
  }

  render() {
    const { props } = this
    const { protocol, active_configuration, state } = props.device
    const { editing, configuration, selectedIndex } = this.state

    let inputs = this.inputs[protocol]

    // в форме показываем конфигурацию подключения только к выбранному источнику данных
    const config = configuration[selectedIndex]
    if (!inputs || !config) return null

    // в режиме TCP_UDP или UDP_ONLY показываем дополнительную настройку порта приема данных по UDP
    if (protocol === 'C.37.118' && config.connection_type === 'TCP_UDP') {
      inputs = [...inputs]
      inputs.splice(2, 0, { type: 'number', key: 'listen_port', integer: true, fullWidth: true, min: 1024 })
    }

    // для каких ресиверов реализовано подключение к нескольким источникам
    const showFallbacks = protocol === 'C.37.118' || protocol === 'IEC104' || protocol === 'db-client'
    const activeIndex = state === 'RUNNING' ? active_configuration : -1

    return (
      <Panel id="connection_config" title="nsi.connection_config" open={props.isOpen} onToggle={props.onToggle}>
        {showFallbacks && (
          <FallbacksList
            active={activeIndex}
            editing={editing}
            items={configuration}
            onAppend={this.appendConfiguration}
            onRemove={this.removeConfiguration}
            onSelect={this.selectConfiguration}
            onSwap={this.swapConfigurations}
            selected={selectedIndex}
          />
        )}

        <Form editing={editing} onCancel={this.cancelChanges} onSubmit={this.applyChanges}>
          {this.renderForm(inputs, config)}

          <PanelButtons
            editing={this.state.editing}
            allowEditing={this.props.allowConfiguring}
            onEdit={this.startEditing}
            onCancel={this.cancelChanges}
            onSave={this.applyChanges}
          />
        </Form>
      </Panel>
    )
  }
}

interface Props {
  device: Equipment
  allowConfiguring: boolean
  translations: { [x: string]: string }
  isOpen: boolean
  isComposite: boolean
  onToggle: (name: string) => void
  onChange: (config: any) => void
  dispatch: (action: any) => void
  intl: IntlShape
}

interface State {
  editing: boolean
  selectedIndex: number
  configuration: Array<Record<string, any>>
}

export default connect()(injectIntl(ConnectionPanel))
