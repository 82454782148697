import { IVectorChartSettings } from 'au-nsi/dashboards'
import systemColorsService from '../../pages/App/Theme/systemColorsService'

const padding = 25

const angles = [0, 60, 120, 180, 240, 300]
const zeroRadius = 8

/**
 * Draw static vector graph background
 */
export const drawBackground = (options: DrawBackgroundOptions) => {
  const { ctx, size } = options

  const systemColors = systemColorsService.getColors()
  const colors = {
    background: systemColors['--bg-550'],
    black: systemColors['--gray-90'],
    gray: systemColors['--gray-60'],
    text: systemColors['--color'],
  }

  if (size <= 2 * padding) {
    return
  }

  const center = Math.floor(size / 2)
  const R = center - padding

  ctx.clearRect(0, 0, size, size)
  ctx.lineWidth = 2

  // draw title
  if (options.title) {
    ctx.fillStyle = colors.text
    ctx.textAlign = 'start'
    ctx.textBaseline = 'top'
    ctx.font = 'bold 14px Roboto'
    ctx.fillText(options.title, 6, 6)
  }

  fillCircle(ctx, center, R, colors.background)
  fillCircle(ctx, center, zeroRadius, colors.black)

  // draw angles
  for (const angle of angles) {
    const radians = -(Math.PI * angle) / 180
    let x = center + R * Math.sin(radians)
    let y = center - R * Math.cos(radians)
    let textAlign = 'center'
    let textBaseline = 'middle'

    if (x < center - 1) {
      textAlign = 'right'
      x -= 8
    } else if (x > center + 1) {
      textAlign = 'left'
      x += 8
    }

    if (y < center - 1) {
      textBaseline = 'bottom'
      y -= 8
    } else if (y > center + 1) {
      textBaseline = 'top'
      y += 8
    }

    ctx.fillStyle = colors.text
    ctx.font = '12px Roboto'
    ctx.textAlign = textAlign as any
    ctx.textBaseline = textBaseline as any

    drawLine(ctx, center, center, x, y, colors.black)
    ctx.fillText(angle + '°', x, y)
  }

  // draw grid
  for (const line of calculateGrid(R, options.nominalValue)) {
    strokeCircle(ctx, center, line.radius, colors.gray)

    ctx.font = '12px Roboto'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = colors.text
    ctx.fillText(line.value + '', center, center - line.radius + 2)
  }
}

/**
 * Draw vector with specified angle and value
 */
export const drawVector = (options: DrawVectorOptions) => {
  const { ctx, size, angle, value, nominalValue, color, style } = options

  if (size <= 2 * padding) {
    return
  }

  const center = Math.floor(size / 2)
  const R = center - padding
  const r = zeroRadius + ((R - zeroRadius) * value) / nominalValue
  const sin = Math.sin(-angle)
  const cos = Math.cos(-angle)

  const x0 = center + zeroRadius * sin
  const y0 = center - zeroRadius * cos

  const x1 = center + r * sin
  const y1 = center - r * cos

  const x2 = center + R * sin
  const y2 = center - R * cos

  ctx.lineWidth = 3
  drawLine(ctx, x0, y0, x1, y1, color)

  if (style === '2') {
    // продолжение полупрозрачного вектора до внешней границы
    ctx.lineWidth = 3
    drawLine(ctx, x0, y0, x2, y2, options.colorMuted)
  } else if (style === '3') {
    // продолжение рамки до внешней границы
    ctx.lineWidth = 1
    ctx.save()
    ctx.translate(center, center)
    ctx.rotate(-angle - Math.PI / 2)
    ctx.strokeRect(zeroRadius, -2, R - zeroRadius, 4)
    ctx.restore()
  }
}

/**
 * Draw tooltip over vector with its name, angle and magnitude
 */
export const drawTooltip = (options: DrawTooltipOptions) => {
  const { ctx, size, angle, name, value } = options

  const degrees = (180 * angle) / Math.PI
  const angleStr = ((degrees + 360) % 360).toFixed() + '°'

  const center = Math.floor(size / 2)
  const R = center - padding

  let x = center + R * Math.sin(-angle)
  let y = center - R * Math.cos(-angle)

  ctx.font = '14px Roboto'
  ctx.textBaseline = 'top'
  ctx.textAlign = 'left'

  const textWidth = Math.max(ctx.measureText(name).width, ctx.measureText(value).width, ctx.measureText(angleStr).width)
  const h = 4 * 6 + 3 * 14
  const w = textWidth + 12

  // position tooltip towards center
  if (Math.abs(x - center) < w / 2) x = center - w / 2
  else if (x > center) x -= w

  if (Math.abs(y - center) < w / 2) y = center - h / 2
  else if (y > center) y -= h

  ctx.fillStyle = 'rgba(0, 0, 0, 0.6)'
  ctx.fillRect(x, y, w, h)

  ctx.fillStyle = options.color
  ctx.fillText(name, x + 6, y + 6)

  ctx.fillStyle = '#FFF'
  ctx.fillText(value, x + 6, y + 26)
  ctx.fillText(angleStr, x + 6, y + 46)
}

/**
 * Calculate angle for vector going from center to current mouse position
 */
export const calculateMouseAngle = (x: number, y: number, size: number): number => {
  const center = size / 2
  const R = center - padding

  const side1 = x - center
  const side2 = center - y

  if (side1 * side1 + side2 * side2 > R * R) {
    return null
  }

  const angle = Math.atan(side1 / side2)
  return y < center ? angle : angle + Math.PI
}

const drawLine = (ctx: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, color: string) => {
  ctx.strokeStyle = color
  ctx.beginPath()
  ctx.moveTo(x0, y0)
  ctx.lineTo(x1, y1)
  ctx.stroke()
}

const fillCircle = (ctx: CanvasRenderingContext2D, center: number, radius: number, color: string) => {
  ctx.fillStyle = color
  ctx.beginPath()
  ctx.arc(center, center, radius, 0, 2 * Math.PI)
  ctx.fill()
}

const strokeCircle = (ctx: CanvasRenderingContext2D, center: number, radius: number, color: string) => {
  ctx.strokeStyle = color
  ctx.setLineDash([4, 4])

  ctx.beginPath()
  ctx.arc(center, center, radius, 0, 2 * Math.PI)
  ctx.stroke()

  ctx.setLineDash([])
}

/**
 * Calculate position and values for grid lines
 */
const calculateGrid = (radius: number, maxValue: number) => {
  let lines = 10

  while (lines > 1 && (radius / lines < 36 || !Number.isInteger(maxValue / lines))) {
    lines -= 1
  }

  const result: Array<{ value: number; radius: number }> = []
  const valueStep = maxValue / lines
  const radiusStep = radius / lines

  for (let i = 1; i <= lines; i++) {
    result.push({ value: valueStep * i, radius: radiusStep * i })
  }

  return result
}

interface DrawBackgroundOptions {
  ctx: CanvasRenderingContext2D
  size: number
  title: string
  nominalValue: number
}

interface DrawVectorOptions {
  ctx: CanvasRenderingContext2D
  size: number
  nominalValue: number
  value: number
  angle: number
  color: string
  colorMuted: string
  style: IVectorChartSettings['vector_style']
}

interface DrawTooltipOptions {
  ctx: CanvasRenderingContext2D
  size: number
  angle: number
  name: string
  value: string
  color: string
}
