import produce from 'immer'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/store.types'
import Dropdown from '../../../../../shared/Inputs/Dropdown'
import NumberInput from '../../../../../shared/Inputs/NumberInput'
import {
  verificationOptionsTableHeader,
  compareOptions as compareOpt,
  verificationDangerLevels,
} from './verification.options'
import { SiUnit } from 'au-nsi/parameters'
import { validateOptions } from './options.utls'
import { VerificatonOption } from 'au-nsi/settings'

const percent = {
  id: 'percent',
  base_unit: 'fraction',
  base_unit_factor: 0.01,
  symbol: '%',
  name: 'percent',
  prefixable: false,
}

interface IProps {
  name: string
  settings: VerificatonOption[]
  onChange: (options: VerificatonOption[], name: string) => void
  acceptChange: boolean
  service: string
  unit?: SiUnit
  validate?: boolean
  headSuffix?: string
}

export const VerificationOptions = ({
  settings,
  onChange,
  acceptChange,
  service,
  name,
  unit = percent,
  validate = true,
  headSuffix = '',
}: IProps) => {
  const intl = useIntl()

  // Example: [ ['>=', 2], [2, 4]<-interval, ['<=', 5] ]
  const options = settings ? settings : new Array(verificationDangerLevels(intl, service).length).fill([])

  const units = useSelector((state: ReduxState) => state.parameters.units)
  const prefixes = useSelector((state: ReduxState) => state.parameters.prefixes)

  const compareOptions = useMemo(
    () =>
      compareOpt.map((option) => {
        return {
          value: option,
          title: intl.formatMessage({ id: 'dashboards.indicator.settings.operator.' + option }).toLowerCase(),
        }
      }),
    []
  )

  const tableHeaders: string[] = useMemo(() => verificationOptionsTableHeader(intl, service, headSuffix), [])

  const handleRowChange = (index: number, valueIndex: number, value: string | number) => {
    const newOptions = produce(options, (draft) => {
      draft[index][valueIndex] = value === 'in' ? 0 : value
    })

    onChange(newOptions, name)
  }

  const renderValidate = () => {
    const warn = validateOptions(options)

    return (
      warn && (
        <div style={{ color: 'var(--warning)', textAlign: 'center', marginTop: '20px' }}>
          {intl.formatMessage({ id: 'system.verification.options_warning' }, warn)}
        </div>
      )
    )
  }

  const rows = verificationDangerLevels(intl, service).map((row, i) => {
    const isRowRange = options[i] && typeof options[i][0] === 'number'

    return (
      <tr key={row.label + row.value}>
        <td>
          <div style={{ width: '90%' }}>
            {row.label} {row.value !== null && `(${row.value})`}
          </div>
        </td>
        <td>
          {acceptChange ? (
            <Dropdown
              onChange={(value: string) => handleRowChange(i, 0, value)}
              value={options[i] ? (typeof options[i][0] === 'number' ? 'in' : options[i][0]) : ''}
              options={compareOptions}
            />
          ) : isRowRange ? (
            compareOptions.find((option) => option.value === 'in').title
          ) : (
            compareOptions.find((option) => option.value === options[i][0])?.title || '-'
          )}
        </td>
        <td>
          {acceptChange ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isRowRange && (
                <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                  <NumberInput
                    type={'parameter'}
                    name={''}
                    fullWidth={true}
                    onChange={(value) => handleRowChange(i, 0, value)}
                    value={options[i][0] as number}
                    units={units}
                    prefixes={prefixes}
                    baseUnit={unit}
                    displayUnit={unit}
                  />
                </div>
              )}
              <div style={isRowRange ? { width: 'calc(50% - 2px)' } : { width: '100%' }}>
                <NumberInput
                  type={'parameter'}
                  name={''}
                  fullWidth={true}
                  onChange={(value) => handleRowChange(i, 1, value)}
                  value={options[i] && (options[i][1] as number)}
                  units={units}
                  prefixes={prefixes}
                  baseUnit={unit}
                  displayUnit={unit}
                />
              </div>
            </div>
          ) : isRowRange ? (
            `${typeof options[i][0] === 'number' ? options[i][0] : ''}—${
              typeof options[i][1] === 'number' ? options[i][1] : ''
            } ${unit.symbol}`
          ) : typeof options[i][1] === 'number' ? (
            options[i][1] + ` ${unit.symbol}`
          ) : (
            '-'
          )}
        </td>
      </tr>
    )
  })

  return (
    <>
      <table className={'nsi-settings-table'} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}

            <th style={{ width: '314px' }} />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      {acceptChange && validate && renderValidate()}
    </>
  )
}
