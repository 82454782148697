import { IWindroseComponent, IWindroseSettings } from 'au-nsi/dashboards'
import produce from 'immer'
import React from 'react'
import { useIntl } from 'react-intl'
import Form from '../../../shared/Forms/Form'
import PlusButton from '../../../shared/Forms/PlusButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import InputRow from '../../../shared/Inputs/InputRow'
import TextInput from '../../../shared/Inputs/TextInput'
import Modal from '../../../shared/Modal/Modal'
import ModalFooter from '../../../shared/Modal/ModalFooter'
import { showError } from '../../../utils/notifications'
import WindroseParameter from './WindroseParameter'

/**
 * Модальное кно с настройками розы ветров
 */
const WindroseSettings = (props: Props) => {
  const intl = useIntl()

  const [settings, setSettings] = React.useState(props.component.settings)
  const isDetailed = settings.type === 'detailed'

  const handleChange = React.useCallback((value: any, key: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleSave = () => {
    const main = settings.parameters[0]
    if (!main.device_id) return showError('dashboards.windrose.errors.device')
    if (!main.direction_parameter_id) return showError('dashboards.windrose.errors.direction')
    if (isDetailed && !main.speed_parameter_id) return showError('dashboards.windrose.errors.speed')

    props.onSave(settings)
  }

  const handleRowRemove = React.useCallback((index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.parameters.splice(index, 1)
      })
    )
  }, [])

  const handleRowChange = React.useCallback((key: string, value: string, index: number) => {
    setSettings((prev) =>
      produce(prev, (draft) => {
        draft.parameters[index][key] = value
      })
    )
  }, [])

  // добавление новой строки с настройками выводимого текста
  const handleRowAdd = () => {
    setSettings(
      produce(settings, (draft) => {
        draft.parameters.push({
          device_id: '',
          direction_parameter_id: '',
          speed_parameter_id: '',
          timespan: 30 * 24 * 60 * 60 * 1000,
        })
      })
    )
  }

  const typeOptions = ['detailed', 'comparison'].map((type) => ({
    value: type,
    title: intl.formatMessage({ id: 'dashboards.windrose.settings.type.' + type }),
  }))

  const parameters = isDetailed ? settings.parameters.slice(0, 1) : settings.parameters

  const rows = parameters.map((p, i) => {
    return (
      <WindroseParameter
        key={i}
        index={i}
        row={p}
        onChange={handleRowChange}
        onRemove={handleRowRemove}
        detailed={isDetailed}
      />
    )
  })

  return (
    <Modal size="lg" onClose={props.onCancel}>
      <div>
        <h2>{intl.formatMessage({ id: props.title })}</h2>

        <Form editing={true} onCancel={props.onCancel} onSubmit={handleSave}>
          <div className="system__grid">
            <InputRow label={intl.formatMessage({ id: 'common.name' })}>
              <TextInput name="title" required={false} value={settings.title} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.windrose.settings.type' })}>
              <Dropdown name="type" value={settings.type} options={typeOptions} onChange={handleChange} />
            </InputRow>

            <InputRow label={intl.formatMessage({ id: 'dashboards.windrose.settings.sectors' })}>
              <Dropdown name="sectors" value={settings.sectors} options={sectorsOptions} onChange={handleChange} />
            </InputRow>
          </div>

          {rows}
          {!isDetailed && <PlusButton textId="common.add" onClick={handleRowAdd} style={{ marginLeft: 0 }} />}

          <ModalFooter onSave={handleSave} onCancel={props.onCancel} />
        </Form>
      </div>
    </Modal>
  )
}

const sectorsOptions = [
  { value: 8, title: '8' },
  { value: 16, title: '16' },
  { value: 32, title: '32' },
  { value: 64, title: '64' },
]

interface Props {
  component: IWindroseComponent
  onCancel: () => void
  onSave: (settings: IWindroseSettings) => void
  title: string
}

export default WindroseSettings
