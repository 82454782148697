import { IDashboard } from 'au-nsi/dashboards'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import actions from '../dashboard.actions'
import { generateStyles, getIntersectArea, move } from './useDashboardsMove.utils'

interface IProps {
  items: IDashboard[]
  filter?: boolean
  sort?: boolean
  onmouseup?: (e: MouseEvent, draggingElement: IDashboard['id']) => void
}

const animationDuration = 300
const dropIntersect = 0.8
const swapIntersect = 0.6

export const useDashboardsMove = ({ items, filter = true, sort = true, onmouseup }: IProps) => {
  const dispatch = useDispatch()

  const [dropElement, setDropElement] = useState<string>('')
  const [dropFolder, setDropFolder] = useState<string>('')

  const orderedItems = filter
    ? items.filter((d) => !(d.folder_id && d.folder_id !== d.id && items.some((dh) => dh.id === d.folder_id)))
    : items
  sort && orderedItems.sort((a, b) => a.user_ordering_index - b.user_ordering_index)

  useEffect(() => {
    return () => {
      dispatch(actions.setDraggingId({ dashboardId: '' }))
      setDropElement('')
      setDropFolder('')
      document.onmousemove = null
      document.onmouseup = null
    }
  }, [])

  const swap = (target: string, source: string) => {
    if (dropFolder || dropElement) return
    dispatch(actions.swapDashboards(source, target))
  }

  const handleIntersect = (ctx: { ts: number; dropElement: string; dropFolder: string }, id: string) => {
    const { ts } = ctx

    let isFolder = false

    let rect = document.getElementById(id.toString())?.getBoundingClientRect()
    if (!rect) {
      rect = document.getElementById(id + 'folder')?.getBoundingClientRect()
      isFolder = true
    }
    if (!rect) return

    orderedItems.forEach((d) => {
      if (d.id === id) return
      let r = document.getElementById(d.id.toString())?.getBoundingClientRect()
      if (!r) r = document.getElementById(d.id + 'folder').getBoundingClientRect()

      const intersectArea = getIntersectArea(rect, r)
      if (new Date().getTime() - ts > 700) {
        if (intersectArea > dropIntersect && !isFolder) {
          ctx.dropElement = id
          ctx.dropFolder = d.id

          setDropElement(id)
          setDropFolder(d.id)
          return
        }

        if (intersectArea > swapIntersect) {
          swap(d.id, id)
          ctx.dropElement = ''
          ctx.dropFolder = ''

          setTimeout(() => {
            setDropElement('')
            setDropFolder('')
          }, animationDuration)

          ctx.ts = new Date().getTime() + animationDuration
        }
      }
    })
  }

  /* id: id перемещаемого HTML элемента */
  const onDragStart = (e: DragEvent, id: string) => {
    e.preventDefault()

    dispatch(actions.setDraggingId({ dashboardId: id }))

    let DOMEl = document.getElementById(id)
    if (!DOMEl) DOMEl = document.getElementById(id + 'folder')
    if (!DOMEl) return

    /* Абсолютно позиционируем наш элемент. Начальную позицию оставляем прежней, но уже в кординатах */
    const { left, top, width, height } = DOMEl.getBoundingClientRect()
    DOMEl.setAttribute('style', generateStyles(left, top, width, height))
    move(e, DOMEl)
    const ctx = { ts: new Date().getTime(), dropElement, dropFolder }

    /* Перемещаем вместе с мышкой */
    document.onmousemove = (e) => {
      handleIntersect(ctx, id)
      move(e, DOMEl)
    }

    document.onmouseup = (e) => {
      e.preventDefault()
      e.stopPropagation()

      onmouseup && onmouseup(e, id)
      setDropElement('')
      setDropFolder('')

      setTimeout(() => {
        dispatch(actions.setDraggingId({ dashboardId: '' }))
      }, animationDuration)

      if (ctx.dropElement && ctx.dropFolder && !orderedItems.some((d) => d.folder_id === ctx.dropFolder)) {
        // Создать папку из двух дашбордов
        dispatch(actions.createDraftFolder(ctx.dropElement, ctx.dropFolder))
      } else if (ctx.dropFolder && ctx.dropElement) {
        // Добавить дашборд в папку
        dispatch(actions.addDashboardToDraftFolder(ctx.dropFolder, ctx.dropElement))
        //onFolderPush(ctx.dropFolder, ctx.dropElement)
      }

      document.onmousemove = null
      document.onmouseup = null
      DOMEl.removeAttribute('style')
    }

    return false
  }

  return {
    dropFolder,
    dropElement,
    orderedItems,
    onDragStart,
  }
}
