import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import ColorPicker from '../../../shared/Inputs/Colorpicker/ColorPicker'
import NumberInput from '../../../shared/Inputs/NumberInput'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import { selectDenormalizedParametersMap } from '../../Parameters/params.selectors'

const IndicatorConditionRow = (props: Props) => {
  const intl = useIntl()

  const { parameterOption, index, row, ignoreDefaultOperator, ignoreNoDataOperator } = props

  const parametersMap = useSelector(selectDenormalizedParametersMap)
  const selectedParameter = parameterOption ? parametersMap.get(parameterOption.value) : null

  const color = row.color
  const colorPickerProps = { position: 'left' as const, showList: true, defaultMode: 'list' as const }

  const units = useSelector((state: ReduxState) => state.parameters.units)
  const prefixes = useSelector((state: ReduxState) => state.parameters.prefixes)
  const unitsInfo = {
    units,
    prefixes,
    baseUnit: selectedParameter?.unit,
    basePrefix: selectedParameter?.prefix,
    displayUnit: selectedParameter?.display_unit,
    displayPrefix: selectedParameter?.display_prefix,
  }

  let operatorOptions = ['<', '<=', '=', '!=', '>=', '>', 'in', 'out', 'nodata', 'default'].map((operator) => ({
    value: operator,
    title: intl.formatMessage({ id: 'dashboards.indicator.settings.operator.' + operator }),
  }))

  if (ignoreDefaultOperator && row.operator !== 'default') {
    operatorOptions = operatorOptions.filter((o) => o.value !== 'default')
  }
  if (ignoreNoDataOperator && row.operator !== 'nodata') {
    operatorOptions = operatorOptions.filter((o) => o.value !== 'nodata')
  }

  const showRange: boolean = row.operator === 'in' || row.operator === 'out'
  const range = row.range || [0, 0]

  const valueDisabled = row.operator === 'nodata' || row.operator === 'default'

  const actionOptions = ['none', 'hide', 'blink'].map((action) => ({
    value: action,
    title: intl.formatMessage({ id: 'dashboards.indicator.settings.action.' + action }),
  }))

  const hideAction = row.action === 'hide' ? true : false

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, index)
  }

  const handleColorChange = (color: string) => {
    props.onChange('color', color, index)
  }

  const handleRange = (value: number, name: string) => {
    const i = name === 'from' ? 0 : 1
    const copy = [...range]
    copy[i] = value

    props.onChange('range', copy, index)
  }

  return (
    <>
      <tr>
        <td>
          <Dropdown name="operator" value={row.operator} options={operatorOptions} onChange={handleChange} />
        </td>

        <td>
          {showRange && !valueDisabled && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 'calc(50% - 2px)', marginRight: '4px' }}>
                <NumberInput
                  type="parameter"
                  name="from"
                  fullWidth={true}
                  onChange={handleRange}
                  value={range[0]}
                  {...unitsInfo}
                />
              </div>
              <div style={{ width: 'calc(50% - 2px)' }}>
                <NumberInput
                  type="parameter"
                  name="to"
                  fullWidth={true}
                  onChange={handleRange}
                  value={range[1]}
                  {...unitsInfo}
                />
              </div>
            </div>
          )}

          {!showRange && !valueDisabled && (
            <NumberInput
              type="parameter"
              name="value"
              fullWidth={true}
              onChange={handleChange}
              value={row.value}
              disabled={valueDisabled}
              disabledStyle={'input'}
              {...unitsInfo}
            />
          )}
        </td>

        <td>
          <Dropdown name="action" value={row.action} options={actionOptions} onChange={handleChange} />
        </td>
        <td>{!hideAction && <ColorPicker color={color} onChange={handleColorChange} {...colorPickerProps} />}</td>

        <td align="center">
          <RemoveButton id="" onRemove={() => props.onRemove(index)} />
        </td>
      </tr>
    </>
  )
}

interface IIndicatorOption {
  operator: string
  range: [number, number]
  value: number
  action: string
  color: string
}

interface Props {
  key: number
  index: number
  row: IIndicatorOption
  onChange: (key: string, value: any, index: number) => void
  onRemove: (index: number) => void
  parameterOption: { label: string; value: string }
  ignoreDefaultOperator: boolean
  ignoreNoDataOperator: boolean
}

export default React.memo(IndicatorConditionRow)
