import styles from '../../maptopology.module.css'
import { useIntl } from 'react-intl'
import { memo, useState } from 'react'
import CreateGroupModal from '../../Groups/components/CreateGroupModal'
import GroupTile from '../../Groups/components/GroupTile'
import usePlaceGroups from '../../Groups/hooks/usePlaceGroups'
import useGroupsAccessRights from '../../Groups/hooks/useGroupsAccessRights'
import { IPlace } from 'au-nsi/shared'
import { IGroup } from 'au-nsi-elevator/groups'

interface IProps {
  place: IPlace
  onGroupSelect: (groupId: IGroup['id']) => void
}

const PlaceGroupsPanel = (props: IProps) => {
  const intl = useIntl()
  const placeGroups = usePlaceGroups(props.place)
  const accessRights = useGroupsAccessRights()
  const [createModalOpen, setCreateGroupModal] = useState(false)

  const renderFooter = () => {
    if (!accessRights.update) return null

    return (
      <div className={styles.addressFooter}>
        <button className="nsi-button default" onClick={() => setCreateGroupModal(true)}>
          {intl.formatMessage({ id: 'map.ae.groups.add' })}
        </button>
      </div>
    )
  }

  return (
    <>
      {createModalOpen && <CreateGroupModal onClose={() => setCreateGroupModal(false)} place={props.place} />}
      {placeGroups.map((group) => (
        <GroupTile group={group} key={group.id} onCLick={props.onGroupSelect} />
      ))}
      {renderFooter()}
    </>
  )
}

export default memo(PlaceGroupsPanel)
