import { IGanttSettings } from 'au-nsi/dashboards'
import { BaseEquipment } from 'au-nsi/equipment'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import ganttFactory from '../../../services/gantt/gantt.factory'
import Gantt from '../../GanttTable/Gantt'
import GanttRow from './GanttRow'

/**
 * Диаграмма сигнальных ситуаций
 */
const GanttTable = (props: Props) => {
  const service = React.useMemo(() => ganttFactory(), [])
  const devices = useSelector((state: ReduxState) => state.nsi.equipment)

  // если выбрана опция show_every_device, то показываем все имеющиеся в НСИ устройства,
  // если не выбрана, то только устройства указанные в настройках самой диаграммы
  const list = props.settings.show_every_device ? devices.map((d) => d.id) : props.settings.equipment

  const rows = list.map((id) => {
    const data$ = service.select(id)
    const device = devices.find((d) => d.id === id)

    return device ? <GanttRow key={id} data$={data$} device={device as BaseEquipment} /> : null
  })

  return (
    <Gantt settings={props.settings} service={service}>
      <Gantt.Header>
        <Gantt.Title></Gantt.Title>
        <Gantt.Timeline />
      </Gantt.Header>
      <Gantt.Body>{rows}</Gantt.Body>
    </Gantt>
  )
}

interface Props {
  id: number
  settings: IGanttSettings
}

export default GanttTable
