import GroupsLoader from './components/GroupsLoader'
import GroupsPanel from '../MapLeftPanel/PlacePanel/PlaceGroupsPanel'
import { IPlace } from 'au-nsi/shared'
import { IGroup } from 'au-nsi-elevator/groups'

export const equipmentGroupsResource = 'ae_groups'
export type EquipmentGroupsResource = 'ae_groups'

interface IProps {
  place: IPlace
  onGroupSelect: (groupId: IGroup['id']) => void
}

const GroupsPanelWithLoader = (props: IProps) => (
  <GroupsLoader>
    <GroupsPanel place={props.place} onGroupSelect={props.onGroupSelect} />
  </GroupsLoader>
)

export default GroupsPanelWithLoader
