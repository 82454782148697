import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'

export const selectCommandsMap = createSelector(
  (state: ReduxState) => state.commands.commands,
  (commands) => new Map(commands.map((c) => [c.id, c]))
)

export const selectCommandTypesMap = createSelector(
  (state: ReduxState) => state.commands.command_types,
  (types) => new Map(types.map((t) => [t.id, t]))
)
