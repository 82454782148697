import { DataChunk } from '../../../utils/canvas.utils'

/**
 * Заливка области между минимальными и максимальными значениями
 */
export const fillBand = (options: FillBandOptions) => {
  const { ctx, top, bottom, xScale, yScale } = options
  if (top.length === 0) return

  let isStart = true
  let chunkIndex0 = 0
  let tsIndex0 = top[0].i0 - 1
  let prevX = null

  const DELTA = options.disconnectDistance || null
  const chunkIndexLast = top.length - 1

  // отрисовка точек верхней границы
  for (let i = 0; i <= chunkIndexLast; i++) {
    const chunk = top[i]
    if (chunk.i0 === -1 || chunk.i1 === -1 || chunk.i0 > chunk.i1 || !chunk.x || !chunk.y) continue

    for (let j = chunk.i0; j <= chunk.i1; j++) {
      const x = chunk.x[j]
      const y = chunk.y[j]

      const isDisconnect = x == null || y == null || (prevX != null && x - prevX > DELTA)
      if (x != null) prevX = x

      // если разрыв области из-за null значений или пропущенных пакетов, то замыкаем оласть рисуя нижнюю границу этого
      // участка и запоминаем конечные индексы, чтобы при отрисовке следующей области знать что уже было отрисовано
      if (isDisconnect) {
        if (!isStart) {
          drawBottom({ ctx, bottom, xScale, yScale, chunkIndex0, chunkIndex1: i, tsIndex0, tsIndex1: j - 1 })
          chunkIndex0 = i
          tsIndex0 = j
        }

        isStart = true
        continue
      }

      if (isStart) {
        isStart = false
        ctx.beginPath()
      }

      ctx.lineTo(xScale(x), yScale(y))

      // если дошли до конца верхней границы, то отрисовываем нижнюю
      if (i === chunkIndexLast && j === chunk.i1) {
        drawBottom({ ctx, bottom, xScale, yScale, chunkIndex0, chunkIndex1: i, tsIndex0, tsIndex1: j })
      }
    }
  }
}

/**
 * Отрисовка нижней границы области в обратном порядке между указанными индексами и заливка области
 */
const drawBottom = (options: DrawBottomOptions) => {
  const { ctx, xScale, yScale, chunkIndex0, chunkIndex1 } = options

  for (let i = chunkIndex1; i >= chunkIndex0; i--) {
    const chunk = options.bottom[i]
    if (chunk.i0 === -1 || chunk.i1 === -1 || chunk.i0 > chunk.i1 || !chunk.x || !chunk.y) continue

    const tsIndex1 = i === chunkIndex1 ? options.tsIndex1 : chunk.i1
    const tsIndex0 = i === chunkIndex0 ? options.tsIndex0 : chunk.i0

    for (let j = tsIndex1; j > tsIndex0; j--) {
      const x = xScale(chunk.x[j])
      const y = yScale(chunk.y[j])
      ctx.lineTo(x, y)
    }
  }

  ctx.closePath()
  ctx.fill()
}

interface FillBandOptions {
  top: DataChunk[]
  bottom: DataChunk[]
  ctx: CanvasRenderingContext2D
  disconnectDistance: number
  xScale: (v: number) => number
  yScale: (v: number) => number
}

interface DrawBottomOptions {
  bottom: DataChunk[]
  ctx: CanvasRenderingContext2D
  xScale: (v: number) => number
  yScale: (v: number) => number
  chunkIndex0: number
  chunkIndex1: number
  tsIndex0: number
  tsIndex1: number
}
