import { Boundaries } from '../../interfaces'

export const boundaries: Boundaries = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 300,
  height: 100,
}
