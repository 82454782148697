import React from 'react'
import { ReactComponent as CogIcon } from '../../icons/cog.svg'
import { COLORS } from '../constants'
import RemoveButton from '../Forms/RemoveButton'
import css from './inputs.module.css'

/**
 * Опциональное поле для ввода, которое пользователь может не заполнять и оставить undefined
 */
function OptionalInput<T>(props: Props<T>) {
  const isUndefined = props.value == null

  const style = { color: COLORS.gray }

  const handleClick = () => {
    const value = isUndefined ? props.defaultValue : undefined
    props.onChange(value, props.name)
  }

  const undefinedContent = (
    <React.Fragment>
      <span className="system__input-disabled" style={{ background: 'var(--bg-default)' }}>
        {props.defaultLabel}
      </span>
      <CogIcon width={20} height={20} style={style} onClick={handleClick} />
    </React.Fragment>
  )

  const valueContent = (
    <React.Fragment>
      {props.children}
      <RemoveButton onRemove={handleClick} style={{ width: 20, marginLeft: 4 }} />
    </React.Fragment>
  )

  return <div className={css.optionalInput}>{isUndefined ? undefinedContent : valueContent}</div>
}

interface Props<T> {
  name: string
  value?: T
  defaultValue: T
  defaultLabel: string
  onChange: (value: T, name: string) => void
  children: React.ReactNode
}

export default OptionalInput
