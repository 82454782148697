import { IUser } from 'au-nsi/user'
import { NotificationSettings } from 'au-nsi/notifications'
import { DEFAULT_LOCALE } from '../../shared/constants'
import { defaultNotificationSettings } from '../NotificationSettings/settings.api'

export const SET_USER = 'app/user/SET_USER'
export const SET_LANG = 'app/user/SET_LANG'
export const UPDATE_USER = 'app/user/UPDATE_USER'
export const UPDATE_USER_NTF = 'app/user/UPDATE_USER_NTF'
const SET_STATE = 'app/user/SET_STATE'

/** Информация о текущем пользователе */
export interface CurrentUserState extends IUser {
  role: string
  is_admin: boolean
  access_rights: { [id: string]: boolean }
  notifications: NotificationSettings
  timezone_offset: number
  last_notification_seen?: number
}

const initialState: CurrentUserState = {
  id: 0,
  lang: DEFAULT_LOCALE,
  name: '',
  role: '',
  role_id: 0,
  username: '',
  email: '',
  is_admin: false,
  access_rights: {},
  notifications: defaultNotificationSettings,
  timezone_offset: 0,
  organization_id: -1,
  suspended: false,
  use_role_ntf_settings: false,
  preferences: { theme: localStorage.getItem('theme'), home_dashboards: [] },
  security_settings: { '2fa_method': '', '2fa_options': {}, login_notification: false },
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
    case SET_STATE:
      return action.payload
    case SET_LANG:
      return { ...state, lang: action.payload }
    case UPDATE_USER:
      return { ...state, ...action.payload }
    case UPDATE_USER_NTF:
      return { ...state, notifications: { ...state.notifications, ...action.payload } }
    default:
      return state
  }
}

export default userReducer
