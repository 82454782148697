import React from 'react'
import RemoveButton from '../Forms/RemoveButton'
import { IAttribute } from './attributes.types'
import AttributeDropdown from './AttributeDropdown'
import AttributeInput from './AttributeInput'
import ResourceInput from './ResourceInput'

import './attributes.styles.css'

const AttributesEditor = (props: Props) => {
  const { allowEditing } = props
  const attributes = props.attributes || []

  const handleChange = (value: any, index: number, key: string) => {
    const result = attributes.map((attr, i) => {
      return i === index ? { ...attr, [key]: value } : attr
    })

    props.onChange(result, props.index, props.name)
  }

  const handleRemove = (e) => {
    const index = +e.target.dataset.id
    const result = [...attributes.slice(0, index), ...attributes.slice(index + 1)]
    props.onChange(result, props.index, props.name)
  }

  const addAttribute = (type: string) => {
    const value = type === 'object' ? [] : null
    const result: any = [...attributes, { key: '', type, value }]
    props.onChange(result, props.index, props.name)
  }

  const rows = attributes.map((attribute, i) => {
    let value = null
    const ops = { index: i, allowEditing, onChange: handleChange }
    const isObject = attribute.type === 'object'

    switch (attribute.type) {
      case 'string':
        value = <AttributeInput name="value" type="string" value={attribute.value} {...ops} />
        break
      case 'number':
        value = <AttributeInput name="value" type="number" value={attribute.value} {...ops} />
        break
      case 'object':
        value = <AttributesEditor name="value" attributes={attribute.value} {...ops} />
        break
      case 'device':
      case 'parameter':
        value = <ResourceInput name="value" type={attribute.type} value={attribute.value} {...ops} />
        break
    }

    return (
      <React.Fragment key={i}>
        <div className="attribute_row">
          <AttributeInput
            index={i}
            name="key"
            value={attribute.key}
            type="string"
            allowEditing={allowEditing}
            onChange={handleChange}
          />

          {!isObject && value}

          <span className="attributes__remove">
            {allowEditing && <RemoveButton onRemove={handleRemove} id={i + ''} />}
          </span>
        </div>
        {isObject && value}
      </React.Fragment>
    )
  })

  const className = allowEditing ? 'attributes__editor is-editable' : 'attributes__editor'

  return (
    <div className={className}>
      {rows}
      {allowEditing && <AttributeDropdown onSelect={addAttribute} />}
    </div>
  )
}

interface Props {
  allowEditing: boolean
  attributes: IAttribute[]
  index?: number
  name?: string
  onChange: (attributes: IAttribute[], index: number, key: string) => void
}

export default AttributesEditor
