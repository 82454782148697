import { IGroup } from 'au-nsi-elevator/groups'
import { useIntl } from 'react-intl'
import KeyValueTable from '../../../../../shared/Forms/KeyValueTable/KeyValueTable'
import EditableText from '../../../../../shared/Inputs/EditableText/EditableText'
import InputRow from '../../../../../shared/Inputs/InputRow'
import { PLacePicker } from '../../../../../shared/Inputs/PlacePicker/PlacePicker'
import useGroupsAccessRights from '../../hooks/useGroupsAccessRights'

interface Props {
  group: IGroup
  onChange: (value: any, key: keyof IGroup) => void
}

const GroupForm = ({ group, onChange }: Props) => {
  const intl = useIntl()
  const accessRights = useGroupsAccessRights()

  return (
    <>
      <h2>
        <EditableText
          allowEditing={accessRights.update}
          value={group.name}
          name="name"
          onChange={onChange}
          style={{ fontSize: '2rem', background: 'var(--bg-input)', borderRadius: '0.5em' }}
        />
      </h2>
      <KeyValueTable
        style={{ marginBottom: '1em' }}
        pairs={[['common.id', <span style={{ paddingLeft: '0.5em' }}>{group.id}</span>]]}
      />
      <InputRow label={intl.formatMessage({ id: 'common.description' })}>
        <EditableText
          style={{ maxWidth: '100%' }}
          allowEditing={accessRights.update}
          onChange={onChange}
          value={group.description}
          name="description"
          useTextArea={true}
        />
      </InputRow>
      <InputRow label={intl.formatMessage({ id: 'nsi.address' })}>
        <PLacePicker
          selectedPlace={group.address}
          allowEditing={accessRights.update}
          name="address"
          onSave={onChange}
        />
      </InputRow>
    </>
  )
}

export default GroupForm
