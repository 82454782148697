/**
 * Сравнение двух объектов. Работает только с сериализируемыми структурами (примитивы, массивы, объекты)
 */
export const isJsonEqual = (a: any, b: any) => {
  let ta: string = typeof a
  let tb: string = typeof b

  if (a === null) ta = 'null'
  if (b === null) tb = 'null'
  if (Array.isArray(a)) ta = 'array'
  if (Array.isArray(b)) tb = 'array'

  if (ta !== tb) return false

  const isPrimitive = ta === 'number' || ta === 'string' || ta === 'undefined' || ta === 'null' || ta === 'boolean'
  if (isPrimitive) return a === b

  if (ta === 'array') {
    if (a.length !== b.length) return false
    return a.every((value, i) => isJsonEqual(value, b[i]))
  }

  // objects
  const keys1 = Object.keys(a)
  const keys2 = Object.keys(b)
  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => isJsonEqual(a[key], b[key]))
}

/**
 * Копирование сериализируемых структур (примитивы, массивы, объекты)
 */
export const deepCopy = <T>(value: T): T => {
  if (!value) return value

  if (Array.isArray(value)) {
    return value.map((v) => deepCopy(v)) as any
  }

  if (typeof value === 'object') {
    const copy = { ...value }

    for (const [k, v] of Object.entries(value)) {
      copy[k] = deepCopy(v)
    }

    return copy
  }

  return value
}

/**
 * Создать объект включающий только измененные поля
 * a - начальное состояние объекта, b - состояние после редактирования
 */
export const objectDiff = (a: any, b: any) => {
  const result = {}

  for (const key of Object.keys(b)) {
    const aValue = a[key]
    const bValue = b[key]

    if (bValue !== undefined && !isJsonEqual(aValue, bValue)) {
      result[key] = bValue
    }
  }

  return result
}

/**
 * Преобразовать массив объектов в маппинг id -> item
 */
export const array2map = <T extends { id: any }>(array: T[]) => {
  const result = new Map<any, T>()

  for (const item of array) {
    result.set(item.id, item)
  }

  return result
}

const MB = 1e3
const GB = 1e6
const TB = 1e9

export const formatMemory = (memory = 0) => {
  if (memory > TB) return (memory / TB).toFixed(1) + ' TB'
  else if (memory > GB) return (memory / GB).toFixed(1) + ' GB'
  else if (memory > MB) return (memory / MB).toFixed(1) + ' MB'
  else return memory.toFixed(1) + ' KB'
}
