import { useEffect, useState } from 'react'
import { ReactComponent as Arrow } from '../../../../icons/arrow-right.svg'
import { ColorFormat, ColorType, HSL } from '../colorpicker.types'
import styles from './colorselector.module.css'
import { IColorState } from '../ColorPicker'

interface IProps {
  colorState: IColorState
  onChange: (v: HSL, resource: IColorState['resource']) => void
  formats: ColorFormat<ColorType>[]
}

export const ColorFormatPicker = ({ colorState, onChange, formats }: IProps) => {
  const [formatIndex, setFormatIndex] = useState(0)
  const [colors, setColors] = useState(formats.map((format) => format.fromHsl(colorState.color)))

  useEffect(() => {
    if (colorState.resource === 'formatPicker') return

    setColors(formats.map((format) => format.fromHsl(colorState.color)))
  }, [colorState])

  const handleChange = (name: string, value: string) => {
    const { validation, toHsl, codes, format } = formats[formatIndex]

    const newColor = format({ ...colors[formatIndex], [name]: value })

    // Number('0.') === 0
    if (value.indexOf('.') + 1 !== value.length) {
      codes[0] !== 'HEX' && Object.keys(newColor).map((key) => (newColor[key] = Number(newColor[key])))
    }

    setColors([...colors.slice(0, formatIndex), newColor, ...colors.slice(formatIndex + 1)])
    if (validation(newColor)) onChange(toHsl(newColor), 'formatPicker')
  }

  const renderFormat = <T extends ColorType>(format: ColorFormat<T>, ind: number) => (
    <div
      key={format.codes[0] + 'colorpicker'}
      className={styles.colorpickerFormatGrid}
      style={{ gridTemplateColumns: `repeat(${format.codes.length}, 1fr)` }}
    >
      {format.codes.map((code, i) => {
        const key = code.toLowerCase()
        const value =
          colors[ind][key] != null && !Number.isNaN(colors[ind][key]) ? colors[ind][key].toString().substr(0, 7) : ''
        const isCurrent = formatIndex === ind

        return (
          <div className={styles.colorpickerCenter} key={key + i}>
            <input
              tabIndex={isCurrent ? i + 1 : -1}
              className={`nsi-input ${styles.colorpickerInput}`}
              value={value}
              onChange={(ev) => handleChange(key, ev.target.value)}
            />
          </div>
        )
      })}
      {format.codes.map((code, i) => (
        <div style={{ userSelect: 'none' }} className={styles.colorpickerCenter} key={'input' + code + i}>
          {code}
        </div>
      ))}
    </div>
  )

  return (
    <div className={styles.colorformatContainer}>
      <div className={styles.colorpickerCenter}>
        <Arrow
          className={styles.colorformatArrow}
          style={{ transform: 'rotate(180deg)' }}
          onClick={() => setFormatIndex(formatIndex - 1 === -1 ? formats.length - 1 : formatIndex - 1)}
        />
      </div>
      <div style={{ overflow: 'hidden' }}>
        <div
          style={{
            width: formats.length * 100 + '%',
            display: 'inline-flex',
            transition: 'all ease 0.3s',
            transform: `translateX(-${(formatIndex % formats.length) * (100 / formats.length) + '%'})`,
          }}
        >
          {formats.map((format, ind) => renderFormat(format, ind))}
        </div>
      </div>
      <div className={styles.colorpickerCenter}>
        <Arrow
          className={styles.colorformatArrow}
          onClick={() => setFormatIndex(formatIndex + 1 === formats.length ? 0 : formatIndex + 1)}
        />
      </div>
    </div>
  )
}
