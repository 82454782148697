import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../redux/store.types'
import GanttTable from '../../shared/GanttTable/GanttTable'
import { PageMode } from '../../shared/interfaces'
import HistoryToggle from '../../shared/Utils/HistoryToggle'
import ChartPlayer from '../ChartPlayer/ChartPlayer'
import { Equipment } from 'au-nsi/equipment'
import { selectReceivers } from '../Nsi/nsi.selectors'
import settingsActions from '../System/Settings/settings.actions'
import { actions } from './lfo.reducers'
import { getMatchedDevices, getSelectedDevices } from './lfo.selectors'
import './lfo.styles.css'
import LfoCharts from './LfoCharts'
import LfoTable from './LfoTable'

class Lfo extends Component<LfoProps> {
  componentDidMount() {
    this.props.dispatch(settingsActions.loadSettings('ms-lfo'))
  }

  changeCurrentDeviceId = (e: any) => {
    const id = e.target.dataset.id
    this.props.dispatch(actions.selectDevice(id))
  }

  private changeMode = (mode: PageMode) => {
    this.props.dispatch(actions.setMode(mode))
  }

  private handleSearch = (s: string) => {
    this.props.dispatch(actions.setSearch(s))
  }

  private renderGantt(selectedId: string) {
    return (
      <GanttTable
        page="lfo"
        registrators={this.props.matchedRegistrators}
        onSelectRegistrator={this.changeCurrentDeviceId}
        selectedId={selectedId}
        search={this.props.search}
        onSearch={this.handleSearch}
      />
    )
  }

  private renderTable(selectedId: string) {
    return (
      <LfoTable
        equipment={this.props.registrators}
        equipmentMatch={this.props.matchedRegistrators}
        selectedDeviceId={selectedId}
        onSelect={this.changeCurrentDeviceId}
        search={this.props.search}
        onSearch={this.handleSearch}
      />
    )
  }

  render() {
    const { mode, selectedRegistrators } = this.props
    const selectedId = selectedRegistrators[0] && selectedRegistrators[0].id

    return (
      <React.Fragment>
        <ChartPlayer page="lfo" title={this.props.title || ''}>
          <HistoryToggle mode={mode} onChange={this.changeMode} />
        </ChartPlayer>
        <div className="pq">
          {mode === 'history' ? this.renderGantt(selectedId) : this.renderTable(selectedId)}
          <LfoCharts selectedRegistrators={selectedRegistrators} />
        </div>
      </React.Fragment>
    )
  }
}

interface LfoProps {
  mode: PageMode
  registrators: Equipment[]
  matchedRegistrators: Equipment[]
  selectedRegistrators: Equipment[]
  search: string
  title: string
  dispatch: (action: any) => any
}

function mapStateToProps(state: ReduxState) {
  const title = state.dashboards.dashboards.find((d) => d.id === 'lfo')?.name

  return {
    registrators: selectReceivers(state),
    matchedRegistrators: getMatchedDevices(state),
    selectedRegistrators: getSelectedDevices(state),
    mode: state.lfo.mode,
    search: state.lfo.search,
    title,
  }
}

export default connect(mapStateToProps)(Lfo)
