import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMouseMove } from '../../hooks/useMouseMove'
import styles from './slider.module.css'
import { ReactComponent as Arrow } from '../../icons/arrow-right.svg'

interface IProps {
  slides: JSX.Element[]
}

export const Slider = ({ slides }: IProps) => {
  const pagesStyle: CSSProperties = useMemo(() => {
    return {
      width: slides.length * 100 + '%',
      gridTemplateColumns: `repeat(${slides.length}, 1fr)`,
      height: slides.length > 1 ? 'calc(90% - 10px)' : '100%',
    }
  }, [slides.length])

  const ref = useRef(null)
  const arrowL = useRef(null)
  const arrowR = useRef(null)
  const { mouseMove, startEv, stopMove } = useMouseMove({ ref })

  const [page, setPage] = useState(0)
  const [translateX, setTranslateX] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [slides.length])

  useEffect(() => {
    setTranslateX(-(page / slides.length) * 100)
  }, [page])

  useEffect(() => {
    const callback = (e) => {
      e.key === 'ArrowRight' && nextPage()
      e.key === 'ArrowLeft' && prevPage()
    }
    window.addEventListener('keyup', callback)

    return () => window.removeEventListener('keyup', callback)
  }, [])

  useEffect(() => {
    if (!startEv || !mouseMove) {
      ref.current.style.transition = 'all ease 0.3s'
      setTranslateX(-(page / slides.length) * 100)
      return
    }

    if (ref && ref.current && mouseMove && startEv) {
      ref.current.style.transition = ''
      const dxPercent = (mouseMove.clientX - startEv.clientX) / ref.current.getBoundingClientRect().width

      if (Math.abs(dxPercent) < 0.1) {
        setTranslateX(-(page / slides.length) * 100 + dxPercent * 100)
      } else {
        dxPercent > 0 ? prevPage() : nextPage()
        stopMove()
      }
    }
  }, [mouseMove, startEv])

  const nextPage = useCallback(() => {
    if (ref && ref.current) {
      ref.current.style.transition = 'all ease 0.3s'
      setPage((page) => (page < slides.length - 1 ? page + 1 : page))

      if (arrowR && arrowR.current) {
        arrowR.current.style.opacity = '1'
        setTimeout(() => {
          if (arrowR && arrowR.current) arrowR.current.style.opacity = '0.5'
        }, 300)
      }
    }
  }, [ref, arrowR, arrowL])

  const prevPage = useCallback(() => {
    if (ref && ref.current) {
      ref.current.style.transition = 'all ease 0.3s'
      setPage((page) => (page > 0 ? page - 1 : page))

      if (arrowL && arrowL.current) {
        arrowL.current.style.opacity = '1'
        setTimeout(() => {
          if (arrowL && arrowL.current) arrowL.current.style.opacity = '0.5'
        }, 300)
      }
    }
  }, [ref, arrowR, arrowL])

  return (
    <div className={styles.container}>
      <div
        ref={ref}
        className={styles.pages}
        style={Object.assign({ transform: `translateX(${translateX}%)` }, pagesStyle)}
      >
        {slides}
      </div>

      {slides.length > 1 && (
        <div className={styles.pointers}>
          <Arrow ref={arrowL} className={styles.arrow} style={{ transform: 'rotate(180deg)' }} onClick={prevPage} />
          {slides.map((_, ind) => (
            <div
              key={'slider' + ind}
              className={styles.pointer}
              style={{ background: page === ind && 'var(--primary-80)' }}
              onClick={() => setPage(ind)}
            />
          ))}
          <Arrow ref={arrowR} className={styles.arrow} onClick={nextPage} />
        </div>
      )}
    </div>
  )
}
