import { Equipment } from 'au-nsi/equipment'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { FormMode } from '../../../../shared/interfaces'
import EquipmentPassportForm from './EquipmentPassportForm'
import FormButtons from '../../../../shared/Forms/FormButtons'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessRights } from '../../../App/app.selectors'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import { validateItemValues } from '../../../Catalogs/catalogs.validators'
import { patchEquipment } from '../../nsi.actions'
import InputRow from '../../../../shared/Inputs/InputRow'
import { useIntl } from 'react-intl'
import { getSchemaDefaultValuesMap, catalogItemToObject } from '../../../Catalogs/catalogs.utils'
import { selectAllCatalogs, selectPassports } from '../../../Catalogs/catalogs.selectors'
import { ObjectModal } from '../../../Catalogs/components/ObjectModal'
import SaveAsTemplateInscription from '../../../Catalogs/components/Templates/SaveAsTemplateInscription/SaveAsTemplateInscription'
import SelectTemplateScreen from '../../../MapTopology/DeviceScreen/DevicePassport/SelectTemplate/SelectTemplateScreen'
import { API_URL } from '../../../../shared/constants'
import { ReduxState } from '../../../../redux/store.types'
import useDeviceWidth from '../../../../hooks/useDeviceWidth'
import { ICatalogItem, ICatalogPassport } from 'au-nsi/catalogs'
import { showError } from '../../../../utils/notifications'
import { Select } from '@alterouniversal/au-react-components'

interface IProps {
  device: Equipment
  allowTemplateImport?: boolean
  formButtonsStyle?: CSSProperties
}

/**
 Компонент паспорта устройства в топологии.

 Умеет редактировать паспорт и отправлять запросы на его обновление.
*/
const EquipmentPassportPanel = ({ device, allowTemplateImport, formButtonsStyle }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { isMobile } = useDeviceWidth()
  const { wait, loading } = useHttpLoader()

  const accessToken = useSelector((state: ReduxState) => state.auth.accessToken)
  const catalogs = useSelector(selectAllCatalogs)
  const passports = useSelector(selectPassports)
  const accessRights = useSelector(selectAccessRights)

  const [draftDevice, setDraftDevice] = useState<Equipment>(device)
  const [formMode, setFormMode] = useState<FormMode>('view')
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectTemplateOpen, setSelectTemplateOpen] = useState(false)

  const passportsOptions = useMemo(
    () => [{ label: '-', value: null }, ...passports.map((p) => ({ label: p.name, value: p.id }))],
    [passports]
  )
  const selectedPassport = useMemo(
    () => passports.find((p) => p.id === draftDevice.passport_catalog_id),
    [passports, draftDevice.passport_catalog_id]
  )

  useEffect(() => {
    if (formMode === 'view') setDraftDevice(device)
  }, [device.id, formMode])

  const handlePassportSchemaChange = (id: Equipment['passport_catalog_id']) => {
    const updatedPassport = passports.find((p) => p.id === id)

    setDraftDevice({
      ...draftDevice,
      passport_catalog_id: id,
      passport_values: updatedPassport ? getSchemaDefaultValuesMap(updatedPassport.schema) : {},
    })
  }

  const handleSelectTemplate = (template: ICatalogItem) => {
    setDraftDevice(applyEquipmentTemplate(device, template, passports))
    setFormMode('edit')
    setSelectTemplateOpen(false)
  }

  const handlePassportValuesChange = useCallback((schemaId: string, value: any) => {
    setDraftDevice((prev) => ({ ...prev, passport_values: { ...prev.passport_values, [schemaId]: value } }))
  }, [])

  const handleSave = () => {
    if (selectedPassport && !validateItemValues(selectedPassport.schema_tree, draftDevice.passport_values)) {
      return showError('common.validation_error')
    }

    const updateRequest = dispatch(
      patchEquipment(device.id, {
        passport_catalog_id: draftDevice.passport_catalog_id,
        passport_values: draftDevice.passport_values,
      })
    )

    wait(updateRequest as any, () => {
      setFormMode('view')
    })
  }

  const renderSaveAsTemplate = () => {
    if (!selectedPassport) return null

    return (
      <div className="justify_flex_end">
        <SaveAsTemplateInscription catalogId={selectedPassport.id} properties={draftDevice.passport_values} />
      </div>
    )
  }

  const handlePassportExport = () => {
    const name = encodeURIComponent(device.name)
    window.open(API_URL + `/nsi/v1/equipment/${device.id}/passport/${name}.pdf?accessToken=${accessToken}`)
  }

  const renderExtraButtons = () => {
    const detailsBtn = selectedPassport && (
      <button className="nsi-button secondary" onClick={() => setDetailsModalOpen(true)}>
        {intl.formatMessage({ id: 'common.details' })}
      </button>
    )

    const importTemplateBtn = allowTemplateImport !== false && (
      <button className="nsi-button secondary" onClick={() => setSelectTemplateOpen(true)}>
        {intl.formatMessage({ id: 'catalogs.template.import' })}
      </button>
    )

    const exportInPdfBtn = formMode === 'view' && device.protocol === 'LP001' && selectedPassport && (
      <button
        className="nsi-button secondary"
        onClick={handlePassportExport}
        style={{ marginLeft: isMobile ? undefined : 'auto' }}
      >
        {intl.formatMessage({ id: 'nsi.device.passport.pdf' })}
      </button>
    )

    return (
      <>
        {detailsBtn}
        {accessRights['equipment:update'] && importTemplateBtn}
        {exportInPdfBtn}
      </>
    )
  }

  if (selectTemplateOpen) {
    return (
      <SelectTemplateScreen
        device={device}
        onSelect={handleSelectTemplate}
        onCancel={() => setSelectTemplateOpen(false)}
      />
    )
  }

  return (
    <div className="relative">
      {detailsModalOpen && (
        <ObjectModal
          onClose={() => setDetailsModalOpen(false)}
          item={catalogItemToObject(
            { properties: draftDevice.passport_values, catalog_id: draftDevice.passport_catalog_id },
            catalogs
          )}
          header={'common.details'}
        />
      )}

      <div className="system__grid">
        <InputRow label={intl.formatMessage({ id: 'catalogs.schema' })}>
          {formMode === 'view' && (selectedPassport?.name || '-')}
          {formMode === 'edit' && (
            <Select
              value={draftDevice.passport_catalog_id}
              name={''}
              onChange={handlePassportSchemaChange}
              options={passportsOptions}
            />
          )}
        </InputRow>
        {allowTemplateImport !== false && renderSaveAsTemplate()}
      </div>
      {selectedPassport && (
        <EquipmentPassportForm
          passport_values={draftDevice.passport_values}
          passport_schema={selectedPassport.schema_tree}
          onChange={handlePassportValuesChange}
          editing={formMode === 'edit'}
        />
      )}
      <FormButtons
        style={{ ...formButtonsStyle, position: 'sticky', bottom: 0, padding: '1em 0' }}
        mode={formMode}
        onEdit={() => setFormMode('edit')}
        onEditSave={handleSave}
        onCancel={() => setFormMode('view')}
        allowEditing={accessRights['equipment:update']}
        allowDeleting={false}
        isLoading={loading}
      >
        {renderExtraButtons()}
      </FormButtons>
    </div>
  )
}

export const applyEquipmentTemplate = (
  device: Equipment,
  passportTemplate: ICatalogItem,
  passports: ICatalogPassport[]
) => {
  const passport = passports.find((p) => p.id === passportTemplate.catalog_id)
  if (!passport) return device

  const passport_values = { ...device.passport_values }

  for (const property of passport.schema) {
    const id = property.id
    if (!property.autofill_mode) passport_values[id] = passportTemplate.properties[id]
  }

  return { ...device, passport_values, passport_catalog_id: passport.id }
}

export default EquipmentPassportPanel
