import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Form from 'shared/Forms/Form'
import useHttpLoader from '../../../../hooks/useHttpLoader'
import Dropdown from '../../../../shared/Inputs/Dropdown'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import http, { handleHttpError } from '../../../../utils/http'
import { showError, showInfo } from '../../../../utils/notifications'
import { loadCalendars } from '../../../Scheduler/Calendar/calendar.api'
import { CalendarProfile } from '../../../Scheduler/Calendar/calendar.state'

interface Props {
  file: File
  onClose: () => void
}

/**
 * Модальное окно загрузки ЧПН СО: после выбора файла пользователю необходимо
 * выбрать календарь, в который будут записаны выходные дни
 */
const SOPHUploadModal = ({ file, onClose }: Props) => {
  const intl = useIntl()
  const { loading, wait } = useHttpLoader()
  const [calendars, setCalendars] = useState<CalendarProfile[]>([])
  const [calendar, setCalendar] = useState<number>()

  const calendarOptions = calendars.map((c) => ({ value: c.id, title: c.name }))

  // загрузка списка календарей
  useEffect(() => {
    wait(loadCalendars(), (r) => {
      if (r.length > 0) setCalendars(r)
      else showError('system.aeps.upload_soph.empty_calendars_list')
    })
  }, [])

  // загрузка файла
  const handleSave = () => {
    if (!calendar) return

    const formData = new FormData()
    formData.append('csv', file)
    formData.append('calendar_id', calendar.toString())
    const headers = { 'content-type': 'multipart/form-data' }

    const successMessage = intl.formatMessage({ id: 'system.aeps.upload_soph.success' }, { name: file.name })

    const request = http
      .post('/nsi/v1/aeps/soph/files', formData, { headers })
      .then(() => showInfo(successMessage, successMessage))
      .catch(handleHttpError)

    wait(request, () => onClose())
  }

  return (
    <Modal onClose={onClose} closeOnClickOutside={false}>
      <h2>{intl.formatMessage({ id: 'system.aeps.upload_soph.select_calendar' })}</h2>

      <Form editing={true} onSubmit={handleSave}>
        <Dropdown value={calendar} options={calendarOptions} onChange={setCalendar} />
        <ModalFooter loading={loading} onCancel={onClose} onSave={handleSave} />
      </Form>
    </Modal>
  )
}

export default SOPHUploadModal
