import { PLacePicker } from '../../../../../../shared/Inputs/PlacePicker/PlacePicker'
import KeyValueTable from '../../../../../../shared/Forms/KeyValueTable/KeyValueTable'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { IGroup } from 'au-nsi-elevator/groups'

interface IProps {
  group: IGroup
  editing: boolean
  onChange: (value: any, key: keyof IGroup) => void
}

const GroupRequestFormLayout = (props: IProps) => {
  const renderGroupMeta = () => {
    const pairs = []
    pairs.push(['common.id', props.group.id])

    pairs.push([
      'common.description',
      <TextInput
        onChange={props.onChange}
        value={props.group.description}
        name="description"
        disabled={!props.editing}
      />,
    ])

    pairs.push([
      'nsi.address',
      props.editing ? (
        <PLacePicker
          selectedPlace={props.group.address}
          allowEditing={true}
          name="address"
          onChange={props.onChange}
          isEditing={props.editing}
          controlByParent={true}
        />
      ) : (
        props.group.address.name
      ),
    ])

    return <KeyValueTable pairs={pairs} />
  }

  return (
    <>
      {!props.editing && <h2>{props.group.name}</h2>}
      {props.editing && (
        <TextInput value={props.group.name} name="name" onChange={props.onChange} style={{ marginBottom: '2em' }} />
      )}
      {renderGroupMeta()}
    </>
  )
}

export default GroupRequestFormLayout
