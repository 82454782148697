import { AnyCatalogWithInstances } from '../../catalogs.types'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import TextInput from '../../../../shared/Inputs/TextInput'
import RemoveButton from '../../../../shared/Forms/RemoveButton'
import styles from '../../catalogs.module.css'
import useDnD from '../../../../hooks/useDnD'
import { getBooleanOptions } from '../../catalogs.utils'
import { useIntl } from 'react-intl'
import Datepicker from '../../../../shared/Inputs/Datepicker/Datepicker'
import { memo, useMemo, useRef, useState } from 'react'
import ToggleTableCell from '../../../../shared/Inputs/ToggleTableCell'
import TextareaInput from '../../../../shared/Inputs/TextareaInput'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/store.types'
import { IPropertyDescription } from 'au-nsi/catalogs'
import { Select, SelectOption } from '@alterouniversal/au-react-components'

interface BaseProps {
  row: IPropertyDescription
  editing: boolean
  onChange: (updatedRow: IPropertyDescription, name?: string) => void
  onRemove: (id: string, name?: string) => void
  typeOptions: SelectOption[]
  catalogsOptions: SelectOption[]
  userTypesOptions: SelectOption[]
  catalogs: AnyCatalogWithInstances[]
  renderDefaultValue?: boolean
  rowName?: string
}

// Для плоского случая для перемещения достачно индексов в массиве
interface IFlatProps extends BaseProps {
  index: number
  onMove: (index1: number, index2: number, name?: string) => void
}

// Для древовидного необходимы id
interface ITreeProps extends BaseProps {
  onTreeMove: (id1: IPropertyDescription['id'], id2: IPropertyDescription['id']) => void
}

type IProps = IFlatProps | ITreeProps

const SchemaRow = ({
  row,
  editing,
  typeOptions,
  onChange,
  catalogsOptions,
  catalogs,
  userTypesOptions,
  onRemove,
  renderDefaultValue = true,
  rowName,
  ...moveProps
}: IProps) => {
  const intl = useIntl()

  const organizationTypes = useSelector((state: ReduxState) => state.organizations.organizations)
  const organizationTypesOptions = useMemo(
    () => organizationTypes.map((org) => ({ label: org.name, value: org.id })),
    [organizationTypes]
  )

  const booleanOptions = getBooleanOptions(intl)
  const rowRef = useRef(null)

  const [draggable, setDraggable] = useState(true)

  const dndHandlers = useDnD({
    id: 'onMove' in moveProps ? moveProps.index : row.id,
    onDrop:
      'onMove' in moveProps
        ? (ind) => moveProps.onMove(moveProps.index, parseInt(ind), rowName)
        : (id) => moveProps.onTreeMove(id, row.id),
    prefix: 'catalogs:',
    ref: rowRef,
    style: {},
  })

  const handleChange = (value: any, name: string) => {
    const updates = { ...row, [name]: value }

    if (name === 'type' && row.type !== value) {
      updates.default_value = undefined
    }

    onChange(updates, rowName)
  }

  const TypeEditingCol = () => {
    const TypeSelect = (
      <Select value={row.type} name={'type'} onChange={handleChange} options={typeOptions} required={true} />
    )

    if (row.type === 'ref' || row.type === 'object' || row.type === 'organization_ref') {
      let options: SelectOption[]
      if (row.type === 'ref') options = catalogsOptions
      else if (row.type === 'object') options = userTypesOptions
      else if (row.type === 'organization_ref') options = organizationTypesOptions

      return (
        <div className={styles.catalogs__table_default_schema_ref}>
          {TypeSelect}
          <Select
            value={row.ref_catalog_id}
            name={'ref_catalog_id'}
            onChange={handleChange}
            options={options}
            required={true}
          />
        </div>
      )
    }

    return TypeSelect
  }

  const DefaultValueEditingCol = () => {
    let options: SelectOption[]

    if (row.type === 'ref' || row.type === 'object') {
      options = catalogs
        .find((c) => c.id === row.ref_catalog_id)
        ?.instances?.map((instance) => ({ label: instance.name, value: instance.id }))
    } else if (row.type === 'organization_ref') {
      options = organizationTypes
        .find((org) => org.id === row.ref_catalog_id)
        ?.instances?.map((instance) => ({ label: instance.name, value: instance.id }))
    } else if (row.type === 'boolean') {
      options = booleanOptions
    }

    options = options ?? []
    const props = { name: 'default_value', onChange: handleChange, required: false }

    switch (row.type) {
      case 'number':
        return <NumberInput {...props} value={row.default_value as number} fullWidth={true} allowUndefined={true} />
      case 'string':
        return <TextInput {...props} value={row.default_value as string} />
      case 'ref':
      case 'object':
      case 'organization_ref':
        return <Select {...props} value={row.default_value} options={options} />
      case 'date':
        return (
          <Datepicker
            name={'default_value'}
            date={row.default_value as string}
            onDateChange={handleChange}
            renderTime={false}
          />
        )
      case 'boolean':
        return <Select {...props} value={row.default_value} options={options} />
      case 'text':
        return <TextareaInput {...props} value={row.default_value as string} useWrapper={false} />
    }
  }

  const renderStatic = () => {
    let typeValue = typeOptions.find((o) => o.value === row.type)?.label
    let defaultValue: any = row.default_value

    if (row.type === 'ref' || row.type === 'object') {
      typeValue = catalogs.find((c) => c.id === row.ref_catalog_id)?.name
      defaultValue = catalogs
        .find((c) => c.id === row.ref_catalog_id)
        ?.instances?.find((inst) => inst.id === row.default_value)?.name
    } else if (row.type === 'boolean') {
      defaultValue = booleanOptions.find((o) => o.value === row.default_value)?.label
    } else if (row.type === 'text') {
      defaultValue = <span style={{ whiteSpace: 'pre-wrap' }}>{row.default_value}</span>
    } else if (row.type === 'organization_ref') {
      typeValue = organizationTypes.find((org) => org.id === row.ref_catalog_id)?.name
      defaultValue = organizationTypes
        .find((org) => org.id === row.ref_catalog_id)
        ?.instances?.find((instance) => instance.id === row.default_value)?.name
    }

    return (
      <tr>
        <td className={styles.catalogsTableTd}>{row.name}</td>
        <td className={styles.catalogsTableTd}>{typeValue ?? '-'}</td>
        {renderDefaultValue && <td className={styles.catalogsTableTd}>{defaultValue ?? '-'}</td>}
        <td className={styles.catalogsTableTd}>
          {intl.formatMessage({ id: row.required ? 'common.yes' : 'common.no' })}
        </td>
      </tr>
    )
  }

  const renderEditing = () => (
    <tr className={styles.catalogs_row} ref={rowRef} draggable={draggable} {...dndHandlers}>
      <td
        className={styles.catalogsTableTd}
        onMouseOver={() => setDraggable(false)}
        onMouseLeave={() => setDraggable(true)}
      >
        <TextInput value={row.name} name={'name'} onChange={handleChange} />
      </td>
      <td
        className={styles.catalogsTableTd}
        onMouseOver={() => setDraggable(false)}
        onMouseLeave={() => setDraggable(true)}
      >
        {TypeEditingCol()}
      </td>
      {renderDefaultValue && (
        <td
          className={styles.catalogsTableTd}
          onMouseOver={() => setDraggable(false)}
          onMouseLeave={() => setDraggable(true)}
        >
          {DefaultValueEditingCol()}
        </td>
      )}
      <ToggleTableCell checked={row.required} name={'required'} onChange={handleChange} />
      <td>
        <RemoveButton onRemove={() => onRemove(row.id, rowName)} />
      </td>
    </tr>
  )

  return editing ? renderEditing() : renderStatic()
}

export default memo(SchemaRow)
