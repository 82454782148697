import logoSrc from '../../images/icons/logo.svg'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import RuIcon from '../../icons/russia.svg'
import EnIcon from '../../icons/en.svg'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { systemPublicState } from '../System/system.state'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguage } from '../App/app.selectors'
import { API_URL } from '../../shared/constants'
import { setLanguage } from '../App/user.actions'
import { Lang } from 'au-nsi/shared'

interface IProps {
  children: React.ReactNode
  title: string
  buttonHelp?: string
  showSignin?: boolean
  showSignup?: boolean
}

/*
 * Хедер экрана авторизации.
 * Отображает название и логотип системы, позволяет менять язык.
 * Роутит между страницами логина и регистрации (если регистрация разрешена)
 */

export const AuthWrapper = ({ children, title, buttonHelp, showSignin, showSignup }: IProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const systemInfo = useRecoilValue(systemPublicState)
  const locale = useSelector(selectLanguage)

  const backgroundImage = systemInfo.logo
    ? `url("${API_URL}/nsi/v1/images/${systemInfo.logo}/raw")`
    : `url("${logoSrc}")`

  const handleLanguageChange = (v: Lang) => dispatch(setLanguage(v))

  return (
    <div className="login-screen">
      <div className="login-screen-background" style={{ backgroundImage: `url(${logoSrc})` }} />
      <div className="login-header">
        <div className="login-logo" style={{ backgroundImage }} />
        <div className="login-logo-title line_clamp" title={systemInfo.title}>
          {systemInfo.title}
        </div>
        <div className="login-register">
          <span className="register-title">{buttonHelp}</span>
          {showSignup && (
            <NavLink to="/signup">
              <button className="nsi-button inverted">{intl.formatMessage({ id: 'auth.signup' })}</button>
            </NavLink>
          )}
          {showSignin && (
            <NavLink to="/login">
              <button className="nsi-button inverted">{intl.formatMessage({ id: 'auth.signin' })}</button>
            </NavLink>
          )}

          <div className="login_header_lang_splitter" />
          <div className={'login_lang_selector'}>
            {locale === 'ru' && (
              <div onClick={() => handleLanguageChange('en')} className={'login_lang_selector_item'}>
                <div className={'login_lang_selector_flag'} style={{ background: `url(${RuIcon})` }} />
                <span>ru</span>
              </div>
            )}
            {locale === 'en' && (
              <div onClick={() => handleLanguageChange('ru')} className={'login_lang_selector_item'}>
                <div className={'login_lang_selector_flag'} style={{ background: `url(${EnIcon})` }} />
                <span>en</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <h2 className="login-form-header">{title}</h2>
      {children}
    </div>
  )
}
