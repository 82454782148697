import { Select } from '@alterouniversal/au-react-components'
import { IImageCondition } from 'au-nsi/dashboards'
import React from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import RemoveButton from '../../../shared/Forms/RemoveButton'
import Dropdown from '../../../shared/Inputs/Dropdown'
import NumberInput from '../../../shared/Inputs/NumberInput'
import ImageInput from '../../Libraries/Images/ImageInput'
import { selectDataSources } from '../../Nsi/nsi.selectors'
import { selectDenormalizedParameters } from '../../Parameters/params.selectors'
import { operatorOptions } from '../condition.utils'
import css from './image.module.css'

const ImageCondition = (props: Props) => {
  const intl = useIntl()
  const { condition } = props

  const data = useSelector((state: ReduxState) => {
    return {
      equipment: selectDataSources(state),
      parameters: selectDenormalizedParameters(state),
      prefixes: state.parameters.prefixes,
      units: state.parameters.units,
    }
  }, shallowEqual)

  const { equipment, parameters } = data
  const parameter = parameters.find((p) => p.id === condition.parameter_id)

  const parameterOptions = React.useMemo(() => parameters.map((p) => ({ value: p.id, label: p.name })), [parameters])
  const equipmentOptions = React.useMemo(() => equipment.map((e) => ({ value: e.id, label: e.name })), [equipment])

  const handleChange = (value: any, key: string) => {
    props.onChange(key, value, props.index)
  }

  return (
    <div className={css.condition}>
      <div className={css.conditionCell}>{intl.formatMessage({ id: 'dashboards.image.settings.show' })}</div>

      <div>
        <ImageInput name="image_id" value={condition.image_id} onChange={handleChange} />
      </div>

      <div className={css.conditionCell}>{intl.formatMessage({ id: 'dashboards.image.settings.if' })}</div>

      <div>
        <Select name="parameter_id" options={parameterOptions} value={condition.parameter_id} onChange={handleChange} />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <RemoveButton onRemove={() => props.onRemove(props.index)} />
      </div>

      <div className={css.conditionCell}>{intl.formatMessage({ id: 'dashboards.image.settings.device' })}</div>

      <div>
        <Select name="device_id" options={equipmentOptions} value={condition.device_id} onChange={handleChange} />
      </div>

      <div>
        <Dropdown name="condition" value={condition.condition} options={operatorOptions} onChange={handleChange} />
      </div>

      <div>
        <NumberInput
          type="parameter"
          name="value"
          fullWidth={true}
          value={condition.value}
          onChange={handleChange}
          baseUnit={parameter && parameter.unit}
          basePrefix={parameter && parameter.prefix}
          displayUnit={parameter && parameter.display_unit}
          displayPrefix={parameter && parameter.display_prefix}
          units={data.units}
          prefixes={data.prefixes}
        />
      </div>
    </div>
  )
}

interface Props {
  index: number
  condition: IImageCondition
  onRemove: (index: number) => void
  onChange: (key: string, value: string, index: number) => void
}

export default ImageCondition
