import { Equipment } from 'au-nsi/equipment'
import Checkbox from '../Inputs/Checkbox/Checkbox'
import RegistratorCell from './RegistratorCell'

const CellWithToggle = (props: CellProps) => {
  const { registrator } = props

  const handleChange = () => {
    props.onChange(registrator.id)
  }

  return (
    <RegistratorCell registrator={registrator} danger={props.danger} warning={props.warning}>
      <Checkbox checked={props.visible} onChange={handleChange} style={{ display: 'inline-flex' }} />
    </RegistratorCell>
  )
}

interface CellProps {
  registrator: Equipment
  visible: boolean
  danger?: boolean
  warning?: boolean
  onChange: (id: string) => void
}

export default CellWithToggle
