import { useIntl } from 'react-intl'
import MultiSelect from '../../../../../shared/Inputs/MultiSelect'
import produce from 'immer'
import RemoveButton from '../../../../../shared/Forms/RemoveButton'
import PlusButton from '../../../../../shared/Forms/PlusButton'

interface IProps {
  name: string
  header: string
  devices: string[][]
  deviceOptions: any
  acceptChange: boolean
  onChange: (devices: string[][], name: string) => void
}

export const PmuCheckDeviceTable = ({ name, header, devices, deviceOptions, acceptChange, onChange }: IProps) => {
  const intl = useIntl()

  const handleChange = (selection: string[], nm: string) => {
    const i = +nm

    onChange(
      produce(devices, (draft) => {
        draft[i] = selection
      }),
      name
    )
  }

  const handleRemove = (index: number) =>
    onChange(
      produce(devices, (draft) => {
        draft.splice(index, 1)
      }),
      name
    )

  const handleAdd = () => onChange([...devices, []], name)

  return (
    <>
      <table className={'nsi-settings-table'} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: header })}</th>
            {acceptChange && <th style={{ width: '24px' }} />}
          </tr>
        </thead>
        <tbody>
          {devices.length === 0 && !acceptChange && (
            <tr>
              <td>-</td>
            </tr>
          )}
          {devices.map((devices, ind) => (
            <tr key={header + ind}>
              <td>
                <MultiSelect
                  onChange={handleChange}
                  selection={devices}
                  options={deviceOptions}
                  disabled={!acceptChange}
                  name={ind + ''}
                />
              </td>
              {acceptChange && (
                <td align="center">
                  <RemoveButton onRemove={() => handleRemove(ind)} id={'Remove'} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {acceptChange && (
        <PlusButton
          onClick={handleAdd}
          style={{ marginLeft: '0' }}
          textId={intl.formatMessage({ id: 'system.verification.addGroup' })}
        />
      )}
    </>
  )
}
