import { createSelector } from 'reselect'
import { ReduxState } from '../../redux/store.types'
import { selectReceivers } from '../Nsi/nsi.selectors'

const getSelectedId = (state: ReduxState) => state.lfo.registratorId
const getSearch = (state: ReduxState) => state.lfo.search

// показываем графики НЧК только для устройств заданных в настройках НЧК
const getDeviceFilter = (state: ReduxState) => state.service_settings['ms-lfo']?.equipment as string[]

export const getMatchedDevices = createSelector(
  [selectReceivers, getDeviceFilter, getSearch],
  (devices, filter, search) => {
    const selectedDevices = new Set(filter || [])
    const result = devices.filter((d) => selectedDevices.has(d.id))

    if (!search) return result

    const s = search.toLowerCase()
    return result.filter((d) => d.name.toLowerCase().includes(s) || d.shortname.toLowerCase().includes(s))
  }
)

export const getSelectedDevices = createSelector([getMatchedDevices, getSelectedId], (registrators, id) =>
  registrators.filter((r) => r.id === id)
)
