import { UserRole } from 'au-nsi/user'
import http, { handleHttpError } from '../../../utils/http'
import { generateActions } from '../collection.actions'
import { SET_ACCESS_RIGHTS } from './roles.reducers'

const URL = '/nsi/roles/'

const actions = generateActions<UserRole>('roles', URL)

const loadAccessRights = () => (dispatch, getState) => {
  const isLoaded = getState().roles.rightsLoaded
  if (isLoaded) return

  return http
    .get('/nsi/rights')
    .then(({ data }) => dispatch({ type: SET_ACCESS_RIGHTS, accessRights: data }))
    .catch((err) => handleHttpError(err))
}

const loadItems = () => (dispatch, getState) => {
  const accessRights = getState().user.access_rights

  if (accessRights['roles:get']) dispatch(actions.loadItems())
}

const rolesActions = { ...actions, loadAccessRights, loadItems }
export default rolesActions
