import { IChartSettings, IChartSettings2 } from 'au-nsi/dashboards'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectEquipmentMap } from '../../../pages/Nsi/nsi.selectors'
import { selectParametersNames } from '../../../pages/Parameters/params.selectors'
import { extractLines } from '../utils/version.utils'

/**
 * Легенда линейного графика
 */
const ChartLegend = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>()
  const lines = extractLines(props.settings)

  const equipment = useSelector(selectEquipmentMap)
  const parameters = useSelector(selectParametersNames)

  // Через CSS невозможно сделать так чтобы легенда занимала место внизу компонента
  // и по высоте определялась своим содержимым, а сам график занимал все остальное место вверху.
  // Поэтому у легенды задается position: absolute у нижнего края, затем вычисляется ее высота
  // и после этого у графика выставляется высота равная оставшемуся свободному месту
  React.useEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    props.onHeightChange(rect.height)
  }, [props.lastResize, lines, equipment.size, parameters.size])

  React.useEffect(() => () => props.onHeightChange(0), [])

  // при клике по линии либо скрываем ее либо показываем если она уже была скрыта
  const handleClick = (e) => {
    const i = +e.currentTarget.dataset.id
    const updates = new Set(props.linesHidden)

    if (updates.has(i)) updates.delete(i)
    else updates.add(i)

    props.onToggle(updates)
  }

  // список всех линий указанных в настройках линейного графика (цвет, устройство, параметр)
  const items = lines.map((line, i) => {
    let className = 'line-chart__legend-item'
    if (props.linesHidden.has(i)) className += ' is-hidden'

    const device = equipment.get(line.device_id)
    const parameter = parameters.get(line.parameter_id)

    return (
      <div key={i} data-id={i} className={className} onClick={handleClick}>
        <span className="line-chart__legend-line" style={{ background: line.color }} />
        <span>
          {device && device.shortname} {parameter}
        </span>
      </div>
    )
  })

  return (
    <div ref={ref} className="line-chart__legend">
      {items}
    </div>
  )
}

interface Props {
  lastResize: number
  linesHidden: Set<number>
  onHeightChange: (height: number) => void
  onToggle: (linesHidden: Set<number>) => void
  settings: IChartSettings | IChartSettings2
}

export default React.memo(ChartLegend)
