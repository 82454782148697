import { Select, SelectOption } from '@alterouniversal/au-react-components'
import { ISVGAnimationsSettings } from 'au-nsi/dashboards'
import { SiPrefix, SiUnit } from 'au-nsi/parameters'
import { memo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import FormPanel from '../../../../shared/FormPanel/FormPanel'
import RemoveButton from '../../../../shared/Forms/RemoveButton'
import HelpTooltip from '../../../../shared/HelpTooltip/HelpTooltip'
import Dropdown, { DropdownOption } from '../../../../shared/Inputs/Dropdown'
import NumberInput from '../../../../shared/Inputs/NumberInput'
import Toggle from '../../../../shared/Inputs/Toggle'
import { ParameterDn } from '../../../Parameters/params.interfaces'
import css from '../svg.module.css'
import { directionOptions, svgAnimation } from './animations.utils'

interface IProps {
  deviceOptions: SelectOption[]
  index: number
  nodeOptions: SelectOption[]
  onChange: (key: string, value: string, index: number) => void
  svg: SVGSVGElement
  onNodeHover: (id: string) => void
  onRemove: (index: number) => void
  operatorOptions: DropdownOption<string>[]
  parameter: ParameterDn
  parameterOptions: SelectOption[]
  row: ISVGAnimationsSettings
  units: Record<string, SiUnit>
  prefixes: Record<string, SiPrefix>
}

const SettingsAnimationsRow = (props: IProps) => {
  const { row, index, parameter } = props

  const intl = useIntl()
  const locDirectionOptions = directionOptions[row.type].map((option) => {
    return {
      ...option,
      title: intl.formatMessage({ id: 'dashboards.svg_diagram.settings.animations.' + option.label }),
    }
  })

  const [settingsOpen, setSettingsOpen] = useState(false)

  useEffect(() => {
    svgAnimation.prepareSVG([row], props.svg)
    if (settingsOpen) {
      svgAnimation.animate(row, props.svg, true)
      return () => {
        svgAnimation.cancelAnimations([row], props.svg, true)
      }
    }
  }, [row, settingsOpen])

  const handleChange = (value: any, key: string) => {
    if (key === 'node_id') {
      svgAnimation.cancelAnimations([row], props.svg)
    }
    if (key === 'rotate_one_way') {
      value && handleChange(360, 'distance')
      value && row.cycle && handleChange(!value, 'cycle')
    }
    if (key === 'cycle' && value && row.rotate_one_way) {
      handleChange(!value, 'rotate_one_way')
    }

    props.onChange(key, value, index)
  }

  const handleRemove = () => {
    props.onRemove(index)
  }

  const renderAnimationSettings = () => {
    return (
      <FormPanel
        id={index.toString()}
        open={settingsOpen}
        title={'dashboards.svg_diagram.settings.animations.title'}
        onToggle={() => setSettingsOpen(!settingsOpen)}
        minified={true}
      >
        <div style={{ padding: '20px', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div>
            <div className={css.animation_settings_block}>
              <span style={{ minWidth: '200px' }}>
                {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.direction' })}:
              </span>
              <Dropdown
                fullwidth={true}
                name={'direction'}
                options={locDirectionOptions}
                onChange={handleChange}
                value={row.direction}
              />
            </div>
            <div className={css.animation_settings_block}>
              <span style={{ minWidth: '200px' }}>
                <div>{intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.duration' })}:</div>
              </span>
              <NumberInput
                type={'parameter'}
                units={props.units}
                prefixes={props.prefixes}
                name={'duration'}
                onChange={handleChange}
                fullWidth={true}
                value={row.duration}
                displayUnit={props.units['s']}
                baseUnit={props.units['s']}
                min={0.01}
              />
            </div>
            <div className={css.animation_settings_block}>
              <span style={{ minWidth: '200px' }}>
                {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.distance' })}:
              </span>
              <NumberInput
                type={'parameter'}
                units={props.units}
                prefixes={props.prefixes}
                name={'distance'}
                disabled={row.rotate_one_way}
                onChange={handleChange}
                fullWidth={true}
                value={row.distance}
                displayUnit={row.type === 'rotations' ? props.units['deg'] : props.units['percent']}
                baseUnit={row.type === 'rotations' ? props.units['deg'] : props.units['percent']}
                min={0.01}
              />
              {row.type === 'movements' && (
                <HelpTooltip
                  position={'top'}
                  style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.animations.movement_description' })}
                </HelpTooltip>
              )}
            </div>
            <div className={css.animation_settings_block} style={{ marginBottom: '10px' }}>
              <span style={{ width: '200px' }}>
                {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.cycle' })}:
              </span>
              <div style={{ alignItems: 'center', display: 'flex', marginLeft: '-16px' }}>
                <Toggle checked={row.cycle} onChange={handleChange} name={'cycle'} />
              </div>
            </div>
            {row.type === 'rotations' && (
              <div className={css.animation_settings_block}>
                <span style={{ width: '200px' }}>
                  {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.rotate_one_way' })}:
                </span>
                <div style={{ alignItems: 'center', display: 'flex', marginLeft: '-16px' }}>
                  <Toggle checked={row.rotate_one_way} onChange={handleChange} name={'rotate_one_way'} />
                </div>
              </div>
            )}
            {row.type === 'movements' && (
              <div className={css.animation_settings_block}>
                <span style={{ width: '200px' }}>
                  {intl.formatMessage({ id: 'dashboards.svg_diagram.settings.movements.hide_self' })}:
                </span>
                <div style={{ alignItems: 'center', display: 'flex', marginLeft: '-16px' }}>
                  <Toggle checked={row.hide_self} onChange={handleChange} name={'hide_self'} />
                </div>
              </div>
            )}
          </div>
        </div>
      </FormPanel>
    )
  }

  return (
    <>
      <tr>
        <td>
          <Select
            name="node_id"
            options={props.nodeOptions}
            value={row.node_id}
            onChange={handleChange}
            onHover={props.onNodeHover}
            required={true}
          />
        </td>
        <td>
          <Select
            name="device_id"
            options={props.deviceOptions}
            value={row.device_id}
            onChange={handleChange}
            required={true}
          />
        </td>
        <td>
          <Select
            name="parameter_id"
            options={props.parameterOptions}
            value={row.parameter_id}
            onChange={handleChange}
            required={true}
          />
        </td>
        <td>
          <Dropdown
            name="condition"
            options={props.operatorOptions}
            value={row.condition}
            onChange={handleChange}
            compact={true}
          />
        </td>
        <td>
          <NumberInput
            fullWidth={true}
            type="parameter"
            name="value"
            value={row.value}
            onChange={handleChange}
            units={props.units}
            prefixes={props.prefixes}
            baseUnit={parameter && parameter.unit}
            basePrefix={parameter && parameter.prefix}
            displayUnit={parameter && parameter.display_unit}
            displayPrefix={parameter && parameter.display_prefix}
          />
        </td>
        <td align="center" style={{ width: '3ch' }}>
          <RemoveButton id="" onRemove={handleRemove} />
        </td>
      </tr>
      <tr>
        <td colSpan={5}>{renderAnimationSettings()}</td>
      </tr>
    </>
  )
}

export default memo(SettingsAnimationsRow)
