import { ISVGAnimationsSettings } from 'au-nsi/dashboards'

const addedTransformSuffix = '  '

export const getSourceTransform = (element: HTMLElement | Element): string => {
  /*
    При добавлении трансформации к svg элементу ('g', 'path', 'group' etc)
    она ставится на первое место для корректного применения
    (в случае, например, уже существующего rotate, ставить после него translate является ошибкой:
    элемент будет перемещаться по изменённой оси)

     При добавление свойства/(группы свойств) к ним приписывается суффикс - двойной пробел(addedTransformSuffix),
     для различения добавленных свойств и уже существующих

     Метод возвращает существоваший до любых изменений transform.
  */

  const transform = element.getAttribute('transform')
  if (!transform) return ''

  let sourceTransform: string | string[] = transform ? transform.split('  ') : ['']

  if (sourceTransform.length === 2) sourceTransform = sourceTransform[1]
  else sourceTransform = sourceTransform[0]

  return sourceTransform
}

export const setSvgTransform = (element: HTMLElement | Element, transform: string): void => {
  const sourceTransform = getSourceTransform(element)
  element.setAttribute('transform', transform + addedTransformSuffix + sourceTransform)
}

export const svgWrap = (element: HTMLElement, setting: ISVGAnimationsSettings, mark) => {
  /*
    Оборачиваем svg элемент в другой (<svg> или <g> в зависимости от настройки) и ставит пометку о том, что
    это добавленный нами элемент (mark)
  */
  const node_id = setting.node_id
  const parent = element.parentElement
  const wrapper = document.createElementNS('http://www.w3.org/2000/svg', setting.hide_self ? 'svg' : 'g')
  setting.hide_self && wrapper.setAttribute('fill', 'auto')
  wrapper.setAttribute('id', node_id + mark)
  parent.appendChild(wrapper)
  wrapper.appendChild(element)
}

export const svgUnwrap = (element) => {
  /*
    Отменям действия svgWrap()
  */
  const srcBlock = element.firstChild.cloneNode(true)
  const parent = element.parentElement
  element.remove()

  parent.appendChild(srcBlock)
}
