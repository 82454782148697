import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SearchIcon } from '../../../../icons/search.svg'
import { ReduxState } from '../../../../redux/store.types'
import SearchInput from '../../../../shared/Inputs/Search/SearchInput'
import * as utils from '../../../../utils/search'
import Loader from '../../../../shared/Utils/Loader'
import http from '../../../../utils/http'
import css from '../docs.module.css'
import { actions } from '../docs.reducers'
import { openFile } from '../docs.utils'
import DocumentIcon from './DocumentIcon'
import { IDocument } from 'au-nsi/documents'
import { showError } from '../../../../utils/notifications'

/**
 * Поиск по библиотеке документов
 */
const DocumentsSearch = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const documents = useSelector((state: ReduxState) => state.documents.items)
  const token = useSelector((state: ReduxState) => state.auth.accessToken)

  const [search, setSearch] = useState('')
  const [results, setResults] = useState<IDocument[]>([])
  const [loading, setLoading] = useState(true)

  const { selection } = props
  const searchTerms = search.toLowerCase().split(/\s/g)

  // сброс выбранных элементов при переходе от сетки к поиску
  useEffect(() => {
    if (selection.length > 0) props.onSelect([])
  }, [])

  // запрос в НСИ для получения документов по введенному названию
  useEffect(() => {
    let cancelled = false

    let url = '/nsi/v1/documents?'
    if (search) url += 'limit=50&search=' + encodeURIComponent(search)
    else url += 'order_by=updated_at&limit=10&type=file'
    if (props.resource) url += '&resource=' + props.resource
    if (props.resource_item_id) url += '&resource_item_id=' + props.resource_item_id

    setLoading(true)
    http
      .get(url)
      .then((r) => !cancelled && setResults(r.data))
      .catch((err) => console.error(err))
      .finally(() => !cancelled && setLoading(false))

    return () => {
      cancelled = true
    }
  }, [search])

  const findDocument = (e: MouseEvent): IDocument => {
    e.stopPropagation()
    const id = (e.currentTarget as HTMLDivElement).dataset.id
    return results.find((d) => d.id === id)
  }

  // выбор элемента по клику
  const handleClick: MouseEventHandler = (e) => {
    const { id } = findDocument(e)
    if (!e.ctrlKey) return props.onSelect([id])

    const isAlreadySelected = selection.includes(id)
    props.onSelect(isAlreadySelected ? selection.filter((e) => e !== id) : [...selection, id])
  }

  // по двойному клику открытие папки или файла
  const handleDoubleClick = (e) => {
    const doc = findDocument(e)
    doc.is_folder ? navigate(null, doc.id) : openFile(doc, token)
  }

  // переход к родительской папке при клике на путь элемента
  const handlePathClick: MouseEventHandler = (e) => {
    const doc = findDocument(e)
    navigate(doc.id, doc.parent_id)
  }

  // переход к указанной папке
  const navigate = (docId: string, folderId: string) => {
    setLoading(true)

    loadParents(folderId)
      .then((path) => {
        dispatch(actions.setPath(path))
        props.onCancel()
        docId && props.onSelect([docId])
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
        showError('documents.errors.path_not_found')
      })
  }

  // загрузка всей цепочки родительских элементов указанной папки
  const loadParents = async (folderId: string) => {
    const path: string[] = []
    let nextParentId = folderId

    while (nextParentId != null) {
      let nextParent = documents.find((d) => d.id === nextParentId) // eslint-disable-line

      if (!nextParent) {
        let url = '/nsi/v1/documents?ids=' + nextParentId
        if (props.resource) url += '&resource=' + props.resource
        if (props.resource_item_id) url += '&resource_item_id=' + props.resource_item_id

        const r = await http.get(url)
        dispatch(actions.addDocuments(r.data))
        nextParent = r.data[0]
      }

      path.unshift(nextParentId)
      nextParentId = nextParent.parent_id
    }

    path.unshift(null)
    return path
  }

  const list = results.map((doc) => {
    const name = doc.is_folder ? doc.name : doc.name + '.' + doc.extension
    const isSelected = selection.includes(doc.id)
    const className = isSelected ? css.searchResult + ' ' + css.selectedDocument : css.searchResult

    const matches = utils.search(doc.full_path, searchTerms)

    return (
      <div key={doc.id} data-id={doc.id} className={className} onClick={handleClick} onDoubleClick={handleDoubleClick}>
        <DocumentIcon document={doc} />
        <div>
          <div>{name}</div>
          <div data-id={doc.id} className={css.searchResultPath} onClick={handlePathClick}>
            {utils.renderSearchResult(matches)}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <div className={css.header}>
        <SearchInput onChange={setSearch} autofocus={true} throttling={800} />

        <div className={css.searchIcon} onClick={props.onCancel} style={{ backgroundColor: 'var(--bg-selected)' }}>
          <SearchIcon width={20} height={20} />
        </div>
      </div>

      <div style={{ position: 'relative', opacity: loading ? 0.8 : 1 }}>
        {loading && (
          <div className={css.searchLoader}>
            <Loader />
          </div>
        )}

        {!loading && results.length === 0 && (
          <div className="text--gray text--center" style={{ margin: 14 }}>
            {intl.formatMessage({ id: 'common.no_results' })}
          </div>
        )}

        <div style={{ maxHeight: props.maxHeight, overflowY: 'auto' }}>{list}</div>
      </div>
    </div>
  )
}

interface Props {
  selection: string[]
  onSelect: (selection: string[]) => void
  onCancel: () => void
  maxHeight?: string
  resource?: string
  resource_item_id?: string | number
}

export default DocumentsSearch
