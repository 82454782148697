import { Dispatch, useState, SetStateAction, useMemo } from 'react'
import { Primitive } from '../shared/interfaces'
import LocalStorageStrategy, {
  ILSCodec,
} from '../utils/percistenceStrategies/LocalStorageStrategy/LocalStorageStrategy'

/**
 * On unmount сохраняет последний стейт в localStorage по ключу {stateKey}.
 * On mount пытается взять последний сохраненённый стейт из localStorage. В случае, если его нет - использует {initialState}
 */
const usePersistentState = <T extends Object | Array<unknown> | Set<unknown> | Primitive>(
  initialState: T,
  stateKey: string,
  codec: ILSCodec<T> = JSON
): [T, Dispatch<SetStateAction<T>>] => {
  const strategy = useMemo(() => new LocalStorageStrategy<T>(codec), [codec])
  const [state, setState] = useState<T>(strategy.get(stateKey) ?? initialState)

  const setBothStates: Dispatch<SetStateAction<T>> = (arg) => {
    setState((prev) => {
      const updates = typeof arg === 'function' ? arg(prev) : arg
      strategy.set(stateKey, updates)
      return updates
    })
  }

  return [state, setBothStates]
}

export default usePersistentState
