import { IntlShape } from 'react-intl'

export const dateTimeMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
]

export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

export const dateTimeRegex = /\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2}/
export const dateRegex = /\d{2}\.\d{2}\.\d{4}/

export const dateTimeFormat = 'dd.MM.yyyy HH:mm:ss'
export const dateFormat = 'dd.MM.yyyy'

export const getLocWeekDays = (intl: IntlShape) =>
  ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) =>
    intl.formatMessage({ id: 'calendar.' + day })
  )

export const getLocMonths = (intl: IntlShape) =>
  [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ].map((month) => intl.formatMessage({ id: 'calendar.' + month }))
