import axios from 'axios'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { API_URL } from '../../shared/constants'
import PasswordInput from '../../shared/Inputs/PasswordInput'
import Loader from '../../shared/Utils/Loader'
import idb from '../../utils/idb'
import { selectLanguage } from '../App/app.selectors'
import { actions } from './auth.reducers'
import { getResponseErrorMessage } from './auth.utils'
import { AuthWrapper } from './AuthWrapper'

const LoginForm = ({ allowRegister }: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const lang = useSelector(selectLanguage)

  const [state, setState] = React.useState({
    username: '',
    password: '',
    rememberMe: true,
    error: false,
    errorMessage: '',
    isLoading: false,
    '2fa_code': '',
    '2fa_method': '',
  })

  // clear all local cache that can be left
  React.useEffect(() => {
    localStorage.clear()
    idb.clear()
  }, [])

  const handleUsernameChange = (e) => setState({ ...state, username: e.target.value })
  const handlePasswordChange = (e) => setState({ ...state, password: e })
  const handleCheckboxChange = (e) => setState({ ...state, rememberMe: e.target.checked })
  const handleCodeChange = (e) => setState({ ...state, '2fa_code': e.target.value })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (state.isLoading) return

    const { username, password, rememberMe } = state
    const code = state['2fa_code']
    setState({ ...state, isLoading: true })

    axios
      .post(API_URL + '/nsi/v2/auth/login', { username, password, rememberMe, lang, code }, { withCredentials: true })
      .then((r) => {
        // успешный вход
        if (r.data.status === 'success') {
          const { accessToken, expires } = r.data.payload
          const action = actions.setToken(accessToken, expires)
          dispatch(action)

          // возвращение на предыдущую страницу до редиректа на форму авторизации
          const page = (location.state as any)?.from || '/'
          navigate(page)
        }

        // у пользователя включена 2FA и необходимо дополнительно ввести отправленный код
        if (r.data.status === 'confirmation_required') {
          setState((prev) => ({ ...prev, '2fa_method': r.data.payload.method, error: false, isLoading: false }))
        }
      })
      .catch((err) => {
        const message = getResponseErrorMessage(err, intl)
        setState((prev) => ({ ...prev, error: true, errorMessage: message, isLoading: false }))
      })
  }

  // основная форма - ввод логина и пароля
  const credentialsForm = (
    <>
      <div className="login-form__row">
        <label htmlFor="username">
          {intl.formatMessage({ id: 'auth.signin.username' })}
          <input
            className="nsi-input login-form__input"
            id="username"
            autoComplete="username"
            required={true}
            value={state.username}
            onChange={handleUsernameChange}
          />
        </label>
      </div>
      <div className="login-form__row">
        <label htmlFor="password">
          {intl.formatMessage({ id: 'auth.signin.password' })}
          <PasswordInput
            className="nsi-input login-form__input"
            required={true}
            value={state.password}
            name={''}
            onChange={handlePasswordChange}
            autocomplete={'current-password'}
          />
        </label>
      </div>
      <div onClick={() => navigate('/login/recover')} className="login-form__reset-link">
        {intl.formatMessage({ id: 'auth.signin.forgot_password' })}
      </div>
    </>
  )

  // дополнительная форма для пользователей с 2FA - ввод отправленного кода
  const confirmationCodeForm = (
    <div className="login-form__row">
      <label htmlFor="code">
        {intl.formatMessage({ id: 'auth.signin.2fa_code.' + state['2fa_method'] })}
        <input
          className="nsi-input login-form__input"
          id="code"
          autoComplete="off"
          required={true}
          value={state['2fa_code']}
          onChange={handleCodeChange}
        />
      </label>
    </div>
  )

  return (
    <AuthWrapper
      title={intl.formatMessage({ id: 'auth.signin' })}
      showSignup={allowRegister}
      buttonHelp={allowRegister ? intl.formatMessage({ id: 'auth.no_account' }) : null}
    >
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          {state['2fa_method'] ? confirmationCodeForm : credentialsForm}

          <div className="login-submit-form">
            <div className="login-form__row">
              <label htmlFor="remember" className="login-form__checkbox">
                <input type="checkbox" id="remember" checked={state.rememberMe} onChange={handleCheckboxChange} />
                <span>{intl.formatMessage({ id: 'auth.signin.remember_me' })}</span>
              </label>
            </div>
            <button type="submit" className="nsi-button default">
              {state.isLoading ? <Loader /> : intl.formatMessage({ id: 'auth.signin' })}
            </button>
          </div>
        </form>
      </div>
      {state.error && <div className="login-form__error">{state.errorMessage}</div>}
    </AuthWrapper>
  )
}

interface Props {
  allowRegister: boolean
}

export default LoginForm
