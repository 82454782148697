import { AnyCatalogWithInstances } from '../../../../Catalogs/catalogs.types'
import { Equipment } from 'au-nsi/equipment'
import Modal from '../../../../../shared/Modal/Modal'
import { useCallback, useState } from 'react'
import { createLogbookEntry } from '../logbooks.utils'
import { FormMode } from '../../../../../shared/interfaces'
import LogbookEntryForm from './LogbookEntryForm'
import { useIntl } from 'react-intl'
import useInstanceFormCallback from '../../../../Catalogs/components/InstanceForm/useInstanceFormCallback'
import { validateItemValues } from '../../../../Catalogs/catalogs.validators'
import { ILogbookEntry } from 'au-nsi/logbook'
import { showError } from '../../../../../utils/notifications'

interface IProps {
  device: Equipment
  logbook: AnyCatalogWithInstances
  onCreateSave?: (logbook: ILogbookEntry) => void
  onClose: () => void
  initialMode: FormMode
  logbookEntry?: ILogbookEntry
  loading?: boolean
}

const DeviceLogbookModal = ({ device, logbook, onClose, onCreateSave, initialMode, logbookEntry, loading }: IProps) => {
  const intl = useIntl()
  const [draftLogbook, setDraftLogbook] = useState(logbookEntry ?? createLogbookEntry(device.id, logbook))
  const handlePropertiesChange = useInstanceFormCallback({ setter: setDraftLogbook, propertiesKey: 'values', deps: [] })

  const handleCreateSave = () => {
    if (validateItemValues(logbook.schema_tree, draftLogbook.values)) {
      onCreateSave(draftLogbook)
    } else {
      showError(intl.formatMessage({ id: 'router.errors.all_fields_must_be_filled' }))
    }
  }

  const handleChange = useCallback((value: any, name: string) => {
    setDraftLogbook((prev) => ({ ...prev, [name]: value }))
  }, [])

  return (
    <Modal size={'lg'} onClose={onClose} style={{ fontSize: '16px' }}>
      <LogbookEntryForm
        logbookEntry={draftLogbook}
        logbook={logbook}
        onPropertiesChange={handlePropertiesChange}
        onChange={handleChange}
        loading={loading}
        formMode={initialMode}
        onCreateSave={handleCreateSave}
        onCancel={onClose}
      />
    </Modal>
  )
}

export default DeviceLogbookModal
