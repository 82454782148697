import { LoaderSpinner } from '@alterouniversal/au-react-components'
import React from 'react'

type LoaderProps = React.ComponentProps<typeof LoaderSpinner>

const Loader = (props: LoaderProps) => <LoaderSpinner {...props} />

export const LoaderRow = (props: LoaderProps) => {
  return (
    <div className="loader__row">
      <Loader {...props} />
    </div>
  )
}

export default Loader
