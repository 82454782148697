import InputRow from '../../../../../shared/Inputs/InputRow'
import TextInput from '../../../../../shared/Inputs/TextInput'
import InstanceRow from '../../../components/InstanceForm/InstanceRow'
import FormButtons from '../../../../../shared/Forms/FormButtons'
import { OrganizationTypeWithInstances } from '../../organizations.types'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { FormMode } from '../../../../../shared/interfaces'
import { selectAllCatalogs, selectCatalogsAccessRights } from '../../../catalogs.selectors'
import { Organization } from 'au-nsi/organizations'
import Form from '../../../../../shared/Forms/Form'

interface IProps {
  formMode: FormMode
  organization: Organization
  organizationType: OrganizationTypeWithInstances

  onChange?: (org: Organization) => void
  onEdit?: () => void
  onEditSave?: () => void
  onCreateSave?: () => void
  onDelete?: () => void
  onDeleteConfirm?: () => void
  onCancel?: () => void

  allowEditing?: boolean
  allowDeleting?: boolean

  shouldRenderName?: boolean
  shouldRenderFormButtons?: boolean
  loading?: boolean
}

const OrganizationForm = ({
  organization,
  organizationType,
  onChange,
  formMode,
  onDeleteConfirm,
  onDelete,
  onCreateSave,
  onEditSave,
  onEdit,
  onCancel,
  allowDeleting,
  allowEditing,
  shouldRenderFormButtons = true,
  shouldRenderName = true,
  loading,
}: IProps) => {
  const intl = useIntl()
  const catalogs = useSelector(selectAllCatalogs)
  const rights = useSelector(selectCatalogsAccessRights)

  const handlePropertyChange = (schemaId: string, val: any) => {
    const updated: Organization = {
      ...organization,
      organization_properties: { ...organization.organization_properties, [schemaId]: val },
    }

    onChange(updated)
  }

  const editing = formMode === 'edit' || formMode === 'create'

  const renderName = () => {
    return editing ? (
      <div className={'system__grid'}>
        <InputRow label={intl.formatMessage({ id: 'catalogs.organization.name' })}>
          <TextInput
            value={organization.name}
            onChange={(name) => onChange({ ...organization, name })}
            name={'name'}
            required={true}
          />
        </InputRow>
        <InputRow label={intl.formatMessage({ id: 'catalogs.organization.shortname' })}>
          <TextInput
            required={false}
            maxlength={8}
            value={organization.shortname ?? ''}
            name={'shortname'}
            onChange={(shortname) => onChange({ ...organization, shortname })}
          />
        </InputRow>
      </div>
    ) : (
      <h2>
        {organization.name}
        <span className="text--gray">{organization.shortname && ` (${organization.shortname})`}</span>
      </h2>
    )
  }

  const renderThead = () => (
    <tr>
      <th>{intl.formatMessage({ id: 'catalogs.schema_property' })}</th>
      <th>{intl.formatMessage({ id: 'catalogs.value' })}</th>
    </tr>
  )

  if (!organization || !organizationType) return <></>

  const tableClass = formMode === 'view' ? 'nsi-settings-table is-striped' : 'nsi-settings-table'
  return (
    <Form editing={editing} onSubmit={formMode === 'create' ? onCreateSave : onEditSave}>
      {shouldRenderName && renderName()}
      <table className={tableClass} style={{ tableLayout: 'fixed' }}>
        <thead>{renderThead()}</thead>
        <tbody>
          {organizationType.organization_schema.map((schema) => (
            <InstanceRow
              key={schema.id}
              value={organization.organization_properties[schema.id]}
              rowSchema={schema}
              editing={editing}
              onChange={handlePropertyChange}
              catalogs={catalogs}
            />
          ))}
        </tbody>
      </table>
      {shouldRenderFormButtons && (
        <FormButtons
          style={{ marginBottom: 0 }}
          mode={formMode}
          onEdit={onEdit}
          onDelete={onDelete}
          onDeleteConfirm={onDeleteConfirm}
          onCancel={onCancel}
          allowEditing={allowEditing ?? rights.allowInstancesEditing}
          allowDeleting={allowDeleting ?? rights.allowInstancesEditing}
          isLoading={loading}
        />
      )}
    </Form>
  )
}

export default OrganizationForm
