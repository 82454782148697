const SEC = 1000
const MIN = 60 * SEC
const HOUR = 60 * MIN
const DAY = 24 * HOUR

// предпочтительные "круглые" значения для ширины шага сетки по оси времени
const steps = [DAY, HOUR, 10 * MIN, MIN, 10 * SEC, SEC, 100, 10, 1, 0.1]

/**
 * Рассчитать шаг между линиями сетки по оси времени (так чтобы он был кратен круглому значению - сек, мин и т.д.)
 */
export const calcTimeStep = (frame: number, t0: number, lines: number) => {
  // вычисляем ширину шага при котром на график войдут примерно *lines* линий сетки
  const baseStep = steps.find((v) => 2 * v < frame)
  const count = Math.ceil(frame / (baseStep * lines))
  const step = baseStep * count

  // вычисляем время первой линии сетки (минимальное круглое число кратное шагу и большее чем t0)
  let dayStart = new Date(t0).setHours(0)
  dayStart = dayStart - (dayStart % HOUR)
  const start = dayStart + step * Math.ceil((t0 - dayStart) / step)

  return { step, start }
}

/**
 * Отформатировать время в зависимости от ширины кадра
 */
export const formatTime = (ts: number, frame: number) => {
  const d = new Date(ts)

  if (frame < 5) return formatMicro(d, ts)
  else if (frame < 5 * SEC) return formatMilli(d)
  else if (frame < 5 * MIN) return pad`${d.getMinutes()}:${d.getSeconds()}`
  else if (frame < DAY) return pad`${d.getHours()}:${d.getMinutes()}`
  else if (frame < 7 * DAY) return pad`${d.getDate()}.${d.getMonth() + 1} ${d.getHours()}:${d.getMinutes()}`
  else return pad`${d.getDate()}.${d.getMonth() + 1}`
}

const help = {
  en: ['sec:ms.μs', 'sec:ms', 'min:sec', 'hr:min', 'date hr:min', 'date'],
  ru: ['сек:мс.мкс', 'сек:мс', 'мин:сек', 'час:мин', 'дата час:мин', 'дата'],
}

// подсказка по формату выводимого времени
export const formatTimeHelp = (frame: number, lang = 'en') => {
  if (frame < 5) return help[lang][0]
  else if (frame < 5 * SEC) return help[lang][1]
  else if (frame < 5 * MIN) return help[lang][2]
  else if (frame < DAY) return help[lang][3]
  else if (frame < 7 * DAY) return help[lang][4]
  else return help[lang][5]
}

const formatMicro = (d: Date, ts: number) => {
  const us = Math.round((ts * 1000) % 1000)
  const ms = d.getMilliseconds()
  const s = d.getSeconds()

  return `${zeropad(s)}:${zeropad3(ms)}.${zeropad3(us)}`
}

const formatMilli = (d: Date) => {
  const ms = d.getMilliseconds()
  const s = d.getSeconds()

  return `${zeropad(s)}:${zeropad3(ms)}`
}

const zeropad = (n: number) => (n < 10 ? '0' + n : '' + n)

const zeropad3 = (t: number) => (t < 10 ? '00' + t : t < 100 ? '0' + t : '' + t)

const pad = (parts: TemplateStringsArray, ...values: number[]) => {
  let result = parts[0]

  for (let i = 1; i < parts.length; i++) {
    result += zeropad(values[i - 1]) + parts[i]
  }

  return result
}
