import { InputOptions } from '../../../shared/Forms/forms.utils'
import { Equipment } from 'au-nsi/equipment'
import MappingForm from './MappingForm'

const defaultMapping = { name: '' }

const fields: InputOptions[] = [{ type: 'text', key: 'name' }]

interface Props {
  device: Equipment
  allowEditing: boolean
}

const MLP14Mapping = ({ device, allowEditing }: Props) => {
  return (
    <MappingForm
      columnsSize={[50, 50]}
      device={device}
      fields={fields}
      defaultMapping={defaultMapping}
      allowEditing={allowEditing}
    />
  )
}

export default MLP14Mapping
