import { useState } from 'react'
import Modal from '../../../../shared/Modal/Modal'
import ModalFooter from '../../../../shared/Modal/ModalFooter'
import AccessExpirationForm from './AccessExpirationForm'
import { DAY, getMidnight } from './devices-form.utils'

/**
 * Форма настройки срока действия доступа к устройствам
 */
const ExpirationModal = (props: Props) => {
  const [expiration, setExpiration] = useState(getMidnight() + 30 * DAY)

  const handleSave = () => {
    props.onSave(expiration)
    props.onClose()
  }

  return (
    <Modal onClose={props.onClose} shouldAnimate={props.shouldAnimate}>
      <h2>{props.title}</h2>

      <AccessExpirationForm expiration={expiration} onChange={setExpiration} />

      <ModalFooter onCancel={props.onClose} onSave={handleSave} />
    </Modal>
  )
}

interface Props {
  title: string
  onSave: (expires_at: number) => void
  onClose: () => void
  shouldAnimate?: boolean
}

export default ExpirationModal
