import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ReduxState } from '../../../redux/store.types'
import PanelButtons from '../../../shared/Forms/PanelButtons'
import { selectAccessRights } from '../../App/app.selectors'
import { ParameterDn } from '../../Parameters/params.interfaces'
import ImportModal from '../Modals/ImportModal'
import { patchEquipment } from '../nsi.actions'
import { Equipment } from 'au-nsi/equipment'
import { selectDevice as deviceSelector } from '../nsi.selectors'
import TolerancesTable from './TolerancesTable'
import { ToleranceSettings } from './vp.interfaces'
import { deviceParametersSelector, selectRootParameterGroups } from './vp.selectors'
import * as utils from './vp.utils'
import { ParameterGroup } from 'au-nsi/parameters'

const DataVerificationPanel = (props: Props) => {
  const tolerances = props.device.data_quality_options.tolerances || {}

  const [editing, setEditing] = useState(false)
  const [importing, setImporting] = useState(false)

  const [draftTolerances, setDraftTolerances] = useState<ToleranceSettings[]>([])

  // group tolerance settings into tabs
  useEffect(() => {
    if (!editing) {
      setDraftTolerances(utils.parseTolerances(tolerances, props.params))
    }
  }, [props.device])

  const handleChange = (rows: ToleranceSettings[]) => {
    setDraftTolerances(rows)
  }

  // user clicks 'edit'
  const startEditing = useCallback(() => setEditing(true), [])

  const startImport = useCallback(() => setImporting(true), [])
  const cancelImport = useCallback(() => setImporting(false), [])

  // user clicks 'cancel'
  const cancelChanges = useCallback(() => {
    setEditing(false)
    setDraftTolerances(utils.parseTolerances(tolerances, props.params))
  }, [props.device, props.params])

  // user clicks 'save'
  const applyChanges = useCallback(() => {
    setEditing(false)
    props.onChange(props.device, utils.serializeTolerances(draftTolerances))
  }, [props.device, draftTolerances])

  const handleImport = useCallback(
    (sourceId: string) => {
      const source = props.equipment.find((e) => e.id === sourceId)
      props.onChange(props.device, { ...source.data_quality_options.tolerances })
      setImporting(false)
    },
    [props.device, props.equipment]
  )

  return (
    <div>
      <TolerancesTable rows={draftTolerances} parameters={props.params} editing={editing} onChange={handleChange} />
      <PanelButtons
        editing={editing}
        allowEditing={props.allowEditing}
        allowImport={true}
        onEdit={startEditing}
        onCancel={cancelChanges}
        onSave={applyChanges}
        onImport={startImport}
      />
      <ImportModal
        isOpen={importing}
        onClose={cancelImport}
        onImport={handleImport}
        sources={props.equipment}
        targetId={props.device.id}
      />
    </div>
  )
}

interface Props {
  device: Equipment
  params: ParameterDn[] // only parameters that are recording by equipment
  groups: ParameterGroup[]
  equipment: Equipment[]
  allowEditing: boolean
  onChange: (device: Equipment, tolerances: { [param: string]: string }) => void
}

const mapStateToProps = (state: ReduxState) => {
  return {
    device: deviceSelector(state),
    params: deviceParametersSelector(state),
    groups: selectRootParameterGroups(state),
    equipment: state.nsi.equipment,
    allowEditing: selectAccessRights(state)['equipment:configure'],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChange(device: Equipment, tolerances: { [param: string]: string }) {
      const data_quality_options = { ...device.data_quality_options, tolerances }
      dispatch(patchEquipment(device.id, { data_quality_options }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataVerificationPanel)
