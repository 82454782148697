import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchInput from '../../../shared/Inputs/Search/SearchInput'
import confirmService from '../../../shared/Modal/confirm.service'
import { selectAccessRights } from '../../App/app.selectors'
import actions from './image.actions'
import styles from './image.module.css'
import { selectImages } from './image.selectors'
import ImageTile from './ImageTile'
import UpdateForm from './UpdateForm'
import UploadInput from './UploadInput'
import { useSelect } from '../../../hooks/useSelect'
import { highlightSearch } from '../../../utils/search'
import { Image } from 'au-nsi/images'

const ImagesPage = () => {
  const dispatch = useDispatch()

  const images = useSelector(selectImages)
  const allowEditing = useSelector(selectAccessRights)['images:update']
  const { selectedIndexes, setSelected, selectedCount, setSelectAll } = useSelect({ len: images.length })

  // последнее выбранное изображение
  const [selectedImage, setSelectedImage] = useState<Image>()

  // открыть/закрыть форму редактирования послежнего выбранного изображения
  const [updating, setUpdating] = useState(false)

  // поиск изображений по названию
  const [filter, setFilter] = useState('')
  const search = filter.split(/\s+/g)

  useEffect(() => {
    dispatch(actions.loadImages())
  }, [])

  // перемещение изображений
  const handleMove = useCallback((sourceId: string, targetId: string) => {
    dispatch(actions.moveImage(sourceId, targetId))
  }, [])

  // запросить подтверждение и удалить выбранные изображения
  const handleDelete = (id: Image['id']) => {
    const toRemove = [...selectedIndexes]

    if (toRemove.length === 0) {
      toRemove.push(id)
    }

    confirmService.requestDeleteConfirmation().then((r) => {
      r === 'delete' && toRemove.forEach((id) => dispatch(actions.deleteImage(id as string)))
    })
    // reset set
    setSelectAll(false)
  }

  const tiles = images.map((image) => {
    const { isMatch, result } = highlightSearch(image.fullname, search)
    if (!isMatch) return null

    const selected = selectedIndexes.has(image.id)

    return (
      <ImageTile
        allowEditing={allowEditing}
        image={image}
        imagesLength={images.length}
        key={image.id}
        name={result}
        onMove={handleMove}
        onSelect={(id, val) => setSelected(id, val)}
        selected={selected}
        selectedCount={selectedCount}
        onEdit={() => {
          setSelectedImage(image)
          setUpdating(true)
        }}
        onRemove={handleDelete}
        onContextMenuOpen={() => !selected && setSelectAll(false)}
      />
    )
  })

  return (
    <div className="nsi-main__container is-wide">
      <SearchInput onChange={(value) => setFilter(value.toLowerCase())} autofocus={true} />
      <div className={styles.library} style={{ marginTop: '10px' }}>
        {allowEditing && <UploadInput />}
        {tiles}
        {updating && <UpdateForm image={selectedImage} onClose={() => setUpdating(false)} />}
      </div>
    </div>
  )
}

export default ImagesPage
